import { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react'
import { Button, Drawer, makeStyles } from '@material-ui/core'
import { LoadingButton, AdvanceEditor } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  DrawerContent,
  DrawerFooter
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import clsx from 'clsx'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { hideChatMessenger, showChatMessenger, userRoles } from 'utils'
import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import { createTask } from 'thunks/task/actions'
import CreateTaskPanelHeader from './CreateTaskPanelHeader'
import taskSidePanelStyles from '../Workspace/Panel/taskSidePanelStyles'
import StatusDropDown from 'components/Workspace/StatusDropDown'
import EstimateTimePopup from 'components/Workspace/Panel/EstimateTimePopup'
import CreateTaskBody from './CreateTaskBody'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'

const CreateTaskPanel = ({ open, onClose, addSelectedTask }) => {
  const dispatch = useDispatch()
  const { currentWorkspace: project, currentBoard } = useSelector(
    state => state.userWorkspaces
  )

  const classes = taskSidePanelStyles({ inDashboardArea: true })
  const editorClasses = useStyles()
  // const [selectedProject, setSelectedProject] = useState({})
  const meData = useSelector(state => state.me.data)
  const [taskState, setTaskState] = useState({
    title: '',
    collaborators: [],
    description: '',
    priority: null,
    taskSection: {},
    estimateTimeInMilliSecond: null,
    estimateTimeInString: null,
    markAsComplete: null,
    startDate: '',
    dueDate: ''
  })

  const [popper, setPopper] = useState({
    anchorEl: null,
    type: ''
  })
  const [collabOptions, setCollabOptions] = useState([])
  const editorRef = useRef()
  const [loading, setLoading] = useState(false)
  // const [estimateTimeAnchor, setEstimateTimeAnchor] = useState(null)
  const [statusList, setStatusList] = useState([])
  const { getCollaboratorsOptions } = useWorkspaceFunctions()

  const users = useMemo(() => {
    if (taskState.collaborators) {
      return taskState.collaborators.map(item => ({
        ...item,
        id: item._id,
        value: item.name
      }))
    }
  }, [taskState.collaborators])

  useLayoutEffect(() => {
    if (open) {
      setTaskState({
        title: '',
        collaborators: [meData],
        description: '',
        priority: null,
        taskSection: currentBoard.boardSection
          ? currentBoard.boardSection[0]
          : [],
        estimateTimeInMilliSecond: null,
        estimateTimeInString: null,
        markAsComplete: null,
        startDate: '',
        endDate: ''
      })

      setStatusList(currentBoard.boardSection || [])
      hideChatMessenger()
      setPopper({ anchorEl: null, type: '' })
    } else {
      showChatMessenger()
    }
  }, [open, project, currentBoard])

  useEffect(() => {
    const collabs = getCollaboratorsOptions(taskState.collaborators, project)
    setCollabOptions(collabs)
  }, [taskState])

  const handleTitleChange = e => {
    setTaskState(prev => ({ ...prev, title: e.target.value }))
  }

  const handleRemoveTeammate = teammate => {
    setTaskState(prev => ({
      ...prev,
      collaborators: prev.collaborators.filter(
        item => item._id !== teammate._id
      )
    }))
  }

  // const handleAddTeammate = (teammate) => {}

  const handlePopperClose = () => {
    setPopper({ type: '', anchorEl: null })
  }

  const handleCollaborator = val => {
    setTaskState(prev => ({
      ...prev,
      collaborators: [...prev.collaborators, { ...val.user, role: val.role }]
    }))
    handlePopperClose()
  }

  const triggerPopover = (e, type) => {
    console.log(type, e.currentTarget)
    setPopper({ type, anchorEl: e.currentTarget })
  }

  const handleDescriptionChange = (range, source, user, editor) => {
    setTaskState(prev => ({ ...prev, description: editor.getContents() }))
  }

  const handlePriorityLabel = priority => {
    handlePopperClose()
    setTaskState(prev => ({
      ...prev,
      priority: priority.order === 5 ? '' : priority.order
    }))
  }

  const handleCreateTask = e => {
    e.preventDefault()

    if (!taskState.title.trim()) {
      dispatch(fireErrorToaster('Task title is required!'))
      return
    }

    const data = {
      title: taskState.title.trim(),
      workSpace: project._id,
      workspaceBoard: currentBoard._id,
      taskSection: currentBoard.boardSection[0]._id,
      description: taskState.description,
      priority: taskState.priority,
      assignedAgencyTeam: taskState.collaborators
        .filter(item => item.role < userRoles.USER_CLIENT)
        .map(item => item._id),
      assignedClientTeam: taskState.collaborators
        .filter(item => item.role >= userRoles.USER_CLIENT)
        .map(item => item._id),
      markAsComplete: taskState.markAsComplete,
      startDate: taskState.startDate,
      dueDate: taskState.dueDate,
      estimateTimeInMilliSecond: taskState.estimateTimeInMilliSecond,
      estimateTimeInString: taskState.estimateTimeInString
    }

    setLoading(true)
    dispatch(
      createTask(data, (res, err) => {
        setLoading(false)
        addSelectedTask({
          taskTitle: res.title,
          taskId: res._id,
          projectId: res.workSpace
        })
        onClose()
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster('Task created successfully!'))
        }
      })
    )
  }

  // const toggleEstimateTimePopup = (e) => {
  //   setEstimateTimeAnchor(estimateTimeAnchor ? null : e.currentTarget)
  // }

  const handleChangeEstimateTime = ({ timeStr, totalMs }) => {
    setTaskState(prev => ({
      ...prev,
      estimateTimeInString: timeStr,
      estimateTimeInMilliSecond: totalMs
    }))

    handlePopperClose()
  }

  const handleChangeSection = section => {
    setTaskState(prev => ({ ...prev, taskSection: section }))
  }

  const toggleMarkAsComplete = () => {
    setTaskState(prev => ({ ...prev, markAsComplete: !prev.markAsComplete }))
  }

  const handleDateChange = (e, prop) => {
    setTaskState(prev => ({ ...prev, [prop]: e.toISOString() }))
  }

  return (
    <>
      <Drawer
        onClose={onClose}
        classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
        anchor="right"
        open={open}
      >
        {/* ============== HEADER =============== */}
        <CreateTaskPanelHeader
          onClose={onClose}
          taskState={taskState}
          triggerPopover={triggerPopover}
          changeEstimateTime={handleChangeEstimateTime}
          // updateSection={updateSection}
          toggleMarkAsComplete={toggleMarkAsComplete}
        />
        <DrawerContent open={open} style={{ padding: 0 }}>
          <div className="overflow-y-auto">
            <div className="mt-2 mb-4 px-2">
              <input
                type="text"
                name="title"
                onChange={handleTitleChange}
                value={taskState.title}
                className="py-2 px-3 text-2xl font-semibold border border-transparent hover:border-gray-300 block w-full rounded-md"
                placeholder="Task Title"
              />
            </div>
            <CreateTaskBody
              taskState={taskState}
              triggerPopover={triggerPopover}
              handleDateChange={handleDateChange}
              handleRemoveTeammate={handleRemoveTeammate}
              project={project}
              // assignedTags={assignedTags}
              // setTagsPopoverData={setTagsPopoverData}
              // updateProjectStats={updateProjectStats}
            />

            <div className="mb-4 px-6">
              <FieldLabel className="mb-2">Description</FieldLabel>
              <div className="text-sm">
                <AdvanceEditor
                  ref={editorRef}
                  value={taskState.description}
                  imageDrop={false}
                  users={users}
                  onChange={handleDescriptionChange}
                  className={editorClasses.editor}
                />
              </div>
            </div>
          </div>
        </DrawerContent>
        <StatusDropDown
          open={popper.type === 'status'}
          anchorEl={popper.anchorEl}
          onClose={handlePopperClose}
          changeStatus={handleChangeSection}
          statusList={statusList}
          taskSection={taskState.taskSection}
          style={{ zIndex: 2000 }}
        />
        <CollabDrowdown
          open={popper.type === 'collab'}
          anchorEl={popper.anchorEl}
          onClose={handlePopperClose}
          list={collabOptions}
          handleListItemClick={handleCollaborator}
          meData={meData}
          style={{ zIndex: 2000 }}
        />
        <PriorityDropDown
          open={popper.type === 'priority'}
          anchorEl={popper.anchorEl}
          onClose={handlePopperClose}
          currentPriority={taskState.priority}
          handlePriorityLabel={handlePriorityLabel}
          style={{ zIndex: 2000 }}
        />
        <EstimateTimePopup
          open={popper.type === 'estimateTime'}
          anchorEl={popper.anchorEl}
          onClose={handlePopperClose}
          selectedTime={taskState.estimateTimeInString}
          handleChangeEstimateTime={handleChangeEstimateTime}
          style={{ zIndex: 2000 }}
        />

        <DrawerFooter>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton loading={loading} onClick={handleCreateTask}>
            Create
          </LoadingButton>
        </DrawerFooter>
      </Drawer>
    </>
  )
}

export const FieldLabel = props => {
  const { className, children, ...restProps } = props
  return (
    <label
      {...restProps}
      className={clsx('block text-xs text-gray-600', className)}
    >
      {children}
    </label>
  )
}

const useStyles = makeStyles({
  editor: {
    '& .ql-editor': {
      minHeight: 260,
      overflow: 'auto'
    }
  }
})

export default CreateTaskPanel
