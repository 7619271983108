import { useRef } from 'react'
import { List, ListItem, Popover } from '@material-ui/core'
import workspaceStyles from './workspaceStyles'
import { downloadFile } from 'utils'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'

const AttachmentDropDown = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    data,
    handleDeleteAttachment,
    handlePreviewImage,
    ...rest
  } = props
  const classes = workspaceStyles()
  const imageAnchorRef = useRef(null)
  const dispatch = useDispatch()

  const handleDownloadAttachment = () => {
    downloadFile(data, (err) => {
      if (err) {
        dispatch(
          fireErrorToaster(
            'Error occurred while downloading image, please try again'
          )
        )
      }
      onClose()
    })
    // fetch(data, {
    //   method: 'GET',
    //   headers: {
    //     Accept:
    //       'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
    //   }
    // })
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     // Create blob link to download
    //     const url = window.URL.createObjectURL(new Blob([blob]))
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.setAttribute('download', `FileName.png`)

    //     // Append to html link element page
    //     document.body.appendChild(link)

    //     // Start download
    //     link.click()

    //     // Clean up and remove the link
    //     link.parentNode.removeChild(link)
    //     onClose()
    //   })
    //   .catch((err) => console.log('Error occurred!'))
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      {...rest}
    >
      <List classes={{ root: classes.listRoot }} dense>
        <ListItem onClick={() => handlePreviewImage(data)} button>
          Preview
        </ListItem>
        <ListItem onClick={handleDownloadAttachment} button>
          Download Attachment
        </ListItem>
        <ListItem
          style={{ color: '#ff5263' }}
          button
          onClick={() => handleDeleteAttachment(data)}
        >
          Delete Attachment
        </ListItem>
        <a
          href={data}
          ref={imageAnchorRef}
          download
          style={{ display: 'none' }}
        >
          <img src={data} alt="" />
        </a>
      </List>
    </Popover>
  )
}

export default AttachmentDropDown
