import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { format } from 'date-fns'
import { Card } from 'global/globalComponents'

const OverdueTasks = ({ loading, tasks = [], openTaskPanel }) => {
  return (
    <Card className="h-full p-4">
      <header className="flex items-center justify-between mb-2">
        <h4 className="text-sm font-semibold text-primary-dark">
          Tasks Overdue
          <span className="w-8 h-8 rounded-full border inline-flex justify-center items-center ml-2 text-primary-mid-dark">
            {tasks.length}
          </span>
        </h4>
      </header>
      <div>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <div>
            {tasks.length ? (
              <ul
                className="text-sm overflow-auto"
                style={{ maxHeight: '280px' }}
              >
                {tasks.map(item => (
                  <li
                    key={item._id}
                    className="p-2 flex text-gray-700 text-xs lg:text-smaller mb-1 justify-between rounded cursor-pointer hover:bg-gray-100"
                    onClick={openTaskPanel.bind(this, item, 'overdue')}
                  >
                    <div className="flex-1 overdue-task-title">
                      {item.title}
                    </div>
                    <div className="text-red-400 ml-4 text-xs">
                      <time>{format(new Date(item.dueDate), 'dd MMM')}</time>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-gray-500">No overdue tasks found</div>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      {[
        Array(4)
          .fill('')
          .map(item => <Skeleton variant="rect" height={20} className="mb-4" />)
      ]}
    </>
  )
}

export default OverdueTasks
