// import logo from './logo.svg'
import { MuiThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux'
import Routes from 'Routes/Routes'
import theme from 'themes/themes'
import configureStore from 'store/store'
import { storeToken } from 'utils/userLogin'
import { useEffect } from 'react'
import './App.css'
import './AntdDatePicker.css'
import 'config/axiosConfig'

const store = configureStore()

var url_string = window.location.href
var url = new URL(url_string)
var auth_token = url.searchParams.get('auth_token')

if (auth_token) {
  storeToken(`Bearer ${auth_token}`)
}

function App() {
  useEffect(() => {
    if (window.testTimer) clearInterval(window.testTimer)
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <div className="font-sans">
          <Routes />
        </div>
      </Provider>
    </MuiThemeProvider>
  )
}

export default App
