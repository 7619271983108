import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { fetchUserActivePlanInfo } from 'thunks/userActivePlanInfo/actions'
import headerStyles from './headerStyles'
import GlobalHeader from './GlobalHeader/GlobalHeader'
import WorkspaceHeader from './WorkspaceHeader/WorkspaceHeader'
import { userRoles } from 'utils'
import { useLocation, Link } from 'react-router-dom'

const Header = ({ pusherMessage, payNowLink }) => {
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const normalHeaderState = useSelector(
    (state) => state.generalAppState.normalHeader
  )

  const { sidebarOpen, headerShadow } = useSelector(
    (state) => state.generalAppState
  )
  const classes = headerStyles({ headerShadow })

  const { pathname } = useLocation()
  const showStripe = useMemo(() => {
    return [
      '/agency/dashboard',
      '/agency/settings',
      '/agency/clients',
      '/agency/teammates'
    ].find((item) => pathname.startsWith(item))
  }, [pathname])

  useEffect(() => {
    if (meData.role === userRoles.USER_AGENCY) {
      dispatch(fetchUserActivePlanInfo())
    }
  }, [meData.role])

  return (
    <div
      className={clsx(classes.headerContainer, {
        sidebarExpanded: sidebarOpen
      })}
    >
      {meData.payment?.status !== 'paid' &&
        payNowLink &&
        [userRoles.AGENCY_ADMIN, userRoles.USER_AGENCY].includes(
          meData.role
        ) && (
          <div>
            {
              <div
                className={`flex justify-center items-center space-x-4 bg-primary-main z-10 py-2 `}
              >
                <p className="mb-0 text-white text-sm">
                  Your latest payment has failed, please pay now
                </p>
                <a
                  href={payNowLink}
                  // target="_blank"
                  className="ml-4 bg-white text-primary-main px-4 py-2 rounded
                  font-medium text-xs"
                >
                  Pay Now
                </a>
              </div>
            }
          </div>
        )}

      {normalHeaderState ? (
        <div
          className="flex justify-between items-center"
          style={{ height: '4.5rem' }}
        >
          <GlobalHeader
            pusherMessage={pusherMessage}
            // leftTrialDays={leftTrialDays}
            isSidebarOpen={sidebarOpen}
          />
        </div>
      ) : (
        <WorkspaceHeader isSidebarOpen={sidebarOpen} />
      )}
      {/* <AccountBlockedModal open={true} /> */}
    </div>
  )
}

export default React.memo(Header)
