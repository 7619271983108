import axios from 'axios'
import { TEAMMATES } from 'thunks/teammate/reducers'
import { errorMessages } from 'utils'
import { VERTICALS } from './reducer'

/**
 * Create Team Verticals
 * @param {Object} data title, description, image(optional)
 * @param {Function} callback
 */
export const createTeamVerticals = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/category',
        method: 'POST',
        data
      })

      if (callback) callback(res.data, false)
      dispatch({ type: VERTICALS.ADD_ONE_VERTICAL, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch Team Verticals
 * @param {Function} callback
 */
export const fetchTeamVerticals = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/category/category?type=vertical',
        method: 'GET'
      })

      if (callback) callback(res.data)
      dispatch({ type: VERTICALS.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update Team Verticals
 * @param {String} Id vertical id
 * @param {Object} data title, description, image(optional)
 * @param {Function} callback
 */
export const updateTeamVerticals = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/category/${id}`,
        method: 'PUT',
        data
      })
      if (callback) callback(res.data)
      dispatch({ type: VERTICALS.UPDATE_ONE_VERTICAL, payload: res.data })
      // if (data.newTeammates?.length) {
      //   dispatch({
      //     type: TEAMMATES.UPDATE_MANY_TEAMMATES,
      //     payload: {
      //       teams: data.newTeammates,
      //       data: {
      //         _id: res.data._id,
      //         name: res.data.name,
      //         description: res.data.description
      //       }
      //     }
      //   })
      // }
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete Team Vertical
 * @param {String} Id vertical id
 * @param {Function} callback
 */
export const deleteTeamVertical = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/category/${id}`,
        method: 'DELETE'
      })

      if (callback) callback(res.data)
      dispatch({ type: VERTICALS.DELETE_ONE_VERTICAL, payload: res.data._id })
    } catch (err) {
      if (callback)
        callback(err.response.data.message ?? errorMessages.ERROR_MESSAGE, true)
    }
  }
}
