import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import useTimerFunctions from './useTimerFunctions'
import { ReactComponent as StartTimer } from 'static/svg/play.svg'
import { ReactComponent as StopTimer } from 'static/svg/stop.svg'
import { ReactComponent as CloseIcon } from 'static/svg/close.svg'
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined'
import { AlertModal, LoadingButton } from 'global/globalComponents'
import { removeTimer } from 'thunks/Tracking/action'
import { TIMER } from 'thunks/Tracking/reducer'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'

const FloatingTimer = () => {
  const dispatch = useDispatch()
  const projectsData = useSelector((state) => state.userWorkspaces.data)
  const { activeTimer, isPaused: timerIsPaused } = useSelector(
    (state) => state.timer.data
  )
  const userWorkspaces = useSelector((state) => state.userWorkspaces)
  const timerProject = useMemo(() => {
    if (activeTimer) {
      return projectsData.find((item) => item._id === activeTimer.workspace)
    }
  }, [activeTimer, projectsData.length])

  const [move, setMove] = useState(false)
  const ref = useRef()
  const [containerStyle, setContainerStyle] = useState({ left: 16 })
  const {
    getTimeFromSeconds,
    startTimer,
    stopTimer,
    getTime,
    hideFloatingTimer
  } = useTimerFunctions()
  const [time, setTime] = useState({
    minutes: '00',
    seconds: '00',
    hours: '00'
  })
  const [loading, setLoading] = useState(false)
  const timerTask = useMemo(() => {
    if (activeTimer) {
      if (activeTimer.type === 'task') return activeTimer.task
      return activeTimer.subTask
    }
    return {}
  }, [activeTimer])
  const [alertModal, setAlertModal] = useState({ open: false, loading: false })

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userWorkspaces.fetchedFirstTime])

  useEffect(() => {
    if (activeTimer) {
      let timerId = '',
        timerDuration =
          activeTimer.type === 'task'
            ? activeTimer.task?.timerDuration ?? 0
            : activeTimer.subTask?.timerDuration ?? 0

      if (!timerIsPaused)
        timerId = getTime(activeTimer.start, timerDuration, setTime)
      else {
        clearInterval(timerId)
        const totalTime = getTimeFromSeconds(timerDuration / 1000)
        setTime(totalTime)
      }

      return () => clearInterval(timerId)
    }
  }, [activeTimer])

  const handleMouseDown = (e) => {
    setMove(true)
  }

  const handleMouseUp = (e) => {
    setMove(false)
  }

  const handleMouseMove = (e) => {
    if (move) {
      setContainerStyle({ left: e.clientX - 30 })
      // ref.current.style.left = e.screenX - 25 + 'px'
    }
  }

  const handleStartTimer = () => {
    let data = {
      workspace: activeTimer.workspace,
      workspaceBoard: activeTimer.workspaceBoard,
      type: activeTimer.type
    }

    if (activeTimer.type === 'task') {
      data.task = timerTask._id
    } else {
      data.subTask = timerTask._id
      data.task = timerTask.task
    }

    // console.log(data)
    // return

    setLoading(true)
    startTimer(data, activeTimer.task.title, () => {
      setLoading(false)
    })
  }

  const handleStopTimer = () => {
    let data = { type: activeTimer.type }

    if (activeTimer.type === 'task') {
      data.task = timerTask._id
    } else {
      data.subTask = timerTask._id
      data.task = timerTask.task
    }

    // console.log(data)
    // return

    setLoading(true)
    stopTimer(data, timerTask.title, (res, err) => {
      if (!err) setLoading(false)
    })
  }

  const handleToggleModal = () => {
    setAlertModal((prev) => ({ ...prev, open: !prev.open }))
  }

  const handleOnRemove = () => {
    if (timerIsPaused) dispatch({ type: TIMER.REMOVE })
    else handleToggleModal()
  }

  const handleRemoveTimerClick = () => {
    setAlertModal((prev) => ({ ...prev, loading: true }))

    let data = { type: activeTimer.type }

    if (activeTimer.type === 'task') {
      data.task = timerTask._id
    } else {
      data.subTask = timerTask._id
      data.task = timerTask.task
    }

    dispatch(
      removeTimer(data, timerTask.title, (res, err) => {
        if (err) {
          setAlertModal((prev) => ({ ...prev, loading: false }))
        } else {
          setAlertModal({ open: false, loading: false })
        }
      })
    )
  }

  return (
    <div
      className="fixed bg-primary-light shadow-lg rounded p-4 border border-primary-main show-on-hover-parent"
      style={{ zIndex: 999999999, bottom: '1rem', ...containerStyle }}
      ref={ref}
      // onMouseLeave={handleMouseUp}
    >
      <button
        className="absolute top-0 right-0 w-5 h-5 rounded-full border flex justify-center items-center bg-white text-primary-main show-on-hover-child"
        style={{ transform: 'translate(50%, -50%)' }}
        onClick={hideFloatingTimer}
      >
        &#8722;
      </button>

      <div
        className="flex items-center"
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div
          className="mr-2 show-on-hover-child"
          style={{ cursor: 'ew-resize' }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
        >
          <DragIndicatorOutlinedIcon fontSize="small" />
        </div>

        <div
          className="border-r border-gray-300 pr-12"
          style={{ maxWidth: '14rem' }}
        >
          <div
            className="font-medium text-primary-mid-dark-2 truncate"
            style={{ fontSize: 11 }}
          >
            {timerProject && timerProject.name}
          </div>
          <div className="text-smaller truncate">{timerTask?.title}</div>
        </div>

        <div className="text-smaller ml-4 w-24">
          {time.hours} : {time.minutes} : {time.seconds}
        </div>

        <div className="space-x-2">
          <LoadingButton
            startIcon={
              timerIsPaused ? (
                <StartTimer className="w-4 h-4" />
              ) : (
                <StopTimer className="w-4 h-4" />
              )
            }
            size="small"
            onClick={timerIsPaused ? handleStartTimer : handleStopTimer}
            loading={loading}
          >
            {timerIsPaused ? 'Start' : 'Stop'}
          </LoadingButton>

          <Button size="small" variant="outlined" onClick={handleOnRemove}>
            Remove
          </Button>

          <AlertModal
            warningText={
              'The timer is running, removing it will eventually stop the timer. Do you still want to remove it?'
            }
            open={alertModal.open}
            handleDialog={handleToggleModal}
            handleDeleteAction={handleRemoveTimerClick}
            loading={alertModal.loading}
            deleteBtnText="Remove"
            style={{ zIndex: 2000 }}
          />
        </div>
      </div>
    </div>
  )
}

export default FloatingTimer
