import React, { useEffect, useState } from 'react'
import {
  Box,
  TextField,
  Button,
  Modal,
  makeStyles,
  Backdrop,
  Fade
} from '@material-ui/core'
import { createTask } from 'thunks/task/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  // CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Select from 'react-select'
import {
  LoadingButton,
  FormLabel,
  CustomTooltip
} from 'global/globalComponents'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
// import taskSidePanelStyles from 'components/Workspace/Panel/taskSidePanelStyles'
import { updateRaisedRequest } from 'thunks/clientRaisedRequestAgency/actions'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import Collaborator from 'components/Workspace/Panel/Collaborator'
import { userRoles } from 'utils'

const RequestTaskModal = ({ open, handleDialog, data }) => {
  const classes = useStyles()
  const userWorkspaces = useSelector((state) => state.userWorkspaces.data)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [workspaceOptions, setWorkspaceOptions] = useState([])
  const [taskData, setTaskData] = useState({
    title: '',
    workSpace: {},
    createdAt: `${new Date()}`,
    description: '',
    workspaceBoard: '',
    taskSection: '',
    collaborators: []
  })
  const [collabOptions, setCollabOptions] = useState([])
  const [collabAnchor, setCollabAnchor] = useState(null)
  const meData = useSelector((state) => state.me.data)

  useEffect(() => {
    setTaskData({
      title: data.title,
      dueDate: '',
      workSpace: {},
      createdAt: `${new Date()}`,
      description: data.description,
      workspaceBoard: '',
      taskSection: '',
      collaborators: []
    })
  }, [data])

  useEffect(() => {
    if (userWorkspaces.length) {
      setWorkspaceOptions(
        userWorkspaces
          .filter((item) => !item.isArchived && !item.isComplete)
          .map((item) => ({ label: item.name, value: item._id }))
      )
    }
  }, [userWorkspaces])

  useEffect(() => {
    if (taskData.workSpace.value) {
      const ws = userWorkspaces.find(
        (item) => item._id === taskData.workSpace.value
      )

      if (ws) {
        let arr = []
        if (ws.assignedAgencyTeam) {
          const collab = [...ws.assignedAgencyTeam, ...ws.assignedClientTeam]

          for (let item of collab) {
            const idx = taskData.collaborators.findIndex(
              (user) => user._id === item.user._id
            )
            if (idx === -1) arr.push(item)
          }
        }

        setCollabOptions(arr)
      }
    }
  }, [taskData.workSpace.value, userWorkspaces, taskData.collaborators.length])

  const formInputChangeHandler = (e) => {
    const { name, value } = e.target
    setTaskData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleDateChange = (e) => {
    setTaskData((prev) => ({
      ...prev,
      dueDate: e?.toISOString()
    }))
  }

  const selectWorkspace = (e) => {
    setTaskData((prev) => ({
      ...prev,
      workSpace: e
    }))
  }

  const saveChangesHandler = (e) => {
    const taskDataClone = { ...taskData }

    if (!taskDataClone.title || !taskDataClone.workSpace.value) {
      return dispatch(fireErrorToaster('Please fill all the required fields'))
    }
    const selectedWs = userWorkspaces.find(
      (ws) => ws._id === taskDataClone.workSpace.value
    )
    if (selectedWs) {
      taskDataClone.workSpace = selectedWs._id
      taskDataClone.workspaceBoard = selectedWs.defaultBoard._id
      taskDataClone.taskSection = selectedWs.defaultBoard.boardSection[0]._id
    }
    if (taskDataClone.priority) {
      taskDataClone.priority = taskDataClone.priority
    }

    if (taskDataClone.collaborators.length) {
      taskDataClone.assignedAgencyTeam = taskDataClone.collaborators
        .filter((item) => item.role < userRoles.USER_CLIENT)
        .map((item) => item._id)
      taskDataClone.assignedClientTeam = taskDataClone.collaborators
        .filter((item) => item.role >= userRoles.USER_CLIENT)
        .map((item) => item._id)
    }

    delete taskDataClone.collaborators

    setLoading(true)
    dispatch(
      createTask(taskDataClone, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(updateRaisedRequest(data._id, { status: 'resolved' }))
          dispatch(fireSuccessToaster('Task Created Successfully!'))
          handleDialog()
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  const handleAddCollaborator = (team) => {
    setTaskData((prev) => ({
      ...prev,
      collaborators: [...prev.collaborators, { ...team.user, role: team.role }]
    }))
    toggleCollabAnchor()
  }

  const handleRemoveApprover = (team) => {
    setTaskData((prev) => ({
      ...prev,
      collaborators: prev.collaborators.filter((item) => item._id !== team._id)
    }))
  }

  const toggleCollabAnchor = (e) => {
    if (collabAnchor) {
      setCollabAnchor(null)
    } else {
      if (taskData.workSpace.value) setCollabAnchor(e.currentTarget)
      else dispatch(fireErrorToaster('Please select project first!'))
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleDialog}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className={classes.modal}
      >
        <Fade in={open}>
          <div
            className={`relative rounded-3xl ${classes.paper} overflow-visible`}
          >
            <CustomModalHeader
              heading="Convert To Task"
              // handleClose={handleDialog}
            />
            <CustomModalBody>
              <Box component="form" className="">
                <div className="mb-4 w-full">
                  <FormLabel required htmlFor="taskName">
                    Task Name
                  </FormLabel>
                  <TextField
                    id="taskName"
                    name="title"
                    value={taskData.title}
                    style={{ width: '100%' }}
                    onChange={formInputChangeHandler}
                    required
                  />
                </div>

                <div className="mt-4">
                  <FormLabel htmlFor="taskDescription">
                    Task Description
                  </FormLabel>
                  <TextField
                    name="description"
                    id="taskDescription"
                    onChange={formInputChangeHandler}
                    style={{ width: '100%' }}
                    multiline
                    rows={3}
                    rowsMax={6}
                    value={taskData.description}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: '5px',

                      colors: {
                        ...theme.colors,
                        primary25: '#F9F9F9',
                        primary: '#e6e6e6'
                      }
                    })}
                  />
                </div>
                <div className="w-full mt-4">
                  <FormLabel required>Select Project</FormLabel>
                  <Select
                    menuPlacement="top"
                    styles={reactSelectCustomStyles}
                    options={workspaceOptions}
                    onChange={selectWorkspace}
                    value={taskData.workSpace}
                    maxMenuHeight={130}
                    placeholder="Select"
                    className="border-0 border-none w-full"
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center mt-4 space-x-6">
                    <div>
                      <FormLabel>Set Due Date</FormLabel>
                    </div>

                    <div className="leading-3">
                      <DatePicker
                        onChange={(date) => handleDateChange(date)}
                        selected={
                          taskData.dueDate ? new Date(taskData.dueDate) : null
                        }
                        showTimeSelect
                        customInput={
                          taskData.dueDate ? (
                            <input
                              style={{
                                width: '180px',
                                padding: '4px 4px'
                              }}
                            />
                          ) : (
                            <CalenderIcon
                              className="text-base text-gray-600"
                              width="2.5em"
                            />
                          )
                        }
                        minDate={new Date()}
                        placeholderText="Select Date"
                        dateFormat="MMM d, yyyy h:mm aa"
                        className={classes.pseudoDropDownContainer}
                      />
                    </div>
                  </div>

                  <div className="flex items-center mt-2">
                    {taskData.collaborators.map((teammate) => (
                      <Collaborator
                        teammate={teammate}
                        handleRemove={handleRemoveApprover}
                        removeBtn={true}
                        size="small"
                      />
                    ))}

                    <CustomTooltip title="Add collaborators" placement="top">
                      <button
                        type="button"
                        onClick={toggleCollabAnchor}
                        className="w-6 h-6 border border-dashed border-gray-300 text-gray-500 rounded-full flex justify-center items-center"
                      >
                        <PlusIcon />
                      </button>
                    </CustomTooltip>
                  </div>
                </div>
              </Box>
            </CustomModalBody>
            <CustomModalFooter>
              <LoadingButton loading={loading} onClick={saveChangesHandler}>
                Create Task
              </LoadingButton>
              <Button onClick={handleDialog} variant="outlined">
                Cancel
              </Button>
            </CustomModalFooter>
          </div>
        </Fade>
      </Modal>
      <CollabDrowdown
        open={Boolean(collabAnchor)}
        anchorEl={collabAnchor}
        onClose={toggleCollabAnchor}
        list={collabOptions}
        handleListItemClick={handleAddCollaborator}
        meData={meData}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    userSelect: 'none'
  },
  paper: {
    width: '90%',
    maxWidth: '550px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none'
  },
  pseudoDropDownContainer: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    height: 36,
    padding: theme.spacing(0, 1),
    cursor: 'pointer',
    borderRadius: 6,
    marginLeft: '-8px',
    fontSize: 13,
    '&:hover': {
      backgroundColor: 'rgba(21,27,38,.04)'
    }
  }
}))

export default RequestTaskModal
