import axios from 'axios'
import { errorMessages } from 'utils'

export const createCustomURL = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/custom ',
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const updateCustomURL = async (data, id, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/custom/${id}`,
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const fetchCustomURLData = async (originalURL, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/custom?url=${originalURL}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const deleteCustomURL = async (id, callback) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/custom/${id}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
