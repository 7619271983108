export const LOGS = {
  LOADING: 'LOADING_LOGS',
  ERROR: 'LOGS_ERROR',
  FETCHED: 'LOGS_DATA_FETCHED',
  ALL: 'LOGS_ALL',
  POSTED_LOG: 'POSTED_LOG',
  DELETE_COMMENT: 'DELETE_COMMENT',
  WORKSPACE_LOGS: 'WORKSPACE_LOGS',
  SUBTASK_LOGS: 'SUBTASK_LOGS',
  WORKSPACE_LOGS: 'WORKSPACE_LOGS',
  UPDATE_WORKSPACE_LOGS: 'UPDATE_WORKSPACE_LOGS',
  UPDATE_SUBTASK_LOGS: 'UPDATE_SUBTASK_LOGS'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  currentWorkspaceLogs: { _id: '', data: [] },
  subtaskLogs: []
}

const logsReducer = (state = initialState, action) => {
  // const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case LOGS.LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }
    case LOGS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case LOGS.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: [...state.data, action.payload]
      }
    case LOGS.ALL:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      }
    case LOGS.SUBTASK_LOGS:
      return {
        ...state,
        subtaskLogs: action.payload
      }
    case LOGS.WORKSPACE_LOGS:
      let Data
      if (action.payload._id === state.currentWorkspaceLogs._id) {
        Data = [
          ...new Map(
            [
              ...state.currentWorkspaceLogs.data,
              ...action.payload.data
            ].map((item) => [item._id, item])
          ).values()
        ].sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      } else {
        Data = action.payload.data
      }

      return {
        ...state,
        currentWorkspaceLogs: { _id: action.payload._id, data: Data }
      }
    case LOGS.UPDATE_WORKSPACE_LOGS:
      return {
        ...state,
        currentWorkspaceLogs: {
          ...state.currentWorkspaceLogs,
          data: [action.payload, ...state.currentWorkspaceLogs.data]
        }
      }
    case LOGS.UPDATE_SUBTASK_LOGS:
      return {
        ...state,
        subtaskLogs: [...state.subtaskLogs, action.payload]
      }

    case LOGS.POSTED_LOG:
      return {
        ...state,
        data: [...state.data, action.payload]
      }

    case LOGS.DELETE_COMMENT:
      return {
        ...state,
        data: [...state.data].filter((item) => item._id !== action.payload._id),
        subtaskLogs: [...state.subtaskLogs].filter(
          (item) => item._id !== action.payload._id
        ),
        currentWorkspaceLogs: {
          ...state.currentWorkspaceLogs,
          data: [
            ...state.currentWorkspaceLogs.data.filter(
              (item) => item._id !== action.payload._id
            )
          ]
        }
      }
    default:
      return state
  }
}

export default logsReducer
