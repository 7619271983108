import React, { useMemo } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Popover,
  makeStyles
} from '@material-ui/core'
import { ColoredAvatars } from 'global/globalComponents'
import { userRoles } from 'utils'
import taskSidePanelStyles from './taskSidePanelStyles'

const CollabDrowdown = ({
  open,
  anchorEl,
  onClose,
  list = [],
  handleListItemClick,
  meData,
  elmFor = 'workspace',
  collabType = 'teammate',
  style = {}
}) => {
  const classes = taskSidePanelStyles()
  const filteredList = useMemo(() => {
    return list.filter((item) => {
      if (item.user && typeof item.user === 'string') return false
      return true
    })
  }, [list])

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      style={{ maxHeight: '65vh', ...style }}
    >
      <div>
        <List dense component="nav" aria-label="main mailbox folders">
          {filteredList.length > 0 ? (
            filteredList.map((item, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleListItemClick(item)}
              >
                <ListItemAvatar className={classes.avatar + ' mr-2'}>
                  <ColoredAvatars
                    user={
                      elmFor === 'workspace'
                        ? item?.user ?? { name: 'Unknown' }
                        : item
                    }
                    tooltip={false}
                    size="small"
                  />
                </ListItemAvatar>
                <ListItemText className="text-sm">
                  <span className="inline-block pr-4">
                    {elmFor === 'workspace' ? item?.user?.name : item?.name}
                  </span>
                  {/* {meData.role < userRoles.USER_CLIENT && (
                    <span className="text-xs ml-2 text-gray-500">
                      {elmFor === 'workspace' ? item?.user?.email : item?.email}
                    </span>
                  )} */}
                </ListItemText>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText>
                {collabType === 'client'
                  ? 'No client available'
                  : 'No collaborator available'}
              </ListItemText>
            </ListItem>
          )}
        </List>
      </div>
    </Popover>
  )
}

// const useStyles = makeStyles({
//   avatar: {
//     minWidth: 'initial'
//   }
// })

const areEqual = (prevProps, nextProps) => {
  return prevProps.open === nextProps.open
}

export default React.memo(CollabDrowdown, areEqual)
