/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Tabs, Tab } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Pill from 'global/globalComponents/Pill/Pill'
import { AvatarGroup } from '@material-ui/lab'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { useHistory } from 'react-router-dom'
import UserDashboardStyles from 'components/UserDashboard/userDashboardStyles'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { fetchAllStandupsAnalytics } from 'thunks/widget/actions'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { dateAndTimeHelper } from 'utils'

const totalTasksToShow = 10

const StandupsTasks = ({ widgetId, editMode, handleRemoveWidget }) => {
  const classes = UserDashboardStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const standups = useSelector(state => state.widget.standups)

  const tabsData = [
    { label: 'Today', value: 0 },
    { label: 'Yesterday', value: 1 },
    { label: 'Last week', value: 7 }
  ]

  const [selectedTab, setSelectedTab] = useState(0)

  const trackedTime = data => {
    return data.reduce((prev, obj) => prev + obj.duration, 0)
  }

  useEffect(() => {
    let start = new Date(new Date().setHours(0, 0, 0)).toISOString()
    let end = new Date(new Date().setHours(23, 59, 59)).toISOString()
    if (selectedTab === 1) {
      let d = new Date(Date.now() - 24 * 3600 * 1000)
      start = new Date(d.setHours(0, 0, 0)).toISOString()
      end = new Date(d.setHours(23, 59, 59)).toISOString()
    }
    if (selectedTab === 7) {
      start = new Date(
        new Date(Date.now() - 7 * 24 * 3600 * 1000).setHours(0, 0, 0)
      ).toISOString()
    }

    dispatch(fetchAllStandupsAnalytics(start, end))
  }, [selectedTab])

  const handleTabChange = (e, val) => {
    setSelectedTab(val)
  }

  const handleDeleteWidgetClick = () => {
    handleRemoveWidget(widgetId)
  }

  // useEffect(() => {
  //   if (!userWorkspaces.fetchedFirstTime) {
  //     dispatch(fetchAllWorkspacesForCurrentUser())
  //   }
  // }, [userWorkspaces.fetchedFirstTime])

  return (
    <>
      <div className="mb-2 flex justify-between items-center pr-2">
        <h2 className="text-base lg:text-lg leading-6 font-medium text-gray-900 flex items-center">
          <span>
            Standups{' '}
            <span className="text-gray-500 text-sm">
              ({standups.data.length})
            </span>
          </span>
        </h2>
        <div>
          {editMode ? (
            <button className="text-red-400" onClick={handleDeleteWidgetClick}>
              <DeleteIcon />
            </button>
          ) : (
            <div className="flex items-center space-x-2">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="simple tabs example"
                classes={{ root: classes.tabRoot }}
              >
                {tabsData.map((item, index) => (
                  <Tab
                    label={
                      <div className="flex items-center gap-1 text-xs">
                        {item.label}
                      </div>
                    }
                    key={index}
                    value={item.value}
                  />
                ))}
              </Tabs>
            </div>
          )}
        </div>
      </div>

      <TableContainer
        containerProps={{
          style: {
            overflowY: 'auto',
            height: 'calc(100% - 55px)',
            background: '#fff'
          }
        }}
        mb={9}
      >
        <Thead className="sticky top-0" style={{ zIndex: 99 }}>
          <Th>Standups</Th>
          <Th center>Project</Th>
          <Th center>Assignee</Th>
          <Th center>Estimated Time</Th>
          <Th center>Tracked Time</Th>
        </Thead>
        <Tbody>
          {standups.loading ? (
            <TableDataLoading cols={5} />
          ) : standups.data.length === 0 ? (
            <Tr>
              <Td colSpan="9">There are no standups.</Td>
            </Tr>
          ) : (
            standups.data.map((item, ind) => (
              <Tr key={ind} className="">
                <Td className="whitespace-nowrap text-gray-900">
                  <div>{item.name}</div>
                </Td>

                <Td center className="text-center whitespace-nowrap ">
                  {item.projectName ? (
                    <Pill variant="red" style={{ cursor: 'pointer' }}>
                      {item.projectName}
                    </Pill>
                  ) : (
                    <span>-</span>
                  )}
                </Td>
                <Td className="text-center">
                  <AvatarGroup
                    max={3}
                    style={{ display: 'inline-flex' }}
                    className={classes.avatarRoot}
                  >
                    {(item?.assignedAgencyTeam?.length ||
                      item?.assignedClientTeam?.length) &&
                      [...item?.assignedAgencyTeam, ...item?.assignedClientTeam]
                        .slice(0, 4)
                        .map(user => (
                          <ColoredAvatars
                            className="mx-auto"
                            size="small"
                            user={user}
                            tooltip={true}
                          />
                        ))}
                  </AvatarGroup>
                </Td>
                <Td className="text-center whitespace-nowrap">
                  {item.task?.estimateTimeInMilliSecond
                    ? dateAndTimeHelper.formatMilliseconds(
                        item.task.estimateTimeInMilliSecond
                      )
                    : '-'}
                </Td>
                <Td className="text-center whitespace-nowrap">
                  {item.timeTracking.length
                    ? dateAndTimeHelper.formatMilliseconds(
                        trackedTime(item.timeTracking)
                      )
                    : '-'}
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </TableContainer>
    </>
  )
}

export default StandupsTasks
