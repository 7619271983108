import React from 'react'
import { TestimonialImage } from 'static/Images'

function Testimonial() {
  return (
    <section className="relative max-w-lg mx-auto mb-16 py-12 bg-gray-50 rounded-3xl shadow-2xl overflow-hidden lg: mt-4">
      <div className="relative max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <img
            className="mx-auto h-10 w-10"
            src={TestimonialImage}
            alt="Workcation"
          />
          <blockquote className="mt-5">
            <div className="max-w-3xl mx-auto text-center text-lg leading-9 font-medium text-gray-900 lg: text-base">
              <p>
                &ldquo;We’ve been able to scale our SEO agency from a very high
                touch model to a very low touch and self serving model. Scaling
                our services has become easier with ClientVenue.&rdquo;
              </p>
            </div>
            <footer className="mt-5">
              <div className="flex-col item center justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src="https://cdns.useinfluence.co/website/agni-img.jpg"
                    alt=""
                  />
                </div>
                <div className="mt-3 flex-col items-center text-center md:mt-0 md:ml-4">
                  <div className="text-base font-medium text-gray-900">
                    Agni Chaterjee
                  </div>
                  <div className="text-base font-medium text-gray-500">
                    CEO, EvoSeedBox
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
