import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRoleBadge, userRoles } from 'utils'
import { useEffect } from 'react'
import { AvatarGroup } from '@material-ui/lab'
import { ColoredAvatars, Pagination, PopupMenu } from 'global/globalComponents'
import { useHistory } from 'react-router-dom'
import format from 'date-fns/format'
import {
  Popover,
  Tooltip,
  LinearProgress,
  makeStyles,
  createStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { Skeleton } from '@material-ui/lab'
import Pill from 'global/globalComponents/Pill/Pill'
import { DiagonalLines } from 'static/Images'
import moment from 'moment'
import getTimeString from '../Timesheets/TimesheetFunctions'
import workspaceStyles from 'components/Workspace/workspaceStyles'
import workloadStyles from './workloadStyles'
// import Task from 'components/Boards/BoardView/Column/Task'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'

const countDaysOfWeekBetweenDates = (
  sDate = '2022-05-09T00:00:00.000Z',
  eDate = '2022-05-15T00:00:00.000Z'
) => {
  const startDate = moment(sDate)
  const endDate = moment(eDate)

  endDate.add(1, 'day')
  const daysOfWeekCount = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0
  }
  while (startDate < endDate) {
    daysOfWeekCount[startDate.day()] = daysOfWeekCount[startDate.day()] + 1
    startDate.add(1, 'day')
  }

  let weekDays = {}

  ;['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].forEach((day, index) => {
    weekDays[day] = daysOfWeekCount[index]
  })

  return weekDays
}

const totalProjectsToShow = 8

const WorkloadTable = ({
  search = '',
  timelineData,
  loading,
  selectedDays,
  startDate,
  endDate,
  setLoading
}) => {
  const classes = workloadStyles()
  const meData = useSelector(state => state.me.data)
  const teamVerticals = useSelector(state => state.teamVerticals.data)

  const userVertical = user => {
    if (user.verticals?.length) {
      const vertical = teamVerticals.find(el => el._id === user.verticals[0])
      if (vertical) {
        return <Pill variant="pink">{vertical.name}</Pill>
      } else {
        return null
      }
    } else {
      return null
    }
  }
  const [visibleData, setVisibleData] = useState([])
  const [page, setPage] = useState(0)
  const [hoverIndex, setHoverIndex] = useState('')
  const [newTimelineData, setNewTimelineDate] = useState([])
  const [dayHours, setDayHours] = useState(8)
  const [capacity, setCapacity] = useState(48)
  const [offDay, setOffDay] = useState([])
  const [workingDays, setWorkingDays] = useState({
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: false
  })

  useEffect(() => {
    if (meData?._id) {
      setCapacity(
        meData?.profile?.capacity ?? meData?.team?.profile?.capacity ?? 56
      )
      setWorkingDays(
        (meData?.profile?.workingDays ||
          meData?.team?.profile?.workingDays) ?? {
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
          sun: true
        }
      )
    }
  }, [meData])

  useEffect(() => {
    setDayHours(
      capacity / Object.keys(workingDays).filter(day => workingDays[day]).length
    )
  }, [capacity, workingDays])

  useEffect(() => {
    setOffDay(Object.keys(workingDays).filter(day => workingDays[day]))
  }, [workingDays])

  useEffect(() => {
    if (!newTimelineData.length) return setVisibleData([])

    let arr = [...newTimelineData]

    if (search.trim() !== '') {
      arr = arr.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    }

    setVisibleData(arr.splice(page * totalProjectsToShow, totalProjectsToShow))
  }, [newTimelineData, page, search])

  const handleHoverCol = e => {
    setHoverIndex(parseInt(e.currentTarget.dataset.index))
  }

  const handleMouseLeave = e => {
    setHoverIndex('')
  }

  const maxNumber = data => {
    const arr = data.map(el => el.end)
    if (arr.length) {
      let max = arr[0]
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i] > max) {
          max = arr[i]
        }
      }
      return max
    }
  }

  useEffect(() => {
    const updatedTimelineData = timelineData.map(user => {
      let userWorkload = {}
      selectedDays.forEach(day => {
        userWorkload[day] = { time: 0, tasks: [] }
      })

      user.assignedWorkspace?.forEach(ws => {
        ws.tasks
          ?.filter(task => {
            let taskStartDate = task.startDate ? task.startDate : task.createdAt
            return (
              new Date(taskStartDate).getTime() <=
                new Date(endDate).getTime() &&
              (task.completedAt
                ? new Date(task.completedAt).getTime() >=
                  new Date(startDate).getTime()
                : true)
            )
          })
          .forEach(task => {
            let taskStartDate = task.startDate ? task.startDate : task.createdAt
            let taskEndDate
            if (task.completedAt) {
              if (task.timerDuration && task.timeTracking.length) {
                task.timeTracking.forEach(item => {
                  taskEndDate = new Date(item.end)
                  taskStartDate = new Date(item.start)
                  userWorkload = taskWorkloadPerDay(
                    task,
                    taskStartDate,
                    taskEndDate,
                    item.end - item.start,
                    1,
                    userWorkload
                  )
                })
              } else if (
                !task.timerDuration &&
                task.estimateTimeInMilliSecond
              ) {
                taskEndDate = new Date(task.completedAt)
                userWorkload = taskWorkloadPerDay(
                  task,
                  taskStartDate,
                  taskEndDate,
                  task.estimateTimeInMilliSecond,
                  task.assignedAgencyTeam.length,
                  userWorkload
                )
              } else {
                return
              }
            } else if (
              !task.completedAt &&
              task.dueDate &&
              task.timeTracking.length
            ) {
              const trackedTime = task.timeTracking.reduce(
                (acc, obj) => acc + obj.duration,
                0
              )

              task.timeTracking.forEach(item => {
                taskEndDate = new Date(item.end)
                taskStartDate = new Date(item.start)
                userWorkload = taskWorkloadPerDay(
                  task,
                  taskStartDate,
                  taskEndDate,
                  item.end - item.start,
                  1,
                  userWorkload
                )
              })
              if (
                task.estimateTimeInMilliSecond &&
                trackedTime <
                  task.estimateTimeInMilliSecond /
                    task.assignedAgencyTeam.length
              ) {
                const lastTimeTrackedDate = maxNumber(task.timeTracking)
                taskEndDate = task.dueDate
                taskStartDate = new Date(lastTimeTrackedDate)
                userWorkload = taskWorkloadPerDay(
                  task,
                  taskStartDate,
                  taskEndDate,
                  task.estimateTimeInMilliSecond /
                    task.assignedAgencyTeam.length -
                    trackedTime,
                  1,
                  userWorkload
                )
              }
            } else if (
              !task.completedAt &&
              task.dueDate &&
              task.estimateTimeInMilliSecond &&
              new Date(task.dueDate).getTime() >= new Date(startDate).getTime()
            ) {
              taskEndDate = task.dueDate
              userWorkload = taskWorkloadPerDay(
                task,
                taskStartDate,
                taskEndDate,
                task.estimateTimeInMilliSecond,
                task.assignedAgencyTeam.length,
                userWorkload
              )
            } else if (
              !task.completedAt &&
              !task.dueDate &&
              task.timeTracking.length
            ) {
              task.timeTracking.forEach(item => {
                taskEndDate = new Date(item.end)
                taskStartDate = new Date(item.start)
                userWorkload = taskWorkloadPerDay(
                  task,
                  taskStartDate,
                  taskEndDate,
                  item.end - item.start,
                  1,
                  userWorkload
                )
              })
            } else if (
              !task.completedAt &&
              !task.dueDate &&
              task.estimateTimeInMilliSecond
            ) {
              taskEndDate = new Date(
                new Date(taskStartDate).getTime() +
                  task.estimateTimeInMilliSecond
              )
              userWorkload = taskWorkloadPerDay(
                task,
                taskStartDate,
                taskEndDate,
                task.estimateTimeInMilliSecond,
                task.assignedAgencyTeam.length,
                userWorkload
              )
            } else {
              return
            }
          })
      })
      return { ...user, workload: userWorkload }
    })
    setNewTimelineDate(updatedTimelineData)
  }, [timelineData, selectedDays, startDate])

  const taskWorkloadPerDay = (
    task,
    taskStartDate,
    taskEndDate,
    estimateTimeInMilliSecond,
    usersCount,
    userWorkload
  ) => {
    let taskWorkload = { ...userWorkload }
    selectedDays.forEach(day => {
      if (!workingDays[day.split(' ')[0]?.toLowerCase()]) {
        taskWorkload[day].time = 0
        taskWorkload[day].tasks = []
      } else {
        if (
          new Date(taskEndDate).getTime() >= new Date(day).getTime() &&
          new Date(taskStartDate).getTime() <=
            new Date(day).setHours(23, 59, 59, 999)
        ) {
          let timeSpan = 0

          const daysFrequency = countDaysOfWeekBetweenDates(
            new Date(taskStartDate).toDateString(),
            new Date(taskEndDate).toDateString()
          )

          Object.keys(daysFrequency).forEach(day => {
            if (workingDays[day]) {
              timeSpan = timeSpan + daysFrequency[day]
            }
          })

          let timePerDay =
            estimateTimeInMilliSecond / usersCount / (timeSpan * 3600 * 1000)

          if (timePerDay <= 24) {
            taskWorkload[day].time = taskWorkload[day].time + timePerDay
            taskWorkload[day].tasks = [
              ...taskWorkload[day].tasks,
              { ...task, workloadTimeDistribution: timePerDay }
            ]
          }
        }
      }
    })

    return taskWorkload
  }

  const timePercentage = (time, tasks) => {
    let percent = (time * 100) / dayHours
    let element
    if (percent === 0) {
      element = (
        <div
          className="w-full rounded-md font-medium text-white p-2 text-right text-xs "
          style={{
            height: '100%',
            backgroundColor: 'rgba(245, 158, 11, 0.8)'
          }}
        >
          FREE
        </div>
      )
    } else if (percent > 0 && percent <= 100) {
      element = (
        <CustomTooltip
          title={<TooltipTitle time={time} dayHours={dayHours} data={tasks} />}
        >
          <div
            className="relative w-full rounded-md overflow-hidden"
            style={{ height: '100%' }}
          >
            <div className="absolute z-10 right-0 text-white p-2 text-xs">
              {getTimeString(time * 3600)}/{Math.round(dayHours)}h
            </div>
            <div
              className="w-full "
              style={{
                height: `${100 - percent}%`,
                backgroundColor: 'rgb(134, 239, 172)'
              }}
            ></div>
            <div
              className="w-full"
              style={{
                height: `${percent}%`,
                backgroundColor: 'rgba(21, 128, 61, 0.8)'
              }}
            ></div>
          </div>
        </CustomTooltip>
      )
    } else if (percent > 100) {
      element = (
        <CustomTooltip
          title={<TooltipTitle time={time} dayHours={dayHours} data={tasks} />}
        >
          <div
            className="w-full text-right text-white p-2 bg-red-500 rounded-md"
            style={{
              height: `100%`,
              backgroundColor: 'rgba(239, 68, 68, 0.8)'
            }}
          >
            <span> {getTimeString((time - dayHours) * 3600)}</span>
            <span className="block font-medium text-gray-100 text-xs">
              OVER CAPACITY
            </span>
          </div>
        </CustomTooltip>
      )
    } else {
      element = null
    }
    return element
  }

  return (
    <div>
      <div
        className={clsx(
          'bg-white flex align-middle overflow-hidden shadow sm:rounded-lg mb-6',
          classes.table
        )}
      >
        <ColGroup
          heading={`Assignee (${timelineData.length})`}
          sort={false}
          styles={{ minWidth: 250, maxWidth: 300 }}
          loading={loading}
        >
          {visibleData.length ? (
            <div className={(classes.colWrapper, 'border-r')}>
              {visibleData.map((item, index) => (
                <Td
                  className="flex items-center space-x-1 text-xs lg:text-sm cursor-pointer whitespace-nowrap truncate"
                  key={item._id}
                  data-id={item._id}
                  index={index}
                  isHovered={hoverIndex === index}
                  onMouseEnter={handleHoverCol}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="w-full">
                    <div className="w-full pl-2 flex space-x-2 items-center">
                      <ColoredAvatars size="medium" user={item} />
                      <div className="flex flex-col w-full">
                        <div className="flex items-center leading-3">
                          <span className="text-primary-mid-dark font-medium mr-2 truncate">
                            {item.name}
                          </span>{' '}
                          {userVertical(item)}
                        </div>

                        <span className="mt-1 mb-2 text-xs  font-medium">
                          {getTimeString(
                            Object.values(item.workload).reduce(
                              (acc, obj) => acc + obj.time,
                              0
                            ) * 3600
                          )}
                          /{capacity}h
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 pl-2  pr-2 w-full">
                      <LinearProgress
                        variant="determinate"
                        value={
                          (Object.values(item.workload).reduce(
                            (acc, obj) => acc + obj.time,
                            0
                          ) *
                            100) /
                          capacity
                        }
                        style={{
                          width: '100%',
                          borderRadius: '3px'
                        }}
                      />
                    </div>
                  </div>
                </Td>
              ))}
            </div>
          ) : (
            <Td>
              <span className="text-sm">No data found!</span>
            </Td>
          )}
        </ColGroup>
        <div
          className={clsx(
            classes.tableRight,
            'flex-1 flex overflow-x-auto w-full'
          )}
        >
          {selectedDays?.map(day => (
            <ColGroup
              heading={
                <div className="flex justify-between">
                  {' '}
                  <span className="font-medium capitalize">
                    {day.split(' ')[0]}
                  </span>
                  <span className="font-base tect-xs text-gray-500 capitalize">
                    {day.split(' ').slice(2, 3).join(' ')}
                  </span>
                </div>
              }
              classes={{ root: classes.tableCell }}
              loading={loading}
            >
              <div className="border-r">
                {visibleData.map((item, index) => (
                  <Td
                    className="text-xs p-0"
                    key={item._id}
                    data-id={item._id}
                    index={index}
                    isHovered={hoverIndex === index}
                    onMouseEnter={handleHoverCol}
                    onMouseLeave={handleMouseLeave}
                  >
                    {!workingDays[day.split(' ')[0].toLowerCase()] ? (
                      // <CustomTooltip>
                      <div
                        className="w-full"
                        style={{ height: '100%', overflow: 'hidden' }}
                      >
                        <img
                          src={DiagonalLines}
                          alt="Diagonal line"
                          className="w-full h-full object-contain"
                        />
                      </div>
                    ) : (
                      // </CustomTooltip>
                      timePercentage(
                        item.workload[day]?.time,
                        item.workload[day]?.tasks
                      )
                    )}
                  </Td>
                ))}
              </div>
            </ColGroup>
          ))}
        </div>
      </div>

      {timelineData.length > totalProjectsToShow && (
        <Pagination
          handlePagination={setPage}
          totalResultsCount={timelineData.length}
          visibleResultsCount={visibleData.length}
          page={page}
          limit={totalProjectsToShow}
        />
      )}
      <Popover></Popover>
    </div>
  )
}

const ColGroup = ({
  heading,
  classes = {},
  children,
  loading,
  styles = {}
}) => {
  return (
    <div style={styles ? { ...styles } : null} className={clsx(classes.root)}>
      <Th className={clsx(classes.heading)}>
        <span>{heading}</span>
      </Th>
      <div className={clsx(classes.tdRoot)}>
        {loading ? <ColumnSkeleton /> : children}
      </div>
    </div>
  )
}

const Th = React.memo(({ children, className, ...rest }) => {
  return (
    <div
      style={{ minHeight: 40 }}
      className={clsx(
        'px-6 py-4 tracking-wider font-medium py-3 px-6 text-xs text-gray-600 bg-gray-100 uppercase select-none border-r',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
})

const Td = React.memo(
  ({ children, loading, className, index, isHovered, ...rest }) => {
    return (
      <>
        {loading ? (
          <div
            className={clsx('px-6 py-4 text-gray-500 transition-all')}
            style={{ height: 50 }}
          >
            <Skeleton variant="rect" height={30} className="w-full rounded" />
          </div>
        ) : (
          <div
            className={clsx(
              'px-2 py-4 text-gray-500 transition-all border-b',
              { 'bg-gray-100': isHovered },
              className
            )}
            style={{ height: 100 }}
            data-index={index}
            {...rest}
          >
            {children}
          </div>
        )}
      </>
    )
  }
)

const ColumnSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill('')
        .map((item, index) => (
          <Td key={index} className="border-r">
            <Skeleton variant="rect" width="100%" height={20} />
          </Td>
        ))}
    </>
  )
}

const TooltipTitle = ({ time, dayHours, data }) => {
  return (
    <div className="p-4" style={{ width: 260 }}>
      <header>
        <div className="flex justify-between items-center mb-1">
          <span className="text-smaller font-medium text-gray-500">
            Scheduled
          </span>
          <span className="text-smaller font-medium text-gray-500">
            {getTimeString(time * 3600)}
          </span>
        </div>

        <div className="flex justify-between items-center mb-1">
          <span className="text-smaller font-medium text-gray-500">
            Capacity
          </span>
          <span className="text-smaller font-medium text-gray-500">
            {Math.round(dayHours)}h
          </span>
        </div>
      </header>

      <hr className="my-2" />

      <div>
        <h6 className="font-semibold text-smaller mb-2">Tasks</h6>
        <ul>
          {data.map((item, index) => (
            <li key={index} className="py-1 flex justify-between items-start">
              <div className="flex items-center text-smaller text-gray-600">
                {/* <CheckCircle
                  className={clsx(
                    'w-4 h-4',
                    item.isComplete ? 'text-green-400' : 'text-gray-400'
                  )}
                  style={{ flexShrink: 0, marginRight: '4px' }}
                /> */}
                <span className="">{item.title}</span>
              </div>
              <span className="text-xs font-medium text-gray-500 whitespace-nowrap pl-2">
                {getTimeString(item.workloadTimeDistribution * 3600)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

/**
 * Custom tooltip
 * @param {Object} props Takes props of `Tooltip` of material ui
 */
const CustomTooltip = props => {
  const classes = useStyles()
  return <Tooltip placement="top" arrow classes={classes} {...props} />
}

const useStyles = makeStyles(theme =>
  createStyles({
    arrow: {
      color: theme.palette.common.white
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px'
    }
  })
)

export default WorkloadTable

// Use Cases
// 1. Completed Task =>
// a) time tracked
// b) timeEstimates
// c) Due date - start date

// 2. Ongoing task
// a) TimeEstimate
// b) Time tracked

// 3. Future Task
// a. Estimate Time

//  task.completedAt >= startDate
