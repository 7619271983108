import React, { useState, useEffect, useImperativeHandle } from 'react'
import { ColoredAvatars, LoadingButton } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'
// import { updateCustomInvoice } from 'thunks/invoices/actions'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { Skeleton } from '@material-ui/lab'
import { userRoles } from 'utils'
import InvoiceFromTo from './InvoiceFromTo'
import InvoicePricingTable from './InvoicePricingTable'
import InvoiceExpensesAndTotal from './InvoiceExpensesAndTotal'
import InvoiceOtherInfo from './InvoiceOtherInfo'
// import { useBreadcrumbs } from 'customHooks'
// import { Button } from '@material-ui/core'
// import { ArrowLeft } from '@material-ui/icons'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { TextField } from '@material-ui/core'
import { getClient } from 'thunks/addClient/actions'

const invoiceItemFields = {
  invoiceId: '',
  name: '',
  description: '',
  quantity: '1',
  rate: '',
  itemType: '',
  position: '',
  total: 0
}

const EditInvoice = React.forwardRef(({ invoices }, ref) => {
  const [editInvoice, setEditInvoice] = useState({})
  const [invoiceLoading, setInvoiceLoading] = useState(true)
  const { invoiceId } = useParams()
  const [fromToData, setFromToData] = useState({
    agency: {},
    client: {},
    issuedDate: new Date(),
    dueDate: new Date(),
    notes: ''
  })

  const [invoiceItems, setInvoiceItems] = useState([])
  const [expenses, setExpenses] = useState({
    tax: '',
    discount: ''
  })

  const [invoiceItemsTotal, setInvoiceItemsTotal] = useState({
    grandTotal: 0,
    subtotal: 0
  })
  const meData = useSelector((state) => state.me.data)
  const userClients = useSelector((state) => state.userClients)
  // const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  // const { url } = useRouteMatch()
  const [taxDetails, setTaxDetails] = useState([])
  const userServicesData = useSelector((state) => state.userServices.data)
  const [services, setServices] = useState([])
  const history = useHistory()
  const [serviceIsRecurring, setServiceIsRecurring] = useState(false)

  useEffect(() => {
    if (!userClients.fetched) {
      dispatch(getClient())
    }
  }, [userClients])

  useEffect(() => {
    if (invoiceId) {
      const invoice = invoices.find((item) => item._id === invoiceId)
      if (invoice && invoice.status === 'paid') {
        history.goBack()
      }
    }
  }, [invoiceId])

  useEffect(() => {
    if (invoices.length) {
      const invoice = invoices.find((item) => item._id === invoiceId)

      // 1. Getting the invoice to edit
      setEditInvoice({ ...invoice })

      // 2. Setting From To data (Top)
      setFromToData({
        agency: {
          ...invoice.agency,
          agencyCompanyName:
            invoice.agency.agencyCompanyName || getBrandObj(meData).brandName, // adding this field on update
          agencyAddress:
            invoice.agency.agencyAddress || getStringAddress(meData) || '' // adding this field on update
        },

        client: {
          ...invoice.client,
          clientId: invoice.client.clientId ?? '',
          clientName: invoice.client.clientName ?? '',
          clientEmail: invoice.client.clientEmail ?? '',
          clientCompanyName: invoice.client.clientCompanyName || '', // adding this field on update
          clientAddress: invoice.client.clientAddress || '' // adding this field on update
        },
        issuedDate: invoice.issuedDate,
        dueDate: invoice.dueDate
      })

      // 3. Setting Invoice Items (Middle)
      if (invoice.invoiceItems.length) {
        setInvoiceItems([...invoice.invoiceItems])
      } else {
        setInvoiceItems([
          {
            ...invoiceItemFields,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          }
        ])
      }

      // 4. Setting Expenses
      setExpenses({
        tax: invoice.taxPercent || '',
        discount: invoice.discountAmount || ''
      })

      // 5. Tax Details
      if (invoice.taxDetail) setTaxDetails(invoice.taxDetail)

      setInvoiceLoading(false)
    }
  }, [invoices])

  useEffect(() => {
    if (invoiceItems.length) {
      let invoiceSubtotal = 0

      for (let item of invoiceItems) {
        invoiceSubtotal += +item.total
      }

      let grandTotal = invoiceSubtotal - (expenses.discount || 0)
      const taxAmount = (grandTotal / 100) * (expenses.tax || 0)

      setInvoiceItemsTotal({
        subtotal: invoiceSubtotal.toFixed(2),
        grandTotal: (grandTotal + taxAmount).toFixed(2)
      })
    }
  }, [invoiceItems, expenses])

  useEffect(() => {
    if (invoiceItems.find((item) => item.isRecurring)) {
      setServiceIsRecurring(true)
    } else {
      setServiceIsRecurring(false)
    }
  }, [invoiceItems])

  useEffect(() => {
    if (serviceIsRecurring) {
      const items = [...invoiceItems]
      setInvoiceItems(items.filter((el) => el.name))
    }
  }, [serviceIsRecurring])

  useEffect(() => {
    setServices([...userServicesData])
  }, [userServicesData])

  useImperativeHandle(ref, () => ({
    getUpdateInvoiceData: () => {
      return {
        editInvoice,
        fromToData,
        invoiceItemsTotal,
        taxDetails,
        expenses,
        invoiceItems
      }
    }
  }))

  const handleDateChange = (type, date) => {
    setFromToData((prev) => ({
      ...prev,
      [type]: new Date(date).toISOString()
    }))
    // setFromToData((prev) => ({ ...prev, [type]: new Date(date).toISOString() }))
  }

  const handleClientFieldChange = (e) => {
    const field = e.target.name
    setFromToData((prev) => ({
      ...prev,
      client: { ...prev.client, [field]: e.target.value }
    }))
  }

  const handleSelectClient = (data) => {
    setFromToData((prev) => ({ ...prev, client: { ...prev.client, ...data } }))
  }

  const handleAgencyFieldChange = (e) => {
    const field = e.target.name
    setFromToData((prev) => ({
      ...prev,
      agency: { ...prev.agency, [field]: e.target.value }
    }))
  }

  const handleFieldChange = (e) => {
    const field = e.target.name
    setEditInvoice((prev) => ({ ...prev, [field]: e.target.value }))
    // setFromToData((prev) => ({ ...prev, [field]: e.target.value }))
  }

  const updateClientCCEmail = (arr) => {
    setFromToData((prev) => ({
      ...prev,
      client: { ...prev.client, client_cc_email: arr }
    }))
  }

  const addInvoiceItem = () => {
    setInvoiceItems((prev) => [
      ...prev,
      {
        ...invoiceItemFields,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }
    ])
  }

  const removeInvoiceItem = (e) => {
    const index = e.currentTarget.dataset.index
    setInvoiceItems(invoiceItems.filter((item, idx) => idx !== +index))
  }

  const updateInvoiceItemField = (e) => {
    const field = e.target.name
    const index = e.target.dataset.index
    const invoiceItemsClone = [...invoiceItems]

    if (field === 'quantity') {
      invoiceItemsClone[index][field] = parseInt(e.target.value) //preventing floating numbers
    } else {
      invoiceItemsClone[index][field] = e.target.value
    }

    invoiceItemsClone[index]['invoiceId'] = editInvoice._id

    if (field === 'rate' || field === 'quantity') {
      invoiceItemsClone[index]['total'] =
        invoiceItemsClone[index]['rate'] *
          invoiceItemsClone[index]['quantity'] || 0
    }
    setInvoiceItems(invoiceItemsClone)
  }

  const handleExpensesChange = (e) => {
    const field = e.target.name
    setExpenses((prev) => ({ ...prev, [field]: e.target.value }))
  }

  const handleCurrencyChange = (currency) => {
    // setFromToData((prev) => ({ ...prev, currency }))
    setEditInvoice((prev) => ({ ...prev, currency }))
  }

  const updateTaxDetail = (e) => {
    // Right now it is just strings inside an array, in future it may become array of objects
    setTaxDetails([e.target.value])
  }

  const selectServiceFromPopup = (service, index) => {
    const invoiceItemsClone = [...invoiceItems]

    invoiceItemsClone[index] = service
    invoiceItemsClone[index].quantity = 1
    invoiceItemsClone[index].rate = service.amount * 0.01
    invoiceItemsClone[index].total = service.amount * 0.01

    setInvoiceItems(invoiceItemsClone)
  }

  return (
    <div>
      {invoiceLoading ? (
        <Skeleton
          variant="rect"
          width={650}
          height={600}
          className="mx-auto rounded"
        />
      ) : (
        <div className="bg-white border border-gray-300 shadow p-4 rounded">
          <div className="mb-4">
            <InvoiceNumber
              // invoiceNumber={editInvoice.invoiceNumber}
              customInvoiceNumber={
                editInvoice.customInvoiceNumber ?? editInvoice.invoiceNumber
              }
              handleFieldChange={handleFieldChange}
            />
          </div>
          <header className="flex flex-col items-center text-center">
            <div className="py-6 flex flex-col items-center">
              <div>
                {fromToData.agency.agencyLogo.smallLogo ? (
                  <img
                    src={fromToData.agency.agencyLogo.smallLogo}
                    alt=""
                    className="w-36"
                  />
                ) : (
                  <div className="mb-1">
                    <ColoredAvatars
                      user={fromToData.agencyCompanyName || { name: 'Unknown' }}
                      tooltip={false}
                      size="extraLarge"
                    />
                  </div>
                )}
              </div>
              <h4 className="font-semibold text-xl">Invoice</h4>

              <TextField
                className="text-xs text-gray-500"
                value={editInvoice.invoiceTitle}
                name="invoiceTitle"
                placeholder="Enter invoice title"
                onChange={(e) =>
                  setEditInvoice((prev) => ({
                    ...prev,
                    invoiceTitle: e.target.value
                  }))
                }
              />
            </div>
          </header>
          <hr />
          <div className="px-8">
            <InvoiceFromTo
              fromToData={fromToData}
              handleDateChange={handleDateChange}
              handleClientFieldChange={handleClientFieldChange}
              handleSelectClient={handleSelectClient}
              handleAgencyFieldChange={handleAgencyFieldChange}
              updateClientCCEmail={updateClientCCEmail}
            />

            <InvoicePricingTable
              invoiceItems={invoiceItems}
              addInvoiceItem={addInvoiceItem}
              addAnotherInvoiceItem={serviceIsRecurring}
              removeInvoiceItem={removeInvoiceItem}
              updateInvoiceItemField={updateInvoiceItemField}
              currency={editInvoice.currency || 'USD'}
              services={services}
              type={editInvoice.type}
              selectServiceFromPopup={selectServiceFromPopup}
            />

            <InvoiceExpensesAndTotal
              taxName={editInvoice.taxName}
              invoiceItemsTotal={invoiceItemsTotal}
              expenses={expenses}
              handleExpensesChange={handleExpensesChange}
              currency={editInvoice.currency || 'USD'}
              handleCurrencyChange={handleCurrencyChange}
              handleFieldChange={handleFieldChange}
            />

            <div className="mt-12">
              <InvoiceOtherInfo
                taxDetails={taxDetails}
                updateTaxDetail={updateTaxDetail}
                notes={editInvoice.notes}
                handleFieldChange={handleFieldChange}
              />
            </div>
            <div className="pb-8"></div>
          </div>
        </div>
      )}
    </div>
  )
})

const InvoiceNumber = ({ customInvoiceNumber, handleFieldChange }) => {
  const [edit, setEdit] = useState(false)

  const toggleEditInvoice = () => {
    setEdit((prev) => !prev)
  }

  return (
    <div className="text-right text-sm">
      {edit ? (
        <div className="inline-flex items-center border border-primary-main rounded px-2 py-1">
          <span>INVOICE #</span>{' '}
          <input
            type="text"
            value={customInvoiceNumber}
            onChange={handleFieldChange}
            className="w-24 text-right"
            onBlur={toggleEditInvoice}
            name="customInvoiceNumber"
            autoFocus
          />
        </div>
      ) : (
        <div className="flex items-center space-x-2 justify-end">
          <div className="border px-2 py-1 rounded">
            <span className="text-gray-700">INVOICE #</span>&nbsp;&nbsp;
            <span className="font-medium">{customInvoiceNumber}</span>
          </div>
          <Pen
            className="px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 hover:bg-gray-200"
            onClick={toggleEditInvoice}
          />
        </div>
      )}
    </div>
  )
}

const getStringAddress = (meData) => {
  let addressObj =
    meData.role === userRoles.USER_AGENCY
      ? meData.profile?.address
      : meData.team?.profile?.address

  const { line1, city, state, country, zipCode } = addressObj

  return `${line1 ? `${line1}, ` : ''}${city ? `${city}, ` : ''}${
    state ? `${state}, ` : ''
  }${country.label ? `${country.label}, ` : ''}${zipCode ? zipCode : ''}`
}

const getBrandObj = (meData) => {
  return meData.role === userRoles.USER_AGENCY
    ? meData.profile?.brand
    : meData.team?.profile?.brand
}

export default EditInvoice
