import { makeStyles, createStyles } from '@material-ui/core'

const AgencyPlansStyles = makeStyles((theme) =>
  createStyles({
    // headerContainer: {
    // width: `calc(100vw - ${theme.spacing(4)}px)`,
    // width: '100vw',
    // height: theme.custom.header.height,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // boxShadow: theme.shadows[1],
    // position: 'fixed',
    // zIndex: theme.zIndex.drawer + 1,
    // background: '#fff',
    // top: 0,
    // left: 0,
    // padding: theme.spacing(0, 2)
    // },
    pageBodyContainer: {
      // marginTop: theme.custom.header.height,
      maxWidth: 1240
      // margin: 'auto'
    },
    planCardsContainer: {
      // padding: '1% 6%',
      // flexWrap: 'wrap',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
      gridGap: '1rem',
      // flexDirection: 'row',
      // width: '100%',
      paddingTop: theme.spacing(0.5)
      // justifyContent: 'space-between'
      // maxWidth: '1700px',
      // margin: 'auto'
    },
    // planCard: {
    // backgroundColor: '#fff',
    // marginTop: theme.spacing(4)
    // paddingBottom: theme.spacing(2),
    // width: '280px'
    // border: '1px solid rgba(198, 198, 198, 0.8)',
    // textAlign: 'center',
    // boxShadow: '1px 2px 7px 4px rgba(128, 128, 128, 0.2)'
    // borderRadius: theme.shape.borderRadius,
    // '&:hover': {
    //   boxShadow: '0 10px 20px 0 rgba(0,43,86,.15)',
    //   borderColor: '#50a3f5'
    // }
    // },
    // planName: {
    //   fontSize: '17px',
    //   fontWeight: '700',
    //   letterSpacing: '0.5px',
    //   textTransform: 'uppercase',
    //   color: '#5d5d5d',
    //   margin: theme.spacing(1, 0)
    // },
    // planPrice: {
    //   fontSize: '28px',
    //   fontWeight: '700',
    //   color: '#2c66c3',
    //   marginBottom: theme.spacing(2)
    // },
    planDescriptionContainer: {
      height: '225px',
      // textAlign: 'left',
      // fontSize: '14px',
      // marginTop: theme.spacing(2),
      // padding: theme.spacing(0, 2),
      '&> li': {
        // fontSize: '13px',
        // padding: '2px 5px',
        // margin: theme.spacing(0),
        // color: '#082a52',
        // letterSpacing: '0.3px',
        // fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
        // '&.notAllowed': {
        //   '&:before': {
        //     content: `''`,
        //     display: 'inline-block',
        //     height: theme.spacing(2),
        //     width: theme.spacing(2),
        //     backgroundImage: `url(https://s3.wasabisys.com/assets.clientvenue.com/other/cross-red.jpg)`,
        //     backgroundSize: 'contain',
        //     marginRight: theme.spacing(1)
        //   }
        // },
        // '&:before': {
        //   content: `''`,
        //   display: 'inline-block',
        //   height: theme.spacing(2),
        //   width: theme.spacing(2),
        //   backgroundImage: `url(https://cdns.useinfluence.co/images/green-check.png)`,
        //   backgroundSize: 'contain',
        //   marginRight: theme.spacing(1)
        // }
      }
    },
    planAdditionalInfoCard: {
      backgroundColor: '#EBF5FF',
      color: '#508ff5',
      maxWidth: '460px',

      '& > ul': {
        lineHeight: 1.95
      }
    },
    planAdditionalInfoCardText: {
      fontSize: '0.9rem',
      marginLeft: '0.65rem',
      color: '#2c66c3'
    },

    dialogPaper: {
      width: '80vw',
      maxWidth: 1200,
      minWidth: 400,
      padding: theme.spacing(4),
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      top: 10,
      right: 10,
      borderRadius: '50%',
      cursor: 'pointer',
      transform: 'rotate(45deg)'
    },
    planBuyButton: {
      marginTop: 32,
      width: '100%',
      backgroundColor: 'rgb(31,41,55)',
      background: 'rgb(31,41,55)',
      fontSize: 14,
      '&:hover': {
        background: 'rgb(17,24,39)'
      }
    },
    smallDialog: {
      minWidth: 400,
      width: 400,
      height: 400,
      minHeight: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    dialogPaper: {
      minWidth: 400
    }
  })
)

export default AgencyPlansStyles
