import produce from 'immer'

export const FILES_ASSETS = {
  FETCHED: 'FILES_ASSETS_FETCHED',
  FOLDERS_FETCHED: 'FILES_ASSETS_FOLDERS_FETCHED',
  FILES_FETCHED: 'FILES_ASSETS_FILES_FETCHED',
  ADD_FOLDER: 'FILES_ASSETS_ADD_FOLDER',
  RENAME_FOLDER: 'FILES_ASSETS_RENAME_FOLDER',
  RENAME_FILE: 'FILES_ASSETS_RENAME_FILE',
  DELETE_FOLDER: 'FILES_ASSETS_DELETE_FOLDER',
  DELETE_FILE: 'FILES_ASSETS_DELETE_FILE',
  ADD_FILE: 'FILES_ASSETS_ADD_FILE',
  UPLOADING_FILE: 'FILES_ASSETS_UPLOADING_FILE',
  UPDATE_PERCENTAGE: 'FILES_ASSETS_UPDATE_PERCENTAGE',
  REMOVE_UPLOADING: 'FILES_ASSETS_REMOVE_UPLOADING',
  UPLOADING_COMPLETE: 'FILES_UPLOADING_COMPLETE',
  MOVE_FILE: 'FILES_ASSETS_MOVE_FILE',
  COPY_FILE: 'FILES_ASSETS_COPY_FILE'
}

const initialState = {
  loading: true,
  isActive: false,
  currentWSAssets: {
    workspace: '',
    data: {} //{"<folder1>": {folders: [...], files: [...]}, "<folder2>": {folders: [...], files: [...]}}
  },
  uploadingFiles: [] //[{name: "<fileName>", percentage: "0-100%", isComplete: false}]
}

const fileAssetReducer = produce((draft, action) => {
  switch (action.type) {
    case FILES_ASSETS.FOLDERS_FETCHED: {
      const { workspace, parent, data } = action.payload
      let parentData = {}

      for (let dir of Object.values(draft.currentWSAssets.data)) {
        const idx = dir.folders.findIndex((item) => item._id === parent)
        if (idx !== -1) {
          parentData = dir.folders[idx]
        }
      }

      if (draft.currentWSAssets.workspace === workspace) {
        if (draft.currentWSAssets.data[parent]) {
          draft.currentWSAssets.data[parent].folders = data
        } else {
          draft.currentWSAssets.data[parent] = {
            folders: data,
            files: [],
            ...parentData
          }
        }
      } else {
        draft.currentWSAssets = {
          workspace,
          data: {
            [parent]: {
              folders: data,
              files: [],
              ...parentData
            }
          }
        }
      }
      break
    }

    case FILES_ASSETS.FILES_FETCHED: {
      const { parent, data } = action.payload
      let parentData = {}

      for (let dir of Object.values(draft.currentWSAssets.data)) {
        const idx = dir.folders.findIndex((item) => item._id === parent)
        if (idx !== -1) {
          parentData = dir.folders[idx]
        }
      }

      if (draft.currentWSAssets.data[parent]) {
        draft.currentWSAssets.data[parent].files = data
      } else {
        draft.currentWSAssets.data[parent] = {
          files: data,
          folders: [],
          ...parentData
        }
      }
      break
    }

    case FILES_ASSETS.ADD_FOLDER: {
      const { parent, data } = action.payload
      draft.currentWSAssets.data[parent].folders.push(data)
      break
    }

    case FILES_ASSETS.ADD_FILE: {
      const { parent, data } = action.payload
      if (draft.currentWSAssets.data[parent].files) {
        draft.currentWSAssets.data[parent].files.push(data)
      } else {
        draft.currentWSAssets.data[parent].files = [data]
      }
      break
    }

    case FILES_ASSETS.RENAME_FOLDER: {
      const { parent, data } = action.payload
      const index = draft.currentWSAssets.data[parent].folders.findIndex(
        (item) => item._id === data._id
      )
      draft.currentWSAssets.data[parent].folders.splice(index, 1, data)
      break
    }

    case FILES_ASSETS.RENAME_FILE: {
      const { data, parent } = action.payload

      const index = draft.currentWSAssets.data[parent].files.findIndex(
        (item) => item._id === data._id
      )

      draft.currentWSAssets.data[parent].files.splice(index, 1, data)
      break
    }

    case FILES_ASSETS.DELETE_FOLDER: {
      const { parent, data } = action.payload
      const index = draft.currentWSAssets.data[parent].folders.findIndex(
        (item) => item._id === data._id
      )
      draft.currentWSAssets.data[parent].folders.splice(index, 1)
      break
    }

    case FILES_ASSETS.DELETE_FILE: {
      const { parent, data } = action.payload
      const index = draft.currentWSAssets.data[parent].files.findIndex(
        (item) => item._id === data._id
      )
      draft.currentWSAssets.data[parent].files.splice(index, 1)
      break
    }

    case FILES_ASSETS.UPLOADING_FILE:
      draft.uploadingFiles.push(action.payload)
      break

    case FILES_ASSETS.UPDATE_PERCENTAGE:
      for (let file of draft.uploadingFiles) {
        if (file.uid === action.payload.uid) {
          file.percentage = action.payload.percentage
          break
        }
      }
      break

    case FILES_ASSETS.REMOVE_UPLOADING:
      const index = draft.uploadingFiles.findIndex(
        (item) => item.uid === action.payload
      )
      if (index !== -1) {
        draft.uploadingFiles.splice(index, 1)
      }
      break

    case FILES_ASSETS.UPLOADING_COMPLETE: {
      const index = draft.uploadingFiles.findIndex(
        (item) => item.uid === action.payload
      )
      if (index !== -1) {
        draft.uploadingFiles[index].isComplete = true
      }
      break
    }

    case FILES_ASSETS.MOVE_FILE: {
      const { newFileAssetsFolder, oldFileAssetsFolder, file } = action.payload
      const oldIndex = draft.currentWSAssets.data[
        oldFileAssetsFolder
      ].files.findIndex((item) => item._id === file._id)
      draft.currentWSAssets.data[oldFileAssetsFolder].files.splice(oldIndex, 1)
      draft.currentWSAssets.data[newFileAssetsFolder].files.push(file)
      break
    }
    case FILES_ASSETS.COPY_FILE: {
      const {
        newFileAssetsFolder,
        // oldFileAssetsFolder,
        file
      } = action.payload
      // const oldIndex = draft.currentWSAssets.data[
      //   oldFileAssetsFolder
      // ].files.findIndex((item) => item._id === file._id)
      // const file = draft.currentWSAssets.data[oldFileAssetsFolder].files.splice(
      //   oldIndex,
      //   1
      // )
      draft.currentWSAssets.data[newFileAssetsFolder].files.push(file)
      break
    }

    default:
      return draft
  }
}, initialState)

export default fileAssetReducer
