import React from 'react'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { Card } from 'global/globalComponents'

const LateCompletedTasks = ({ loading, tasks, openTaskPanel }) => {
  return (
    <Card className="h-full p-4">
      <header className="mb-2 flex items-center">
        <h4 className="text-sm font-semibold text-primary-dark">
          Tasks Completed Late
        </h4>
        <span className="w-8 h-8 rounded-full border inline-flex justify-center items-center ml-2 text-primary-mid-dark">
          {tasks.length}
        </span>
      </header>
      <div>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <div className="text-sm">
            {tasks.length ? (
              // Display only five results
              <ul
                className="text-sm"
                style={{ maxHeight: '280px', overflow: 'scroll' }}
              >
                {tasks.map(item => (
                  <li
                    key={item._id}
                    className={clsx(
                      'p-2 flex text-gray-700 text-xs lg:text-smaller mb-1 justify-between rounded cursor-pointer hover:bg-gray-100'
                    )}
                    onClick={openTaskPanel.bind(this, item, 'lateCompleted')}
                  >
                    <div className="flex-1 overdue-task-title">
                      {item.title}
                    </div>
                    <div className="text-red-400 ml-4 text-xs">
                      {getTimeDifference(
                        new Date(item.completedAt).getTime() -
                          new Date(item.dueDate).getTime()
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-gray-500">No late completed tasks found</div>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}

const getTimeDifference = timeDiff => {
  let time = null
  let diff = Math.round(timeDiff / 3600000)

  if (diff < 24) {
    time = `${diff}h`
  } else {
    diff = Math.round(diff % 24)
    const day = Math.round(timeDiff / (3600 * 1000 * 24)).toFixed(0)
    time = `${day}d ${diff}h`
  }
  return time
}

const LoadingSkeleton = () => {
  return (
    <>
      {[
        Array(4)
          .fill('')
          .map(item => <Skeleton variant="rect" height={20} className="mb-4" />)
      ]}
    </>
  )
}

export default LateCompletedTasks
