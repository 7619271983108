import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

const useCalendarFunctions = () => {
  const dispatch = useDispatch()

  const setTimeInTwentyFourHoursFormat = val => {
    if (val.toLowerCase().includes('am')) {
      const newVal = val.toLowerCase().replace('am', '').trim().split(':')
      if (newVal[0] * 1 === 12) {
        newVal[0] = '00'
      }
      if (newVal[0] * 1 >= 24) {
        newVal[0] = (newVal[0] * 1) % 24
      }
      return newVal.join(':')
    } else if (val.toLowerCase().includes('pm')) {
      const newVal = val.toLowerCase().replace('pm', '').trim().split(':')
      if (newVal[0] * 1 < 12) {
        newVal[0] = 12 + newVal[0] * 1
      }
      if (newVal[0] * 1 >= 24) {
        newVal[0] = (newVal[0] * 1) % 24
      }
      return newVal.join(':')
    } else {
      const newVal = val.split(':')
      return newVal.join(':')
    }
  }

  const setTimeInTwelveHoursFormat = time => {
    const newVal = new Date(time).toTimeString().split(':').slice(0, 2)

    if (newVal[0] * 1 < 12) {
      return newVal.join(':') + 'am'
    } else if (newVal[0] * 1 > 12) {
      newVal[0] = newVal[0] * 1 - 12
      return newVal.join(':') + 'pm'
    } else {
      newVal[0] = '00'
      return newVal.join(':') + 'am'
    }
  }

  const validateTime = val => {
    if (
      !(
        /^(?:[01]?\d|2[0-3])(?::[0-5]\d){1,2}$/.test(val) ||
        /^(0?[1-9]|1[0-2]):([0-5]\d)\s?((?:A|P)\.?M\.?)$/i.test(val)
      )
    ) {
      dispatch(fireErrorToaster(`Please enter valid time in 12 hours formate`))
      return false
    } else {
      return true
    }
  }

  return {
    setTimeInTwentyFourHoursFormat,
    setTimeInTwelveHoursFormat,
    validateTime
  }
}

export default useCalendarFunctions
