import { FormLabel } from 'global/globalComponents'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button
} from '@material-ui/core'
import { previousDay } from 'date-fns'
import {
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
import CustomTableHeader from 'global/globalComponents/CustomTable/CustomTableHeader'
import {
  fetchAllWorkspacesForCurrentUser,
  handleAssignTeammateEvent
} from 'thunks/workspace/actions'

const Step2 = ({
  setStep,
  projectData,
  setProjectData,
  columnHeader = [],
  handleChanges,
  loading,
  projectSpace,
  setProjectSpace,
  delimiter,
  setDelimiter,
  delimiterKeys,
  setDelimiterKeys
}) => {
  const dispatch = useDispatch()
  const userWorkspaces = useSelector((state) => state.userWorkspaces)
  const [projectOptions, setProjectOptions] = useState([])
  // const [space, setSpace] = useState({})
  // const [project, setProject] = useState('')
  // const [delimiter, setDelimiter] = useState({
  //   key: null,
  //   other: false
  // })
  // const [delimiterKeys, setDelimiterKeys] = useState({})

  // useEffect(() => {
  //   let data = {}
  //   if (
  //     columnHeader.includes('assignedAgencyTeam') &&
  //     columnHeader.includes('tags')
  //   ) {
  //     data = {
  //       collaborators: false,
  //       tags: false
  //     }
  //   } else if (columnHeader.includes('assignedAgencyTeam')) {
  //     data = {
  //       collaborators: false
  //     }
  //   } else if (columnHeader.includes('tags')) {
  //     data = {
  //       tags: false
  //     }
  //   } else {
  //     data = {}
  //   }
  //   setDelimiterKeys(data)
  // }, [columnHeader])

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [])

  useEffect(() => {
    setProjectOptions(
      userWorkspaces.data.map((ws) => ({
        label: ws.name,
        value: ws._id,
        ...ws
      }))
    )
  }, [userWorkspaces])

  const handleSelectSpace = (e) => {
    setProjectSpace({
      space: e,
      project: ''
    })
  }

  const handleProjectSelect = (e) => {
    if (e.label) {
      setProjectSpace((prev) => ({
        ...prev,
        project: e
      }))
      setProjectData((prev) => ({
        new: false,
        _id: e._id,
        workspaceBoard: e.defaultBoard?._id
      }))
    } else {
      setProjectSpace((prev) => ({
        ...prev,
        project: e.target.value
      }))
      setProjectData((prev) => ({ new: true, name: e.target.value }))
    }
  }

  console.log(projectData, 'projectDataaaaaaaaaaaaaaa')

  const handleCheckClick = (e) => {
    const { name, checked } = e.target
    setDelimiterKeys((prev) => ({
      ...prev,
      [name]: !delimiterKeys[name]
    }))
  }

  const handleSelectDelimiter = (e) => {
    if (e.value !== 'other') {
      setDelimiter({
        key: e.value,
        other: false
      })
    } else {
      setDelimiter((prev) => ({
        key: '',
        other: true
      }))
    }
  }

  return (
    <>
      <CustomModalHeader heading="Import Detail" className="text-center" />
      <CustomModalBody style={{ height: '450px' }}>
        <form className="my-4 w-2/3 mx-auto">
          <div className="mt-2">
            <div className="">
              <FormLabel required>Where to import</FormLabel>
              <Select
                menuPosition={'fixed'}
                styles={reactSelectCustomStyles}
                options={[
                  { label: 'Existing Project', value: 'existing' },
                  { label: 'New Project', value: 'new' }
                ]}
                onChange={handleSelectSpace}
                value={projectSpace.space}
                maxMenuHeight={150}
                placeholder="Select Space"
                className="border-0 border-none w-full"
              />
            </div>

            <div className="mt-4">
              {projectSpace.space.value === 'existing' && (
                <div className="">
                  <FormLabel required>Select Project</FormLabel>
                  <Select
                    menuPosition={'fixed'}
                    styles={reactSelectCustomStyles}
                    options={projectOptions}
                    onChange={handleProjectSelect}
                    value={projectSpace.project}
                    maxMenuHeight={150}
                    placeholder="Select Project"
                    className="border-0 border-none w-full"
                  />
                </div>
              )}
              {projectSpace.space.value === 'new' && (
                <div>
                  <FormLabel required>Project Name</FormLabel>
                  <TextField
                    onChange={handleProjectSelect}
                    className="w-full"
                    placeholder="Enter Project name"
                    value={projectSpace.project}
                  />
                </div>
              )}
            </div>
          </div>
          {Object.keys(delimiterKeys).length > 0 && (
            <div>
              <div className="mt-4">
                <FormLabel>
                  Do you have any columns separated by delimiters?
                </FormLabel>
                <p className="text-xs">
                  For example, you could have tags separated by commas such as
                  "tag1, tag2, tag3". Please check any columns that have
                  multiple values.
                </p>
                <div className="flex space-x-4 text-xs">
                  {Object.keys(delimiterKeys).map((item) => (
                    <FormControlLabel
                      name={item}
                      onChange={handleCheckClick}
                      checked={delimiterKeys[item]}
                      control={<Checkbox fontSize="small" />}
                      label={
                        <p className="capitalize text-sm text-primary-dark-2">
                          {item}
                        </p>
                      }
                    />
                  ))}
                </div>
              </div>
              {Object.keys(delimiterKeys).filter((el) => delimiterKeys[el])
                .length > 0 && (
                <div className="mt-4">
                  <div>
                    <FormLabel required>
                      What delemiters are you using?
                    </FormLabel>
                    <Select
                      menuPosition={'fixed'}
                      styles={reactSelectCustomStyles}
                      options={[
                        { label: 'Comma(,)', value: ',' },
                        { label: 'Pipe(|)', value: '|' },
                        { label: 'Other', value: 'other' }
                      ]}
                      onChange={handleSelectDelimiter}
                    />
                  </div>
                  {delimiter.other && (
                    <div className="mt-4">
                      <FormLabel required>
                        Which character is your delimiter separating the values?
                      </FormLabel>
                      <TextField
                        helperText={delimiter.key ? '' : 'It is required'}
                        className="w-full"
                        onChange={(e) =>
                          setDelimiter((prev) => ({
                            ...prev,
                            key: e.target.value
                          }))
                        }
                        inputProps={{ maxLength: 1 }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </form>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          loading={loading}
          onClick={handleChanges}
          disabled={projectSpace.project ? false : true}
        >
          {delimiterKeys.collaborators ? 'Next' : 'Complete'}
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={() => {
            setStep(2)
            setDelimiterKeys({})
          }}
        >
          Back
        </Button>
      </CustomModalFooter>
    </>
  )
}

export default Step2
