import { useState, useEffect, useContext } from 'react'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { CircularProgress, TextField } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
// import { times } from 'lodash'
import { Card } from 'global/globalComponents'
// import {
//   addCurrentBoard
// } from 'thunks/workspace/actions'

const ProjectHours = ({ timeTracked, workspace = {}, roleData }) => {
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  const dispatch = useDispatch()
  const { workspaceState } = useContext(SingleWorkspaceContext)
  const workspacePermission = workspaceState.permission.workspacePermission
  const [budgetedInput, setBudgetdInput] = useState(false)
  const [loading, setLoading] = useState(true)
  // const [boardOptions, setBoardOptions] = useState([])
  // const [selectedBoard, setSelectedBoard] = useState({})
  // const allEmbeds = useSelector((state) => state.userWorkspaceEmbed.data)
  const [workspaceData, setWorkspaceData] = useState({
    budgetedHours: '',
    loading: false
  })
  // const dispatch = useDispatch()

  useEffect(() => {
    if (workspace._id) {
      setLoading(false)
      setWorkspaceData(prev => ({
        ...prev,
        budgetedHours: workspace.budgetedHours ?? ''
      }))
    }
  }, [workspace])

  // useEffect(() => {
  //   if (allEmbeds.length) {
  //     const defaultOption = { label: 'All', value: 'all' }

  //     const embedBoards = allEmbeds
  //       .filter((item) => item.type === 'board')
  //       .map((item) => ({ label: item.name, value: item._id }))

  //     setBoardOptions([defaultOption, ...embedBoards])
  //     setSelectedBoard(defaultOption)
  //   }
  // }, [allEmbeds])

  const handleProjectBudgetedHours = () => {
    if (!workspaceData.budgetedHours) {
      return dispatch(fireErrorToaster('Please enter a valid number'))
    }
    setWorkspaceData(prev => ({ ...prev, loading: true }))
    dispatch(
      updateWorkSpace1(
        workspace._id,
        { budgetedHours: +workspaceData.budgetedHours },
        'budgeted hours',
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            setBudgetdInput(false)
            setWorkspaceData(prev => ({ ...prev, loading: false }))
          }
        }
      )
    )
  }

  const handleBudgetedHours = e => {
    if (e.key === 'Enter') {
      handleProjectBudgetedHours()
    }
  }

  return (
    <Card className="h-full">
      <header className="bg-orange-100 px-4 py-4">
        <h4 className="text-sm font-heading font-semibold">Project Hours</h4>
        <p className="text-xs text-gray-500">
          Get insights on your project's time
        </p>
      </header>

      <div
        className="py-4 px-4"
        style={{ maxHeight: '280px', overflow: 'scroll' }}
      >
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <div className="flex items-center mb-2">
              <div className="w-1/2">
                <p className="text-xs font-medium">Tracked hours</p>
                <p className="text-xs  tracking-wide py-1">
                  <span className="">
                    {timeTracked ? getTimeString(timeTracked) : '-'}
                  </span>
                </p>
              </div>
              <div className=" w-1/2">
                <p className="text-xs font-medium">Remaining hours</p>
                <p className="text-xs tracking-wide py-1">
                  {workspace.budgetedHours ? (
                    <>
                      {workspace.budgetedHours * 3600 * 1000 - timeTracked >=
                      0 ? (
                        <span className="">
                          {getTimeString(
                            workspace.budgetedHours * 3600 * 1000 - timeTracked
                          )}
                        </span>
                      ) : (
                        <span className=" text-red-500">
                          -
                          {getTimeString(
                            timeTracked - workspace.budgetedHours * 3600 * 1000
                          )}
                        </span>
                      )}{' '}
                      <span className=" text-xs ">
                        (
                        {remainingTimePercentage(
                          workspace.budgetedHours * 3600 * 1000,
                          timeTracked
                        )}
                        %)
                      </span>
                    </>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
            </div>

            <div className="w-full mb-4">
              <span className="text-xs font-medium ">Budgeted hours</span>
              {budgetedInput ? (
                <div className="flex items-center space-x-1">
                  <TextField
                    type="number"
                    value={workspaceData.budgetedHours}
                    placeholder="Eg:1000"
                    onChange={e =>
                      setWorkspaceData(prev => ({
                        ...prev,
                        budgetedHours: e.target.value
                      }))
                    }
                    autoFocus
                    style={{ width: '100px' }}
                    onKeyDown={handleBudgetedHours}
                  />
                  {!workspaceData.loading ? (
                    <Check
                      className="p-0.5 cursor-pointer text-primary-main hover:bg-gray-50"
                      fontSize="small"
                      onClick={handleProjectBudgetedHours}
                    />
                  ) : (
                    <CircularProgress
                      fontSize="small"
                      style={{ width: '10px', height: '10px' }}
                    />
                  )}
                  <CloseIcon
                    className="p-0.5 cursor-pointer text-red-500 hover:bg-gray-50"
                    fontSize="small"
                    onClick={() => setBudgetdInput(false)}
                  />
                </div>
              ) : workspace.budgetedHours ? (
                <div className="flex items-baseline space-x-4">
                  <p className="p-0.5 text-xs tracking-wide">
                    {workspace.budgetedHours}h
                  </p>
                  {workspacePermission?.update && (
                    <Pen
                      className="px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 hover:bg-gray-100"
                      onClick={() => setBudgetdInput(true)}
                    />
                  )}
                </div>
              ) : (
                <p
                  className="text-primary-main text-xs cursor-pointer"
                  onClick={() => {
                    if (workspacePermission?.update) {
                      setBudgetdInput(true)
                    } else {
                      dispatch(
                        fireErrorToaster(
                          'You do not have permission to perform this action'
                        )
                      )
                    }
                  }}
                >
                  Set budgeted hours
                </p>
              )}
            </div>

            {timeTracked >= 60000 && (
              <div className="w-full">
                <div className="text-xs font-medium my-2">Categories:</div>

                {roleData.map(category => (
                  <>
                    <div className="w-full px-1 flex items-center justify-between tracking-wide">
                      <div className="text-xs font-medium capitalize overdue-task-title my-1">
                        {category.vertical.name}
                      </div>
                      <div className="text-xs font-medium">
                        {getTimeString(
                          category.trackingData.reduce(
                            (acc, obj) => acc + obj.duration,
                            0
                          )
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  )
}

const getTimeString = time => {
  if (time === 0) return '0m'
  time = time / 1000
  const hours = Math.floor(time / (60 * 60))
  const minutes = Math.floor(Math.floor(time % (60 * 60)) / 60)
  if (hours !== 0) {
    return `${hours}h ${formatTime(minutes)}m`
  } else {
    return `${minutes}m`
  }
}

const remainingTimePercentage = (budgeted, tracked) => {
  return ((1 - tracked / budgeted) * 100).toFixed(2)
}

const formatTime = num => {
  return num < 10 ? `0${num}` : num
}

const Row = ({ children, className }) => {
  return (
    <div className={clsx('flex items-center space-x-8 text-xs', className)}>
      {children}
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      {Array(1)
        .fill('')
        .map(item => (
          <Row className="mb-4">
            <Skeleton variant="rect" height={15} style={{ minWidth: '45%' }} />
            <Skeleton variant="rect" height={15} style={{ minWidth: '45%' }} />
          </Row>
        ))}
      <Row className="mb-4">
        <Skeleton variant="rect" height={15} style={{ minWidth: '100%' }} />
      </Row>
    </>
  )
}

export default ProjectHours
