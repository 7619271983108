import { useState, useEffect, useMemo } from 'react'
import MyTimesheets from './MyTimesheets'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSelectedUserTimesheets } from 'thunks/timesheets/action'
import moment from 'moment'
import TimesheetHead from './TimesheetHead'
import { useParams, useHistory } from 'react-router-dom'
import { ColoredAvatars, CustomTooltip } from 'global/globalComponents'
import { userRoles, getRoleBadge } from 'utils'
import { getUserPath } from 'utils/userRoles'
import { ReactComponent as BackArrow } from 'static/svg/long-arrow-left.svg'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import getTimeString from './TimesheetFunctions'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})

function getWeekDays(weekStart) {
  const days = []
  for (let i = 0; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, 'days').toDate().toDateString())
  }
  return days
}

const SelectedUserTimesheet = ({ user }) => {
  const dispatch = useDispatch()
  const { time: selectedDate, userId } = useParams()
  const history = useHistory()
  const me = useSelector(state => state.me)
  const userWorkspaces = useSelector(state => state.userWorkspaces.data)
  const [loading, setLoading] = useState(false)
  const [timesheetData, setTimesheetData] = useState({ user: {}, data: [] })
  const [selectedDays, setSelectedDays] = useState(
    getWeekDays(new Date(selectedDate))
  )
  const [totalTimeTracked, setTotalTimeTracked] = useState()
  const [downloadData, setDownloadData] = useState({ fileName: '', data: [] })
  const teammates = useSelector(state => state.userTeammates.data)
  const [selectedUserData, setSelectedUserData] = useState({})

  const startTime = useMemo(() => {
    return moment(selectedDate).startOf('week').toISOString()
  }, [selectedDays])

  const endTime = useMemo(() => {
    return moment(selectedDate).endOf('week').toISOString()
  }, [selectedDays])

  useEffect(() => {
    if (!loading && timesheetData.user === null) {
      setSelectedUserData({ name: 'Deleted User' })
      setDownloadData(prev => ({
        ...prev,
        fileName: 'UserTimesheet'
      }))
    } else {
      setSelectedUserData(timesheetData.user)
      setDownloadData(prev => ({
        ...prev,
        fileName: `${timesheetData.user?.name}-Timesheets`
      }))
    }
  }, [timesheetData])

  useEffect(() => {
    if (!me.loading) {
      fetchWeeklyData()
    }
  }, [me, selectedDate])

  useEffect(() => {
    setSelectedDays(getWeekDays(moment(selectedDate).startOf('week').toDate()))
  }, [selectedDate])

  const fetchWeeklyData = () => {
    const startDate = moment(selectedDate).startOf('week').toISOString()
    const endDate = moment(selectedDate).endOf('week').toISOString()
    setLoading(true)

    dispatch(
      fetchSelectedUserTimesheets(userId, startDate, endDate, (res, err) => {
        setLoading(false)
        if (!err) {
          // const workspaces = res.map((el) => el._id)
          // const newWs = userWorkspaces
          //   .filter((ws) => !workspaces.includes(ws._id))
          //   .map((ws) => ({
          //     _id: ws._id,
          //     defaultBoard: ws.defaultBoard._id,
          //     name: ws.name,
          //     tasks: []
          //   }))
          // setTimesheetData([...res, ...newWs])
          let totalTracked = []
          res.data.forEach(ws => {
            ws.tasks.forEach(task => {
              totalTracked = [...totalTracked, ...task.data]
            })
          })

          setTotalTimeTracked(
            getTimeString(
              totalTracked
                .filter(
                  time =>
                    time.start >= new Date(startTime).getTime() &&
                    time.start <= new Date(endTime).getTime()
                )
                .reduce((acc, obj) => acc + obj.duration, 0) / 1000
            )
          )
          setTimesheetData(res)
        }
      })
    )
  }

  const handleGoBack = () => {
    history.push(
      `${getUserPath(me.data.role)}/resource-management/timesheets/${moment(
        selectedDate
      ).format('YYYY-MM-DD')}`
    )
  }

  // const addNewTimesheetData = (wsId, taskId, newData) => {
  //   setTimesheetData((prev) => ({
  //     ...prev,
  //     data: prev.data.map((entry) => {
  //       if (entry._id === wsId) {
  //         let newTasksData = [...entry.tasks]
  //         const taskObj = entry.tasks.find((task) => task._id === taskId)
  //         if (taskObj) {
  //           newTasksData = entry.tasks.map((task) => {
  //             if (task._id === taskId) {
  //               return { ...task, data: [...task.data, newData] }
  //             } else {
  //               return task
  //             }
  //           })
  //         } else {
  //           newTasksData = [
  //             ...entry.tasks,
  //             {
  //               _id: newData.task?._id,
  //               title: newData.task?.title,
  //               data: [newData]
  //             }
  //           ]
  //         }

  //         return { ...entry, tasks: newTasksData }
  //       }
  //       return entry
  //     })
  //   }))
  // }

  return (
    <OuterContentWrapper>
      <div>
        <div
          className="inline-block p-1 hover:bg-gray-100 rounded-sm text-primary-main cursor-pointer"
          onClick={handleGoBack}
        >
          <BackArrow
            className="h-6 w-6 text-primary-main"
            style={{ fill: 'blue' }}
          />{' '}
        </div>
        <div className="flex items-center space-x-4 mb-4">
          <ColoredAvatars user={selectedUserData} />
          <div className="flex flex-col">
            <div className="flex items-center leading-3">
              <span className="mr-2 text-base lg:text-lg font-medium text-custom-table-primaryText">
                {selectedUserData?.name}
              </span>
              {selectedUserData?.role && getRoleBadge(selectedUserData?.role)}
            </div>
            <span className="text-gray-500 text-xs">
              {selectedUserData?.email}
            </span>
          </div>
        </div>
      </div>

      <TimesheetHead
        totalTimeTracked={totalTimeTracked}
        totalUsers={1}
        elmFor="selectedUser"
        downloadData={downloadData}
      />

      <MyTimesheets
        loading={loading}
        timesheetData={timesheetData.data}
        setTimesheetData={setTimesheetData}
        userId={timesheetData.user?._id}
        selectedDays={selectedDays}
        startTime={startTime}
        endTime={endTime}
        elmFor="selectedUser"
        setDownloadData={setDownloadData}
        // addNewTimesheetData={addNewTimesheetData}
      />
    </OuterContentWrapper>
  )
}

export default SelectedUserTimesheet
