export const SUBTASKS = {
  LOADING: 'SUBTASKS_LOADING',
  FETCHED: 'ALL_SUBTASKS_FETCHED',
  ADD_ONE_TASK: 'ADD_ONE_SUBTASK',
  DELETE_ONE_TASK: 'DELETE_ONE_SUBTASK',
  ERROR: 'ALL_SUBTASKS_ERROR',
  UPDATE_ONE_TASK: 'UPDATE_ONE_SUBTASK',
  UPLOAD_SUBTASK_ATTACHMENT: 'UPLOAD_SUBTASK_ATTACHMENT'
}

const initialState = {
  error: false,
  loading: false,
  data: []
}

export const subTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBTASKS.LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }

    case SUBTASKS.FETCHED:
      return {
        ...state,
        loading: false,
        data: action.payload
      }

    case SUBTASKS.ADD_ONE_TASK:
      const idx = state.data.findIndex(
        (item) => item._id === action.payload._id
      )

      // checking if subtask already exists bcoz we are getting data from pusher as well
      if (idx === -1) {
        return {
          ...state,
          loading: false,
          data: [...state.data, action.payload]
        }
      } else {
        return state
      }

    case SUBTASKS.UPDATE_ONE_TASK:
      const ind = state.data.findIndex(
        (item) => item._id === action.payload._id
      )
      const newData = [...state.data]
      newData[ind] = action.payload
      return {
        ...state,
        // loading: { ...state.loading, all: true },
        data: [...newData]
      }
    case SUBTASKS.DELETE_ONE_TASK:
      return {
        ...state,
        // loading: false,
        data: state.data.filter((item) => item._id !== action.payload)
      }

    case SUBTASKS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case SUBTASKS.UPLOAD_SUBTASK_ATTACHMENT:
      const index = state.data.findIndex(
        (item) => item._id === action.payload._id
      )
      const updatedData = [...state.data]
      updatedData[index].imageUrl = action.payload.imageUrl
      return {
        ...state,
        data: [...updatedData]
      }

    default:
      return state
  }
}
export default subTaskReducer
