import { useState, useContext, useEffect } from 'react'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { Button } from '@material-ui/core'
import onboardingStyles from './onboardingStyles'
import { OnboardingContext, ONBOARDING } from './onboardingReducer'
import OnboardingLayout from './OnboardingLayout'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import {
  createNewTemplate,
  updateTemplate,
  updateTemplateStatus
} from 'thunks/onboarding/actions'
import { onboardingCategories } from 'utils/onboardingCategory'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'

const AddNewTemplate = ({ edit }) => {
  const { onboardingState, onboardingDispatch } = useContext(OnboardingContext)
  const currentTemplate = onboardingState.currentTemplate
  const [templateNameEdit, setTemplateEdit] = useState(false)
  const [prevTempName, setPrevTempName] = useState(currentTemplate.name)
  const [addStep, setAddStep] = useState(false)
  const [loading, setLoading] = useState(false)
  const [savePublishLoading, setSavePublishLoading] = useState(false)
  const [goBackAlert, setGoBackAlert] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = onboardingStyles()

  // useEffect(() => {
  //   setAddStep(!Boolean(currentTemplate.data?.length))
  // }, [currentTemplate.data])

  useEffect(() => {
    if (currentTemplate.data?.length > 1) {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
      }, 350)
    }
  }, [currentTemplate.data?.length])

  const handleTemplateNameChange = (e) => {
    onboardingDispatch({
      type: ONBOARDING.UPDATE_TEMPLATE_PROP,
      payload: { prop: 'templateName', value: e.target.value }
    })
    // setTemplateName((prev) => ({ ...prev, value: e.target.value }))
  }

  const toggleTemplateNameEdit = () => {
    setTemplateEdit((prev) => !prev)
  }

  const saveTemplateName = () => {
    if (currentTemplate.name === '') {
      onboardingDispatch({
        type: ONBOARDING.UPDATE_TEMPLATE_PROP,
        payload: { prop: 'templateName', value: prevTempName }
      })
    } else {
      setPrevTempName(currentTemplate.name)
    }

    toggleTemplateNameEdit()
  }

  const toggleAddStep = () => {
    setAddStep((prev) => !prev)
  }

  const checkValidation = (steps) => {
    steps = steps.filter((item) => item.name !== 'intakeForm') // filtering out intake form
    let isValid = true

    for (let step of steps) {
      const fieldObject = step.fields[0]

      for (let prop in fieldObject) {
        if (fieldObject[prop].required && !fieldObject[prop].value) {
          isValid = false
          onboardingDispatch({
            type: ONBOARDING.FORM_FIELD_ERROR,
            payload: {
              sectionId: step.id,
              key: prop,
              error: true
            }
          })
        } else if (
          fieldObject[prop].error &&
          Boolean(fieldObject[prop].value)
        ) {
          onboardingDispatch({
            type: ONBOARDING.FORM_FIELD_ERROR,
            payload: {
              sectionId: step.id,
              key: prop,
              error: false
            }
          })
        }
      }
    }

    return isValid
  }

  const handleCreateTemplate = (e) => {
    const templateData = { ...currentTemplate }

    templateData.data = templateData.data.map((item) => {
      const obj = { ...item }

      if (obj.description.startsWith('Edit this description')) {
        obj.description = ''
      }
      return obj
    })

    if (checkValidation(templateData.data)) {
      setLoading(true)
      dispatch(createNewTemplate(templateData, createTemplateCallback))
    } else {
      dispatch(fireErrorToaster('Please fill all the required fields!'))
    }
  }

  const createTemplateCallback = (res, err) => {
    setLoading(false)

    if (!err) {
      dispatch(fireSuccessToaster('Template created successfully!'))
      history.push('/agency/onboarding')
    } else {
      dispatch(fireErrorToaster(err))
    }
  }

  const handleUpdateTemplate = (e) => {
    const templateData = { ...currentTemplate }

    templateData.data = templateData.data.map((item) => {
      const obj = { ...item }
      if (obj.description.startsWith('Edit this description')) {
        obj.description = ''
      }
      return obj
    })
    setLoading(true)

    dispatch(
      updateTemplate(templateData._id, templateData, updateTemplateCallback)
    )
  }

  const updateTemplateCallback = (res, err) => {
    setLoading(false)

    if (!err) {
      dispatch(fireSuccessToaster('Changes saved!'))
      // history.push('/agency/onboarding')
    } else {
      dispatch(fireErrorToaster(err))
    }
  }

  const handleSavePublish = (e) => {
    const templateData = { ...currentTemplate, isActive: true }

    templateData.data = templateData.data.map((item) => {
      const obj = { ...item }

      if (obj.description.startsWith('Edit this description')) {
        obj.description = ''
      }
      return obj
    })

    if (checkValidation(templateData.data)) {
      setSavePublishLoading(true)

      if (edit) {
        dispatch(
          updateTemplate(templateData._id, templateData, publishCallback)
        )
      } else {
        dispatch(createNewTemplate(templateData, publishCallback))
      }
    } else {
      dispatch(fireErrorToaster('Please fill all the required fields!'))
    }
  }

  const publishCallback = (res, err) => {
    if (!err) {
      dispatch(
        updateTemplateStatus(
          currentTemplate._id,
          { isActive: true },
          (res, err) => {
            setSavePublishLoading(false)

            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(fireSuccessToaster('Template published successfully!'))
              history.push('/agency/onboarding')
            }
          }
        )
      )
    }
  }

  const handleGoBack = () => {
    if (!edit && currentTemplate.data.length) {
      setGoBackAlert(true)
    } else {
      history.push('/agency/onboarding')
    }
  }

  const closeGoBackAlert = () => {
    setGoBackAlert(false)
  }

  const handleGoBackAction = () => {
    closeGoBackAlert()
    history.push('/agency/onboarding')
  }

  if (!currentTemplate.data) return <Redirect to="/agency/onboarding" />

  return (
    <div className={classes.newTemplateWrapper}>
      <header className="mb-6">
        {templateNameEdit ? (
          <input
            type="text"
            value={currentTemplate.templateName}
            onChange={handleTemplateNameChange}
            onBlur={saveTemplateName}
            className="text-2xl font-semibold font-heading w-full"
            style={{ background: 'transparent' }}
            autoFocus
          />
        ) : (
          <div>
            <button
              onClick={handleGoBack}
              className="text-primary-main text-sm mb-6"
            >
              <ArrowBackRoundedIcon fontSize="small" />
              &nbsp; Back to templates
            </button>
            {/* <Link
              to="/agency/onboarding"
              style={{ color: '#1600e5', fontSize: '13px' }}
              className="mb-6 inline-block"
            >
            </Link> */}

            <div className="w-full flex justify-between">
              <SectionHeading
                className="capitalize cursor-pointer mb-1"
                onClick={toggleTemplateNameEdit}
              >
                <span className="mr-2">{currentTemplate.templateName}</span>
                <span>
                  <EditOutlinedIcon />
                </span>
              </SectionHeading>

              <LoadingButton
                onClick={handleSavePublish}
                loading={savePublishLoading}
                disabled={!currentTemplate.data.length}
              >
                Save & Publish
              </LoadingButton>
            </div>
          </div>
        )}
      </header>
      {addStep && <StepCategories toggleAddStep={toggleAddStep} />}
      <div className="bg-white border-2 rounded shadow pb-6">
        <OnboardingLayout />
        <div className="flex justify-end gap-4 py-4 px-8">
          {!edit ? (
            <LoadingButton
              disabled={!currentTemplate.data.length}
              loading={loading}
              onClick={handleCreateTemplate}
            >
              Save
            </LoadingButton>
          ) : (
            <LoadingButton
              disabled={!currentTemplate.data.length}
              loading={loading}
              onClick={handleUpdateTemplate}
            >
              Save
            </LoadingButton>
          )}
          <Button
            startIcon={<Add />}
            onClick={toggleAddStep}
            variant="outlined"
          >
            New Step
          </Button>
        </div>
      </div>

      <AlertModal
        open={goBackAlert}
        heading={'Are you sure'}
        warningText={
          'Do you want to leave this page without saving the changes you have made?'
        }
        cancelText="Cancel"
        deleteBtnText="Yes"
        handleDialog={closeGoBackAlert}
        handleDeleteAction={handleGoBackAction}
      />
    </div>
  )
}

const StepCategories = ({ toggleAddStep }) => {
  const classes = onboardingStyles()
  const { onboardingDispatch } = useContext(OnboardingContext)

  const addNewStep = (e) => {
    const stepCategory = e.currentTarget.dataset.category

    if (stepCategory === 'intakeForm')
      onboardingDispatch({ type: ONBOARDING.ADD_INTAKE })
    else if (stepCategory === 'document')
      onboardingDispatch({ type: ONBOARDING.ADD_DOCUMENT })
    else if (stepCategory === 'video')
      onboardingDispatch({ type: ONBOARDING.ADD_VIDEO })
    else if (stepCategory === 'instructions')
      onboardingDispatch({ type: ONBOARDING.ADD_INSTRUCTIONS })

    toggleAddStep()
  }

  const hideOverlay = (e) => {
    if (e.currentTarget === e.target) {
      toggleAddStep()
    }
  }

  return (
    <div
      className={`fixed inset-0 flex justify-center items-center ${classes.categoryWrapper}`}
      onClick={hideOverlay}
    >
      <div>
        <h3 className="text-center py-8 text-l font-medium text-gray-700 text-xl">
          Choose from the types of Steps you want to create
        </h3>

        <div className={classes.stepsCardWrapper}>
          {onboardingCategories.map((step) => (
            <div
              key={step.id}
              className={`cursor-pointer bg-white rounded-xl flex flex-col justify-center items-center ${classes.stepsCard}`}
              onClick={addNewStep}
              data-category={step.category}
            >
              <div>{step.icon}</div>
              <div>{step.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AddNewTemplate
