import { useState, useEffect, useContext } from 'react'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { CircularProgress, TextField } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { Card } from 'global/globalComponents'
import currency from 'static/Currency/Currency'

const ProjectCost = ({ workspace = {}, financialData = {} }) => {
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  const dispatch = useDispatch()
  const { workspaceState } = useContext(SingleWorkspaceContext)
  const workspacePermission = workspaceState.permission.workspacePermission
  const [budgetedInput, setBudgetdInput] = useState(false)
  const [loading, setLoading] = useState(true)
  const [workspaceData, setWorkspaceData] = useState({
    cost: 0,
    costProjection: 0,
    costInputTillNow: 0,
    loading: false
  })

  useEffect(() => {
    if (workspace._id) {
      setWorkspaceData(prev => ({
        ...prev,
        cost: workspace.cost ?? '',
        costProjection: workspace.forecast?.costProjection ?? 0,
        costInputTillNow: workspace.forecast?.costInputTillNow ?? 0
      }))
      setLoading(false)
    }
  }, [workspace])

  const handleProjectcost = () => {
    if (!workspaceData.cost) {
      return dispatch(fireErrorToaster('Please enter a valid number'))
    }
    setWorkspaceData(prev => ({ ...prev, loading: true }))
    dispatch(
      updateWorkSpace1(
        workspace._id,
        { cost: +workspaceData.cost },
        'project cost',
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            setWorkspaceData(prev => ({ ...prev, loading: false }))
          } else {
            setBudgetdInput(false)
            setWorkspaceData(prev => ({ ...prev, loading: false }))
          }
        }
      )
    )
  }

  const handlecost = e => {
    if (e.key === 'Enter') {
      handleProjectcost()
    }
  }

  return (
    <Card className="h-full">
      <header className="bg-orange-100 px-4 py-4">
        <h4 className="text-sm font-heading font-semibold">
          Project Costs (
          {currency[workspace.currency?.toUpperCase() ?? 'USD'].symbol_native})
        </h4>
        <p className="text-xs text-gray-500">
          Get insights on your project's costs
        </p>
      </header>

      <div className="py-4 px-4">
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <Row>
              <div className="font-medium flex-1 py-3">Budget</div>
              <div className="flex-1">
                {budgetedInput ? (
                  <div className="flex items-center space-x-1">
                    <TextField
                      type="number"
                      value={workspaceData.cost}
                      placeholder="Eg:1000"
                      onChange={e =>
                        setWorkspaceData(prev => ({
                          ...prev,
                          cost: e.target.value
                        }))
                      }
                      className="text-xs font-medium w-full"
                      autoFocus
                      style={{ width: '100px' }}
                      onKeyDown={handlecost}
                    />
                    {!workspaceData.loading ? (
                      <Check
                        className="p-0.5 cursor-pointer text-primary-main hover:bg-gray-50"
                        fontSize="small"
                        onClick={handleProjectcost}
                      />
                    ) : (
                      <CircularProgress
                        fontSize="small"
                        style={{ width: '10px', height: '10px' }}
                      />
                    )}
                    <CloseIcon
                      className="p-0.5 cursor-pointer text-red-500 hover:bg-gray-50"
                      fontSize="small"
                      onClick={() => setBudgetdInput(false)}
                    />
                  </div>
                ) : workspace.cost ? (
                  <div className="flex items-baseline space-x-4">
                    {
                      currency[workspace.currency?.toUpperCase() ?? 'USD']
                        .symbol_native
                    }
                    &nbsp;
                    <p className="text-xs ">
                      {workspace.cost}
                      {/* {
                        currency[workspace.currency?.toUpperCase() ?? 'USD']
                          .symbol_native
                      } */}
                    </p>
                    {workspacePermission?.update && (
                      <Pen
                        className="px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 hover:bg-gray-100"
                        onClick={() => setBudgetdInput(true)}
                      />
                    )}
                  </div>
                ) : (
                  <p
                    className="text-primary-main text-xs cursor-pointer"
                    onClick={() => {
                      if (workspacePermission?.update) {
                        setBudgetdInput(true)
                      } else {
                        dispatch(
                          fireErrorToaster(
                            'You do not have permission to perform this action'
                          )
                        )
                      }
                    }}
                  >
                    Set budget
                  </p>
                )}
              </div>
            </Row>
            <Row className="py-3">
              <div className="font-medium flex-1">Cost Projection</div>
              <div className=" flex-1">
                {
                  currency[workspace.currency?.toUpperCase() ?? 'USD']
                    .symbol_native
                }
                &nbsp;
                {workspaceData.costProjection}
                {/* {financialData.forCompletedTask?.completedTaskWithTimeTrack !==
                0
                  ? Math.round(
                      financialData.averageSalaryOfOneUserPerHour *
                        (financialData.averageTrackedHour /
                          financialData.forCompletedTask
                            ?.completedTaskWithTimeTrack) *
                        financialData.totalTask
                    )
                  : 0} */}
              </div>
            </Row>
            <Row className="py-3">
              <div className="font-medium flex-1">Cost input till now</div>
              <div className=" flex-1">
                {
                  currency[workspace.currency?.toUpperCase() ?? 'USD']
                    .symbol_native
                }
                &nbsp;
                {workspaceData.costInputTillNow}
                {/* {Math.round(
                  financialData.actual?.averageSalaryOfOneUserPerHour *
                    financialData.actual?.totalTimeTrackedInHour
                )} */}
              </div>
            </Row>
            {/* <Row className="py-3">
              <div className="font-medium flex-1">Input time forecast</div>
              <div className=" flex-1">{deliveredTime(financialData)}</div>
            </Row> */}
          </>
        )}
      </div>
    </Card>
  )
}

const deliveredTime = data => {
  if (data.forCompletedTask.completedTaskWithTimeTrack && data.activeTeammate) {
    const totalTimePerUser =
      ((data.averageTrackedHour /
        data.forCompletedTask.completedTaskWithTimeTrack) *
        data.totalTask) /
      data.activeTeammate
    const userCapacity =
      data.agencyData.capacity /
      Object.keys(data.agencyData.workingDays).filter(
        day => data.agencyData.workingDays[day]
      ).length
    return getTimeString(totalTimePerUser / userCapacity)
  } else {
    return 0
  }
}

const getTimeString = time => {
  if (time === 0) return '0m'
  time = time * 3600
  const hours = Math.floor(time / (60 * 60))
  const minutes = Math.floor(Math.floor(time % (60 * 60)) / 60)
  if (hours !== 0) {
    return `${hours}h ${formatTime(minutes)}m`
  } else {
    return `${minutes}m`
  }
}

const formatTime = num => {
  return num < 10 ? `0${num}` : num
}

const Row = ({ children, className }) => {
  return (
    <div className={clsx('flex items-center space-x-8 text-xs', className)}>
      {children}
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      {Array(1)
        .fill('')
        .map(item => (
          <Row className="mb-4">
            <Skeleton variant="rect" height={15} style={{ minWidth: '45%' }} />
            <Skeleton variant="rect" height={15} style={{ minWidth: '45%' }} />
          </Row>
        ))}
      <Row className="mb-4">
        <Skeleton variant="rect" height={15} style={{ minWidth: '100%' }} />
      </Row>
    </>
  )
}

export default ProjectCost
