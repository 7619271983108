import axios from 'axios'
import { errorMessages } from 'utils'
import { STANDUPS } from './reducer'
import { TASKS } from '../task/reducers'

export const transformStandupsData = (data, meId) => {
  const transformedData = { ...data }

  transformedData.standups = transformedData.standups.map((item) => {
    const obj = { ...item }

    obj.timeTracked = obj.timeTracked.length
      ? obj.timeTracked
          .filter((durationObj) => durationObj.user === meId)
          .reduce((prevVal, currVal) => {
            return prevVal + currVal.duration
          }, 0)
      : 0
    return obj
  })

  return transformedData
}

/**
 * Create today's standups
 * @param {Function} callback Callback function
 */
export const updateStandupById = (standupId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/standups/${standupId}`,
        method: 'PUT',
        data
      })

      dispatch({ type: STANDUPS.ADD_STANDUPS, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create today's standups
 * @param {Function} callback Callback function
 */
export const createStandups = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/standups',
        method: 'POST',
        data
      })

      dispatch({ type: STANDUPS.ADD_STANDUPS, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete Standup
 * @param {String} standupId
 * @param {String} taskId
 * @param {Function} callback Callback function
 */
export const deleteStandup = (standupId, taskId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/standups/task/${standupId}/${taskId}`,
        method: 'DELETE'
      })

      dispatch({ type: STANDUPS.UPDATE, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch current user's today's standups
 * @param {Function} callback Callback function
 */
export const fetchTodaysStandups = (callback) => {
  return async (dispatch, getState) => {
    const {
      me: { data: meData }
    } = getState()

    try {
      const res = await axios({
        url: '/standups/current',
        method: 'GET'
      })

      const transformedData = transformStandupsData(res.data, meData._id)

      dispatch({ type: STANDUPS.FETCHED, payload: transformedData })
      if (callback) callback(transformedData)
    } catch (err) {
      if (err.response.status === 404) {
        dispatch({ type: STANDUPS.FETCHED, payload: { standups: [] } })
        return
      }

      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch current user's today's standups
 * @param {String} task id
 * @param {String} Standup id
 * @param {Function} callback Callback function
 */
export const markStandupAsComplete = (taskId, standupId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/standups/complete/${taskId}/${standupId}`,
        method: 'PUT',
        data
      })

      dispatch({ type: STANDUPS.UPDATE, payload: res.data })
      dispatch({
        type: TASKS.MARK_AS_COMPLETE,
        payload: { taskId, isComplete: data.markAsComplete }
      })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
