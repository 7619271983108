import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgencyPlan } from 'thunks/agencyPlan/actions'
import clsx from 'clsx'
import { toggleUpgradeModal } from 'thunks/generlAppState/actions'
import agencyPlansOfferings from './planOfferings'
import { LoadingButton } from 'global/globalComponents'
import {
  agencyUpgradePlan,
  fetchCardDetails
} from 'thunks/agencyPayment/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import UpdateCardModal from 'components/Settings/Subscription/UpdateCardModal'
import { errorMessages } from 'utils'
import AgencyPlansStyles from './AgencyPlansStyles'

const planDurations = ['month', 'year']

const AgencPlansModal = () => {
  const customStyles = AgencyPlansStyles()
  const dispatch = useDispatch()
  const agencyPlans = useSelector((state) => state.agencyPlans)
  const userActivePlan = useSelector((state) => state.userActivePlanInfo)
  const modalOpen = useSelector(
    (state) => state.generalAppState.upgradePlanModal
  )

  const [activeDuration, setActiveDuration] = useState(0)
  const [loadingPlanBtn, setLoadingPlanBtn] = useState(false)
  const [upgradeSuccessModal, setUpgradeSuccessModal] = useState(false)
  const [addCardModal, setAddCardMoal] = useState(false)

  useEffect(() => {
    if (agencyPlans.data.length === 0) dispatch(fetchAgencyPlan())
  }, [])

  const toggleDuration = (newDuration) => {
    setActiveDuration(newDuration)
  }

  const handleCloseDialog = () => {
    dispatch(toggleUpgradeModal())
  }

  const handleBuyPlan = async (plan) => {
    setLoadingPlanBtn(plan.id)
    const cardData = await fetchCardDetails()
    if (!cardData.err) {
      // alert('card avilable')
      try {
        await agencyUpgradePlan({
          id: plan.id,
          nickname: plan.nickname,
          amount: plan.amount
        })
        setLoadingPlanBtn(false)
        dispatch(fireSuccessToaster('Plan Upgraded Successfully.'))
        setUpgradeSuccessModal(true)
        setTimeout(() => {
          window.open(window.location.origin, '_self')
        }, 3000)
      } catch (err) {
        setLoadingPlanBtn(false)
        dispatch(
          fireErrorToaster(
            err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
          )
        )
      }
    } else {
      setAddCardMoal(true)
    }
  }

  const addCardCallback = (res, err) => {
    if (err) {
    } else {
      setAddCardMoal(false)
    }
  }

  return (
    <Dialog
      maxWidth="md"
      // classes={{ paper: 'max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8' }}
      classes={{ paper: customStyles.dialogPaper }}
      open={modalOpen}
    >
      <span
        role="button"
        onClick={handleCloseDialog}
        className={customStyles.closeButton}
      >
        +
      </span>
      <h2 className="text-center font-medium text-lg">Select New Plan</h2>
      <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
        {['Monthly billing', 'Yearly billing'].map((i, index) => (
          <CustomTab
            onClick={() => toggleDuration(index)}
            active={index === activeDuration}
            key={i}
          >
            {i}
          </CustomTab>
        ))}
      </div>

      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
        {agencyPlans.data
          .filter((i) => i.interval === planDurations[activeDuration])
          .map((plan) => (
            <div
              key={plan.nickname}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {plan.nickname}
                </h2>
                <p className="mt-4 text-sm text-gray-500">{plan.description}</p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    ${plan.amount * 0.01}
                  </span>{' '}
                  <span className="text-base font-medium text-gray-500">
                    /{activeDuration === 0 ? 'mo' : 'yr'}
                  </span>
                </p>
                <LoadingButton
                  loading={loadingPlanBtn === plan.id}
                  disabled={plan.id === userActivePlan.data?.payment_plan?.id}
                  onClick={() => handleBuyPlan(plan)}
                  className={customStyles.planBuyButton}
                >
                  {plan.id === userActivePlan.data?.payment_plan?.id
                    ? 'Active Plan'
                    : `Buy ${plan.nickname?.split(' ')[0]}`}
                </LoadingButton>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-4">
                  {agencyPlansOfferings[
                    plan.nickname?.split(' ')[0].toLowerCase()
                  ]
                    ? Object.entries(
                        agencyPlansOfferings[
                          plan.nickname?.split(' ')[0].toLowerCase()
                        ]
                      ).map(
                        ([key, value], index) =>
                          value && (
                            <li key={key} className="flex space-x-3">
                              <Check
                                className="flex-shrink-0 h-5 w-5 text-green-500"
                                aria-hidden="true"
                              />
                              <span className="text-sm text-gray-500">
                                {key}
                              </span>
                            </li>
                          )
                      )
                    : null}
                </ul>
              </div>
            </div>
          ))}
      </div>
      <PlanUpgradedSucessModal open={upgradeSuccessModal} />
      <UpdateCardModal
        open={addCardModal}
        callback={addCardCallback}
        onClose={() => setAddCardMoal(false)}
      />
    </Dialog>
  )
}

const PlanUpgradedSucessModal = (props) => {
  const { open } = false
  const customStyles = AgencyPlansStyles()

  return (
    <Dialog classes={{ paper: customStyles.smallDialog }} open={open}>
      <DialogContent>
        <p className="">
          Your plan has been upgraded successfully. <br />
          Please wait we're updating your data.
        </p>
      </DialogContent>
    </Dialog>
  )
}

export default AgencPlansModal

const CustomTab = (props) => {
  const { active = false, children, ...restprops } = props
  return (
    <button
      {...restprops}
      type="button"
      className={clsx(
        'ml-0.5 select-none relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none active:ring-2 active:ring-indigo-500 active:z-10 sm:w-auto sm:px-8',
        { 'text-gray-900 bg-white': active }
      )}
    >
      {children}
    </button>
  )
}
