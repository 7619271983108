import React, { useEffect, useMemo, useState } from 'react'
// import { Tabs, Tab } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
// import {
//   getPrioritizedTask,
//   getPrioritizedTaskWithDates
// } from 'thunks/task/actions'
// import Pill from 'global/globalComponents/Pill/Pill'
// import { AvatarGroup } from '@material-ui/lab'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
// import moment from 'moment'
// import { getUserPath } from 'utils/userRoles'
// import { useHistory } from 'react-router-dom'
import { triggerOnScrollToBottom } from 'utils'
import {
  AlertModal
  // CustomFlag,
  // LoadingButton,
  // OutlinedButton,
  // PopupMenu
} from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
// import pillVariantObj from 'utils/pillVariantObj'
// import widgetStyles from '../widgetStyles'
// import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
// import taskStatus from 'utils/taskStatus'
// import { ColoredAvatarGroup } from 'global/globalComponents'
// import DatePicker from 'react-datepicker'
// import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
// import { ReactComponent as CheckCircleFilled } from 'static/svg/check-circle-filled.svg'
// import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import clsx from 'clsx'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import { getUserPath } from 'utils/userRoles'
import { useCallback } from 'react'
import { deleteTaskByID } from 'thunks/task/actions'
import WidgetTableRow from './WidgetTableRow'
// import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import SelectColumnsPopup from './SelectColumnPopup'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
// import { Collaborators } from 'components/Workspace/Panel/Collaborator'
// import { ReactComponent as Add } from 'static/svg/plus.svg'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'

const infiniteScroll = triggerOnScrollToBottom()

const WidgetTable = ({
  isLoading,
  tasks,
  tasksCount,
  loadMoreTasks,
  removeTaskById,
  updateSingleTask,
  openEditTaskPanel
}) => {
  const [alertModal, setAlertModal] = useState({
    type: '',
    loading: false,
    data: {}
  })
  const dispatch = useDispatch()
  const {
    fetchedFirstTime,
    loading: projectsLoading,
    data: allProjects
  } = useSelector(state => state.userWorkspaces)
  const [hiddenCols, setHiddenCols] = useState({})
  const [selectColAnchorEl, setSelectColAnchorEl] = useState(null)
  const columns = useMemo(() => {
    return [
      { label: 'Task', value: 'task', position: 'left' },
      { label: 'Project', value: 'project', position: 'left' },
      { label: 'Assignee', value: 'assignee', position: 'left' },
      { label: 'Priority', value: 'priority', position: 'center' },
      { label: 'Status', value: 'status', position: 'left' },
      { label: 'Start date', value: 'startDate', position: 'left' },
      { label: 'Due date', value: 'dueDate', position: 'left' },
      { label: 'Mark complete', value: 'markComplete', position: 'center' }
    ]
  }, [hiddenCols])
  const [saveHiddenColsCount, setSaveHiddenCols] = useState(0)

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useEffect(() => {
    const savedHiddenCols = localStorage.getItem('priorityTasksHiddenCols')
    if (savedHiddenCols) setHiddenCols(JSON.parse(savedHiddenCols))
  }, [])

  useEffect(() => {
    if (saveHiddenColsCount === 0) return
    localStorage.setItem('priorityTasksHiddenCols', JSON.stringify(hiddenCols))
  }, [saveHiddenColsCount])
  // const classes = widgetStyles()
  // const history = useHistory()
  // const priorityTasks = useSelector((state) => state.userTasks.priorityTasks)
  // const meData = useSelector((state) => state.me.data)
  // const priorityTaskLoading = useSelector(
  //   (state) => state.userTasks.loading.priority
  // )
  // const [loadingComponentData, setLoadingComponentData] = useState(true)
  // const [olderTasks, setOlderTasks] = useState({ fetch: false, loading: false })
  // const [filteredTasks, setFilteredTasks] = useState([])
  // const tabsData = [
  //   { label: 'Open', value: 0 },
  //   { label: 'Overdue', value: 1 }
  // ]

  // const [selectedTab, setSelectedTab] = useState(0)

  // useEffect(() => {
  //   const endDate = new Date().setHours(23, 59, 0)
  //   const startDate = new Date(endDate - 30 * 24 * 3600 * 1000 + 60000)
  //   if (
  //     [
  //       userRoles.USER_AGENCY,
  //       userRoles.AGENCY_ADMIN
  //       // userRoles.USER_CLIENT
  //     ].includes(meData.role)
  //   ) {
  //     dispatch(
  //       getPrioritizedTaskWithDates(
  //         new Date(endDate).toISOString(),
  //         new Date(startDate).toISOString()
  //       )
  //     )
  //   } else {
  //     dispatch(getPrioritizedTask())
  //   }
  // }, [])

  // // useEffect(() => {
  // //   if (!userWorkspaces.fetchedFirstTime) {
  // //     dispatch(fetchAllWorkspacesForCurrentUser())
  // //   }
  // // }, [userWorkspaces.fetchedFirstTime])

  // const fetchOlderTasks = () => {
  //   setOlderTasks((prev) => ({ ...prev, loading: true }))
  //   dispatch(
  //     getPrioritizedTask((res, err) => {
  //       if (err) {
  //         dispatch(fireErrorToaster(res))
  //         setOlderTasks({ fetch: false, loading: false })
  //       } else {
  //         setOlderTasks({ fetch: true, loading: false })
  //       }
  //     })
  //   )
  // }

  // useEffect(() => {
  //   if (!priorityTaskLoading) {
  //     setLoadingComponentData(false)
  //   }
  // }, [priorityTaskLoading])

  // useEffect(() => {
  //   setFilteredTasks(priorityTasks.filter((task) => !task.markAsComplete))
  // }, [priorityTasks])

  // useEffect(() => {
  //   if (selectedTab === null) return

  //   switch (selectedTab) {
  //     case 1:
  //       // overdue
  //       setFilteredTasks(
  //         priorityTasks.filter(
  //           (item) => item.dueDate && new Date(item.dueDate) < new Date()
  //         )
  //       )
  //       break

  //     default:
  //       setFilteredTasks(priorityTasks.filter((task) => !task.markAsComplete))
  //   }
  // }, [selectedTab])

  // const taskPanelHandler = (task) => {
  //   history.push(
  //     `${getUserPath(meData.role)}/workspace/${task.workSpace._id}/board/${
  //       task.workspaceBoard
  //     }/task/${task._id}?view=board`
  //   )
  // }

  // const handleWorkspacePageRedirect = (workspace) => {
  //   history.push(
  //     `${getUserPath(meData.role)}/workspace/${workspace._id}/overview`
  //   )
  // }

  // const handleTabChange = (e, newVal) => {
  //   setSelectedTab(newVal)
  //   // setSelectedOption(null)
  // }

  // const handleDeleteWidgetClick = () => {
  //   handleRemoveWidget(widgetId)
  // }

  const handleLoadTasks = e => {
    infiniteScroll(e, {
      cb: activateInfiniteScroll => {
        if (tasksCount > tasks.length) {
          loadMoreTasks()
          setTimeout(() => {
            activateInfiniteScroll()
          }, 500)
        }
      }
    })
  }

  const toggleAlertModal = useCallback(
    (type, data) => {
      if (alertModal.type !== '') {
        setAlertModal(prev => ({ ...prev, type: '' }))
      } else {
        setAlertModal({ type, data, loading: false })
      }
    },
    [alertModal]
  )

  const handleDeleteTask = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))

    dispatch(
      deleteTaskByID(alertModal.data._id, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
          setAlertModal(prev => ({ ...prev, loading: false }))
        } else {
          removeTaskById(alertModal.data._id)
          dispatch(fireSuccessToaster('Task deleted successfully'))
          setAlertModal(prev => ({ ...prev, type: '', loading: false }))
        }
      })
    )
  }

  const toggleSelectColPopup = useCallback(e => {
    setSelectColAnchorEl(prev => (Boolean(prev) ? null : e.currentTarget))
  }, [])

  const toggleColumnsVisibility = (key, val) => {
    setHiddenCols(prev => ({ ...prev, [key]: !val }))
    setSaveHiddenCols(prev => prev + 1)
  }

  const filteredColumns = columns.filter(item => !hiddenCols[item.value])

  return (
    <>
      <TableContainer
        containerProps={{
          style: {
            overflowY: 'auto',
            height: 'calc(100% - 55px)',
            background: '#fff'
          },
          onScroll: handleLoadTasks
        }}
        mb={6}
      >
        <Thead className="sticky top-0 z-10">
          {filteredColumns.map(item => (
            <Th
              className={clsx(
                'h-14 whitespace-nowrap',
                item.position === 'left'
                  ? 'text-left'
                  : item.position === 'right'
                  ? 'text-right'
                  : 'text-center'
              )}
              key={item.label}
            >
              {item.label}
            </Th>
          ))}
          {/* <Th>Task</Th>
          <Th className="text-center h-14">Project</Th>
          <Th className="text-center h-14">Assignee</Th>
          <Th className="text-center h-14">Priority</Th>
          <Th className="text-center h-14">Status</Th>
          <Th className="text-center whitespace-nowrap h-14">Start Date</Th>
          <Th className="text-center whitespace-nowrap h-14">Due Date</Th>
          <Th className="text-center whitespace-nowrap h-14">Mark Complete</Th> */}
          <Td>
            <span className="inline-block">
              <button
                className={clsx(
                  'w-7 h-7 rounded-full text-sm flex justify-center items-center border',
                  Boolean(selectColAnchorEl) &&
                    'text-white bg-primary-main border-primary-main'
                )}
                tabIndex={0}
                onClick={toggleSelectColPopup}
              >
                <AddIcon className="w-5 h-5" />
              </button>
            </span>
          </Td>
        </Thead>
        <Tbody>
          {isLoading || projectsLoading ? (
            <TableDataLoading cols={filteredColumns.length} />
          ) : tasks.length === 0 ? (
            <Tr>
              <Td colSpan={filteredColumns.length}>
                There are no priority tasks.
              </Td>
            </Tr>
          ) : (
            <>
              {tasks.map(task => (
                <WidgetTableRow
                  key={task._id}
                  task={task}
                  openDeleteAlertModal={toggleAlertModal}
                  allProjects={allProjects}
                  updateSingleTask={updateSingleTask}
                  openEditTaskPanel={openEditTaskPanel}
                  hiddenCols={hiddenCols}
                  // handleWorkspacePageRedirect={handleWorkspacePageRedirect}
                  // taskPanelHandler={taskPanelHandler}
                />
              ))}
              {/* {[
                userRoles.USER_AGENCY,
                userRoles.AGENCY_ADMIN
                // userRoles.USER_CLIENT
              ].includes(meData.role) &&
                !olderTasks.fetch && (
                  <Tr>
                    <Td colSpan="9">
                      <div className="flex justify-end space-x-4">
                        <LoadingButton
                          variant="outlined"
                          size="small"
                          onClick={fetchOlderTasks}
                          loading={olderTasks.loading}
                        >
                          Older than 30 days
                        </LoadingButton>
                      </div>
                    </Td>
                  </Tr>
                )} */}
            </>
          )}
        </Tbody>
      </TableContainer>
      <AlertModal
        open={alertModal.type === 'deleteTask'}
        warningText={`Do you want to delete ${alertModal.data.title}`}
        handleDialog={toggleAlertModal}
        handleDeleteAction={handleDeleteTask}
        heading="Are you sure"
        loading={alertModal.loading}
      />
      <SelectColumnsPopup
        anchorEl={selectColAnchorEl}
        handleClose={toggleSelectColPopup}
        columns={columns}
        toggleColumnsVisibility={toggleColumnsVisibility}
        hiddenCols={hiddenCols}
      />
    </>
  )
}

export default React.memo(WidgetTable)
