import { useState, useEffect } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { CustomTabs } from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocation,
  useRouteMatch,
  Switch,
  Route,
  useHistory
} from 'react-router-dom'
import { userRoles } from 'utils'
import { ReactComponent as ServiceIcon } from 'static/svg/flash.svg'
// import { ReactComponent as PeopleIcon } from 'static/svg/people.svg'
import { ReactComponent as InvoicesIcon } from 'static/svg/invoice.svg'
import { getUserPath } from 'utils/userRoles'
import Connect from 'components/Invoices/StripeConnect/Connect'
import { Services } from 'components'
import InvoiceView from 'components/Invoices/Invoices/InvoiceView'
import EditInvoicePage from 'components/Invoices/StripeConnect/EditInvoicePage'
import { fetchAllServices } from 'thunks/service/actions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { fetchCustomInvoices } from 'thunks/invoices/actions'

const PaymentsNavigation = () => {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const invoices = useSelector(state => state.clientInvoices)
  // const stripeInfo = useSelector((state) => state.userStripeInfo)
  // const access = useSelector((state) => state.permission.data?.access)

  const userServices = useSelector(state => state.userServices)
  const isProjectsFetched = useSelector(
    state => state.userWorkspaces.fetchedFirstTime
  )
  const permission = useSelector(state => state.permission.data)
  const [selectedTab, setSelectedTab] = useState(pathname)

  const tabsData = [
    {
      label: 'Invoices',
      value: path + '/client-billing/all',
      icon: <InvoicesIcon />,
      allowedRoles: role =>
        [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(role),
      access: permission.access?.billings
    },
    {
      label: 'Services',
      value: path + '/services/all',
      icon: <ServiceIcon />,
      allowedRoles: role =>
        [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(role),
      access: permission.access?.services
    }
  ]

  useEffect(() => {
    if (
      permission.access &&
      !(
        permission.access.billings?.create ||
        permission.access.billings?.update ||
        permission.access.billings?.view ||
        permission.access.billings?.delete
      ) &&
      pathname.includes('/payments/client-billing')
    ) {
      history.push(`${getUserPath(meData.role)}/dashboard`)
    }
  }, [permission.access?.billings])

  useEffect(() => {
    if (
      permission.access &&
      !(
        permission.access.services?.create ||
        permission.access.services?.update ||
        permission.access.services?.view ||
        permission.access.services?.delete
      ) &&
      pathname.includes('/payments/services')
    ) {
      history.push(`${getUserPath(meData.role)}/dashboard`)
    }
  }, [permission.access?.services])

  useEffect(() => {
    if (!userServices.fetched) {
      dispatch(fetchAllServices())
    }
  }, [userServices.fetched])

  useEffect(() => {
    if (
      !(
        permission.access?.billings?.create ||
        permission.access?.billings?.update ||
        permission.access?.billings?.view ||
        permission.access?.billings?.delete
      )
    )
      return
    if (
      !invoices.fetched &&
      [
        userRoles.USER_AGENCY,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_ADMIN
      ].includes(meData.role)
    ) {
      let id =
        meData.role === userRoles.USER_AGENCY ? meData?._id : meData?.team?._id
      dispatch(fetchCustomInvoices(id))
    }
  }, [permission, invoices.fetched])

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }

  const handleViewInvoice = invoice => {
    history.push(`${path}/client-billing/i/${invoice._id}`)
  }

  const handleEditInvoice = invoice => {
    history.push(`${path}/client-billing/i/${invoice._id}/edit`)
  }

  return (
    <>
      <Switch>
        <Route exact path={path + '/client-billing/i/:invoiceId'}>
          <InvoiceView
            allInvoices={invoices.data}
            handleEditInvoice={handleEditInvoice}
            billingPermission={permission.access?.billings}
            // openMarkAsPaidAlert={openMarkAsPaidAlert}
          />
        </Route>
        <Route path={path + '/client-billing/i/:invoiceId/edit'}>
          <EditInvoicePage
            handleViewInvoice={handleViewInvoice}
            invoices={invoices.data}
          />
        </Route>
        <Route>
          <OuterContentWrapper>
            <SectionHeading className="mb-3 lg:mb-4">Payments</SectionHeading>
            <div className="">
              <Tabs value={selectedTab} onChange={handleTabChange}>
                {tabsData
                  .filter(
                    tab =>
                      tab.access?.create ||
                      tab.access?.update ||
                      tab.access?.view ||
                      tab.access?.delete
                  )
                  .map((item, index) => (
                    <Tab
                      label={
                        <div className="flex items-center gap-1">
                          <span>{item.icon}</span> <span>{item.label}</span>
                        </div>
                      }
                      key={index}
                      value={item.value}
                    />
                  ))}
              </Tabs>
              {/* <hr /> */}
            </div>
            <Switch>
              <Route
                path={`${getUserPath(meData?.role)}/payments/client-billing`}
                component={Connect}
              />
              <Route
                path={`${getUserPath(meData?.role)}/payments/services/all`}
                component={Services}
              />
            </Switch>
          </OuterContentWrapper>
        </Route>
      </Switch>
    </>
  )
}

export default PaymentsNavigation
