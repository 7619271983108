import axios from 'axios'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { errorMessages, getAuthToken } from 'utils'
import { SERVICES } from './reducers'
import { DASHBOARD } from 'thunks/userDashboard/reducer'
import { createAccountLogs } from 'thunks/accountLogs/action'

/**
 * Create a new service of the agency user
 * @param {Object} data service object data
 * @param {Function} callback Callback function
 */
export const createService = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/service',
        data: {
          ...data,
          amount: data.amount * 100
        }
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `<span>created a  service <strong>${res?.data?.name}</strong></span>`,
          category: 'service',
          service: res.data._id,
          method: 'post'
        })
      )
      dispatch({ type: SERVICES.ADD_ONE_SERVICE, payload: res.data })
      // dispatch({
      //   type: DASHBOARD.UPDATE,
      //   payload: { count: 1, key: 'totalServices' }
      // })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SERVICES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Fetch all service of a user
 * @param {Function} callback Callback function
 */
export const fetchAllServices = (callback) => {
  return async (dispatch) => {
    dispatch({ type: SERVICES.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/service/user',
        headers: {
          Authorization: getAuthToken()
        }
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SERVICES.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SERVICES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch all services by domain
 * @param {Function} callback callback function
 */
export const fetchAllServicesByDomain = (callback) => {
  return async (dispatch) => {
    dispatch({ type: SERVICES.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/service/domain',
        headers: {
          Authorization: getAuthToken()
        }
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SERVICES.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SERVICES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
      dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    }
  }
}

/**
 * fetch services by id
 * @param {Function} callback callback function
 */
export const fetchServiceById = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/service/public/${id}`
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * delete a service by service id
 * @param {String} serviceId Service identifier
 * @param {Function} callback Callback function
 */
export const deleteServiceByID = (serviceId, callback) => {
  return async (dispatch) => {
    dispatch({ type: SERVICES.LOADING })
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/service/${serviceId}`
      })
      dispatch({ type: SERVICES.DELETE_ONE_SERVICE, payload: serviceId })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `deleted a service <strong>${res?.data?.name}</strong>`,
          category: 'service',
          service: res.data._id,
          method: 'delete'
        })
      )
      dispatch(fireSuccessToaster('Service Deleted'))
    } catch (err) {
      dispatch({
        type: SERVICES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
      dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * it will update one service.
 * @param {String} id serviceid
 * @param {Object} data description,offerings,displayOnCatalog,free trial,amount,url,isrecurring
 * @param {Function} callback  callback function
 */

export const updateService = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/service/${id}`,
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `updated a service <strong>${res?.data?.name}</strong>`,
          category: 'service',
          service: res.data._id,
          method: 'put'
        })
      )
      dispatch({ type: SERVICES.UPDATE_ONE_SERVICE, payload: res.data })
      dispatch(fireSuccessToaster('Service Updated Successfully'))
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true,
          id
        )
      dispatch({
        type: SERVICES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
      dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    }
  }
}

/**
 * it will delete one service.
 * @param {String} id serviceid
 * @param {Function} callback  callback function
 */

export const deleteService = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/service/${id}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SERVICES.DELETE_ONE_SERVICE, payload: res.data._id })
      // dispatch({
      //   type: DASHBOARD.UPDATE,
      //   payload: { count: -1, key: 'totalServices' }
      // })
      dispatch(
        createAccountLogs({
          description: `deleted a service <strong>${res?.data?.name}</strong>`,
          category: 'service',
          service: res.data._id,
          method: 'delete'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SERVICES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * This functionality has been removed (needed in stripe services)
 * it will update one service.
 * @param {String} id service planId
 * @param {Object} data isActive
 * @param {Function} callback  callback function
 */

export const updateArchiveService = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/service/archive/${id}`,
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SERVICES.UPDATE_ONE_SERVICE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SERVICES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * stripe payment api.
 * @param {Object} data isActive
 * @param {Function} callback  callback function
 */

export const stripePayment = (data, token, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/clientpayment/checkout/stripepayment`,
        headers: {
          Authorization: token
        },
        data: data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch stripe account id of agency api.
 * @param {Function} callback  callback function
 */

export const fetchAgencyStripeAccountId = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/connectaccount/fetchaccount`
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create Service Category
 * @param {Object} data title, description, image(optional)
 * @param {Function} callback
 */
export const createServiceCategory = async (data, callback) => {
  try {
    const res = await axios({
      url: '/category',
      method: 'POST',
      data
    })

    callback(res.data)
  } catch (err) {
    callback(err.response.data.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * fetch Service categories
 * @param {Function} callback
 */
export const fetchServiceCategories = async (callback) => {
  try {
    const res = await axios({
      url: '/category/category?type=service',
      method: 'GET'
    })

    callback(res.data)
  } catch (err) {
    callback(err.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Update Service category
 * @param {String} Id category id
 * @param {Object} data title, description, image(optional)
 * @param {Function} callback
 */
export const updateServiceCategory = async (id, data, callback) => {
  try {
    const res = await axios({
      url: `/category/${id}`,
      method: 'PUT',
      data
    })

    callback(res.data)
  } catch (err) {
    callback(err.response.data.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Delete Service category
 * @param {String} Id category id
 * @param {Function} callback
 */
export const deleteServiceCategory = async (id, callback) => {
  try {
    const res = await axios({
      url: `/category/${id}`,
      method: 'DELETE'
    })

    callback(res.data)
  } catch (err) {
    callback(err.response.data.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}
