import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import StatusCategory from './StatusCategory'

const AllStatusesCategories = ({
  categories,
  updateCategories,
  openPopper,
  updateCategoryStatus
}) => {
  const handleStatusDragEnd = ({ destination, draggableId, source }) => {
    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return

    const allCategories = [...categories]
    const sourceCategoryIndex = allCategories.findIndex(
      item => item.id === source.droppableId
    )
    const sourceStatuses = [...allCategories[sourceCategoryIndex].statuses]
    const removedFromSource = sourceStatuses.splice(source.index, 1)

    if (source.droppableId === destination.droppableId) {
      sourceStatuses.splice(destination.index, 0, removedFromSource[0])
      allCategories[sourceCategoryIndex] = {
        ...allCategories[sourceCategoryIndex],
        statuses: sourceStatuses
      }
    } else {
      const destinationCategoryIndex = allCategories.findIndex(
        item => item.id === destination.droppableId
      )
      const destinationStatuses = [
        ...allCategories[destinationCategoryIndex].statuses
      ]

      destinationStatuses.splice(destination.index, 0, {
        ...removedFromSource[0],
        category: destination.droppableId
      })

      allCategories[destinationCategoryIndex] = {
        ...allCategories[destinationCategoryIndex],
        statuses: destinationStatuses
      }

      allCategories[sourceCategoryIndex] = {
        ...allCategories[sourceCategoryIndex],
        statuses: sourceStatuses
      }
    }

    updateCategories(allCategories)
  }

  return (
    <DragDropContext onDragEnd={handleStatusDragEnd}>
      {categories.map(item => (
        <StatusCategory
          key={item.id}
          {...item}
          openPopper={openPopper}
          updateCategoryStatus={updateCategoryStatus}
        />
      ))}
    </DragDropContext>
  )
}

export default AllStatusesCategories
