import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  showIntegratedHeader,
  showNormalHeader
} from 'thunks/generlAppState/actions'
import {
  addOpenedWorkspace,
  fetchAllWorkspacesForCurrentUser,
  addCurrentBoard,
  fetchProjectFinancialData
} from 'thunks/workspace/actions'
import { fetchProjectStatusPosts } from 'thunks/StatusPost/action'
import { getUserPath } from 'utils/userRoles'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchEmbed } from 'thunks/embed/action'
import { fetchWorkspaceTagsById } from 'thunks/tags/actions'
import { fetchLogsByWorkspaceId } from 'thunks/logs/action'
import clsx from 'clsx'
import WorkspaceRoutes from './WorkspaceRoutes'
import {
  SingleWorkspaceContext,
  reducer,
  initialState,
  WORKSPACE
} from './singleWorkspaceContext'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import SingleWorkspaceActions from './SingleWorkspaceActions'

const SingleWorkspace = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { workspaceId } = useParams()
  const meData = useSelector(state => state.me.data)
  const { data: userWorkspaces, fetchedFirstTime } = useSelector(
    state => state.userWorkspaces
  )
  const workspacePermission = useSelector(
    state => state.workspacePermission.data
  )
  const embedData = useSelector(state => state.userWorkspaceEmbed)
  const { pathname, search } = useLocation()
  const view = useMemo(() => new URLSearchParams(search), [search]).get('view')
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const [workspaceState, workspaceDispatch] = useReducer(reducer, initialState)
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const { fetched: workspacePermissionFetched } = useSelector(
    state => state.workspacePermission
  )
  const teammates = useSelector(state => state.userTeammates)
  const [boardId, setBoardId] = useState('')

  useEffect(() => {
    if (workspaceId) {
      let permissionObj

      for (let item of workspacePermission) {
        if (typeof item[workspaceId] === 'object') {
          permissionObj = item[workspaceId].access
        }
      }

      if (permissionObj) {
        workspaceDispatch({
          type: WORKSPACE.SET_PERMISSION,
          payload: {
            workspacePermission: permissionObj.workspace,
            workspaceViewPermission: permissionObj.workspaceView,
            taskPermission: permissionObj.task,
            subtaskPermission: permissionObj.subTask
          }
        })
      }
    }
  }, [workspacePermission, workspaceId])

  useEffect(() => {
    workspaceDispatch({ type: WORKSPACE.PAGE_LOADING, payload: true })
  }, [workspaceState.defaultView])

  useEffect(() => {
    if (workspaceId) {
      dispatch(fetchEmbed(workspaceId))
    }
  }, [workspaceId])

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser(throwUserOutside))
    }
    // dispatch(getTaskByWorkspaceId(boardId))  // IN multiple board feature fetch all task by boardId
    // dispatch(getTaskByWorkspaceId(workspaceId))
    dispatch(showIntegratedHeader())
    return () => {
      dispatch(showNormalHeader())
    }
  }, [workspaceId])

  useEffect(() => {
    if (!workspacePermissionFetched) {
      dispatch(fetchWorkspacePermissions())
    }
  }, [workspacePermissionFetched])

  useEffect(() => {
    if (!teammates.fetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [teammates.fetched])

  useEffect(() => {
    const ws = userWorkspaces.find(item => item._id === workspaceId)
    if (ws) {
      dispatch(addOpenedWorkspace(ws))
    }
  }, [userWorkspaces, workspaceId])

  useEffect(() => {
    dispatch(fetchWorkspaceTagsById(workspaceId))
    dispatch(fetchLogsByWorkspaceId(workspaceId, 1, 20))
    fetchProjectFinancialData(workspaceId)
  }, [workspaceId])

  useEffect(() => {
    if (!embedData.loading && currentWorkspace._id) {
      if (embedData.data[0].workspace !== currentWorkspace._id) return

      const board =
        embedData.data.length === 1
          ? embedData.data[0]
          : embedData.data.find(item => item.pinView)
      if (board) {
        dispatch(addCurrentBoard(board))
      }
    }
  }, [embedData, currentWorkspace._id])

  useEffect(() => {
    if (boardId && currentBoard._id !== boardId) {
      const board = embedData.data.find(item => item._id === boardId)
      if (board) {
        dispatch(addCurrentBoard(board))
      }
    }
  }, [boardId])

  useEffect(() => {
    if (currentBoard && currentBoard.groupedByDetail) {
      const group = currentBoard.groupedByDetail.find(
        item => item.user === meData._id
      )

      if (group) {
        handleSetGroups(group.groupedByDetail)
      } else {
        handleSetGroups('status')
      }
    } else {
      handleSetGroups('status')
    }
  }, [currentBoard.groupedByDetail])

  useEffect(() => {
    dispatch(fetchProjectStatusPosts(workspaceId))
  }, [])

  // ===== Cleanup before unmount ====
  useEffect(() => {
    return () => {
      dispatch(addOpenedWorkspace({}))
      dispatch(addCurrentBoard({}))
    }
  }, [])

  const handleSetGroups = group => {
    workspaceDispatch({
      type: WORKSPACE.UPDATE_DEFAULT_GROUP_BY,
      payload: group
    })

    workspaceDispatch({
      type: WORKSPACE.UPDATE_GROUP_BY,
      payload: group
    })
  }

  const throwUserOutside = (res, err) => {
    if (!err) {
      const ws = res.find(i => i._id === workspaceId)
      if (!ws) {
        history.push(`${getUserPath(meData?.role)}/workspace/all`)
      }
    }
  }

  return (
    <SingleWorkspaceContext.Provider
      value={{ workspaceState, workspaceDispatch }}
    >
      <div
        className={clsx('relative h-full px-10 border-t border-transparent')}
      >
        {Boolean(view) && (
          <SingleWorkspaceActions pathname={pathname} search={search} />
        )}

        <WorkspaceRoutes workspaceId={workspaceId} setBoardId={setBoardId} />
      </div>
    </SingleWorkspaceContext.Provider>
  )
}

export default SingleWorkspace
