import React, { useEffect, useState } from 'react'
import { Box, Typography, TextField, Button, Paper } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import {
  resetPassword,
  resetPasswordTokenValidity
} from 'thunks/passwordAndDomain/actions'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useHistory } from 'react-router-dom'
import passwordResetStyles from './passwordResetStyles'

const ResetPassword = (props) => {
  const history = useHistory()
  const classes = passwordResetStyles()
  const dispatch = useDispatch()
  const [inputError, setInputError] = useState({
    field_1: '',
    field_2: ''
  })
  const [loading, setLoading] = useState(false)
  const [tokenValid, settokenValid] = useState(true)
  const [inputData, setInputData] = useState({
    newPassword: '',
    confirmNewPassword: ''
  })

  useEffect(() => {
    tokenValidity()
  }, [])

  const tokenValidity = () => {
    resetPasswordTokenValidity(props.match.params.token, tokenValidityCallback)
  }

  const tokenValidityCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      if (res.status !== 200) {
        settokenValid(false)
      }
    }
  }

  const inputChangeHandler = ({ target: { name, value } }) => {
    // console.log(name, value)
    setInputData((oldData) => ({ ...oldData, [name]: value }))
    if (
      name === 'newPassword' &&
      value.match(/[a-z]/g) &&
      value.match(/[A-Z]/g) &&
      value.match(/[0-9]/g) &&
      value.match(/[^a-zA-Z\d]/g) &&
      value.length >= 8 &&
      value.length <= 20
    ) {
      setInputError((oldState) => ({
        ...oldState,
        field_1: ''
      }))
    }
    if (inputError.field_2 && value === inputData.newPassword) {
      setInputError((oldData) => ({
        ...oldData,
        field_2: ''
      }))
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (inputData.newPassword !== inputData.confirmNewPassword) {
      setInputError((oldState) => ({
        ...oldState,
        field_2: 'Password is not matching'
      }))
    } else if (!inputError.field_1 && !inputError.field_2) {
      setLoading(true)
      resetPassword(inputData, props.match.params.token, resetPasswordCallback)
    }
  }

  const resetPasswordCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Password Updated Successfully!'))
      history.push('/login')
    }
  }

  const handleOnBlur = ({ target: { name, value } }) => {
    // console.log(name, value)
    setInputError((oldState) => ({
      ...oldState,
      field_1:
        value.length < 6
          ? 'Minimum password length should be 6 characters long'
          : ''
    }))
  }

  const redirectHandler = () => {
    history.push('/forgot-password')
  }

  return (
    <Box className={classes.resetPasswordContainer}>
      {tokenValid ? (
        <Box>
          <Typography variant="h4" className={classes.heading}>
            Reset Password
          </Typography>
          <Paper className={classes.formCard}>
            <Box
              onSubmit={handleFormSubmit}
              className={classes.formContainer}
              component="form"
            >
              <TextField
                onChange={inputChangeHandler}
                // helperText={'Minimun Password length is 6' || inputError.newPassword}
                helperText={inputError.field_1}
                error={inputError.field_1}
                name="newPassword"
                label="New Password"
                inputProps={{ onBlur: handleOnBlur }}
              />
              <TextField
                type="password"
                helperText={inputError.field_2}
                error={inputError.field_2}
                label="Confirm Password"
                name="confirmNewPassword"
                onChange={inputChangeHandler}
              />
              <LoadingButton
                loading={loading}
                className={classes.btnReset}
                type="submit"
              >
                Reset Password
              </LoadingButton>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Button
          style={{ marginTop: '50px', fontSize: '16px' }}
          onClick={redirectHandler}
        >
          Reset Password Link has expired. <br /> Generate new link
        </Button>
      )}
    </Box>
  )
}

export default ResetPassword
