import produce from 'immer'

const initialState = {
  loading: false,
  error: null,
  data: {},
  fetched: false
}

export const STRIPE_ACCOUNT = {
  LOADING: 'LOADING_USER_STRIPE_INFO',
  ERROR: 'USER_STRIPE_INFO_LOADING_ERROR',
  FETCHED: 'USER_STRIPE_INFO_FETCHED',
  DELETE: 'USER_STRIPE_INFO_DELETE'
}

const stripeInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case STRIPE_ACCOUNT.LOADING:
      return { ...state, loading: true, error: null }

    case STRIPE_ACCOUNT.ERROR:
      return { ...state, loading: false, error: action.payload }

    case STRIPE_ACCOUNT.FETCHED:
      return {
        ...state,
        loading: false,
        fetched: true,
        error: null,
        data: action.payload
      }

    case STRIPE_ACCOUNT.DELETE:
      return {
        ...state,
        data: {}
      }

    default:
      return state
  }
}

export default stripeInfoReducer

// const stripeInfoReducer = produce((draft, action) => {
//   switch (action.type) {
//     case STRIPE_ACCOUNT.LOADING:
//       draft.error = null
//       draft.loading = true
//       break

//     case STRIPE_ACCOUNT.ERROR:
//       draft.loading = false
//       draft.error = action.payload
//       break

//     case STRIPE_ACCOUNT.FETCHED:
//       draft.loading = false
//       draft.data = action.payload
//       break

//     default:
//       return draft
//   }
// }, initialState)
