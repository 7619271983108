import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// import { fetchLogo } from 'sagas/auth'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { getParsedJwt, userRoles } from 'utils'
import { userSignup } from 'thunks/signup'
import axios from 'axios'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import clsx from 'clsx'
// import { Clientvenue_logo } from 'static/Images/index'
import useTitle from 'customHooks/useTitle'
import {
  LoadingButton,
  RoundedTextInput,
  PatternScreen
} from 'global/globalComponents'
import PoweredBy from 'components/PoweredBy/PoweredBy'
import clientVenueAssets from 'static/clientVenueAssets'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import GeneralFormModal from './generalformModal'
import { ReactComponent as AngleLeft } from 'static/svg/angle-left.svg'
import { checkDomainAvailability } from 'thunks/passwordAndDomain/actions'
// import globalCustomStyles from 'global/stlyeClasses/GlobalCustomStyles'

const SignupCopy = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()

  const inviteToken = props.match?.params?.inviteToken ?? false
  const [open, setOpen] = useState(false)
  const [response, setResponse] = useState({})
  const [domain, setDomain] = useState('')
  const [activeTab, setActiveTab] = useState(true)
  const [isWhitelabelActive, setWhitelabelActive] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [userDomainStatus, setUserDomainStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })
  // const [waitingModalStaus, setWaitingModalStatus] = useState({
  //   open: false,
  //   time: null
  // })
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })
  const [loading, setLoading] = useState(false)
  const [isValid, setIsvalid] = useState(true)
  const [formData, setFormData] = useState({
    // firstName: '',
    // lastName: '',
    name: '',
    email: '',
    password: '',
    brandName: '',
    website: '',
    role: userRoles.USER_AGENCY,
    phoneNumber: ''
  })
  useTitle('clientvenue', clientVenueAssets.favicon)
  useEffect(() => {
    if (inviteToken) {
      let { iat, ...userInviteData } = getParsedJwt(inviteToken)
      setFormData((oldData) => ({
        ...oldData,
        ...userInviteData
      }))
      setDomain(userInviteData.agencyDomain)
    }
  }, [inviteToken])

  const modalCloseHandler = () => {
    setOpen(false)
  }

  const userSignupHandler = (e) => {
    e.preventDefault()

    if (!userDomainStatus.available) {
      return dispatch(fireErrorToaster('Please enter the valid domain'))
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    if (formData.password !== confirmPassword) {
      setLoading(false)
      return dispatch(
        fireErrorToaster('Password does not match with confirm password')
      )
    }
    setLoading(true)
    userSignup(
      {
        ...formData,
        source: window.location.href,
        agencyDomain: domain + '.clientvenue.com'
      },
      signupCallback
    )
  }
  const handleDomain = (e) => {
    const value = e.target.value.trim().toLowerCase()
    if (/^([\w\d]-?)*$/.test(value)) {
      setDomain(value)
    }
  }

  const signupCallback = (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      // setWaitingModalStatus({ open: true, time: 15 })
      setOpen(true)
      setResponse({
        domain: res?.agencyDomain,
        path: res?.path,
        token: res?.login?.token,
        id: res?.profile?._id,
        brandName: res?.profile?.brand?.brandName
      })
      dispatch(fireSuccessToaster('Signup successful'))
    }
  }

  const formInputHandler = (e) => {
    const { name, value } = e.target
    setFormData((oldState) => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const phoneNumberIputHandler = (valid, phone, country) => {
    setFormData((oldData) => ({
      ...oldData,
      phoneNumber: phone.length > 0 ? '+' + country.dialCode + ' ' + phone : ''
    }))
  }

  const handleSubmitDomain = async () => {
    setFormData((prev) => ({ ...prev, brandName: '' }))
    if (domain.length > 0) {
      setUserDomainStatus((props) => ({
        ...props,
        loading: true,
        message: 'Checking',
        available: undefined
      }))

      checkDomainAvailability(domain, checkDomainCallback)
    } else {
      setUserDomainStatus((props) => ({
        ...props,
        message: undefined,
        available: undefined
      }))
    }
  }

  const checkDomainCallback = (res, err) => {
    if (err) {
      setUserDomainStatus({
        loading: false,
        message: undefined,
        available: undefined
      })

      dispatch(fireErrorToaster(res))
      return
    }

    if (res.domainExist) {
      setUserDomainStatus({
        loading: false,
        message: 'Not Available',
        available: false
      })
      dispatch(fireErrorToaster(`${domain} domain already exist.`))
    } else {
      setFormData((prev) => ({ ...prev, brandName: domain }))
      setUserDomainStatus({
        loading: false,
        message: 'Available',
        available: true
      })
    }
  }

  const fetchAgencyInfo = async () => {
    try {
      const res = await getAgencyInfoByDomain()
      setWhitelabelActive(res?.data?.isWhiteLabelActive)
      setAgencyInfo(res?.data?.brand?.smallLogo ? res?.data.brand : agencyInfo)
    } catch (err) {
      setAgencyInfo(agencyInfo)
    }
  }

  useEffect(() => {
    fetchAgencyInfo()
  }, [])

  useEffect(() => {
    const reg = /\S+@\S+\.\S+/
    if (
      reg.test(formData.email) &&
      formData.name.length !== 0 &&
      formData.phoneNumber.length !== 0
    ) {
      setIsvalid(false)
    } else {
      setIsvalid(true)
    }
  }, [formData])

  useEffect(() => {
    if (
      !params &&
      window.location.href !== 'https://app.clientvenue.com/signup'
    )
      window.open('https://app.clientvenue.com/login', '_self')
  }, [window.location])

  // useEffect(() => {
  // }, [location])

  return (
    <>
      {window.location.href === 'https://app.clientvenue.com/signup' ? (
        <PatternScreen>
          <header className="mb-8">
            {/* <Link>
              <img
                src={clientVenueAssets.largeLogo}
                alt="logo"
                target="_self"
                style={{
                  height: '3rem',
                  margin: 'auto'
                }}
              />
            </Link> */}

            <h1 className="text-4xl font-bold">
              Create Your <span className="text-primary-main">Account</span>
            </h1>
          </header>

          <div>
            <form onSubmit={userSignupHandler}>
              {activeTab ? (
                <div>
                  <div className="mb-4">
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <RoundedTextInput
                      required
                      onChange={formInputHandler}
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                    />
                  </div>

                  <div className="mb-4">
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <RoundedTextInput
                      required
                      onChange={formInputHandler}
                      name="email"
                      placeholder="Email"
                      type="email"
                      id="email"
                      value={formData.email}
                      disabled={Boolean(inviteToken)}
                    />
                  </div>

                  <div className="mb-4">
                    <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                    <IntlTelInput
                      onPhoneNumberChange={phoneNumberIputHandler}
                      name="phone"
                      value={formData.phoneNumber.split(' ')[1] || ''}
                      inputClassName={clsx(
                        classes.intelInput,
                        'rounded-full w-full py-3 rounded-full text-sm'
                      )}
                      containerClassName="intl-tel-input w-full"
                    />
                  </div>
                  <div className="mt-8">
                    <Button
                      onClick={() => setActiveTab(false)}
                      className="w-full"
                      disabled={isValid}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="mb-4">
                    <FormLabel htmlFor="agencyDomain">Agency Domain</FormLabel>

                    <div className="flex">
                      <RoundedTextInput
                        id="agencyDomain"
                        required
                        value={domain}
                        inputProps={{ onBlur: handleSubmitDomain }}
                        onChange={handleDomain}
                        disabled={Boolean(inviteToken)}
                      />
                      {!Boolean(inviteToken) && (
                        <p className="self-center text-gray-500">
                          .clientvenue.com
                        </p>
                      )}
                    </div>

                    {userDomainStatus.message && (
                      <div className="flex items-center ml-2 mt-1">
                        {userDomainStatus.loading && (
                          <CircularProgress size={15} />
                        )}
                        <p
                          className={clsx('font-medium text-xs ml-2', {
                            'text-red-500': !userDomainStatus.available,
                            'text-green-500': userDomainStatus.available,
                            'text-blue-600': userDomainStatus.loading
                          })}
                        >
                          {userDomainStatus.message}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <FormLabel htmlFor="companyName">Company Name</FormLabel>
                    <RoundedTextInput
                      required
                      id="companyName"
                      onChange={formInputHandler}
                      name="brandName"
                      placeholder="Company Name "
                      value={formData.brandName}
                    />
                  </div>

                  <div className="mb-4">
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <RoundedTextInput
                      required
                      onChange={formInputHandler}
                      name="password"
                      placeholder="Password (minimum 6 character)"
                      type="password"
                      value={formData.password}
                      id="password"
                      onBlur={validPasswordHandler}
                    />

                    {formData.password && !passwordValid && (
                      <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                        Password should be a combination of an
                        uppercase/lowercase letter, a number, a symbol and
                        length between 8 to 20.
                      </p>
                    )}
                  </div>

                  <div className="mb-4">
                    <FormLabel htmlFor="confirmPassword">
                      Confirm Password
                    </FormLabel>
                    <RoundedTextInput
                      required
                      id="confirmPassword"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      name="confirm password"
                      placeholder="Confirm Password"
                      type="password"
                      value={confirmPassword}
                    />
                  </div>

                  <div className="">
                    <LoadingButton
                      loading={loading}
                      type="submit"
                      disabled={!userDomainStatus.available}
                      className="w-full"
                    >
                      {location.pathname.includes('free')
                        ? 'Create Free Account'
                        : 'Create Account'}
                    </LoadingButton>
                  </div>

                  <div className="mt-2 flex justify-between items-center">
                    <button
                      onClick={() => setActiveTab(true)}
                      className="text-xs font-medium text-primary-main flex items-center mb-1"
                    >
                      <span style={{ marginLeft: -3 }}>
                        <AngleLeft />
                      </span>
                      <span>Previous step</span>
                    </button>

                    <p className="text-xs font-medium">
                      Have an Account?{' '}
                      <Link to="/login" className="text-primary-main font-bold">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </>
              )}
            </form>
            <GeneralFormModal
              open={open}
              handleClose={modalCloseHandler}
              data={response}
            />
          </div>

          {/* <div>{!isWhitelabelActive && <PoweredBy marginTop="2rem" />}</div> */}
        </PatternScreen>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  )
}

const FormLabel = ({ children, ...rest }) => {
  return (
    <label
      className="inline-block text-sm font-medium tracking-wide mb-2"
      {...rest}
    >
      {children}
    </label>
  )
}

const useStyles = makeStyles({
  intelInput: {
    backgroundColor: 'transparent',
    border: '1px solid hsl(0,0%,80%)'
  }
})

export default SignupCopy
