import { Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import { AlertModal, ColoredAvatars } from 'global/globalComponents'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { errorMessages, getRoleBadge, userRoles } from 'utils'
import EditTeammatePanelNew from 'components/Teammates/EditTeammatePanelNew'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTeammate, updateClientTeammate } from 'thunks/teammate/actions'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { IconButton } from '@material-ui/core'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const Members = ({ clientData, clientInfo, setClientInfo }) => {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState(0)
  const meData = useSelector((state) => state.me.data)
  const [editClientTeammate, setEditClientTeammate] = useState({
    open: false,
    data: {}
  })
  const [deleteClientTeammate, setDeleteClientTeammate] = useState({
    open: false,
    data: {},
    loading: false
  })

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleEditClientTeammate = (data) => {
    setEditClientTeammate((prev) => ({ ...prev, open: true, data: data }))
  }

  const handleDeleteClientTeammate = () => {}

  const closeEditPanel = () => {
    setEditClientTeammate({ open: false, data: {} })
  }

  const handleUpdateTeammate = (id, data, panelCallback) => {
    dispatch(
      updateClientTeammate(id, data, (res, err) => {
        panelCallback()
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          setClientInfo((prev) => {
            const updatedData = [...prev.data.clientTeammates]
            const ind = updatedData.findIndex((item) => item._id === res._id)
            updatedData[ind] = res
            return {
              ...prev,
              data: {
                ...prev.data,
                clientTeammates: updatedData
              }
            }
          })

          dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
        }
      })
    )
  }

  const deleteTeammateCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setDeleteClientTeammate((prev) => ({
        ...prev,
        loading: false
      }))
    } else {
      setClientInfo((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          clientTeammates: prev.data.clientTeammates.filter(
            (item) => item._id !== res._id
          )
        }
      }))
      setDeleteClientTeammate({
        open: false,
        data: {},
        loading: false
      })
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }

  const handleDeleteTeammate = (teammate) => {
    if (deleteClientTeammate.open) {
      setDeleteClientTeammate({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteClientTeammate({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleTeammateData = () => {
    setDeleteClientTeammate((prev) => ({ ...prev, loading: true }))
    dispatch(
      deleteTeammate(deleteClientTeammate.data._id, deleteTeammateCallback)
    )
  }

  return (
    <>
      <Wrapper className="my-8">
        {clientInfo.totalAgencyAssignee?.length > 0 ||
        clientInfo.clientTeammates?.length > 0 ? (
          <>
            <div className="mb-4">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                {['client team', 'your team'].map((item, index) => (
                  <Tab label={item} key={index} />
                ))}
              </Tabs>
              <div style={{ border: '1px solid #ECEBF5' }}></div>
            </div>
            <div
              className="pt-4"
              style={{ maxHeight: '250px', overflow: 'scroll' }}
            >
              {selectedTab === 0 ? (
                <div>
                  {clientInfo.clientTeammates?.length > 0 ? (
                    clientInfo.clientTeammates.map((item) => (
                      <MemberCard
                        className="mb-6"
                        key={item._id}
                        user={item}
                        role={item.role}
                        subText={item.role}
                        handleEdit={handleEditClientTeammate}
                        handleDelete={handleDeleteTeammate}
                        canEdit={[
                          userRoles.USER_AGENCY,
                          userRoles.AGENCY_ADMIN
                        ].includes(meData.role)}
                      />
                    ))
                  ) : (
                    <p className="text-base text-custom-gray-light-6 font-medium">
                      No members found
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  {clientInfo.totalAgencyAssignee?.length > 0 ? (
                    clientInfo.totalAgencyAssignee.map((assignee) => (
                      <MemberCard user={assignee.user} role={assignee.role} />
                    ))
                  ) : (
                    <p className="text-base text-custom-gray-light-6 font-medium">
                      No members found
                    </p>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="mb-8">
              <p className="font-medium text-lg mb-3 text-black">Your Team</p>
              <p className="text-base text-custom-gray-light-6 font-medium">
                No members found
              </p>
            </div>

            <div className="">
              <p className="font-medium text-lg mb-3 text-black">Client Team</p>
              <p className="text-base text-custom-gray-light-6 font-medium">
                No members found
              </p>
            </div>
          </>
        )}
      </Wrapper>
      <EditTeammatePanelNew
        open={editClientTeammate.open}
        onClose={closeEditPanel}
        data={editClientTeammate.data}
        updateTeammate={handleUpdateTeammate}
        elmFor="client"
        canEditAccount={true}
        clientOwner={clientData?._id}
      />
      <AlertModal
        warningText="Teammate will be deleted."
        open={deleteClientTeammate.open}
        handleDialog={handleDeleteTeammate}
        handleDeleteAction={handleTeammateData}
        loading={deleteClientTeammate.loading}
      />
    </>
  )
}

const MemberCard = ({ user, role, handleEdit, handleDelete, canEdit }) => {
  return (
    <div className={`flex items-center justify-between mb-6 `}>
      <div className="w-full flex items-center">
        <ColoredAvatars user={user} size="medium" />

        <p className="capitalize font-medium text-small text-primary-mid-dark ml-2 mr-4">
          {user.name}
        </p>
        {getRoleBadge(role)}
      </div>

      <p className="w-full text-base text-custom-gray-light-6">{user.email}</p>
      {canEdit && (
        <div className="flex justify-center pr-4 ">
          <IconButton size="small" onClick={() => handleEdit(user)}>
            <EditIcon className="h-4 w-4 text-primary-main" />
          </IconButton>

          <IconButton size="small" onClick={() => handleDelete(user)}>
            <DeleteIcon className="h-4 w-4 text-custom-red-main" />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default Members
