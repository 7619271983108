import { makeStyles, createStyles } from '@material-ui/core'

const newTeammateStyles = makeStyles((theme) =>
  createStyles({
    active: {
      borderBottom: '1px solid #4aa3d4',
      color: '#4aa3d4'
    },
    addUser: {
      fontSize: '14px',
      fontWeight: '500',
      color: '#151b26'
    },
    footer: {
      height: '40px'
    },
    teammatesListRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '55px',
      padding: '0.60rem',
      transition: 'background 150ms ease-in-out',
      '&:hover': {
        background: 'rgba(229, 231, 235, 0.5)'
      },
      '&.active': {
        backgroundColor: 'rgba(229, 231, 235, 0.5)'
      }
    },
    list: {
      minHeight: '230px',
      maxHeight: '230px',
      overflow: 'auto'
    },
    icon: {
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: 'rgba(21, 7, 38, 0.08)',
        padding: '5px 8px'
      },
      // '&:focus': {
      //   backgroundColor: 'rgba(21, 7, 38, 0.08)',
      //   padding: '5px 8px'
      // },
      '&.active': {
        backgroundColor: 'rgba(21, 7, 38, 0.08)'
      }
    },
    listItemIconRoot: {
      minWidth: '20px'
    },
    listTextItemRoot: {
      padding: '0 8px'
    },
    paper: {
      boxShadow: '0 1px 4px 0 rgb(21 27 38 / 8%)'
    }
  })
)

export default newTeammateStyles
