import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { CustomModalHeader } from 'global/globalComponents/CustomModal'
import { useEffect, useRef, useState } from 'react'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'
import ct from 'countries-and-timezones'
import Select from 'react-select'
import Countries from '../../static/countries/Countries'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { whitelabel } from 'thunks/whiteLabel/action'
import userRoles from 'utils/userRoles'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import { LoadingButton } from 'global/globalComponents'
// import updateDocumentTitle from 'utils/updateDocumentTitle'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
// import { getAuthToken, loadSampleData } from 'utils'
import PersonAvatar from 'global/globalComponents/PersonAvtar/PersonAvtar'
import ImageUploadPopover from 'global/globalComponents/ImageUploadPopover/ImageUploadPopover'
import { createFreePlan } from 'thunks/agencyPayment/actions'
import settingStyles from 'components/Settings/settingStyles'

const defaultImage =
  'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'

const GeneralFormModal = ({ open, handleClose, data: redirectData }) => {
  const classes = settingStyles()
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me.data)
  const imageUploadPopoverAnchorEl = useRef(null)
  const [imageUploadPopoverStatus, setImageUloadPopoverStatus] = useState(false)
  const [savingLoading, setSavingLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [formData, setFormData] = useState({
    brand: {
      favicon: '',
      largeLogo: '',
      smallLogo: '',
      brandName: ''
    },
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    defaultTimeZone: '',
    facebookurl: '',
    linkedinUrl: '',
    twitterurl: '',
    pinteresturl: ''
  })

  // const [waitingModalStaus, setWaitingModalStatus] = useState({
  //   open: false,
  //   time: null
  // })
  // const [imageUploadPopoverAnchorEl, setImageUploadPopoverAnchorEl] = useState(null)
  const handleImageUploadPopover = () => {
    // console.log('===========')
    setImageUloadPopoverStatus((prev) => !prev)
  }

  useEffect(() => {
    const countries = ct.getAllCountries()
    let arr = []
    Object.values(countries).forEach((country) => {
      arr = arr.concat(country.timezones)
    })
    if (arr.length > 0) {
      setOptions(
        [...new Set(arr)].sort().map((el) => (el = { value: el, label: el }))
      )
    }
  }, [])

  // const country = []

  // useEffect(() => {
  //   dispatch(updateUserAcces(me.role))
  //   Countries.map((item) => {
  //     country.push(item)
  //   })
  //   //  console.log(country)
  // }, [])

  const timezoneHandler = (e) => {
    setFormData((prev) => ({ ...prev, defaultTimeZone: e }))
  }

  const handleAddressChange = (e) => {
    const { name, value } = e.target
    let items = { ...formData }
    let item = items.address
    items.address = { ...item, [name]: value }
    setFormData(items)
  }

  const handleCountryChange = (e) => {
    let items = { ...formData }
    let item = items.address
    items.address = { ...item, country: e }
    // setFormData(items)

    setFormData((prev) => ({
      ...prev,
      address: { ...prev.address, country: e }
    }))
  }

  const handleImageChange = (value) => {
    let items = { ...formData }
    let item = items.brand
    items.brand = {
      ...item,
      favicon: value,
      smallLogo: value,
      largeLogo: value
    }
    setFormData(items)
    // setFormData((prev) => ({...prev,file:value}))
  }

  const whitelabelCallback = async (res, err) => {
    if (err) {
      setSavingLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Saved Successfully'))
      if (redirectData && handleClose) {
        const { token, freePlansObj, domain, path } = redirectData
        if (freePlansObj) {
          try {
            const freePlanres = await createFreePlan(freePlansObj, token)
            window.open(
              `https://${freePlanres.agencyDomain}${
                freePlanres.path
              }?signup=true&auth_token=${token.split(' ')[1]}`,
              '_self'
            )
            // handleClose()
          } catch (err) {
            window.open(
              `https://${domain}${path}?signup=true&auth_token=${
                token.split(' ')[1]
              }`,
              '_self'
            )
            // handleClose()
          }
        } else {
          window.open(
            `https://${domain}${path}?signup=true&auth_token=${
              token.split(' ')[1]
            }`,
            '_self'
          )
          // handleClose()
        }

        // setWaitingModalStatus({ open: true, time: 1 })

        // const intervalTimer = setInterval(() => {
        //   setWaitingModalStatus((prev) => ({
        //     ...prev,
        //     time: prev.time - 1
        //   }))
        // }, 1000)

        // setTimeout(() => {
        //   window.open(
        //     `https://${freePlanres.agencyDomain}${
        //       freePlanres.path
        //     }?signup=true&auth_token=${token.split(' ')[1]}`,
        //     '_self'
        //   )
        //   clearInterval(intervalTimer)
        // }, 40000)
      }
    }
  }

  const handleSave = () => {
    const { city, country, line1, state, zipCode } = formData.address
    const { defaultTimeZone } = formData
    if (
      !city ||
      !country.label ||
      !line1 ||
      !state ||
      !zipCode ||
      !defaultTimeZone
    ) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    setSavingLoading(true)
    dispatch(
      whitelabel(
        redirectData?.id ?? me?.profile?._id,
        {
          ...formData,
          defaultTimeZone: formData.defaultTimeZone.value,
          brand: { ...formData.brand, brandName: redirectData?.brandName }
        },
        whitelabelCallback
      )
    )
  }
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 7,
      paddingBottom: 7,
      height: '30px'
    })
  }

  const handleImageUploadFromSystem = (e) => {
    const file = e.target.files[0]
    const name = e.target.name
    const imgReader = new FileReader()
    imgReader.onloadend = function () {
      if (file) {
        setFormData((prev) => ({
          ...prev,
          brand: {
            ...prev.brand,
            [name]: imgReader.result
          }
        }))
      }
    }
    imgReader.readAsDataURL(file)
  }

  return (
    <CustomModal open={open}>
      <CustomModalHeader heading="Please enter your company details" />
      <CustomModalBody overflow="visible">
        <div className="">
          <div className="hidden">
            {['favicon', 'smallLogo', 'largeLogo'].map((i) => (
              <input
                name={i}
                key={i}
                accept="image/*"
                style={{ display: 'none' }}
                id={i}
                multiple={false}
                type="file"
                onChange={handleImageUploadFromSystem}
              />
            ))}
          </div>
          <FormLabel className="block text-sm font-semibold leading-8 text-gray-700 col-span-8 ">
            Upload brand images
          </FormLabel>
          <div className="flex mt-2 gap-8 mb-8">
            <div>
              <FormLabel className={classes.imgLabel}>Favicon</FormLabel>
              <label htmlFor="favicon">
                <PersonAvatar
                  img={
                    formData.brand?.favicon?.length
                      ? formData.brand.favicon
                      : defaultImage
                  }
                />
              </label>
            </div>
            <div>
              <FormLabel className={classes.imgLabel}>Icon</FormLabel>
              <label htmlFor="smallLogo">
                <PersonAvatar
                  img={
                    formData.brand?.smallLogo?.length
                      ? formData.brand.smallLogo
                      : defaultImage
                  }
                />
              </label>
            </div>
            <div>
              <FormLabel className={classes.imgLabel}>
                Logo{' '}
                <span style={{ color: 'grey', fontSize: '10px' }}>
                  (Icon + Text)
                </span>
              </FormLabel>
              <label htmlFor="largeLogo">
                <PersonAvatar
                  img={
                    formData.brand?.largeLogo?.length
                      ? formData.brand.largeLogo
                      : defaultImage
                  }
                />
              </label>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <FormLabel required>Set Timezone</FormLabel>
          <Select
            disabled={[
              userRoles.AGENCY_VIEWER,
              userRoles.CLIENT_VIEWER
            ].includes(me.role)}
            isSearchable={true}
            placeholder="Timezone"
            onChange={timezoneHandler}
            value={formData.defaultTimeZone}
            options={options}
            maxMenuHeight={140}
            // className="flex-grow"
            className="col-span-4 md:col-span-2 text-sm"
            styles={{ ...reactSelectCustomStyles, ...customStyles }}
          />
        </div>

        <div className="mt-4">
          <FormLabel required htmlFor="companyName">
            Company/Personal Address
          </FormLabel>
          <div className="grid grid-cols-8 gap-4">
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Line 1"
              name="line1"
              inputProps={{ id: 'companyName' }}
              className="w-full col-span-4"
              value={formData.address.line1}
              onChange={handleAddressChange}
            />
            <TextField
              required
              placeholder="Line 2 (Optional)"
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              name="line2"
              value={formData.address.line2}
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="City"
              value={formData.address.city}
              name="city"
              className="col-span-4 md:col-span-2"
              onChange={handleAddressChange}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              value={formData.address.state}
              name="state"
              placeholder="State"
              className="col-span-4 md:col-span-2"
              onChange={handleAddressChange}
            />

            <Select
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              isSearchable={true}
              placeholder="country"
              options={Countries}
              maxMenuHeight={90}
              value={formData.address.country}
              onChange={handleCountryChange}
              className="col-span-4 md:col-span-2 text-sm"
              styles={{ ...reactSelectCustomStyles, ...customStyles }}
              menuPlacement="top"
            />

            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Zip Code"
              name="zipCode"
              value={formData.address.zipCode}
              className="col-span-4 md:col-span-2"
              onChange={handleAddressChange}
              type="text"
            />
          </div>
        </div>
        <ImageUploadPopover
          onClose={handleImageUploadPopover}
          open={imageUploadPopoverStatus}
          anchorEl={imageUploadPopoverAnchorEl.current}
          profileImageChange={handleImageChange}
        />
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          loading={savingLoading}
          disabled={[userRoles.AGENCY_VIEWER, userRoles.CLIENT_VIEWER].includes(
            me.role
          )}
          className="w-auto"
          onClick={handleSave}
        >
          Proceed to your dashboard
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default GeneralFormModal
