import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import gettingStartedStyles from './GettingStartedStyles'

function GettingStartedTasksNav({ tabs, changeTab, openTabIndex, loading }) {
  const classes = gettingStartedStyles()
  return (
    <div className={clsx(classes.sideNav, 'relative')}>
      <span
        className={clsx(classes.activeTabLine, 'absolute bg-primary-main')}
        style={{ transform: `translateY(${100 * openTabIndex}%)` }}
      ></span>
      {tabs.map((item, index) => (
        <div
          key={item.text}
          role="button"
          className={clsx(
            classes.sideNavTab,
            `flex justify-start items-center pl-6 border-b ${
              index === openTabIndex ? 'border-r-0' : 'border-r'
            }`
          )}
          onClick={() => changeTab(index)}
        >
          <div className="flex items-center text-gray-700">
            <span>
              {loading ? (
                <CircularProgress size={20} />
              ) : item.isCompleted ? (
                <CheckCircleIcon style={{ color: '#1e9a50' }} />
              ) : (
                item.icon
              )}
            </span>
            <span className="ml-5">{item.text}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default GettingStartedTasksNav
