import React from 'react'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import PeoplePerformance from './PeoplePerformance/PeoplePerformance'
import StandupReports from './StandupReports/StandupReports'
import SingleUserStandups from './SingleUserStandups/SingleUserStandups'
import { useSelector } from 'react-redux'
import ReportsOverview from './ReportsOverview/ReportsOverview'
import FinancialReport from './FinancialReport/FinancialReport'

const Reports = () => {
  const { path } = useRouteMatch()
  const meData = useSelector(state => state.me.data)
  const { data: permissionData, loading: permissionLoading } = useSelector(
    state => state.permission
  )

  if (permissionLoading) return null

  return (
    <>
      <OuterContentWrapper>
        <div>
          <Switch>
            <Route exact path={path}>
              {permissionData.access?.report?.view ? (
                <ReportsOverview />
              ) : (
                <Redirect to={path + `/standups/${meData._id}`} />
              )}
            </Route>
            <Route path={path + '/people-performance'}>
              {permissionData.access?.report?.view ? (
                <PeoplePerformance />
              ) : (
                <Redirect to={path + `/standups/${meData._id}`} />
              )}
            </Route>
            <Route exact path={path + '/standups'}>
              {permissionData.access?.report?.view ? (
                <StandupReports />
              ) : (
                <Redirect to={path + `/standups/${meData._id}`} />
              )}
            </Route>
            <Route exact path={path + '/finance'}>
              {permissionData.access?.report?.view ? (
                <FinancialReport />
              ) : (
                <Redirect to={path + `/standups/${meData._id}`} />
              )}
            </Route>
            <Route path={path + '/standups/:userId'}>
              <SingleUserStandups
                showBackButton={permissionData.access?.report?.view}
              />
            </Route>
          </Switch>
        </div>
      </OuterContentWrapper>
    </>
  )
}

export default Reports
