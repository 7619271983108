import axios from 'axios'
import { PERMISSION } from './reducer'

export const fetchPermissions = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/permission/user'
      })
      dispatch({ type: PERMISSION.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: PERMISSION.ERROR })
      if (callback) callback(err, true)
    }
  }
}
