import { useContext, useEffect, useState } from 'react'
import { LoadingButton, RoundedTextInput } from 'global/globalComponents'
// import {
//   CustomModal,
//   CustomModalBody,
//   CustomModalFooter,
//   CustomModalHeader
// } from 'global/globalComponents/CustomModal'
import { Button, Popover } from '@material-ui/core'
import { addTaskInCalendar, createTask } from 'thunks/task/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const CreateTaskPopover = ({ anchorEl, data, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const currentWorkspaceBoard = useSelector(
    state => state.userWorkspaces.currentBoard
  )
  const { boardId, workspaceId } = useParams()
  const [title, setTitle] = useState('')
  const { socketId } = useContext(WorkspacePusherContext)

  useEffect(() => {
    if (anchorEl) setTitle('')
  }, [anchorEl])

  const handleCreateTask = e => {
    e.preventDefault()
    if (title.trim() === '') return

    setLoading(true)

    dispatch(
      createTask(
        {
          title: title.trim(),
          taskSection: currentWorkspaceBoard.boardSection[0]._id,
          workspaceBoard: boardId,
          workSpace: workspaceId,
          startDate: new Date(data.startDate).toISOString(),
          dueDate: new Date(data.dueDate).toISOString(),
          socketId
        },
        createNewTaskCallback
      )
    )
  }

  const createNewTaskCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(addTaskInCalendar(res._id))
      dispatch(fireSuccessToaster('Task created successfully'))
      handleClose()
    }
  }

  const handleTitleChange = e => {
    setTitle(e.target.value)
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <div className="w-65 p-4">
        <h5 className="mb-1 font-bold">Create Task</h5>
        <form onSubmit={handleCreateTask}>
          <RoundedTextInput
            value={title}
            onChange={handleTitleChange}
            autoFocus
            size="small"
            className="mb-4"
            placeholder="Enter task name"
          />
        </form>
        <div className="flex justify-end space-x-2">
          <Button variant="outlined" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            size="small"
            loading={loading}
            onClick={handleCreateTask}
          >
            Create
          </LoadingButton>
        </div>
      </div>
    </Popover>
  )
}

export default CreateTaskPopover
