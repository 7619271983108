import React, { useRef, useState, useCallback } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import { Skeleton } from '@material-ui/lab'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import AddIcon from '@material-ui/icons/Add'
import { CustomTooltip } from 'global/globalComponents'
import CustomToolbar from './CustomToolbar'
import { useEffect } from 'react'
// import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'

const DragAndDropCalendar = withDragAndDrop(Calendar)

const TasksCalendar = ({
  isLoading,
  events,
  permission,
  handleUpdateEvent,
  openTaskSidePanel,
  onAddTaskClick,
  localizer,
  onRangeChange
}) => {
  const eventPropGetter = (event, start, end, isSelected) => ({
    style: {
      backgroundColor: event.taskSection?.color,
      fontSize: '12px',
      fontWeight: 500,
      padding: '1px 6px'
    }
  })
  const ref = useRef()

  const calendarStyle = date => {
    return {
      style: {
        backgroundColor:
          new Date(date).getDay() === 0 &&
          new Date().getMonth() === new Date(date).getMonth()
            ? '#ffffea'
            : '',
        fontSize: '12px'
      }
    }
  }

  const slotGroupPropGetter = useCallback(
    () => ({
      style: {
        // minHeight: 60,
        fontSize: '12px'
      }
    }),
    []
  )

  return (
    <>
      {isLoading ? (
        <CalendarViewLoading />
      ) : (
        <div className="App__project-calendar-view bg-white">
          <DragAndDropCalendar
            expandToFit={true}
            ref={ref}
            localizer={localizer}
            defaultView="month"
            events={events}
            startAccessor="start"
            endAccessor="end"
            resizable={permission.update}
            onEventResize={handleUpdateEvent}
            onEventDrop={handleUpdateEvent}
            eventPropGetter={eventPropGetter}
            onRangeChange={onRangeChange}
            // onSelectSlot={handleSlotClick}
            draggableAccessor={() => permission.update}
            onSelectEvent={openTaskSidePanel}
            max={1}
            components={{
              toolbar: CustomToolbar,
              header: props => {
                const { date } = props
                return (
                  <div className="text-xs text-primary-dark font-medium p-2">
                    {props.label}
                  </div>
                )
              },
              dateCellWrapper: ({ children, value }) => {
                return React.Children.map(children, child => {
                  return React.cloneElement(
                    child,
                    {
                      className: `${child.props.className} relative show-on-hover-parent bg-white`
                    },
                    permission.create ? (
                      <CustomTooltip title="Create new task" placement="top">
                        <button
                          className="absolute right-2 bottom-0 show-on-hover-child bg-primary-main text-white mb-1 w-5 h-5 text-xs flex justify-center items-center rounded-sm"
                          onClick={onAddTaskClick}
                          data-value={value}
                        >
                          <AddIcon fontSize="small" />
                        </button>
                      </CustomTooltip>
                    ) : null
                  )
                })
              }
            }}
            slotGroupPropGetter={slotGroupPropGetter}
            dayPropGetter={calendarStyle}
            style={{ height: '150vh' }}
          />
        </div>
      )}
    </>
  )
}

export const CalendarViewLoading = () => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
      gridAutoRows: 180,
      gridGap: 2
    }}
  >
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
  </div>
)

export default TasksCalendar
