import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import TeammatesHeader from './TeammatesHeader'
import TeammatesTable from './TeammatesTable'
// import AddNewTeammatePanel from './AddNewTeammatePanel'
import EditTeammatePanelNew from './EditTeammatePanelNew'
import {
  updateTeammate,
  reInviteTeammate,
  fetchTeammateOfCurrentUser
} from 'thunks/teammate/actions'
import { errorMessages, userRoles } from 'utils'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import {
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
  Route
} from 'react-router-dom'
// import VerticalsTable from './VerticalsTable'
// import VerticalsModal from './VerticalsModal'
import {
  // createTeamVerticals,
  fetchTeamVerticals
} from 'thunks/Verticals/action'
import { CustomTabs } from 'global/globalComponents'
import ApproveTeammateTable from './ApproveTeammateTable'
// import { toggleAddTeammateSidePanel } from 'thunks/generlAppState/actions'

const AgencyTeammates = () => {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const search = useSelector((state) => state.generalAppState.headerSearch)
  // const [openAddTeammatePanel, setOpenAddTeammatePanel] = useState(false)
  const [editTeammatePanel, setEditTeammatePanel] = useState({
    open: false,
    data: {}
  })
  const teammates = useSelector((state) => state.userTeammates)
  const { fetchedFirstTime } = useSelector((state) => state.userWorkspaces)
  const verticals = useSelector((state) => state.teamVerticals)
  const { data: meData } = useSelector((state) => state.me)
  const [teammatesData, setTeammatesData] = useState([])
  const [teammatesUnderApproval, setTeammatesUnderApproval] = useState([])
  const [openVerticalModal, setOpenVerticalModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState(pathname)
  const canEditAccount = useMemo(() => {
    //manager, admin, super admin
    let meRole = meData.role,
      mateRole = editTeammatePanel.data.role

    // user cannot edit himself or his superiors
    if (meRole === mateRole) return false
    if (meRole <= userRoles.USER_AGENCY) return true // agency can edit everyone
    if (meRole === userRoles.AGENCY_ADMIN && mateRole > userRoles.USER_AGENCY)
      return true
    if (
      meRole === userRoles.AGENCY_MANAGER &&
      mateRole > userRoles.USER_AGENCY &&
      mateRole !== userRoles.AGENCY_ADMIN
    )
      return true
    return false
  }, [meData.role, editTeammatePanel.data])

  const tabsData = useMemo(
    () => [
      {
        label: 'Teammates',
        value: path + '/all',
        number: teammatesData.length
      },
      {
        label: 'Under Approval',
        value: path + '/under-approval',
        number: teammatesUnderApproval.length
      }
    ],
    [path, teammatesData.length, teammatesUnderApproval.length]
  )
  const history = useHistory()

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useEffect(() => {
    if (!teammates.fetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [teammates.fetched])

  useEffect(() => {
    if (meData.role < userRoles.USER_CLIENT && !verticals.fetched) {
      dispatch(fetchTeamVerticals())
    }
  }, [verticals])

  useEffect(() => {
    if (search.length > 0) {
      let arr = [...teammates.data].filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase())
      )

      let approved = [],
        underApproval = []

      for (let teammate of arr) {
        if (teammate.status === 'need_approval') {
          underApproval.push(teammate)
        } else approved.push(teammate)
      }

      setTeammatesData(
        teammates.data.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.email.toLowerCase().includes(search.toLowerCase())
        )
      )

      setTeammatesUnderApproval(
        teammates.underApproval.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.email.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else {
      setTeammatesData([...teammates.data])
      setTeammatesUnderApproval([...teammates.underApproval])
    }
  }, [teammates.data, teammates.underApproval, search])

  // const handleToggleAddPanel = () => {
  //   dispatch(toggleAddTeammateSidePanel())
  // }

  const handletoggleVerticalModal = () => {
    setOpenVerticalModal((prev) => !prev)
  }

  // const handleAddTeammate = (data, panelCallback) => {
  //   dispatch(
  //     addTeammate(data, (res, err) => {
  //       panelCallback()
  //       addTeammateCallback(res, err)
  //     })
  //   )
  // }

  // const addTeammateCallback = (res, err) => {
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     dispatch(fireSuccessToaster('Teammate Added Successfully'))
  //     handleToggleAddPanel()
  //   }
  // }

  const openEditPanel = (data) => {
    setEditTeammatePanel(data)
  }
  const closeEditPanel = () => {
    setEditTeammatePanel((prev) => ({ ...prev, open: false }))
  }

  const handleUpdateTeammate = (id, data, panelCallback) => {
    dispatch(
      updateTeammate(id, data, (res, err) => {
        panelCallback()
        updateTeammateCallback(res, err)
      })
    )
  }

  const updateTeammateCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
    }
  }

  const handleInviteTeammate = (teammateId) => {
    if (teammateId) {
      reInviteTeammate(teammateId, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster('Invitation Sent!'))
        }
      })
    }
  }

  const handleTabChange = useCallback((newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }, [])

  return (
    <OuterContentWrapper>
      <TeammatesHeader openAddVerticalModal={handletoggleVerticalModal} />
      <div className="flex justify-between items-center mb-6">
        <div className="w-full">
          <CustomTabs
            tabsData={tabsData}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>

      {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
          Search results for:{' '}
          <span className="text-primary-dark">{search}</span>
        </p>
      )}

      <Switch>
        {/* <Route exact path={path + '/company'}>
          <ClientCompany search={search} />
        </Route> */}
        <Route exact path={path + '/all'}>
          <TeammatesTable
            loading={teammates.loading}
            teammates={teammatesData}
            openEditPanel={openEditPanel}
            handleInviteTeammate={handleInviteTeammate}
            canEditAccount={canEditAccount}
          />
        </Route>

        <Route path={path + '/under-approval'}>
          <ApproveTeammateTable
            loading={teammates.loading}
            teammates={teammatesUnderApproval}
          />
        </Route>
      </Switch>

      {/* <AddNewTeammatePanel
          open={openAddTeammatePanel}
          onClose={setOpenAddTeammatePanel}
          handleAddTeammate={handleAddTeammate}
        /> */}
      <EditTeammatePanelNew
        open={editTeammatePanel.open}
        onClose={closeEditPanel}
        data={editTeammatePanel.data}
        updateTeammate={handleUpdateTeammate}
        elmFor="agency"
        canEditAccount={canEditAccount}
      />
      {/* <VerticalsModal
          open={openVerticalModal}
          onClose={handletoggleVerticalModal}
          verticals={verticals.data}
        /> */}
    </OuterContentWrapper>
  )
}

export default AgencyTeammates
