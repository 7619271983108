import { makeStyles } from '@material-ui/core'

const FilesAssetsStyles = makeStyles((theme) => ({
  addNewButton: {
    color: '#666',
    '&:hover, &:focus, &:focus-within': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    }
  },
  dropArea: {
    minHeight: '50%'
  },
  container: {
    boxShadow: '0 0 10px -6px rgba(0, 0, 0, 0.25)'
  },
  folder: {
    transition:
      'transform 300ms ease-in-out, box-shadow 300ms ease-in-out, background-color 300ms ease-in-out',

    '& button': {
      color: '#aaa'
    },

    '&:hover, &:focus, &:focus-within': {
      background: theme.palette.primary.main,
      color: '#fff',
      transform: 'scale(1.05)',
      boxShadow: '0 0 10px rgba(0, 0, 255, 0.25)'
    },
    '&:hover button, &:focus button, &:focus-within button': {
      color: '#fff'
    },
    '&:hover $folderIcon, &:focus $folderIcon, &:focus-within $folderIcon': {
      color: '#fff'
    }
  },
  folderIcon: {
    fill: '#ffd734'
  }
}))

export default FilesAssetsStyles
