import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './WriteEmail.css'

const WriteEmail = ({ emailHTML, setEmailHTML }) => {
  const handleChange = (content) => {
    setEmailHTML(content)
  }

  return (
    <div className="WriteEmail">
      <ReactQuill
        theme="snow"
        modules={{
          toolbar: [
            { header: [1, 2, 3] },
            'bold',
            'italic',
            'underline',
            'strike',
            'link'
          ]
        }}
        value={emailHTML}
        onChange={handleChange}
      />
      {/* <CKEditor
        editor={ClassicEditor}
        data={emailHTML}
        config={{
          toolbar: ['heading', 'paragraph', 'bold', 'italic', 'link']
        }}
        onChange={handleChange}
      /> */}
    </div>
  )
}

export default WriteEmail
