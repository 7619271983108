import { makeStyles, createStyles } from '@material-ui/core'

const clrPrimary = '#2a8263'
const clrPrimaryLight = '#c7efdc'
const invoiceStyles = makeStyles((theme) =>
  createStyles({
    rowContainer: {
      border: '1px solid #EFEFEF',
      borderRadius: '12px',
      backgroundColor: 'white',
      transition: theme.transitions.create(['box-shadow'], {
        duration: theme.transitions.duration.enteringScreen
      }),
      '&:hover': {
        boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.08)'
      }
    },
    image: {
      height: '50px',
      width: '50px'
    },
    modalRowContainer: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      '& select': {
        width: '100%'
      },
      '& button': {
        outline: 'none'
      }
    },

    textFieldStyle: {
      width: '100%'
    },
    Itembutton: {
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'blue',
      marginTop: '1rem'
    },
    displayClass: {
      display: 'flex',
      flexDirection: 'row'
    },

    sendButton: {
      marginRight: '1rem'
    },

    serviceTypeContainer: {
      '&>p, &>a': {
        fontSize: 14,
        fontWeight: 500,
        color: '#6f7782',
        // paddingBottom: theme.spacing(0.5),
        cursor: 'pointer',
        minWidth: 32,
        textAlign: 'center',
        userSelect: 'none',
        transitionDuration: '.2s',
        transitionProperty: 'box-shadow,color',
        whiteSpace: 'nowrap',
        '&.active': {
          boxShadow: `0px 2px ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      }
    },
    fieldsWrapper: {
      maxWidth: '600px',
      boxSizing: 'border-box'
    },
    tabButton: {
      marginBottom: '30px'
    },
    invoice: {
      boxShadow: '0 0 5px 2px rgba(0, 0, 0, 0.25)',
      padding: '2rem'
    },
    textDark: {
      color: '#32325d'
    },
    textLight: {
      color: '#525f7f'
    },
    tableBody: {
      background: '#f6f9fc',
      borderTop: '1px solid #aab7c4',
      borderBottom: '1px solid #aab7c4',

      '& > td': {
        padding: '0.5rem'
      }
    },
    subtotal: {
      width: '40%'
    },
    amountDue: {
      width: '40%',
      background: '#f6f9fc',
      borderTop: '1px solid #aab7c4',
      borderBottom: '1px solid #aab7c4'
    },
    line: {
      borderColor: '#aab7c4'
    },
    root: {
      zIndex: 9999999999,
      background: `rgba(0, 0, 0, 0.75)`
    },
    container: {
      height: '100%',
      width: '90%',
      maxWidth: 650,
      position: 'relative'
    },
    tableIcon: {
      color: 'rgba(20,20,43,1)',
      cursor: 'pointer'
    },
    menuPaper: {
      borderRadius: '0.375rem'
    },

    list: {
      padding: '4px 0'
    },

    listItem: {
      padding: '0.5rem 1.5rem',
      minWidth: 115
    },

    listItemText: {
      margin: 0
    }
  })
)

export default invoiceStyles
