import axios from 'axios'
import { errorMessages } from 'utils'
import { REPORTING } from './reducer'

/**
 * Create post regarding workspace status
 * @param {Object} data workspace id, status (1/2/3), title, file, sec1, sec2
 * @param {Function} callback
 */
export const createProjectStatusPost = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/workspacestatus',
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: REPORTING.ADD_ONE_REPORTING, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch posts regarding workspace status
 * @param {Object} data workspace id, status (1/2/3), title, file, sec1, sec2
 * @param {Function} callback
 */
export const fetchProjectStatusPosts = (wsId, callback) => {
  return async (dispatch, getState) => {
    const {
      me: { data: meData }
    } = getState()
    dispatch({ type: REPORTING.LOADING, payload: true })
    try {
      const res = await axios({
        method: 'GET',
        url: `/workspacestatus/workspace/${wsId}`
      })
      if (callback) callback(res.data)
      const filterData = res.data.filter(
        (status) =>
          !(status.state === 'draft' && status.user?._id !== meData._id)
      )
      dispatch({ type: REPORTING.FETCHED, payload: filterData.reverse() })
    } catch (err) {
      if (callback)
        if (callback)
          callback(
            err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
    }
  }
}

/**
 * Update project status post
 * @param {String} id workspace id
 * @param {Object} data update data
 * @param {Function} callback
 */
export const updateProjectStatusPost = (wsId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspacestatus/${wsId}`,
        data
      })

      if (callback) callback(res.data, false)
      dispatch({ type: REPORTING.UPDATE_ONE_REPORTING, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete project status post
 * @param {String} id post id
 * @param {Function} callback
 */
export const deleteProjectStatusPost = (postId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/workspacestatus/${postId}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: REPORTING.DELETE_ONE_REPORTING, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
