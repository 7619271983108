import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { changeInvoiceStatusById } from 'thunks/invoices/actions'
import { LoadingButton } from 'global/globalComponents'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useDispatch } from 'react-redux'

export default function InvoiceStatusModal({
  open,
  onClose,
  id
  // handleChangeStatus,
  // loading,
  // invoiceStatus,
  // setInvoiceStatus
}) {
  const dispatch = useDispatch()
  const [invoiceStatus, setInvoiceStatus] = useState('paid')
  const [loading, setLoading] = useState(false)

  const handleChangeStatus = () => {
    dispatch(
      changeInvoiceStatusById(
        { status: invoiceStatus },
        id,
        statusChangeCallback
      )
    )
  }

  const statusChangeCallback = (res, err) => {
    if (!err) {
      handleClose()
      dispatch(fireSuccessToaster('Saved Successfully'))
    }
  }

  const handleClose = () => {
    setInvoiceStatus('paid')
    onClose()
  }

  return (
    <CustomModal open={open} size="extraSmall">
      <CustomModalHeader heading="Change Invoice Status" size="small" />
      <CustomModalBody>
        <span className="text-sm mb-4 font-medium">Mark Invoice as...</span>
        <div className="flex flex-col">
          {/* <FormLabel>Mark Invoice as...</FormLabel> */}
          <RadioGroup
            value={invoiceStatus}
            onChange={(e) => setInvoiceStatus(e.target.value)}
            name="payment_method"
          >
            <FormControlLabel
              className="mt-2 mb-2 text-sm"
              control={<Radio />}
              value="paid"
              // label="Automatically charge a payment method on file"
              label={
                <div className="text-gray-700 flex flex-col mt-4">
                  <span className="font-medium">Paid</span>
                  <span className="text-xs">
                    Payment was collected outside of Stripe
                  </span>
                </div>
              }
            />
            <FormControlLabel
              className="mb-2"
              control={<Radio />}
              value="void"
              // label="Email Invoice to the customer to pay manually"
              label={
                <div className="text-gray-700 flex flex-col mt-4">
                  <span className="font-medium">Void</span>
                  <span className="text-xs">
                    This invoice was accidentally finalised or contain a mistake
                  </span>
                </div>
              }
            />
            <FormControlLabel
              className="mb-2"
              control={<Radio />}
              value="uncollectible"
              // label="Email Invoice to the customer to pay manually"
              label={
                <div className="text-gray-700 flex flex-col mt-4">
                  <span className="font-medium">Uncollectible</span>
                  <span className="text-xs">
                    Payment of this invoice is not expected.
                    {/* It is still
                    possible to collect payment should you customer attempt to
                    pay. */}
                  </span>
                </div>
              }
            />
          </RadioGroup>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <div className="flex flex-row-reverse gap-2">
          <LoadingButton onClick={handleChangeStatus} loading={loading}>
            Update Status
          </LoadingButton>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </CustomModalFooter>
    </CustomModal>
  )
}
