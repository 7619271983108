import React from 'react'
import CheckIcon from '@material-ui/icons/Check'

const PlanCard = ({ plan, handleSelectPlan }) => {
  const selectPlan = () => {
    handleSelectPlan(plan)
  }

  return (
    <div className="bg-white">
      <div
        key={plan.nickname}
        className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
      >
        <div className="p-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            {plan.nickname}
          </h2>
          <p className="mt-8">
            <span className="text-4xl font-extrabold text-gray-900">
              ${plan.amount * 0.01}
            </span>{' '}
            <span className="text-base font-medium text-gray-500">/mo</span>
          </p>
          <button
            className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
            onClick={selectPlan}
          >
            Buy {plan.nickname}
          </button>
        </div>
        <div className="pt-6 pb-8 px-6">
          <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
            What's included
          </h3>
          <ul className="mt-6 space-y-4">
            <li className="flex space-x-3">
              <CheckIcon
                className="flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">
                Add {plan.metadata.teammateQuota} teammates
              </span>
            </li>
            <li className="flex space-x-3">
              <CheckIcon
                className="flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">
                Add {plan.metadata.clientQuota} clients
              </span>
            </li>
            <li className="flex space-x-3">
              <CheckIcon
                className="flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">
                Create {plan.metadata.serviceQuota} services
              </span>
            </li>
            <li className="flex space-x-3">
              <CheckIcon
                className="flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">
                Get {plan.metadata.storageQuota}GB storage
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PlanCard
