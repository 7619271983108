import { useEffect } from 'react'
import { Clientvenue_logo } from 'static/Images/index'
/**
 * Change title of the document according to the current page
 * @param {string} title Title of the current page
 * @param {String}  image  Image for current page
 */

const useTitle = (title, image) => {
  // const me = useSelector((state) => state.me.data)

  useEffect(() => {
    if (image !== undefined && image !== null) {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = image
      document.querySelectorAll('[rel="icon"]')[0].replaceWith(link)
    } else {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = Clientvenue_logo
      document.querySelectorAll('[rel="icon"]')[0].replaceWith(link)
    }

    if (title == undefined || title == null || title.length == 0) {
      document.title = 'ClientVenue'
    } else {
      document.title = `${title}`
    }
  }, [title, image])
}

export default useTitle
