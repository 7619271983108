import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover
} from '@material-ui/core'
import clsx from 'clsx'
import Pill from 'global/globalComponents/Pill/Pill'
// import taskStatus from 'utils/taskStatus'
import workspaceStyles from './workspaceStyles'
import { ReactComponent as CheckIcon } from 'static/svg/check-icon.svg'
import { adjustColor } from 'utils'

const StatusDropDown = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    taskSection,
    statusList = [],
    changeStatus,
    ...rest
  } = props
  const classes = workspaceStyles()

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{ paper: classes.popoverPaper }}
      {...rest}
    >
      <div>
        <List dense classes={{ root: classes.listRoot }}>
          {statusList.length ? (
            statusList.map((item, index) => (
              <ListItem
                onClick={() => changeStatus(item)}
                key={item._id}
                dense
                className={clsx(classes.listItem, index)}
                button
              >
                <ListItemIcon style={{ minWidth: '20px' }}>
                  {item._id === taskSection ? <CheckIcon /> : null}
                </ListItemIcon>
                <ListItemText>
                  <span className="text-sm rounded py-1">
                    <Pill
                      className="text-xs"
                      style={{
                        // fontSize: '12px',
                        color: adjustColor(item.color, -70),
                        backgroundColor: item.color + '20'
                      }}
                    >
                      {item.label}
                    </Pill>
                  </span>
                </ListItemText>
                {/* <span
                style={{ backgroundColor: taskStatusColors[index] }}
                className="text-sm rounded px-2 py-1"
              >
                {item}
              </span> */}
              </ListItem>
            ))
          ) : (
            <div className="text-center py-1">
              <CircularProgress size={20} />
            </div>
          )}
        </List>
      </div>
    </Popover>
  )
}
export default StatusDropDown
