import React, { useState, useEffect } from 'react'
import staticCols from './columns'
import useReportsFunctions from '../useReportsFunctions'
import { fetchReportsView } from 'thunks/views/actions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPeoplePerformanceData } from 'thunks/reports/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import PeoplePerformanceContents from './PeoplePerformanceContents'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { GoBack } from 'global/globalComponents'

const PeoplePerformance = () => {
  const [projectCols, setProjectCols] = useState([])
  const [peoplePerformanceData, setPeoplePerformanceData] = useState([])
  const [peoplePerformanceCols, setPeoplePerformanceCols] = useState([])
  // const [loading, setLoading] = useState(true)
  const [timeline, setTimeline] = useState('')
  const { getMappedReportsData } = useReportsFunctions()
  const peoplePerformanceView = useSelector((state) => state.views.data.reports)
  const reports = useSelector((state) => state.reports)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!peoplePerformanceView._id) {
      dispatch(fetchReportsView())
    }
  }, [])

  useEffect(() => {
    dispatch(
      fetchPeoplePerformanceData(timeline, (res, err) => {
        if (err) dispatch(fireErrorToaster(res))
      })
    )
  }, [timeline])

  useEffect(() => {
    if (reports.fetched) {
      const { mappedData, projectCols } = getMappedReportsData(reports.data)
      setPeoplePerformanceData(mappedData)
      setProjectCols(Object.values(projectCols))
      setPeoplePerformanceCols(staticCols.concat(Object.values(projectCols)))
    }
  }, [reports.data, reports.fetched])

  const handleChangeTimeline = (newVAl) => {
    // setLoading(true)
    setTimeline(newVAl)
  }

  return (
    <div className="flex flex-col">
      <header className="mb-6">
        <GoBack className="mb-2" />
        <SectionHeading>People Performance</SectionHeading>
      </header>

      <PeoplePerformanceContents
        loading={reports.loading}
        cols={peoplePerformanceCols}
        data={peoplePerformanceData}
        staticCols={staticCols}
        projectCols={projectCols}
        handleChangeTimeline={handleChangeTimeline}
        peoplePerformanceView={peoplePerformanceView}
      />
    </div>
  )
}

export default PeoplePerformance
