import React, { useState, useEffect } from 'react'
import Currency from 'static/Currency/Currency'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Select from 'react-select'
import { isEqual } from 'lodash'
import clsx from 'clsx'

const InvoiceExpensesAndTotal = ({
  taxName,
  invoiceItemsTotal,
  expenses,
  handleExpensesChange,
  handleFieldChange,
  currency,
  handleCurrencyChange
}) => {
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: currency.toUpperCase(),
    value: currency.toUpperCase()
  })

  useEffect(() => {
    let arr = Object.keys(Currency).map((el) => ({
      value: el,
      label: el
    }))
    setCurrencyOptions(arr)
  }, [])

  useEffect(() => {
    setSelectedCurrency({
      label: currency.toUpperCase(),
      value: currency.toUpperCase()
    })
  }, [currency])

  const selectCurrency = (option) => {
    handleCurrencyChange(option.value)
  }

  return (
    <>
      <div>
        <div className="flex justify-between">
          <div className="text-sm font-semibold py-2">Subtotal</div>
          <div className="text-sm font-semibold py-2">
            {Currency[selectedCurrency.value].symbol_native}
            {invoiceItemsTotal.subtotal}
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-sm text-gray-500 flex py-2">
            <DivInput
              type="number"
              value={expenses.discount}
              onChange={handleExpensesChange}
              placeholder={'0'}
              name="discount"
            />
            <span>Discount</span>
          </div>
          <div className="text-sm text-gray-500 py-2">
            -{Currency[selectedCurrency.value].symbol_native}
            {expenses.discount || 0.0}
          </div>
        </div>

        <div className="flex justify-between">
          <div className="flex text-sm text-gray-500 py-2">
            <DivInput
              type="number"
              value={expenses.tax}
              onChange={handleExpensesChange}
              placeholder="0"
              name="tax"
            />
            <span>
              <span>%</span>
              <DivInput
                type="text"
                value={taxName}
                onChange={handleFieldChange}
                name="taxName"
                className="ml-1"
              />
            </span>
          </div>
          <div className="text-sm text-gray-500 py-2">{expenses.tax || 0}%</div>
        </div>
      </div>
      <hr />

      <div className="flex justify-between">
        <div className="font-bold text-xl py-4">Total</div>
        <div className="font-bold text-xl py-4">
          <div className="flex items-center space-x-1">
            <span>{invoiceItemsTotal.grandTotal}</span>

            <Select
              styles={{ ...reactSelectCustomStyles, ...SelectStyles }}
              options={currencyOptions}
              onChange={selectCurrency}
              value={selectedCurrency}
              maxMenuHeight={150}
              className="w-16 border-0 border-none"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const clrPrimary = '#2a8263'
const SelectStyles = {
  control: (base, state) => ({
    ...base,
    border: 'none',
    '&:focus-within': {
      borderColor: clrPrimary,
      boxShadow: `0 0 0 1px ${clrPrimary}`
    }
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  dropdownIndicator: (base, state) => ({ ...base, padding: 0 }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    fontSize: '12px',
    fontWeight: 500,
    backgroundColor: isSelected ? clrPrimary : isFocused ? '#c7efdc' : null,
    ':active': {
      ...provided[':active'],
      backgroundColor: '#c7efdc'
    }
  })
}

const DivInput = ({ value, spanProps, className, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false)

  const toggleFocus = () => {
    setIsFocused((prev) => !prev)
  }

  return (
    <>
      {isFocused ? (
        <input
          value={value}
          className={clsx(
            'w-10 px-1 hover:bg-gray-100 rounded border border-transparent focus:bg-gray-100  focus:border-primary-main mr-1',
            className
          )}
          {...rest}
          onBlur={toggleFocus}
          autoFocus
        />
      ) : (
        <span
          {...spanProps}
          className="pl-1 pr-2 hover:bg-gray-100 cursor-pointer rounded"
          onClick={toggleFocus}
        >
          {!value ? 0 : value}
        </span>
      )}
    </>
  )
}

const checkEquality = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.expenses, nextProps.expenses) &&
    isEqual(prevProps.invoiceItemsTotal, nextProps.invoiceItemsTotal) &&
    prevProps.taxName === nextProps.taxName
  )
}

export default React.memo(InvoiceExpensesAndTotal, checkEquality)
