import axios from 'axios'
import { USERPERMISSION } from './reducers'
import { errorMessages } from 'utils'

export const fetchUserPermissions = (agencyId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/permission/user/agency/${agencyId}`
      })
      // dispatch({ type: USERPERMISSION.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({ type: USERPERMISSION.ERROR })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateUserPermissions = (data, agencyId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/permission/user/agency/${agencyId}`,
        data
      })
      // dispatch({ type: USERPERMISSION.UPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({ type: USERPERMISSION.ERROR })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
