import { makeStyles, createStyles } from '@material-ui/core'

const dashboardStyles = makeStyles(theme =>
  createStyles({
    bodyContainer: {
      display: 'flex',
      background: '#f9f8f8'
      // paddingTop: theme.custom.header.height
    },
    contentArea: {
      backgroundColor: '#f9f8f8',
      width: '100%',
      marginTop: theme.custom.header.height,
      minHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      // padding: theme.spacing(2),
      paddingBottom: 5,
      transition: theme.transitions.create(['margin', 'width'], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeInOut
      }),
      '&.sidebarExpanded': {
        transition: theme.transitions.create(['margin', 'width'], {
          duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: theme.custom.sidebar.width,
        width: `calc(100% - ${theme.custom.sidebar.width}px)`
      }
    }
  })
)

export default dashboardStyles
