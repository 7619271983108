import { makeStyles, createStyles } from '@material-ui/core'

const BoardsStyles = makeStyles(theme =>
  createStyles({
    // categoriesContainer: {
    //   '&>div': {
    //     display: 'flex',
    //     gap: theme.spacing(1.2),
    //     maxWidth: '100%',
    //     overflowX: 'scroll',
    //     minHeight: `calc(100vh - 171px)`
    //   }
    // },

    popper: {
      fontSize: 18,
      margin: '10px',
      cursor: 'pointer'
    },
    addNewCategoryBtn: {
      width: '278px',
      minWidth: '180px',
      height: '40px',
      color: 'gray',
      '& svg': {
        color: 'gray'
      }
    },
    loderWrapper: {
      display: 'flex',
      flexDirection: 'row',
      '&>span': {
        flex: '0 0 304px'
      }
    },

    columnContainer: {
      // margin: theme.spacing(0.5),
      // borderRadius: 6,
      flex: `0 0 ${theme.custom.taskCard.width}`
      // backgroundColor: 'transparent',
      // padding: '0 8px 0 0',
      // display: 'flex',
      // flexDirection: 'column',
      // border: '1px solid transparent'
    },
    columnHeaderContainer: {
      // maxWidth: 285,
      minWidth: '100%'
      // boxShadow: '0 1px 2px 0 rgb(0 0 0 / 30%)',
      // borderTopWidth: 3
    },
    // addMoreTask: {
    //   fontSize: 13,
    //   color: '#95929a',
    //   cursor: 'pointer',
    //   justifyContent: 'flex-start',
    //   textTransform: 'uppercase',
    //   border: 'none',
    //   background: 'transparent',
    //   width: 'calc(100% - 7px)',
    //   marginTop: 8,
    //   '& svg': {
    //     fill: '#6f7782'
    //   },

    //   '&:hover': {
    //     background: ' transparent',
    //     color: '#1600e5',
    //     '& svg': {
    //       fill: '#1600e5'
    //     }
    //   }
    // },
    // topIconContainer: {
    //   cursor: 'pointer',
    //   height: 28,
    //   width: 28,
    //   minHeight: 28,
    //   minWidth: 28,
    //   borderRadius: 6,
    //   // marginLeft: 4,
    //   padding: 4,
    //   display: 'inline-flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   '&>svg': {
    //     fill: '#6f7782',
    //     fontSize: 22
    //   },
    //   '&:hover': {
    //     backgroundColor: 'rgba(21,27,38,.04)',
    //     '&>svg': {
    //       fill: '#151b26'
    //     }
    //   },
    //   '&:focus': {
    //     backgroundColor: 'rgba(21,27,38,.08)'
    //   }
    // },
    dragTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      transition: '0.25s margin',
      marginLeft: -24,
      gap: 4,
      '&>svg': {
        transform: 'scale(0)',
        width: 24,
        height: 24,
        color: 'gray'
      },
      '&:hover': {
        marginLeft: 0,
        '&>svg': {
          transform: 'scale(1)'
        }
      },
      '& h3': {
        cursor: 'pointer'
      }
    },
    taskCardsContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingBottom: '4rem',
      borderRadius: 3,
      transition: 'background-color 0.18s ease 0s',
      minHeight: '100px',
      width: '100%',
      minWidth: `calc(${theme.custom.taskCard.width}px + 1rem)`, // card width + margin right
      height: `calc(100vh - 260px)`, //picking 260 static value
      paddingRight: 2,
      '&::-webkit-scrollbar-track': {
        background: '#E2E2E2'
      },
      '&.emtpyColumn': {
        width: '100%',
        paddingRight: 0,
        overflowY: 'hidden'
      },

      '&:hover::-webkit-scrollbar-thumb': {
        background: '#ACACAC'
      }
    },

    paper: {
      position: 'relative',
      width: '80vw',
      borderRadius: 8
    },

    taskCardContainer: {
      // cursor: 'pointer !important',
      maxWidth: theme.custom.taskCard.width,
      minWidth: theme.custom.taskCard.width,
      marginBottom: theme.spacing(2),
      boxShadow: '0px 1.8008px 5.40239px rgba(0, 0, 0, 0.05)',
      marginRight: 16
      // '&:hover': {
      //   // boxShadow: '0 1px 2px 0 rgb(0 0 0 / 40%)',
      //   // borderColor: '#cbd4db',
      //   '& $actionIconsContainer': {
      //     visibility: 'visible'
      //   },
      //   '& $iconFooter': {
      //     display: 'block'
      //   },
      //   '& $addAssignee': {
      //     opacity: 1
      //   }
      // }
    },

    // addAssignee: {
    //   transition: 'opacity 150ms',
    //   outline: 'none',
    //   '&:focus, &:hover': {
    //     opacity: 1
    //   }
    // },

    actionIconsContainer: {
      border: '1px dashed gray',
      borderRadius: '50%',
      padding: 4,
      height: 24,
      width: 24,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      display: 'flex',
      visibility: 'visible',
      '&>svg': {
        height: 14,
        width: 14,
        color: 'gray'
      }
    },
    outlinedIcon: {
      border: '1px dashed gray',
      borderRadius: '50%',
      padding: 4,
      height: 24,
      width: 24,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      display: 'flex',
      '&>svg': {
        height: 14,
        width: 14,
        color: 'gray'
      }
    },
    colorsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(0.8),
      paddingBottom: 16,
      '&>label': {
        width: 20,
        height: 20,
        backgroundColor: '#fff',
        cursor: 'pointer',
        borderRadius: theme.shape.borderRadius
      }
    },
    iconsPopoverPaper: {
      boxShadow:
        '0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%)',
      maxWidth: '220px',
      height: 'auto',
      padding: theme.spacing(1)
    },
    btnAdd: {
      marginLeft: '-0.25rem',
      borderStyle: 'dashed',
      width: '22px',
      height: '22px'
    },
    dateContainer: {
      color: '#252631',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.custom.gray.light
      }
    },
    avatarRoot: {
      '& > div': {
        width: '24px',
        height: '24px',
        fontSize: 10,
        textTransform: 'uppercase'
      }
    },
    taskAssigneeRoot: {
      '& > div': {
        width: '26px',
        height: '26px',
        fontSize: 10,
        textTransform: 'uppercase'
      }
    },

    cardRoot: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
      cursor: 'pointer',
      overflow: 'visible'
    },
    footerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'sapce-between',
      '&> div:first-child': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '&> div': {
          height: '17px',
          width: '17px',
          cursor: 'pointer',
          borderRadius: '50%',
          border: '1px #6f6f6f59 dashed',
          padding: '4px',
          fontSize: '11px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff',
          color: 'gray',
          '&:hover': {
            borderStyle: 'solid'
          },
          '&>svg': {
            color: 'gray',
            fontSize: '14px'
          }
        }
      }
    },
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },

    // cardContainer: {
    //   width: '50px',
    //   minWidth: '280px',
    //   height: `calc(100vh - 200px)`,
    //   paddingBottom: 15,
    //   overflow: 'hidden',
    //   paddingRight: 3,
    //   overflowY: 'scroll',
    //   // backgroundColor: 'red',
    //   backgroundColor: 'transparent',
    //   //backgroundImage: "linear-gradient(to bottom, #dddddd  , #eef1f2)",
    //   // padding: theme.spacing(1)
    //   '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: 'transparent'
    //   },
    //   '&:hover::-webkit-scrollbar-thumb': {
    //     backgroundColor: '#babac0'
    //   }
    // },
    boardHeaderContianer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#fff',
      padding: '9.6px 10px',
      marginRight: 9,
      borderRadius: '4px',
      borderTop: '2px solid rgb(211, 211, 211)',
      color: '#454545',
      '&:hover': {
        '& $dragIcon': {
          display: 'block'
        }
      },
      '& span': {
        height: 19,
        lineHeight: '13px'
      }
      // padding: '8px 0'
    },
    categoryNameContainer: {
      cursor: 'pointer',
      width: '100%'
    },
    title: {
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '25px'
    },
    textFieldStyle: {
      '& input': {
        fontSize: 13,
        fontWeight: 600,
        padding: '4.69px'
      }
    },
    addButton: {
      fontSize: 13,
      color: '#95929a',
      cursor: 'pointer',
      textTransform: 'uppercase',
      '&:hover': {
        background: 'transparent',
        color: '#3f51b5'
      }
    },
    headerIcons: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '& >svg': {
        fontSize: '1rem',
        cursor: 'pointer'
      }
    },
    dragIcon: {
      color: 'gray',
      height: 25,
      width: 20,
      display: 'none',
      cursor: 'grab'
    },

    drawerPaper: {
      width: '40vw',
      marginTop: theme.custom.header.height
    },
    toolbarRoot: {
      backgroundColor: '#fff',
      borderBottom: 'solid 1px rgba(9,30,66,.12)',
      // display: 'block',
      height: '40px',
      // padding: '12px 16px 0',
      width: '39vw',
      position: 'fixed',
      minHeight: '50px',
      zIndex: theme.zIndex.appBar,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    headerTextField: {
      borderColor: 'transparent',
      border: 'none',
      width: '100%',
      '&:hover': {}
    },
    notchedOutlineStyle: {
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      }
    },
    headerInputStyle: {
      fontSize: '22px'
    },
    iconsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '&>button': {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1)
      }
    },
    fieldLabel: {
      fontSize: '13px',
      color: 'gray'
    },
    inputFieldButton: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      width: 'fit-content',
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      // border:'1px solid gray',
      cursor: 'pointer',
      fontSize: '12px',

      '&>p': {
        fontSize: '12px',
        userSelect: 'none'
      },
      '&>svg': {
        fontSize: '16px',
        userSelect: 'none',
        color: 'gray'
      },
      '&:hover': {
        backgroundColor: '#f6f6f6'
      }
    },
    subtaskRowContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      // margin:theme.spacing(2,0),
      padding: theme.spacing(1, 0),
      '&>p': {
        width: '90%',
        fontSize: '14px',
        color: '#222'
      }
    },
    subtaskCheckIcon: {
      borderRadius: '50%',
      border: '1px solid gray',
      color: 'gray',
      cursor: 'pointer',
      width: '16px',
      height: '16px',
      padding: '2px',
      boxSizing: 'border-box',
      // backgroundColor:theme.palette.success.main,
      '&.checked': {
        backgroundColor: theme.palette.success.main,
        color: '#fff',
        border: `1px solid ${theme.palette.success.main}`
      }
    },
    subtaskIconsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      '&>*': {
        height: '20px',
        width: '20px',
        padding: '4px',
        cursor: 'pointer',
        borderRadius: '50%',
        border: '1px dashed transparent',
        color: 'gray',
        '&:hover': {
          border: '1px dashed lightgray'
        }
      }
    },
    imageContianer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1)
    },
    attachmentDeleteBtn: {
      display: 'none',
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'rotate(45deg)'
    },
    attachmentImageWrapper: {
      cursor: 'pointer',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      width: 45,
      height: 'auto',
      maxHeight: 50,
      '&>img': {
        width: 45,
        height: 'auto',
        maxHeight: 50
      },
      '&:hover': {
        '& $attachmentDeleteBtn': {
          display: 'block'
        }
      }
    },
    editorStyle: {
      zIndex: 3000
    },
    icons: {
      color: '#6f7782',
      transition: '0.2s transform',
      '&.expanded': {
        transform: 'rotate(180deg)'
      }
    },
    assignTag: {
      position: 'relative',
      padding: '3px 10px',
      borderRadius: '10px',
      fontSize: '12px',
      '&:hover .cross-btn': {
        opacity: 1
      }
    },
    crossBtn: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      color: 'rgba(255, 0, 0, 0.75)',
      opacity: 0,
      transition: 'opacity 150ms ease-in-out'
    },
    iconFooter: {
      borderTop: '0.5px solid #f8f8f8',
      paddingTop: '8px',
      display: 'none',
      '&.expanded': {
        display: 'block'
      }
    },
    taskCardAssignee: {
      '& button': {
        opacity: 0,
        position: 'absolute',
        top: -5,
        right: -5,
        transition: 'opacity 100ms ease'
      },
      '&:hover button': {
        opacity: 1
      }
    }
  })
)

export default BoardsStyles
