import React, { useContext, useState } from 'react'
import { SingleWorkspaceContext, WORKSPACE } from './singleWorkspaceContext'
import { ReactComponent as LayerGroup } from 'static/svg/layer-group.svg'
import { ReactComponent as SortCircle } from 'static/svg/sort-circle.svg'
import { ReactComponent as Calender } from 'static/svg/calendar.svg'
import { ReactComponent as CheckIcon } from 'static/svg/check-icon.svg'
import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import {
  Popover,
  // List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import { OutlinedButton } from 'global/globalComponents'

const GroupByPopup = () => {
  const {
    workspaceState: { groupBy, defaultGroup },
    workspaceDispatch
  } = useContext(SingleWorkspaceContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuItems, setMenuItems] = useState([
    {
      label: 'Status',
      icon: <SortCircle />,
      value: 'status',
      method: () => handleListItemClick('status')
    },
    {
      label: 'Due Date',
      icon: <Calender />,
      value: 'dueDate',
      method: () => handleListItemClick('dueDate')
    },
    {
      label: 'Assignee',
      icon: <UserIcon />,
      value: 'assignee',
      method: () => handleListItemClick('assignee')
    }
  ])

  const handleTogglePopover = (e) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleListItemClick = (value) => {
    workspaceDispatch({ type: WORKSPACE.UPDATE_GROUP_BY, payload: value })
    setAnchorEl(null)
  }

  return (
    <div>
      <OutlinedButton onClick={handleTogglePopover} isActive={true}>
        <LayerGroup />
        <span className="ml-1">Group by: {groupByLabels[groupBy]}</span>
      </OutlinedButton>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleTogglePopover}
      >
        {menuItems.map((item, index) => (
          <ListItem key={index} button onClick={item.method}>
            <ListItemAvatar style={{ minWidth: 'initial', marginRight: 6 }}>
              {item.icon}
            </ListItemAvatar>
            <ListItemText>
              <div className="text-xs">
                {item.label}{' '}
                {item.value === defaultGroup && (
                  <span className="text-gray-500">(default)</span>
                )}
                {item.value === groupBy && (
                  <CheckIcon className="ml-4 inline-block" />
                )}
              </div>
            </ListItemText>
          </ListItem>
        ))}
      </Popover>
    </div>
  )
}

const groupByLabels = {
  status: 'Status',
  dueDate: 'Due Date',
  assignee: 'Assignee'
}

export default GroupByPopup
