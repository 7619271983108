import { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import { ColoredAvatars } from 'global/globalComponents'
import React from 'react'
import clsx from 'clsx'
import { useEffect } from 'react'
import Currency from 'static/Currency/Currency'

export const InvoiceFile = ({ invoice, readOnly = false }) => {
  const classes = useStyles()
  const [subtotal, setSubtotal] = useState(0)
  const selectedCurrency = invoice.currency
  const agency = invoice.agency ?? {}
  const client = invoice.client ?? {}

  useEffect(() => {
    if (invoice.invoiceItems && invoice.invoiceItems.length) {
      let subtotal = 0

      for (let item of invoice.invoiceItems) {
        subtotal += item.total
      }

      setSubtotal(subtotal)
    }
  }, [invoice])

  return (
    <div className="invoice-print bg-white border border-gray-300 shadow p-4 rounded flex-1">
      <div className="text-right text-sm text-gray-600">
        <div className="mb-4">
          <span>INVOICE #</span>&nbsp;&nbsp;
          <span className="font-medium">
            {invoice.customInvoiceNumber ?? invoice.invoiceNumber}
          </span>
        </div>
        {invoice.status === 'paid' && (
          <div className="inline-block px-10 py-2 border border-primary-main rounded text-primary-main tracking-widest mt-2 origin-bottom-left rotate-45 opacity-60">
            PAID
          </div>
        )}
      </div>
      <header className="flex flex-col items-center text-center">
        <div className="py-6 flex flex-col items-center">
          <div className="mb-2">
            {agency.agencyLogo?.smallLogo ? (
              <img src={agency.agencyLogo.smallLogo} alt="" className="w-32" />
            ) : (
              <ColoredAvatars
                user={{ name: agency.agencyCompanyName || 'Unknown' }}
                size="extraLarge"
                tooltip={false}
              />
            )}
          </div>
          <h4 className="font-semibold text-xl">Invoice</h4>
          <h5 className="text-xs text-gray-500">{invoice.invoiceTitle}</h5>
        </div>
      </header>
      <hr />
      <div className="px-8">
        {agency && (
          <div className="mb-8">
            <div className="flex space-x-6 py-8">
              {/* ------ From ------- */}
              <div className="flex-1">
                <h6 className="mb-2 text-gray-500">From</h6>
                <div>
                  <div className="mb-2">
                    <div>{agency.agencyName}</div>
                  </div>

                  <div className="mb-2 text-sm">
                    <div>{agency.agencyCompanyName}</div>
                  </div>
                  <div className="mb-2 text-xs text-gray-500">
                    <div>{agency.agencyAddress}</div>
                  </div>

                  {!readOnly && (
                    <div className="mb-2 text-xs text-primary-main">
                      {agency.agencyEmail}
                    </div>
                  )}
                </div>
              </div>

              {/* ------ To ------- */}
              <div className="flex-1">
                <h6 className="mb-2 text-gray-500">To</h6>
                {client.clientId ? (
                  <div>
                    <div className="mb-2">
                      <div>{client.clientName}</div>
                    </div>

                    <div className="mb-2 text-sm">
                      <div>{client.clientCompanyName}</div>
                    </div>

                    <div className="mb-2 text-xs text-gray-500">
                      {client.clientAddress}
                    </div>

                    <div className="text-primary-main mb-2 text-xs">
                      {!readOnly && (
                        <div className="mb-2">
                          <div>{client.clientEmail}</div>
                        </div>
                      )}

                      <div>
                        {client.client_cc_email?.map((item, index) => (
                          <div key={item.id}>{item}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <span className="text-md ">No client is selected</span>
                )}
              </div>
            </div>

            <div className="flex space-x-6">
              <div className="flex-1 mb-2 mt-auto">
                <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500">
                  Issued On
                </h6>

                <div className="text-sm">
                  {format(new Date(invoice.issuedDate), 'MMM d, yyyy')}
                </div>
              </div>

              <div className="flex-1">
                <div className="mb-2">
                  <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500">
                    Due On
                  </h6>
                  <div className="text-sm">
                    {format(new Date(invoice.dueDate), 'MMM d, yyyy')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <div className={clsx(classes.invoicePricingTable, 'mb-2')}>
            <div className="uppercase text-xs">Item name</div>
            <div className="uppercase text-right text-xs">Units</div>
            <div className="uppercase text-right text-xs">Rate</div>
            <div className="uppercase text-right text-xs">Total</div>
          </div>
          {invoice.invoiceItems.map((item, index) => (
            <div
              key={index}
              className={clsx(
                classes.invoicePricingTable,
                'border-b py-4 mb-2 relative'
              )}
            >
              <div className="text-sm py-1">{item.name}</div>
              <div className="text-sm py-1 text-right">{item.quantity}</div>
              <div className="text-sm py-1 text-right">{item.rate}</div>

              <div className="text-right">
                <span>{Currency[selectedCurrency].symbol_native}</span>
                <span>{item.total || 0}</span>
              </div>
            </div>
          ))}

          <div>
            <div className="flex justify-between">
              <div className="text-sm font-semibold py-2">Subtotal</div>
              <div className="text-sm font-semibold py-2">
                {Currency[selectedCurrency].symbol_native}
                {subtotal}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-sm text-gray-500">Discount</div>
              <div className="text-sm text-gray-500 py-2">
                -{Currency[selectedCurrency].symbol_native}
                {invoice.discountAmount || 0}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-sm text-gray-500 capitalize">
                {invoice.taxName}
              </div>
              <div className="text-sm text-gray-500 py-2">
                {invoice.taxPercent || 0}%
              </div>
            </div>
          </div>
          <hr />

          <div className="flex justify-between">
            <div className="font-bold text-xl py-4">Total</div>
            <div className="font-bold text-xl py-4">
              {Currency[selectedCurrency].symbol_native}
              {invoice.totalAmount}
            </div>
          </div>
        </div>

        <div className="mt-16 mb-16">
          {invoice.taxDetail && invoice.taxDetail[0] && (
            <div className="mb-6">
              <h6 className="text-gray-400 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
                Tax Info
              </h6>
              <p className="text-sm text-gray-700">{invoice.taxDetail}</p>
            </div>
          )}

          {invoice.notes && (
            <div className="mb-6">
              <h6 className="text-gray-400 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
                Notes
              </h6>

              {/* <p className="text-sm text-gray-700">{invoice.notes}</p> */}
              <div className="">
                {invoice.notes.split('\n').map((line) => (
                  <p className="text-sm text-gray-700 py-0.5">{line}</p>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  invoicePricingTable: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '1rem',
    alignItems: 'center'
  },
  paidBadge: {
    transform: 'rotate(40deg)',
    transformOrigin: 'bottom left'
  }
})

export default InvoiceFile
