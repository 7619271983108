import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const sizeStyles = {
  tiny: {
    padding: '2px 10px',
    fontSize: '12px'
  },
  small: {
    padding: '5px 10px',
    fontSize: '12px'
  },

  medium: {
    padding: '8px 18px',
    fontSize: '14px'
  }
}

const useStyles = makeStyles((theme) => ({
  input: ({ size }) => ({
    borderRadius: '100vh',
    backgroundColor: '#fff',
    border: '1px solid hsl(0,0%,80%)',
    transition: '0.3s',
    width: '100%',
    // height: '40px',
    display: 'flex',
    justifyContent: 'center',
    ...sizeStyles[size]
  }),
  focus: () => ({
    border: `1px solid ${theme.palette.primary.main}`
  })
}))

const TextInput = (props) => {
  const { onChange, type, size = 'medium' } = props
  const classes = useStyles({ size })

  return (
    <InputBase
      {...props}
      classes={{ focused: classes.focus, root: classes.input }}
      type={type ? type : 'text'}
      onChange={(e) => onChange(e)}
    />
  )
}

export default TextInput
