// import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import TaskCollaborators from './TaskCollaborators'
import taskSidePanelStyles from './taskSidePanelStyles'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import FieldLabel from './FieldLabel'
import clsx from 'clsx'
import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
import { setHours } from 'date-fns'
import { ReactComponent as TagsIcon } from 'static/svg/tags.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { ReactComponent as ClockIcon } from 'static/svg/clock.svg'
import { useMemo } from 'react'
// import { useMemo } from 'react'

const TaskBody = ({
  taskState,
  triggerPopover,
  taskPermission,
  handleDateChange,
  elmFor,
  assignedTags = [],
  setTagsPopoverData,
  updateProjectStats,
  addCollaborator,
  removeCollaborator,
  type = 'task'
}) => {
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const hasPassedDueDate = useMemo(() => {
    if (taskState.markAsComplete) return false
    if (taskState.dueDate && new Date(taskState.dueDate) < new Date())
      return true
  }, [taskState.dueDate, taskState.markAsComplete])
  const classes = taskSidePanelStyles()

  return (
    <div className={clsx(classes.taskBodyContainer, 'px-6 mb-4')}>
      <RowContainer>
        <div>
          <FieldLabel>Collaborators</FieldLabel>
        </div>
        <div>
          <TaskCollaborators
            taskState={taskState}
            currentWorkspace={currentWorkspace}
            triggerPopover={triggerPopover}
            taskPermission={taskPermission}
            elmFor={elmFor}
            updateProjectStats={updateProjectStats}
            addCollaborator={addCollaborator}
            removeCollaborator={removeCollaborator}
            type={type}
          />
        </div>
      </RowContainer>
      {elmFor === 'task' && (
        <RowContainer>
          <div>
            <FieldLabel>Tags</FieldLabel>
          </div>
          <div className="flex space-x-2 items-center">
            {Boolean(assignedTags.length) && (
              <AssignedTags
                assignedTag={assignedTags}
                taskId={taskState?._id}
                elmFor="task"
                setTagsPopoverData={setTagsPopoverData}
                tagIcon={true}
                className="flex-wrap"
              />
            )}
            <button
              // className={classes.outlinedIcon}
              onClick={e => {
                setTagsPopoverData({ open: true, anchorEl: e.currentTarget })
              }}
              className="w-6 h-6 border border-dashed flex justify-center items-center text-base rounded-full text-gray-400"
            >
              <TagsIcon />
            </button>
          </div>
        </RowContainer>
      )}
      <RowContainer>
        <div>
          <FieldLabel>Start Date</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div className={classes.pseudoDropDownContainer}>
            <DatePicker
              selected={
                taskState.startDate
                  ? new Date(taskState.startDate)
                  : setHours(new Date(), new Date().getHours())
              }
              onChange={date => {
                handleDateChange(date, 'startDate')
              }}
              disabled={currentWorkspace?.isComplete || !taskPermission.update}
              customInput={
                taskState.startDate ? (
                  <input
                    className="text-xs"
                    style={{ width: '180px', padding: '4px 0' }}
                  />
                ) : (
                  <CalenderIcon className="text-base text-gray-600" />
                )
              }
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              // minTime={setMinutes(new Date(), 0)}
              // maxTime={setHours(setMinutes(new Date(), 45), 23)}
              // maxTime={
              //   taskState.dueDate
              //     ? new Date(taskState.dueDate.toString())
              //     : null
              // }
              // minDate={new Date(taskState.createdAt)}
              // maxDate={taskState.dueDate ? new Date(taskState.dueDate) : null}
              showDisabledMonthNavigation
            />
          </div>
        </div>
      </RowContainer>
      <RowContainer>
        <div>
          <FieldLabel>Due Date</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div className={classes.pseudoDropDownContainer}>
            <div>
              <DatePicker
                selected={
                  taskState.dueDate
                    ? new Date(taskState.dueDate)
                    : setHours(new Date(), new Date().getHours())
                }
                disabled={
                  currentWorkspace?.isComplete || !taskPermission.update
                }
                onChange={date => {
                  handleDateChange(date, 'dueDate')
                }}
                showTimeSelect
                customInput={
                  taskState.dueDate ? (
                    <input
                      className={clsx(
                        hasPassedDueDate && 'text-red-400',
                        'text-xs'
                      )}
                      style={{ width: '180px', padding: '4px 0' }}
                    />
                  ) : (
                    <CalenderIcon className="text-base text-gray-600" />
                  )
                }
                // minDate={
                //   taskState.startDate
                //     ? new Date(taskState.startDate)
                //     : new Date()
                // }
                placeholderText="Select Date"
                dateFormat="MMM d, yyyy h:mm aa"
                // minTime={
                //   taskState.startDate
                //     ? new Date(taskState.startDate)
                //     : new Date()
                // }
                // maxTime={new Date()}
                popperPlacement={elmFor === 'task' ? 'bottom-end' : undefined}
              />
            </div>
          </div>
        </div>
      </RowContainer>
      {/* {approvalStatusString && (
        <RowContainer>
          <div>
            <FieldLabel>Approval Status</FieldLabel>
          </div>

          <ApprovalBadge
            status={approvalStatusString}
            openApprovalPanel={openApprovalPanel}
          />
        </RowContainer>
      )} */}
    </div>
  )
}

const ApprovalBadge = ({ status, openApprovalPanel }) => {
  return (
    <div
      className={clsx(
        'px-2 py-2 rounded cursor-pointer',
        status === 'approved'
          ? 'bg-green-50'
          : status === 'rejected'
          ? 'bg-red-50'
          : 'bg-primary-light'
      )}
      onClick={openApprovalPanel}
    >
      <div className="text-smaller flex items-center">
        <span className="mr-2">
          {status === 'approved' ? (
            <CheckCircleIcon className="w-4 h-4 text-green-400" />
          ) : status === 'rejected' ? (
            <CrossIcon className="w-4 h-4 text-red-400" />
          ) : (
            <ClockIcon className="w-4 h-4 text-primary-main" />
          )}
        </span>
        <span className="capitalize font-medium text-xs">
          {status === 'under_approval' ? 'pending' : status}
        </span>
      </div>
    </div>
  )
}

export const RowContainer = props => {
  const { children, ...restProps } = props
  return (
    <div {...restProps} className="flex items-center space-x-4 leading-3">
      {children}
    </div>
  )
}

export default TaskBody
