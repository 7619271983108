import React, { useState, useEffect } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'
import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { getUserPath } from 'utils/userRoles'
import { useSelector } from 'react-redux'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as PeopleIcon } from 'static/svg/people.svg'
import { userRoles } from 'utils'
import Workload from './WorkloadView/Workload'
import Timesheets from './Timesheets/Timesheets'
import { format } from 'date-fns'
// import SidebarModulePermissions from 'components/Hoc/SidebarModulePermissions'

const ResourceManagement = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const meData = useSelector(state => state.me.data)
  const [selectedTab, setSelectedTab] = useState(pathname)
  const [newDate, setNewDate] = useState(new Date())

  const tabsData = [
    {
      label: 'Timesheets',
      value: path + `/timesheets/${format(new Date(newDate), 'yyyy-MM-dd')}`,
      icon: <StopWatchIcon />,
      allowedRoles: role =>
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_EDITOR,
          userRoles.AGENCY_VIEWER
        ].includes(role)
    },
    {
      label: [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
        meData.role
      )
        ? 'Resource Management'
        : 'My Workload',
      value: path,
      icon: <PeopleIcon />,
      allowedRoles: role =>
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_EDITOR,
          userRoles.AGENCY_VIEWER
        ].includes(role)
    }
  ]

  useEffect(() => {
    setSelectedTab(pathname)

    if (pathname.includes('timesheets/')) {
      setNewDate(pathname.split('timesheets/')[1])
    }
  }, [pathname])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)

    history.push(newVal)
  }

  return (
    <OuterContentWrapper>
      <SectionHeading className="mb-3 lg:mb-4">Timesheets</SectionHeading>

      <div className="mb-6 lg:mb-8">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          {tabsData
            .filter(tab => tab.allowedRoles(meData.role))
            .map((item, index) => (
              <Tab
                label={
                  <div className="flex items-center gap-1">
                    <span>{item.icon}</span> <span>{item.label}</span>
                  </div>
                }
                key={index}
                value={item.value}
              />
            ))}
        </Tabs>

        {/* <div style={{ border: '1px solid #ECEBF5' }}></div> */}
      </div>
      <Switch>
        <Route exact path={`${getUserPath(meData?.role)}/resource-management`}>
          <Workload />
        </Route>
        <Route
          path={`${getUserPath(
            meData?.role
          )}/resource-management/timesheets/:time`}
          component={Timesheets}
        />
      </Switch>
    </OuterContentWrapper>
  )
}

export default ResourceManagement

// export default SidebarModulePermissions(ResourceManagement, 'timesheet')
