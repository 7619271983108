export const ACCOUNTLOGS = {
  FETCHED: 'ACCOUNTLOGS_FETCHED',
  LOADING: 'ACCOUNTLOGS_LOADING',
  ERROR: 'ACCOUNTLOGS_ERROR',
  UPDATE: 'ACCOUNTLOGS_UPDATE',
  POSTED: 'ACCOUNTLOGS_POSTED'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  fetched: false
}

const accountLogs = (state = initialState, action) => {
  // const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case ACCOUNTLOGS.LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }
    case ACCOUNTLOGS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case ACCOUNTLOGS.FETCHED:
      const Data = [
        ...new Map(
          [...state.data, ...action.payload].map((item) => [item._id, item])
        ).values()
      ].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      return {
        ...state,
        loading: false,
        error: false,
        data: Data,
        fetched: true
      }
    case ACCOUNTLOGS.UPDATE:
      const updatedData = [...state.data].map((el) => {
        if (action.payload.data.includes(el._id)) {
          el.readBy = [...el.readBy, action.payload.userId]
          return el
        } else {
          return el
        }
      })
      return {
        ...state,
        loading: false,
        error: false,
        data: updatedData
      }
    case ACCOUNTLOGS.POSTED:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data]
      }
    default:
      return state
  }
}

export default accountLogs
