import { makeStyles, createStyles } from '@material-ui/core'

const clientSignupStyles = makeStyles((theme) =>
  createStyles({
    intelInput: {
      backgroundColor: 'transparent',
      border: '1px solid hsl(0,0%,80%)'
    }
  })
)

export default clientSignupStyles
