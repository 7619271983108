import { Skeleton } from '@material-ui/lab'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalFooter,
  CustomModalBody
} from 'global/globalComponents/CustomModal'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'
import { DragDropContext } from 'react-beautiful-dnd'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCalendar,
  createNewCalendar,
  deleteCalendar,
  fetchCalendarEvents,
  fetchProjectsEvents,
  updateCalendarEvents
} from 'thunks/calender/action'
import AddIcon from '@material-ui/icons/Add'
import { CustomTooltip } from 'global/globalComponents'
import EventCreatePopover from './EventCreatePopper'
import { userRoles } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import EventHandlePopover from './EventHandlePopper'
import CustomCalendarToolbar from './CustomCalendarToolbar'
import { Tabs, Tab } from '@material-ui/core'
import { getPrioritizedTask } from 'thunks/task/actions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar)

const days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 }

const CalendarModal = ({ open, handleClose }) => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const calendar = useSelector(state => state.calendar)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const priorityTasks = useSelector(state => state.userTasks.priorityTasks)
  const [createEventAnchorEl, setCreateEventAnchorEl] = useState({
    anchorEl: null,
    data: {}
  })
  const [editEventAnchorEl, setEditEventAnchorEl] = useState({
    anchorEl: null,
    data: {}
  })
  const [loading, setLoading] = useState(false)
  const [currentCalendar, setCurrentCalendar] = useState({ _id: '' })
  const [events, setEvents] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedView, setSelectedView] = useState('month')
  const [selectedTab, setSelectedTab] = useState('personal')
  const [filteredPriorityTasks, setFilteredPriorityTask] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [createEventPermission, setCreateEventPermission] = useState(false)
  // const [eventModal, setEventModal] = useState({
  //   open: false,
  //   data: {},
  //   top: '',
  //   left: ''
  // })

  const agencyOffDays = useMemo(() => {
    const workingDays =
      meData.profile?.workingDays ?? meData.team?.profile?.workingDays
    if (workingDays) {
      return Object.keys(workingDays)
        .filter(item => !workingDays[item])
        .map(item => days[item])
    } else {
      return [0]
    }
  }, [meData.profile?.workingDays, meData.team?.profile?.workingDays])

  const eventPropGetter = event => {
    return {
      style: {
        backgroundColor: event.color ? event.color : 'transparent',
        color: event.color ? '#fff' : 'black',
        fontSize: '11.5px',
        fontWeight: 500,
        padding: '1px 6px'
      }
    }
  }

  const slotGroupPropGetter = useCallback(
    () => ({
      style: {
        // minHeight: 60,
        fontSize: '12px'
      }
    }),
    []
  )

  const calendarStyle = date => {
    return {
      style: {
        backgroundColor: agencyOffDays.includes(new Date(date).getDay())
          ? // && new Date().getMonth() === new Date(date).getMonth()
            '#ffffea'
          : '',
        fontSize: '12px'
      }
    }
  }

  useState(() => {
    if (
      currentCalendar.type === 'agency' &&
      [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
    ) {
      setCreateEventPermission(true)
    } else {
      setCreateEventPermission(false)
    }
  }, [currentCalendar?._id])

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      fetchAllWorkspacesForCurrentUser()
    }
  }, [userWorkspaces.fetchedFirstTime])

  useEffect(() => {
    if (!priorityTasks.fetched) {
      dispatch(getPrioritizedTask())
    }
  }, [priorityTasks.fetched])

  useEffect(() => {
    setFilteredPriorityTask(priorityTasks.data.filter(task => task.dueDate))
  }, [priorityTasks.data])

  useEffect(() => {
    setFilteredProjects(userWorkspaces.data.filter(project => project.dueDate))
  }, [userWorkspaces.data])

  useEffect(() => {
    if (calendar.fetched) {
      if (
        [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
      ) {
        const cal = calendar.data.find(el => el.type === 'agency')
        if (!cal) {
          dispatch(
            createNewCalendar({
              title: 'Working Days',
              type: 'agency'
            })
          )
        }
      }
      const cal = calendar.data.find(el => el.type === 'personal')
      if (!cal) {
        dispatch(
          createNewCalendar({
            title: 'Working Days',
            type: 'personal'
          })
        )
      }
    }
  }, [calendar.fetched])

  useEffect(() => {
    if (calendar.data?.length) {
      const cal = calendar.data.find(el => el.type === selectedTab)
      if (cal) {
        setCurrentCalendar(cal)
      } else {
        setCurrentCalendar({ _id: '' })
      }
    }
  }, [calendar.data, selectedTab])

  useEffect(() => {
    if (currentCalendar.type === 'agency') {
      setEvents([...calendar.events, ...calendar.projects])
    }
  }, [calendar.events, calendar.projects, currentCalendar])

  useEffect(() => {
    if (currentCalendar._id && selectedView === 'month') {
      const start = moment(selectedDate).startOf('month').toISOString()
      const end = moment(selectedDate).endOf('month').toISOString()
      const query = `?startDate=${start}&endDate=${end}`
      if (currentCalendar.type === 'agency') {
        dispatch(fetchCalendarEvents(currentCalendar?._id, query))
        dispatch(fetchProjectsEvents(query))
      } else {
        const tasks = filteredPriorityTasks
          .filter(
            item =>
              new Date(item.dueDate).getTime() >= new Date(start).getTime() &&
              new Date(item.dueDate).getTime() <= new Date(end).getTime()
          )
          .map(task => ({
            ...task,
            start: new Date(Date.parse(task.dueDate)),
            end: new Date(Date.parse(task.dueDate)),
            eventType: 'tasks',
            color: task.taskSection?.color ?? '',
            allDay: true
          }))
        setEvents(tasks)
      }
    }
  }, [selectedDate, currentCalendar, filteredPriorityTasks])

  const onAddEventClick = e => {
    e?.stopPropagation()
    if (createEventAnchorEl.anchorEl) {
      setCreateEventAnchorEl({ anchorEl: null, data: {} })
    } else {
      setCreateEventAnchorEl({
        anchorEl: e.currentTarget,
        data: {
          start: e.currentTarget.dataset.value,
          end: e.currentTarget.dataset.value,
          calendar: calendar.data[0]?._id
        }
      })
    }
  }

  const handleUpdateEvent = ({ event, start, end }) => {
    if (event.eventType === 'projects' || event.eventType === 'tasks') return
    const data = {
      start: new Date(Date.parse(start)),
      end: new Date(Date.parse(end))
    }
    const updatedEvents = events.map(item => {
      if (item._id === event._id) {
        return { ...item, ...data }
      } else {
        return item
      }
    })
    setEvents(updatedEvents)

    dispatch(
      updateCalendarEvents(event._id, data, (res, err) => {
        if (err) {
          setEvents([...calendar.events, ...calendar.projects])
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster('Event updated successfully'))
        }
      })
    )
  }

  const handleEditEventModal = (e, data) => {
    e?.stopPropagation()
    // if (data?.eventType === 'projects') return
    if (editEventAnchorEl.anchorEl) {
      setEditEventAnchorEl({ anchorEl: null, data: {} })
    } else {
      setEditEventAnchorEl({
        anchorEl: e.currentTarget,
        data
      })
    }
  }

  const handleTabChange = (e, val) => {
    setSelectedTab(val)
    setSelectedView('month')
    setEvents([])
  }

  const handleSlotSelect = event => {
    if (
      [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role) &&
      currentCalendar.type === 'agency'
    ) {
      const elements = document.elementsFromPoint(
        event.box.clientX,
        event.box.clientY
      )

      if (createEventAnchorEl.anchorEl) {
        setCreateEventAnchorEl({ anchorEl: null, data: {} })
      } else {
        if (selectedView === 'month') {
          const dayElement = elements.find(element =>
            element.matches('.rbc-day-bg')
          )
          setCreateEventAnchorEl({
            anchorEl: dayElement,
            data: {
              start: event.start,
              end: event.end,
              calendar: calendar.data[0]?._id,
              allDay: true
            }
          })
        } else {
          const timeSlotElement = elements.find(element =>
            element.matches('.rbc-time-slot')
          )
          setCreateEventAnchorEl({
            anchorEl: timeSlotElement,
            data: {
              start: event.start,
              end: event.end,
              calendar: calendar.data[0]?._id,
              allDay: false
            }
          })
        }
      }
    }
  }

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      style={{ width: '80vw', height: '90vh', maxWidth: '80vw' }}
      size="extraLarge"
      // overflow="visible"
    >
      <header
        className={
          'flex items-center justify-between gap-2 py-5 px-6 select-none rounded-t-3xl'
        }
      >
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab
            label={<div className="">Personal Calendar</div>}
            value="personal"
          />
          <Tab label={<div className="">Agency Calendar</div>} value="agency" />
        </Tabs>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon size="small" fontSize="small" />
        </IconButton>
      </header>

      <div className="px-6 overflow-auto">
        <>
          {calendar.loading ? (
            <CalendarViewLoading />
          ) : currentCalendar._id ? (
            <div className="App__project-calendar-view bg-white">
              <DragAndDropCalendar
                expandToFit={true}
                selectable
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                date={selectedDate}
                onNavigate={date => setSelectedDate(new Date(date))}
                view={selectedView}
                onView={view => setSelectedView(view)}
                resizable={
                  currentCalendar.type === 'agency' &&
                  [userRoles.AGENCY_ADMIN, userRoles.USER_AGENCY].includes(
                    meData.role
                  )
                }
                onSelectSlot={handleSlotSelect}
                onEventResize={handleUpdateEvent}
                onEventDrop={handleUpdateEvent}
                eventPropGetter={eventPropGetter}
                draggableAccessor={() =>
                  currentCalendar.type === 'agency' &&
                  [userRoles.AGENCY_ADMIN, userRoles.USER_AGENCY].includes(
                    meData.role
                  )
                }
                components={{
                  toolbar: CustomCalendarToolbar,
                  header: props => {
                    const { date } = props
                    return (
                      <div className="text-xs text-primary-dark font-medium p-2">
                        {props.label}
                      </div>
                    )
                  },
                  event: ({ event }) => {
                    return (
                      <div onClick={e => handleEditEventModal(e, event)}>
                        {event.title}
                      </div>
                    )
                  },
                  dateCellWrapper: props => {
                    const { children, value } = props
                    return React.Children.map(children, child => {
                      return React.cloneElement(
                        child,
                        {
                          className: `${child.props.className} relative show-on-hover-parent bg-white`
                        }

                        // <div>
                        //   {createEventPermission && (
                        //     <CustomTooltip
                        //       title="Create new event"
                        //       placement="top"
                        //     >
                        //       <button
                        //         className="absolute right-2 bottom-0 show-on-hover-child bg-primary-main text-white mb-1 w-5 h-5 text-xs flex justify-center items-center rounded-sm"
                        //         onClick={onAddEventClick}
                        //         data-value={value}
                        //       >
                        //         <AddIcon fontSize="small" />
                        //       </button>
                        //     </CustomTooltip>
                        //   )}
                        // </div>
                      )
                    })
                  }
                }}
                slotGroupPropGetter={slotGroupPropGetter}
                dayPropGetter={calendarStyle}
                style={{ height: 'calc(90vh - 100px)' }}
              />
            </div>
          ) : (
            <div>No Calendar Available</div>
          )}
        </>
      </div>
      <EventCreatePopover
        anchorEl={createEventAnchorEl.anchorEl}
        data={createEventAnchorEl.data}
        handleClose={onAddEventClick}
      />
      <EventHandlePopover
        anchorEl={editEventAnchorEl.anchorEl}
        data={editEventAnchorEl.data}
        handleClose={handleEditEventModal}
      />
      {/* <EventModal
        open={eventModal.open}
        onClose={() => setEventModal(prev => ({ ...prev, open: false }))}
        top={eventModal.top}
        left={eventModal.left}
      /> */}
    </CustomModal>
  )
}

export const CalendarViewLoading = () => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
      gridAutoRows: 180,
      gridGap: 2
    }}
  >
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
  </div>
)

export default CalendarModal
