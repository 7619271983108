import React, { useCallback, useEffect, useState } from 'react'
import { Button, IconButton } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { useHistory, useRouteMatch, useLocation } from 'react-router'
import userRoles, { getUserPath } from 'utils/userRoles'
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import RefreshIcon from '@material-ui/icons/Refresh'
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined'
import BreadCrumbs from 'components/Breadcrumbs/BreadCrumbs'
import useIsUserOnline from 'customHooks/useIsUserOnline'
import HeaderProfileMenu from '../HeaderProfileMenu'
// import Notification from '@material-ui/icons/NotificationsOutlined'
import headerStyles from '../headerStyles'
// import RaisedRequests from './RaisedRequests'
// import { LogsIcon } from 'static/Images'
// import { setHeaderSearch } from 'thunks/generlAppState/actions'
import AccountNotifications from 'components/UserDashboard/AccountNotifications'
// import TimerWatch from 'components/Workspace/Panel/TimeWatch'
import { fetchOpenTimeTracking } from 'thunks/Tracking/action'
// import Timer from 'components/Workspace/Panel/Timer/Timer'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
import { setHeaderSearch, toggleSidebar } from 'thunks/generlAppState/actions'
// import { ReactComponent as BarsIcon } from 'static/svg/bars.svg'
import { ReactComponent as IndentIncrease } from 'static/svg/indent-increase.svg'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
// import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
import StartTimerModal from 'components/Timer/StartTimerModal'
import HeaderTimer from 'components/Timer/HeaderTimer'
import TimerPopupOptions from './TimerPopupOptions'
import LogTimerModal from 'components/Timer/LogTimerModal'
import CreateStandupsModal from 'components/Standups/CreateStandupsModal'
import { ReactComponent as PulseIcon } from 'static/svg/pulse.svg'
import { CustomTooltip } from 'global/globalComponents'
import CalendarModal from 'components/Settings/ResourceManagement/CalendarModal'
import { ReactComponent as CalenderIcon } from 'static/svg/calender-icon.svg'
import { fetchCalendar } from 'thunks/calender/action'
import { ReactComponent as BriefcaseIcon } from 'static/svg/briefcase.svg'

const GlobalHeader = ({ pusherMessage, isSidebarOpen }) => {
  const userOnlineStatus = useIsUserOnline()
  const classes = headerStyles()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const userData = useSelector(state => state.user.data)
  const history = useHistory()
  // const [leftDays, setLeftDays] = useState('')
  const { data: userActivePlan, loading: planDataLoading } = useSelector(
    state => state.userActivePlanInfo
  )
  const { fetched: fetchedTimer, data: timerData } = useSelector(
    state => state.timer
  )
  const calendar = useSelector(state => state.calendar)
  const { loggedIn } = useSelector(state => state.login)
  // const { timer, fetchedTimer } = useSelector((state) => state.dashboard)
  // const { timerFloat } = useSelector((state) => state.generalAppState)
  // const [timerOn, setTimerOn] = useState()
  // const [timeData, setTimeData] = useState({})
  const [timerModalOpen, setTimerModalOpen] = useState(false)
  const [logModalOpen, setLogModalOpen] = useState(false)
  const [standupsModalOpen, setStandupsModalOpen] = useState(false)
  const [calendarModalOpen, setCalendarModalOpen] = useState(false)

  // useEffect(() => {
  //   if (timer.length) {
  //     setTimeData(timer[0])
  //   }
  // }, [timer])

  useEffect(() => {
    if (!calendar.fetched) {
      dispatch(fetchCalendar())
    }
  }, [calendar.fetched])

  useEffect(() => {
    if (!fetchedTimer && loggedIn) {
      dispatch(fetchOpenTimeTracking())
    }
  }, [fetchedTimer, loggedIn])

  // useEffect(() => {
  //   const days = getRemainingTrialDays(
  //     meData.payments?.createdAt,
  //     meData.payment?.trial_period_days
  //   )
  //   setLeftDays(days)
  // }, [meData])

  // const getRemainingTrialDays = (createdDate, trialDays) => {
  //   if (createdDate && trialDays) {
  //     const dateGap =
  //       (new Date() - new Date(createdDate)) / (24 * 60 * 60 * 1000)
  //     return Math.ceil(trialDays - dateGap)
  //   } else {
  //     return -1
  //   }
  // }

  const handleRefresh = () => {
    window.location.reload()
  }

  const goToSubscriptions = () => {
    history.push(`${getUserPath(meData.role)}/settings/subscription`)
  }

  const toggleTimerModal = useCallback(() => {
    setTimerModalOpen(prev => !prev)
  }, [])

  const toggleLogModal = useCallback(() => {
    setLogModalOpen(prev => !prev)
  }, [])

  const toggleStandupsModal = () => {
    setStandupsModalOpen(prev => !prev)
  }

  const handleSidebar = () => {
    dispatch(toggleSidebar())
  }

  const handleUsersPulse = () => {
    history.push(`${getUserPath(meData.role)}/pulse`)
  }

  const toggleCalenderModal = () => {
    if (calendarModalOpen) {
      setCalendarModalOpen(false)
    } else {
      setCalendarModalOpen(true)
    }
  }

  return (
    <>
      <div className="w-full h-full px-6 lg:px-8">
        {!userOnlineStatus ? (
          <div
            className={clsx(classes.errorMessageContainer, 'offlineMessage')}
          >
            You are offline. Trying to reconnect...
          </div>
        ) : pusherMessage ? (
          <div
            onClick={handleRefresh}
            className={classes.errorMessageContainer}
          >
            {pusherMessage}
            <span className="refreshBtn">
              <RefreshIcon fontSize="small" />
            </span>
          </div>
        ) : null}

        <div className="w-full h-full flex items-center justify-between">
          <div className="flex items-center mr-4">
            {!isSidebarOpen && (
              <IndentIncrease
                width="2em"
                height="2em"
                onClick={handleSidebar}
                className="text-primary-mid-dark cursor-pointer hover:text-primary-main p-1 rounded-md w-7 h-7 mr-4 flex-shrink-0"
              />
            )}

            <HeaderTopLeft />
          </div>

          <div className="flex items-center space-x-4">
            {meData.role < userRoles.USER_CLIENT && (
              <Button
                size="small"
                variant="outlined"
                onClick={toggleStandupsModal}
                className="whitespace-nowrap"
                startIcon={
                  <BriefcaseIcon className="h-3 w-3 lg:h-4 lg:w-4 text-primary-main" />
                }
              >
                Daily Standups
              </Button>
            )}

            {meData.role < userRoles.USER_CLIENT && (
              <Button
                size="small"
                variant="outlined"
                onClick={toggleCalenderModal}
                className="whitespace-nowrap"
                startIcon={
                  <CalenderIcon className="h-3 w-3 lg:h-4 lg:w-4 text-primary-main" />
                }
              >
                Calendar
              </Button>
            )}

            {/* {meData.role < userRoles.USER_CLIENT && (
              <CustomTooltip title="Calendar">
                <button
                  size="small"
                  onClick={toggleCalenderModal}
                  className="whitespace-nowrap"
                >
                  <CalenderIcon
                    className="text-primary-main"
                    width="18px"
                    height="18px"
                  />
                </button>
              </CustomTooltip>
            )} */}

            {meData.role < userRoles.USER_CLIENT && !timerData.activeTimer && (
              <TimerPopupOptions
                toggleTimerModal={toggleTimerModal}
                toggleLogModal={toggleLogModal}
              />
              // <button onClick={toggleTimerModal}>
              //   <StopWatchIcon className="w-5 h-5 text-primary-main" />
              // </button>
            )}
            {!timerData.float && Boolean(timerData.activeTimer) && (
              <HeaderTimer />
            )}
            {/* {timer.length > 0 && (
            <Timer
              elmFor="header"
              task={
                timeData.type === 'subtask' ? timeData.subTask : timeData.task
              }
            />
          )} */}

            {meData.role < userRoles.USER_CLIENT && (
              <CustomTooltip title="Pulse">
                <button>
                  <PulseIcon
                    className="h-5 w-5 text-primary-main"
                    onClick={handleUsersPulse}
                  />
                </button>
              </CustomTooltip>
            )}

            <AccountNotifications />

            {meData.role === userRoles.USER_AGENCY &&
            !planDataLoading &&
            [
              'enterprise',
              'plan-d',
              'plan-c',
              'plan-b',
              'plan-a',
              'plan-e'
            ].every(i => {
              return !userActivePlan?.name?.toLocaleLowerCase()?.includes(i)
            }) ? (
              <Button
                onClick={goToSubscriptions}
                size="small"
                startIcon={<TrendingUpOutlinedIcon fontSize="small" />}
              >
                Upgrade
              </Button>
            ) : null}

            {/*
          Here i'm sending user data instead of me data bcoz when user updates his profile picture
          user data gets updated and not the me data (meData vs userData issue)
          */}
            <HeaderProfileMenu meData={userData} />
          </div>
        </div>
      </div>
      {meData.role < userRoles.USER_CLIENT && (
        <>
          {timerModalOpen && (
            <StartTimerModal open={timerModalOpen} onClose={toggleTimerModal} />
          )}
          {logModalOpen && (
            <LogTimerModal open={logModalOpen} onClose={toggleLogModal} />
          )}
        </>
      )}

      {standupsModalOpen && (
        <CreateStandupsModal
          open={standupsModalOpen}
          onClose={toggleStandupsModal}
        />
      )}
      {calendarModalOpen && (
        <CalendarModal
          open={calendarModalOpen}
          handleClose={toggleCalenderModal}
          // elmFor="personal"
        />
      )}
    </>
  )
}

const HeaderTopLeft = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  if (
    pathname.includes(`${path}/dashboard`) ||
    pathname.includes(`${path}/reports`)
  )
    return null

  return (
    <>
      {pathname.includes(`${path}/settings`) ? (
        <BreadCrumbs />
      ) : (
        <GlobalSearch />
      )}
    </>
  )
}

const GlobalSearch = () => {
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    // Debounce
    let timer = setTimeout(() => {
      dispatch(setHeaderSearch(search.trim()))
    }, 300)

    return () => clearTimeout(timer)
  }, [search])

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  return (
    <div className="bg-gray-100 rounded-full lg:w-72 flex items-center px-4">
      <SearchIcon className="text-primary-main flex-shrink-0" />
      <input
        type="text"
        placeholder="Search here"
        className="bg-transparent px-4 py-2 md:py-2.5 w-full text-smaller lg:text-sm"
        value={search}
        onChange={handleSearchChange}
      />
      {search && (
        <CancelRoundedIcon
          fontSize="small"
          style={{ fill: 'gray' }}
          onClick={() => setSearch('')}
          className="cursor-pointer"
        />
      )}
    </div>
  )
}

export default React.memo(GlobalHeader)
