import { useState } from 'react'
import { TextField } from '@material-ui/core'
import { FormLabel, LoadingButton } from 'global/globalComponents'
import { redeemCode } from 'thunks/agencyPayment/actions'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { fetchUserActivePlanInfo } from 'thunks/userActivePlanInfo/actions'
import { fetchMeData } from 'thunks/me/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'

const RedeemCode = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [code, setCode] = useState('')

  const handleCodeChange = (e) => {
    setError('')
    setCode(e.target.value)
  }

  const handleRedeemCode = () => {
    if (!code) return dispatch(fireErrorToaster('code is undefined!'))

    setLoading(true)
    redeemCode(
      {
        codeStack: [code]
      },
      handleRedeemCodeCallback
    )
  }

  const handleRedeemCodeCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
      setError(res)
    } else {
      dispatch(fetchUserActivePlanInfo(userActivePlanInfoCallback))
      dispatch(fetchMeData())
    }
  }

  const userActivePlanInfoCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      dispatch(fireSuccessToaster('AppSumo code redeemed successfully!'))
    }
  }

  return (
    <div className="h-full">
      <div className="w-full" style={{ maxWidth: 550 }}>
        <SectionHeading className="text-primary-main">
          Stack AppSumo codes
        </SectionHeading>
        <p className="text-sm text-custom-gray-main mb-4">
          If you're one of the lucky few that purchased more than one AppSumo
          code, then you've come to the right place. Simply enter your codes in
          the form below and we'll stack them on top of your existing account
        </p>
        <div className="flex gap-4 items-center">
          <TextField
            classes={{ root: 'w-full block' }}
            required
            placeholder="Enter AppSumo code"
            onChange={handleCodeChange}
          />
          <LoadingButton loading={loading} onClick={handleRedeemCode}>
            Redeem
          </LoadingButton>
        </div>
        {error && <p className="text-red-400 text-sm mt-2">{error}</p>}
      </div>
    </div>
  )
}

export default RedeemCode
