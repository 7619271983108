import CustomPopper from 'global/globalComponents/CustomPopper/CustomPopper'
import currency from 'static/Currency/Currency'

const CurrencyDropdown = ({ anchorEl, onClose, handleCurrency }) => {
  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <div className=" overflow-auto" style={{ maxHeight: '300px' }}>
        {Object.keys(currency).map((item) => (
          <div
            className="text-xs text-primary-dark px-4 py-2 cursor-pointer hover:bg-gray-100"
            onClick={() => handleCurrency(item.toLowerCase())}
          >
            {item}
          </div>
        ))}
      </div>
    </CustomPopper>
  )
}

export default CurrencyDropdown
