import React, { useMemo } from 'react'
import { ReactComponent as UsersIcon } from 'static/svg/users.svg'
import { ReactComponent as BriefcaseIcon } from 'static/svg/briefcase.svg'
import { ReactComponent as ArrowRight } from 'static/svg/arrow-right.svg'
import { Link } from 'react-router-dom'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useSelector } from 'react-redux'
import { userRoles } from 'utils'

const ReportsOverview = () => {
  const meData = useSelector(state => state.me.data)

  return (
    <div>
      <header className="mb-6 lg:mb-8">
        <SectionHeading>Reports</SectionHeading>
      </header>
      <ReportCards
        isManager={meData.role === userRoles.AGENCY_MANAGER}
        meId={meData._id}
      />
    </div>
  )
}

const ReportCards = ({ isManager, meId }) => {
  const cardsData = useMemo(() => {
    return [
      {
        id: 1,
        title: 'People Performance',
        intro: "Analyse your team's efficiency, collaboration and utilisation",
        icon: <UsersIcon className="w-6 h-6" />,
        path: '/agency/reports/people-performance'
      },
      {
        id: 2,
        title: 'Standups',
        intro: isManager
          ? 'Check your daily standups'
          : 'Check your teammates daily standups',
        icon: <BriefcaseIcon className="w-6 h-6" />,
        path: isManager
          ? `/agency/reports/standups/${meId}`
          : '/agency/reports/standups'
      },
      {
        id: 3,
        title: 'Financials',
        intro: "Check your company's finances",
        icon: <BriefcaseIcon className="w-6 h-6" />,
        path: '/agency/reports/finance'
      }
    ]
  }, [isManager])

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '1rem'
      }}
    >
      {cardsData.map(item => (
        <SingleReportCard key={item.id} {...item} />
      ))}
    </div>
  )
}

const SingleReportCard = ({ title, intro, icon, path }) => {
  return (
    <div className="bg-white rounded shadow hover:shadow-lg transition">
      <Link to={path} className="p-6 block w-full h-full">
        <div className="mb-4 text-primary-main">{icon}</div>
        <h4 className="text-sm font-semibold mb-1 text-primary-main">
          {title}
        </h4>
        <p className="text-smaller text-gray-600">{intro}</p>

        <div className="flex justify-end mt-4 text-primary-main">
          <ArrowRight />
        </div>
      </Link>
    </div>
  )
}

export default ReportsOverview
