import { Checkbox, FormControlLabel } from '@material-ui/core'
import {
  CheckBox,
  CheckBoxOutlineBlank,
  NotificationImportantSharp
} from '@material-ui/icons'
import { Tab, Tabs } from '@material-ui/core'
import { useBreadcrumbs } from 'customHooks'
import { LoadingButton } from 'global/globalComponents'
import Pill from 'global/globalComponents/Pill/Pill'
import SectionHeading from 'global/globalComponents/SectionHeading'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllWebhookEvents } from 'thunks/webhooks/action'
import {
  fetchUserSubscribedNotificationEvents,
  fetchAllNotificationsEvents,
  saveChanges,
  fetchSubscribedPushNotifications,
  deleteSubscribedPushNotifications
} from 'thunks/emailNotificationEvents/actions'
import NotificationsItem from './NotificationsItem'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { errorMessages, userRoles } from 'utils'
// import settingStyles from '../settingStyles'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import SetNotification from './SetNotification'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'

const Notifications = () => {
  const dispatch = useDispatch()
  const notificationEvents = useSelector(state => state.emailNotificationEvents)
  const meData = useSelector(state => state.me.data)
  // const classes = settingStyles()
  const [loading, setLoading] = useState(false)
  const [allNotificationEvents, setAllNotificationEvents] = useState([])
  const [eventTypes, setEventTypes] = useState({
    workspace: [],
    task: [],
    standups: []
  })
  const [selectedTab, setSelectedTab] = useState('workspace')
  const [pushNotificationsBrowers, setPushNotificationsBrowsers] = useState([])

  const tabs = useMemo(() => {
    const arr = ['workspace', 'task']

    if (meData.role < userRoles.USER_CLIENT) {
      arr.push('standups')
    }

    return arr
  }, [meData])

  // Fetching all notification events
  useEffect(() => {
    dispatch(fetchAllNotificationsEvents())
    dispatch(fetchUserSubscribedNotificationEvents())
  }, [])

  useEffect(() => {
    if (allNotificationEvents?.length) {
      Object.keys(eventTypes).map(event => {
        setEventTypes(prev => ({
          ...prev,
          [event]: allNotificationEvents?.filter(item => item.type === event)
        }))
      })
    }
  }, [allNotificationEvents])

  useEffect(() => {
    if (
      !notificationEvents.loading &&
      notificationEvents.subscribedEvents._id
    ) {
      // setLoading(false)
      // storing in a different array so that we can do the changes and show that in UI
      const notifications = notificationEvents.events.email?.map(item => {
        let event = { ...item, notificationType: { email: false, push: false } }
        if (
          [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
        ) {
          event.view = true
        } else if (
          [
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER,
            userRoles.AGENCY_VIEWER,
            userRoles.AGENCY_EDITOR,
            userRoles.USER_CLIENT
          ].includes(meData.role)
        ) {
          event.view = event.permissions[meData.role].view
        } else {
          event.view = event.permissions[userRoles.USER_CLIENT].view
        }

        if (
          notificationEvents.subscribedEvents.emailSubscribedEvents?.includes(
            event._id
          )
        ) {
          event.notificationType.email = true
          // event.isActive = true
          if (
            [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
              meData.role
            )
          ) {
            event.disable = false
          } else if (
            [
              userRoles.AGENCY_MANAGER,
              userRoles.AGENCY_VIEWER,
              userRoles.AGENCY_EDITOR,
              userRoles.USER_CLIENT
            ].includes(meData.role)
          ) {
            event.disable = event.permissions[meData.role].update ? false : true
          } else {
            event.disable = event.permissions[userRoles.USER_CLIENT].update
              ? false
              : true
          }
        } else {
          event.notificationType.email = false
          event.disable = false
        }

        if (
          notificationEvents.subscribedEvents.pushNotificationSubscribedEvents?.includes(
            event._id
          )
        ) {
          event.notificationType.push = true
          // event.isActive = true
          if (
            [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
              meData.role
            )
          ) {
            event.disable = false
          } else if (
            [
              userRoles.AGENCY_MANAGER,
              userRoles.AGENCY_VIEWER,
              userRoles.AGENCY_EDITOR,
              userRoles.USER_CLIENT
            ].includes(meData.role)
          ) {
            event.disable = event.permissions[meData.role].update ? false : true
          } else {
            event.disable = event.permissions[userRoles.USER_CLIENT].update
              ? false
              : true
          }
        } else {
          event.notificationType.push = false
          event.disable = false
        }

        return event
      })
      setAllNotificationEvents(notifications)
    }
  }, [
    notificationEvents.loading,
    notificationEvents.subscribedEvents,
    notificationEvents.events
  ])

  useEffect(() => {
    fetchSubscribedPushNotifications((res, err) => {
      if (!err) {
        setPushNotificationsBrowsers(res)
      }
    })
  }, [])

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Email Notifications',
      link: `/settings/notification`,
      index: 1
    }
  ])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleCheckFields = (isChecked, id, type) => {
    // updating event notification status without mutating anything
    const eventsClone = [...allNotificationEvents]
    const index = eventsClone.findIndex(item => item._id === id)
    const event = {
      ...eventsClone[index],
      notificationType: {
        ...eventsClone[index].notificationType,
        [type]: isChecked
      }
    }
    eventsClone.splice(index, 1, event)

    setAllNotificationEvents(eventsClone)
  }

  const postChangesAction = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Notifications Updated Successfully'))
    }
  }

  const handleSaveChanges = () => {
    const emailList = allNotificationEvents
      ?.filter(item => item.notificationType.email)
      .map(el => el._id)

    const pushList = allNotificationEvents
      ?.filter(item => item.notificationType.push)
      .map(el => el._id)
    setLoading(true)
    dispatch(
      saveChanges(
        {
          emailSubscribedEvents: emailList,
          pushNotificationSubscribedEvents: pushList
        },
        postChangesAction
      )
    )
  }

  const handleDeleteNotificationsFromBrowser = id => {
    deleteSubscribedPushNotifications(id, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
      } else {
        setPushNotificationsBrowsers(prev => prev.filter(el => el._id !== id))
      }
    })
  }

  return (
    <>
      <Wrapper className="mb-12">
        <>
          <SecondaryHeading className="mb-3 lg:mb-5">
            Notification Settings
          </SecondaryHeading>
          <div className="mb-5 lg:mb-6">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              {tabs.map((item, index) => (
                <Tab label={item} key={index} value={item.toLowerCase()} />
              ))}
            </Tabs>
            {/* <div style={{ border: '1px solid #ECEBF5' }}></div> */}
          </div>
          <div>
            <TableContainer shadow="none">
              {!notificationEvents.loading &&
              allNotificationEvents?.length > 0 ? (
                <>
                  <Thead>
                    <Th className="text-left capitalize font-medium whitespace-nowrap">
                      {selectedTab}
                    </Th>
                    <Th className="text-center capitalize font-medium whitespace-nowrap">
                      Email
                    </Th>
                    <Th className="text-center capitalize font-medium whitespace-nowrap">
                      Push
                    </Th>
                  </Thead>
                  <Tbody>
                    {eventTypes[selectedTab]
                      .filter(item => item.view)
                      .map(item => (
                        <Tr>
                          <Td className="text-left  whitespace-nowrap text-primary-mid-dark font-medium capitalize">
                            {item.name}
                          </Td>
                          <Td className="text-center whitespace-nowrap text-gray-900">
                            <NotificationsItem
                              key={item.name}
                              type="email"
                              notification={item}
                              handleCheckFields={handleCheckFields}
                            />
                          </Td>
                          <Td className="text-center whitespace-nowrap text-gray-900">
                            <NotificationsItem
                              key={item.name}
                              type="push"
                              notification={item}
                              handleCheckFields={handleCheckFields}
                            />
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </>
              ) : (
                <TableDataLoading cols={1} />
              )}
            </TableContainer>
            <div className="text-right">
              <LoadingButton
                onClick={handleSaveChanges}
                loading={loading}
                className="w-40"
              >
                Save Changes
              </LoadingButton>
            </div>
          </div>
        </>
      </Wrapper>
      <ManagePushNotifications
        Browsers={pushNotificationsBrowers}
        handleDeleteNotificationsFromBrowser={
          handleDeleteNotificationsFromBrowser
        }
      />
      {!notificationEvents.loading &&
      allNotificationEvents?.length &&
      [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role) ? (
        <SetNotification notificationEvents={notificationEvents.events} />
      ) : null}
    </>
  )
}

const ManagePushNotifications = ({
  Browsers,
  handleDeleteNotificationsFromBrowser
}) => {
  return (
    <Wrapper className="mb-12">
      <SecondaryHeading className="mb-4 lg:mb-6">
        Manage Push Notification
      </SecondaryHeading>
      <TableContainer shadow="none">
        <Thead>
          <Th>Browser Name</Th>
          <Th className="text-right">Action</Th>
        </Thead>
        <Tbody>
          {Browsers.length ? (
            Browsers.map(browser => (
              <Tr>
                <Td>{browser.browser.browserName}</Td>
                <Td className="text-right">
                  {' '}
                  <button
                    onClick={() =>
                      handleDeleteNotificationsFromBrowser(browser._id)
                    }
                    // loading={loading}
                    className="text-primary-main"
                  >
                    Deactivate
                  </button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="2">Push Notifications are not active</Td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {/* <div className="text-right">
        <LoadingButton
          // onClick={handleSaveChanges}
          // loading={loading}
          className="w-40"
        >
          Delete
        </LoadingButton>
      </div> */}
    </Wrapper>
  )
}

export default Notifications
