import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { KeyboardArrowDown } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getClient } from '../../thunks/addClient/actions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { fetchClientRaisedRequests } from 'thunks/clientRaisedRequestAgency/actions'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { toggleAddClientSidePanel } from 'thunks/generlAppState/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'
import { clearAndReplaceBreadCumbs } from 'thunks/breadcrumbs/action'
import userRoles, { getUserPath } from 'utils/userRoles'
import ClientRequestsAgency from './ClientRequestsAgency'
import { CustomTabs } from 'global/globalComponents'
import ActiveClients from './ActiveClients'
import InactiveClients from './InactiveClients'
import ClientCompany from './ClientCompany'
import AddNewCompany from './AddNewCompany'
import PopupMenu from 'global/globalComponents/PopupMenu/PopupMenu'
import AddNewClient from './AddNewClient'
import { getClientCompanies } from 'thunks/clientCompany/action'

const Clients = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const access = useSelector(state => state.permission.data?.access)
  const { data: me } = useSelector(state => state.me)
  const { fetchedFirstTime } = useSelector(state => state.userWorkspaces)
  const userClients = useSelector(state => state.userClients)
  const clientRaisedRequests = useSelector(
    state => state.clientRaisedRequestAgency
  )
  const clientCompany = useSelector(state => state.clientCompany)
  // const [pendingRequests, setPendingRequests] = useState([])
  const search = useSelector(state => state.generalAppState.headerSearch)
  const [selectedTab, setSelectedTab] = useState(pathname)
  // const addClientSidePanelStatus = useSelector(
  //   (state) => state.generalAppState.sidePanel.addClient
  // )
  // const [clientCompany, setClientCompany] = useState({
  //   open: false
  // })
  const [clientAddPanel, setClientAddPanel] = useState({
    open: false,
    clientType: 'company'
  })
  const tabsData = useMemo(() => {
    const arr = [
      { label: 'Contacts', value: path, number: userClients.data.length },
      {
        label: 'Companies',
        value: path + '/company',
        number: clientCompany.data.length
      },
      {
        label: 'Inactive Contacts',
        value: path + '/inactive',
        number: userClients.data.filter(el => !el.isActive).length
      }
    ]

    if (
      me.role !== userRoles.AGENCY_EDITOR &&
      me.role !== userRoles.AGENCY_VIEWER
    ) {
      arr.push({
        label: 'Requests',
        value: path + '/requests',
        number: clientRaisedRequests.data.length
      })
    }

    return arr
  }, [
    me,
    userClients.data.length,
    clientRaisedRequests.data.length,
    clientCompany.data.length
  ])

  useEffect(() => {
    if (!userClients.fetched) {
      dispatch(getClient())
    }
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userClients.fetched])

  useEffect(() => {
    if (!clientRaisedRequests.fetched) {
      dispatch(fetchClientRaisedRequests())
    }
  }, [clientRaisedRequests.fetched])

  useEffect(() => {
    if (!clientCompany.fetched) {
      dispatch(getClientCompanies())
    }
  }, [clientCompany.fetched])

  useEffect(() => {
    dispatch(
      clearAndReplaceBreadCumbs([
        {
          name: 'Clients',
          link: `${getUserPath(me?.role)}/dashboard`,
          index: 0
        }
      ])
    )
  }, [me])

  // useEffect(() => {
  //   dispatch(updateUserAcces(me.role))
  // }, [])

  // useEffect(() => {
  //   return () => {
  //     if (addClientSidePanelStatus) {
  //       dispatch(toggleAddClientSidePanel(false))
  //     }
  //   }
  // }, [addClientSidePanelStatus])

  // useEffect(() => {
  //   if (clientRaisedRequests.data.length) {
  //     const pendingRequests = clientRaisedRequests.data.filter(
  //       (item) => item.status === 'pending'
  //     )
  //     setPendingRequests(pendingRequests)
  //   }
  // }, [clientRaisedRequests.data])

  // const handleChange = (e) => {
  //   setSearch(e.target.value)
  // }

  // useEffect(() => {
  //   setSearch('')
  // }, [])

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = useCallback(newVal => {
    setSelectedTab(newVal)
    history.push(newVal)
  }, [])

  const handleClose = () => {
    setClientAddPanel({ open: false, clientType: 'company' })
    // setClientCompany({ open: false })
  }

  // const handleSidePanel = () => {
  //   if (selectedTab.includes('/company')) {
  //     setClientCompany({ open: true })
  //   } else {
  //     dispatch(toggleAddClientSidePanel(true))
  //   }
  // }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-6 lg:mb-8">
        <SectionHeading>Clients</SectionHeading>
        {/* {access?.clients.create && (
          <Button
            variant="outlined"
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleSidePanel}
          >
            New Client
          </Button>
        )} */}
        {access?.clients.create && (
          <PopupMenu
            menuItems={[
              {
                label: 'Add Client',
                method: () => dispatch(toggleAddClientSidePanel(true))
                // method: () => setClientAddPanel({ open: true, clientType: 'individual' }),
              },
              {
                label: 'Add Company',
                method: () =>
                  setClientAddPanel({ open: true, clientType: 'company' })
              }
            ]}
            targetComp={
              <Button endIcon={<KeyboardArrowDown />}>Client Options</Button>
            }
          />
        )}
      </div>
      <div className="flex justify-between items-center mb-6">
        <div className="w-full">
          <CustomTabs
            tabsData={tabsData}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>
      {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
          Search results for:{' '}
          <span className="text-primary-dark">{search}</span>
        </p>
      )}

      <Switch>
        <Route exact path={path + '/company'}>
          <ClientCompany search={search} />
        </Route>
        <Route exact path={path + '/inactive'}>
          <InactiveClients />
        </Route>
        <Route exact path={path + '/requests'}>
          <ClientRequestsAgency />
        </Route>
        <Route path={path}>
          <ActiveClients />
        </Route>
      </Switch>
      <AddNewCompany open={clientAddPanel.open} onClose={handleClose} />
      <AddNewClient />
    </OuterContentWrapper>
  )
}

export default Clients
