import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { TextField, Button } from '@material-ui/core'
import {
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
import { ReactComponent as ArrowRightIcon } from 'static/svg/arrow-right.svg'
import { dataTypes } from 'utils'

const CustomFieldsMapping = ({
  setStep,
  handleSave,
  loading,
  mappedColumn,
  setMappedColumn,
  handleClose
}) => {
  const onChange = (e, key) => {
    console.log(e, 'event', key, 'key', mappedColumn[key])
    setMappedColumn(prev => ({
      ...prev,
      [key]: { ...prev[key], dataType: e.value }
    }))
  }

  return (
    <div id="import">
      <CustomModalHeader
        heading="Custom Fields Mapping"
        className="text-center"
        handleClose={handleClose}
      />
      <CustomModalBody style={{ height: '450px' }}>
        <div className="w-2/3 mb-8 mx-auto">
          <p className="text-xs text-center">
            Map any of your custom fields to existing Clientvenue fields
          </p>
          <form>
            <div className="flex space-x-4 items-center justify-between w-full mt-4">
              <p className="w-1/2 text-xs">COLUMN:</p>
              <p className="w-1/2 pl-4 text-xs">DATA TYPE:</p>
            </div>

            {Object.keys(mappedColumn)
              .filter(el => mappedColumn[el].customField)
              .map(item => (
                <div className="flex space-x-4 items-center justify-between w-full mt-2">
                  <TextField
                    value={mappedColumn[item].label}
                    className="w-full"
                    disabled={true}
                  />
                  <ArrowRightIcon className="text-primary-main" width={40} />
                  <Select
                    menuPosition={'fixed'}
                    styles={{
                      ...reactSelectCustomStyles,
                      menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                    onChange={e => onChange(e, item)}
                    value={dataTypes.find(
                      type => type.value === mappedColumn[item].dataTypes
                    )}
                    options={dataTypes}
                    className="w-full"
                  />
                </div>
              ))}
          </form>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton loading={loading} onClick={handleSave}>
          Complete
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={() => {
            setStep(3)
          }}
        >
          Back
        </Button>
      </CustomModalFooter>
    </div>
  )
}

export default CustomFieldsMapping
