import React, { useMemo } from 'react'
import { isEqual } from 'lodash'
import { TextField } from '@material-ui/core'
import DatePicker from 'react-datepicker'
import Close from '@material-ui/icons/Close'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const InvoiceFromTo = ({
  fromToData,
  updateClientCCEmail,
  handleDateChange,
  handleClientFieldChange,
  handleSelectClient,
  handleAgencyFieldChange
}) => {
  const agency = fromToData.agency
  const client = fromToData.client
  const userClients = useSelector((state) => state.userClients.data)
  const options = useMemo(() => {
    return userClients.map((user) => ({
      label: user.name,
      value: user._id,
      email: user.email
    }))
  }, [userClients])

  const addNewRecipient = () => {
    updateClientCCEmail([...client.client_cc_email, ''])
  }

  const handleRecipientChange = (e) => {
    const updatedRecipient = client.client_cc_email.map((item, index) => {
      if (index == e.target.id) {
        item = e.target.value
      }
      return item
    })
    updateClientCCEmail(updatedRecipient)
  }

  const removeRecipientField = (e) => {
    updateClientCCEmail(
      client.client_cc_email.filter(
        (item, index) => index != e.currentTarget.dataset.id
      )
    )
  }

  const handleClient = (e) => {
    handleSelectClient({
      clientId: e.value,
      clientName: e.label,
      clientEmail: e.email
    })
  }

  return (
    <div className="mb-8">
      <div className="flex space-x-6 py-8">
        {/* ------ From ------- */}
        <div className="flex-1">
          <h6 className="mb-2 text-gray-500">From</h6>
          <div>
            <div className="mb-2">
              <TextField
                value={agency.agencyName}
                onChange={handleAgencyFieldChange}
                placeholder="Your Name"
                className="w-full"
                name="agencyName"
              />
            </div>

            <div className="mb-2">
              <TextField
                value={agency.agencyCompanyName}
                onChange={handleAgencyFieldChange}
                placeholder="Your Company"
                className="w-full"
                name="agencyCompanyName"
              />
            </div>
            <div className="mb-2">
              <TextField
                value={agency.agencyAddress}
                onChange={handleAgencyFieldChange}
                placeholder="Address"
                className="w-full"
                name="agencyAddress"
                multiline={true}
              />
            </div>
            <div className="mb-2 text-xs text-primary-main">
              {agency.agencyEmail}
            </div>
          </div>
        </div>

        {/* ------ To ------- */}
        <div className="flex-1">
          <h6 className="mb-2 text-gray-500">To</h6>
          <div>
            {client.clientId ? (
              <div className="mb-2">
                <TextField
                  value={client.clientName}
                  onChange={handleClientFieldChange}
                  placeholder="Client Name"
                  className="w-full"
                  name="clientName"
                />
              </div>
            ) : (
              <div className="mb-2">
                <Select
                  onChange={handleClient}
                  placeholder="Select Client"
                  options={options}
                  styles={reactSelectCustomStyles}
                />
              </div>
            )}

            <div className="mb-2">
              <TextField
                value={client.clientCompanyName}
                onChange={handleClientFieldChange}
                placeholder="Client Company"
                className="w-full"
                name="clientCompanyName"
              />
            </div>

            <div className="mb-2">
              <TextField
                value={client.clientAddress}
                onChange={handleClientFieldChange}
                placeholder="Client Address"
                className="w-full"
                name="clientAddress"
                multiline={true}
              />
            </div>

            <div className="text-primary-main mb-2">
              {client.clientEmail && (
                <div className="mb-2">
                  <TextField
                    value={client.clientEmail}
                    onChange={handleClientFieldChange}
                    placeholder="Client Email"
                    className="w-full"
                    name="clientEmail"
                  />
                </div>
              )}
              <div>
                {client.client_cc_email.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mb-2"
                  >
                    <TextField
                      type="email"
                      value={item}
                      onChange={handleRecipientChange}
                      id={index}
                      className="w-full"
                      autoFocus
                      placeholder="client-cc@gmail.com"
                    />

                    <button
                      onClick={removeRecipientField}
                      data-id={index}
                      className="ml-2"
                    >
                      <Close fontSize="small" />
                    </button>
                  </div>
                ))}
              </div>

              <div
                role="button"
                onClick={addNewRecipient}
                className="text-xs font-semibold"
              >
                + ADD RECIPIENT
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-6">
        <div className="flex-1 mb-2 mt-auto">
          <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500">
            Issued On
          </h6>

          <DatePicker
            selected={
              fromToData.issuedDate
                ? new Date(fromToData.issuedDate)
                : new Date()
            }
            onChange={(date) => handleDateChange('issuedDate', date)}
            dateFormat="MMM d, yyyy"
            className="text-sm"
          />
        </div>

        <div className="flex-1">
          <div className="mb-2">
            <h6 className="uppercase text-xs font-semibold tracking-wide text-gray-500">
              Due On
            </h6>
            <DatePicker
              selected={
                fromToData.dueDate ? new Date(fromToData.dueDate) : null
              }
              customInput={
                fromToData.dueDate ? (
                  <input style={{ width: '180px', padding: '4px 0' }} />
                ) : (
                  <CalendarTodayOutlinedIcon
                    fontSize="small"
                    className="cursor-pointer text-custom-gray-main"
                  />
                )
              }
              onChange={(date) => handleDateChange('dueDate', date)}
              dateFormat="MMM d, yyyy"
              className="text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const checkEquality = (prevProps, nextProps) => {
  return isEqual(prevProps.fromToData, nextProps.fromToData)
}

export default React.memo(InvoiceFromTo, checkEquality)
