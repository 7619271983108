import React, { useEffect, useState } from 'react'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useDispatch, useSelector } from 'react-redux'
import { getStripeConnectStatus } from 'thunks/stripeAccount/actions'
import { Radio } from '@material-ui/core'
import { MasterCard } from 'static/svg'
import { fetchCardDetails } from 'thunks/agencyPayment/actions'
import clsx from 'clsx'
import UpdateCardModal from 'components/Settings/Subscription/UpdateCardModal'
import invoiceStyles from '../InvoiceStyles'

const CardDetails = () => {
  const userStripeInfo = useSelector((state) => state.userStripeInfo)
  const dispatch = useDispatch()
  const [card, setCard] = useState({})
  const [open, setOpen] = React.useState(false)
  const classes = invoiceStyles()

  const cardDetailsCallback = (res, err) => {
    if (err) {
    } else {
      setCard(res)
    }
  }

  const handleClose = () => {
    setOpen((prev) => !prev)
    fetchCardDetails(cardDetailsCallback)
  }
  // function stripeCallback(res, error) {
  //   if (error) {
  //     console.log(error)
  //   } else {
  //     window.open(res.url)
  //   }
  // }

  useEffect(() => {
    dispatch(getStripeConnectStatus())
    fetchCardDetails(cardDetailsCallback)
  }, [])
  return (
    <div>
      <SectionHeading>Card Details</SectionHeading>
      <ul
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
        style={{ margin: '24px 0' }}
      >
        <div>
          {Object.keys(card).includes('last4') ? (
            <>
              <div className="flex flex-row ">
                <Radio
                  checked={Object.keys(card).includes('last4')}
                  disable
                  value="b"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'B' }}
                />
                <div
                  className={clsx(
                    'flex flex-row w-full pr-2',
                    classes.rowContainer
                  )}
                >
                  {card.brand == 'visa' ? (
                    <img
                      alt="visa"
                      src="https://logotyp.us/files413/visa.svg"
                      className={classes.image}
                    />
                  ) : (
                    <MasterCard
                      height="50px"
                      width="40px"
                      style={{ padding: '7px' }}
                    />
                  )}
                  <p className="self-center flex-grow">
                    {card.brand == 'visa' ? 'Visa Debit Card' : 'Master Card'}
                  </p>
                  <p className="self-center">********{card.last4}</p>
                </div>
              </div>
              <div
                className="px-2 py-3 flex flex-row-reverse cursor-pointer"
                onClick={handleClose}
              >
                <p className="text-primary-main text-sm">
                  Update your Payment Method
                </p>
              </div>
            </>
          ) : (
            <div
              className="px-10 py-3 cursor-pointer"
              onClick={handleClose}
              role="button"
            >
              <p className="text-primary-main text-sm">
                + Add a payment Method
              </p>
            </div>
          )}
        </div>
      </ul>
      <UpdateCardModal
        addingNewCard={!Object.keys(card).includes('last4')}
        open={open}
        handleClose={handleClose}
      />
    </div>
  )
}

export default CardDetails
