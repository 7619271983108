import React from 'react'

const SecondaryHeading = props => {
  const { children, className, ...restProps } = props
  return (
    <h5
      className={`text-base lg:text-xl font-semibold text-primary-dark font-heading capitalize mb-8 ${
        className ?? ''
      }`}
      {...restProps}
    >
      {children}
    </h5>
  )
}

export default SecondaryHeading
