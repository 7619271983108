import axios from 'axios'
import { errorMessages } from 'utils'
import { STRIPE_ACCOUNT } from './reducers'

/**
 * get stripe account data whether the client is connected or not.
 * @param {Function} callback callback function
 */
export const getStripeConnectStatus = (callback) => {
  return async (dispatch) => {
    dispatch({ type: STRIPE_ACCOUNT.LOADING })
    try {
      const res = await axios({
        url: '/connectaccount/stripeconnect',
        method: 'GET'
      })
      if (callback) callback(res.data, false)
      dispatch({ type: STRIPE_ACCOUNT.FETCHED, payload: res.data })
    } catch (err) {
      dispatch({ type: STRIPE_ACCOUNT.ERROR, payload: err })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * post api to get client connected or verified to stripe.
 * @param {Function} callback callback function
 * @param {String}  auth_code auth_code recieved in query params
 */
export const postStripeAccountData = async (auth_code, callback) => {
  try {
    const res = await axios.post('/connectaccount', {
      //authorization_code: window.location.href.split('code=')[1]
      authorization_code: auth_code
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback) callback(err?.response?.data, true)
  }
}

/**
 * delete api to delete or deactivate stripe.
 * @param {Function} callback callback function
 * @param {String}  auth_code auth_code recieved in query params
 */
export const deleteStripeAccount = (accountId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/connectaccount/${accountId}`,
        method: 'DELETE'
      })
      if (callback) callback(res.data, false)
      dispatch({ type: STRIPE_ACCOUNT.DELETE, payload: res.data })
    } catch (err) {
      dispatch({ type: STRIPE_ACCOUNT.ERROR, payload: err })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch stripe customers.
 * @param {Function} callback callback function
 */
export const fetchStripeCustomers = async (callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/user/stripe-customer'
    })

    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
