import { Button } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { CLIENT } from 'thunks/addClient/reducers'
import { useBreadcrumbs } from 'customHooks'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
import {
  ColoredAvatars,
  CustomTooltip,
  CustomTabs,
  Card
} from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { toggleAddClientSidePanel } from 'thunks/generlAppState/actions'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { getRoleBadge, userRoles } from 'utils'
import { getClientCompanies } from 'thunks/clientCompany/action'
import { fetchClientInfo, getClient } from 'thunks/addClient/actions'
import ClientInvoices from '../ClientComponent/ClientList/ClientInvoices'
import TeamMembers from 'components/ClientComponent/ClientList/TeamMembers'
import { ReactComponent as LockIcon } from 'static/svg/lock.svg'
import ProjectCard from 'components/UserDashboard/Workspaces/ProjectsCardView/ProjectCard'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import clsx from 'clsx'
import useStyles from 'components/UserDashboard/Workspaces/ProjectsCardView/projectsCardViewStyles'
import { NoAccess } from 'static/Images'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import { ReactComponent as BackArrow } from 'static/svg/long-arrow-left.svg'
import { Skeleton } from '@material-ui/lab'
import { ReactComponent as AngleIcon } from 'static/svg/angle-down.svg'
import CurrencyDropdown from './CurrencyDropdown'
import { updateClientCompany } from 'thunks/clientCompany/action'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { Create } from '@material-ui/icons'
import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import { ReactComponent as UserPlusIcon } from 'static/svg/user-plus.svg'
import { CircularProgress, TextField } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import AddressModal from './AddressModal'
import clientCompanyStyles from './clientCompanyStyles'
import { updateClient } from 'thunks/addClient/actions'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import TemplatesModal from 'components/Templates/TemplateModal'
import fileToDataURL from 'utils/fileToDataUrl'
import ProjectCardsPage from './ProjectCardsPage'

export default function CompanyMain() {
  const classes = clientCompanyStyles()
  const access = useSelector(state => state.permission.data?.access)
  const meData = useSelector(state => state.me.data)
  const { companyId } = useParams()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const allClientsData = useSelector(state => state.userClients)
  const clientCompany = useSelector(state => state.clientCompany)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const [companyData, setCompanyData] = useState({})
  const [clientInfo, setClientInfo] = useState({ loading: false, data: {} })
  const [clientOnboardingData, setClientOnboardingData] = useState({
    loading: true,
    fetched: false,
    data: {}
  })
  const userPermission = useSelector(state => state.permission.data)
  const [selectedTab, setSelectedTab] = useState(0)
  const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null)
  const [editText, setEditText] = useState({
    address: false,
    tax: false,
    brandName: false
  })
  const [loading, setLoading] = useState(false)
  const [addressModalState, setAddressModalState] = useState(false)
  const selectedClientCompany = useMemo(() => {
    return clientCompany.data.find(el => el._id === companyId)
  }, [companyId, clientCompany])

  const clientData = useSelector(state => state.userClients.currentClient)
  const agencyTeammates = useSelector(state => state.userTeammates)
  const [clientSelectOptions, setClientSelectOptions] = useState([])
  const [teammateSelectOptions, setTeammateSelectOptions] = useState([])
  const [assignUser, setAssignUser] = useState({
    anchorEl: null,
    list: [],
    handleDialog: null,
    type: 'teammate'
  })

  useEffect(() => {
    if (!allClientsData.fetched) {
      dispatch(getClient())
    }
  }, [allClientsData.fetched])

  useEffect(() => {
    setClientSelectOptions(
      allClientsData.data
        .filter(client => !(client.company && client.company._id))
        .map(item => ({
          user: { ...item },
          role: item.role
        }))
    )
  }, [allClientsData])

  useEffect(() => {
    setTeammateSelectOptions(
      agencyTeammates.data.map(item => ({
        user: { ...item },
        role: item.role
      }))
    )
  }, [agencyTeammates.data])

  const getRepresentative = () => {
    if (companyData.clientOwner && clientData?._id) {
      const representativeObj = agencyTeammates.data.find(
        mate => mate._id === clientData.agencyRepresentative
      )
      if (representativeObj) {
        return (
          <div className="flex items-center space-x-2 mt-1">
            <ColoredAvatars user={representativeObj} size="small" />
            <span className="text-custom-gray-light-6 text-sm">
              {representativeObj?.name}
            </span>
          </div>
        )
      }
      return (
        <>
          {[
            userRoles.USER_AGENCY,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER
          ].includes(meData.role) ? (
            <CustomTooltip title="Assign account manager" placement="top">
              <UserPlusIcon
                className="cursor-pointer w-4 h-4 text-primary-mid-dark mt-1"
                onClick={e => {
                  setAssignUser({
                    anchorEl: e.currentTarget,
                    list: teammateSelectOptions,
                    handleDialog: handleAgencyRepresentative,
                    type: 'teammate'
                  })
                }}
              />
            </CustomTooltip>
          ) : (
            <UserIcon className="w-4 h-4 text-primary-mid-dark mt-1" />
          )}
        </>
      )
    } else {
      return <UserIcon className="w-4 h-4 text-primary-mid-dark mt-1" />
    }
  }

  const getCompanyRepresentative = () => {
    if (
      companyData.clientOwner &&
      clientData?._id === companyData.clientOwner
    ) {
      return (
        <div className="flex items-center space-x-2 mt-1">
          <ColoredAvatars user={clientData} size="small" />
          <span className="text-custom-gray-light-6 text-sm">
            {clientData?.name}
          </span>
        </div>
      )
    }

    return (
      <>
        {[
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER
        ].includes(meData.role) ? (
          <CustomTooltip title="Assign company representative" placement="top">
            <UserPlusIcon
              className="cursor-pointer w-4 h-4 text-primary-mid-dark mt-1"
              onClick={e => {
                setAssignUser({
                  anchorEl: e.currentTarget,
                  list: clientSelectOptions,
                  handleDialog: handleCompanyRepresentative,
                  type: 'client'
                })
              }}
            />
          </CustomTooltip>
        ) : (
          <UserIcon className="w-4 h-4 text-primary-mid-dark mt-1" />
        )}
      </>
    )
  }

  const getCompanyAddress = () => {
    if (companyData.address) {
      const {
        line1,
        line2,
        city,
        state,
        country,
        zipCode
      } = companyData?.address
      return `${line1 ? `${line1}, ` : ''}${line2 ? `${line2}, ` : ''}${
        city ? `${city}, ` : ''
      }${state ? `${state}, ` : ''}${
        country.label ? `${country.label}, ` : ''
      }${zipCode ? zipCode : ''}`
    }
    return ''
  }

  const handleAgencyRepresentative = item => {
    dispatch(
      updateClient(
        clientData._id,
        {
          agencyRepresentative: item.user._id
        },
        (res, err) => {
          handleClose()
          if (!err) {
            dispatch(fireSuccessToaster('Updated Successfully'))
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleCompanyRepresentative = item => {
    dispatch(
      updateClientCompany(
        companyData._id,
        {
          newClientOwner: item.user._id
        },
        (res, err) => {
          handleClose()
          if (!err) {
            dispatch(fireSuccessToaster('Updated Successfully'))
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleClose = () => {
    setAssignUser({
      anchorEl: null,
      list: [],
      handleDialog: null,
      type: 'teammate'
    })
  }

  useBreadcrumbs([
    {
      name: 'Clients',
      link: `/clients/all`,
      index: 0
    },
    {
      name: 'Client Settings',
      link: `/clients/company`,
      index: 1
    }
  ])

  useEffect(() => {
    if (!clientCompany.fetched) {
      dispatch(getClientCompanies())
    }
  }, [clientCompany.fetched])

  useEffect(() => {
    if (!agencyTeammates.fetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [agencyTeammates.fetched])

  useEffect(() => {
    if (clientCompany.fetched) {
      setCompanyData(clientCompany.data.find(el => el._id === companyId))
    }
  }, [companyId, clientCompany.fetched, selectedClientCompany])

  useEffect(() => {
    if (allClientsData.fetched) {
      if (companyData.clientOwner) {
        dispatch({
          type: CLIENT.ADD_CURRENT_CLIENT,
          payload: companyData.clientOwner
        })
      } else {
        dispatch({
          type: CLIENT.ADD_CURRENT_CLIENT,
          payload: undefined
        })
      }
    }
  }, [companyData?.clientOwner, allClientsData.fetched])

  useEffect(() => {
    if (allClientsData.currentClient?._id && companyData.clientOwner) {
      setClientInfo(prev => ({ ...prev, loading: true }))
      fetchClientInfo(allClientsData.currentClient._id, (res, err) => {
        if (!err) {
          setClientInfo({ loading: false, data: res })
        } else {
          setClientInfo(prev => ({ ...prev, loading: false }))
          dispatch(
            fireErrorToaster('Error occurred while fetching client info!')
          )
        }
      })
    }
  }, [allClientsData.currentClient])

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userWorkspaces.fetchedFirstTime])

  const handleTabChange = newVal => {
    setSelectedTab(newVal)
  }

  const handleSidePanel = () => {
    dispatch(toggleAddClientSidePanel(true))
  }

  const handleCurrencyDropdown = e => {
    e.stopPropagation()
    setCurrencyAnchorEl(e.currentTarget)
  }

  const handleCurrency = currency => {
    dispatch(
      updateClientCompany(
        companyData._id,
        { defaultCurrency: currency },
        updateClientCompanyCallback
      )
    )
  }

  const handleCompanyData = e => {
    const { name, value } = e.target
    setCompanyData(prev => ({ ...prev, [name]: value }))
  }

  const handleBrandName = e => {
    e.preventDefault()
    if (!companyData.brandName) {
      setCompanyData(selectedClientCompany)
      return dispatch(fireErrorToaster('Brand name is required'))
    }
    setLoading(true)
    dispatch(
      updateClientCompany(
        companyData._id,
        { brandName: companyData.brandName },
        updateClientCompanyCallback
      )
    )
  }

  const handleTax = e => {
    e.preventDefault()
    setLoading(true)
    dispatch(
      updateClientCompany(
        companyData._id,
        { tax: companyData.tax },
        updateClientCompanyCallback
      )
    )
  }

  const updateClientCompanyCallback = (res, err) => {
    setLoading(false)
    setEditText({
      address: false,
      tax: false,
      brandName: false
    })
    if (!err) {
      setCompanyData(res)
      dispatch(fireSuccessToaster('Updated Successfully!'))
    } else {
      setCompanyData(selectedClientCompany)
      dispatch(fireErrorToaster(res))
    }
  }

  const imageLoadHandler = e => {
    const file = e.target.files[0]
    fileToDataURL(file).then(res => {
      // setCompanyData(prev => ({...prev, logo: res}))
      dispatch(
        updateClientCompany(
          companyData._id,
          { file: res },
          updateClientCompanyCallback
        )
      )
    })
  }

  return (
    <OuterContentWrapper>
      {companyData && (
        <>
          <Link
            to={path.split(`/company`)[0] + '/all/company'}
            className="text-blue-400 text-smaller lg:text-sm hover:underline flex items-center space-x-2"
          >
            <BackArrow
              className="h-6 w-4 text-blue-400"
              style={{ fill: 'rgba(96, 165, 250, 1)' }}
            />
            <span>Companies</span>
          </Link>
          <Wrapper className="mb-9 mt-4">
            <div
              className={clsx(
                'text-xs lg:text-sm',
                classes.companyInfoContainer
              )}
            >
              <div className="flex-1 max-w-lg">
                <div className="flex items-center mb-4">
                  <div className="relative">
                    {access?.clients?.update && (
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="user-image-uploadbtn"
                        multiple={false}
                        type="file"
                        onChange={imageLoadHandler}
                      />
                    )}
                    <label htmlFor="user-image-uploadbtn">
                      <img
                        src={
                          companyData.logo
                            ? companyData.logo.includes('https://s3')
                              ? `${companyData.logo}?${Date.now()}`?.replace(
                                  's3.wasabisys.com/',
                                  ''
                                )
                              : companyData.logo
                            : 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'
                        }
                        className="object-contain cursor-pointer"
                        style={{ maxWidth: 130, maxHeight: 60, minWidth: 60 }}
                        alt=""
                      />
                    </label>
                  </div>
                  <div className="ml-4">
                    {editText.brandName ? (
                      <div className="flex items-center space-x-1">
                        <form onSubmit={handleBrandName}>
                          <TextField
                            placeholder="Brand Name"
                            value={companyData.brandName}
                            onChange={handleCompanyData}
                            name="brandName"
                            autoFocus
                            style={{ width: '100px' }}
                          />
                        </form>
                        {!loading ? (
                          <Check
                            className="p-0.5 cursor-pointer text-primary-main hover:bg-gray-50"
                            fontSize="small"
                            onClick={handleBrandName}
                          />
                        ) : (
                          <CircularProgress
                            fontSize="small"
                            style={{ width: '10px', height: '10px' }}
                          />
                        )}
                        <CloseIcon
                          className="p-0.5 cursor-pointer text-red-500 hover:bg-gray-50"
                          fontSize="small"
                          onClick={() =>
                            setEditText(prev => ({
                              address: false,
                              tax: false,
                              brandName: !prev.brandName
                            }))
                          }
                        />
                      </div>
                    ) : (
                      <p className="text-primary-dark font-semibold capitalize text-sm md:text-base lg:text-lg flex items-center space-x-1 py-1 show-on-hover-parent">
                        <span>{companyData.brandName}</span>
                        {access?.clients?.update && (
                          <Pen
                            className="show-on-hover-child px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 opacity-0 hover:bg-gray-100"
                            onClick={() =>
                              setEditText(prev => ({
                                address: false,
                                tax: false,
                                brandName: !prev.brandName
                              }))
                            }
                          />
                        )}
                      </p>
                    )}
                    <p className=" text-custom-gray-light-6">
                      <span>{companyData.category}</span>
                    </p>
                  </div>
                </div>

                <div className=" show-on-hover-parent">
                  <p className="text-primary-dark font-medium  flex items-center space-x-2 py-1">
                    <span>Address</span>
                    {access?.clients?.update && (
                      <Pen
                        className="show-on-hover-child px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 opacity-0 hover:bg-gray-100"
                        onClick={() => setAddressModalState(true)}
                      />
                    )}
                  </p>

                  <p className="text-custom-gray-light-6 flex space-x-1">
                    {getCompanyAddress()}
                  </p>
                </div>
              </div>

              <div className={clsx(classes.companyInfoRight, 'flex-1')}>
                {/* 1. Company rep */}
                <div className="show-on-hover-parent">
                  <p className="text-primary-dark font-medium  flex items-center space-x-2">
                    <span>Company representative</span>
                  </p>
                  <div className="flex items-center space-x-1">
                    {getCompanyRepresentative()}
                    {access?.clients?.update && (
                      <CustomTooltip
                        title="Change company representative"
                        placement="top"
                      >
                        <AngleIcon
                          className="show-on-hover-child opacity-0 mt-1 h-5 w-5 p-0.5 rounded hover:bg-gray-100"
                          onClick={e => {
                            setAssignUser({
                              anchorEl: e.currentTarget,
                              list: clientSelectOptions,
                              handleDialog: handleCompanyRepresentative,
                              type: 'client'
                            })
                          }}
                        />
                      </CustomTooltip>
                    )}
                  </div>
                </div>

                {/* 2. Account Manager */}
                <div>
                  <p className="text-primary-dark font-medium ">
                    Account Manager
                  </p>
                  {getRepresentative()}
                </div>

                {/* 3. Currency */}
                <div className="show-on-hover-parent">
                  <p className="text-primary-dark font-medium ">Currency</p>
                  <p className="text-custom-gray-light-6  flex items-center space-x-2 py-1">
                    <span>{companyData.defaultCurrency?.toUpperCase()}</span>
                    {access?.clients?.update && (
                      <AngleIcon
                        className="show-on-hover-child h-5 w-5 p-0.5 rounded hover:bg-gray-100 opacity-0"
                        onClick={handleCurrencyDropdown}
                      />
                    )}
                  </p>
                </div>

                {/* 4. Tax information */}
                <div className="show-on-hover-parent">
                  <p
                    className={`text-primary-dark font-medium flex items-center space-x-2`}
                  >
                    <span>Tax Information</span>
                  </p>
                  {editText.tax ? (
                    <div className="flex items-center space-x-1">
                      <form onSubmit={handleTax}>
                        <TextField
                          size="small"
                          placeholder="Tax id"
                          value={companyData.tax}
                          onChange={handleCompanyData}
                          name="tax"
                          autoFocus
                          style={{ width: '100px' }}
                        />
                      </form>
                      {!loading ? (
                        <Check
                          className="p-0.5 cursor-pointer text-primary-main hover:bg-gray-50"
                          fontSize="small"
                          onClick={handleTax}
                        />
                      ) : (
                        <CircularProgress
                          fontSize="small"
                          style={{ width: '10px', height: '10px' }}
                        />
                      )}
                      <CloseIcon
                        className="p-0.5 cursor-pointer text-red-500 hover:bg-gray-50"
                        fontSize="small"
                        onClick={() =>
                          setEditText(prev => ({
                            address: false,
                            brandName: false,
                            tax: !prev.tax
                          }))
                        }
                      />
                    </div>
                  ) : (
                    <p className="text-custom-gray-light-6 flex items-center space-x-2 pt-1 pb-3">
                      <span>
                        {companyData.tax ? companyData.tax : 'Tax id'}
                      </span>
                      {access?.clients?.update && (
                        <Pen
                          className="show-on-hover-child px-1 pt-1 h-4 w-5 cursor-pointer border-b border-gray-600 opacity-0 hover:bg-gray-100"
                          onClick={() =>
                            setEditText(prev => ({
                              address: false,
                              brandName: false,
                              tax: !prev.tax
                            }))
                          }
                        />
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
          <div>
            <div className="w-full mb-6">
              <CustomTabs
                tabsData={[
                  { label: 'Projects', value: 0 },
                  { label: 'Members', value: 1 },
                  { label: 'Invoices', value: 2 }
                ]}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
          </div>

          <div className="">
            {selectedTab === 0 && (
              <ProjectCardsPage
                userWorkspaces={userWorkspaces.data}
                clientInfo={clientInfo}
                clientData={clientData}
                companyData={companyData}
                access={access}
                elmFor="company"
              />
            )}
            {selectedTab === 1 && (
              <TeamMembers
                clientInfo={clientInfo.data}
                setClientInfo={setClientInfo}
                elmFor="company"
              />
            )}
            {selectedTab === 2 &&
              (userPermission.access?.billings?.view ? (
                <ClientInvoices />
              ) : (
                <>
                  <div className="bg-white shadow rounded-xl text-center py-8">
                    <span className="inline-block text-primary-main">
                      <LockIcon className="w-10 h-10" />
                    </span>
                    <p>You do not have permission to view invoices</p>
                  </div>
                </>
              ))}
          </div>
        </>
      )}
      <CurrencyDropdown
        anchorEl={currencyAnchorEl}
        onClose={() => setCurrencyAnchorEl(null)}
        handleCurrency={handleCurrency}
      />
      <AddressModal
        open={addressModalState}
        handleClose={() => setAddressModalState(false)}
        company={companyData}
      />
      <CollabDrowdown
        open={Boolean(assignUser.anchorEl)}
        anchorEl={assignUser.anchorEl}
        onClose={handleClose}
        list={assignUser.list}
        handleListItemClick={assignUser.handleDialog}
        meData={meData}
        collabType={assignUser.type}
      />
    </OuterContentWrapper>
  )
}
