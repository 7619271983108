const userRoles = {
  USER_ADMIN: 1,

  PROJECT_OWNER: 15,

  USER_AGENCY: 20,
  AGENCY_MANAGER: 21,
  AGENCY_EDITOR: 22,
  AGENCY_VIEWER: 23,
  AGENCY_ADMIN: 24,

  USER_CLIENT: 30,
  CLIENT_MANAGER: 31,
  CLIENT_EDITOR: 32,
  CLIENT_VIEWER: 33,
  CLIENT_ADMIN: 34
}

export default userRoles

export const agencyRoleOptions = [
  { value: 24, label: 'Admin' },
  { value: 21, label: 'Manager' },
  { value: 22, label: 'Editor' },
  { value: 23, label: 'Viewer' }
  // { value: 20, label: 'Super Admin' }
]

export const agencyRoleOptionsForManager = [
  { value: 21, label: 'Manager' },
  { value: 22, label: 'Editor' },
  { value: 23, label: 'Viewer' }
]

export const clientRoleOptions = [
  // { value: 30, label: 'Owner' },
  { value: 31, label: 'Manager' },
  { value: 32, label: 'Editor' },
  { value: 33, label: 'Viewer' }
]

export const clientCompanyRoleOption = [
  { value: 30, label: 'Owner' },
  { value: 34, label: 'Admin' }
]

export const agencyRoleLabel = {
  20: 'Super Admin',
  21: 'Manager',
  22: 'Editor',
  23: 'Viewer',
  24: 'Admin'
}

export const clientRoleLabel = {
  30: 'Owner',
  31: 'Manager',
  32: 'Editor',
  33: 'Viewer',
  34: 'Admin'
}

export const getRoleLabel = (role) => {
  const res = [
    ...agencyRoleOptions,
    ...clientRoleOptions,
    { value: 20, label: 'Super Admin' },
    { value: 34, label: 'Admin' },
    { value: 15, label: 'Project Owner' }
  ].find((item) => item.value === role)

  return res?.label
}

export const getUserPath = (role) => {
  const arr = [
    userRoles.USER_ADMIN,
    userRoles.USER_AGENCY,
    userRoles.AGENCY_MANAGER,
    userRoles.AGENCY_EDITOR,
    userRoles.AGENCY_VIEWER,
    userRoles.AGENCY_ADMIN
  ]
  if (arr.includes(role)) return '/agency'
  else return '/client'
}
