import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as StartTimer } from 'static/svg/play.svg'
import { ReactComponent as StopTimer } from 'static/svg/stop.svg'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import { totalTimeOnTask, totalTimeOnSubtask } from 'thunks/Tracking/action'
import useTimerFunctions from './useTimerFunctions'
import TimeTrackingPopup from './TimeTrackingPopup'

const PanelTimer = ({ task, elmFor }) => {
  const dispatch = useDispatch()
  const {
    fetched: activeTimerFetched,
    data: timerData,
    taskTimer,
    subtaskTimer
  } = useSelector(state => state.timer)
  const { activeTimer, isPaused: timerIsPaused } = timerData

  const [time, setTime] = useState({
    seconds: '00',
    minutes: '00',
    hours: '00'
  })
  const [timeTrackingAnchor, setTimeTrackingAnchor] = useState(null)
  const [isActive, setIsActive] = useState(false)
  const [totalDuration, setTotalDuration] = useState(0)
  // const [timeSpend, setTimeSpend] = useState({
  //   usersTime: {},
  //   totalDuration: 0
  // })
  const timerTask = useMemo(() => {
    if (activeTimer) {
      if (activeTimer.type === 'task') return activeTimer.task
      return activeTimer.subTask
    }
    return {}
  }, [activeTimer])

  const {
    getTimeFromSeconds,
    startTimer,
    stopTimer,
    getTime
  } = useTimerFunctions()

  useEffect(() => {
    if (timerTask) {
      setIsActive(timerTask._id === task._id && !timerIsPaused)
    }
  }, [task, timerTask, timerIsPaused])

  useEffect(() => {
    // 1. if data is not fetched return
    if (!activeTimerFetched || !task?._id) return

    // 2. if current task doesn't have any active timer
    if (activeTimer === null || timerTask._id !== task._id) {
      let timer = elmFor === 'task' ? taskTimer.data : subtaskTimer.data
      let timerDuration = timer.reduce((total, curr) => total + curr.time, 0)
      const totalTime = getTimeFromSeconds(timerDuration / 1000)

      setTotalDuration(timerDuration)
      setTime(totalTime)
      return
    } else {
      // 3. if current task does have active timer
      let timerId = '',
        timerDuration =
          activeTimer.type === 'task'
            ? activeTimer.task.timerDuration ?? 0
            : activeTimer.subTask.timerDuration ?? 0

      if (!timerIsPaused) {
        timerId = getTime(activeTimer.start, timerDuration, setTime)
      } else {
        //4. if timer is paused
        clearInterval(timerId)
        const totalTime = getTimeFromSeconds(timerDuration / 1000)
        setTime(totalTime)
        setTotalDuration(timerDuration)
      }

      return () => clearInterval(timerId)
    }
  }, [
    activeTimer,
    timerTask,
    task?._id,
    timerIsPaused,
    taskTimer,
    subtaskTimer
  ])

  useEffect(() => {
    if (task._id) {
      getTotalTimeTracking() // fetching time intervals
    }
  }, [task?._id, elmFor])

  const getTotalTimeTracking = () => {
    elmFor === 'subtask'
      ? dispatch(totalTimeOnSubtask(task._id))
      : dispatch(totalTimeOnTask(task._id))
  }

  // const totalTimeCallback = (res, err) => {
  //   if (!err) {
  //     const totalDurations = Object.values(res)
  //       .map((el) => el.time)
  //       .reduce((a, b) => a + b)

  //     setTimeSpend((prev) => ({
  //       ...prev,
  //       usersTime: res,
  //       totalDuration: totalDurations / 1000
  //     }))
  //   }
  // }

  const handleStartTimer = () => {
    let data = {
      workspace: task.workSpace,
      workspaceBoard: task.workspaceBoard,
      type: elmFor
    }

    if (elmFor === 'task') {
      data.task = task._id
    } else {
      data.subTask = task._id
      data.task = task.task
    }

    startTimer(data, task.title)
  }

  const handleStopTimer = () => {
    let data = { type: elmFor }

    if (elmFor === 'task') {
      data.task = task._id
    } else {
      data.subTask = task._id
      data.task = task.task
    }

    stopTimer(data, task.title)
  }

  const toggleTimeTrackingPopup = e => {
    if (timeTrackingAnchor) {
      setTimeTrackingAnchor(null)
    } else {
      setTimeTrackingAnchor(e.currentTarget)
    }
  }

  return (
    <div>
      <p
        className="text-extraSmaller md:text-xs text-gray-500 cursor-pointer"
        onClick={toggleTimeTrackingPopup}
      >
        Time Tracked
      </p>
      <div className="flex items-center space-x-1">
        <div
          className="flex items-center cursor-pointer"
          style={{
            flexDirection: 'row-reverse',
            fontSize: '12px',
            borderRadius: '10px',
            fontWeight: 500
          }}
        >
          <div
            className="text-extraSmaller md:text-xs mx-2"
            onClick={toggleTimeTrackingPopup}
          >
            <span>{time.hours}</span>
            <span>:</span>
            <span>{time.minutes}</span>
            <span>:</span>
            <span>{time.seconds}</span>
          </div>
          <div
            style={{
              borderRadius: '50%',
              backgroundColor: isActive ? '#fd7179' : 'blue',
              cursor: 'pointer',
              padding: '4px'
            }}
            onClick={isActive ? handleStopTimer : handleStartTimer}
          >
            <CustomTooltip
              title={isActive ? 'Stop Timer' : 'Start Timer'}
              placement="top"
            >
              {isActive ? (
                <StopTimer className="h-1.5 w-1.5 text-white" />
              ) : (
                <StartTimer className="h-1.5 w-1.5 text-white" />
              )}
            </CustomTooltip>
          </div>
        </div>
      </div>

      <TimeTrackingPopup
        anchorEl={timeTrackingAnchor}
        open={Boolean(timeTrackingAnchor)}
        onClose={toggleTimeTrackingPopup}
        time={time}
        isActive={isActive}
        setIsActive={setIsActive}
        startTimer={handleStartTimer}
        stopTimer={handleStopTimer}
        // timeSpend={timeSpend}
        elmFor={elmFor}
        task={task}
        totalTimeTracking={getTotalTimeTracking}
        totalDuration={totalDuration}
      />
    </div>
  )
}

export default PanelTimer
