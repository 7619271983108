import { ThreeSixtySharp } from '@material-ui/icons'
import axios from 'axios'
import { errorMessages, getAuthToken } from 'utils'
import { AGENCY_PAYMENT } from './reducers'

/**
 * Add a new plan for agency
 * @param {object} data email,plan,quantity
 * @param {Function} callback callback function
 */
export const fetchStripeChekoutSession = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/agencypayment',
      data,
      headers: {
        Authorization: getAuthToken()
      }
    })
    if (callback) callback(res.data, false)
    return res.data
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Update plan for agency
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */

export const agencyUpgradePlan = async (data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: '/agencypayment/upgrade',
      data
    })
    callback(res.data, false)
    // return res.data
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const redeemCode = async (data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: '/coupen/update/appsumo',
      data
    })
    callback(res.data, false)
  } catch (err) {
    if (err.response.data.coupen) {
      callback(err.response.data.coupen[0]?.message, true)
    } else {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Update Card for agency
 * @param {object} data payment method object
 * @param {Function} callback callback function
 */
export const agencyUpdateCard = async (data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: 'agencypayment/update/card',
      data,
      headers: {
        Authorization: getAuthToken()
      }
    })
    callback(res.data, false)
    return res.data
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Generate invoice
 * @param {object} data
 * @param {Function} callback callback function
 */
export const generateInvoice = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/agencypayment/invoice`,
        data
      })

      dispatch({
        type: AGENCY_PAYMENT.ADD_INVOICE,
        payload: res.data.payment ?? res.data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * send payment reminder to user with pending invoice
 * @param {String} invoiceId invoice id
 * @param {Function} callback callback function
 */
export const sendPaymentReminder = async (invoiceId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/clientinvoice/sendreminder/${invoiceId}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * send payment receipt to user with pending invoice
 * @param {String} invoiceId invoice id
 * @param {Function} callback callback function
 */
export const sendPaymentReciept = async (invoiceId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/clientinvoice/sendreciept/${invoiceId}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * get card details of user
 * @param {Function} callback callback function
 */
export const fetchCardDetails = async (callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/agencypayment/card'
    })
    if (callback) callback(res.data, false)
    return res.data
  } catch (err) {
    if (callback) callback(err.response, true)
    return false
  }
}

export const fetchAllAgencyInvoices = (callback) => {
  return async (dispatch) => {
    dispatch({ type: AGENCY_PAYMENT.LOADING, payload: true })
    try {
      const res = await axios({
        url: '/agencypayment/agency/invoice',
        method: 'GET'
      })

      dispatch({
        type: AGENCY_PAYMENT.FETCH_ALL_INVOICES,
        payload: res.data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          false
        )
    }
  }
}

export const createFreePlan = async (data, token, callback) => {
  try {
    const res = await axios({
      url: 'agencypayment/freeplan',
      data,
      method: 'POST',
      headers: {
        Authorization: token
      }
    })
    if (callback) callback(res.data, false)
    return res.data
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
