import React, { useEffect, useState, useRef } from 'react'
import { Box, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import InvoiceTable from 'components/Invoices/Invoices/InvoiceTable'
import { fetchCustomInvoices } from 'thunks/invoices/actions'
import {
  Switch,
  useHistory,
  useRouteMatch,
  Route,
  useParams
} from 'react-router-dom'
// import { Pagination } from 'global/globalComponents'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { Skeleton } from '@material-ui/lab'
import InvoiceFile from 'components/Invoices/InvoiceFile'
import ReactToPrint from 'react-to-print'

// const totalInvoicesToShow = 10
export default function Payments() {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  useEffect(() => {
    dispatch(fetchCustomInvoices())
  }, [])

  return (
    <OuterContentWrapper>
      <Switch>
        <Route path={url} exact>
          <Invoices />
        </Route>
        <Route path={url + '/:invoiceId/preview'} exact>
          <InvoicePreview />
        </Route>
      </Switch>
    </OuterContentWrapper>
  )
}

const Invoices = () => {
  // const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const invoices = useSelector(state => state.clientInvoices)
  const [visibleInvoices, setVisibleInvoices] = useState({
    loading: true,
    data: []
  })
  const history = useHistory()
  const { url } = useRouteMatch()
  const search = useSelector(state => state.generalAppState.headerSearch)

  useEffect(() => {
    if (!invoices.loading) {
      let arr = invoices.data
        .filter(el => el.status !== 'draft')
        .filter(item => {
          let items = item.invoiceItems
            .map(item => item.name.toLowerCase())
            .join(' ')
          return (
            item.status.toLowerCase().includes(search.toLowerCase()) ||
            items.includes(search.toLowerCase())
          )
        })

      // const sliced = arr.splice(page * totalInvoicesToShow, totalInvoicesToShow)
      setVisibleInvoices({ loading: false, data: arr })
      // setPaginationTotalDataCount(myPayments.data.length)
    }
  }, [invoices, search])

  const openInvoicePreview = invoice => {
    history.push(`${url}/${invoice._id}/preview`)
  }

  return (
    <Box>
      <header className="mb-6 lg:mb-8">
        <SectionHeading>Invoices</SectionHeading>
      </header>
      {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
          Search results for:{' '}
          <span className="text-primary-dark">{search}</span>
        </p>
      )}

      <InvoiceTable
        invoices={visibleInvoices.data}
        openInvoicePreview={openInvoicePreview}
        loading={visibleInvoices.loading}
        elmFor="client"
        clientCol={false}
      />
    </Box>
  )
}

const InvoicePreview = () => {
  const [invoice, setInvoice] = useState({
    loading: true,
    data: {}
  })
  const history = useHistory()
  const invoices = useSelector(state => state.clientInvoices)
  const { invoiceId } = useParams()
  const invoiceRef = useRef()

  useEffect(() => {
    if (!invoices.loading) {
      const inv = invoices.data.find(item => item._id === invoiceId)

      if (inv) {
        setInvoice({ loading: false, data: inv })
      }
    }
  }, [invoices])

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <div className="px-10 w-90 mx-auto" style={{ maxWidth: 700 }}>
      {invoice.loading ? (
        <>
          <Skeleton
            variant="rect"
            width={650}
            height={600}
            className="rounded"
          />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between mb-2">
            <Button
              variant="outlined"
              startIcon={<ArrowLeftIcon />}
              onClick={handleGoBack}
            >
              Go back
            </Button>
            {invoice.data.status !== 'draft' && (
              <div className="text-center my-4">
                <ReactToPrint
                  trigger={() => {
                    return (
                      <Button
                        // size="small"
                        // className="px-3 py-2 rounded text-gray-100 text-sm inline-flex items-center gap-2"
                        style={{ backgroundColor: '#222' }}
                      >
                        Download PDF
                      </Button>
                    )
                  }}
                  content={() => invoiceRef.current}
                  documentTitle={`${
                    invoice.data.customInvoiceNumber ??
                    invoice.data.invoiceNumber
                  }_invoice`}
                />
              </div>
            )}
          </div>

          <div ref={invoiceRef}>
            <InvoiceFile invoice={invoice.data} />
          </div>
          {/* {invoice.data.status !== 'draft' && (
            <div className="text-center my-4">
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button
                      // size="small"
                      // className="px-3 py-2 rounded text-gray-100 text-sm inline-flex items-center gap-2"
                      style={{ backgroundColor: '#222' }}
                    >
                      Download PDF
                    </Button>
                  )
                }}
                content={() => invoiceRef.current}
                documentTitle={`${
                  invoice.data.customInvoiceNumber ?? invoice.data.invoiceNumber
                }_invoice`}
              />
            </div>
          )} */}
        </>
      )}
    </div>
  )
}
