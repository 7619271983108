import { useContext } from 'react'
import {
  IntakeForm,
  Video,
  Document,
  Instructions
} from './OnboardingComponents'
import { OnboardingContext } from './onboardingReducer'

const OnboardingLayout = () => {
  const { onboardingState } = useContext(OnboardingContext)
  const currentTemplate = onboardingState.currentTemplate

  const getOnboardingComponent = (item) => {
    switch (item.name) {
      case 'intakeForm':
        return <IntakeForm {...item} />
      case 'video':
        return <Video {...item} />
      case 'document':
        return <Document {...item} />
      case 'instructions':
        return <Instructions {...item} />
      default:
        return null
    }
  }

  return (
    <div>
      <header className="py-4 px-8 border-b border-gray-300">
        <h3 className="text-xl font-medium text-primary-main">
          {currentTemplate.welcomeText}
        </h3>
      </header>
      <div className="py-4 px-8">
        {currentTemplate.data.map((item) => (
          <div key={item.id} className="pt-4 mb-4">
            {getOnboardingComponent(item)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default OnboardingLayout
