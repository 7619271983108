import React, { useState } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { format } from 'date-fns'
import { ToggleSwitch } from 'global/globalComponents'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'

const WebhookTable = ({
  slackWebhooksData,
  openEditModal,
  toggleWebhookStatus,
  toggleDeleteAlert
}) => {
  return (
    <div className="mt-12">
      <TableContainer shadow="none" mb={0}>
        <Thead>
          <Th>Slack Webhook</Th>
          <Th center>Events</Th>
          <Th>Last Updated</Th>
          <Th center>Status</Th>
          <Th></Th>
        </Thead>
        <Tbody>
          {slackWebhooksData.length ? (
            slackWebhooksData.map(item => (
              <Row
                key={item._id}
                slackWebhook={item}
                openEditModal={openEditModal}
                toggleDeleteAlert={toggleDeleteAlert}
                toggleWebhookStatus={toggleWebhookStatus}
                isLoading={false}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>No connected Webhook</Td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
    </div>
  )
}

const Row = ({
  slackWebhook,
  openEditModal,
  toggleDeleteAlert,
  toggleWebhookStatus
}) => {
  const [loading, setLoading] = useState(false)
  const handleOpenEditModal = () => {
    openEditModal(slackWebhook)
  }

  const handleDeleteWebhook = () => {
    toggleDeleteAlert(slackWebhook)
  }

  const handleStatusChange = e => {
    setLoading(true)
    toggleWebhookStatus(e, () => {
      setLoading(false)
    })
  }

  return (
    <Tr>
      <Td
        className="text-primary-mid-dark font-medium font-bold cursor-pointer"
        onClick={handleOpenEditModal}
      >
        {slackWebhook.name}
      </Td>
      <Td center className="font-semibold text-gray-500">
        {slackWebhook.events?.length}
      </Td>
      <Td className="text-custom-gray-main text-xs font-semibold">
        {format(new Date(slackWebhook?.updatedAt ?? ''), 'do MMM, yyyy')}
      </Td>
      <Td center>
        <ToggleSwitch
          onChange={handleStatusChange}
          loading={loading}
          name={slackWebhook._id}
          checked={slackWebhook?.isActive}
        />
      </Td>
      <Td center>
        <button
          onClick={handleDeleteWebhook}
          className="w-8 h-8 border rounded-full inline-flex justify-center items-center"
        >
          <TrashIcon className="text-lg" />
        </button>
      </Td>
    </Tr>
  )
}

export default WebhookTable
