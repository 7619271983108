import React, { useState } from 'react'
import { TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import { changePassword } from 'thunks/user/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { useBreadcrumbs } from 'customHooks'
import { userRoles } from 'utils'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import Apikey from '../ApiKey/ApiKey'

const Security = () => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)

  const [loading, setLoading] = useState(false)
  const [passwordData, setPassword] = useState({
    password: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const [passwordValid, setPasswordValid] = useState(true)

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/${
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER
        ].includes(meData?.role)
          ? 'general'
          : 'profile'
      }`,
      index: 0
    },
    {
      name: 'Security',
      link: `/settings/security`,
      index: 1
    }
  ])

  const handleChange = e => {
    setPassword(prev => ({ ...prev, [e.target.name]: e.target.value }))
    if (e.target.name === 'newPassword') {
      setPasswordValid(true)
    }
  }

  const changePasswordCallback = () => {
    setLoading(false)
    setPassword(prev => ({
      ...prev,
      password: '',
      newPassword: '',
      confirmNewPassword: ''
    }))
  }
  const handleSave = () => {
    if (!passwordData.password) {
      return dispatch(fireErrorToaster('Please enter your old password !'))
    }
    if (!passwordData.newPassword) {
      return dispatch(fireErrorToaster('Please enter your new password !'))
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return dispatch(
        fireErrorToaster(
          'OOPS Weak Password ! Password must contains at-least 8 characters!'
        )
      )
    }
    if (!passwordData.confirmNewPassword) {
      return dispatch(fireErrorToaster('Please confirm your new password !'))
    }
    if (passwordData.newPassword === passwordData.confirmNewPassword) {
      setLoading(true)
      dispatch(changePassword(passwordData, changePasswordCallback))
    } else {
      dispatch(fireErrorToaster("Password did't match !"))
    }
  }

  const validPasswordHandler = () => {
    const password = passwordData.newPassword
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  return (
    <>
      <Wrapper className="mb-12">
        <SecondaryHeading className="mb-6 lg:mb-8">
          Update Password
        </SecondaryHeading>
        <form className="w-full">
          <div className="flex justify-between items-center space-x-4">
            <div className="w-full">
              <FormLabel required>Old Password</FormLabel>
              <TextField
                required
                className="w-full"
                name="password"
                type="password"
                placeholder="Enter your old password"
                value={passwordData.password}
                onChange={handleChange}
              />
            </div>

            <div className="w-full">
              <FormLabel required>New Password</FormLabel>
              <TextField
                required
                className="w-full"
                name="newPassword"
                type="password"
                value={passwordData.newPassword}
                placeholder="Enter your new password"
                onChange={handleChange}
                onBlur={validPasswordHandler}
              />
            </div>
            {passwordData.newPassword && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </div>
          <div className="mt-4 w-full md:w-1/2 md:pr-2">
            <FormLabel required>Confirm New Password</FormLabel>
            <TextField
              required
              name="confirmNewPassword"
              className="w-full"
              type="password"
              placeholder="Confirm your new password"
              value={passwordData.confirmNewPassword}
              onChange={handleChange}
            />
          </div>

          <div className="mt-12 text-right">
            <LoadingButton
              loading={loading}
              onClick={handleSave}
              className="w-52"
            >
              Update Password
            </LoadingButton>
          </div>
        </form>
      </Wrapper>

      {[userRoles.USER_AGENCY].includes(meData?.role) && <Apikey />}
    </>
  )
}

export default Security
