import { makeStyles } from '@material-ui/core/styles'

const PoweredBy = (props) => {
  const classes = useStyles(props)

  return (
    <a
      href="https://clientvenue.com"
      target="_blank"
      className={`flex items-center justify-center text-sm py-4 ${classes.root}`}
    >
      <span className="text-gray-700">Powered by</span>
      <img
        className={classes.logo}
        src="https://assets.clientvenue.com/other/clientvenue-icon.png"
        alt=""
      />
      <span className="text-black text-custom-dark-main font-semibold">
        ClientVenue
      </span>
    </a>
  )
}

const useStyles = makeStyles({
  root: (props) => ({
    marginTop: props.marginTop || 0,
    width: '200px',
    marginLeft: 'auto',
    marginRight: 'auto'
  }),
  logo: {
    width: 20,
    height: 20,
    margin: '0 0.25rem 0 0.5rem'
  }
})

export default PoweredBy
