import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Popover } from '@material-ui/core'
import CellWrapper from './CellWrapper'
import { AdvanceEditor } from 'global/globalComponents'
import styles from './table.module.css'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { ReactComponent as TextIcon } from 'static/svg/text-left.svg'
import { getQuillHTML } from 'utils'
import { isEqual } from 'lodash'

const DescriptionCell = (props) => {
  const {
    value,
    row: { index, values, original },
    column: { id, fieldType },
    updateDynamicColData,
    updateTaskProperty,
    uploadTaskAttachment,
    // handleUploadAttachment,
    handleUploadDynamicAttachment,
    taskPermission
  } = props
  const [anchorEl, setAnchorEl] = useState()
  const [description, setDescription] = useState('')
  const [prevTaskId, setPrevTaskId] = useState('')
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const editorRef = useRef()
  const [htmlValue, textValue] = useMemo(() => {
    let html, text

    if (typeof description === 'string') {
      text = description
      html = `<div>${description}<div>`
    } else {
      html = getQuillHTML(description, 'html')
      text = getQuillHTML(description)
    }
    return [html, text]
  }, [description])

  const users = useMemo(() => {
    if (currentWorkspace.assignedAgencyTeam) {
      return [
        ...currentWorkspace.assignedAgencyTeam,
        ...currentWorkspace.assignedClientTeam
      ].map((item) => ({
        ...item.user,
        id: item.user._id,
        value: item.user.name
      }))
    }
  }, [currentWorkspace])

  useEffect(() => {
    if (original._id !== prevTaskId) {
      setDescription(value)
      setPrevTaskId(original._id)
    }
  }, [value])

  const handleOpenPopup = (e) => {
    setAnchorEl(e.currentTarget)
  }

  // const handleDescriptionChange = (e) => {
  //   setDescription(e.target.value)
  // }

  const handleDescriptionChange = (content, delta, source, editor) => {
    setTimeout(() => {
      const editor = editorRef.current.editor
      setDescription(editor.getContents())
    }, 100)
  }

  const handleClose = () => {
    if (fieldType === 'static') {
      updateTaskProperty({
        taskId: original._id,
        workspaceId: original.workSpace,
        taskSection: original.taskSection,
        updateData: { description }
      })
    } else {
      updateDynamicColData({
        rowIndex: index,
        columnId: id,
        value: description,
        allRowValues: values,
        taskId: original.taskId
      })
    }

    setAnchorEl(null)
  }

  const handleUploadFile = (imageDataUrl, type, imageData) => {
    const editor = editorRef.current.getEditor()
    let index = (editor.getSelection() || {}).index
    let loadingText = 'uploading...'

    if (index === undefined || index < 0) {
      index = editor.getLength()
    }

    editor.insertText(index, loadingText)

    if (fieldType === 'static') {
      uploadTaskAttachment({
        taskId: original._id,
        workspaceId: original.workSpace,
        file: imageDataUrl,
        cb: imageUploadCallback
      })
      // handleUploadAttachment({
      //   rowIndex: index,
      //   columnId: id,
      //   value: imageDataUrl,
      //   taskId: original.taskId,
      //   cb: imageUploadCallback
      // })
    } else {
      handleUploadDynamicAttachment({
        rowIndex: index,
        columnId: id,
        value: imageDataUrl,
        allRowValues: values,
        taskId: original.taskId,
        cb: imageUploadCallback
      })
    }
  }

  const imageUploadCallback = (res, err) => {
    if (err) return

    const editor = editorRef.current.getEditor()
    editor.deleteText(index, 12)
    editor.insertEmbed(
      index,
      'image',
      res.imageUrl[res.imageUrl.length - 1],
      'user'
    )

    setDescription(editor.getContents())
  }

  return (
    <>
      <CellWrapper
        onClick={taskPermission.update ? handleOpenPopup : undefined}
        styles={{ minWidth: 250 }}
        className="px-2"
      >
        {textValue.trim() ? (
          <div className={clsx('w-full', styles.descriptionCell)}>
            <div
              className="text-xs"
              dangerouslySetInnerHTML={{ __html: htmlValue }}
            />
          </div>
        ) : (
          <div
            style={{ minHeight: 'inherit' }}
            className="w-full h-full show-on-hover-parent flex justify-center items-center"
          >
            <TextIcon className="w-4 h-4 show-on-hover-child" />
          </div>
        )}
      </CellWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className="w-72 bg-white p-2 rounded text-xs description-cell">
          <AdvanceEditor
            value={description}
            onKeyDown={handleDescriptionChange}
            handleKeyPress={handleDescriptionChange}
            readOnly={!taskPermission.update}
            users={users}
            ref={editorRef}
            imageDrop={true}
            imageHandler={handleUploadFile}
          />
        </div>
      </Popover>
    </>
  )
}

// ===== Prevents unnecessary re-rendering ====
const canRender = (prevState, nextState) => {
  return isEqual(prevState.value, nextState.value)
}

export default React.memo(DescriptionCell, canRender)
