import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { dateAndTimeHelper } from 'utils'

const useReportsFunctions = () => {
  const meData = useSelector((state) => state.me.data)

  const getMappedReportsData = useCallback((data) => {
    let projectCols = {}

    // ===== Setting Cols Header (projects) =======
    for (let user of data) {
      for (let project of user.assignedWorkspace) {
        if (!projectCols[project._id]) {
          projectCols[project._id] = {
            Header: () => (
              <span className="text-center">
                {project.name} <br />
                <span
                  className="inline-block font-medium mt-1 capitalize"
                  style={{ fontSize: 10 }}
                >
                  Tasks (completed / total)
                </span>
              </span>
            ),
            label: project.name,
            align: 'center',
            accessor: project._id,
            Cell: (props) =>
              props.value?.completedTasks === undefined &&
              props.value?.totalTasks === undefined ? (
                <div className="text-center px-6 py-4">-</div>
              ) : (
                <div className="text-center px-6 py-4">
                  <span className="text-custom-green-main">
                    {props.value.completedTasks}
                  </span>
                  /
                  <span className="text-custom-blue-main">
                    {props.value.totalTasks}
                  </span>
                </div>
              )
          }
        }
      }
    }

    // ===== Setting Cols Value =======
    const mappedData = data.map((user) => {
      let totalDuration = 0
      let totalCompletedTasks = user.assignedWorkspace.reduce((total, curr) => {
        return total + curr.completedTaskData.length
      }, 0)

      for (let timeObj of user.timeTracking) {
        totalDuration += timeObj.duration
      }

      const obj = {
        Name: user.name,
        Email: user.email,
        Role: user.verticals ? user.verticals.map((item) => item.name) : [],
        'Total tasks': user.totalTaskAssigned,
        'Total tasks completed': totalCompletedTasks,
        Completion:
          Math.round((totalCompletedTasks / user.totalTaskAssigned) * 100) || 0,
        'Time tracked': (totalDuration / (1000 * 60 * 60)).toFixed(2) //in hours
      }

      for (let project of user.assignedWorkspace) {
        obj[project._id] = {
          completedTasks: project.completedTaskData.length,
          totalTasks:
            project.completedTaskData.length +
            project.inCompletedTaskData.length
        }
      }

      return obj
    })

    return { mappedData, projectCols: Object.values(projectCols) }
  }, [])

  const getTimelineQuery = (value) => {
    const startDate = new Date()
    const endDate = new Date()
    const today = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    )

    switch (value) {
      case 'last7Days': {
        startDate.setDate(today.getDate() - 7)
        startDate.setHours(0, 0, 0)
        endDate.setHours(23, 59, 59)
        break
      }
      case 'last14Days': {
        startDate.setDate(today.getDate() - 14)
        startDate.setHours(0, 0, 0)
        endDate.setHours(23, 59, 59)
        break
      }
      case 'last30Days': {
        startDate.setDate(today.getDate() - 30)
        startDate.setHours(0, 0, 0)
        endDate.setHours(23, 59, 59)
        break
      }
      case 'last60Days': {
        startDate.setDate(today.getDate() - 60)
        startDate.setHours(0, 0, 0)
        endDate.setHours(23, 59, 59)
        break
      }
      case 'last90Days': {
        startDate.setDate(today.getDate() - 90)
        startDate.setHours(0, 0, 0)
        endDate.setHours(23, 59, 59)
        break
      }
      default:
        return ''
    }

    return `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
  }

  const getTrackedTime = useCallback((trackedTimeArr = [], userId) => {
    return trackedTimeArr
      .filter((item) => item.user === userId)
      .reduce((prev, curr) => {
        return prev + curr.duration
      }, 0)
  }, [])

  const getCostByTrackedTime = useCallback(
    ({ trackedTimeInMs, hourlyRate }) => {
      const minuteRate = Math.round(hourlyRate / 60)
      const { hours, minutes } = dateAndTimeHelper.getMinutesAndHours(
        trackedTimeInMs
      )
      return hours * hourlyRate + minutes * minuteRate
    },
    []
  )

  return {
    getMappedReportsData,
    getTimelineQuery,
    getTrackedTime,
    getCostByTrackedTime
  }
}

export default useReportsFunctions
