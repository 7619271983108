import React, { useCallback } from 'react'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { useDispatch } from 'react-redux'
import UserDashboardStyles from 'components/UserDashboard/userDashboardStyles'
import clsx from 'clsx'

const AssignedWorkspaces = ({ teammate, userProjects, setUserProjects }) => {
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()

  const unassignProject = (id) => {
    setUserProjects((prev) => prev.filter((item) => item._id !== id))
  }

  return (
    <>
      <div
        className={`relative px-2 py-4 flex flex-wrap justify-start items-center gap-2 text-xs font-medium leading-3`}
        style={{ maxHeight: '100px', overflowY: 'auto' }}
      >
        {userProjects.length > 0 ? (
          userProjects?.map((item) => (
            <SingleProject
              key={item.name}
              {...item}
              unassignProject={unassignProject}
              classes={classes}
            />
          ))
        ) : (
          <p className="text-gray-300 text-sm">assigned projects</p>
        )}
      </div>
    </>
  )
}

const SingleProject = React.memo(({ _id, name, unassignProject, classes }) => {
  const handleRemoveProject = (e) => {
    e.stopPropagation()
    unassignProject(_id)
  }

  return (
    <div
      className="relative py-1 px-2 rounded-full border border-current show-on-hover-parent"
      style={{
        padding: '2px 7px',
        borderRadius: '100vh',
        fontWeight: 500,
        backgroundColor: '#ECEAFF'
      }}
    >
      <span className="font-medium capitalize text-tiny">{name}</span>
      <button
        onClick={handleRemoveProject}
        size="small"
        className={clsx(
          'absolute top-0 -right-1 show-on-hover-child text-custom-red-main bg-white rounded-full',
          classes.removeAssigneeBtn
        )}
      >
        <CancelRoundedIcon fontSize="small" />
      </button>
    </div>
  )
})

export default AssignedWorkspaces
