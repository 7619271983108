import React, { useEffect, useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton, RoundedTextInput } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import UserDashboardStyles from '../userDashboardStyles'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import Currency from 'static/Currency/Currency'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Select from 'react-select'
import { ToggleSwitch, CustomTooltip } from 'global/globalComponents'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import clsx from 'clsx'
import { ReactComponent as QuestionMarkIcon } from 'static/svg/question-circle.svg'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import { errorMessages, userRoles } from 'utils'
import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import { ReactComponent as ShareIcon } from 'static/svg/share-icon.svg'

const clrPrimary = '#1600E5'
const clrPrimaryLight = '#F3F2FF'

const EditWorkspaceDialog = props => {
  const { workspace, open, handleDialog } = props
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    team: '',
    description: '',
    showPendingTask: false,
    category: 'Internal',
    restrictDueDate: {
      isDueDateRestrict: false,
      totalDueDateChangeCounter: null
    },
    restrictMode: false
  })
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false)

  const meData = useSelector(state => state.me.data)

  useEffect(() => {
    let arr = Object.keys(Currency).map(el => ({
      value: el,
      label: el
    }))
    setCurrencyOptions(arr)
  }, [])

  useEffect(() => {
    if (typeof workspace.currency === 'string') {
      setSelectedCurrency({
        label: workspace.currency.toUpperCase(),
        value: workspace.currency.toUpperCase()
      })
    }
  }, [workspace])

  useEffect(() => {
    setFormData({
      name: workspace.name,
      description: workspace.description,
      category: workspace.category,
      showPendingTask: workspace.showPendingTask ?? false,
      restrictDueDate: workspace.restrictDueDate ?? {
        isDueDateRestrict: false,
        totalDueDateChangeCounter: null
      },
      restrictMode: workspace.restrictMode
    })
  }, [workspace])

  const formInputChangeHandler = e => {
    const { name, value } = e.target
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const saveChangesHandler = e => {
    if (formData.name.length) {
      if (
        formData.restrictDueDate.isDueDateRestrict &&
        (formData.restrictDueDate.totalDueDateChangeCounter < 0 ||
          !formData.restrictDueDate.totalDueDateChangeCounter)
      ) {
        return dispatch(
          fireErrorToaster(
            'Please enter the valid number in due Date edit field'
          )
        )
      }
      if (formData.restrictDueDate.isDueDateRestrict) {
        formData.restrictDueDate.totalDueDateChangeCounter = Number(
          formData.restrictDueDate.totalDueDateChangeCounter
        )
      }
      setLoading(true)
      dispatch(
        updateWorkSpace1(
          workspace._id,
          {
            ...formData,
            currency: selectedCurrency.value
            // paymentType: selectedPaymentType.value,
            // paymentAmount: parseFloat(paymentAmount) || 0
          },
          'name, description and currency',
          saveChangesCallback
        )
      )
    } else {
      dispatch(fireErrorToaster('Empty project name is not allowed'))
    }
  }

  const saveChangesCallback = (err, res) => {
    handleDialog()
    setLoading(false)
    setFormData(prevState => ({
      ...prevState,
      name: '',
      team: '',
      description: '',
      category: 'Internal',
      restrictDueDate: {
        isDueDateRestrict: false,
        totalDueDateChangeCounter: null
      }
    }))
  }

  const selectCurrency = newVal => {
    setSelectedCurrency(newVal)
  }

  const toggleAdvanceSettingsOption = () => {
    setShowAdvanceSettings(prev => !prev)
  }

  const handleSwitch = () => {
    setFormData(prevState => ({
      ...prevState,
      category: prevState.category === 'Internal' ? 'External' : 'Internal'
    }))
  }

  return (
    <CustomModal open={open} handleClose={handleDialog} size="small">
      <CustomModalHeader
        heading="Project Settings"
        // handleClose={handleDialog}
        icon={
          <ShareIcon
            width={40}
            height={40}
            className="text-primary-main bg-custom-blue-light rounded-lg"
          />
        }
      />
      <CustomModalBody>
        <Box component="form" className="py-4 ">
          <div>
            <div className="mb-4">
              <FormLabel
                required
                htmlFor="workspaceName"
                className="text-custom-gray-dark-3 font-semibold"
              >
                Name of Project
              </FormLabel>
              <RoundedTextInput
                id="workspaceName"
                name="name"
                value={formData.name}
                onChange={formInputChangeHandler}
                className={classes.selectBox}
                required
              />
            </div>
            <div className="mt-4">
              <FormLabel
                htmlFor="workspaceDescription"
                className="text-custom-gray-dark-3 font-semibold"
              >
                Description
              </FormLabel>
              <RoundedTextInput
                name="description"
                id="workspaceDescription"
                onChange={formInputChangeHandler}
                style={{ width: '100%' }}
                // multiline
                // rows={3}
                // rowsMax={6}
                value={formData.description}
                theme={theme => ({
                  ...theme,
                  borderRadius: '5px',

                  colors: {
                    ...theme.colors,
                    primary25: '#F9F9F9',
                    primary: '#e6e6e6'
                  }
                })}
              />
            </div>

            <div className="mt-3 w-full">
              <FormLabel className="text-custom-gray-dark-3 font-semibold">
                Currency
              </FormLabel>

              <Select
                menuPlacement="top"
                styles={{
                  ...reactSelectCustomStyles,
                  control: (base, state) => ({
                    ...base,
                    borderRadius: '35px',
                    paddingLeft: 3,
                    paddingRight: 3,
                    '&:focus-within': {
                      borderColor: clrPrimary,
                      boxShadow: `0 0 0 1px ${clrPrimary}`
                    }
                  })
                }}
                options={currencyOptions}
                onChange={selectCurrency}
                value={selectedCurrency}
                maxMenuHeight={150}
                placeholder="Select"
                className="border-0 border-none w-full"
              />
            </div>
            <div className="flex items-center justify-between mt-3 w-full">
              <FormLabel className="text-custom-gray-dark-3 font-semibold">
                Client Project
              </FormLabel>

              <div className="">
                <ToggleSwitch
                  checked={formData?.category === 'External'}
                  onChange={handleSwitch}
                  disableRipple={true}
                />
              </div>
            </div>
          </div>

          {/* <hr className="mt-8 mb-4 border-dashed" /> */}

          <div className="mt-4">
            <button
              className="text-smaller lg:text-sm text-primary-main font-bold"
              // className="text-sm text-custom-gray-dark-3 font-bold"
              onClick={toggleAdvanceSettingsOption}
              type="button"
            >
              Advance Settings
              <span
                className={clsx(
                  'inline-block transition-transform duration-200 ease-in-out',
                  showAdvanceSettings && 'rotate-180'
                )}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </span>
            </button>
          </div>

          {showAdvanceSettings && (
            <AdvanceSettings
              meData={meData}
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </Box>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton loading={loading} onClick={saveChangesHandler}>
          Save Changes
        </LoadingButton>
        <Button onClick={handleDialog} variant="outlined">
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

const AdvanceSettings = ({ meData, formData, setFormData }) => {
  const togglePendingTasksVisibility = e => {
    setFormData(prev => ({ ...prev, showPendingTask: e.target.checked }))
  }

  const toggleDueDateChangeHandler = e => {
    if (e.target.checked) {
      setFormData(prev => ({
        ...prev,
        restrictDueDate: {
          ...formData.restrictDueDate,
          isDueDateRestrict: e.target.checked
        }
      }))
    } else {
      setFormData(prev => ({
        ...prev,
        restrictDueDate: {
          isDueDateRestrict: e.target.checked,
          totalDueDateChangeCounter: null
        }
      }))
    }
  }

  const handleDueDateChange = e => {
    setFormData(prev => ({
      ...prev,
      restrictDueDate: {
        ...formData.restrictDueDate,
        totalDueDateChangeCounter: e.target.value
      }
    }))
  }

  const toggleRestrictMode = e => {
    setFormData(prev => ({
      ...prev,
      restrictMode: e.target.checked
    }))
  }

  return (
    <div className="mt-2">
      {/* <div className="flex items-center justify-between py-1">
        <p className="text-sm text-custom-gray-dark-3 font-semibold ">
          Show pending tasks of other boards
        </p>

        <div>
          <ToggleSwitch
            checked={formData.showPendingTask}
            onChange={togglePendingTasksVisibility}
            loading={false}
          />
        </div>
      </div> */}
      <div className="flex items-center justify-between py-1">
        <div>
          <p className="text-smaller lg:text-sm text-custom-gray-dark-3 font-semibold ">
            Restrict users to change the due-date of any task
          </p>
          {/* <p className="text-custom-gray-dark-4 text-xs">
            Number of times a user can change Due-date
          </p> */}
        </div>

        <div>
          <ToggleSwitch
            checked={formData.restrictDueDate.isDueDateRestrict}
            onChange={toggleDueDateChangeHandler}
            loading={false}
          />
        </div>
      </div>

      {formData.restrictDueDate.isDueDateRestrict && (
        <div className="w-48">
          <RoundedTextInput
            size="tiny"
            name="dueDate"
            value={formData?.restrictDueDate.totalDueDateChangeCounter}
            type="number"
            onChange={handleDueDateChange}
            placeholder="User can change Due-date"
            id="dueDate"
          />
        </div>
      )}

      {[
        userRoles.USER_AGENCY,
        userRoles.AGENCY_ADMIN,
        userRoles.AGENCY_MANAGER
      ].includes(meData?.role) && (
        <div className="flex items-center justify-between py-1">
          <div className="flex items-center">
            <p className="text-smaller lg:text-sm text-custom-gray-dark-3 font-semibold  mr-2">
              Restrict mode
            </p>
            <QuestionMarkTooltip
              text="Members will be able to see only the tasks assigned to them"
              direction="right"
              toolTipstyles="lowercase"
              info={true}
            />
          </div>

          <div>
            <ToggleSwitch
              checked={formData.restrictMode}
              loading={false}
              onChange={toggleRestrictMode}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default EditWorkspaceDialog
