import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { updateCustomInvoice } from 'thunks/invoices/actions'

const useInvoiceFunctions = () => {
  const dispatch = useDispatch()
  const [invoiceUpdateLoading, setInvoiceUpdateLoading] = useState(false)

  const handleUpdateInvoice = useCallback(
    ({
      editInvoice,
      fromToData,
      invoiceItemsTotal,
      taxDetails,
      expenses,
      invoiceItems,
      cb
    }) => {
      if (editInvoice.customInvoiceNumber === '')
        return dispatch(fireErrorToaster('Invoice number is required!'))
      if (!fromToData.agency.agencyName)
        return dispatch(fireErrorToaster('Agency name is required!'))
      if (!fromToData.client.clientName)
        return dispatch(fireErrorToaster('Client name is required!'))

      if (!fromToData.client.clientEmail)
        return dispatch(fireErrorToaster('Client email is required!'))

      if (parseFloat(invoiceItemsTotal.grandTotal) < 0) {
        return dispatch(fireErrorToaster('Invoice amount cannot be negative!'))
      }

      let emptyItem = invoiceItems.find(
        (item) => item.name === '' || item.quantity === '' || item.rate === ''
      )

      if (emptyItem) {
        return dispatch(fireErrorToaster('Please fill all the items fields!'))
      }

      setInvoiceUpdateLoading(true)
      dispatch(
        updateCustomInvoice(
          editInvoice._id,
          {
            ...editInvoice,
            ...fromToData,
            client: {
              ...fromToData.client,
              client_cc_email: fromToData.client.client_cc_email.filter(
                (item) => item !== ''
              )
            },
            taxDetail: taxDetails,
            discountAmount: parseFloat(expenses.discount),
            taxPercent: parseFloat(expenses.tax),
            totalAmount: parseFloat(invoiceItemsTotal.grandTotal),
            invoiceItems: invoiceItems
              .map((item, index) => {
                const itemClone = { ...item }
                itemClone.position = index
                itemClone.quantity = +item.quantity
                itemClone.rate = parseFloat(item.rate)
                return itemClone
              })
              .filter((item, index) => item.invoiceId || index === 0)
          },
          (res, err) => {
            setInvoiceUpdateLoading(false)
            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(fireSuccessToaster('Changes saved!'))
              if (cb) cb(res, err)
            }
          }
        )
      )
    },
    []
  )

  return { invoiceUpdateLoading, handleUpdateInvoice }
}

export default useInvoiceFunctions
