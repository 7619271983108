import React, {
  // forwardRef,
  useEffect,
  useState,
  useContext,
  // useMemo,
  useCallback,
  useMemo
} from 'react'
import { Draggable } from 'react-beautiful-dnd'
// import DatePicker from 'react-datepicker'
import 'react-datetime/css/react-datetime.css'
import { useSelector } from 'react-redux'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as CheckCircleFilled } from 'static/svg/check-circle-filled.svg'
import 'react-datepicker/dist/react-datepicker.css'
// import {
//   // deleteTaskCollaborator,
//   updateTaskDetails,
//   // updateTaskDetailsteam,
//   updateTaskPriority
// } from 'thunks/task/actions'
import { CustomFlag } from 'global/globalComponents'
// import { ReactComponent as Repeat } from 'static/svg/repeat.svg'
import // fireErrorToaster
// fireSuccessToaster
'thunks/fireToaster/actions'
import clsx from 'clsx'
import BoardsStyles from '../BoardsStyles'
// import { ReactComponent as TagsIcon } from 'static/svg/tags.svg'
// import Tags from 'components/UserDashboard/Workspaces/Tags'
// import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
// import { postTaskLog } from 'thunks/logs/action'
// import { createAccountLogs } from 'thunks/accountLogs/action'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
// import TaskCardAssignee from './TaskCardAssignee'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
// import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { stopEventPropagation, adjustColor } from 'utils'
import { useParams } from 'react-router-dom'
import { ReactComponent as StartTimer } from 'static/svg/play-circle (1).svg'
// import RecurringDropDown from 'components/Workspace/RecurringDropDown'
import { ReactComponent as ClockIcon } from 'static/svg/clock.svg'
// import { ReactComponent as FlagOutlined } from 'static/svg/pennant-outlined.svg'
// import { ReactComponent as VerticalThreeDotIcon } from 'static/svg/verticalThreeDot.svg'
// import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
// import { setHours } from 'date-fns'
import { ColoredAvatarGroup } from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { format } from 'date-fns'
import Pill from 'global/globalComponents/Pill/Pill'

function Task(props) {
  const {
    task,
    openSidePanel,
    index,
    // aboveTask,
    // // titleInputOnBlur,
    // meData,
    // getCollabOptions,
    markAsComplete
    // removeCollaborator,
    // addCollaborator,
    // handleDueDateChange
  } = props
  const classes = BoardsStyles()
  // const dispatch = useDispatch()
  // const [collabAnchorEl, setCollabAnchorEl] = useState(null)
  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  const timer = useSelector(state => state.dashboard.timer)
  const taskTags = useSelector(state => state.userTags.currentWorkspaceTags)
  // const { task: taskPermission } = useWorkspacePermission(currentWorkspace?._id)
  const { workspaceState } = useContext(SingleWorkspaceContext)
  const { taskPermission } = workspaceState.permission

  // const [anchorEl, setAnchorEl] = useState(null)
  // const [popperOpen, setPopperOpen] = useState('')

  const [taskState, setTaskState] = useState({
    creator: '',
    title: '',
    workSpace: '',
    taskSection: '',
    dueDate: `${new Date()}`,
    description: ' ',
    imageUrl: [],
    status: 0
  })
  // const [openPopper, setOpenPopper] = useState(false)
  // const [collabOptions, setCollabOptions] = useState([])
  // const [isActive, setIsActive] = useState(false)
  const [allCollaborators, setAllCollaborators] = useState([])
  const [assignedTags, setAssignedTags] = useState([])
  const [exTags, setExTags] = useState('')
  // const [expanded, setExpanded] = useState(false)
  const { taskId } = useParams()

  // const [tagsPopoverData, setTagsPopoverData] = useState({
  //   open: false,
  //   anchorEl: null,
  //   expanded: false
  // })
  // const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTaskState(task)
  }, [task])

  useEffect(() => {
    let tags = taskTags.filter(tag => tag.taskIds.includes(task._id))
    if (tags.length > 3) {
      setAssignedTags(tags.slice(0, 3))
      setExTags(tags.length - 3)
    } else {
      setAssignedTags(tags)
      setExTags('')
    }
  }, [task._id, taskTags])

  useEffect(() => {
    /*
    Here i'm storing all the assigned task members in this allCollaborators array
    this way we don't have to repeat the same code twice.
  */
    const arr = []

    if (task.assignedAgencyTeam) {
      arr.push(...task.assignedAgencyTeam)
    }
    if (task.assignedClientTeam) {
      arr.push(...task.assignedClientTeam)
    }

    setAllCollaborators(arr)
  }, [task])

  // const handleDateChange = (newDate) => {
  //   // params => newDate, task, aboveTask, cb
  //   handleDueDateChange(newDate, task, aboveTask, (res, err) => {
  //     if (!err) {
  //       dispatch(
  //         postTaskLog({
  //           description: `updated the due date in the task <b>${task.title}</b>`,
  //           activityType: 'log',
  //           task: task._id
  //         })
  //       )
  //       dispatch(
  //         createAccountLogs({
  //           description: `updated the due date of the task <strong>${task.title}</strong> in the project <strong>${currentWorkspace?.name}</strong>`,
  //           category: 'task',
  //           task: task._id
  //         })
  //       )
  //     }
  //   })
  // }
  // const handleClose = () => {
  //   // setOpenPopper('')
  //   setCollabAnchorEl(null)
  // }

  // const handleCollaborator = (userObj) => {
  //   handleClose()
  //   // params => userRole, userId, taskId, cb
  //   addCollaborator(
  //     userObj.role.value,
  //     userObj.user._id,
  //     task._id,
  //     (res, err) => {
  //       if (!err) setTaskState(res)
  //     }
  //   )
  // }

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     inputRef.current?.blur()
  //   }
  // }

  const handleCardClick = () => {
    openSidePanel(task)
  }

  // const toggleCollabPopover = (target) => {
  //   if (!collabAnchorEl) {
  //     setCollabOptions(getCollabOptions(task))
  //     setCollabAnchorEl(target)
  //   } else {
  //     setCollabAnchorEl(null)
  //   }
  // }

  // const handleRemoveCollab = useCallback(
  //   (assignee) => {
  //     removeCollaborator(assignee, task._id, (res, err) => {
  //       if (err) {
  //         dispatch(fireErrorToaster(res))
  //       }
  //     })
  //   },
  //   [task._id]
  // )

  // const handleCollabCallback = (res, err) => {
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   }
  // }

  const handleMarkAsComplete = useCallback(
    val => {
      markAsComplete(val, task)
    },
    [task]
  )

  // const recurringTimeHandler = (time) => {
  //   const recurringData = Object.values(recurringTime)
  //   const recurringAt = recurringData.find((el) => el[1] === time)
  //   return recurringAt[0]
  // }

  // const triggerPopover = (event, value) => {
  //   setAnchorEl(event.currentTarget)
  //   setPopperOpen(value)
  //   setExpanded(true)
  // }

  // const handlePopperClose = () => {
  //   setPopperOpen('')
  //   setAnchorEl(null)
  // }

  // const handleRecurringTime = (recurringTime) => {
  //   if (currentWorkspace?.isComplete)
  //     dispatch(
  //       fireErrorToaster(
  //         `${taskState.title} belongs to a complete project and you can not change its status.`
  //       )
  //     )
  //   else {
  //     if (taskPermission?.update) {
  //       const data = {
  //         workSpace: task.workSpace,
  //         taskSection: task.taskSection._id,
  //         setRecurringTime: recurringTime
  //       }
  //       data.isTaskRecurring = recurringTime === 0 ? false : true
  //       dispatch(
  //         updateTaskDetails(task._id, data, (res, err) => {
  //           if (!err) {
  //             dispatch(fireSuccessToaster('Updated Successfully!'))
  //             handlePopperClose()
  //           }
  //         })
  //       )
  //     }
  //   }
  // }

  // const handleOpenTagsPopover = (e) => {
  //   setExpanded(true)
  //   setTagsPopoverData({
  //     open: true,
  //     anchorEl: e.currentTarget
  //   })
  // }

  // const handleTaskRecurring = (e) => {
  //   if (currentWorkspace?.isComplete) {
  //     dispatch(
  //       fireErrorToaster(
  //         `This task belongs to a complete project and you can not set its status.`
  //       )
  //     )
  //   } else {
  //     taskPermission?.update
  //       ? triggerPopover(e, 'recurring')
  //       : dispatch(fireErrorToaster(`You are not authorized`))
  //   }
  // }

  // const handleOpenPriorityPopup = (e) => {
  //   setPopperOpen('priority')
  //   setAnchorEl(e.currentTarget)
  // }

  // const handlePriorityChange = (priority) => {
  //   handlePopperClose()
  //   dispatch(
  //     updateTaskPriority(
  //       taskState._id,
  //       { priority: priority.order },
  //       priorityCallback
  //     )
  //   )
  // }

  // const priorityCallback = (res, err) => {
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   }
  // }

  return (
    <>
      <Draggable
        isDragDisabled={!taskPermission?.update}
        draggableId={task._id || Date.now().toString()} // draggable requires a unique string id
        index={index}
      >
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            onClick={handleCardClick}
          >
            <div
              className={clsx(
                classes.taskCardContainer,
                'rounded-lg',
                taskId === task._id ? 'border-blue-400 bg-blue-50' : 'bg-white'
              )}
            >
              <div>
                {/* ====== Card Header ===== */}
                {/* title, taskId, assignedTags, exTags, setTagsPopoverData */}
                <TaskCardHeader
                  title={task.title}
                  taskId={task._id}
                  attachments={task.imageUrl}
                  assignedTags={assignedTags}
                  exTags={exTags}
                  // setTagsPopoverData={setTagsPopoverData}
                  estimateTimeInString={task.estimateTimeInString}
                  isComplete={task.markAsComplete}
                  handleMarkAsComplete={handleMarkAsComplete}
                  timer={timer}
                />

                <footer
                  className="px-3 py-1.5 text-custom-gray-light-3 cursor-default"
                  onClick={stopEventPropagation}
                  style={{ minHeight: 30 }}
                >
                  <div className="flex justify-between items-center">
                    {/* 1. Priority */}
                    {taskState?.priority && taskState.priority !== 5 && (
                      <span className="mr-2">
                        <CustomFlag
                          priority={taskState.priority}
                          size="small"
                        />
                      </span>
                    )}
                    {/* {taskState?.priority && taskState.priority !== 5 ? (
                      <button
                        className="mr-2"
                        onClick={handleOpenPriorityPopup}
                      >
                        <CustomFlag
                          priority={taskState.priority}
                          size="small"
                        />
                      </button>
                    ) : (
                      <CustomTooltip title="Set Priority" placement="top">
                        <button
                          className="text-sm mr-2"
                          onClick={handleOpenPriorityPopup}
                        >
                          <FlagOutlined />
                        </button>
                      </CustomTooltip>
                    )} */}

                    {/* 2. Due Date */}
                    {task.dueDate && (
                      <span className="leading-none flex items-center text-extraSmaller lg:text-xs">
                        <ClockIcon className="text-base lg:text-lg mr-1" />
                        <time className="font-medium">
                          {format(new Date(task.dueDate), 'MMM d')}
                        </time>
                        {/* <DatePicker
                        selected={
                          props.task?.dueDate
                            ? new Date(props.task.dueDate)
                            : setHours(new Date(), new Date().getHours())
                        }
                        onChange={(date) => handleDateChange(date)}
                        showTimeSelect
                        customInput={
                          task.dueDate ? (
                            <DateCustomInput />
                          ) : (
                            <ClockIcon className="text-lg cursor-pointer" />
                          )
                        }
                        dateFormat="MMM d"
                        className="text-custom-gray-light-3"
                        minDate={
                          props.task?.startDate
                            ? new Date(props.task?.startDate)
                            : new Date(props.task?.createdAt)
                        }
                        // calendarContainer={MyContainer}
                      /> */}
                      </span>
                    )}

                    {/* 4. Approval requested */}
                    {/* {[userRoles.USER_CLIENT, userRoles.CLIENT_MANAGER].includes(
                      meData.role
                    ) &&
                      task.clientApproval === 'request' && (
                        <span className="text-xs text-red-400 font-medium">
                          Approval requested
                        </span>
                      )} */}

                    {/* 5. Approved badge */}
                    {/* {task.clientApproval === 'approve' && (
                      <CustomTooltip placement="top" title="Approved by client">
                        <div>
                          <Pill variant="green">Approved</Pill>
                        </div>
                      </CustomTooltip>
                    )} */}

                    {/* 6. Task Recurring */}
                    {/* {task.isTaskRecurring && (
                      <CustomTooltip
                        title={`Recurring Task: ${recurringTimeHandler(
                          task.setRecurringTime
                        )}`}
                        placement="top"
                      >
                        <Repeat className="text-primary-main h-4 w-4" />
                      </CustomTooltip>
                    )} */}

                    {/* 8. Collaborators */}
                    <div className="ml-auto flex items-center">
                      <ColoredAvatarGroup
                        users={allCollaborators}
                        tooltip={true}
                        size="small"
                      />
                      {/* <div onClick={toggleCollabPopover}>
                        <TaskCardAssignee
                          taskId={task._id}
                          allCollaborators={allCollaborators}
                          toggleCollabPopover={toggleCollabPopover}
                          taskPermission={taskPermission}
                          handleRemoveCollab={handleRemoveCollab}
                          isPopoverOpen={Boolean(collabAnchorEl)}
                        />
                      </div> */}

                      {/* <button className="ml-2">
                        <VerticalThreeDotIcon />
                      </button> */}
                    </div>
                  </div>
                </footer>

                {/* <TaskCardActions
                  task={task}
                  handleDateChange={handleDateChange}
                  expanded={true}
                  setExpanded={setExpanded}
                  assignedTags={assignedTags}
                  handleMarkAsComplete={handleMarkAsComplete}
                  handleOpenTagsPopover={handleOpenTagsPopover}
                  handleTaskRecurring={handleTaskRecurring}
                /> */}
              </div>
            </div>
          </div>
        )}
      </Draggable>
      {/* <CollabDrowdown
        open={Boolean(collabAnchorEl)}
        anchorEl={collabAnchorEl}
        onClose={toggleCollabPopover}
        list={collabOptions}
        handleListItemClick={handleCollaborator}
        meData={meData}
      /> */}
      {/* {currentWorkspace?._id && (
        <Tags
          taskId={task._id}
          workspace={currentWorkspace}
          tagsPopoverData={tagsPopoverData}
          setTagsPopoverData={setTagsPopoverData}
          // setExpanded={setExpanded}
          elmFor="task"
        />
      )} */}
      {/* <RecurringDropDown
        open={popperOpen === 'recurring' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        changeStatus={handleRecurringTime}
        recurring={task.setRecurringTime}
      /> */}
      {/* <PriorityDropDown
        open={popperOpen === 'priority'}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        currentPriority={taskState.priority}
        handlePriorityLabel={handlePriorityChange}
      /> */}
    </>
  )
}

const TaskCardHeader = React.memo(
  ({
    title,
    taskId,
    assignedTags,
    attachments,
    // exTags,
    // setTagsPopoverData,
    estimateTimeInString,
    isComplete,
    handleMarkAsComplete,
    timer
  }) => {
    const firstImageAttachment = useMemo(() => {
      if (attachments) {
        return attachments.find(item =>
          /\.(png|svg|jpe?g|gif|tiff?|webp)$/i.test(item)
        )
      }
      return null
    }, [attachments])

    // const completion = useMemo(() => Math.floor(Math.random() * 100), [])
    const handleMarkComplete = e => {
      e.stopPropagation()
      handleMarkAsComplete(!isComplete)
    }

    return (
      <div className="cursor-pointer">
        <header>
          <div className="px-3 py-3 flex items-center justify-between">
            <h4 className="text-smaller lg:text-sm font-semibold text-primary-dark capitalize truncate">
              {title}
            </h4>

            <div className="flex items-center space-x-2 ml-6">
              {/* Mark as complete */}
              <CustomTooltip
                placement="top"
                title={isComplete ? 'Mark incomplete' : 'Mark Complete'}
              >
                <button>
                  {isComplete ? (
                    <CheckCircleFilled
                      onClick={handleMarkComplete}
                      className="w-4 h-4 text-custom-green-main"
                    />
                  ) : (
                    <CheckCircleIcon
                      className="h-4 w-4 text-gray-400"
                      onClick={handleMarkComplete}
                    />
                  )}
                </button>
              </CustomTooltip>
              {/* 7. Timer */}
              {timer.length > 0 && timer[0]?.task?._id === taskId && (
                <CustomTooltip title="Timer is on" placement="top">
                  <StartTimer className="h-3 w-3" style={{ fill: 'green' }} />
                </CustomTooltip>
              )}
            </div>
          </div>
          {firstImageAttachment && (
            <img
              src={firstImageAttachment}
              alt=""
              className="w-full h-32 object-cover mb-2"
            />
          )}
          <div className="px-3">
            {assignedTags.length > 0 && (
              <div className="flex items-center space-x-1 mb-2 leading-3">
                {assignedTags.map(item => (
                  <Pill
                    style={{
                      color: adjustColor(item.textColor, -30),
                      backgroundColor: item.backgroundColor,
                      fontWeight: 600
                    }}
                  >
                    {item.name}
                  </Pill>
                  // <div
                  //   className="relative py-1 px-2 rounded-full border border-current show-on-hover-parent"
                  //   style={{
                  //     color: adjustColor(item.textColor, -30),
                  //     backgroundColor: item.backgroundColor
                  //   }}
                  // >
                  //   <span className="font-medium capitalize">{item.name}</span>
                  // </div>
                ))}
                {/* <AssignedTags
                  assignedTag={assignedTags}
                  taskId={taskId}
                  elmFor="task"
                  setTagsPopoverData={setTagsPopoverData}
                  tagIcon={true}
                  exTags={exTags}
                /> */}
              </div>
            )}

            <div className="flex items-center mb-2">
              {/* <div className="flex items-center">
              <LinearProgress
                variant="determinate"
                value={completion}
                style={{ height: 6, width: 100, borderRadius: 10 }}
              />
              <span className="text-xs text-custom-gray-main font-medium ml-1">
                {completion}% (random data)
              </span>
            </div> */}
              {estimateTimeInString && (
                <span className="px-2 rounded bg-custom-gray-light-4 font-medium">
                  {/* <span className="ml-auto px-2 py-0.5 rounded bg-custom-gray-light-4 font-medium"> */}
                  <CustomTooltip placement="top" title="Estimate Time">
                    <span className="text-extraSmaller lg:text-xs text-custom-gray-light-3">
                      Est. {estimateTimeInString}
                    </span>
                  </CustomTooltip>
                </span>
              )}
            </div>
          </div>
        </header>
        <hr />
      </div>
    )
  }
)

// const DateCustomInput = React.forwardRef(({ value = '', onClick }, ref) => {
//   return (
//     <div
//       className="flex items-center text-custom-gray-light-3 cursor-pointer"
//       onClick={onClick}
//       ref={ref}
//     >
//       <ClockIcon className="text-lg" />
//       <input
//         defaultValue={value}
//         className="w-20 bg-transparent text-xs font-medium ml-1"
//       />
//     </div>
//   )
// })

export default React.memo(Task)
