import React, { useCallback, useEffect, useState } from 'react'
import Divider from '@material-ui/core/Divider'
import { Skeleton } from '@material-ui/lab'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { useSelector } from 'react-redux'
import userRoles from 'utils/userRoles'
import ProjectCards from './ProjectCards'
import ExternalWorkSpace from './ExternalWorkSpace'
import clsx from 'clsx'
import useStyles from './projectsCardViewStyles'

const ProjectsCards = () => {
  const classes = useStyles()
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const meData = useSelector(state => state.me)
  const [open, setOpen] = useState(false)
  const [cardsRow, setCardsRow] = useState({
    agency: {
      open: false,
      data: []
    },
    client: {
      open: true,
      data: []
    },
    archived: {
      open: false,
      data: []
    },
    completed: {
      open: false,
      data: []
    }
  })
  const search = useSelector(state => state.generalAppState.headerSearch)

  useEffect(() => {
    if (userWorkspaces.data.length) {
      const internalProjects = [],
        externalProjects = [],
        archivedProjects = [],
        completedProjects = []

      const filteredData = userWorkspaces.data.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )

      for (let workspace of filteredData) {
        if (workspace.isArchived) archivedProjects.push(workspace)
        else if (workspace.isComplete) completedProjects.push(workspace)
        else if (workspace.category === 'External')
          externalProjects.push(workspace)
        else internalProjects.push(workspace)
      }

      setCardsRow(prev => ({
        agency: { open: prev.agency.open, data: internalProjects },
        client: { open: prev.client.open, data: externalProjects },
        archived: { open: prev.archived.open, data: archivedProjects },
        completed: { open: prev.completed.open, data: completedProjects }
      }))
    }
  }, [userWorkspaces.data, search])

  const handleExpandWorkspace = useCallback(e => {
    const target = e.currentTarget.dataset.target

    if (target) {
      setCardsRow(prev => ({
        ...prev,
        [target]: { open: !prev[target].open, data: prev[target].data }
      }))
    }
  }, [])

  const onClose = () => {
    setOpen(prev => !prev)
  }

  return (
    <div>
      {userWorkspaces.loading ? (
        <div>
          <Skeleton height={50} />
          <div className="flex items-center mt-4">
            {[...Array(3)].map((i, index) => (
              <div className={classes.cardLoader}>
                <Skeleton
                  key={index}
                  className="rounded-2xl"
                  variant="rect"
                  width={120}
                  height={120}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          {/* ====== External Projects ====== */}
          {search !== '' && (
            <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
              Search results for:{' '}
              <span className="text-primary-dark">{search}</span>
            </p>
          )}
          <div>
            <CardsRowHeader
              classes={classes}
              target="client"
              isExpanded={cardsRow.client.open}
              heading={
                meData.data?.role < userRoles.USER_CLIENT
                  ? 'External'
                  : 'Projects'
              }
              totalCards={cardsRow.client.data.length}
              handleExpandWorkspace={handleExpandWorkspace}
            />

            {cardsRow.client.open && <CardsRow data={cardsRow.client.data} />}
          </div>

          {/* ====== Internal Projects ====== */}
          {meData.data?.role < userRoles.USER_CLIENT && (
            <div>
              <CardsRowHeader
                classes={classes}
                target="agency"
                isExpanded={cardsRow.agency.open}
                heading="Internal"
                totalCards={cardsRow.agency.data.length}
                handleExpandWorkspace={handleExpandWorkspace}
              />
              {cardsRow.agency.open && <CardsRow data={cardsRow.agency.data} />}
            </div>
          )}

          {/* ====== Archived Projects ====== */}
          <div>
            <CardsRowHeader
              classes={classes}
              target="archived"
              isExpanded={cardsRow.archived.open}
              heading="Archived"
              totalCards={cardsRow.archived.data.length}
              handleExpandWorkspace={handleExpandWorkspace}
            />
            {cardsRow.archived.open && (
              <CardsRow data={cardsRow.archived.data} />
            )}
          </div>

          {/* ====== Completed Projects ====== */}
          <div>
            <CardsRowHeader
              classes={classes}
              target="completed"
              isExpanded={cardsRow.completed.open}
              heading="Completed"
              totalCards={cardsRow.completed.data.length}
              handleExpandWorkspace={handleExpandWorkspace}
            />

            {cardsRow.completed.open && (
              <CardsRow data={cardsRow.completed.data} />
            )}
          </div>
          <ExternalWorkSpace open={open} onClose={onClose} />
        </>
      )}
    </div>
  )
}

const CardsRowHeader = React.memo(
  ({
    handleExpandWorkspace,
    target,
    isExpanded,
    classes,
    heading,
    totalCards
  }) => {
    return (
      <>
        <div
          className="flex items-center"
          onClick={handleExpandWorkspace}
          data-target={target}
        >
          <ArrowRightIcon
            className={clsx('mr-4 cursor-pointer rounded', classes.icons, {
              expanded: isExpanded
            })}
          />
          <header className="flex items-center">
            <h3 className="text-base lg:text-lg font-medium mr-2">{heading}</h3>
            <span className="flex justify-center items-center w-7 h-7  rounded-full border bg-white font-semibold text-primary-mid-dark text-smaller lg:text-sm">
              {totalCards}
            </span>
          </header>
        </div>
        <Divider className={classes.divider} />
      </>
    )
  }
)

const CardsRow = ({ data }) => {
  return (
    <div className="flex flex-col">
      {data.length === 0 && (
        <div className="flex flex-col" style={{ marginLeft: '40px' }}>
          <p className="text-sm text-gray-500 mt-3">
            No External Project Available
          </p>
        </div>
      )}
      <>
        <ProjectCards newWsButton category="External" data={data} />
      </>
    </div>
  )
}

export default ProjectsCards
