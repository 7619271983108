import React, { useState, useEffect } from 'react'
import { isUrl } from 'utils'
import CellWrapper from './CellWrapper'
import { ReactComponent as ExclamationCircle } from 'static/svg/exclamation-circle.svg'

const UrlCell = ({
  value,
  row: { index, values, original },
  column: { id },
  updateDynamicColData,
  taskPermission
}) => {
  const [edit, setEdit] = useState(false)
  const [url, setUrl] = useState(value ?? '')
  const [isUrlValid, setIsUrlValid] = useState(true)

  useEffect(() => {
    if (value && value !== url) {
      setUrl(value)
    }
  }, [value])

  useEffect(() => {
    if (!edit && url) {
      setIsUrlValid(isUrl(url))
    }
  }, [edit])

  const handleChangeUrl = (e) => {
    setUrl(e.target.value)
  }

  const handleUpdateUrl = (e) => {
    e.preventDefault()
    handleToggleEdit()

    if (value === url) return

    if (url.trim() !== '') {
      updateDynamicColData({
        rowIndex: index,
        columnId: id,
        value: url,
        allRowValues: values,
        taskId: original.taskId
      })
    }
  }

  const handleToggleEdit = () => {
    setEdit((prev) => !prev)
  }

  return (
    <>
      {edit ? (
        <CellWrapper
          styles={{ minWidth: 180, maxWidth: 180 }}
          className="px-2 w-full h-full truncate flex items-center"
        >
          <form onSubmit={handleUpdateUrl}>
            <input
              value={url}
              onChange={handleChangeUrl}
              onBlur={handleUpdateUrl}
              className="bg-transparent w-full h-full"
              autoFocus
            />
          </form>
        </CellWrapper>
      ) : (
        <CellWrapper
          styles={{ minWidth: 180, maxWidth: 180 }}
          onClick={taskPermission.update ? handleToggleEdit : undefined}
          className="px-2 truncate flex items-center"
        >
          <a
            href={
              url.startsWith('http://') || url.startsWith('https://')
                ? url
                : `https://${url}`
            }
            target="_blank"
            className="text-blue-700 underline truncate"
            rel="noreferrer"
          >
            {url}
          </a>
          {/* {url && isUrlValid ? (
          ) : url ? (
            <div className="text-red-400 flex space-x-2 items-center">
              <ExclamationCircle />
              <span>{url}</span>
            </div>
          ) : null} */}
        </CellWrapper>
      )}
    </>
  )
}

export default UrlCell
