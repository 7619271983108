import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import Papa from 'papaparse'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import { useRef, useState } from 'react'
import TemplateDataMappingModal from './TemplateDataMappingModal'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'

const acceptedFiles = ['csv']

const TemplateImport = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [fileData, setFileData] = useState([])
  const [file, setFile] = useState({})
  const ref = useRef()

  const handleFileInput = e => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0]
      const fileType = inputFile.type.split('/')[1]
      if (!acceptedFiles.includes(fileType)) {
        return dispatch(fireErrorToaster('Please choose the csv file'))
      }
      setFile(inputFile)
      setLoading(true)
      Papa.parse(inputFile, {
        complete: function (results) {
          setLoading(false)
          setFileData(results.data)
          setOpen(true)
        }
      })
    } else {
      dispatch(fireErrorToaster('Please choose the file'))
    }
  }

  const onClose = () => {
    ref.current.value = ''
    setOpen(false)
    setFileData([])
    setFile({})
  }

  const onInputClick = event => {
    event.target.value = ''
  }

  return (
    <OuterContentWrapper>
      <SectionHeading className="mb-6 lg:mb-8">
        Import Template from CSV/Excel file
      </SectionHeading>
      <div className="flex space-x-8 items-center border px-2 py-6 mt-8 border-dashed">
        <input
          ref={ref}
          type="file"
          onChange={handleFileInput}
          accept=".csv,.xlsx,.xls"
          className="w-1/3"
          // onClick={onInputClick}
        />

        <div className="border-l px-8 flex-1 align-middle">
          <p>
            You can upload any .csv
            {/* .tsv, .xls, .xlsx, .xml, .txt  */} file with any set of columns
            as long as it has 1 record per row. The next step will allow you to
            match your spreadsheet columns to the right data points. You'll be
            able to clean up or remove any corrupted data before finalizing your
            report.
          </p>
        </div>
      </div>
      <TemplateDataMappingModal
        open={open}
        onClose={onClose}
        fileData={fileData}
      />
    </OuterContentWrapper>
  )
}

export default TemplateImport
