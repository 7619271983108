import axios from 'axios'
import { errorMessages } from 'utils'
import { WORKSPACE_PERMISSION } from './reducer'

/**
 * Fetch all workspace permissions
 * @param {Function} callback callback function
 */
export const fetchWorkspacePermissions = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/workspace/role/user'
      })
      dispatch({ type: WORKSPACE_PERMISSION.FETCHED, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
