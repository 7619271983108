import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllAgencyInvoices } from 'thunks/agencyPayment/actions'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { format } from 'date-fns'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { PopupMenu } from 'global/globalComponents'
import Pill from 'global/globalComponents/Pill/Pill'
import pillVariantObj from 'utils/pillVariantObj'
import { ReactComponent as ViewIcon } from 'static/svg/eye.svg'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'

const totalInvoicesToShow = 8
const AgencyInvoicesList = () => {
  const dispatch = useDispatch()
  const { loading, allInvoices: agencyInvoices } = useSelector(
    state => state.agencyPayment
  )

  // ---- Pagination related ------
  const [visibleInvoices, setVisibleInvoices] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)

  useEffect(() => {
    if (agencyInvoices.length) {
      setVisibleInvoices(
        [...agencyInvoices].splice(
          page * totalInvoicesToShow,
          totalInvoicesToShow
        )
      )
      setPaginationTotalDataCount(agencyInvoices.length)
    }
  }, [agencyInvoices, page, paginationTotalDataCount])

  useEffect(() => {
    dispatch(fetchAllAgencyInvoices())
  }, [])

  const handleDownloadInvoice = url => {
    window.open(url, '_blank')
  }

  const handleViewInvoice = url => {
    window.open(url, '_blank')
  }

  return (
    <div>
      <TableContainer mb={6} shadow="none">
        <Thead>
          <Th className="text-left whitespace-nowrap">Plan Name</Th>
          <Th className="text-center border-collapse border-t border-gray-200 w-2/3">
            Billing Date
          </Th>
          <Th className="text-left whitespace-nowrap">Status</Th>
          <Th></Th>
        </Thead>

        <Tbody>
          {loading ? (
            <TableDataLoading cols={4} />
          ) : visibleInvoices?.length ? (
            visibleInvoices.map(invoice => (
              <Tr>
                <Td className="whitespace-nowrap text-primary-mid-dark font-medium capitalize">
                  {invoice.lines?.data[0]?.plan?.nickname}
                </Td>
                <Td className="text-center text-custom-gray-main text-xs">
                  {format(
                    new Date(invoice.created * 1000), //lines.data[0].period.start
                    'do MMM, yyyy'
                  )}
                </Td>
                <Td className="text-left whitespace-nowrap">
                  <Pill variant={pillVariantObj[invoice.status]}>
                    {invoice.status}
                  </Pill>
                </Td>
                <Td>
                  <PopupMenu
                    menuItems={[
                      {
                        label: 'View',
                        method: () =>
                          handleViewInvoice(invoice.hosted_invoice_url),
                        icon: <ViewIcon className="text-primary-main" />
                      },
                      {
                        label: 'Download',
                        method: () =>
                          handleDownloadInvoice(invoice.invoice_pdf),
                        icon: <DownloadIcon className="text-primary-main" />
                      }
                    ]}
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td>No Invoice available</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>

      {paginationTotalDataCount > totalInvoicesToShow && (
        <Pagination
          shadow="none"
          handlePagination={setPage}
          totalResultsCount={paginationTotalDataCount}
          visibleResultsCount={visibleInvoices.length}
          page={page}
          limit={totalInvoicesToShow}
        />
      )}
    </div>
  )
}

export default AgencyInvoicesList
