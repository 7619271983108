import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClientCompanies } from 'thunks/clientCompany/action'
import CompanyCard from './CompanyCard'
import useStyles from 'components/UserDashboard/Workspaces/ProjectsCardView/projectsCardViewStyles'
import clsx from 'clsx'
import { NoAccess } from 'static/Images'
import { Skeleton } from '@material-ui/lab'

const ClientCompany = ({ search }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { fetched, loading, data: companies } = useSelector(
    (state) => state.clientCompany
  )
  const [visibleCompanies, setVisibleCompanies] = useState([])

  useEffect(() => {
    if (!loading && !fetched) {
      dispatch(getClientCompanies())
    }
  }, [])

  useEffect(() => {
    if (companies.length) {
      if (search) {
        setVisibleCompanies(
          companies.filter(
            (item) =>
              item.brandName.toLowerCase().includes(search.toLowerCase()) ||
              item.companyLegalName.toLowerCase().includes(search.toLowerCase())
          )
        )
      } else {
        setVisibleCompanies(companies)
      }
    }
  }, [companies, search])

  return (
    <div>
      {!loading ? (
        companies.length ? (
          <div className={clsx(classes.cardsContainer, 'mb-12')}>
            {visibleCompanies.map((company) => (
              <CompanyCard company={company} />
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <div
              style={{ width: '100%', maxHeight: '300px' }}
              className="bg-white my-auto"
            >
              <img
                src={NoAccess}
                alt="No Access"
                className="w-full h-full object-contain"
                style={{
                  maxWidth: '800px',
                  maxHeight: '300px',
                  margin: 'auto'
                }}
              />
            </div>
          </div>
        )
      ) : (
        <div className={clsx(classes.cardsContainer, 'mb-12')}>
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
        </div>
      )}
    </div>
  )
}

export default ClientCompany
