import { Button } from '@material-ui/core'
import LoopRoundedIcon from '@material-ui/icons/LoopRounded'

const RefreshButton = ({ refreshing, onClick, size = 'small' }) => {
  return (
    <Button
      startIcon={<LoopRoundedIcon className={refreshing ? 'spin' : ''} />}
      variant="outlined"
      onClick={onClick}
      size={size}
    >
      Refresh
    </Button>
  )
}

export default RefreshButton
