import React from 'react'
import clsx from 'clsx'
import { CustomTooltip } from 'global/globalComponents'
import Pill from 'global/globalComponents/Pill/Pill'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import { ReactComponent as CheckCircleFilled } from 'static/svg/check-circle-filled.svg'

const StandupList = ({
  data,
  handleRemoveStandup,
  toggleStandupCompletion,
  toggleDeleteStandupAlert,
  openEditTaskPanel,
  canUpdate = false,
  canDelete = false,
  canRemove = false
}) => {
  if (data.length === 0) return null
  return (
    <div className="pb-4">
      <div className="flex justify-between my-2 px-2">
        <span className="font-semibold text-smaller">Task Name</span>
        {(canUpdate || canDelete) && (
          <span className="font-semibold text-smaller">Action</span>
        )}
      </div>
      <ol>
        {data.map((item, index) => (
          <StandupsListItem
            key={index}
            standupData={item}
            index={index}
            handleRemoveStandup={handleRemoveStandup}
            toggleStandupCompletion={toggleStandupCompletion}
            toggleDeleteStandupAlert={toggleDeleteStandupAlert}
            openEditTaskPanel={openEditTaskPanel}
            canUpdate={canUpdate}
            canDelete={canDelete}
            canRemove={canRemove}
          />
        ))}
      </ol>
    </div>
  )
}

const StandupsListItem = ({
  standupData,
  index,
  handleRemoveStandup,
  toggleStandupCompletion,
  toggleDeleteStandupAlert,
  openEditTaskPanel,
  canUpdate,
  canDelete,
  canRemove
}) => {
  const handleRemoveState = () => {
    if (canRemove) {
      handleRemoveStandup(index)
    } else {
      toggleDeleteStandupAlert(standupData)
    }
  }

  const handleStandupCompletion = () => {
    toggleStandupCompletion(standupData)
  }

  const handleOpenTaskPanel = () => {
    openEditTaskPanel(standupData.task._id ?? standupData.task)
  }

  return (
    <li className="flex items-center py-2 pr-2 bg-gray-50 border-t border-b pl-2">
      <span className="mr-2 text-sm text-gray-600">{index + 1}.</span>

      <div className="flex items-center">
        <div className="cursor-pointer" onClick={handleOpenTaskPanel}>
          <span
            className={clsx(
              'block text-smaller font-medium capitalize text-gray-600',
              standupData.taskDeleted && 'line-through'
            )}
          >
            {standupData.name}
          </span>
        </div>
        <div className="ml-4">
          <Pill
            variant="blue"
            style={{ paddingLeft: 8, paddingRight: 8, maxWidth: 300 }}
            className="truncate"
          >
            {standupData.projectName}
          </Pill>
        </div>
      </div>

      <div className="ml-auto leading-3 flex space-x-4">
        {standupData.task?.estimateTimeInString && (
          <span className="text-xs text-gray-500">
            Est: {standupData.task.estimateTimeInString}
          </span>
        )}
        {canUpdate && !standupData.taskDeleted && (
          <CustomTooltip
            title={standupData.isComplete ? 'Mark incomplete' : 'Mark complete'}
            placement="top"
          >
            <button
              onClick={handleStandupCompletion}
              data-id={index}
              className={clsx(
                'text-custom-green-main',
                !standupData.isComplete && 'opacity-60 hover:opacity-100'
              )}
            >
              {standupData.isComplete ? <CheckCircleFilled /> : <CheckCircle />}
            </button>
          </CustomTooltip>
        )}
        {(canRemove || canDelete) && (
          <CustomTooltip
            title={canRemove ? 'Remove' : 'Delete'}
            placement="top"
          >
            <button
              className="text-custom-red-main opacity-60 hover:opacity-100"
              onClick={handleRemoveState}
            >
              <TrashIcon />
            </button>
          </CustomTooltip>
        )}
      </div>
    </li>
  )
}

export default React.memo(StandupList)
