import React from 'react'
import { useSelector } from 'react-redux'
import StandupList from './StandupsListItem'

const EveningStandups = ({
  toggleStandupCompletion,
  // timeWindow,
  // abbreviation,
  toggleDeleteStandupAlert,
  standupsOpenFor,
  openEditTaskPanel,
  standupsData
}) => {
  const meData = useSelector(state => state.me.data)

  return (
    <div>
      {!Boolean(standupsData.length) && (
        <div>
          <h3 className="font-semibold text-lg lg:text-xl mb-1">
            Hello {meData.name}!
          </h3>
          <p className="text-smaller lg:text-sm leading-6">
            Here you can update your morning standups (mark them as complete)
            and add new standups.
            {/* <span className="font-semibold">{timeWindow.eveningStart}</span> to{' '}
            <span className="font-semibold">
              {timeWindow.eveningEnd} ({abbreviation})
            </span> */}
          </p>
        </div>
      )}

      <StandupList
        data={standupsData}
        toggleStandupCompletion={toggleStandupCompletion}
        toggleDeleteStandupAlert={toggleDeleteStandupAlert}
        standupsOpenFor={standupsOpenFor}
        openEditTaskPanel={openEditTaskPanel}
        canUpdate={true}
        canDelete={true}
      />
    </div>
  )
}

export default React.memo(EveningStandups)
