import React from 'react'
import { Button, Radio } from '@material-ui/core'
import { ReactComponent as MasterCard } from 'static/svg/mastercard-2.svg'
import clsx from 'clsx'

const AppSumoPaymentMethod = ({ card, classes, handleClose }) => {
  return (
    <div>
      <h5 className="text-primary-dark font-medium text-sm lg:text-base mb-8">
        Select Payment Method
      </h5>
      <div>
        {Object.keys(card).includes('last4') ? (
          <>
            <div className="flex flex-row ">
              <Radio
                checked={Object.keys(card).includes('last4')}
                disable
                value="b"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'B' }}
              />
              <div
                className={clsx(
                  'flex flex-row w-full pr-2',
                  classes.rowContainer
                )}
              >
                {card.brand === 'visa' ? (
                  <img
                    alt="visa"
                    src=" https://s3.wasabisys.com/assets.clientvenue.com/other/card/visa.png"
                    className={classes.image}
                  />
                ) : (
                  <MasterCard
                    height="50px"
                    width="40px"
                    style={{ padding: '7px' }}
                  />
                )}
                <p className="self-center flex-grow">
                  {card.brand === 'visa' ? 'Visa Debit Card' : 'Master Card'}
                </p>
                <p className="self-center">********{card.last4}</p>
              </div>
            </div>
            <div className="text-right mt-6">
              <Button variant="outlined" onClick={handleClose}>
                Update Payment Method
              </Button>
            </div>
          </>
        ) : (
          <div
            className="px-10 py-3 cursor-pointer"
            role="button"
            onClick={handleClose}
          >
            <p className="text-primary-main text-sm">+ Add a payment Method</p>
          </div>
        )}
      </div>
      {/* {Object.keys(me.profile).includes('address') ? (
            <div className="mt-4 rounded-lg bg-custom-gray-light py-4 px-6 flex items-center justify-between">
              <div className="font-medium text-sm">
                <p>
                  {[
                    me.profile?.address?.line1,
                    me.profile?.address?.line2,
                    me.profile?.address?.city
                  ]
                    .filter((i) => i && i.length > 0)
                    .join(', ')}
                </p>
                <p>
                  {[
                    me.profile?.address?.state,
                    me.profile?.address?.country.label,
                    me.profile?.address?.zipCode
                  ]
                    .filter((i) => i && i.length > 0)
                    .join(', ')}
                </p>
              </div>
              <span
                role="button"
                className="py-2 px-4 rounded font-medium text-primary-main text-sm"
                onClick={hadnlePushToGeneralSettings}
              >
                Edit
              </span>
            </div>
          ) : (
            <div className="mt-4 rounded-lg bg-custom-gray-light py-4 px-6 flex items-center justify-between">
              <div className="font-medium text-sm">
                <span
                  role="button"
                  className="py-2 px-4 rounded font-medium text-primary-main text-sm mt-4"
                  onClick={hadnlePushToGeneralSettings}
                >
                  Add Address
                </span>
              </div>
            </div>
          )} */}
    </div>
  )
}

export default AppSumoPaymentMethod
