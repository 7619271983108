import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './table.module.css'
import CellWrapper from './CellWrapper'
import { ReactComponent as ExclamationCircle } from 'static/svg/exclamation-circle.svg'
import { stopEventPropagation } from 'utils'

const EditableCell = (props) => {
  const {
    value,
    row: { index, values, original },
    column: { id },
    updateDynamicColData,
    taskPermission
  } = props
  const [email, setEmail] = useState(value ?? '')
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (value && value !== email) {
      setEmail(value)
    }
  }, [value])

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  const handleToggleEdit = () => {
    setEdit((prev) => !prev)
  }

  const handleUpdateValue = (e) => {
    e.preventDefault()
    handleToggleEdit()

    if (value === email) return

    if (email.trim() !== '') {
      updateDynamicColData({
        rowIndex: index,
        columnId: id,
        value: email,
        allRowValues: values,
        taskId: original.taskId
      })
    }
  }

  return (
    <div style={{ minWidth: 250 }}>
      {edit ? (
        <form onSubmit={handleUpdateValue}>
          <input
            value={email}
            onChange={onChange}
            onBlur={handleUpdateValue}
            className="bg-transparent w-full px-2 h-full"
            autoFocus
          />
        </form>
      ) : (
        <CellWrapper
          onClick={taskPermission.update ? handleToggleEdit : undefined}
          className={clsx(
            'px-2 w-full h-full flex justify-between',
            id === 'title' ? styles.tableCell : ''
          )}
        >
          {email ? (
            /\S+@\S+\.\S+/.test(email) ? (
              <div className="flex justify-between items-center show-on-hover-parent w-full h-full">
                <span className="truncate text-blue-400">{email}</span>
                <a
                  href={`mailto:${email}`}
                  onClick={stopEventPropagation}
                  className="inline-block border px-2 py-1 rounded ml-2 text-gray-300 show-on-hover-child"
                >
                  @
                </a>
              </div>
            ) : (
              <div className="text-red-400 flex space-x-2 items-center">
                <ExclamationCircle />
                <span>{email}</span>
              </div>
            )
          ) : (
            ''
          )}
        </CellWrapper>
      )}
    </div>
  )
}

export default EditableCell
