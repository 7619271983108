import { useState, useEffect, useMemo, useContext } from 'react'
// import Card from './Card'
// import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { LinearProgress } from '@material-ui/core'
import Pill from 'global/globalComponents/Pill/Pill'
import format from 'date-fns/format'
import { ColoredAvatars, CustomTooltip } from 'global/globalComponents'
import { userRoles } from 'utils'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Select from 'react-select'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import { ReactComponent as UserPlusIcon } from 'static/svg/user-plus.svg'
import { updateTeammateRole, assignWorkspace } from 'thunks/workspace/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import {
//   addCurrentBoard
// } from 'thunks/workspace/actions'
import { Card } from 'global/globalComponents'
import CollabDrowdown from '../Panel/CollabDrowdown'
import { getClient } from 'thunks/addClient/actions'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'

const ProjectInfo = ({
  changeOverviewBoard,
  readOnly,
  workspace = {},
  totalTasks,
  workspacePermission,
  completedTasks
}) => {
  const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  // const { workspaceState } = useContext(SingleWorkspaceContext)
  // const workspacePermission = workspaceState.permission.workspacePermission
  const agencyTeammates = useSelector((state) => state.userTeammates)
  const meData = useSelector((state) => state.me.data)
  const clients = useSelector((state) => state.userClients)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [boardOptions, setBoardOptions] = useState([])
  const [selectedBoard, setSelectedBoard] = useState({})
  const allEmbeds = useSelector((state) => state.userWorkspaceEmbed.data)
  const [workspaceData, setWorkspaceData] = useState({
    startDate: '',
    dueDate: ''
  })
  const [assignUser, setAssignUser] = useState({
    anchorEl: null,
    list: [],
    handleDialog: null,
    type: 'teammate'
  })

  const [clientOptions, setClientOptions] = useState([])

  useEffect(() => {
    if (workspace._id) {
      setLoading(false)
      setWorkspaceData({
        startDate: workspace.startDate,
        dueDate: workspace.dueDate
      })
    }
  }, [workspace])

  const completion = useMemo(
    () => ((completedTasks * 100) / totalTasks).toFixed(2),
    []
  )

  // useEffect(() => {
  //   if (currentBoard?._id) {
  //     setSelectedBoard({ label: currentBoard.name, value: currentBoard._id })
  //   }
  // }, [currentBoard])

  useEffect(() => {
    if (allEmbeds.length) {
      const defaultOption = { label: 'All', value: 'all' }

      const embedBoards = allEmbeds
        .filter((item) => item.type === 'board')
        .map((item) => ({ label: item.name, value: item._id }))

      setBoardOptions([defaultOption, ...embedBoards])
      setSelectedBoard(defaultOption)
    }
  }, [allEmbeds])

  useEffect(() => {
    if (
      !clients.fetched &&
      workspacePermission.update &&
      meData?.role < userRoles.USER_CLIENT
    ) {
      dispatch(getClient())
    }
  }, [clients.fetched, workspacePermission])

  useEffect(() => {
    var arr = []
    const addedClients = workspace?.assignedClientTeam?.map((i) => i.user?._id)
    clients.data.map((item) => {
      if (!addedClients?.includes(item._id)) {
        arr.push({
          user: {
            _id: item._id,
            name: item.name,
            profileImage: item.profileImage
          },
          role: item.role
        })
      }
    })
    setClientOptions(arr)
  }, [clients.data, workspace._id])

  const getCreator = (project) => {
    let userObj = [
      ...project.assignedAgencyTeam,
      ...project.assignedClientTeam
    ].find((mate) => mate.user._id === (project.user?._id ?? project.user))
    if (userObj) {
      return (
        <div className="flex items-center space-x-2">
          <ColoredAvatars user={userObj.user} size="small" />
          <span>{userObj.user?.name}</span>
        </div>
      )
    } else {
      userObj = [
        ...agencyTeammates.data,
        {
          _id: meData?._id,
          name: meData?.name,
          profileImage: meData.profileImage
        }
      ].find((mate) => mate._id === project.user)

      if (userObj)
        return (
          <div className="flex items-center space-x-2">
            <ColoredAvatars user={userObj.user} size="small" />
            <span>{userObj.user?.name}</span>
          </div>
        )
      else return <UserIcon />
    }
  }

  const getOwner = (project) => {
    const ownerObj = project.assignedAgencyTeam?.find(
      (item) => item.role === userRoles.PROJECT_OWNER
    )
    if (ownerObj) {
      return (
        <div className="flex items-center space-x-2 cursor-pointer">
          <ColoredAvatars
            user={ownerObj.user}
            size="small"
            onClick={handleProjectOwnerModal}
            tooltip={false}
          />
          <span>{ownerObj.user?.name}</span>
        </div>
      )
    }
    return (
      <>
        {meData.role < userRoles.USER_CLIENT && workspacePermission.update ? (
          <CustomTooltip title="Assign project Owner" placement="top">
            <UserPlusIcon
              className="cursor-pointer w-4 h-4 text-primary-mid-dark"
              onClick={handleProjectOwnerModal}
            />
          </CustomTooltip>
        ) : (
          <UserIcon className="w-4 h-4 text-primary-mid-dark" />
        )}
      </>
    )
  }

  const getClientUser = (project) => {
    const clientObj = project.assignedClientTeam?.find(
      (item) => item.role === userRoles.USER_CLIENT
    )
    if (clientObj) {
      return (
        <div className="flex items-center space-x-2">
          <ColoredAvatars user={clientObj.user} size="small" />
          <span>{clientObj.user?.name}</span>
        </div>
      )
    }
    return (
      <>
        {workspace.category === 'External' &&
        meData.role < userRoles.USER_CLIENT &&
        workspacePermission.update ? (
          <CustomTooltip title="Assign Client" placement="top">
            <UserPlusIcon
              className="cursor-pointer w-4 h-4 text-primary-mid-dark"
              onClick={(e) => {
                setAssignUser({
                  anchorEl: e.currentTarget,
                  list: clientOptions,
                  handleDialog: handleClient,
                  type: 'client'
                })
              }}
            />
          </CustomTooltip>
        ) : (
          <UserIcon className="w-4 h-4 text-primary-mid-dark" />
        )}
      </>
    )
  }

  const handleBoardChange = (newVal) => {
    setSelectedBoard(newVal)
    const newBoard = allEmbeds.find((item) => item._id === newVal.value)
    changeOverviewBoard(newBoard ?? 'all')
  }

  const handleProjectOwnerModal = (e) => {
    if (meData.role >= userRoles.USER_CLIENT && !workspacePermission.update)
      return dispatch(
        fireErrorToaster('You are not authorised to update project owner')
      )
    setAssignUser({
      anchorEl: e.currentTarget,
      list: workspace.assignedAgencyTeam.filter(
        (user) =>
          ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(user.role)
      ),
      handleDialog: handleProjectOwner,
      type: 'teammate'
    })
  }

  const handleProjectOwner = (item) => {
    if (item.user._id === meData.profile?.user) {
      dispatch(fireErrorToaster('You are not authorised to update your role'))
      handleClose()
      return
    }

    let recentProjectOwner = workspace.assignedAgencyTeam.find(
      (mate) => mate.role === userRoles.PROJECT_OWNER
    )

    if (recentProjectOwner?.user._id === item.user._id) {
      handleClose()
      return
    }

    dispatch(
      updateTeammateRole(
        workspace._id,
        {
          assignToAgencyTeammate: {
            user: item.user._id,
            role: userRoles.PROJECT_OWNER
          }
        },
        item.user.name,
        (res, err) => {
          if (!err) {
            if (
              recentProjectOwner &&
              recentProjectOwner.user._id !== item.user._id
            ) {
              dispatch(
                updateTeammateRole(
                  workspace._id,
                  {
                    assignToAgencyTeammate: {
                      user: recentProjectOwner.user._id,
                      role: userRoles.AGENCY_EDITOR
                    }
                  },
                  recentProjectOwner.user.name,
                  projectOwnerCallback
                )
              )
            } else {
              handleClose()
            }
          } else {
            dispatch(fireErrorToaster(res))
            handleClose()
          }
        }
      )
    )
  }

  const projectOwnerCallback = (res, err) => {
    handleClose()
    if (err) {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleClient = (item) => {
    dispatch(
      assignWorkspace(
        {
          workspace: workspace._id,
          assignToAgencyTeammate: [],
          assignToClientTeammate: [item.user._id]
        },
        [item.user.name],
        (res, err) => {
          handleClose()
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleClose = () => {
    setAssignUser((prev) => ({
      anchorEl: null,
      list: [],
      handleDialog: null,
      type: 'teammate'
    }))
  }

  return (
    <Card className="h-full">
      <header className="bg-orange-100 px-4 py-4">
        <h4 className="text-sm font-heading font-semibold">Project Info</h4>
        <p className="text-xs text-gray-500">
          Some info related to this project
        </p>
      </header>

      <div className="py-4 px-4">
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <>
            {!readOnly && (
              <Row>
                <div className="font-medium flex-1">Board</div>
                <div className="flex-1">
                  <Select
                    styles={{ ...reactSelectCustomStyles, ...SelectStyles }}
                    options={boardOptions}
                    onChange={handleBoardChange}
                    value={selectedBoard}
                  />
                </div>
              </Row>
            )}
            <Row className="py-3">
              <div className="font-medium flex-1">Status</div>
              <div className="flex-1 pl-4">{getStatusBadge(workspace)}</div>
            </Row>

            <Row className="py-3">
              <div className="font-medium flex-1">Creator</div>
              <div className="text-xs flex-1 pl-4">{getCreator(workspace)}</div>
            </Row>

            <Row className="py-3">
              <div className="font-medium flex-1">Project Owner</div>
              <div className="text-xs flex-1 pl-4">{getOwner(workspace)}</div>
            </Row>

            <Row className="py-3">
              <div className="font-medium flex-1">Client</div>
              <div className="text-xs flex-1 pl-4">
                {getClientUser(workspace)}
              </div>
            </Row>
          </>
        )}
      </div>
      <CollabDrowdown
        open={Boolean(assignUser.anchorEl)}
        anchorEl={assignUser.anchorEl}
        onClose={handleClose}
        list={assignUser.list}
        handleListItemClick={assignUser.handleDialog}
        meData={meData}
        collabType={assignUser.type}
      />
    </Card>
  )
}

const Row = ({ children, className }) => {
  return (
    <div className={clsx('flex items-center space-x-8 text-xs', className)}>
      {children}
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill('')
        .map((item) => (
          <Row className="mb-4">
            <Skeleton variant="rect" height={15} width={70} />
            <Skeleton variant="rect" height={15} width={150} />
          </Row>
        ))}
    </>
  )
}

const getStatusBadge = (project) => {
  return (
    <Pill
      variant={
        project.isComplete ? 'orange' : project.isArchived ? 'gray' : 'green'
      }
    >
      {project.isComplete
        ? 'Completed'
        : project.isArchived
        ? 'Archived'
        : 'Active'}
    </Pill>
  )
}

const clrPrimary = '#1600E5'
const clrPrimaryLight = '#F3F2FF'
const SelectStyles = {
  control: (base, state) => ({
    ...base,
    textTransform: 'capitalize',
    border: 'none',
    '&:focus-within': {
      borderColor: clrPrimary,
      boxShadow: `0 0 0 1px ${clrPrimary}`
    }
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    fontSize: '13px',
    textTransform: 'capitalize',
    // fontWeight: 500,
    backgroundColor: isSelected
      ? clrPrimary
      : isFocused
      ? clrPrimaryLight
      : null,
    ':active': {
      ...provided[':active'],
      backgroundColor: clrPrimaryLight
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '12px'
  })
}

export default ProjectInfo
