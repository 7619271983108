// import { userRoles } from 'utils'

export const GENERAL_APP_STATE = {
  TOGGLE_SIDE_DRAWER: 'TOGGLE_SIDE_DRAWER',
  TOGGLE_SIDE_BAR: 'TOGGLE_SIDE_BAR',
  SHOW_INTEGRATED_HEADER: 'SHOW_INTEGRATED_HEADER',
  SHOW_NORMAL_HEADER: 'SHOW_NORMAL_HEADER F',
  // UPDATE_USER_ACCESS: 'UPDATE_USER_ACCESS',
  TOGGLE_SIDE_PANEL: 'TOGGLE_SIDE_PANEL',
  SHOW_SIDEDRAWER: 'SHOW_SIDEDRAWER',
  SET_TEAMMATE_ROLE: 'SET_TEAMMATE_ROLE',
  SET_EXPAND_STATE: 'SET_EXPAND_STATE',
  OPEN_REMOVED_FROM_WS_MODAL: 'OPEN_REMOVED_FROM_WS_MODAL',
  TOGGLE_UPGRADE_PLAN_MODAL: 'TOGGLE_UPGRADE_PLAN_MODAL',
  TOGGLE_CREATE_INVOICE_MODAL: 'TOGGLE_CREATE_INVOICE_MODAL',
  TOGGLE_DOCUMENT_PREVIEW: 'TOGGLE_DOCUMENT_PREVIEW',
  TOGGLE_HEADER_SHADOW: 'TOGGLE_HEADER_SHADOW',
  SET_HEADER_SEARCH: 'HEADER_SET_HEADER_SEARCH',
  SET_BOARD_SEARCH: 'HEADER_SET_BOARD_SEARCH',
  TOGGLE_MODAL: 'TOGGLE_MODAL'
}

const initialState = {
  sideDrawerOpen: true,
  sidebarOpen: true,
  normalHeader: true,
  headerHeight: 60,
  teammaterole: { value: 0, label: 'All' },
  expandState: false,
  workspaceRemoveModal: false,
  upgradePlanModal: false,
  createInvoiceModal: {
    type: 'stripe',
    open: false
  },
  sidePanel: {
    addTeammate: {
      open: false,
      data: {}
    },
    addClient: {
      open: false,
      data: {}
    },
    addWorkspace: {
      open: false,
      category: 'Internal'
    },
    addTask: {
      open: false,
      data: {}
    },
    addService: false,
    editTeammate: false,
    logsPanel: false
  },
  modal: {
    embedModal: false,
    anchorPos: ''
  },
  filePreview: {
    open: false,
    file: ''
  },
  // userAccess: {
  //   workspace: {
  //     create: null,
  //     edit: null,
  //     view: null
  //   },
  //   teammate: {
  //     create: null,
  //     edit: null,
  //     view: null
  //   },
  //   services: {
  //     create: null,
  //     edit: null,
  //     view: null
  //   },
  //   clients: {
  //     create: null,
  //     edit: null,
  //     view: null
  //   },
  //   billings: {
  //     manage: null,
  //     view: null
  //   },
  //   task: {
  //     create: null,
  //     edit: null,
  //     view: true
  //   }
  // },
  headerShadow: true,
  headerSearch: '',
  boardSearch: ''
}

// const setUserAccess = (role) => {
//   return {
//     workspace: {
//       create: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       edit: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER
//       ].includes(role),
//       view: true,
//       delete: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       ws: {
//         assignTeammate: [
//           userRoles.AGENCY_MANAGER,
//           userRoles.USER_AGENCY,
//           userRoles.AGENCY_EDITOR
//         ].includes(role),
//         removeTeammate: [
//           userRoles.AGENCY_MANAGER,
//           userRoles.USER_AGENCY,
//           userRoles.AGENCY_ADMIN
//         ].includes(role)
//       }
//     },
//     task: {
//       create: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       edit: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       view: true,
//       delete: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER
//       ].includes(role)
//     },
//     subTask: {
//       create: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.USER_CLIENT,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       edit: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.USER_CLIENT,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       view: true,
//       delete: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.USER_CLIENT,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role)
//     },
//     teammate: {
//       create: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.AGENCY_EDITOR
//       ].includes(role),
//       edit: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.AGENCY_EDITOR
//       ].includes(role),
//       view: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.AGENCY_EDITOR,
//         userRoles.AGENCY_VIEWER
//       ].includes(role),
//       delete: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.AGENCY_EDITOR,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_AGENCY
//       ].includes(role)
//     },
//     services: {
//       create: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       edit: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_EDITOR
//       ].includes(role),
//       view: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_AGENCY,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER,
//         userRoles.CLIENT_VIEWER,
//         userRoles.AGENCY_VIEWER,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       delete: [].includes(role)
//     },
//     clients: {
//       create: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       edit: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       view: role < userRoles.USER_CLIENT,
//       delete: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role)
//     },
//     billings: {
//       manage: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       view: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role)
//     },
//     subscription: {
//       manage: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       view: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role)
//     },
//     setup: {
//       manage: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       view: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role)
//     },
//     general: {
//       manage: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN
//       ].includes(role),
//       view: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_EDITOR,
//         userRoles.AGENCY_ADMIN
//       ].includes(role)
//     },
//     profile: {
//       manage: true,
//       view: true
//     },
//     security: {
//       manage: true,
//       view: true
//     },
//     filesAssets: {
//       create: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       edit: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       view: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.USER_CLIENT,
//         userRoles.AGENCY_ADMIN,
//         userRoles.CLIENT_MANAGER,
//         userRoles.CLIENT_VIEWER,
//         userRoles.AGENCY_VIEWER,
//         userRoles.AGENCY_EDITOR,
//         userRoles.CLIENT_EDITOR
//       ].includes(role),
//       delete: [
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY,
//         userRoles.AGENCY_ADMIN,
//         userRoles.USER_CLIENT,
//         userRoles.CLIENT_MANAGER
//       ].includes(role)
//     }
//   }
// }

const generalAppStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERAL_APP_STATE.TOGGLE_SIDE_DRAWER:
      return {
        ...state,
        sideDrawerOpen:
          action.payload === undefined ? !state.sideDrawerOpen : action.payload
      }

    case GENERAL_APP_STATE.TOGGLE_SIDE_BAR:
      return {
        ...state,
        sidebarOpen:
          action.payload === undefined ? !state.sidebarOpen : action.payload
      }

    case GENERAL_APP_STATE.SHOW_INTEGRATED_HEADER:
      return {
        ...state,
        normalHeader: false,
        headerHeight: 72
      }

    case GENERAL_APP_STATE.SHOW_NORMAL_HEADER:
      return {
        ...state,
        normalHeader: true,
        headerHeight: 60
      }

    // case GENERAL_APP_STATE.UPDATE_USER_ACCESS:
    //   const obj = setUserAccess(action.payload)
    //   return {
    //     ...state,
    //     userAccess: { ...obj }
    //   }

    case GENERAL_APP_STATE.TOGGLE_SIDE_PANEL:
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          ...action.payload
        }
      }

    case GENERAL_APP_STATE.SHOW_SIDEDRAWER:
      return {
        ...state,
        sideDrawerOpen: action.payload
      }

    case GENERAL_APP_STATE.SET_TEAMMATE_ROLE:
      return {
        ...state,
        teammaterole: action.payload
      }

    case GENERAL_APP_STATE.SET_EXPAND_STATE: {
      return {
        ...state,
        expandState: action.payload
      }
    }

    case GENERAL_APP_STATE.TOGGLE_CREATE_INVOICE_MODAL: {
      return {
        ...state,
        createInvoiceModal: {
          open: !state.createInvoiceModal.open,
          invoiceType: action.payload
        }
      }
    }

    case GENERAL_APP_STATE.OPEN_REMOVED_FROM_WS_MODAL: {
      return {
        ...state,
        workspaceRemoveModal: action.payload
      }
    }

    case GENERAL_APP_STATE.TOGGLE_UPGRADE_PLAN_MODAL: {
      return {
        ...state,
        upgradePlanModal: !state.upgradePlanModal
      }
    }

    case GENERAL_APP_STATE.TOGGLE_DOCUMENT_PREVIEW:
      return { ...state, filePreview: action.payload }

    case GENERAL_APP_STATE.TOGGLE_HEADER_SHADOW:
      return { ...state, headerShadow: action.payload }

    case GENERAL_APP_STATE.SET_HEADER_SEARCH:
      return { ...state, headerSearch: action.payload }

    case GENERAL_APP_STATE.SET_BOARD_SEARCH:
      return { ...state, boardSearch: action.payload }

    case GENERAL_APP_STATE.TOGGLE_MODAL:
      return { ...state, modal: { ...state.modal, ...action.payload } }
    default:
      return state
  }
}

export default generalAppStateReducer
