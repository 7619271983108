import React, { useMemo, useState } from 'react'
import { IconButton } from '@material-ui/core'
import { ReactComponent as BugIcon } from 'static/asana/globe.svg'
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball'
import clsx from 'clsx'
import { Link, useHistory } from 'react-router-dom'
import userRoles, { getUserPath } from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import AsanaIcons from 'static/asana/AsanaIcons'
import ColoredAvatars, {
  ColoredAvatarGroup
} from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { Card, PopupMenu } from 'global/globalComponents'
import { ReactComponent as VerticalThreeDots } from 'static/svg/verticalThreeDot.svg'
import useStyles from 'components/UserDashboard/Workspaces/ProjectsCardView/projectsCardViewStyles'
import EditClientCompany from './EditClientCompany'
import ClientDeleteModal from './ClientDeleteModal'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { deleteCompany } from 'thunks/clientCompany/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { deleteclient } from 'thunks/addClient/actions'

const CompanyCard = ({ company }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { role: userRole } = useSelector(state => state.me.data)
  const access = useSelector(state => state.permission.data?.access)
  const userClients = useSelector(state => state.userClients.data)
  const [editCompanyPanel, setEditCompanyPanel] = useState({
    open: false,
    data: {}
  })

  const [deleteClientCompany, setDeleteClientCompany] = useState({
    open: false,
    data: {},
    loading: false
  })

  const [checkList, setCheckList] = useState({
    isClientPaymentDelete: false,
    isWorkspaceDelete: false,
    isOnBoardingResponseDelete: false
  })

  const clientRepresentative = useMemo(() => {
    return userClients.find(user => user._id === company.clientOwner)
  }, [company])

  const handleEditCompany = (e, company) => {
    e.preventDefault()
    setEditCompanyPanel({ open: true, data: company })
  }

  const handleDeleteClientCompany = (e, client) => {
    e.preventDefault()
    if (access?.clients?.delete) {
      if (deleteClientCompany.open) {
        setDeleteClientCompany({
          open: false,
          data: {},
          loading: false
        })
        setCheckList({
          isClientPaymentDelete: false,
          isWorkspaceDelete: false,
          isOnBoardingResponseDelete: false
        })
      } else {
        setDeleteClientCompany({
          open: true,
          data: client,
          loading: false
        })
      }
    } else {
      dispatch(fireErrorToaster('You are not authorized user'))
    }
  }

  const handleClientCompanyDelete = () => {
    setDeleteClientCompany(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteCompany(deleteClientCompany.data._id, deleteClientCompanycallback)
    )
  }

  const deleteClientCompanycallback = (res, err) => {
    setDeleteClientCompany(prev => ({
      ...prev,
      open: false,
      loading: false
    }))
    if (err) {
      dispatch(fireErrorToaster(res))
      setCheckList({
        isClientPaymentDelete: false,
        isWorkspaceDelete: false,
        isOnBoardingResponseDelete: false
      })
    } else {
      dispatch(fireSuccessToaster('Company deleted successfully'))
      if (deleteClientCompany.data.clientOwner) {
        dispatch(
          deleteclient(
            deleteClientCompany.data.clientOwner,
            checkList,
            (res, err) => {
              setDeleteClientCompany({
                open: false,
                data: {},
                loading: false
              })
              setCheckList({
                isClientPaymentDelete: false,
                isWorkspaceDelete: false,
                isOnBoardingResponseDelete: false
              })
            }
          )
        )
      }
    }
  }

  const OpenSingleClientPage = e => {
    e.preventDefault()
    history.push(`${getUserPath(userRole)}/clients/company/${company._id}`)
  }

  const handleClick = e => {
    e.preventDefault()
  }

  return (
    <>
      <Link to={`${getUserPath(userRole)}/clients/company/${company._id}`}>
        <Card className={classes.cardWrapper}>
          <div>
            <header className="flex items-start justify-between p-4">
              <div
                className=" rounded-full"
                style={{
                  width: '36px',
                  height: '36px'
                }}
              >
                {company.logo ? (
                  <img
                    src={company.logo}
                    className="selected-icon object-contain"
                  />
                ) : (
                  <SportsVolleyballIcon
                    className="selected-icon"
                    style={{ fill: '#1600e5', width: '36px', height: '36px' }}
                  />
                )}
              </div>

              {company.clientOwner && (
                <div className={clsx('leading-none', classes.owner)}>
                  <span
                    className="inline-block mb-1 text-custom-gray-light-3 font-medium"
                    style={{ fontSize: 9 }}
                  >
                    Contact
                  </span>
                  <ColoredAvatars
                    size="small"
                    user={clientRepresentative}
                    tooltip={true}
                  />
                </div>
              )}
            </header>

            <div className="mb-4 lg:mb-6 px-4">
              <h4 className="font-medium text-primary-dark truncate mb-4 capitalize text-smaller lg:text-sm">
                {company.brandName}
              </h4>
              {/* <p className="text-xs mb-4 -mt-1 text-primary-mid-dark-2">
                {company.address?.state || company.address?.country?.label
                  ? `(${company.address?.state ?? ''} ${
                      company.address?.country?.label ?? ''
                    })`
                  : ''}
              </p> */}
            </div>

            <div className="px-4 text-primary-dark text-xs mb-2 font-medium">
              <div className="flex justify-between">
                <p>Projects</p>
                <p>{company.totalProjects ?? 0}</p>
              </div>
              <div className="flex justify-between">
                <p>Teammates</p>
                <p>{company.employeeList.length}</p>
              </div>
            </div>

            <footer
              className="py-3 pl-4 pr-2 border-t flex justify-between items-center"
              style={{ minHeight: '48px' }}
            >
              <ColoredAvatarGroup
                users={company.employeeList}
                size="small"
                tooltip={true}
              />
              <div className={`link flex opacity-0`}>
                {access?.clients?.update && (
                  <IconButton
                    size="small"
                    onClick={e => handleEditCompany(e, company)}
                  >
                    <EditIcon className="h-4 w-4 " />
                  </IconButton>
                )}
                {access?.clients?.delete && (
                  <IconButton
                    size="small"
                    onClick={e => handleDeleteClientCompany(e, company)}
                  >
                    <DeleteIcon className="h-4 w-4 " />
                  </IconButton>
                )}
              </div>

              {/* <PopupMenu
                targetComp={
                  <IconButton size="small">
                    <VerticalThreeDots
                      className="w-4 h-4"
                      onClick={handleClick}
                    />
                  </IconButton>
                }
                menuItems={[
                  {
                    label: 'Edit Company',
                    method: (e) => handleEditCompany(e, company),
                    icon: <EditIcon className="text-primary-main" />
                  },
                  access?.clients?.delete && {
                    label: 'Delete Company',
                    method: (e) => handleDeleteClientCompany(e, company),
                    icon: <DeleteIcon className="text-primary-main" />
                  }
                ]}
              /> */}
            </footer>
          </div>
        </Card>
      </Link>
      <EditClientCompany
        open={editCompanyPanel.open}
        company={editCompanyPanel.data}
        handleClose={() => setEditCompanyPanel({ open: false, data: {} })}
      />
      <ClientDeleteModal
        open={deleteClientCompany.open}
        handleDialog={handleDeleteClientCompany}
        handleDeleteAction={handleClientCompanyDelete}
        loading={deleteClientCompany.loading}
        checkList={checkList}
        setCheckList={setCheckList}
        type="company"
      />
    </>
  )
}

export default CompanyCard
