import React from 'react'
import overviewStyles from './overviewStyles'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'

const OverviewLoading = () => {
  const classes = overviewStyles()

  return (
    <>
      <div className={classes.tasksCount}>
        <Skeleton
          variant="rect"
          style={{ height: '100%' }}
          className="rounded flex-1"
        />
        <Skeleton
          variant="rect"
          style={{ height: '100%' }}
          className="rounded flex-1"
        />
        <Skeleton
          variant="rect"
          style={{ height: '100%' }}
          className="rounded flex-1"
        />
        <Skeleton
          variant="rect"
          style={{ height: '100%' }}
          className="rounded flex-1"
        />
      </div>

      <div className={classes.projectCompletion}>
        <Skeleton
          variant="rect"
          style={{ height: '100%' }}
          className="rounded flex-1"
        />
      </div>

      <div className={classes.projectDates}>
        <Skeleton
          variant="rect"
          style={{ height: '100%' }}
          className="rounded flex-1"
        />
      </div>

      <div
        className={clsx(
          classes.tallerCard,
          classes.tallerCardSecondRow,
          classes.tasksOverdue
        )}
      >
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard, classes.projectInfo)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard, classes.tallerCardSecondRow)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>
      <div
        className={clsx(
          classes.tallerCard,
          classes.tallerCardSecondRow,
          classes.projectHours
        )}
      >
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard, classes.taskTimeWise)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard, classes.projectCost)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard, classes.timesheet)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard, classes.statusUpdate)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>

      <div className={clsx(classes.tallerCard)}>
        <Skeleton variant="rect" style={{ height: '100%' }} />
      </div>
    </>
  )
}

export default OverviewLoading
