import moment from 'moment'

const dateAndTimeHelper = {
  getWeekDays: date => {
    const days = []

    for (let i = 0; i < 7; i++) {
      days.push(moment(date).startOf('week').add(i, 'days').toDate())
    }

    return days
  },
  formatMilliseconds(ms) {
    if (!ms) return '0 min'

    let hours, mins
    hours = Math.floor(ms / (1000 * 60 * 60))

    if (hours > 0) {
      mins = Math.round((ms % (1000 * 60 * 60)) / (1000 * 60))
      return `${hours} h ${mins} min`
    } else {
      return `${Math.round(ms / (1000 * 60))} min`
    }
  },
  getMinutesAndHours(ms = 0) {
    let hours = 0,
      minutes = 0

    hours = Math.floor(ms / (1000 * 60 * 60))
    minutes = Math.round((ms % (1000 * 60 * 60)) / (1000 * 60))

    return { hours, minutes }
  },
  // if date1 is greater then returns +1
  compareDate(date1, date2) {
    if (date1.getFullYear() !== date2.getFullYear())
      return date1.getFullYear() > date2.getFullYear() ? 1 : -1
    if (date1.getMonth() !== date2.getMonth())
      return date1.getMonth() > date2.getMonth() ? 1 : -1
    if (date1.getDate() !== date2.getDate())
      return date1.getDate() > date2.getDate() ? 1 : -1
    return 0
  }
}

export default dateAndTimeHelper
