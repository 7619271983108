import { Button } from '@material-ui/core'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteSingleWebhook,
  fetchAllWebhookEvents,
  fetchUserCreatedWebhooks,
  updateSingleWebhook
} from 'thunks/webhooks/action'
// import {
//   Th,
//   Thead,
//   TableContainer,
//   Tbody,
//   Td,
//   Tr
// } from 'global/globalComponents/TailwindTable/TailwindTable'
import { AlertModal } from 'global/globalComponents'
// import { format } from 'date-fns'
import { useBreadcrumbs } from 'customHooks'
// import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import WebhookModal from './WebhookModal'
import WebhookTable from './WebhookTable'

const CommonWebhookPage = ({ elmFor, webhookData }) => {
  const dispatch = useDispatch()
  const [webhookModal, setWebhookModal] = useState({
    open: false,
    data: {},
    edit: false
  })
  const [deleteAlert, setDeleteAlert] = useState({
    open: false,
    data: {},
    loading: false
  })

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Integrations',
      link: `/settings/integrations`,
      index: 1
    },
    {
      name: elmFor === 'allWebhook' ? 'Webhooks' : 'Slack',
      link: `/settings/integrations/${
        elmFor === 'allWebhook' ? 'webhooks' : 'slack'
      }`,
      index: 2
    }
  ])

  useEffect(() => {
    dispatch(fetchUserCreatedWebhooks())
    dispatch(fetchAllWebhookEvents())
  }, [])

  const toggleWebhookStatus = (e, cb) => {
    const { name, checked } = e.target
    dispatch(
      updateSingleWebhook(name, { isActive: checked }, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        }
        if (cb) cb()
      })
    )
  }

  const toggleWebhookModal = () => {
    setWebhookModal(prev => ({ open: !prev.open }))
  }

  const openEditModal = data => {
    setWebhookModal({ open: true, edit: true, data })
  }

  const handleDeleteWebhook = () => {
    setDeleteAlert(prev => ({ ...prev, loading: true }))
    dispatch(deleteSingleWebhook(deleteAlert.data._id, deleteWebhookCallback))
  }

  const deleteWebhookCallback = (res, err) => {
    if (err) {
      fireErrorToaster(res)
      setDeleteAlert(prev => ({ ...prev, loading: false }))
    } else {
      fireSuccessToaster('Webhook deleted successfully!')
      toggleDeleteAlert()
    }
  }

  const toggleDeleteAlert = data => {
    if (deleteAlert.open) {
      setDeleteAlert(prev => ({ ...prev, open: false }))
    } else {
      setDeleteAlert({ data, open: true, loading: false })
    }
  }

  return (
    <>
      <OuterContentWrapper>
        <div className="flex items-center justify-between mb-6 lg:mb-8">
          <SectionHeading>
            {elmFor === 'allWebhook' ? 'Webhooks Setup' : 'Slack Integration'}
          </SectionHeading>
          <Button
            startIcon={<Add fontSize="small" />}
            onClick={toggleWebhookModal}
          >
            Add New
          </Button>
        </div>
        <div>
          <WebhookTable
            slackWebhooksData={webhookData}
            openEditModal={openEditModal}
            toggleWebhookStatus={toggleWebhookStatus}
            toggleDeleteAlert={toggleDeleteAlert}
          />
        </div>
      </OuterContentWrapper>
      <WebhookModal
        {...webhookModal}
        onClose={toggleWebhookModal}
        elmFor={elmFor}
      />
      <AlertModal
        open={deleteAlert.open}
        handleDialog={toggleDeleteAlert}
        handleDeleteAction={handleDeleteWebhook}
        warningText={`Do you want to delete ${deleteAlert.data.name} webhook`}
        heading="Alert"
        loading={deleteAlert.loading}
      />
    </>
  )
}

export default CommonWebhookPage
