import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'

const PaymentFailModal = (props) => {
  const { open, onClose } = props
  const history = useHistory()
  const [counter, setCounter] = useState(5)
  const [timer, setTimer] = useState(null)

  // useEffect(() => {
  //   if (open) {
  //     const interval = setInterval(() => {
  //       setCounter((prev) => prev - 1)
  //     }, 1000)
  //     setTimer(interval)
  //     return () => clearInterval(interval)
  //   }

  //   console.log('IsOpen', open)
  // }, [open])

  // useEffect(() => {
  //   if (counter === 0) {
  //     clearInterval(timer)
  //     history.push('/agency/dashboard?newAgency=true&payment=true')
  //   }
  // }, [counter])

  return (
    <AlertModal
      open={open}
      deleteBtnText={'Close'}
      handleDeleteAction={() => onClose('fail')}
      handleDialog={() => onClose('fail')}
      warningText="Some error occured while processing your payment. Please contact our support team"
    />
  )
}

export default PaymentFailModal
