export const PERMISSION = {
  FETCHED: 'FETCHED_USER_PERMISSION',
  ERROR: 'ERROR_FETCHING_PERMISSION'
}

const initialState = {
  data: {},
  error: false,
  loading: true,
  fetched: false
}

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERMISSION.FETCHED:
      return {
        data: action.payload,
        error: false,
        loading: false,
        fetched: true
      }

    case PERMISSION.ERROR:
      return { data: {}, error: true, loading: false }

    default:
      return state
  }
}

export default permissionReducer
