import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import {
  getStripeConnectStatus,
  postStripeAccountData
} from 'thunks/stripeAccount/actions'
import StripeConnect from './StripeConnect'
import Invoices from '../Invoices/Invoices'
import { fetchCustomInvoices } from 'thunks/invoices/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { toggleCreateInvoiceModal } from 'thunks/generlAppState/actions'
import CreateInvoiceModal from '../CreateInvoices/CreateInvoiceModal'
import { errorMessages, userRoles } from 'utils'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import InvoiceView from '../Invoices/InvoiceView'
import EditInvoicePage from './EditInvoicePage'
import { fetchAllServices } from 'thunks/service/actions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'

export default function Connect() {
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const invoices = useSelector((state) => state.clientInvoices)
  const stripeInfo = useSelector((state) => state.userStripeInfo)
  const access = useSelector((state) => state.permission.data?.access)
  const { path } = useRouteMatch()
  const history = useHistory()
  const userServices = useSelector((state) => state.userServices)
  const isProjectsFetched = useSelector(
    (state) => state.userWorkspaces.fetchedFirstTime
  )

  const handleToggleInvoiceModal = (invoiceType = 'stripe') => {
    if (access?.billings.create) dispatch(toggleCreateInvoiceModal(invoiceType))
    else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const [stripeConnectedStatus, setStripeConnectStatus] = useState({
    isConnected: stripeInfo.data?.isConnected,
    status: 'notFound'
  })

  useEffect(() => {
    dispatch(getStripeConnectStatus(stripeCallback))

    if (window.location.href.includes('code=')) {
      postStripeAccountData(
        window.location.href.split('code=')[1],
        postStripeAccountDataCallback
      )
    }
  }, [])

  useEffect(() => {
    if (!userServices.fetched) {
      dispatch(fetchAllServices())
    }
  }, [userServices.fetched])

  useEffect(() => {
    if (
      !invoices.fetched &&
      [
        userRoles.USER_AGENCY,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_ADMIN
      ].includes(me.data.role)
    ) {
      let id =
        me.data.role === userRoles.USER_AGENCY
          ? me?.data?._id
          : me?.data?.team?._id
      dispatch(fetchCustomInvoices(id))
    }
  }, [])

  useEffect(() => {
    if (!isProjectsFetched) {
      //fetching projects if not fetched, so that we could show them during invoice creation
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [isProjectsFetched])

  function stripeCallback(res, error) {
    if (!error) {
      setStripeConnectStatus(res)
    }
  }

  function postStripeAccountDataCallback(res, error) {
    if (!error) {
      setStripeConnectStatus(res)
    }
  }

  function HandleClick() {
    if (!stripeConnectedStatus.isConnected) {
      stripeConnectedStatus.url
        ? window.open(stripeConnectedStatus.url, '_blank')
        : dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    }
  }

  const handleViewInvoice = (invoice) => {
    history.push(`${path}/i/${invoice._id}`)
  }

  const handleEditInvoice = (invoice) => {
    history.push(`${path}/i/${invoice._id}/edit`)
  }

  return (
    <>
      <Switch>
        <Route exact path={path + '/all'}>
          {invoices.loading ? (
            <InvoicesLoadingAnimation />
          ) : stripeConnectedStatus.isConnected ||
            invoices.data.length ||
            ![userRoles.USER_AGENCY].includes(me.data.role) ? (
            <Invoices
              handleToggleInvoiceModal={handleToggleInvoiceModal}
              invoices={invoices.data.sort(
                (a, b) =>
                  b.invoiceNumber.split('-')[1] - a.invoiceNumber.split('-')[1]
              )}
              loading={invoices.loading}
            />
          ) : (
            <StripeConnect
              handleToggleInvoiceModal={handleToggleInvoiceModal}
              connect={HandleClick}
            />
          )}

          <CreateInvoiceModal
            handleClose={handleToggleInvoiceModal}
            handleEditInvoice={handleEditInvoice}
            handleViewInvoice={handleViewInvoice}
          />
        </Route>
        {/* <Route exact path={path + '/i/:invoiceId'}>
          <InvoiceView
            allInvoices={invoices.data}
            handleEditInvoice={handleEditInvoice}
            billingPermission={access?.billings}
            // openMarkAsPaidAlert={openMarkAsPaidAlert}
          />
        </Route>
        <Route path={path + '/i/:invoiceId/edit'}>
          <EditInvoicePage
            handleViewInvoice={handleViewInvoice}
            invoices={invoices.data}
          />
        </Route> */}
      </Switch>
    </>
  )
}

const InvoicesLoadingAnimation = () => {
  return (
    <div className="py-6">
      <div className="flex-1">
        <Skeleton
          className="rounded"
          variant="text"
          width={'25%'}
          height={80}
        />
      </div>

      <div className="flex justify-between items-center mb-6">
        <div className="flex-1 flex gap-4">
          {Array(4)
            .fill('')
            .map((item, index) => (
              <Skeleton
                key={index}
                className="rounded"
                variant="text"
                width={'15%'}
                height={40}
              />
            ))}
        </div>
        <div className="flex-1 flex gap-4 justify-end">
          <Skeleton
            className="rounded"
            variant="text"
            width={'25%'}
            height={60}
          />
          <Skeleton
            className="rounded"
            variant="text"
            width={'25%'}
            height={60}
          />
        </div>
      </div>

      <div>
        <Skeleton
          variant="rect"
          width={'100%'}
          height={300}
          className="rounded"
        />
      </div>
    </div>
  )
}
