import { useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Task from './Task'

const ColumnTasks = React.memo(function ({
  isLoading,
  handleMarkTaskAsComplete,
  handleTaskSidePanel,
  sectionId,
  groupBy,
  setLastTaskOrderIndex,
  setTotalVisibleTasks,
  totalTasks
}) {
  const {
    // data: boardTasksData,
    data: boardNewTasksData,
    sectionWiseTasks,
    dueDateWiseTasks,
    assigneeWiseTasks
  } = useSelector(state => state.userTasks)
  const [tasks, setTasks] = useState([])

  useEffect(() => {
    if (groupBy === 'status') {
      setTasks(
        sectionWiseTasks[sectionId]?.data
          .filter(taskId => boardNewTasksData[taskId])
          .map(taskId => boardNewTasksData[taskId]) ?? []
      )
    } else if (groupBy === 'dueDate') {
      setTasks(
        dueDateWiseTasks[sectionId]?.data
          .filter(taskId => boardNewTasksData[taskId])
          .map(taskId => boardNewTasksData[taskId]) ?? []
      )
    } else if (groupBy === 'assignee') {
      setTasks(
        assigneeWiseTasks[sectionId]?.data
          .filter(taskId => boardNewTasksData[taskId])
          .map(taskId => boardNewTasksData[taskId]) ?? []
      )
    }
  }, [sectionWiseTasks, dueDateWiseTasks, assigneeWiseTasks, boardNewTasksData])

  useEffect(() => {
    if (tasks.length) {
      setLastTaskOrderIndex(tasks[tasks.length - 1][groupByKeyWords[groupBy]])
      setTotalVisibleTasks(tasks.length)
    }
  }, [tasks.length])

  return (
    <>
      {isLoading ? (
        <LoadingTasks />
      ) : (
        <>
          {tasks.map((task, index, arr) => (
            <Task
              key={task?._id}
              task={task}
              index={index}
              openSidePanel={handleTaskSidePanel}
              markAsComplete={handleMarkTaskAsComplete}
            />
          ))}

          {!isLoading && totalTasks > tasks.length && <LoadingTasks />}
        </>
      )}
    </>
  )
})

const groupByKeyWords = {
  status: 'orderIndex',
  dueDate: 'dueDateOrderIndex',
  assignee: 'assigneeOrderIndex'
}

const LoadingTasks = () => {
  const theme = useTheme()

  return (
    <>
      {new Array(3).fill('').map((item, index) => (
        <Skeleton
          key={index}
          variant="rect"
          width={theme.custom.taskCard.width}
          className="mb-2 rounded"
          height={100}
        />
      ))}
    </>
  )
}

export default ColumnTasks
