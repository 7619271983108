import React, {
  // useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react'
import { Skeleton } from '@material-ui/lab'
import { useTable, useSortBy, usePagination } from 'react-table'
import EditableCell from './EditableCell'
// import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as VerticalThreeDots } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as AtIcon } from 'static/svg/at.svg'
import { ReactComponent as SelectIcon } from 'static/svg/caret-circle-down.svg'
import { ReactComponent as CheckboxIcon } from 'static/svg/check-square.svg'
import { ReactComponent as HashTagIcon } from 'static/svg/hashtag.svg'
import { ReactComponent as PhoneAlt } from 'static/svg/phone-alt.svg'
import { ReactComponent as TextIcon } from 'static/svg/text-left.svg'
import { ReactComponent as UsersIcon } from 'static/svg/user-friends.svg'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { ReactComponent as PaperclipIcon } from 'static/svg/paperclip.svg'
import { ReactComponent as LinkIcon } from 'static/svg/link.svg'
import { useSelector, useDispatch } from 'react-redux'
// import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import styles from './table.module.css'
import SelectColumnsPopup from './SelectColumnsPopup'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import {
  createProjectListView,
  updateProjectListView,
  fetchProjectListView
} from 'thunks/views/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { triggerOnScrollToBottom } from 'utils'
import { Loader } from 'global/globalComponents'

const infiniteScroll = triggerOnScrollToBottom()

const defaultColumn = {
  Cell: EditableCell
}

const datatypeIcon = {
  text: <TextIcon />,
  multiline: <TextIcon />,
  email: <AtIcon />,
  'single select': <SelectIcon />,
  'multiple select': <SelectIcon />,
  checkbox: <CheckboxIcon />,
  numbers: <HashTagIcon />,
  phone: <PhoneAlt />,
  person: <UsersIcon />,
  date: <CalenderIcon />,
  url: <LinkIcon />,
  attachment: <PaperclipIcon />
}

let prevScrollTop = 0

const ReactTable = ({
  columnsData,
  data,
  updateDynamicColData,
  // updateStaticColData,
  handleDeleteAttachment,
  // handleUploadAttachment,
  handleOpenTaskSidePanel,
  // handleRemoveAssignee,
  addTaskCollaborator,
  removeTaskCollaborator,
  updateTaskProperty,
  changeTaskSection,
  changeTaskPriority,
  uploadTaskAttachment,
  handleTaskDateChange,
  loading,
  // openDeleteTaskAlert,
  openOptionsPopup,
  handleUploadDynamicAttachment,
  handlePreviewAttachment,
  classes,
  toggleCreateTaskModal,
  toggleAddColumnPopup,
  taskPermission,
  loadMoreTasks,
  totalTasks,
  totalVisibleTasks,
  readOnly = false,
  loadingMoreData
}) => {
  // const dispatch = useDispatch()
  // const allTasksData = useSelector((state) => state.userTasks.data)
  const currentWorkspaceBoard = useSelector(
    state => state.userWorkspaces.currentBoard
  )
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const [colSelectAnchorEl, setColSelectAnchorEl] = useState(null)
  const [columnsToHide, setColumnsToHide] = useState([])
  const [savedHiddenCols, setSavedHiddenCols] = useState([])
  const [saveView, setSaveView] = useState(false)
  const childRef = useRef()
  const [allColOptions, setAllColOptions] = useState([])
  const dispatch = useDispatch()
  const projectListView = useSelector(state => state.views.data.projectList)
  // const [pageSize, setPageSize] = useState(15)
  // const tableData = useMemo(() => {
  //   return [...data].slice(0, pageSize)
  // }, [data, pageSize])

  useEffect(() => {
    if (!projectListView._id) {
      dispatch(fetchProjectListView())
    }
  }, [])

  useEffect(() => {
    if (projectListView._id) {
      setSavedHiddenCols(projectListView.content[0].hiddenColumns)
      setColumnsToHide(projectListView.content[0].hiddenColumns)
    }
  }, [projectListView])

  const tableInstance = useTable(
    {
      columns: columnsData,
      data,
      defaultColumn,
      updateDynamicColData,
      // updateStaticColData,
      // handleUploadAttachment,
      handleDeleteAttachment,
      handleOpenTaskSidePanel,
      addTaskCollaborator,
      removeTaskCollaborator,
      uploadTaskAttachment,
      handleTaskDateChange,
      updateTaskProperty,
      handleUploadDynamicAttachment,
      handlePreviewAttachment,
      changeTaskSection,
      changeTaskPriority,
      taskPermission,
      // subtaskPermission,
      currentWorkspaceBoard,
      currentWorkspace,
      classes,
      readOnly,
      initialState: {
        hiddenColumns: columnsToHide
        // pageSize: 15,
        // manualPagination: true
      }
    },
    useSortBy
    // usePagination
    // useExpanded,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // page,
    // nextPage,
    // canNextPage,
    // previousPage,
    // canPreviousPage,
    prepareRow,
    setHiddenColumns
  } = tableInstance

  useEffect(
    function () {
      setHiddenColumns(columnsToHide)
      setSaveView(!isEqual(savedHiddenCols, columnsToHide))
      // let timer = setTimeout(() => {
      // }, 100)

      // return () => clearTimeout(timer)
    },
    [columnsToHide]
  )

  useEffect(() => {
    if (columnsData) {
      setAllColOptions(
        columnsData.map(item => ({
          label: item.Header,
          value: item.accessor,
          isChecked: !columnsToHide.includes(item.accessor)
        }))
      )
    }
  }, [columnsData, columnsToHide])

  useEffect(
    function () {
      setHiddenColumns(columnsToHide)
      setSaveView(!isEqual(savedHiddenCols, columnsToHide))
    },
    [columnsToHide]
  )

  useEffect(() => {
    return () => {
      prevScrollTop = 0
    }
  }, [])

  // const handleDeleteTask = (row) => {
  //   openDeleteTaskAlert(allTasksData[row.index])
  // }

  const toggleSelectColumnsPopup = e => {
    if (colSelectAnchorEl) setColSelectAnchorEl(null)
    else {
      e.stopPropagation()
      setColSelectAnchorEl(e.currentTarget)
    }
  }

  const toggleColumnsVisibility = (accessor, checked) => {
    if (!checked) {
      setColumnsToHide(prev => [...prev, accessor])
    } else {
      setColumnsToHide(prev => prev.filter(item => item !== accessor))
    }
  }

  const handleSaveView = useCallback(() => {
    childRef.current.setLoading(true)

    // If view object exists then call update else call create
    if (projectListView._id) {
      dispatch(
        updateProjectListView(
          projectListView._id,
          { content: [{ hiddenColumns: columnsToHide }] },
          saveViewCallback
        )
      )
    } else {
      dispatch(
        createProjectListView(
          { type: 'projectList', content: [{ hiddenColumns: columnsToHide }] },
          saveViewCallback
        )
      )
    }
  }, [columnsToHide, projectListView._id])

  const saveViewCallback = (res, err) => {
    childRef.current.setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else dispatch(fireSuccessToaster('Changes saved successfully!'))
  }

  const handleTableContainerScroll = e => {
    // ignore horizontal scroll
    if (e.target.scrollTop > prevScrollTop) {
      prevScrollTop = e.target.scrollTop
      infiniteScroll(e, {
        cb: activateInfiniteScroll => {
          loadMoreTasks((res, err) => {
            if (!err) activateInfiniteScroll()
          })
        }
      })
    }
  }

  return (
    <>
      <header className="text-right mb-4 flex items-center justify-end space-x-4">
        {!readOnly && (
          <button
            onClick={toggleSelectColumnsPopup}
            className={clsx(
              'w-7 h-7 rounded-full text-sm flex justify-center items-center shadow-lg border',
              Boolean(colSelectAnchorEl) && 'bg-primary-main text-white'
            )}
          >
            <AddIcon />
          </button>
        )}

        {taskPermission.create && (
          <>
            <Button
              onClick={toggleCreateTaskModal}
              startIcon={<AddIcon fontSize="small" />}
              size="small"
            >
              New Task
            </Button>
            <Button
              onClick={toggleAddColumnPopup}
              startIcon={<AddIcon fontSize="small" />}
              size="small"
              variant="outlined"
            >
              New Column
            </Button>
          </>
        )}
      </header>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className="relative">
          <div
            className={clsx('overflow-auto', styles.listView)}
            onScroll={
              !readOnly && totalTasks && totalTasks > totalVisibleTasks
                ? handleTableContainerScroll
                : undefined
            }
          >
            <table {...getTableProps()} className="text-sm bg-white">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {/* {!readOnly && <th className={styles.extraRow}></th>} */}
                    {headerGroup.headers.map((col, index) => (
                      <th
                        {...col.getHeaderProps(col.getSortByToggleProps())}
                        className={clsx(
                          'text-sm font-normal capitalize text-left bg-white',
                          index === 0 &&
                            `sticky left-0 z-10 ${styles.stickyCell}`
                        )}
                      >
                        <div className="flex items-center">
                          <span className="inline-block mr-2 text-sm">
                            {datatypeIcon[col.datatype]}
                          </span>
                          <span>{col.render('Header')}</span>
                          <span className="ml-1">
                            {col.isSorted ? (
                              col.isSortedDesc ? (
                                <>&#8593;</>
                              ) : (
                                <>&#8595;</>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                          {col.fieldType === 'dynamic' && (
                            <button
                              onClick={openOptionsPopup}
                              className="p-1 ml-auto"
                              data-id={col.id}
                            >
                              <VerticalThreeDots />
                            </button>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length ? (
                  rows.map(row => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <td
                            {...cell.getCellProps()}
                            className={clsx(
                              'bg-white',
                              index === 0 &&
                                `sticky left-0 z-10 ${styles.stickyCell}`
                            )}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={columnsData.length}
                      className="bg-white py-2 px-2"
                    >
                      No data found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {loadingMoreData && (
            <div
              className="absolute z-9 opacity-60 top-2/4 left-2/4"
              style={{
                transform: `translate(-50%, -50%)`
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
      <SelectColumnsPopup
        anchorEl={colSelectAnchorEl}
        handleClose={toggleSelectColumnsPopup}
        toggleColumnsVisibility={toggleColumnsVisibility}
        hiddenCols={columnsToHide}
        saveView={saveView}
        handleSaveView={handleSaveView}
        ref={childRef}
        allColOptions={allColOptions}
      />
    </>
  )
}

const LoadingAnimation = () => {
  return (
    <div className="flex space-x-1 pl-3">
      <div className="flex-1 space-y-1">
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
      </div>
      <div className="flex-1 space-y-1">
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
      </div>
      <div className="flex-1 space-y-1">
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
      </div>
      <div className="flex-1 space-y-1">
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
      </div>
      <div className="flex-1 space-y-1">
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={40} />
      </div>
    </div>
  )
}

export default ReactTable
