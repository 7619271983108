import { makeStyles, createStyles } from '@material-ui/core'

const ClientDashboardStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: '70vw',
      maxWidth: '600px',
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },
    pseudoDropDownContainer: {
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      height: 36,
      width: 'fit-content',
      padding: theme.spacing(0, 1),
      cursor: 'pointer',
      borderRadius: 6,
      fontSize: 13,
      '&:hover': {
        backgroundColor: 'rgba(21,27,38,.04)'
      }
    },
    attachmentAddbtn: {
      width: 63,
      height: 63,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed #9ca6af',
      borderRadius: 8,
      cursor: 'pointer',
      '&>svg': {
        color: '#9ca6af'
      }
    },
    panelFooter: {
      background: '#f3f3f3'
    },
    teammate: {
      '&:hover .cross-btn': {
        opacity: 1
      }
    },

    removeAssigneeBtn: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      color: 'rgba(255, 0, 0, 0.75)',
      opacity: 0,
      transition: 'opacity 150ms ease-in-out'
    },

    label: {
      fontSize: '0.9rem',
      color: '#000'
    },
    attachment: {
      width: '110px',
      height: '70px',

      '&:hover $attachmentRemoveBtn': {
        opacity: 1
      }
    },
    attachmentRemoveBtn: {
      opacity: 0,
      transition: '150ms ease-in-out'
    }
  })
)

export default ClientDashboardStyles
