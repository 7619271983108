import { useState, useEffect } from 'react'
import { TextField, InputAdornment, Button } from '@material-ui/core'
import { CVLogo } from 'static/Images'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
// import { ReactComponent as CVLogo } from 'static/svg/cv logo.svg'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import TemplateModalLeftView from './TemplateModalLeftView'
import TemplateModalRightView from './TemplateModalRightView'
import templateStyles from './templateStyles'

const TemplateLibrary = ({
  step,
  setStep,
  onClose,
  setSelectedTemplate,
  templateData,
  updateDataHandler,
  handleSearchChange,
  deleteTemplateHandler,
  updateTemplateHandler
}) => {
  const classes = templateStyles()
  const [linkData, setLinkData] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('all')

  const handleSelectTemplate = data => {
    setSelectedTemplate(data)
    setStep(1)
  }

  return (
    <>
      <header
        className={clsx(
          'flex items-center px-6 select-none rounded-t-3xl border-b h-16'
        )}
        style={{ borderBottom: '1px solid #ddd' }}
      >
        <div
          className="flex space-x-2 items-center"
          style={{ flex: '0 0 300px' }}
        >
          {/* <img
            src={CVLogo}
            className=""
            style={{ width: '35px', height: '35px' }}
          /> */}
          <div>
            <h2 className="text-lg lg:text-xl font-semibold text-primary-dark mr-8 whitespace-nowrap">
              Template Center
            </h2>
          </div>
        </div>
        <div className="pl-2 py-4 mr-4" style={{ width: '600px' }}>
          <TextField
            type="text"
            placeholder="Search all templates"
            className="bg-transparent px-2 py-1 w-full text-sm "
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <SearchIcon width="1em" height="1em" />
                </InputAdornment>
              )
            }}
          />
        </div>
      </header>
      <div className="flex pb-5">
        <div className="border-r" style={{ flex: '0 0 240px' }}>
          <TemplateModalLeftView
            linkData={linkData}
            setLinkData={setLinkData}
            setSelectedCategory={setSelectedCategory}
            setStep={setStep}
          />
        </div>
        <div className="flex-1">
          <TemplateModalRightView
            linkData={linkData}
            setLinkData={setLinkData}
            templateData={templateData}
            handleSelectTemplate={handleSelectTemplate}
            updateDataHandler={updateDataHandler}
            selectedCategory={selectedCategory}
            deleteTemplateHandler={deleteTemplateHandler}
            updateTemplateHandler={updateTemplateHandler}
          />
        </div>
      </div>
    </>
  )
}

export default TemplateLibrary
