import { CustomPopper } from 'global/globalComponents'
import { ReactComponent as UserCircle } from 'static/svg/user-circle.svg'

const EmailAddressPopper = ({
  anchorEl,
  onClose,
  handleDialogue,
  list = [],
  type
}) => {
  return (
    <CustomPopper
      anchorEl={anchorEl}
      onClose={onClose}
      // zIndex={zIndex ? zIndex : undefined}
    >
      <div
        className="py-2 rounded-xl overflow-y-auto overflow-x-hidden"
        style={{ minWidth: 250, maxHeight: '50vh' }}
      >
        {list.map((item) => (
          <div
            className="flex items-center space-x-2 text-md text-primary-dark px-4 py-2 cursor-pointer hover:bg-custom-blue-light"
            onClick={(e) => handleDialogue(e, item, type, true)}
          >
            <UserCircle className="w-6 h-6" />
            <p>{item}</p>
          </div>
        ))}
      </div>
    </CustomPopper>
  )
}

export default EmailAddressPopper
