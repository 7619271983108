import axios from 'axios'
import { errorMessages } from 'utils'

export const postUserActivityLogs = async (data) => {
  try {
    const res = await axios({
      url: `/activity`,
      method: 'POST',
      data
    })
  } catch (err) {
    throw err
  }
}

export const fetchUsersActivityLogs = async (callback) => {
  try {
    const res = await axios({
      url: '/activity/user',
      method: 'GET'
    })
    if (callback) callback(res, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const fetchPulseGraphData = async (startDate, endDate, callback) => {
  try {
    const res = await axios({
      url: `/activity/active?start=${startDate}&end=${endDate}`,
      method: 'GET'
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
