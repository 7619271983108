import { makeStyles } from '@material-ui/core'

const financialReportStyles = makeStyles({
  container: {
    display: 'grid',
    gridAutoFlow: 'dense',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '1.5rem'
  },

  clientWiseRevenue: {
    overflowY: 'auto',
    height: '300px',
    background: '#fff'
  },

  '@media(min-width: 624px)': {
    container: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    smallCards: {
      gridColumn: 'span 1',
      gridRow: 'span 1'
    },

    clientRevenue: {
      gridColumn: '2/-1',
      gridRow: '1/4'
    },

    costPerProject: {
      gridColumn: '1/3',
      gridRow: '4/6'
    },
    clientWiseRevenue: {
      height: '500px'
    }

    // costEfficiency: {
    //   gridColumn: '1/3',
    //   gridRow: '3/4'
    // },

    // costPerProject: {
    //   gridColumn: '1/-1',
    //   gridRow: '4/6'
    // }
  },

  '@media(min-width: 824px)': {
    container: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    smallCards: {
      gridColumn: 'span 1',
      gridRow: 'span 1'
    },

    clientRevenue: {
      gridColumn: '3/-1',
      gridRow: '2/4'
    },

    costPerProject: {
      gridColumn: '1/3',
      gridRow: '2/4'
    },

    // costEfficiency: {
    //   gridColumn: '1/3',
    //   gridRow: '2/3'
    // },

    // costPerProject: {
    //   gridColumn: '2/-1',
    //   gridRow: '3/6'
    // },

    clientWiseRevenue: {
      height: '300px'
    }
  },

  '@media(min-width: 1024px)': {
    container: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    },

    smallCards: {
      gridColumn: 'span 1',
      gridRow: 'span 1'
    },

    clientRevenue: {
      gridColumn: '4/-1',
      gridRow: '1/4'
    },

    costPerProject: {
      gridColumn: '1/4',
      gridRow: '2/4'
    },

    clientWiseRevenue: {
      height: '500px'
    }

    // costEfficiency: {
    //   gridColumn: '1/4',
    //   gridRow: '2/3'
    // },

    // costPerProject: {
    //   gridColumn: '2/-1',
    //   gridRow: '3/5'
    // }
  }
})
export default financialReportStyles
