export const SERVICES = {
  LOADING: 'SERVICES_LOADING',
  FETCHED: 'ALL_SERVICES_FETCHED',
  ADD_ONE_SERVICE: 'ADD_ONE_SERVICES',
  DELETE_ONE_SERVICE: 'DELETE_ONE_SERVICES',
  ERROR: 'SERVICES_ERROR',
  UPDATE_ONE_SERVICE: 'UPDATE_ONE_SERVICE'
}

const initialState = {
  error: false,
  loading: false,
  data: [],
  fetched: false
}

export const serviceReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case SERVICES.LOADING:
      return { ...state, loading: true, error: false }

    case SERVICES.ERROR:
      return { ...state, loading: false, error: true }

    case SERVICES.FETCHED:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: action.payload,
        error: false
      }

    case SERVICES.ADD_ONE_SERVICE:
      return { ...state, loading: false, data: [action.payload, ...state.data] }

    case SERVICES.DELETE_ONE_SERVICE:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item._id !== action.payload)
      }
    case SERVICES.UPDATE_ONE_SERVICE:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        loading: false,
        data: updatedData1
      }
    default:
      return state
  }
}
export default serviceReducer
