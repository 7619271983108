import { useState, useEffect } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import {
  deleteStripeAccount,
  getStripeConnectStatus
} from 'thunks/stripeAccount/actions'
import { isEmpty } from 'lodash'
import { useBreadcrumbs } from 'customHooks'
import { Button, CircularProgress } from '@material-ui/core'
import StripeCustomersModal from './StripeCustomersModal'
import userRoles from 'utils/userRoles'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'

function StripePage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const getStripeData = useSelector(state => state.userStripeInfo)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Integrations',
      link: `/settings/integrations`,
      index: 1
    },
    {
      name: 'Stripe',
      link: `/settings/integrations/stripe`,
      index: 2
    }
  ])

  useEffect(() => {
    dispatch(getStripeConnectStatus())
  }, [])

  const removeStripeAccount = id => {
    setLoading(true)
    dispatch(
      deleteStripeAccount(id, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch('Account Removed Successfully!')
          history.push(`/agency/settings/integrations`)
        } else {
          dispatch('Please try again or contact to support!')
        }
      })
    )
  }

  const toggleModalState = () => {
    setModalOpen(prev => !prev)
  }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-6 lg:mb-8">
        <SectionHeading>Stripe Setup</SectionHeading>
        <Button onClick={toggleModalState}>View Customers</Button>
      </div>
      <Wrapper>
        <TableContainer shadow="none">
          <Thead>
            <Th>Stripe</Th>
            <Th center>Status</Th>
            {meData.role === userRoles.USER_AGENCY && <Th center>action</Th>}
          </Thead>
          <Tbody>
            {getStripeData?.loading ? (
              <Tr>
                <Td>Loading ...</Td>
                <Td />
                <Td />
              </Tr>
            ) : !isEmpty(getStripeData?.data) ? (
              <Tr>
                <Td className="text-primary-mid-dark font-medium">
                  {getStripeData?.data?.accountId}
                </Td>
                <Td center>
                  {getStripeData?.data?.isActive ? 'Active' : 'InActive'}
                </Td>
                {meData.role === userRoles.USER_AGENCY && (
                  <Td className="text-center ">
                    {!loading ? (
                      <span
                        className="text-center text-primary-main cursor-pointer"
                        onClick={() =>
                          removeStripeAccount(getStripeData?.data?._id)
                        }
                      >
                        remove
                      </span>
                    ) : (
                      <CircularProgress
                        style={{
                          width: '27px',
                          height: '27px'
                        }}
                      />
                    )}
                  </Td>
                )}
              </Tr>
            ) : (
              <Tr>
                <Td>No connected Stripe</Td>
                <Td />
              </Tr>
            )}
          </Tbody>
        </TableContainer>
      </Wrapper>

      {modalOpen && (
        <StripeCustomersModal open={modalOpen} onClose={toggleModalState} />
      )}
    </OuterContentWrapper>
  )
}

export default StripePage
