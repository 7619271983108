import React, { useEffect, useMemo, useRef, useState } from 'react'
import { IconButton, Tab, Tabs, Typography } from '@material-ui/core'
import { ReactComponent as CaretDown } from 'static/svg/caret-down.svg'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { GoogleCalender } from 'static/Images'
import { getUserPath } from 'utils/userRoles'
// import useWorkspacePermission from 'customHooks/useWorkspacePermission';
import {
  Add
  // FilterCenterFocus,
  // HomeSharp,
  // LocationOn
} from '@material-ui/icons'
// import EmbedModal from 'components/EmbedModal/EmbedModal'
import headerStyles from '../headerStyles'
// import { toggleSideBar } from 'thunks/generlAppState/actions'
import EmbedDropdown from './EmbedDropdown'
import { deleteEmbed, markBoardAsPinned } from 'thunks/embed/action'
import {
  useHistory,
  NavLink,
  // useRouteMatch,
  useLocation
} from 'react-router-dom'
import useWorkspacePermission from 'customHooks/useWorkspacePermission'
import { hideChatMessenger, userRoles } from 'utils'
import { GoogleDocs, GoogleSheet, GoogleSlides } from 'static/Images'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
// import Pill from 'global/globalComponents/Pill/Pill'
import BoardDropdown from './BoardDropdown'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import { addCurrentBoard } from 'thunks/workspace/actions'
import { EMBEDS } from 'thunks/embed/reducer'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { toggleEmbedModal } from 'thunks/generlAppState/actions'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
import SelectTabPopup from 'components/UserDashboard/Workspaces/selectedTabPopup'
import { isEqual } from 'lodash'
import { updateWorkSpace1 } from 'thunks/workspace/actions'

const embed = ['sheet', 'docs', 'slides', 'calendar', 'embed']

const tabIcon = {
  sheet: GoogleSheet,
  docs: GoogleDocs,
  slides: GoogleSlides,
  calendar: GoogleCalender
}

const WorkspaceTabs = ({
  embedModalOpen,
  setEmbedModalOpen
  // openEmbedModalHandler
  // handleTabClick
}) => {
  const classes = headerStyles()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('')

  const viewWidth = useRef(null)
  const [workspaceTab, setWorkspaceTab] = useState({ tab: [], data: {} })
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const meData = useSelector(state => state.me.data)
  // const embedPermission = useSelector(
  //   (state) => state.permission?.data?.access?.workspaceView
  // )
  const { workspaceView: workspaceViewPermission } = useWorkspacePermission(
    currentWorkspace?._id
  )
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const userReportings = useSelector(state => state.userReportings.data)
  // const projectTabViews = useSelector((state) => state.views.data.projectTab)
  // const [embedModalOpen, setEmbedModalOpen] = useState(false)
  const [anchorE2, setAnchorE2] = useState(null)
  const [popoverData, setPopoverData] = useState([])
  const history = useHistory()
  const [iconPopoverData, setIconPopoverData] = useState({
    open: false,
    anchorEl: null,
    id: ''
  })
  const [deletedTab, setDeletedTab] = useState({
    open: false,
    data: {},
    loading: false
  })
  const embedData = useSelector(state => state.userWorkspaceEmbed)
  const [boardPopupMenu, setBoardPopupMenu] = useState([])
  const [boardAnchorEl, setBoardAnchorEl] = useState(null)
  const { pathname, search } = useLocation()
  // const view = useMemo(() => new URLSearchParams(search), [search]).get('view')
  const [deleteBoardAlert, setDeleteBoardAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })

  const [pinBoardAlert, setPinBoardAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })
  const [selectedTabPopup, setSelectedTabPopup] = useState({
    anchorEl: null,
    columns: [
      { label: 'Overview', accessor: 'overview', disable: false },
      { label: 'Board', accessor: 'board', disable: false },
      { label: 'Calendar', accessor: 'calendar', disable: false },
      { label: 'List', accessor: 'list', disable: false },
      { label: 'Reporting', accessor: 'reporting', disable: false },
      { label: 'Files', accessor: 'files', disable: false },
      {
        label: 'Invoices',
        accessor: 'invoice',
        disable: false
      },
      { label: 'Embed Views', show: true, accessor: 'embeds', disable: false }
    ]
  })
  const [hiddenCols, setHiddenCols] = useState([])
  const childRef = useRef()
  const [saveView, setSaveView] = useState(false)

  const projectOwner = useMemo(() => {
    const owner = currentWorkspace.assignedAgencyTeam?.find(
      el => el.role === userRoles.PROJECT_OWNER
    )
    if (owner) {
      return owner.user._id
    } else {
      return null
    }
  }, [currentWorkspace.assignedAgencyTeam])

  const showInvoicesTab = useMemo(() => {
    return (
      currentWorkspace?.category === 'External' &&
      [
        userRoles.USER_AGENCY,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_ADMIN,
        userRoles.USER_CLIENT,
        userRoles.CLIENT_MANAGER
      ].includes(meData.role)
    )
  }, [currentWorkspace?.category, meData.role])
  const userPath = useMemo(() => {
    return getUserPath(meData.role)
  }, [meData.role])

  useEffect(() => {
    setActiveTab(window.location.pathname + window.location.search)
  }, [meData])

  useEffect(() => {
    if (
      currentWorkspace?.category === 'Internal' ||
      ![
        userRoles.USER_AGENCY,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_ADMIN,
        userRoles.USER_CLIENT,
        userRoles.CLIENT_MANAGER
      ].includes(meData.role)
    ) {
      setSelectedTabPopup(prev => ({
        ...prev,
        columns: prev.columns.filter(el => el.accessor !== 'invoice')
      }))
    }
  }, [currentWorkspace?.category])

  // useEffect(() => {
  //   if (
  //     projectTabViews._id &&
  //     projectTabViews.workspace === currentWorkspace._id
  //   ) {
  //     setHiddenCols(projectTabViews.content[0].hiddenColumns)
  //   }
  // }, [projectTabViews, currentWorkspace._id])

  useEffect(() => {
    if (currentWorkspace.projectTabs) {
      setHiddenCols(currentWorkspace.projectTabs[0].hiddenColumns)
    } else {
      setHiddenCols([])
    }
  }, [currentWorkspace])

  useEffect(() => {
    if (currentWorkspace.projectTabs) {
      setSaveView(
        !isEqual(currentWorkspace.projectTabs[0].hiddenColumns, hiddenCols)
      )
    } else if (hiddenCols.length) {
      setSaveView(true)
    } else {
      setSaveView(false)
    }
  }, [currentWorkspace, hiddenCols])

  useEffect(() => {
    const obj = {}

    if (!embedData.loading && workspaceViewPermission?.view) {
      setWorkspaceTab({ tab: Object.keys(obj), data: obj })

      if (embedData.data?.length) {
        embed.forEach(key => {
          let arr = embedData.data?.filter(
            el => el.type === key || el.type.toLowerCase().includes(key)
          )
          if (arr.length) return (obj[key] = arr)
        })

        setWorkspaceTab(prev => ({
          tab: Object.keys(obj),
          data: obj
        }))
      } else {
        setWorkspaceTab(prev => ({
          tab: Object.keys(obj),
          data: obj
        }))
      }
    } else {
      setWorkspaceTab(prev => ({
        tab: Object.keys(obj),
        data: obj
      }))
    }
  }, [embedData, workspaceViewPermission, currentWorkspace?.category])

  useEffect(() => {
    if (embedData && embedData.data.length) {
      const filteredBoards = embedData.data.filter(
        item => item.type === 'board'
      )
      setBoardPopupMenu(
        filteredBoards.map(item => ({
          _id: item._id,
          label: item.name,
          method: handleChangeActiveBoard,
          pinView: item.pinView
        }))
      )
    }
  }, [embedData, pathname])

  const handleChangeActiveBoard = boardId => {
    setActiveTab(`${pathname.split('/board')[0]}/board/${boardId}?view=board`)
    history.push(`${pathname.split('/board')[0]}/board/${boardId}?view=board`)
    closeBoardPopover()
  }

  const closeBoardPopover = () => {
    setBoardAnchorEl(null)
  }

  const handleDeleteWorkspaceTab = data => {
    if (deletedTab.open) {
      setDeletedTab({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeletedTab({
        open: true,
        data: data,
        loading: false
      })
    }
  }

  const deleteEmbedLink = () => {
    setDeletedTab(prevState => ({ ...prevState, loading: true }))
    dispatch(deleteEmbed(deletedTab.data._id, deleteEmbedCallback))
    setIconPopoverData({ open: false, anchorEl: null })
    handleClosePopover()
  }

  const deleteEmbedCallback = (res, err) => {
    setDeletedTab({
      open: false,
      data: {},
      loading: false
    })
    if (!err) {
      history.push(
        `${getUserPath(meData.role)}/workspace/${
          currentWorkspace?._id
        }/overview`
      )
    }
  }

  const handleSwitchActiveTab = (e, newTab) => {
    setActiveTab(newTab)
    history.push(newTab)
  }

  const handleEmbedToggle = () => {
    const { left } = viewWidth.current.getBoundingClientRect()
    dispatch(toggleEmbedModal(true, left))
  }

  const handleClosePopover = () => {
    setAnchorE2(null)
  }

  const handleTabClick = (event, data) => {
    event.stopPropagation()
    setAnchorE2(event.currentTarget)
    setPopoverData(data)
  }

  const handleBoardTabClick = e => {
    if (search === '?view=board') {
      // e.preventDefault()
      e.stopPropagation()
      setBoardAnchorEl(e.currentTarget)
    } else {
      handleSwitchActiveTab(1)
    }
  }

  console.log(activeTab, 'activeTab++++++++++++++++++++++')

  const handleDeleteBoardAlert = data => {
    if (deleteBoardAlert.open) {
      setDeleteBoardAlert(prev => ({ ...prev, open: false, loading: false }))
    } else {
      setDeleteBoardAlert({ open: true, data, loading: false })
    }
  }

  const handleDeleteBoard = () => {
    setDeleteBoardAlert(prevState => ({ ...prevState, loading: true }))
    dispatch(deleteEmbed(deleteBoardAlert.data._id, deleteBoardCallback))
  }

  const deleteBoardCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setDeleteBoardAlert(prevState => ({
        ...prevState,
        loading: false
      }))
    } else {
      setDeleteBoardAlert(prevState => ({
        ...prevState,
        loading: false,
        open: false
      }))

      // If deleting the current active board, go back to default
      if (res._id === currentBoard._id) {
        history.push(
          pathname.split('/board')[0] +
            '/board/' +
            currentWorkspace.defaultBoard._id
        )
      }
      dispatch({ type: EMBEDS.DELETE, payload: res })
    }
  }

  const handlePinBoardAlert = data => {
    if (pinBoardAlert.open) {
      setPinBoardAlert(prev => ({ ...prev, open: false, loading: false }))
    } else {
      setPinBoardAlert({ open: true, data, loading: false })
    }
  }

  const handlePinBoard = () => {
    setPinBoardAlert(prevState => ({ ...prevState, loading: true }))
    dispatch(
      markBoardAsPinned(
        currentWorkspace._id,
        pinBoardAlert.data._id,
        (res, err) => {
          handleChangeActiveBoard(pinBoardAlert.data._id)
          setPinBoardAlert(prevState => ({
            ...prevState,
            open: false,
            loading: false
          }))
        }
      )
    )
  }

  const handleSelectedTab = e => {
    e.stopPropagation()
    if (selectedTabPopup.anchorEl) {
      setSelectedTabPopup(prev => ({ ...prev, anchorEl: null }))
    } else {
      setSelectedTabPopup(prev => ({ ...prev, anchorEl: e.currentTarget }))
    }
  }

  const toggleColumnsVisibility = (accessor, checked) => {
    if (checked) {
      setHiddenCols(prev => prev.filter(el => el !== accessor))
    } else {
      setHiddenCols(prev => [...prev, accessor])
    }
  }

  const handleSaveView = () => {
    childRef.current.setLoading(true)
    dispatch(
      updateWorkSpace1(
        currentWorkspace._id,
        {
          projectTabs: [{ hiddenColumns: hiddenCols }]
        },
        '',
        saveViewCallback
      )
    )
  }

  const saveViewCallback = (res, err) => {
    childRef.current.setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Changes Saved Successfully!'))
      setSelectedTabPopup(prev => ({ ...prev, anchorEl: null }))
    }
  }

  return (
    <>
      <div className={clsx('flex items-center space-x-2')}>
        <Tabs
          classes={{ root: classes.tabsRoot }}
          value={activeTab}
          onChange={handleSwitchActiveTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {!hiddenCols.includes('overview') && (
            <Tab
              classes={{
                root: classes.tabRoot
              }}
              label={
                <div className={clsx('flex items-center justify-center')}>
                  Overview
                </div>
              }
              value={`${getUserPath(meData.role)}/workspace/${
                currentWorkspace?._id
              }/overview`}
              // activeClassName="isActive"
            />
          )}

          {!hiddenCols.includes('board') && (
            <Tab
              classes={{
                root: classes.tabRoot
              }}
              label={
                <div
                  className={clsx('flex items-center justify-center')}
                  onClick={handleBoardTabClick}
                >
                  {boardPopupMenu.length > 1 ? (
                    <>
                      <span className="capitalize">
                        {currentBoard?.name || 'Board'}
                      </span>
                      <button className="ml-2 flex items-center">
                        <span className="text-sm font-semibold">
                          ({boardPopupMenu.length})
                        </span>
                        <CaretDown className={`h-4 w-4`} />
                      </button>
                    </>
                  ) : (
                    <span>Board</span>
                  )}
                </div>
              }
              value={`${userPath}/workspace/${currentWorkspace?._id}/board/${currentBoard?._id}?view=board`}
            />
          )}

          {!hiddenCols.includes('list') && (
            <Tab
              classes={{
                root: classes.tabRoot
              }}
              label={
                <div className={clsx('flex items-center justify-center')}>
                  {' '}
                  List
                </div>
              }
              value={`${userPath}/workspace/${currentWorkspace?._id}/board/${currentBoard?._id}?view=list`}
            />
          )}

          {!hiddenCols.includes('calendar') && (
            <Tab
              classes={{
                root: classes.tabRoot
              }}
              label={
                <div className={clsx('flex items-center justify-center')}>
                  {' '}
                  Calendar
                </div>
              }
              value={`${userPath}/workspace/${currentWorkspace?._id}/board/${currentBoard?._id}?view=calendar`}
            />
          )}

          {!hiddenCols.includes('reporting') && (
            <Tab
              classes={{
                root: classes.tabRoot
              }}
              label={
                <div className={clsx('flex items-center justify-center')}>
                  {' '}
                  Reporting
                </div>
              }
              value={
                userReportings.length
                  ? `${userPath}/workspace/${currentWorkspace?._id}/status-updates/${userReportings[0]._id}`
                  : `${userPath}/workspace/${currentWorkspace?._id}/status-updates`
              }
              activeClassName="isActive"
            />
          )}

          {!hiddenCols.includes('files') && (
            <Tab
              classes={{
                root: classes.tabRoot
              }}
              label={
                <div className={clsx('flex items-center justify-center')}>
                  Files
                </div>
              }
              value={`${userPath}/workspace/${currentWorkspace?._id}/files`}
            />
          )}

          {showInvoicesTab && !hiddenCols.includes('invoice') && (
            <Tab
              classes={{
                root: classes.tabRoot
              }}
              label={
                <div className={clsx('flex items-center justify-center')}>
                  Invoices
                </div>
              }
              value={`${userPath}/workspace/${currentWorkspace?._id}/invoices`}
            />
          )}

          {!hiddenCols.includes('embeds') &&
            workspaceTab.tab.map((item, index) => (
              <Tab
                classes={{
                  root: classes.tabRoot
                }}
                label={
                  <div
                    className={clsx(
                      'flex items-center space-x-1 show-on-hover-parent relative'
                    )}
                    style={{ paddingRight: 10 }}
                  >
                    {tabIcon[item] && (
                      <img
                        src={tabIcon[item]}
                        alt=""
                        height="14px"
                        width="14px"
                        className="block"
                      />
                    )}
                    <span>
                      {item === 'embed' && workspaceTab.data[item].length <= 1
                        ? workspaceTab.data[item][0]?.name
                        : item}
                    </span>

                    {workspaceViewPermission?.delete &&
                      workspaceTab.data[item].length === 1 &&
                      item !== 'board' && (
                        <CustomTooltip
                          title={
                            item.includes('google')
                              ? `Delete ${item.replace('google', 'Google ')}`
                              : `Delete ${workspaceTab.data[item][0].name}`
                          }
                          placement="top"
                        >
                          <IconButton
                            onClick={() =>
                              handleDeleteWorkspaceTab(
                                workspaceTab.data[item][0]
                              )
                            }
                            size="small"
                            className={`cross-btn show-on-hover-child ${classes.deleteEmbedBtn}`}
                          >
                            <CancelRoundedIcon
                              fontSize="small"
                              style={{ width: '18px', height: '18px' }}
                            />
                          </IconButton>
                        </CustomTooltip>
                      )}
                    {workspaceTab.data[item].length > 1 && (
                      <div
                        className={clsx(
                          { isActive: activeTab === index },
                          classes.popover
                        )}
                        onClick={e =>
                          handleTabClick(e, workspaceTab.data[item])
                        }
                      >
                        <p className="text-green-700">
                          {workspaceTab.data[item].length}
                        </p>
                        <CaretDown
                          className={`h-4 w-4 text-white fill-white`}
                          style={{ fill: 'gray' }}
                        />
                      </div>
                    )}
                  </div>
                }
                // activeClassName="isActive"
                value={
                  !workspaceTab.data[item].length
                    ? `${userPath}/workspace/${
                        currentWorkspace?._id
                      }/${item?.toLowerCase()}`
                    : `${userPath}/workspace/${
                        currentWorkspace?._id
                      }/${item?.toLowerCase()}/${
                        workspaceTab.data[item][0]._id
                      }`
                }
              />
            ))}
        </Tabs>
        {workspaceViewPermission?.create && (
          <Typography
            ref={viewWidth}
            onClick={handleEmbedToggle}
            style={{ position: 'relative' }}
            className={clsx(classes.tab, 'flex items-center space-x-1')}
          >
            <Add classes={{ root: classes.add }} />
            <span>Add View</span>
          </Typography>
        )}
        {([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
          meData.role
        ) ||
          (projectOwner && meData._id === projectOwner)) && (
          <div className="relative" onClick={handleSelectedTab}>
            <ThreeDotsIcon />
          </div>
        )}
      </div>
      <EmbedDropdown
        deleteEmbedLink={handleDeleteWorkspaceTab}
        handleClose={handleClosePopover}
        data={popoverData}
        anchorEl={anchorE2}
        setActiveTab={setActiveTab}
        iconPopoverData={iconPopoverData}
        setIconPopoverData={setIconPopoverData}
        workspaceViewPermission={workspaceViewPermission}
      />
      <BoardDropdown
        anchorEl={boardAnchorEl}
        menuItems={boardPopupMenu}
        handleClose={closeBoardPopover}
        activeBoardId={currentBoard?._id}
        workspaceViewPermission={workspaceViewPermission}
        handleDeleteBoardAlert={handleDeleteBoardAlert}
        handlePinBoardAlert={handlePinBoardAlert}
        currentWorkspace={currentWorkspace}
      />
      <DeleteModal
        warningText={`The Selected ${
          deletedTab.data.type
        } and all its related ${
          deletedTab.data.type === 'board' ? 'sections and tasks' : 'data'
        } will be deleted forever.`}
        open={deletedTab.open}
        handleDialog={handleDeleteWorkspaceTab}
        handleDeleteAction={deleteEmbedLink}
        loading={deletedTab.loading}
      />
      <DeleteModal
        heading={deleteBoardAlert.data.label}
        warningText="Do you want to delete this board and all the tasks in it?"
        open={deleteBoardAlert.open}
        handleDialog={handleDeleteBoardAlert}
        handleDeleteAction={handleDeleteBoard}
        loading={deleteBoardAlert.loading}
      />
      <AlertModal
        heading={pinBoardAlert.data.label}
        warningText="Do you want to pin this board and make it your default board?"
        alertType="confirm"
        open={pinBoardAlert.open}
        handleDialog={handlePinBoardAlert}
        handleDeleteAction={handlePinBoard}
        loading={pinBoardAlert.loading}
        deleteBtnText="Pin"
      />
      <SelectTabPopup
        anchorEl={selectedTabPopup.anchorEl}
        handleClose={handleSelectedTab}
        colOptions={selectedTabPopup.columns}
        toggleColumnsVisibility={toggleColumnsVisibility}
        hiddenCols={hiddenCols}
        handleSaveView={handleSaveView}
        saveView={saveView}
        ref={childRef}
      />
    </>
  )
}

export default WorkspaceTabs
