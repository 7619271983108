import React from 'react'
import clsx from 'clsx'
import GettingVideoCard from './GettingVideoCard'
import GettingStartedTasks from './GettingStartedTasks'
import { useBreadcrumbs } from 'customHooks'
import gettingStartedStyles from './GettingStartedStyles'

function GettingStarted() {
  const classes = gettingStartedStyles()

  useBreadcrumbs([
    {
      name: 'Getting Started',
      link: `/getting-started`,
      index: 0
    }
  ])

  return (
    <div className="p-8">
      <div className={clsx(classes.container, 'mx-auto')}>
        <GettingVideoCard />
        <GettingStartedTasks />
      </div>
    </div>
  )
}

export default GettingStarted
