import axios from 'axios'
import { errorMessages } from 'utils'
import { CANCEL_CLIENT_SERVICE_REQUEST } from './reducers'
import { createAccountLogs } from 'thunks/accountLogs/action'

/**
 * fetch all cancel client service request  by domain
 * @param {Function} callback callback function
 */

export const fetchCancelServiceRequests = (callback) => async (dispatch) => {
  dispatch({ type: CANCEL_CLIENT_SERVICE_REQUEST.LOADING })

  try {
    const res = await axios({
      url: `/clientpayment/subscription/cancelrequest/view`,
      method: 'GET'
    })

    const sortedData = res.data.sort(
      (a, b) =>
        new Date(b.createdAt || b.created * 1000) -
        new Date(a.createdAt || a.created * 1000)
    )

    if (callback) callback(res.data)
    dispatch({
      type: CANCEL_CLIENT_SERVICE_REQUEST.FETCHED,
      payload: sortedData
    })
  } catch (err) {
    dispatch({ type: CANCEL_CLIENT_SERVICE_REQUEST.ERROR })
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Approve or reject user's cancel service request
 * @param {String} id service request id
 * @param {Object} data object
 * @param {Function} callback function
 */
export const approveRejectServiceCancelReq = (
  serviceReqId,
  data,
  callback
) => async (dispatch) => {
  try {
    const res = await axios({
      url: `/clientpayment/subscription/cancelrequest/${serviceReqId}`,
      method: 'PUT',
      data
    })
    createAccountLogs({
      description: `${
        res.data?.cancelRequest === 'cancel' ? 'rejected' : 'approved'
      } a service cancellation request of client ${
        res?.data?.user?.name ? res?.data?.user?.name : null
      } for service ${res.data?.name}`,
      category: 'clientPayment',
      clientPayment: res.data._id,
      method: 'put'
    })
    dispatch({
      type: CANCEL_CLIENT_SERVICE_REQUEST.REMOVE,
      payload: res.data._id
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
