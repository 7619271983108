import axios from 'axios'
import { createAccountLogs } from 'thunks/accountLogs/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { INVOICES } from 'thunks/invoices/reducer'
import { DASHBOARD } from 'thunks/userDashboard/reducer'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { errorMessages } from 'utils'
import { CLIENT } from './reducers'
import { createDefaultWidgets } from 'thunks/widget/actions'
import { PULSE } from 'thunks/pulse/reducer'

/**
 * Add one client
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const addClient = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: CLIENT.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: '/user/addClient',
        data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `created a client <strong>${res?.data?.name}</strong>`,
          category: 'client',
          client: res.data._id,
          method: 'post'
        })
      )
      dispatch({ type: CLIENT.ADD_ONE_CLIENT, payload: res.data })
      // dispatch({
      //   type: DASHBOARD.UPDATE,
      //   payload: { count: 1, key: 'totalClients' }
      // })
    } catch (err) {
      const errMessage =
        err.response?.data?.message || errorMessages.ERROR_MESSAGE
      dispatch({ type: CLIENT.ERROR, payload: err.response })
      if (callback) callback(errMessage, true)
    }
  }
}

/**
 * Add multi clients
 * @param {Function} callback callback function
 */
export const addMultiClient = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/user/addMultipleClient`,
        data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Add one client
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const inviteClient = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/user/inviteclient',
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(err.response?.data?.message || errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * fetch all clients of a user.
 * @param {Function} callback  callback function
 */

export const getClient = (callback) => {
  return async (dispatch) => {
    dispatch({ type: CLIENT.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/client'
      })
      dispatch({ type: CLIENT.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
      dispatch({
        type: PULSE.CREATE_USERS_PULSE,
        payload: res.data.map((item) => ({
          _id: item._id,
          lastActive: item.lastActive
        }))
      })
    } catch (err) {
      dispatch({ type: CLIENT.ERROR, payload: err.response })
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete one client of a user.
 * @param {String} client client Id
 * @param {Function} callback  callback function
 */
export const deleteclient = (clientId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/user/${clientId}`,
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `deleted a client <strong>${res?.data?.name}</strong>`,
          category: 'client',
          client: res.data._id,
          method: 'delete'
        })
      )
      dispatch({ type: CLIENT.DELETE_ONE_CLIENT, payload: res.data })
      // dispatch({
      //   type: DASHBOARD.UPDATE,
      //   payload: { count: -1, key: 'totalClients' }
      // })

      // if (data.isWorkspaceDelete) {
      //   console.log('inside workspace')
      //   dispatch({
      //     type: WORKSPACES.UPDATE_MANY_WORKSPACES,
      //     payload: { id: res.data?._id, key: 'assignedClientTeam' }
      //   })
      // }
      // if (data.isClientPaymentDelete) {
      //   console.log('inside clientpayment')
      //   dispatch({
      //     type: INVOICES.DELETE_MANY_INVOICE,
      //     payload: { id: res.data?._id }
      //   })
      // }
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: CLIENT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * update client data.
 * @param {String} client clientId
 * @param {Object} data name,workspaces
 * @param {Function} callback callback
 */

export const updateClient = (client, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `clientprofile/client/${client}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `updated a client <strong>${res?.data?.name}</strong>`,
          category: 'client',
          client: res.data._id,
          method: 'put'
        })
      )
      dispatch({ type: CLIENT.UPDATE_ONE_CLIENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: CLIENT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Update client status
 * @param {String} client clientId
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateClientStatus = (client, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${client}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: CLIENT.UPDATE_ONE_CLIENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: CLIENT.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Update client active services payment status
 * @param {String} id service id
 * @param {Object} data status paid or unpaid
 * @param {Function} callback callback function
 */
export const updateClientServicePaymentStatus = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/clientpayment/agency/${id}`,
        data
      })
      dispatch({
        type: CLIENT.UPDATE_CURRENT_CLIENT_PAYMENT_STATUS,
        payload: res.data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch client invoices
 * @param {String} id service id
 * @param {Function} callback callback function
 */
export const fetchClientInvoices = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/clientinvoice/user/${id}`
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch client info
 * @param {String} id client id
 * @param {Function} callback callback function
 */
export const fetchClientInfo = async (id, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/clientprofile/client/info/${id}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
