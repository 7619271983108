import {
  SlackLogo,
  CrispLogo,
  LiveChatLogo,
  HubSpotLogo,
  TwakLogo,
  IntercomLogo,
  StripeLogo,
  FacebookLogo,
  ZapierLogo
} from 'static/Images'

const integrationCardDetails = {
  stripe: {
    name: 'Stripe',
    type: 'payments',
    image: StripeLogo,
    actionText: 'Connect',
    connected: false
  },
  zapier: {
    name: 'Zapier',
    type: 'others',
    image: ZapierLogo,
    commingsoon: true,
    actionText: 'Connect',
    commingsoon: true,
    connected: false
  },
  webhooks: [
    {
      name: 'Slack',
      type: 'others',
      image: SlackLogo,
      actionText: 'Connect',
      connected: false,
      to: '/agency/settings/integrations/slack'
    },
    {
      name: 'Webhook',
      type: 'others',
      image: 'https://api.useinfluence.co/images/webhooksBtnIcon.svg',
      actionText: 'Connect',
      connected: false,
      to: '/agency/settings/integrations/webhooks'
    }
  ],
  chat: [
    {
      name: 'Intercom',
      type: 'support',
      image: IntercomLogo,
      actionText: 'Connect',
      connected: false,
      commingsoon: false,
      modalContent: {
        description:
          'Chat with customers in real time and send proactive engagement messages.',
        accountId: 'Workspace ID',
        helpLink: 'Settings > Installation > Web'
      }
    },
    {
      name: 'Crisp',
      type: 'support',
      image: CrispLogo,
      actionText: 'Connect',
      connected: false,
      commingsoon: false,
      modalContent: {
        description:
          'A live chat solution with video call capability and proactive messaging.',
        accountId: 'Website ID',
        helpLink:
          'Settings > Website Settings > Setup Instructions > Website ID within your Crisp account.'
      }
    },
    {
      name: 'LiveChat',
      type: 'support',
      image: LiveChatLogo,
      actionText: 'Connect',
      connected: false,
      commingsoon: true,
      modalContent: {
        description:
          'A complete customer service platform that delights your customers and fuels your sales.',
        accountId: 'License Number',
        helpLink:
          "Settings > Channels > Website and it will be located in the snippet under the 'Install LiveChat code manually' section."
      }
    },
    {
      name: 'HubSpot',
      type: 'support',
      image: HubSpotLogo,
      actionText: 'Connect',
      connected: false,
      commingsoon: true,
      modalContent: {
        description:
          "HubSpot's live chat tool is fully integrated with a chatbot builder — no coding required. Easily create bots that help you qualify leads and more.",
        accountId: 'Embed Code Src',
        helpLink:
          "URL contained in the 'src' property included in the code snippet."
      }
    },
    {
      name: 'Tawk.to',
      type: 'support',
      image: TwakLogo,
      actionText: 'Connect',
      connected: false,
      commingsoon: true,
      modalContent: {
        description:
          'A simple and free live chat solution to respond to client messages.',
        accountId: 'Direct Chat Link',
        helpLink: 'Administration > Chat Widget within your Tawk.to account.'
      }
    },
    {
      name: 'Facebook Pixel',
      type: 'support',
      image: FacebookLogo,
      actionText: 'Connect',
      connected: false,
      commingsoon: false,
      modalContent: {
        description: 'Measure conversions & power up your advertising',
        accountId: 'Enter your Id',
        helpLink: 'Administration > Chat Widget within your facebook account.'
      }
    }
  ]
}

export default integrationCardDetails
