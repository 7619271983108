import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router'

import { getUserPath } from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'

import SelectedUserTimesheet from './Timesheets/SelectedUserTimesheet'
import ResourceManagement from './ResourceManagement'
import { fetchTeamVerticals } from 'thunks/Verticals/action'

const ResourceNavigation = () => {
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const teamVerticals = useSelector((state) => state.teamVerticals)

  useEffect(() => {
    if (!teamVerticals.fetched) {
      dispatch(fetchTeamVerticals())
    }
  }, [])

  return (
    <Switch>
      <Route
        exact
        path={`${getUserPath(
          meData?.role
        )}/resource-management/timesheets/:time/:userId`}
        component={SelectedUserTimesheet}
      />
      <Route path={`${getUserPath(meData?.role)}/resource-management`}>
        <ResourceManagement />
      </Route>
    </Switch>
  )
}

export default ResourceNavigation
