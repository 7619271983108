// ========= Will make it better soon ============
function findSpaceInGrid(box, cols, max_h = 0) {
  let collision = false,
    maxCols = 12

  // Filter out all the columns which are on the same row
  const filteredCols = cols.filter((col) => {
    if (col.y === box.y) return true
    if (col.y < box.y && col.y + col.h >= box.y) return true
    if (col.y > box.y && box.y + box.h >= col.y) return false
  })

  for (let col of filteredCols) {
    if (
      (col.x === box.x && col.y === box.y) ||
      (col.x <= box.x && col.x + col.w > box.x && col.y + col.h > box.y) ||
      (box.x <= col.x &&
        box.x + box.w > col.x &&
        box.h > col.y &&
        box.y <= col.y)
    ) {
      collision = true
      if (col.x + col.w + box.w > maxCols) {
        box.y = max_h
        box.x = 0
      } else {
        box.x = col.x + col.w
      }

      if (max_h < col.y + col.h) {
        max_h = col.y + col.h
      }
      break
    }
  }

  let spaceData
  if (collision) spaceData = findSpaceInGrid(box, cols, max_h)
  return box
}

export default findSpaceInGrid
