import React, { useEffect, useState } from 'react'
import { Box, Popover, List, ListItem } from '@material-ui/core'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
// import AsanaIcons from 'static/asana/AsanaIcons'
// import { updateWorkSpace1 } from 'thunks/workspace/actions'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEmbed } from 'thunks/embed/action'
import Category from 'utils/workspaceCategories'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import CheckIcon from '@material-ui/icons/Check'
import UserDashboardStyles from '../userDashboardStyles'
import Tags from './Tags'
import DuplicateModal from './DuplicateModal'
import WorkspaceIconPopover from './WorkspaceIconPopover'

const WorkspaceMenu = (props) => {
  const {
    open,
    anchorEl,
    onClose,
    workspace = {},
    EditWorkspace,
    duplicateWorkspace,
    deleteWorkspace,
    archiveWorkspace,
    completeStatus
  } = props

  const dispatch = useDispatch()
  const classes = UserDashboardStyles()
  // const userAccess = useSelector((state) => state.permission.data?.access)
  const [iconPopoverData, setIconPopoverData] = useState({
    open: false,
    anchorEl: null
  })

  const [tagsPopoverData, setTagsPopoverData] = useState({
    open: false,
    anchorEl: null
  })

  const [duplicateProject, setDuplicateProject] = useState({
    open: false
  })

  const [workspaceBoard, setWorkspaceBoard] = useState([])

  // const [colorOptions, setColorOptions] = useState([
  //   '#ffffff',
  //   '#fb5779',
  //   '#ff7511',
  //   '#ffa800',
  //   '#ffd100',
  //   '#ace60f',
  //   '#19db7e',
  //   '#00d4c8',
  //   '#48dafd',
  //   '#0064fb',
  //   '#6457f9',
  //   '#9f46e4',
  //   '#ff78ff',
  //   '#ff4ba6',
  //   '#ff93af',
  //   '#5a7896'
  // ])

  // const handleUpdateBgColor = (backgroundcolor) => {
  //   dispatch(
  //     updateWorkSpace1(workspace._id, { backgroundcolor }, 'background color')
  //   )
  // }

  // const handleUpdateWSicon = (icon) => {
  //   dispatch(updateWorkSpace1(workspace._id, { image: icon }, 'image icon'))
  // }

  // const errorToaster = (error) => {
  //   dispatch(fireErrorToaster('You are not an authorized user'))
  // }

  const handleDuplicateProjectModal = () => {
    if (duplicateProject.open) {
      setDuplicateProject({
        open: false
      })
    } else {
      setDuplicateProject({
        open: true
      })

      dispatch(
        fetchEmbed(workspace._id, (res, err) => {
          if (!err) {
            setWorkspaceBoard(res)
          }
        })
      )
      onClose()
    }
  }

  const duplicateWorkspaceHandler = (list) => {
    duplicateWorkspace(workspace, list)
  }

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorPosition={{ top: 50, left: 140 }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{ paper: classes.popoverPaper }}
      >
        <List classes={{ root: classes.listRoot }} dense>
          {!workspace?.isArchived && !workspace?.isComplete && (
            <>
              <ListItem
                onClick={(e) =>
                  setIconPopoverData({ open: true, anchorEl: e.currentTarget })
                }
                // onMouseLeave={(e) =>
                //   setIconPopoverData({ open: false, anchorEl: null })
                // }
                button
              >
                Set color &amp; icon <ChevronRightRoundedIcon />
              </ListItem>
              <ListItem
                button
                onClick={(e) =>
                  setTagsPopoverData({ open: true, anchorEl: e.currentTarget })
                }
              >
                Edit tags
                <ChevronRightRoundedIcon />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  EditWorkspace(workspace)
                  onClose()
                }}
              >
                Settings
              </ListItem>
              <ListItem button onClick={handleDuplicateProjectModal}>
                Duplicate Project
              </ListItem>
            </>
          )}
          {workspace &&
          Object.keys(workspace).includes('isComplete') &&
          workspace.isComplete ? null : workspace?.category ==
            Category?.INTERNAL ? null : workspace?.isArchived ? null : (
            <ListItem
              button
              onClick={() => {
                completeStatus(workspace)
                onClose()
              }}
            >
              Mark as complete
            </ListItem>
          )}

          {!workspace.isComplete && (
            <ListItem
              button
              onClick={() => {
                archiveWorkspace(workspace)
                onClose()
              }}
            >
              {workspace?.isArchived ? 'Unarchive' : 'Archive'} Project
            </ListItem>
          )}
          {!workspace?.isArchived && (
            <ListItem
              button
              onClick={() => {
                deleteWorkspace(workspace)
                onClose()
              }}
            >
              Delete Project
            </ListItem>
          )}
        </List>
      </Popover>
      <WorkspaceIconPopover
        setIconPopoverData={setIconPopoverData}
        iconPopoverData={iconPopoverData}
        onClose={onClose}
        workspace={workspace}
      />
      {/* <Popover
        open={iconPopoverData.open}
        onClose={() => {
          setIconPopoverData({ open: false, anchorEl: null })
          onClose()
        }}
        anchorEl={iconPopoverData.anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        classes={{ paper: classes.iconsPopoverPaper }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box className={classes.colorsContainer}>
          {colorOptions.map((item) => (
            <label
              onClick={() => handleUpdateBgColor(item)}
              key={item}
              style={{ backgroundColor: item }}
              className="flex justify-center items-center"
            >
              {currentWorkspace?.backgroundcolor === item && (
                <CheckIcon
                  style={{
                    color: currentWorkspace.backgroundcolor.includes('#fff')
                      ? '#000'
                      : '#fff'
                  }}
                />
              )}
            </label>
          ))}
        </Box>
        <div className={classes.iconScrollableWrapper}>
          <div className={classes.iconsContainer}>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Board')}
            >
              <AsanaIcons.Board />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('BriefCase')}
            >
              <AsanaIcons.BriefCase />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Bug')}
            >
              <AsanaIcons.Bug />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Calender')}
            >
              <AsanaIcons.Calender />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('ChatBubbles')}
            >
              <AsanaIcons.ChatBubbles />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Check')}
            >
              <AsanaIcons.Check />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Coins')}
            >
              <AsanaIcons.Coins />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Computer')}
            >
              <AsanaIcons.Computer />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Gear')}
            >
              <AsanaIcons.Gear />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Globe')}
            >
              <AsanaIcons.Globe />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Graph')}
            >
              <AsanaIcons.Graph />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Html')}
            >
              <AsanaIcons.Html />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('LightBulb')}
            >
              <AsanaIcons.LightBulb />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('LineAndSymbols')}
            >
              <AsanaIcons.LineAndSymbols />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('List')}
            >
              <AsanaIcons.List />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Map')}
            >
              <AsanaIcons.Map />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('MegaPhone')}
            >
              <AsanaIcons.MegaPhone />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('MountainFlag')}
            >
              <AsanaIcons.MountainFlag />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Notebook')}
            >
              <AsanaIcons.Notebook />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('PageLayout')}
            >
              <AsanaIcons.PageLayout />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('People')}
            >
              <AsanaIcons.People />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Presentation')}
            >
              <AsanaIcons.Presentation />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Puzzle')}
            >
              <AsanaIcons.Puzzle />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Ribbon')}
            >
              <AsanaIcons.Ribbon />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Rocket')}
            >
              <AsanaIcons.Rocket />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Shoe')}
            >
              <AsanaIcons.Shoe />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('ShoppingBasket')}
            >
              <AsanaIcons.ShoppingBasket />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('SpeedDial')}
            >
              <AsanaIcons.SpeedDial />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Star')}
            >
              <AsanaIcons.Star />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Target')}
            >
              <AsanaIcons.Target />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Ticket')}
            >
              <AsanaIcons.Ticket />
            </div>
            <div
              className={classes.iconWrapper}
              onClick={() => handleUpdateWSicon('Timeline')}
            >
              <AsanaIcons.Timeline />
            </div>
          </div>
        </div>
      </Popover> */}
      <Tags
        workspace={workspace}
        setIconPopoverData={setIconPopoverData}
        onClose={onClose}
        tagsPopoverData={tagsPopoverData}
        setTagsPopoverData={setTagsPopoverData}
        elmFor="workspace"
      />
      <DuplicateModal
        open={duplicateProject.open}
        handleDialog={handleDuplicateProjectModal}
        handleDuplicateAction={duplicateWorkspaceHandler}
        workspace={workspace}
        workspaceBoard={workspaceBoard}
      />
    </>
  )
}

export default WorkspaceMenu
