import React, { useState } from 'react'
import { makeStyles, Popper, ClickAwayListener } from '@material-ui/core'
import clsx from 'clsx'
import { useEffect } from 'react'

const CustomPopper = ({ anchorEl, onClose, children, zIndex }) => {
  const classes = useStyles()
  const [arrowRef, setArrowRef] = useState(null)
  const [active, setActive] = useState(false)

  /*
  In some cases ClickAwayListener's onClickAway method gets invoked as soon as
  popup opens thats why this popup never shows up on screen

  */
  useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        setActive(true)
      }, 100)
    } else {
      setActive(false)
    }
  }, [anchorEl])

  return (
    <ClickAwayListener onClickAway={e => active && onClose(e)}>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal={false}
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          arrow: {
            enabled: true,
            element: arrowRef
          }
        }}
        style={{ zIndex: zIndex ? zIndex : 2000 }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <div className={clsx('bg-white', classes.menuPaper)}>{children}</div>
      </Popper>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles({
  menuPaper: {
    borderRadius: '10px'
  },

  popper: {
    zIndex: 2000,
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '0 100%'
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '100% 0'
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '100% 100%'
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.71em',
      height: '1em',
      width: '0.71em',
      marginTop: 4,
      marginBottom: 4,
      '&::before': {
        transformOrigin: '0 0'
      }
    }
  },
  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: '#fff',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: '0 0 1px rgba(0, 0, 0, 0.20)',
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)'
    }
  }
})

export default React.memo(CustomPopper)
