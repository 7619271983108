import React, { useMemo } from 'react'
import { List, ListItem, Popover } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as CheckIcon } from 'static/svg/check-icon.svg'

const StatusUpdateDropdown = ({
  anchorEl,
  selectedStatus,
  onClose,
  handleChangeStatus
}) => {
  const options = useMemo(() => Object.values(projectStatusData), [])
  const handleSelectOption = (value) => {
    handleChangeStatus(value)
    onClose()
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      style={{ zIndex: 2000 }}
    >
      <div className="bg-white shadow w-40">
        <List>
          {options.map((item) => (
            <ListItem
              key={item.value}
              button
              onClick={() => handleSelectOption(item.value)}
            >
              <div className="flex items-center">
                <span className="w-4 mr-2">
                  {selectedStatus === item.value && (
                    <CheckIcon className="text-sm text-gray-500" />
                  )}
                </span>
                <span
                  className={clsx(
                    'inline-block w-3 h-3 rounded-full mr-2',
                    item.bgColorClass
                  )}
                ></span>
                <span className="text-sm">{item.label}</span>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </Popover>
  )
}

export const projectStatusData = {
  1: {
    label: 'On track',
    value: 1,
    colorClass: 'text-custom-green-main',
    bgColorClass: 'bg-custom-green-main',
    color: '#098C45',
    colorString: 'green'
  },
  2: {
    label: 'At risk',
    value: 2,
    colorClass: 'text-custom-orange-main',
    bgColorClass: 'bg-custom-orange-main',
    color: '#E78333',
    colorString: 'orange'
  },
  3: {
    label: 'Off track',
    value: 3,
    colorClass: 'text-custom-red-main',
    bgColorClass: 'bg-custom-red-main',
    color: '#E02A2A',
    colorString: 'red'
  }
}

export default React.memo(StatusUpdateDropdown)
