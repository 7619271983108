export const CLIENT_RAISE_REQUEST = {
  FETCHED: 'CLIENT_RAISE_REQUEST_FETCHED',
  ERROR: 'CLIENT_RAISE_REQUEST_ERROR',
  UPDATE_ONE: 'CLIENT_RAISE_REQUEST_UPDATE_ONE',
  ADD_ONE: 'CLIENT_RAISE_REQUEST_ADD_ONE'
}

const initialState = {
  loading: true,
  data: [],
  error: false,
  fetched: false
}

const clientRaiseRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_RAISE_REQUEST.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case CLIENT_RAISE_REQUEST.UPDATE_ONE:
      const updatedData = state.data.map((item) => {
        if (item._id === action.payload._id) return action.payload
        return item
      })

      return { ...state, data: updatedData }

    case CLIENT_RAISE_REQUEST.ADD_ONE:
      const newData = [action.payload, ...state.data]
      return { ...state, data: newData }

    case CLIENT_RAISE_REQUEST.ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}

export default clientRaiseRequestReducer
