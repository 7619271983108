import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import LinkIcon from '@material-ui/icons/Link'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { useParams } from 'react-router-dom'

// const fieldsKeyValues = [
//   { title: 'Name', keyName: 'title', isDisabled: true },
//   { title: 'Description', keyName: 'description', isDisabled: true },
//   { title: 'Attachments', keyName: 'attachments', isDisabled: false },
//   { title: 'Due Date', keyName: 'dueDate', isDisabled: false },
//   { title: 'Priority', keyName: 'priority', isDisabled: false },
//   { title: 'Status', keyName: 'status', isDisabled: false }
// ]

const ShareBoardCopyLink = ({ workspaceId, url }) => {
  const dispatch = useDispatch()
  // const classes = BoardsStyles()
  // const { workspaceId } = useParams()

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url)
    dispatch(fireSuccessToaster('Link Copied'))
  }

  return (
    <div className="bg-custom-gray-light py-2">
      <div className="flex flex-row justify-between py-2">
        <div className="flex">
          <LinkIcon fontSize="small" />
          <p className="ml-2 text-smaller lg:text-sm font-medium">
            Public Link
          </p>
        </div>
        <p className="text-xs font-normal text-gray-600">
          only those with the link can access
        </p>
      </div>

      <div className="border my-2 p-2 flex justify-between bg-white">
        <p className="text-smaller lg:text-sm font-medium truncate">{url}</p>
        <span
          role="button"
          className="text-smaller lg:text-sm font-medium text-primary-main cursor-pointer whitespace-nowrap"
          onClick={handleCopyLink}
        >
          Copy Link
        </span>
      </div>
    </div>
  )
}

export default React.memo(ShareBoardCopyLink)
