import { useEffect, useMemo, useState } from 'react'
import { ColoredAvatars, ToggleSwitch } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import {
  updateTeammateStatus,
  deleteTeammate,
  updateTeammate
} from 'thunks/teammate/actions'
import { format } from 'date-fns'
import { errorMessages } from 'utils'
import Pill from 'global/globalComponents/Pill/Pill'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { userRoles, getRoleBadge } from 'utils'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as Calendar } from 'static/svg/calendar.svg'
import { CustomTooltip } from 'global/globalComponents'
import CompanyDropdown from 'components/ClientCompanyComponents/CompanyDropdown'
import ProjectsDropdown from './ProjectsDropdown'
import { ReactComponent as AngleIcon } from 'static/svg/angle-down.svg'

const totalTeammatesToShow = 8

const TeammatesTable = ({
  loading,
  openEditPanel,
  teammates,
  handleInviteTeammate,
  canEditAccount
}) => {
  const dispatch = useDispatch()
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])
  const access = useSelector(state => state.permission.data.access)
  const meData = useSelector(state => state.me.data)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const teamVerticals = useSelector(state => state.teamVerticals.data)
  const [deleteTeammateData, setdeleteTeammateData] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [roleAnchorEl, setRoleAnchorEl] = useState({
    anchorEl: null,
    data: {},
    key: ''
  })
  // ------ Related to the pagination --------
  const [visibleTeammates, setVisibleTeammates] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)

  const activeProjects = useMemo(() => {
    return userWorkspaces.data
      .filter(item => item => !item.isArchived)
      .map(item => ({ ...item, label: item.name }))
  }, [userWorkspaces])

  const verticals = useMemo(() => {
    return teamVerticals.map(item => ({ ...item, label: item.name }))
  }, [teamVerticals])

  useEffect(() => {
    if (teammates) {
      setVisibleTeammates(
        [...teammates].splice(page * totalTeammatesToShow, totalTeammatesToShow)
      )
      setPaginationTotalDataCount(teammates.length)
    }
  }, [teammates, page, paginationTotalDataCount])

  const handleSwitch = item => {
    if (access?.teammate.update) {
      if (
        item.role === userRoles.AGENCY_ADMIN &&
        meData.role === userRoles.AGENCY_MANAGER
      ) {
        return dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
      } else {
        setToggleSwitchLoading(prevState => [...prevState, item._id])
        dispatch(
          updateTeammateStatus(
            item._id,
            { isActive: !item.isActive },
            switchCallback
          )
        )
      }
    } else {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
    }
  }

  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading(prevState =>
        prevState.filter(item => res._id !== item)
      )
    }
  }

  const deleteTeammateCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setdeleteTeammateData(prev => ({
        ...prev,
        loading: false
      }))
    } else {
      setdeleteTeammateData({
        open: false,
        data: {},
        loading: false
      })
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }

  const handleDeleteTeammate = teammate => {
    if (access?.teammate.delete) {
      if (deleteTeammateData.open) {
        setdeleteTeammateData({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setdeleteTeammateData({
          open: true,
          data: teammate,
          loading: false
        })
      }
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const handleTeammateData = () => {
    setdeleteTeammateData(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteTeammate(deleteTeammateData.data._id, deleteTeammateCallback)
    )
  }

  const handleUpdateTeammate = data => {
    let updatedData = {}
    if (roleAnchorEl.key === 'role') {
      updatedData.verticals = [data._id]
    } else {
      updatedData.newWorkspace = [data._id]
    }

    dispatch(
      updateTeammate(roleAnchorEl.data._id, updatedData, (res, err) => {
        setRoleAnchorEl({ anchorEl: null, data: {}, key: '' })
        if (!err) {
          dispatch(fireSuccessToaster('Updated Successfully!'))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th>Name</Th>
          {meData.role < userRoles.USER_CLIENT && (
            <Th className="whitespace-nowrap">Roles</Th>
          )}
          <Th className="whitespace-nowrap">Assigned projects</Th>
          <Th className="whitespace-nowrap">Last Active</Th>
          <Th className="text-center whitespace-nowrap">Status</Th>
          {(access?.teammate.update || access?.teammate.delete) && (
            <Th className=" whitespace-nowrap text-center">Action</Th>
          )}
        </Thead>

        <Tbody>
          {!loading ? (
            visibleTeammates.length > 0 ? (
              visibleTeammates.map((item, index) => (
                <Row
                  key={item._id}
                  item={item}
                  // classes={classes}
                  // setAnchorEl={setAnchorEl}
                  openEditPanel={openEditPanel}
                  handleDeleteTeammate={handleDeleteTeammate}
                  // setTeammateToEdit={setTeammateToEdit}
                  toggleSwitchLoading={toggleSwitchLoading}
                  handleSwitch={handleSwitch}
                  access={access}
                  handleInviteTeammate={handleInviteTeammate}
                  meData={meData}
                  canEditAccount={canEditAccount}
                  setRoleAnchorEl={setRoleAnchorEl}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan={meData.role < userRoles.USER_CLIENT ? 6 : 5}
                  className="text-left py-3 px-6 text-gray-500 font-normal"
                >
                  There are no teammates
                </th>
              </Tr>
            )
          ) : (
            <TableDataLoading
              cols={meData.role < userRoles.USER_CLIENT ? 6 : 5}
            />
          )}
        </Tbody>
      </TableContainer>

      {!loading.loading && paginationTotalDataCount > totalTeammatesToShow && (
        <Pagination
          handlePagination={setPage}
          totalResultsCount={paginationTotalDataCount}
          visibleResultsCount={visibleTeammates.length}
          page={page}
          limit={totalTeammatesToShow}
        />
      )}

      <DeleteModal
        warningText="Teammate will be deleted."
        open={deleteTeammateData.open}
        handleDialog={handleDeleteTeammate}
        handleDeleteAction={handleTeammateData}
        loading={deleteTeammateData.loading}
      />
      {roleAnchorEl.key === 'role' && (
        <CompanyDropdown
          anchorEl={roleAnchorEl.anchorEl}
          onClose={() => setRoleAnchorEl({ anchorEl: null, data: {}, key: '' })}
          handleDialogue={handleUpdateTeammate}
          list={roleAnchorEl.key === 'role' ? verticals : activeProjects}
          elmFor={roleAnchorEl.key}
          canCreate={true}
          zIndex={1250}
        />
      )}
      {roleAnchorEl.key !== 'role' && (
        <ProjectsDropdown
          anchorEl={roleAnchorEl.anchorEl}
          onClose={() => setRoleAnchorEl({ anchorEl: null, data: {}, key: '' })}
          handleDialogue={handleUpdateTeammate}
          list={activeProjects}
          teammate={roleAnchorEl.data}
        />
      )}
    </div>
  )
}

const Row = ({
  item,
  toggleSwitchLoading,
  handleSwitch,
  access,
  openEditPanel,
  handleDeleteTeammate,
  handleInviteTeammate,
  meData,
  canEditAccount,
  setRoleAnchorEl
}) => {
  const teamVerticals = useSelector(state => state.teamVerticals)
  const [options, setOptions] = useState([])
  const dispatch = useDispatch()

  const userVertical = useMemo(() => {
    if (item.verticals.length && teamVerticals.fetched) {
      const vertical = teamVerticals.data.find(
        el => el._id === item.verticals[0]?._id
      )
      return vertical ? vertical.name : null
    } else {
      return null
    }
  }, [item._id, teamVerticals, item.verticals])

  useEffect(() => {
    const arr = [
      {
        label: 'Edit',
        method: handleEdit,
        icon: <EditIcon className="text-primary-main" />
      },
      {
        label: 'Delete',
        method: handleDelete,
        icon: <DeleteIcon className="text-primary-main" />
      }
    ]

    if (handleInviteTeammate) {
      arr.push({
        label: 'Resend Invite',
        method: handleSendInvitation,
        icon: <Calendar className="text-primary-main" />
      })
    }

    setOptions(arr)
  }, [item, canEditAccount])

  const handleOpenEditPanel = () => {
    openEditPanel({ open: false, data: item })
  }

  const handleEdit = () => {
    openEditPanel({ open: true, data: item })
  }
  const handleDelete = e => {
    if (!canEditAccount) {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
      return
    }
    handleDeleteTeammate(item)
  }

  const handleSendInvitation = () => {
    if (!canEditAccount) {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
      return
    }

    handleInviteTeammate(item._id)
  }

  const handleUpdate = (e, key) => {
    e.stopPropagation()
    setRoleAnchorEl({ anchorEl: e.currentTarget, data: item, key: key })
  }

  const assignProject = () => {
    if (
      access?.teammate?.update &&
      [
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_EDITOR,
        userRoles.AGENCY_VIEWER
      ].includes(item.role)
    ) {
      return (
        <div>
          <CustomTooltip title="Assign Projects" placement="top">
            <AddIcon
              className="h-5 w-5 p-1 ml-1 cursor-pointer border rounded-full"
              onClick={e => handleUpdate(e, 'project')}
            />
          </CustomTooltip>
        </div>
      )
    }
  }

  return (
    <Tr>
      <Td className=" px-6 py-4 whitespace-nowrap flex items-center space-x-2">
        <ColoredAvatars size="medium" user={item} />
        <div className="flex flex-col">
          <div className="flex items-center leading-3">
            <span className="text-primary-mid-dark font-medium mr-2">
              {item.name.length < 35
                ? item.name
                : `${item.name.substring(0, 34)}...`}
            </span>{' '}
            {getRoleBadge(item.role)}
          </div>
          <span className="text-primary-mid-dark-2 text-xs">{item.email}</span>
        </div>
      </Td>
      {meData.role < userRoles.USER_CLIENT && (
        <Td className="show-on-hover-parent">
          {userVertical ? (
            <div className="flex items-center">
              <Pill variant="yellow">{userVertical}</Pill>
              {access?.teammate?.update && (
                <CustomTooltip title="Change role" placement="top">
                  <AngleIcon
                    className={`show-on-hover-child mt-1 h-5 w-5 p-0.5 rounded-full hover:bg-gray-200`}
                    onClick={e => handleUpdate(e, 'role')}
                  />
                </CustomTooltip>
              )}
            </div>
          ) : access?.teammate?.update ? (
            <div>
              <CustomTooltip title="Assign Role" placement="top">
                <AddIcon
                  className="h-5 w-5 p-1 cursor-pointer border rounded-full"
                  onClick={e => handleUpdate(e, 'role')}
                />
              </CustomTooltip>
            </div>
          ) : (
            <div>-</div>
          )}
        </Td>
      )}
      <Td className="whitespace-nowrap w-0.5 truncate">
        {item?.assignedWorkspace?.length ? (
          <>
            {item.assignedWorkspace.slice(0, 2).map((ws, index) => (
              <div className={index === 0 ? 'leading-6' : 'mt-2'}>
                {index === 0 ? (
                  <div className="flex items-center leading-3">
                    <Pill variant="pink">{ws.name}</Pill>
                    {item?.assignedWorkspace?.length === 1 && assignProject()}
                  </div>
                ) : (
                  <div className="flex items-center leading-3">
                    <Pill variant="blue">{ws.name}</Pill>

                    {item.assignedWorkspace?.length > 2 ? (
                      <Pill
                        variant="black"
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        +{item.assignedWorkspace?.length - 2}
                      </Pill>
                    ) : null}
                    {assignProject()}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : access?.teammate?.update ? (
          assignProject()
        ) : (
          <div>-</div>
        )}
      </Td>
      <Td className="text-xs text-custom-gray-main whitespace-nowrap">
        {item.lastActive ? format(new Date(item.lastActive), 'd MMM p') : '-'}
      </Td>
      <Td className="">
        <ToggleSwitch
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>

      {(access?.teammate.update || access?.teammate.delete) && (
        // <Td className="text-center">
        //   {item.role === userRoles.AGENCY_ADMIN &&
        //   meData.role === userRoles.AGENCY_MANAGER ? null : (
        //     <PopupMenu menuItems={options} />
        //   )}
        // </Td>
        <Td className="text-center">
          <PopupMenu menuItems={options} onClick={handleOpenEditPanel} />
        </Td>
      )}
    </Tr>
  )
}

export default TeammatesTable
