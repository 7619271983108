import { useMemo, useEffect, useState, useRef } from 'react'

const Completion = ({
  completion,
  setAnchorEl,
  anchorEl,
  totalTasks,
  completedTasks
}) => {
  const [percentData, setPercentData] = useState(0)
  const circleElement1 = useRef()
  const circleElement2 = useRef()
  const circleSize = 50
  const circleCenter = circleSize / 2

  const color = useMemo(() => {
    return completion <= 25
      ? '#FF6100'
      : completion <= 50
      ? '#8B4AF0'
      : '#20DF78'
  }, [completion])

  useEffect(() => {
    setTimeout(() => {
      setPercentData(isNaN(completion) ? 0 : Math.round(completion))
    }, 500)
  }, [completion])

  useEffect(() => {
    // Safari does not support cx and cy directly as circle elm attributes, that is why setting it via apis
    if (circleElement1.current) {
      circleElement1.current.setAttribute('cx', circleCenter)
      circleElement1.current.setAttribute('cy', circleCenter)
    }

    if (circleElement2) {
      circleElement2.current.setAttribute('cx', circleCenter)
      circleElement2.current.setAttribute('cy', circleCenter)
    }
  }, [circleElement1, circleElement2])

  let radius = 20
  let circumference = radius * 2 * Math.PI

  const handleShowPopup = (e) => {
    if (anchorEl?.element) return

    setAnchorEl({
      element: e.currentTarget,
      data: { totalTasks, completedTasks }
    })
  }

  const handleHidePopup = () => {
    setAnchorEl((prev) => ({ ...prev, element: null }))
  }

  return (
    <div
      className="mr-4 relative cursor-default"
      onMouseOver={handleShowPopup}
      onMouseLeave={handleHidePopup}
    >
      <svg className="relative z-10" width={circleSize} height={circleSize}>
        <g>
          <circle
            stroke={color}
            stroke-width="4"
            fill="transparent"
            r={radius}
            ref={circleElement1}
            cx={circleCenter}
            cy={circleCenter}
            style={{
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset:
                circumference - (percentData / 100) * circumference,
              transition: 'all 1s ease-in-out',
              transformOrigin: 'center',
              transform: 'rotate(-90deg)'
            }}
            strokeLinecap="round"
            className="origin-center"
          ></circle>
          {/* <CustomTooltip
                title="completed tasks / total tasks"
                placement="top"
              > */}
          <text
            x="50%"
            y="50%"
            text-anchor="middle"
            stroke="#000"
            stroke-width="0.5"
            dy=".3em"
            fontSize="10"
          >
            {percentData}%
          </text>
          {/* </CustomTooltip> */}
        </g>
      </svg>
      <svg
        className="absolute top-0"
        width={circleSize}
        height={circleSize}
        onMouseEnter={handleShowPopup}
      >
        <circle
          ref={circleElement2}
          stroke="#efefef"
          stroke-width="4"
          fill="transparent"
          r={radius}
          cx={circleCenter}
          cy={circleCenter}
        />
      </svg>
    </div>
  )
}

export default Completion
