import React, { useState, useEffect, useMemo } from 'react'
import {
  TableDataLoading,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTable, useSortBy } from 'react-table'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import Pill from 'global/globalComponents/Pill/Pill'
import ColoredAvatars, {
  ColoredAvatarGroup
} from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import {
  archiveWorkspace,
  createWorkspaceCopy,
  deleteWorkspace,
  UpdateWorkspceCompleteStatus
} from 'thunks/workspace/actions'
import {
  fireErrorToaster,
  fireProcessToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
// import { Skeleton } from '@material-ui/lab'
// import DuplicateModal from './DuplicateModal'
import { fetchEmbed } from 'thunks/embed/action'
// import AssignedTags from './AssignedTags'
import { useHistory } from 'react-router-dom'
import userRoles, { getUserPath } from 'utils/userRoles'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
// import { ReactComponent as VerticalThreeDots } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as ArchiveIcon } from 'static/svg/archive.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
// import ProjectsReactTableViewPopup from './ProjectsReactTableViewPopup'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import EditWorkspaceDialog from '../EditWorkspaceDialog'
import DuplicateModal from '../DuplicateModal'
import { Pagination, PopupMenu } from 'global/globalComponents'
import SelectColumnsPopup from './SelectColumnsPopup'
import currency from 'static/Currency/Currency'
import Completion from 'components/UserDashboard/Widget/ProjectAnalyticsTable/Completion'
import InfoPopper from 'components/UserDashboard/Widget/ProjectAnalyticsTable/InfoPopper'
import {
  fetchProjectTableView,
  updateProjectTableView,
  createProjectTableView
} from 'thunks/views/actions'

const totalProjectsToShow = 15

const ProjectsReactTableView = () => {
  const [visibleColumns, setVisibleColumns] = useState([
    {
      Header: 'Name',
      label: 'Name',
      accessor: 'projectName',
      align: 'left',
      Cell: ({ value }) => {
        return (
          <div className="px-6 py-4 flex items-center space-x-2">
            <div className="whitespace-nowrap">
              {value.name.length < 30
                ? value.name
                : `${value.name.substring(0, 29)}...`}
            </div>
            <Pill
              variant={
                value.category.toLowerCase() === 'internal' ? 'blue' : 'pink'
              }
              // className="py-0.5"
            >
              {value.category}
            </Pill>
          </div>
        )
      },
      sortType: (rowA, rowB, id, desc) => {
        let val1 = rowA.values[id].name.toLowerCase(),
          val2 = rowB.values[id].name.toLowerCase()

        if (val1 > val2) return 1
        else if (val2 > val1) return -1
        return 0
      }
    },
    // {
    //   Header: 'Type',
    //   label: 'Type',
    //   accessor: 'category',
    //   Cell: ({ value }) => (
    //     <div className="px-6 py-4">
    //       <Pill variant={value.toLowerCase() === 'internal' ? 'blue' : 'pink'}>
    //         {value}
    //       </Pill>
    //     </div>
    //   ),
    //   align: 'center'
    // },
    {
      Header: 'Status',
      label: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <div className="px-6 py-4">{getStatusBadge(value)}</div>
      ),
      align: 'center',
      disableSortBy: true
    },
    {
      Header: 'Total Tasks',
      label: 'Total Tasks',
      accessor: 'totalTask',
      Cell: ({ value }) => (
        <div className="px-6 text-center">
          <Pill variant="orange" style={{ fontSize: 11 }}>
            {value ?? 0}
          </Pill>
        </div>
      ),
      align: 'center'
    },
    {
      label: 'Completion',
      Header: 'Completion',
      accessor: 'completion',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="whitespace-nowrap">
            <Completion
              totalTasks={value.totalTasks}
              completedTasks={value.completedTasks}
              completion={(
                (value.completedTasks / value.totalTasks) *
                100
              ).toFixed(1)}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
            />
          </div>
        </div>
      ),
      align: 'left',
      sortType: (rowA, rowB, id, desc) => {
        const val1 =
          (rowA.values[id].completedTasks / rowA.values[id].totalTasks || 0) *
          100
        const val2 =
          (rowB.values[id].completedTasks / rowB.values[id].totalTasks || 0) *
          100

        return val1 - val2
      }
    },
    {
      Header: 'Members',
      label: 'Members',
      accessor: 'members',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="w-full flex justify-center">
            <ColoredAvatarGroup size="small" users={value} />
          </div>
        </div>
      ),
      align: 'center',
      disableSortBy: true
    },

    {
      Header: 'Client',
      label: 'Client',
      accessor: 'client',
      Cell: ({ value }) => (
        <div className="px-6 py-4 text-center">
          {value ? (
            <ColoredAvatars
              className="mx-auto"
              size="small"
              user={value}
              tooltip={true}
            />
          ) : (
            '-'
          )}
        </div>
      ),
      align: 'center',
      sortType: (rowA, rowB, id, desc) => {
        // taking z (last) if name is undefined
        let val1 = rowA.values[id].name?.toLowerCase() ?? 'z',
          val2 = rowB.values[id].name?.toLowerCase() ?? 'z'

        if (val1 > val2) return 1
        else if (val2 > val1) return -1
        return 0
      }
    },
    {
      Header: 'Creator',
      label: 'Creator',
      accessor: 'creator',
      Cell: ({ value }) => (
        <div className="px-6 py-4 text-center">
          {value ? (
            <ColoredAvatars
              className="mx-auto"
              size="small"
              user={value}
              tooltip={true}
            />
          ) : (
            '-'
          )}
        </div>
      ),
      align: 'center',
      sortType: (rowA, rowB, id, desc) => {
        let val1 = rowA.values[id].name?.toLowerCase(),
          val2 = rowB.values[id].name?.toLowerCase()

        if (val1 > val2) return 1
        else if (val2 > val1) return -1
        return 0
      }
    },

    {
      Header: 'Created',
      label: 'Created',
      accessor: 'createdAt',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="whitespace-nowrap">
            {' '}
            {format(new Date(value), 'dd MMM yyyy')}
          </div>
        </div>
      ),
      align: 'left'
    },
    {
      Header: 'Updated',
      label: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="whitespace-nowrap">
            {format(new Date(value), 'dd MMM yyyy')}
          </div>
        </div>
      ),
      align: 'left'
    },
    {
      Header: 'Budgeted Hours',
      label: 'Budgeted Hours',
      accessor: 'budgetedHours',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="whitespace-nowrap">
            {value ? `${value} hours` : '-'}
          </div>
        </div>
      ),
      align: 'left'
    },
    {
      label: 'Budget',
      Header: 'Budget',
      accessor: 'budget',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="whitespace-nowrap">
            {value.cost
              ? `${
                  currency[value.currency?.toUpperCase() ?? 'USD'].symbol_native
                } ${value.cost}`
              : '-'}
          </div>
        </div>
      ),
      align: 'left'
    }
  ])
  const [anchorEl, setAnchorEl] = useState({
    element: null,
    data: {}
  })

  const { loading, data: workspaceData } = useSelector(
    state => state.userWorkspaces
  )
  const meData = useSelector(state => state.me.data)
  const [projectsData, setProjectsData] = useState([])
  const [visibleProjects, setVisibleProjects] = useState([])
  const [columnsToHide, setColumnsToHide] = useState([])
  const [page, setPage] = useState(0)
  const [alertModal, setAlertModal] = useState({
    open: '',
    loading: false,
    data: {}
  })
  const dispatch = useDispatch()
  const [duplicateProject, setDuplicateProject] = useState({
    open: false,
    data: {}
  })
  const [workspaceBoard, setWorkspaceBoard] = useState([])
  const history = useHistory()
  const workspacePermission = useSelector(
    state => state.workspacePermission.data
  )
  const projectTableView = useSelector(state => state.views.data.projectTable)
  // const [editOptionsPopup, setEditOptionsPopup] = useState({
  //   anchorEl: null,
  //   data: []
  // })

  const [selectColumnsAnchorEl, setSelectColumnsAnchorEl] = useState(null)
  const search = useSelector(state => state.generalAppState.headerSearch)

  const permission = project => {
    let permissionObj = {}
    for (let item of workspacePermission) {
      if (typeof item[project._id] === 'object') {
        permissionObj = item[project._id].access
      }
    }
    if (permissionObj?.workspace) return permissionObj.workspace
    return {}
  }

  const columnSaveState = useMemo(() => {
    if (projectTableView._id) {
      let savedColumnData = projectTableView.content[0].hiddenColumns
      if (columnsToHide.length !== savedColumnData.length) {
        return true
      } else {
        for (let item of columnsToHide) {
          if (!savedColumnData.includes(item)) return true
        }
        return false
      }
    } else {
      return true
    }
  }, [columnsToHide, projectTableView])

  useEffect(() => {
    if (!projectTableView._id) {
      dispatch(fetchProjectTableView())
    }
  }, [])

  useEffect(() => {
    if (projectTableView._id)
      setColumnsToHide(projectTableView.content[0].hiddenColumns)
  }, [projectTableView])

  useEffect(() => {
    if (!loading) {
      const creator = value => {
        const userObj = [
          ...value.assignedAgencyTeam,
          ...value.assignedClientTeam
        ].find(mate => mate.user._id === value.user)

        if (userObj) return userObj.user
        return ''
      }

      const client = project => {
        const clientObj = project.assignedClientTeam.find(
          mate => mate.role === userRoles.USER_CLIENT
        )
        if (clientObj) return clientObj.user
        return ''
      }

      const members = project => {
        return [
          ...project.assignedAgencyTeam,
          ...project.assignedClientTeam
        ].map(obj => obj.user)
      }

      const modifiedData = workspaceData.map(project => ({
        ...project,
        projectName: { name: project.name, category: project.category },
        creator: creator(project),
        client: client(project),
        members: members(project),
        status: {
          isComplete: project.isComplete,
          isArchived: project.isArchived
        },
        budget: { cost: project.cost ?? 0, currency: project.currency },
        completion: {
          totalTasks: project.totalTask,
          completedTasks: project.completedTask
        }
      }))
      setProjectsData(modifiedData)
    }
  }, [workspaceData])

  useEffect(() => {
    if (!projectsData.length) return setVisibleProjects([])

    let arr = projectsData.filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase())
    )

    // View permission and pagination logic
    if (meData.role < userRoles.USER_CLIENT) {
      setVisibleProjects(
        arr.splice(page * totalProjectsToShow, totalProjectsToShow)
      )
    } else {
      setVisibleProjects(
        arr
          .filter(item => item.category === 'External')
          .splice(page * totalProjectsToShow, totalProjectsToShow)
      )
    }
  }, [projectsData, page, meData.role, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns
  } = useTable(
    {
      columns: visibleColumns,
      data: visibleProjects,
      initialState: { hiddenColumns: columnsToHide }
    },
    useSortBy
  )

  useEffect(() => {
    setHiddenColumns(columnsToHide)
  }, [columnsToHide])

  const handleDuplicateProjectModal = data => {
    if (duplicateProject.open) {
      setDuplicateProject({
        open: false,
        data: {}
      })
    } else {
      setDuplicateProject({
        open: true,
        data: data
      })
      dispatch(
        fetchEmbed(data._id, (res, err) => {
          if (!err) {
            setWorkspaceBoard(res)
          }
        })
      )
    }
  }

  const openAlertModal = (type, data) => {
    setAlertModal({ open: type, loading: false, data })
  }

  const closeAlertModal = () => {
    setAlertModal(prev => ({ ...prev, open: '' }))
  }

  const handleDeleteWorkspace = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))
    dispatch(deleteWorkspace(alertModal.data._id, actionCallback('delete')))
  }

  const handleArchiveWorkspace = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))
    dispatch(
      archiveWorkspace(
        alertModal.data._id,
        !alertModal.data.isArchived,
        actionCallback(alertModal.data.isArchived ? 'archive' : 'unarchive')
      )
    )
  }

  const completeStatus = () => {
    setAlertModal(prev => ({ ...prev, loading: true }))

    dispatch(
      UpdateWorkspceCompleteStatus(
        alertModal.data._id,
        { isComplete: true },
        actionCallback('complete')
      )
    )
  }

  const actionCallback = type => {
    return (res, err) => {
      if (err) {
        setAlertModal(prev => ({ ...prev, loading: false }))
        dispatch(fireErrorToaster(res))
      } else {
        setAlertModal(prev => ({ ...prev, loading: false, open: '' }))
        const msg = type === 'delete' ? 'Project deleted successfully!' : null
        if (msg) {
          dispatch(fireSuccessToaster(msg))
        }
      }
    }
  }

  const duplicateWorkspaceHandler = data => {
    setAlertModal(prev => ({ ...prev, open: false }))

    dispatch(
      createWorkspaceCopy(duplicateProject.data._id, data, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(`Duplicate was created successfully!`))
          dispatch(
            fetchWorkspacePermissions((res, err) => {
              // setLoading(false)
              if (err) {
                dispatch(fireErrorToaster(res))
              }
            })
          )
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
    // setLoading(true)
    dispatch(
      fireProcessToaster(
        `Duplicate of ${alertModal.data.name} is being created! Please wait...`
      )
    )
  }

  const getPopupMenuOptions = item => {
    if (item.isComplete) {
      return [
        {
          label: 'Delete',
          method: openAlertModal.bind(this, 'delete', item),
          icon: <DeleteIcon className="text-primary-main" />
        }
      ]
    } else {
      return [
        {
          label: 'Settings',
          method: handleEditProject.bind(this, item),
          icon: <SettingIcon className="text-primary-main" />
        },
        {
          label: item.isArchived ? 'Unarchive' : 'Archive',
          method: openAlertModal.bind(this, 'archive', item),
          icon: <ArchiveIcon className="text-primary-main" />
        },
        {
          label: 'Mark Complete',
          method: handleListItemClick('complete', item),
          icon: <CheckCircleIcon className="text-primary-main" />
        },
        {
          label: 'Duplicate',
          method: handleDuplicateProjectModal.bind(this, item),
          icon: <CopyIcon className="text-primary-main" />
        },
        {
          label: 'Delete',
          method: openAlertModal.bind(this, 'delete', item),
          icon: <DeleteIcon className="text-primary-main" />
        }
      ]
    }
  }

  const handleEditProject = item => {
    openAlertModal('edit', item)
  }

  const handleListItemClick = (type, item) => {
    return () => openAlertModal(type, item)
  }

  const handleViewProject = workspace => {
    history.push(
      `${getUserPath(meData.role)}/workspace/${workspace._id}/board/${
        workspace.defaultBoard._id
      }?view=board`
    )
  }

  const toggleSelectColumnsPopup = e => {
    // e?.stopPropagation()
    if (selectColumnsAnchorEl) {
      setSelectColumnsAnchorEl(null)
    } else {
      setSelectColumnsAnchorEl(e.currentTarget)
    }
  }

  const toggleColumnsVisibility = (accessor, checked) => {
    if (!checked) {
      setColumnsToHide(prev => [...prev, accessor])
    } else {
      setColumnsToHide(prev => prev.filter(item => item !== accessor))
    }
  }

  const handleSaveColumns = callback => {
    if (projectTableView._id) {
      dispatch(
        updateProjectTableView(
          projectTableView._id,
          {
            content: [{ hiddenColumns: columnsToHide }]
          },
          (res, err) => {
            callback()
            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(fireSuccessToaster('Saved Successfully!'))
              toggleSelectColumnsPopup()
            }
          }
        )
      )
    } else {
      dispatch(
        createProjectTableView(
          {
            type: 'projectTable',
            content: [{ hiddenColumns: columnsToHide }]
          },
          (res, err) => {
            callback()
            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(fireSuccessToaster('Saved Successfully!'))
              toggleSelectColumnsPopup()
            }
          }
        )
      )
    }
  }

  return (
    <>
      <div>
        {search !== '' && (
          <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
            Search results for:{' '}
            <span className="text-primary-dark">{search}</span>
          </p>
        )}
        <div
          className={clsx(
            `mb-2 align-middle min-w-full overflow-hidden sm:rounded-xl border rounded-xl shadow-lg`,
            !loading && 'overflow-x-auto'
          )}
        >
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-gray-200 border-collapse border-hidden"
          >
            <thead className="font-medium uppercase text-left h-14">
              {headerGroups.map(headerGroup => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="hover:bg-gray-100"
                >
                  {headerGroup.headers.map((col, index) => (
                    <th
                      className={clsx(
                        'tracking-wider font-medium px-5 py-3 lg:px-6 text-custom-gray-dark whitespace-nowrap text-extraSmaller lg:text-xs bg-custom-gray-light-5',
                        col.id === 'projectName' && `sticky left-0 z-10`,
                        col.align === 'center' && 'text-center'
                      )}
                      {...col.getHeaderProps(col.getSortByToggleProps())}
                    >
                      {col.render('Header')}
                      <span className="ml-1">
                        {col.isSorted ? (
                          col.isSortedDesc ? (
                            <>&#8593;</>
                          ) : (
                            <>&#8595;</>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))}
                  <th className="px-6">
                    <span className="inline-block">
                      <button
                        className={clsx(
                          'w-8 h-8 rounded-full text-sm flex justify-center items-center shadow-lg border',
                          Boolean(selectColumnsAnchorEl) &&
                            'text-white bg-primary-main border-primary-main'
                        )}
                        tabIndex={0}
                        onClick={toggleSelectColumnsPopup}
                      >
                        <AddIcon className="w-5 h-5" />
                      </button>
                    </span>
                  </th>
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="bg-white divide-y divide-gray-200 text-xs lg:text-sm text-gray-500"
            >
              {!loading ? (
                visibleProjects.length ? (
                  rows.map(row => {
                    prepareRow(row)
                    return (
                      <tr
                        {...row.getRowProps()}
                        // className="hover:bg-gray-100"
                      >
                        {row.cells.map((cell, index) => (
                          <td
                            {...cell.getCellProps()}
                            className={clsx(
                              'text-gray-500 bg-white',
                              cell.column.id === 'projectName' &&
                                `sticky left-0 z-20 cursor-pointer text-gray-900`
                            )}
                            onClick={
                              cell.column.id === 'projectName'
                                ? () => handleViewProject(row.original)
                                : null
                            }
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                        <td className="px-5 lg:px-6">
                          {permission(row.original)?.update &&
                            permission(row.original)?.delete && (
                              <PopupMenu
                                menuItems={getPopupMenuOptions(row.original)}
                              />
                            )}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <Tr className="bg-white">
                    <th
                      colSpan={visibleColumns.length + 1}
                      className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
                    >
                      There are no projects
                    </th>
                  </Tr>
                )
              ) : (
                <TableDataLoading cols={visibleColumns.length} />
              )}
            </tbody>
          </table>
        </div>

        {projectsData.length > totalProjectsToShow && (
          <div className="mt-6">
            <Pagination
              handlePagination={setPage}
              totalResultsCount={projectsData.length}
              visibleResultsCount={visibleProjects.length}
              page={page}
              limit={totalProjectsToShow}
            />
          </div>
        )}
      </div>

      <InfoPopper
        {...anchorEl}
        onClose={() => setAnchorEl({ element: null, data: {} })}
      />
      <EditWorkspaceDialog
        open={alertModal.open === 'edit'}
        handleDialog={closeAlertModal}
        workspace={alertModal.data}
      />
      <AlertModal
        heading={alertModal.data.name}
        warningText="All the tasks and attachments related to this project will be deleted."
        open={alertModal.open === 'delete'}
        handleDialog={closeAlertModal}
        handleDeleteAction={handleDeleteWorkspace}
        loading={alertModal.loading}
      />
      <AlertModal
        heading={alertModal.data.name}
        warningText={`All the tasks and attachments related to this project will be ${
          alertModal.data.isArchived ? 'unarchived' : 'archived'
        }.`}
        open={alertModal.open === 'archive'}
        handleDialog={closeAlertModal}
        handleDeleteAction={handleArchiveWorkspace}
        loading={alertModal.loading}
        deleteBtnText={alertModal.data.isArchived ? 'Unarchive' : 'Archive'}
      />
      <AlertModal
        heading={alertModal.data.name}
        warningText="Once you mark this project as completed you won't be able to change its status back and won't be able to make any changes either."
        open={alertModal.open === 'complete'}
        handleDialog={closeAlertModal}
        handleDeleteAction={completeStatus}
        loading={alertModal.loading}
        deleteBtnText="Complete"
      />
      <DuplicateModal
        open={duplicateProject.open}
        handleDialog={handleDuplicateProjectModal}
        handleDuplicateAction={duplicateWorkspaceHandler}
        workspace={duplicateProject.data}
        workspaceBoard={workspaceBoard}
      />
      <SelectColumnsPopup
        anchorEl={selectColumnsAnchorEl}
        handleClose={toggleSelectColumnsPopup}
        toggleColumnsVisibility={toggleColumnsVisibility}
        hiddenCols={columnsToHide}
        columns={visibleColumns}
        handleSaveColumns={handleSaveColumns}
        columnSaveState={columnSaveState ?? false}
      />
    </>
  )
}

const getStatusBadge = project => {
  return (
    <Pill
      variant={
        project.isComplete ? 'orange' : project.isArchived ? 'gray' : 'green'
      }
    >
      {project.isComplete
        ? 'Completed'
        : project.isArchived
        ? 'Archived'
        : 'Active'}
    </Pill>
  )
}

export default ProjectsReactTableView
