import { useState, useCallback } from 'react'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { bytesToSize, errorMessages } from 'utils'
import moment from 'moment'
import { PopupMenu } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import { deleteFile } from 'thunks/fileAssets/actions'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as VerticalThreeDot } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
import { ReactComponent as LogoutIcon } from 'static/svg/logout.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as EditIcon } from 'static/svg/edit.svg'
import { ReactComponent as ImageFile } from 'static/svg/image-file.svg'
import { ReactComponent as AudioFile } from 'static/svg/music-note.svg'
import { ReactComponent as VideoFile } from 'static/svg/film-reel.svg'
import { ReactComponent as NoteText } from 'static/svg/note-text.svg'
import FilesAssetsStyles from './filesAssetsStyles'

const getFileIcon = (file) => {
  if (/\.?(png|svg|jpe?g|gif|tiff?)$/i.test(file.extension))
    return <ImageFile fontSize="25px" className="text-primary-main" />
  if (/\.?(m4a|flac|mp3|wav|aiff)$/i.test(file.extension))
    return <AudioFile fontSize="25px" className="text-primary-main" />
  if (/\.?(mp4|mov|avi|flv|mkv|wmv|avchd|webm|mpeg-4)$/i.test(file.extension))
    return <VideoFile fontSize="25px" className="text-primary-main" />
  return <NoteText fontSize="25px" className="text-primary-main" />
}

const FilesTable = ({
  loading,
  files,
  openPreview,
  openMoveFileModal,
  openFolderModal,
  canUpdate
}) => {
  const classes = FilesAssetsStyles()
  const dispatch = useDispatch()
  const [fileAlert, setFileAlert] = useState({
    open: false,
    loading: false,
    data: {
      id: '',
      name: ''
    }
  })

  const openFileAlertModal = (data) => {
    setFileAlert({ loading: false, open: true, data })
  }

  const closeFileAlertModal = () => {
    setFileAlert((prev) => ({ ...prev, loading: false, open: false }))
  }

  const handleDeleteFile = () => {
    setFileAlert((prev) => ({ ...prev, loading: true }))
    dispatch(deleteFile(fileAlert.data.id, deleteFileCallback))
  }

  const deleteFileCallback = (res, err) => {
    if (!err) {
      setFileAlert((prev) => ({ ...prev, open: false, loading: false }))
    } else {
      setFileAlert((prev) => ({ ...prev, loading: false }))
      dispatch(fireErrorToaster(res))
    }
  }

  const handleDownloadFile = (file) => {
    const link = document.createElement('a')
    link.setAttribute('download', '')
    link.href = file.url
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const handleCopyFileLink = (url) => {
    navigator.clipboard.writeText(url)

    dispatch(fireSuccessToaster(errorMessages.LINK_COPIED))
  }

  const getPopupOptions = useCallback((file) => {
    return [
      { label: 'Preview', icon: <EyeIcon />, method: () => openPreview(file) },
      {
        label: 'Rename',
        icon: <EditIcon />,
        method: () => openFolderModal(file)
      },
      {
        label: 'Delete',
        icon: <TrashIcon />,
        method: () => openFileAlertModal({ name: file.name, id: file._id })
      },
      {
        label: 'Download',
        icon: <DownloadIcon />,
        method: () => handleDownloadFile(file)
      },
      {
        label: 'Copy link',
        icon: <CopyIcon />,
        method: () => handleCopyFileLink(file.url)
      },
      {
        label: 'Move to',
        icon: <LogoutIcon />,
        method: () => openMoveFileModal(file, 'move')
      },
      {
        label: 'Copy to',
        icon: <LogoutIcon />,
        method: () => openMoveFileModal(file, 'copy')
      }
    ]
  }, [])

  const handleOpenPreview = useCallback(
    function (e) {
      const file = files.find((item) => item._id === e.currentTarget.dataset.id)
      openPreview(file)
    },
    [files.length]
  )

  return (
    <>
      <div
        className={clsx(
          'overflow-x-auto bg-white rounded-lg py-2',
          classes.container
        )}
      >
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="border-b">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Size
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Added
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Modified
              </th>
              {canUpdate && (
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <TableDataLoading cols={4} />
            ) : files.length ? (
              files.map((item) => (
                <Row
                  key={item._id}
                  file={item}
                  getPopupOptions={getPopupOptions}
                  handleOpenPreview={handleOpenPreview}
                  canUpdate={canUpdate}
                />
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 tracking-wide"
                >
                  No files data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AlertModal
        warningText={`Do you want to delete ${fileAlert.data.name}`}
        open={fileAlert.open}
        handleDialog={closeFileAlertModal}
        handleDeleteAction={handleDeleteFile}
        loading={fileAlert.loading}
        deleteBtnText="Delete"
      />
    </>
  )
}

const Row = ({ file, getPopupOptions, handleOpenPreview, canUpdate }) => {
  const [menuItems, setMenuItems] = useState([])

  const handleThreeDotsClick = () => {
    setMenuItems(getPopupOptions(file))
  }

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex gap-2 items-center">
          <span>{getFileIcon(file)}</span>
          <div
            role="button"
            tabIndex="0"
            data-id={file._id}
            onClick={handleOpenPreview}
          >
            {file.name}
            {file.extension}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {bytesToSize(+file.size)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <time>{moment(file.createdAt).endOf('minutes').fromNow()}</time>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <time>{moment(file.updatedAt).endOf('minutes').fromNow()}</time>
      </td>
      {canUpdate && (
        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium">
          <PopupMenu
            menuItems={menuItems}
            onClick={handleThreeDotsClick}
            targetComp={
              <button className="text-gray-500 text-lg">
                <VerticalThreeDot />
              </button>
            }
          />
        </td>
      )}
    </tr>
  )
}

// const useStyles = makeStyles({
//   container: {
//     boxShadow: '0 0 10px -6px rgba(0, 0, 0, 0.25)'
//   }
// })
export default FilesTable
