import React from 'react'
import { CustomPopper } from 'global/globalComponents'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import clsx from 'clsx'

const StatsPopper = ({ anchorEl, onClose, data }) => {
  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <div className="p-4" style={{ width: 250 }}>
        <header>
          <h6 className="font-semibold text-smaller mb-2">Standups Report</h6>

          <div className="flex justify-between items-center mb-1">
            <span className="text-smaller font-medium text-gray-500">
              Total Standups
            </span>
            <span className="text-smaller font-medium text-gray-500">
              {data.stats.total}
            </span>
          </div>

          <div className="flex justify-between items-center mb-1">
            <span className="text-smaller font-medium text-gray-500">
              Completed
            </span>
            <span className="text-smaller font-medium text-gray-500">
              {data.stats.completed}
            </span>
          </div>

          <div className="flex justify-between items-center mb-1">
            <span className="text-smaller font-medium text-gray-500">
              Percentage
            </span>
            <span className="text-smaller font-medium text-gray-500">
              {data.stats.percentage ? data.stats.percentage.toFixed(1) : 0}%
            </span>
          </div>
        </header>

        <hr className="my-2" />

        <div>
          <ul>
            {data.standups.map((item, index) => (
              <li key={index} className="py-1">
                <div className="flex items-center text-smaller text-gray-600">
                  <CheckCircle
                    className={clsx(
                      'w-4 h-4',
                      item.isComplete ? 'text-green-400' : 'text-gray-400'
                    )}
                    style={{ flexShrink: 0 }}
                  />
                  <span className="ml-2">{item.name}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* <div className="flex items-center space-x-4">
          <div className="flex justify-between items-center mb-1">
            <span className="text-smaller font-medium text-gray-500">
              Total Standups
            </span>
            <span className="text-smaller font-medium text-gray-500">
              {data.total}
            </span>
          </div>

          <div className="flex justify-between items-center mb-1">
            <span className="text-smaller font-medium text-gray-500">
              Completed
            </span>
            <span className="text-smaller font-medium text-gray-500">
              {data.completed}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center mb-1">
          <span className="text-smaller font-medium text-gray-500">
            Percentage
          </span>
          <span className="text-smaller font-medium text-gray-500">
            {data.percentage ? data.percentage.toFixed(1) : 0}%
          </span>
        </div> */}
      </div>
    </CustomPopper>
  )
}

export default StatsPopper
