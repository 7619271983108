import React, { useMemo, useState } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  TableDataLoading,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { ReactComponent as SquareIcon } from 'static/svg/stop.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ArrowRight } from '@material-ui/icons'
import clsx from 'clsx'
import useReportsFunctions from '../useReportsFunctions'
import { dateAndTimeHelper } from 'utils'
import Currency from 'static/Currency/Currency'

const DayWiseStandupsTable = ({ standupsData, user }) => {
  const { getTrackedTime, getCostByTrackedTime } = useReportsFunctions()

  return (
    <TableContainer shadow="none">
      <Thead>
        <Th className="w-44 whitespace-nowrap">Days</Th>
        <Th center>Estimated Time</Th>
        <Th center>Tracked Time</Th>
        <Th className="text-right">
          Cost (
          {Currency[user.profile?.defaultCurrency ?? 'USD']?.symbol_native})
        </Th>
        <Th center>Status</Th>
      </Thead>
      <Tbody>
        {standupsData.loading || !user.profile ? (
          <TableDataLoading cols={5} />
        ) : (
          standupsData.data.map((item, index) => (
            <Row
              standupsData={item}
              getTrackedTime={getTrackedTime}
              userProfile={user.profile}
              getCostByTrackedTime={getCostByTrackedTime}
            />
          ))
        )}
      </Tbody>
    </TableContainer>
  )
}

const Row = ({
  standupsData,
  getTrackedTime,
  userProfile,
  getCostByTrackedTime
}) => {
  const [expand, setExpand] = useState(false)

  const data = useMemo(() => {
    const obj = {
      estimatedTime: 0,
      timeTracked: 0,
      isComplete: standupsData.standups.length
        ? standupsData.standups.every(item => item.isComplete)
        : false,
      cost: 0
    }

    const isWorkingDay = Object.keys(userProfile?.workingDays).some(item => {
      return (
        standupsData.day.toLowerCase().includes(item) &&
        userProfile?.workingDays[item]
      )
    })

    if (isWorkingDay) {
      obj.cost =
        (userProfile.capacity / userProfile.workingDays.count) *
        userProfile.hourlyRate
    }

    for (let standup of standupsData.standups) {
      obj.estimatedTime += standup.task.estimateTimeInMilliSecond ?? 0
      obj.timeTracked += getTrackedTime(standup.timeTracking, userProfile.user)
    }

    obj.timeTracked = obj.timeTracked
      ? dateAndTimeHelper.formatMilliseconds(obj.timeTracked)
      : '-'
    obj.estimatedTime = obj.estimatedTime
      ? dateAndTimeHelper.formatMilliseconds(obj.estimatedTime)
      : '-'

    return obj
  }, [standupsData, userProfile])

  const toggleExpandState = () => {
    setExpand(prev => !prev)
  }

  return (
    <>
      <Tr>
        <Td
          className="cursor-pointer flex items-center"
          onClick={toggleExpandState}
        >
          <ArrowRight className={clsx(expand && 'rotate-90')} />
          <span>{standupsData.day}</span>
          <div className="flex ml-2 w-6 h-6 border rounded-full items-center justify-center">
            <span className="text-xs lg:text-smaller font-medium text-gray-500">
              {standupsData.standups.length}
            </span>
          </div>
        </Td>
        <Td className="text-center">{data.estimatedTime}</Td>
        <Td className="text-center">{data.timeTracked}</Td>
        <Td className="text-right">{data.cost || '-'}</Td>
        <Td className="text-center flex justify-center">
          <CheckCircleIcon
            className={clsx(
              'w-4 h-4',
              data.isComplete ? 'text-green-400' : 'text-gray-400'
            )}
          />
        </Td>
      </Tr>
      {expand &&
        (standupsData.standups.length ? (
          standupsData.standups.map(item => (
            <NestedRow
              standup={item}
              key={item._id}
              getTrackedTime={getTrackedTime}
              hourlyRate={userProfile.hourlyRate}
              getCostByTrackedTime={getCostByTrackedTime}
              userProfile={userProfile}
            />
          ))
        ) : (
          <Tr>
            <Td colSpan={5} className=" pl-10">
              No data found!
            </Td>
          </Tr>
        ))}
    </>
  )
}

const NestedRow = ({
  standup,
  getTrackedTime,
  hourlyRate,
  getCostByTrackedTime,
  userProfile
}) => {
  const data = useMemo(() => {
    const obj = {
      estimatedTime: standup.task.estimateTimeInString,
      timeTracked: getTrackedTime(standup.task.timeTracking, userProfile.user),
      cost: '-'
    }

    if (obj.timeTracked) {
      obj.cost = getCostByTrackedTime({
        trackedTimeInMs: obj.timeTracked,
        hourlyRate
      })
    }

    obj.timeTrackedString = obj.timeTracked
      ? dateAndTimeHelper.formatMilliseconds(obj.timeTracked)
      : '-'

    return obj
  }, [standup, userProfile])

  return (
    <Tr className="bg-gray-50">
      <Td className="flex items-center flex-1 pl-10">
        <SquareIcon
          className={clsx(
            'h-2 w-4 shrink-0',
            standup.isComplete ? 'text-green-400' : 'text-red-400'
          )}
        />
        <span className="ml-2">{standup.name}</span>
      </Td>
      <Td className="text-center">{data.estimatedTime}</Td>
      <Td className="text-center">{data.timeTrackedString}</Td>
      <Td className="text-right">{data.cost}</Td>
      <Td className="flex justify-center">
        <CheckCircleIcon
          className={clsx(
            'w-4 h-4',
            standup.isComplete ? 'text-green-400' : 'text-gray-400'
          )}
        />
      </Td>
    </Tr>
  )
}

export default DayWiseStandupsTable
