import { combineReducers } from 'redux'
import { fireToaster } from 'thunks/fireToaster/reducers'
import { loginReducer } from 'thunks/login/reduers'
import meReducer from 'thunks/me/reducers'
import userReducer from 'thunks/user/reducers'
import teammatesReducer from 'thunks/teammate/reducers'
import workspacesReducer from 'thunks/workspace/reducers'
import tagReducer from 'thunks/tags/reducers'
import integrationsReducer from 'thunks/integration/reducers'
import tasksReducer from 'thunks/task/reducers'
import serviceReducer from 'thunks/service/reducers'
import clientReducer from 'thunks/addClient/reducers'
import fetchAgencyPlansReducer from 'thunks/agencyPlan/reducers'
import subTaskReducer from 'thunks/subTask/reducers'
import generalAppStateReducer from 'thunks/generlAppState/reducers'
import fileAssetReducer from 'thunks/fileAssets/reducers'
import stripeInfoReducer from 'thunks/stripeAccount/reducers'
import userActivePlanReducer from 'thunks/userActivePlanInfo/reducers'
import logsReducer from 'thunks/logs/reducer'
import webhookReducer from 'thunks/webhooks/reducer'
import breadcrumbsReducer from 'thunks/breadcrumbs/reducer'
import agencyPaymenyReducer from 'thunks/agencyPayment/reducers'
import assignedClientsReducer from 'thunks/assignedClients/reducers'
import emailNotificationEventsReducer from 'thunks/emailNotificationEvents/reducer'
import clientInvoicesReducer from 'thunks/invoices/reducer'
import gettingStartedTasksReducer from 'thunks/gettingStarted/reducers'
import permissionReducer from 'thunks/permission/reducer'
import workspacePermissionReducer from 'thunks/workspacePermission/reducer'
import cancelClientServiceRequest from 'thunks/cancelClientServiceRequest/reducers'
import onboardingReducer from 'thunks/onboarding/reducer'
import clientRaiseRequestReducer from 'thunks/clientRaiseRequest/reducer'
import clientRaisedRequestAgencyReducer from 'thunks/clientRaisedRequestAgency/reducer'
import embedReducer from 'thunks/embed/reducer'
import userDashboard from 'thunks/userDashboard/reducer'
import accountLogs from 'thunks/accountLogs/reducers'
import userPermissionReducer from 'thunks/userPermissions/reducers'
import verticalsReducer from 'thunks/Verticals/reducer'
import clientCompanyReducer from 'thunks/clientCompany/reducer'
import widgetReducer from 'thunks/widget/reducer'
import viewsReducer from 'thunks/views/reducer'
import timerReducer from 'thunks/Tracking/reducer'
import reportsReducer from 'thunks/reports/reducer'
import standupsReducer from 'thunks/standups/reducer'
import pulseReducer from 'thunks/pulse/reducer'
import approvalsReducer from 'thunks/approvals/reducer'
import reportingReducer from 'thunks/StatusPost/reducer'
import calendarReducer from 'thunks/calender/reducer'

const appReducer = combineReducers({
  appToaster: fireToaster,
  login: loginReducer,
  dashboard: userDashboard,
  me: meReducer,
  user: userReducer,
  userTeammates: teammatesReducer,
  teamVerticals: verticalsReducer,
  userWorkspaces: workspacesReducer,
  userTags: tagReducer,
  userWorkspaceEmbed: embedReducer,
  userReportings: reportingReducer,
  userIntegrations: integrationsReducer,
  userTasks: tasksReducer,
  subTasks: subTaskReducer,
  userServices: serviceReducer,
  userClients: clientReducer,
  clientCompany: clientCompanyReducer,
  agencyPlans: fetchAgencyPlansReducer,
  generalAppState: generalAppStateReducer,
  filesAssets: fileAssetReducer,
  userStripeInfo: stripeInfoReducer,
  userActivePlanInfo: userActivePlanReducer,
  logs: logsReducer,
  accountLogs: accountLogs,
  userWebhooks: webhookReducer,
  breadcrumbs: breadcrumbsReducer,
  agencyPayment: agencyPaymenyReducer,
  assignedClients: assignedClientsReducer,
  emailNotificationEvents: emailNotificationEventsReducer,
  clientInvoices: clientInvoicesReducer,
  gettingStartedTasks: gettingStartedTasksReducer,
  permission: permissionReducer,
  workspacePermission: workspacePermissionReducer,
  userPermission: userPermissionReducer,
  cancelClientServiceRequest: cancelClientServiceRequest,
  onboarding: onboardingReducer,
  clientRaisedRequest: clientRaiseRequestReducer,
  clientRaisedRequestAgency: clientRaisedRequestAgencyReducer,
  widget: widgetReducer,
  views: viewsReducer,
  timer: timerReducer,
  reports: reportsReducer,
  standups: standupsReducer,
  pulse: pulseReducer,
  approvals: approvalsReducer,
  calendar: calendarReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.removeItem('authToken')

    if (window.fcWidget) {
      window.fcWidget.destroy()
    }

    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
