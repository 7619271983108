import { makeStyles, createStyles } from '@material-ui/core'

const ClientCheckoutStyles = makeStyles((theme) =>
  createStyles({
    label: {
      color: '#6b778c',
      fontSize: '.81rem',
      display: 'block',
      marginTop: '.25rem'
    },
    image: {
      width: '180px',
      height: '25px'
    },
    serviceImage: {
      width: '50px',
      height: '50px'
    },
    loader: {
      backgroundColor: 'black',
      opacity: '0.8',
      position: 'fixed',
      zIndex: '10'
    }
  })
)

export default ClientCheckoutStyles
