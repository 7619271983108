import React, { useState, useRef, useCallback, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { fileToDataUrl } from 'utils'
import CellWrapper from './CellWrapper'
import { ReactComponent as VideoIcon } from 'static/svg/video.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { stopEventPropagation } from 'utils'
import { ReactComponent as PaperclipIcon } from 'static/svg/paperclip.svg'
import { isEqual } from 'lodash'

const AttachmentCell = (props) => {
  const {
    value,
    row: { index, values, original },
    column: { id, fieldType },
    handleDeleteAttachment,
    // handleUploadAttachment,
    uploadTaskAttachment,
    handleUploadDynamicAttachment,
    handlePreviewAttachment,
    taskPermission
  } = props
  const classes = useStyles()
  const [files, setFiles] = useState(value || [])
  const inputRef = useRef()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (value) {
      setFiles(value)
    }
  }, [value])

  const handleRemoveAttachment = useCallback(
    (fileIndex) => {
      handleDeleteAttachment({
        rowIndex: index,
        columnId: id,
        value: files[fileIndex],
        taskId: original.taskId,
        fieldType
      })
    },
    [files]
  )

  const handleTriggerFileUpload = () => {
    inputRef.current.click()
  }

  const handleUploadFile = (e) => {
    const newFile = Object.values(e.target.files)[0]

    if (newFile) {
      setLoading(true)
      fileToDataUrl(newFile).then((res) => {
        if (fieldType === 'static') {
          // taskId, workspaceId, file, cb
          uploadTaskAttachment({
            taskId: original._id,
            workspaceId: original.workSpace,
            file: res,
            cb: (res, err) => {
              setLoading(false)
            }
          })
          // handleUploadAttachment({
          //   rowIndex: index,
          //   columnId: id,
          //   value: res,
          //   taskId: original.taskId,
          //   cb: () => setLoading(false)
          // })
        } else {
          handleUploadDynamicAttachment({
            rowIndex: index,
            columnId: id,
            value: res,
            allRowValues: values,
            taskId: original.taskId,
            cb: () => setLoading(false)
          })
        }
      })
    }
    e.currentTarget.value = ''
  }

  return (
    <CellWrapper
      styles={{ minWidth: 180, maxWidth: 180 }}
      className="px-2 justify-center relative"
      onClick={taskPermission.update ? handleTriggerFileUpload : undefined}
    >
      {files.length ? (
        <div>
          {files.map((item, index) => (
            <AttachmentPill
              key={index}
              index={index}
              file={item}
              classes={classes}
              handleRemoveAttachment={
                handleDeleteAttachment ? handleRemoveAttachment : null
              }
              handlePreviewAttachment={handlePreviewAttachment}
              taskPermission={taskPermission}
            />
          ))}
        </div>
      ) : (
        <div
          style={{ minHeight: 'inherit' }}
          className="w-full h-full show-on-hover-parent flex justify-center items-center"
        >
          <PaperclipIcon className="w-4 h-4 show-on-hover-child" />
        </div>
      )}
      {loading && (
        <div
          className="absolute inset-0 flex justify-center items-center"
          onClick={stopEventPropagation}
        >
          <CircularProgress size={20} />
        </div>
      )}
      <input
        type="file"
        onChange={handleUploadFile}
        ref={inputRef}
        className="invisible absolute w-6"
        accept="*"
      />
    </CellWrapper>
  )
}

const AttachmentPill = React.memo(
  ({
    index,
    file,
    classes,
    handleRemoveAttachment,
    handlePreviewAttachment,
    taskPermission
  }) => {
    const [fileType, setFileType] = useState('file')

    useEffect(() => {
      if (!file) return
      if (/\.(mp3|mp4|mkv|avi|webm|mov)$/.test(file)) setFileType('video')
      else if (/\.(png|svg|jpe?g|gif|tiff?)$/.test(file)) setFileType('image')
    }, [file])

    const handleRemove = () => {
      handleRemoveAttachment(index)
    }

    const handleFileClick = () => {
      handlePreviewAttachment(file)
    }

    return (
      <div
        className={clsx(
          classes.badge,
          'relative inline-block mr-2 mb-1 w-8 h-8 flex-1'
        )}
        onClick={stopEventPropagation}
      >
        <div onClick={handleFileClick} className="cursor-pointer h-full w-full">
          {fileType === 'image' ? (
            <img alt="" className="w-full h-full object-contain" src={file} />
          ) : fileType === 'video' ? (
            <span className="text-2xl text-gray-500">
              <VideoIcon />
            </span>
          ) : (
            <span className="text-2xl text-gray-500">
              <FileIcon />
            </span>
          )}
        </div>

        {handleRemoveAttachment && taskPermission.update && (
          <button
            className={clsx(
              'w-5 h-5 rounded-full bg-red-400 text-white flex justify-center items-center',
              classes.btnRemove
            )}
            onClick={handleRemove}
          >
            <CloseIcon style={{ fontSize: 12 }} />
          </button>
        )}
      </div>
    )
  }
)

const useStyles = makeStyles({
  badge: {
    '&:hover button': {
      opacity: 1
    }
  },
  btnRemove: {
    position: 'absolute',
    top: -4,
    right: -4,
    opacity: 0
  }
})

// ===== Prevents unnecessary re-rendering ====
const canRender = (prevState, nextState) => {
  return isEqual(prevState.value, nextState.value)
}

export default React.memo(AttachmentCell, canRender)
