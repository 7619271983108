import { makeStyles, createStyles } from '@material-ui/core'

const clientSubscriptionStyles = makeStyles(theme =>
  createStyles({
    durationTabContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      // marginBottom: theme.spacing(3),
      // maxWidth: '569px',
      // width: '569px',
      '&>p, &>a': {
        fontSize: 14,
        fontWeight: 400,
        color: '#778CA2',
        paddingBottom: theme.spacing(0.5),
        cursor: 'pointer',
        minWidth: 32,
        textAlign: 'center',
        userSelect: 'none',
        '&.active': {
          boxShadow: `0px 3px ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      }
    },

    cardsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gridGap: '1.5rem'
    },
    icons: {
      transition: '0.2s transform, 0.2s color, 0.2s backround-color',
      '&:hover': {
        backgroundColor: 'rgb(214 214 214 / 58%)',
        color: '#333'
      },
      '&.expanded': {
        transform: 'rotate(90deg)'
      }
    },
    cardIcon: {
      fontSize: '2.2rem',
      color: '#1600e5'
    },
    pricing: {
      color: '#1600e5'
    },
    active: {
      color: '#2bc72b'
    },
    cancel: {
      color: '#2E8B57'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
    }
  })
)

export default clientSubscriptionStyles
