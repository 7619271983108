import { useState } from 'react'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { Button } from '@material-ui/core'

const ServicePreviewModal = ({ open, handleClose }) => {
  const [copy, setCopy] = useState(false)

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/services`
    )
    setCopy(true)
  }

  const visitServicesPage = () => {
    let a = document.createElement('a')
    a.target = '_blank'
    a.href = `${window.location.protocol}//${window.location.host}/services`
    a.click()
  }

  return (
    <CustomModal open={open} handleClose={handleClose} size="extraSmall">
      <CustomModalHeader heading="Services Page Link" />

      <CustomModalBody>
        <p className="mb-4">Share this with your customers</p>
        <div className="space-y-2">
          <Button
            onClick={handleCopyLink}
            // size="small"
            className="w-full"
            // style={{ fontSize: 14, height: 32, marginBottom: 8 }}
          >
            {!copy && <CopyIcon fontSize="1rem" />}
            <span className="inline-block py-1 font-medium ml-2">
              {copy ? 'Copied!' : 'Copy Services Link'}
            </span>
          </Button>
          <Button
            variant="outlined"
            // size="small"
            className="w-full"
            onClick={visitServicesPage}
          >
            <span className="inline-block py-1">Visit Services Page</span>
          </Button>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button onClick={handleClose}>Close</Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default ServicePreviewModal
