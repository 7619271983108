export const CLIENT_RAISED_REQUESTS_AGENCY = {
  LOADING: 'CLIENT_RAISE_REQUEST_AGENCY_LOADING',
  FETCHED: 'CLIENT_RAISE_REQUEST_AGENCY_FETCHED',
  ADD_ONE: 'ADD_ONE',
  ERROR: 'CLIENT_RAISE_REQUEST_AGENCY_ERROR',
  UPDATE_ONE: 'CLIENT_RAISE_REQUEST_AGENCY_UPDATE_ONE'
}

const initialState = {
  loading: true,
  data: [],
  error: false,
  fetched: false
}

const clientRaisedRequestAgencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_RAISED_REQUESTS_AGENCY.LOADING:
      return { ...state, loading: true }
    case CLIENT_RAISED_REQUESTS_AGENCY.FETCHED:
      return { ...state, loading: false, data: action.payload, fetched: true }

    case CLIENT_RAISED_REQUESTS_AGENCY.UPDATE_ONE:
      const updatedData = state.data.map((item) => {
        if (item._id === action.payload._id) return action.payload
        return item
      })

      return { ...state, data: updatedData }

    case CLIENT_RAISED_REQUESTS_AGENCY.ADD_ONE:
      return { ...state, data: [action.payload, ...state.data] }

    case CLIENT_RAISED_REQUESTS_AGENCY.ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}

export default clientRaisedRequestAgencyReducer
