import React, { useEffect, useState } from 'react'
import { ColoredAvatars, LoadingButton } from 'global/globalComponents'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as UserCircle } from 'static/svg/user-circle.svg'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { Button, TextField } from '@material-ui/core'
import DatePicker from 'react-datepicker'
import CollabDrowdown from '../CollabDrowdown'
import Collaborator from '../Collaborator'
// import { updateTaskDetails } from 'thunks/task/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeIconSlash } from 'static/svg/eye-slash.svg'
import { createTaskApproval } from 'thunks/approvals/actions'
import { userRoles } from 'utils'

const CreateApprovalCard = ({
  taskId,
  subtaskId,
  boardId,
  currentWorkspace,
  addNewApproval,
  removeCreateApproval,
  handleCancelApprovalCreate,
  elmFor
}) => {
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const [isInternal, setIsInternal] = useState(false)

  const [approvalData, setApprovalData] = useState({
    status: '',
    dueDate: '',
    requestTo: [],
    approvalBy: {},
    approvedBy: [],
    notes: ''
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [approversList, setApproversList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let arr = []
    if (currentWorkspace.assignedAgencyTeam) {
      const collab = isInternal
        ? [...currentWorkspace.assignedAgencyTeam]
        : [
            // ...currentWorkspace.assignedAgencyTeam,
            ...currentWorkspace.assignedClientTeam
          ]

      for (let item of collab) {
        const idx = approvalData.requestTo.findIndex(
          (user) => user._id === item.user._id
        )
        if (idx === -1) arr.push(item)
      }
    }

    setApproversList(arr)
  }, [
    currentWorkspace.assignedAgencyTeam,
    currentWorkspace.assignedClientTeam,
    approvalData.requestTo,
    isInternal
  ])

  const togglePrivateState = () => {
    setApprovalData((prev) => ({ ...prev, requestTo: [] }))
    setIsInternal((prev) => !prev)
  }

  const handleDueDateChange = (e) => {
    setApprovalData((prev) => ({ ...prev, dueDate: e.toISOString() }))
  }

  const toggleCollabPopper = (e) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleSelectCollab = (teammate) => {
    setApprovalData((prev) => ({
      ...prev,
      requestTo: [...prev.requestTo, teammate.user]
    }))
    toggleCollabPopper()
  }

  const handleRemoveApprover = (teammate) => {
    setApprovalData((prev) => ({
      ...prev,
      requestTo: prev.requestTo.filter((item) => item._id !== teammate._id)
    }))
  }

  const handleUpdateNotes = (e) => {
    setApprovalData((prev) => ({ ...prev, notes: e.target.value }))
  }

  const handleCreateApproval = () => {
    if (approvalData.requestTo.length === 0) {
      dispatch(fireErrorToaster('Please select approver!'))
      return
    }

    setLoading(true)
    const data = {
      task: taskId,
      status: 'under_approval',
      dueDate: approvalData.dueDate,
      requestTo: approvalData.requestTo.map((item) => item._id),
      approvalBy: meData._id,
      notes: approvalData.notes,
      type: isInternal ? 'internal' : 'external',
      category: elmFor,
      workspace: currentWorkspace._id,
      workspaceBoard: boardId
    }

    if (elmFor === 'subtask') data.subTask = subtaskId

    createTaskApproval(data, (res, err) => {
      setLoading(false)

      if (!err) {
        dispatch(fireSuccessToaster('Approval request sent!'))
        addNewApproval(res)
        removeCreateApproval()
      } else {
        dispatch(fireErrorToaster(res))
      }
    })
  }

  return (
    <>
      <div className="bg-white shadow p-4">
        <div>
          <div className="flex items-start">
            <ColoredAvatars user={meData} size="small" tooltip={false} />
            <div className="ml-3 leading-3 w-full">
              <div className="w-full flex justify-between items-center">
                <div>
                  <h6 className="font-semibold text-smaller mb-1">
                    {meData.name}
                  </h6>
                  <span className="text-gray-400 text-xs">
                    Requesting {isInternal ? 'internal' : 'external'} approval
                  </span>
                </div>
              </div>

              <div className="mt-6 w-60 text-gray-600">
                {meData.role < userRoles.USER_CLIENT && (
                  <div className="flex items-center text-xs mb-4">
                    <div className="w-28">Type</div>
                    <div
                      className="flex cursor-pointer"
                      onClick={togglePrivateState}
                    >
                      {!isInternal ? <EyeIcon /> : <EyeIconSlash />}
                      <span className="ml-2">
                        {isInternal ? 'Internal' : 'External'}
                      </span>
                    </div>
                  </div>
                )}

                <div className="flex items-center text-smaller mb-4">
                  <div className="w-28">
                    Approvers <span className="text-red-400">*</span>
                  </div>

                  <div className="flex text-blue-400">
                    {Boolean(approvalData.requestTo.length) ? (
                      <div className="flex items-center">
                        {approvalData.requestTo.map((teammate) => (
                          <Collaborator
                            teammate={teammate}
                            handleRemove={handleRemoveApprover}
                            removeBtn={true}
                            size="tiny"
                          />
                        ))}
                        <button
                          onClick={toggleCollabPopper}
                          className="w-5 h-5 ml-2 border border-dashed border-gray-300 text-gray-500 rounded-full flex justify-center items-center"
                        >
                          <PlusIcon />
                        </button>
                      </div>
                    ) : (
                      <div
                        onClick={toggleCollabPopper}
                        className="flex items-center cursor-pointer"
                      >
                        <UserCircle />
                        <span className="ml-2">Choose...</span>
                      </div>
                    )}
                  </div>
                </div>

                {/* No one can update the due date except the approval request creator*/}
                <div className="flex items-center text-smaller">
                  <div className="w-28">Due Date</div>

                  <div
                    className="flex cursor-pointer text-blue-400"
                    // onClick={togglePrivateState}
                  >
                    <DatePicker
                      selected={
                        approvalData.dueDate
                          ? new Date(approvalData.dueDate)
                          : false
                      }
                      onChange={handleDueDateChange}
                      customInput={
                        approvalData.dueDate ? (
                          <input className="w-32 text-xs text-red-400" />
                        ) : (
                          <div className="flex">
                            <CalendarIcon />
                            <span className="ml-2">Choose...</span>
                          </div>
                        )
                      }
                      dateFormat="MMMM d, yyyy"
                      // disabled={!meApprovalState.canUpdate.dueDate}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <TextField
                  multiline={true}
                  placeholder="Add your short here"
                  fullWidth={true}
                  rows={4}
                  inputProps={{ style: { fontSize: 13 } }}
                  value={approvalData.notes}
                  onChange={handleUpdateNotes}
                />

                <div className="mt-4 space-x-2">
                  <LoadingButton
                    loading={loading}
                    size="small"
                    onClick={handleCreateApproval}
                  >
                    Request
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleCancelApprovalCreate}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CollabDrowdown
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={toggleCollabPopper}
        list={approversList}
        handleListItemClick={handleSelectCollab}
        meData={meData}
        style={{ zIndex: 2000 }}
      />
    </>
  )
}

export default CreateApprovalCard
