import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useWorkspacePermission = (workspaceId) => {
  const workspacePermission = useSelector(
    (state) => state.workspacePermission.data
  )
  const [permissionObj, setPermissionObj] = useState({
    workspace: {},
    workspaceView: {},
    task: {},
    subTask: {}
  })

  useEffect(() => {
    for (let item of workspacePermission) {
      if (typeof item[workspaceId] === 'object') {
        setPermissionObj(item[workspaceId].access)
      }
    }
  }, [workspaceId, workspacePermission])

  return {
    workspace: permissionObj?.workspace,
    workspaceView: permissionObj?.workspaceView,
    task: permissionObj?.task,
    subtask: permissionObj?.subTask
  }
}

export default useWorkspacePermission
