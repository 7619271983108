export const chatMessengerInit = (user) => {
  if (window.fcWidget) {
    window.fcWidget.init({
      token: 'baa8c17c-2e44-407a-a0a7-de97521cfced',
      host: 'https://wchat.freshchat.com'
    })

    // window.fcWidget.user.setProperties({
    //   firstName: user.name.split(' ')[0],
    //   lastName: user.name.split(' ')[1],
    //   email: user.email
    // })
  }
}

export const chatMessengerDestroy = () => {
  if (window.fcWidget) {
    window.fcWidget.destroy()
  }
}

export const hideChatMessenger = () => {
  // window?.Intercom('update', {
  //   hide_default_launcher: true
  // })
  if (window.fcWidget) {
    window.fcWidget.hide()
  }
}

export const showChatMessenger = () => {
  if (window.fcWidget) {
    window.fcWidget.show()
  }
  // window?.Intercom('update', {
  //   hide_default_launcher: false
  // })
}

export const openChatMessengerWithMessage = (message = '') => {
  if (window.fcWidget) {
    window.fcWidget.open({
      name: 'Support',
      replyText: message
    })
  }
}

export const startChatService = (name, email, user_id) => {
  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: 'ddb38c1c',
      name,
      email,
      user_id
    })
  }
}

export const postEvent = (eventName, metaData) => {
  if (metaData) {
    window?.Intercom('trackEvent', eventName, metaData)
  } else {
    window?.Intercom('trackEvent', eventName)
  }
}

// ============= Intercom Connectivity ================
export const intercomConnect = (intercomId, meData) => {
  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: intercomId,
      name: meData.name,
      email: meData.email,
      user_id: meData._id
    })
  }
}

// ============= Crisp Connectivity ================
export const crispConnect = (crispId) => {
  window.CRISP_WEBSITE_ID = crispId
  window.$crisp = []
  ;(function () {
    const d = document
    const s = d.createElement('script')
    s.src = 'https://client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}

// ============= Dashly Connectivity ================
export const dashlyConnect = (dahslyId) => {
  window.dashly.connect(dahslyId)
}

// ============= Facebook Pixel Connectivity ================
export const facebookPixelConnect = (facebookPixelId) => {
  ;(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  )
  window.fbq('init', facebookPixelId)
  window.fbq('track', 'PageView')
}
