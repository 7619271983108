import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const AddClientsTask = () => {
  const { clientsConnected } = useSelector(
    (state) => state.gettingStartedTasks.data
  )

  return (
    <div>
      {clientsConnected ? (
        <p>This step is complete.</p>
      ) : (
        <div>
          <p className="mb-3">Please add/invite Clients into your portal.</p>
          <Button to="/agency/clients/all" component={Link}>
            Add Clients
          </Button>
        </div>
      )}
    </div>
  )
}

export const AddTeammateTask = () => {
  const { teammatesConnected } = useSelector(
    (state) => state.gettingStartedTasks.data
  )

  return (
    <div>
      {teammatesConnected ? (
        <p>
          This step is complete. Manage the roles of each teammate from their
          settings section.
        </p>
      ) : (
        <div>
          <p className="mb-3">Please add/invite teammates into your Portal.</p>
          <Button to="/agency/teammates/all" component={Link}>
            Add Teammates
          </Button>
        </div>
      )}
    </div>
  )
}

export const CreateServicesTask = () => {
  const { servicesConnected } = useSelector(
    (state) => state.gettingStartedTasks.data
  )

  return (
    <div>
      {servicesConnected ? (
        <p>
          This step is complete. Now your Clients can see the available services
          &amp; can avail them
        </p>
      ) : (
        <div>
          <p className="mb-3">
            Please create Services (or Products, or Plans) which will be shown
            to your Clients.
          </p>
          <Button to="/agency/payments/services/all" component={Link}>
            Create Services
          </Button>
        </div>
      )}
    </div>
  )
}

export const CreateWorkspaceTask = () => {
  const { workspaceConnected } = useSelector(
    (state) => state.gettingStartedTasks.data
  )

  return (
    <div>
      {workspaceConnected ? (
        <p>This step is complete.</p>
      ) : (
        <div>
          <p className="mb-3">
            Please create new Projects internally for your team or externally
            for Clients.
          </p>
          <Button to="/agency/workspace/all" component={Link}>
            Create Projects
          </Button>
        </div>
      )}
    </div>
  )
}

export const StripeConnectionTask = () => {
  const { stripeConnected } = useSelector(
    (state) => state.gettingStartedTasks.data
  )

  return (
    <div>
      {stripeConnected ? (
        <p>
          This step is complete, please create new Services or raise invoices to
          your Clients
        </p>
      ) : (
        <div>
          <p className="mb-3">
            Please connect your Stripe account with the portal
          </p>
          <Button to="/agency/settings/integrations" component={Link}>
            Connect Stripe
          </Button>
        </div>
      )}
    </div>
  )
}

export const WhiteLabelPortalTask = () => {
  const { whiteLabelActive } = useSelector(
    (state) => state.gettingStartedTasks.data
  )

  return (
    <div>
      {whiteLabelActive ? (
        <p>
          This step is complete, please ensure that the portal is showing SSL
          secured or not
        </p>
      ) : (
        <div>
          <p className="mb-3">Rebrand &amp; whitelabel your agency's portal.</p>
          <Button to="/agency/settings/branding" component={Link}>
            Click Here
          </Button>
        </div>
      )}
    </div>
  )
}
