import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
  useImperativeHandle
  // useCallback,
  // useLayoutEffect
} from 'react'
// import Task from './Task'
import { Draggable, Droppable } from 'react-beautiful-dnd'
// import {
//   // Button,
//   List,
//   ListItem,
//   TextField,
//   Popover
//   // Box
// } from '@material-ui/core'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import { errorMessages, triggerOnScrollToBottom } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { addTaskInSection, createTask } from 'thunks/task/actions'
import { useParams, useHistory } from 'react-router-dom'
// import TaskPanel from '../Workspace/Panel/TaskPanel'
// import { updateWorkspaceSection } from 'thunks/embed/action'
// import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
// import { ReactComponent as Pen } from 'static/svg/pen.svg'
// import { ReactComponent as PaintIcon } from 'static/svg/paint-brush.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
// import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
// import { updateWorkspaceSection } from 'thunks/workspace/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined'
import clsx from 'clsx'
// import useWorkspacePermission from 'customHooks/useWorkspacePermission'
import BoardsStyles from '../BoardsStyles'
// import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
// import { AvatarGroup, Skeleton } from '@material-ui/lab'
// import { ColoredAvatars } from 'global/globalComponents'
// import CheckIcon from '@material-ui/icons/Check'
import CreateTaskCard from './CreateTaskCard'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
// import { getUserPath } from 'utils/userRoles'
// import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined'
// import { isEqual } from 'lodash'
// import { useTheme } from '@material-ui/styles'
import { getSectionTasks } from 'thunks/task/actions'
import ColumnHeader from './ColumnHeader'
import ColumnTasks from './ColumnTasks'

const infiniteScroll = triggerOnScrollToBottom()

// const checkVisibility = (elm, right) => {
//   const rect = elm.getBoundingClientRect()
//   return rect.left < right
// }

const Column = React.forwardRef((props, ref) => {
  const {
    column,
    index,
    workspacePermission,
    taskPermission,
    handleDeleteWorkspaceSection,
    sectionId,
    groupBy,
    showApprovalTasks,
    // workspaceId,
    handleTaskSidePanel,
    workspaceComplete,
    handleMarkTaskAsComplete,
    categoryNameChange,
    search,
    filters,
    toggleEditStatusModal
    // boardId
  } = props
  const classes = BoardsStyles()
  const dispatch = useDispatch()
  const sectionNameInputRef = useRef(null)
  const { workspaceId, boardId } = useParams()
  const me = useSelector(state => state.me.data)
  // const [taskCategoryName, setTaskCategoryName] = useState(column.title)
  const [createNewTask, setCreateNewTask] = useState({
    top: false,
    bottom: false
  })
  const [lastTaskOrderIndex, setLastTaskOrderIndex] = useState('0')
  const [totalVisibleTasks, setTotalVisibleTasks] = useState(0)
  // const history = useHistory()
  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)

  // const [editSectionTitle, setEditSectionTitle] = useState(false)
  // const [anchorEl, setAnchorEl] = useState(null)
  const { socketId } = useContext(WorkspacePusherContext)
  // const meData = useSelector((state) => state.me.data)
  const [page, setPage] = useState(0)
  const [tasksLoading, setTasksLoading] = useState(true)
  const [isInViewport, setIsInViewport] = useState(false)
  const elmRef = useRef()

  // const { sectionWiseTasks, dueDateWiseTasks, data: allTasks } = useSelector(
  //   (state) => state.userTasks
  // )
  // const [columnState, setColumnState] = useState({
  //   loading: true,
  //   tasks: [],
  //   totalTasks: 0,
  //   page: 0
  // })
  // const columnTasksData = sectionWiseTasks[column._id] ?? {}
  // const [tasksToRender, setTasksToRender] = useState({
  //   loading: true,
  //   data: []
  // })

  useEffect(() => {
    setPage(0) //resetting page to 0 on group by change
  }, [groupBy])

  useEffect(() => {
    if (elmRef.current) {
      const rect = elmRef.current.getBoundingClientRect()
      setIsInViewport(rect.left < window.innerWidth - 40) // 40px padding
    }
  }, elmRef)

  // First time fetch
  useEffect(
    function () {
      if (!isInViewport || filters.loading) return
      setTasksLoading(true)
      fetchSectionTasks((res, err) => {
        setTasksLoading(false)
      })
    },
    [showApprovalTasks, groupBy, search, isInViewport, filters]
  )

  useEffect(() => {
    if (categoryNameChange.change && sectionNameInputRef.current !== null) {
      sectionNameInputRef.current.focus()
    }
  }, [categoryNameChange, sectionNameInputRef])

  useImperativeHandle(
    elm => {
      ref.current[index] = elm
    },
    () => ({
      handleParentScroll: parentElm => {
        if (isInViewport) return
        const rect1 = elmRef.current.getBoundingClientRect()
        const rect2 = parentElm.getBoundingClientRect()
        setIsInViewport(rect1.left < rect2.right)
      }
    })
  )

  const fetchSectionTasks = (cb, pageCount = 0) => {
    const data = {
      workspace: workspaceId,
      workspaceBoard: boardId,
      myApprovals: showApprovalTasks,
      search: search || null
    }

    // Different group by sections expects some different data in body
    if (groupBy === 'status') {
      data.section = column._id
    } else if (groupBy === 'dueDate') {
      data.startDate = column.startDate
      data.endDate = column.endDate
      data.category =
        column._id === 'overdue'
          ? 'overdue'
          : column._id === 'noDueDate'
          ? 'noDueDate'
          : column._id
    } else if (groupBy === 'assignee') {
      data.agencyTeammates = column.agencyTeammates
      data.clientTeammates = column.clientTeammates
    }

    if (filters.data._id) {
      data.filters = {
        op: filters.data.op,
        fields: filters.data.fields
      }
    }

    // data, query, sectionId, groupBy, callback
    dispatch(
      getSectionTasks(
        data,
        {
          // === query ===
          view: 'board',
          type:
            groupBy === 'status'
              ? 'section'
              : groupBy === 'dueDate'
              ? 'dueDate'
              : 'assignee',
          limit: 10,
          page: pageCount
        },
        column._id,
        groupBy,
        cb
      )
    )
  }

  // const fetchTasksByDate = (cb, pageCount = 0) => {
  //   const bodyData = {
  //     workspace: workspaceId,
  //     workspaceBoard: boardId,
  //     category:
  //       column._id === 'overdue'
  //         ? 'overdue'
  //         : column._id === 'noDueDate'
  //         ? 'noDueDate'
  //         : column._id,
  //     startDate: column.startDate,
  //     endDate: column.endDate,
  //     myApprovals: showApprovalTasks,
  //     search: search || null
  //   }

  //   if (filters.data._id) {
  //     bodyData.filters = {
  //       op: filters.data.op,
  //       fields: filters.data.fields
  //     }
  //   }

  //   dispatch(
  //     getTasksByDate(
  //       column._id,
  //       bodyData,
  //       {
  //         view: 'board',
  //         type: 'dueDate',
  //         limit: 10,
  //         page: pageCount
  //       },
  //       cb
  //     )
  //   )
  // }

  // const fetchTasksByAssignee = (cb, pageCount = 0) => {
  //   const bodyData = {
  //     workspace: workspaceId,
  //     workspaceBoard: boardId,
  //     agencyTeammates: column.agencyTeammates,
  //     clientTeammates: column.clientTeammates,
  //     myApprovals: showApprovalTasks,
  //     search: search || null
  //   }

  //   dispatch(
  //     getTasksByDate(
  //       column._id,
  //       bodyData,
  //       {
  //         view: 'board',
  //         type: 'assignee',
  //         limit: 10,
  //         page: pageCount
  //       },
  //       cb
  //     )
  //   )
  // }

  const handleCreateNewTask = useCallback(
    position => {
      if (workspaceComplete)
        dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
      else {
        if (taskPermission?.create) {
          setCreateNewTask(prev => ({ ...prev, [position]: true }))
        } else dispatch(fireErrorToaster('You are not an authorized user'))
      }
    },
    [workspaceComplete, taskPermission]
  )

  const createNewTaskCallback = (res, err) => {
    if (!err) {
      dispatch(
        addTaskInSection({
          sectionId: groupBy === 'status' ? sectionId : column._id,
          top: createNewTask.top,
          taskId: res._id,
          groupBy
        })
      )

      setCreateNewTask({
        top: false,
        bottom: false
      })
    }
  }

  const titleInputOnBlur = e => {
    const value = e.target.value.trim()

    if (value.length > 0) {
      const data = {
        title: value,
        workSpace: workspaceId,
        workspaceBoard: boardId,
        taskSection: sectionId,
        socketId
      }

      if (groupBy === 'assignee') {
        data.assignedAgencyTeam = column.agencyTeammates
        data.assignedClientTeam = column.assignedClientTeam
      } else if (groupBy === 'dueDate') {
        data.dueDate = column.value
      }

      data[groupByKeyWords[groupBy]] = createNewTask.top
        ? '0'
        : lastTaskOrderIndex

      dispatch(
        createTask(
          data,
          createNewTaskCallback,
          `${createNewTask.top ? 'top' : 'bottom'}`
        )
      )
    } else {
      setCreateNewTask({
        top: false,
        bottom: false
      })
    }
  }

  // const handleTaskCategoryName = e => {
  //   setTaskCategoryName(e.target.value)
  // }

  // const sectionUpdateCallback = (res, err) => {
  //   if (err) {
  //     setTaskCategoryName(column.title)
  //     dispatch(fireErrorToaster('error'))
  //   } else {
  //     dispatch(fireSuccessToaster('Updated Successfully!'))
  //   }
  // }

  // const onBlur = () => {
  //   const newName = taskCategoryName.trim()
  //   toggleEditSectionTitle()

  //   if (!newName) {
  //     setTaskCategoryName(column.title)
  //     return
  //   }

  //   if (newName === column.title) return // if nothing changed

  //   dispatch(
  //     updateWorkspaceSection(
  //       column.value,
  //       { label: taskCategoryName },
  //       column.title,
  //       sectionUpdateCallback
  //     )
  //   )
  // }

  // const toggleEditSectionTitle = () => {
  //   if (workspaceComplete) {
  //     dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
  //     return
  //   }

  //   setEditSectionTitle(prev => !prev)
  // }

  // const handleTogglePopup = e => {
  //   if (anchorEl) {
  //     setAnchorEl(null)
  //   } else {
  //     setAnchorEl(e.currentTarget)
  //   }
  // }

  // const handleRenameSection = () => {
  //   setAnchorEl(null)

  //   // text input not showing up (BUG fix)
  //   // setTimeout(() => {
  //   //   toggleEditSectionTitle()
  //   // }, 100)
  // }

  const handleDeleteSection = useCallback(() => {
    if (workspaceComplete) {
      dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
      return
    }
    handleDeleteWorkspaceSection(column)
    // setAnchorEl(null)
  }, [workspaceComplete, column._id])

  const handleLoadTasks = e => {
    infiniteScroll(e, {
      cb: activateInfiniteScroll => {
        fetchSectionTasks((res, err) => {
          if (!err) activateInfiniteScroll()
        }, page + 1)
        setPage(prev => prev + 1)

        // if (groupBy === 'status') {
        //   fetchTasksBySection((res, err) => {
        //     if (!err) activateInfiniteScroll()
        //   }, page + 1)
        // } else if (groupBy === 'dueDate') {
        //   fetchTasksByDate((res, err) => {
        //     if (!err) activateInfiniteScroll()
        //   }, page + 1)
        //   setPage(prev => prev + 1)
        // } else if (groupBy === 'assignee') {
        //   fetchTasksByAssignee((res, err) => {
        //     if (!err) activateInfiniteScroll()
        //   }, page + 1)
        //   setPage(prev => prev + 1)
        // }
      },
      offset: 400
    })
  }

  return (
    <>
      <Draggable
        isDragDisabled={groupBy !== 'status' || !taskPermission?.update}
        draggableId={column._id}
        index={index}
        key={column._id}
      >
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div
              style={{
                height: 'fit-content',
                width: 'fit-content'
              }}
              className={clsx(
                'pl-4 pt-4 pb-6 rounded-lg bg-custom-gray-light-4',
                classes.columnContainer
              )}
              ref={elmRef}
            >
              <div
                className={clsx(
                  classes.columnHeaderContainer,
                  'flex mb-4 items-center justify-between overflow-hidden bg-transparent flex-shrink-0 pr-4'
                )}
              >
                <ColumnHeader
                  columnId={column._id}
                  columnTitle={column.title}
                  totalTasks={column.totalTasks}
                  dragHandleProps={provided.dragHandleProps}
                  isWorkspaceComplete={workspaceComplete}
                  taskPermission={taskPermission}
                  workspacePermission={workspacePermission}
                  // handleRenameSection={handleRenameSection}
                  handleDeleteSection={handleDeleteSection}
                  handleCreateNewTask={handleCreateNewTask}
                  toggleEditStatusModal={toggleEditStatusModal}
                  groupBy={groupBy}
                  meRole={me.role}
                />
              </div>
              <Droppable droppableId={column._id} type="task">
                {(provided, snapshot) => (
                  <div
                    className={clsx(classes.taskCardsContainer)}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                    onScroll={
                      totalVisibleTasks < column.totalTasks
                        ? handleLoadTasks
                        : undefined
                    }
                    // className="h-full overflow-y-scroll"
                  >
                    {createNewTask.top && (
                      <CreateTaskCard titleInputOnBlur={titleInputOnBlur} />
                    )}
                    <ColumnTasks
                      isLoading={tasksLoading}
                      sectionId={column._id}
                      // tasks={column.tasks}
                      handleMarkTaskAsComplete={handleMarkTaskAsComplete}
                      handleTaskSidePanel={handleTaskSidePanel}
                      groupBy={groupBy}
                      setLastTaskOrderIndex={setLastTaskOrderIndex}
                      setTotalVisibleTasks={setTotalVisibleTasks}
                      totalTasks={column.totalTasks}
                    />
                    {provided.placeholder}
                    {createNewTask.bottom && (
                      <CreateTaskCard titleInputOnBlur={titleInputOnBlur} />
                    )}
                    {taskPermission?.create && (
                      <div className="text-right pr-4 mt-8">
                        <button
                          className="inline-flex items-center text-sm text-custom-gray-main hover:text-primary-main"
                          variant="outlined"
                          onClick={() => handleCreateNewTask('bottom')}
                        >
                          <span className="inline-block mr-2">
                            <AddIcon className="text-sm" />
                          </span>
                          <span className="font-medium">Add a task</span>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        )}
      </Draggable>
    </>
  )
})

const groupByKeyWords = {
  status: 'orderIndex',
  dueDate: 'dueDateOrderIndex',
  assignee: 'assigneeOrderIndex'
}

// const areEqual = (prevProps, nextProps) => {
//   return (
//     isEqual(prevProps.column, nextProps.column) &&
//     prevProps.showApprovalTasks === nextProps.showApprovalTasks
//   )
//   // isEqual(prevProps.workspacePermission, nextProps.workspacePermission)
// }

export default React.memo(Column)
