import { catchAsync } from 'utils'
import axios from 'axios'
import { errorMessages } from 'utils'
import { ACCOUNTLOGS } from './reducers'

export const createAccountLogs = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      me: { data: meData }
    } = getState()

    try {
      const res = await axios({
        method: 'POST',
        url: '/accountlogs',
        data: {
          ...data,
          description: `<span><strong>${meData.name}</strong> ${data.description}</span>`
        }
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchAccountLogsByUser = (startDate, endDate, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/accountlogs/user?startDate=${startDate}&endDate=${endDate}`
      })
      const sortedData = res.data.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      if (callback) callback(sortedData, false)
      dispatch({ type: ACCOUNTLOGS.FETCHED, payload: sortedData })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateLogsView = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      me: { data: meData }
    } = getState()
    try {
      const res = await axios({
        method: 'PUT',
        url: `/accountlogs/read`,
        data
      })

      if (callback) callback(res.data, false)
      dispatch({
        type: ACCOUNTLOGS.UPDATE,
        payload: { data: data.readNotification, userId: meData._id }
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateAccountLogs = (Id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/accountlogs/${Id}`
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const deleteAccountLogs = (Id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/accountlogs/${Id}`
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
