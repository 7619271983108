import React, { useEffect, useState } from 'react'
import TeamMembers from './TeamMembers'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { CLIENT } from 'thunks/addClient/reducers'
import { fetchClientInfo, getClient } from 'thunks/addClient/actions'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
// import { useBreadcrumbs } from 'customHooks'
import ClientInvoices from './ClientInvoices'
// import { fetchClientOnboardingData } from 'thunks/onboarding/actions'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { ColoredAvatars, CustomTabs } from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { ReactComponent as LockIcon } from 'static/svg/lock.svg'
import { ReactComponent as BackArrow } from 'static/svg/long-arrow-left.svg'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import ProjectCardsPage from 'components/ClientCompanyComponents/ProjectCardsPage'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'

export default function ClientList(props) {
  const { clientId } = useParams()
  const dispatch = useDispatch()
  const allClientsData = useSelector(state => state.userClients)
  const [clientInfo, setClientInfo] = useState({
    loading: true,
    data: {}
  })
  const clientData = useSelector(state => state.userClients.currentClient)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  // const [clientOnboardingData, setClientOnboardingData] = useState({
  //   loading: true,
  //   fetched: false,
  //   data: {}
  // })

  const [selectedTab, setSelectedTab] = useState(0)
  const userPermission = useSelector(state => state.permission.data)

  useEffect(() => {
    if (!allClientsData.fetched) {
      dispatch(getClient())
    }
  }, [allClientsData.fetched])

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userWorkspaces.fetchedFirstTime])

  useEffect(() => {
    if (allClientsData.currentClient?._id) {
      fetchClientInfo(allClientsData.currentClient._id, (res, err) => {
        if (!err) {
          setClientInfo({ loading: false, data: res })
        } else {
          dispatch(
            fireErrorToaster('Error occurred while fetching client info!')
          )
        }
      })
    }
  }, [allClientsData.currentClient])

  // useEffect(() => {
  //   if (!clientOnboardingData.fetched) {
  //     fetchClientOnboardingData(params.clientId, clientOnboardingCallback)
  //   }
  // }, [])

  // const clientOnboardingCallback = (res, err, status) => {
  //   if (err) {
  //     if (status === 404) {
  //       setClientOnboardingData((prev) => ({ ...prev, loading: false }))
  //     } else {
  //       dispatch(fireErrorToaster(res))
  //     }
  //   } else {
  //     setClientOnboardingData({
  //       loading: false,
  //       fetched: true,
  //       data: {
  //         response: res.response,
  //         template: res.template
  //       }
  //     })
  //   }
  // }

  // useBreadcrumbs([
  //   {
  //     name: 'Clients',
  //     link: `/clients/all`,
  //     index: 0
  //   },
  //   {
  //     name: 'Client Settings',
  //     link: `/clients/${params.clientId}`,
  //     index: 1
  //   }
  // ])

  useEffect(() => {
    if (allClientsData.fetched) {
      dispatch({ type: CLIENT.ADD_CURRENT_CLIENT, payload: clientId })
    }
  }, [clientId, allClientsData.fetched])

  // useEffect(() => {
  //   if (!allClientsData.fetched) {
  //     dispatch(getClient())
  //   }
  // }, [allClientsData.fetched])

  const handleTabChange = newVal => {
    setSelectedTab(newVal)
  }

  return (
    <OuterContentWrapper>
      {allClientsData.currentClient && (
        <>
          <Link
            to="/agency/clients/all"
            className="text-blue-400 text-sm hover:underline flex items-center space-x-2"
          >
            <BackArrow
              className="h-6 w-4 text-blue-400"
              style={{ fill: 'rgba(96, 165, 250, 1)' }}
            />
            <span>Clients</span>
          </Link>
          <div className="w-full bg-white shadow-lg rounded-xl shadow px-8 py-8 mb-9 mt-4 flex items-center space-x-3">
            <ColoredAvatars
              user={allClientsData.currentClient}
              size="extraLarge"
            />
            <div>
              <p className="text-primary-dark font-semibold capitalize text-sm md:text-base lg:text-lg">
                {allClientsData.currentClient.name}
              </p>
              <p className="text-custom-gray-light-6 text-xs lg:text-sm">
                {allClientsData.currentClient.email}
              </p>
            </div>
          </div>
          <div>
            <div className="w-full mb-6">
              <CustomTabs
                tabsData={[
                  { label: 'Projects', value: 0 },
                  { label: 'Members', value: 1 },
                  { label: 'Invoices', value: 2 }
                ]}
                // count={false}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
          </div>

          <div className="">
            {selectedTab === 0 && (
              <ProjectCardsPage
                userWorkspaces={userWorkspaces.data}
                clientInfo={clientInfo}
                clientData={clientData}
                access={userPermission.access}
                elmFor="client"
              />
            )}
            {selectedTab === 1 && (
              <TeamMembers
                clientInfo={clientInfo.data}
                setClientInfo={setClientInfo}
              />
            )}
            {selectedTab === 2 &&
              (userPermission.access?.billings?.view ? (
                <ClientInvoices />
              ) : (
                <>
                  <div className="bg-white shadow rounded-xl text-center py-8">
                    <span className="inline-block text-primary-main">
                      <LockIcon className="w-10 h-10" />
                    </span>
                    <p>You do not have permission to view invoices</p>
                  </div>
                </>
              ))}
          </div>
        </>
      )}
    </OuterContentWrapper>
  )
}
