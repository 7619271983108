import React from 'react'
// import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
// import { ColoredAvatars } from 'global/globalComponents'
// import workspaceStyles from '../workspaceStyles'
// import taskStatus from 'utils/taskStatus'
// import clsx from 'clsx'
// import Card from './Card'
import { Card } from 'global/globalComponents'

const TasksCount = ({
  loading,
  // tasks = [],
  // readOnly,
  atRisk,
  completed,
  unPlanned,
  overdue
}) => {
  return (
    <>
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <TasksCountCard
            status="Overdue"
            // data={taskCountData.data.overdue}
            data={overdue}
          />
          <TasksCountCard status="At Risk" data={atRisk} />
          <TasksCountCard status="Unplanned" data={unPlanned} />
          <TasksCountCard status="Completed" data={completed} />
        </>
      )}
    </>
  )
}

const TasksCountCard = ({ status, data }) => {
  // const [assignee, setAssignee] = useState([])

  // useEffect(() => {
  //   if (typeof data.assignee === 'object') {
  //     let sorted = Object.values(data.assignee).sort((a, b) =>
  //       a.taskCount < b.taskCount ? 1 : -1
  //     )

  //     setAssignee(sorted.slice(0, 2))
  //   }
  // }, [data.assignee])

  return (
    <Card className="flex-1 p-4">
      <div className="capitalize text-sm font-semibold text-custom-gray-main mb-2">
        {status}
      </div>
      <div className="text-xl lg:text-2xl font-semibold">
        {data.totalTask < 10 ? '0' + data.totalTask : data.totalTask}
      </div>
    </Card>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      {[
        Array(4)
          .fill('')
          .map(item => (
            <Skeleton variant="rect" height={140} className="rounded" />
          ))
      ]}
    </>
  )
}

export default TasksCount
