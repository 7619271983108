import React, { useContext, useEffect } from 'react'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import onboardingStyles from './onboardingStyles'
import {
  Link,
  useRouteMatch,
  Switch,
  Route,
  useHistory
} from 'react-router-dom'
import AddNewTemplate from './AddNewTemplate'

import {
  onboardingReducer,
  OnboardingContext,
  initialState,
  ONBOARDING
} from './onboardingReducer'
import { useBreadcrumbs } from 'customHooks'
import { fetchAllTemplates } from 'thunks/onboarding/actions'
import { useImmerReducer } from 'use-immer'
import { useSelector, useDispatch } from 'react-redux'
import TemplateCard from './TemplateCard'
import { Skeleton } from '@material-ui/lab'
import { LockOpen } from '@material-ui/icons'
import clsx from 'clsx'

const OnboardingNew = () => {
  const onboarding = useSelector(state => state.onboarding)
  const dispatch = useDispatch()
  const [onboardingState, onboardingDispatch] = useImmerReducer(
    onboardingReducer,
    initialState
  )
  const history = useHistory()
  const { path } = useRouteMatch()

  useBreadcrumbs([
    {
      name: 'Onboarding',
      link: `/onboarding`,
      index: 0
    }
  ])

  useEffect(() => {
    history.push('/agency/onboarding')
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (!onboarding.fetched) {
        dispatch(fetchAllTemplates())
      }
    }
  }, [])

  useEffect(() => {
    onboardingDispatch({
      type: ONBOARDING.UPDATE_TEMPLATE,
      payload: onboarding.data
    })
  }, [onboarding.data])

  return (
    <OuterContentWrapper>
      <OnboardingContext.Provider
        value={{ onboardingState, onboardingDispatch }}
      >
        {/* {process.env.NODE_ENV !== 'development' && (
          <div
            className={clsx('h-full w-full inset-0 p-6 z-50', {
              absolute: false,
              fixed: true
              // [classes.wrapper]: sideDrawerOpen,
              // [classes.wrapperExpanded]: !sideDrawerOpen
            })}
            style={{ backgroundColor: '#808080d9' }}
          >
            <div className="flex items-center justify-center flex-col h-full">
              <span className="rounded-full bg-gray-800 p-4">
                <LockOpen
                  style={{ fill: '#fff' }}
                  className="h-10 w-10 text-white"
                />
              </span>
              <div className="mt-8 text-center rounded p-6  bg-gray-800">
                <p className="font-medium text-lg text-white">Coming Soon</p>
              </div>
            </div>
          </div>
        )} */}
        <Switch>
          <Route exact path={path}>
            <OnboardingOverview loading={onboarding.loading} />
          </Route>
          <Route exact path={`${path}/template/add`}>
            <AddNewTemplate />
          </Route>
          <Route exact path={`${path}/template/:templateId`}>
            <AddNewTemplate edit={true} />
          </Route>
        </Switch>
      </OnboardingContext.Provider>
    </OuterContentWrapper>
  )
}

const OnboardingOverview = ({ loading }) => {
  const classes = onboardingStyles()
  const { path } = useRouteMatch()
  const { onboardingState, onboardingDispatch } = useContext(OnboardingContext)
  const history = useHistory()

  const handleAddTemplate = () => {
    onboardingDispatch({ type: ONBOARDING.ADD_TEMPLATE })
    history.push(`${path}/template/add`)
  }

  return (
    <>
      <header className="mb-6 lg:mb-8">
        <SectionHeading>Client Onboarding</SectionHeading>
      </header>
      <div className="flex flex-wrap gap-4">
        {loading && process.env.NODE_ENV === 'development' ? (
          <LoadingAnimation />
        ) : (
          onboardingState.templates.map(item => (
            <TemplateCard key={item._id} template={item} />
          ))
        )}

        <div
          role="button"
          aria-label="Add new template"
          onClick={handleAddTemplate}
          className={`cursor-pointer bg-white border-2 rounded-xl flex justify-center items-center ${classes.card}`}
        >
          <div className="flex justify-center flex-col items-center text-center text-gray-600">
            <div className="mb-2">
              <AddIcon className="w-6 h-6" />
            </div>
            <span className="text-sm">New Template</span>
          </div>
        </div>
      </div>
    </>
  )
}

const LoadingAnimation = () => (
  <>
    {Array(2)
      .fill('')
      .map((item, index) => (
        <Skeleton
          key={index}
          variant="rect"
          width={180}
          height={160}
          className="rounded-xl"
        />
      ))}
  </>
)

export default OnboardingNew
