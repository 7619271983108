import React from 'react'
import clsx from 'clsx'

const variantClasses = {
  primary: 'text-primary-main bg-primary-light border-primary-light',
  primary__hover:
    'hover:bg-primary-light hover:text-primary-main hover:border-primary-light',
  success: 'bg-green-50 text-green-500 border-green-500',
  success__hover:
    'hover:bg-green-50 hover:border-green-500 hover:text-green-500'
}

const OutlinedButton = ({
  variant = 'primary',
  onClick,
  isActive,
  children,
  roundedFull = true,
  hoverColorChange = true,
  className
}) => {
  return (
    <button
      className={clsx(
        'text-extraSmaller md:text-xs lg:text-smaller flex items-center px-1 lg:px-2 py-1 border whitespace-nowrap',
        hoverColorChange && variantClasses[`${variant}__hover`],
        isActive ? variantClasses[variant] : 'text-gray-500 bg-white',
        roundedFull ? 'rounded-full' : 'rounded',
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default OutlinedButton
