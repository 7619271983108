// import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { useEffect, useState } from 'react'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import {
  // Box,
  Button,
  TextField
  // IconButton,
  // InputAdornment
} from '@material-ui/core'
// import { CustomTooltip, ToggleSwitch } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
// import { ReactComponent as EditIcon } from 'static/svg/edit-2.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as DotIcon } from 'static/svg/circle.svg'
import { ReactComponent as VerticalThreeDot } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ArrowRight } from '@material-ui/icons'
import templateStyles from './templateStyles'
import clsx from 'clsx'
import { LoadingButton } from 'global/globalComponents'

import {
  createProjectTemplate,
  updateProjectTemplate
} from 'thunks/projectTemplate/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { PopupMenu } from 'global/globalComponents'
// import { id } from 'date-fns/locale'

const colorOptions = [
  '#8d84e8',
  '#f06a6a',
  '#aecf55',
  '#ec8d71',
  '#f1bd6c',
  '#f8df72',
  '#5da283',
  '#4ecbc4',
  '#4573d2',
  '#b36bd4',
  '#f9aaef',
  '#f26fb2',
  '#fc979a',
  '#6d6e6f',
  '#c7c4c4',
  '#9ee7e3'
]

const CreateTemplateModal = ({
  open,
  handleClose,
  updateDataHandler,
  tempData = {}
}) => {
  const classes = templateStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    title: tempData.title ?? '',
    description: tempData.description ?? '',
    category: tempData.category ?? '',
    sections: tempData.content?.section ?? []
  })
  const [sectionName, setSectionName] = useState('')
  const [task, setTask] = useState({
    taskName: '',
    index: ''
  })
  const [expanded, setExpanded] = useState({})
  const [editable, setEditable] = useState({
    type: '',
    sectionId: '',
    taskId: '',
    text: ''
  })

  useEffect(() => {
    if (tempData?._id) {
      setFormData({
        title: tempData.title ?? '',
        description: tempData.description ?? '',
        category: tempData.category ?? '',
        sections: tempData.content?.section ?? []
      })
    }
  }, [tempData])

  const handleFormData = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSectionInput = (e) => {
    setSectionName(e.target.value)
  }

  const handleSectionData = (e) => {
    e.preventDefault()
    if (sectionName?.trim()) {
      const id = parseInt(Math.random() * 10000000000)
      setFormData((prev) => ({
        ...prev,
        sections: [
          ...formData.sections,
          {
            id: id,
            label: sectionName,
            order: formData.sections.length + 1,
            color:
              colorOptions[Math.floor(Math.random() * colorOptions.length)],
            tasks: []
          }
        ]
      }))
      setSectionName('')
      setExpanded((prev) => ({ ...prev, [id]: true }))
    }
  }

  const handleTaskInput = (e, index) => {
    setTask({
      taskName: e.target.value,
      index: index
    })
  }

  const handleEditInput = (e) => {
    setEditable((prev) => ({
      ...prev,
      text: e.target.value
    }))
  }

  const handleTaskData = (e) => {
    e.preventDefault()

    if (task.taskName?.trim()) {
      const updatedData = [...formData.sections]
      updatedData[task.index] = {
        ...formData.sections[task.index],
        tasks: [
          ...formData.sections[task.index].tasks,
          {
            taskId: parseInt(Math.random() * 10000000000),
            sectionId: formData.sections[task.index].id,
            title: task.taskName,
            subTask: []
          }
        ]
      }
      setFormData((prev) => ({
        ...prev,
        sections: updatedData
      }))
      setTask('')
    }
  }

  const handleListExpansion = (id) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id]
    }))
  }

  const EditSectionData = (e, sectionIndex, taskIndex) => {
    e.preventDefault()
    let updatedData = [...formData.sections]
    if (editable.type === 'section') {
      updatedData[sectionIndex] = {
        ...updatedData[sectionIndex],
        label: editable.text
      }
      setFormData((prev) => ({
        ...prev,
        sections: updatedData
      }))
    } else {
      // const updatedSectionTasks = [...updatedData[sectionIndex].tasks]
      updatedData[sectionIndex].tasks[taskIndex] = {
        ...updatedData[sectionIndex].tasks[taskIndex],
        title: editable.text
      }

      setFormData((prev) => ({
        ...prev,
        sections: updatedData
      }))
    }
    setEditable({
      type: '',
      sectionId: '',
      taskId: '',
      text: ''
    })
  }

  const handleSave = () => {
    if (!formData.title || !formData.description) {
      return dispatch(fireErrorToaster('Please fill the required fields'))
    }
    setLoading(true)
    const data = {
      title: formData.title,
      category: formData.category,
      description: formData.description,
      type: 'private',
      content: { private: 'true', section: formData.sections }
    }
    if (tempData._id) {
      updateProjectTemplate(tempData._id, data, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster('template Updated Successfully!'))
          updateDataHandler(res, { new: false })
          handleCloseModal()
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    } else {
      createProjectTemplate(data, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster('template Created Successfully!'))
          updateDataHandler(res, { new: true })
          handleCloseModal()
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    }
  }

  const handleCloseModal = () => {
    setFormData({
      title: '',
      description: '',
      category: '',
      sections: []
    })
    setExpanded({ type: '', sectionId: '', taskId: '', text: '' })
    setEditable({})
    handleClose()
    setLoading(false)
  }

  const deleteSection = (type, sectionIndex, taskIndex) => {
    let updatedData = [...formData.sections]
    if (type === 'section') {
      updatedData.splice(sectionIndex, 1)
      setFormData((prev) => ({
        ...prev,
        sections: updatedData
      }))
    } else {
      const updatedSectionTasks = [...updatedData[sectionIndex].tasks]
      updatedSectionTasks.splice(taskIndex, 1)
      updatedData[sectionIndex].tasks = updatedSectionTasks
      setFormData((prev) => ({
        ...prev,
        sections: updatedData
      }))
    }
  }

  return (
    <CustomModal
      size="medium"
      open={open}
      handleClose={handleCloseModal}
      className={classes.templateWrapper}
    >
      <CustomModalHeader heading="New template" />
      <CustomModalBody>
        <div style={{ minHeight: '400px' }}>
          <div className=" h-full">
            <div className="shadow-r">
              <div className="mt-2 flex flex-col space-y-1">
                <FormLabel required className="text-xs">
                  Template Name
                </FormLabel>
                <TextField
                  name="title"
                  placeholder="Name"
                  value={formData.title}
                  onChange={handleFormData}
                />
              </div>

              <div className="mt-4 flex">
                <div className="flex-1 flex flex-col space-y-1 mr-2">
                  <FormLabel required>Description</FormLabel>
                  <TextField
                    placeholder="Template Description"
                    name="description"
                    value={formData.description}
                    onChange={handleFormData}
                  />
                </div>

                <div className="ml-2 flex-1 flex flex-col space-y-1">
                  <FormLabel>Category</FormLabel>
                  <TextField
                    placeholder="Category"
                    name="category"
                    value={formData.category}
                    onChange={handleFormData}
                  />
                </div>
              </div>

              <div className="mt-8">
                <div className="text-sm font-semibold text-primary-mid-dark p-2 bg-gray-50 border-b">
                  Create Sections
                </div>
                <div className="">
                  {formData.sections.map((item, sectionIndex) => (
                    <div className="">
                      {editable.type === 'section' &&
                      editable.sectionId === item.id ? (
                        <form
                          onSubmit={(e) => EditSectionData(e, sectionIndex)}
                          className="flex items-center pl-8 border-b"
                        >
                          <input
                            type="text"
                            value={editable.text}
                            onChange={handleEditInput}
                            autoFocus
                            className="pl-4 bg-transparent p-2 w-full text-sm template"
                            onBlur={(e) => EditSectionData(e, sectionIndex)}
                          />
                        </form>
                      ) : (
                        <div
                          className={`pl-4 p-2 flex items-between justify-between border-b ${classes.editIcon}`}
                        >
                          <div className="flex items-center">
                            <span
                              onClick={() => handleListExpansion(item.id)}
                              className={clsx(
                                {
                                  expanded: expanded === item.id
                                },
                                classes.btn
                              )}
                            >
                              <ArrowRight
                                style={{
                                  width: '20px',
                                  height: '20px',
                                  fill: 'black'
                                }}
                              />
                            </span>
                            <p className="text-sm capitalize font-semibold text-primary-dark mr-2">
                              {item.label}
                            </p>
                          </div>

                          {/* <EditIcon
                            className={`text-primary-main p-1 hover:bg-custom-blue-light rounded-md pen ${classes.penIcon}`}
                            width="1.5em"
                            height="1.5em"
                            onClick={() =>
                              setEditable((prev) => ({
                                ...prev,
                                sectionId: item.id,
                                type: 'section',
                                text: item.label
                              }))
                            }
                          /> */}
                          <PopupMenu
                            targetComp={
                              <VerticalThreeDot
                                className={` text-primary-main p-1 hover:bg-custom-blue-light rounded-md pen ${classes.penIcon}`}
                                width="2em"
                                height="1.5em"
                              />
                            }
                            menuItems={[
                              {
                                label: 'Edit',
                                method: () =>
                                  setEditable((prev) => ({
                                    ...prev,
                                    sectionId: item.id,
                                    type: 'section',
                                    text: item.label
                                  })),
                                icon: <EditIcon className="text-primary-main" />
                              },
                              {
                                label: 'Delete',
                                method: () =>
                                  deleteSection('section', sectionIndex),
                                icon: (
                                  <DeleteIcon className="text-primary-main" />
                                )
                              }
                            ]}
                          />
                        </div>
                      )}

                      {expanded[item.id] && (
                        <div className="">
                          {item.tasks?.map((task, taskIndex) => (
                            <>
                              {editable.type === 'task' &&
                              editable.taskId === task.taskId ? (
                                <form
                                  onSubmit={(e) =>
                                    EditSectionData(e, sectionIndex, taskIndex)
                                  }
                                  className="flex items-center pl-8 border-b"
                                >
                                  <input
                                    type="text"
                                    value={editable.text}
                                    onChange={handleEditInput}
                                    autoFocus
                                    className="pl-4 bg-transparent p-2 w-full text-sm template"
                                    onBlur={(e) =>
                                      EditSectionData(
                                        e,
                                        sectionIndex,
                                        taskIndex
                                      )
                                    }
                                  />
                                </form>
                              ) : (
                                <div
                                  className={` pl-10 p-2 flex items-center justify-between border-b ${classes.editIcon}`}
                                >
                                  <div className="flex items-center">
                                    <DotIcon className="h-1 w-1 mr-2" />
                                    <p className="text-sm text-primary-dark-2 mr-2">
                                      {task.title}
                                    </p>
                                  </div>

                                  {/* <EditIcon
                                    className={`text-primary-main p-1 hover:bg-custom-blue-light rounded-md pen ${classes.penIcon}`}
                                    width="1.5em"
                                    height="1.5em"
                                    onClick={() =>
                                      setEditable((prev) => ({
                                        sectionId: item.id,
                                        taskId: task.taskId,
                                        type: 'task',
                                        text: task.title
                                      }))
                                    }
                                  /> */}
                                  <PopupMenu
                                    targetComp={
                                      <VerticalThreeDot
                                        className={`text-primary-main p-1 hover:bg-custom-blue-light rounded-md pen ${classes.penIcon}`}
                                        width="2em"
                                        height="1.5em"
                                      />
                                    }
                                    menuItems={[
                                      {
                                        label: 'Edit',
                                        method: () =>
                                          setEditable((prev) => ({
                                            sectionId: item.id,
                                            taskId: task.taskId,
                                            type: 'task',
                                            text: task.title
                                          })),
                                        icon: (
                                          <EditIcon className="text-primary-main" />
                                        )
                                      },
                                      {
                                        label: 'Delete',
                                        method: () =>
                                          deleteSection(
                                            'task',
                                            sectionIndex,
                                            taskIndex
                                          ),
                                        icon: (
                                          <DeleteIcon className="text-primary-main" />
                                        )
                                      }
                                    ]}
                                  />
                                </div>
                              )}
                            </>
                          ))}
                          <form
                            onSubmit={handleTaskData}
                            className="relative flex items-center pl-8 border-b"
                          >
                            <AddIcon
                              className="absolute text-primary-main"
                              width="0.8em"
                              height="0.8em"
                            />
                            <input
                              type="text"
                              placeholder="Add new task"
                              value={
                                task.index === sectionIndex ? task.taskName : ''
                              }
                              onChange={(e) => handleTaskInput(e, sectionIndex)}
                              autoFocus
                              className="pl-4 bg-transparent p-2 w-full text-sm template"
                              onBlur={handleTaskData}
                            />
                          </form>
                        </div>
                      )}
                    </div>
                  ))}
                  <form
                    onSubmit={handleSectionData}
                    className="relative flex items-center pl-4"
                  >
                    <AddIcon
                      className="absolute text-primary-main"
                      width="0.8em"
                      height="0.8em"
                    />
                    <input
                      type="text"
                      placeholder="Add new section"
                      value={sectionName}
                      className="pl-4 template bg-transparent p-2 w-full text-sm"
                      onChange={handleSectionInput}
                      onBlur={handleSectionData}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-center items-center h-full w-full bg-pink-100 text-center">
            <CreateTemplateRightView />
          </div> */}
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton onClick={handleSave} loading={loading}>
          {tempData._id ? 'Update' : 'Save'}
        </LoadingButton>
        <Button variant="outlined" onClick={handleCloseModal}>
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default CreateTemplateModal
