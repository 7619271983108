import React, { useContext, useState, useEffect } from 'react'
// import { v4 as uuidv4 } from 'uuid'
import SectionHeader from './SectionHeader'
import { ONBOARDING, OnboardingContext } from '../onboardingReducer'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CloseIcon from '@material-ui/icons/Close'
import onboardingStyles from '../onboardingStyles'
import ObjectID from 'bson-objectid'
import { Button } from '@material-ui/core'

const Instructions = (props) => {
  const { id: sectionId, sectionHeading, description, fields } = props

  return (
    <div>
      <SectionHeader
        sectionHeading={sectionHeading}
        description={description}
        sectionId={sectionId}
      />
      <div>
        <InstructionFields sectionId={sectionId} fields={fields} />
      </div>
    </div>
  )
}

const InstructionFields = ({ sectionId, fields }) => {
  const { onboardingDispatch } = useContext(OnboardingContext)
  const [newInstruction, setNewInstruction] = useState('')

  const handleAddInstruction = (e) => {
    e.preventDefault()
    onboardingDispatch({
      type: ONBOARDING.ADD_INSTRUCTIONS_Field,
      payload: {
        sectionId,
        value: {
          id: ObjectID().toString(),
          text: newInstruction
        }
      }
    })
    setNewInstruction('')
  }

  const handleInstructionChange = (e) => {
    setNewInstruction(e.target.value)
  }

  return (
    <div style={{ marginLeft: '2rem' }}>
      <ul
        className="text-sm text-gray-700 leading-6 mb-4"
        style={{ listStyle: 'disc' }}
      >
        {fields.map((item) => (
          <SingleInstruction key={item.id} item={item} sectionId={sectionId} />
        ))}
      </ul>
      <form onSubmit={handleAddInstruction} className="mt-6">
        <div className="flex">
          <input
            value={newInstruction}
            onChange={handleInstructionChange}
            type="text"
            placeholder="start writing..."
            autoFocus
            className="w-2/3 text-sm"
          />
          <Button onClick={handleAddInstruction} size="small">
            Add
          </Button>
        </div>
      </form>
    </div>
  )
}

const SingleInstruction = ({ item, sectionId }) => {
  const classes = onboardingStyles()
  const [instruction, setInstruction] = useState('')
  const [edit, setEdit] = useState(false)
  const { onboardingDispatch } = useContext(OnboardingContext)

  useEffect(() => {
    setInstruction(item.text)
  }, [item])

  const editInstruction = () => {
    setEdit(true)
  }

  const handleSaveInstruction = (e) => {
    setEdit(false)
    // Props SectionId, fieldId, value
    onboardingDispatch({
      type: ONBOARDING.UPDATE_INSTRUCTIONS_Field,
      payload: {
        sectionId,
        fieldId: item.id,
        value: e.target.value
      }
    })
  }

  const handleInstructionChange = (e) => {
    setInstruction(e.target.value)
  }

  const deleteInstruction = (e) => {
    onboardingDispatch({
      type: ONBOARDING.REMOVE_INTAKE_FIELD,
      payload: {
        sectionId,
        fieldId: item.id
      }
    })
  }

  return (
    <>
      {!edit ? (
        <li key={item.id} className={`mb-4 ${classes.instruction}`}>
          <div className="flex items-start">
            <span className="mr-auto">{instruction}</span>
            <button className="ml-4 mr-4" onClick={editInstruction}>
              <EditOutlinedIcon fontSize="small" />
            </button>
            <button onClick={deleteInstruction}>
              <CloseIcon fontSize="small" />
            </button>
          </div>
        </li>
      ) : (
        <textarea
          value={instruction}
          autoFocus
          onBlur={handleSaveInstruction}
          className="w-full"
          onChange={handleInstructionChange}
        />
      )}
    </>
  )
}

export default Instructions
