import axios from 'axios'
import { CLIENT } from 'thunks/addClient/reducers'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { errorMessages, userRoles } from 'utils'
import { TEAMMATES } from './reducers'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { storeToken } from 'utils/userLogin'
import { LOGIN } from '../login/reduers'
import { createDefaultWidgets } from 'thunks/widget/actions'
import { PULSE } from 'thunks/pulse/reducer'

const teammateWidgets = [
  {
    title: 'projects',
    attributes: { column_position: 0, row_position: 0, width: 12, height: 8 }
  },
  {
    title: 'tasks',
    attributes: { column_position: 0, row_position: 8, width: 12, height: 8 }
  }
]

/**
 * Invite a user by email
 * @param {String} email User email
 * @param {Function} callback callback function
 */
export const inviteTeammate = async (email, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/user/inviteteammate',
      data: {
        email
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Re Invite User Teammate
 * @param {String} id teammate id
 * @param {Function} callback callback function
 */
export const reInviteTeammate = async (teammateId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/user/teammate/resend/invitation/${teammateId}`
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Add a new teammate
 * @param {Object} data Teammate data
 * @param {Function} callback Callback function (res/errorMessage,error status)
 */
export const addTeammate = (data, callback, key) => {
  return async (dispatch) => {
    try {
      // data.password = data.password || 'Test@123'
      data.isTeammate = true

      const res = await axios({
        method: 'POST',
        url: '/user/addTeammate',
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `created a teammate <strong>${res?.data?.name}</strong>`,
          category: `${
            res?.data?.role < userRoles.USER_CLIENT
              ? 'agencyTeammate'
              : 'clientTeammate'
          }`,
          [res?.data?.role < userRoles.USER_CLIENT
            ? 'agencyTeammate'
            : 'clientTeammate']: res.data._id,
          method: 'post'
        })
      )
      if (key !== 'client') {
        dispatch({
          type: TEAMMATES.ADD_ONE_TEAMMATE,
          payload: {
            ...res.data,
            assignedWorkspace: res.data.profile?.assignedWorkspace ?? [],
            verticals: res.data.profile?.verticals ?? []
          }
        })
      }
      // dispatch(fireSuccessToaster('Teammate Added'))
    } catch (err) {
      const error = err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      callback(error, true)
      dispatch(fireErrorToaster(error))
      dispatch({
        type: TEAMMATES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Fetches all teammates of user
 * @param {Function} callback Callback function to
 */
export const fetchTeammateOfCurrentUser = (callback) => {
  return async (dispatch, getState) => {
    const state = getState()

    try {
      const res = await axios({
        method: 'GET',
        url: '/user/teammate'
      })
      const filteredResponse = res.data.filter(
        (item) => item._id !== state.me.data._id
      )
      if (callback) callback(filteredResponse, false)
      dispatch({ type: TEAMMATES.FETCHED, payload: filteredResponse })
      dispatch({
        type: PULSE.CREATE_USERS_PULSE,
        payload: filteredResponse.map((item) => ({
          _id: item._id,
          lastActive: item.lastActive
        }))
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TEAMMATES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Delete a teammate
 * @param {String} id User id of deleting teammate
 * @param {Function} callback Callback function (err/response, err status)
 */
export const deleteTeammate = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/user/${id}`
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `deleted a ${
            res?.data?.role < userRoles.USER_CLIENT ? 'teammate' : 'client user'
          } <strong>${res?.data?.name}</strong>`,
          category: `${
            res?.data?.role < userRoles.USER_CLIENT
              ? 'agencyTeammate'
              : 'clientTeammate'
          }`,
          [res?.data?.role < userRoles.USER_CLIENT
            ? 'agencyTeammate'
            : 'clientTeammate']: res.data._id,
          method: 'delete'
        })
      )
      dispatch({ type: TEAMMATES.DELETE_ONE_TEAMMATE, payload: res.data })
      dispatch({
        type: WORKSPACES.UPDATE_MANY_WORKSPACES,
        payload: { id: res.data._id, key: 'assignedAgencyTeam' }
      })
      dispatch({ type: CLIENT.UPDATE_MANY_CLIENTS, payload: res.data._id })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update teammate's active status.
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateTeammateStatus = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${id}`,
        data
      })
      dispatch({
        type: TEAMMATES.UPDATE_ONE_TEAMMATE,
        payload: { _id: res.data._id, isActive: res.data.isActive }
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update agency teammate.
 * @param {String} id teammate id
 * @param {Object} data workspaces id
 * @param {Function} callback  callback function
 */

export const updateTeammate = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/agencyprofile/agency/${id}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `updated a teammate <strong>${res?.data?.name}</strong>`,
          category: `${
            res?.data?.role < userRoles.USER_CLIENT
              ? 'agencyTeammate'
              : 'clientTeammate'
          }`,
          [res?.data?.role < userRoles.USER_CLIENT
            ? 'agencyTeammate'
            : 'clientTeammate']: res.data._id,
          method: 'put'
        })
      )
      dispatch({ type: TEAMMATES.UPDATE_ONE_TEAMMATE, payload: res.data })
    } catch (err) {
      // dispatch({type: , payload:err.response.data.message})
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Approve teammate signup request.
 * @param {String} id teammate id
 * @param {Object} data {status: "approved"}
 * @param {Function} callback  callback function
 */
export const approveSignupRequest = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/agencyprofile/agency/${id}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TEAMMATES.APPROVE_SIGNUP_REQUEST, payload: res.data })
    } catch (err) {
      // dispatch({type: , payload:err.response.data.message})
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
/**
 * Reject teammate signup request.
 * @param {String} id teammate id
 * @param {Object} data workspaces id
 * @param {Function} callback  callback function
 */
export const rejectSignupRequest = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/user/${id}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TEAMMATES.REJECT_SIGNUP_REQUEST, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update client teammate.
 * @param {String} id teammate id
 * @param {Object} data workspaces id
 * @param {Function} callback  callback function
 */

export const updateClientTeammate = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/clientprofile/client/${id}`,
        data
      })
      dispatch({ type: TEAMMATES.UPDATE_ONE_TEAMMATE, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({type: , payload:err.response.data.message})
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch all project's analytics
 * @param {Object} data name, email, permission, password, agencyOwner
 * @param {Function} callback
 */
export const teammateSignup = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/user/addTeammate/public',
        data
      })

      storeToken(res.data.login.token)
      dispatch({ type: LOGIN.LOGGED_IN })
      callback(res.data)
    } catch (err) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}
