const fileToDataURL = (file) => {
  const reader = new FileReader()

  return new Promise(function (resolve, reject) {
    reader.onload = function (event) {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export default fileToDataURL
