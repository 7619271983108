import { useState, useEffect, useMemo } from 'react'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { fetchUserTimesheets } from 'thunks/timesheets/action'
import { userRoles, getRoleBadge } from 'utils'
import { useHistory } from 'react-router-dom'
import { agencyRoleLabel, getUserPath } from 'utils/userRoles'
import { useSelector } from 'react-redux'
import moment from 'moment'
import SelectedUserTimesheet from './SelectedUserTimesheet'
import getTimeString from './TimesheetFunctions'
import Pill from 'global/globalComponents/Pill/Pill'

const UsersTimesheets = ({
  loading,
  timesheetData,
  selectedDays,
  startTime,
  endTime,
  setDownloadData
}) => {
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const teamVerticals = useSelector(state => state.teamVerticals.data)
  const [openSelectedUser, setOpenSelectedUser] = useState({
    open: false,
    data: {}
  })

  const userVertical = user => {
    if (user.verticals?.length) {
      const vertical = teamVerticals.find(el => el._id === user.verticals[0])
      if (vertical) {
        return <Pill variant="pink">{vertical.name}</Pill>
      } else {
        return null
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    const downloadedData = timesheetData
      .filter(el => el.user !== null)
      .map((item, index) => ({
        Name: item.user.name,
        Permission: agencyRoleLabel[item.user.role],
        'Total Time': getTimeOfProject(
          item.data?.filter(
            time =>
              time.start >= new Date(startTime).getTime() &&
              time.start <= new Date(endTime).getTime()
          )
        ),
        ...timesheetDataFormat(item)
      }))
    setDownloadData(prev => ({ ...prev, data: downloadedData }))
  }, [timesheetData])

  const timesheetDataFormat = item => {
    let data = {}
    selectedDays.forEach(
      day => (data[day] = getTimeByDay(item.data, day) || '')
    )
    return data
  }

  const getTimeOfProject = data => {
    const time = data.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  const getTimeByDay = (data, day) => {
    const dayData = data.filter(el => new Date(el.start).toDateString() === day)
    const time = dayData.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  const handleSelectedUser = user => {
    history.push(
      `${getUserPath(meData.role)}/resource-management/timesheets/${moment(
        startTime
      ).format('YYYY-MM-DD')}/${user._id}`
    )
    // setOpenSelectedUser((prev) => ({
    //   ...prev,
    //   open: true,
    //   data: user
    // }))
  }

  const closeSelecteduser = () => {
    setOpenSelectedUser({
      open: false,
      data: {}
    })
  }

  return (
    <>
      <TableContainer mb={2} shadow="none">
        <Thead>
          <Th className="w-44 whitespace-nowrap">
            Users ({timesheetData.length})
          </Th>
          <Th center className="capitalize">
            Total Time
          </Th>
          {selectedDays?.map(day => (
            <Th key={day} center>
              <span className="block font-medium capitalize">
                {day.split(' ')[0]}
              </span>
              <span className="font-base tect-xs text-gray-500 capitalize">
                {day.split(' ').slice(1, 3).join(' ')}
              </span>
            </Th>
          ))}
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={9} />
          ) : timesheetData.length > 0 ? (
            timesheetData
              .filter(el => el.user !== null)
              .map((item, index) => (
                <Tr key={index}>
                  <Td
                    className="px-6 py-4 whitespace-nowrap text-gray-900 cursor-pointer"
                    onClick={() => handleSelectedUser(item.user)}
                  >
                    <div className="flex flex-col">
                      <div className="flex items-center leading-3">
                        <span className="mr-2 text-custom-table-primaryText">
                          {item.user?.name}
                        </span>
                        {item.user.role === userRoles.USER_AGENCY
                          ? getRoleBadge(item.user?.role)
                          : userVertical(item.user)}
                      </div>
                      <span className="text-gray-500 text-xs">
                        {item.user?.email}
                      </span>
                    </div>
                  </Td>
                  <Td className="text-center text-xs mt-1">
                    {getTimeOfProject(
                      item.data?.filter(
                        time =>
                          time.start >= new Date(startTime).getTime() &&
                          time.start <= new Date(endTime).getTime()
                      )
                    )}
                  </Td>
                  {selectedDays.map(day => (
                    <Td className="text-center text-xs" key={day}>
                      {getTimeByDay(item.data, day) || '-'}
                    </Td>
                  ))}
                </Tr>
              ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                No Data Available
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {openSelectedUser.open && (
        <SelectedUserTimesheet
          user={openSelectedUser.data}
          startTime={startTime}
          onClose={closeSelecteduser}
        />
      )}
    </>
  )
}

export default UsersTimesheets
