import { useContext, useEffect, useState } from 'react'
import {
  CustomTooltip,
  CheckBoxField,
  LoadingButton,
  RoundedTextInput,
  CustomPopper
} from 'global/globalComponents'
import {
  Button,
  Popover,
  TextField,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  updateCalendarEvents,
  deleteCalendarEvents
} from 'thunks/calender/action'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as WatchIcon } from 'static/svg/watch-icon.svg'
import { ReactComponent as TasksIcon } from 'static/svg/tasks.svg'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import format from 'date-fns/format'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import TimeDropdown from 'global/globalComponents/TimeDropdown/TimeDropdown'
import useCalendarFunctions from './useCalendarFunctions'

const EventHandlePopover = ({ anchorEl, data, handleClose }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [eventData, setEventData] = useState({
    title: '',
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    allDay: true
  })
  const [time, setTime] = useState({
    startTime: '',
    endTime: ''
  })
  const [edit, setEdit] = useState(false)
  const [timeAnchorEl, setTimeAnchorEl] = useState({
    anchorEl: null,
    name: 'startTime'
  })
  const {
    setTimeInTwelveHoursFormat,
    setTimeInTwentyFourHoursFormat,
    validateTime
  } = useCalendarFunctions()

  useEffect(() => {
    if (anchorEl) {
      setEventData(prev => ({
        title: data.title,
        start: new Date(data.start).toISOString(),
        end: new Date(data.end).toISOString(),
        allDay: data.allDay
      }))
      if (!data.allDay) {
        setTime({
          startTime: setTimeInTwelveHoursFormat(data.start),
          endTime: setTimeInTwelveHoursFormat(data.end)
        })
      }
    }
  }, [data, anchorEl])

  const handleUpdateEvent = e => {
    e.preventDefault()
    if (eventData.title.trim() === '')
      return dispatch(fireErrorToaster('Title is required'))

    if (eventData.allDay) {
      eventData.start = new Date(new Date(eventData.start).setHours(0, 0, 0, 0))
      eventData.end = new Date(new Date(eventData.end).setHours(23, 59, 59, 0))
    } else {
      if (validateTime(time.startTime) && validateTime(time.endTime)) {
        eventData.start = new Date(
          `${new Date(
            eventData.start
          ).toDateString()} ${setTimeInTwentyFourHoursFormat(time.startTime)}`
        ).toISOString()
        eventData.end = new Date(
          `${new Date(
            eventData.end
          ).toDateString()} ${setTimeInTwentyFourHoursFormat(time.endTime)}`
        ).toISOString()
      } else {
        return
      }
    }

    if (
      new Date(eventData.start).getTime() > new Date(eventData.end).getTime()
    ) {
      console.log(
        new Date(eventData.start).toTimeString(),
        'start time++++',
        new Date(eventData.end).toTimeString()
      )
      return dispatch(
        fireErrorToaster("Event's end time must be greater than start Time")
      )
    }

    setLoading(true)
    dispatch(updateCalendarEvents(data._id, eventData, updateEventCallback))
  }

  const handleDeleteEvent = () => {
    dispatch(
      deleteCalendarEvents(data._id, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster('Event deleted successfully'))
          handleCloseModal()
        }
      })
    )
  }

  const updateEventCallback = (res, err) => {
    setLoading(false)
    setEdit(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Event updated successfully'))
      handleCloseModal()
    }
  }

  const handleTitleChange = e => {
    setEventData(prev => ({ ...prev, title: e.target.value }))
  }

  const handleDateChange = (date, key) => {
    if (
      key === 'start' &&
      new Date(date).getTime() > new Date(eventData.end).getTime()
    ) {
      const timeDiff =
        new Date(eventData.end).getTime() - new Date(eventData.start).getTime()
      setEventData(prev => ({
        ...prev,
        start: date.toISOString(),
        end: new Date(new Date(date).getTime() + timeDiff).toISOString()
      }))
    } else {
      setEventData(prev => ({ ...prev, [key]: date.toISOString() }))
    }
  }

  const handleAllDayEvent = () => {
    setEventData(prev => ({ ...prev, allDay: !prev.allDay }))
    if (eventData.allDay) {
      setTime({
        startTime: setTimeInTwelveHoursFormat(eventData.start),
        endTime: setTimeInTwelveHoursFormat(
          new Date(new Date(eventData.end).setHours(23, 59, 59))
        )
      })
    }
  }

  const handleTimeInput = e => {
    const { value, name } = e.target
    setTime(prev => ({ ...prev, [name]: value }))
  }

  const handleSelectTime = (value, name) => {
    setTime(prev => ({ ...prev, [name]: value }))
    toggleTimePopup()
  }

  const toggleTimePopup = e => {
    e?.stopPropagation()
    if (!timeAnchorEl.anchorEl) {
      setTimeAnchorEl({ anchorEl: e.currentTarget, name: e?.target?.name })
    } else {
      setTimeAnchorEl({ anchorEl: null, name: 'startTime' })
    }
  }

  const handleCloseModal = () => {
    handleClose()
    setEventData({
      title: '',
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      allDay: true
    })
    setEdit(false)
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      classes={{ paper: classes.paper }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <div className="p-4" style={{ minWidth: '280px', maxWidth: '330px' }}>
        <header className="flex justify-end items-center space-x-2">
          {!['projects', 'tasks'].includes(data.eventType) && (
            <CustomTooltip title="Edit Event">
              <IconButton onClick={() => setEdit(true)} size="small">
                <EditIcon />
              </IconButton>
            </CustomTooltip>
          )}
          {!['projects', 'tasks'].includes(data.eventType) && (
            <CustomTooltip title="Delete Event">
              <IconButton onClick={handleDeleteEvent} size="small">
                <DeleteIcon />
              </IconButton>
            </CustomTooltip>
          )}
          <CustomTooltip title="Close">
            <IconButton onClick={handleCloseModal} size="small">
              <CloseIcon size="small" fontSize="small" />
            </IconButton>
          </CustomTooltip>
        </header>

        {!edit ? (
          <div className="py-2">
            <div>
              <p className="text-sm font-semibold text-gray-900">
                {eventData.title}
              </p>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <WatchIcon className="h-4 w-4 text-primary-main" />
              <div className="text-smaller font-medium">
                {eventData.allDay &&
                new Date(eventData.start).toDateString() ===
                  new Date(eventData.end).toDateString() ? (
                  format(new Date(eventData.start), 'd MMMM, yyyy')
                ) : (
                  <div className="flex items-center ">
                    <p>
                      {format(new Date(eventData.start), 'd MMM, yy h:mmaa')}
                    </p>
                    <p className="px-1">-</p>
                    <p>{format(new Date(eventData.end), 'd MMM, yy h:mmaa')}</p>
                  </div>
                )}
              </div>
            </div>
            {['projects', 'tasks'].includes(data.eventType) && (
              <div className="flex items-center space-x-2 mt-2">
                <TasksIcon className="h-4 w-4 text-primary-main" />
                <p className="text-smaller font-medium">
                  {data.eventType === 'tasks' ? 'My Tasks' : 'My Projects'}
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="pt-4">
            <div>
              <TextField
                value={eventData.title}
                onChange={handleTitleChange}
                autoFocus
                size="small"
                className="w-full "
                placeholder="Enter Title"
              />
              <div
                className={`${
                  eventData.allDay
                    ? 'flex items-center space-x-2 text-smaller justify-between'
                    : ''
                }`}
              >
                <div className="flex items-center space-x-2 text-xs mt-4">
                  {!eventData.allDay && (
                    <span className="text-xs font-medium w-7">Start</span>
                  )}
                  <div className="flex bg-custom-gray-light-4">
                    <DatePicker
                      onChange={date => handleDateChange(date, 'start')}
                      selected={
                        eventData.start ? new Date(eventData.start) : null
                      }
                      customInput={
                        eventData.start ? (
                          <input
                            className=" py-1 px-2 bg-custom-gray-light-4"
                            style={{
                              width: '100px'
                            }}
                          />
                        ) : (
                          <CalenderIcon
                            className="text-base text-gray-600"
                            width="2.5em"
                          />
                        )
                      }
                      placeholderText="Select Date"
                      dateFormat="d MMM, yyyy"
                    />
                    {!eventData.allDay && (
                      <input
                        name="startTime"
                        onChange={handleTimeInput}
                        onClick={toggleTimePopup}
                        value={time.startTime}
                        className="w-20 text-xs py-1 px-2 bg-custom-gray-light-4"
                      />
                    )}
                  </div>
                </div>
                {eventData.allDay && (
                  <span className="font-medium mt-4">to</span>
                )}
                <div className="flex items-center space-x-2 text-xs mt-4">
                  {!eventData.allDay && (
                    <span className="text-xs font-medium w-7">End</span>
                  )}
                  <div className="flex bg-custom-gray-light-4">
                    <DatePicker
                      onChange={date => handleDateChange(date, 'end')}
                      selected={eventData.end ? new Date(eventData.end) : null}
                      customInput={
                        eventData.end ? (
                          <input
                            className=" py-1 px-2 bg-custom-gray-light-4"
                            style={{
                              width: '100px'
                            }}
                          />
                        ) : (
                          <CalenderIcon
                            className="text-base text-gray-600"
                            width="2.5em"
                          />
                        )
                      }
                      minDate={new Date(eventData.start)}
                      placeholderText="Select Date"
                      dateFormat="d MMM, yyyy"
                    />
                    {!eventData.allDay && (
                      <input
                        name="endTime"
                        onChange={handleTimeInput}
                        value={time.endTime}
                        onClick={toggleTimePopup}
                        className="w-20 text-xs py-1 px-2 bg-custom-gray-light-4"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <CheckBoxField
                  name="All Date"
                  label="All Day"
                  onChange={handleAllDayEvent}
                  checked={eventData.allDay}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-2 mt-2">
              <Button
                variant="outlined"
                size="small"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                size="small"
                loading={loading}
                onClick={handleUpdateEvent}
              >
                Update
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
      <TimeDropdown
        anchorEl={timeAnchorEl.anchorEl}
        onClose={toggleTimePopup}
        handleSelectTime={handleSelectTime}
        name={timeAnchorEl.name}
      />
    </Popover>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      overflow: 'visible',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)'
    }
  })
)

export default EventHandlePopover
