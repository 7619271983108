import { Checkbox, FormControlLabel } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'

function NotificationItem({ notification, handleCheckFields, type }) {
  const handleCheckClick = (e) => {
    handleCheckFields(e.target.checked, notification._id, type)
  }

  return (
    <div>
      <FormControlLabel
        name={notification._id}
        onChange={handleCheckClick}
        checked={notification.notificationType[type]}
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
          />
        }
        disabled={
          notification.disable ||
          (type === 'push' &&
            ['62d237dc15578f1088ef1692'].includes(notification._id))
        }
      />
    </div>
  )
}

export default NotificationItem
