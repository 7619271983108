import { useEffect, useRef, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  // Tab,
  // Tabs,
  TextField
} from '@material-ui/core'
// import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  createService,
  // updateArchiveService,
  updateService
} from 'thunks/service/actions'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
// import CloseButton from 'global/globalComponents/CloseButton/CloseButton'
import Select from 'react-select'
import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
// import InfoStrip from 'global/globalComponents/InfoStrip/InfoStrip'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import currencyObj from '../../static/Currency/Currency'
// import { arrayMove } from 'react-sortable-hoc'
import { fileToDataUrl } from 'utils'
// import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import userServiceStyles from './userServiceStyles'

const options = [
  {
    label: 'Monthly',
    value: { interval: 'month', interval_count: 1, trial_period_days: 0 }
  },
  {
    label: 'Quarterly',
    value: { interval: 'month', interval_count: 3, trial_period_days: 0 }
  },
  {
    label: 'Semi-Annually',
    value: { interval: 'month', interval_count: 6, trial_period_days: 0 }
  },
  {
    label: 'Yearly',
    value: { interval: 'year', interval_count: 1, trial_period_days: 0 }
  }
]

const getIntervalLabel = (interval, intervalCount) => {
  if (interval === 'year') return 'Yearly'
  return intervalCount === 1
    ? 'Monthly'
    : intervalCount === 3
    ? 'Quarterly'
    : 'Semi-Annually'
}

export default function NewService({
  open,
  onClose,
  data = null,
  serviceCategories
}) {
  const classes = userServiceStyles()
  const dispatch = useDispatch()
  const defaultCurrency = useSelector(state => state.me.data.defaultCurrency)
  // const isStripeConnected = Boolean(defaultCurrency)
  const [radioServiceType, setRadioServiceType] = useState('0')
  const [offering, setOffering] = useState('')
  const [loading, setLoading] = useState(false)
  const descriptionRef = useRef(null)
  const [recurring, setRecurring] = useState(null)
  // const [selectedTab, setSelectedTab] = useState(0)

  const [serviceData, setServiceData] = useState({
    // serviceType: data?.serviceType ?? 'stripe',
    name: data?.name ?? '',
    description: data?.description ?? '',
    image: data?.image ?? null,
    offerings: data?.offerings ?? [],
    isRecurring: data?.isRecurring ?? true,
    displayOnCatalog: data?.displayOnCatalog ?? true,
    isActive: data?.isActive ?? false,
    // freeTrial: 0,
    amount: data?.amount * 0.01 || 0,
    currency: defaultCurrency
      ? defaultCurrency
      : data
      ? data.currency
      : { value: 'usd', label: 'USD', minimum_charge_amount: 0.5 }
    //interval: options[0].value
  })
  const [categoryOptions, setCategoryOptions] = useState([])
  const [selectedCategoryOption, setSelectedCategoryOption] = useState(null)

  useEffect(() => {
    if (data) {
      if (data.isRecurring) {
        setRadioServiceType('0')
        setRecurring({
          label: getIntervalLabel(
            data.recurring.interval,
            data.recurring.interval_count
          ),
          value: data.recurring
        })
      } else {
        setRadioServiceType('1')
      }

      if (data.category) {
        setSelectedCategoryOption({
          label: data.category.name,
          value: data.category._id
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (serviceCategories.length) {
      setCategoryOptions(
        serviceCategories.map(item => ({ label: item.name, value: item._id }))
      )
    }
  }, [serviceCategories])

  // const clearFieldsData = (type) => {
  //   setServiceData({
  //     // isStripePlan: me ? true : false,
  //     serviceType: type,
  //     name: '',
  //     description: '',
  //     image: null,
  //     offerings: [],
  //     isRecurring: type === 'stripe',
  //     displayOnCatalog: true,
  //     // freeTrial: 0,
  //     amount: 0,
  //     currency: defaultCurrency
  //       ? defaultCurrency
  //       : data
  //       ? data.currency
  //       : { value: 'usd', label: 'USD', minimumChargeAmount: 0.5 }
  //     //interval: options[0].value
  //   })

  //   setRecurring(null)
  //   setRadioServiceType(type === 'other' ? '1' : '0')
  // }

  // const handleServiceTypeChange = (e) => {
  //   const type = e.currentTarget.dataset.type
  //   clearFieldsData(type)
  // }

  // useEffect(() => {
  //   let arr = Object.keys(currency).map((el) => ({
  //     value: el.toLowerCase(),
  //     label: el,
  //     minimumChargeAmount: currency[el]?.minimum_charge_amount ?? 1
  //   }))
  //   setCurrecyOptions(arr)
  // }, [])

  const handleRadioChange = event => {
    const value = event.target.value
    setRadioServiceType(value)
    setServiceData(prev => ({
      ...prev,
      isRecurring: value === '0' ? true : false
    }))
  }

  const imageLoadHandler = e => {
    const file = e.target.files[0]
    fileToDataUrl(file).then(res =>
      setServiceData(prev => ({ ...prev, image: res }))
    )
  }

  const handlePlanChange = e => {
    setRecurring(e)
    // setServiceData((prev) => ({
    //   ...prev,
    //   recurring: e
    // }))
  }

  const handleChange = e => {
    if (e.target.name === 'amount') {
      setServiceData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
    } else {
      setServiceData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
  }

  const handleCurrencyChange = e => {
    setServiceData(prev => ({ ...prev, currency: e }))
  }

  const handleOffering = (e, index) => {
    setServiceData(prev => ({
      ...prev,
      offerings: Object.assign([], serviceData.offerings, {
        [index]: e.target.value
      })
    }))
  }

  const handleOfferingChange = e => {
    setOffering(e.target.value)
  }

  const handleAddOffering = e => {
    e.preventDefault()

    if (serviceData.offerings.length >= 5) {
      dispatch(fireErrorToaster('You can add maximum 5 offerings'))
      return
    }

    setServiceData(prev => ({
      ...prev,
      offerings: [...prev.offerings, offering]
    }))

    setOffering('')
  }

  const handleDeleteOfferings = value => {
    const arr = serviceData.offerings.filter((item, index) => index !== value)
    setServiceData(prev => ({
      ...prev,
      offerings: arr
    }))
  }

  // const offeringChange = () => {
  //   if (offerings.length === 0 || serviceData.offerings.length > 4) {
  //     offeringInputRef.current.focus()
  //   } else {
  //     setServiceData((prev) => ({
  //       ...prev,
  //       offerings: [...prev.offerings, offerings]
  //     }))
  //     // setOfferings('')
  //   }
  // }

  const handleSaveServiceCallback = (res, error) => {
    onClose()
    if (error) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Service Created Successfully'))
    }
  }

  const addNewServiceHandler = () => {
    const currency =
      typeof serviceData.currency === 'object'
        ? serviceData.currency.value.toLowerCase()
        : serviceData.currency.toLowerCase()

    const minimumChargeAmount =
      currencyObj[currency.toUpperCase()].minimum_charge_amount || 1

    if (!serviceData.name || !serviceData.description) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    if (!serviceData.currency) {
      return dispatch(fireErrorToaster('Please select currency!'))
    }

    if (serviceData.amount < minimumChargeAmount) {
      return dispatch(
        fireErrorToaster(
          `Amount can not be less than ${minimumChargeAmount} ${
            currencyObj[currency.toUpperCase()].symbol_native
          } `
        )
      )
    }

    if (serviceData.isRecurring && !recurring) {
      return dispatch(fireErrorToaster('Please select interval!'))
    }

    /*
    Filtering serviceData before sending API Req (coverting currency from object to str)
    Here serviceData.currency could either be an object or a string, depending on whether
    the user is connected to the stripe or not. If the user is connected then the serviceData.currency will
    be a string (rupee) else the we'll show a dropdown and that results in an object
    */

    setLoading(true)
    let filteredServiceData = {
      ...serviceData,
      currency
    }

    if (filteredServiceData.isRecurring) {
      filteredServiceData.recurring = recurring.value
    }

    if (selectedCategoryOption) {
      filteredServiceData.category = selectedCategoryOption.value
    }

    dispatch(createService(filteredServiceData, handleSaveServiceCallback))
  }

  const updateServiceHandler = () => {
    if (!serviceData.name || !serviceData.description) {
      dispatch(fireErrorToaster('Please fill all the required fields!'))
      return
    }

    if (serviceData.amount < 1) {
      dispatch(fireErrorToaster('Amount can not be less than unit'))
      return
    }

    setLoading(true)
    let filteredServiceData = {
      ...serviceData,
      amount: serviceData.amount * 100,
      currency:
        typeof serviceData.currency === 'object'
          ? serviceData.currency.value.toLowerCase()
          : serviceData.currency.toLowerCase()
    }

    if (selectedCategoryOption) {
      filteredServiceData.category = selectedCategoryOption.value
    }

    dispatch(
      updateService(data._id, filteredServiceData, handleUpdateServiceCallback)
    )
  }

  const handleUpdateServiceCallback = (res, err) => {
    setLoading(false)
    onClose()
    if (!err) {
      dispatch(fireSuccessToaster('Service Updated Successfully'))
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleFreeTrialChange = e => {
    setRecurring(prev => ({
      ...prev,
      value: { ...prev.value, trial_period_days: parseInt(e.target.value) }
    }))
  }

  // const handleTabChange = (e, newVal) => {
  //   setSelectedTab(newVal)
  //   clearFieldsData(newVal === 0 ? 'stripe' : 'other')
  // }

  // const handleArchiveAction = () => {
  //   if (data.planId) {
  //     setLoading('archive')
  //     dispatch(
  //       updateArchiveService(
  //         data.planId,
  //         { isActive: !serviceData.isActive },
  //         archiveServiceCallback
  //       )
  //     )
  //   } else {
  //     dispatch(fireErrorToaster('Non stripe service  cannot be archived'))
  //   }
  // }

  // const archiveServiceCallback = (res, err) => {
  //   setLoading('')

  //   if (!err) {
  //     setServiceData(res)
  //   }
  // }

  const handleCategoryChange = option => {
    setSelectedCategoryOption(option)
  }

  return (
    <div>
      <CustomModal size="large" open={open} handleClose={onClose}>
        <CustomModalHeader
          heading={data ? 'Edit Service' : 'Create a New Service'}
        />

        <CustomModalBody>
          <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-6">
            <div>
              <FormLabel htmlFor="name" required>
                Name
              </FormLabel>

              <TextField
                name="name"
                value={serviceData.name}
                onChange={handleChange}
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder="Enter Service Name"
                id="name"
              />
            </div>
            <div>
              <FormLabel className="text-sm">Add image/cover icon</FormLabel>
              <div className="flex flex-row items-center gap-4">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="user-image-uploadbtn"
                  multiple={false}
                  type="file"
                  onChange={imageLoadHandler}
                />

                <FormLabel htmlFor="user-image-uploadbtn">
                  <Button component="span" size="small">
                    Choose File
                  </Button>
                </FormLabel>
                <img
                  src={
                    serviceData.image
                      ? serviceData.image
                      : 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'
                  }
                  alt="service icon"
                  className="ml-4 outline-none h-14 w-14 rounded-full object-cover shadow-md"
                  height="60px"
                  width="60px"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-6 mt-4">
            <div>
              <FormLabel required htmlFor="description">
                Description
              </FormLabel>
              <TextField
                name="description"
                value={serviceData.description}
                onChange={handleChange}
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder="Enter Description"
                id="description"
                ref={descriptionRef}
                multiline={true}
              />
            </div>
            <div>
              <FormLabel htmlFor="offerings">
                Add Offerings{' '}
                <span className="font-normal ml-1 text-xs">(Maximum 5)</span>
              </FormLabel>
              <form onSubmit={handleAddOffering}>
                <div className="flex items-center justify-between gap-2">
                  <TextField
                    name="offering"
                    value={offering}
                    onChange={handleOfferingChange}
                    className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                    placeholder="Enter Service Offerings"
                    id="offerings"
                  />
                  <Button
                    type="submit"
                    disabled={offering.trim().length === 0}
                    size="small"
                  >
                    Add
                  </Button>
                </div>
              </form>
              {/* <div
                    className="flex flex-row h-6 w-auto   border-gray-50 float-right mt-1 cursor-pointer"
                    onClick={offeringChange}
                  >
                    <AddIcon
                      color="primary"
                      style={{
                        fontSize: '18px',
                        alignSelf: 'center'
                      }}
                    />
                    <p className="text-sm text-primary-main self-center">
                      Add More
                    </p>
                  </div> */}
            </div>
          </div>
          {/* <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-6 mt-4">
            {/* ============== Categories ============= */}
          {/* <div>
              <FormLabel>Select Category</FormLabel>

              <Select
                styles={reactSelectCustomStyles}
                options={categoryOptions}
                onChange={handleCategoryChange}
                value={selectedCategoryOption}
                maxMenuHeight={120}
                placeholder="Select Category"
              />
            </div>
          </div> */}

          <div className="grid grid-flow-col grid-cols-2 grid-rows-1 gap-6 mt-6">
            <div>
              <div>
                <FormLabel>Select Category</FormLabel>

                <Select
                  styles={reactSelectCustomStyles}
                  options={categoryOptions}
                  onChange={handleCategoryChange}
                  value={selectedCategoryOption}
                  maxMenuHeight={120}
                  placeholder="Select Category"
                />
              </div>
              <div className="mt-5">
                <FormLabel required>
                  Service type (one-time or recurring)
                </FormLabel>

                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={radioServiceType}
                  onChange={handleRadioChange}
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value="0"
                    control={
                      <Radio
                        disabled={Boolean(data)}
                        // disabled={serviceData.serviceType !== 'stripe' || data}
                      />
                    }
                    label={<p className="text-smaller lg:text-sm">Recurring</p>}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio disabled={Boolean(data)} />}
                    label={<p className="text-smaller lg:text-sm">One-time</p>}
                  />
                </RadioGroup>

                {serviceData.isRecurring ? (
                  <div className="mt-3">
                    <FormLabel htmlFor="offerings" required>
                      Add Interval
                    </FormLabel>

                    <Select
                      menuPosition={'fixed'}
                      // styles={{
                      //   // Fixes the overlapping problem of the component
                      //   menuPortal: (provided) => ({
                      //     ...provided,
                      //     zIndex: 9999
                      //   })
                      // }}
                      value={recurring}
                      isSearchable={false}
                      onChange={handlePlanChange}
                      placeholder="Billing Period"
                      options={options}
                      className={classes.selectStyle}
                      styles={reactSelectCustomStyles}
                      isDisabled={Boolean(data)} //data true means update service modal
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex flex-row mt-5 gap-3">
                <SelectCurrency
                  defaultCurrency={defaultCurrency}
                  serviceData={serviceData}
                  handleCurrencyChange={handleCurrencyChange}
                  isUpdating={Boolean(data)}
                />
                <div>
                  <FormLabel htmlFor="amount">Amount</FormLabel>
                  <TextField
                    name="amount"
                    value={serviceData.amount}
                    onChange={handleChange}
                    type="number"
                    className="hover:border-blue-500 border border-custom-gray-main p-2 rounded-md outline-none w-24"
                    placeholder="Amount"
                    id="amount"
                    // disabled={Boolean(data)}
                  />
                </div>
                {serviceData.isRecurring ? (
                  <div>
                    <FormLabel htmlFor="freeTrial">Free trial Days</FormLabel>
                    <TextField
                      name="freeTrial"
                      value={recurring?.trial_period_days}
                      type="number"
                      onChange={handleFreeTrialChange}
                      className="hover:border-blue-500 border border-custom-gray-main p-2 rounded-md outline-none w-24"
                      placeholder="Trial days"
                      id="freeTrial"
                      disabled={!recurring || Boolean(data)}
                    />
                  </div>
                ) : null}

                {radioServiceType == '1' ? (
                  <p className="self-end text-custom-gray-main mt-3 ml-2 text-xs">
                    (Billed only once leave empty to disable)
                  </p>
                ) : null}
              </div>
              <div className="flex items-center mt-3 -ml-2">
                <Checkbox
                  checked={serviceData.displayOnCatalog}
                  color="primary"
                  id="showInCatalog"
                  onChange={e =>
                    setServiceData(prev => ({
                      ...prev,
                      displayOnCatalog: e.target.checked
                    }))
                  }
                />
                <FormLabel
                  htmlFor="showInCatalog"
                  className="self-center"
                  style={{ padding: 0 }}
                >
                  Show in Catalog
                </FormLabel>
                <span className="flex items-center ml-2">
                  <QuestionMarkTooltip
                    text="This service will be displayed to the Clients"
                    direction="right"
                    info={true}
                  />
                </span>
              </div>
            </div>
            <div className="mt-3">
              {serviceData.offerings?.map((item, index) => (
                <div
                  key={index}
                  className="w-full border border-custom-gray-main p-2 hover:border-primary-main focus-within:border-primary-main rounded-md outline-none flex flex-row justify-between mt-3"
                >
                  <input
                    name="offerings"
                    onChange={e => handleOffering(e, index)}
                    value={item}
                    className="text-sm flex-grow pr-4"
                    id="offerings"
                  />
                  <button
                    onClick={() => handleDeleteOfferings(index)}
                    className="leading-3"
                  >
                    <CloseIcon
                      className="cursor-pointer text-gray-600"
                      fontSize="small"
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </CustomModalBody>
        <CustomModalFooter>
          {/* <div className="flex-grow flex items-center">
            {!data &&
              serviceData.serviceType === 'stripe' &&
              (!defaultCurrency ? (
                <p className="text-sm text-yellow-700">
                  Please connect your Stripe account.
                </p>
              ) : (
                <InfoStrip transparent>
                  By default, all the services will be created inside your
                  Stripe account.
                </InfoStrip>
              ))}
          </div> */}

          <div className="flex items-center gap-4">
            {data ? (
              <LoadingButton
                onClick={updateServiceHandler}
                loading={loading}
                disabled={loading}
              >
                Save Changes
              </LoadingButton>
            ) : (
              <LoadingButton onClick={addNewServiceHandler} loading={loading}>
                Create
              </LoadingButton>
            )}
            <Button variant="outlined" onClick={onClose}>
              cancel
            </Button>
          </div>
        </CustomModalFooter>
      </CustomModal>
    </div>
  )
}

const SelectCurrency = ({
  defaultCurrency,
  handleCurrencyChange,
  serviceData,
  isUpdating
}) => {
  const [currencyOptions, setCurrencyOptions] = useState([])

  useEffect(() => {
    let arr = Object.keys(currencyObj).map(el => ({
      value: el.toLowerCase(),
      label: el,
      minimumChargeAmount: currencyObj[el]?.minimum_charge_amount ?? 1
    }))
    setCurrencyOptions(arr)
  }, [])

  return (
    <>
      <div>
        <FormLabel htmlFor="currency">Currency</FormLabel>
        {defaultCurrency ? (
          <TextField
            name="currency"
            value={currencyObj[defaultCurrency.toUpperCase()].symbol_native}
            disabled
            type="text"
            className="border border-custom-gray-main p-2 rounded-md outline-none w-24"
          />
        ) : isUpdating ? (
          <TextField
            name="currency"
            value={
              currencyObj[serviceData?.currency.toUpperCase()].symbol_native
            }
            disabled
            type="text"
            className="border border-custom-gray-main p-2 rounded-md outline-none w-24"
          />
        ) : (
          <div className="w-28">
            <Select
              styles={reactSelectCustomStyles}
              options={currencyOptions}
              onChange={handleCurrencyChange}
              value={serviceData?.currency}
              maxMenuHeight={120}
            />
          </div>
        )}
      </div>
    </>
  )
}
