import { makeStyles, createStyles } from '@material-ui/core'

const projectStatusUpdateStyles = makeStyles(theme =>
  createStyles({
    drawerPaper: {
      width: '30vw',
      maxWidth: '600px',
      marginTop: theme.custom.header.height,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      '&.drawerOpen': {
        boxShadow: theme.custom.drawerPaper.boxShadow,
        overflow: 'visible'
      }
    },
    rightSection: {
      marginLeft: '230px',
      '@media (min-width: 1284px)': {
        marginLeft: '280px'
      }
    },
    leftSection: {
      width: '230px',
      top: 0,
      bottom: '-1.5rem',
      '@media (min-width: 1284px)': {
        width: '280px'
      }
    },
    header: {
      minHeight: theme.custom.header.height,
      boxShadow: '0px 4px 24px -4px rgba(0, 0, 0, 0.08)',
      zIndex: 9
    },
    popoverRoot: {
      zIndex: 1500
    },
    tooltipPopover: {
      zIndex: 1500,
      position: 'fixed'
    },
    attachmentsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridAutoRows: '150px',
      gridGap: '1rem'
    },

    [theme.breakpoints.down('md')]: {
      attachmentsContainer: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      }
    },

    [theme.breakpoints.down('sm')]: {
      attachmentsContainer: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },

    logo: {
      maxWidth: 110,
      '@media (min-width: 1284px)': {
        maxWidth: 130
      }
    },

    attachmentWrapper: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent',
        transition: 'background-color 150ms ease-in-out'
      },
      '&:hover::before': {
        backgroundColor: 'rgba(0, 0, 0, 0.25)'
      }
    },
    description: {
      outline: 'none',
      fontSize: '13px',
      padding: '0.25rem',
      color: 'rgb(65 75 99)',
      '@media (min-width: 1284px)': {
        fontSize: '14px'
      }
    },
    title: {
      outline: 'none',
      fontSize: '14px',
      fontWeight: 700,
      padding: '0.35rem 0.25rem',
      color: theme.palette.secondary,
      '@media (min-width: 1284px)': {
        fontSize: '16px'
      }
    },
    mainTitle: {
      outline: 'none',
      fontWeight: 500,
      padding: '0.25rem',
      color: '#17191C'
      // '&:hover': {
      //   border: '1px solid rgba(212, 212, 216)'
      // }
    },
    avatarRoot: {
      height: 20,
      fontSize: 11,
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&>div': {
        width: 20,
        height: 20,
        borderWidth: 1,
        fontSize: 11,
        boxShadow: 'inset 0 0 0 1px rgb(0 0 0 / 7%)',
        fontWeight: 300
      }
    },
    row: {
      '&:hover .cross-btn': {
        opacity: 1
      }
    },
    btn: {
      opacity: 0
    }
  })
)

export default projectStatusUpdateStyles
