import axios from 'axios'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { CLIENT } from 'thunks/addClient/reducers'
import { errorMessages } from 'utils'
import { CLIENTCOMPANY } from './reducer'

/**
 * Add one client Company
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const addClientCompany = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/clientcompany',
        data
      })
      if (callback) callback(res, false)
      dispatch({ type: CLIENTCOMPANY.ADD_ONE_CLIENTCOMPANY, payload: res.data })
      if (res.data.clientOwner) {
        dispatch({
          type: CLIENT.UPDATE_CLIENT_COMPANY,
          payload: { clientId: res.data.clientOwner, company: res.data }
        })
      }
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch all clients companies of a user.
 * @param {Function} callback  callback function
 */

export const getClientCompanies = (callback) => {
  return async (dispatch) => {
    dispatch({ type: CLIENTCOMPANY.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/clientcompany/agency'
      })
      if (callback) callback(res.data, false)
      dispatch({ type: CLIENTCOMPANY.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete one client of a user.
 * @param {String} client client Id
 * @param {Function} callback  callback function
 */
export const deleteCompany = (companyId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/clientcompany/${companyId}`
        // data: data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: CLIENTCOMPANY.DELETE_ONE_CLIENTCOMPANY,
        payload: res.data
      })
      if (res.data.clientOwner) {
        dispatch({
          type: CLIENT.UPDATE_CLIENT_COMPANY,
          payload: { clientId: res.data.clientOwner, company: [] }
        })
      }
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update client data.
 * @param {String} client clientId
 * @param {Object} data name,workspaces
 * @param {Function} callback callback
 */

export const updateClientCompany = (companyId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/clientcompany/${companyId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: CLIENTCOMPANY.UPDATE_ONE_CLIENTCOMPANY,
        payload: res.data
      })
      if (res.data.clientOwner) {
        dispatch({
          type: CLIENT.UPDATE_CLIENT_COMPANY,
          payload: { clientId: res.data.clientOwner, company: res.data }
        })
      }
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
