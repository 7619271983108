import { useEffect, useState } from 'react'
import { fetchAllProjectTemplates } from 'thunks/projectTemplate/action'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { getUserPath } from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import ImageCard from './ImageCard'
import { NoData } from 'static/Images'
import templateStyles from './templateStyles'
import { createProjectTemplate } from 'thunks/projectTemplate/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const Templates = ({
  templatesData,
  setPageState,
  setSingleTemplateData,
  deleteTemplateHandler,
  updateDataHandler,
  updateTemplateHandler
}) => {
  const dispatch = useDispatch()
  const classes = templateStyles()
  const meData = useSelector((state) => state.me.data)
  const [loading, setLoading] = useState(false)

  const handleSingleTemplate = (data) => {
    setPageState(1)
    setSingleTemplateData(data)
  }

  const handleDuplicateTemplate = (data) => {
    setLoading(true)
    createProjectTemplate(
      {
        title: `${data.title}_copy`,
        description: data.description,
        category: data.category ?? '',
        type: 'private',
        content: data.content
      },
      (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster(`Template Duplicated Successfully!`))
          updateDataHandler(res, { new: true })
        } else {
          dispatch(fireErrorToaster(res))
        }
      }
    )
  }

  return (
    <div>
      <div className="mb-8">
        <h1>
          <span className="text-primary-dark font-medium">Templates</span>{' '}
        </h1>
      </div>
      {templatesData.length ? (
        <div className={`${classes.templateGrid} pb-4`}>
          {templatesData.map((temp) => (
            <ImageCard
              data={temp}
              handleSingleTemplate={() => handleSingleTemplate(temp)}
              deleteTemplateHandler={deleteTemplateHandler}
              handleDuplicateTemplate={handleDuplicateTemplate}
              updateTemplateHandler={updateTemplateHandler}
            />
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center gap-4 w-full h-full text-center bg-gray-50 px-6 my-4">
          {/* <p className="text-gray-500">No tasks available</p> */}
          <div style={{ width: '200px', height: '250px' }}>
            <img
              src={NoData}
              alt="No Access"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Templates
