import Card from 'global/globalComponents/Card/Card'
import { CreativeRequestTemplate } from 'static/Images'
import { TemplateIcon } from 'static/Images'
import { ReactComponent as ImageIcon } from 'static/svg/image-3.svg'
import { ReactComponent as MarketingIcon } from 'static/svg/megaphone.svg'
import { ReactComponent as DesignIcon } from 'static/svg/pen-tool.svg'
import { ReactComponent as SoftwareIcon } from 'static/svg/file-code.svg'
import { ReactComponent as VerticalThreeDot } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import templateStyles from './templateStyles'
import { AlertModal, PopupMenu } from 'global/globalComponents'
import { useState } from 'react'
import { userRoles } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import CreateTemplateModal from './CreateTemplateModal'

const truncateText = (text, maxWord) => {
  text = text.replace(text[0], text[0]?.toUpperCase())
  if (text.length >= maxWord) {
    const transformed = text.substr(0, maxWord - 2)
    return `${transformed}...`
  }
  return text
}

const handleTemplateIcon = category => {
  if (category?.toLowerCase() === 'marketing') {
    return (
      <MarketingIcon className="text-primary-main" width={40} height={40} />
    )
  } else if (category?.toLowerCase() === 'design') {
    return <DesignIcon className="text-primary-main" width={40} height={40} />
  } else if (category?.toLowerCase() === 'software development') {
    return <SoftwareIcon className="text-primary-main" width={40} height={40} />
  } else {
    return <ImageIcon className="text-primary-main" width={40} height={40} />
  }
}

const ImageCard = ({
  data,
  handleSingleTemplate,
  deleteTemplateHandler,
  handleDuplicateTemplate,
  updateTemplateHandler
}) => {
  const classes = templateStyles()
  const meData = useSelector(state => state.me.data)

  return (
    <div
      className="bg-white border rounded-xl shadow-sm overflow-hidden cursor-pointer hover:shadow-lg"
      style={{ maxHeight: '280px' }}
    >
      <div className="relative show-on-hover-parent">
        {data.type === 'private' &&
          [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
            meData.role
          ) && (
            <PopupMenu
              targetComp={
                <VerticalThreeDot
                  className="absolute top-5 right-4 text-primary-main p-1 hover:bg-custom-blue-light rounded-md show-on-hover-child"
                  width="2em"
                  height="1.5em"
                />
              }
              menuItems={[
                {
                  label: 'Edit',
                  method: () => updateTemplateHandler(data),
                  icon: <EditIcon className="text-primary-main" />
                },
                {
                  label: 'Delete',
                  method: () => deleteTemplateHandler(data._id),
                  icon: <DeleteIcon className="text-primary-main" />
                },
                {
                  label: 'Duplicate',
                  method: () => handleDuplicateTemplate(data),
                  icon: <CopyIcon className="text-primary-main" />
                }
              ]}
            />
          )}
        <div onClick={handleSingleTemplate}>
          <div className="px-6 pt-4">{handleTemplateIcon(data.category)}</div>
          <div className="w-full p-6 pt-4 break-words">
            <h1 className="text-sm lg:text-base font-semibold overflow-hidden mb-2">
              {data.title}
            </h1>
            <p className="text-smaller lg:text-sm overflow-hidden">
              {truncateText(data.description, 100)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageCard
