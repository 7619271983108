import React, { useMemo, useState } from 'react'
import { LoadingButton } from 'global/globalComponents'
import AgencyPlansStyles from './AgencyPlansStyles'
// import agencyPlansOfferings from './planOfferings'
import clsx from 'clsx'
import { ReactComponent as CheckCircleFilled } from 'static/svg/check-circle-filled.svg'
import { useEffect } from 'react'

const PlansCard = ({
  plan,
  index,
  selectPlanHandler,
  loading,
  selectedSeats,
  currentPlanDuration,
  getSeatBasedPlanAmount
}) => {
  const [totalAmount, setTotalAmount] = useState(0)
  const customStyles = AgencyPlansStyles()
  const classes = [
    'text-red-400',
    'text-blue-400',
    'text-green-400',
    'text-orange-400'
  ]
  const basePrice = useMemo(() => {
    return plan.tiers[0].unit_amount / 100
  }, [plan])

  useEffect(() => {
    setTotalAmount(getSeatBasedPlanAmount({ plan, seats: selectedSeats }))
  }, [plan.tiers, selectedSeats])

  const handleSelectPlan = () => {
    selectPlanHandler(plan, totalAmount * 100)
  }

  return (
    <div
      className={clsx(
        customStyles.planCard,
        'border border-t-8 bg-white pt-4 pb-8 px-6 border-t-current rounded',
        classes[index]
      )}
    >
      <h3 className="font-bold mb-4 capitalize">
        {plan.nickname ?? 'Base Plan'}
      </h3>
      {selectedSeats !== 'contact us' ? (
        <>
          <div className="mb-4">
            <div className="flex items-center">
              <span className="text-4xl mr-2" style={{ color: 'inherit' }}>
                ${totalAmount}
              </span>
              <span className="text-gray-600 text-sm">
                per {currentPlanDuration}
              </span>

              <div className="flex items-center space-x-1 text-smaller ml-2 text-gray-600 ">
                (<span className="font-medium">{basePrice}</span>
                <span className="text-primary-main font-medium">&times;</span>
                <span className="font-medium">{selectedSeats}</span>)
              </div>
            </div>
          </div>

          <LoadingButton
            loading={loading}
            style={{ minWidth: 128 }}
            onClick={handleSelectPlan}
            variant="outlined"
            size="small"
          >
            Start Free Trial
          </LoadingButton>
        </>
      ) : (
        <a
          href="https://clientvenue.com/sales-demo"
          target="_blank"
          className="inline-block border text-gray-500 px-4 py-1 rounded-full text-smaller font-medium mt-2 text-blue-600 hover:border-blue-600"
          rel="noreferrer"
        >
          Contact us
        </a>
      )}

      <hr className="my-4" />
      <ul
        className={clsx(
          customStyles.planDescriptionContainer,
          'text-sm text-gray-600'
        )}
      >
        <li className="py-1 px-2 flex items-center">
          <CheckCircleFilled className="mr-2 text-green-500 w-4 h-4" />
          {plan.metadata.teammateQuota *
            (isNaN(selectedSeats) ? 50 : selectedSeats)}{' '}
          Teammates
        </li>
        <li className="py-1 px-2 flex items-center">
          <CheckCircleFilled className="mr-2 text-green-500 w-4 h-4" />
          {plan.metadata.teammateQuota *
            (isNaN(selectedSeats) ? 50 : selectedSeats)}{' '}
          Clients
        </li>
        <li className="py-1 px-2 flex items-center">
          <CheckCircleFilled className="mr-2 text-green-500 w-4 h-4" />
          {plan.metadata.serviceQuota *
            (isNaN(selectedSeats) ? 50 : selectedSeats)}{' '}
          Services
        </li>
        <li className="py-1 px-2 flex items-center">
          <CheckCircleFilled className="mr-2 text-green-500 w-4 h-4" />
          {plan.metadata.storageQuota} GB Storage
        </li>
        {/* {agencyPlansOfferings[plan.nickname.split(' ')[0].toLowerCase()]
          ? Object.entries(
              agencyPlansOfferings[plan.nickname.split(' ')[0].toLowerCase()]
            ).map(([key, value], index) => (
              <li
                className={clsx({ notAllowed: !value })}
                component="li"
                key={index}
              >
                {key}
              </li>
            ))
          : null} */}
      </ul>
    </div>
  )
}

export default PlansCard
