import { useState, useEffect } from 'react'
import CustomModal from 'global/globalComponents/CustomModal/CustomModal'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import {
  Divider,
  TextField,
  Tabs,
  Tab,
  Popover,
  List,
  ListItem,
  Button
} from '@material-ui/core'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { ReactComponent as AngleDown } from 'static/svg/angle-down.svg'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Categories from 'utils/workspaceCategories'
import {
  assignWorkspace,
  updateTeammateRole,
  removeTeammateFromWorkspace,
  updateWorkspaceCategory
} from 'thunks/workspace/actions'
import userRoles from 'utils/userRoles'
import { getClient, inviteClient } from '../../../thunks/addClient/actions'
import TotalMember from './TotalMember'
import MemberList from './MemberList'
import CloseIcon from '@material-ui/icons/Close'
import newTeammateStyles from './newTeammateStyles'
import { useWorkspacePermission } from 'customHooks'
import {
  toggleAddTeammateSidePanel,
  toggleAddClientSidePanel
} from 'thunks/generlAppState/actions'
import {
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
// import { CLIENT } from 'thunks/addClient/reducers'
// import { TEAMMATES } from 'thunks/teammate/reducers'

const NewTeammateModal = (props) => {
  const { open, onClose } = props

  return (
    <CustomModal open={open} handleClose={onClose} size="medium">
      <CustomModalHeader heading="Share project" />
      <NewTeammateModalContent onClose={onClose} />
      <CustomModalFooter>
        <Button onClick={onClose}>Close</Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

const NewTeammateModalContent = ({ onClose }) => {
  const dispatch = useDispatch()
  const classes = newTeammateStyles()
  const [userState, setUserState] = useState(0)
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const { workspace: workspacePermission } = useWorkspacePermission(
    currentWorkspace?._id
  )
  const userTeammates = useSelector((state) => state.userTeammates.data)
  const me = useSelector((state) => state.me.data)
  const clients = useSelector((state) => state.userClients)
  const [anchorEl, setAnchorEl] = useState(null)
  const [teammateOptions, setTeammateOptions] = useState([])
  const [clientInviteEmail, setClientInviteEmail] = useState('')
  const [invitingClientLoading, setInvitingClientLoading] = useState(false)
  const [clientOptions, setClientOptions] = useState([])
  const [clientData, setClientData] = useState([])
  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })
  const [selectedTeammates, setSelectedTeammates] = useState([])
  const [loading, setLoading] = useState({
    team: false,
    client: false
  })
  const [selectedTab, setSelectedTab] = useState(0)
  // const [inviteMember, setInviteMember] = useState(false)
  const [inviteData, setInviteData] = useState({
    name: '',
    email: '',
    password: '',
    assignedWorkspace: [],
    role: 0
  })

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const arr = []
    const addedMates = currentWorkspace?.assignedAgencyTeam?.map(
      (i) => i.user?._id
    )
    userTeammates?.forEach((mate) => {
      if (!addedMates?.includes(mate._id)) {
        if ([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(me.role)) {
          arr.push({ label: `${mate.name} (${mate?.email})`, value: mate._id })
        } else {
          if (mate.role !== userRoles.AGENCY_ADMIN) {
            arr.push({
              label: `${mate.name} (${mate?.email})`,
              value: mate._id
            })
          }
        }
      }
    })
    setTeammateOptions(arr)
  }, [userTeammates, currentWorkspace])

  useEffect(() => {
    if (!clients.fetched && me?.role < userRoles.USER_CLIENT) {
      dispatch(getClient())
    }
  }, [clients.fetched])

  const handleUserState = (value) => {
    setUserState(value)
    setSelectedTab(value)
  }

  useEffect(() => {
    var arr = []
    const addedClients = currentWorkspace?.assignedClientTeam?.map(
      (i) => i.user?._id
    )
    clients.data.map((item) => {
      if (!addedClients?.includes(item._id)) {
        arr.push({ label: `${item.name} (${item?.email})`, value: item._id })
      }
    })
    setClientOptions(arr)
  }, [clients.data, currentWorkspace?._id])

  const handleUserRoleUpdate = (value, user) => {
    if (user === me.profile?.user) {
      dispatch(fireErrorToaster('You are not authorised to update your role'))
      return
    }

    let recentProjectOwner = null

    if (value === userRoles.PROJECT_OWNER) {
      recentProjectOwner = currentWorkspace.assignedAgencyTeam.find(
        (mate) => mate.role === userRoles.PROJECT_OWNER
      )

      if (recentProjectOwner) {
        if (recentProjectOwner.user._id === user) {
          return
        }
        dispatch(
          updateTeammateRole(
            currentWorkspace._id,
            {
              assignToAgencyTeammate: {
                user: recentProjectOwner.user._id,
                role: userRoles.AGENCY_EDITOR
              }
            },
            recentProjectOwner.user.name,
            (res, err) => {
              if (!err) {
                const { name } = userTeammates.find((team) => team._id === user)
                dispatch(
                  updateTeammateRole(
                    currentWorkspace._id,
                    {
                      assignToAgencyTeammate: { user, role: value }
                    },
                    name
                  )
                )
              } else {
                dispatch(fireErrorToaster(res))
              }
            }
          )
        )
      }
    }
    if (!recentProjectOwner) {
      const { name } = userTeammates.find((team) => team._id === user)
      dispatch(
        updateTeammateRole(
          currentWorkspace._id,
          {
            assignToAgencyTeammate: { user, role: value }
          },
          name
        )
      )
    }
  }

  const handleRole = (val) => {
    setInviteData((prev) => ({ ...prev, role: val }))
  }

  const updateWorkspaceCallback = (res, err) => {
    setToggleSwitch((prev) => ({
      ...prev,
      load: false
    }))
    if (err) {
    } else {
      setToggleSwitch((prev) => ({
        ...prev,
        checked: !toggleSwitch.checked,
        disable: false
      }))
    }
  }

  const handleSwitch = () => {
    setToggleSwitch((prev) => ({
      ...prev,
      load: true
    }))
    if (
      ![
        userRoles.USER_CLIENT,
        userRoles.CLIENT_EDITOR,
        userRoles.CLIENT_MANAGER,
        userRoles.CLIENT_VIEWER
      ].includes(me.role)
    ) {
      dispatch(
        updateWorkspaceCategory(
          currentWorkspace._id,
          currentWorkspace?.category === 'Internal'
            ? Categories.EXTERNAL
            : Categories.INTERNAL,
          updateWorkspaceCallback
        )
      )
    }
  }

  const handleRemoveTeammate = (teammate) => {
    if (teammate.user._id === me.profile?.user) {
      return dispatch(
        fireErrorToaster('You are not authorizes to remove yourself')
      )
    }
    dispatch(
      removeTeammateFromWorkspace(
        currentWorkspace._id,
        {
          assignToAgencyTeammate: {
            user: teammate.user._id,
            role: teammate.role
          }
        },
        teammate.user?.name
      )
    )
  }

  const addTeammateCallback = (res, err) => {
    setLoading((prev) => ({ ...prev, team: false }))
    if (err) {
    } else {
      dispatch(fireSuccessToaster('teammate added successfully'))
      // dispatch({
      //   type: TEAMMATES.UPDATE_MANY_TEAMMATES_WORKSPACES,
      //   payload: { teams: selectedTeammates.map((i) => i.value), data: res }
      // })
      setSelectedTeammates([])
    }
  }

  const handleAddTeammate = () => {
    if (selectedTeammates?.length === 0) {
      return dispatch(fireErrorToaster('Please enter the teammates'))
    }
    setLoading((prev) => ({ ...prev, team: true }))
    const selectedTeammate = selectedTeammates?.map((i) => i.value)

    const teammate = userTeammates
      .filter((team) => selectedTeammate.includes(team._id))
      .map((el) => el.name)
    dispatch(
      assignWorkspace(
        {
          workspace: currentWorkspace._id,
          assignToAgencyTeammate: selectedTeammate,
          assignToClientTeammate: []
        },
        teammate,
        addTeammateCallback
      )
    )

    // Since we added a new teammate in a workspace we need to fetch the updated data of teammates
    // So that we could show the changes on Teammates page
    // dispatch(fetchTeammateOfCurrentUser())
  }

  const addClientCallback = (res, err) => {
    setLoading((prev) => ({ ...prev, client: false }))
    if (err) {
    } else {
      dispatch(fireSuccessToaster('client added successfully'))
      // dispatch({
      //   type: CLIENT.UPDATE_ONE_CLIENT,
      //   payload: { _id: clientData.value, assignedWorkspace: [res] }
      // })
      setClientData([])
    }
  }

  const handleAddClient = () => {
    if (clientData?.length === 0) {
      return dispatch(fireErrorToaster('Please enter the client'))
    }
    setLoading((prev) => ({ ...prev, client: true }))

    const client = clients.data
      .filter((team) => team._id === clientData.value)
      .map((el) => el.name)

    dispatch(
      assignWorkspace(
        {
          workspace: currentWorkspace._id,
          assignToAgencyTeammate: [],
          assignToClientTeammate: [clientData.value]
        },
        client,
        addClientCallback
      )
    )
  }

  const handleRemoveClientTeammate = (teammate) => {
    // if (teammate.role.value > 30) {
    //   return dispatch(fireErrorToaster("You can't remove client's teammate"))
    // }
    dispatch(
      removeTeammateFromWorkspace(
        currentWorkspace._id,
        {
          assignToClientTeammate: {
            user: teammate.user._id,
            role: teammate.role
          }
        },
        teammate.user?.name,
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleInviteClient = (e) => {
    e.preventDefault()
    setInvitingClientLoading(true)
    inviteClient({ email: clientInviteEmail }, inviteClientCallback)
  }

  const inviteClientCallback = (res, err) => {
    setInvitingClientLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Invite sent to client'))
    }
  }

  const threeDotProps = (userState) => {
    const arr = [
      {
        name: 'Can manage',
        description: 'Team can add, edit, and delete anything in the project',
        method: userState !== 0 ? handleUserRoleUpdate : handleRole,
        role: userRoles.AGENCY_MANAGER
      },
      {
        name: 'Can edit',
        description:
          "Team can edit and view, but can't add and delete anything in the project.",
        method: userState !== 0 ? handleUserRoleUpdate : handleRole,
        role: userRoles.AGENCY_EDITOR
      },
      {
        name: 'Can view',
        description: "Team can view, but can't edit anything in the project.",
        method: userState !== 0 ? handleUserRoleUpdate : handleRole,
        role: userRoles.AGENCY_VIEWER
      },
      {
        name: 'Set as project owner',
        description: 'User will have admin permissions for this project',
        method: userState !== 0 ? handleUserRoleUpdate : handleRole,
        role: userRoles.PROJECT_OWNER
      },
      {
        name: 'Remove from project',
        method: handleRemoveTeammate
      }
    ]
    if (workspacePermission?.update && userState === 0) {
      return arr.slice(0, 4)
    }
    if (workspacePermission?.update && userState === 1) {
      return arr
    }
    if (userState === 2) {
      return [
        {
          name: 'Remove from project',
          method: handleRemoveClientTeammate
        }
      ]
    }
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
    setUserState(newVal)
  }

  const inviteTeammate = (inputValue = '') => {
    dispatch(
      toggleAddTeammateSidePanel(true, {
        email: inputValue,
        assignedWorkspace: [
          { label: currentWorkspace.name, value: currentWorkspace._id }
        ]
      })
    )
    // if (openAddTeammatePanel) {
    //   openAddTeammatePanel(
    // {
    //   email: inputValue,
    //   assignedWorkspace: [
    //     { label: currentWorkspace.name, value: currentWorkspace._id }
    //   ]
    // },
    //     'teammatePanelOpen'
    //   )
    // } else {
    //   dispatch(() => )
    // }
    handleClose()
    onClose()
  }

  const inviteClientUser = (inputValue = '') => {
    // if (openAddTeammatePanel) {
    //   openAddTeammatePanel(
    // {
    //   email: inputValue,
    //   assignedWorkspace: [
    //     { label: currentWorkspace.name, value: currentWorkspace._id }
    //   ]
    // },
    //     'clientPanelOpen'
    //   )
    // } else {
    // }
    dispatch(
      toggleAddClientSidePanel(true, {
        email: inputValue,
        assignedWorkspace: [
          { label: currentWorkspace.name, value: currentWorkspace._id }
        ]
      })
    )

    handleClose()
    onClose()
  }

  return (
    <>
      <div className="px-8">
        <div className="flex justify-between items-center">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            {['Share', 'Teammates', 'Client'].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
          {userState === 0 && (
            <div
              className="flex text-sm font-medium text-gray-500 hover: text-gray-900"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              Add New User
              <AngleDown
                className={`h-6 w-6 text-white fill-white`}
                style={{ fill: 'gray', fontSize: '5px' }}
              />
            </div>
          )}
        </div>

        <Divider orientation="horizontal" />

        {userState === 0 && (
          <>
            <div className="w-full mt-6 mb-3">
              <>
                <div className={clsx(classes.addUser, 'mb-2')}>
                  Add Teammate
                </div>

                <div className="flex items-center justify-between gap-2">
                  <Select
                    placeholder="Add Teammates"
                    isSearchable={true}
                    onChange={(e) => setSelectedTeammates(e)}
                    value={selectedTeammates}
                    isMulti
                    options={teammateOptions}
                    styles={reactSelectCustomStyles}
                    className="flex-grow"
                    maxMenuHeight={150}
                    noOptionsMessage={({ inputValue }) => {
                      return (
                        <span
                          className="text-md text-blue-700 text-left cursor-pointer"
                          onClick={() => inviteTeammate(inputValue)}
                        >
                          <AddIcon fontSize="small" className="mr-2" />
                          Invite '{inputValue}' via email
                        </span>
                      )
                    }}
                  />

                  <LoadingButton
                    onClick={handleAddTeammate}
                    loading={loading.team}
                    size="small"
                  >
                    Add
                  </LoadingButton>
                </div>
                <TotalMember
                  members={
                    [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
                      me.role
                    )
                      ? currentWorkspace?.assignedAgencyTeam?.filter(
                          (mate) => mate.role !== userRoles.USER_AGENCY
                        )
                      : currentWorkspace?.assignedAgencyTeam?.filter(
                          (mate) =>
                            ![
                              userRoles.USER_AGENCY,
                              userRoles.AGENCY_ADMIN
                            ].includes(mate.role)
                        )
                  }
                  handleUserState={handleUserState}
                  userState={1}
                  type="agency"
                />
                <Divider />

                <div className={clsx('mt-6', classes.addUser)}>Add clients</div>
                <div className="flex items-center justify-between mb-3">
                  <p className="text-xs text-gray-700">
                    {currentWorkspace?.category === 'Internal'
                      ? ' Make this project external to add clients'
                      : 'This is an external project'}
                  </p>

                  <div className="flex items-center gap-1">
                    <span className="text-xs font-medium text-gray-700">
                      No
                    </span>
                    <ToggleSwitch
                      checked={currentWorkspace?.category === 'External'}
                      onChange={handleSwitch}
                      disableRipple={true}
                      loading={toggleSwitch.load}
                    />
                    <span className="text-sm font-medium text-gray-700">
                      Yes
                    </span>
                  </div>
                </div>
                {[
                  userRoles.USER_AGENCY,
                  userRoles.AGENCY_ADMIN,
                  userRoles.AGENCY_MANAGER
                ].includes(me.role) &&
                currentWorkspace?.category === 'External' &&
                currentWorkspace?.assignedClientTeam?.length === 0 ? (
                  <>
                    <div className="flex items-center justify-between gap-2">
                      <Select
                        menuPlacement="top"
                        placeholder="Add Client"
                        isSearchable={true}
                        onChange={(e) => setClientData(e)}
                        // isMulti
                        value={clientData}
                        options={clientOptions}
                        styles={reactSelectCustomStyles}
                        className="flex-grow"
                        maxMenuHeight={150}
                        noOptionsMessage={({ inputValue }) => {
                          return (
                            <span
                              className="text-md text-blue-700 text-left cursor-pointer"
                              onClick={() => inviteClientUser(inputValue)}
                            >
                              <AddIcon fontSize="small" className="mr-2" />
                              Invite '{inputValue}' via email
                            </span>
                          )
                        }}
                      />
                      <LoadingButton
                        onClick={handleAddClient}
                        loading={loading.client}
                        size="small"
                      >
                        Add
                      </LoadingButton>
                    </div>
                    <TotalMember
                      members={currentWorkspace?.assignedClientTeam}
                      handleUserState={handleUserState}
                      userState={2}
                      type="agency"
                    />
                  </>
                ) : clients.fetched ? (
                  clients.data?.length > 0 ? (
                    <>
                      <TotalMember
                        members={currentWorkspace?.assignedClientTeam}
                        handleUserState={handleUserState}
                        userState={2}
                      />
                    </>
                  ) : (
                    <div className="mt-4">
                      <p className="text-sm">
                        No clients available. Invite a new client
                      </p>
                      <form onSubmit={handleInviteClient} className="mt-2">
                        <FormLabel required>Client Email</FormLabel>
                        <div className="flex items-center gap-2 mt-1">
                          <TextField
                            onChange={(e) =>
                              setClientInviteEmail(e.target.value)
                            }
                            value={clientInviteEmail}
                            className="flex-grow"
                            type="email"
                            required
                          />
                          <LoadingButton
                            loading={invitingClientLoading}
                            type="submit"
                          >
                            Invite
                          </LoadingButton>
                        </div>
                      </form>
                    </div>
                  )
                ) : null}
              </>
            </div>
          </>
        )}

        {userState === 1 && (
          <MemberList
            members={
              [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(me.role)
                ? currentWorkspace?.assignedAgencyTeam?.filter(
                    (mate) => mate.role !== userRoles.USER_AGENCY
                  )
                : currentWorkspace?.assignedAgencyTeam?.filter(
                    (mate) =>
                      ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
                        mate.role
                      )
                  )
            }
            threeDotProps={threeDotProps}
            userState={1}
            type="agency"
          />
        )}

        {userState === 2 && (
          <MemberList
            members={currentWorkspace?.assignedClientTeam}
            threeDotProps={threeDotProps}
            userState={2}
            type="agency"
          />
        )}
        {/* <div className={clsx('mt-3', classes.footer)}></div> */}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List
          disablePadding
          dense
          style={{ maxWidth: '280px', padding: '5px 0' }}
        >
          <ListItem
            style={{ fontSize: '13px', padding: '3px 8px' }}
            onClick={() => inviteTeammate()}
            button
          >
            Add Teammate
          </ListItem>
          <ListItem
            style={{ fontSize: '13px', padding: '3px 8px' }}
            onClick={() => inviteClientUser()}
            button
          >
            Add Client
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default NewTeammateModal
