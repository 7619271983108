import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

const CustomModalHeader = ({
  heading,
  subHeading,
  icon,
  handleClose,
  className
}) => (
  <header
    className={clsx(
      'flex items-center justify-between gap-2 py-4 lg:py-5 px-6 select-none rounded-t-2xl lg:rounded-t-3xl',
      className
    )}
    // style={{ borderBottom: '1px solid #ddd' }}
  >
    <div className="flex flex-1 space-x-2 items-center">
      {icon && <span>{icon}</span>}
      <div className="w-full">
        <h2 className="text-lg lg:text-xl font-semibold text-primary-dark">
          {heading}
        </h2>
        {subHeading && (
          <p className="text-sm text-custom-gray-dark-3">{subHeading}</p>
        )}
      </div>
    </div>
    {handleClose && (
      <IconButton onClick={handleClose} size="small">
        <CloseIcon size="small" fontSize="small" />
      </IconButton>
    )}
  </header>
)

export default CustomModalHeader
