import React, { useCallback, useContext, useEffect, useState } from 'react'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import CreateNewStatus from './CreateNewStatus'
import StatusUpdateSidebar from './StatusUpdateSidebar'
import { Skeleton } from '@material-ui/lab'
import {
  fetchProjectStatusPosts,
  deleteProjectStatusPost,
  createProjectStatusPost
} from 'thunks/StatusPost/action'
// import { getTaskByWorkspaceBoardId } from 'thunks/task/actions'
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import StatusUpdatePage from './StatusUpdatePage'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { errorMessages } from 'utils'
import { getUserPath } from 'utils/userRoles'
import projectStatusUpdateStyles from './projectStatusUpdateStyles'
import clsx from 'clsx'
// import {
//   SingleWorkspaceContext,
//   WORKSPACE
// } from 'components/SingleWorkspace/singleWorkspaceContext'

const getTimeString = time => {
  let dif = Math.floor((Date.now() - new Date(time)) / (1000 * 60 * 60))
  let date = new Date(time)

  if (dif > 24 && dif <= 48) {
    return `yesterday at ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })}`
  } else if (dif < 24) {
    return `today at ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })}`
  } else {
    return format(new Date(time), 'd MMM p')
  }
}

const StatusUpdates = () => {
  const dispatch = useDispatch()
  const classes = projectStatusUpdateStyles()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  // const { path } = useRouteMatch()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  // const { workspaceState, workspaceDispatch } = useContext(
  //   SingleWorkspaceContext
  // )
  const userReportings = useSelector(state => state.userReportings)
  const { statusId, workspaceId } = useParams()
  const [currentStatusPost, setCurrentStatusPost] = useState({})
  const [statusPosts, setStatusPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [deletePostModal, setDeletePostModal] = useState({
    open: false,
    loading: false,
    data: {}
  })

  // useEffect(() => {
  //   if (currentWorkspace?._id) {
  //     dispatch(getTaskByWorkspaceBoardId(currentWorkspace.defaultBoard?._id))
  //   }
  // }, [currentWorkspace?._id])

  useEffect(() => {
    if (userReportings.fetched) {
      setStatusPosts(
        userReportings.data.filter(
          status =>
            !(status.state === 'draft' && status.user?._id !== meData._id)
        )
      )
      setLoading(false)
    }
  }, [userReportings])

  useEffect(() => {
    if (!statusId) {
      history.replace(
        `${getUserPath(meData?.role)}/workspace/${workspaceId}/status-updates`
      )
    } else {
      const post = statusPosts.find(item => item._id === statusId)
      setCurrentStatusPost({ ...post })
    }
  }, [statusId, statusPosts])

  // useEffect(() => {
  //   if (!statusId) {
  //     if (statusPosts.length) {
  //       history.replace(
  //         `${getUserPath(
  //           meData?.role
  //         )}/workspace/${workspaceId}/status-updates/${statusPosts[0]._id}`
  //       )
  //     } else {
  //       history.replace(
  //         `${getUserPath(meData?.role)}/workspace/${workspaceId}/status-updates`
  //       )
  //     }
  //   } else {
  //     const post = statusPosts.find((item) => item._id === statusId)
  //     if (post) {
  //       setCurrentStatusPost({ ...post })
  //     } else {
  //       history.replace(
  //         `${getUserPath(meData?.role)}/workspace/${workspaceId}/status-updates`
  //       )
  //     }
  //   }
  // }, [statusId, statusPosts])

  const handleViewStatus = e => {
    history.push(
      `${getUserPath(meData?.role)}/workspace/${workspaceId}/status-updates/${
        e.currentTarget.dataset.id
      }`
    )
  }

  const addNewStatusPost = post => {
    dispatch(
      createProjectStatusPost(post, (res, err) => {
        if (!err) {
          history.push(
            `${getUserPath(
              meData?.role
            )}/workspace/${workspaceId}/status-updates/${res._id}`
          )
        } else dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
      })
    )
  }

  // const updateStatusPost = useCallback((post) => {
  //   history.push(
  //     `${getUserPath(meData?.role)}/workspace/${workspaceId}/status-updates/${
  //       post._id
  //     }`
  //   )
  // }, [])

  const toggleDeletePostAlert = data => {
    if (deletePostModal.open) {
      setDeletePostModal(prev => ({ ...prev, open: false }))
    } else {
      setDeletePostModal({ open: true, data, loading: false })
    }
  }

  const handleDeleteStatusPost = () => {
    setDeletePostModal(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteProjectStatusPost(deletePostModal.data._id, deletePostCallback)
    )
  }

  const deletePostCallback = (res, err) => {
    setDeletePostModal(prev => ({ ...prev, loading: false, open: false }))
    if (err) {
      dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    } else {
      dispatch(fireSuccessToaster('Post deleted successfully!'))
      const newCurrentPost = statusPosts.find(
        item => item._id !== deletePostModal.data._id
      )
      if (newCurrentPost) {
        history.push(
          `${getUserPath(
            meData?.role
          )}/workspace/${workspaceId}/status-updates/${newCurrentPost._id}`
        )
      } else {
        history.push(
          `${getUserPath(meData?.role)}/workspace/${workspaceId}/status-updates`
        )
      }
    }
  }

  return (
    <>
      <div className="" style={{ margin: '-2rem -2.5rem' }}>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <Switch>
            <Route
              path={`${getUserPath(
                meData?.role
              )}/workspace/:workspaceId/status-updates/:statusId`}
            >
              <div
                className={clsx(
                  'absolute border-r overflow-y-auto bg-white',
                  classes.leftSection
                )}
              >
                <StatusUpdateSidebar
                  addNewStatusPost={addNewStatusPost}
                  allStatusPosts={statusPosts}
                  currentStatusPostId={currentStatusPost._id}
                  handleViewStatus={handleViewStatus}
                  timeStringHandler={getTimeString}
                />
              </div>
              <div className={clsx('px-8', classes.rightSection)}>
                <CreateNewStatus
                  currentStatusPost={currentStatusPost}
                  toggleDeletePostAlert={toggleDeletePostAlert}
                  timeStringHandler={getTimeString}
                />
              </div>
            </Route>
            <Route
              exact
              path={`${getUserPath(
                meData?.role
              )}/workspace/:workspaceId/status-updates`}
            >
              <StatusUpdatePage addNewStatusPost={addNewStatusPost} />
            </Route>
          </Switch>
        )}
      </div>
      <AlertModal
        open={deletePostModal.open}
        warningText={`Do you want to delete "${deletePostModal.data.title}" status? You cannot undo this action!`}
        handleDialog={toggleDeletePostAlert}
        handleDeleteAction={handleDeleteStatusPost}
        loading={deletePostModal.loading}
      />
    </>
  )
}

const LoadingSkeleton = () => {
  return (
    <div
      className="flex flex-col justify-center h-full"
      style={{ margin: '-2rem -2.5rem' }}
    >
      <div
        className="absolute border-r overflow-y-auto"
        style={{
          width: '280px',
          top: '0',
          bottom: '-1.5rem'
        }}
      >
        <div className=" ">
          <div className="w-full h-full px-6 whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={100} />
          </div>
          <div className="w-full h-full px-6 whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={100} />
          </div>
          <div className="w-full h-full px-6 whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={100} />
          </div>
          <div className="w-full h-full px-6 whitespace-nowrap text-sm text-gray-900">
            <Skeleton height={100} />
          </div>
        </div>
      </div>
      <div
        className="px-8 flex justify-center items-center"
        style={{ margin: '0 0 0 280px' }}
      >
        <div className="w-full h-full px-6 mx-auto whitespace-nowrap text-sm text-gray-900">
          <Skeleton height={400} />
        </div>
      </div>
    </div>
  )
}

export default StatusUpdates
