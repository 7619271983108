import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Badge, Button, makeStyles, Popover } from '@material-ui/core'
import Select from 'react-select'
import clsx from 'clsx'
import {
  ColoredAvatars,
  LoadingButton,
  OutlinedButton
} from 'global/globalComponents'
import ClearIcon from '@material-ui/icons/Clear'
import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
// import {
//   createFilterForBoardTask,
//   fetchBoardFilters,
//   updateBoardFilter,
//   deleteBoardFilterById
// } from 'thunks/embed/action'
// import { getTaskByWorkspaceBoardId } from 'thunks/task/actions'
// import SaveViewPopup from 'components/SingleWorkspace/SaveViewPopup'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { CustomFlag } from 'global/globalComponents'
import moment from 'moment'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import Pill from 'global/globalComponents/Pill/Pill'

/* ========================= PLANNING ============================
1. Data :
    a. Filter Options => Status, Due Date, Created By etc.
    b. Add Ons => Is, Is not, Greater than, Less than etc.
    c. Query Options (values)

2. Elements:
    a. Popover
    b. Select => select requires data in label and value format ({label: "", value: ""})

3. Functionality Process
  1. Select a Filter Option (ex: status)
  2. Store this filter data in an array because user could add multiple filters
  3. It would an array of objects, and each object should have following properties:

    i) field: this key will hold the selected filter option, its datatype will be an object
       because we're gonna show this in a Select element.

    ii) addOn: selected add on, default value => {label: "Is", value: "is"}

    iii) query: the selected query value, same datatype => {label: "", value: ""}
        here label could hold jsx instead of just strings in some special cases (ex: created by filter)

    NOTE: These added filter properties can be updated

    ex: {
      field: {label: "Status", value: "status"},
      addOn: {label: "Is", value: "is"},
      query: {label: "open", value: "{task section id}"}
    }

  4. Remove filter option (button)
========================= PLANNING ============================ */

const WidgetFilter = ({ handleApplyFilters, widgetId }) => {
  const dispatch = useDispatch()
  // const currentWorkspaceBoard = useSelector(
  //   (state) => state.userWorkspaces.currentBoard
  // )
  // const taskTags = useSelector((state) => state.userTags.currentWorkspaceTags)
  const teammates = useSelector(state => state.userTeammates.data)
  const clients = useSelector(state => state.userClients.data)
  const { data: allProjects } = useSelector(state => state.userWorkspaces)

  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  const classes = useStyles()
  // const [loadingSaveBtn, setLoadingSaveBtn] = useState(false)

  const filterFields = useMemo(
    () => [
      {
        label: 'Start Date', //single
        value: 'startDate'
      },
      {
        label: 'Due Date', //Single
        value: 'dueDate'
      },
      {
        label: 'Priority', //multi
        value: 'priority'
      },
      {
        label: 'Created By', //Single
        value: 'user'
      },
      // {
      //   label: 'Tags', //multi
      //   value: 'tags'
      // },
      {
        label: 'Agency Teammates', //multi
        value: 'assignedAgencyTeam'
      },
      {
        label: 'Client Teammates', //multi
        value: 'assignedClientTeam'
      },
      {
        label: 'Projects', //multi
        value: 'projects'
      }
    ],
    []
  )

  const operatorOptions = useMemo(
    () => [
      { label: 'Is', value: 'EQ' },
      { label: 'Is not', value: 'NE' }
    ],
    []
  )

  const dateOptions = useMemo(
    () => [
      { label: 'Today', value: '=' + new Date() },
      {
        label: 'Yesterday',
        value: '-' + moment().subtract(1, 'days').format('ll')
      },
      {
        label: 'Tomorrow',
        value: '+' + moment().add(1, 'days').format('ll')
      },
      {
        label: 'Last 7 days',
        value: '-' + moment().subtract(7, 'days').format('ll')
      },
      {
        label: 'Next 7 days',
        value: '+' + moment().add(7, 'days').format('ll')
      },
      {
        label: 'Last 30 days',
        value: '-' + moment().subtract(30, 'days').format('ll')
      },
      {
        label: 'Next 30 days',
        value: '+' + moment().add(30, 'days').format('ll')
      },
      {
        label: 'Last 90 days',
        value: '-' + moment().subtract(90, 'days').format('ll')
      },
      {
        label: 'Next 90 days',
        value: '+' + moment().add(90, 'days').format('ll')
      }
    ],
    []
  )

  const [filterValues, setFilterValues] = useState({
    priority: [
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={1} />
            <span>Urgent</span>
          </div>
        ),
        value: 1,
        backgroundColor: 'rgb(245, 0, 0)',
        color: 'rgb(255, 255, 255)'
      },
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={2} />
            <span>High</span>
          </div>
        ),
        value: 2,
        backgroundColor: 'rgb(255, 204, 0)',
        color: 'rgb(255, 255, 255)'
      },
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={3} />
            <span>Medium</span>
          </div>
        ),
        value: 3,
        backgroundColor: 'rgb(111, 221, 255)',
        color: 'rgb(255, 255, 255)'
      },
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={4} />
            <span>Low</span>
          </div>
        ),
        value: 4,
        backgroundColor: 'rgb(216, 216, 216)',
        color: 'rgb(255, 255, 255)'
      }
    ],
    startDate: dateOptions,
    dueDate: dateOptions,
    tags: [],
    user: [],
    taskSection: [],
    collaborators: [],
    projects: []
  })

  // const [filtersData, setFiltersData] = useState({})
  const [savedFilters, setSavedFilters] = useState([])
  const [addedFilters, setAddedFilters] = useState([])
  // const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  // const [openSaveViewPopup, setOpenSaveViewPopup] = useState(false)
  // const [saveFilter, setSaveFilter] = useState(false)
  // const [filterCreated, setFilterCreated] = useState(false)

  useEffect(() => {
    const filters = window.localStorage.getItem('taskFilter_' + widgetId)
    if (filters) {
      const parsedData = JSON.parse(filters)
      setAddedFilters(parsedData)
      setSavedFilters(parsedData)
    }
  }, [filterModalOpen])

  useEffect(() => {
    const arr1 = teammates.map(item => ({
      label: (
        <div className="flex items-center">
          <ColoredAvatars user={item} size="tiny" />
          <span className="text-xs ml-2">{item.name}</span>
        </div>
      ),
      value: item._id
    }))

    const arr2 = clients.map(item => ({
      label: (
        <div className="flex items-center">
          <ColoredAvatars user={item} size="tiny" />
          <span className="text-xs ml-2">{item.name}</span>
        </div>
      ),
      value: item._id
    }))

    setFilterValues(prev => ({
      ...prev,
      user: [...arr1, ...arr2],
      assignedAgencyTeam: arr1,
      assignedClientTeam: arr2
    }))
  }, [teammates, clients])

  useEffect(() => {
    setFilterValues(prev => ({
      ...prev,
      projects: allProjects.map(item => ({
        label: (
          <span variant="red" className="text-xs text-primary-main">
            {item.name}
          </span>
        ),
        value: item._id
      }))
    }))
  }, [allProjects])

  const toggleFilterFieldsPopup = e => {
    if (anchorEl2) {
      setAnchorEl2(null)
    } else {
      setAnchorEl2(e.currentTarget)
    }
  }

  const toggleAddFilterModal = () => {
    if (filterModalOpen) {
      setFilterModalOpen(false)
    } else {
      setFilterModalOpen(true)
    }
  }

  const handleAddFilter = option => {
    const filterObj = {
      field: option.value,
      op: operatorOptions[0].value,
      values: [],
      idx: addedFilters.length
    }

    setAddedFilters(prev => [...prev, filterObj])
    toggleFilterFieldsPopup()
    // setSaveFilter(true)
  }

  const updateFilterProp = ({ idx, key, value }) => {
    const updatedData = addedFilters.map((item, index) => {
      if (index === idx) {
        if (key === 'field') {
          return { ...item, [key]: value, values: [] }
        }
        return { ...item, [key]: value }
      }
      return item
    })

    setAddedFilters(updatedData)
    // setSaveFilter(true)
  }

  const removeAddedFilter = index => {
    const filteredData = addedFilters.filter((item, idx) => idx !== index)
    setAddedFilters(filteredData)
    // setSaveFilter(true)
  }

  const handleOnSave = () => {
    const filteredData = addedFilters.filter(item => !item.values.length)
    if (filteredData.length) {
      dispatch(fireErrorToaster('Please select filter value!'))
      return // if there is a filter with empty value (query) do nothing
    }

    handleApplyFilters(addedFilters)
    toggleAddFilterModal()

    window.localStorage.setItem(
      'taskFilter_' + widgetId,
      JSON.stringify(addedFilters)
    )
    setSavedFilters(addedFilters)
  }

  const handleOnClose = () => {}

  return (
    <>
      <Badge
        color="primary"
        variant="dot"
        badgeContent={savedFilters.length}
        max={9}
        classes={{ badge: classes.badge }}
      >
        <OutlinedButton
          isActive={Boolean(savedFilters.length)}
          onClick={toggleAddFilterModal}
        >
          <FilterIcon />
          <span className="ml-1">Filter</span>
        </OutlinedButton>
      </Badge>

      {/* ------ First Popup ------ */}
      <CustomModal open={filterModalOpen} onClose={toggleAddFilterModal}>
        <CustomModalHeader heading="Filter" />

        <CustomModalBody>
          {Boolean(addedFilters.length) ? (
            addedFilters.map((item, index) => (
              <SingleFilter
                key={index}
                index={index}
                filter={item}
                filterFields={filterFields}
                operatorOptions={operatorOptions}
                filterValues={filterValues[item.field]}
                handleAddFilter={handleAddFilter}
                updateFilterProp={updateFilterProp}
                removeAddedFilter={removeAddedFilter}
              />
            ))
          ) : (
            <p className="text-sm text-gray-500 font-medium">
              No filters added
            </p>
          )}

          <OutlinedButton
            onClick={toggleFilterFieldsPopup}
            className="ml-auto mt-4"
          >
            <PlusIcon />
            <span className="ml-1">Add Filter</span>
          </OutlinedButton>
        </CustomModalBody>

        <CustomModalFooter>
          <Button onClick={handleOnSave}>Apply</Button>
          <Button variant="outlined" onClick={toggleAddFilterModal}>
            Cancel
          </Button>
        </CustomModalFooter>
      </CustomModal>
      {/* <Popover
        open={Boolean(anchorEl)}
        onClose={toggleAddFilterModal}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className="p-3 rounded" style={{ minWidth: 250 }}>
          <h5 className="text-gray-400 text-xs font-bold tracking-wider mb-4">
            ACTIVE FILTERS
          </h5>
          {Boolean(addedFilters.length) &&
            addedFilters.map((item, index) => (
              <SingleFilter
                key={index}
                index={index}
                filter={item}
                filterFields={filterFields}
                operatorOptions={operatorOptions}
                filterValues={filterValues[item.field]}
                handleAddFilter={handleAddFilter}
                updateFilterProp={updateFilterProp}
                removeAddedFilter={removeAddedFilter}
              />
            ))}
          <button
            onClick={toggleFilterFieldsPopup}
            className="text-primary-main text-xs font-semibold py-2"
          >
            + Add Filter
          </button>

          <div className="text-right">
            <LoadingButton
              onClick={handleOnSave}
              loading={loadingSaveBtn}
              disabled={!saveFilter}
              size="small"
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </Popover> */}
      {/* ------ First Popup Ends------ */}

      {/* ------ Second Popup ------ */}
      <Popover
        open={Boolean(anchorEl2)}
        onClose={toggleFilterFieldsPopup}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className="rounded w-40">
          <h5 className="px-3 py-2 mb-0 text-gray-400 text-xs tracking-wider">
            ADD FILTER
          </h5>
          <ul>
            {filterFields.map((item, index) => (
              <li
                key={item.label}
                className={clsx(
                  'py-1.5 px-3 cursor-pointer text-sm',
                  index === 0
                    ? 'bg-primary-main text-white'
                    : 'hover:bg-gray-100'
                )}
                onClick={() => handleAddFilter(item)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
      {/* ------ First Popup Ends ------ */}

      {/* <SaveViewPopup
        heading="Do you want to save this filter?"
        message="This filter will be saved as default"
        open={openSaveViewPopup}
        onClose={handleCloseSaveViewPopup}
        onSave={handleSaveView}
      /> */}
    </>
  )
}

const SingleFilter = ({
  index,
  filter,
  filterFields,
  operatorOptions,
  filterValues,
  updateFilterProp,
  removeAddedFilter
}) => {
  const [singleFilerData, setSingleFilterData] = useState({
    field: {},
    op: {},
    values: []
  })

  useEffect(() => {
    let field = filterFields.find(item => item.value === filter.field)
    let op = operatorOptions.find(item => item.value === filter.op)
    let values = []

    if (filter.values.length) {
      values = filterValues?.filter(item => {
        let idx = filter.values.findIndex(val => val === item.value)
        return idx >= 0
      })
    }

    setSingleFilterData({ field, op, values })
  }, [filter])

  const handleOptionChange = newVal => {
    updateFilterProp({ idx: index, key: 'field', value: newVal.value })
  }

  const handleOperatorOnChange = newVal => {
    updateFilterProp({ idx: index, key: 'op', value: newVal.value })
  }

  const handleValueChange = newVal => {
    updateFilterProp({
      idx: index,
      key: 'values',
      value: ['startDate', 'dueDate'].includes(filter.field)
        ? [newVal.value]
        : newVal.map(item => item.value)
    })
  }

  const handleRemoveFilter = () => {
    removeAddedFilter(index)
  }

  return (
    <div className="mb-2 flex space-x-2">
      <div style={{ width: 200 }}>
        <Select
          menuPosition={'fixed'}
          options={filterFields}
          value={singleFilerData.field}
          className="w-full"
          styles={{ ...reactSelectCustomStyles, ...customSelectStyles }}
          onChange={handleOptionChange}
        />
      </div>
      <div style={{ width: 100 }}>
        <Select
          menuPosition={'fixed'}
          options={
            ['startDate', 'dueDate'].includes(filter.field)
              ? [operatorOptions[0]]
              : operatorOptions
          } //A quick fix (will change it later)
          value={singleFilerData.op}
          className="w-full"
          styles={{ ...reactSelectCustomStyles, ...customSelectStyles }}
          onChange={handleOperatorOnChange}
        />
      </div>
      <div style={{ width: 350 }}>
        <Select
          menuPosition={'fixed'}
          options={filterValues}
          value={singleFilerData.values}
          className="w-full"
          styles={{ ...reactSelectCustomStyles, ...customSelectStyles }}
          onChange={handleValueChange}
          isSearchable={false}
          isMulti={!['startDate', 'dueDate'].includes(filter.field)}
        />
      </div>
      <button onClick={handleRemoveFilter} className="text-gray-400">
        <ClearIcon style={{ fontSize: 15 }} />
      </button>
    </div>
  )
}

const clrPrimary = '#1600E5'
const clrPrimaryLight = '#F3F2FF'

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: 35,
    '&:focus-within': {
      borderColor: clrPrimary,
      boxShadow: `0 0 0 1px ${clrPrimary}`
    }
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '0 4px 0 0',
    width: 20,
    height: 20
  }),
  singleValue: () => ({ fontSize: 13 }),
  valueContainer: provided => ({ ...provided, paddingRight: 0 }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: isSelected
      ? clrPrimary
      : isFocused
      ? clrPrimaryLight
      : null,
    ':active': {
      ...provided[':active'],
      backgroundColor: clrPrimaryLight
    }
  }),
  multiValueLabel: (provided, { data }) => {
    return {
      ...provided,
      padding: '3px 5px 3px 0',
      paddingLeft: '0'
    }
  }
}

const useStyles = makeStyles({
  badge: {
    right: 2,
    top: 6
  }
})

export default WidgetFilter
