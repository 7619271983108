import axios from 'axios'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { errorMessages, getAuthToken } from 'utils'
import { USER } from './reducers'
import { ME } from '../me/reducers'

/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchUser = (callback) => {
  return async (dispatch) => {
    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/user',
        headers: {
          Authorization: getAuthToken()
        }
      })
      dispatch({ type: USER.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * fetch user by id
 * @param {string} id user id
 * @param {Function} callback callback function
 */

export const fetchUserById = async (id, callback) => {
  // return async (dispatch) => {
  // dispatch({ type: USER.LOADING })
  try {
    const res = await axios({
      method: 'GET',
      url: `user/${id}`,
      headers: {
        Authorization: getAuthToken()
      }
    })
    // dispatch({ type: USER.FETCHED, payload: res.data })
    if (callback) callback(res.data, false)
  } catch (err) {
    // dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
  //}
}

/**
 * update a user
 * @param {Object} data profile image ,name
 * @param {Function} callback callback function
 */

export const updateUser = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      user: {
        data: { _id: userId }
      }
    } = getState()

    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${userId}`,
        data
      })

      dispatch({ type: USER.UPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

/**
 * update a user
 * @param {String} username user name
 * @param {Function} callback callback function
 */

export const updateUsername = (username, callback) => {
  return async (dispatch) => {
    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/username`,
        params: {
          username
        }
      })
      // dispatch(fireSuccessToaster('Username Updated'))
      dispatch({ type: USER.USERNAMEUPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({
        type: USER.ERROR,
        payload: err?.response?.data?.message ?? 'Unsuccessful'
      })
      // dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

/**
 * update a user agency details
 * @param {String} id user id
 * @param {Object} data  agency logo,address,agency name
 * @param {Function} callback callback function
 */

export const updateUserProfile = (id, data, callback) => {
  return async (dispatch) => {
    dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/agencyprofile/agency/${id}`,
        data
      })
      dispatch(fireSuccessToaster('Country profile updated'))
      dispatch({ type: USER.PROFILEUPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({
        type: USER.ERROR,
        payload: err?.response?.data?.message ?? 'Unsuccessful'
      })
      dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

/**
 * update a user agency details
 * @param {Object} data  old password,new password
 * @param {Function} callback callback function
 */

export const changePassword = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: `/user/changepassword`,
        data
      })
      dispatch(fireSuccessToaster('Password Changed Successfully'))
      // dispatch({ type: USER.USERNAMEUPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({
      //   type: USER.ERROR,
      //   payload: err?.response?.data?.message ?? 'Some Error Occured'
      // })
      dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

/**
 * update a user agency details
 * @param {String} id user id
 * @param {Function} callback callback function
 */

export const deactivateUser = (id, callback) => {
  return async (dispatch) => {
    // dispatch({ type: USER.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `user/deactivate/${id}`
      })
      // dispatch(fireSuccessToaster('Profile Updated'))
      // dispatch({ type: USER.UPDATED, payload: res.data })
      if (callback) callback(res?.data, false)
    } catch (err) {
      // dispatch({ type: USER.ERROR, payload: err?.response?.data?.message })
      dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const getAgencyInfoByDomain = () => {
  return axios({
    method: 'GET',
    url: '/user/companyinfo'
  })
}

/**
 * get new api ket
 * @param {Function} callback callback function
 */
export const getNewApiKey = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/user/apikey`
      })
      dispatch({ type: ME.UPDATE, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
