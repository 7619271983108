import React, { useState } from 'react'
import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import { CustomFlag, CustomTooltip } from 'global/globalComponents'
import { ReactComponent as FlagOutlined } from 'static/svg/pennant-outlined.svg'
import { isEqual } from 'lodash'

const PriorityCell = (props) => {
  const {
    value,
    row: { original },
    taskPermission,
    changeTaskPriority
  } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const togglePopper = (e) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleChangePriority = (priority) => {
    togglePopper()
    changeTaskPriority({
      task: original,
      priority: priority.order,
      cb: () => {
        togglePopper()
      }
    })
  }

  return (
    <>
      <div style={{ minWidth: 200 }}>
        <div className="px-3 py-2 leading-3 flex justify-center show-on-hover-parent">
          {!isNaN(value) && value !== 5 ? (
            taskPermission.update ? (
              <button onClick={togglePopper}>
                <CustomFlag priority={value} />
              </button>
            ) : (
              <span>
                <CustomFlag priority={value} />
              </span>
            )
          ) : taskPermission.update ? (
            <CustomTooltip title="Set Priority" placement="top">
              <button
                onClick={togglePopper}
                className="show-on-hover-child text-sm inline-block text-gray-400 w-6 h-6 border border-gray-400 border-dashed rounded-full flex justify-center items-center"
              >
                <FlagOutlined />
              </button>
            </CustomTooltip>
          ) : (
            <span className="text-sm inline-block text-gray-400 w-6 h-6 border border-gray-400 border-dashed rounded-full flex justify-center items-center">
              <FlagOutlined />
            </span>
          )}
        </div>
      </div>
      {taskPermission.update && (
        <PriorityDropDown
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={togglePopper}
          currentPriority={value}
          handlePriorityLabel={handleChangePriority}
        />
      )}
    </>
  )
}

// ===== Prevents unnecessary re-rendering ====
const canRender = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.value, nextProps.value) &&
    isEqual(prevProps.taskPermission, nextProps.taskPermission)
  )
}

export default React.memo(PriorityCell, canRender)
