import { Card, ColoredAvatars } from 'global/globalComponents'
import { Button } from '@material-ui/core'

const InactiveUserCard = ({ user = {}, handleViewProfile }) => {
  return (
    <Card className="p-4 md:p-6 lg:p-8">
      <div className="flex flex-col justify-center items-center gap-4">
        <ColoredAvatars user={user} size="normal"></ColoredAvatars>
        <div>
          <p className="text-primary-dark  text-sm lg:text-base font-medium text-center">
            {user.name}
          </p>
          <p className="text-xs text-primary-mid-dark-2 text-center">
            {user.email}
          </p>
        </div>
        <Button
          variant="outlined"
          // size="small"
          style={{ width: '100%' }}
          onClick={handleViewProfile}
        >
          View Profile
        </Button>
      </div>
    </Card>
  )
}

export default InactiveUserCard
