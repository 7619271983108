import React from 'react'
import { CustomPopper } from 'global/globalComponents'
import { sectionColorOptions } from 'utils/getSectionColor'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'

const StatusColorPopup = ({
  anchorEl,
  onClose,
  updateCategoryStatus,
  data
}) => {
  const handleSelectColor = e => {
    const color = e.currentTarget.dataset.color
    updateCategoryStatus({
      categoryId: data.categoryId,
      statusId: data.statusId,
      prop: 'color',
      value: color
    })
  }

  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <div className="w-56 px-2 py-3 relative">
        <button
          className="absolute top-4 right-2 text-xl text-gray-500"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
        <div className="mr-4 mt-2">
          {sectionColorOptions.map(item => (
            <span
              className="inline-block w-3 h-3 my-1 mx-2 rounded cursor-pointer hover:scale-125 transition-transform"
              style={{ backgroundColor: item }}
              key={item}
              role="button"
              onClick={handleSelectColor}
              data-color={item}
            ></span>
          ))}
        </div>
      </div>
    </CustomPopper>
  )
}

export default StatusColorPopup
