import { makeStyles, createStyles } from '@material-ui/core'

const settingStyles = makeStyles((theme) =>
  createStyles({
    // Billing Section
    rowContainer: {
      border: '1px solid #EFEFEF',
      borderRadius: '5px',
      background: '#fff',
      transition: theme.transitions.create(['box-shadow'], {
        duration: theme.transitions.duration.enteringScreen
      }),
      '& p': {
        color: '#778CA2'
      },
      '& button': {
        borderRadius: theme.shape.borderRadius,
        fontSize: 12
      },
      '&:hover': {
        boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.08)'
      }
    },
    tabsRoot: {
      position: 'relative',
      height: '48px',
      minHeight: '48px'
    },
    // ConnectedApp Section
    container: {
      border: '1px solid #EFEFEF',
      marginBottom: theme.spacing(2)
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      marginLeft: 'auto',
      marginRight: theme.spacing(4),
      '&>button': {
        width: 40,
        height: 40,
        backgroundColor: '#F2F2F2',
        borderRadius: 6,
        '&.connectBtn': {
          width: theme.spacing(25)
        },
        '& svg': {
          color: '#14142B'
        }
      }
    },
    // Notification
    '@media (min-width: 992px)': {
      notificationWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },

    // General & ProfileSection
    textFieldIcon: {
      color: theme.palette.primary.main,
      borderRadius: '5px'
    },
    aboutForm: {
      maxWidth: '1000px'
    },
    imgLabel: {
      fontSize: '0.8rem',
      color: '#555'
    },
    inputBox: {
      width: '100%'
    },
    input: {
      display: 'none'
    },
    // Subscription Section
    // rowContainer: {
    //   border: '1px solid #EFEFEF',
    //   borderRadius: '12px',
    //   backgroundColor: 'white',
    //   transition: theme.transitions.create(['box-shadow'], {
    //     duration: theme.transitions.duration.enteringScreen
    //   }),
    //   '&:hover': {
    //     boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.08)'
    //   }
    // },
    infoIcon: {
      fontSize: 15,
      cursor: 'pointer'
    },
    image: {
      height: '50px',
      width: '50px',
      marginLeft: '5px',
      marginRight: '10px'
    },
    priceInfoRowContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
      '&> p': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '&:first-child': {
          width: '75%'
        },
        '&:last-child': {
          width: '25%',
          color: theme.palette.custom.gray.main
        }
      }
    },
    btnCLoseIcon: {
      border: '1px solid #fff',
      borderRadius: 6,
      padding: 2,
      '&&': {
        fontSize: 16
      }
    },
    durationTabContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      // marginBottom: theme.spacing(3),
      // maxWidth: '569px',
      // width: '569px',
      '&>p, &>a': {
        fontSize: 14,
        fontWeight: 400,
        color: '#778CA2',
        paddingBottom: theme.spacing(0.5),
        cursor: 'pointer',
        minWidth: 32,
        textAlign: 'center',
        userSelect: 'none',
        '&.active': {
          boxShadow: `0px 3px ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      }
    },
    subscriptionInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(6),

      '@media(min-width: 1000px)': {
        flexDirection: 'row',

        '& > div:first-child': {
          width: '40%'
        },

        '& > div:last-child': {
          width: '60%'
        }
      }
    },

    paymentInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',

      '@media (min-width: 992px)': {
        '&': {
          flexDirection: 'row'
        },

        '& > div': {
          flex: '1'
        }
      }
    },
    influenceText: {
      fontSize: '9.5px',
      fontWeight: '600',
      fontFamily: 'sans-serif',
      color: '#044fff',
      marginBottom: '-1%',
      whiteSpace: 'nowrap'
    },
    cardInput: {
      marginBottom: '25px'
    },
    addPaymentCardDialog: {
      borderRadius: 20,
      width: 500
    }
  })
)

export default settingStyles
