import React, { useEffect, useState, useMemo } from 'react'
import {
  AlertModal,
  ColoredAvatarGroup,
  ColoredAvatars,
  CustomTooltip,
  LoadingButton,
  PopupMenu
} from 'global/globalComponents'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as CheckCircleFilledIcon } from 'static/svg/check-circle-filled.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { ReactComponent as VerticalThreeDotIcon } from 'static/svg/verticalThreeDot.svg'
import { Button, TextField } from '@material-ui/core'
// import Collaborator from '../Collaborator'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { ReactComponent as InfoIcon } from 'static/svg/info.svg'
import {
  deleteTaskApproval,
  updateTaskApproval
} from 'thunks/approvals/actions'
import clsx from 'clsx'
import { APPROVALS } from 'thunks/approvals/reducer'

const ApprovalRequestCard = ({
  approvalData,
  currentWorkspace,
  removeApprovalById,
  updateApproval
}) => {
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const [approvalDataState, setApprovalDataState] = useState({
    status: '',
    dueDate: '',
    requestTo: [],
    approvalBy: {},
    approvedBy: [],
    notes: ''
  })
  const approvalStatus = useMemo(() => {
    // 1. If no one hasn't submitted their response yet that means the status is 'under approval'
    if (!approvalData.comments?.length) return 'under_approval'

    // 2. If someone rejected that means the status is rejected
    const rejectResponse = approvalData.comments.find(
      (item) => item.status === 'rejected'
    )

    if (rejectResponse) return 'rejected'

    // 3. if requested to length is equal to submitted comment response (and no one rejected, checked above)
    if (approvalData.requestTo.length === approvalData.comments.length)
      return 'approved'

    return 'under_approval'
  }, [approvalData])
  const [responseStatus, setResponseStatus] = useState('')

  const canApprove = useMemo(() => {
    return (
      approvalData.requestTo.includes(meData._id) &&
      !approvalData.approvedBy.includes(meData._id) &&
      approvalStatus === 'under_approval'
    )
  }, [approvalStatus, approvalData, meData._id])

  const [alertModal, setAlertModal] = useState({
    open: false,
    loading: false
  })

  useEffect(() => {
    setApprovalDataState({ ...approvalData })
    setApprovalDataState({
      status: approvalData.status,
      dueDate: approvalData.dueDate,
      requestTo: approvalData.requestTo.map(getWorkspaceTeammateById),
      approvalBy: getWorkspaceTeammateById(approvalData.approvalBy),
      approvedBy: approvalData.approvedBy.map(getWorkspaceTeammateById),
      notes: approvalData.notes
    })
  }, [approvalData])

  const getWorkspaceTeammateById = (teammateId) => {
    const user = [
      ...currentWorkspace.assignedAgencyTeam,
      ...currentWorkspace.assignedClientTeam
    ].find((item) => item.user._id === teammateId)

    return user?.user ?? {}
  }

  const toggleAlertModal = () => {
    if (alertModal.open) {
      setAlertModal((prev) => ({ ...prev, open: false }))
    } else {
      setAlertModal((prev) => ({ ...prev, open: true }))
    }
  }

  const handleDeleteRequest = () => {
    setAlertModal((prev) => ({ ...prev, loading: true }))
    deleteTaskApproval(approvalData._id, (res, err) => {
      if (err) {
        dispatch(fireErrorToaster(res))
        setAlertModal((prev) => ({ ...prev, loading: false }))
      } else {
        removeApprovalById(approvalData._id)
        setAlertModal((prev) => ({ ...prev, open: false, loading: false }))
        dispatch(fireSuccessToaster('Deleted approval request!'))
      }
    })
  }

  const handleSetResponseStatus = (e) => {
    if (Boolean(responseStatus)) {
      setResponseStatus('')
    } else {
      const status = e.currentTarget.dataset.status
      setResponseStatus(status)
    }
  }

  const handleSubmitResponse = (status, comment, cb) => {
    const data = {
      status,
      comment,
      commentedBy: meData._id
    }

    updateTaskApproval(
      approvalData._id,
      {
        approvedBy: [...approvalData.approvedBy, meData._id],
        comments: approvalData.comments
          ? [...approvalData.comments, data]
          : [data]
      },
      (res, err) => {
        cb()
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          handleSetResponseStatus()
          dispatch(fireSuccessToaster('Approval response submitted!'))
          updateApproval(res)
          dispatch({ type: APPROVALS.REMOVE_ONE_TASK, payload: res.task })
        }
      }
    )
  }

  return (
    <>
      <div className="bg-white shadow p-4">
        <div>
          <div className="flex items-start">
            <ColoredAvatars
              user={approvalDataState.approvalBy}
              size="small"
              tooltip={false}
            />
            <div className="ml-3 leading-3 w-full">
              <div className="w-full flex justify-between items-center">
                <div>
                  <div className="flex items-center mb-1">
                    <h6 className="font-semibold text-smaller">
                      {approvalDataState.approvalBy.name}
                    </h6>

                    <span className="text-tiny ml-2 text-gray-500">
                      {new Date(approvalData.createdAt).toLocaleDateString(
                        undefined,
                        {
                          month: 'short',
                          day: 'numeric'
                        }
                      )}
                    </span>
                  </div>
                  <span className="text-gray-400 text-xs">
                    Requested {approvalData.type} approval
                  </span>
                </div>

                {/* Only the creator can delete approval */}
                {approvalData.approvalBy === meData._id && (
                  <PopupMenu
                    targetComp={
                      <button className="text-sm text-gray-500">
                        <VerticalThreeDotIcon className="w-4 h-4" />
                      </button>
                    }
                    menuItems={[
                      {
                        label: 'Delete',
                        method: toggleAlertModal
                      }
                    ]}
                  />
                )}
              </div>

              <div className="mt-6 w-60 text-gray-600">
                <div className="flex items-center text-smaller mb-4">
                  <div className="w-28">
                    Approvers <span className="text-red-400">*</span>
                  </div>

                  <div className="flex text-blue-400">
                    <div className="flex items-center">
                      <ColoredAvatarGroup
                        users={approvalDataState.requestTo}
                        tooltip={true}
                        size="small"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center text-smaller">
                  <div className="w-28">Due Date</div>

                  <div className="flex text-blue-400">
                    {approvalDataState.dueDate && (
                      <span className="text-red-400">
                        {new Date(approvalDataState.dueDate).toLocaleDateString(
                          undefined,
                          {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          }
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <TextField
                  multiline={true}
                  placeholder="Add your short here"
                  fullWidth={true}
                  rows={4}
                  inputProps={{ style: { fontSize: 13 } }}
                  value={approvalDataState.notes}
                  disabled={true}
                />

                <RequestState status={approvalStatus} />
                {approvalData.comments &&
                  approvalData.comments.map((item) => (
                    <UserComment
                      key={item._id}
                      {...item}
                      getWorkspaceTeammateById={getWorkspaceTeammateById}
                    />
                  ))}

                {canApprove &&
                  (responseStatus ? (
                    <div className="mt-4">
                      <CommentArea
                        status={responseStatus}
                        onCancel={handleSetResponseStatus}
                        meData={meData}
                        handleSubmitResponse={handleSubmitResponse}
                      />
                    </div>
                  ) : (
                    <div className="mt-4 space-x-2">
                      <Button
                        onClick={handleSetResponseStatus}
                        data-status="approved"
                        size="small"
                      >
                        Approve
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleSetResponseStatus}
                        data-status="rejected"
                        size="small"
                      >
                        Reject
                      </Button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertModal
        open={alertModal.open}
        handleDialog={toggleAlertModal}
        handleDeleteAction={handleDeleteRequest}
        warningText="Do you want to delete this approval request?"
        deleteBtnText="Delete"
        cancelText="Cancel"
        alertType="error"
        heading="Are you sure"
        loading={alertModal.loading}
        style={{ zIndex: 2000 }}
      />
    </>
  )
}

const UserComment = ({
  commentedBy,
  status,
  comment,
  getWorkspaceTeammateById
}) => {
  const user = useMemo(() => {
    return getWorkspaceTeammateById(commentedBy)
  }, [commentedBy])

  return (
    <div className="my-4">
      <div className="flex space-x-2 item-start">
        <CustomTooltip title={status === 'approved' ? 'Approved' : 'Rejected'}>
          {status === 'approved' ? (
            <CheckCircleFilledIcon className="w-5 h-5 text-green-500 mt-1" />
          ) : (
            <CrossIcon className="w-5 h-5 text-red-500 mt-1 border rounded-full border-red-400" />
          )}
        </CustomTooltip>
        <ColoredAvatars user={user} />
        {comment && (
          <div className="bg-blue-50 flex-1 flex items-center p-2 leading-5">
            <p className="text-smaller text-gray-600">{comment}</p>
          </div>
        )}
        {/* <div className="flex-1">
          {comment && (
            <TextField
              multiline={true}
              fullWidth={true}
              // rows={3}
              inputProps={{ style: { fontSize: 13 } }}
              value={comment}
              disabled={true}
            />
          )}
        </div> */}
      </div>
    </div>
  )
}

const CommentArea = ({ meData, status, onCancel, handleSubmitResponse }) => {
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCommentChange = (e) => {
    setComment(e.currentTarget.value)
  }

  const handleSubmit = () => {
    setLoading(true)
    handleSubmitResponse(status, comment.trim(), () => {
      setLoading(false)
    })
  }

  return (
    <div>
      <div className="flex items-start space-x-2">
        <ColoredAvatars user={meData} size="small" />

        <div className="flex-1">
          <TextField
            multiline={true}
            placeholder="Add your short here"
            fullWidth={true}
            rows={3}
            inputProps={{ style: { fontSize: 13 } }}
            value={comment}
            onChange={handleCommentChange}
            autoFocus
          />

          <div className="flex items-center space-x-2 mt-3">
            <LoadingButton
              size="small"
              onClick={handleSubmit}
              loading={loading}
            >
              {status === 'approved' ? 'Confirm approve' : 'Confirm reject'}
            </LoadingButton>
            <Button onClick={onCancel} variant="outlined" size="small">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const RequestState = ({ status }) => {
  return (
    <div
      className={clsx(
        'p-4 border mt-4 rounded',
        status === 'approved'
          ? 'bg-green-50'
          : status === 'rejected'
          ? 'bg-red-50'
          : 'bg-primary-light'
      )}
    >
      <p className="text-smaller flex items-center">
        <span className="mr-2">
          {status === 'approved' ? (
            <CheckCircleIcon className="w-4 h-4 text-green-400" />
          ) : status === 'rejected' ? (
            <CrossIcon className="w-4 h-4 text-red-400 border rounded-full border-red-400" />
          ) : (
            <InfoIcon className="w-4 h-4 text-primary-main" />
          )}
        </span>
        <span>
          {status === 'approved'
            ? 'Approved'
            : status === 'rejected'
            ? 'Approval rejected'
            : 'Waiting for approval'}
        </span>
      </p>
    </div>
  )
}

export default ApprovalRequestCard
