import clsx from 'clsx'

const CustomModalBody = ({
  children,
  className,
  overflow = 'auto',
  style = {}
}) => (
  <div
    className={clsx('px-6', className)}
    style={{ maxHeight: 500, overflow, ...style }}
  >
    {children}
  </div>
)
export default CustomModalBody
