import React, { useState } from 'react'
import {
  Typography,
  Button,
  Popover,
  makeStyles,
  createStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import customTabStyles from 'global/stlyeClasses/CustomTabStyles'

const ImageUploadPopover = (props) => {
  const dispatch = useDispatch()
  const customTabClasses = customTabStyles()
  const { open, anchorEl, onClose, profileImageChange } = props
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  const handleSwitchActiveTab = (newTab) => {
    setActiveTab(newTab)
  }

  const imageLoadHandler = (e) => {
    // console.log(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    const imgReader = new FileReader()
    imgReader.onloadend = function () {
      if (file) {
        profileImageChange(imgReader.result)
        onClose()
      } else {
        dispatch(fireSuccessToaster('image upload not successful'))
        onClose()
      }
    }
    imgReader.readAsDataURL(file)
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <div className="p-4 relative">
        <div className={`${customTabClasses.tabSwitchContainer}`}>
          {[
            //'Paste image URL',
            'Upload an image'
          ].map((item, index) => (
            <Typography
              key={item + index}
              className={clsx({ active: activeTab === index })}
              onClick={() => handleSwitchActiveTab(index)}
            >
              {item}
            </Typography>
          ))}
        </div>
        {activeTab === 1 ? (
          <div className={classes.textBoxContainer}>
            <input placeholder="enter url" />
            <Button variant="contained" size="small">
              Search by image
            </Button>
          </div>
        ) : (
          <div className="flex gap-4 items-center">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="user-image-uploadbtn"
              multiple={false}
              type="file"
              onChange={imageLoadHandler}
            />

            <label htmlFor="user-image-uploadbtn">
              <Button component="span">Choose File</Button>
            </label>
            <p className="text-sm text-gray-500">No file Chosen</p>
          </div>
        )}
      </div>
    </Popover>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    // tabSwitchContainer: {
    //   display: 'flex',
    //   alignItems: 'center',
    //   gap: theme.spacing(3),
    //   marginBottom: theme.spacing(3),
    //   maxWidth: '569px',
    //   width: '569px',
    //   '&>p': {
    //     fontSize: 14,
    //     fontWeight: 400,
    //     color: '#778CA2',
    //     paddingBottom: theme.spacing(1),
    //     cursor: 'pointer',
    //     '&.active': {
    //       boxShadow: `0px 3px ${theme.palette.primary.main}`,
    //       color: theme.palette.primary.main
    //     }
    //   }
    // },
    textBoxContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5),
      gap: theme.spacing(1),
      border: '1px solid gray',
      borderRadius: theme.shape.borderRadius,
      justifyContent: 'space-between',
      '&>input': {
        width: '70%',
        paddingLeft: theme.spacing(1),
        '&:focus': {
          outline: 'none'
        }
      },
      '&>button': {
        fontSize: 14,
        fontWeight: 400,
        textTransform: 'unset',
        outline: 'none !important'
      }
    }
  })
)

export default ImageUploadPopover
