import { CustomPopper } from 'global/globalComponents'

const InfoPopper = ({ element, data, onClose }) => {
  return (
    <CustomPopper anchorEl={element} onClose={onClose}>
      <div className="w-48 p-3">
        <div className="flex justify-center items-center">
          <p className="text-sm font-semibold text-gray-500 mr-8">
            Late completed Tasks
          </p>
          <span className="text-sm text-gray-900 font-semibold">
            {data.lateCompletedCount}
          </span>
        </div>
      </div>
    </CustomPopper>
  )
}

export default InfoPopper
