import { useEffect, useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSingleClientInvoices } from 'thunks/invoices/actions'
import InvoiceTable from 'components/Invoices/Invoices/InvoiceTable'
import { useHistory } from 'react-router-dom'
import { NoAccess } from 'static/Images'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { Button } from '@material-ui/core'
import { toggleCreateInvoiceModal } from 'thunks/generlAppState/actions'
import CreateInvoiceModal from 'components/Invoices/CreateInvoices/CreateInvoiceModal'

export default function ClientInvoices() {
  const dispatch = useDispatch()
  const { currentClient } = useSelector((state) => state.userClients)
  const access = useSelector((state) => state.permission.data?.access)
  const [invoices, setInvoices] = useState({
    loading: true,
    data: []
  })
  const [selectedTab, setSelectedTab] = useState('all')
  const history = useHistory()
  const [invoiceData, setInvoiceData] = useState({
    paid: [],
    pending: [],
    draft: [],
    overdue: []
  })

  useEffect(() => {
    if (currentClient?._id) {
      fetchSingleClientInvoices(currentClient._id, (res, err) => {
        if (!err) {
          setInvoices({ loading: false, data: res })
        } else {
          setInvoices({ loading: false, data: [] })
        }
      })
    }
  }, [currentClient?._id])

  useEffect(() => {
    const invoiceGroup = {
      paid: [],
      pending: [],
      draft: [],
      overdue: []
    }

    for (let invoice of invoices.data) {
      if (invoiceGroup[invoice.status]) {
        invoiceGroup[invoice.status].push(invoice)
      }
    }

    setInvoiceData(invoiceGroup)
  }, [invoices])

  const openInvoicePreview = (invoice) => {
    history.push(`/agency/payments/client-billing/i/${invoice._id}`)
  }

  const handleEditInvoice = (invoice) => {
    history.push(`/agency/payments/client-billing/i/${invoice._id}/edit`)
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const markAsPaidCallback = (invoice) => {
    setInvoices({
      loading: false,
      data: invoices.data.map((item) => {
        if (item._id === invoice._id) return invoice
        return item
      })
    })
  }

  const deleteInvoiceCallback = (invoiceId) => {
    setInvoices({
      loading: false,
      data: invoices.data.filter((item) => item._id !== invoiceId)
    })
  }

  const handleCreateInvoice = () => {
    dispatch(toggleCreateInvoiceModal('stripe'))
  }

  const handleViewInvoice = (invoice) => {
    history.push(`/agency/payments/client-billing/i/${invoice._id}/edit`)
  }

  return (
    <>
      {currentClient?._id ? (
        <div>
          <div className="text-right">
            {access?.billings.create && (
              <Button
                variant="outlined"
                onClick={handleCreateInvoice}
                startIcon={<AddIcon fontSize="small" />}
              >
                Create Invoice
              </Button>
            )}
          </div>
          <div className="mb-8">
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              {['All', 'Draft', 'Pending', 'Paid', 'Overdue'].map(
                (item, index) => (
                  <Tab label={item} value={item.toLowerCase()} key={index} />
                )
              )}
            </Tabs>
            {/* <div style={{ border: '1px solid #ECEBF5' }}></div> */}
          </div>

          <InvoiceTable
            invoices={
              selectedTab === 'all' ? invoices.data : invoiceData[selectedTab]
            }
            openInvoicePreview={openInvoicePreview}
            handleEditInvoice={handleEditInvoice}
            markAsPaidCallback={markAsPaidCallback}
            deleteInvoiceCallback={deleteInvoiceCallback}
            clientCol={false}
            loading={invoices.loading}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <div
            style={{ width: '100%', maxHeight: '300px' }}
            className="bg-white my-auto"
          >
            <img
              src={NoAccess}
              alt="No Access"
              className="w-full h-full object-contain"
              style={{
                maxWidth: '800px',
                maxHeight: '300px',
                margin: 'auto'
              }}
            />
          </div>
        </div>
      )}
      <CreateInvoiceModal
        currentClient={currentClient}
        handleEditInvoice={handleEditInvoice}
        handleViewInvoice={handleViewInvoice}
      />
    </>
  )
}
