import axios from 'axios'
import { errorMessages, getAuthToken } from 'utils'

/**
 * Create Project Template
 * @param {Function} callback function.
 */
export const createProjectTemplate = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: 'workspacetemplate',
      data
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Fetch Project Template
 * @param {Function} callback function.
 */
export const fetchAllProjectTemplates = async (callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: 'workspacetemplate/agency'
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Fetch Project Template
 * @param {Function} callback function.
 */
export const fetchPublicProjectTemplates = async (callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: 'workspacetemplate/public'
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Update Project Template
 * @param {Function} callback function.
 */
export const updateProjectTemplate = async (id, data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `workspacetemplate/${id}`,
      data
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Delete Project Template
 * @param {Function} callback function.
 */
export const deleteProjectTemplate = async (id, callback) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `workspacetemplate/${id}`
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
