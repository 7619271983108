import axios from 'axios'
import { errorMessages } from 'utils'

/**
 * Coupen validation api call
 * @param {String} coupen copen code
 * @param {Function} callback callback function
 */
export const couponValidation = async (coupen, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/coupen/validate/${coupen}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * AppSumo Coupon validation
 * @param {Object} data {codeStack: string[]}
 * @param {Function} callback () => void
 */
export const appSumoCouponValidation = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/coupen/validate/appsumo',
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (err.response.status === 400) {
      callback(err.response.data, true)
    } else {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}
