import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getClient } from 'thunks/addClient/actions'
import { LOGS } from 'thunks/logs/reducer'
import { SUBTASKS } from 'thunks/subTask/reducers'
import {
  addOneTaskByPusher,
  deletOneTaskByPusher,
  pusherUpdateOneTask
} from 'thunks/task/actions'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import {
  handleUnAssignPersonEvent,
  updateOneWokspaceData,
  handleAssignTeammateEvent,
  updateBoardData
} from 'thunks/workspace/actions'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { getUserPath } from 'utils/userRoles'
import userRoles from 'utils/userRoles'
import { EMBEDS } from 'thunks/embed/reducer'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import WorkspacePusherContext from './WorkspacePusherContext'

const WorkspacesPusherWrapper = (props) => {
  const meData = useSelector((state) => state.me)
  const userWorkspacesData = useSelector((state) => state.userWorkspaces.data)
  const allBoards = useSelector((state) => state.userWorkspaceEmbed.data)
  const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  const { pathname } = useLocation()
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )

  const dispatch = useDispatch()
  const history = useHistory()
  const [socketId, setSocketId] = useState('')
  const [pusherConnected, setPusherConnected] = useState(false)

  useEffect(() => {
    setPusherConnected(Boolean(window.pusherInstance))
  })

  // ============= Pusher Setup (Subscription) =============

  useEffect(() => {
    if (allBoards.length) {
      // 1. Subscribing each available board
      const workspaceBoardChannels = allBoards.map((i) =>
        window.pusherInstance.subscribe(`workspaceView_${i._id}`)
      )

      if (workspaceBoardChannels) {
        workspaceBoardChannels.forEach((ch) => {
          setSocketId(ch.pusher.connection.socket_id)
          if (!ch.subscribed) {
            connectBoardToPusher(ch)
          }
        })
      }
    }
  }, [allBoards.length])

  useEffect(() => {
    if (!window.pusherInstance) return

    // 1. Looking for the channel in the pusher instance (whether it exists or not)
    const myChannel = window.pusherInstance.channel(
      `create_${
        meData?.data?.role === userRoles.USER_AGENCY
          ? meData.data?._id
          : meData.data.team?._id
      }`
    )

    // 2. If channel does not exist or channel does exist but not subscribed then do the following
    if (!myChannel || !myChannel.subscribed) {
      const newCreateChannelSubscribed = window.pusherInstance.subscribe(
        `create_${
          meData?.data?.role === userRoles.USER_AGENCY
            ? meData.data?._id
            : meData.data.team?._id
        }`
      )

      newCreateChannelSubscribed.bind('workspace_add', (data) => {
        // If me (the current user) is not a member of the project then simply return and do nothing
        const myWorkspace = [
          ...data.assignedAgencyTeam,
          ...data.assignedClientTeam
        ].find((item) => item.user._id === meData.data._id)

        if (!myWorkspace) return

        dispatch({
          type: WORKSPACES.PUSHER_ADD_ONE_WORKSPACE,
          payload: data
        })

        dispatch(fetchWorkspacePermissions())

        const wsConnected = window.pusherInstance.subscribe(
          `workspace_${data._id}`
        )
        if (wsConnected) {
          connecteWsToPusher(wsConnected)
        }
      })

      // newCreateChannelSubscribed.bind('subTask_add', (data) => {
      //   // console.log('----subTask_add event captured ----', data)
      //   dispatch({ type: SUBTASKS.ADD_ONE_TASK, payload: data })
      // })

      // newCreateChannelSubscribed.bind('log_add', (data) => {
      //   // console.log('--- log_add event captured --', data)
      //   dispatch({ type: LOGS.POSTED_LOG, payload: data })
      // })

      // newCreateChannelSubscribed.bind('log_delete', (data) => {
      //   // console.log('--- log_delete event captured --', data)
      //   dispatch({ type: LOGS.DELETE_COMMENT, payload: data })
      // })
    }
  }, [meData.data?._id, meData.data.team?._id, pusherConnected])

  useEffect(() => {
    const workspaceChannels = userWorkspacesData.map((i) =>
      window.pusherInstance.subscribe(`workspace_${i._id}`)
    )

    if (workspaceChannels) {
      workspaceChannels.forEach((ch) => {
        if (ch.subscribed) {
          // console.log('already subscribed')
        } else {
          connecteWsToPusher(ch)
        }
      })
    }
  }, [userWorkspacesData.length])

  const pushUserToWorkspaces = () => {
    // only push when user is in workspaces area
    if (window.location.pathname.includes('/workspace')) {
      history.push(`${getUserPath(meData.data.role)}/workspace/all`)
    }
  }

  // ============= Receiving Project (Workspace) Related Event ==============
  const connecteWsToPusher = (wsPusher) => {
    wsPusher.bind('workspace_update', (data) => {
      // console.log('00', data)
      dispatch(updateOneWokspaceData(data))
      // handleUserUnassignEvent(data)
    })
    wsPusher.bind('workspace_delete', (data) => {
      // console.log('00', data)
      dispatch({
        type: WORKSPACES.PUSHER_DELETE_ONE_WORKSPACE,
        payload: data
      })
    })

    wsPusher.bind('workspace_assignUser', (data) => {
      // console.log('00', data)
      dispatch(updateOneWokspaceData(data))
      dispatch(handleAssignTeammateEvent(data))
      dispatch(fetchTeammateOfCurrentUser())
      if (meData.data?.role < userRoles.USER_CLIENT) dispatch(getClient())
      // handleUserUnassignEvent(data)
    })

    wsPusher.bind('workspace_unAssignUser', (data) => {
      // console.log('workspace_unAssignUser event captured', data)
      dispatch(handleUnAssignPersonEvent(data, pushUserToWorkspaces))
      dispatch(fetchTeammateOfCurrentUser())
      if (meData.data?.role < userRoles.USER_CLIENT) dispatch(getClient())
    })
  }

  // ============= Receiving Board Related Event ==============
  const connectBoardToPusher = (boardPusher) => {
    boardPusher.bind('workspaceView_delete', (data) => {
      // console.log('workspaceView_delete event captured', data)

      // If user is on "/board" route and board gets deleted
      if (data._id === currentBoard?._id && pathname.includes('/board')) {
        history.push(
          pathname.split('/board')[0] +
            '/board/' +
            currentWorkspace.defaultBoard._id
        )
      }
      dispatch({ type: EMBEDS.DELETE, payload: data })
    })

    boardPusher.bind('workspaceView_update', (data) => {
      // console.log('00', data)
      dispatch(updateBoardData(data))
    })

    boardPusher.bind('task_add', (data) => {
      // console.log('00', data)
      // broadcast()
      // If current workspace has restrict mode ON then add tasks conditionally
      // Bcoz we don't want agency viewer and editor to see the task recently added

      if (
        meData.data.role === userRoles.USER_AGENCY ||
        meData.data._id === userRoles.AGENCY_ADMIN ||
        meData.data._id === data.user._id
      ) {
        dispatch(addOneTaskByPusher(data))
        return
      }

      if (!currentWorkspace?.restrictMode) {
        dispatch(addOneTaskByPusher(data))
        return
      }

      const teammateObj = [
        ...currentWorkspace.assignedAgencyTeam,
        ...currentWorkspace.assignedClientTeam
      ].find((item) => item.user._id === meData.data._id)

      if (teammateObj) {
        const workspaceRole = teammateObj.role

        if (
          workspaceRole !== userRoles.AGENCY_EDITOR &&
          workspaceRole !== userRoles.AGENCY_VIEWER
        ) {
          dispatch(addOneTaskByPusher(data))
        }
      }
    })

    boardPusher.bind('task_delete', (data) => {
      // console.log('----task_delete event captured ----', data)
      dispatch(deletOneTaskByPusher(data))
    })

    boardPusher.bind('task_update', (data) => {
      // console.log('---- task_update event captured ---', data)
      dispatch(pusherUpdateOneTask(data))
    })

    boardPusher.bind('subTask_update', (data) => {
      // console.log('---- subTask_update event captured ---', data)
      dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: data })
    })

    boardPusher.bind('subTask_delete', (data) => {
      // console.log('-- subTask_delete event captured --', data)
      dispatch({ type: SUBTASKS.DELETE_ONE_TASK, payload: data._id })
    })
  }

  return (
    <WorkspacePusherContext.Provider value={{ socketId }}>
      {props.children}
    </WorkspacePusherContext.Provider>
  )
}

// async function broadcast() {
//   const d = await fetch('https://clientvenue-dev2.herokuapp.com/broadcast', {
//     method: 'GET',
//     headers: {
//       'content-type': 'application/json'
//     }
//   })
// }

export default WorkspacesPusherWrapper
