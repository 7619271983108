import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import newTeammateStyles from './newTeammateStyles'
import Check from '@material-ui/icons/Check'

const roleText = {
  15: 'set as project owner',
  20: 'agency owner',
  21: 'can manage',
  22: 'can edit',
  23: 'can view',
  30: 'client owner',
  31: 'can manage',
  32: 'can edit',
  33: 'can view'
}

const PopOver = ({ handleClose, threeDotProps, mate, anchorEl }) => {
  const classes = newTeammateStyles()
  const firePopper = (item, mate) => {
    handleClose()
    item.name === 'Remove from project'
      ? item.method(mate)
      : item.method(item.role, mate.user?._id)
  }
  return (
    <Popover
      open={Boolean(anchorEl)}
      classes={{ paper: classes.paper }}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <List
        disablePadding
        dense
        style={{ maxWidth: '280px', padding: '5px 0' }}
      >
        {threeDotProps?.map((item, index) => (
          <ListItem
            style={{ padding: '3px 8px' }}
            key={index}
            onClick={() => firePopper(item, mate)}
            button
          >
            {threeDotProps.length > 1 && (
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                {roleText[mate?.role?.value || mate.role] ===
                item?.name?.toLowerCase() ? (
                  <Check fontSize="small" style={{ color: 'crimson' }} />
                ) : null}
              </ListItemIcon>
            )}
            <ListItemText
              classes={{ root: classes.listTextItemRoot }}
              primary={item.name}
              secondary={item.description}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  )
}

export default PopOver
