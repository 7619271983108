export const EMBEDS = {
  LOADING: 'EMBED_LOADING',
  FETCHED: 'EMBED_FETCHED',
  ERROR: 'EMBED_ERROR',
  ADD: 'EMBED_ADD',
  UPDATE: 'EMBED_UPDATE',
  UPDATE_ALL: 'EMBED_UPDATE_ALL',
  DELETE: 'EMBED_DELETE',
  BOARD_SECTION: 'BOARD_SECTION',
  SECTION_UPDATE: 'BOARD_SECTION_UPDATE',
  UPDATE_GROUP_BY: 'UPDATE_GROUP_BY'
}

const initialState = {
  loading: true,
  error: false,
  data: []
}

const embedReducer = (state = initialState, action) => {
  const getCurrentIndex = id => state.data.findIndex(i => i._id === id)
  switch (action.type) {
    case EMBEDS.LOADING:
      return { ...state, loading: true }

    case EMBEDS.ADD:
      let updatedData

      if (action.payload.type === 'board' && action.payload.pinView) {
        updatedData = state.data.map(item => {
          if (item.pinView) {
            return { ...item, pinView: false }
          }
          return item
        })
        updatedData.unshift(action.payload)
      } else {
        updatedData = [action.payload, ...state.data]
      }

      return { ...state, data: updatedData }

    case EMBEDS.UPDATE:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return { ...state, data: updatedData1 }

    case EMBEDS.UPDATE_GROUP_BY:
      const update = {
        ...state,
        data: state.data.map(item =>
          item._id === action.payload._id
            ? { ...item, groupedByDetail: action.payload.groupedByDetail }
            : item
        )
      }

      return update

    case EMBEDS.UPDATE_ALL:
      return { ...state, data: action.payload }

    case EMBEDS.DELETE:
      return {
        ...state,
        data: state.data.filter(item => item._id !== action.payload._id)
      }

    case EMBEDS.FETCHED:
      return { ...state, loading: false, data: action.payload }

    case EMBEDS.ERROR:
      return { ...state, error: true, loading: false }

    case EMBEDS.BOARD_SECTION:
      const updatedData2 = [...state.data]
      updatedData2[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData2
      }

    case EMBEDS.SECTION_UPDATE:
      const updatedData3 = [...state.data]
      const boardIndex = updatedData3.findIndex(
        item => item._id === action.payload.workspaceBoard
      )
      const board = { ...updatedData3[boardIndex] }
      board.boardSection = board.boardSection.map(item => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })

      updatedData3[boardIndex] = board
      return {
        ...state,
        data: updatedData3
      }
    default:
      return state
  }
}

export default embedReducer
