export const ONBOARDING = {
  FETCHED: 'ONBOARDING_FETCHED',
  LOADING: 'ONBOARDING_LOADING',
  ERROR: 'ONBOARDING_ERROR',
  ADD_TEMPLATE: 'ONBOARDING_ADD_TEMPLATE',
  UPDATE_TEMPLATE: 'ONBOARDING_UPDATE_TEMPLATE',
  UPDATE_ALL: 'ONBOARDING_UPDATE_ALL'
}

const initialState = {
  loading: true,
  error: false,
  fetched: false,
  data: []
}

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING.FETCHED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        fetched: true
      }

    case ONBOARDING.ADD_TEMPLATE:
      return { ...state, data: [...state.data, action.payload] }

    case ONBOARDING.UPDATE_TEMPLATE:
      const updatedData = state.data.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload
        }
        return item
      })
      return { ...state, data: updatedData }

    case ONBOARDING.UPDATE_ALL:
      return { ...state, data: action.payload }

    case ONBOARDING.ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}

export default onboardingReducer
