import React, { useState } from 'react'
import { Checkbox } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'

const CheckboxCell = (props) => {
  const {
    value,
    row: { index, values, original },
    column: { id },
    updateDynamicColData,
    taskPermission
  } = props
  const [checked, setChecked] = useState(!!value)

  const handleOnChange = (e) => {
    setChecked(e.currentTarget.checked)
    updateDynamicColData({
      rowIndex: index,
      columnId: id,
      value: e.currentTarget.checked,
      allRowValues: values,
      taskId: original.taskId
    })
  }

  return (
    <div className="text-center">
      <Checkbox
        onChange={handleOnChange}
        checked={checked}
        icon={<CheckBoxOutlineBlank fontSize="small" />}
        checkedIcon={<CheckBox fontSize="small" />}
        disabled={!taskPermission.update}
      />
    </div>
  )
}

export default CheckboxCell
