import getParsedJwt from './parseJwt'
import parseJwt from './parseJwt'
/**
 * Checks if teh user is logged in or not by checking the present auth token in local storage.
 * @returns {Boolean} Logged in status
 */
const checkLogin = () => {
  const cookie = localStorage.getItem('authToken')
  const authToken = cookie ? JSON.parse(cookie) : null
  if (authToken) {
    const currEPOCHtime = Math.round(Date.now() / 1000)
    return authToken.expiresOn > currEPOCHtime
  }
  return false
}

export default checkLogin

/**
 * Store the auth token in the localstorage
 * @param {String} token user auth token
 * @param {String} expireInHours expire time
 * @returns {Boolean} function successfully executed
 */
export const storeToken = (token, expireInHours = 24) => {
  // if token string is empty return false
  if (!token.trim()) return false
  // Current date plus given hours
  //const expiresOn = Date.now() + (expireInHours + (60 * 60 * 1000));
  const obj = parseJwt(token)
  const expiresOn = obj.exp

  // else store token and expiry time in localstorage and return true
  localStorage.setItem(
    'authToken',
    JSON.stringify({
      token,
      expiresOn
    })
  )

  return true
}
