import React from 'react'
// import Card from './Card'
import WorkspaceLogs from '../WorkspaceLogs'
import { userRoles } from 'utils'
import { useSelector } from 'react-redux'
import { Card } from 'global/globalComponents'

const ProjectLogs = ({ elmFor = 'workspace' }) => {
  const meData = useSelector(state => state.me.data)
  let classes = 'project-stats__project-logs self-start'
  if (meData.role < userRoles.USER_CLIENT && elmFor === 'workspace') {
    classes = 'project-stats__project-logs project_logs self-start'
  }

  return (
    <Card className="h-full p-4">
      <div className="h-full">
        <header className="mb-4">
          <h4 className="text-sm font-semibold text-primary-dark">
            What's the status
          </h4>
        </header>
        <div>
          <WorkspaceLogs elmFor={elmFor} />
        </div>
      </div>
    </Card>
  )
}

export default ProjectLogs
