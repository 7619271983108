import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
import { Button } from '@material-ui/core'
import Select, { components } from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector, useDispatch } from 'react-redux'
import {
  getListViewTasks
  // getTaskByWorkspaceBoardId
} from 'thunks/task/actions'
// import { startTaskTimer } from 'thunks/Tracking/action'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { DASHBOARD } from 'thunks/userDashboard/reducer'
// import useTimerFunctions from './useTimerFunctions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { addManualTime } from 'thunks/Tracking/action'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { getTimeSuggestion, triggerOnScrollToBottom, debounce } from 'utils'

const infiniteScroll = triggerOnScrollToBottom()

const units = {
  s: 'seconds',
  m: 'minutes',
  h: 'hours',
  d: 'days',
  mo: 'months',
  y: 'years'
}

const LogTimerModal = ({ onClose, open }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {
    fetchedFirstTime,
    loading: projectsLoading,
    data: allProjects
  } = useSelector(state => state.userWorkspaces)

  const [projectsOptions, setProjectsOptions] = useState([])
  const [taskOptions, setTaskOptions] = useState({
    loading: true,
    data: []
  })
  const [selectedProject, setSelectedProject] = useState({})
  const [selectedTask, setSelectedTask] = useState({})
  const {
    // data: userTasksData,
    // loading: { all: loadingTasks }
    data: userNewTasksData,
    listViewTasks
  } = useSelector(state => state.userTasks)
  const [manualTime, setManualTime] = useState('')
  const [timeOption, setTimeOption] = useState({
    show: false,
    value: ''
  })
  const [manualTimeData, setManualTimeData] = useState({
    start: '',
    end: '',
    date: ''
  })

  const [allTasksData, setAllTasksData] = useState([])
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useLayoutEffect(() => {
    if (open) {
      setSelectedProject({})
      setSelectedTask({})
      setPage(0)
      setManualTime('')
      setTimeOption({
        show: false,
        value: ''
      })
      setManualTimeData({
        start: '',
        end: '',
        date: ''
      })
      setTaskOptions([])
    }
  }, [open])

  useEffect(() => {
    if (allProjects.length) {
      setProjectsOptions(
        allProjects.map(item => ({
          label: item.name,
          value: item._id,
          boardId: item.defaultBoard?._id
        }))
      )
    }
  }, [allProjects])

  useEffect(() => {
    if (selectedProject.boardId) {
      setTaskOptions({ data: [], loading: true })
      setSelectedTask({})
      handleFetchListViewTasks()
    }
  }, [selectedProject.value, search])

  useEffect(() => {
    const tasks = listViewTasks.data
      .filter(taskId => userNewTasksData[taskId])
      .map(taskId => userNewTasksData[taskId])

    setAllTasksData(tasks)
  }, [listViewTasks, userNewTasksData])

  useEffect(() => {
    setTaskOptions({
      loading: false,
      data: allTasksData.map(item => ({
        label: item.title,
        value: item._id
      }))
    })
  }, [allTasksData])

  const handleFetchListViewTasks = (cb, pageCount = 0) => {
    dispatch(
      getListViewTasks(
        {
          workspace: selectedProject.value,
          workspaceBoard: selectedProject.boardId,
          search: search || null
        },
        { limit: 40, page: pageCount, type: 'list' },
        cb
      )
    )
  }

  const handleSelectProject = newVal => {
    setSelectedProject(newVal)
  }

  const handleSelectTask = newVal => {
    setSelectedTask(newVal)
  }

  const handleTimeChange = e => {
    const value = e.target.value.toLowerCase()
    if (value.includes('.')) return
    if (value.includes('-')) return
    setManualTime(value)

    if (value.trim() !== '') {
      let optionVal = ''

      value.replace(/\d+\s?\w{0,2}/gi, match => {
        optionVal += getTimeAndUnitOption(match) + ' '
      })

      if (optionVal !== null) {
        setTimeOption({ show: true, value: optionVal })
      }
    } else {
      setTimeOption({
        show: false,
        value: ''
      })
    }
  }

  const getTimeAndUnitOption = str => {
    return str.replace(/(\d+)\s?(\w{0,2})/, (match, p1, p2) => {
      const key = p2 === 'mo' ? p2 : p2[0]
      return `${parseFloat(p1)} ${units[key] ?? 'seconds'}`
    })
  }

  const handleBlur = e => {
    setTimeout(() => {
      setTimeOption({ show: false, value: '' })
    }, 500)
  }

  const handleSelectTime = () => {
    const { timeStr, totalMs } = getTimeSuggestion(timeOption.value)
    setManualTime(timeStr)
    setManualTimeData(prev => ({
      ...prev,
      end: totalMs
    }))
  }

  const inputDateHandler = e => {
    const { name, value } = e.target

    setManualTimeData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleDatePickerClick = e => {
    e.currentTarget.showPicker()
  }

  const handleSaveManualTime = () => {
    if (!manualTime.trim()) {
      return dispatch(fireErrorToaster('Please enter the time duration'))
    }

    let manualData = { ...manualTimeData }
    let data = {
      category: 'manual',
      type: 'task',
      task: selectedTask.value
    }

    if (!manualData.end) {
      let val
      manualTime.replace(/\d+\s?\w{0,2}/gi, match => {
        val += getTimeAndUnitOption(match) + ' '
      })

      const { totalMs } = getTimeSuggestion(val)
      manualData.end = totalMs
    }

    if (!manualData.date) {
      data.start = new Date().getTime() - manualData.end
      data.end = new Date().getTime()
    } else {
      data.start = new Date(manualData.date).getTime()
      data.end = new Date(manualData.date).getTime() + manualData.end
    }

    setLoading(true)
    dispatch(
      addManualTime(data, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster('Time Added Successfully!'))
          onClose()
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  const loadMoreTasks = e => {
    infiniteScroll(e, {
      cb: activateInfiniteScroll => {
        setTaskOptions(prev => ({ ...prev, loading: true }))
        handleFetchListViewTasks((res, err) => {
          activateInfiniteScroll()
          setTaskOptions(prev => ({ ...prev, loading: false }))
        }, page + 1)
        setPage(prev => prev + 1)
      }
    })
  }

  const handleSearchTasks = (input, eventObj) => {
    if (eventObj.action === 'input-change') {
      debounce(() => {
        setSearch(input)
      }, 300)
    } else if (eventObj.action === 'menu-close') {
      setSearch('')
    }
  }

  return (
    <CustomModal onClose={onClose} open={open} size="small" overflow="visible">
      <CustomModalHeader heading="Add manual time to your task" />
      <CustomModalBody overflow="visible">
        <div className="mb-4">
          <label className="text-sm font-medium text-primary-mid-dark-2 mb-1 inline-block">
            Select Project
          </label>
          <Select
            options={projectsOptions}
            value={selectedProject}
            className="w-full"
            styles={reactSelectCustomStyles}
            onChange={handleSelectProject}
            isLoading={projectsLoading}
          />
        </div>

        {selectedProject && (
          <div className="mb-8">
            <label className="text-sm font-medium text-primary-mid-dark-2 mb-1 inline-block">
              Select Task
            </label>
            <Select
              options={taskOptions.data}
              value={selectedTask}
              className="w-full"
              styles={reactSelectCustomStyles}
              onChange={handleSelectTask}
              isLoading={taskOptions.loading}
              components={{ MenuList: CustomMenu }}
              onInputChange={handleSearchTasks}
              selectProps={{
                onScroll:
                  listViewTasks.metadata.total > listViewTasks.data.length
                    ? loadMoreTasks
                    : undefined
              }}
              maxMenuHeight={200}
            />
          </div>
        )}

        <div className="mb-4">
          <label className="text-sm font-medium text-primary-mid-dark-2 mb-1 inline-block">
            Enter time
          </label>
          <div>
            <input
              type="text"
              name="start"
              placeholder="Enter time e.g. 3 hours 30 mins"
              className="px-4 py-2 border border-gray-300 rounded w-full text-sm"
              value={manualTime}
              onChange={handleTimeChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
          </div>
          {timeOption.show && (
            <div
              onClick={handleSelectTime}
              className={
                'absolute text-black cursor-pointer px-4 py-2 rounded shadow-lg border border-gray-200 bg-white hover:bg-gray-100 text-sm'
              }
            >
              {timeOption.value}
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="text-sm font-medium text-primary-mid-dark-2 mb-1 inline-block">
            When
          </label>
          <div>
            <input
              type="date"
              name="date"
              value={manualTimeData.date}
              className="px-4 py-2 border border-gray-300 rounded inline-block w-full text-sm"
              onChange={inputDateHandler}
              onClick={handleDatePickerClick}
            />
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          loading={loading}
          disabled={!selectedProject.value || !selectedTask.value}
          onClick={handleSaveManualTime}
        >
          Save
        </LoadingButton>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

const CustomMenu = props => {
  return (
    <components.MenuList
      {...props}
      innerProps={{
        ...props.innerProps,
        onScroll: props.selectProps.selectProps.onScroll
      }}
    >
      {props.children}
    </components.MenuList>
  )
}

export default LogTimerModal
