import { Checkbox, FormControlLabel, Button } from '@material-ui/core'
import {
  Add,
  KeyboardArrowDown,
  CheckBox,
  CheckBoxOutlineBlank
} from '@material-ui/icons'
import { useBreadcrumbs } from 'customHooks'
import { LoadingButton } from 'global/globalComponents'
import SectionHeading from 'global/globalComponents/SectionHeading'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { errorMessages, userRoles } from 'utils'
import settingStyles from '../settingStyles'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { PopupMenu } from 'global/globalComponents'
import { updateNotificationsEvents } from 'thunks/emailNotificationEvents/actions'
import {
  fireSuccessToaster,
  fireErrorToaster
} from 'thunks/fireToaster/actions'
import { filter } from 'lodash'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'

const SetNotification = ({ notificationEvents }) => {
  const dispatch = useDispatch()
  // const notificationEvents = useSelector(
  //   (state) => state.emailNotificationEvents.events
  // )
  const classes = settingStyles()
  const [loading, setLoading] = useState(false)
  const [updatedEvents, setUpdatedEvents] = useState([])
  const [updatedData, setUpdatedData] = useState([])

  useEffect(() => {
    setUpdatedEvents(notificationEvents.email)
  }, [notificationEvents])

  const handleCheckFields = (id, isChecked, data) => {
    const index = updatedEvents.findIndex(el => el._id === id)

    const newEvents = updatedEvents[index]
    newEvents.permissions = {
      ...newEvents.permissions,
      [data.role]: {
        ...newEvents.permissions[data.role],
        [data.access]: isChecked
      }
    }
    setUpdatedData(prev => [...prev, newEvents])
    const newUpdatedEvents = [...updatedEvents]
    newUpdatedEvents[index] = newEvents
    setUpdatedEvents(newUpdatedEvents)
  }

  const handleSave = () => {
    setLoading(true)
    dispatch(
      updateNotificationsEvents(
        notificationEvents._id,
        { email: updatedEvents },
        (res, err) => {
          setLoading(false)
          setUpdatedData([])
          if (!err) {
            dispatch(fireSuccessToaster('Notifications Updated Successfully!'))
          } else {
            dispatch(fireErrorToaster(res))
            setUpdatedEvents(notificationEvents.email)
          }
        }
      )
    )
  }

  return (
    <Wrapper>
      <SecondaryHeading>Set User Notifications</SecondaryHeading>

      <TableContainer shadow="none">
        {updatedEvents?.map(event => (
          <>
            <Thead>
              <Th className="text-left capitalize whitespace-nowrap ">
                {event?.name}
              </Th>
              {/* <Th className="text-center whitespace-nowrap">Manager</Th> */}
              <Th className="text-center capitalize whitespace-nowrap ">
                Manager
              </Th>
              <Th className="text-center capitalize whitespace-nowrap">
                Editor
              </Th>
              <Th className="text-center capitalize whitespace-nowrap">
                Viewer
              </Th>
              <Th className="text-center capitalize  whitespace-nowrap">
                Client
              </Th>
            </Thead>
            <Tbody>
              {['update', 'view'].map(el => (
                <Row
                  handleCheckFields={handleCheckFields}
                  access={el}
                  event={event}
                />
              ))}
            </Tbody>
          </>
        ))}
      </TableContainer>
      <div className="text-right nowrap">
        <LoadingButton onClick={handleSave} loading={loading} className="w-56">
          Set Notifications
        </LoadingButton>
      </div>
    </Wrapper>
  )
}

const Row = ({ access, event, handleCheckFields }) => {
  return (
    <>
      <Tr className="">
        <Td className="text-left whitespace-nowrap text-primary-mid-dark font-medium capitalize">
          {access}
        </Td>
        {[
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_EDITOR,
          userRoles.AGENCY_VIEWER,
          userRoles.USER_CLIENT
        ].map(item => (
          <Td className="text-center">
            <FormControlLabel
              onChange={e =>
                handleCheckFields(event._id, e.target.checked, {
                  access: access,
                  role: item
                })
              }
              checked={event.permissions[item][access]}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                />
              }
            />
          </Td>
        ))}
      </Tr>
    </>
  )
}

export default SetNotification
