import React from 'react'
import { Button } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import EditInvoiceFile from '../Invoices/InvoiceEdit/EditInvoiceFile'
import { useHistory } from 'react-router-dom'
import { ArrowLeft } from '@material-ui/icons'
import { useRef } from 'react'
import useInvoiceFunctions from '../Invoices/useInvoiceFunctions'

const EditInvoicePage = ({ handleViewInvoice, invoices }) => {
  const history = useHistory()
  const invoiceFileRef = useRef()
  const { invoiceUpdateLoading, handleUpdateInvoice } = useInvoiceFunctions()

  const handleGoBack = () => {
    history.goBack()
  }

  const handleSaveChanges = () => {
    const {
      editInvoice,
      fromToData,
      invoiceItemsTotal,
      taxDetails,
      expenses,
      invoiceItems
    } = invoiceFileRef.current.getUpdateInvoiceData()

    handleUpdateInvoice({
      editInvoice,
      fromToData,
      invoiceItemsTotal,
      taxDetails,
      expenses,
      invoiceItems,
      cb: (res, err) => {
        handleViewInvoice(res)
      }
    })
  }

  return (
    <div style={{ maxWidth: 650 }} className="mx-auto mt-10 py-8">
      <div className="mb-6 flex items-center justify-between">
        <Button
          variant="outlined"
          startIcon={<ArrowLeft />}
          onClick={handleGoBack}
        >
          Go back
        </Button>
        <LoadingButton
          loading={invoiceUpdateLoading}
          onClick={handleSaveChanges}
        >
          Save Changes
        </LoadingButton>
      </div>
      <EditInvoiceFile invoices={invoices} ref={invoiceFileRef} />
    </div>
  )
}

export default EditInvoicePage
