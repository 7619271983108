import ForgotModal from './ForgotModal'

const ForgotPassword = () => {
  return (
    <ForgotModal
      name="password"
      title="Reset link has been sent to your mail."
      buttonText="Send Reset Password Link"
      link="/login"
    />
  )
}

export default ForgotPassword
