import axios from 'axios'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { fetchMeData } from 'thunks/me/actions'
import { errorMessages, getAuthToken } from 'utils'
import { EMBEDS } from './reducer'
import { postTaskLog } from 'thunks/logs/action'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { TASKS } from 'thunks/task/reducers'
import { WORKSPACES } from 'thunks/workspace/reducers'

/**
 * Generate Embed
 * @param {object} data
 * @param {Function} callback callback function
 */
export const generateEmbed = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      userWorkspaces: { currentWorkspace }
    } = getState()
    try {
      const res = await axios({
        method: 'POST',
        url: `/workspaceview`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: EMBEDS.ADD, payload: res.data })
      dispatch(
        createAccountLogs({
          description: `embed a ${data.type} <strong>${res.data.name}</strong> in the project <strong>${currentWorkspace?.name}</strong>`,
          category: 'workspaceView',
          workspaceView: res.data._id
        })
      )
      dispatch(
        postTaskLog({
          description: `${data.type === 'board' ? 'created' : 'embed'} a ${
            data.type
          } <b>${res.data.name}</b>`,
          activityType: 'log',
          category: 'workspaceView'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch Embed
 * @param {String} id workspace id
 * @param {Function} callback callback function
 */
export const fetchEmbed = (id, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/workspaceview/workspace/${id}`
      })

      dispatch({ type: EMBEDS.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch Embed
 * @param {String} id embed id
 * @param {Function} callback callback function
 */
export const fetchSingleEmbedById = async (id, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspaceview/${id}`
    })

    // dispatch({ type: EMBEDS.FETCHED, payload: res.data })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Update Embed
 * @param {id} updateEmbedId
 * @param {Function} callback callback function
 */
export const updateEmbed = (id, data, callback) => {
  return async (dispatch, getState) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspaceview/${id}`,
        data
      })

      dispatch({ type: EMBEDS.UPDATE, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update Embed
 * @param {String} id board id
 * @param {Array} data array of objects [{name: "groupName", user: meID}]
 * @param {Function} callback callback function
 */
export const saveBoardView = (id, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspaceview/${id}`,
        data: {
          groupedByDetail: data
        }
      })

      dispatch({ type: EMBEDS.UPDATE_GROUP_BY, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete Embed
 * @param {id} deleteEmbedId
 * @param {Function} callback callback function
 */
export const deleteEmbed = (id, callback) => {
  return async (dispatch, getState) => {
    const {
      userWorkspaces: { currentWorkspace }
    } = getState()
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/workspaceview/${id}`
      })

      dispatch({ type: EMBEDS.DELETE, payload: res.data })
      dispatch(fireSuccessToaster('Deleted Successfully'))
      dispatch(
        createAccountLogs({
          description: `deleted a ${res.data.type} <strong>${res.data.name}</strong> in the project <strong>${currentWorkspace?.name}</strong>`,
          activityType: 'log',
          category: 'workspaceView',
          workspaceView: res.data._id
        })
      )
      dispatch(
        postTaskLog({
          description: `deleted embed ${res.data.name}`,
          activityType: 'log',
          category: 'workspaceView'
        })
      )
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * creates new workspace
 * @param {Object} data New section data
 * @param {String} data.label Workspace section label
 * @param {Number} data.order Workspace section label
 * @param {String} data.workSpace Workspace section label
 *
 * @param {Function} callback callback function
 */

export const createWorkspaceSection = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/workspacesection`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: EMBEDS.BOARD_SECTION,
        payload: res.data
      })

      // dispatch({
      //   type: TASKS.UPDATE_STATUSES,
      //   payload: res.data.boardSection
      // })
      dispatch(
        createAccountLogs({
          description: `created a <b>${data.label}</b> section in the board <b>${res.data.name}</b> of the project`,
          category: 'workspace',
          workspace: res.workspace,
          method: 'post'
        })
      )
      dispatch(
        postTaskLog({
          description: `created a <b>${data.label}</b> section in the board <b>${res.data.name}</b>`,
          activityType: 'log',
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update workspace section data
 * @param {String} id Section id
 * @param {Object} data Updating data object
 * @param {Function} callback callback function
 */
export const updateWorkspaceSection = (id, data, title, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `workspacesection/${id}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: EMBEDS.BOARD_SECTION,
        payload: res.data
      })
      // dispatch({
      //   type: TASKS.UPDATE_STATUSES,
      //   payload: res.data.boardSection
      // })
      dispatch(
        createAccountLogs({
          description: `updated the ${
            data.color ? 'color' : 'title'
          } of the ${title} section in the board <b>${
            res.data.name
          }</b> of the project`,
          category: 'workspace',
          workspace: res.workspace,
          method: 'put'
        })
      )
      dispatch(
        postTaskLog({
          description: `updated the ${
            data.color ? 'color' : 'title'
          } of the <b>${title}</b> section in the board <b>${
            res.data.name
          }</b>`,
          activityType: 'log',
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * delete a section order
 * @param {Object} data task data
 * @param {Function} callback callback function
 */

export const deleteSectionOrders = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/workspacesection/${data._id}`
      })

      if (callback) callback(res.data, false)

      dispatch({
        type: EMBEDS.BOARD_SECTION,
        payload: res.data
      })

      dispatch({
        type: TASKS.DELETE_TASK_SECTION,
        payload: { sectionId: data._id }
      })

      // dispatch({
      //   type: TASKS.UPDATE_STATUSES,
      //   payload: res.data.boardSection
      // })

      dispatch(
        createAccountLogs({
          description: `deleted ${data?.label} category from the project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id
        })
      )
      dispatch(
        postTaskLog({
          description: `deleted ${data?.label} category`,
          activityType: 'log',
          workSpace: res.workSpace,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      // dispatch({
      //   type: WORKSPACES.DELETE_ONE_WORKSPACE_SECTION,
      //   payload: [{ name: 'error' }]
      // })
    }
  }
}

/**
 * Mark board as pinned (default)
 * @param {String} id workspace id
 * @param {String} id board id
 * @param {Function} callback callback function
 */

export const markBoardAsPinned = (workspaceId, boardId, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspaceview/update/pinView/${workspaceId}/${boardId}`
      })

      if (callback) callback(res.data, false)

      dispatch({
        type: EMBEDS.UPDATE_ALL,
        payload: res.data
      })

      dispatch({
        type: WORKSPACES.CHANGE_DEFAULT_BOARD,
        payload: res.data.find(item => item.pinView)
      })
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Fetch applied filters of board (default)
 * @param {Object} data op, fields, workspace, workspaceBoard
 * @param {Function} callback callback function
 */
export const fetchBoardFilters = async (boardId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/filter/user/board/${boardId}`
    })

    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Create filter for board tasks (default)
 * @param {Object} data op, fields, workspace, workspaceBoard
 * @param {Function} callback callback function
 */

export const createFilterForBoardTask = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/filter',
      data
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Update board filter
 * @param {String} id filter id
 * @param {Object} data op, fields, workspace, workspaceBoard
 * @param {Function} callback callback function
 */

export const updateBoardFilter = async (filterId, data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/filter/${filterId}`,
      data
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Delete board filter
 * @param {String} id filter id
 * @param {Function} callback callback function
 */

export const deleteBoardFilterById = async (filterId, callback) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/filter/${filterId}`
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Delete board filter
 * @param {String} id board id
 * @param {data} sections updated sections data, _id, label, color, category
 * @param {Function} callback callback function
 */
export const updateBoardStatuses = (boardId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspacesection/category/board/${boardId}`,
        data
      })

      dispatch({
        type: EMBEDS.BOARD_SECTION,
        payload: res.data
      })
      callback(res.data, false)
    } catch (err) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}
