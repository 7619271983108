import React, { useState, useImperativeHandle } from 'react'
import { CustomPopper, LoadingButton } from 'global/globalComponents'
import { CheckBoxField } from 'global/globalComponents'
import { useEffect } from 'react'

const SelectColumnsPopup = React.forwardRef(
  (
    {
      allColOptions,
      anchorEl,
      handleClose,
      toggleColumnsVisibility,
      saveView,
      handleSaveView
    },
    ref
  ) => {
    const [colOptions, setColOptions] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
      setLoading: setLoading
    }))

    useEffect(() => {
      let timer = setTimeout(() => {
        if (search.trim()) {
          setColOptions(
            allColOptions.filter((item) =>
              item.label.toLowerCase().includes(search)
            )
          )
        } else {
          setColOptions([...allColOptions])
        }
      }, [300])

      return () => clearTimeout(timer)
    }, [allColOptions, search])

    // const toggleOpenProjectOptions = () => {
    //   setOpenProjectOptions((prev) => !prev)
    // }

    const handleSearch = (e) => setSearch(e.target.value)

    return (
      <CustomPopper anchorEl={anchorEl} onClose={handleClose}>
        <div
          className="py-4 rounded-xl overflow-y-auto overflow-x-hidden"
          style={{ width: 250, maxHeight: 415 }}
        >
          <header className="px-4 mb-2">
            {/* {openProjectOptions ? (
              <>
                <button
                  className="flex items-center mb-2"
                  onClick={toggleOpenProjectOptions}
                >
                  <ChevronRight className="rotate-180" />
                  <span className="ml-1 text-sm font-bold">Back</span>
                </button>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  className="w-full px-2 py-1 border rounded text-sm"
                  placeholder="Search"
                  autoFocus
                />
              </>
            ) : (
            )} */}
            <h5 className="text-sm mb-2 font-bold">Select Columns</h5>
            <input
              type="text"
              value={search}
              onChange={handleSearch}
              className="w-full px-2 py-1 border rounded text-sm"
              placeholder="Search"
              autoFocus
            />
          </header>

          <ul className="px-4">
            {colOptions.map((item) => (
              <li key={item.value}>
                <CheckBoxField
                  name="toggle column"
                  onChange={(e) => {
                    toggleColumnsVisibility(item.value, e.target.checked)
                  }}
                  checked={item.isChecked}
                  label={item.label.toUpperCase()}
                />
              </li>
            ))}

            {/* {!openProjectOptions && (
              <li
                className="flex items-center text-sm text-gray-700 py-2"
                style={{ marginLeft: -2 }}
              >
                <span>
                  <CheckBoxOutlineBlank
                    fontSize="small"
                    className="opacity-40"
                  />
                </span>
                <div
                  role="button"
                  className="font-medium flex items-center flex-1 cursor-pointer"
                  onClick={toggleOpenProjectOptions}
                  style={{ marginLeft: 9 }}
                >
                  <span>Projects</span>
                  <ChevronRight className="ml-auto" />
                </div>
              </li>
            )} */}
          </ul>
          <hr className="my-2" />

          <div className="px-4 flex justify-end">
            <LoadingButton
              size="small"
              disabled={!saveView}
              onClick={handleSaveView}
              loading={loading}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </CustomPopper>
    )
  }
)

export default SelectColumnsPopup
