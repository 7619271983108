import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, TextField } from '@material-ui/core'
import { createWorkspace } from 'thunks/workspace/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Select from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import 'react-datetime/css/react-datetime.css'
import AsanaIcons from 'static/asana/AsanaIcons'
import clsx from 'clsx'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import { toggleAddWorkspaceSidePanel } from 'thunks/generlAppState/actions'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import UserDashboardStyles from '../userDashboardStyles'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { userRoles } from 'utils'

const colorOptions = [
  '#ffffff',
  '#fb5779',
  '#ff7511',
  '#ffa800',
  '#ffd100',
  '#ace60f',
  '#19db7e',
  '#00d4c8',
  '#48dafd',
  '#0064fb',
  '#6457f9',
  '#9f46e4',
  '#ff78ff',
  '#ff4ba6',
  '#ff93af',
  '#5a7896'
]

const AddWorkSpaceSidePanel = () => {
  const { open, category } = useSelector(
    (state) => state.generalAppState.sidePanel.addWorkspace
  )
  let selectRef = null
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()
  const userTeammates = useSelector((state) => state.userTeammates.data)
  const userWorkspaces = useSelector((state) => state.userWorkspaces)
  const me = useSelector((state) => state.me.data)
  const permission = useSelector((state) => state.permission.data?.access)

  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    startDate: '',
    dueDate: '',
    category: '',
    assignedAgencyTeam: []
  })

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      category: category
    }))
  }, [category])

  const [teammateOptions, setTeammateOptions] = useState({})

  const [invitationLoading, setInvitationLoading] = useState(false)

  const clearValue = () => {
    selectRef?.select.clearValue()
  }
  const handleOncreateNewWorkspace = (e) => {
    e.preventDefault()

    const createdWorkspaces = userWorkspaces.data.map((i) => i.name)
    if (createdWorkspaces.includes(formData.name)) {
      dispatch(fireErrorToaster('Duplicate project not allowed'))
      return
    }

    setInvitationLoading(true)

    dispatch(
      createWorkspace(
        {
          ...formData,
          assignedAgencyTeam: formData.assignedAgencyTeam.map((i) => i.value),
          image: Object.keys(AsanaIcons)[
            Math.floor(Math.random() * Object.keys(AsanaIcons).length)
          ],
          backgroundcolor:
            colorOptions[Math.floor(Math.random() * colorOptions.length)]
        },
        createNewWorkspaceCallback
      )
    )
  }

  const createNewWorkspaceCallback = (res, err) => {
    setInvitationLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      handleDrawerClose()
      clearValue()
      dispatch(fireSuccessToaster('Project Created'))

      // ----- Fetching workspace permission after successfully creating new workspace -----
      dispatch(
        fetchWorkspacePermissions((res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        })
      )
    }
  }

  // const handleSwitch = () => {
  //   setToggleSwitch((prev) => ({
  //     ...prev,
  //     checked: !toggleSwitch.checked
  //   }))
  //   setFormData((prev) => ({
  //     ...prev,
  //     category: prev.category === 'Internal' ? 'External' : 'Internal'
  //   }))
  // }

  const handleAddTeammate = (e) => {
    setFormData((prev) => ({
      ...prev,
      assignedAgencyTeam: e
    }))
  }

  const handleFormInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    const options = userTeammates
      .filter((mate) => mate.role !== userRoles.AGENCY_ADMIN)
      .map((mate) => {
        if (mate._id === me._id) {
          return
        } else {
          return {
            label: mate.name,
            value: mate._id
          }
        }
      })
    setTeammateOptions(options)
  }, [userTeammates])

  const handleDrawerClose = () => {
    setFormData({
      name: '',
      description: '',
      dueDate: '',
      assignedAgencyTeam: []
    })
    dispatch(toggleAddWorkspaceSidePanel())
  }

  const handleSwitch = () => {
    setToggleSwitch((prev) => ({
      ...prev,
      checked: !toggleSwitch.checked
    }))
    setFormData((prev) => ({
      ...prev,
      category: prev.category === 'Internal' ? 'External' : 'Internal'
    }))
  }

  const handleDateChange = (e, prop) => {
    setFormData((prev) => ({ ...prev, [prop]: e?.toISOString() }))
  }

  return (
    <CustomDrawer
      onClose={handleDrawerClose}
      classes={{
        paper: clsx(classes.drawerPaper, { drawerOpen: open })
      }}
      anchor="right"
      open={open}
    >
      <DrawerHeader onClose={() => dispatch(toggleAddWorkspaceSidePanel())}>
        New Project
      </DrawerHeader>
      <DrawerContent open={open}>
        <Box>
          <Box>
            <Typography className={classes.fieldLabel}></Typography>
            <FormLabel required>Project Name</FormLabel>

            <TextField
              className={classes.textFieldStyle}
              onChange={handleFormInputChange}
              value={formData.name}
              placeholder="Enter Name"
              name="name"
              required
              autoFocus
            />
          </Box>

          <Box component="form" onSubmit={handleOncreateNewWorkspace} mt={3}>
            <Typography className={classes.fieldLabel}></Typography>
            <FormLabel>Description</FormLabel>
            <TextField
              multiline
              className={classes.textFieldStyle}
              onChange={handleFormInputChange}
              minRows={4}
              name="description"
              value={formData.description}
              placeholder="Enter Description"
            />
          </Box>

          <Box mt={3}>
            <Typography className={classes.fieldLabel}></Typography>
            <FormLabel>Add Teammates</FormLabel>

            <div className={classes.demo}>
              <Select
                isSearchable={true}
                ref={(ref) => {
                  selectRef = ref
                }}
                options={teammateOptions}
                isMulti
                //value={formData.teammate}
                onChange={handleAddTeammate}
                styles={reactSelectCustomStyles}
                maxMenuHeight="180px"
              />
            </div>
          </Box>

          <Box mt={3}>
            <FormLabel>Start Date</FormLabel>

            <div className={classes.demo}>
              <DatePicker
                onChange={(date) => handleDateChange(date, 'startDate')}
                selected={
                  formData.startDate ? new Date(formData.startDate) : null
                }
                customInput={
                  formData.startDate ? (
                    <input className="text-xs w-full" />
                  ) : (
                    <CalenderIcon className="text-base text-gray-600 cursor-pointer" />
                  )
                }
                placeholderText="Select Date"
                dateFormat="MMMM d, yyyy"
              />
            </div>
          </Box>

          <Box mt={3}>
            <FormLabel>Due Date</FormLabel>

            <div className={classes.demo}>
              <DatePicker
                onChange={(date) => handleDateChange(date, 'dueDate')}
                selected={formData.dueDate ? new Date(formData.dueDate) : null}
                customInput={
                  formData.dueDate ? (
                    <input className="text-xs w-full" />
                  ) : (
                    <CalenderIcon className="text-base text-gray-600 cursor-pointer" />
                  )
                }
                placeholderText="Select Date"
                dateFormat="MMMM d, yyyy"
                minDate={
                  formData.startDate ? new Date(formData.startDate) : null
                }
              />
            </div>
          </Box>

          <Box className="flex items-center justify-between mt-3">
            <p className="text-xs text-gray-700">
              {/* {formData.category === 'Internal'
                ? ' Make this project external to add clients'
                : 'This is an external project'} */}
              Create External Project
            </p>

            <div className="flex items-center gap-1">
              <span className="text-xs font-medium text-gray-700">No</span>
              <ToggleSwitch
                checked={formData.category === 'External'}
                onChange={handleSwitch}
                disableRipple={true}
                loading={toggleSwitch.load}
              />
              <span className="text-sm font-medium text-gray-700">Yes</span>
            </div>
          </Box>
        </Box>
      </DrawerContent>
      <input type="submit" style={{ display: 'none' }} />
      <DrawerFooter className={classes.panelFooter}>
        {/* <Box mt={2} className={classes.saveBtnContainer}> */}
        <Button
          variant="outlined"
          onClick={handleDrawerClose}
          className={classes.cancelBtn}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleOncreateNewWorkspace}
          loading={invitationLoading}
          // type="submit"
          style={{ color: '#fff' }}
          disabled={!formData.name || !permission?.workspace?.create}
        >
          Create
        </LoadingButton>
      </DrawerFooter>
    </CustomDrawer>
  )
}

export default AddWorkSpaceSidePanel
