import { closestIndexTo } from 'date-fns'
import ClientRevenueCard from './ClientRevenueCard'
import CostEfficiencyCard from './CostEfficiencyCard'
import financialReportStyles from './financialReportStyles'
import FinancialSmallCard from './FinancialSmallCard'
import ProjectCostCard from './ProjectCostCard'
import clsx from 'clsx'
import { ReactComponent as CostInputIcon } from 'static/svg/cost-input.svg'
import { ReactComponent as RevenueIcon } from 'static/svg/revenue.svg'
import { ReactComponent as SackDollar } from 'static/svg/sack-dollar.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { fetchCustomInvoices } from 'thunks/invoices/actions'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { fetchClientRevenue } from 'thunks/reports/actions'

const FinancialCardsContainer = () => {
  const classes = financialReportStyles()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const clientInvoices = useSelector(state => state.clientInvoices)
  const financials = useSelector(state => state.reports.financials)

  const pendingInvoices = useMemo(() => {
    if (clientInvoices.data.length) {
      return clientInvoices.data.filter(invoice => invoice.status === 'pending')
        .length
    } else {
      return 0
    }
  }, [clientInvoices.data])
  const agencyCurrency = useMemo(() => {
    return (
      meData.profile.defaultCurrency?.toUpperCase() ??
      meData.team?.profile?.defaultCurrency?.toUpperCase() ??
      'USD'
    )
  }, [meData])

  const revenue = useMemo(() => {
    const dt = new Date()
    const startDateOfMonth = new Date(dt.getFullYear(), dt.getMonth(), 1)
    const endDateOfMonth = new Date(dt.getFullYear(), dt.getMonth() + 1, 0)
    return clientInvoices.data
      .filter(
        invoice =>
          invoice.status === 'paid' &&
          invoice.currency?.toLowerCase() === agencyCurrency?.toLowerCase() &&
          new Date(invoice.issuedDate).getTime() >=
            new Date(startDateOfMonth).getTime() &&
          new Date(invoice.issuedDate).getTime() <=
            new Date(endDateOfMonth).getTime()
      )
      .reduce((acc, obj) => acc + obj.totalAmount, 0)
  }, [clientInvoices.data])

  useEffect(() => {
    if (!clientInvoices.fetched) {
      dispatch(fetchCustomInvoices())
    }
  }, [clientInvoices.fetched])

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userWorkspaces.fetchedFirstTime])

  useEffect(() => {
    if (!financials.fetched) {
      const dt = new Date()
      const startDateOfMonth = new Date(
        dt.getFullYear(),
        dt.getMonth(),
        1
      ).toISOString()
      const endDateOfMonth = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        0
      ).toISOString()
      const query = `startDate=${startDateOfMonth}&endDate=${endDateOfMonth}`
      dispatch(fetchClientRevenue(query))
    }
  }, [financials.fetched])

  return (
    <div className={clsx('text-smaller lg:text-sm', classes.container)}>
      <div className={clsx(classes.smallCards, classes.inputCost)}>
        <FinancialSmallCard
          icon={<CostInputIcon className="w-8 h-8" />}
          title="This month's input cost"
          revenue={financials.data?.avgInputCost?.toFixed(2) ?? 0}
          currency={agencyCurrency ?? 'USD'}
          loading={financials.loading}
        />
      </div>
      <div className={clsx(classes.smallCards, classes.revenue)}>
        <FinancialSmallCard
          icon={<SackDollar className="w-8 h-8" />}
          title="Revenue this month"
          revenue={revenue}
          currency={agencyCurrency ?? 'USD'}
          loading={clientInvoices.loading}
        />
      </div>
      <div className={clsx(classes.smallCards, classes.invoicePending)}>
        <FinancialSmallCard
          icon={<RevenueIcon className="w-8 h-8" />}
          title="Pending Invoices"
          invoices={pendingInvoices}
          loading={clientInvoices.loading}
        />
      </div>
      <div className={classes.clientRevenue}>
        <ClientRevenueCard
          clientwiseRevenue={financials.data.clientwiseRevenue}
          loading={financials.loading}
          currency={agencyCurrency ?? 'USD'}
        />
      </div>
      {/* <div className={classes.costEfficiency}>
        <CostEfficiencyCard />
      </div> */}
      {/* <div className={clsx(classes.smallCards, classes.costPerTask)}>
        <FinancialSmallCard
          icon={<CostInputIcon className="w-8 h-8" />}
          title="Cost per task"
          revenue={3644694}
        />
      </div> */}

      <div className={classes.costPerProject}>
        <ProjectCostCard
          projects={userWorkspaces.data}
          loading={userWorkspaces.loading}
          currency={agencyCurrency ?? 'USD'}
        />
      </div>
      {/* <div className={clsx(classes.smallCards, classes.averageCost)}>
        <FinancialSmallCard
          icon={<CostInputIcon className="w-8 h-8" />}
          title="Average Cost"
          revenue={3644694}
        />
      </div> */}
    </div>
  )
}

export default FinancialCardsContainer
