import { TextField } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import SectionHeading from 'global/globalComponents/SectionHeading'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { useEffect, useState } from 'react'
import { updateUser } from 'thunks/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import StripeMessage from 'global/globalComponents/StripeMessage/StripeMessage'
import LockedFeatureOverlay from 'components/LockedFeatureOverlay/LockedFeatureOverlay'
import { useBreadcrumbs } from 'customHooks'

const Portal = () => {
  const dispatch = useDispatch()

  const userData = useSelector((state) => state.user.data)
  const userActivePlan = useSelector((state) => state.userActivePlanInfo.data)

  const [loading, setLoading] = useState(false)
  const [scriptsData, setScriptsData] = useState({
    headerScript: '',
    bodyScript: '',
    footerScript: ''
  })

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Portal',
      link: `/settings/portal`,
      index: 1
    }
  ])

  useEffect(() => {
    setScriptsData({
      headerScript: userData.headerScript,
      bodyScript: userData.bodyScript,
      footerScript: userData.footerScript
    })
  }, [userData])

  const handleScriptInput = (e) => {
    const { name, value } = e.target
    setScriptsData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSaveChanges = () => {
    setLoading(true)
    dispatch(
      updateUser(scriptsData, () => {
        setLoading(false)
      })
    )
  }

  return (
    <div className="relative" style={{ maxWidth: 700 }}>
      <SectionHeading>Portal</SectionHeading>
      <div>
        <FormLabel htmlFor="header-script">Header Script</FormLabel>
        <TextField
          id="header-script"
          className="w-full bg-white"
          multiline
          value={scriptsData.headerScript}
          rows={4}
          name="headerScript"
          onChange={handleScriptInput}
        />
      </div>
      <StripeMessage stripProps="p-1 w-full">
        These scripts will be Pasted in the <code>{'<head>'}</code> section
      </StripeMessage>
      <div className="mt-6">
        <FormLabel htmlFor="body-script">Body Script</FormLabel>
        <TextField
          id="body-script"
          className="w-full bg-white"
          multiline
          value={scriptsData.bodyScript}
          name="bodyScript"
          onChange={handleScriptInput}
          rows={4}
        />
        <StripeMessage stripProps="p-1 w-full">
          These scripts will be Pasted just below the opening{' '}
          <code>{'<body>'}</code> tag
        </StripeMessage>
      </div>
      <div className="mt-6">
        <FormLabel htmlFor="footer-script">Footer Script</FormLabel>
        <TextField
          id="footer-script"
          name="footerScript"
          className="w-full bg-white"
          onChange={handleScriptInput}
          value={scriptsData.footerScript}
          multiline
          rows={4}
        />
        <StripeMessage stripProps="p-1 w-full">
          These scripts will be Pasted in the <code>{'<footer>'}</code> section
        </StripeMessage>
      </div>

      <div className="mt-4">
        <LoadingButton loading={loading} onClick={handleSaveChanges}>
          Save Changes
        </LoadingButton>
      </div>
      <LockedFeatureOverlay active={userActivePlan?.name?.includes('Lite')} />
    </div>
  )
}

export default Portal
