import { TextField } from '@material-ui/core'
import { FormLabel } from 'global/globalComponents'

const TemplateFormField = ({
  label,
  subLabel,
  index,
  error,
  field,
  ...rest
}) => (
  <div>
    <FormLabel required={subLabel.toLowerCase() === 'required'}>
      {label}
    </FormLabel>
    {/* <span className="block text-xs text-gray-500 mb-1">{subLabel}</span> */}
    {label === 'Description' ? (
      <TextField
        fullWidth={true}
        multiline
        rows={4}
        className={`border w-full rounded p-2 text-sm ${
          error ? 'border-red-400' : 'border-gray-300'
        }`}
        {...rest}
        inputProps={{ 'data-field': field }}
      />
    ) : (
      <TextField
        fullWidth={true}
        className={`border w-full rounded p-2 text-sm ${
          error ? 'border-red-400' : 'border-gray-300'
        }`}
        {...rest}
        inputProps={{ 'data-field': field }}
      />
    )}
    {error && (
      <span className="text-xs text-red-400">This field is required!</span>
    )}
  </div>
)

export default TemplateFormField
