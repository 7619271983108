import { Button } from '@material-ui/core'
import {
  Add,
  RemoveRedEyeOutlined,
  KeyboardArrowDown
} from '@material-ui/icons'
import { PopupMenu } from 'global/globalComponents'
import SectionHeading from 'global/globalComponents/SectionHeading'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fireWarningToaster } from 'thunks/fireToaster/actions'

const ServiceHeader = ({
  services,
  handlePreviewModal,
  handleOpenNewServiceModal,
  openCategoriesModal
}) => {
  const permission = useSelector((state) => state.permission)
  const meData = useSelector((state) => state.me.data)
  const dispatch = useDispatch()

  const handleAddService = () => {
    if (
      (meData?.profile?.quota?.service?.left ??
        meData?.team.profile?.quota?.service?.left) <= 0
    ) {
      dispatch(
        fireWarningToaster(
          'Your service quota has been exhausted. Please upgrade your plan to add new services'
        )
      )
    } else {
      handleOpenNewServiceModal()
    }
  }

  return (
    <header className="flex justify-end items-center mb-6">
      {/* <div>
        <SectionHeading>Services</SectionHeading>
      </div> */}

      <div className="flex items-center gap-4">
        {services.length ? (
          <Button
            variant="outlined"
            startIcon={<RemoveRedEyeOutlined size="small" />}
            onClick={handlePreviewModal}
          >
            Preview
          </Button>
        ) : null}
        {permission.data.access?.services.create && (
          <PopupMenu
            menuItems={[
              { label: 'Add Service', method: handleAddService },
              { label: 'Categories', method: openCategoriesModal }
            ]}
            targetComp={
              <Button endIcon={<KeyboardArrowDown />}>Service Options</Button>
            }
          />
        )}
      </div>
    </header>

    // <header className="flex justify-between items-center mb-8">
    //       <div className="flex items-center">
    //         <SectionHeading>Services</SectionHeading>
    //         {me.role === userRoles.AGENCY_MANAGER && (
    //           <a
    //             href="https://youtu.be/fSdfbq7ZTJE"
    //             target="_blank"
    //             className="pl-1"
    //             rel="noreferrer"
    //           >
    //             <SvgIcon
    //               component={PlayCircle}
    //               style={{
    //                 color: '#1600e5',
    //                 fontSize: '1rem',
    //                 marginBottom: '3px'
    //               }}
    //               className="cursor-pointer"
    //               viewBox="0 0 600 476.6"
    //             />
    //           </a>
    //         )}
    //       </div>

    //       <div className="flex items-center gap-4">
    //         {visibleServices.length ? (
    //           <Button
    //             variant="outlined"
    //             startIcon={<RemoveRedEyeOutlined size="small" />}
    //             onClick={handlePreviewModal}
    //           >
    //             Preview Services
    //           </Button>
    //         ) : null}
    //         {permission.data.access?.services.create && (
    //           <LoadingButton
    //             onClick={
    //               addServiceValid
    //                 ? handleCreateServicePanel
    //                 : serviceValidMessage
    //             }
    //             startIcon={<Add fontSize="small" />}
    //           >
    //             New Service
    //           </LoadingButton>
    //         )}
    //       </div>
    //     </header>
  )
}

export default ServiceHeader
