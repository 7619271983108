import { useState, useEffect } from 'react'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { fetchUserTimesheets } from 'thunks/timesheets/action'
import NestedTimesheetTable from './NestedTable'
import { startOfDay } from 'date-fns'
import { ArrowRight } from '@material-ui/icons'
import clsx from 'clsx'
import timesheetStyles from './timesheetStyles'
import { ReactComponent as Pen } from 'static/svg/edit.svg'
import ManualTimeEntry from './ManualTimeEntry'
import getTimeString from './TimesheetFunctions'
import { useSelector } from 'react-redux'
import { CustomTooltip } from 'global/globalComponents'

const MyTimesheets = ({
  loading,
  userId,
  timesheetData,
  setTimesheetData,
  selectedDays,
  startTime,
  endTime,
  // addNewTimesheetData,
  elmFor,
  setDownloadData
}) => {
  const classes = timesheetStyles()
  const meData = useSelector(state => state.me.data)
  const [expanded, setExpanded] = useState('')
  const [manualData, setManualData] = useState({
    anchorEl: null,
    data: {}
  })

  useEffect(() => {
    const downloadedData = []
    timesheetData?.forEach((project, index) => {
      let taskData = project.tasks?.map((item, index) => ({
        Project: project.name ?? 'Deleted Project (Project has been deleted)',
        Task: item.title ?? 'Deleted Task (Task has been deleted)',
        'Total Time': getNestedTimeOfProject(
          item.data?.filter(
            time =>
              time.start >= new Date(startTime).getTime() &&
              time.start <= new Date(endTime).getTime()
          )
        ),
        ...timesheetDataFormat(item)
      }))
      downloadedData.push(...taskData)
    })
    setDownloadData(prev => ({ ...prev, data: downloadedData }))
  }, [timesheetData])

  const timesheetDataFormat = item => {
    let data = {}
    selectedDays.forEach(
      day => (data[day] = getNestedTimeByDay(item.data, day) || '')
    )
    return data
  }

  const getTimeOfProject = project => {
    let data = []
    project.tasks.forEach(item => {
      data = [...data, ...item.data]
    })
    const time = data
      .filter(
        time =>
          time.start >= new Date(startTime).getTime() &&
          time.start <= new Date(endTime).getTime()
      )
      .reduce((acc, obj) => acc + obj.duration, 0)

    return getTimeString(time / 1000)
  }

  const getTimeByDay = (project, day) => {
    let data = []
    project.tasks.forEach(item => {
      data = [...data, ...item.data]
    })
    const dayData = data.filter(el => new Date(el.start).toDateString() === day)
    const time = dayData.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  const getNestedTimeOfProject = data => {
    const time = data.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  const getNestedTimeByDay = (data, day) => {
    const dayData = data.filter(el => new Date(el.start).toDateString() === day)
    const time = dayData.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  const handleListExpansion = id => {
    if (expanded === id) {
      setExpanded('')
    } else {
      setExpanded(id)
    }
  }

  const handlePopup = (e, data) => {
    e.stopPropagation()
    if (!manualData.anchorEl) {
      setManualData(prev => ({ ...prev, anchorEl: e.currentTarget, data }))
    } else {
      setManualData(prev => ({ ...prev, anchorEl: null, data: {} }))
    }
  }

  const addNewTimesheetData = (wsId, taskId, newData) => {
    setTimesheetData(prev => ({
      ...prev,
      data: prev.data.map(entry => {
        if (entry._id === wsId) {
          let newTasksData = [...entry.tasks]
          const taskObj = entry.tasks.find(task => task._id === taskId)
          if (taskObj) {
            newTasksData = entry.tasks.map(task => {
              if (task._id === taskId) {
                return { ...task, data: [...task.data, newData] }
              } else {
                return task
              }
            })
          } else {
            newTasksData = [
              ...entry.tasks,
              {
                _id: newData.task?._id,
                title: newData.task?.title,
                data: [newData]
              }
            ]
          }

          return { ...entry, tasks: newTasksData }
        }
        return entry
      })
    }))
  }

  const deleteOneTimeEntry = (wsId, taskId, newData) => {
    console.log(taskId, 'taskId', newData, 'newdata', wsId)
    setTimesheetData(prev => ({
      ...prev,
      data: prev.data.map(entry => {
        if (entry._id === wsId) {
          let newTasksData = [...entry.tasks]
          const taskObj = entry.tasks.find(task => task._id === taskId)
          if (taskObj) {
            newTasksData = entry.tasks.map(task => {
              if (task._id === taskId) {
                return {
                  ...task,
                  data: [...task.data].filter(el => el._id !== newData._id)
                }
              } else {
                return task
              }
            })
          }
          return { ...entry, tasks: newTasksData }
        }
        return entry
      })
    }))
  }

  return (
    <>
      <TableContainer mb={2} shadow="none">
        <Thead>
          <Th className="w-44 whitespace-nowrap">Projects</Th>
          <Th center className="capitalize">
            Total Time
          </Th>
          {selectedDays?.map(day => (
            <Th
              className={
                clsx()
                // 'text-right'
                // manualData.data.day === day && `${classes.activeCell}`
              }
            >
              <span className="block font-medium capitalize">
                {day.split(' ')[0]}
              </span>
              <span className="font-base tect-xs text-gray-500 capitalize">
                {day.split(' ').slice(1, 3).join(' ')}
              </span>
            </Th>
          ))}
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={9} />
          ) : timesheetData?.length > 0 ? (
            timesheetData?.map((item, index) => (
              <>
                <Tr>
                  <Td className="pl-1 whitespace-nowrap flex items-center space-x-1 text-custom-table-primaryText">
                    <span
                      onClick={() => handleListExpansion(item._id)}
                      className={clsx(
                        {
                          expanded: expanded === item._id
                        },
                        classes.btn
                      )}
                    >
                      <ArrowRight />
                    </span>
                    {item.name ? (
                      <span>{item.name}</span>
                    ) : (
                      <span className="text-gray-500">Deleted</span>
                    )}
                  </Td>
                  <Td className="text-center text-xs">
                    {getTimeOfProject(item)}
                  </Td>
                  {selectedDays.map(day => (
                    <Td
                      className={clsx(
                        'px-4 py-2 text-xs text-left',
                        classes.editTime,
                        {
                          active:
                            manualData.data.sheetData?._id === item._id &&
                            manualData.data.day === day
                        }
                      )}
                    >
                      <div className="flex items-center">
                        <div className="text-xs">
                          {getTimeByDay(item, day) ? (
                            <span className="mr-2">
                              {getTimeByDay(item, day)}
                            </span>
                          ) : (
                            !(userId === meData._id && item._id) && '-'
                          )}
                        </div>
                        {userId === meData._id && item._id && (
                          <CustomTooltip title="Add multiple time entries">
                            <div
                              className="pen opacity-1 cursor-pointer"
                              onClick={e =>
                                handlePopup(e, {
                                  sheetData: item,
                                  day: day
                                })
                              }
                            >
                              <Pen className="text-primary-mid-dark-2" />
                            </div>
                          </CustomTooltip>
                        )}
                      </div>
                    </Td>
                  ))}
                </Tr>
                {expanded === item._id && (
                  <NestedTimesheetTable
                    timesheetData={item.tasks}
                    selectedDays={selectedDays}
                    startTime={startTime}
                    endTime={endTime}
                  />
                )}
              </>
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                No Data Available
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      <ManualTimeEntry
        anchorEl={manualData.anchorEl}
        onClose={handlePopup}
        data={manualData.data}
        addNewTimesheetData={addNewTimesheetData}
        deleteOneTimeEntry={deleteOneTimeEntry}
      />
    </>
  )
}

export default MyTimesheets
