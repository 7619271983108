import { useEffect, useState } from 'react'
import { Popover, TextField, Button, CircularProgress } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import Select, { components } from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useDispatch, useSelector } from 'react-redux'
import {
  getListViewTasks,
  getTaskByWorkspaceBoardId
} from 'thunks/task/actions'
import { addManualTime, deleteTimeTracked } from 'thunks/Tracking/action'
import clsx from 'clsx'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import timesheetStyles from './timesheetStyles'
import { CustomPopper, LoadingButton } from 'global/globalComponents'
import Check from '@material-ui/icons/Check'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { debounce, triggerOnScrollToBottom } from 'utils'

const infiniteScroll = triggerOnScrollToBottom()

const ManualTimeEntry = ({
  onClose,
  anchorEl,
  data,
  addNewTimesheetData,
  deleteOneTimeEntry
}) => {
  const classes = timesheetStyles()
  const dispatch = useDispatch()
  const {
    // data: userTasksData,
    // loading: { all: loadingTasks }
    data: userNewTasksData,
    listViewTasks
  } = useSelector(state => state.userTasks)
  const [manualTimeData, setManualTimeData] = useState([
    {
      start: '',
      end: '',
      type: 'task',
      task: '',
      category: 'manual',
      save: false
    }
  ])
  const [taskOptions, setTaskOptions] = useState([])
  const [addNewEntry, setAddNewEntry] = useState(false)
  const [loading, setLoading] = useState(null)
  const [allTasksData, setAllTasksData] = useState([])
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (data.sheetData?._id) {
      setTaskOptions({ data: [], loading: true })
      handleFetchListViewTasks()
      // dispatch(getTaskByWorkspaceBoardId(data.sheetData?.defaultBoard))
    }
  }, [data.sheetData?._id, search])

  useEffect(() => {
    const tasks = listViewTasks.data
      .filter(taskId => userNewTasksData[taskId])
      .map(taskId => userNewTasksData[taskId])

    setAllTasksData(tasks)
  }, [listViewTasks, userNewTasksData])

  useEffect(() => {
    setTaskOptions({
      data: allTasksData.map(item => ({
        ...item,
        label: item.title,
        value: item._id
      })),
      loading: false
    })
  }, [allTasksData])

  // useEffect(() => {
  //   if (
  //     manualTimeData.filter(
  //       (entry) => !entry.task || !entry.start || !entry.end
  //     ).length
  //   ) {
  //     setAddNewEntry(false)
  //   } else {
  //     setAddNewEntry(true)
  //   }
  // }, [manualTimeData])

  const handleFetchListViewTasks = (cb, pageCount = 0) => {
    dispatch(
      getListViewTasks(
        {
          workspace: data.sheetData._id,
          workspaceBoard: data.sheetData.defaultBoard,
          search: search || null
        },
        { limit: 40, page: pageCount, type: 'list' },
        cb
      )
    )
  }

  const handleSelectTask = (name, input, idx) => {
    setManualTimeData(prev =>
      prev.map((entry, index) => {
        if (index === idx) {
          return {
            ...entry,
            [name]: input
          }
        } else {
          return entry
        }
      })
    )
  }

  const handleAddNewEntry = () => {
    setManualTimeData(prev => [
      ...prev,
      {
        start: '',
        end: '',
        type: 'task',
        task: '',
        category: 'manual',
        save: false
      }
    ])
    setAddNewEntry(false)
  }

  const handleSave = index => {
    let entry = manualTimeData[index]
    if (!entry.start || !entry.end || !entry.task.value) {
      return dispatch(fireErrorToaster('Please select valid time and task'))
    }
    if (
      new Date(`${data.day} ${entry.start}`).getTime() >
      new Date(`${data.day} ${entry.end}`).getTime()
    ) {
      return dispatch(
        fireErrorToaster('End time should be greater than start time')
      )
    }
    delete entry.save
    entry = {
      ...entry,
      start: new Date(`${data.day} ${entry.start}`).getTime(),
      end: new Date(`${data.day} ${entry.end}`).getTime(),
      task: entry.task.value
    }
    setLoading(index.toString())
    dispatch(
      addManualTime(entry, (res, err) => {
        if (!err) {
          setLoading(null)
          dispatch(fireSuccessToaster('Time Saved Successfully!'))
          setAddNewEntry(true)
          setManualTimeData(prev =>
            prev.map((item, ind) => {
              if (ind === index) {
                return {
                  ...item,
                  _id: res._id,
                  save: true
                }
              } else {
                return { ...item }
              }
            })
          )
          addNewTimesheetData(data.sheetData._id, entry.task, res)
        } else {
          setLoading(null)
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  const handleDeleteManualEntryTime = entry => {
    dispatch(
      deleteTimeTracked(entry._id, 'timesheet', (res, err) => {
        if (!err) {
          setManualTimeData(prev => prev.filter(item => item._id !== res._id))
          deleteOneTimeEntry(data.sheetData._id, entry.task._id, res)
          if (manualTimeData.length === 1) {
            handleAddNewEntry()
          }
          dispatch(fireSuccessToaster('Time Deleted Successfully!'))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  const handleClose = e => {
    setManualTimeData([
      {
        start: '',
        end: '',
        type: 'task',
        task: '',
        category: 'manual',
        save: false
      }
    ])
    onClose(e)
    setAddNewEntry(false)
  }

  const loadMoreTasks = e => {
    infiniteScroll(e, {
      cb: activateInfiniteScroll => {
        setTaskOptions(prev => ({ ...prev, loading: true }))
        handleFetchListViewTasks((res, err) => {
          activateInfiniteScroll()
          setTaskOptions(prev => ({ ...prev, loading: false }))
        }, page + 1)
        setPage(prev => prev + 1)
      }
    })
  }

  const handleSearchTasks = (input, eventObj) => {
    if (eventObj.action === 'input-change') {
      debounce(() => {
        setSearch(input)
      }, 300)
    } else if (eventObj.action === 'menu-close') {
      setSearch('')
    }
  }

  return (
    <CustomPopper onClose={handleClose} anchorEl={anchorEl}>
      <div style={{ maxWidth: '600px' }}>
        <header
          className="p-4 flex justify-between items-center whitespace-nowrap space-x-2"
          style={{ backgroundColor: '#e8ecee' }}
        >
          <div className="flex flex-col">
            <span className="capitalize font-medium text-sm">
              {data.sheetData?.name}
            </span>
            <span className="text-gray-500 text-xs">{data.day}</span>
          </div>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon size="small" fontSize="small" />
          </IconButton>
        </header>
        <div
          style={{
            maxHeight: '220px',
            overflow: manualTimeData.length > 3 ? 'scroll' : 'visible'
          }}
        >
          <div className="py-2 px-4 flex w-full">
            <p className="text-xs pr-2 text-primary-dark font-base w-1/2">
              Time Tracked
            </p>
            {/* <p className="text-xs pl-2 text-primary-dark font-base w-1/2">
              Select Task
            </p> */}
          </div>
          {manualTimeData.map((entry, idx) => (
            <div className="pt-2 pb-1 px-4 w-full flex items-start justify-between">
              <div className="pr-2">
                <div className="flex items-center space-x-2">
                  <TextField
                    // className="w-23"
                    type="time"
                    name="start"
                    value={entry.start}
                    onChange={e =>
                      handleSelectTask('start', e.target.value, idx)
                    }
                    disabled={entry.save}
                    // onBlur={() => handleSave(idx)}
                  />
                  <span>to</span>
                  <TextField
                    // className="w-23"
                    type="time"
                    name="end"
                    value={entry.end}
                    onChange={e => handleSelectTask('end', e.target.value, idx)}
                    disabled={entry.save}
                  />
                </div>
              </div>

              <div className="pr-2">
                <Select
                  menuPlacement="auto"
                  styles={reactSelectCustomStyles}
                  options={taskOptions.data}
                  value={entry.task}
                  onChange={e => handleSelectTask('task', e, idx)}
                  placeholder="Select Task"
                  className="border-0 border-none w-52"
                  isDisabled={entry.save}
                  isLoading={taskOptions.loading}
                  components={{ MenuList: CustomMenu }}
                  onInputChange={handleSearchTasks}
                  selectProps={{
                    onScroll:
                      listViewTasks.metadata.total > listViewTasks.data.length
                        ? loadMoreTasks
                        : undefined
                  }}
                />
              </div>
              <div className="mt-1">
                {!entry.save ? (
                  loading !== idx.toString() ? (
                    <button
                      disabled={!entry.start || !entry.end || !entry.task.value}
                      // className={}
                    >
                      <IconButton size="small">
                        <Check
                          className=" text-primary-main "
                          fontSize="small"
                          onClick={() => handleSave(idx)}
                        />
                      </IconButton>
                    </button>
                  ) : (
                    <IconButton size="small">
                      <CircularProgress
                        fontSize="small"
                        style={{ width: '1.2em', height: '1.2em' }}
                      />
                    </IconButton>
                  )
                ) : (
                  <IconButton size="small">
                    <DeleteIcon
                      className=" p-0.5 text-custom-red-main"
                      width="1.2em"
                      height="1.2em"
                      onClick={() => handleDeleteManualEntryTime(entry)}
                    />
                  </IconButton>
                )}

                {/* <LoadingButton
                  size="small"
                  onClick={() => handleSave(idx)}
                  disabled={
                    !entry.start ||
                    !entry.end ||
                    !entry.task.value ||
                    entry.save
                  }
                  loading={loading === idx.toString()}
                >
                  {entry.save ? 'Saved' : 'Save'}
                </LoadingButton> */}
              </div>
            </div>
          ))}
        </div>
        <footer className="px-3 py-4 flex justify-between items-center">
          <button
            disabled={!addNewEntry}
            className={clsx(
              'flex justify-start items-center space-x-2 text-xs text-primary-main ',
              !addNewEntry && 'text-gray-500 cursor-not-allowed'
            )}
            onClick={handleAddNewEntry}
          >
            <AddIcon /> <span className="">Add Another Time Entry</span>
          </button>
        </footer>
      </div>
    </CustomPopper>
  )
}

const CustomMenu = props => {
  return (
    <components.MenuList
      {...props}
      innerProps={{
        ...props.innerProps,
        onScroll: props.selectProps.selectProps.onScroll
      }}
    >
      {props.children}
    </components.MenuList>
  )
}

export default ManualTimeEntry
