import React, { useContext, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addTaskInList, createTask } from 'thunks/task/actions'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import { useParams } from 'react-router-dom'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { Button } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import styles from './table.module.css'
import clsx from 'clsx'

const NewTaskModal = ({ open, handleClose }) => {
  const dispatch = useDispatch()
  const currentWorkspaceBoard = useSelector(
    state => state.userWorkspaces.currentBoard
  )
  const { socketId } = useContext(WorkspacePusherContext)
  const { boardId, workspaceId } = useParams()
  const [loading, setLoading] = useState(false)
  const ref = useRef()

  const handleAddNewTask = e => {
    e.preventDefault()

    const title = ref.current.value.trim()
    if (!title) return

    setLoading(true)
    dispatch(
      createTask(
        {
          title: title,
          taskSection: currentWorkspaceBoard.boardSection[0]._id,
          workspaceBoard: boardId,
          workSpace: workspaceId,
          socketId
        },
        createNewTaskCallback
      )
    )
  }

  const createNewTaskCallback = (res, err) => {
    setLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      ref.current.value = ''
      handleClose()
      dispatch(addTaskInList(res._id))
    }
  }

  return (
    <CustomModal open={open} handleClose={handleClose} size="extraSmall">
      <CustomModalHeader heading="Create Task" />
      <CustomModalBody>
        <form onSubmit={handleAddNewTask} className="py-2">
          <input
            className={clsx(
              'text-2xl font-medium w-full text-gray-700',
              styles.createTaskModalInput
            )}
            type="text"
            placeholder="Untitled"
            name="title"
            ref={ref}
            autoFocus
          />
        </form>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton loading={loading} onClick={handleAddNewTask}>
          Create
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default NewTaskModal
