import { Modal, makeStyles, Backdrop, Fade } from '@material-ui/core'
import clsx from 'clsx'

// Modal Size options => small, medium (default), large

const paperSizes = {
  extraSmall: '450px',
  small: '550px',
  medium: '650px',
  large: '800px',
  extraLarge: '900px'
}

const CustomModal = ({
  open,
  handleClose,
  children,
  size = 'medium',
  overflow = 'hidden',
  style = {},
  ...rest
}) => {
  const classes = useStyles({ size })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      {...rest}
      className={classes.modal}
    >
      <Fade in={open}>
        <div
          className={`relative rounded-3xl ${classes.paper} overflow-${overflow}`}
          style={style}
        >
          {children}
        </div>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
    // userSelect: 'none'
  },
  paper: props => ({
    width: '90%',
    maxWidth: paperSizes[props.size],
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none'
    // borderRadius: '1.5rem',
  }),
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  }
}))

export default CustomModal
