export const TASKS = {
  LOADING: 'TASKS_LOADING',
  FETCHED: 'ALL_TASKS_FETCHED',
  FETCHED_PRIORITY_TASKS: 'FETCHED_PRIORITY_TASKS',
  ADD_ONE_TASK: 'ADD_ONE_TASK',
  DELETE_ONE_TASK: 'DELETE_ONE_TASK',
  ERROR: 'ALL_TASKS_ERROR',
  UPDATE_ONE_TASK: 'UPDATE_ONE_TASK',
  UPLOAD_ATTACHMENT: 'UPLOAD_ATTACHMENT',
  FETCH_LOGS: 'FETCH_TASK_LOGS',
  REORDER_TASKS: 'REORDER_TASKS',
  UPDATE_POSITION: 'TASKS_UPDATE_POSITION',
  UPDATE_TASK_SECTION: 'TASKS_UPDATE_TASK_SECTION',
  MARK_AS_COMPLETE: 'TASKS_MARK_AS_COMPLETE',
  FETCHED_SECTION_TASKS: 'TASKS_FETCHED_SECTION_TASKS',
  FETCHED_LIST_VIEW_TASKS: 'TASKS_FETCHED_LIST_VIEW_TASKS',
  FETCHED_CALENDAR_VIEW_TASKS: 'TASKS_FETCHED_CALENDAR_VIEW_TASKS',
  LOAD_MORE_SECTION_TASKS: 'TASKS_LOAD_MORE_SECTION_TASKS',
  LOAD_MORE_LIST_VIEW_TASKS: 'TASKS_LOAD_MORE_LIST_VIEW_TASKS',
  MOVE_SECTION_TASK: 'TASKS_MOVE_SECTION_TASK',
  MOVE_DUE_DATE_TASK: 'TASKS_MOVE_DUE_DATE_TASK',
  ADD_TASK_IN_SECTION: 'TASKS_ADD_TASK_IN_SECTION',
  ADD_TASK_IN_LIST: 'TASKS_ADD_TASK_IN_LIST',
  ADD_TASK_IN_CALENDAR: 'TASKS_ADD_TASK_IN_CALENDAR',
  REMOVE_TASK_FROM_SECTION: 'TASKS_REMOVE_TASK_FROM_SECTION',
  REMOVE_TASK_FROM_LIST_VIEW: 'TASKS_REMOVE_TASK_FROM_LIST_VIEW',
  REMOVE_TASK_FROM_CALENDAR_VIEW: 'TASKS_REMOVE_TASK_FROM_CALENDAR_VIEW',
  DELETE_TASK_SECTION: 'TASKS_DELETE_SECTION',
  FETCHED_PUBLIC_TASKS: 'TASKS_FETCHED_PUBLIC_TASKS'
}

const initialState = {
  error: false,
  loading: {
    all: true,
    priority: true
  },
  data: {},
  publicTasksData: [],
  priorityTasks: {
    loading: true,
    fetched: false,
    data: []
  },
  logs: [],
  sectionWiseTasks: {},
  dueDateWiseTasks: {},
  assigneeWiseTasks: {},
  listViewTasks: {
    loading: true,
    fetched: false,
    metadata: { total: 0, page: 0 },
    data: []
  },
  calendarViewTasks: {
    loading: true,
    fetched: false,
    metadata: {},
    data: []
  }
}
const isPriorityTask = task => {
  if (task.dueDate) {
    return 10 > (new Date() - new Date(task.dueDate)) / (1000 * 3600 * 24)
  } else {
    return false
  }
}

const sortPriorityTasksInOrder = tasks => {
  return tasks.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
}

export const groupByKeyWords = {
  status: 'sectionWiseTasks',
  dueDate: 'dueDateWiseTasks',
  assignee: 'assigneeWiseTasks'
}

export const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case TASKS.LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
        error: false
      }

    case TASKS.FETCHED_PRIORITY_TASKS:
      return {
        ...state,
        priorityTasks: { loading: false, fetched: true, data: action.payload }
      }

    case TASKS.ADD_ONE_TASK:
      return {
        ...state,
        loading: { ...state.loading, all: false },
        data: {
          ...state.data,
          [action.payload._id]: action.payload
        },
        priorityTasks: {
          ...state.priorityTasks,
          data: isPriorityTask(action.payload)
            ? sortPriorityTasksInOrder([
                ...state.priorityTasks.data,
                action.payload
              ])
            : state.priorityTasks.data
        }
      }

    case TASKS.UPDATE_ONE_TASK:
      const updatedState = { ...state }

      if (state.data[action.payload._id]) {
        updatedState.data = {
          ...updatedState.data,
          [action.payload._id]: action.payload
        }
      } else {
        updatedState.priorityTasks = {
          ...updatedState.priorityTasks,
          data: updatedState.priorityTasks.data.map(task => {
            if (task._id === action.payload._id) {
              return {
                ...action.payload,
                workSpace: task.workSpace,
                taskSection: task.taskSection
              }
            }
            return task
          })
        }
      }

      return updatedState

    case TASKS.UPLOAD_ATTACHMENT: {
      const updatedData = { ...state.data }

      if (updatedData[action.payload._id]) {
        updatedData[action.payload._id].imageUrl = action.payload.imageUrl
      }

      return {
        ...state,
        data: updatedData
      }
    }

    case TASKS.FETCH_LOGS:
      return {
        ...state,
        logs: action.payload
      }

    case TASKS.DELETE_ONE_TASK: {
      const filteredTasks = { ...state.data }
      delete filteredTasks[action.payload]

      return {
        ...state,
        // loading: false,
        data: filteredTasks,
        priorityTasks: {
          ...state.priorityTasks,
          data: state.priorityTasks.data.filter(i => i._id !== action.payload)
        }
      }
    }

    case TASKS.REORDER_TASKS:
      return {
        ...state
        // data: action.payload
      }

    case TASKS.ERROR:
      return {
        ...state,
        loading: {
          all: false,
          priority: false
        },
        error: action.payload
      }

    case TASKS.UPDATE_POSITION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload._id]: action.payload
        }
      }

    case TASKS.MARK_AS_COMPLETE: {
      if (state.data[action.payload.taskId]) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.taskId]: {
              ...state.data[action.payload.taskId],
              markAsComplete: action.payload.isComplete
            }
          }
        }
      } else {
        return {
          ...state,
          priorityTasks: {
            ...state.priorityTasks,
            data: state.priorityTasks.data.map(item => {
              if (item._id === action.payload.taskId) {
                return { ...item, markAsComplete: action.payload.isComplete }
              }
              return item
            })
          }
        }
      }
    }

    case TASKS.UPDATE_TASK_SECTION:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.taskId]: {
            ...state.data[action.payload.taskId],
            taskSection: action.payload.section._id
          }
        },
        priorityTasks: {
          ...state.priorityTasks,
          data: state.priorityTasks.data.map(item => {
            if (item._id !== action.payload.taskId) return item
            return { ...item, taskSection: action.payload.section }
          })
        }
      }

    case TASKS.FETCHED_SECTION_TASKS: {
      const { sectionId, groupBy, tasks } = action.payload
      const updatedData = { ...state }
      const key = groupByKeyWords[groupBy]

      const updatedDataNew = { ...updatedData.data }

      tasks.data.forEach(task => {
        if (!updatedDataNew[task._id]) updatedDataNew[task._id] = task
      })

      updatedData.data = updatedDataNew

      updatedData[key] = {
        ...updatedData[key],
        [sectionId]: {
          metadata: tasks.metadata,
          data: tasks.data.map(task => task._id)
        }
      }

      return updatedData
    }

    case TASKS.LOAD_MORE_SECTION_TASKS: {
      const { sectionId, groupBy, tasks } = action.payload
      const updatedData = { ...state }
      const key = groupByKeyWords[groupBy]
      const taskIds = action.payload.tasks.data.map(task => task._id)

      const updatedDataNew = { ...updatedData.data }

      tasks.data.forEach(task => {
        if (!updatedDataNew[task._id]) updatedDataNew[task._id] = task
      })

      updatedData.data = updatedDataNew

      updatedData[key] = {
        ...updatedData[key],
        [sectionId]: {
          metadata: tasks.metadata,
          data: [...updatedData[key][sectionId].data, ...taskIds]
        }
      }

      return updatedData
    }

    case TASKS.FETCHED_LIST_VIEW_TASKS: {
      const dataTasks2 = { ...state.data }

      action.payload.data.forEach(task => {
        if (!dataTasks2[task._id]) dataTasks2[task._id] = task
      })

      return {
        ...state,
        data: dataTasks2,
        listViewTasks: {
          loading: false,
          fetched: true,
          metadata: action.payload.metadata,
          data: action.payload.data.map(task => task._id)
        }
      }
    }

    case TASKS.LOAD_MORE_LIST_VIEW_TASKS: {
      const updatedData = { ...state }
      const updatedDataNew = { ...updatedData.data }
      const taskIds = action.payload.data.map(task => task._id)

      action.payload.data.forEach(task => {
        if (!updatedDataNew[task._id]) updatedDataNew[task._id] = task
      })

      updatedData.data = updatedDataNew
      updatedData.listViewTasks = {
        ...updatedData.listViewTasks,
        metadata: action.payload.metadata,
        data: [...updatedData.listViewTasks.data, ...taskIds]
      }

      return updatedData
    }

    case TASKS.FETCHED_CALENDAR_VIEW_TASKS: {
      const tasksData = { ...state.data }

      action.payload.forEach(task => {
        if (!tasksData[task._id]) tasksData[task._id] = task
      })

      return {
        ...state,
        data: tasksData,
        calendarViewTasks: {
          loading: false,
          fetched: true,
          data: action.payload.map(task => task._id)
        }
      }
    }

    case TASKS.MOVE_SECTION_TASK: {
      let {
        sourceIndex,
        destinationIndex,
        sourceId,
        destinationId,
        taskId,
        groupBy
      } = action.payload
      const key = groupByKeyWords[groupBy]
      console.log('PAYLOAD', action.payload)
      sourceIndex =
        sourceIndex === undefined
          ? state[key][sourceId].data.findIndex(id => id === taskId)
          : sourceIndex

      if (sourceIndex === -1) return

      // if section exists (in most cases it does)
      if (state[key][destinationId]) {
        const destinationData = [...state[key][destinationId].data]

        // 1. Moving in the same section
        if (sourceId === destinationId) {
          destinationData.splice(sourceIndex, 1)
          destinationData.splice(
            destinationIndex ?? destinationData.length,
            0,
            taskId
          )
          return {
            ...state,
            [key]: {
              ...state[key],
              [destinationId]: {
                ...state[key][sourceId],
                data: destinationData
              }
            }
          }
        } else {
          // 2. Moving to another sections
          const sourceData = state[key][sourceId].data.filter(
            item => item !== taskId
          )
          destinationData.splice(
            destinationIndex ?? destinationData.length,
            0,
            taskId
          )

          return {
            ...state,
            [key]: {
              ...state[key],
              [sourceId]: {
                metadata: {
                  ...state[key][sourceId].metadata,
                  total: state[key][sourceId].metadata.total - 1
                },
                data: sourceData
              },
              [destinationId]: {
                metadata: {
                  ...state[key][destinationId].metadata,
                  total: state[key][destinationId].metadata.total + 1
                },
                data: destinationData
              }
            }
          }
        }
      } else {
        // If section not found creating new one (in group by assignee case)
        const sourceData = state[key][sourceId].data.filter(
          item => item !== taskId
        )

        return {
          ...state,
          [key]: {
            ...state[key],
            [sourceId]: {
              metadata: {
                ...state[key][sourceId].metadata,
                total: state[key][sourceId].metadata.total - 1
              },
              data: sourceData
            },
            [destinationId]: {
              metadata: { total: 1, page: 0 },
              data: [taskId]
            }
          }
        }
      }
    }

    case TASKS.ADD_TASK_IN_SECTION: {
      const { sectionId, top, taskId, groupBy } = action.payload
      const key = groupByKeyWords[groupBy]

      console.log(action.payload)

      const updatedStateData = { ...state }

      updatedStateData[key] = {
        ...updatedStateData[key],
        [sectionId]: {
          metadata: {
            ...updatedStateData[key][sectionId].metadata,
            total: updatedStateData[key][sectionId].metadata.total + 1
          },
          data: top
            ? [taskId, ...updatedStateData[key][sectionId].data]
            : [...updatedStateData[key][sectionId].data, taskId]
        }
      }

      return updatedStateData
    }

    case TASKS.ADD_TASK_IN_LIST:
      return {
        ...state,
        listViewTasks: {
          ...state.listViewTasks,
          data: [...state.listViewTasks.data, action.payload.taskId]
        }
      }

    case TASKS.ADD_TASK_IN_CALENDAR:
      return {
        ...state,
        calendarViewTasks: {
          ...state.calendarViewTasks,
          data: [...state.calendarViewTasks.data, action.payload.taskId]
        }
      }

    case TASKS.REMOVE_TASK_FROM_SECTION: {
      const { sectionId, taskId, groupBy } = action.payload
      const updatedStateData = { ...state }
      const key = groupByKeyWords[groupBy]

      updatedStateData[key] = {
        ...updatedStateData[key],
        [sectionId]: {
          metadata: {
            ...updatedStateData[key][sectionId].metadata,
            total: updatedStateData[key][sectionId].metadata.total - 1
          },
          data: updatedStateData[key][sectionId].data.filter(
            id => id !== taskId
          )
        }
      }

      return updatedStateData
    }

    case TASKS.REMOVE_TASK_FROM_LIST_VIEW: {
      const updatedStateData = { ...state }
      updatedStateData.listViewTasks = {
        ...updatedStateData.listViewTasks,
        data: updatedStateData.listViewTasks.data.filter(
          taskId => taskId !== action.payload.taskId
        )
      }

      return updatedStateData
    }

    case TASKS.REMOVE_TASK_FROM_CALENDAR_VIEW: {
      const updatedStateData = { ...state }
      updatedStateData.calendarViewTasks = {
        ...updatedStateData.calendarViewTasks,
        data: updatedStateData.calendarViewTasks.data.filter(
          taskId => taskId !== action.payload.taskId
        )
      }

      return updatedStateData
    }

    case TASKS.DELETE_TASK_SECTION: {
      const updatedStatusSections = { ...state.sectionWiseTasks }
      delete updatedStatusSections[action.payload.sectionId]

      return { ...state, sectionWiseTasks: updatedStatusSections }
    }
    case TASKS.FETCHED_PUBLIC_TASKS: {
      return {
        ...state,
        publicTasksData: action.payload,
        loading: { ...state.loading, all: false }
      }
    }

    default:
      return state
  }
}
export default tasksReducer
