import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Badge, makeStyles, Popover } from '@material-ui/core'
import Select from 'react-select'
import clsx from 'clsx'
import {
  ColoredAvatars,
  LoadingButton,
  OutlinedButton
} from 'global/globalComponents'
import ClearIcon from '@material-ui/icons/Clear'
import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import {
  createFilterForBoardTask,
  fetchBoardFilters,
  updateBoardFilter,
  deleteBoardFilterById
} from 'thunks/embed/action'
import { getTaskByWorkspaceBoardId } from 'thunks/task/actions'
// import SaveViewPopup from 'components/SingleWorkspace/SaveViewPopup'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { CustomFlag } from 'global/globalComponents'
import {
  SingleWorkspaceContext,
  WORKSPACE
} from 'components/SingleWorkspace/singleWorkspaceContext'
import moment from 'moment'
// import BoardsStyles from '../BoardView/BoardsStyles'

/* ========================= PLANNING ============================
1. Data :
    a. Filter Options => Status, Due Date, Created By etc.
    b. Add Ons => Is, Is not, Greater than, Less than etc.
    c. Query Options (values)

2. Elements:
    a. Popover
    b. Select => select requires data in label and value format ({label: "", value: ""})

3. Functionality Process
  1. Select a Filter Option (ex: status)
  2. Store this filter data in an array because user could add multiple filters
  3. It would an array of objects, and each object should have following properties:

    i) field: this key will hold the selected filter option, its datatype will be an object
       because we're gonna show this in a Select element.

    ii) addOn: selected add on, default value => {label: "Is", value: "is"}

    iii) query: the selected query value, same datatype => {label: "", value: ""}
        here label could hold jsx instead of just strings in some special cases (ex: created by filter)

    NOTE: These added filter properties can be updated

    ex: {
      field: {label: "Status", value: "status"},
      addOn: {label: "Is", value: "is"},
      query: {label: "open", value: "{task section id}"}
    }

  4. Remove filter option (button)
========================= PLANNING ============================ */
const getStartAndEndDateFromString = str => {
  let lte, gte

  if (str === 'today') {
    gte = moment()
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .toISOString()
    lte = moment()
      .set('hours', 23)
      .set('minutes', 59)
      .set('seconds', 59)
      .toISOString()
  } else if (str === 'yesterday') {
    let yesterDay = moment().subtract(1, 'days')
    gte = yesterDay
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .toISOString()
    lte = yesterDay
      .set('hours', 23)
      .set('minutes', 59)
      .set('seconds', 59)
      .toISOString()
  } else {
    let days = str.match(/\d+/)[0]

    if (str.startsWith('last')) {
      gte = moment()
        .subtract(days, 'days')
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .toISOString()
      lte = moment()
        .subtract(1, 'days')
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toISOString()
    } else {
      gte = moment()
        .add(1, 'days')
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .toISOString()
      lte = moment()
        .add(days, 'days')
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toISOString()
    }
  }

  return {
    startDate: gte,
    endDate: lte
    // startDate: {
    //   $lte: lte,
    //   $gte: gte
    // }
  }
}

// modifying filters data
const transformFiltersData = data => {
  return {
    ...data,
    fields: data.fields.map(item => {
      if (item.field === 'startDate' || item.field === 'dueDate') {
        return {
          ...item,
          values: [getStartAndEndDateFromString(item.values[0])]
        }
      }
      return item
    })
  }
}

const Filter = () => {
  // const allTasksData = useSelector((state) => state.userTasks.data)
  const dispatch = useDispatch()
  const currentWorkspaceBoard = useSelector(
    state => state.userWorkspaces.currentBoard
  )
  // const taskTags = useSelector(state => state.userTags.currentWorkspaceTags)
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const classes = useStyles()
  const [loadingSaveBtn, setLoadingSaveBtn] = useState(false)
  const { workspaceDispatch } = useContext(SingleWorkspaceContext)

  const filterFields = useMemo(
    () => [
      {
        label: 'Status', //multi
        value: 'taskSection'
      },
      {
        label: 'Start Date', //single
        value: 'startDate'
      },
      {
        label: 'Due Date', //Single
        value: 'dueDate'
      },
      {
        label: 'Priority', //multi
        value: 'priority'
      },
      {
        label: 'Created By', //Single
        value: 'user'
      },
      // {
      //   label: 'Tags', //multi
      //   value: 'tags'
      // },
      {
        label: 'Agency Teammates', //multi
        value: 'assignedAgencyTeam'
      },
      {
        label: 'Client Teammates', //multi
        value: 'assignedClientTeam'
      }
    ],
    []
  )

  const operatorOptions = useMemo(
    () => [
      { label: 'Is', value: 'EQ' },
      { label: 'Is not', value: 'NE' }
    ],
    []
  )

  const dateOptions = useMemo(
    () => [
      { label: 'Today', value: 'today' },
      {
        label: 'Yesterday',
        value: 'yesterday'
      },
      { label: 'Tomorrow', value: 'tomorrow' },
      { label: 'Last 7 days', value: 'last 7 days' },
      { label: 'Next 7 days', value: 'next 7 days' },
      {
        label: 'Last 30 days',
        value: 'last 30 days'
      },
      { label: 'Next 30 days', value: 'next 30 days' },
      {
        label: 'Last 90 days',
        value: 'last 90 days'
      },
      { label: 'Next 90 days', value: 'next 90 days' }
    ],
    []
  )

  const [filterValues, setFilterValues] = useState({
    priority: [
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={1} />
            <span>Urgent</span>
          </div>
        ),
        value: 1,
        backgroundColor: 'rgb(245, 0, 0)',
        color: 'rgb(255, 255, 255)'
      },
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={2} />
            <span>High</span>
          </div>
        ),
        value: 2,
        backgroundColor: 'rgb(255, 204, 0)',
        color: 'rgb(255, 255, 255)'
      },
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={3} />
            <span>Medium</span>
          </div>
        ),
        value: 3,
        backgroundColor: 'rgb(111, 221, 255)',
        color: 'rgb(255, 255, 255)'
      },
      {
        label: (
          <div className="text-xs h-5 leading-5 flex items-center space-x-2">
            <CustomFlag priority={4} />
            <span>Low</span>
          </div>
        ),
        value: 4,
        backgroundColor: 'rgb(216, 216, 216)',
        color: 'rgb(255, 255, 255)'
      }
    ],
    startDate: dateOptions,
    dueDate: dateOptions,
    // tags: [],
    user: [],
    taskSection: [],
    collaborators: []
  })

  const [filtersData, setFiltersData] = useState({})
  const [addedFilters, setAddedFilters] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  // const [openSaveViewPopup, setOpenSaveViewPopup] = useState(false)
  const [saveFilter, setSaveFilter] = useState(false)
  const [filterCreated, setFilterCreated] = useState(false)

  // useEffect(() => {
  //   if (currentWorkspaceBoard?.filters) {
  //     setFiltersData(currentWorkspaceBoard.filters[0]?.fields ?? [])
  //     setAddedFilters(currentWorkspaceBoard.filters[0]?.fields ?? [])
  //   }
  // }, [currentWorkspaceBoard])
  useEffect(() => {
    if (currentWorkspaceBoard._id) {
      fetchBoardFilters(currentWorkspaceBoard._id, (res, err) => {
        let transformedData = {}
        // Right now filter is an array, don't know why though :|
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          if (Array.isArray(res) && res.length) {
            const data = {
              ...res[0],
              fields: res[0].fields.filter(item => item.field !== 'tags')
            }
            setFilterCreated(true)
            setFiltersData(data)
            setAddedFilters(data.fields)

            transformedData = transformFiltersData(data)
          }
        }

        workspaceDispatch({
          type: WORKSPACE.FILTERS_FETCHED,
          payload: transformedData
        })
      })
    }
  }, [currentWorkspaceBoard._id])

  useEffect(() => {
    if (currentWorkspace.assignedAgencyTeam) {
      const arr1 = currentWorkspace.assignedAgencyTeam.map(item => ({
        label: (
          <div className="flex items-center">
            <ColoredAvatars user={item.user} size="tiny" />
            <span className="text-xs ml-2">{item.user.name}</span>
          </div>
        ),
        value: item.user._id
        // field: 'assignedAgencyTeam'
      }))

      const arr2 = currentWorkspace.assignedClientTeam.map(item => ({
        label: (
          <div className="flex items-center">
            <ColoredAvatars user={item.user} size="tiny" />
            <span className="text-xs ml-2">{item.user.name}</span>
          </div>
        ),
        value: item.user._id
        // field: 'assignedClientTeam'
      }))

      const statuses = currentWorkspaceBoard.boardSection?.map(item => ({
        label: item.label,
        value: item._id,
        color: item.color,
        backgroundColor: item.color + '20'
      }))

      setFilterValues(prev => ({
        ...prev,
        user: [...arr1, ...arr2],
        taskSection: statuses,
        assignedAgencyTeam: arr1,
        assignedClientTeam: arr2
      }))
    }
  }, [currentWorkspace, currentWorkspaceBoard])

  // useEffect(() => {
  //   if (saveFilter) {
  // const data = addedFilters.filter((item) => !item.values.length)
  // if (data.length) return // if there is a filter with empty value (query) do nothing

  //     const queryStr = addedFilters.map(parseFilterObjToQueryStr).join('&')
  //     console.log(queryStr)
  //     setOpenSaveViewPopup(true)
  //     // dispatch(
  //     //   getTaskByWorkspaceBoardId(
  //     //     currentWorkspaceBoard._id,
  //     //     (res, err) => {
  //     //       setOpenSaveViewPopup(true)
  //     //     },
  //     //     '?' + queryStr
  //     //   )
  //     // )
  //   }
  // }, [saveFilter])

  // useEffect(() => {
  //   const tags = taskTags.map(item => ({
  //     label: (
  //       <div
  //         className="capitalize text-xs font-bold"
  //         style={{
  //           color: item.textColor,
  //           lineHeight: 'inherit'
  //         }}
  //       >
  //         {item.name}
  //       </div>
  //     ),
  //     value: item._id
  //   }))
  //   setFilterValues(prev => ({ ...prev, tags }))
  // }, [taskTags])

  const toggleFilterFieldsPopup = e => {
    if (anchorEl2) {
      setAnchorEl2(null)
    } else {
      setAnchorEl2(e.currentTarget)
    }
  }

  const toggleAddFilterPopup = e => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAddedFilters(filtersData.fields ? [...filtersData.fields] : [])
      setSaveFilter(false)
      setAnchorEl(e.currentTarget)
    }
  }

  const handleAddFilter = option => {
    const filterObj = {
      field: option.value,
      op: operatorOptions[0].value,
      values: [],
      idx: addedFilters.length
    }

    setAddedFilters(prev => [...prev, filterObj])
    toggleFilterFieldsPopup()
    setSaveFilter(true)
  }

  const updateFilterProp = ({ idx, key, value }) => {
    const updatedData = addedFilters.map((item, index) => {
      if (index === idx) {
        if (key === 'field') {
          return { ...item, [key]: value, values: [] }
        }
        return { ...item, [key]: value }
      }
      return item
    })

    setAddedFilters(updatedData)
    setSaveFilter(true)
  }

  const removeAddedFilter = index => {
    const filteredData = addedFilters.filter((item, idx) => idx !== index)
    setAddedFilters(filteredData)
    setSaveFilter(true)
  }

  // const handleCloseSaveViewPopup = () => {
  //   setOpenSaveViewPopup(false)
  // }

  const handleSaveView = useCallback(
    x => {
      const filteredData = addedFilters.filter(item => !item.values.length)
      if (filteredData.length) {
        dispatch(fireErrorToaster('Please select filter value!'))
        return // if there is a filter with empty value (query) do nothing
      }

      const data = {
        op: 'AND',
        fields: addedFilters,
        workspace: currentWorkspace._id,
        workspaceBoard: currentWorkspaceBoard._id
      }

      setLoadingSaveBtn(true)
      if (!filterCreated) {
        createFilterForBoardTask(data, filterSaveCallback)
      } else {
        if (data.fields.length)
          updateBoardFilter(filtersData._id, data, filterSaveCallback)
        else deleteBoardFilterById(filtersData._id, filterDeleteCallback)
      }
    },
    [addedFilters]
  )

  const filterSaveCallback = (res, err) => {
    setLoadingSaveBtn(false)

    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      const transformedData = transformFiltersData(res)
      workspaceDispatch({
        type: WORKSPACE.FILTERS_FETCHED,
        payload: transformedData
      })

      // dispatch(getTaskByWorkspaceBoardId(currentWorkspaceBoard._id))
      // When we create filter we get data in an array but when we update it
      // we just get an object, don't know why #needBackendImprovement
      setFiltersData(res)
      setFilterCreated(true)
      dispatch(fireSuccessToaster('Changes saved successfully!'))
      toggleAddFilterPopup()
    }
  }

  const filterDeleteCallback = (res, err) => {
    setLoadingSaveBtn(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      workspaceDispatch({
        type: WORKSPACE.FILTERS_FETCHED,
        payload: {}
      })

      // dispatch(getTaskByWorkspaceBoardId(currentWorkspaceBoard._id))
      setFiltersData({})
      setFilterCreated(false)
      dispatch(fireSuccessToaster('Changes saved successfully!'))
      toggleAddFilterPopup()
    }
  }

  return (
    <>
      <Badge
        color="primary"
        variant="dot"
        badgeContent={filtersData.fields?.length ?? 0}
        max={9}
        classes={{ badge: classes.badge }}
      >
        <OutlinedButton
          isActive={Boolean(filtersData.fields?.length)}
          onClick={toggleAddFilterPopup}
        >
          <FilterIcon />
          <span className="ml-1">Filter</span>
        </OutlinedButton>
      </Badge>

      {/* ------ First Popup ------ */}
      <Popover
        open={Boolean(anchorEl)}
        onClose={toggleAddFilterPopup}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className="p-3 rounded" style={{ minWidth: 250 }}>
          <h5 className="text-gray-400 text-xs font-bold tracking-wider mb-4">
            ACTIVE FILTERS
          </h5>
          {Boolean(addedFilters.length) &&
            addedFilters.map((item, index) => (
              <SingleFilter
                key={index}
                index={index}
                filter={item}
                filterFields={filterFields}
                operatorOptions={operatorOptions}
                filterValues={filterValues[item.field]}
                handleAddFilter={handleAddFilter}
                updateFilterProp={updateFilterProp}
                removeAddedFilter={removeAddedFilter}
              />
            ))}
          <button
            onClick={toggleFilterFieldsPopup}
            className="text-primary-main text-xs font-semibold py-2"
          >
            + Add Filter
          </button>

          <div className="text-right">
            <LoadingButton
              onClick={handleSaveView}
              loading={loadingSaveBtn}
              disabled={!saveFilter}
              size="small"
            >
              Save
            </LoadingButton>
          </div>
          {/* {Boolean(addedFilters.length) && (
          )} */}
        </div>
      </Popover>
      {/* ------ First Popup Ends------ */}

      {/* ------ Second Popup ------ */}
      <Popover
        open={Boolean(anchorEl2)}
        onClose={toggleFilterFieldsPopup}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className="rounded w-40">
          <h5 className="px-3 py-2 mb-0 text-gray-400 text-xs tracking-wider text-gray-400">
            ADD FILTER
          </h5>
          <ul>
            {filterFields.map((item, index) => (
              <li
                key={item.label}
                className={clsx(
                  'py-1.5 px-3 cursor-pointer text-sm',
                  index === 0
                    ? 'bg-primary-main text-white'
                    : 'hover:bg-gray-100'
                )}
                onClick={() => handleAddFilter(item)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
      {/* ------ First Popup Ends ------ */}

      {/* <SaveViewPopup
        heading="Do you want to save this filter?"
        message="This filter will be saved as default"
        open={openSaveViewPopup}
        onClose={handleCloseSaveViewPopup}
        onSave={handleSaveView}
      /> */}
    </>
  )
}

const SingleFilter = ({
  index,
  filter,
  filterFields,
  operatorOptions,
  filterValues,
  updateFilterProp,
  removeAddedFilter
}) => {
  const [singleFilerData, setSingleFilterData] = useState({
    field: {},
    op: {},
    values: []
  })

  useEffect(() => {
    let field = filterFields.find(item => item.value === filter.field)
    let op = operatorOptions.find(item => item.value === filter.op)
    let values = []

    if (filter.values.length) {
      // if (filter.field === 'startDate' || filter.field === 'dueDate') {
      //   values = filterValues.find(
      //     (item) =>
      //       item.value.dir === filter.values[0].dir &&
      //       item.value.days === filter.values[0].days
      //   )
      // } else {
      // }
      values = filterValues?.filter(item => {
        let idx = filter.values.findIndex(val => val === item.value)
        return idx >= 0
      })
    }

    setSingleFilterData({ field, op, values })
  }, [filter])

  const handleOptionChange = newVal => {
    updateFilterProp({ idx: index, key: 'field', value: newVal.value })
  }

  const handleOperatorOnChange = newVal => {
    updateFilterProp({ idx: index, key: 'op', value: newVal.value })
  }

  const handleValueChange = newVal => {
    updateFilterProp({
      idx: index,
      key: 'values',
      value: ['startDate', 'dueDate'].includes(filter.field)
        ? [newVal.value]
        : newVal.map(item => item.value)
    })
  }

  const handleRemoveFilter = () => {
    removeAddedFilter(index)
  }

  return (
    <div className="mb-2 flex space-x-2">
      <div style={{ width: 200 }}>
        <Select
          menuPosition={'fixed'}
          options={filterFields}
          value={singleFilerData.field}
          className="w-full"
          styles={{ ...reactSelectCustomStyles, ...customSelectStyles }}
          onChange={handleOptionChange}
        />
      </div>
      <div style={{ width: 100 }}>
        <Select
          menuPosition={'fixed'}
          options={
            ['startDate', 'dueDate'].includes(filter.field)
              ? [operatorOptions[0]]
              : operatorOptions
          } //A quick fix (will change it later)
          value={singleFilerData.op}
          className="w-full"
          styles={{ ...reactSelectCustomStyles, ...customSelectStyles }}
          onChange={handleOperatorOnChange}
        />
      </div>
      <div style={{ width: 350 }}>
        <Select
          menuPosition={'fixed'}
          options={filterValues}
          value={singleFilerData.values}
          className="w-full"
          styles={{ ...reactSelectCustomStyles, ...customSelectStyles }}
          onChange={handleValueChange}
          isSearchable={false}
          isMulti={!['startDate', 'dueDate'].includes(filter.field)}
        />
      </div>
      <button onClick={handleRemoveFilter} className="text-gray-400">
        <ClearIcon style={{ fontSize: 15 }} />
      </button>
    </div>
  )
}

// const parseFilterObjToQueryStr = ({ field, op, values }) => {
// if (field === 'startDate' || field === 'dueDate') {
//   let today = new Date(),
//     { dir, days } = values[0]

//   if (dir === 'today') return `${field}=${today.toISOString()}`
//   else if (dir === 'yesterday')
//     return `${field}=${new Date(
//       today.setDate(today.getDate() - 1)
//     ).toISOString()}`
//   else if (dir === 'tomorrow')
//     return `${field}=${new Date(
//       today.setDate(today.getDate() + 1)
//     ).toISOString()}`
//   else if (dir === 'last') {
//     let date = new Date(today)
//     date = new Date(date.setDate(today.getDate() - days))

//     return `${field}[gte]=${date.toISOString()}&${field}[lte]=${today.toISOString()}`
//   } else {
//     let date = new Date(today)
//     date = new Date(date.setDate(today.getDate() + days))
//     return `${field}[gte]=${today.toISOString()}&${field}[lte]=${date.toISOString()}`
//   }
// }

// return `${field}${op === 'EQ' ? '[in]=' : '[nin]='}${values.join(',')}`
// }

const clrPrimary = '#1600E5'
const clrPrimaryLight = '#F3F2FF'

const customSelectStyles = {
  control: provided => ({
    ...provided,
    minHeight: 35,
    '&:focus-within': {
      borderColor: clrPrimary,
      boxShadow: `0 0 0 1px ${clrPrimary}`
    }
  }),

  // dropdownIndicator: (provided) => ({
  //   ...provided,
  //   padding: '0 4px 0 0',
  //   width: 20,
  //   height: 20
  // }),
  clearIndicator: provided => ({
    ...provided,
    padding: '0 4px 0 0',
    width: 20,
    height: 20
  }),
  singleValue: () => ({ fontSize: 13 }),
  valueContainer: provided => ({ ...provided, paddingRight: 0 }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: isSelected
      ? clrPrimary
      : isFocused
      ? clrPrimaryLight
      : null,
    ':active': {
      ...provided[':active'],
      backgroundColor: clrPrimaryLight
    }
  }),
  // multiValue: (provided) => ({
  //   ...provided,
  //   padding: '2px 7px',
  //   borderRadius: '100vh',
  //   fontWeight: 500,
  //   backgroundColor: '#ECEAFF'
  // }),
  multiValueLabel: (provided, { data }) => {
    return {
      ...provided,
      padding: '3px 5px 3px 0',
      paddingLeft: '0'
    }
  }
  // multiValueRemove: (provided, { data }) => ({
  //   ...provided,
  //   top: 2,
  //   position: 'relative',
  //   left: 2,
  //   width: 22,
  //   height: 22,
  //   color: clrPrimary,
  //   borderRadius: '50%',
  //   cursor: 'pointer',
  //   backgroundColor: '#fff',
  //   ':hover': {
  //     backgroundColor: clrPrimary,
  //     color: '#fff'
  //   }
  // })
}

const useStyles = makeStyles({
  badge: {
    right: 0,
    top: 6
  }
})

export default Filter
