import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'

const CheckBoxField = ({
  name,
  onChange,
  checked,
  label,
  disabled = false
}) => {
  return (
    <FormControlLabel
      className="flex items-center"
      name={name}
      control={
        <Checkbox
          onChange={onChange}
          checked={checked}
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" />}
          disabled={disabled}
        />
      }
      label={
        <span className="text-xs font-medium text-gray-700 truncate">
          {label}
        </span>
      }
    />
  )
}

export default CheckBoxField
