import React, { useState, useEffect, useContext, memo } from 'react'
import { OnboardingContext, ONBOARDING } from '../onboardingReducer'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { CustomTooltip } from 'global/globalComponents'
import CloseIcon from '@material-ui/icons/Close'

const SectionHeader = ({
  sectionId,
  sectionHeading,
  description: sectionDescription
}) => {
  const { onboardingState, onboardingDispatch } = useContext(OnboardingContext)
  const [stepIndex, setStepIndex] = useState(0)
  const [title, setTitle] = useState({
    edit: false,
    value: ''
  })

  const [description, setDescription] = useState({
    edit: false,
    value: ''
  })

  useEffect(() => {
    setTitle((prev) => ({ edit: prev.edit, value: sectionHeading }))
  }, [sectionHeading])

  useEffect(() => {
    setDescription((prev) => ({ edit: prev.edit, value: sectionDescription }))
  }, [sectionDescription])

  useEffect(() => {
    const index = onboardingState.currentTemplate.data.findIndex(
      (item) => item.id === sectionId
    )
    if (index !== -1) {
      setStepIndex(index + 1)
    }
  }, [onboardingState.currentTemplate.data.length])

  const toggleTitleEdit = () => {
    setTitle((prev) => ({ ...prev, edit: !prev.edit }))
  }

  const handleTitleChange = (e) => {
    setTitle({ edit: true, value: e.target.value })
  }

  const handleTitleSave = () => {
    if (title.value !== '') {
      onboardingDispatch({
        type: ONBOARDING.UPDATE_SECTION_PROP,
        payload: { id: sectionId, prop: 'sectionHeading', value: title.value }
      })
      toggleTitleEdit()
    } else {
      setTitle({ edit: false, value: sectionHeading })
    }
  }

  const toggleDescriptionEdit = () => {
    setDescription((prev) => ({ ...prev, edit: !prev.edit }))
  }

  const handleDescriptionChange = (e) => {
    setDescription({ edit: true, value: e.target.value })
  }

  const handleDescriptionSave = () => {
    if (description.value !== '') {
      onboardingDispatch({
        type: ONBOARDING.UPDATE_SECTION_PROP,
        payload: {
          id: sectionId,
          prop: 'description',
          value: description.value
        }
      })
      toggleDescriptionEdit()
    } else {
      setTitle({ edit: false, value: sectionDescription })
    }
  }

  const handleRemoveStep = () => {
    onboardingDispatch({
      type: ONBOARDING.REMOVE_STEP,
      payload: {
        id: sectionId
      }
    })
  }

  return (
    <header className="mb-4">
      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-primary-main">
            Step {stepIndex}
          </h2>
          <div className="ml-auto cursor-pointer">
            <CustomTooltip title="Remove this step" placement="top">
              <button onClick={handleRemoveStep}>
                <CloseIcon />
              </button>
            </CustomTooltip>
          </div>
        </div>

        <div className="flex-1">
          <div>
            {title.edit ? (
              <input
                value={title.value}
                onBlur={handleTitleSave}
                autoFocus={true}
                onChange={handleTitleChange}
                className="text-md font-medium rounded-t mr-2 mb-1 w-full"
              />
            ) : (
              <div className="inline-flex items-start">
                <h3 className="text-lg font-medium rounded-t mr-2 mb-1">
                  {title.value}
                </h3>
                <button onClick={toggleTitleEdit}>
                  <EditOutlinedIcon fontSize="small" />
                </button>
              </div>
            )}
          </div>
          <div>
            {description.edit ? (
              <input
                value={description.value}
                onBlur={handleDescriptionSave}
                autoFocus={true}
                onChange={handleDescriptionChange}
                className="text-sm text-gray-500 p-1 w-full"
              />
            ) : (
              <p
                className="text-sm text-gray-500 cursor-pointer"
                onClick={toggleDescriptionEdit}
              >
                {description.value ||
                  "Edit this description by just clicking on it or leave it as it is if you don't want to add any description"}
              </p>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default memo(SectionHeader)
