import React from 'react'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { Card } from 'global/globalComponents'
import format from 'date-fns/format'
import { projectStatusData } from '../StatusUpdate/StatusUpdateDropdown'
import Pill from 'global/globalComponents/Pill/Pill'
import { getUserPath } from 'utils/userRoles'
import {
  useParams,
  // Switch,
  // Route,
  useHistory
  // useRouteMatch
} from 'react-router-dom'
import { useSelector } from 'react-redux'

const StatusUpdateCard = ({ loading, statusPostsData, readOnly }) => {
  const history = useHistory()
  const { customURL, workspaceId } = useParams()
  const meData = useSelector(state => state.me.data)

  const handleStatusView = statusId => {
    if (!readOnly) {
      history.push(
        `${getUserPath(
          meData?.role
        )}/workspace/${workspaceId}/status-updates/${statusId}`
      )
    }
  }

  return (
    <Card className="h-full p-4">
      <header className="mb-2 flex items-center">
        <h4 className="text-sm font-semibold text-primary-dark">
          Project Status
        </h4>
        <span className="w-8 h-8 rounded-full border inline-flex justify-center items-center ml-2 text-primary-mid-dark">
          {statusPostsData.length}
        </span>
      </header>
      <div>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <ul className="text-sm overflow-auto" style={{ height: '280px' }}>
            {statusPostsData.length ? (
              statusPostsData.map(item => (
                <li
                  key={item._id}
                  className={clsx(
                    'p-2 flex text-gray-700 text-xs lg:text-smaller mb-1 whitespace-nowrap item-center rounded cursor-pointer hover:bg-gray-100 leading-3'
                  )}
                  onClick={() => handleStatusView(item._id)}
                >
                  <Pill variant={projectStatusData[item.status]?.colorString}>
                    {projectStatusData[item.status]?.label}
                  </Pill>

                  <div className=" ml-2 flex items-center">
                    <span className="text-gray-500">
                      updated by{' '}
                      <span className="text-primary-dark">
                        {item.user?.name}
                      </span>{' '}
                      on {format(new Date(item.updatedAt), 'd MMM')}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="text-gray-500">No status found</li>
            )}
          </ul>
        )}
      </div>
    </Card>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      {[
        Array(4)
          .fill('')
          .map(item => <Skeleton variant="rect" height={20} className="mb-4" />)
      ]}
    </>
  )
}

export default StatusUpdateCard
