import { useEffect, useState } from 'react'

const useIsUserOnline = () => {
  const [onlineStatus, setOnlineStatus] = useState(true)

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false)
    })
    window.addEventListener('online', () => {
      setOnlineStatus(true)
    })
    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false)
      })
      window.removeEventListener('online', () => {
        setOnlineStatus(true)
      })
    }
  }, [])
  return onlineStatus
}

export default useIsUserOnline
