import { useState, useRef } from 'react'
import { Modal, Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { RadioGroup } from '@headlessui/react'
import { LoadingButton } from 'global/globalComponents'
import clientSubscriptionStyles from './ClientSubscriptionStyles'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SubscriptionCancelModal = ({
  open,
  onClose,
  handleUnsubscribe,
  loading,
  serviceStatus
}) => {
  const classes = clientSubscriptionStyles()
  const reasons = [
    'Too expensive',
    'Switching to another product',
    'Customer service was poor',
    'Product is missing key features',
    'Other'
  ]
  const [selected, setSelected] = useState(reasons[0])
  const [refundCheck, setRefundCheck] = useState(false)
  const [textarea, setTextarea] = useState({
    isVisible: false,
    value: ''
  })
  const textareaRef = useRef(null)

  const handleClose = () => {
    onClose(false)
  }

  const handleUnsubscribeClick = () => {
    if (textarea.isVisible && !textarea.value) {
      textareaRef.current.focus()
      return
    }

    handleUnsubscribe(
      selected.toLowerCase() === 'other' ? textarea.value : selected,
      refundCheck
    )
  }

  const handleOptionChange = (val) => {
    setSelected(val)
    if (val.toLowerCase() === 'other') {
      setTextarea((prev) => ({ ...prev, isVisible: true }))
    } else {
      setTextarea((prev) => ({ ...prev, isVisible: false }))
    }
  }

  const handleUserInput = (e) => {
    setTextarea((prev) => ({ ...prev, value: e.target.value }))
  }

  return (
    <div>
      <Modal
        aria-labelledby="Service cancellation request"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <div className="relative" style={{ outline: 'none' }}>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-3/4 mx-auto sm:mx-0">
                <div className="mb-4">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900 mb-1"
                    id="modal-title"
                  >
                    Cancel Subscription
                  </h3>
                  <p className="text-sm text-custom-gray-main">
                    We're sorry to see you go.
                  </p>
                </div>

                <RadioGroup value={selected} onChange={handleOptionChange}>
                  <RadioGroup.Label className="text-sm font-medium text-gray-900">
                    What is the primary reason for cancelling your subscription?
                  </RadioGroup.Label>

                  <div className="mt-4 bg-white rounded-md shadow-sm -space-y-px">
                    {reasons.map((reason, index) => (
                      <RadioGroup.Option
                        key={reason.name}
                        value={reason}
                        className="flex cursor-pointer focus:outline-none p-2"
                      >
                        {({ active, checked }) => (
                          <>
                            <span
                              className={classNames(
                                checked
                                  ? 'bg-blue-600 border-transparent'
                                  : 'bg-white border-gray-300',
                                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                              )}
                              aria-hidden="true"
                            >
                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <div className="ml-3 flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className={classNames(
                                  checked ? 'text-sky-900' : 'text-gray-900',
                                  'block text-sm font-medium'
                                )}
                              >
                                {reason}
                              </RadioGroup.Label>
                            </div>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
                {textarea.isVisible && (
                  <div className="mt-4">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Write in description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        ref={textareaRef}
                        className="p-2 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md outline-none"
                        value={textarea.value}
                        onChange={handleUserInput}
                        style={{ outline: 'none' }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5 flex flex-row-reverse justify-between items-center ">
              <div className="flex flex-row-reverse">
                <div className="ml-3">
                  <LoadingButton
                    color="secondary"
                    onClick={handleUnsubscribeClick}
                    loading={loading}
                  >
                    Unsubscribe
                  </LoadingButton>
                </div>
                <div>
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                </div>
              </div>
              {serviceStatus === 'paid' && (
                <FormControlLabel
                  className="flex items-center"
                  checked={refundCheck}
                  onChange={() => setRefundCheck(!refundCheck)}
                  label={
                    <span className="text-xs font-medium text-gray-700 ">
                      refund request
                    </span>
                  }
                  control={<Checkbox size="medium" />}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SubscriptionCancelModal
