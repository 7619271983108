import { useState, useEffect } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { updateClient } from '../../thunks/addClient/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import clsx from 'clsx'
import Category from 'utils/workspaceCategories'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { userRoles } from 'utils'
import clientStyles from './ClientStyles'
import { InputAdornment, IconButton } from '@material-ui/core'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'

export default function ClientEditPanel(props) {
  const classes = clientStyles()
  const dispatch = useDispatch()
  const { open, onClose, data } = props
  const [agencyRepresentative, setAgencyRepresentative] = useState([])
  const teammate = useSelector((state) => state.userTeammates.data)
  const [load, setLoad] = useState(false)
  const [passwordValid, setPasswordValid] = useState(true)
  const [clientWorkspaces, setClientWorkspaces] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const [dataSet, setDataSet] = useState({
    name: '',
    // newWorkspace: [],
    newWorkspace: [],
    agencyRepresentative: '',
    password: '',
    email: ''
  })
  const [WorkspaceName, setWorkspaceName] = useState([])
  const workspaces = useSelector((state) => state.userWorkspaces)
  const meData = useSelector((state) => state.me.data)
  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })

  const [addTeammateToggle, setAddTeammateToggle] = useState({
    checked: false,
    load: false
  })

  useEffect(() => {
    let arr = []
    let arr1 = []
    workspaces.data.map((item) => {
      if (
        item.category === Category.EXTERNAL &&
        !item.isArchived &&
        !item.isComplete &&
        item.assignedClientTeam.length === 0
      )
        arr.push({ value: item._id, label: item.name })
    })
    data?.assignedWorkspace?.map((item1) => {
      if (item1) {
        arr1.push({ value: item1?._id, label: item1?.name })
        if (arr.find((o) => o.value == item1?._id)) {
        } else {
          arr.push({ value: item1._id, label: item1.name })
        }
      }
    })

    setWorkspaceName(arr)
    setClientWorkspaces(arr1)

    if (data) {
      const newWorkspace = data.assignedWorkspace?.map((obj) => {
        let clone = { value: obj._id, name: obj.name, image: obj.image } // we need value key but server returns _id
        return clone
      })

      setDataSet((prev) => ({
        ...prev,
        name: data.name,
        email: data.email,
        newWorkspace
      }))

      setAddTeammateToggle({
        checked:
          data.canAddTeammates !== undefined ? data.canAddTeammates : true,
        load: false
      })

      if (data.agencyRepresentative) {
        let representative = agencyRepresentative.find(
          (item) => item.value === data.agencyRepresentative
        )

        setDataSet((prev) => ({
          ...prev,
          agencyRepresentative: representative
        }))
      }
    }
    // data &&
    // data &&
    //   setDataSet((prev) => ({
    //     ...prev,

    //     assignedWorkspace: data.assignedWorkspace?.map((obj) => {
    //       let clone = { value: obj._id, name: obj.name, image: obj.image } // we need value key but server returns _id
    //       return clone
    //     })
    //   }))

    // if (Object.keys(data).includes('agencyRepresentative')) {
    //   let representative = agencyRepresentative.find(
    //     (item) => item.value == data.agencyRepresentative?._id
    //   )
    //   setDataSet((prev) => ({ ...prev, agencyRepresentative: representative }))
    // } else setDataSet((prev) => ({ ...prev, agencyRepresentative: '' }))
  }, [workspaces, data])

  useEffect(() => {
    let arr = []

    if (
      [
        userRoles.USER_AGENCY,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_ADMIN
      ].includes(meData.role)
    ) {
      arr.push({ value: meData._id, label: meData.name })
    }

    teammate
      .filter((mate) => mate.role !== 23)
      .map((item) => {
        arr.push({ value: item._id, label: item.name })
      })
    setAgencyRepresentative(arr)
  }, [teammate])

  const clientCallback = (res, err) => {
    setLoad(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      onClose()
      setDataSet({ name: '', newWorkspace: [], agencyRepresentative: '' })
      dispatch(fireSuccessToaster('Client Updated'))
    }
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    setDataSet((prevState) => ({ ...prevState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const handleUpdateClient = () => {
    const filteredDataSet = {
      ...dataSet,
      email: dataSet.email.trim().toLowerCase(),
      agencyRepresentative: dataSet.agencyRepresentative?.value
    }

    filteredDataSet.newWorkspace = dataSet.newWorkspace.map((obj) => {
      return obj.value
    })

    if (!filteredDataSet.name.trim()) {
      dispatch(fireErrorToaster('Invalid name value!'))
      return
    }

    if (dataSet.password && !validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    // Do not add password field in the body if no password value added
    if (dataSet.password === '') delete filteredDataSet.password
    if (filteredDataSet.email === data.email) delete filteredDataSet.email

    // =========== DO NOT REMOVE THIS COMMENTED CODE =============
    // if (
    //   data.assignedWorkspace.length > filteredDataSet.newWorkspace.length
    // ) {
    //   filteredDataSet.removedWorkspace = data.assignedWorkspace
    //     .filter((item) => !filteredDataSet.newWorkspace.includes(item._id))
    //     .map((item) => item._id)
    // }
    const removedWorkspace = data.assignedWorkspace
      .filter((item) => !filteredDataSet.newWorkspace.includes(item._id))
      .map((item) => item._id)

    const oldWorkspace = data.assignedWorkspace.map((item) => item._id)

    filteredDataSet.newWorkspace = [...filteredDataSet.newWorkspace].filter(
      (item) => !oldWorkspace.includes(item)
    )

    filteredDataSet.removedWorkspace = removedWorkspace
    setLoad(true)

    if (toggleSwitch.checked) {
      filteredDataSet.path = '/client/dashboard'
    }

    filteredDataSet.canAddTeammates = addTeammateToggle.checked

    dispatch(updateClient(data._id, filteredDataSet, clientCallback))
  }

  const handleClientWorkspace = (event) => {
    let e = [...event]
    setDataSet((prev) => ({ ...prev, newWorkspace: e }))
    setClientWorkspaces(e)
  }

  const togglePasswordView = () => {
    setShowPassword((prev) => !prev)
  }

  const validPasswordHandler = () => {
    const password = dataSet.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const handleAgencyRepresentativeChange = (e) => {
    setDataSet((prev) => ({ ...prev, agencyRepresentative: e }))
  }

  const handleSwitch = () => {
    setToggleSwitch((prev) => ({
      ...prev,
      checked: !toggleSwitch.checked
    }))
  }

  const handleAddTeammateSwitch = () => {
    setAddTeammateToggle((prev) => ({
      ...prev,
      checked: !addTeammateToggle.checked
    }))
  }

  return (
    <CustomDrawer
      onClose={onClose}
      // classes={{
      //   paper: clsx(classes.drawerPaper, { drawerOpen: open })
      // }}
      anchor="right"
      open={open}
    >
      <DrawerHeader onClose={onClose}>Edit Client</DrawerHeader>
      <DrawerContent open={open}>
        <Box style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <Box style={{ flexGrow: '1' }}>
            <FormLabel required>Full Name</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="name"
              value={dataSet.name}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box
          mt={2}
          style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
        >
          <Box style={{ flexGrow: '1' }}>
            <FormLabel required>Email</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="email"
              value={dataSet.email}
              inputProps={{
                autoComplete: 'rutjfkde' //using random character bcoz chrome ignores "off"
              }}
              disabled={
                ![
                  userRoles.USER_AGENCY,
                  userRoles.AGENCY_MANAGER,
                  userRoles.AGENCY_ADMIN
                ].includes(meData.role)
              }
              onChange={handleChange}
              autocomplete="do-not-autofill"
            />
          </Box>
        </Box>
        <Box mt={2}>
          <FormLabel>Agency Representative</FormLabel>
          <Select
            className="col-span-4 md:col-span-2 text-sm"
            placeholder="Assign Agency Representative"
            options={agencyRepresentative}
            value={dataSet.agencyRepresentative}
            onChange={handleAgencyRepresentativeChange}
            styles={reactSelectCustomStyles}
            maxMenuHeight={120}
          />
        </Box>
        <Box mt={2}>
          <FormLabel>Assign Project</FormLabel>
        </Box>
        <Select
          onChange={handleClientWorkspace}
          placeholder="Assign Project"
          isSearchable={false} // preventing auto fill
          options={WorkspaceName}
          className={classes.textFieldStyle}
          value={clientWorkspaces}
          isMulti={true}
          styles={reactSelectCustomStyles}
          maxMenuHeight={120}
        />

        <Box mt={2}>
          <FormLabel>Reset Password</FormLabel>
          <input
            style={{ display: 'none' }}
            type="password"
            name="fakeusernameremembered"
          />
          <input
            style={{ display: 'none' }}
            type="password"
            name="fakepasswordremembered"
          />
          <TextField
            className={classes.textFieldStyle}
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter New Password"
            onChange={handleChange}
            onBlur={validPasswordHandler}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={togglePasswordView}>
                    {!showPassword ? (
                      <EyeIcon className="text-base" />
                    ) : (
                      <EyeSlashIcon className="text-base" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {dataSet.password && !passwordValid && (
            <p className="text-xs mt-1" style={{ color: '#f44336' }}>
              Password should be a combination of an uppercase/lowercase letter,
              a number, a symbol and length between 8 to 20.
            </p>
          )}
        </Box>
        {data.path && data.path !== '/client/dashboard' && (
          <Box className="flex items-center justify-between mt-3">
            <p className="text-sm font-medium text-gray-700">Skip Checkout</p>

            <div className="flex items-center gap-1">
              <span className="text-sm font-medium text-gray-700">No</span>
              <ToggleSwitch
                checked={toggleSwitch.checked}
                onChange={handleSwitch}
                disableRipple={true}
              />
              <span className="text-sm font-medium text-gray-700">Yes</span>
            </div>
          </Box>
        )}
        <Box className="flex items-center justify-between mt-3">
          <p className="text-sm font-medium text-gray-700">
            Allow Teammates Creation
          </p>

          <div className="flex items-center gap-1">
            <span className="text-sm font-medium text-gray-700">No</span>
            <ToggleSwitch
              checked={addTeammateToggle.checked}
              onChange={handleAddTeammateSwitch}
              disableRipple={true}
              loading={addTeammateToggle.load}
            />
            <span className="text-sm font-medium text-gray-700">Yes</span>
          </div>
        </Box>
      </DrawerContent>
      <DrawerFooter className={classes.panelFooter}>
        <Button variant="outlined" onClick={onClose} className="w-full">
          Cancel
        </Button>

        <LoadingButton
          className="w-full"
          loading={load}
          onClick={handleUpdateClient}
        >
          Update Client
        </LoadingButton>
      </DrawerFooter>
    </CustomDrawer>
  )
}
