import { useEffect, useState } from 'react'
import {
  AlertModal,
  ColoredAvatars,
  ToggleSwitch
} from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import {
  rejectSignupRequest,
  approveSignupRequest
} from 'thunks/teammate/actions'
import { format } from 'date-fns'
import { errorMessages } from 'utils'
import Pill from 'global/globalComponents/Pill/Pill'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { userRoles, getRoleBadge } from 'utils'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as Calendar } from 'static/svg/calendar.svg'
import { Button } from '@material-ui/core'

const totalTeammatesToShow = 8

const ApproveTeammateTable = ({ loading, teammates }) => {
  const dispatch = useDispatch()
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])
  const access = useSelector(state => state.permission.data.access)
  const meData = useSelector(state => state.me.data)
  const [deleteTeammateData, setdeleteTeammateData] = useState({
    open: false,
    data: {},
    loading: false
  })

  // ------ Related to the pagination --------
  const [visibleTeammates, setVisibleTeammates] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const [requestApproveAlert, setRequestApproveAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })

  const [requestRejectAlert, setRequestRejectAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })

  useEffect(() => {
    if (teammates) {
      setVisibleTeammates(
        [...teammates].splice(page * totalTeammatesToShow, totalTeammatesToShow)
      )
      setPaginationTotalDataCount(teammates.length)
    }
  }, [teammates, page, paginationTotalDataCount])

  const toggleApproveAlert = data => {
    if (requestApproveAlert.open) {
      setRequestApproveAlert(prev => ({ ...prev, open: false }))
    } else {
      if (
        ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
      ) {
        return dispatch(
          fireErrorToaster('You do not have permission to perform this action')
        )
      }
      setRequestApproveAlert(prev => ({ open: true, data, loading: false }))
    }
  }

  const toggleRejectAlert = data => {
    if (requestRejectAlert.open) {
      setRequestRejectAlert(prev => ({ ...prev, open: false }))
    } else {
      if (
        ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
      ) {
        return dispatch(
          fireErrorToaster('You do not have permission to perform this action')
        )
      }
      setRequestRejectAlert(prev => ({ open: true, data, loading: false }))
    }
  }

  const handleRejectUserRequest = () => {
    setRequestRejectAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      rejectSignupRequest(requestRejectAlert.data._id, rejectRequestCallback)
    )
  }

  const rejectRequestCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setRequestRejectAlert(prev => ({ ...prev, loading: false }))
    } else {
      setRequestRejectAlert(prev => ({
        ...prev,
        loading: false,
        open: false
      }))
      dispatch(fireSuccessToaster('Request removed successfully'))
    }
  }

  const handleApproveUserRequest = () => {
    setRequestApproveAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      approveSignupRequest(
        requestApproveAlert.data._id,
        { status: 'approved' },
        approveRequestCallback
      )
    )
  }

  const approveRequestCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setRequestApproveAlert(prev => ({ ...prev, loading: false }))
    } else {
      setRequestApproveAlert(prev => ({
        ...prev,
        loading: false,
        open: false
      }))
      dispatch(fireSuccessToaster('Request approved successfully'))
    }
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th>Name</Th>
          <Th className="whitespace-nowrap text-center">Permission</Th>
          <Th className="whitespace-nowrap">Registration Date</Th>
          <Th className="text-center whitespace-nowrap">Action</Th>
        </Thead>

        <Tbody>
          {!loading ? (
            visibleTeammates.length > 0 ? (
              visibleTeammates.map((item, index) => (
                <Row
                  key={item._id}
                  user={item}
                  toggleApproveAlert={toggleApproveAlert}
                  toggleRejectAlert={toggleRejectAlert}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan={meData.role < userRoles.USER_CLIENT ? 6 : 5}
                  className="text-left py-3 px-6 text-gray-500 font-normal"
                >
                  There are no teammates
                </th>
              </Tr>
            )
          ) : (
            <TableDataLoading
              cols={meData.role < userRoles.USER_CLIENT ? 6 : 5}
            />
          )}
        </Tbody>
      </TableContainer>

      {!loading.loading && paginationTotalDataCount > totalTeammatesToShow && (
        <Pagination
          handlePagination={setPage}
          totalResultsCount={paginationTotalDataCount}
          visibleResultsCount={visibleTeammates.length}
          page={page}
          limit={totalTeammatesToShow}
        />
      )}

      {/* <DeleteModal
        warningText="Teammate will be deleted."
        open={deleteTeammateData.open}
        handleDialog={handleDeleteTeammate}
        handleDeleteAction={handleTeammateData}
        loading={deleteTeammateData.loading}
      /> */}
      <AlertModal
        open={requestApproveAlert.open}
        handleDialog={toggleApproveAlert}
        heading="Are you sure?"
        warningText={`Do you want to approve ${requestApproveAlert.data.name}'s onboarding request?`}
        cancelText="Cancel"
        deleteBtnText="Yes"
        alertType="confirmation"
        loading={requestApproveAlert.loading}
        handleDeleteAction={handleApproveUserRequest}
      />
      <AlertModal
        open={requestRejectAlert.open}
        handleDialog={toggleRejectAlert}
        heading="Are you sure?"
        warningText={`Do you want to reject ${requestRejectAlert.data.name}'s onboarding request?`}
        cancelText="Cancel"
        deleteBtnText="Yes"
        loading={requestRejectAlert.loading}
        handleDeleteAction={handleRejectUserRequest}
      />
    </div>
  )
}

const Row = ({ user, toggleApproveAlert, toggleRejectAlert }) => {
  const handleApproveUser = () => {
    toggleApproveAlert(user)
  }

  const handleRejectUser = () => {
    toggleRejectAlert(user)
  }

  return (
    <Tr>
      <Td className=" px-6 py-4 whitespace-nowrap flex items-center space-x-2">
        <ColoredAvatars size="medium" user={user} />
        <div className="flex flex-col">
          <div className="flex items-center leading-3">
            <span className="text-primary-mid-dark font-medium mr-2">
              {user.name}
            </span>
          </div>
          <span className="text-primary-mid-dark-2 text-xs">{user.email}</span>
        </div>
      </Td>
      <Td className="whitespace-nowrap text-center">
        {getRoleBadge(user.role)}
      </Td>
      <Td className="text-custom-gray-main">
        {format(new Date(user.createdAt), 'd MMM')}
      </Td>
      <Td className="text-custom-gray-main whitespace-nowrap">
        <div className="flex items-center justify-center space-x-2">
          <Button size="small" onClick={handleApproveUser}>
            Approve
          </Button>
          <Button variant="outlined" size="small" onClick={handleRejectUser}>
            Reject
          </Button>
        </div>
      </Td>
    </Tr>
  )
}

export default ApproveTeammateTable
