import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { ReactComponent as Check } from 'static/svg/check.svg'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import { loadStripe } from '@stripe/stripe-js'
import {
  agencyUpdateCard,
  fetchCardDetails
} from 'thunks/agencyPayment/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import settingStyles from '../settingStyles'
import { FormLabel, TextField } from '@material-ui/core'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

function AlertDialog({ open, handleClose, callback, addingNewCard }) {
  const classes = settingStyles()
  const dispatch = useDispatch()
  const [userName, setUserName] = useState('')
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)

  const updatecardCallback = (res, err) => {
    setLoading(false)
    if (callback) callback(res, err)
    if (err) dispatch(fireErrorToaster(res.message))
    else {
      dispatch(fireSuccessToaster(res.message))
      setUserName('')
      handleClose()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!userName) {
      return dispatch(fireErrorToaster('Username is missing!'))
    }
    setLoading(true)

    try {
      let token = await stripe.createToken(elements.getElement(CardElement))
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: { token: token.token.id },
        billing_details: { name: userName }
      })
      agencyUpdateCard(paymentMethod, updatecardCallback)
    } catch (err) {
      setLoading(false)
      dispatch(fireErrorToaster(err?.response?.data?.message ?? 'Unsuccessful'))
    }
  }

  const handleUserName = (e) => {
    setUserName(e.target.value)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.addPaymentCardDialog }}
    >
      <DialogContent style={{ padding: '0px' }}>
        <div className="p-8 rounded-lg">
          <header className="mb-4">
            <h3 className="font-semibold text-xl mb-1">
              {addingNewCard ? 'Add a new card' : 'Update payment card'}
            </h3>
            {addingNewCard && (
              <p className="text-xs text-primary-dark">
                Adding a new card ensures seamless subscription
              </p>
            )}
          </header>
          <form onSubmit={handleSubmit}>
            <TextInput
              placeholder="Name on card"
              onChange={handleUserName}
              value={userName}
              rounded={true}
              size="medium"
            />
            <div className="my-4 border border-gray-300 rounded-full flex items-center px-4 py-3">
              <div className="flex-1 py-0.5">
                <CardElement
                  className={classes.cardElement}
                  options={{
                    style: {
                      base: {
                        fontSize: '14px',
                        '::placeholder': {
                          color: '#aaa'
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
            <footer className="flex items-center justify-between mt-8">
              <div className="flex items-center gap-4">
                <LoadingButton
                  type="submit"
                  loading={loading}
                  style={{ width: 100 }}
                >
                  {addingNewCard ? 'Save' : 'Update Card'}
                </LoadingButton>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  style={{ width: 100 }}
                >
                  Cancel
                </Button>
              </div>
              <div className="flex items-center gap-1">
                <Check style={{ marginRight: '1%' }} />
                <em className={classes.influenceText}>Powered By Stripe</em>
              </div>
            </footer>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default function UpdateCardModal({
  open,
  addingNewCard,
  handleClose,
  callback
}) {
  return (
    <Elements stripe={stripePromise}>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        addingNewCard={addingNewCard}
        callback={callback}
      />
    </Elements>
  )
}
