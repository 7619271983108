import React from 'react'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import { Card } from 'global/globalComponents'

const Timesheet = ({ loading, userTimesheetData }) => {
  const getUserTime = data => {
    const time = data.reduce((acc, obj) => acc + obj.duration, 0)
    return getTimeString(time / 1000)
  }

  return (
    <Card className="h-full p-4">
      <header className="flex items-center mb-2">
        <h4 className="text-sm font-semibold text-primary-dark">Timesheet</h4>

        <span className="w-8 h-8 rounded-full border inline-flex justify-center items-center ml-2 text-primary-mid-dark">
          {
            userTimesheetData?.filter(
              item => item.user.name && item.trackingData.length
            ).length
          }
        </span>
      </header>

      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div className="text-sm">
          {userTimesheetData?.filter(
            item => item.user.name && item.trackingData.length
          ).length ? (
            <>
              <div
                className={clsx(
                  'px-2 pb-3 pt-1 flex border-b border-gray-100 justify-between whitespace-nowrap'
                )}
              >
                <div className="text-gray-900 font-medium text-xs">Users</div>
                <div className="text-gray-900 font-medium text-xs">
                  Tracked Time
                </div>
              </div>
              <ul
                className="pt-1"
                style={{ maxHeight: '270px', overflow: 'scroll' }}
              >
                {userTimesheetData
                  ?.filter(item => item.user.name && item.trackingData.length)
                  .map(item => (
                    <li
                      key={item._id}
                      className={clsx(
                        'p-2 flex text-gray-700 text-xs lg:text-smaller mb-1 justify-between whitespace-nowrap rounded cursor-pointer hover:bg-gray-100'
                      )}
                    >
                      <div className="truncate flex-1 overdue-task-title capitalize">
                        {item.user.name}
                      </div>
                      <div className="ml-4 mr-2 text-xs">
                        {getUserTime(item.trackingData)}
                      </div>
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <div className="text-gray-500">No data found</div>
          )}
        </div>
      )}
    </Card>
  )
}

const getTimeString = time => {
  if (time === 0) return 0
  const hours = Math.floor(time / (60 * 60))
  const minutes = Math.floor(Math.floor(time % (60 * 60)) / 60)
  const seconds = Math.ceil(Math.floor(time % (60 * 60)) % 60)
  if (hours !== 0) {
    return minutes !== 0
      ? seconds !== 0
        ? `${hours}h ${minutes}m ${seconds}s`
        : `${hours}h ${minutes}m`
      : `${hours}h`
  } else if (hours === 0 && minutes === 0) {
    return `${seconds}s`
  } else {
    return seconds === 0 ? `${minutes}m` : `${minutes}m ${seconds}s`
  }
}

const LoadingSkeleton = () => {
  return (
    <>
      {[
        Array(4)
          .fill('')
          .map(item => <Skeleton variant="rect" height={20} className="mb-4" />)
      ]}
    </>
  )
}

export default Timesheet
