import axios from 'axios'
import { errorMessages } from 'utils'
import { APPROVALS } from './reducer'

/**
 * fetch all task approvals
 * @param {String} id task id
 * @param {String} type task, subtask
 * @param {Function} callback callback function
 */
export const fetchTaskApprovals = async (taskId, type, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/approval/${type}/${taskId}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(err.response?.data?.message || errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch all the approval request that requires my approval
 * @param {Function} callback callback function
 */
export const fetchMyApprovals = (approvalId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/approval/user`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: APPROVALS.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create task approval
 * @param {String} id task id
 * @param {Object} data type, category, status, approvalBy, requestedTo, approvedBy, notes dueDate, taskSection, workspace
 * @param {Function} callback callback function
 */
export const createTaskApproval = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/approval',
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(err.response?.data?.message || errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Create task approval
 * @param {String} id approval id
 * @param {Object} data
 * @param {Function} callback callback function
 */
export const updateTaskApproval = async (approvalId, data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/approval/${approvalId}`,
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(err.response?.data?.message || errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Delete task approval
 * @param {String} id approval id
 * @param {Function} callback callback function
 */
export const deleteTaskApproval = async (approvalId, callback) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/approval/${approvalId}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(err.response?.data?.message || errorMessages.ERROR_MESSAGE, true)
  }
}
