import { agencyRoleOptions, clientRoleOptions } from './userRoles'
import Pill from 'global/globalComponents/Pill/Pill'

const getRoleBagde = (roleNum, classes) => {
  let roles = [
    ...agencyRoleOptions,
    ...clientRoleOptions,
    { value: 20, label: 'Super Admin' },
    { value: 30, label: 'Client Owner' },
    { value: 15, label: 'Project Owner' }
  ]
  const role = roles.find((obj) => obj.value === roleNum)

  return (
    <Pill
      className={classes}
      variant={
        role?.label === 'Admin'
          ? 'purple'
          : role?.label === 'Manager'
          ? 'blue'
          : role?.label === 'Viewer'
          ? 'red'
          : 'yellow'
      }
    >
      {role?.label}
    </Pill>
  )
}

export default getRoleBagde
