import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField
  // Select
} from '@material-ui/core'
import { Create } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import { updateUser, updateUsername } from 'thunks/user/actions'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { useBreadcrumbs } from 'customHooks'
import { userRoles, validator } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import clsx from 'clsx'
import PersonAvatar from 'global/globalComponents/PersonAvtar/PersonAvtar'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import settingStyles from '../settingStyles'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { fetchMeData } from 'thunks/me/actions'

const defaultImage =
  'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'

const Profile = () => {
  const classes = settingStyles()
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const {
    data: userData,
    loading: userLoading,
    error: userError
  } = useSelector(state => state.user)

  const [formData, setFormData] = useState({
    name: '',
    profileImage: '',
    phoneNumber: '',
    email: ''
  })

  const [username, setUsername] = useState('')

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/${
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER
        ].includes(meData?.role)
          ? 'general'
          : 'profile'
      }`,
      index: 0
    },
    {
      name: 'Profile',
      link: `/settings/profile`,
      index: 1
    }
  ])

  useEffect(() => {
    setFormData(oldData => ({
      name: userData.name,
      email: userData.email,
      profileImage: userData.profileImage,
      phoneNumber: userData?.profile?.phoneNumber
    }))
    setUsername(userData.username)
  }, [userData])

  const formInputHandler = e => {
    const { name, value } = e.target
    setFormData(oldData => ({
      ...oldData,
      [name]: value
    }))
  }

  const handleSaveForm = () => {
    if (!formData.name || !username || formData.email.trim() === '') {
      dispatch(fireErrorToaster('Please fill all the required fields'))
      return
    }

    if (!validator.isEmailValid(formData.email)) {
      dispatch(fireErrorToaster('Invalid email address!'))
      return
    }

    let updatedData = { ...formData }
    if (meData.role === userRoles.USER_AGENCY) {
      updatedData = {
        name: formData.name,
        profileImage: formData.profileImage,
        phoneNumber: formData.phoneNumber
      }
    }

    userData.username !== username
      ? dispatch(
          updateUsername(username, (res, err) => {
            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(updateUser(updatedData, updateUserCallback))
            }
          })
        )
      : dispatch(updateUser(updatedData, updateUserCallback))
  }

  const updateUserCallback = (res, err) => {
    if (!err) {
      dispatch(fireSuccessToaster('Updated Successfully'))
      if (userData.email !== formData.email) {
        dispatch(fetchMeData())
      }
    }
  }

  const imageLoadHandler = e => {
    const file = e.target.files[0]
    const imgReader = new FileReader()
    imgReader.onloadend = function () {
      if (file) {
        setFormData(oldData => ({
          ...oldData,
          profileImage: imgReader.result
        }))
      }
    }
    imgReader.readAsDataURL(file)
  }

  const handleUsernameInput = e => {
    setUsername(e.target.value)
  }

  return (
    <Wrapper>
      <SecondaryHeading className="mb-6 lg:mb-8">
        Your Information
      </SecondaryHeading>

      <form className="text-xs md:text-smaller lg:text-sm">
        <div className="mt-2 gap-8 mb-6 lg:mb-8">
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file1"
            multiple={false}
            type="file"
            onChange={imageLoadHandler}
          />

          <FormLabel className="pb-2">Profile Picture</FormLabel>
          <label htmlFor="contained-button-file1" className="inline-block">
            <PersonAvatar
              img={
                formData?.profileImage ? formData?.profileImage : defaultImage
              }
            />
          </label>
        </div>

        <div className="flex justify-between items-center space-x-4">
          <div className=" w-full">
            <FormLabel htmlFor="userName" required>
              Username
            </FormLabel>
            <TextField
              id="userName"
              className={classes.inputBox}
              value={username}
              // inputProps={{ onBlur: handleuserNameUpdate }}
              disabled={userLoading}
              onChange={handleUsernameInput}
              name={'username'}
            />
          </div>

          <div className="w-full">
            <div className="flex-grow">
              <FormLabel htmlFor="name" required>
                Name
              </FormLabel>
              <TextField
                className={classes.inputBox}
                id="name"
                value={formData.name}
                onChange={formInputHandler}
                disabled={userLoading}
                name={'name'}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center space-x-4 mt-4">
          <div className="w-full">
            <FormLabel required>Email</FormLabel>
            <TextField
              className={classes.inputBox}
              value={formData.email}
              disabled={meData.role === userRoles.USER_AGENCY}
              onChange={formInputHandler}
              name="email"
            />
          </div>

          <div className="w-full">
            <FormLabel htmlFor="phone">Phone</FormLabel>
            <TextField
              id="phone"
              className={classes.inputBox}
              value={formData.phoneNumber}
              onChange={formInputHandler}
              // disabled={userLoading}
              name={'phoneNumber'}
            />
          </div>
        </div>
        <div className="mt-4 w-full md:w-1/2 md:pr-2">
          <FormLabel required>Agency Domain</FormLabel>
          <TextField
            className={classes.inputBox}
            value={userData?.agencyDomain}
            disabled
          />
        </div>

        <div className="text-right mt-12">
          <LoadingButton
            loading={userLoading}
            variant="contained"
            onClick={handleSaveForm}
            className="w-40"
          >
            Save
          </LoadingButton>
        </div>
      </form>
    </Wrapper>
  )
}

export default Profile
