export const TEAMMATES = {
  LOADING: 'ALL_TEAMMATES_LOADING',
  FETCHED: 'ALL_TEAMMATES_FETCHED',
  ADD_ONE_TEAMMATE: 'ADD_ONE_TEAMMATE',
  DELETE_ONE_TEAMMATE: 'DELETE_ONE_TEAMMATE',
  ERROR: 'ALL_TEAMMATES_ERROR',
  UPDATE_ONE_TEAMMATE: 'UPDATE_ONE_TEAMMATE',
  UPDATE_MANY_TEAMMATES: 'UPDATE_MANY_TEAMMATES',
  UPDATE_MANY_TEAMMATES_WORKSPACES: 'UPDATE_MANY_TEAMMATES_WORKSPACES',
  APPROVE_SIGNUP_REQUEST: 'TEAMMATES_APPROVE_SIGNUP_REQUEST',
  REJECT_SIGNUP_REQUEST: 'TEAMMATES_REJECT_SIGNUP_REQUEST'
}

const initialState = {
  error: false,
  loading: true,
  data: [],
  underApproval: [],
  fetched: false
}

const teammatesReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case TEAMMATES.LOADING:
      return { ...state, loading: true, error: false }

    case TEAMMATES.FETCHED:
      let teammates = [],
        underApproval = []
      for (let teammate of action.payload) {
        if (teammate.status === 'need_approval') underApproval.push(teammate)
        else teammates.push(teammate)
      }

      return {
        ...state,
        loading: false,
        data: teammates,
        underApproval,
        fetched: true
      }

    case TEAMMATES.ADD_ONE_TEAMMATE:
      return { ...state, loading: false, data: [action.payload, ...state.data] }

    case TEAMMATES.DELETE_ONE_TEAMMATE:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }
    case TEAMMATES.UPDATE_ONE_TEAMMATE:
      const updatedData1 = [...state.data]
      const index = getCurrentIndex(action.payload._id)
      if (index !== -1) {
        updatedData1[index] = { ...updatedData1[index], ...action.payload }
      }
      return {
        ...state,
        data: updatedData1
      }

    // case TEAMMATES.UPDATE_MANY_TEAMMATES_WORKSPACES:
    //   let updatedData2 = [...state.data]
    //   action.payload.teams.forEach((mate) => {
    //     updatedData2[getCurrentIndex(mate)].assignedWorkspace = [
    //       action.payload.data,
    //       ...updatedData2[getCurrentIndex(mate)].assignedWorkspace
    //     ]
    //   })
    //   return {
    //     ...state,
    //     data: updatedData2
    //   }

    // case TEAMMATES.UPDATE_MANY_TEAMMATES:
    //   let updatedData2 = [...state.data]
    //   action.payload.teams.forEach((mate) => {
    //     updatedData2[getCurrentIndex(mate)].verticals = [
    //       ...updatedData2[getCurrentIndex(mate)].verticals,
    //       action.payload.data
    //     ]
    //   })
    //   return {
    //     ...state,
    //     data: updatedData2
    //   }

    case TEAMMATES.APPROVE_SIGNUP_REQUEST:
      return {
        ...state,
        data: [action.payload, ...state.data],
        underApproval: state.underApproval.filter(
          (item) => item._id !== action.payload._id
        )
      }

    case TEAMMATES.REJECT_SIGNUP_REQUEST:
      return {
        ...state,
        underApproval: state.underApproval.filter(
          (item) => item._id !== action.payload._id
        )
      }
    default:
      return state
  }
}

export default teammatesReducer
