const clientVenueAssets = {
  favicon: 'https://assets.clientvenue.com/other/clientvenue-icon.png',
  smallLogo: 'https://assets.clientvenue.com/other/clientvenue-icon-darkbg.png',
  smallLogoForLightBg:
    'https://assets.clientvenue.com/other/clientvenue-icon.png',
  largeLogo: 'https://assets.clientvenue.com/other/clientvenue-logo.png',
  brandName: 'Clientvenue'
}

export default clientVenueAssets
