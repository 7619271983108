import React from 'react'
import { useTable, useSortBy } from 'react-table'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'

const StandupReportsTable = ({
  columns,
  data,
  loading,
  classes,
  setStatsPopper
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      loading: true,
      loadingComponent: <p>Loading....</p>,
      classes,
      setStatsPopper
    },
    useSortBy
  )

  return (
    <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-xl border rounded-xl">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-200 border-collapse border-hidden"
      >
        <thead className="font-medium uppercase text-left h-14">
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="hover:bg-gray-100"
            >
              {headerGroup.headers.map((col, index) => (
                <th
                  className={clsx(
                    'px-6 py-4 text-gray-500 whitespace-nowrap text-tiny lg:text-xs bg-custom-gray-light-5',
                    col.id === 'user' && `sticky left-0 z-10`
                  )}
                  {...col.getHeaderProps(col.getSortByToggleProps())}
                >
                  {col.render('Header')}
                  <span className="ml-1">
                    {col.isSorted ? (
                      col.isSortedDesc ? (
                        <>&#8593;</>
                      ) : (
                        <>&#8595;</>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200 text-xs lg:text-sm text-gray-500"
        >
          {loading ? (
            <>
              <tr>
                {new Array(columns.length).fill('').map((col, index) => (
                  <td className="text-gray-500 bg-white border-r border-gray-200">
                    <div className="px-4 py-2">
                      <Skeleton fill="rect" width={100} height={40} />
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                {new Array(columns.length).fill('').map((col, index) => (
                  <td className="text-gray-500 bg-white border-r border-gray-200">
                    <div className="px-4 py-2">
                      <Skeleton fill="rect" width={100} height={40} />
                    </div>
                  </td>
                ))}
              </tr>
              <tr>
                {new Array(columns.length).fill('').map((col, index) => (
                  <td className="text-gray-500 bg-white border-r border-gray-200">
                    <div className="px-4 py-2">
                      <Skeleton fill="rect" width={100} height={40} />
                    </div>
                  </td>
                ))}
              </tr>
            </>
          ) : (
            <>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={clsx(
                          'text-gray-500 bg-white border-r border-gray-200 px-1',
                          cell.column.id === 'user' && 'sticky left-0 z-10'
                        )}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default React.memo(StandupReportsTable)
