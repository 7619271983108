import React, { useState, useEffect, useMemo } from 'react'
// import SectionHeading from 'global/globalComponents/SectionHeading'
import Folder from './Folder'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import AddNew from './AddNew'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchFolders,
  fetchFiles,
  uploadFile,
  deleteFolder
  // getFolderStorageData
} from 'thunks/fileAssets/actions'
import FilesUploadLoading from './FilesUploadLoading'
import FilesTable from './FilesTable'
// import { getUserPath } from 'utils/userRoles'
// import { fileToDataUrl } from 'utils'
import { hideChatMessenger, showChatMessenger } from 'utils/manageChatMessenger'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import AssetPreview from './AssetPreview'
import CreateRenameAssetModal from './CreateRenameAssetModal'
import FilesAssetsBreadCrumbs from './FilesAssetsBreadCrumbs'
import fileAssetsStyles from './filesAssetsStyles'
import clsx from 'clsx'
import { errorMessages, userRoles } from 'utils'
// import axios from 'axios'
import { ReactComponent as EditIcon } from 'static/svg/edit.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { ReactComponent as FolderOpen } from 'static/svg/folder-open.svg'
import { handleFilePreview } from 'thunks/generlAppState/actions'
import { uniqueId } from 'lodash'
import MoveFileModal from './MoveFileModal'

const FilesFolders = ({ displayLoader }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = fileAssetsStyles()
  let { workspaceId, dirIds: dirIdsString } = useParams()
  const dirIdsArray = useMemo(() => {
    return dirIdsString.split('/')
  }, [dirIdsString])
  let currentDirId = dirIdsArray.reverse()[0]
  const [assetsFolders, setAssetsFolders] = useState([])
  const [assetsFiles, setAssetsFiles] = useState([])
  const [filesLoading, setFilesLoading] = useState(false)
  const filesAssetsData = useSelector(
    (state) => state.filesAssets.currentWSAssets.data
  )
  // const meData = useSelector((state) => state.me.data)
  const [filesStack, setFilesStack] = useState({
    open: false,
    items: []
  })
  // const [filesData, setFilesData] = useState([])

  const [createRenameModal, setCreateRenameModal] = useState({
    open: false,
    data: {},
    elmFor: '',
    actionType: ''
  })
  const [deleteFolderAlert, setDeleteFolderAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })
  const [preview, setPreview] = useState({
    open: false,
    file: {}
  })
  const { pathname } = useLocation()

  // const [uploadFileReqCount, setUploadFileReqCount] = useState(0)
  const [dragOver, setDragOver] = useState(false)
  // const [parentList, setParentList] = useState([])
  // const [file, setFile] = useState(null)
  const meData = useSelector((state) => state.me.data)
  const [moveFileModal, setMoveFileModal] = useState({
    open: false,
    file: {},
    actionType: 'move'
  })
  const [duplicateFileAlert, setDuplicateFileAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })

  const [canCreate, canUpdate] = useMemo(() => {
    const createPermission = [
      userRoles.AGENCY_ADMIN,
      userRoles.USER_AGENCY,
      userRoles.USER_CLIENT
    ].includes(meData.role)

    const updatePermission = [
      userRoles.AGENCY_ADMIN,
      userRoles.USER_AGENCY,
      userRoles.USER_CLIENT
    ].includes(meData.role)

    return [createPermission, updatePermission]
  }, [meData.role])
  // const [uploader, setUploader] = useState(undefined)
  // const [file, setFile] = useState({
  //   selectedFile: null,
  //   uploadId: '',
  //   fileName: ''
  // })
  // const [uploadId, setUploadId] = useState('')

  useEffect(() => {
    for (let dirId of dirIdsArray) {
      const data = filesAssetsData[dirId]

      if (typeof data !== 'object') {
        // displayLoader(true)
        setFilesLoading(true)
        dispatch(fetchFolders(workspaceId, dirId, fetchFoldersCallback))
        dispatch(fetchFiles(dirId, fetchFilesCallback))
        return
      }

      if (dirId === currentDirId) {
        setAssetsFolders(data.folders)
        setAssetsFiles(data.files)
        // displayLoader(false)
      }
    }
  }, [dirIdsArray, filesAssetsData])

  // useEffect(() => {
  //   dispatch(
  //     getFolderStorageData(currentDirId)
  //   )
  // }, [currentDirId])

  // useEffect(() => {
  //   if (dirId) {
  //     const folderName = getFolderNameById(dirId)
  //     setParentList((prev) => [...prev, { name: folderName, id: dirId }])
  //   }
  // }, [dirId])

  useEffect(() => {
    if (filesStack.open) {
      hideChatMessenger()
    } else {
      showChatMessenger()
      setFilesStack((prev) => ({
        ...prev,
        items: prev.items.filter((item) => item.loading)
      }))
    }
  }, [filesStack.open])

  const fetchFilesCallback = (res, err) => {
    setFilesLoading(false)
  }

  const fetchFoldersCallback = (res, err) => {
    if (!err) {
      setAssetsFolders(res)
      displayLoader(false)
    }
  }

  const handleFolderClick = (id) => {
    history.push(`${pathname}/${id}`)
  }

  const fileUploading = (uploadData) => {
    dispatch(
      uploadFile(uploadData, (res, err) => {
        // setFile(undefined)
        if (err) dispatch(fireErrorToaster(res))
      })
    )
  }

  const handleFileUpload = (files) => {
    const file = files[0]

    if (file) {
      const fileData = {
        fileName: file.name.slice(0, file.name.lastIndexOf('.')),
        file: file,
        fileAssetsFolder: currentDirId,
        uid: uniqueId('file_')
      }

      if (
        doesFileExist(
          fileData.fileName,
          file.name.match(/.\w+$/)[0] // getting extension
        )
      ) {
        toggleDuplicateFileAlert(fileData)
      } else {
        fileUploading(fileData)
      }
    }
  }

  const doesFileExist = (fileName, fileExtension) => {
    return Boolean(
      assetsFiles.find((file) => {
        return file.name === fileName && file.extension === fileExtension
      })
    )
  }

  const closeFilesStack = () => {
    setFilesStack((prev) => ({ ...prev, open: false }))
  }

  const openCreateFolderModal = () => {
    setCreateRenameModal({ open: true, elmFor: 'folder', actionType: 'create' })
  }

  const openRenameFolderModal = (data = null) => {
    setCreateRenameModal({
      open: true,
      elmFor: 'folder',
      actionType: 'rename',
      data
    })
  }

  const openRenameFileModal = (data = null, elmFor) => {
    setCreateRenameModal({
      open: true,
      elmFor: 'file',
      actionType: 'rename',
      data
    })
  }

  const closeFolderModal = () => {
    setCreateRenameModal((prev) => ({ ...prev, open: false }))
  }

  const openDeleteAlertModal = (data) => {
    setDeleteFolderAlert({
      open: true,
      loading: false,
      data
    })
  }

  const closeDeleteFolderAlert = () => {
    setDeleteFolderAlert((prev) => ({ ...prev, open: false }))
  }

  const handleDeleteFolder = () => {
    setDeleteFolderAlert((prev) => ({ ...prev, loading: true }))
    dispatch(deleteFolder(deleteFolderAlert.data._id, deleteFolderCallback))
  }

  const deleteFolderCallback = (res, err) => {
    if (!err) {
      closeDeleteFolderAlert()
      dispatch(fireSuccessToaster('Folder deleted successfully!'))
    } else {
      setDeleteFolderAlert((prev) => ({
        ...prev,
        loading: false
      }))
      dispatch(fireErrorToaster(res))
    }
  }

  const openPreview = (file) => {
    dispatch(handleFilePreview({ open: true, file: file.url }))
    // setPreview({ open: true, file })
  }

  const closePreview = () => {
    setPreview((prev) => ({ ...prev, open: false }))
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    setDragOver(true)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files

    setDragOver(false)
    handleFileUpload(files)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragOver(false)
  }

  const handleCopyFolderLink = (folder) => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}${pathname}/${folder._id}`
    )

    dispatch(fireSuccessToaster(errorMessages.LINK_COPIED))
  }

  const folders = useMemo(() => {
    return assetsFolders.map((item) => (
      <Folder
        key={item._id}
        onClick={(e) => {
          e.stopPropagation()
          handleFolderClick(item._id)
        }}
        name={item.name}
        className="text-primary-main"
        id={item._id}
        canUpdate={canUpdate}
        folderOptions={[
          {
            label: 'Open',
            icon: <FolderOpen />,
            id: item._id,
            method: (e) => {
              e.stopPropagation()
              handleFolderClick(item._id)
            }
          },
          {
            label: 'Rename',
            icon: <EditIcon />,
            id: item._id,
            method: (e) => {
              e.stopPropagation()
              openRenameFolderModal(item)
            }
          },
          {
            label: 'Delete',
            icon: <TrashIcon />,
            id: item._id,
            method: (e) => {
              e.stopPropagation()
              openDeleteAlertModal(item)
            }
          },
          {
            label: 'Copy link',
            icon: <CopyIcon />,
            id: item._id,
            method: (e) => {
              e.stopPropagation()
              handleCopyFolderLink(item)
            }
          }
        ]}
      />
    ))
  }, [assetsFolders])

  const toggleMoveFileModal = (data, actionType) => {
    if (moveFileModal.open)
      setMoveFileModal((prev) => ({ ...prev, open: false }))
    else setMoveFileModal({ open: true, file: data, actionType })
  }

  const toggleDuplicateFileAlert = (data) => {
    if (duplicateFileAlert.open) {
      setDuplicateFileAlert((prev) => ({ ...prev, open: false }))
    } else {
      setDuplicateFileAlert({ open: true, loading: false, data })
    }
  }

  const uploadDuplicateFile = () => {
    let newName,
      count = 2,
      file = duplicateFileAlert.data.file,
      extension = file.name.match(/.\w+$/)[0] // getting extension

    while (!newName) {
      const tempFileName = duplicateFileAlert.data.fileName + `(${count})`
      if (!doesFileExist(tempFileName, extension)) newName = tempFileName
      else count++
    }

    const formData = new FormData()
    formData.append(
      'file',
      duplicateFileAlert.data.file,
      `${newName}${extension}`
    )

    const newFile = formData.get('file')
    setDuplicateFileAlert((prev) => ({ ...prev, open: false }))
    fileUploading({
      ...duplicateFileAlert.data,
      file: newFile,
      fileName: newName
    })
  }

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-6 mt-4">
        <FilesAssetsBreadCrumbs />
        {canCreate && (
          <AddNew
            openFolderModal={openCreateFolderModal}
            handleFileUpload={handleFileUpload}
          />
        )}
      </div>

      {folders.length ? <div className="flex gap-4 mb-8">{folders}</div> : null}

      <div
        className={clsx('mb-12', dragOver && 'bg-gray-200', classes.dropArea)}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
      >
        <FilesTable
          loading={filesLoading}
          files={assetsFiles}
          openPreview={openPreview}
          openMoveFileModal={toggleMoveFileModal}
          openFolderModal={openRenameFileModal}
          canUpdate={canUpdate}
        />
      </div>

      <FilesUploadLoading
        items={filesStack.items}
        open={filesStack.open}
        onClose={closeFilesStack}
      />
      <CreateRenameAssetModal
        open={createRenameModal.open}
        onClose={closeFolderModal}
        data={createRenameModal.data}
        elmFor={createRenameModal.elmFor}
        actionType={createRenameModal.actionType}
      />

      <AlertModal
        warningText={`Do you want to delete ${deleteFolderAlert.data.name} and all its contents?`}
        open={deleteFolderAlert.open}
        handleDialog={closeDeleteFolderAlert}
        handleDeleteAction={handleDeleteFolder}
        loading={deleteFolderAlert.loading}
        deleteBtnText="Delete"
      />
      <AlertModal
        warningText={`${duplicateFileAlert.data.file?.name} already exists! do you still want to upload this file?`}
        open={duplicateFileAlert.open}
        handleDialog={toggleDuplicateFileAlert}
        handleDeleteAction={uploadDuplicateFile}
        loading={duplicateFileAlert.loading}
        deleteBtnText="Yes"
      />
      <AssetPreview
        open={preview.open}
        onClose={closePreview}
        file={preview.file}
      />
      <MoveFileModal
        open={moveFileModal.open}
        file={moveFileModal.file}
        onClose={toggleMoveFileModal}
        actionType={moveFileModal.actionType}
      />
    </div>
  )
}

export default React.memo(FilesFolders)
