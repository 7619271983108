import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

// import { compress, compressAccurately } from 'image-conversion'

const AddAttachment = ({ id, children, handleFile, labelProps, disabled }) => {
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault()
    }
  }

  const handleUploadImage = async (e) => {
    if (currentWorkspace.isComplete) {
      dispatch(
        fireErrorToaster(
          'This task belongs to a complete workspace and you can not upload any attachment to it.'
        )
      )
    } else {
      let file = e.target.files[0]

      // file = await compress(file, {
      //   quality: 0.4
      // })
      if (file) {
        const imgReader = new FileReader()
        imgReader.onloadend = function () {
          handleFile(imgReader.result)
          e.target.value = ''
        }
        imgReader.readAsDataURL(file)
      }
    }
  }

  return (
    <div className="flex flex-row justify-evenly">
      <input
        type="file"
        id={id}
        style={{ display: 'none' }}
        multiple={false}
        accept="*"
        onChange={handleUploadImage}
      />
      <label
        onClick={handleClick}
        role="button"
        component="span"
        htmlFor={id}
        {...labelProps}
      >
        {children}
      </label>
    </div>
  )
}

export default AddAttachment
