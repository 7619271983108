import axios from 'axios'
import { errorMessages } from 'utils'

import { EMAIL_NOTIFICATION_EVENTS } from './reducer'

export const fetchAllNotificationsEvents = (callback) => {
  return async (dispatch) => {
    dispatch({ type: EMAIL_NOTIFICATION_EVENTS.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: 'notificationpermission/user'
      })
      dispatch({ type: EMAIL_NOTIFICATION_EVENTS.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateNotificationsEvents = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `notificationpermission/${id}`,
        data
      })
      dispatch({ type: EMAIL_NOTIFICATION_EVENTS.UPDATED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchUserSubscribedNotificationEvents = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/notification/user'
      })
      dispatch({
        type: EMAIL_NOTIFICATION_EVENTS.USER_SUBSCRIBED_EVENTS,
        payload: res.data
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const saveChanges = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: '/notification/user',
        data
      })
      dispatch({
        type: EMAIL_NOTIFICATION_EVENTS.UPDATE_STATUS,
        payload: res.data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchSubscribedPushNotifications = async (callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/subscription/user'
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const deleteSubscribedPushNotifications = async (id, callback) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/subscription/${id}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
