import { makeStyles, createStyles } from '@material-ui/core'

const sidebarStyles = makeStyles(theme =>
  createStyles({
    iconLink: {
      color: '#4C4C5B',
      fontSize: 13,
      padding: '7px 16px',
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
      },
      '&:hover:not(.active)': {
        color: '#4C4C5B'
      },
      '@media (min-width: 1284px)': {
        fontSize: 15,
        padding: '8px 20px'
      }
    },
    sidebarHeader: {
      height: theme.custom.header.height
    },

    navIconsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: `calc(100vh - ${theme.custom.header.height}px)`,
      // gap: theme.spacing(2),
      paddingTop: '1rem',
      paddingBottom: '1rem',

      '& > * + *': {
        marginTop: theme.spacing(1.5)
      }
    },
    logoutIcon: {
      fill: '#fff',
      height: 20,
      width: 20
    },
    drawerPaper: {
      transform: 'translateX(0px) !important',
      visibility: 'visible !important',

      // overflowX: 'hidden',
      width: 0,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen
      }),
      '&>p': {
        padding: 0,
        overflow: 'hidden',
        transition: theme.transitions.create(['padding'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.leavingScreen
        })
      },
      '&.expanded': {
        boxShadow: '4px 4px 6px -4px rgba(0, 0, 0, 0.08)',
        zIndex: theme.zIndex.drawer + 10,
        width: theme.custom.sidebar.width,
        transition: theme.transitions.create(['width'], {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeInOut
        }),
        '&>p': {
          transition: theme.transitions.create(['padding'], {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.easeInOut
          })
        }
      }
    },
    drawerOpen: {
      zIndex: theme.zIndex.drawer + 10,
      width: theme.custom.sidebar.width,
      transition: theme.transitions.create(['width'], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeInOut
      }),
      '&>p': {
        transition: theme.transitions.create(['padding'], {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeInOut
        })
      }
    },

    drawerClose: {
      overflowX: 'hidden',
      width: 0,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen
      }),
      '&>p': {
        padding: 0,
        overflow: 'hidden',
        transition: theme.transitions.create(['padding'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.leavingScreen
        })
      }
    },
    paperAnchorLeft: {
      left: '0px'
    },
    paperAnchorDockedLeft: {
      left: '0px'
    }
  })
)

export default sidebarStyles
