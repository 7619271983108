import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Divider } from '@material-ui/core'
import { ReactComponent as AngleDown } from 'static/svg/angle-down.svg'

function ThreeDotMenu(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <div className="flex-shrink-0 self-center">
      <Menu
        as="div"
        className={`${
          props.position || 'relative'
        } -mt-2 -ml-6  inline-block text-left"`}
      >
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                <span className="sr-only">Open options</span>
                {!props.icon ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                    />
                  </svg>
                ) : (
                  <div className="flex justify-center text-sm">
                    <p> {props.icon}</p>
                    <AngleDown
                      className="h-6 w-6 text-white fill-white"
                      style={{ fill: 'gray', fontSize: '5px' }}
                    />
                  </div>
                )}
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                style={{
                  transform:
                    props.menuPosition === 'left'
                      ? 'translateX(-50%)'
                      : 'translateX(0)',
                  minWidth: '115px',
                  maxWidth: '350px'
                }}
              >
                <div className="py-1 text-left">
                  {props?.threeDotProps?.map((item) => {
                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <div>
                            {item.name && (
                              <div
                                href="#"
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900 cursor-pointer'
                                    : 'text-gray-700',
                                  'flex flex-col px-6 py-2 text-sm'
                                )}
                                onClick={item.method}
                              >
                                <p className="text-md">{item.name}</p>
                                <p className="text-xs">{item.description}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </Menu.Item>
                    )
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default ThreeDotMenu
