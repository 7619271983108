import axios from 'axios'
import { errorMessages, userRoles } from 'utils'
import { LOGS } from './reducer'

/**
 * Create Logs
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const createComment = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/log',
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: LOGS.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: LOGS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Delete comments
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const deleteComment = (commentId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/log/${commentId}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: LOGS.DELETE_COMMENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: LOGS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch comments by task id
 * @param {String} id task id
 * @param {Function} callback callback function
 */
export const findCommentBytaskId = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/log/task/${id}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: LOGS.ALL, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: LOGS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch comments by subtask id
 * @param {String} id task id
 * @param {Function} callback callback function
 */
export const findCommentBySubtaskId = (id, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/log/subtask/${id}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: LOGS.SUBTASK_LOGS, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: LOGS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch logs by workspace id
 * @param {String} id workspace id
 * @param {Function} callback callback function
 */
export const fetchLogsByWorkspaceId = (id, page, limit, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/log/workspace/${id}?page=${page}&limit=${limit}`
      })
      const sortedData = res.data.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      if (callback) callback(res.data, false)
      dispatch({
        type: LOGS.WORKSPACE_LOGS,
        payload: { _id: id, data: sortedData }
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: LOGS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Post a log
 * @param {Object} data logs data
 * @param {Function} callback callback function
 */
export const postTaskLog = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      logs: { data: prevLogsData },
      me: { data: meData },
      userWorkspaces: { currentWorkspace }
    } = getState()

    try {
      const res = await axios({
        method: 'POST',
        url: '/log',
        data: {
          /*
          here we are invoking this function from 3 different places and 2 of them don't include
          workSpace prop in the data object but 1 does so that's why distructing data obj after adding workSpace.
          */
          workSpace: data.workSpace ?? currentWorkspace._id,
          ...data,
          pComment: prevLogsData[prevLogsData.length - 1]?._id ?? 0,
          user: meData._id,
          category: data.category ?? 'task',
          description: `${data.description}`,
          isExternal:
            meData.role < userRoles.USER_CLIENT
              ? typeof data?.isExternal === 'undefined'
                ? true
                : data.isExternal
              : data?.isExternal ?? false,
          createdAt: new Date(),
          updatedAt: new Date()
        }
      })
      if (callback) callback(res.data, false)
      if (res.data.category === 'task') {
        dispatch({ type: LOGS.FETCHED, payload: res.data })
      } else if (res.data.category === 'subtask') {
        dispatch({ type: LOGS.UPDATE_SUBTASK_LOGS, payload: res.data })
      } else {
        dispatch({ type: LOGS.UPDATE_WORKSPACE_LOGS, payload: res.data })
      }
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const userActiveTimeLog = async () => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/user/log'
    })
  } catch (err) {}
}
