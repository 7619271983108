import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import TeammatesHeader from './TeammatesHeader'
import TeammatesTable from './TeammatesTable'
// import AddNewTeammatePanel from './AddNewTeammatePanel'
import EditTeammatePanelNew from './EditTeammatePanelNew'
import {
  fetchTeammateOfCurrentUser,
  updateClientTeammate
} from 'thunks/teammate/actions'
import { errorMessages, userRoles } from 'utils'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
// import { toggleAddTeammateSidePanel } from 'thunks/generlAppState/actions'

const ClientTeammates = () => {
  const dispatch = useDispatch()
  const search = useSelector((state) => state.generalAppState.headerSearch)
  // const [openAddTeammatePanel, setOpenAddTeammatePanel] = useState(false)
  const [editTeammatePanel, setEditTeammatePanel] = useState({
    open: false,
    data: {}
  })
  const teammates = useSelector((state) => state.userTeammates)
  const { fetchedFirstTime } = useSelector((state) => state.userWorkspaces)
  const [teammatesData, setTeammatesData] = useState([])
  const meData = useSelector((state) => state.me.data)

  const canEditAccount = useMemo(() => {
    //manager, admin, super admin
    let meRole = meData.role,
      mateRole = editTeammatePanel.data.role

    // user cannot edit himself or his superiors
    if (meRole === mateRole) return false
    if (meRole === userRoles.USER_CLIENT) return true // client owner can edit everyone
    if (meRole === userRoles.CLIENT_ADMIN && mateRole > userRoles.USER_CLIENT)
      return true
    if (
      meRole === userRoles.CLIENT_MANAGER &&
      mateRole > userRoles.USER_CLIENT &&
      mateRole !== userRoles.CLIENT_ADMIN
    )
      return true
    return false
  }, [meData.role, editTeammatePanel.data])

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useEffect(() => {
    if (!teammates.fetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [teammates.fetched])

  useEffect(() => {
    if (search.length > 0) {
      let arr = teammates.data.filter((item) =>
        item?.name.toLowerCase().includes(search.toLowerCase())
      )

      setTeammatesData(arr)
    } else {
      setTeammatesData([...teammates.data])
    }
    // if (teammates.data.length) {
    // }
  }, [teammates, search])

  // const handleToggleAddPanel = () => {
  //   dispatch(toggleAddTeammateSidePanel())
  // }

  // const handleAddTeammate = (data, panelCallback) => {
  //   dispatch(
  //     addTeammate(data, (res, err) => {
  //       panelCallback()
  //       addTeammateCallback(res, err)
  //     })
  //   )
  // }

  // const addTeammateCallback = (res, err) => {
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     dispatch(fireSuccessToaster('Teammate Added Successfully'))
  //     handleToggleAddPanel()
  //   }
  // }

  const openEditPanel = (data) => {
    setEditTeammatePanel(data)
  }
  const closeEditPanel = () => {
    setEditTeammatePanel((prev) => ({ ...prev, open: false }))
  }

  const handleUpdateTeammate = (id, data, panelCallback) => {
    dispatch(
      updateClientTeammate(id, data, (res, err) => {
        panelCallback()
        updateTeammateCallback(res, err)
      })
    )
  }

  const updateTeammateCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
    }
  }

  return (
    <OuterContentWrapper>
      <TeammatesHeader />
      {search !== '' && (
        <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
          Search results for:{' '}
          <span className="text-primary-dark">{search}</span>
        </p>
      )}

      <TeammatesTable
        loading={teammates.loading}
        teammates={teammatesData}
        openEditPanel={openEditPanel}
        canEditAccount={canEditAccount}
      />
      {/* <AddNewTeammatePanel
        open={openAddTeammatePanel}
        onClose={setOpenAddTeammatePanel}
        handleAddTeammate={handleAddTeammate}
      /> */}
      <EditTeammatePanelNew
        open={editTeammatePanel.open}
        onClose={closeEditPanel}
        data={editTeammatePanel.data}
        updateTeammate={handleUpdateTeammate}
        // elmFor="client"
        canEditAccount={canEditAccount}
      />
    </OuterContentWrapper>
  )
}

export default ClientTeammates
