import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMemo } from 'react'
import { format } from 'date-fns'
import { fetchStandupsReport } from 'thunks/reports/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
// import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import { ColoredAvatars, GoBack, OutlinedButton } from 'global/globalComponents'
import DaysFilterPopup from '../PeoplePerformance/DaysFilterPopup'
import useReportsFunctions from '../useReportsFunctions'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { useHistory, useRouteMatch } from 'react-router-dom'
import SectionHeading from 'global/globalComponents/SectionHeading'
import moment from 'moment'
// import { CustomTooltip } from 'global/globalComponents'
import { CSVLink } from 'react-csv'
import { ReactComponent as ImportIcon } from 'static/svg/import.svg'
import { dateAndTimeHelper } from 'utils'
import Currency from 'static/Currency/Currency'
import FinancialCardsContainer from './FinacialCardsContainer'

const FinancialReport = () => {
  const [timelineAnchorEl, setTimelineAnchorEl] = useState(null)
  const daysOptions = useMemo(
    () => [
      { label: 'Last 7 days', value: 'last7Days' },
      { label: 'Last 30 days', value: 'last30Days' },
      { label: 'Last 60 days', value: 'last60Days' }
    ],
    []
  )
  const [selectedDaysOption, setSelectedDaysOption] = useState({
    label: 'Last 7 days',
    value: 'last7Days'
  })
  const [timeline, setTimeline] = useState('')
  const { getTimelineQuery } = useReportsFunctions()

  const handleToggleTimelinePopup = useCallback(
    e => {
      if (timelineAnchorEl) setTimelineAnchorEl(null)
      else {
        setTimelineAnchorEl(e.currentTarget)
        e.stopPropagation()
      }
    },
    [timelineAnchorEl]
  )

  const handleSelectTimelineOption = option => {
    const query = getTimelineQuery(option.value)
    setTimeline(query)
    setSelectedDaysOption(option)
  }

  return (
    <>
      <header className="mb-4">
        <GoBack className="mb-2" />
        <SectionHeading>Financials</SectionHeading>
      </header>

      <div className="flex justify-end pr-4 mb-4">
        <div
          role="button"
          onClick={handleToggleTimelinePopup}
          className="cursor-pointer flex items-center text-smaller mr-4"
        >
          <CalendarIcon />
          <span className="font-medium ml-1 text-gray-600 text-xs">
            {selectedDaysOption.label}
          </span>
        </div>

        <OutlinedButton>
          <span>
            <ImportIcon className="w-4 h-4" />
          </span>
          <span className="ml-2">Download CSV</span>
        </OutlinedButton>

        <OutlinedButton className="ml-4">
          <span>
            <ImportIcon className="w-4 h-4" />
          </span>
          <span className="ml-2">Download Detailed CSV</span>
        </OutlinedButton>

        {/* <CSVLink
          // ref={csvRef}
          // data={downloadData}
          filename="Standups summary.csv"
        ></CSVLink> */}
      </div>
      <FinancialCardsContainer />
      <DaysFilterPopup
        anchorEl={timelineAnchorEl}
        onClose={handleToggleTimelinePopup}
        daysOptions={daysOptions}
        selectedDaysOption={selectedDaysOption}
        handleSelectTimelineOption={handleSelectTimelineOption}
      />
    </>
  )
}

export default FinancialReport
