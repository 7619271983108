import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  // deleteTaskCollaborator,
  deleteTaskDetailsteam,
  updateTaskDetails,
  updateTaskDetailsteam,
  updateTaskPosition,
  updateTaskPriority,
  updateTaskSection,
  uploadAttachment
} from 'thunks/task/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { postTaskLog } from 'thunks/logs/action'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { getQuillHTML, userRoles } from 'utils'
// import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import { priorityLabels } from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import { updateSubTaskDetails } from 'thunks/subTask/actions'
import { fetchSingleEmbedById } from 'thunks/embed/action'
import { addCurrentBoard } from 'thunks/workspace/actions'

const indexKey = {
  status: 'orderIndex',
  dueDate: 'dueDateOrderIndex',
  assignee: 'assigneeOrderIndex'
}

const useWorkspaceFunctions = groupBy => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)

  const getCollaboratorsOptions = useCallback((teammates, currentWorkspace) => {
    let arr = []
    if (
      currentWorkspace.assignedAgencyTeam &&
      currentWorkspace.assignedClientTeam
    ) {
      currentWorkspace.assignedAgencyTeam.forEach(item => {
        const idx = teammates.findIndex(mate => mate._id === item.user._id)
        if (idx === -1) arr.push(item)
      })
    }

    return arr
  }, [])

  /**
   * Mark task as complete or incomplete
   * @param {Boolean} value true, false
   * @param {Object} Task
   */
  const markTaskAsComplete = useCallback(
    ({ value, task, elmFor, socketId, cb, board }) => {
      let completeSection = {}

      if (board?.boardSection) {
        completeSection = value
          ? board.boardSection.find(
              item => item.label.toLowerCase() === 'completed'
            )
          : {}
      }

      const data = {
        markAsComplete: value,
        workSpace: task.workSpace,
        socketId,
        completedAt: value ? new Date() : null,
        taskSection: completeSection?._id ?? task.taskSection
      }

      if (elmFor === 'subtask') {
        dispatch(
          updateSubTaskDetails(
            task._id,
            {
              ...data,
              workSpace: task.workSpace
            },
            (res, err) => {
              if (cb) cb(res, err)
              if (!err) {
                dispatch(fireSuccessToaster('Updated Successfully!'))
                updateLog({
                  taskId: task._id,
                  logMsg: `${
                    data.markAsComplete
                      ? `changed the status as "Complete" in the subtask <b>${task.title}</b>`
                      : `removed the "Complete" status from the subtask <b>${task.title}</b>`
                  }`,
                  workspaceId: task.workSpace
                })
              }
            }
          )
        )
      } else {
        dispatch(
          updateTaskDetails(task._id, data, (res, err) => {
            if (cb) cb(res, err)
            if (!err) {
              dispatch(fireSuccessToaster('Updated Successfully!'))
              dispatch(
                postTaskLog({
                  description: value
                    ? `changed the status as "Complete"`
                    : `removed the "Complete" status`,
                  activityType: 'log',
                  task: task._id,
                  workSpace: task.workSpace
                })
              )
              dispatch(
                createAccountLogs({
                  description: `${
                    value
                      ? `changed the status as "Complete"`
                      : `removed the "Complete" status`
                  } of the task <strong>${
                    task.title
                  }</strong> in the project <strong>${
                    currentWorkspace?.name
                  }</strong>`,
                  category: 'task',
                  task: task._id
                })
              )
            }
          })
        )
      }
    },
    [currentWorkspace?.name, currentBoard?.boardSection]
  )

  /**
   * Remove assigned collaborator from task
   * @param {Object} Assignee
   * @param {String} id task id
   * @param {Function} callback
   */
  const removeTaskCollaborator = useCallback(
    ({ userRole, userId, taskId, cb }) => {
      let data
      if (
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_EDITOR,
          userRoles.AGENCY_VIEWER,
          userRoles.AGENCY_ADMIN
        ].includes(userRole)
      ) {
        data = { assignedAgencyTeam: userId }
      } else {
        data = { assignedClientTeam: userId }
      }

      dispatch(deleteTaskDetailsteam(taskId, data, cb))
    },
    []
  )

  /**
   * Add collaborator to task
   * @param {Number} role
   * @param {String} id user id
   * @param {String} id task id
   * @param {Function} callback
   */
  const addTaskCollaborator = useCallback(
    ({ userRole, userId, taskId, cb }) => {
      if (
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_EDITOR,
          userRoles.AGENCY_VIEWER,
          userRoles.AGENCY_ADMIN
        ].includes(userRole)
      ) {
        dispatch(
          updateTaskDetailsteam(
            taskId,
            {
              assignedAgencyTeam: userId
            },
            cb
          )
        )
      } else {
        dispatch(
          updateTaskDetailsteam(
            taskId,
            {
              assignedClientTeam: userId
            },
            cb
          )
        )
      }
    },
    []
  )

  const canUpdateTask = canUpdateTask => {
    if (currentWorkspace.isComplete) {
      dispatch(
        fireErrorToaster(
          `${currentWorkspace.name} is already complete and you can not update its tasks anymore`
        )
      )
      return false
    }

    if (!canUpdateTask) {
      dispatch(
        fireErrorToaster('You do not have permission to perform this action')
      )
      return false
    }

    return true
  }

  // const updateTaskDueDateInDueDateGroup = useCallback(
  //   ({ updateKey, updateValue, task, aboveTask, cb }) => {
  //     let updateData = {
  //       orderIndexColumn: indexKey[groupBy],
  //       newIndex: aboveTask
  //         ? (parseFloat(aboveTask[indexKey[groupBy]] || 0) + 1).toString()
  //         : '0',
  //       above: aboveTask ? aboveTask[indexKey[groupBy]] || '0' : null, //above index,
  //       aboveTask: aboveTask ? aboveTask._id : null,
  //       belowTask: null,
  //       workSpace: task.workSpace,
  //       [updateKey]: updateValue
  //     }

  //     if (!aboveTask) {
  //       updateData.newIndex = '0'
  //     } else {
  //       updateData.newIndex = (
  //         parseFloat(aboveTask[indexKey[groupBy]] || 0) + 1
  //       ).toString()
  //     }

  //     dispatch(updateTaskPosition(task._id, updateData, cb))
  //   },
  //   [groupBy, currentWorkspace.restrictDueDate, meData.role]
  // )

  const handleTaskDateChange = useCallback(
    ({ updateKey, updateValue, task, cb }) => {
      if (
        ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
          meData.role
        ) &&
        currentWorkspace.restrictDueDate?.isDueDateRestrict &&
        currentWorkspace.restrictDueDate?.totalDueDateChangeCounter <=
          task.editDueDateCounter
      ) {
        dispatch(
          fireErrorToaster('Due Date editing has been restricted by Admin')
        )
        return
      }

      dispatch(
        updateTaskDetails(
          task._id,
          {
            [updateKey]: updateValue,
            workSpace: task.workSpace,
            taskSection: task.taskSection
          },
          (res, err) => {
            if (cb) cb(res, err)
            if (!err) {
              updateLog({
                taskId: task._id,
                workspaceId: task.workSpace,
                logMsg: `updated the ${
                  updateKey === 'startDate' ? 'start date' : 'due date'
                } in the task <b>${task.title}</b>`
              })
              updateAccountLogs({
                taskTitle: task.title,
                taskId: task._id,
                logMsg: `changed the ${
                  updateKey === 'startDate' ? 'start date' : 'due date'
                } of the task`
              })
            }
          }
        )
      )
    },
    [groupBy, currentWorkspace.restrictDueDate]
  )

  const getTaskById = useCallback((taskId, allTasks = []) => {
    return [...allTasks].find(item => item._id === taskId)
  }, [])

  const getSearchedTasks = useCallback((search, allTasks = []) => {
    let searchedValue = search.trim().toLowerCase()
    let allTasksData = [...allTasks]

    const handler = task => {
      const text = getQuillHTML(task.description)
      return (
        task.title.toLowerCase().includes(searchedValue) ||
        text.toLocaleLowerCase().includes(searchedValue)
      )
    }

    if (searchedValue) {
      allTasksData = allTasksData.filter(handler)
    }

    return { allTasks: allTasksData }
  }, [])

  const getSectionsWithUpdatedTasksPosition = ({
    sections,
    destinationSectionIndex,
    sourceSectionIndex,
    destinationIndex,
    sourceIndex,
    task
  }) => {
    let updatedSections
    if (
      sections[destinationSectionIndex]._id === sections[sourceSectionIndex]._id
    ) {
      updatedSections = sections.map((item, index) => {
        if (index === destinationSectionIndex) {
          const section = {
            ...item,
            tasks: [...item.tasks]
          }
          section.tasks.splice(sourceIndex, 1)
          section.tasks.splice(destinationIndex, 0, task)
          return section
        }
        return item
      })
    } else {
      updatedSections = sections.map((item, index) => {
        if (index === sourceSectionIndex) {
          // 1. if section is the source
          const section = { ...item }
          section.tasks = section.tasks.filter(
            (task, index) => index !== sourceIndex
          )
          return section
        } else if (index === destinationSectionIndex) {
          // 2. if section is the destination
          const section = { ...item, tasks: [...item.tasks] }
          section.tasks.splice(destinationIndex, 0, task)
          return section
        }

        return item
      })
    }

    return updatedSections
  }

  const getPositionChangeUpdate = (groupBy, section) => {
    if (groupBy === 'status') return { taskSection: section.value }
    if (groupBy === 'dueDate') return { dueDate: section.value }
    if (groupBy === 'assignee')
      return {
        assignedAgencyTeam: section.value.assignedAgencyTeam.map(
          item => item._id
        ),
        assignedClientTeam: section.value.assignedClientTeam.map(
          item => item._id
        )
      }
  }

  const dndTaskPositionChange = ({
    // index,
    targetTaskId,
    // targetSection,
    aboveTask,
    belowTask,
    update
    // allTasks,
    // sections
  }) => {
    // const update = getPositionChangeUpdate(groupBy, targetSection)

    let updateData = {
      orderIndexColumn: indexKey[groupBy],
      newIndex: '',
      // above: allTasks[index - 1]
      //   ? allTasks[index - 1][indexKey[groupBy]] || 0
      //   : null, //above index,
      // aboveTask: allTasks[index - 1] ? allTasks[index - 1]._id : null,
      // belowTask: allTasks[index] ? allTasks[index]._id : null,
      workSpace: currentWorkspace._id,
      workspaceBoard: currentBoard._id,
      ...update
    }

    if (!aboveTask) updateData.newIndex = '0'
    else if (!belowTask) {
      updateData.newIndex = (
        parseFloat(aboveTask[indexKey[groupBy]] || 0) + 1
      ).toString()
    } else {
      updateData.newIndex = (
        (parseFloat(aboveTask[indexKey[groupBy]] || 0) +
          parseFloat(belowTask[indexKey[groupBy]] || 0)) /
        2
      ).toString()
    }
    // else if (index === 0) {
    //   updateData.newIndex = (
    //     parseFloat(allTasks[index][indexKey[groupBy]] || 0) - 1
    //   ).toString()
    // } else if (index === allTasks.length) {
    //   updateData.newIndex = (
    //     parseFloat(allTasks[index - 1][indexKey[groupBy]] || 0) + 1
    //   ).toString()
    // } else {
    //   updateData.newIndex = (
    //     (parseFloat(allTasks[index - 1][indexKey[groupBy]] || 0) +
    //       parseFloat(allTasks[index][indexKey[groupBy]] || 0)) /
    //     2
    //   ).toString()
    // }

    // if (updateData.taskSection && groupBy === 'status') {
    //   const completeSection = sections.find(
    //     (section) => section.value === updateData.taskSection
    //   )
    //   if (
    //     completeSection &&
    //     completeSection.title.toLowerCase() === 'completed'
    //   ) {
    //     updateData.completedAt = new Date()
    //     updateData.markAsComplete = true
    //   }
    // }

    dispatch(updateTaskPosition(targetTaskId, updateData))
  }

  const updateLog = ({ taskId, workspaceId, logMsg = 'updated this task' }) => {
    dispatch(
      postTaskLog({
        description: logMsg,
        activityType: 'log',
        task: taskId,
        workSpace: workspaceId
      })
    )
  }

  const updateAccountLogs = ({ taskTitle, taskId, logMsg }) => {
    dispatch(
      createAccountLogs({
        description: `${logMsg} <strong>${taskTitle}</strong> in the project <strong>${currentWorkspace?.name}</strong>`,
        category: 'task',
        task: taskId
      })
    )
  }

  const changeTaskSection = useCallback(({ task, section, board, cb }) => {
    const prevSection = board.boardSection.find(
      item => item._id === task.taskSection
    )

    dispatch(
      updateTaskSection(
        task._id,
        {
          workSpace: task.workSpace,
          taskSection: section._id,
          completedAt:
            section.label.toLowerCase() === 'completed' ? new Date() : null,
          markAsComplete: section.label.toLowerCase() === 'completed'
        },
        section,
        (res, err) => {
          if (cb) cb(res, err)

          updateLog({
            taskId: task._id,
            workspaceId: task.workSpace,
            logMsg: `changed the status from "${prevSection.label}" to "${section.label}" in the task <b>${task.title}</b>`
          })

          updateAccountLogs({
            taskTitle: task.title,
            taskId: task._id,
            logMsg: `changed the status from "${prevSection.label}" to "${section.label}" of the task`
          })
        }
      )
    )
  }, [])

  const changeTaskPriority = useCallback(({ task, priority, cb }) => {
    dispatch(
      updateTaskPriority(task._id, { priority }, (res, err) => {
        if (cb) cb(res, err)

        if (!err) {
          let oldPriority = priorityLabels.find(
            el => el.order === task.priority
          )
          let newPriority = priorityLabels.find(el => el.order === res.priority)
          const msg = `changed the priority ${
            task.priority ? `from "${oldPriority.label}"` : ''
          } to "${newPriority.label}"`

          updateLog({
            taskId: task._id,
            logMsg: msg,
            workspaceId: task.workSpace
          })
        }
      })
    )
  }, [])

  const updateTaskProperty = useCallback(
    ({ taskId, workspaceId, taskSection, updateData, cb }) => {
      dispatch(
        updateTaskDetails(
          taskId,
          {
            workSpace: workspaceId,
            taskSection: taskSection,
            ...updateData
          },
          (res, err) => {
            if (cb) cb(res, err)
          }
        )
      )
    },
    [currentWorkspace.isComplete]
  )

  const uploadTaskAttachment = useCallback(
    ({ taskId, workspaceId, file, cb }) => {
      dispatch(
        uploadAttachment(
          {
            task: taskId,
            workSpace: workspaceId,
            file
          },
          cb
        )
      )
    },
    [currentWorkspace.isComplete]
  )

  const setCurrentBoardById = useCallback(boardId => {
    if (currentBoard._id === boardId) return
    fetchSingleEmbedById(boardId, (res, err) => {
      if (!err) dispatch(addCurrentBoard(res))
    })
  }, [])

  return {
    getCollaboratorsOptions,
    markTaskAsComplete,
    removeTaskCollaborator,
    addTaskCollaborator,
    handleTaskDateChange,
    // updateTaskDueDateInDueDateGroup,
    getTaskById,
    getSearchedTasks,
    getSectionsWithUpdatedTasksPosition,
    dndTaskPositionChange,
    canUpdateTask,
    changeTaskSection,
    updateLog,
    updateAccountLogs,
    changeTaskPriority,
    updateTaskProperty,
    uploadTaskAttachment,
    setCurrentBoardById
  }
}

export default useWorkspaceFunctions
