import { errorMessages } from 'utils'

export default function catchAsync(fn, cb) {
  return (...rest) => {
    fn(...rest).catch((err) => {
      const errMsg = err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      if (cb) cb(errMsg, true)
    })
  }
}
