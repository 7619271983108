import React, { useEffect, useState } from 'react'
import { Box, Drawer, Typography, Button, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getClient } from '../../../../thunks/addClient/actions'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import 'react-datetime/css/react-datetime.css'
import clsx from 'clsx'
import Select from 'react-select'
import {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import UserDashboardStyles from '../../userDashboardStyles'
import { userRoles } from 'utils'

const ExternalWorkSpace = (props) => {
  const { open, onClose } = props
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()
  const [options, setOptions] = useState({})
  const meData = useSelector((state) => state.me.data)
  const fetchAllClient = useSelector((state) => state.userClients)
  const closePanel = () => {
    onClose()
  }

  useEffect(() => {
    if (!fetchAllClient.fetched && meData?.role < userRoles.USER_CLIENT) {
      dispatch(getClient())
    }
  }, [fetchAllClient.fetched])

  useEffect(() => {
    const options = fetchAllClient.data.map((mate) => {
      return {
        label: mate.name,
        value: mate._id
      }
    })
    setOptions(options)
  }, [fetchAllClient.data])

  return (
    <Drawer
      onClose={closePanel}
      classes={{ paper: clsx(classes.paper, { drawerOpen: open }) }}
      anchor="right"
      open={open}
      variant="persistent"
    >
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
      {/* <Toolbar classes={{ root: classes.toolbarRoot }}>
        <IconButton className={classes.fabRoot} onClick={onClose}>
          <Close />
        </IconButton>
        <Typography variant="h5">New Workspace</Typography>
      </Toolbar> */}

      <DrawerHeader onClose={onClose}>New External Project</DrawerHeader>
      <DrawerContent open={open}>
        {/* <Box p={2} mt={6} style={{ marginBottom: '70px' }}> */}
        <Box p={2}>
          <Box style={{ flexGrow: '1' }}>
            <Typography className={classes.fieldLabel}></Typography>
            <FormLabel style={{ marginTop: '16px' }} required>
              Project Name
            </FormLabel>

            <TextField
              className={classes.textFieldStyle}
              placeholder="Enter Name"
              name="name"
              required
            />
          </Box>

          <br />

          <Box component="form" style={{ flexGrow: '1' }}>
            <Typography className={classes.fieldLabel}></Typography>
            <FormLabel style={{ marginTop: '16px' }}>Description</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="description"
              placeholder="Enter Description"
            />
          </Box>

          <br />

          <Box style={{ flexGrow: '1' }}>
            <Typography className={classes.fieldLabel}></Typography>
            <FormLabel style={{ marginTop: '16px' }} required>
              Add Clients
            </FormLabel>
            <div className={classes.demo}>
              <Select options={options} styles={reactSelectCustomStyles} />
            </div>
          </Box>
        </Box>
      </DrawerContent>
      <input type="submit" style={{ display: 'none' }} />
      <DrawerFooter>
        {/* <Box mt={2} className={classes.saveBtnContainer}> */}
        <Button
          variant="outlined"
          onClick={onClose}
          className={classes.cancelBtn}
        >
          Cancel
        </Button>
        <LoadingButton style={{ color: '#fff' }}>Create</LoadingButton>
        {/* </Box> */}
      </DrawerFooter>
      {/* </Box> */}
      {/* </MuiPickersUtilsProvider> */}
    </Drawer>
  )
}

export default ExternalWorkSpace
