import { ReactComponent as EmbedCalender } from 'static/svg/embed-calender.svg'
import { ReactComponent as EmbedSheet } from 'static/svg/embed-sheet.svg'
import { ReactComponent as EmbedDocs } from 'static/svg/embed-docs.svg'
import { ReactComponent as EmbedImg } from 'static/svg/embed.svg'
import { ReactComponent as BoardImg } from 'static/svg/board.svg'
import TableIcon from 'static/svg/table.svg'
import {
  GoogleCalender,
  GoogleDocs,
  GoogleSheet,
  CodeIcon,
  GoogleSlides
} from 'static/Images'

export const embedObj = {
  board: {
    id: 'board',
    name: 'Board',
    description:
      'Build your perfect Board and easily drag-and-drop tasks between columns.',
    image: <BoardImg />
  },
  calendar: {
    id: 'calendar',
    name: 'Calendar',
    description:
      'Calendar view is your place for planning, scheduling, and resource management.',
    image: <EmbedCalender />
  },
  docs: {
    id: 'docs',
    name: 'Docs',
    description:
      "Create docs, wikis, and knowledge-bases with Doc view's flexible.",
    image: <EmbedDocs />
  },
  sheet: {
    id: 'sheet',
    name: 'Sheet',
    description:
      'Sheets supports more than 400 spreadsheet functions that can be used to create formulas that manipulate data, calculate numbers, and generate strings',
    image: <EmbedSheet />
  },
  slides: {
    id: 'slides',
    name: 'Slides',
    description:
      'Slides is a presentation program included as part of the free, web-based Google Docs Editors suite',
    image: <EmbedSheet />
  },
  embed: {
    id: 'embed',
    name: 'Embed',
    description:
      'Add apps and websites alongside your tasks with Embed view to save time and reduce context switching.',
    image: <EmbedImg />
  }
}

export const sideDrawerLinks = [
  {
    id: 'board',
    text: 'Board',
    icon: TableIcon
  },
  {
    id: 'calendar',
    text: 'Calendar',
    icon: GoogleCalender
  },
  {
    id: 'docs',
    text: 'Docs',
    icon: GoogleDocs
  },
  {
    id: 'sheet',
    text: 'Sheet',
    icon: GoogleSheet
  },
  {
    id: 'slides',
    text: 'Slides',
    icon: GoogleSlides
  },
  {
    id: 'embed',
    text: 'Embed',
    icon: CodeIcon
  }
]
