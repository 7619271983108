import React from 'react'
import Pill from 'global/globalComponents/Pill/Pill'
import QuestionMarkTooltip from '../CustomTooltip'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'

const IntegrationCard = props => {
  const {
    name,
    image,
    actionText,
    connected,
    to,
    commingsoon,
    handlerFunction,
    modalContent
  } = props.data
  const { path } = useRouteMatch()
  const history = useHistory()

  const handleClick = () => {
    if (handlerFunction) {
      handlerFunction({ ...modalContent, name, connected })
    }
    if (!['stripe', 'zapier'].includes(name.toLowerCase())) {
      history.push(
        `${path}/connection/${name.split(' ').join('').toLowerCase()}`
      )
    }
  }

  return (
    <div className="col-span-1 bg-white rounded-xl shadow-md border divide-gray-200">
      <div className="w-full p-6 ">
        <div className="object-cover pb-2 mb-1">
          {image && (
            <img
              src={image}
              alt={name}
              className="h-8 lg:h-10 w-auto object-content"
            />
          )}
        </div>
        <div className="flex-1 ">
          <div className="flex items-center space-x-2">
            <h3 className="text-primary-dark text-smaller md:text-sm lg:text-base font-medium">
              {name}
            </h3>
            {commingsoon ? (
              <span className="whitespace-nowrap leading-none">
                <Pill className="flex-shrink-0" variant="yellow">
                  Coming Soon
                </Pill>
              </span>
            ) : (
              <span className="leading-none">
                <Pill
                  className="flex-shrink-0 "
                  variant={connected ? 'greenLight2' : 'orange'}
                >
                  {connected ? 'Active' : 'Inactive'}
                </Pill>
              </span>
            )}
            {name === 'Stripe' && (
              <QuestionMarkTooltip
                text="Connect your Stripe account for seamless payment from Clients"
                iconSize="small"
                direction="right"
                info={true}
              />
            )}
            {name === 'Zapier' && (
              <QuestionMarkTooltip
                text="Connect your account with zapier"
                iconSize="small"
                direction="right"
                info={true}
              />
            )}
            {name === 'Slack' && (
              <QuestionMarkTooltip
                text="Connect with your Slack account to never miss an update"
                direction="right"
                info={true}
              />
            )}
            {name === 'Webhook' && (
              <QuestionMarkTooltip
                text="Use Webhooks to connect your portal with other platforms"
                direction="right"
                info={true}
              />
            )}
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">
            {/* {person.title} */}
          </p>
        </div>

        {/* <div className="flex items-center justify-center h-10 w-20 object-cover">
          {image && (
            <img
              src={image}
              alt={name}
              className="h-10 w-auto object-content"
            />
          )}
        </div> */}
      </div>
      <div>
        <div className="-mt-px px-6 flex divide-x divide-gray-200">
          {to ? (
            <Link
              to={to}
              className="w-full h-10 text-smaller lg:text-sm font-medium flex items-center justify-center text-primary-main"
            >
              {connected ? 'Manage' : 'Connect'}
            </Link>
          ) : (
            <div
              className="w-full h-10 text-smaller lg:text-sm font-medium flex items-center justify-center text-primary-main cursor-pointer"
              onClick={handleClick}
            >
              <button className="w-full h-10 text-smaller lg:text-sm font-medium text-primary-main">
                {connected ? 'Manage' : 'Connect'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default IntegrationCard
