import { useState } from 'react'
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { LoadingButton } from 'global/globalComponents'
import { Button } from '@material-ui/core'
import Pill from 'global/globalComponents/Pill/Pill'
import Moment from 'react-moment'
import clientSubscriptionStyles from './ClientSubscriptionStyles'
import { recurringData } from 'utils'

const SubscriptionCard = ({
  service,
  isActive,
  handleCardBtnClick,
  withCancel,
  cancelRequested,
  loading
  // status
}) => {
  const classes = clientSubscriptionStyles()
  // const [loading, setLoading] = useState(false)
  // console.log(service, 'SERVICE')

  const handleActiveClick = () => {
    // setLoading(true)
    handleCardBtnClick(service)
  }

  const handleCancelClick = () => {
    // setLoading(true)
    handleCardBtnClick(service)
  }

  const dateFormatter = (date, key) => {
    if (key === 'create') return date?.slice(0, 10)
    else {
      return date?.payment_plan?.isRecurring
        ? date?.payment_plan?.recurring?.interval === 'month'
          ? new Date(date.createdAt).getTime() +
            date?.payment_plan?.recurring?.interval_count *
              30 *
              24 *
              60 *
              60 *
              1000
          : new Date(date.createdAt).getTime() +
            date?.payment_plan?.recurring?.interval_count *
              365 *
              24 *
              60 *
              60 *
              1000
        : new Date(date.createdAt).getTime() + 30 * 24 * 60 * 60 * 1000
    }
  }

  let element
  if (isActive) {
    if (withCancel) {
      element = (
        <Button
          disabled={cancelRequested === 'raise'}
          onClick={handleCancelClick}
          color="secondary"
          size="small"
        >
          {cancelRequested === 'raise' ? 'Cancellation requested' : 'cancel'}
        </Button>
      )
    }
    // else if (status) {
    //   element = (
    //     <div className={`flex items-center ${classes.cancel}`}>
    //       <CheckCircleIcon
    //         fontSize="small"
    //         style={{ marginRight: '0.5rem', color: 'inherit' }}
    //       />
    //       <span className="text-sm font-medium">Cancelled</span>
    //     </div>
    //   )
    // }
    else {
      element = (
        <div className={`flex items-center ${classes.active}`}>
          <CheckCircleIcon
            fontSize="small"
            style={{ marginRight: '0.5rem', color: 'inherit' }}
          />
          <span className="text-xs font-medium">Activated</span>
        </div>
      )
    }
  } else {
    element = (
      <LoadingButton
        variant="outlined"
        loading={loading === service._id}
        onClick={handleActiveClick}
        size="small"
      >
        Activate
      </LoadingButton>
    )
  }

  // console.log(service, 'Service')

  return (
    <div
      className={`text-smaller lg:text-sm rounded bg-white p-6 rounded-xl relative border-2`}
    >
      <div className="flex items-center justify-between mb-4">
        {service.image || service.payment_plan?.image ? (
          <img
            src={`${service.image || service.payment_plan?.image}`?.replace(
              's3.wasabisys.com/',
              ''
            )}
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
            alt=""
          />
        ) : (
          <SportsVolleyballIcon className={classes.cardIcon} />
        )}

        {element}
      </div>
      <div className="flex items-center">
        <h4 className="text-smaller md:text-sm lg:text-base font-medium text-custom-dark-light mr-1">
          {service.name}
        </h4>
        <span className="leading-3">
          <Pill
            variant={
              service.isRecurring || service.payment_plan?.isRecurring
                ? 'yellow'
                : 'blue'
            }
          >
            {service.isRecurring || service.payment_plan?.isRecurring
              ? 'recurring'
              : 'one time'}
          </Pill>
        </span>
        {service?.payment_plan && (
          <span className="leading-3 ml-1">
            <Pill variant={service?.status === 'paid' ? 'green' : 'red'}>
              {service?.status}
            </Pill>
          </span>
        )}
      </div>

      <p className="text-xs text-custom-gray-main">
        {service.description || service.payment_plan.description}
      </p>
      <div className="flex justify-between items-center flex-wrap text-xs lg:text-sm gap-2 mt-4">
        <span className={classes.pricing}>
          {service.amount * 0.01}{' '}
          {service.currency
            ? service.currency.toUpperCase()
            : service.payment_plan.currency.toUpperCase()}{' '}
          {(service.isRecurring || service?.payment_plan?.isRecurring) &&
            recurringData.find(
              el =>
                el.interval ===
                  (service?.payment_plan?.recurring?.interval ||
                    service?.recurring?.interval) &&
                el.interval_count ===
                  (service?.payment_plan?.recurring?.interval_count ||
                    service?.recurring?.interval_count)
            ).name}
        </span>
        {service.payment_plan && (
          <div className="flex flex-col" style={{ fontSize: '11px' }}>
            <div className="flex">
              <p
                style={{
                  width: service?.payment_plan?.isRecurring ? '95px' : '70px'
                }}
              >
                Active since:
              </p>
              <p className="text-custom-gray-main">
                <Moment
                  format="MMM D, YYYY"
                  date={dateFormatter(service.createdAt, 'create')}
                />
              </p>
            </div>

            {service?.payment_plan?.isRecurring && (
              <div className="flex">
                <p style={{ width: '95px' }}>Next invoice date:</p>
                <p className="text-custom-gray-main">
                  <Moment
                    format="MMM D, YYYY"
                    date={dateFormatter(service, 'expire')}
                  />
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SubscriptionCard
