import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'

export const TableContainer = props => {
  const {
    children,
    containerProps = {},
    mb = 16,
    shadow = 'lg',
    ...restProps
  } = props
  return (
    <div
      {...containerProps}
      className={`align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-xl mb-${mb} border rounded-xl shadow-${shadow}`}
    >
      <table
        className="min-w-full divide-y divide-gray-200 border-collapse border-hidden"
        {...restProps}
      >
        {children}
      </table>
    </div>
  )
}

export const Thead = props => {
  const { children, className, ...restProps } = props

  return (
    <thead
      {...restProps}
      className={clsx(
        'font-medium uppercase bg-custom-gray-light-5 text-left h-14',
        className
      )}
    >
      <tr>{children}</tr>
    </thead>
  )
}

export const Th = props => {
  const { children, className, center = false, ...restProps } = props
  return (
    <th
      className={clsx(
        'tracking-wider font-medium py-3 px-4 lg:px-6  text-extraSmaller lg:text-xs text-custom-gray-dark',
        className,
        { 'text-center': center }
      )}
      {...restProps}
    >
      {children}
    </th>
  )
}

export const Tbody = props => {
  const { children, className, ...restProps } = props
  return (
    <tbody
      {...restProps}
      className={clsx(
        'bg-white divide-y divide-gray-200 text-gray-500 text-xs lg:text-sm',
        className
      )}
    >
      {children}
    </tbody>
  )
}

export const Tr = props => {
  const { children, className, ...restProps } = props
  return (
    <tr className={clsx('hover:bg-gray-100', className)} {...restProps}>
      {children}
    </tr>
  )
}

export const Td = props => {
  const { children, className, center = false, ...restProps } = props
  return (
    <td
      {...restProps}
      className={clsx('px-4 py-3 lg:px-6 lg:py-4 text-gray-500 ', className, {
        'text-center': center
      })}
    >
      {children}
    </td>
  )
}

export const TableDataLoading = ({ cols }) => {
  return (
    <>
      {Array(3)
        .fill('')
        .map((i, index) => (
          <tr key={index} className="bg-white">
            {Array(cols)
              .fill('')
              .map((item, index) =>
                index === 0 ? (
                  <td
                    key={index}
                    className="px-4 lg:px-6 py-1 whitespace-nowrap text-sm text-gray-900"
                  >
                    <Skeleton height={40} />
                  </td>
                ) : (
                  <td
                    key={index}
                    className="px-6 py-1 text-right whitespace-nowrap text-sm text-gray-500"
                  >
                    <Skeleton height={40} />
                  </td>
                )
              )}
          </tr>
        ))}
    </>
  )
}
