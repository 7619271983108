import React from 'react'
import clsx from 'clsx'
import { PopupMenu } from 'global/globalComponents'
import { ReactComponent as ArrowRight } from 'static/svg/arrow-right.svg'
import Card from 'components/Workspace/ProjectStats/Card'
import Currency from 'static/Currency/Currency'
import { Skeleton } from '@material-ui/lab'

const FinancialSmallCard = ({
  icon,
  currency,
  revenue,
  invoices,
  title,
  view,
  loading
}) => {
  return (
    <Card className="h-full w-full">
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <div className="flex items-center justify-between">
            {icon && <div className="text-primary-main">{icon}</div>}
            {/* <div>0.5%</div> */}
            {/* <PopupMenu menuItems={[]} /> */}
          </div>
          <div className="my-4 font-semibold text-sm lg:text-base text-primary-mid-dark">
            {currency ? Currency[currency].symbol_native : ''}{' '}
            {revenue ?? invoices}
          </div>
          <div className="my-4 font-medium">{title}</div>
          {/* <footer className="flex justify-end">
        <button className="flex items-center text-primary-main text-xs lg:text-sm">
          view
          <ArrowRight className="ml-2" />
        </button>
      </footer> */}
        </>
      )}
    </Card>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      <div className="flex items-center justify-between">
        <Skeleton height={80} width={60} />
      </div>
      <div className="my-4 ">
        <Skeleton height={40} width={150} />
      </div>
      <div className="my-4 ">
        {' '}
        <Skeleton height={40} />
      </div>
    </>
  )
}

export default FinancialSmallCard
