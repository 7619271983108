import StatusDropDown from 'components/Workspace/StatusDropDown'
import Pill from 'global/globalComponents/Pill/Pill'
import { isEqual } from 'lodash'
import React, { useState, useMemo } from 'react'
import { adjustColor } from 'utils'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

const StatusCell = (props) => {
  const {
    value,
    row: { original },
    taskPermission,
    changeTaskSection
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  const taskSection = useMemo(() => {
    if (currentBoard?.boardSection) {
      return currentBoard.boardSection.find((item) => item._id === value) ?? {}
    }
    return {}
  }, [currentBoard, value])

  const togglePopper = (e) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleChangeSection = (section) => {
    changeTaskSection({
      section,
      task: original,
      board: currentBoard,
      cb: () => {
        togglePopper()
      }
    })
  }

  return (
    <>
      <div style={{ minWidth: 200 }}>
        <div className="px-3 py-2">
          {taskSection.label && (
            <Pill
              style={{
                color: adjustColor(taskSection.color, -70),
                backgroundColor: taskSection.color + '20'
              }}
              className={clsx(taskPermission.update && 'cursor-pointer')}
              onClick={taskPermission.update ? togglePopper : undefined}
            >
              {taskSection.label}
            </Pill>
          )}
        </div>
      </div>

      {taskPermission.update && (
        <StatusDropDown
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={togglePopper}
          changeStatus={handleChangeSection}
          statusList={currentBoard.boardSection}
          taskSection={taskSection?._id}
        />
      )}
    </>
  )
}

// ===== Prevents unnecessary re-rendering ====
// const canRender = (prevProps, nextProps) => {
//   return (
//     isEqual(prevProps.value, nextProps.value) &&
//     isEqual(prevProps.taskPermission, nextProps.taskPermission)
//   )
// }

export default React.memo(StatusCell)
