import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { Button } from '@material-ui/core'

const SingleTemplate = ({ handleBack, data, handleSelectTemplate }) => {
  return (
    <div className="mr-8">
      <div>
        <button onClick={handleBack} className="text-primary-main text-sm ">
          <ArrowBackRoundedIcon fontSize="small" />
          &nbsp; Back
        </button>
        <div className="my-4 flex justify-between items-center">
          <h1 className="text-primary-dark text-lg lg:text-xl font-medium my-2">
            {data.title}
          </h1>
          <Button
            onClick={() =>
              handleSelectTemplate({
                ...data,
                label: data.title,
                value: data._id
              })
            }
          >
            Use Template
          </Button>
        </div>

        <p className=" text-primary-mid-dark-2 text-base lg:text-lg">
          {data.description}
        </p>
      </div>
    </div>
  )
}

export default SingleTemplate
