import React, { useEffect, useState } from 'react'
import projectStatusUpdateStyles from './projectStatusUpdateStyles'
import clsx from 'clsx'
import TaskStatusDropdown from './TaskStatusDropdown'
import {
  ToggleSwitch,
  CustomTooltip,
  ColoredAvatarGroup,
  Loader
} from 'global/globalComponents'
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as AngleIcon } from 'static/svg/angle-down.svg'
import { ReactComponent as CircleIcon } from 'static/svg/ring.svg'
import { ReactComponent as DotIcon } from 'static/svg/circle.svg'
import { DatePicker } from 'antd'
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close'
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField
} from '@material-ui/core'
// import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
// import ConfigProvider from 'antd/lib/config-provider'
import DateDropdown from './DateDropdown'
import { ColoredAvatars } from 'global/globalComponents'
import { format } from 'date-fns'
import { AvatarGroup } from '@material-ui/lab'
import { NoAccess } from 'static/svg'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { getListViewTasks, getSectionTasks } from 'thunks/task/actions'

const StatusUpdateSection = ({
  title,
  description,
  taskConfiguration,
  tasks,
  handleUpdateSectionData,
  index,
  disabled,
  currentStatusPostId,
  handleDeleteSection
}) => {
  const dispatch = useDispatch()
  const { RangePicker } = DatePicker
  const [attachTasks, setAttachTasks] = useState(false)
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const AllTasks = useSelector(state => state.userTasks.data)
  const [sectionOptions, setSectionOptions] = useState([])
  const classes = projectStatusUpdateStyles()
  const [secData, setSecData] = useState({
    title,
    description
  })
  const [taskStatusAnchor, setTaskStatusAnchor] = useState(null)
  const [dateAnchor, setDateAnchor] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState({})
  const [sectionTasks, setSectionTasks] = useState({
    loading: false,
    fetched: false,
    data: []
  })

  const customFormat = value => moment(value).format('DD MMM')

  useEffect(() => {
    if (disabled)
      return setSectionTasks({
        loading: false,
        data: tasks,
        fetched: true
      })
    if (selectedStatus?._id) {
      setSectionTasks(prev => ({ ...prev, data: [], loading: true }))
      dispatch(
        getSectionTasks(
          {
            workspace: currentWorkspace._id,
            workspaceBoard: currentBoard._id,
            section: selectedStatus._id
          },
          {
            view: 'board',
            type: 'section',
            limit: 500,
            page: 0
          },
          selectedStatus._id, //sectionId
          'status', //groupBy (default)
          (res, err) => {
            if (!err) {
              setSectionTasks({
                loading: false,
                data: res.data,
                fetched: true
              })
              // setFetchedTasks(prev => ({ ...prev, [selectedStatus._id]: res.data }))
            }
          }
        )
      )
    }
  }, [selectedStatus?._id, disabled])

  useEffect(() => {
    if (sectionTasks.data.length) {
      handleChangeTaskStatus()
    }
  }, [sectionTasks])

  useEffect(() => {
    if (currentBoard._id) {
      const sections = currentBoard.boardSection.map(sec => ({
        _id: sec._id,
        label: sec.label,
        value: sec._id,
        color: sec.color ?? 'blue'
      }))

      setSectionOptions(sections)
    }
  }, [currentBoard._id])

  useEffect(() => {
    if (taskConfiguration?.workspaceSection?._id) {
      if (!attachTasks) {
        setAttachTasks(true)
        setSelectedStatus(taskConfiguration.workspaceSection)
      }
    } else {
      setAttachTasks(false)
    }
  }, [index, currentStatusPostId, taskConfiguration])

  useEffect(() => {
    setSecData({
      title,
      description
    })
  }, [title, description, currentStatusPostId])

  const getFilteredTasks = selectedDate => {
    let filterTasks = sectionTasks.data.filter(task => {
      if (task.dueDate) {
        return (
          new Date(task.dueDate).getTime() >= selectedDate.start &&
          new Date(task.dueDate).getTime() <= selectedDate.end
        )
      } else {
        return (
          new Date(task.createdAt).getTime() >= selectedDate.start &&
          new Date(task.createdAt).getTime() <= selectedDate.end
        )
      }
      // if (task.taskSection === selectedStatus.value) {
      // } else {
      //   return false
      // }
    })
    return filterTasks
  }

  const updateSectionData = (taskData, selectedDate, selectedStatus) => {
    handleUpdateSectionData('tasks', taskData, index)
    handleUpdateSectionData(
      'taskConfiguration',
      {
        workspaceSection: selectedStatus,
        start: selectedDate.start,
        end: selectedDate.end
      },
      index
    )
  }

  const handleChangeTaskStatus = () => {
    // const selectedStatus = e
    const selectedDate = {
      start: taskConfiguration.start,
      end: taskConfiguration.end
    }
    if (taskConfiguration?.start && taskConfiguration?.end) {
      const filterTask = getFilteredTasks(selectedDate)
      updateSectionData(filterTask, selectedDate, selectedStatus)
    }
  }

  const handleAttachTasks = () => {
    if (!attachTasks) {
      const selectedDate = {
        start: new Date().getTime() - 7 * 24 * 3600 * 1000,
        end: new Date().getTime()
      }
      const selectedStatus = sectionOptions[0]
      const filterTasks = getFilteredTasks(selectedDate, selectedStatus)
      updateSectionData(filterTasks, selectedDate, selectedStatus)
    } else {
      setSelectedStatus(sectionOptions[0])
      handleUpdateSectionData('tasks', [], index)
      handleUpdateSectionData('taskConfiguration', {}, index)
    }
    setAttachTasks(!attachTasks)
  }

  const setSectionData = e => {
    handleUpdateSectionData(e.target.name, e.target.value, index)
  }

  const handleChange = e => {
    setSecData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const toggleTaskStatusDropdown = e => {
    if (taskStatusAnchor) setTaskStatusAnchor(null)
    else {
      setTaskStatusAnchor(e.currentTarget)
    }
  }

  const toggleDateDropdown = e => {
    if (dateAnchor) setDateAnchor(null)
    else {
      setDateAnchor(e.currentTarget)
    }
  }

  const deleteTask = id => {
    const filterTasks = tasks.filter(task => task._id !== id)
    handleUpdateSectionData('tasks', filterTasks, index)
  }

  const handleDateChange = date => {
    const selectedDate = {
      start: new Date(moment(date[0]).format('YYYY-MM-DD')).getTime(),
      end: new Date(moment(date[1]).format('YYYY-MM-DD')).getTime()
    }
    const selectedStatus = taskConfiguration.workspaceSection
    const filterTasks = getFilteredTasks(selectedDate, selectedStatus)
    updateSectionData(filterTasks, selectedDate, selectedStatus)
  }

  return (
    <>
      {index !== 0 && <hr className="mt-4" />}
      <section className="my-4" style={{ minHeight: '120px' }}>
        <header className="flex justify-between items-center">
          <div className="flex-1">
            {!disabled ? (
              <>
                <TextField
                  variant="standard"
                  type="text"
                  multiline
                  value={secData.title}
                  onBlur={setSectionData}
                  className="w-full bg-transparent p-1 my-1 font-bold border border-transparent hover:border-gray-300 rounded-md"
                  name="title"
                  disabled={disabled}
                  onChange={handleChange}
                  InputProps={{
                    disableUnderline: true, // <== added this to remove border with variant = 'standard'
                    className: classes.title
                  }}
                />
              </>
            ) : (
              <p
                className={clsx(
                  'w-full bg-transparent font-bold outline-0',
                  classes.title
                )}
              >
                {secData.title}
              </p>
            )}
          </div>
          {!disabled && (
            <div>
              <DeleteIcon
                className="text-custom-red-main cursor-pointer ml-2"
                onClick={() => handleDeleteSection(index)}
              />
            </div>
          )}
        </header>
        <div>
          {!disabled ? (
            <TextField
              variant="standard"
              multiline
              value={secData.description}
              placeholder={disabled ? '' : 'Start writing here'}
              name="description"
              onChange={handleChange}
              disabled={disabled}
              className={clsx('w-full')}
              onBlur={setSectionData}
              InputProps={{
                disableUnderline: true, // <== added this to remove border with variant = 'standard'
                className: classes.description
              }}
            />
          ) : secData.description ? (
            <p
              className={clsx(
                'w-full text-gray-700 bg-transparent',
                classes.description
              )}
            >
              {secData.description}
            </p>
          ) : null}
        </div>

        {!disabled && (
          <>
            <div className="flex items-center text-smaller lg:text-sm mt-4 mb-8">
              <div className="flex items-center space-x-2">
                <ToggleSwitch
                  checked={attachTasks}
                  onChange={handleAttachTasks}
                  loading={false}
                />
                <p className=" font-medium">Attach Tasks</p>
              </div>

              {attachTasks && (
                <div className="flex items-center space-x-1 ">
                  <button
                    onClick={toggleTaskStatusDropdown}
                    className={clsx(
                      'ml-2 px-1 py-2 rounded transition-colors ease-in-out duration-150 flex items-center'
                    )}
                  >
                    <span className=" text-blue-600  capitalize">
                      {taskConfiguration?.workspaceSection?.label}
                    </span>

                    <AngleIcon
                      className="h-4 w-4 ml-1"
                      style={{ fill: '#0f62fe' }}
                    />
                  </button>
                  <DotIcon className="h-1 w-1 mr-2" />
                  <span className=" text-gray-500">between</span>

                  <RangePicker
                    defaultValue={[
                      moment(new Date(taskConfiguration?.start), 'YYYY-MM-DD'),
                      moment(new Date(taskConfiguration?.end), 'YYYY-MM')
                    ]}
                    onChange={handleDateChange}
                    format={customFormat}
                    bordered={false}
                    allowClear={false}
                    style={{
                      width: '180px'
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}

        {attachTasks || disabled ? (
          <div className="text-smaller lg:text-sm mt-4 mb-8 px-1">
            {disabled && taskConfiguration.workspaceSection?._id && (
              <div className=" mb-4 flex items-center space-x-2">
                <p className="text-gray-900 font-semibold">
                  Tasks {taskConfiguration.workspaceSection?.label}
                </p>
                <DotIcon className="h-1 w-1 mr-2" />
                <span className=" text-gray-700 font-normal">
                  between{' '}
                  {taskConfiguration?.start &&
                    format(
                      new Date(taskConfiguration?.start),
                      'dd MMM yy'
                    )}{' '}
                  and{' '}
                  {taskConfiguration?.end &&
                    format(new Date(taskConfiguration?.end), 'dd MMM yy')}
                </span>
              </div>
            )}
            {sectionTasks.loading ? (
              <div className="flex justify-center items-center">
                <CircularProgress size={30} />
              </div>
            ) : tasks.length > 0 ? (
              <table className="w-full px-2 ">
                <thead className="text-left border-b">
                  <th className="px-1 py-2 text-gray-900 font-semibold">
                    Task Name
                  </th>
                  <th className="p-2 text-gray-900 font-semibold">Assigned</th>
                  <th className="p-2 text-gray-900 font-semibold">
                    Created At
                  </th>
                  <th className="p-2 text-gray-900 font-semibold">Due Date</th>
                  {!disabled && <th className="p-3"> </th>}
                </thead>
                <tbody className="bg-gray-50">
                  {tasks.map(item => (
                    <tr
                      className={` border-b text-gray-700 hover:bg-gray-100 show-on-hover-parent`}
                    >
                      <td className="p-2">{item.title}</td>
                      <td className="p-2">
                        <ColoredAvatarGroup
                          users={[
                            ...item?.assignedAgencyTeam,
                            ...item?.assignedClientTeam
                          ]}
                          size="tiny"
                          max={4}
                        />
                        {/* <AvatarGroup
                          classes={{ root: classes.avatarRoot }}
                          max={4}
                        >
                          {item?.assignedAgencyTeam?.map((item) => (
                            <ColoredAvatars
                              key={item?._id}
                              user={item}
                              size="small"
                            />
                          ))}
                          {item?.assignedClientTeam?.map((item) => (
                            <ColoredAvatars
                              key={item._id}
                              user={item}
                              size="small"
                            />
                          ))}
                        </AvatarGroup> */}
                      </td>
                      <td className="p-2 text-left text-xs">
                        {item.createdAt
                          ? format(new Date(item.createdAt), 'd MMM yy')
                          : '-'}
                      </td>
                      <td className="p-2 text-left text-xs">
                        {item.dueDate
                          ? format(new Date(item.dueDate), 'd MMM yy')
                          : '-'}
                      </td>
                      {!disabled && (
                        <td className="p-2">
                          <CloseIcon
                            size="small"
                            fontSize="small"
                            className={`show-on-hover-child `}
                            onClick={() => deleteTask(item._id)}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              (!disabled || taskConfiguration.workspaceSection?.label) && (
                <div className="h-48 w-full flex items-center justify-between gap-4 text-center bg-gray-50 px-6 my-4">
                  <p className="text-gray-500">No task available</p>
                  <div style={{ width: '200px', height: '150px' }}>
                    <img
                      src={NoAccess}
                      alt="No Access"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              )
            )}
          </div>
        ) : null}
      </section>

      <TaskStatusDropdown
        anchorEl={taskStatusAnchor}
        onClose={toggleTaskStatusDropdown}
        handleChangeTaskStatus={setSelectedStatus}
        options={sectionOptions}
      />
      {/* <DateDropdown
        anchorEl={dateAnchor}
        onClose={toggleDateDropdown}
        handleDateChange={handleChangeDate}
        options={datesOptions}
      /> */}
    </>
  )
}

export default StatusUpdateSection
