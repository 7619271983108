import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import {
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { ReactComponent as Icon } from 'static/svg/i.svg'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import { shareTask } from 'thunks/task/actions'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import workspaceStyles from './workspaceStyles'
// import {
//   CustomModal,
//   CustomModalHeader
// } from 'global/globalComponents/CustomModal'

const fieldsKeyValues = [
  { title: 'Assignee', keyName: 'assignedAgencyTeam' },
  { title: 'Priority', keyName: 'priority' },
  { title: 'Due Date', keyName: 'dueDate' },
  { title: 'Description', keyName: 'description' },
  { title: 'Comments', keyName: 'comments' },
  { title: 'Attachments', keyName: 'imageUrl' },
  { title: 'Sub Tasks', keyName: 'subTask' }
]

export default function ShareTaskModal({ task, handleClose }) {
  const classes = workspaceStyles()
  const dispatch = useDispatch()
  const [expand, setExpand] = useState(false)
  const [selectedFields, setSelectedFields] = useState(['title', 'status'])
  const [toggleSwitchState, setToggleSwitch] = useState(false)
  const [loading, setLoading] = useState({
    toggle: false,
    saveBtn: false
  })

  useEffect(() => {
    setToggleSwitch(task.data.sharing?.public ?? false)
    setSelectedFields(task.data?.sharing?.publicFields ?? [])
  }, [task.data])

  const shareTaskCallback = (res, err) => {
    setLoading((prev) => ({ ...prev, toggle: false }))
    if (err) setToggleSwitch((prev) => !prev)
    else {
      setToggleSwitch(res?.sharing?.public)
      dispatch(
        fireSuccessToaster(
          res?.sharing?.public
            ? 'Task shareable link generated'
            : 'Task sharing turned off'
        )
      )
    }
  }

  const handleExpandFieldOptions = () => {
    setExpand((prev) => !prev)
  }

  const handleSwitchstate = (e) => {
    const { checked } = e.target
    setLoading((prev) => ({ ...prev, toggle: true }))
    dispatch(
      shareTask(
        task.data._id,
        {
          sharing: {
            public: checked,
            publicFields: selectedFields
          }
        },
        shareTaskCallback
      )
    )
  }

  const handleSelectFields = (e) => {
    const { name, checked } = e.target
    if (checked) {
      setSelectedFields((prev) => [...prev, ...name.split(', ')])
    } else {
      setSelectedFields((prev) => prev.filter((i) => i !== name))
    }
  }

  const handleCheckEverything = (e) => {
    const { checked } = e.target
    if (checked) {
      const str = fieldsKeyValues.reduce(
        (acc, item) => acc + ', ' + item.keyName,
        ''
      )
      setSelectedFields(['title', 'status', ...str.slice(2).split(', ')])
    } else {
      setSelectedFields(['title', 'status'])
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.origin}/public/share/task/${task.data._id}`
    )
    dispatch(fireSuccessToaster('Link Copied'))
  }

  const handleSaveCallback = (res, err) => {
    // setLoading(((prev) ) =>  ({  ...prev, saveBtn:  false  }))
    setLoading((prev) => ({ ...prev, saveBtn: false }))
    if (err) {
      dispatch(fireErrorToaster('Unsuccessful'))
    } else {
      dispatch(fireSuccessToaster('Task sharing details updated'))
    }
  }

  const handleSaveChanges = () => {
    setLoading((prev) => ({ ...prev, saveBtn: true }))
    dispatch(
      shareTask(
        task.data._id,
        {
          sharing: {
            public: toggleSwitchState,
            publicFields: selectedFields
          }
        },
        handleSaveCallback
      )
    )
  }

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      open={task.open}
    >
      {/* <CustomModalHeader heading="Share Board" /> */}
      <DialogTitle>
        <span className="font-heading font-lg font-semibold">Share Task</span>
      </DialogTitle>
      <DialogContent>
        <div className="border rounded-md outline-none w-full mt-5 bg-custom-gray-light">
          <div className="border-b rounded-md outline-none bg-white py-2 px-4 flex justify-between items-center">
            <p className="flex-grow">Public sharing</p>
            <ToggleSwitch
              loading={loading.toggle}
              checked={toggleSwitchState}
              onChange={handleSwitchstate}
              // onChange={()=> setToggleSwitch((prev) => !prev)}
            />
          </div>

          <div className={clsx({ hidden: !toggleSwitchState })}>
            <div className="flex flex-row justify-between px-4 py-2">
              <div className="flex">
                <LinkIcon fontSize="small" />
                <p className="ml-2 text-sm font-medium">Public Link</p>
              </div>
              <p className="text-xs font-normal text-gray-600">
                only those with the link can access
              </p>
            </div>
            <div className="border mx-6 my-2 p-2 flex justify-between bg-white ">
              <p className="text-sm font-medium truncate">
                {window.origin}/public/share/task/{task.data._id}
              </p>
              <span
                role="button"
                className="text-sm font-medium text-primary-main cursor-pointer whitespace-nowrap"
                onClick={handleCopyLink}
              >
                Copy Link
              </span>
            </div>
            <div
              className={clsx('border-t p-4', {
                hidden: !expand
              })}
            >
              <p>What do you want to share?</p>

              <div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckEverything}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={
                          selectedFields.length === fieldsKeyValues.length + 2
                        }
                      />
                    }
                    label={
                      <span className="text-xs font-medium text-gray-700 ">
                        Everything
                      </span>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckEverything}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={true}
                        disabled={true}
                      />
                    }
                    label={
                      <span className="text-xs font-medium text-gray-700 ">
                        Task Name &amp; Status
                      </span>
                    }
                  />
                </div>
                {fieldsKeyValues.map((field, index) => (
                  <div key={field.keyName}>
                    <FormControlLabel
                      name={field.keyName}
                      control={
                        <Checkbox
                          checked={selectedFields.includes(field.keyName)}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          onChange={handleSelectFields}
                        />
                      }
                      label={
                        <span className="text-xs font-medium text-gray-700 ">
                          {field.title}
                        </span>
                      }
                    />
                  </div>
                ))}
                <div className="mt-2">
                  <LoadingButton
                    loading={loading.saveBtn}
                    variant="outlined"
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </LoadingButton>
                </div>
              </div>
            </div>
            <div
              className="flex m-auto justify-center mt-4 cursor-pointer items-center text-gray-900 mb-4"
              onClick={handleExpandFieldOptions}
            >
              <p className="text-sm font-medium">Show Advanced Options</p>
              <ExpandMoreIcon
                className={clsx(classes.icons, {
                  expanded: expand
                })}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-4 text-primary-main mb-4">
          <Icon
            height="12px"
            width="12px"
            style={{ alignSelf: 'center', fill: 'currentColor' }}
          />
          <p className="self-center ml-2 text-xs font-normal text-primary-main">
            This task will be shared as view-only
          </p>
        </div>
      </DialogContent>
    </Dialog>
  )
}
