import { Checkbox, FormControlLabel, Button, Select } from '@material-ui/core'
import {
  Add,
  KeyboardArrowDown,
  CheckBox,
  CheckBoxOutlineBlank
} from '@material-ui/icons'
import { useBreadcrumbs } from 'customHooks'
import { LoadingButton } from 'global/globalComponents'
import SectionHeading from 'global/globalComponents/SectionHeading'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { errorMessages, permissionObj, userRoles } from 'utils'
import settingStyles from '../settingStyles'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { PopupMenu } from 'global/globalComponents'
import {
  fetchUserPermissions,
  updateUserPermissions
} from 'thunks/userPermissions/action'
import {
  fireSuccessToaster,
  fireErrorToaster
} from 'thunks/fireToaster/actions'
import { filter } from 'lodash'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const UserPermissions = () => {
  const dispatch = useDispatch()
  // const { loading, data: userPermissions } = useSelector(
  //   (state) => state.userPermission
  // )
  const meData = useSelector(state => state.me.data)
  const [userPermissions, setUserPermissions] = useState({})
  const [loading, setLoading] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [newPermissions, setNewPermissions] = useState()
  const classes = settingStyles()
  const [selectedOption, setSelectedOption] = useState('workspace')
  const [permissionOptions, setPermissionOptions] = useState([])
  const [newPermissionData, setNewPermissionData] = useState({})

  // Fetching all notification events
  useEffect(() => {
    if (meData.role === userRoles.USER_AGENCY) {
      setLoading(true)
      dispatch(
        fetchUserPermissions(meData._id, (res, err) => {
          setLoading(false)
          if (!err) {
            setUserPermissions(res)
          } else {
            dispatch(fireErrorToaster(res))
          }
        })
      )
    }
  }, [])

  useEffect(() => {
    if (userPermissions?.access) setNewPermissionData(userPermissions.access)
  }, [userPermissions])

  useEffect(() => {
    if (userPermissions?.access) {
      setPermissionOptions(
        Object.keys(userPermissions?.access[userRoles.USER_AGENCY])
          .filter(el => el !== 'settings')
          .map(el => ({
            label: permissionObj[el]?.label ?? el.toLowerCase(),
            method: () => handlePermissionOptions(el)
          }))
      )
    }
  }, [userPermissions])

  useEffect(() => {
    const newObject = { create: [], update: [], view: [], delete: [] }
    Object.keys(newObject).forEach(access => {
      newObject[access] = Object.keys(newPermissionData).filter(
        el => newPermissionData[el][selectedOption][access]
      )
    })
    setNewPermissions(newObject)
  }, [newPermissionData, selectedOption])

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Permissions',
      link: `/settings/permissions`,
      index: 1
    }
  ])

  const handleCheckFields = (isChecked, data) => {
    if (newPermissionData[data.role]) {
      let newData = {
        ...newPermissionData[data.role],
        [selectedOption]: {
          ...newPermissionData[data.role][selectedOption],
          [data.access]: isChecked
        }
      }
      setNewPermissionData(prev => ({ ...prev, [data.role]: newData }))
    }
  }

  const handlePermissionOptions = option => {
    setSelectedOption(option)
  }

  const handleSave = () => {
    setUpdateLoading(true)
    dispatch(
      updateUserPermissions(
        { access: newPermissionData },
        meData._id,
        (res, err) => {
          setUpdateLoading(false)
          if (!err) {
            setUserPermissions(res)
            dispatch(fireSuccessToaster('Permissions Updated Successfully'))
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  return (
    <Wrapper>
      <div className="flex justify-between items-center mb-6 lg:mb-8">
        <SecondaryHeading className="mb-0">User Permissions</SecondaryHeading>
        <div>
          <PopupMenu
            menuItems={permissionOptions}
            targetComp={
              <Button variant="outlined" endIcon={<KeyboardArrowDown />}>
                Permissions Options
              </Button>
            }
          />
        </div>
      </div>
      <TableContainer shadow="none">
        <Thead>
          <Th className="text-left whitespace-nowrap">
            {permissionObj[selectedOption]?.label ?? selectedOption} Permissions
          </Th>
          <Th className="text-center whitespace-nowrap">Admin</Th>
          <Th className="text-center whitespace-nowrap">Manager</Th>
          <Th className="text-center whitespace-nowrap">Editor</Th>
          <Th className="text-center whitespace-nowrap">Viewer</Th>
          <Th className="text-center whitespace-nowrap">Client</Th>
        </Thead>
        {loading ? (
          <TableDataLoading cols={7} />
        ) : (
          <Tbody>
            {newPermissions &&
              Object.entries(newPermissions).map((item, index) => (
                <Row
                  key={index}
                  handleCheckFields={handleCheckFields}
                  access={item[0]}
                  permissions={item[1]}
                />
              ))}
          </Tbody>
        )}
      </TableContainer>
      <div className="text-right mt-12">
        <LoadingButton
          onClick={handleSave}
          className="w-56"
          loading={updateLoading}
        >
          Update Permissions
        </LoadingButton>
      </div>
    </Wrapper>
  )
}

const Row = ({ access, permissions, handleCheckFields }) => {
  return (
    <>
      <Tr className="">
        <Td className="text-left whitespace-nowrap text-primary-mid-dark font-medium capitalize">
          {access}
        </Td>
        {[
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_EDITOR,
          userRoles.AGENCY_VIEWER,
          userRoles.USER_CLIENT
        ].map((item, index) => (
          <Td key={index} className="text-center">
            <FormControlLabel
              onChange={e =>
                handleCheckFields(e.target.checked, {
                  access: access,
                  role: item
                })
              }
              checked={permissions?.includes(item.toString())}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                />
              }
            />
          </Td>
        ))}
      </Tr>
    </>
  )
}

export default UserPermissions
