import axios from 'axios'

const createWorkspace = async (token, data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/workspace',
      data: data,
      headers: {
        Authorization: token,
        'access-control-allow-origin': '*',
        'Content-type': 'application/json'
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback) callback('error', true)
  }
}

const addTeammate = async (token, data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/user/addTeammate',
      data: data,
      headers: {
        Authorization: token,
        'access-control-allow-origin': '*',
        'Content-type': 'application/json'
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback) callback('error', true)
  }
}

const addClient = async (token, data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/user/addClient',
      data,
      headers: {
        Authorization: token,
        'access-control-allow-origin': '*',
        'Content-type': 'application/json'
      }
    })
    if (callback) callback(res, false)
  } catch (err) {
    if (callback) callback('error', true)
  }
}

const createTask = async (token, data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/task',
      data: data,
      headers: {
        Authorization: token,
        'access-control-allow-origin': '*',
        'Content-type': 'application/json'
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback) callback('error', true)
  }
}

const createService = async (token, data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/service',
      data: {
        ...data,
        amount: data.amount * 100
      },
      headers: {
        Authorization: token,
        'access-control-allow-origin': '*',
        'Content-type': 'application/json'
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback) callback('error', true)
  }
}

const loadSampleData = async (token) => {
  // Create Internal Project

  await createWorkspace(token, projectSampleData[0], async (res, err) => {
    if (!err) {
      // Create Teammate

      await addTeammate(token, {
        ...teammateSampleData[0],
        newWorkspace: [res._id],
        email: `[sample]kelly${parseInt(Math.random() * 1000000)}@flowminer.com`
      })

      await addTeammate(token, {
        ...teammateSampleData[1],
        newWorkspace: [res._id],
        email: `[sample]prajukta${parseInt(
          Math.random() * 1000000
        )}@karavic.com`
      })

      await addTeammate(token, {
        ...teammateSampleData[2],
        newWorkspace: [res._id],
        email: `[sample]rajan${parseInt(Math.random() * 1000000)}@kuruapp.com`
      })

      // Create Tasks

      await createTask(token, {
        ...task1SampleData[0],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[0]._id
      })

      await createTask(token, {
        ...task1SampleData[1],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[1]._id
      })

      await createTask(token, {
        ...task1SampleData[2],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[2]._id
      })

      await createTask(token, {
        ...task1SampleData[3],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[3]._id
      })
    }
  })

  await createWorkspace(token, projectSampleData[1], async (res, err) => {
    if (!err) {
      // Create Client in second project

      await addClient(token, {
        ...clientSampleData[0],
        newWorkspace: [res._id],
        email: `[sample]david${parseInt(Math.random() * 1000000)}@gmail.com`
      })

      // Create task in second project

      await createTask(token, {
        ...task2SampleData[0],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[0]._id
      })

      await createTask(token, {
        ...task2SampleData[1],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[1]._id
      })

      await createTask(token, {
        ...task2SampleData[2],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[2]._id
      })

      await createTask(token, {
        ...task2SampleData[3],
        workSpace: res._id,
        workspaceBoard: res.defaultBoard._id,
        taskSection: res.defaultBoard.boardSection[3]._id
      })
    }
  })

  // Create Service

  // await createService(token, serviceSampleData[0])
  return { message: 'success' }
}

export default loadSampleData

const clientSampleData = [
  {
    name: 'David [Sample]',
    role: 30,
    email: '[sample]david@gmail.com',
    phone: '',
    sampleData: true,
    password: 'Test123@',
    address: {},
    agencyRepresentative: null,
    newWorkspace: [],
    canAddTeammates: true,
    path: '/client/dashboard',
    clientEmail: {
      subject: 'Invitation to join ',
      message:
        '<h3>Hey dfa,</h3><p>You have been invited to join our agency portal.</p><p>Thanks!</p><p>&nbsp;</p>'
    }
  }
]

const teammateSampleData = [
  {
    name: 'kelly [Sample]',
    email: '[sample]kelly37861@flowminer.com',
    isActive: true,
    role: 24,
    sampleData: true,
    newWorkspace: [],
    password: 'Test@123',
    isTeammate: true
  },
  {
    name: 'Prajukta [Sample]',
    email: '[sample]prajukta7029@karavic.com',
    isActive: true,
    role: 21,
    sampleData: true,
    password: 'Test@123',
    isTeammate: true,
    newWorkspace: []
  },
  {
    name: 'Rajan [Sample]',
    email: '[sample]rajan31397@kuruapp.com',
    isActive: true,
    role: 22,
    sampleData: true,
    password: 'Test@123',
    newWorkspace: [],
    isTeammate: true
  },

  {
    name: 'Shaun Rae [Sample]',
    email: 'shaun.r@gmail.com',
    isActive: true,
    role: 24,
    sampleData: true,
    password: 'Test@123',
    newWorkspace: [],
    isTeammate: true
  },
  {
    name: 'Ishan Kundra [Sample]',
    email: 'ishan.123@gmail.com',
    isActive: true,
    role: 21,
    password: 'Test@123',
    newWorkspace: [],
    isTeammate: true
  },
  {
    name: 'Monika Verma [Sample]',
    email: 'monika.v@gmail.com',
    isActive: true,
    role: 22,
    password: 'Test@123',
    newWorkspace: [],
    isTeammate: true
  }
]

const projectSampleData = [
  {
    name: `[Sample] Influencer Marketing`,
    description: '',
    category: 'Internal',
    image: 'Coins',
    backgroundcolor: '#ace60f'
  },
  {
    name: `[Sample] WebApp Development`,
    description: 'Stack - MERN',
    category: 'External',
    image: 'Check',
    backgroundcolor: '#ffffff'
  },
  {
    name: '[Sample] PPC',
    description: 'Manage PPC ads',
    category: 'External',
    image: 'Html',
    backgroundcolor: '#ffd100'
  },
  {
    name: '[Sample] Content Marketing',
    description: 'Content writing, SEO optimization, Keyword research.',
    category: 'External',
    image: 'Rocket',
    backgroundcolor: '#0064fb',
    assignedAgencyTeam: []
  }
]

const task1SampleData = [
  {
    title: 'Supreme Products - Campaign Outline',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    startDate: new Date(),
    orderIndex: '0',
    priority: 3
  },
  {
    title: 'Campaign Metrics Report & Review - Kelly',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '0',
    startDate: new Date(),
    priority: 2
  },
  {
    title: 'New Year Campaign with Leo',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '0',
    startDate: new Date(),
    priority: 2
  },
  {
    title: 'Jovees Brand - Campaign Launch',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '0',
    priority: 1,
    startDate: new Date(),

    markAsComplete: true,
    completedAt: new Date()
  },
  {
    title: 'April Week-2 Creatives for Shaun',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '1',
    startDate: new Date(),

    priority: 3
  },
  {
    title: 'Campaign Outline for Westin',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '1',
    startDate: new Date(),

    priority: 2
  },
  {
    title: 'Campaign',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '1',
    startDate: new Date(),

    priority: 2
  },
  {
    title: 'Reporting - Engagement Metrics for Myntra',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '1',
    priority: 1,
    markAsComplete: true,
    startDate: new Date(),

    completedAt: new Date()
  }
]

const task2SampleData = [
  {
    title: 'New Design Screens from Figma',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '0',
    startDate: new Date(),

    priority: 3
  },
  {
    title: 'Stripe payment integration',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '0',
    startDate: new Date(),

    priority: 2
  },
  {
    title: 'Redis Integration on Backend',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '0',
    startDate: new Date(),

    priority: 2
  },
  {
    title: 'Kubernetes Cluster Environment Set',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '0',
    priority: 1,
    startDate: new Date(),

    markAsComplete: true,
    completedAt: new Date()
  },
  {
    title: 'Automations Feature',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '1',
    startDate: new Date(),

    priority: 3
  },
  {
    title: 'Campaign Outline for Westin',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '1',
    startDate: new Date(),

    priority: 1
  },
  {
    title: 'Website Theme (Brand Colors)',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '1',
    startDate: new Date(),

    priority: 2
  },
  {
    title: 'Wireframing Activity',
    workSpace: '',
    workspaceBoard: '',
    taskSection: '',
    orderIndex: '2',
    priority: 3,
    startDate: new Date(),

    markAsComplete: true,
    completedAt: new Date()
  }
]

const serviceSampleData = [
  {
    name: '[Sample] Facebook Ads Services',
    description: 'Facebook ad campaigns',
    image: null,
    offerings: ['Ecomm Campaigns'],
    isRecurring: true,
    displayOnCatalog: true,
    isActive: false,
    amount: 10000,
    currency: 'inr',

    recurring: { interval: 'month', interval_count: 1, trial_period_days: 7 }
  },
  {
    name: '[Sample] Digital Marketing',
    description: 'Digitalize the world',
    image: null,
    offerings: [],
    isRecurring: false,
    displayOnCatalog: true,
    isActive: false,
    amount: 5000,
    currency: 'inr'
  }
]
