import React, { useCallback } from 'react'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
// import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
// import { IconButton } from '@material-ui/core'
// import UserDashboardStyles from '../userDashboardStyles'
import { unassignTagById } from 'thunks/tags/actions'
import { useDispatch } from 'react-redux'
// import { ReactComponent as TagsIcon } from 'static/svg/tags.svg'
// import { Avatar } from '@material-ui/core'
import { adjustColor } from 'utils'
import UserDashboardStyles from '../userDashboardStyles'
import clsx from 'clsx'
import Pill from 'global/globalComponents/Pill/Pill'

const AssignedTags = ({
  assignedTag,
  elmFor,
  taskId,
  workspace,
  tagIcon,
  setTagsPopoverData,
  className,
  exTags
}) => {
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()

  const unassignTag = useCallback(id => {
    let data
    if (elmFor === 'task') {
      data = {
        taskId: taskId
      }
    } else {
      data = {
        workspaceId: workspace._id
      }
    }
    dispatch(unassignTagById(data, id))
  }, [])

  return (
    <>
      <div
        className={`relative py-1 flex flex-wrap justify-start items-center gap-2 text-xs font-medium leading-3 ${
          className ? className : ''
        } `}
      >
        {assignedTag?.map(item => (
          <SingleTag
            key={item.name}
            {...item}
            unassignTag={unassignTag}
            classes={classes}
          />
          // <div>
          //   <span
          //     className={classes.assignTag}
          //     style={{
          // color: adjust(item.textColor, -30),
          // backgroundColor: item.backgroundColor
          //     }}
          //   >
          //     {item.name}
          //     {/* <CustomTooltip title="remove tag" placement="top"> */}
          //     <IconButton
          //       onClick={(e) => {
          //         e.stopPropagation()
          //         unassignTag(item._id)
          //       }}
          //       size="small"
          //       className={`cross-btn ${classes.crossBtn}`}
          //     >
          //       <CancelRoundedIcon fontSize="small" />
          //     </IconButton>
          //     {/* </CustomTooltip> */}
          //   </span>
          // </div>
        ))}
        {/* {exTags ? (
          <Avatar
            style={{
              textTransform: 'uppercase',
              backgroundColor: '#cbc9cf',
              width: '20px',
              height: '20px',
              fontSize: '10px',
              fontWeight: 'bold'
            }}
            onClick={(e) => {
              e.stopPropagation()
              setTagsPopoverData({ open: true, anchorEl: e.currentTarget })
            }}
          >
            +{exTags}
          </Avatar>
        ) : // )}
        tagIcon ? (
          <button
            // className={classes.outlinedIcon}
            onClick={(e) => {
              e.stopPropagation()
              setTagsPopoverData({ open: true, anchorEl: e.currentTarget })
            }}
            className="w-6 h-6 border border-dashed flex justify-center items-center text-base rounded-full text-gray-400"
          >
            <TagsIcon />
          </button>
        ) : null} */}
      </div>
    </>
  )
}

const SingleTag = React.memo(
  ({ _id, name, textColor, backgroundColor, unassignTag, classes }) => {
    const handleRemoveTag = e => {
      e.stopPropagation()
      unassignTag(_id)
    }

    return (
      <div className="relative show-on-hover-parent">
        <span
          style={{
            color: adjustColor(textColor, -30),
            backgroundColor: backgroundColor
          }}
          className={clsx(
            'inline-block px-2 font-medium rounded-full capitalize border border-current whitespace-nowrap',
            classes.tagStyles
          )}
        >
          {name}
        </span>
        <button
          onClick={handleRemoveTag}
          size="small"
          className={clsx(
            'absolute show-on-hover-child text-custom-red-main bg-white rounded-full',
            classes.removeAssigneeBtn
          )}
        >
          <CancelRoundedIcon style={{ width: '18px', height: '18px' }} />
        </button>
      </div>
    )
  }
)

export default AssignedTags
