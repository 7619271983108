import axios from 'axios'
import { errorMessages } from 'utils'
import { SUBTASKS } from './reducers'
import { postTaskLog } from 'thunks/logs/action'
import { createAccountLogs } from 'thunks/accountLogs/action'

/**
 * fetch all subtask of a task
 * @param {String} id  task_id
 * @param {Function} callback callback function
 */
export const getAllSubTask = (id, callback) => {
  return async (dispatch) => {
    dispatch({ type: SUBTASKS.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: `/subtask/task/${id}`
      })
      dispatch({ type: SUBTASKS.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: SUBTASKS.ERROR, payload: err.response })
      if (callback) callback(err.response, true)
    }
  }
}

/**
 * Delete a task
 * @param {String} task_id task id
 * @param {Function} callback callback function
 */
export const deleteSubTaskByID = (task_id, callback) => {
  return async (dispatch) => {
    // dispatch({type: })
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/subtask/${task_id}`
      })
      dispatch({ type: SUBTASKS.DELETE_ONE_TASK, payload: task_id })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({type: , payload:err.response.data.message})
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update Subtask  assigned team
 * @param {string} taskId subtask id
 * @param {Object} data  assigned teammate id
 * @param {Function} callback callback function
 */

export const updateSubTaskDetailsteam = (taskId, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/subtask/addteammate/${taskId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
      dispatch(
        postTaskLog({
          description: `assigned`,
          activityType: 'assignment',
          category: 'subtask',
          subTask: res.data._id,
          assignedUser: data.assignedAgencyTeam ?? data.assignedClientTeam,
          workSpace: res.data.workSpace
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: SUBTASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * update Subtask  assigned team
 * @param {string} taskId subtask id
 * @param {Object} data  assigned teammate id
 * @param {Function} callback callback function
 */

export const deleteSubtaskCollaborator = (taskId, data, callback) => async (
  dispatch
) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/subtask/deleteteammate/${taskId}`,
      data
    })
    if (callback) callback(res.data, false)
    dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
    dispatch(
      postTaskLog({
        description: `removed`,
        activityType: 'assignment',
        category: 'subtask',
        subTask: res.data._id,
        assignedUser: data.assignedAgencyTeam ?? data.assignedClientTeam,
        workSpace: res.data.workSpace
      })
    )
  } catch (err) {
    const message = err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
    if (callback) callback(message, true)
  }
}

/**
 * update Subtask  details
 * @param {String} subTaskId sub task id
 * @param {Object} data  user,workspace_id,subtask title,task id
 * @param {Function} callback callback function
 */

export const updateSubTaskDetails = (subTaskId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `subtask/${subTaskId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SUBTASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}
/**
 * Add a new Subtask
 * @param {Object} data  user,workspace_id,subtask title,task id
 * @param {Function} callback callback function
 */
export const createSubTask = (data, taskTitle, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/subtask',
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch(
        postTaskLog({
          description: `created a subtask <b>${
            res.data.title
          }</b> in the task <b>${taskTitle ? taskTitle : ''}</b> `,
          activityType: 'log',
          category: 'task',
          task: data.task,
          workSpace: res.data.workSpace
        })
      )
      dispatch({ type: SUBTASKS.ADD_ONE_TASK, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SUBTASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Update sub task Priority
 * @param {String} subtaskId subtask id
 * @param {object} data  sub task priority
 * @param {Function} callback callback function
 */
export const updateSubTaskPriority = (subtaskId, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/subtask/taskPriority/${subtaskId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: SUBTASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * upload  sub task  attachment
 * @param {Object} data workspace id,task id,file url
 * @param {Function} callback callback function
 */

export const uploadSubTaskAttachment = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/subtask/attachment`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: SUBTASKS.UPLOAD_SUBTASK_ATTACHMENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: SUBTASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Delete a task
 * @param {String} subTask_id task id
 * @param {String} url attachment url
 * @param {Function} callback callback function
 */
export const deleteSubtaskAttachment = (subTask_id, url, callback) => {
  return async (dispatch) => {
    // dispatch({type: })
    try {
      const res = await axios({
        method: 'DELETE',
        url: `subtask/delete/Attachment?subtask=${subTask_id}&url=${url}`
      })
      dispatch({ type: SUBTASKS.UPDATE_ONE_TASK, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({type: , payload:err.response.data.message})
      if (callback) callback(err?.response?.data.message, true)
    }
  }
}
