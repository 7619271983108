import { TextField } from '@material-ui/core'
import React from 'react'

const InvoiceOtherInfo = ({
  updateTaxDetail,
  taxDetails,
  notes,
  handleFieldChange
}) => {
  return (
    <div>
      <div className="mb-6 w-1/2">
        <label className="text-gray-400 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
          Tax Info
        </label>
        <TextField
          value={taxDetails[0]}
          onChange={updateTaxDetail}
          className="w-full"
          name="clientAddress"
          multiline={true}
        />
      </div>
      <div className="mb-6">
        <label className="text-gray-400 uppercase tracking-wider text-xs font-medium mb-1 inline-block">
          Notes
        </label>
        <TextField
          value={notes}
          onChange={handleFieldChange}
          placeholder="Thank you for your business"
          className="w-full"
          name="notes"
          multiline={true}
          rows={3}
        />
      </div>
    </div>
  )
}

export default InvoiceOtherInfo
