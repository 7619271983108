import React, { useMemo } from 'react'
import { LinearProgress } from '@material-ui/core'
import { Card } from 'global/globalComponents'

const ProjectCompletedStatus = ({ totalTasks, completedTasks }) => {
  const completion = useMemo(
    () => ((completedTasks * 100) / totalTasks).toFixed(2),
    []
  )

  return (
    <Card className="p-4 h-full">
      <div className="flex justify-between items-center mb-4">
        <h4 className="capitalize text-sm font-semibold text-custom-gray-main">
          Project Completion
        </h4>
        <span className="text-xs text-gray-700">
          {completedTasks}/{totalTasks} tasks
        </span>
      </div>

      {totalTasks > 0 ? (
        <div className="flex items-center space-x-4">
          <span className="text-md text-gray-700 ">{completion}%</span>
          <LinearProgress
            variant="determinate"
            value={completion}
            style={{ height: '20px', width: '100%', borderRadius: '3px' }}
          />
        </div>
      ) : (
        <div className="text-sm text-gray-500">
          <span>View project completion percentage here.</span>
          {/* <span className="text-primary-main border-collapse outline-none">
            create new task.
          </span> */}
        </div>
      )}
    </Card>
  )
}

export default ProjectCompletedStatus
