import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { agencyUserLogin } from 'thunks/login/actions'
import useTitle from 'customHooks/useTitle'
import {
  LoadingButton,
  RoundedTextInput,
  PatternScreen
} from 'global/globalComponents'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import clientVenueAssets from 'static/clientVenueAssets'
import PoweredBy from 'components/PoweredBy/PoweredBy'
import globalCustomStyles from 'global/stlyeClasses/GlobalCustomStyles'
// import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
// import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'

const masterUrls = [
  'app.clientvenue.com/',
  // 'staging.clientvenue.com',
  'localhost:3000'
]

const LoginCopy = props => {
  const classes = globalCustomStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  // const { loading, error } = useSelector((state) => state.login)
  const [loading, setLoading] = useState(false)
  // const [showPassword, setShowPassword] = useState(false)

  const [isWhitelabelActive, setWhitelabelActive] = useState(true)
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })

  useTitle(agencyInfo.brandName, agencyInfo.favicon)

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    agencyDomain: window.location.href
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split('/')[0]
      .replace('/', '')
  })

  useEffect(() => {
    if (window.location.href.split('//')[1].startsWith('app.clientvenue.com')) {
      history.push('/login/domain')
    }
    fetchAgencyInfo()
  }, [])

  /**
   * For Development purpose only
   */
  useEffect(() => {
    if (
      process.env.NODE_ENV === 'development' ||
      masterUrls.includes(window.location.host)
    ) {
      setFormData(oldData => ({
        ...oldData,
        agencyDomain: 'coco.clientvenue.com'
      }))
    }
  }, [])

  const loginHandler = e => {
    e.preventDefault()
    setLoading(true)
    dispatch(agencyUserLogin(formData, postLoginAction))
  }

  const formInputHandler = e => {
    setFormData(data => ({ ...data, [e.target.name]: e.target.value }))
  }

  const postLoginAction = (res, err) => {
    if (!err) {
      history.push(res.path)
      dispatch(fireSuccessToaster('Login Successful'))
    } else {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    }
  }

  const fetchAgencyInfo = async () => {
    try {
      const res = await getAgencyInfoByDomain()
      setWhitelabelActive(res?.data?.isWhiteLabelActive)
      setAgencyInfo(
        res?.data?.brand?.smallLogo ||
          res?.data?.brand?.largeLogo ||
          res?.data?.brand?.favicon
          ? res?.data.brand
          : clientVenueAssets
      )
    } catch (err) {
      setAgencyInfo(clientVenueAssets)
    }
  }

  // const togglePasswordView = () => {
  //   setShowPassword((prev) => !prev)
  // }

  return (
    <PatternScreen>
      <div>
        <header className="mb-8">
          <h1 className="text-4xl font-bold">
            Welcome back <br />
            to <span className="text-primary-main">{agencyInfo.brandName}</span>
          </h1>
        </header>

        <div>
          <form onSubmit={loginHandler}>
            <div className="mb-6">
              <FormLabel htmlFor="email">Email Address</FormLabel>
              <RoundedTextInput
                type="email"
                id="email"
                placeholder="Enter your business email address"
                name="email"
                onChange={formInputHandler}
                value={formData.email}
                className={classes.inputField}
              />
            </div>

            <div className="mb-6">
              <FormLabel htmlFor="password">Password</FormLabel>
              <RoundedTextInput
                // type={showPassword ? 'text' : 'password'}
                type="password"
                placeholder="Enter your password"
                id="password"
                name="password"
                onChange={formInputHandler}
                required
                value={formData.password}
                className={classes.inputField}
                // style={{ backgroundColor: 'white' }}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton size="small" onClick={togglePasswordView}>
                //       {!showPassword ? (
                //         <EyeIcon className="text-base" />
                //       ) : (
                //         <EyeSlashIcon className="text-base" />
                //       )}
                //     </IconButton>
                //   </InputAdornment>
                // }
              />
            </div>

            {process.env.NODE_ENV === 'development' && (
              <>
                <FormLabel htmlFor="password">
                  Agency Domain (For Development only)
                </FormLabel>
                <RoundedTextInput
                  type="text"
                  placeholder="Agency Domain"
                  name="agencyDomain"
                  onChange={formInputHandler}
                  required
                  value={formData.agencyDomain}
                  className={classes.inputField}
                />
              </>
            )}

            <div className="mt-3 flex justify-between items-center">
              <p className="text-xs font-medium">
                Forgot password?{' '}
                <Link
                  to="/forgot-password"
                  className="text-primary-main font-bold"
                >
                  Click here
                </Link>
              </p>

              {/* <span style={{ alignSelf: 'baseline' }}>
                  {window.location.href
                    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
                    .split('/')[0]
                    .replace('/', '') == 'app.clientvenue.com' ? (
                    <Typography
                      className={clsx(classes.para)}
                      style={{
                        textDecoration: 'none',
                        color: '#64bdff',
                        letterSpacing: '0px',
                        fontSize: '13px',
                        fontWeight: '400'
                      }}
                      component={Link}
                      to={'/signup'}
                    >
                      Create New Account
                    </Typography>
                  ) : (
                    <Typography
                      className={
                        'text-primary-main no-underline tracking-normal font-normal mb-1'
                      }
                      style={{
                        fontSize: '13px'
                      }}
                      component={Link}
                      to={'/clientsignup'}
                    >
                      Client Signup
                    </Typography>
                  )}
                </span> */}
            </div>
            <div className="mt-8">
              <LoadingButton loading={loading} type="submit" className="w-full">
                Sign In
              </LoadingButton>
              <p className="text-xs mt-2 font-medium">
                Haven't signed up?{' '}
                <Link
                  to={
                    window.location.href.includes('app.clientvenue.com')
                      ? '/signup'
                      : '/clientsignup'
                  }
                  className="text-primary-main font-bold"
                >
                  Register here
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      {/* <div>{!isWhitelabelActive && <PoweredBy marginTop="2rem" />}</div> */}
    </PatternScreen>
  )
}

const FormLabel = ({ children, ...rest }) => {
  return (
    <label
      className="inline-block text-xs md:text-smaller lg:text-sm font-medium tracking-wide mb-2"
      {...rest}
    >
      {children}
    </label>
  )
}

export default LoginCopy
