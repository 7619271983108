import clsx from 'clsx'
import Pill from 'global/globalComponents/Pill/Pill'

const staticCols = [
  {
    Header: 'Name',
    label: 'Name',
    accessor: 'Name',
    align: 'left',
    Cell: ({ value }) => (
      <div className="px-6 py-4">
        <div className="whitespace-nowrap">{value}</div>
      </div>
    ),
    sortType: (rowA, rowB, id, desc) => {
      let val1 = rowA.values[id].toLowerCase(),
        val2 = rowB.values[id].toLowerCase()

      if (val1 > val2) return 1
      else if (val2 > val1) return -1
      return 0
    }
  },
  {
    Header: 'Role',
    label: 'Role',
    accessor: 'Role',
    align: 'left',
    Cell: ({ value }) => (
      <div className="px-6 py-4">
        {value.map((item, index) => (
          <Pill key={index} variant="purple">
            {item}
          </Pill>
        ))}
        {/* <div>{value}</div> */}
      </div>
    )
  },
  {
    Header: () => <span className="text-center">Total tasks</span>,
    label: 'Total tasks',
    accessor: 'Total tasks',
    align: 'center',
    Cell: ({ value }) => (
      <div
        className={clsx(
          'text-center px-6 py-4',
          value === 0
            ? 'bg-custom-red-light'
            : value > 75
            ? 'bg-custom-green-light'
            : 'bg-custom-blue-light'
        )}
      >
        {value}
      </div>
    )
  },
  // {
  //   Header: 'Last month tasks',
  //   accessor: 'lastMonthTasks',
  //   Cell: ({ value }) => <div className="px-6 py-4">{value}</div>
  // },
  {
    Header: () => (
      <span className="text-center">
        Total tasks <br /> completed
      </span>
    ),
    label: 'Total tasks completed', // using it in SelectColumnsPopup
    accessor: 'Total tasks completed',
    align: 'center',
    Cell: ({ value }) => (
      <div
        className={clsx(
          'text-center px-6 py-4',
          value === 0
            ? 'bg-custom-red-light'
            : value > 75
            ? 'bg-custom-green-light'
            : 'bg-custom-blue-light'
        )}
      >
        {value}
      </div>
    )
  },
  {
    Header: () => (
      <span className="text-center">
        Completion <br />
        <span
          className="inline-block text-xs font-medium mt-1 capitalize"
          style={{ fontSize: 10 }}
        >
          (%)
        </span>
      </span>
    ),
    label: 'Completion (%)',
    accessor: 'Completion',
    align: 'center',
    Cell: ({ value }) => (
      <div
        className={clsx(
          'text-center px-6 py-4',
          value === 0
            ? 'bg-custom-red-light'
            : value > 75
            ? 'bg-custom-green-light'
            : 'bg-custom-blue-light'
        )}
      >
        {value}%
      </div>
    )
  },
  {
    Header: () => (
      <span className="text-center">
        Time tracked <br />
        <span
          className="inline-block text-xs font-medium mt-1 capitalize"
          style={{ fontSize: 10 }}
        >
          (hours)
        </span>
      </span>
    ),
    label: 'Time tracked (hours)',
    accessor: 'Time tracked',
    align: 'center',
    Cell: ({ value }) => <div className="px-6 py-4 text-center">{value}</div>
  }
]

export default staticCols
