import { useState } from 'react'
// import InfoIcon from '@material-ui/icons/Info'
import {
  Tooltip,
  makeStyles,
  ClickAwayListener,
  SvgIcon
} from '@material-ui/core'
import { ReactComponent as QuestionCircleIcon } from 'static/svg/question-circle.svg'

function QuestionMarkTooltip(props) {
  const [open, setOpen] = useState(false)
  const {
    text,
    direction,
    toolTipstyles = '',
    // info = true,
    color = '#1600e5'
  } = props
  const classes = useStyles()
  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const handleTooltipClose = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={toolTipstyles}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          classes={{ tooltip: classes.customStyling }}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}
          placement={direction}
          arrow
        >
          <SvgIcon
            component={QuestionCircleIcon}
            style={{ color, fontSize: '1rem' }}
            onClick={handleTooltipOpen}
            className="cursor-pointer"
            viewBox="0 0 600 476.6"
          />
          {/* {info ? (
          ) : (
            <SvgIcon
              component={QuestionCircleIcon}
              style={{ color: '#1600e5', fontSize: '1rem' }}
              onClick={handleTooltipOpen}
              className="cursor-pointer"
              viewBox="0 0 600 476.6"
            />
          )} */}
          {/* <Button onClick={handleTooltipOpen}>Click</Button> */}
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(theme => ({
  customStyling: {
    maxWidth: 200,
    textTransform: 'capitalize'
  },
  iconbutton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

export default QuestionMarkTooltip
