import axios from 'axios'
import { fetchMeData } from 'thunks/me/actions'
import { errorMessages, getAuthToken } from 'utils'
import { INVOICES } from './reducer'
import { CLIENT } from 'thunks/addClient/reducers'
import { DASHBOARD } from 'thunks/userDashboard/reducer'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { postTaskLog } from 'thunks/logs/action'

/**
 * Fetch all invoices
 * @param {id} agencyOwnerId .
 * @param {Function} callback function.
 */
export const fetchCustomInvoices = (agencyOwnerId, callback) => {
  return async (dispatch) => {
    dispatch({ type: INVOICES.LOADING })

    try {
      const res = await axios({
        method: 'GET',
        url: '/custominvoice/user'
      })

      const sortedData = res.data.sort(
        (a, b) =>
          new Date(b.createdAt || b.created * 1000) -
          new Date(a.createdAt || a.created * 1000)
      )

      dispatch({ type: INVOICES.FETCHED, payload: sortedData })
      if (callback) callback(res.data)
    } catch (err) {
      dispatch({ type: INVOICES.ERROR })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchSingleClientInvoices = async (clientId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `custominvoice/client/${clientId}`
    })

    const sortedData = res.data.sort(
      (a, b) =>
        new Date(b.createdAt || b.created * 1000) -
        new Date(a.createdAt || a.created * 1000)
    )

    if (callback) callback(sortedData)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Generate invoice
 * @param {object} data
 * @param {Function} callback callback function
 */
export const generateInvoice = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        // url: `/agencypayment/invoice`,
        url: '/custominvoice',
        data
      })

      dispatch({ type: INVOICES.ADD, payload: res.data })
      if (callback) callback(res.data, false)

      dispatch(
        createAccountLogs({
          description: `created invoice for client <strong>${
            res?.data?.client?.clientName ?? ''
          }</strong>`,
          category: 'invoice',
          invoice: res.data._id,
          method: 'post'
        })
      )
      // if (res.data.status === 'paid') {
      //   dispatch({
      //     type: DASHBOARD.UPDATE,
      //     payload: {
      //       count: {
      //         amount: res.data.totalAmount,
      //         currency: res.data.currency
      //       },
      //       key: 'totalRevenue',
      //       status: res.data.status
      //     }
      //   })
      // }
      // dispatch({ type: CLIENT.ASSIGN_SERVICE_TO_CLIENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update invoice
 * @param {String} Id invoice id
 * @param {Object} Data
 * @param {Function} callback function
 */
export const updateCustomInvoice = (invoiceId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/customInvoice/${invoiceId}`,
        method: 'PUT',
        data
      })

      dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data })
      if (callback) callback(res.data)
      dispatch(
        createAccountLogs({
          description: `updated an invoice for client <strong>${
            res.data?.client?.clientName ?? ''
          }</strong> in the project <strong>${res.data.workspaceName}</strong>`,
          category: 'workspaceInvoice',
          invoice: res.data._id,
          method: 'put'
        })
      )
      dispatch(
        postTaskLog({
          description: `updated invoice for client`,
          activityType: 'assignment',
          workSpace: data.workspace,
          assignedUser: data?.client.clientId,
          category: 'workspaceInvoice'
        })
      )
    } catch (err) {
      console.log(err, 'error++++++++++++++++=')
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Delete invoice
 * @param {String} Id invoice id
 * @param {Function} callback function
 */
export const deleteCustomInvoice = (invoiceId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/customInvoice/${invoiceId}`,
        method: 'DELETE'
      })

      dispatch({ type: INVOICES.DELETE_ONE_INVOICE, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Mark Invoice as Paid
 * @param {String} id invoice id
 * @param {Function} callback function
 */
export const markInvoiceAsPaid = (invoiceId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/custominvoice/markAsPaid/${invoiceId}`
      })

      dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data })
      // if (res.data.status === 'paid') {
      //   dispatch({
      //     type: DASHBOARD.UPDATE,
      //     payload: {
      //       count: {
      //         amount: res.data.amount,
      //         currency: res.data.currency
      //       },
      //       key: 'totalRevenue'
      //     }
      //   })
      // }
      if (callback) callback(res.data)
    } catch (err) {
      console.log(err, 'errorrrrrrrrrrrr')
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Save banking details
 * @param {Object} data object.
 * @param {Function} callback function.
 */
export const saveBankingDetails = async (data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: '/agencyprofile/agency/bankdetail',
      data
    })

    callback(res.data)
  } catch (err) {
    if (!axios.isCancel(err)) {
      callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true)
    }
  }
}

/**
 * Save tax details
 * @param {Object} data object.
 * @param {Function} callback function.
 */
export const saveTaxDetails = async (data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: '/agencyprofile/agency/taxdetail',
      data
    })

    callback(res.data, false)
  } catch (err) {
    if (!axios.isCancel(err)) {
      callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true)
    }
  }
}

/**
 * Save tax details
 * @param {id} customerId .
 * @param {Function} callback function.
 */
export const fetchCustomerData = (customerId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/clientpayment/check/stripecustomer/${customerId}`
      })
      callback(res.data, false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        callback(
          err?.response?.data.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

// /**
//  * Save tax details
//  * @param {id} agencyOwnerId .
//  * @param {Function} callback function.
//  */
// export const fetchClientInvoiceForAgency = (agencyOwnerId, callback) => {
//   return async (dispatch) => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/clientinvoice/agency/${agencyOwnerId}`
//       })
//       callback(res.data, false)
//     } catch (err) {
//       if (!axios.isCancel(err)) {
//         callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true
//         )
//       }
//     }
//   }
// }

/**
 * Save tax details
 * @param {Function} callback function.
 */
export const fetchClientInvoiceForClient = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/clientinvoice/user`
      })
      callback(res.data, false)
    } catch (err) {
      callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true)
    }
  }
}

/**
 * Save tax details
 * @param {id} stripeInvoiceId
 * @param {Function} callback function.
 */
export const deleteStripeInvoiceById = (stripeInvoiceId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/clientinvoice/draft/${stripeInvoiceId}`
      })
      callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `deleted a invoice of client <strong>${res?.data?.user.name}</strong>`,
          category: 'invoice',
          invoice: res.data._id,
          method: 'delete'
        })
      )
      dispatch({ type: INVOICES.DELETE_ONE_INVOICE, payload: res.data })
      dispatch({
        type: CLIENT.DELETE_DRAFT_SERVICE_OF_CLIENT,
        payload: res.data
      })
    } catch (err) {
      callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true)
    }
  }
}

/**
 * Change Invoice Status
 * @param {id} InvoiceId
 * @param {Function} callback function.
 */
export const changeInvoiceStatusById = (data, InvoiceId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/clientinvoice/status/${InvoiceId}`,
        data
      })
      callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `marked the invoice ${data.status} named to client <strong>${res?.data?.user.name}</strong>`,
          category: 'invoice',
          invoice: res.data._id,
          method: 'put'
        })
      )
      dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: res.data })
      // if (res.data.status === 'paid') {
      //   dispatch({
      //     type: DASHBOARD.UPDATE,
      //     payload: {
      //       count: {
      //         amount: res.data.amount,
      //         currency: res.data.currency
      //       },
      //       key: 'totalRevenue',
      //       status: res.data.status
      //     }
      //   })
      // }
      dispatch({
        type: CLIENT.UPDATE_CURRENT_CLIENT_PAYMENT_STATUS,
        payload: res.data
      })
    } catch (err) {
      callback(err?.response?.data.message ?? errorMessages.ERROR_MESSAGE, true)
    }
  }
}

/**
 * Fetch invoice analytics
 * @param {Function} callback
 */
export const fetchInvoiceAnalytics = async (callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/custominvoice/dashboard/analytics/invoice'
    })

    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}
