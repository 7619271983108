import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Card,
  Paper,
  AppBar,
  Grid,
  Toolbar
} from '@material-ui/core'
import { forgotPassword, forgotDomain } from 'thunks/passwordAndDomain/actions'
import { LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import useTitle from 'customHooks/useTitle'
import clientVenueAssets from 'static/clientVenueAssets'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import globalCustomStyles from 'global/stlyeClasses/GlobalCustomStyles'
import { PatternScreen, RoundedTextInput } from 'global/globalComponents'

const ForgotModal = ({ name, title, buttonText, link }) => {
  const classes = globalCustomStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [domainRes, setDomainRes] = useState('')
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })

  useTitle(agencyInfo.brandName, agencyInfo.favicon)

  useEffect(() => {
    fetchAgencyInfo()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    if (email.trim() === '')
      return dispatch(fireErrorToaster('Please enter your Email address!'))
    if (!/\S+@\S+\.\S+/.test(email))
      return dispatch(fireErrorToaster('Invalid email address!'))

    setLoading(true)

    if (name === 'domain') forgotDomain(email, domainCallback)
    else if (name === 'password') forgotPassword(email, passwordCallback)
  }

  const domainCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setLinkSent(true)
      setDomainRes(res.agencyDomain)
    }
  }

  const passwordCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(res.message))
      setLinkSent(true)
    }
  }

  const handleEmailInput = (e) => {
    setEmail(e.target.value)
  }

  const fetchAgencyInfo = async () => {
    try {
      const res = await getAgencyInfoByDomain()
      setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
    } catch (err) {
      setAgencyInfo(clientVenueAssets)
    }
  }

  return (
    <>
      <PatternScreen>
        <div>
          <header className="mb-8">
            {/* <Link to="/login">
              <img
                src={clientVenueAssets.largeLogo}
                alt="logo"
                target="_self"
                style={{ height: '2.8rem', margin: 'auto' }}
              />
            </Link> */}

            <h1 className="text-4xl font-bold">
              Forgot your <br />
              <span className="text-primary-main capitalize">{name}?</span>
            </h1>
          </header>

          <div>
            <form onSubmit={handleSubmit}>
              {linkSent ? (
                <div>
                  {title ? (
                    <Typography>{title}</Typography>
                  ) : (
                    <div className="mb-4">
                      <h3 className="font-bold">Your Domain</h3>
                      <a
                        href={`https://${domainRes}`}
                        style={{ color: 'blue', display: 'block' }}
                      >
                        {domainRes}
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <label
                    htmlFor="email"
                    className="inline-block text-sm font-medium tracking-wide mb-2"
                  >
                    Enter your registered Email id below.
                  </label>
                  <RoundedTextInput
                    id="email"
                    placeholder="Enter Email"
                    type="text"
                    name="domain"
                    onChange={handleEmailInput}
                    value={email}
                  />
                  <div className="mt-8">
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      className="w-full"
                    >
                      {buttonText}
                    </LoadingButton>
                  </div>
                </>
              )}
            </form>

            {!domainRes ? (
              <p className="text-xs mt-2">
                Back to{' '}
                <Link to={link} className="font-bold text-primary-main">
                  Login
                </Link>
              </p>
            ) : (
              <p
                className={classes.para}
                style={{
                  textDecoration: 'none',
                  letterSpacing: '0px',
                  fontSize: '13px',
                  fontWeight: '400'
                }}
              >
                <p className="text-xs">
                  Proceed to{' '}
                  <a
                    href={`https://${domainRes}`}
                    className="text-primary-main font-bold"
                  >
                    login
                  </a>
                </p>
              </p>
            )}
          </div>
        </div>
      </PatternScreen>
      {/* <Grid container direction="column" spacing={4}>
        <Grid
          item
          container
          style={{ justifyContent: 'center' }}
          direction={'column'}
          spacing={8}
        >
          <Grid item xs={12}>
            <Box className={classes.loginParent}>
              <Card className={classes.leftCard}>
                <Paper elevation={0} className={classes.formContainer}>
                  <div
                    className={classes.loginHeader}
                    style={{
                      flexDirection: 'row-reverse',
                      marginTop: 'auto'
                    }}
                  ></div>
                </Paper>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid> */}
    </>
  )
}

export default ForgotModal
