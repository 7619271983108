import { IconButton } from '@material-ui/core'
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import DownloadIcon from '@material-ui/icons/GetAppRounded'
import { handleDownloadAttachment } from 'utils'

const AssetPreview = ({ open, onClose, file = {} }) => {
  let preview

  if (file.extension === '.png' || file.extension === '.jpg') {
    preview = <img src={file.url} alt="" className="w-full h-full contain" />
  } else {
    preview = (
      <iframe
        src={file.url}
        title="I frame"
        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        className="w-full h-full"
      ></iframe>
    )
  }

  const handleDownload = () => {
    handleDownloadAttachment(file)
  }

  return (
    <CustomModal open={open} size="extraLarge" handleClose={onClose}>
      <CustomModalHeader heading={file.name} handleClose={onClose} />
      <CustomModalBody>
        <div style={{ height: '450px' }}>{preview}</div>
      </CustomModalBody>
      <CustomModalFooter>
        <IconButton onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default AssetPreview
