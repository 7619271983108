import { useContext, memo } from 'react'
import { Button } from '@material-ui/core'
import TemplateFormField from './TemplateFormField'
import { OnboardingContext } from '../onboardingReducer'
import { ONBOARDING } from '../onboardingReducer'
import SectionHeader from './SectionHeader'
import { FormLabel } from 'global/globalComponents'
// import onboardingStyles from '../onboardingStyles'

const Document = (props) => {
  const { id: sectionId, sectionHeading, description } = props
  const fields = props.fields[0]

  return (
    <div>
      <SectionHeader
        sectionHeading={sectionHeading}
        description={description}
        sectionId={sectionId}
      />
      <div>
        <DocumentsFieldsForm sectionId={sectionId} fields={fields} />
      </div>
    </div>
  )
}

const DocumentsFieldsForm = ({ sectionId, fields }) => {
  // const classes = onboardingStyles()
  const { onboardingDispatch } = useContext(OnboardingContext)

  const handleFieldChange = (e) => {
    // props: sectionId, fieldId, prop, value
    onboardingDispatch({
      type: ONBOARDING.UPDATE_FORM_FIELD,
      payload: {
        sectionId: sectionId,
        key: e.target.dataset.field,
        value: e.target.value
      }
    })
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0]

    if (file) {
      const imgReader = new FileReader()
      imgReader.onloadend = function () {
        onboardingDispatch({
          type: ONBOARDING.UPDATE_FORM_FIELD,
          payload: {
            sectionId: sectionId,
            key: 'document',
            value: imgReader.result
          }
        })
      }

      imgReader.readAsDataURL(file)
    }
  }

  return (
    <div className="py-4">
      <div className="border border-gray-300 rounded p-6">
        <div className="flex flex-col gap-4">
          <TemplateFormField
            label="Title"
            subLabel="Required"
            value={fields.title.value}
            error={fields.title.error}
            field="title"
            onChange={handleFieldChange}
            autoFocus
          />

          <TemplateFormField
            label="Description"
            subLabel="Required"
            value={fields.description.value}
            error={fields.description.error}
            field="description"
            onChange={handleFieldChange}
          />

          <div>
            <FormLabel required>Upload Document</FormLabel>
            <div className="flex gap-2 items-center">
              {fields.document.value && (
                <img
                  src={fields.document.value}
                  alt=""
                  style={{ width: '150px', height: '100px' }}
                  className="rounded-md"
                />
              )}
              <Button variant="outlined" component="label">
                Upload File
                <input type="file" hidden onChange={handleFileUpload} />
              </Button>
              {fields.document.error && (
                <span className="text-xs text-red-400">
                  This field is required!
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Document)
