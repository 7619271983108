import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { getParsedJwt, hideChatMessenger, userRoles } from 'utils'
import { userSignup } from 'thunks/signup'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import useTitle from 'customHooks/useTitle'
import { LoadingButton } from 'global/globalComponents'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import clientVenueAssets from 'static/clientVenueAssets'
import { InputAdornment, IconButton } from '@material-ui/core'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'
import globalCustomStyles from 'global/stlyeClasses/GlobalCustomStyles'
import { RoundedTextInput, PatternScreen } from 'global/globalComponents'
import clsx from 'clsx'
import clientSignupStyles from './ClientSignupStyles'

// const defaultImage = 'https://assets.clientvenue.com/other/clientvenue-logo.png'

const ClientSignupCopy = (props) => {
  const classes = clientSignupStyles()
  const dispatch = useDispatch()

  const inviteToken = props.match?.params?.inviteToken ?? false
  // const [domain, setDomain] = useState('')
  // const [disable, setDisable] = useState(true)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [passwordValid, setPasswordValid] = useState(true)
  // const [logoLoader, setLogoLoader] = useState(false)
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    website: '',
    role: userRoles.USER_CLIENT,
    phoneNumber: ''
  })

  useTitle(agencyInfo.brandName, agencyInfo.favicon)

  useEffect(() => {
    fetchAgencyInfo()
    hideChatMessenger()
  }, [])

  useEffect(() => {
    if (inviteToken) {
      let { iat, ...userInviteData } = getParsedJwt(inviteToken)
      setFormData((oldData) => ({
        ...oldData,
        ...userInviteData
      }))
    }
  }, [inviteToken])

  const userSignupHandler = (e) => {
    e.preventDefault()

    if (
      !formData.firstName.trim() ||
      !formData.lastName.trim() ||
      !formData.email.trim() ||
      !formData.password.trim() ||
      !formData.phoneNumber.trim()
    ) {
      return dispatch('Please fill all the required fields!')
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    setLoading(true)
    userSignup(
      {
        ...formData,
        name: formData.firstName + ' ' + formData.lastName,
        agencyDomain:
          process.env.NODE_ENV === 'development'
            ? 'roni.clientvenue.com'
            : window.location.host
        // .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
        // .split('/')[0]
        // .replace('/', '')
      },
      signupCallback
    )
  }

  // const handleDomain = (e) => {
  //   setDomain(e.target.value)
  // }

  const signupCallback = (res, err) => {
    setLoading(false)
    if (err) {
      // alert(res)
      dispatch(fireErrorToaster(res))
    } else {
      window.open(
        `${window.location.origin}${res.path}?signup=true&auth_token=${
          res.login.token.split(' ')[1]
        }`,
        '_self'
      )
    }
  }

  const fetchAgencyInfo = async () => {
    try {
      // setLogoLoader(true)
      const res = await getAgencyInfoByDomain()
      // setLogoLoader(false)
      setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
    } catch (err) {
      setAgencyInfo(clientVenueAssets)
      // setLogoLoader(false)
    }
  }

  const formInputHandler = (e) => {
    const { name, value } = e.target
    setFormData((oldState) => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const phoneNumberIputHandler = (valid, phone, country) => {
    setFormData((oldData) => ({
      ...oldData,
      phoneNumber: phone.length > 0 ? '+' + country.dialCode + ' ' + phone : ''
    }))
  }

  // const togglePasswordView = () => {
  //   setShowPassword((prev) => !prev)
  // }

  return (
    <>
      <PatternScreen>
        <div>
          <header className="mb-8">
            {/* <Link>
              <img
                src={
                  agencyInfo?.largeLogo?.length > 0
                    ? agencyInfo.largeLogo?.replace('s3.wasabisys.com/', '')
                    : defaultImage
                }
                alt="logo"
                target="_self"
                style={{
                  height: '2.8rem',
                  margin: 'auto'
                }}
              />
            </Link> */}

            <h1 className="text-4xl font-bold">
              Create An{' '}
              <span className="text-primary-main capitalize">Account</span>
            </h1>
          </header>

          <div>
            <form onSubmit={userSignupHandler}>
              <div className="flex gap-2 mb-4">
                <div className="w-1/2">
                  <Label htmlFor="firstName">First Name</Label>
                  <RoundedTextInput
                    onChange={formInputHandler}
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    id="firstName"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <Label htmlFor="lastName">Last Name</Label>
                  <RoundedTextInput
                    onChange={formInputHandler}
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    id="lastName"
                    required
                  />
                </div>
              </div>

              <div className="mb-4">
                <Label htmlFor="email">Email</Label>
                <RoundedTextInput
                  type="email"
                  placeholder="Email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={formInputHandler}
                  disabled={Boolean(inviteToken)}
                  required
                />
              </div>

              <div className="mb-4">
                <Label htmlFor="phoneNumber">Phone Number</Label>
                <IntlTelInput
                  onPhoneNumberChange={phoneNumberIputHandler}
                  name="phone"
                  value={formData.phoneNumber.split(' ')[1] || ''}
                  inputClassName={clsx(
                    classes.intelInput,
                    'rounded-full w-full py-3 rounded-full text-sm'
                  )}
                  containerClassName="intl-tel-input w-full"
                />
              </div>

              <div className="mb-4">
                <Label htmlFor="password">Password</Label>
                <RoundedTextInput
                  onChange={formInputHandler}
                  id="password"
                  name="password"
                  placeholder="Password (minimum 8 character)"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  // autoFocus={process.env.NODE_ENV === 'development'}
                  //className={!errorEmail ? classes.signinInput : classes.errorSigninInput}
                  required
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton size="small" onClick={togglePasswordView}>
                  //       {!showPassword ? (
                  //         <EyeIcon className="text-base" />
                  //       ) : (
                  //         <EyeSlashIcon className="text-base" />
                  //       )}
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                  onBlur={validPasswordHandler}
                />
                {formData.password && !passwordValid && (
                  <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                    Password should be a combination of an uppercase/lowercase
                    letter, a number, a symbol and length between 8 to 20.
                  </p>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              ></div>

              <div className="mt-4">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  className="w-full"
                  type="submit"
                  // disabled={
                  // !formData.firstName ||
                  // !formData.lastName ||
                  // !formData.email ||
                  // formData.password.length < 5 ||
                  // !formData.phoneNumber
                  // }
                >
                  Create Account
                </LoadingButton>
              </div>
            </form>

            <p className="text-xs font-medium mt-2">
              Already Registered?{' '}
              <Link
                to="/login"
                className="text-primary-main inline-block font-bold"
              >
                Login here
              </Link>
            </p>
          </div>
        </div>
      </PatternScreen>
    </>
  )
}

const Label = ({ children, ...rest }) => {
  return (
    <label
      className="inline-block text-sm font-medium tracking-wide mb-2"
      {...rest}
    >
      {children}
    </label>
  )
}

export default ClientSignupCopy
