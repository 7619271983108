import React, { useState, useEffect } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'
import General from './General/General'
import Security from './Security/Security'
import Subscription from './Subscription/Subscription'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { getUserPath } from 'utils/userRoles'
import { useSelector } from 'react-redux'
import Profile from './Profile/Profile'
import Notifications from './Notifications'
import Apikey from './ApiKey/ApiKey'
import Branding from 'components/Setup/Branding'
import Integrations from 'components/Setup/Integrations'
import UserPermissions from './Permissions/UserPermissions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as BuildingIcon } from 'static/svg/buildings.svg'
import { ReactComponent as KeyIcon } from 'static/svg/key.svg'
import { ReactComponent as ProfileIcon } from 'static/svg/profile.svg'
import { ReactComponent as SecurityIcon } from 'static/svg/security.svg'
import { ReactComponent as PlanIcon } from 'static/svg/plans.svg'
import { ReactComponent as PermissionIcon } from 'static/svg/permissions.svg'
import { ReactComponent as NotificationIcon } from 'static/svg/notification.svg'
import { ReactComponent as PeopleIcon } from 'static/svg/people.svg'
import { ReactComponent as ImportIcon } from 'static/svg/import-2.svg'
import { userRoles } from 'utils'
import ResourceManagement from './ResourceManagement/ResourceManagement'
import Import from './Import/Import'
import settingStyles from './settingStyles'

const SettingMain = props => {
  const classes = settingStyles()
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const meData = useSelector(state => state.me.data)
  const [selectedTab, setSelectedTab] = useState(pathname)
  const tabsData = [
    {
      label: 'Company',
      value: path + '/general',
      icon: <BuildingIcon />,
      allowedRoles: role =>
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER
        ].includes(role)
    },
    {
      label: 'Profile',
      value: path + '/profile',
      icon: <ProfileIcon />,
      allowedRoles: role => true
    },
    {
      label: 'Branding',
      value: path + '/branding',
      icon: <SecurityIcon />,
      allowedRoles: role =>
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER
        ].includes(role)
    },
    {
      label: 'Integration',
      value: path + '/integrations',
      icon: <SecurityIcon />,
      allowedRoles: role =>
        [
          userRoles.USER_AGENCY
          // userRoles.AGENCY_ADMIN,
          // userRoles.AGENCY_MANAGER
        ].includes(role)
    },
    {
      label: 'Security',
      value: path + '/security',
      icon: <SecurityIcon />,
      allowedRoles: role => true
    },
    {
      label: 'Plans',
      value: path + '/subscription',
      icon: <PlanIcon />,
      allowedRoles: role => [userRoles.USER_AGENCY].includes(role)
    },
    {
      label: 'Resource Management',
      value: path + '/resource-manage',
      icon: <PeopleIcon />,
      allowedRoles: role =>
        [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(role)
    },
    {
      label: 'Import',
      value: path + '/import',
      icon: <ImportIcon />,
      allowedRoles: role =>
        [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(role)
    },
    {
      label: 'Notification',
      value: path + '/notification',
      icon: <NotificationIcon />,
      allowedRoles: role => true
    },
    {
      label: 'Permission',
      value: path + '/permissions',
      icon: <PermissionIcon />,
      allowedRoles: role => [userRoles.USER_AGENCY].includes(role)
    }

    // {
    //   label: 'Api Key',
    //   value: path + '/apikey',
    //   icon: <KeyIcon />,
    //   allowedRoles: (role) => [userRoles.USER_AGENCY].includes(role)
    // }
  ]

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }

  return (
    <OuterContentWrapper>
      <SectionHeading className="mb-3 lg:mb-4">Settings</SectionHeading>
      <div className="mb-6 lg:mb-8">
        <Tabs
          classes={{ root: classes.tabsRoot }}
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabsData
            .filter(tab => tab.allowedRoles(meData.role))
            .map((item, index) => (
              <Tab
                label={
                  <div className="flex items-center gap-1">
                    <span>{item.icon}</span> <span>{item.label}</span>
                  </div>
                }
                key={index}
                value={item.value}
              />
            ))}
        </Tabs>
      </div>
      <Switch>
        <Route path={`${getUserPath(meData?.role)}/settings/general`}>
          <General />
        </Route>
        <Route
          path={`${getUserPath(meData?.role)}/settings/security`}
          component={Security}
        />
        <Route
          path={`${getUserPath(meData?.role)}/settings/profile`}
          component={Profile}
        />
        <Route
          path={`${getUserPath(meData?.role)}/settings/branding`}
          component={Branding}
        />
        <Route
          path={`${getUserPath(meData?.role)}/settings/integrations`}
          component={Integrations}
        />
        <Route
          path={`${getUserPath(meData?.role)}/settings/notification`}
          component={Notifications}
        />
        <Route
          path={`${getUserPath(meData?.role)}/settings/permissions`}
          component={UserPermissions}
        />
        {/* <Route
          path={`${getUserPath(meData?.role)}/settings/billing`}
          component={Billing}
        /> */}
        <Route
          path={`${getUserPath(meData?.role)}/settings/subscription`}
          component={Subscription}
        />
        <Route
          path={`${getUserPath(meData?.role)}/settings/resource-manage`}
          component={ResourceManagement}
        />
        {/* <Route
          path={`${getUserPath(meData?.role)}/settings/connected-apps`}
          component={ConnectedApps}
        /> */}
        {/* <Route
          path={`${getUserPath(meData?.role)}/settings/apikey`}
          component={Apikey}
        /> */}
        <Route
          path={`${getUserPath(meData?.role)}/settings/import`}
          component={Import}
        />
      </Switch>
    </OuterContentWrapper>
  )
}

export default SettingMain
