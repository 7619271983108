export const WEBHOOK = {
  FETCHING: 'FETCHING_WEBHOOK',
  FETCHED_CREATED: 'FETCHED_CREATED_WEBHOOk',
  CREATED: 'CREATED_WEBHOOK',
  FETCHED_ALL_EVENTS: 'FETCHED_WEBHOOK_ALL_EVENTS',
  FETCHED_ONE_WEBHOOK: 'FETCHED_ONE_WEBHOOK_DATA',
  UPDATE_ONE_WEBHOOK: 'UPDATE_ONE_WEBHOOK_DATA',
  DELETE_ONE_WEBHOOK: 'DELETE_ONE_WEBHOOK_DATA'
}

const initialState = {
  fetching: false,
  data: [],
  createdWebhook: [],
  error: false,
  allEvents: [],
  slackData: []
}

const webhookReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEBHOOK.CREATED:
      return {
        ...state,
        createdWebhook:
          action.payload.type !== 'slack'
            ? [...state.createdWebhook, action.payload]
            : state.createdWebhook,
        slackData:
          action.payload.type === 'slack'
            ? [...state.slackData, action.payload]
            : state.slackData
      }

    case WEBHOOK.FETCHED_ALL_EVENTS:
      return {
        ...state,
        allEvents: action.payload
      }

    case WEBHOOK.FETCHED_CREATED:
      return {
        ...state,
        createdWebhook: action.payload.filter((i) => i.type !== 'slack'),
        slackData: action.payload.filter((i) => i.type === 'slack')
      }

    case WEBHOOK.UPDATE_ONE_WEBHOOK:
      // const createdWebhookIndex = state.createdWebhook.findIndex(
      //   (i) => i._id === action.payload._id
      // )

      // const slackWebhookIndex = state.slackData.findIndex(
      //   (i) => i._id === action.payload._id
      // )

      // const updatedState = [...state.createdWebhook]
      // updatedState[updatingIndex] = action.payload

      return {
        ...state,
        createdWebhook: state.createdWebhook.map((item) => {
          if (item._id === action.payload._id) return action.payload
          return item
        }),
        slackData: state.slackData.map((item) => {
          if (item._id === action.payload._id) return action.payload
          return item
        })
        // slackData:
        //   action.payload._id === state.slackData._id
        //     ? action.payload
        //     : state.slackData
      }

    case WEBHOOK.DELETE_ONE_WEBHOOK:
      return {
        ...state,
        createdWebhook: state.createdWebhook.filter(
          (el) => el._id !== action.payload._id
        ),
        slackData: state.slackData.filter(
          (item) => item._id !== action.payload._id
        )
      }

    default:
      return state
  }
}

export default webhookReducer
