import React, { useCallback } from 'react'

const usePlanFunctions = () => {
  const getSeatBasedPlanAmount = useCallback(({ plan, seats }) => {
    let amount = 0,
      leftSeats = seats

    for (let i = 0; i < plan.tiers.length; i++) {
      if (leftSeats <= 0) break

      if (plan.tiers[i].up_to === null || seats <= plan.tiers[i].up_to) {
        amount += leftSeats * (plan.tiers[i].unit_amount / 100)
        break
      }

      leftSeats = seats - plan.tiers[i].up_to

      amount +=
        i === 0
          ? plan.tiers[i].up_to * (plan.tiers[i].unit_amount / 100)
          : (plan.tiers[i].up_to - plan.tiers[i - 1].up_to) *
            (plan.tiers[i].unit_amount / 100)
    }

    return amount
  }, [])

  return {
    getSeatBasedPlanAmount
  }
}

export default usePlanFunctions
