import React, { useEffect, useState } from 'react'
import {
  Box,
  // Grid,
  Typography,
  TextField,
  // RadioGroup,
  // FormControlLabel,
  // FormControl,
  // Radio,
  // Checkbox,
  Button
} from '@material-ui/core'
import {
  // CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { storeToken } from 'utils/userLogin'
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userRoles } from 'utils'
import { fetchAllServicesByDomain } from 'thunks/service/actions'
import { LoadingButton } from 'global/globalComponents'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import {
  fetchClientStripeChekoutSession,
  fetchClientStripeNonStripeChekoutSession
} from '../../../thunks/clientService/action'
import { userSignup } from 'thunks/signup'
import { DefaultServiceImage } from 'static/Images'
import { hideChatMessenger } from 'utils'
import useTitle from 'customHooks/useTitle'

let stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const Checkout1 = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const [disable, setDisable] = useState(false)
  const [dataObject, setDataObject] = useState({
    plans: props.plan.match.params.plan,
    user: ''
  })
  const me = useSelector((state) => state.me.data)
  const [loading, setLoading] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState({})
  const [domain, setDomain] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [tokenData, setTokenData] = useState({
    error: '',
    stripeToken: process.env.REACT_APP_STRIPE_KEY,
    name: '',
    email: '',
    loading: false
  })
  const userServices = useSelector((state) => state.userServices)
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    invoiceTo: '',
    taxCode: '',
    streetAddress: '',
    city: '',
    country: '',
    zipCode: ''
  })

  useTitle(agencyInfo?.brandName, agencyInfo?.favicon)

  useEffect(() => {
    dispatch(fetchAllServicesByDomain())
  }, [])

  useEffect(() => {
    setDomain(
      window.location.href
        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
        .split('/')[0]
        .replace('/', '')
    )
  })

  useEffect(() => {
    setSelectedPlan(() =>
      userServices?.data.find(
        (el) => (el.planId ?? el._id) === dataObject.plans
      )
    )
  }, [userServices, dataObject])

  useEffect(() => {
    hideChatMessenger()
  })

  const handleFormChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const selectNonStripePlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster('Unable to select files'))
    } else {
      window.open(res.path, '_self')
    }
  }

  const selectPlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(err))
    } else {
      window.open(res.url, '_self')
      // const stripe = await stripePromise
      // const result = await stripe.redirectToCheckout({
      //   sessionId: res.id
      // })
    }
  }

  const selectPlanHandler = async (res, plan) => {
    setLoading(true)
    let domain = window.location.href
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split('/')[0]
      .replace('/', '')

    if (plan.serviceType === 'stripe') {
      const stripeSessionInfo = await fetchClientStripeChekoutSession(
        {
          user: res._id,
          plan: selectedPlan,
          url: {
            success_url: `https://${domain}/select-service?success=true`,
            cancel_url: `https://${domain}/select-service?success=false`
          }
        },
        selectPlanHandlerCallback
      )
    } else {
      const stripeSessionInfo = await fetchClientStripeNonStripeChekoutSession(
        {
          plan: selectedPlan
        },
        selectNonStripePlanHandlerCallback
      )
    }
  }

  // console.log(userServices.data, userServices.data.find(el => el.planId === dataObject.plans)
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!formData.name || !formData.email || !formData.password) {
      return dispatch(fireErrorToaster('please fill all the fields'))
    }
    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return dispatch(fireErrorToaster('Please enter a strong password'))
    }
    setLoading(true)
    userSignup(
      {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        agencyDomain: window.location.href
          .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
          .split('/')[0]
          .replace('/', ''),
        role: userRoles.USER_CLIENT
      },
      signupCallback
    )
  }

  const signupCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      storeToken(res.login.token)
      selectPlanHandler(res, selectedPlan)
    }
  }

  const fetchAgencyInfo = async () => {
    try {
      const res = await getAgencyInfoByDomain()
      // setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
      setAgencyInfo(res?.data?.brand?.smallLogo ? res?.data.brand : agencyInfo)
    } catch (err) {
      setAgencyInfo(agencyInfo)
    }
  }

  useEffect(() => {
    fetchAgencyInfo()
  }, [])

  // let token = await stripe.createToken(elements.getElement(CardElement))

  // 	if(token.error){
  //    // console.log(token.error.message,"@@@@@@@@@@@@@@")
  // 		setTokenData((prev)=>({...prev,error: token.error.message, loading:false}))
  // 		return
  // 	}

  // const {error, paymentMethod} = await stripe.createPaymentMethod({
  //   type: 'card',
  //   card: elements.getElement(CardElement),
  //   billing_details: {name: formData.name},
  // });
  // 	// console.log(error,"***************")
  //    console.log(paymentMethod,"&&&&&&&&&&&&&&&&&&&")

  // if(paymentMethod){
  //   const res =  await axios({
  //     url:'/clientpayment',
  // 		method: 'POST',
  // 		headers: {
  // 			'Content-Type': 'application/json',
  //       "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiamtqIiwicm9sZSI6MywiZGVmYXVsdFRpbWVab25lIjoiVVMtRWFzdCIsInByb2ZpbGVJbWFnZSI6IiIsImlzQWN0aXZlIjp0cnVlLCJwYXRoIjoiL3NlbGVjdC1zZXJ2aWNlIiwiY3JlYXRlZEF0IjoiMjAyMS0wMy0yNVQwNjoyMjo1MS4zODFaIiwidXBkYXRlZEF0IjoiMjAyMS0wMy0yNVQwNjoyMjo1MS4zODFaIiwiX2lkIjoiNjA1YzNhOTg5NTI5YjYwMDE1MTIxMTk3IiwiZW1haWwiOiJraUBnbWFpbC5jb20iLCJhZ2VuY3lEb21haW4iOiJhZ2VuY3kxLmNsaWVudHZlbnVlLmNvbSIsInVzZXJuYW1lIjoia2kiLCJfX3YiOjAsImlhdCI6MTYxNjY1NzA0OSwiZXhwIjoxNjE2NjkzMDQ5fQ.tkyvlbqIUEoF7Ljt_4SRdd4laOuyB2XPDuhT5c2l2HU"
  // 		},
  // 		data:{
  // 			user:dataObject.user,
  // 			plan:{id:dataObject.plans},
  // 			paymentMethod:paymentMethod,
  // 			//token:token.token,
  // 		}
  // 	})
  //  if(res){
  //     console.log(res,"oooooooooooooooooooo")
  // history.push('')
  // window.location.reload();
  //   }
  // }

  // .then(()=>{
  // 		this.setState({loading:false, error:'Subscription created successfully.'})
  // 		this.callDashly()
  // 		this.props.
  // }).catch(() =>{
  // 	this.setState({error:'Internal Server Error', loading:false})
  // })

  // useEffect(() => {
  //   console.log(dataObject, '/////////////////////')
  // }, [dataObject])

  return (
    <Box className="h-full">
      <Box className="max-w-7xl mx-auto px-4 pb-8 sm:px-6 md:px-8">
        <Box className="flex align-center py-8 justify-center">
          <img
            className="h-12 w-auto"
            src={agencyInfo.largeLogo?.replace('s3.wasabisys.com/', '')}
            alt=""
          />
        </Box>

        <Box className="bg-white border border-gray-300 overflow-hidden sm:rounded-lg">
          <Box className="px-4 py-5 sm:p-6 md:grid grid-cols-1 md:grid-cols-2 flex flex-col-reverse">
            <Box className="overflow-hidden p-3 sm:col-span-1 mb-6">
              <Box className=" mb-6 hidden md:flex">
                <Box className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-200 text-white">
                  <img
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'object-cover'
                    }}
                    src={(selectedPlan?.image ?? DefaultServiceImage)?.replace(
                      's3.wasabisys.com/',
                      ''
                    )}
                    alt=""
                  />
                </Box>
                <Box className="ml-4">
                  <h4 className="text-lg leading-6 font-medium capitalize text-gray-900">
                    {selectedPlan?.name}
                  </h4>
                  <Typography className="mt-2 text-base leading-6 text-gray-500 trix-content">
                    {selectedPlan?.description}
                  </Typography>
                </Box>
              </Box>

              <div className="border-t border-gray-200 pt-8">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Billing information
                </h3>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    className="block text-sm font-medium leading-5 text-gray-700"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <TextField
                    name="name"
                    onChange={handleFormChange}
                    className="w-full"
                    placeholder="Name"
                    id="name"
                    value={formData.name}
                  />
                </div>
                <div className="sm:col-span-6">
                  <label
                    className="block text-sm font-medium leading-5 text-gray-700"
                    htmlFor="userEmail"
                  >
                    Email
                  </label>
                  <TextField
                    name="email"
                    onChange={handleFormChange}
                    placeholder="Email"
                    className="w-full"
                    id="userEmail"
                    value={formData.email}
                  />
                  <p className="mt-2 text-sm text-gray-500 sm:col-span-6">
                    Already have an account?{' '}
                    <a
                      className="text-green-600	"
                      href={`https://${domain}/login`}
                    >
                      Login
                    </a>
                  </p>
                </div>
                <div className="sm:col-span-6">
                  <label
                    className="block text-sm font-medium leading-5 text-gray-700"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <TextField
                    name="password"
                    type="password"
                    id="password"
                    placeholder="Password"
                    className="w-full"
                    value={formData.password}
                    onChange={handleFormChange}
                    onBlur={validPasswordHandler}
                  />

                  {formData.password && !passwordValid && (
                    <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                      Password should be a combination of an uppercase/lowercase
                      letter, a number, a symbol and length between 8 to 20.
                    </p>
                  )}
                </div>
                <div className="hidden">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="invoice_to"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Invoice to:
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <TextField
                        disabled
                        className="w-full"
                        id="invoice_to"
                        value={formData.invoiceTo}
                        name="invoiceTo"
                        onChange={handleFormChange}
                        placeholder="Yours or your company name here"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="tax_code"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Tax Code: (optional)
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <TextField
                        disabled
                        className="w-full"
                        name="taxCode"
                        id="tax_code"
                        value={formData.taxCode}
                        onChange={handleFormChange}
                        placeholder="Tax code, VAT code or optional"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="street_address"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Street address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <TextField
                        disabled
                        className="w-full"
                        name="streetAddress"
                        id="street_address"
                        onChange={handleFormChange}
                        value={formData.streetAddress}
                        placeholder="Tax code, VAT code or optional"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      City/State
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <TextField
                        disabled
                        className="w-full"
                        name="city"
                        id="city"
                        value={formData.city}
                        onChange={handleFormChange}
                        placeholder="City"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Country
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <TextField
                        disabled
                        className="w-full"
                        name="country"
                        onChange={handleFormChange}
                        id="country"
                        value={formData.zipCode}
                        placeholder="Country"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="zip_code"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      ZIP Code
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <TextField
                        disabled
                        className="w-full"
                        name="zipCode"
                        onChange={handleFormChange}
                        id="zip_code"
                        value={formData.zipCode}
                        placeholder="ZIP Code"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="mt-8 border-t border-gray-200 pt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Subscription type
                  </h3>
                  <div className="mt-4">
                    <FormControl component="fieldset">
                      <RadioGroup name="subscription_interval">
                        <FormControlLabel
                          value="monthly"
                          control={<Radio />}
                          label="Monthyl"
                        />
                        <FormControlLabel
                          value="quarterly"
                          control={<Radio />}
                          label="Quarterly"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div> */}

              <div className="mt-12 pt-5">
                <p className="text-gray-500 mb-3 text-center">
                  By submitting this form you agree to our terms of service.
                  {/* <a href="#" className="text-green-500" target="_blank">
                    terms of service.
                  </a> */}
                </p>
                <LoadingButton
                  className="mt-8 w-full"
                  size="large"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Complete Checkout
                </LoadingButton>
                <Button
                  style={{ marginTop: 16 }}
                  className="mt-8 w-full"
                  size="large"
                  variant="outlined"
                  component={Link}
                  to="/services"
                >
                  Cancel
                </Button>
              </div>
            </Box>

            <div className="overflow-hidden p-3 sm:col-span-1">
              <div className="mb-6 sm:hidden block">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-200 text-white">
                      <img
                        className="h-full object-cover w-auto"
                        src={(
                          selectedPlan?.image ?? DefaultServiceImage
                        )?.replace('s3.wasabisys.com/', '')}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="ml-4 ">
                    <h4 className="text-lg leading-6 font-medium capitalize text-green-600">
                      {selectedPlan?.name}
                    </h4>
                    <div className="mt-2 text-base leading-6 text-gray-500 trix-content ">
                      <div>{selectedPlan?.description}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 overflow-hidden shadow rounded-lg">
                <div className="border-b border-gray-200 px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium  text-green-600">
                    Order summary
                  </h3>
                </div>
                <div className="px-4 py-5 sm:px-6">
                  <div className="mt-2 min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <span className="block text-sm font-medium capitalize leading-5 text-gray-700">
                      1x {selectedPlan?.name}
                    </span>
                    <h5 className="block text-sm font-medium leading-5 text-green-600	">
                      <span>{selectedPlan?.amount / 100} </span>
                      {selectedPlan?.currency?.toUpperCase()}
                    </h5>
                  </div>
                </div>
                <div className="border-t border-gray-200 px-4 py-4 sm:px-6 min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <span className="block text-sm font-medium leading-5 text-gray-700">
                    Sub-Total:
                  </span>
                  <h5 className="block text-sm font-medium leading-5 text-green-600	">
                    <span>{selectedPlan?.amount / 100} </span>
                    {selectedPlan?.currency?.toUpperCase()}
                  </h5>
                </div>
                <div className="border-t border-gray-200 px-4 py-4 sm:px-6 min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <span className="block text-sm font-medium leading-5 text-gray-700">
                    Total:
                  </span>
                  <h5 className="block text-sm font-medium leading-5 text-green-600	">
                    <span>{selectedPlan?.amount / 100} </span>
                    {selectedPlan?.currency?.toUpperCase()}
                  </h5>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
    // </Elements>
  )
}

const Checkout = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout1 plan={props} />
    </Elements>
  )
}

export default withRouter(Checkout)
