import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LinearProgress, Button, Tabs, Tab } from '@material-ui/core'
import { ReactComponent as ServiceIcon } from 'static/svg/list-alt.svg'
import { ReactComponent as StorageIcon } from 'static/svg/storage.svg'
// import { ReactComponent as MasterCard } from 'static/svg/mastercard-2.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getStripeConnectStatus } from 'thunks/stripeAccount/actions'
import { fetchAgencyPlan } from 'thunks/agencyPlan/actions'
// import { useHistory } from 'react-router'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import clsx from 'clsx'
import { fetchUserActivePlanInfo } from 'thunks/userActivePlanInfo/actions'
// import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import {
  agencyUpgradePlan,
  // fetchStripeChekoutSession,
  fetchCardDetails
  // fetchAllAgencyInvoices
} from 'thunks/agencyPayment/actions'
// import { deactivateUser } from 'thunks/user/actions'
// import { LOGIN } from 'thunks/login/reduers'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import { loadStripe } from '@stripe/stripe-js'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import UpdateCardModal from './UpdateCardModal'
import getNumberWithOrdinal from 'utils/getNumberWithOrdinal'
import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
  // TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
// import { format } from 'date-fns'
import { useBreadcrumbs } from 'customHooks'
import SubscriptionCard from './SubscriptionCard'
import { fetchMeData } from 'thunks/me/actions'
import { openChatMessengerWithMessage, bytesToSize } from 'utils'
// import Pagination from 'global/globalComponents/Pagination/Pagination'
import settingStyles from '../settingStyles'
import RedeemCode from './RedeemCode'
// import { PopupMenu } from 'global/globalComponents'
import Pill from 'global/globalComponents/Pill/Pill'
// import pillVariantObj from 'utils/pillVariantObj'
// import { ReactComponent as ViewIcon } from 'static/svg/eye.svg'
// import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import PlanSubscriptionModal from './PlanSubscriptionModal'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import QuotaStatusCard from './QuotaStatusCard'
import AgencyInvoicesList from './AgencyInvoicesList'
import AppSumoPaymentMethod from './AppSumoPaymentMethod'
import { Skeleton } from '@material-ui/lab'

const Subscription = () => {
  const classes = settingStyles()
  const dispatch = useDispatch()
  // const history = useHistory()
  const allPlansEl = useRef(null)
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  // const userStripeInfo = useSelector((state) => state.userStripeInfo)
  const agencyPlans = useSelector(state => state.agencyPlans)
  const userActivePlan = useSelector(state => state.userActivePlanInfo)
  const [card, setCard] = useState({})
  const [loading, setLoading] = useState(null)
  // const [activeDuration, setActiveTabDuration] = useState(0)
  const [selectedTab, setSelectedTab] = useState('month')
  const meData = useSelector(state => state.me.data)
  const [open, setOpen] = React.useState(false)
  const [disable, setDisable] = useState(false)
  const [planUpgradeModal, setPlanUpgradeModal] = useState({
    open: false,
    data: {
      plan: {},
      quantity: 0,
      amount: 0
    }
  })
  const plansData = useMemo(() => {
    const plans = agencyPlans.data.filter(
      item => item.tiers_mode === 'graduated'
    )
    return {
      month: plans.filter(item => item.interval === 'month'),
      year: plans.filter(item => item.interval === 'year')
    }
  }, [agencyPlans.data])
  const seatOptions = useMemo(() => {
    const arr = [1, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(item => ({
      label: `${item} ${item === 1 ? 'Seat' : 'Seats'}`,
      value: item
    }))
    arr.push({ label: '50+ Seats', value: 'contact us' })
    return arr
  }, [])
  const [selectedSeats, setSelectedSeats] = useState({
    label: '1 Seat',
    value: 1
  })
  const quotaData = useMemo(() => {
    const obj = {
      services: {
        total:
          meData.profile?.quota?.service?.total ??
          meData.team?.profile?.quota?.service?.total,
        used:
          meData.profile?.quota?.service?.used ??
          meData.team?.profile?.quota?.service?.used
      },
      teammates: {
        total:
          meData.profile?.quota?.teammate?.total ??
          meData.team?.profile?.quota?.teammate?.total,
        used:
          meData.profile?.quota?.teammate?.used ??
          meData.team?.profile?.quota?.teammate?.used
      },
      client: {
        total:
          meData.profile?.quota?.client?.total ??
          meData.team?.profile?.quota?.client?.total,
        used:
          meData.profile?.quota?.client?.used ??
          meData.team?.profile?.quota?.client?.used
      },
      storage: {
        total:
          meData.profile?.quota?.storage?.total ??
          meData.team?.profile?.quota?.storage?.total,
        used:
          meData.profile?.quota?.storage?.used ??
          meData.team?.profile?.quota?.storage?.used
      }
    }

    return obj
  }, [meData])

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Subscription',
      link: `/settings/subscription`,
      index: 1
    }
  ])

  useEffect(() => {
    dispatch(getStripeConnectStatus())
    dispatch(fetchAgencyPlan())
    // dispatch(fetchUserActivePlanInfo())
  }, [])

  useEffect(() => {
    const plans = ['plan-a', 'plan-b', 'plan-c', 'plan-d', 'plan-e']
    if (plans.includes(userActivePlan.data?.name?.toLowerCase()))
      setDisable(true)
  }, [userActivePlan.data?.name])

  useEffect(() => {
    if (userActivePlan.data.quantity) {
      setSelectedSeats({
        label: `${userActivePlan.data.quantity ?? 1} ${
          userActivePlan.data.quantity <= 1 ? 'Seat' : 'Seats'
        }`,
        value: userActivePlan.data.quantity ?? 1
      })
    }
  }, [userActivePlan])

  const handleClose = () => {
    setOpen(prev => !prev)
    fetchCardDetails(cardDetailsCallback)
  }

  // const handledurationTabSwitch = (ind) => {
  //   setActiveTabDuration(ind)
  // }

  // const hadnlePushToGeneralSettings = () => {
  //   history.push('/agency/settings/general')
  // }

  const scrollPlansHorizontally = scrollOffset => {
    allPlansEl.current.scrollLeft += scrollOffset
  }

  // const deactivateCallback = (res, err) => {
  //   if (err) {
  //   } else {
  //     localStorage.removeItem('authToken')
  //     dispatch({ type: LOGIN.LOGOUT })
  //   }
  // }

  const storageData = () => {
    return quotaData.storage.used >= 1024
      ? quotaData.storage.used / 1024 + 'GB'
      : quotaData.storage.used + ' MB'
  }

  // const deactivate = () => {
  //   dispatch(deactivateUser(me._id, deactivateCallback))
  // }

  const cardDetailsCallback = (res, err) => {
    if (err) {
    } else {
      setCard(res)
    }
  }

  // const selectPlanHandler = async (plan) => {
  //   // setLoading(plan.id)
  //   const stripeSessionInfo = await agencyUpgradePlan(
  //     // {
  //     //    email: 'mandeep@gmail.com',
  //     //   plan
  //     //    quantity: 1
  //     // },
  //     plan,
  //     selectPlanHandlerCallback
  //   )
  // }

  const selectPlanHandler = planData => {
    setLoading(planData.plan.id)
    agencyUpgradePlan(planData, selectPlanHandlerCallback)
  }

  const selectPlanHandlerCallback = async (res, err) => {
    if (err) {
      setLoading(null)
      dispatch(fireErrorToaster(res))
    } else {
      toggleUpgradePlanModal()
      // If user has no plan selected then redirect to Stript Payment
      if (userActivePlan?.data?.mode === 'manual') {
        const stripe = await stripePromise
        await stripe.redirectToCheckout({
          sessionId: res.id
        })
      }
      dispatch(fetchUserActivePlanInfo(userActivePlanInfoCallback))
      dispatch(fetchMeData())
    }
  }

  const userActivePlanInfoCallback = (res, err) => {
    setLoading(null)
    if (!err) {
      dispatch(fireSuccessToaster('your plan has been changed'))
    }
  }

  useEffect(() => {
    fetchCardDetails(cardDetailsCallback)
    // dispatch(updateUserAcces(me.role))
  }, [])

  const calculateUsagePercentage = () => {
    const totalUsed =
      (quotaData.services.used +
        quotaData.teammates.used +
        quotaData.client.used +
        quotaData.storage.used) /
      1024
    const totalAvailable =
      (quotaData.services.total +
        quotaData.teammates.total +
        quotaData.client.total +
        quotaData.storage.total) /
      1024

    return Math.round((totalUsed / totalAvailable) * 100)
  }

  const toggleUpgradePlanModal = planData => {
    if (planUpgradeModal.open) {
      setPlanUpgradeModal(prev => ({ ...prev, open: false }))
    } else {
      setPlanUpgradeModal({ open: true, data: planData })
    }
  }

  const handleTabChange = (e, val) => {
    setSelectedTab(val)
  }

  const handleSeatChange = val => {
    setSelectedSeats(val)
  }

  return (
    <div>
      <Wrapper className="mb-4">
        <SecondaryHeading className="mb-5 lg:mb-6">Plans</SecondaryHeading>
        {!disable && !meData.isAppsumoUser && (
          <p
            className="text-custom-gray-main font-light text-xs md:text-smaller  mb-4"
            style={{ borderRadius: '5px' }}
          >
            Your current subscription is billed on the{' '}
            {userActivePlan?.data?.createdAt
              ? getNumberWithOrdinal(
                  new Date(userActivePlan?.data?.createdAt).getDate()
                )
              : '1st'}{' '}
            of every month
          </p>
        )}

        <div className={clsx(classes.subscriptionInfo, 'mt-0')}>
          <div className="col-span-3 text-smaller lg:text-sm">
            <div>
              {userActivePlan.loading ? (
                <p>Loading...</p>
              ) : (
                <>
                  {userActivePlan?.data ? (
                    <ActivePlan
                      plan={userActivePlan.data}
                      toggleUpgradePlanModal={toggleUpgradePlanModal}
                    />
                  ) : (
                    <div>NA</div>
                  )}
                </>
              )}

              <div className="my-8">
                <p className="mb-2 text-smaller lg:text-sm">
                  {calculateUsagePercentage()}% quota used
                </p>
                <LinearProgress
                  variant="determinate"
                  value={calculateUsagePercentage()}
                />
              </div>
            </div>

            <div>
              <p className="mb-2">Used Quota</p>
              <div className="flex flex-wrap gap-4">
                <QuotaStatusCard
                  helpText={'Services quota usage'}
                  title="Services"
                  total={quotaData.services.total}
                  used={quotaData.services.used}
                  Icon={ServiceIcon}
                />
                <QuotaStatusCard
                  helpText={'Teammates quota usage'}
                  title="Teammates"
                  total={quotaData.teammates.total}
                  used={quotaData.teammates.used}
                  Icon={UserIcon}
                />
                <QuotaStatusCard
                  helpText={'Storage quota usage'}
                  title="Storage"
                  total={quotaData.storage.total + 'GB'}
                  used={storageData()}
                  Icon={StorageIcon}
                />
              </div>
            </div>
          </div>

          <div className="col-span-6 text-smaller lg:text-sm">
            {meData.isAppsumoUser ? (
              <RedeemCode />
            ) : (
              <>
                <div className="mb-4">
                  <div className="mb-4 flex justify-between">
                    <div className="flex items-center">
                      <p className="mr-4 text-smaller lg:text-sm text-gray-600">
                        Choose team size:
                      </p>
                      <Select
                        options={seatOptions}
                        value={selectedSeats}
                        className="w-40 text-smaller lg:text-sm"
                        onChange={handleSeatChange}
                        styles={reactSelectCustomStyles}
                      />
                    </div>
                    <div className="flex items-center gap-4">
                      <Tabs value={selectedTab} onChange={handleTabChange}>
                        {[
                          { label: 'Monthly', value: 'month' },
                          { label: 'Yearly', value: 'year' }
                        ].map(item => (
                          <Tab
                            className="text-capitalize"
                            label={item.label}
                            key={item.label}
                            value={item.value}
                          />
                        ))}
                      </Tabs>
                      {/* <div className={classes.durationTabContainer}>
                        {[
                          { label: 'Monthly', value: "month" },
                          { label: 'Yearly', value: "year" },
                        ].map((item, index) => (
                        <p
                          className={clsx({ active: item.value === activeDuration})}
                          onClick={() => handledurationTabSwitch(index)}
                        >
                          {item}
                        </p>
                      ))}
                    </div> */}
                      <div className="flex items-center gap-2">
                        <span
                          role="button"
                          className="rounded w-6 h-6 cursor-pointer flex items-center justify-center border border-gray-200"
                          onClick={() => scrollPlansHorizontally(-200)}
                        >
                          <ChevronLeftRoundedIcon />
                        </span>
                        <span
                          role="button"
                          className="rounded w-6 h-6 cursor-pointer flex items-center justify-center border border-gray-200"
                          onClick={() => scrollPlansHorizontally(200)}
                        >
                          <ChevronRightRoundedIcon />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  ref={allPlansEl}
                  style={{ scrollBehavior: 'smooth' }}
                  className="flex gap-4 pb-4 overflow-scroll px-2"
                >
                  {agencyPlans.loading ? (
                    <LoadingPlanCards />
                  ) : (
                    plansData[selectedTab].map(
                      (plan, index) => (
                        <SubscriptionCard
                          key={plan.id}
                          plan={plan}
                          disable={disable}
                          activePlan={userActivePlan?.data}
                          isActive={
                            disable
                              ? true
                              : userActivePlan?.data?.payment_plan?.id ===
                                plan?.id
                          }
                          loading={loading}
                          selectPlanHandler={selectPlanHandler}
                          toggleUpgradePlanModal={toggleUpgradePlanModal}
                          selectedSeats={
                            selectedSeats.value === 'contact us'
                              ? 0
                              : selectedSeats.value
                          }
                          contactUs={
                            selectedSeats.value === 'contact us' ||
                            userActivePlan?.data?.payment_plan?.tiers_mode !==
                              'graduated'
                          }
                        />
                      )
                      // plan.interval === 'month' && activeDuration === 0 ? (
                      // ) : plan.interval === 'year' && activeDuration === 1 ? (
                      //   <SubscriptionCard
                      //     key={plan.id}
                      //     plan={plan}
                      //     isActive={
                      //       userActivePlan?.data?.payment_plan?.id === plan?.id
                      //     }
                      //     selectPlanHandler={selectPlanHandler}
                      //     toggleUpgradePlanModal
                      //   />
                      // ) : null
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Wrapper>

      <Wrapper className="mb-6 lg:mb-8">
        <div className={clsx(classes.paymentInfo, 'overflow-hidden mb-2')}>
          <div>
            <h5 className="text-primary-dark font-medium text-sm lg:text-base mb-8">
              Subscription plan {meData.isAppsumoUser && '(Lifetime)'}
            </h5>

            <TableContainer mb={2}>
              <Thead>
                <Th className="text-left whitespace-nowrap">Selected Plan</Th>
                <Th className="text-center">
                  Price
                  {!meData.isAppsumoUser &&
                    `/${
                      userActivePlan.data?.payment_plan?.interval === 'month'
                        ? 'mo'
                        : 'yr'
                    }`}
                </Th>
              </Thead>
              <Tbody>
                <Tr>
                  <Td className="text-primary-mid-dark font-medium">
                    Plan Price
                  </Td>
                  <Td center>
                    {userActivePlan.data?.amount ? (
                      <p>$ {userActivePlan.data?.amount * 0.01}.00</p>
                    ) : (
                      <p>NA</p>
                    )}
                  </Td>
                </Tr>
                <Tr>
                  <Td className="text-primary-dark font-medium">Total</Td>
                  <Td className="text-center text-primary-dark font-medium">
                    {userActivePlan.data?.amount ? (
                      <p>$ {userActivePlan.data?.amount * 0.01 ?? 'NA'}.00</p>
                    ) : (
                      <p>NA</p>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </TableContainer>
          </div>

          {!meData.isAppsumoUser && (
            <AppSumoPaymentMethod
              card={card}
              classes={classes}
              handleClose={handleClose}
            />
          )}
        </div>

        {!meData.isAppsumoUser && (
          <div className="flex items-center gap-4 justify-end">
            {/* <Button
          startIcon={<DeletIcon className="text-primary-main" />}
          variant="outlined"
          onClick={deactivate}
        >
          Deactivate Account
        </Button> */}
            <Button
              onClick={() =>
                openChatMessengerWithMessage(
                  'I want to cancel my subscription.'
                )
              }
              startIcon={<CloseIcon />}
            >
              Cancel Subscription
            </Button>
          </div>
        )}
      </Wrapper>

      <Wrapper className="mb-9">
        <SecondaryHeading className="mb-6 lg:mb-8">Invoices</SecondaryHeading>
        <AgencyInvoicesList />
      </Wrapper>

      <UpdateCardModal
        // addingNewCard={!Object.keys(card).includes('last4')}
        addingNewCard={true}
        open={open}
        handleClose={handleClose}
      />
      {planUpgradeModal.open && (
        <PlanSubscriptionModal
          open={planUpgradeModal.open}
          onClose={toggleUpgradePlanModal}
          data={planUpgradeModal.data}
          selectPlanHandler={selectPlanHandler}
          loading={loading}
        />
      )}
    </div>
  )
}

const ActivePlan = ({ plan, toggleUpgradePlanModal }) => {
  const isNewPlan = plan.payment_plan?.tiers_mode === 'graduated'

  const handleOpenUpgradePlanModal = () => {
    if (!isNewPlan) {
      openChatMessengerWithMessage('I want to upgrade my plan')
    } else {
      toggleUpgradePlanModal({
        plan: plan.payment_plan,
        quantity: plan.quantity,
        amount: plan.amount
      })
    }
  }

  return (
    <div>
      <div className="flex items-center space-x-2">
        <h4 className="font-bold text-base md:text-lg lg:text-2xl text-primary-main">
          ${parseFloat(plan.amount / 100).toFixed(2)}
        </h4>
        <Pill variant="orange" size="small">
          Active Plan
        </Pill>
      </div>
      <p className="text-custom-gray-light-6 text-smaller md:text-sm lg:text-base font-medium mb-4">
        {plan.name ?? 'NA'}
      </p>
      <Button onClick={handleOpenUpgradePlanModal}>Upgrade</Button>
    </div>
  )
}

const LoadingPlanCards = () => {
  return (
    <>
      {new Array(3).fill().map((item, index) => (
        <Skeleton
          key={index}
          variant="rect"
          width={300}
          height={200}
          className="rounded"
        />
      ))}
    </>
  )
}
export default Subscription
