import InfoIcon from '@material-ui/icons/Info'

export default function StripeMessage(props) {
  const {
    stripProps = 'bg-primary-light p-4 w-full md:w-3/4',
    action = false,
    actionText = 'Details'
  } = props
  return (
    <div className={stripProps}>
      <div className="rounded-md">
        <div className="flex items-baseline">
          <div className="flex-shrink-0 leading-none">
            <InfoIcon
              className="h-2 w-2 text-primary-main"
              aria-hidden="true"
              style={{ fontSize: '1.1rem' }}
            />
          </div>
          <div className="ml-1 flex-1 items-center md:flex md:justify-between">
            <p
              className="text-smaller lg:text-sm text-primary-main"
              style={{ fontSize: '0.8rem' }}
            >
              {props.children}
            </p>
            {action && (
              <p className="mt-3 text-smaller lg:text-sm md:mt-0 md:ml-6">
                <a
                  href={props.url || '#'}
                  target="_blank"
                  className="whitespace-nowrap font-medium text-primary-main text-sm"
                >
                  {actionText} <span aria-hidden="true">&rarr;</span>
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
