import React from 'react'
import clsx from 'clsx'
import gettingStartedStyles from './GettingStartedStyles'

function GettingVideoCard() {
  const classes = gettingStartedStyles()

  return (
    <div
      className={clsx(
        classes.videoCard,
        'bg-white border shadow rounded my-6 select-none'
      )}
    >
      <div className={classes.videoCardCol}>
        <iframe
          width="100%"
          height="247px"
          src="https://www.youtube.com/embed/I_YFl1590tE"
          title="YouTube video player"
          frameborder="0"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        {/* <div className="relative cursor-pointer" onClick={displayVideo}>
          <div>
            <img src="https://clientvenue.com/images/dash-landing.png" alt="" />
          </div>
          <div
            className={clsx(
              classes.videoOverlay,
              'absolute inset-0 flex justify-center items-center'
            )}
          >
            <button className={classes.videoPlayButton}>
              <PlayArrowRoundedIcon />
            </button>
          </div>
        </div> */}
      </div>
      <div className={clsx(classes.videoCardCol, 'p-4')}>
        <h5 className="text-base mb-4">
          Learn how to get started with Clientvenue in less than 5 minutes
        </h5>
        <p className="text-sm leading-5 text-gray-500">
          In this short video, get a basic understanding of how each module
          works and what the client user experience is like.
        </p>
      </div>
      {/* {play && (
        <div
          className={`fixed w-full h-full z-50 inset-0 flex justify-center items-center ${classes.videoWrapper}`}
          onClick={hideVideo}
        >
          <iframe
            width="720px"
            height="400px"
            src="https://www.youtube.com/embed/I_YFl1590tE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      )} */}
    </div>
  )
}

export default GettingVideoCard
