const agencyPlansOfferings = {
  lite: {
    '5 Teammates': true,
    'Unlimited Clients': true,
    '20 Service Offerings': true,
    'Dedicated Client Dashboard': true,
    'Custom Scripts for Chat': false,
    'White Lable Portal': false,
    'Event Webhooks': false,
    'Slack Integration': false
  },
  plus: {
    '15 Teammates': true,
    'Unlimited Clients': true,
    '50 Service Offerings': true,
    'Dedicated Client Dashboard': true,
    'Custom Scripts for Chat': true,
    'White Lable Portal': true,
    'Event Webhooks': true,
    'Slack Integration': false
  },
  enterprise: {
    'Unlimited Teammates': true,
    'Unlimited Clients': true,
    'Unlimited Service Offerings': true,
    'Dedicated Client Dashboard': true,
    'Custom Scripts for Chat': true,
    'White Lable Portal': true,
    'Event Webhooks': true,
    'Slack Integration': true
  }
}

export default agencyPlansOfferings
