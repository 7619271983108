import { useEffect, useState } from 'react'
import axios from 'axios'
import clsx from 'clsx'
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core'
// import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import { useDispatch } from 'react-redux'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { ClientVenueLogo } from 'static/Images/index'
// import TextInput from 'global/globalComponents/TextInput/TextInput'
import Testimonial from 'global/globalComponents/Testimonial/Testimonial'
import { userRoles } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import { userSignup } from 'thunks/signup'
import { appSumoCouponValidation } from 'thunks/couponValidation/couponValidation'
import GeneralFormModal from './generalformModal'
import useTitle from 'customHooks/useTitle'
import { AppSumoLogo } from 'static/Images'
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone'

const AppSumoSignup = () => {
  const dispatch = useDispatch()
  useTitle()
  const [domain, setDomain] = useState('')
  const [open, setOpen] = useState(false)
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(true)
  // const [enterCode, setEnteredCode] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [codeStatus, setCodeStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })
  const [userDomainStatus, setUserDomainStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    brandName: '',
    website: '',
    role: userRoles.USER_AGENCY,
    type: 'normal'
  })
  const [coupons, setCoupons] = useState([{ value: '', error: '' }])

  useEffect(() => {
    // console.log('disable')
    const hasCoupon = coupons.find((item) => Boolean(item.value))

    if (userDomainStatus) {
      if (
        formData.name === '' ||
        formData.email === '' ||
        formData.password === '' ||
        formData.brandName === '' ||
        domain === '' ||
        !hasCoupon
      ) {
        setDisable(true)
      } else {
        setDisable(false)
      }
    } else {
      setDisable(true)
    }
  }, [formData, domain, codeStatus, userDomainStatus, confirmPassword, coupons])

  const handleDomain = (e) => {
    const value = e.target.value.trim().toLowerCase()
    if (/^([\w\d]-?)*$/.test(value)) {
      setDomain(value)
    }
  }

  const handleCode = (e) => {
    const index = e.target.dataset.index
    const couponsClone = [...coupons]

    couponsClone[index] = { value: e.target.value }
    setCoupons(couponsClone)
    // setCodeStatus({
    //   loading: false,
    //   message: undefined,
    //   available: false
    // })
    // setEnteredCode(e.target.value)
  }

  // const confirmPasswordHandler = () => {
  //   if (formData.password !== '' && confirmPassword !== '') {
  //     formData.password !== confirmPassword &&
  //       dispatch(
  //         fireErrorToaster('Password does not match with confirm password')
  //       )
  //   }
  // }

  const handleSubmitDomain = async () => {
    setFormData((prev) => ({ ...prev, brandName: '' }))
    if (domain.length > 0) {
      setUserDomainStatus((props) => ({
        ...props,
        loading: true,
        message: 'Checking',
        available: undefined
      }))
      try {
        const res = await axios({
          method: 'GET',
          url: `/user/checkagencydomain?domain=${domain}.clientvenue.com`,
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if (res.data.domainExist) {
          setUserDomainStatus({
            loading: false,
            message: 'Not Available',
            available: false
          })
          dispatch(fireErrorToaster(`${domain} domain already exist.`))
        } else {
          setFormData((prev) => ({ ...prev, brandName: domain }))
          setUserDomainStatus({
            loading: false,
            message: 'Available',
            available: true
          })
        }
      } catch (err) {
        setUserDomainStatus({
          loading: false,
          message: 'Not Available',
          available: false
        })
      }
    } else {
      setUserDomainStatus((props) => ({
        ...props,
        message: undefined,
        available: undefined
      }))
    }
  }

  const couponValidationCallback = (res, err) => {
    if (!err) {
      userSignup(
        {
          ...formData,
          source: window.location.href,
          codeStack: coupons
            .filter((item) => Boolean(item.value.trim()))
            .map((item) => item.value),
          isAppsumoUser: true,
          agencyDomain: domain + '.clientvenue.com'
        },
        signupCallback
      )
    } else {
      setLoading(false)
      const updatedList = [...coupons].map((item) => {
        const errObj = res.coupen.find((item2) => item2.coupen === item.value)

        if (errObj) {
          return { value: item.value, error: errObj.message }
        }
        return item
      })
      setCoupons(updatedList)
    }
    // if (err) {
    //   setLoading(false)
    //   setCodeStatus({
    //     loading: false,
    //     message: res,
    //     available: false
    //   })
    // } else {
    //   setCodeStatus({
    //     loading: false,
    //     message: res.message,
    //     available: true
    //   })

    // }
  }

  const formInputHandler = (e) => {
    const { name, value } = e.target
    setFormData((oldState) => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validEmailAddress = () => {
    let validEmail = /.+@.+\..+/.test(formData.email)
    if (!validEmail) {
      dispatch(fireErrorToaster('please enter valid email address'))
      return false
    } else {
      return true
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  // const phoneNumberIputHandler = (valid, phone, country) => {
  //   setFormData((oldData) => ({
  //     ...oldData,
  //     phoneNumber: phone.length > 0 ? '+' + country.dialCode + ' ' + phone : ''
  //   }))
  // }

  const userSignupHandler = (e) => {
    e.preventDefault()

    if (!userDomainStatus.available) {
      return dispatch(fireErrorToaster('Please enter the valid domain'))
    }

    if (!validEmailAddress()) {
      return dispatch(fireErrorToaster('please enter valid email address'))
    }

    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }

    if (formData.password !== confirmPassword) {
      setLoading(false)
      return dispatch(
        fireErrorToaster('Password does not match with confirm password')
      )
    }

    setLoading(true)
    setCodeStatus({
      loading: true,
      message: 'Checking',
      available: false
    })

    appSumoCouponValidation(
      {
        codeStack: coupons
          .filter((item) => Boolean(item.value.trim()))
          .map((item) => item.value)
      },
      couponValidationCallback
    )
  }

  const getCouponPlan = async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/coupen/user`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return res
    } catch (err) {
      dispatch(fireErrorToaster('Unsuccessful Coupon request'))
    }
  }

  const modalCloseHandler = () => {
    setOpen(false)
  }

  const signupCallback = async (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      try {
        let plan = await getCouponPlan()
        if (plan.status === 200) {
          const obj = {
            email: formData.email,
            plan: plan.data
          }

          // const freePlanres = await createFreePlan(obj, res.login.token)
          // setLoading(false)
          // setWaitingModalStatus({ open: true, time: 40 })
          // storeToken(res.login.token)
          setOpen(true)
          setResponse({
            freePlansObj: obj,
            token: res?.login?.token,
            id: res?.profile?._id,
            domain: res?.agencyDomain,
            path: res?.path,
            brandName: res?.profile?.brand?.brandName
          })
          dispatch(fireSuccessToaster('Signup successful'))

          // const intervalTimer = setInterval(() => {
          //   setWaitingModalStatus((prev) => ({
          //     ...prev,
          //     time: prev.time - 1
          //   }))
          // }, 1000)

          // setTimeout(() => {
          //   window.open(
          //     `https://${freePlanres.agencyDomain}${
          //       freePlanres.path
          //     }?signup=true&auth_token=${res.login.token.split(' ')[1]}`,
          //     '_self'
          //   )
          //   clearInterval(intervalTimer)
          // }, 40000)
        } else {
          dispatch(fireErrorToaster('Unsuccessful code request'))
        }
      } catch (err) {
        dispatch(fireErrorToaster('Unsuccessful'))
        return
      }
    }
  }

  const handleStackCoupon = () => {
    setCoupons((prev) => [...prev, { value: '', error: '' }])
  }

  return (
    <div className="w-full h-screen grid grid-cols-5">
      <div className="col-span-5 md:col-span-3 p-4 md:p-12">
        <header className="flex flex-col items-center mb-8">
          <div className="text-center h-12 flex items-center gap-4">
            <img
              src={ClientVenueLogo}
              alt="logo"
              target="_self"
              className="h-full"
            />
            <FavoriteTwoToneIcon fontSize="large" className="text-red-400" />
            <img src={AppSumoLogo} style={{ width: 160 }} alt="appsumo logo" />
          </div>
          <p className="font-medium flex items-center mx-auto">
            Redeem Your AppSumo Code
          </p>
        </header>

        <form
          onSubmit={userSignupHandler}
          className="mt-4 py-4 w-full md:w-3/4 mx-auto space-y-4 bg-white shadow rounded p-8"
        >
          <div>
            <FormLabel required htmlFor="name">
              Name
            </FormLabel>
            <TextField
              onChange={formInputHandler}
              placeholder="Name"
              required
              id="name"
              name="name"
              value={formData.name}
              className="w-full"
            />
          </div>
          <div>
            <FormLabel required htmlFor="email">
              Email
            </FormLabel>
            <TextField
              onChange={formInputHandler}
              required
              value={formData.email}
              placeholder="Email"
              id="email"
              className="w-full"
              name="email"
              onBlur={validEmailAddress}
            />
          </div>
          <div>
            <FormLabel required htmlFor="agencyDomain">
              Agency Domain
            </FormLabel>
            <div className="flex h-8" style={{ margin: '0 0 1.425rem' }}>
              <div className="flex flex-row p-0 m-0 rounded-sm items-center w-full">
                <TextField
                  required
                  value={domain}
                  inputProps={{ onBlur: handleSubmitDomain }}
                  placeholder="Agency Domain"
                  onChange={handleDomain}
                  className="w-full"
                />
                <Typography
                  style={{
                    alignSelf: 'center',
                    fontSize: '12px',
                    color: '#778ca2'
                  }}
                >
                  .clientvenue.com
                </Typography>
              </div>
              {userDomainStatus.message && (
                <div className="flex items-center gap-1 ml-4">
                  {userDomainStatus.loading && (
                    <CircularProgress value={0} size={15} />
                  )}
                  <p
                    className={clsx('font-medium text-xs', {
                      'text-red-500': !userDomainStatus.available,
                      'text-green-500': userDomainStatus.available,
                      'text-blue-600': userDomainStatus.loading
                    })}
                  >
                    {userDomainStatus.message}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div>
            <FormLabel required htmlFor="company">
              Company Name
            </FormLabel>
            <TextField
              required
              placeholder="Company name"
              className="w-full"
              name="brandName"
              id="company"
              value={formData.brandName}
              onChange={formInputHandler}
            />
          </div>

          <div>
            <FormLabel required>Password</FormLabel>
            <TextField
              required
              // error={formData.password && !passwordValid ? true : false}
              placeholder="Password"
              name="password"
              className="w-full"
              value={formData.password}
              type="password"
              onChange={formInputHandler}
              onBlur={validPasswordHandler}
              variant="outlined"
            />
            {formData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </div>

          <div>
            <FormLabel required>Confirm Password</FormLabel>
            <TextField
              required
              placeholder="Confirm Password"
              name="confirm password"
              className="w-full"
              value={confirmPassword}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              // onBlur={confirmPasswordHandler}
            />
          </div>

          <div>
            <FormLabel required htmlFor="agencyDomain">
              AppSumo Code
            </FormLabel>
            <div>
              <div>
                {coupons.map((item, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <TextField
                      inputProps={{ 'data-index': index }}
                      classes={{ root: 'mb-4 w-full block' }}
                      className="mb-4"
                      style={{ marginBottom: '8px' }}
                      required
                      value={item.value}
                      placeholder="Enter AppSumo code"
                      onChange={handleCode}
                    />
                    {item.error && (
                      <p className="text-xs whitespace-nowrap text-red-400">
                        {item.error}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="text-right">
              {coupons.length < 8 && (
                <Button size="small" onClick={handleStackCoupon}>
                  Stack
                </Button>
              )}
            </div>
          </div>

          <div>
            <LoadingButton
              onClick={userSignupHandler}
              style={{ width: '100%' }}
              loading={loading}
              disabled={disable}
            >
              Sign Up
            </LoadingButton>
          </div>
        </form>
      </div>

      <div className="col-span-0 flex flex-col justify-center md:col-span-2 bg-primary-light">
        <div className="fixed hidden px-12 md:block">
          <Testimonial />
        </div>
      </div>
      <GeneralFormModal
        open={open}
        handleClose={modalCloseHandler}
        data={response}
      />
    </div>
  )
}

export default AppSumoSignup
