import ColoredAvatars, {
  ColoredAvatarGroup
} from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { AvatarGroup } from '@material-ui/lab'

const TotalMember = (props) => {
  const { members, userState, handleUserState, type } = props
  return (
    <div className="flex justify-between items-center my-4">
      {members?.length > 0 ? (
        <>
          <div
            className={`relative cursor-pointer flex justify-start items-center flex-shrink-0 gap-0`}
          >
            <ColoredAvatarGroup
              users={members.map((item) => item.user)}
              max={4}
              style={{ zIndex: 0 }}
              tooltip={true}
            />
            {/* <AvatarGroup max={4} style={{ zIndex: 0 }}>
              {members?.slice(0, 4)?.map((item) => (
                <ColoredAvatars key={item?.user?._id} user={item.user} />
              ))}
            </AvatarGroup> */}
            <p className="pl-2 text-sx">
              {members[0]?.user?.name}{' '}
              {members?.length - 1 > 0 && `and ${members?.length - 1} others`}
            </p>
          </div>
          <p
            className="font-medium text-green-500 text-sm cursor-pointer"
            onClick={() => handleUserState(userState)}
          >
            view{' '}
            {type === 'client'
              ? 'all teammates'
              : userState === 1
              ? 'all teammates'
              : 'client'}
          </p>
        </>
      ) : (
        <p className="text-xs text-gray-700">
          No{' '}
          {type === 'client'
            ? 'teammates'
            : userState === 1
            ? 'teammate'
            : 'client'}{' '}
          in this project
        </p>
      )}
    </div>
  )
}

export default TotalMember
