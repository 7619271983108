import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button
  // Typography,
  // Divider,
  // CircularProgress
} from '@material-ui/core'
import { BigToggle } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgencyPlan } from 'thunks/agencyPlan/actions'
// import agencyPlanOfferings from 'constants/agencyPlanOfferings'
import { fetchStripeChekoutSession } from 'thunks/agencyPayment/actions'
import { loadStripe } from '@stripe/stripe-js'
import PaymentSuccessfulModal from './PaymentSuccessfulModal'
import { withRouter } from 'react-router-dom'
import PaymentFailModal from './PaymentFailModal'
import { LOGIN } from 'thunks/login/reduers'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { fetchMeData } from 'thunks/me/actions'
import useTitle from 'customHooks/useTitle'
import { Clientvenue_logo } from 'static/Images/index'
// import agencyPlansOfferings from './planOfferings'
import clsx from 'clsx'
// import InfoIcon from '@material-ui/icons/Info'
import AgencyPlansStyles from './AgencyPlansStyles'
import PlanCard from './PlanCard'
import Select from 'react-select'
import { Skeleton } from '@material-ui/lab'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import usePlanFunctions from './usePlanFunctions'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const AgencyPlans = (props) => {
  const { location } = props
  const customStyles = AgencyPlansStyles()
  const dispatch = useDispatch()
  const agencyPlans = useSelector((state) => state.agencyPlans)
  const me = useSelector((state) => state.me)
  const [loading, setLoading] = useState(null)
  // const [open, setOpen] = useState(false)
  const [paymentStatusModal, setPaymentStatusModal] = useState({
    success: false,
    fail: false
  })
  const [currentPlanDuration, setCurrentPlanDuration] = useState('month')
  const seatOptions = useMemo(() => {
    const arr = [1, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((item) => ({
      label: `${item} ${item === 1 ? 'Seat' : 'Seats'}`,
      value: item
    }))
    arr.push({ label: '50+ Seats', value: 'contact us' })
    return arr
  }, [])
  const { getSeatBasedPlanAmount } = usePlanFunctions()

  const [selectedSeats, setSelectedSeats] = useState({
    label: '1 Seat',
    value: 1
  })
  const plansData = useMemo(() => {
    const plans = agencyPlans.data.filter(
      (item) => item.tiers_mode === 'graduated'
    )
    return {
      month: plans.filter((item) => item.interval === 'month'),
      year: plans.filter((item) => item.interval === 'year')
    }
  }, [agencyPlans.data])

  const handleDurationToggle = (e, duration) => {
    setCurrentPlanDuration(duration)
  }

  useTitle('Clientvenue | Checkout | Select Plan', Clientvenue_logo)

  useEffect(() => {
    dispatch(fetchAgencyPlan())
    dispatch(fetchMeData())
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const success = params.get('success')

    if (success) {
      if (
        success === 'true' &&
        (me.data.path === '/agency/getting-started' ||
          me.data.path === '/agency/dashboard')
      ) {
        setPaymentStatusModal({ fail: false, success: true })
      } else if (
        success === 'false' ||
        me.data.path !== '/agency/getting-started' ||
        me.data.path === '/agency/dashboard'
      ) {
        setPaymentStatusModal({ fail: true, success: false })
      }
    }
  }, [location, me.data])

  const selectPlanHandler = async (plan, amount) => {
    setLoading(plan.id)
    fetchStripeChekoutSession(
      { email: me.data.email, plan, quantity: selectedSeats.value, amount },
      selectPlanHandlerCallback
    )
  }

  const selectPlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster('unable to select plan'))
    } else {
      const stripe = await stripePromise
      const result = await stripe.redirectToCheckout({
        sessionId: res.id
      })
    }
  }

  const modalCloseHandler = (type) => {
    setPaymentStatusModal((prev) => ({ ...prev, [type]: false }))
  }

  const handleLogout = () => {
    // localStorage.removeItem('authToken')
    // localStorage.removeItem('openTimeCounter')
    dispatch({ type: LOGIN.LOGOUT })
  }

  const handleSeatChange = (val) => {
    setSelectedSeats(val)
  }

  return (
    <div className="pb-12">
      {/* <Box className={customStyles.headerContainer}> */}
      <Box className="bg-white py-4 px-4 shadow flex justify-end sticky top-0 z-50">
        <Button onClick={handleLogout} variant="outlined" size="small">
          Logout
        </Button>
      </Box>
      <Box
        py={2}
        px={4}
        className={clsx(customStyles.pageBodyContainer, 'mt-8 mx-auto')}
      >
        <div className="text-center">
          {/* <h3 className="text-xl font-medium text-center py-3">
            Choose any plan
          </h3> */}
          <h3 className="text-2xl font-semibold py-3">
            Simple, honest & affordable pricing
          </h3>
          <p className="text-sm text-gray-500">
            Enjoy any plan free for 14 days | Cancel anytime | No questions
            asked
          </p>
        </div>
        <BigToggle
          onChange={handleDurationToggle}
          value={currentPlanDuration}
        />

        <div className="flex items-center">
          <p className="mr-4 text-sm text-gray-600">Choose team size:</p>
          <Select
            options={seatOptions}
            value={selectedSeats}
            className="w-40 text-sm"
            onChange={handleSeatChange}
            styles={reactSelectCustomStyles}
          />
        </div>

        <Box className={clsx(customStyles.planCardsContainer, 'mt-8')}>
          {agencyPlans.loading ? (
            <LoadingAnimation />
          ) : (
            plansData[currentPlanDuration].map((plan, index) => (
              <PlanCard
                key={plan.id}
                plan={plan}
                selectPlanHandler={selectPlanHandler}
                loading={loading === plan.id}
                index={index}
                selectedSeats={selectedSeats.value}
                currentPlanDuration={currentPlanDuration}
                getSeatBasedPlanAmount={getSeatBasedPlanAmount}
              />
            ))
          )}
        </Box>

        {/* <div
          className={`py-6 px-5 font-medium mx-auto rounded-lg mt-10 shadow-md ${customStyles.planAdditionalInfoCard}`}
        >
          <ul className="list-none">
            {[
              "You won't be charged until the end of your trial period",
              'Cancel anytime',
              'Our team will help you in setting up & get going!'
            ].map((i, index) => (
              <li key={i}>
                <InfoIcon fontSize="small" />
                <span className={customStyles.planAdditionalInfoCardText}>
                  {i}
                </span>
              </li>
            ))}
          </ul>
        </div> */}
      </Box>
      <PaymentSuccessfulModal
        open={paymentStatusModal.success}
        path={'/agency/dashboard?newAgency=true&payment=true'}
      />
      <PaymentFailModal
        onClose={modalCloseHandler}
        open={paymentStatusModal.fail}
      />
    </div>
  )
}

const LoadingAnimation = () => (
  <>
    {new Array(4).fill('').map((item, index) => (
      <Skeleton key={index} variant="rect" style={{ height: 400 }} />
    ))}
  </>
)

export default withRouter(AgencyPlans)
