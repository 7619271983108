import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import Papa from 'papaparse'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import { useRef, useState } from 'react'
import ImportMappingModal from './ImportMappingModal'

const acceptedFiles = ['csv']

const Import = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [fileData, setFileData] = useState([])
  const [file, setFile] = useState({})
  const ref = useRef()

  const handleFileInput = e => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0]
      const fileType = inputFile.type.split('/')[1]
      if (!acceptedFiles.includes(fileType)) {
        return dispatch(fireErrorToaster('Please choose the csv file'))
      }
      setFile(inputFile)
      setLoading(true)
      Papa.parse(inputFile, {
        complete: function (results) {
          setLoading(false)
          setFileData(results.data)
          setOpen(true)
        }
      })
    } else {
      dispatch(fireErrorToaster('Please choose the file'))
    }
  }

  const onClose = () => {
    ref.current.value = ''
    setOpen(false)
    setFileData([])
    setFile({})
  }

  // const onInputClick = (event) => {
  //   event.target.value = ''
  // }

  return (
    <Wrapper>
      <SecondaryHeading className="mb-6 lg:mb-8">
        Import from CSV/Excel file
      </SecondaryHeading>
      <div className="flex flex-col md:flex-row md:items-center border px-2 py-6 border-dashed">
        <input
          ref={ref}
          type="file"
          onChange={handleFileInput}
          accept=".csv,.xlsx,.xls"
          className="w-1/3 mb-4 md:mb-0 text-smaller"
          // onClick={onInputClick}
        />

        <div className="md:border-l md:px-8 flex-1 align-middle text-smaller lg:text-sm">
          <p>
            You can upload any .csv
            {/* .tsv, .xls, .xlsx, .xml, .txt  */} file with any set of columns
            as long as it has 1 record per row. The next step will allow you to
            match your spreadsheet columns to the right data points. You'll be
            able to clean up or remove any corrupted data before finalizing your
            report.
          </p>
        </div>
      </div>
      <ImportMappingModal open={open} onClose={onClose} fileData={fileData} />
    </Wrapper>
  )
}

export default Import
