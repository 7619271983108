import React, { useState, useLayoutEffect, useImperativeHandle } from 'react'
import { CustomPopper, LoadingButton } from 'global/globalComponents'
import { CheckBoxField } from 'global/globalComponents'
import staticColumns from './columns'
import { useEffect } from 'react'
import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import clsx from 'clsx'

const SelectColumnsPopup = React.forwardRef(
  (
    {
      anchorEl,
      handleClose,
      toggleColumnsVisibility,
      hiddenCols,
      projectCols,
      saveView,
      handleSaveView
    },
    ref
  ) => {
    const [colOptions, setColOption] = useState([])
    const [projectColOptions, setProjectColOptions] = useState([])
    const [openProjectOptions, setOpenProjectOptions] = useState(false)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
      setLoading: setLoading
    }))

    useLayoutEffect(() => {
      handleSetColumns()
    }, [hiddenCols, projectCols])

    const handleSetColumns = () => {
      setColOption(
        staticColumns
          .filter((item) => item.label.toLowerCase().includes(search))
          .map((item) => ({
            ...item,
            show: !hiddenCols.includes(item.accessor)
          }))
      )

      setProjectColOptions(
        projectCols
          .filter((item) => item.label.toLowerCase().includes(search))
          .map((item) => ({
            ...item,
            show: !hiddenCols.includes(item.accessor)
          }))
      )
    }

    useEffect(() => {
      let timer = setTimeout(() => {
        if (search) {
          setColOption((prev) =>
            prev.filter((item) => item.label.toLowerCase().includes(search))
          )
          setProjectColOptions((prev) =>
            prev.filter((item) => item.label.toLowerCase().includes(search))
          )
        } else {
          handleSetColumns()
        }
      }, [300])

      return () => clearTimeout(timer)
    }, [search])

    const toggleOpenProjectOptions = () => {
      setOpenProjectOptions((prev) => !prev)
    }

    const handleSearch = (e) => setSearch(e.target.value)

    return (
      <CustomPopper anchorEl={anchorEl} onClose={handleClose}>
        <div
          className="py-4 rounded-xl overflow-y-auto overflow-x-hidden"
          style={{ width: 250, maxHeight: 415 }}
        >
          <header className="px-4 mb-2">
            {openProjectOptions ? (
              <>
                <div className="flex items-center justify-between mb-2">
                  <button
                    className="flex items-center"
                    onClick={toggleOpenProjectOptions}
                  >
                    <ChevronRight className="rotate-180" />
                    <span className="ml-1 text-sm font-bold">Back</span>
                  </button>
                  <button
                    className={clsx(
                      'text-primary-main text-sm font-semibold',
                      !saveView && 'opacity-60 cursor-default'
                    )}
                    disabled={!saveView}
                    onClick={handleSaveView}
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </div>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  className="w-full px-2 py-1 border rounded text-sm"
                  placeholder="Search"
                  autoFocus
                />
              </>
            ) : (
              <>
                <div className="flex items-center justify-between mb-2">
                  <h5 className="text-sm font-bold">Select Columns</h5>
                  <button
                    className={clsx(
                      'text-primary-main text-sm font-semibold',
                      !saveView && 'opacity-60 cursor-default'
                    )}
                    disabled={!saveView}
                    onClick={handleSaveView}
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                </div>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearch}
                  className="w-full px-2 py-1 border rounded text-sm"
                  placeholder="Search"
                  autoFocus
                />
              </>
            )}
          </header>

          <ul className="px-4">
            {openProjectOptions && (
              <li>
                <CheckBoxField
                  name="toggle column"
                  onChange={(e) => {
                    toggleColumnsVisibility('allProjects', e.target.checked)
                  }}
                  checked={projectColOptions.every((item) => item.show)}
                  label="ALL"
                />
              </li>
            )}

            {!openProjectOptions
              ? colOptions.map((item) => (
                  <li key={item.accessor}>
                    <CheckBoxField
                      name="toggle column"
                      onChange={(e) => {
                        toggleColumnsVisibility(item.accessor, e.target.checked)
                      }}
                      checked={item.show}
                      label={item.label.toUpperCase()}
                    />
                  </li>
                ))
              : projectColOptions.map((item) => (
                  <li key={item.accessor}>
                    <CheckBoxField
                      name="toggle column"
                      onChange={(e) => {
                        toggleColumnsVisibility(item.accessor, e.target.checked)
                      }}
                      checked={item.show}
                      label={item.label.toUpperCase()}
                    />
                  </li>
                ))}

            {!openProjectOptions && (
              <li
                className="flex items-center text-sm text-gray-700 py-2"
                style={{ marginLeft: -2 }}
              >
                <span>
                  {projectColOptions.some((item) => item.show) ? (
                    <CheckBox
                      fontSize="small"
                      color="blue"
                      style={{ fill: 'blue' }}
                    />
                  ) : (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      className="opacity-40"
                    />
                  )}
                </span>
                <div
                  role="button"
                  className="font-medium flex items-center flex-1 cursor-pointer"
                  onClick={toggleOpenProjectOptions}
                  style={{ marginLeft: 9 }}
                >
                  <span>Projects</span>
                  <ChevronRight className="ml-auto" />
                </div>
              </li>
            )}
          </ul>
        </div>
      </CustomPopper>
    )
  }
)

export default SelectColumnsPopup
