import axios from 'axios'
import { errorMessages } from 'utils'

export const updateResourceManagementData = async (
  agencyOwerId,
  data,
  callback
) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/agencyprofile/agency/resources/${agencyOwerId}`,
      data
    })

    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
