import axios from 'axios'
import { errorMessages } from 'utils'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { postTaskLog } from 'thunks/logs/action'
/**
 * Create project invoice
 * @param {Object} data
 * @param {Function} callback function
 */
export const createCustomInvoice = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/custominvoice',
        method: 'POST',
        data
      })

      if (callback) callback(res.data)
      dispatch(
        createAccountLogs({
          description: `created an invoice for client <strong>${data?.client.clientName}</strong> in the project <strong>${data.workspaceName}</strong>`,
          category: 'workspaceInvoice',
          invoice: res.data._id,
          method: 'post'
        })
      )
      dispatch(
        postTaskLog({
          description: `created invoice for client`,
          activityType: 'assignment',
          workSpace: data.workspace,
          assignedUser: data?.client.clientId,
          category: 'workspaceInvoice'
        })
      )
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Get project invoices
 * @param {String} Id workspace id
 * @param {Function} callback function
 */
export const getProjectInvoices = async (wsId, callback) => {
  try {
    const res = await axios({
      url: `/custominvoice/workspace/${wsId}`,
      method: 'GET'
    })

    if (callback) callback(res.data)
  } catch (err) {
    if (callback) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Update invoice
 * @param {String} Id invoice id
 * @param {Object} Data
 * @param {Function} callback function
 */
export const updateInvoice = (invoiceId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/customInvoice/${invoiceId}`,
        method: 'PUT',
        data
      })

      if (callback) callback(res.data)
      dispatch(
        createAccountLogs({
          description: `updated an invoice for client <strong>${res.data?.client.clientName}</strong> in the project <strong>${res.data.workspaceName}</strong>`,
          category: 'workspaceInvoice',
          invoice: res.data._id,
          method: 'put'
        })
      )
      dispatch(
        postTaskLog({
          description: `updated invoice for client`,
          activityType: 'assignment',
          workSpace: data.workspace,
          assignedUser: data?.client.clientId,
          category: 'workspaceInvoice'
        })
      )
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * GET Invoice by id (PUBLIC API)
 * @param {String} id invoice id
 * @param {String} token
 * @param {Function} callback function
 */
export const getPublicInvoice = async (invoiceId, token, callback) => {
  try {
    const res = await axios({
      url: `/custominvoice/view/checkout/${invoiceId}/${token}`,
      method: 'GET'
    })

    if (callback) callback(res.data)
  } catch (err) {
    if (callback) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Invoice Payment
 * @param {Object} data payment data
 * @param {Function} callback function
 */
export const invoicePayment = async (data, callback) => {
  try {
    const res = await axios({
      url: '/custominvoice/pay',
      method: 'POST',
      data
    })

    if (callback) callback(res.data)
  } catch (err) {
    if (callback) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Send Invoice Email
 * @param {String} id invoice id
 * @param {data} body message subject
 * @param {Function} callback function
 */
export const sendInvoiceEmail = async (invoiceId, data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/customInvoice/sendmail/${invoiceId}`,
      data
    })
    if (callback) callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Mark Invoice as Paid
 * @param {String} id invoice id
 * @param {Function} callback function
 */
export const markAsPaid = async (invoiceId, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/custominvoice/markAsPaid/${invoiceId}`
    })
    if (callback) callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch Client project invoices
 * @param {String} Id client id
 * @param {String} Id Workspace (project) id
 * @param {Function} callback function
 */
export const fetchClientInvoices = async (clientId, wsId, callback) => {
  try {
    const res = await axios({
      url: `/custominvoice/client/${clientId}/${wsId}`,
      method: 'GET'
    })

    if (callback) callback(res.data)
  } catch (err) {
    if (callback) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Fetch info about payment (whether the payment is done or not)
 * @param {String} Id invoice id
 * @param {String} paymentIntent paymentIntent id
 * @param {Function} callback function
 */
export const fetchPaymentInfo = async (invoiceId, paymentIntent, callback) => {
  try {
    const res = await axios({
      url: `/custominvoice/checkpaymentstatus/${invoiceId}/${paymentIntent}`,
      method: 'GET'
    })

    if (callback) callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}
