import { useEffect, useMemo, useRef, useState } from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import PinterestIcon from '@material-ui/icons/Pinterest'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import ct from 'countries-and-timezones'
import Select from 'react-select'
import Countries from '../../../static/countries/Countries'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { whitelabel } from 'thunks/whiteLabel/action'
import userRoles from 'utils/userRoles'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import { LoadingButton } from 'global/globalComponents'
// import { Create } from '@material-ui/icons'
import updateDocumentTitle from 'utils/updateDocumentTitle'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
// import { getAuthToken } from 'utils'
// import SectionHeading from 'global/globalComponents/SectionHeading/SectionHeading'
import { useBreadcrumbs } from 'customHooks'
import PersonAvatar from 'global/globalComponents/PersonAvtar/PersonAvtar'
import ImageUploadPopover from 'global/globalComponents/ImageUploadPopover/ImageUploadPopover'
import settingStyles from '../settingStyles'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import Currency from 'static/Currency/Currency'
import { ME } from 'thunks/me/reducers'

const defaultImage =
  'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'

const General = props => {
  const classes = settingStyles()
  const me = useSelector(state => state.me.data)
  const dispatch = useDispatch()
  const imageUploadPopoverAnchorEl = useRef(null)
  const [imageUploadPopoverStatus, setImageUloadPopoverStatus] = useState(false)
  const [savingLoading, setSavingLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [formData, setFormData] = useState({
    defaultCurrency: { label: 'USD', value: 'usd' },
    brand: {
      favicon: '',
      largeLogo: '',
      smallLogo: '',
      brandName: ''
    },
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    defaultTimeZone: '',
    facebookurl: '',
    linkedinUrl: '',
    twitterurl: '',
    pinteresturl: ''
  })

  let agencyOwner = useMemo(() => {
    if (me.role === userRoles.USER_AGENCY) {
      return me._id
    } else {
      return me.team?._id ?? me.team
    }
  }, [me._id])

  const country = []
  // const [imageUploadPopoverAnchorEl, setImageUploadPopoverAnchorEl] = useState(null)
  const handleImageUploadPopover = () => {
    // console.log('===========')
    setImageUloadPopoverStatus(prev => !prev)
  }

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'General',
      link: `/settings/general`,
      index: 1
    }
  ])

  useEffect(() => {
    const countries = ct.getAllCountries()
    let arr = []
    Object.values(countries).forEach(country => {
      arr = arr.concat(country.timezones)
    })
    if (arr.length > 0) {
      setOptions(
        [...new Set(arr)].sort().map(el => (el = { value: el, label: el }))
      )
    }
  }, [])

  useEffect(() => {
    updateDocumentTitle(
      me?.profile?.brand?.brandName,
      me?.profile?.brand?.favicon
    )
    setFormData(prev => ({
      ...prev,
      defaultCurrency: {
        label:
          me.profile?.defaultCurrency?.toUpperCase() ??
          me.team?.profile?.defaultCurrency?.toUpperCase() ??
          'USD',
        value:
          me.profile?.defaultCurrency ??
          me.team?.profile?.defaultCurrency ??
          'usd'
      },
      brand:
        me.role === userRoles.USER_AGENCY
          ? {
              ...me.profile.brand
              // brandName: me?.profile?.brand?.brandName ?? '',
              // favicon: `${me?.profile?.brand?.favicon ?? ''}`,
              // largeLogo: `${me?.profile?.brand?.largeLogo ?? ''}`,
              // smallLogo: `${me?.profile?.brand?.smallLogo ?? ''}`
            }
          : { ...me.team.profile.brand },
      address:
        me.role === userRoles.USER_AGENCY
          ? {
              ...me?.profile.address,
              country: me.profile.address?.country ?? {}
              // line1: me?.profile?.address?.line1,
              // line2: me?.profile?.address?.line2,
              // city: me?.profile?.address?.city,
              // state: me?.profile?.address?.state,
              // zipCode: me?.profile?.address?.zipCode,
            }
          : {
              ...me?.team?.profile.address,
              country: me.team?.profile.address?.country ?? {}
            },
      defaultTimeZone: {
        label:
          me.role === userRoles.USER_AGENCY
            ? me.profile.defaultTimeZone
            : me.team.profile.defaultTimeZone,
        value:
          me.role === userRoles.USER_AGENCY
            ? me.profile.defaultTimeZone
            : me.team.profile.defaultTimeZone
      },
      // defaultTimeZone: {
      //   value: me?.profile?.defaultTimeZone,
      //   label: me?.profile?.defaultTimeZone
      // },
      facebookurl: me.profile.facebookurl,
      linkedinUrl: me.profile.linkedinUrl,
      twitterurl: me.profile.twitterurl,
      pinteresturl: me.profile.pinteresturl
    }))
  }, [me])

  // useEffect(() => {
  //   dispatch(updateUserAcces(me.role))
  //   Countries.map((item) => {
  //     country.push(item)
  //   })
  //   //  console.log(country)
  // }, [])

  // useEffect(() => {
  //   updateDocumentTitle(
  //     me?.profile?.brand?.brandName,
  //     me?.profile?.brand?.favicon
  //   )
  // }, [me])

  useEffect(() => {
    let arr = Object.keys(Currency).map(el => ({
      value: el.toLowerCase(),
      label: el.toUpperCase()
    }))
    setCurrencyOptions(arr)
  }, [])

  const handleChange = e => {
    const { name, value } = e.target
    if (e.target.name === 'brandName') {
      var item = { ...formData }
      var items = { ...item.brand, brandName: e.target.value }
      item.brand = items
      setFormData(item)
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }))
    }
  }

  const timezoneHandler = e => {
    setFormData(prev => ({ ...prev, defaultTimeZone: e }))
  }

  const handleAddressChange = e => {
    const { name, value } = e.target
    let items = { ...formData }
    let item = items.address
    items.address = { ...item, [name]: value }
    setFormData(items)
  }

  const handleCurrencyChange = e => {
    setFormData(prev => ({ ...prev, defaultCurrency: e }))
  }

  const handleCountryChange = e => {
    let items = { ...formData }
    let item = items.address
    items.address = { ...item, country: e }
    // setFormData(items)

    setFormData(prev => ({
      ...prev,
      address: { ...prev.address, country: e }
    }))
  }

  const handleImageChange = value => {
    let items = { ...formData }
    let item = items.brand
    items.brand = {
      ...item,
      favicon: value,
      smallLogo: value,
      largeLogo: value
    }
    setFormData(items)
    // setFormData((prev) => ({...prev,file:value}))
  }

  const whitelabelCallback = (res, err) => {
    setSavingLoading(false)
    if (!err) {
      dispatch(fireSuccessToaster('Updated Successfully'))
      const updatedMeData = { ...me }
      if (me.role === userRoles.USER_AGENCY) {
        updatedMeData.profile = {
          ...updatedMeData.profile,
          address: res.address,
          brand: res.brand,
          defaultTimeZone: res.defaultTimeZone,
          defaultCurrency: res.defaultCurrency
        }
      } else {
        updatedMeData.team.profile = {
          ...updatedMeData.team.profile,
          address: res.address,
          brand: res.brand,
          defaultTimeZone: res.defaultTimeZone,
          defaultCurrency: res.defaultCurrency
        }
      }
      dispatch({ type: ME.UPDATE, payload: updatedMeData })
    } else {
      dispatch(fireErrorToaster(res?.response?.data?.message))
    }
  }

  const handleSave = () => {
    const { brandName: companyName } = formData.brand
    const { city, country, line1, state, zipCode } = formData.address
    const { defaultTimeZone } = formData
    if (
      !companyName ||
      !city ||
      !country.label ||
      !line1 ||
      !state ||
      !zipCode ||
      !defaultTimeZone
    ) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    setSavingLoading(true)
    dispatch(
      whitelabel(
        agencyOwner,
        {
          ...formData,
          defaultTimeZone: formData.defaultTimeZone.value,
          defaultCurrency: formData.defaultCurrency.value
        },
        whitelabelCallback
      )
    )
  }
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    dropdownIndicator: styles => ({
      ...styles,
      paddingTop: 7,
      paddingBottom: 7,
      height: '30px'
    })
  }

  const handleImageUploadFromSystem = e => {
    const file = e.target.files[0]
    const name = e.target.name
    const imgReader = new FileReader()
    imgReader.onloadend = function () {
      if (file) {
        setFormData(prev => ({
          ...prev,
          brand: {
            ...prev.brand,
            [name]: imgReader.result
          }
        }))
        dispatch(
          whitelabel(agencyOwner, {
            brand: {
              ...formData.brand,
              [name]: imgReader.result
            }
          })
        )
      }
    }
    imgReader.readAsDataURL(file)
  }

  return (
    <Wrapper>
      <SecondaryHeading className="mb-6 lg:mb-8">
        Company Information
      </SecondaryHeading>

      <form className="text-xs md:text-smaller lg:text-sm">
        <div className="">
          <div className="hidden">
            {['favicon', 'smallLogo', 'largeLogo'].map(i => (
              <input
                name={i}
                key={i}
                accept="image/*"
                style={{ display: 'none' }}
                id={i}
                multiple={false}
                type="file"
                onChange={handleImageUploadFromSystem}
              />
            ))}
          </div>

          <div className="flex gap-8 mb-6 lg:mb-8">
            <div>
              <FormLabel className="pb-2">Favicon</FormLabel>
              <label htmlFor="favicon">
                <PersonAvatar
                  img={
                    formData.brand?.favicon?.length
                      ? formData.brand.favicon
                      : defaultImage
                  }
                />
              </label>
            </div>
            <div>
              <FormLabel className="pb-2">Icon</FormLabel>
              <label htmlFor="smallLogo">
                <PersonAvatar
                  img={
                    formData.brand?.smallLogo?.length
                      ? formData.brand.smallLogo
                      : defaultImage
                  }
                />
              </label>
            </div>
            <div>
              <FormLabel className="pb-2">
                Logo{' '}
                <span style={{ color: 'grey', fontSize: '10px' }}>
                  (Icon + Text)
                </span>
              </FormLabel>
              <label htmlFor="largeLogo">
                <PersonAvatar
                  img={
                    formData.brand?.largeLogo?.length
                      ? formData.brand.largeLogo
                      : defaultImage
                  }
                />
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center space-x-4">
          <div className="w-full">
            <FormLabel htmlFor="brandName" required>
              Company Name
            </FormLabel>
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Enter your company name"
              name="brandName"
              inputProps={{ id: 'brandName' }}
              className="w-full"
              onChange={handleChange}
              value={formData.brand.brandName}
            />
          </div>

          <div className="w-full">
            <FormLabel required>Timezone</FormLabel>
            <Select
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              isSearchable={true}
              placeholder="Timezone"
              onChange={timezoneHandler}
              maxMenuHeight={350}
              value={formData.defaultTimeZone}
              options={options}
              // className="flex-grow"
              // className="col-span-4 md:col-span-2 text-sm w-full"
              styles={{ ...reactSelectCustomStyles, ...customStyles }}
            />
          </div>
        </div>

        <div className="mt-4">
          <FormLabel required htmlFor="companyName">
            Address
          </FormLabel>
          <div className="grid grid-cols-8 gap-4">
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Line 1"
              name="line1"
              inputProps={{ id: 'companyName' }}
              className="w-full col-span-4"
              value={formData.address.line1}
              onChange={handleAddressChange}
            />
            <TextField
              required
              placeholder="Line 2 (Optional)"
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              name="line2"
              value={formData.address.line2}
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="City"
              value={formData.address.city}
              name="city"
              className="col-span-4 md:col-span-2"
              onChange={handleAddressChange}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              value={formData.address.state}
              name="state"
              placeholder="State"
              className="col-span-4 md:col-span-2"
              onChange={handleAddressChange}
            />
            {/* <TextField
              required
              placeholder="Country"
              className="col-span-4 md:col-span-2"
            /> */}
            <Select
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              className="col-span-4 md:col-span-2 text-sm"
              placeholder="Country"
              isSearchable={true}
              options={Countries}
              value={formData.address.country}
              maxMenuHeight={250}
              onChange={handleCountryChange}
              menuPlacement="top"
              styles={{ ...reactSelectCustomStyles, ...customStyles }}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Zip Code"
              name="zipCode"
              value={formData.address.zipCode}
              className="col-span-4 md:col-span-2"
              onChange={handleAddressChange}
              type="text"
            />
          </div>
        </div>

        <div className="mt-4">
          <FormLabel>Set Currency</FormLabel>

          <Select
            // menuPlacement="top"
            styles={reactSelectCustomStyles}
            options={currencyOptions}
            onChange={handleCurrencyChange}
            value={formData.defaultCurrency}
            maxMenuHeight={150}
            placeholder="Select"
            className="border-0 border-none w-1/2"
          />
        </div>

        <div className="mt-4 hidden">
          <FormLabel required htmlFor="companyName">
            Social Media Profiles
          </FormLabel>
          <div className="grid grid-cols-8 gap-4">
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Facebook Page URL"
              name="facebookurl"
              className="col-span-8 lg:col-span-4"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FacebookIcon
                      className={classes.textFieldIcon}
                      fontSize="small"
                    />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              name="twitterurl"
              placeholder="Twitter URL"
              className="col-span-8 lg:col-span-4"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TwitterIcon
                      className={classes.textFieldIcon}
                      fontSize="small"
                    />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Linkedin Page URL"
              name="linkedinUrl"
              className="col-span-8 lg:col-span-4"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkedInIcon
                      className={classes.textFieldIcon}
                      fontSize="small"
                    />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              required
              disabled={[
                userRoles.AGENCY_VIEWER,
                userRoles.CLIENT_VIEWER
              ].includes(me.role)}
              placeholder="Pintrest Page URL"
              className="col-span-8 lg:col-span-4"
              name="pinteresturl"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PinterestIcon
                      className={classes.textFieldIcon}
                      fontSize="small"
                    />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
        <div className="text-right mt-12">
          <LoadingButton
            loading={savingLoading}
            disabled={[
              userRoles.AGENCY_VIEWER,
              userRoles.CLIENT_VIEWER
            ].includes(me.role)}
            onClick={handleSave}
            className="w-40"
          >
            Save
          </LoadingButton>
        </div>

        <ImageUploadPopover
          onClose={handleImageUploadPopover}
          open={imageUploadPopoverStatus}
          anchorEl={imageUploadPopoverAnchorEl.current}
          profileImageChange={handleImageChange}
        />
      </form>
    </Wrapper>
  )
}

export default General
