import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useMemo } from 'react'
import { format } from 'date-fns'
import StandupReportsTable from './StandupReportsTable'
import { fetchStandupsReport } from 'thunks/reports/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
// import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import { ColoredAvatars, GoBack, OutlinedButton } from 'global/globalComponents'
import DaysFilterPopup from '../PeoplePerformance/DaysFilterPopup'
import useReportsFunctions from '../useReportsFunctions'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import StatsPopper from './StatsPopper'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { useHistory, useRouteMatch } from 'react-router-dom'
import SectionHeading from 'global/globalComponents/SectionHeading'
import moment from 'moment'
// import { CustomTooltip } from 'global/globalComponents'
import { CSVLink } from 'react-csv'
import { ReactComponent as ImportIcon } from 'static/svg/import.svg'
import { dateAndTimeHelper } from 'utils'
import Currency from 'static/Currency/Currency'

// const date = new Date();
const StandupReports = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    loading: standupsSummaryLoading,
    fetched: standupsSummaryFetched,
    data: standupsSummary
  } = useSelector(state => state.reports.standupsSummary)

  const [timelineAnchorEl, setTimelineAnchorEl] = useState(null)
  const daysOptions = useMemo(
    () => [
      { label: 'Last 7 days', value: 'last7Days' },
      { label: 'Last 14 days', value: 'last14Days' },
      { label: 'Last 30 days', value: 'last30Days' },
      { label: 'Last 60 days', value: 'last60Days' },
      { label: 'Last 90 days', value: 'last90Days' }
    ],
    []
  )
  const [selectedDaysOption, setSelectedDaysOption] = useState({
    label: 'Last 7 days',
    value: 'last7Days'
  })
  const [timeline, setTimeline] = useState('')
  const {
    getTimelineQuery,
    getTrackedTime,
    getCostByTrackedTime
  } = useReportsFunctions()
  const [statsPopper, setStatsPopper] = useState({
    anchorEl: null,
    data: {
      stats: {},
      standups: []
    }
  })
  const [downloadData, setDownloadData] = useState([])
  const [standupsSummaryReport, setStandupsSummaryReport] = useState({
    dates: [],
    data: []
  })
  const csvRef = useRef()

  const getDaysByNum = num => {
    return new Array(num).fill('').reduce(
      (arr, val) => {
        let obj = arr[0]
        arr.unshift(
          new Date(obj.getFullYear(), obj.getMonth(), obj.getDate() - 1)
        )
        return arr
      },
      [new Date()]
    )
  }

  useEffect(() => {
    const daysCount = selectedDaysOption.value.match(/\d+/)[0]
    const dates = []

    let i = 0
    while (i <= daysCount) {
      dates.push(
        moment(new Date())
          .subtract(daysCount - i, 'days')
          .toDate()
      )
      i++
    }

    const arr = []
    for (let userStandup of standupsSummary) {
      const obj = {
        user: {
          _id: userStandup._id,
          name: userStandup.name,
          profileImage: userStandup.profileImage,
          hourlyRate: userStandup.profile.hourlyRate,
          defaultCurrency: userStandup.profile.defaultCurrency
        },
        standups: userStandup.standups
      }

      arr.push(obj)
    }

    setStandupsSummaryReport({
      dates,
      data: arr
    })
  }, [standupsSummary, selectedDaysOption])

  useEffect(() => {
    if (downloadData.length) {
      csvRef.current.link.click()
    }
  }, [downloadData])

  const columns = useMemo(() => {
    const daysData = getDaysByNum(
      Number(selectedDaysOption.value.match(/\d+/)[0])
    )

    const colsArr = [
      {
        Header: 'User',
        accessor: 'user',
        Cell: StandupUserCell,
        sortType: (rowA, rowB, id, desc) => {
          let val1 = rowA.values[id].name.toLowerCase(),
            val2 = rowB.values[id].name.toLowerCase()

          if (val1 > val2) return 1
          else if (val2 > val1) return -1
          return 0
        }
      }
    ]

    for (let day of daysData) {
      colsArr.push({
        Header: () => (
          <div className="text-center">
            <span>
              {format(new Date(day), 'dd MMM')} ({format(new Date(day), 'eee')})
            </span>
          </div>
        ),
        accessor: new Date(day).toLocaleDateString(),
        Cell: StandupDateCell,
        disableSortBy: true
      })
    }

    return colsArr
  }, [selectedDaysOption])

  const data = useMemo(() => {
    const data = []

    for (let userStandups of standupsSummary) {
      const obj = {
        user: {
          _id: userStandups._id,
          name: userStandups.name,
          email: userStandups.email,
          profileImage: userStandups.profileImage
        }
      }

      for (let standup of userStandups.standups) {
        obj[new Date(standup.createdAt).toLocaleDateString()] = standup
      }

      data.push(obj)
    }

    return data
  }, [standupsSummary])

  useEffect(() => {
    if (!standupsSummaryFetched) {
      const query = getTimelineQuery('last7Days')
      dispatch(fetchStandupsReport(query))
    }
  }, [standupsSummaryFetched])

  useEffect(() => {
    if (timeline) {
      dispatch(fetchStandupsReport(timeline))
    }
  }, [timeline])

  const handleToggleTimelinePopup = useCallback(
    e => {
      if (timelineAnchorEl) setTimelineAnchorEl(null)
      else {
        setTimelineAnchorEl(e.currentTarget)
        e.stopPropagation()
      }
    },
    [timelineAnchorEl]
  )

  const handleSelectTimelineOption = sel => {
    const query = getTimelineQuery(sel.value)
    setSelectedDaysOption(sel)
    setTimeline(query)
  }

  const handleDownloadData = () => {
    const dataToDownload = []

    for (let userStandup of standupsSummaryReport.data) {
      const obj = {
        user: userStandup.user.name
      }

      for (let date of standupsSummaryReport.dates) {
        obj[moment(date).format('MMM Do')] = userStandup.standups.find(
          standup => {
            return (
              moment(standup.createdAt).format('MMM Do') ===
              moment(date).format('MMM Do')
            )
          }
        )
          ? 'Submitted'
          : ''
      }

      dataToDownload.push(obj)
    }
    setDownloadData(dataToDownload)
  }

  const handleDetailedSummaryDownload = () => {
    const dataToDownload = []
    let allStandupsData = []

    for (let i = 0; i < standupsSummaryReport.data.length; i++) {
      const { user, standups } = standupsSummaryReport.data[i]

      standups.forEach(standupObj => {
        const date = moment(standupObj.createdAt).format('Do MMM')

        standupObj.standups.forEach(item => {
          allStandupsData.push({
            userName: user.name,
            userId: user._id,
            hourlyRate: user.hourlyRate,
            defaultCurrency: user.defaultCurrency,
            standup: item,
            createdAt: date
          })
        })
      })
    }

    for (let i = 0; i < standupsSummaryReport.dates.length; i++) {
      const date = moment(standupsSummaryReport.dates[i]).format('Do MMM')
      const day = moment(standupsSummaryReport.dates[i]).format('dddd')
      const currentDateStandupsData = allStandupsData.filter(
        item => item.createdAt === date
      )

      if (!currentDateStandupsData.length) {
        dataToDownload.push({
          Date: date,
          Day: day
        })
      } else {
        for (let j = 0; j < currentDateStandupsData.length; j++) {
          let standup = currentDateStandupsData[j].standup
          let trackedTime = getTrackedTime(
            standup.task.timeTracking,
            currentDateStandupsData[j].userId
          )
          const cost = trackedTime
            ? getCostByTrackedTime({
                trackedTimeInMs: trackedTime,
                hourlyRate: currentDateStandupsData[j].hourlyRate
              })
            : ''

          dataToDownload.push({
            Date: j === 0 ? date : '',
            Day: j === 0 ? day : '',
            Standups: standup.name,
            User: currentDateStandupsData[j].userName,
            Completed: standup.isComplete ? 'Yes' : 'No',
            'Time Estimate': standup.task.estimateTimeInString,
            'Time Tracked': trackedTime
              ? dateAndTimeHelper.formatMilliseconds(trackedTime)
              : '',
            Cost: cost
              ? Currency[currentDateStandupsData[j].defaultCurrency ?? 'USD']
                  ?.symbol_native + cost
              : ''
          })
        }
      }
    }

    setDownloadData(dataToDownload)
  }

  return (
    <>
      <header className="mb-4">
        <GoBack className="mb-2" />
        <SectionHeading>Standups Summary</SectionHeading>
      </header>

      <div className="flex justify-end pr-4 mb-4">
        <div
          role="button"
          onClick={handleToggleTimelinePopup}
          className="cursor-pointer flex items-center text-smaller mr-4"
        >
          <CalendarIcon />
          <span className="font-medium ml-1 text-gray-600 text-xs">
            {selectedDaysOption.label}
          </span>
        </div>

        <OutlinedButton onClick={handleDownloadData}>
          <span>
            <ImportIcon className="w-4 h-4" />
          </span>
          <span className="ml-2">Download CSV</span>
        </OutlinedButton>

        <OutlinedButton
          onClick={handleDetailedSummaryDownload}
          className="ml-4"
        >
          <span>
            <ImportIcon className="w-4 h-4" />
          </span>
          <span className="ml-2">Download Detailed CSV</span>
        </OutlinedButton>

        <CSVLink
          ref={csvRef}
          data={downloadData}
          filename="Standups summary.csv"
        ></CSVLink>
      </div>
      <StandupReportsTable
        columns={columns}
        data={data}
        loading={standupsSummaryLoading}
        classes={classes}
        setStatsPopper={setStatsPopper}
        statsPopper={statsPopper}
      />

      <DaysFilterPopup
        anchorEl={timelineAnchorEl}
        daysOptions={daysOptions}
        onClose={handleToggleTimelinePopup}
        selectedDaysOption={selectedDaysOption}
        handleSelectTimelineOption={handleSelectTimelineOption}
      />
    </>
  )
}

const StandupDateCell = React.memo(({ value, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const standupsData = useMemo(() => {
    const obj = { total: 0, completed: 0 }

    if (value) {
      value.standups.reduce((obj, value) => {
        obj.total += 1
        if (value.isComplete) obj.completed += 1
        return obj
      }, obj)
    }

    obj.percentage = (obj.completed / obj.total) * 100

    return { stats: obj, standups: value ? value.standups : [] }
  }, [value])
  // const [hovered, setHovered] = useState(false)

  const handleMouseOver = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {Boolean(anchorEl) && (
        <StatsPopper
          anchorEl={anchorEl}
          data={standupsData}
          onClose={() => setAnchorEl(null)}
        />
      )}
      <div
        className={clsx(
          'flex justify-center items-center p-4 relative',
          value && Boolean(value.standups.length)
            ? 'bg-custom-green-light'
            : 'bg-custom-red-light',
          classes.dateCell
        )}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {value && Boolean(value.standups.length) ? (
          <CheckIcon className="w-6 h-6 text-green-400" />
        ) : (
          <CrossIcon className="w-6 h-6 text-red-400" />
        )}
      </div>
    </>
  )
})

const StandupUserCell = ({ value }) => {
  const history = useHistory()
  const { path } = useRouteMatch()

  const viewStandupDetails = () => {
    history.push(`${path}/${value._id}`)
  }

  return (
    <div
      className="p-4 cursor-pointer"
      role="button"
      onClick={viewStandupDetails}
    >
      <div className="flex items-center">
        <ColoredAvatars user={value} />
        <div className="ml-2 font-medium">{value.name}</div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  dateCell: {
    minWidth: 130
  }
})

export default StandupReports
