import React, { useEffect, useState } from 'react'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { Button } from '@material-ui/core'
import { CustomTooltip, LoadingButton } from 'global/globalComponents'
import { ReactComponent as Envelope } from 'static/svg/envelope.svg'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { ReactComponent as Link } from 'static/svg/link.svg'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import Currency from 'static/Currency/Currency'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { sendInvoiceEmail } from 'thunks/ProjectInvoices/actions'
import { format } from 'date-fns'
import { userRoles } from 'utils'
import WriteEmail from './WriteEmail'
import { Receipt } from '@material-ui/icons'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import EmailAddressPopper from './EmailAddressPopper'
import { updateCustomInvoice } from 'thunks/invoices/actions'

const SendInvoiceMailModal = ({
  open,
  handleClose,
  invoice = {},
  isReminder,
  updateInvoiceData
}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const dispatch = useDispatch()
  const currency = invoice.currency
  const [preview, setPreview] = useState(false)
  const [loading, setLoading] = useState(false)
  const meData = useSelector((state) => state.me.data)
  const largeLogo =
    meData.role === userRoles.USER_AGENCY
      ? meData.profile?.brand?.largeLogo
      : meData.team?.profile?.brand?.largeLogo
  const [subject, setSubject] = useState('')
  // const [recipients, setRecipients] = useState([])
  const [emailHTML, setEmailHTML] = useState('')
  const [recipients, setRecipients] = useState([
    { label: 'to', value: [], inputValue: '', show: true, btn: ['cc', 'bcc'] },
    { label: 'cc', value: [], inputValue: '', show: false, btn: ['bcc'] },
    { label: 'bcc', value: [], inputValue: '', show: false, btn: ['cc'] }
  ])
  const [emailPopper, setEmailPopper] = useState({
    anchorEl: null,
    list: [],
    key: 'cc'
  })

  const changeInvoiceClientData = (currentEmails, referenceEmails) => {
    if (currentEmails.length !== referenceEmails.length) return true
    for (let email of currentEmails) {
      if (!referenceEmails.includes(email)) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (invoice._id) {
      if (isReminder) {
        setSubject(
          `You have an outstanding invoice from ${invoice.agency.agencyCompanyName}`
        )
        setEmailHTML(getDefaultReminderMessage({ invoice, currency }))
      } else {
        setSubject(`${invoice.agency.agencyCompanyName} sent you an invoice`)
        setEmailHTML(getDefaultEmailMessage({ invoice, currency }))
      }
    }
  }, [invoice])

  useEffect(() => {
    if (invoice.client) {
      const to = [invoice.client.clientEmail]
      const cc = invoice.client.client_cc_email ?? []
      const bcc = invoice.client.client_bcc_email ?? []

      const btns = []
      if (!cc.length) {
        btns.push('cc')
      }
      if (!bcc.length) {
        btns.push('bcc')
      }

      setRecipients([
        {
          label: 'to',
          value: to,
          inputValue: '',
          show: true,
          btn: btns
        },
        {
          label: 'cc',
          value: cc,
          inputValue: '',
          show: cc.length ? true : false,
          btn: []
        },
        {
          label: 'bcc',
          value: bcc,
          inputValue: '',
          show: bcc.length ? true : false,
          btn: []
        }
      ])
    }
  }, [invoice.client, open])

  const handleSubjectChange = (e) => {
    setSubject(e.target.value)
  }

  const handleChangeTab = (e) => {
    const index = e.currentTarget.dataset.index

    if (!isNaN(Number(index))) {
      setSelectedTab(+e.currentTarget.dataset.index)
    }
  }

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/i/${invoice._id}/checkout?vtID=${invoice.viewToken}`
    )
    dispatch(fireSuccessToaster('Link copied!'))
  }

  const toggleEmailPreview = () => {
    setPreview((prev) => !prev)
  }

  const handleUpdateInvoice = () => {
    if (invoice.client) {
      const { value: ccEmail } = recipients.find((item) => item.label === 'cc')
      const { value: bccEmail } = recipients.find(
        (item) => item.label === 'bcc'
      )
      if (
        changeInvoiceClientData(
          ccEmail,
          invoice.client.client_cc_email ?? []
        ) ||
        changeInvoiceClientData(bccEmail, invoice.client.client_bcc_email ?? [])
      ) {
        setLoading(true)
        dispatch(
          updateCustomInvoice(
            invoice._id,
            {
              ...invoice,
              client: {
                ...invoice.client,
                client_cc_email: ccEmail,
                client_bcc_email: bccEmail
              }
            },
            (res, err) => {
              if (err) {
                setLoading(false)
                dispatch(fireErrorToaster(res))
              } else {
                handleSendEmail()
              }
            }
          )
        )
      } else {
        setLoading(true)
        handleSendEmail()
      }
    }
  }

  const handleSendEmail = () => {
    sendInvoiceEmail(
      invoice._id,
      {
        subject,
        message: emailHTML,
        mailType: isReminder ? 'reminder' : 'invoice'
      },
      sendEmailCallback
    )
  }

  const sendEmailCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      dispatch(fireSuccessToaster('Email sent!'))
      updateInvoiceData(res) //getting invoice object with the updated status
      handleModalClose()
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleAddRecipients = (key) => {
    setRecipients((prev) =>
      prev.map((item) => {
        if (key === item.label) {
          return { ...item, show: true }
        } else {
          return {
            ...item,
            btn: item.btn.filter((bt) => bt !== key)
            // btn: item.btn.filter((bt) =>
            //   item.label === 'to' ? false : bt !== key
            // )
          }
        }
      })
    )
  }

  const handleEmailChange = (e, label) => {
    setRecipients((prev) =>
      prev.map((item) => {
        if (item.label === label) {
          return { ...item, inputValue: e.target.value }
        } else {
          return item
        }
      })
    )
    if (/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,})+$/.test(e.target.value)) {
      handleEmailPopper(e, e.target.value, label)
    } else {
      handleClosePopper()
    }
  }

  const handleClickEvent = (e, val, label) => {
    if (/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,})+$/.test(val)) {
      handleEmailPopper(e, val, label)
    } else {
      handleClosePopper()
    }
  }

  const handleRemoveClick = (val, label) => {
    setRecipients((prev) =>
      prev.map((item) => {
        if (item.label === label) {
          return { ...item, value: item.value.filter((el) => el !== val) }
        } else {
          return item
        }
      })
    )
  }

  const handleAddRecipientsEmails = (e, val, label, popper = false) => {
    if (e.key === 'Enter' || popper) {
      if (!val.trim()) return
      if (!/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,})+$/.test(val)) {
        return dispatch(fireErrorToaster('Please enter valid email address!'))
      }
      setRecipients((prev) =>
        prev.map((item) => {
          if (item.label === label) {
            return {
              ...item,
              value: [...item.value, val],
              inputValue: ''
            }
          } else {
            return item
          }
        })
      )
      handleClosePopper()
    }
  }

  const handleEmailPopper = (e, val, key) => {
    e.stopPropagation()
    // if (!emailPopper.anchorEl ) {
    setEmailPopper({
      anchorEl: e.currentTarget,
      list: val ? [val] : [],
      key: key
    })
    // } else {
    //   setEmailPopper({
    //     anchorEl: null,
    //     list: [],
    //     key: 'cc'
    //   })
    // }
  }

  const handleClosePopper = (e) => {
    // e.stopPropagation()
    setEmailPopper({
      anchorEl: null,
      list: [],
      key: 'cc'
    })
  }

  const handleModalClose = () => {
    setRecipients([
      {
        label: 'to',
        value: [],
        inputValue: '',
        show: true,
        btn: ['cc', 'bcc']
      },
      { label: 'cc', value: [], inputValue: '', show: false, btn: ['bcc'] },
      { label: 'bcc', value: [], inputValue: '', show: false, btn: ['cc'] }
    ])
    handleClose()
  }

  return (
    <CustomModal open={open} handleClose={handleModalClose} size="medium">
      <CustomModalHeader
        heading="Send Invoice"
        handleClose={handleModalClose}
      />

      <CustomModalBody>
        {/* ---------- Button Tabs --------- */}
        <div className="space-x-4">
          <div
            role="button"
            tabIndex={0}
            className={clsx(
              'px-5 py-2 border inline-flex space-x-2 items-center rounded cursor-pointer',
              selectedTab === 0
                ? 'text-primary-main border-primary-main'
                : 'text-gray-700 border-gray-700'
            )}
            data-index="0"
            onClick={handleChangeTab}
          >
            <Envelope className="text-xl" />
            <span>Send Email</span>
          </div>

          <div
            role="button"
            tabIndex={0}
            className={clsx(
              'px-5 py-2 border inline-flex space-x-2 items-center rounded cursor-pointer',
              selectedTab === 1
                ? 'text-primary-main border-primary-main'
                : 'text-gray-700 border-gray-700'
            )}
            data-index="1"
            onClick={handleChangeTab}
          >
            <Link />
            <span>Send via URL</span>
          </div>
        </div>

        <hr className="my-6" />

        {selectedTab === 0 ? (
          <div>
            {recipients
              .filter((item) => item.show)
              .map((item, ind) => (
                <div
                  className="flex space-x-2 px-2 py-1.5 border border-gray-300 rounded mb-2"
                  key={item.label}
                >
                  <span className="w-6 text-sm text-gray-700 rounded-l capitalize">
                    {item.label}
                  </span>

                  <div className="w-full h-auto flex flex-1 justify-between">
                    <div className="w-full flex  space-x-2 items-center">
                      {item.value.map((email) => (
                        <div className="flex items-center space-x-2 whitespace-nowrap text-xs text-gray-500 py-0.5 px-2 bg-gray-200 rounded-full">
                          <span>{email}</span>
                          {item.label !== 'to' && (
                            <button
                              onClick={() =>
                                handleRemoveClick(email, item.label)
                              }
                              className={`bg-white rounded-full z-10`}
                            >
                              <CancelRoundedIcon fontSize="small" />
                            </button>
                          )}
                        </div>
                      ))}

                      <input
                        type="text"
                        value={item.inputValue}
                        onChange={(e) => handleEmailChange(e, item.label)}
                        disabled={item.label === 'to' ? true : false}
                        className=" flex-1 bg-white text-sm text-gray-500 rounded"
                        onKeyDown={(e) =>
                          handleAddRecipientsEmails(
                            e,
                            e.target.value,
                            item.label
                          )
                        }
                        onClick={(e) =>
                          handleClickEvent(e, item.inputValue, item.label)
                        }
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      {item.btn.map((el) => (
                        <CustomTooltip
                          title={`Add ${el === 'cc' ? 'Cc' : 'Bcc'} recipients`}
                        >
                          <button
                            className="text-gray-500 text-sm hover:border-b capitalize"
                            onClick={() => handleAddRecipients(el)}
                          >
                            {el}
                          </button>
                        </CustomTooltip>
                      ))}
                    </div>
                  </div>
                </div>
              ))}

            <div className="mb-6">
              <div className="flex border border-gray-300 rounded">
                <span className="px-3 py-1.5 text-sm text-gray-700 bg-gray-50 rounded-l">
                  Subject
                </span>
                <input
                  value={subject}
                  onChange={handleSubjectChange}
                  placeholder="Enter subject"
                  className="w-full px-2 py-1.5 bg-white text-sm text-gray-500 rounded"
                />
              </div>
              {/* <TextField
                value={subject}
                onChange={handleSubjectChange}
                placeholder="Enter subject"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Subject</InputAdornment>
                  )
                }}
                className={clsx('w-full', classes.textField)}
              /> */}
            </div>

            <div>
              {!preview ? (
                <div className="invoice-email">
                  <React.Suspense fallback={''}>
                    <WriteEmail
                      emailHTML={emailHTML}
                      setEmailHTML={setEmailHTML}
                    />
                  </React.Suspense>
                </div>
              ) : (
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getEmailBody({
                        largeLogo,
                        emailHTML,
                        redirectLink: `${window.location.protocol}//${window.location.host}/i/${invoice._id}/checkout?vtID=${invoice.viewToken}`
                      })
                    }}
                  ></div>
                </div>
              )}
              <button
                onClick={toggleEmailPreview}
                className="flex items-center text-primary-main mt-3 font-semibold"
              >
                <Envelope />
                <span className="ml-1 text-xs">
                  {!preview ? 'Show Email Preview' : 'Hide Preview'}
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center py-6">
            <p className="font-semibold text-base mb-2">
              Copy & send this link to your client to pay.
            </p>
            <p className="font-semibold text-base mb-2">
              Remember, anyone with this link can view and pay the invoice.
            </p>
            <div className="mt-4 flex rounded-md shadow-sm">
              <div
                onClick={copyLink}
                role="button"
                className="cursor-pointer inline-flex items-center px-4 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-700 text-xs tracking-wider"
              >
                <span>COPY THIS LINK</span>
                <ArrowRightIcon />
              </div>
              <input
                value={`${window.location.protocol}//${window.location.host}/i/${invoice._id}/checkout?vtID=${invoice.viewToken}`}
                type="text"
                className="flex-1 min-w-0 block w-full px-3 py-3 rounded-none rounded-r-md border sm:text-sm bg-white"
                disabled
              />
            </div>
          </div>
        )}
      </CustomModalBody>

      {selectedTab === 0 && (
        <CustomModalFooter>
          <Button type="button" variant="outlined" onClick={handleModalClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            onClick={handleUpdateInvoice}
            // onClick={handleSendEmail}
            loading={loading}
          >
            Send Invoice
          </LoadingButton>
        </CustomModalFooter>
      )}
      <EmailAddressPopper
        anchorEl={emailPopper.anchorEl}
        onClose={handleClosePopper}
        // onClose={handleEmailPopper}
        list={emailPopper.list}
        handleDialogue={handleAddRecipientsEmails}
        type={emailPopper.key}
      />
    </CustomModal>
  )
}

const getEmailBody = ({ largeLogo, emailHTML, redirectLink }) => `
  <div class="invoice-email">
    <header>
      <img src=${largeLogo} alt="" class='invoice-email__logo' />
    </header>
    <div class="invoice-email__container">
      <div class="invoice-email__body">
        ${emailHTML}
      </div>
      <footer class="invoice-email__footer">
        <button class="invoice-email__link">View & Pay Invoice</button>
      </footer>
    </div>
  </div>
`

const getDefaultEmailMessage = ({ invoice, currency }) => {
  return `
    <h2>${invoice.agency.agencyCompanyName} sent you an invoice</h2>
    <p>You've received an invoice for ${Currency[currency]?.symbol_native}${
    invoice.totalAmount
  } from ${invoice.agency.agencyCompanyName}, due on ${format(
    new Date(invoice.dueDate),
    'MMMM, dd, yyyy'
  )}.</p>
    <p>Here's a link to view and pay the invoice.</p>
    <p>Please email me at ${invoice.agency.agencyEmail} with any questions.</p>
    <p>&nbsp;</p>
    <p>Thanks!</p>
    <p>${invoice.agency.agencyCompanyName}</p>
  `
}

const getDefaultReminderMessage = ({ invoice, currency }) => {
  return `
    <h2>You have an outstanding invoice.</h2>
    <p>This is a reminder that ${
      invoice.agency.agencyCompanyName
    } sent you an invoice for ${Currency[currency]?.symbol_native}${
    invoice.totalAmount
  }, due on ${format(new Date(invoice.dueDate), 'MMMM, dd, yyyy')} with ${
    Currency[currency]?.symbol_native
  }${invoice.totalAmount} to be paid.</p>

    <p>This is just a friendly reminder to take a look at the invoice I've sent. Please email me at ${
      invoice.agency.agencyEmail
    } with any questions.</p>
    <p>&nbsp;</p>
    <p>Thanks!</p>
  `
}

export default SendInvoiceMailModal
