import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'

const QuotaStatusCard = props => {
  const { title = '', total = 0, used = 0, Icon, helpText = '' } = props
  return (
    <div className="border border-custom-gray-light p-2 rounded-sm shadow-sm">
      <div className="flex items-center mb-1">
        <p className="font-medium text-smaller lg:text-sm mr-2">{title}</p>
        <QuestionMarkTooltip
          text={helpText}
          iconSize="small"
          direction="top"
          info={true}
        />
      </div>
      <div className="flex items-center">
        <p className="mr-4">
          <span className="text-sm lg:text-base">{used}/</span>
          <span className="text-smaller lg:text-sm font-medium">
            {parseInt(total, 10) >= 999 ? 'Unlimited' : total}
          </span>
        </p>
        <Icon className="w-3 h-3 lg:w-4 lg:h-4" />
      </div>
    </div>
  )
}

export default QuotaStatusCard
