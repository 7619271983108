import { useEffect, useState } from 'react'
import { fetchAgencyPlan } from 'thunks/agencyPlan/actions'
import { useDispatch, useSelector } from 'react-redux'
import PlanCard from './PlanCard'
import { Skeleton } from '@material-ui/lab'
import { fetchCardDetails } from 'thunks/agencyPayment/actions'
import UpdateCardModal from 'components/Settings/Subscription/UpdateCardModal'
import { hideChatMessenger } from 'utils/manageChatMessenger'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { agencyUpgradePlan } from 'thunks/agencyPayment/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { loadStripe } from '@stripe/stripe-js'
import { fetchUserActivePlanInfo } from 'thunks/userActivePlanInfo/actions'
import { fetchMeData } from 'thunks/me/actions'
import clsx from 'clsx'

const PlanExpired = () => {
  const dispatch = useDispatch()
  const agencyPlans = useSelector((state) => state.agencyPlans)
  const [selectedTab, setSelectedTab] = useState(0)
  const [plans, setPlans] = useState({
    monthly: [],
    yearly: []
  })
  const [card, setCard] = useState({})
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)
  const [selectPlanAlert, setSelectPlanAlert] = useState({
    open: false,
    loading: false,
    data: null
  })
  const userActivePlan = useSelector((state) => state.userActivePlanInfo)
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

  useEffect(() => {
    hideChatMessenger()
  })

  useEffect(() => {
    fetchCardDetails(cardDetailsCallback)
    dispatch(fetchAgencyPlan())
  }, [])

  useEffect(() => {
    if (agencyPlans.data.length) {
      const monthly = []
      const yearly = []
      const allPlans = [...agencyPlans.data]

      allPlans.forEach((plan) => {
        if (plan.interval === 'month') monthly.push(plan)
        else yearly.push(plan)
      })

      setPlans({
        monthly: monthly.sort((a, b) => a.amount - b.amount),
        yearly: yearly.sort((a, b) => a.amount - b.amount)
      })
    }
  }, [agencyPlans.data])

  const toggleCardModal = () => {
    setIsCardModalOpen((prev) => !prev)
  }

  const cardDetailsCallback = (res, err) => {
    if (!err) {
      setCard(res)
    }
  }

  const handleTabChange = (e) => {
    const newVal = e.target.dataset.tab
    setSelectedTab(Number(newVal))
  }

  const handleSelectPlan = (plan) => {
    if (Object.keys(card).includes('last4')) {
      setSelectPlanAlert({ loading: false, open: true, data: plan })
    } else {
      toggleCardModal(true)
    }
  }

  const updatePlan = () => {
    setSelectPlanAlert((prev) => ({ ...prev, loading: true }))
    agencyUpgradePlan(selectPlanAlert.data, updatePlanCallback)
  }

  const updatePlanCallback = async (res, err) => {
    if (err) {
      setSelectPlanAlert((prev) => ({ ...prev, loading: false }))
      dispatch(fireErrorToaster(res))
    } else {
      // If user has no plan selected then redirect to Stript Payment
      if (userActivePlan?.data?.mode === 'manual') {
        const stripe = await stripePromise
        await stripe.redirectToCheckout({
          sessionId: res.id
        })
      }
      dispatch(fetchUserActivePlanInfo(userActivePlanInfoCallback))
    }
  }

  const userActivePlanInfoCallback = (res, err) => {
    setSelectPlanAlert((prev) => ({ ...prev, loading: false }))
    if (!err) {
      dispatch(fireSuccessToaster('Your plan has been changed successfully!'))
      window.location.reload(true)
    }
  }

  const closeSelectPlanAlert = () => {
    setSelectPlanAlert((prev) => ({ ...prev, open: false, loading: false }))
  }

  return (
    <div
      className="mx-auto py-12 px-4 sm:px-6 lg:px-8"
      style={{ maxWidth: 1000 }}
    >
      <div className="sm:flex sm:flex-col sm:align-center">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-center">
          Your Free Trial Has Ended
        </h1>
        <p className="mt-2 text-gray-500 sm:text-center">
          Please select a plan to continue using Clientvenue.
        </p>
        <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
          <button
            type="button"
            className={clsx(
              'relative w-1/2 border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8',
              selectedTab === 0 && 'bg-white'
            )}
            data-tab="0"
            onClick={handleTabChange}
          >
            Monthly billing
          </button>
          <button
            type="button"
            className={clsx(
              'ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8',
              selectedTab === 1 && 'bg-white'
            )}
            data-tab="1"
            onClick={handleTabChange}
          >
            Yearly billing
          </button>
        </div>
      </div>
      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
        {agencyPlans.loading ? (
          <PlanCardsLoading />
        ) : selectedTab === 0 ? (
          plans.monthly.map((item) => (
            <PlanCard
              key={item._id}
              plan={item}
              handleSelectPlan={handleSelectPlan}
            />
          ))
        ) : (
          plans.yearly.map((item) => (
            <PlanCard
              key={item._id}
              plan={item}
              handleSelectPlan={handleSelectPlan}
            />
          ))
        )}
      </div>

      <UpdateCardModal
        addingNewCard={!Object.keys(card).includes('last4')}
        open={isCardModalOpen}
        handleClose={toggleCardModal}
        callback={cardDetailsCallback}
      />

      <AlertModal
        heading="Plan Activation"
        alertType="confirmation"
        warningText={`Please click proceed if you want to subscribe ${selectPlanAlert.data?.nickname}`}
        open={selectPlanAlert.open}
        handleDialog={closeSelectPlanAlert}
        handleDeleteAction={updatePlan}
        loading={selectPlanAlert.loading}
        deleteBtnText="Proceed"
      />
    </div>
  )
}

const PlanCardsLoading = () => {
  return (
    <>
      {Array(3)
        .fill('')
        .map((item, index) => (
          <Skeleton
            key={index}
            variant="rect"
            height={300}
            className="w-full"
          />
        ))}
    </>
  )
}

export default PlanExpired
