import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Header, Sidebar } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
// import { store } from '../../store'
import clsx from 'clsx'
// import { setLeftTrialDays } from 'thunks/me/actions'
// import { fetchUser } from 'thunks/user/actions'
// import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import { checkLogin, hideChatMessenger, userRoles } from 'utils'
import {
  toggleEditTeammatesSidePanel,
  toggleAddTeammateSidePanel,
  toggleAddClientSidePanel,
  toggleEmbedModal
  // toggleCreateTaskPanel
  // toggleCreateTaskPanel
  // toggleLogsSidePanel
} from 'thunks/generlAppState/actions'
import { LOGIN } from 'thunks/login/reduers'
import useTitle from 'customHooks/useTitle'
import AddWorkSpaceSidePanel from 'components/UserDashboard/Workspaces/AddWorkspaceSidePanel'
import AddNewTeammatePanel from 'components/Teammates/AddNewTeammatePanel'
import AddNewClient from 'components/Clients/AddNewClient'
import WorkspacesPusherWrapper from 'components/Pusher/WorkspacesPusherWrapper'
// import SideDrawerNew from 'components/SideDrawer/SideDrawerNew'
import { fetchPermissions } from 'thunks/permission/actions'
// import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
// import PoweredBy from 'components/PoweredBy/PoweredBy'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import ErrorBoundary from 'global/globalComponents/ErrorBoundary/ErrorBoundary'
import AccessDenied from 'components/Opening/AccessDenied'
// import { ME } from 'thunks/me/reducers'
// import { beamerInit } from 'utils'
import NewTeammateModal from 'components/Workspace/NewTeammateStructure/NewTeammateModal'
import NewClientModal from 'components/Workspace/NewTeammateStructure/NewClientModal'
import {
  intercomConnect,
  dashlyConnect,
  crispConnect,
  facebookPixelConnect
} from 'utils'
import { fetchAllIntegrationsForCurrentUser } from 'thunks/integration/actions'
import ErrorBoundary from 'global/globalComponents/ErrorBoundary/ErrorBoundary'
import dashboardStyles from './dashboardStyles'
// import AccountLogs from 'components/UserDashboard/AccountLogs/AccountLogs'
import PlanExpired from 'components/PlanExpired/PlanExpired'
// import { Loader } from 'global/globalComponents'
import NotificationsPusherWrapper from 'components/AccountLogs/Pusher/NotificationsPusherWrapper'
import EmbedModal from 'components/EmbedModal/EmbedModal'
import { useHistory, useLocation } from 'react-router'
import { getUserPath } from 'utils/userRoles'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
// import CreateTaskPanel from 'components/CreateTaskPanel/CreateTaskPanel'
import { postUserActivityLogs } from 'thunks/pulse/action'
import PulsePusherWrapper from 'components/Pusher/PulsePusherWrapper'
import { fetchMyApprovals } from 'thunks/approvals/actions'
import AccountBlockedModal from 'components/Header/AccountBlockedModal'
import { fetchAllAgencyInvoices } from 'thunks/agencyPayment/actions'

const fetchConnectedApps = async (meData, cb) => {
  const agencyOwnerId = meData.role === 30 ? meData.team._id : meData.agency

  if (agencyOwnerId) {
    try {
      const res = await axios({
        method: 'GET',
        url: `connectedapps/client/${agencyOwnerId}`
      })

      cb(res.data)
    } catch (err) {
      cb(err?.response?.data?.message ?? 'some error occurred', true)
    }
  }
}

const Dashboard = ({ children }) => {
  const addTeammate = useSelector(
    (state) => state.generalAppState?.sidePanel.addTeammate
  )
  // const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const addClient = useSelector(
    (state) => state.generalAppState.sidePanel.addClient
  )

  // const logsSidePanelStatus = useSelector(
  //   (state) => state.generalAppState.sidePanel.logsPanel
  // )
  const [payNowCondition, setpayNowCondition] = useState({
    payLink: null,
    blockedModal: false,
    data: {}
  })
  const classes = dashboardStyles({
    headerStripe: Boolean(payNowCondition.payLink)
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const [channelCreatedFlag, setChanneCreatedlFlag] = useState(false)
  const [pusherDisconnectedState, setPusherDisconnectedState] = useState(
    undefined
  )
  const me = useSelector((state) => state.me)
  const { loading, allInvoices: agencyInvoices } = useSelector(
    (state) => state.agencyPayment
  )
  const userIntegrations = useSelector((state) => state.userIntegrations)
  const [freeTrialOver, setFreeTrialOver] = useState(false)
  const [timerStarted, setTimerStarted] = useState(false)
  // const [loading, setLoading] = useState(true)
  // const leftTrialDays = me.leftTrialDays
  const isProjectsFetched = useSelector(
    (state) => state.userWorkspaces.fetchedFirstTime
  )

  useTitle(
    me.data.profile?.brand?.brandName ??
      me.data.team?.profile?.brand?.brandName,
    me.data.profile?.brand?.favicon ?? me.data.team?.profile?.brand?.favicon
  )

  // const handleDrawer = () => {
  //   dispatch(toggleSidebar())
  // }

  // const { loggedIn } = useSelector((state) => state.login)
  const userData = useSelector((state) => state.user.data)
  const { sidebarOpen, modal } = useSelector((state) => state.generalAppState)
  const { editTeammate: editTeammateSidePanelStatus } = useSelector(
    (state) => state.generalAppState.sidePanel
  )

  const unAuthorize =
    (me.data.role < userRoles.USER_CLIENT && pathname.startsWith('/client')) ||
    (me.data.role >= userRoles.USER_CLIENT && pathname.startsWith('/agency'))

  // useEffect(() => {
  //   if (loggedIn) {
  //     // Here i want loader to show up while me data is fetching that's why setting its loading property to true
  //     // dispatch({ type: ME.LOADING })
  //     if (!me.fetched) {
  //       dispatch(
  //         fetchMeData((res, err) => {
  //           if (!err) {
  //             setLoading(false)
  //           }
  //         })
  //       )
  //     } else {
  //       setLoading(false)
  //     }
  //   }
  // }, [loggedIn])

  useEffect(() => {
    if (me.fetched && !unAuthorize) {
      dispatch(fetchPermissions(fetchPermissionCallback))
      // dispatch(fetchTeammateOfCurrentUser())
      // dispatch(fetchWorkspacePermissions())

      if (
        !isProjectsFetched &&
        me.data.role !== userRoles.USER_AGENCY &&
        me.data.role !== userRoles.AGENCY_ADMIN
      ) {
        dispatch(fetchAllWorkspacesForCurrentUser())
      }
    }
  }, [me.fetched, unAuthorize])

  const fetchPermissionCallback = (res, err) => {
    if (err) {
      dispatch(
        fireErrorToaster(
          'Some error has been occurred during login. Please login again'
        )
      )
      // localStorage.removeItem('authToken')
      dispatch({ type: LOGIN.LOGOUT })
    }
  }

  useEffect(() => {
    if (!channelCreatedFlag) {
      window.pusherInstance = new window.Pusher(
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? '4a4b1295055bb008c6b4'
          : '9a342689f95378748dc0',
        {
          cluster:
            process.env.REACT_APP_ENVIRONMENT === 'production' ? 'mt1' : 'ap2'
        }
      )

      window.pusherInstance.connection.bind('disconnected', function () {
        // console.log('Dissconnected from pusher')
        setPusherDisconnectedState('Please reload to get updated data')
      })

      window.pusherInstance.connection.bind('unavailable', function () {
        // console.log('Dissconnected from pusher, unavailable')
        setPusherDisconnectedState('Please reload to get updated data')
      })

      setChanneCreatedlFlag(true)
    }
  }, [])

  useEffect(() => {
    const loginStatus = checkLogin()
    if (loginStatus) {
      if (pathname === '/') {
        if (Object.keys(userData).includes('path')) {
          history.push(userData.path)
        }
      }
      // history.push(props.location.path)
    } else {
      history.push('/login')
    }
  }, [pathname, userData])

  useEffect(() => {
    if (me.data.role) {
      if (
        ![
          userRoles.USER_AGENCY,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_ADMIN
        ].includes(me.data.role)
      ) {
        hideChatMessenger()
      }
    }
  }, [me.data.role])

  useEffect(() => {
    if (
      !userIntegrations?.fetchedFirstTime &&
      [
        userRoles.USER_AGENCY
        // userRoles.AGENCY_MANAGER,
        // userRoles.AGENCY_ADMIN
      ].includes(me.data?.role)
    ) {
      dispatch(fetchAllIntegrationsForCurrentUser())
    }
  }, [userIntegrations?.fetchedFirstTime, me.data.role])

  useEffect(() => {
    if (userIntegrations.data.length) {
      let app = userIntegrations.data?.find(
        (el) => el?.appName === 'facebook pixel'
      )
      facebookPixelConnect(app?.key)
    }
  }, [userIntegrations.data])

  useEffect(() => {
    if (me.data.role >= userRoles.USER_CLIENT) {
      fetchConnectedApps(me.data, (res, err) => {
        if (!err) {
          for (let item of res) {
            if (item.isActive) {
              switch (item.appName) {
                case 'intercom':
                  intercomConnect(item.key, me.data)
                  break
                case 'dashly':
                  dashlyConnect(item.key)
                  break
                case 'crisp':
                  crispConnect(item.key)
                  break
                default:
                  break
              }
            }
          }
        }
      })
    }
  }, [me.data.role])

  useEffect(() => {
    if (me.data.payment?.trial_end) {
      const leftTimeInMS = new Date(me.data.payment.trial_end) - new Date()
      if (leftTimeInMS <= 0) {
        setFreeTrialOver(true)
        // dispatch(setLeftTrialDays(0))
      } else {
        // dispatch(
        //   setLeftTrialDays(Math.floor(leftTimeInMS / (1000 * 60 * 60 * 24)))
        // )
      }
    } else {
      // dispatch(setLeftTrialDays(null))
    }
  }, [me.data.payment])

  useEffect(() => {
    if (
      [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(me.data.role) &&
      (me.data.payment?.status ?? me.data.team?.payment?.status) !== 'paid'
    ) {
      const newInvoice = agencyInvoices
        .slice(0, 2)
        .filter((invoice) => invoice.status !== 'paid')
      if (newInvoice.length) {
        if (newInvoice.length === 2) {
          setpayNowCondition({
            payLink: newInvoice[0].hosted_invoice_url,
            blockedModal: true,
            data: {}
          })
        } else {
          setpayNowCondition({
            payLink: newInvoice[0].hosted_invoice_url,
            blockedModal: false,
            data: {}
          })
        }
      } else {
        setpayNowCondition({
          payLink: null,
          blockedModal: true,
          data: {}
        })
      }
    } else {
      setpayNowCondition({
        payLink: null,
        blockedModal: false,
        data: {}
      })
    }
  }, [agencyInvoices, me.data])

  useEffect(() => {
    if (
      [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(me.data.role) &&
      (me.data.payment?.status ?? me.data.team?.payment?.status) !== 'paid'
    )
      dispatch(fetchAllAgencyInvoices())
  }, [me.data])

  useEffect(() => {
    dispatch(fetchMyApprovals())
  }, [])

  // useEffect(() => {
  //   const timer = setInterval(
  //     () => postUserActivityLogs({ source: window.location.href }),
  //     15 * 60 * 1000
  //   )
  //   return () => clearInterval(timer)
  // })

  useEffect(() => {
    // if(document.hidden) return  ////////////current tab is in view/focus
    if (timerStarted) return
    const timer = setInterval(() => {
      setTimerStarted(true)
      postUserActivityLogs({ source: window.location.href }, (res, err) => {})
    }, 15 * 60 * 1000)
    return () => {
      setTimerStarted(false)
      clearInterval(timer)
    }
  }, [timerStarted])

  useEffect(() => {
    postUserActivityLogs({ source: window.location.href })
  }, [])

  const handleCloseAddClientPanel = useCallback(() => {
    dispatch(toggleAddClientSidePanel())
  }, [])

  const handleCloseTeammatePanel = useCallback(() => {
    dispatch(toggleAddTeammateSidePanel())
  }, [])

  const handleCloseEmbedModal = useCallback(() => {
    dispatch(toggleEmbedModal(false))
  }, [])

  return (
    <>
      {unAuthorize ? (
        <AccessDenied />
      ) : (
        <ErrorBoundary>
          <NotificationsPusherWrapper>
            <PulsePusherWrapper>
              <WorkspacesPusherWrapper>
                <Box>
                  <Sidebar isDrawerOpen={sidebarOpen} />
                  <Box className={classes.bodyContainer}>
                    <Header
                      pusherMessage={pusherDisconnectedState}
                      payNowLink={payNowCondition.payLink}
                      // leftTrialDays={leftTrialDays}
                    />
                    <Box
                      className={clsx(classes.contentArea, {
                        sidebarExpanded: sidebarOpen
                      })}
                    >
                      {freeTrialOver ? (
                        <PlanExpired />
                      ) : payNowCondition.blockedModal ? (
                        <AccountBlockedModal
                          open={true}
                          payNowLink={payNowCondition.payLink}
                        />
                      ) : (
                        <div className="h-full flex flex-col justify-between">
                          <div className="flex-1">{children}</div>
                          {/* {me.data.isWhiteLabelActive === false &&
                            !pathname.includes(
                              `${getUserPath(me.data.role)}/workspace/`
                            ) && (
                              <div>
                                <PoweredBy />
                              </div>
                            )} */}
                        </div>
                      )}
                      {me.data.role < userRoles.USER_CLIENT ? (
                        <NewTeammateModal
                          open={editTeammateSidePanelStatus}
                          onClose={() =>
                            dispatch(toggleEditTeammatesSidePanel())
                          }
                        />
                      ) : (
                        <NewClientModal
                          open={editTeammateSidePanelStatus}
                          onClose={() =>
                            dispatch(toggleEditTeammatesSidePanel())
                          }
                        />
                      )}
                      <AddWorkSpaceSidePanel />
                      {/* <CreateTaskPanel
                      open={addTask.open}
                      project={addTask.data}
                      // open={true}
                      onClose={() => dispatch(toggleCreateTaskPanel(false))}
                    /> */}
                      <AddNewTeammatePanel
                        open={addTeammate.open}
                        onClose={handleCloseTeammatePanel}
                        aboveModal={addTeammate.aboveModal}
                        teammateData={addTeammate.data}
                      />
                      <AddNewClient
                        open={addClient.open}
                        onClose={handleCloseAddClientPanel}
                        inviteData={addClient.data}
                        aboveModal={addClient.aboveModal}
                      />
                      {modal.embedModal.open && (
                        <EmbedModal
                          onClose={handleCloseEmbedModal}
                          anchorPosFromLeft={modal.embedModal.anchorPos}
                        />
                      )}
                      {/* <AccountLogs
                    open={logsSidePanelStatus}
                    onClose={() => dispatch(toggleLogsSidePanel())}
                  /> */}
                    </Box>
                  </Box>
                </Box>
              </WorkspacesPusherWrapper>
            </PulsePusherWrapper>
          </NotificationsPusherWrapper>
        </ErrorBoundary>
      )}
    </>
  )
}

export default Dashboard
