import { useMemo } from 'react'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import Collaborator from './Collaborator'

const TaskCollaborators = ({
  taskState,
  triggerPopover,
  taskPermission,
  elmFor,
  removeCollaborator
}) => {
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const [loading, setLoading] = useState(false)
  const collaborators = useMemo(() => {
    if (taskState?.assignedAgencyTeam) {
      return [...taskState.assignedAgencyTeam, ...taskState.assignedClientTeam]
    }
    return []
  }, [taskState])

  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
  }, [collaborators.length])

  const handleRemove = (teammate) => {
    removeCollaborator({ userId: teammate._id, userRole: teammate.role })
  }

  return (
    <div className="flex flex-grow items-center justify-between">
      <div className="flex space-x-2 items-center ">
        {collaborators.map((teammate) => (
          <Collaborator
            type={elmFor}
            key={teammate._id}
            teammate={teammate}
            taskId={taskState._id}
            handleRemove={handleRemove}
            removeBtn={taskPermission.update}
          />
        ))}
        <button
          type="button"
          onClick={(e) => {
            if (currentWorkspace?.isComplete)
              dispatch(
                fireErrorToaster(
                  `${currentWorkspace?.name} is already complete and you can not add new teammates to any of its task.`
                )
              )
            else
              taskPermission?.update
                ? triggerPopover(e, 'collab')
                : dispatch(fireErrorToaster('You are not an authorized user'))
          }}
          className="flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
        >
          <span className="sr-only">Add team member</span>
          <Add style={{ fontSize: '16px' }} aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

export default TaskCollaborators
