import { useState, useEffect } from 'react'
import { Modal, Backdrop, Fade } from '@material-ui/core'
import AddProjectModal from 'components/UserDashboard/Workspaces/AddProjectModal'
import templateStyles from './templateStyles'
import TemplateLibrary from './TemplateLibrary'
import {
  deleteProjectTemplate,
  fetchAllProjectTemplates
} from 'thunks/projectTemplate/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { AlertModal } from 'global/globalComponents'
import CreateTemplateModal from './CreateTemplateModal'

const TemplatesModal = ({
  open,
  onClose,
  selectedClient = {},
  elmFor = 'workspace'
}) => {
  const classes = templateStyles()
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [templateData, setTemplateData] = useState([])
  const [allTemplateData, setAllTemplateData] = useState([])
  const [search, setSearch] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    startDate: '',
    dueDate: '',
    category: 'Internal',
    budgetedHours: '',
    assignedAgencyTeam: [],
    assignedClientTeam: {}
  })
  const [deleteTemplateModal, setDeleteTemplateModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [createModalOpen, setCreateModalOpen] = useState({
    open: false,
    data: {}
  })
  const [discardProjectDetails, setDiscardProjectDetails] = useState(false)

  useEffect(() => {
    if (selectedClient.value) {
      setFormData(prev => ({
        ...prev,
        category: 'External',
        assignedClientTeam: selectedClient
      }))
    }
  }, [selectedClient])

  const updateTemplateHandler = tempData => {
    setCreateModalOpen({
      open: true,
      data: tempData
    })
  }

  const handleClose = () => {
    setCreateModalOpen({ open: false, data: {} })
  }

  const handleDeleteTemplateModal = () => {
    setDeleteTemplateModal({
      open: false,
      data: '',
      loading: false
    })
  }

  const handleDeleteTemplate = () => {
    deleteProjectTemplate(deleteTemplateModal.id, (res, err) => {
      if (!err) {
        setAllTemplateData(allTemplateData.filter(temp => temp._id !== res._id))
        dispatch(fireSuccessToaster('Template Deleted Successfully'))
      } else {
        dispatch(fireErrorToaster(res))
      }
    })
    handleDeleteTemplateModal()
  }

  const deleteTemplateHandler = id => {
    setDeleteTemplateModal({
      open: true,
      id: id,
      loading: false
    })
  }

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    if (search?.trim()) {
      setTemplateData(
        allTemplateData.filter(temp =>
          temp.title.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else {
      setTemplateData(allTemplateData)
    }
  }, [search, allTemplateData])

  useEffect(() => {
    fetchAllProjectTemplates((res, err) => {
      if (!err) {
        setAllTemplateData(res)
      }
    })
  }, [])

  const updateDataHandler = (res, data) => {
    if (data.new) {
      setAllTemplateData(prev => [res, ...prev])
    } else {
      const updatedData = [...allTemplateData]
      const index = updatedData.findIndex(el => el._id === res._id)
      updatedData[index] = res
      setAllTemplateData(updatedData)
    }
  }

  const handleDiscardProjectDetails = action => {
    setDiscardProjectDetails(false)
    if (action) {
      setFormData({
        name: '',
        description: '',
        startDate: '',
        dueDate: '',
        category: 'Internal',
        budgetedHours: '',
        assignedAgencyTeam: [],
        assignedClientTeam: {}
      })
      setSelectedTemplate({})
      setStep(1)
      onClose()
    }
  }

  const handleModalClose = (key = 'in') => {
    if (key === 'out') {
      if (
        formData.name ||
        formData.description ||
        formData.assignedAgencyTeam.length ||
        formData.startDate ||
        formData.dueDate
      ) {
        setDiscardProjectDetails(true)
      } else {
        handleDiscardProjectDetails(true)
      }
    } else {
      handleDiscardProjectDetails(true)
    }
  }

  return (
    <>
      <Modal
        style={{ zIndex: 1250 }}
        open={open}
        onClose={() => handleModalClose('out')}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className={classes.modal}
      >
        <Fade in={open}>
          <div
            className={`h-full relative rounded-3xl ${classes.paper} overflow-visible`}
          >
            {step === 1 ? (
              <AddProjectModal
                step={step}
                setStep={setStep}
                onClose={handleModalClose}
                selectedTemplate={selectedTemplate}
                templateData={allTemplateData}
                formData={formData}
                setFormData={setFormData}
                setSelectedTemplate={setSelectedTemplate}
                category={formData.category}
                elmFor={elmFor}
              />
            ) : (
              <TemplateLibrary
                step={step}
                setStep={setStep}
                onClose={handleModalClose}
                setSelectedTemplate={setSelectedTemplate}
                templateData={templateData}
                updateDataHandler={updateDataHandler}
                handleSearchChange={handleSearchChange}
                deleteTemplateHandler={deleteTemplateHandler}
                updateTemplateHandler={updateTemplateHandler}
              />
            )}
          </div>
        </Fade>
      </Modal>
      <CreateTemplateModal
        open={createModalOpen.open}
        handleClose={handleClose}
        tempData={createModalOpen.data}
        updateDataHandler={updateDataHandler}
      />
      <AlertModal
        warningText="This template will be deleted and cannot be recovered. Do you want to delete this template?"
        open={deleteTemplateModal.open}
        handleDialog={handleDeleteTemplateModal}
        handleDeleteAction={handleDeleteTemplate}
        loading={deleteTemplateModal.loading}
      />
      <AlertModal
        warningText="Do you want to discard these changes?"
        deleteBtnText="Discard"
        alertType="warning"
        open={discardProjectDetails}
        handleDialog={() => handleDiscardProjectDetails(false)}
        handleDeleteAction={() => handleDiscardProjectDetails(true)}
      />
    </>
  )
}

export default TemplatesModal
