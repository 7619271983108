import React, { useState, useRef } from 'react'
// import {
//   Button,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Popover
// } from '@material-ui/core'
import { ReactComponent as FileUpload } from 'static/svg/file-upload.svg'
// import { ReactComponent as Folder } from 'static/svg/folder.svg'
import { ReactComponent as FolderPlus } from 'static/svg/folder-plus.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import useStyles from './filesAssetsStyles'
import { PopupMenu } from 'global/globalComponents'
import clsx from 'clsx'

const AddNew = ({ openFolderModal, handleFileUpload }) => {
  // const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const inputRef = useRef()

  // const handleOpenMenu = (e) => {
  //   setAnchorEl(e.currentTarget)
  // }

  // const handleCloseMenu = () => {
  //   setAnchorEl(null)
  // }

  const handleUploadClick = () => {
    inputRef.current.click()
    // handleCloseMenu()
  }

  const handleFileChange = (e) => {
    handleFileUpload(e.target.files)
    e.target.value = ''
  }

  return (
    <>
      {/* <Button onClick={handleOpenMenu} variant="outlined" startIcon={<Add />}>
        New
      </Button> */}
      <PopupMenu
        targetComp={
          <button
            className={clsx(
              'w-8 h-8 rounded-full border bg-white flex justify-center items-center shadow hover:shadow-primary-main focus:shadow-primary-main',
              classes.addNewButton
            )}
          >
            <AddIcon className="w-5 h-5" />
          </button>
        }
        menuItems={[
          {
            label: 'Upload files',
            icon: <FileUpload />,
            method: handleUploadClick
          },
          {
            label: 'Create folder',
            icon: <FolderPlus className="text-lg" />,
            method: openFolderModal
          }
        ]}
      />

      <input
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  )
}

export default AddNew
