import axios from 'axios'
import { errorMessages } from 'utils'

export const fetchUserTimesheets = (startDate, endDate, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `tracking/timesheet/user?startDate=${startDate}&&endDate=${endDate}`
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchSelectedUserTimesheets = (
  userId,
  startDate,
  endDate,
  callback
) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/tracking/timesheet/agency/user/${userId}?startDate=${startDate}&&endDate=${endDate}`
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchAllUsersTimesheets = (
  agencyOwnerId,
  startDate,
  endDate,
  callback
) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/tracking/timesheet/agency/${agencyOwnerId}?startDate=${startDate}&&endDate=${endDate}`
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
