import {
  Drawer,
  Toolbar,
  makeStyles,
  createStyles,
  Button
} from '@material-ui/core'
import React, { useState } from 'react'
import { useEffect } from 'react'
import ApprovalRequestCard from './ApprovalRequestCard'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import CreateApprovalCard from './CreateApprovalCard'
// import { Skeleton } from '@material-ui/lab'

const ApprovalPanel = ({
  open,
  onClose,
  data,
  taskId,
  subtaskId,
  boardId,
  currentWorkspace,
  isLoading,
  elmFor,
  addNewApproval,
  removeApprovalById,
  updateApproval
}) => {
  const classes = useStyles()
  const [createApproval, setCreateApproval] = useState(false)

  useEffect(() => {
    if (open && !isLoading && !data.length) {
      setCreateApproval(true)
    }
  }, [open, data.length, isLoading])

  const toggleCreateApproval = () => {
    setCreateApproval((prev) => !prev)
  }

  const handleCancelApprovalCreate = () => {
    if (data.length) setCreateApproval(false)
    else onClose()
  }

  return (
    <Drawer
      onClose={onClose}
      anchor="right"
      open={open}
      classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}
      backfaceVisibility={false}
      // hideBackdrop={true}
      // variant="persistent"
    >
      {/* ============== HEADER =============== */}
      <Toolbar onClose={onClose} style={{ borderBottom: '1px solid #eee' }}>
        <div className="flex items-center justify-between w-full">
          <h2>Approvals</h2>
          <button
            onClick={onClose}
            className="w-8 h-8 rounded-full border flex justify-center items-center border-primary-mid-light"
          >
            <CloseIcon className="text-primary-main w-5 h-5" />
          </button>
        </div>
      </Toolbar>
      <div className="px-4 py-6">
        <div>
          <div className="flex items-center1 mb-4">
            {isLoading && (
              <p className="text-smaller text-gray-5001">Loading...</p>
            )}
            {Boolean(data.length) && !createApproval && (
              <div className="ml-auto">
                <Button
                  size="small"
                  startIcon={<PlusIcon />}
                  onClick={toggleCreateApproval}
                >
                  New
                </Button>
              </div>
            )}
          </div>
          {createApproval && (
            <div className="mb-4">
              <CreateApprovalCard
                taskId={taskId}
                subtaskId={subtaskId}
                currentWorkspace={currentWorkspace}
                boardId={boardId}
                elmFor={elmFor}
                addNewApproval={addNewApproval}
                removeCreateApproval={toggleCreateApproval}
                handleCancelApprovalCreate={handleCancelApprovalCreate}
              />
            </div>
          )}
          <div className="space-y-4">
            {data.map((item) => (
              <ApprovalRequestCard
                key={item._id}
                onClose={onClose}
                approvalData={item}
                currentWorkspace={currentWorkspace}
                taskId={taskId}
                removeApprovalById={removeApprovalById}
                updateApproval={updateApproval}
              />
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

// const LoadingAnimation = () => {
//   return (
//     <div className="bg-white shadow p-4">
//       <div className="flex items-star space-x-2">
//         <Skeleton variant="circle" width={30} height={30} />

//         <div className="flex-1">
//           <div className="mb-4">
//             <Skeleton variant="text" width={50} />
//           </div>
//           <Skeleton variant="rect" width="100%" height={100} />
//         </div>
//       </div>
//     </div>
//   )
// }

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerRoot: {
      zIndex: `2000 !important`
    },
    drawerPaper: () => ({
      width: 450,
      marginTop: theme.custom.header.height,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      maxHeight: `calc(100vh - ${theme.custom.header.height * 2}px)`,
      zIndex: 2000
    })
  })
)

export default ApprovalPanel
