import { createContext } from 'react'
// import { v4 as uuidv4 } from 'uuid'
import ObjectID from 'bson-objectid'

/*
=============
Note: I'm using useImmerReducer for state management which allows state mutation
================
*/

export const ONBOARDING = {
  ADD_TEMPLATE: 'ONBOARDING_ADD_TEMPLATE',
  VIEW_TEMPLATE: 'ONBOARDING_VIEW_TEMPLATE',
  UPDATE_TEMPLATE: 'ONBOARDING_UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_PROP: 'ONBOARDING_UPDATE_TEMPLATE_PROP',
  // SAVE_TEMPLATE: 'ONBOARDING_SAVE_TEMPLATE',
  REMOVE_TEMPLATE: 'ONBOARDING_REMOVE_TEMPLATE',
  ADD_INTAKE: 'ONBOARDING_ADD_INTAKE',
  ADD_DOCUMENT: 'ONBOARDING_ADD_DOCUMENT',
  ADD_VIDEO: 'ONBOARDING_ADD_VIDEO',
  ADD_INSTRUCTIONS: 'ONBOARDING_ADD_INSTRUCTIONS',
  REMOVE_STEP: 'ONBOARDING_REMOVE_STEP',
  UPDATE_SECTION_PROP: 'ONBOARDING_UPDATE_SECTION_PROP',
  ADD_INTAKE_FIELD: 'ONBOARDING_ADD_INTAKE_FIELD',
  UPDATE_INTAKE_FIELD: 'ONBOARDING_UPDATE_INTAKE_FIELD',
  REMOVE_INTAKE_FIELD: 'ONBOARDING_REMOVE_INTAKE_FIELD',
  INTAKE_FIELD_MOVE: 'ONBOARDING_INTAKE_FIELD_MOVE',
  UPDATE_FORM_FIELD: 'ONBOARDING_UPDATE_FORM_FIELD',
  FORM_FIELD_ERROR: 'ONBOARDING_FORM_FIELD_ERROR',
  ADD_INSTRUCTIONS_Field: 'ONBOARDING_ADD_INSTRUCTIONS_Field',
  UPDATE_INSTRUCTIONS_Field: 'ONBOARDING_UPDATE_INSTRUCTIONS_Field',
  REMOVE_INSTRUCTIONS_Field: 'ONBOARDING_REMOVE_INSTRUCTIONS_Field'
}

export const initialState = {
  templates: [],
  currentTemplate: {}
}

export const OnboardingContext = createContext(initialState)

export const onboardingReducer = (draft, action) => {
  switch (action.type) {
    case ONBOARDING.ADD_TEMPLATE: {
      const skeleton = getTemplateSkeleton()
      draft.currentTemplate = skeleton
      return
    }

    case ONBOARDING.VIEW_TEMPLATE: {
      draft.currentTemplate = action.payload
      return
    }

    case ONBOARDING.UPDATE_TEMPLATE: {
      draft.templates = action.payload
      return
    }

    case ONBOARDING.UPDATE_TEMPLATE_PROP: {
      draft.currentTemplate[action.payload.prop] = action.payload.value
      return
    }

    // case ONBOARDING.SAVE_TEMPLATE: {
    //   const template = { ...draft.currentTemplate }
    //   const tempIndex = draft.templates.findIndex(
    //     (item) => item.id === template.id
    //   )

    //   if (tempIndex !== -1) {
    //     draft.templates.splice(tempIndex, 1, template)
    //   } else {
    //     draft.templates.push(template)
    //   }
    //   return
    // }

    case ONBOARDING.REMOVE_TEMPLATE: {
      const tempIndex = draft.templates.findIndex(
        (item) => item.id === draft.currentTemplate.id
      )
      draft.templates.splice(tempIndex, 1)
      return
    }

    case ONBOARDING.ADD_INTAKE: {
      const skeleton = getIntakeFormSkeleton()
      draft.currentTemplate.data.push(skeleton)
      return
    }

    case ONBOARDING.ADD_DOCUMENT: {
      const skeleton = getDocumentSkeleton()
      draft.currentTemplate.data.push(skeleton)
      return
    }

    case ONBOARDING.ADD_VIDEO: {
      const skeleton = getVideoSkeleton()
      draft.currentTemplate.data.push(skeleton)
      return
    }

    case ONBOARDING.ADD_INSTRUCTIONS: {
      const skeleton = getInstructionsSkeleton()
      draft.currentTemplate.data.push(skeleton)
      return
    }

    case ONBOARDING.REMOVE_STEP: {
      const stepIndex = draft.currentTemplate.data.findIndex(
        (item) => item.id === action.payload.id
      )
      draft.currentTemplate.data.splice(stepIndex, 1)
      return
    }

    case ONBOARDING.UPDATE_SECTION_PROP: {
      const section = draft.currentTemplate.data.find(
        (item) => item.id === action.payload.id
      )
      section[action.payload.prop] = action.payload.value
      return
    }

    case ONBOARDING.ADD_INTAKE_FIELD: {
      const section = draft.currentTemplate.data.find(
        (item) => item.id === action.payload.id
      )
      section.fields.push(action.payload.value)
      return
    }

    case ONBOARDING.REMOVE_INTAKE_FIELD: {
      const { sectionId, fieldId } = action.payload
      const section = draft.currentTemplate.data.find(
        (item) => item.id === sectionId
      )
      const fieldIndex = section.fields.findIndex((item) => item.id === fieldId)

      if (fieldIndex !== -1) {
        section.fields.splice(fieldIndex, 1)
      }
      return
    }

    case ONBOARDING.UPDATE_INTAKE_FIELD: {
      const { sectionId, fieldId, prop, value } = action.payload
      const section = draft.currentTemplate.data.find(
        (item) => item.id === sectionId
      )
      const field = section.fields.find((item) => item.id === fieldId)
      field[prop] = value
      return
    }

    case ONBOARDING.INTAKE_FIELD_MOVE: {
      const { sectionId, dropResult } = action.payload
      const section = draft.currentTemplate.data.find(
        (item) => item.id === sectionId
      )
      const temp = section.fields.splice(dropResult.removedIndex, 1)[0]

      section.fields.splice(dropResult.addedIndex, 0, temp)
      return
    }

    case ONBOARDING.UPDATE_FORM_FIELD: {
      const { sectionId, key, value } = action.payload
      const section = draft.currentTemplate.data.find(
        (item) => item.id === sectionId
      )
      section.fields[0][key].value = value
      return
    }

    case ONBOARDING.FORM_FIELD_ERROR: {
      const { sectionId, key, error } = action.payload
      const section = draft.currentTemplate.data.find(
        (item) => item.id === sectionId
      )
      section.fields[0][key].error = error
      return
    }

    case ONBOARDING.ADD_INSTRUCTIONS_Field: {
      const section = draft.currentTemplate.data.find(
        (item) => item.id === action.payload.sectionId
      )
      section.fields.push(action.payload.value)
      return
    }

    case ONBOARDING.UPDATE_INSTRUCTIONS_Field: {
      const { sectionId, fieldId, value } = action.payload
      const section = draft.currentTemplate.data.find(
        (item) => item.id === sectionId
      )
      const fieldIndex = section.fields.findIndex((item) => item.id === fieldId)
      section.fields[fieldIndex].text = value
      return
    }

    case ONBOARDING.REMOVE_INSTRUCTIONS_Field: {
      const section = draft.currentTemplate.data.find(
        (item) => item.id === action.payload.sectionId
      )
      const fieldIndex = section.fields.findIndex(
        (item) => item.id === action.payload.fieldId
      )
      section.fields.splice(fieldIndex, 1)
      return
    }

    default:
      return draft
  }
}

const getTemplateSkeleton = () => ({
  // id: uuidv4(),
  templateName: 'My First Template',
  welcomeText: 'Getting started',
  isActive: false,
  data: []
})

const getIntakeFormSkeleton = () => ({
  name: 'intakeForm',
  sectionHeading: 'Intake Form',
  description:
    "Edit this description by just clicking on it or leave it as it is if you don't want to add any description",
  id: ObjectID().toString(),
  fields: [
    {
      id: ObjectID().toString(),
      label: 'Name',
      type: 'text',
      value: '',
      error: false,
      required: true
    },
    {
      id: ObjectID().toString(),
      label: 'Email',
      type: 'email',
      required: true,
      value: '',
      error: false
    },
    {
      id: ObjectID().toString(),
      label: 'Address',
      type: 'text',
      required: true,
      value: '',
      error: false
    },
    {
      id: ObjectID().toString(),
      label: 'Phone No.',
      type: 'number',
      required: true,
      value: '',
      error: false
    },
    {
      id: ObjectID().toString(),
      label: 'Date Of Birth',
      type: 'date',
      required: true,
      value: '',
      error: false
    },
    // {
    //   id: ObjectID().toString(),
    //   label: 'File',
    //   type: 'file',
    //   required: true,
    //   value: '',
    //   error: false
    // },
    {
      id: ObjectID().toString(),
      label: 'Description',
      type: 'textarea',
      required: true,
      value: '',
      error: false
    }
  ]
})

const getDocumentSkeleton = () => ({
  name: 'document',
  sectionHeading: 'Document',
  description:
    "Edit this description by just clicking on it or leave it as it is if you don't want to add any description",
  id: ObjectID().toString(),
  fields: [
    {
      title: { value: '', label: 'Title', required: true, error: false },
      document: {
        value: '',
        label: 'Upload Document',
        required: true,
        error: false
      },
      description: {
        value: '',
        label: 'Description',
        required: true,
        error: false
      }
    }
  ]
})

const getVideoSkeleton = () => ({
  name: 'video',
  sectionHeading: 'Video',
  description:
    "Edit this description by just clicking on it or leave it as it is if you don't want to add any description",
  id: ObjectID().toString(),
  fields: [
    {
      title: { value: '', label: 'Title', required: true, error: false },
      videoUrl: {
        value: '',
        label: 'Video URL',
        required: true,
        error: false
      },
      description: {
        value: '',
        label: 'Description',
        required: true,
        error: false
      },
      thumb: {
        value: '',
        label: 'Thumbnail',
        required: false,
        error: false
      }
    }
  ]
})

const getInstructionsSkeleton = () => ({
  name: 'instructions',
  sectionHeading: 'Instructions',
  description:
    "Edit this description by just clicking on it or leave it as it is if you don't want to add any description",
  id: ObjectID().toString(),
  fields: []
})
