import React, { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import BoardView from 'components/Boards/BoardView/BoardView'
import OverView from 'components/Workspace/OverView'
import { getUserPath } from 'utils/userRoles'
import EmbedFrame from 'components/EmbedModal/EmbedFrame'
import FilesAssets from 'components/FilesAssets/FilesAssets'
import TableView from 'components/Boards/TableView/TableView'
// import { SingleWorkspaceContext } from './singleWorkspaceContext'
import CalendarView from 'components/Boards/CalendarView/CalendarView'
import { useLocation, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import StatusUpdates from 'components/Workspace/StatusUpdate/StatusUpdates'
import { Loader } from 'global/globalComponents'
// import { WifiLock } from '@material-ui/icons'
// import { NoAccess } from 'static/Images'
// import { ReactComponent as LockIcon } from 'static/svg/lock.svg'

const ProjectInvoices = React.lazy(() =>
  import('components/Workspace/ProjectInvoices/ProjectInvoices')
)
const ClientProjectInvoices = React.lazy(() =>
  import(
    'components/Workspace/ProjectInvoices/ClientProjectInvoices/ClientProjectInvoices'
  )
)

const WorkspaceRoutes = ({ workspaceId, setBoardId }) => {
  const meData = useSelector(state => state.me.data)
  const userWorkspaces = useSelector(state => state.userWorkspaces.data)
  const history = useHistory()
  // const { workspaceState } = useContext(SingleWorkspaceContext)
  // const { search } = useLocation()
  const { pathname, search } = useLocation()
  const view = useMemo(() => new URLSearchParams(search), [search]).get('view')
  const userPath = useMemo(() => {
    return getUserPath(meData.role)
  }, [meData.role])
  const embeds = useSelector(state => state.userWorkspaceEmbed.data)
  const [hiddenCols, setHiddenCols] = useState([])
  const workspace = useMemo(() => {
    const ws = userWorkspaces.find(item => item._id === workspaceId)
    if (ws) return ws
    else return {}
  }, [userWorkspaces])

  const embedData = useMemo(() => {
    return embeds.filter(
      el => el.type !== 'board' && el.workspace === workspaceId
    )
  }, [embeds])
  const [routeSelected, setRouteSelected] = useState(false)

  useEffect(() => {
    if (workspace && workspace.projectTabs) {
      setHiddenCols(workspace.projectTabs[0].hiddenColumns)
    } else {
      setHiddenCols([])
    }
  }, [workspace])

  useEffect(() => {
    if (workspace._id) {
      // ======== Will Improve Following Code ===========
      if (workspace.projectTabs) {
        const boardUrls = {
          board: `${userPath}/workspace/${workspace._id}/board/${workspace.defaultBoard._id}?view=board`,
          overview: `${userPath}/workspace/${workspace._id}/overview`,
          list: `${userPath}/workspace/${workspace._id}/board/${workspace.defaultBoard._id}?view=list`,
          calendar: `${userPath}/workspace/${workspace._id}/board/${workspace.defaultBoard._id}?view=calendar`,
          reporting: `${userPath}/workspace/${workspace._id}/status-updates`,
          embeds: embedData.length
            ? `${userPath}/workspace/${
                workspace._id
              }/${embedData[0].type.toLowerCase()}/${embedData[0]._id}`
            : `${userPath}/workspace/${workspace._id}/embeds`
        }

        let hiddenCols = workspace.projectTabs[0].hiddenColumns,
          selectedTabHidden = false

        for (let key in boardUrls) {
          if (selectedTabHidden) {
            if (!hiddenCols.includes(key)) {
              history.replace(boardUrls[key])
              setRouteSelected(true)
              break
            }
          } else {
            if (pathname + search === boardUrls[key]) {
              if (!hiddenCols.includes(key)) {
                history.replace(boardUrls[key])
                setRouteSelected(true)
                break
              } else {
                selectedTabHidden = true
              }
            }
          }
        }
        setRouteSelected(true)
      } else {
        setRouteSelected(true)
      }
    }
  }, [workspace._id, workspace.projectTabs, embedData])

  if (!routeSelected)
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loader />
      </div>
    )

  return (
    <React.Suspense fallback="">
      <Switch>
        <Route path={`${userPath}/workspace`}>
          <div className={clsx(view && 'mt-12', 'pt-10 h-full')}>
            <Switch>
              {!hiddenCols.includes('overview') && (
                <Route path={`${userPath}/workspace/:workspaceId/overview`}>
                  <OverView />
                </Route>
              )}

              {!hiddenCols.includes('reporting') && (
                <Route
                  exact
                  path={[
                    `${userPath}/workspace/:workspaceId/status-updates`,
                    `${userPath}/workspace/:workspaceId/status-updates/:statusId`
                  ]}
                >
                  <StatusUpdates />
                </Route>
              )}

              {(!hiddenCols.includes('board') ||
                !hiddenCols.includes('list') ||
                !hiddenCols.includes('calendar')) && (
                <Route
                  exact
                  path={[
                    `${userPath}/workspace/:workspaceId/board/:boardId`,
                    `${userPath}/workspace/:workspaceId/board/:boardId/task/:taskId`,
                    `${userPath}/workspace/:workspaceId/board/:boardId/task/:taskId/:subtaskId`
                  ]}
                >
                  {!hiddenCols.includes('list') && view === 'list' ? (
                    <TableView />
                  ) : !hiddenCols.includes('calender') &&
                    view === 'calendar' ? (
                    <CalendarView />
                  ) : (
                    <BoardView setBoardId={setBoardId} />
                  )}
                </Route>
              )}
              {!hiddenCols.includes('invoices') && (
                <Route path="/agency/workspace/:workspaceId/invoices">
                  <ProjectInvoices />
                </Route>
              )}
              {!hiddenCols.includes('invoices') && (
                <Route path="/client/workspace/:workspaceId/invoices">
                  <ClientProjectInvoices />
                </Route>
              )}
              {!hiddenCols.includes('files') && (
                <Route path={`${userPath}/workspace/:workspaceId/files`}>
                  <FilesAssets />
                </Route>
              )}

              {!hiddenCols.includes('embeds') && (
                <Switch>
                  {' '}
                  <Route path={`${userPath}/workspace/:workspaceId/sheet/:id`}>
                    <EmbedFrame name="sheets" />
                  </Route>
                  <Route path={`${userPath}/workspace/:workspaceId/docs/:id`}>
                    <EmbedFrame name="docs" />
                  </Route>
                  <Route path={`${userPath}/workspace/:workspaceId/slides/:id`}>
                    <EmbedFrame name="slides" />
                  </Route>
                  <Route
                    path={`${userPath}/workspace/:workspaceId/calendar/:id`}
                  >
                    <EmbedFrame name="calendar" />
                  </Route>
                  <Route path={`${userPath}/workspace/:workspaceId/embed/:id`}>
                    <EmbedFrame name="embed" />
                  </Route>
                </Switch>
              )}
              {/* <Route>
                <div className="bg-white shadow rounded-xl text-center py-8">
                  <span className="inline-block text-primary-main">
                    <LockIcon className="w-10 h-10" />
                  </span>
                  <p>You do not have permission to view project's data</p>
                </div>
              </Route> */}
            </Switch>
          </div>
        </Route>
      </Switch>
    </React.Suspense>
  )
}

export default WorkspaceRoutes
