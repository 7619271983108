import React, { useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { App, Dashboard } from 'containers'
import { checkLogin } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import UserDashboard from 'components/UserDashboard/UserDashboard'
import Login from '../components/Login/Login'
import SignupCopy from '../components/Signup/SignupCopy'
import Domain from '../components/Login/Domain'
import ClientSignupCopy from '../components/ClientComponent/Signup/ClientSignupCopy'
import {
  Clients,
  ForgotDomain,
  ForgotPassword,
  ResetPassword,
  // Settings,
  Payment,
  // ClientDashboard,
  Connect,
  ClientList,
  Services,
  ClientTeammates,
  AgencyTeammates
} from 'components'
import ProtectedRoutes from './ProtectedRoutes'
import AgencyPlans from 'components/AgencyPlans/AgencyPlans'
import Checkout from 'components/ClientComponent/ClientCheckout/Checkout'
import CheckoutServices from 'components/ClientComponent/ClientCheckout/CheckoutServices'
import TaskAssetsPage from 'components/FilesAssets/TaskAssetsPage'
import ClientSubscription from 'components/ClientComponent/ClientSubscription/ClientSubscription'
import Setup from 'components/Setup/Setup'
import { Workspaces } from 'components/UserDashboard/Workspaces'
import CardDetails from 'components/Invoices/ClientCardDetails/clientCardDetails'
import ClientServices from 'components/ClientComponent/ClientService/ClientServices'
import SingleWorkspace from 'components/SingleWorkspace/SingleWorkspace'
import FreeAgencySignup from 'components/Signup/FreeAgencySignup'
import PitchgroundSignup from 'components/Signup/PitchgroundSignup'
import GettingStarted from 'components/GettingStarted/GettingStarted'
// import PublicShareTask from 'components/PublicShareTask'
import PageNotFound from 'components/Opening/PageNotFound'
// import StripePayment from 'components/ClientComponent/ClientCheckout/StripePayment'
import AppSumoSignup from 'components/Signup/AppSumoSignup'
import { Loader } from 'global/globalComponents'
import { fetchMeData } from 'thunks/me/actions'
import OrderCheckout from 'components/ClientComponent/ClientCheckout/OrderCheckout'
// import ProjectInvoiceCheckoutPage from 'components/Workspace/ProjectInvoices/CheckoutPage'
// import PublicShareBoardRoutes from 'components/PublicSharedBoard/PublicShareBoardRoutes'
import AccountLogs from 'components/AccountLogs/AccountLogs'
// import Timesheets from 'components/ResourceManagement/Timesheets/Timesheets'
import SettingNewDesign from 'components/Settings/SettingNewDesign'
import CompanyMain from 'components/ClientCompanyComponents/CompanyMain'
// import ResourceManagement from 'components/ResourceManagement/ResourceManagement'
import ResourceNavigation from 'components/ResourceManagement/ResourceNavigation'
import TemplateCenter from 'components/Templates/TemplateCenter'
import OnboardingNew from 'components/OnboardingNew/OnboardingNew'
import Reports from 'components/Reports/Reports'
import { fetchUser } from 'thunks/user/actions'
import TemplateImport from 'components/Templates/TemplateImport/TemplateImport'
import Pulse from 'components/Pulse/Pulse'
import PaymentsNavigation from 'components/Payments/PaymentsNavigation'

const ProjectInvoiceCheckoutPage = React.lazy(() =>
  import('components/Workspace/ProjectInvoices/CheckoutPage')
)
const PublicShareBoardRoutes = React.lazy(() =>
  import('components/PublicSharedBoard/PublicShareBoardRoutes')
)
const PublicShareTask = React.lazy(() => import('components/PublicShareTask'))
const TeammateSignup = React.lazy(() =>
  import('components/Signup/TeammateSignup/TeammateSignup')
)

const Routes = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const { loggedIn } = useSelector((state) => state.login)
  const { fetched } = useSelector((state) => state.me)

  useEffect(() => {
    if (checkLogin() && !fetched) {
      setLoading(true)
      dispatch(
        fetchMeData((res, err) => {
          if (!err) {
            setLoading(false)
          }
        })
      )
      dispatch(fetchUser())
    } else {
      setLoading(false)
    }
  }, [loggedIn, fetched])

  return (
    <BrowserRouter>
      {loading ? (
        <div className="w-screen h-screen bg-white flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <Switch>
          <App path="/">
            {/* <Dashboard> */}
            <ProtectedRoutes component={AgencyPlans} path="/plans" />
            {/* <Route path="/plans">
            <AgencyPlans />
          </Route> */}
            {/* </Dashboard> */}
            <Route path={'/agency'}>
              <Dashboard>
                <Switch>
                  <ProtectedRoutes
                    path="/agency/dashboard"
                    component={UserDashboard}
                    name="Dashboard"
                  />
                  <ProtectedRoutes
                    path="/agency/reports"
                    component={Reports}
                    name="Dashboard"
                  />

                  <ProtectedRoutes
                    path="/agency/workspace/all"
                    component={Workspaces}
                  />

                  <ProtectedRoutes
                    path="/agency/teammates"
                    component={AgencyTeammates}
                  />
                  <ProtectedRoutes
                    path="/agency/resource-management"
                    component={ResourceNavigation}
                  />
                  {/* <ProtectedRoutes
                    path="/agency/resource-management/timesheets/:time"
                    component={Timesheets}
                  /> */}

                  {/* <ProtectedRoutes
                path="/agency/stripeconnect"
                component={StripeConnect}
              />
              <ProtectedRoutes path="/agency/invoices" component={Invoices} />
              <ProtectedRoutes
                path="/agency/createinvoices"
                component={CreateInvoices}
              /> */}
                  <ProtectedRoutes
                    path="/agency/invoicesconnect"
                    component={Connect}
                  />
                  {/* <ProtectedRoutes
                  path="/agency/clientsnewUI"
                  component={ClientList}
                /> */}
                  {/* <ProtectedRoutes
                  path="/agency/clients-billing"
                  component={ClientsBilling}
                /> */}
                  {/* <ProtectedRoutes
                    path="/agency/client-billing"
                    component={Connect}
                  />
                  <ProtectedRoutes
                    path="/agency/services"
                    component={Services}
                  /> */}

                  <ProtectedRoutes
                    path="/agency/payments"
                    component={PaymentsNavigation}
                  />

                  <ProtectedRoutes
                    path="/agency/notifications"
                    component={AccountLogs}
                  />

                  <ProtectedRoutes
                    path="/agency/files-assets/:clientId/:workspaceId/:taskId"
                    component={TaskAssetsPage}
                  />

                  {/* <ProtectedRoutes
                  path="/agency/files-assets/all"
                  component={FilesAssets}
                /> */}
                  <ProtectedRoutes
                    path="/agency/clients/company/:companyId"
                    component={CompanyMain}
                  />
                  <ProtectedRoutes
                    path="/agency/workspace/:workspaceId"
                    component={SingleWorkspace}
                  />
                  <ProtectedRoutes
                    path="/agency/clients/all"
                    component={Clients}
                  />
                  <ProtectedRoutes
                    path="/agency/clients/:clientId"
                    component={ClientList}
                  />

                  <ProtectedRoutes
                    path="/agency/getting-started/"
                    component={GettingStarted}
                  />
                  <ProtectedRoutes
                    path="/agency/onboarding"
                    component={OnboardingNew}
                  />
                  <ProtectedRoutes
                    path="/agency/settings"
                    component={SettingNewDesign}
                  />
                  <ProtectedRoutes
                    path="/agency/template-center"
                    component={TemplateCenter}
                  />
                  <ProtectedRoutes
                    path="/agency/import/template"
                    component={TemplateImport}
                  />
                  <ProtectedRoutes path="/agency/pulse" component={Pulse} />
                  {/* <ProtectedRoutes path="/agency/setup" component={Setup} /> */}
                  <ProtectedRoutes>
                    <Redirect to="/agency/dashboard" />
                  </ProtectedRoutes>
                </Switch>
              </Dashboard>
            </Route>
            <ProtectedRoutes
              component={ClientServices}
              path="/select-service"
            />
            {/* <Route></Route> */}
            <Route path={'/client'}>
              <Dashboard>
                <Switch>
                  <ProtectedRoutes
                    component={UserDashboard}
                    path="/client/dashboard"
                  />
                  <ProtectedRoutes path="/client/clients" component={Clients} />
                  <ProtectedRoutes
                    path="/client/teammates"
                    component={ClientTeammates}
                  />
                  <ProtectedRoutes
                    path="/client/invoices"
                    component={Payment}
                  />
                  <ProtectedRoutes
                    path="/client/cardDetails"
                    component={CardDetails}
                  />
                  <Route path="/client/workspace/all" component={Workspaces} />

                  <Route path="/client/workspace/:workspaceId">
                    <SingleWorkspace />
                  </Route>
                  <ProtectedRoutes
                    path="/client/settings"
                    component={SettingNewDesign}
                  />
                  <ProtectedRoutes
                    path="/client/notifications"
                    component={AccountLogs}
                  />
                  {/* <ProtectedRoutes
                  path="/client/getting-started/"
                  component={ClientGettingStarted}
                /> */}

                  <ProtectedRoutes
                    path="/client/services/all"
                    component={ClientSubscription}
                  />
                  <ProtectedRoutes>
                    <Redirect to="/client/dashboard" />
                  </ProtectedRoutes>
                </Switch>
              </Dashboard>
            </Route>

            <React.Suspense fallback={<p>Please wait...</p>}>
              <Switch>
                <Route path="/login/domain" component={Domain} />
                <Route path="/login" component={Login} />
                <Route path="/signup" component={SignupCopy} />
                <Route path="/free-trial" component={FreeAgencySignup} />
                <Route path="/pitchground-sale" component={PitchgroundSignup} />
                <Route path="/appsumo" component={AppSumoSignup} />
                <Route path="/clientsignup" component={ClientSignupCopy} />
                <Route
                  path="/teammate-signup/:agencyId"
                  component={TeammateSignup}
                />
                <Route
                  path="/services/:plan/checkout"
                  component={OrderCheckout}
                />
                {/* <Route
                  path="/services/:plan/:accountId/order-checkout"
                  component={OrderCheckout}
                /> */}
                <Route path="/services" component={CheckoutServices} />
                <Route
                  path="/agencyuser/agency/acceptteaminvitation/:inviteToken"
                  component={SignupCopy}
                />
                <Route
                  path="/inviteteammate/:inviteToken"
                  component={SignupCopy}
                />
                <Route path="/forgot-domain" component={ForgotDomain} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/resetpassword/:token" component={ResetPassword} />
                <Route
                  path={[
                    '/public/share/board/:workspaceId',
                    '/public/:customURL'
                  ]}
                  component={PublicShareBoardRoutes}
                />
                <Route
                  path="/public/share/task/:taskId"
                  component={PublicShareTask}
                />
                <Route
                  path="/i/:invoiceId/checkout"
                  component={ProjectInvoiceCheckoutPage}
                />

                <Route>
                  <PageNotFound />
                </Route>
              </Switch>
            </React.Suspense>
          </App>
        </Switch>
      )}
    </BrowserRouter>
  )
}

export default Routes
