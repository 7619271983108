import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef
} from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, TextField } from '@material-ui/core'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
// import { addCurrentBoard } from 'thunks/workspace/actions'
import {
  deleteSectionOrders,
  createWorkspaceSection,
  saveBoardView,
  updateEmbed
} from 'thunks/embed/action'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { hideChatMessenger, errorMessages, debounce } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { Skeleton } from '@material-ui/lab'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import BoardsStyles from './BoardsStyles'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import TaskPanel from 'components/Workspace/Panel/TaskPanel'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { getAssigneeSections } from 'thunks/task/actions'
import Column from './Column/Column'
import getSectionColor from 'utils/getSectionColor'
import SaveViewPopup from 'components/SingleWorkspace/SaveViewPopup'
import {
  // getStatusSections,
  getDueDateSections
} from './boardSectionsData'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import clsx from 'clsx'
import { ColoredAvatarGroup, Layout } from 'global/globalComponents'
// import { TASKS } from 'thunks/task/reducers'
import moment from 'moment'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import userRoles, { getUserPath } from 'utils/userRoles'
import { moveSectionTask, removeTaskFromSection } from 'thunks/task/actions'
// import AssigneeSectionHeading from './Column/AssigneeSectionHeading'
import { groupByKeyWords } from 'thunks/task/reducers'
import EditStatusModal from 'components/EditStatusModal/EditStatusModal'

const BoardView = ({ setBoardId }) => {
  const dispatch = useDispatch()
  const classes = BoardsStyles()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  // const embedData = useSelector((state) => state.userWorkspaceEmbed)
  const { url } = useRouteMatch()
  const search = useSelector(state => state.generalAppState.boardSearch)
  const currentWorkspaceBoard = useSelector(
    state => state.userWorkspaces.currentBoard
  )

  const { socketId } = useContext(WorkspacePusherContext)

  const [categoryNameChange, setCategoryNameChange] = useState({
    change: false,
    data: ''
  })
  const [deleteSection, setDeleteSection] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [taskPanel, setTaskPanel] = useState({ open: false, data: {} })
  const userTasks = useSelector(state => state.userTasks)
  const {
    // data: boardTasksData,
    data: boardNewTasksData,
    loading: { all: allTasksLoading },
    sectionWiseTasks,
    dueDateWiseTasks,
    assigneeWiseTasks
  } = userTasks

  const {
    workspaceState: {
      groupBy,
      // sortBy,
      permission,
      defaultGroup,
      showApprovalTasks,
      filters
    }
  } = useContext(SingleWorkspaceContext)
  const { workspacePermission, taskPermission } = permission
  // const approvalTasks = useSelector((state) => state.approvals.data.tasks)

  const { taskId, boardId } = useParams()
  const history = useHistory()
  // const [sections, setSections] = useState([])
  const [statusSections, setStatusSections] = useState({
    data: [],
    loading: true
  })
  const [dueDateSections, setDueDateSections] = useState({
    data: [],
    loading: true
  })
  const [
    fetchedAssigneeSectionsData,
    setFetchedAssigneeSectionsData
  ] = useState([])
  const [assigneeSections, setAssigneeSections] = useState({
    data: [],
    loading: true
  })
  const meData = useSelector(state => state.me.data)
  const [openSaveViewPopup, setOpenSaveViewPopup] = useState(false)
  // const [allTasksData, setAllTasksData] = useState([])
  const { markTaskAsComplete, dndTaskPositionChange } = useWorkspaceFunctions(
    groupBy
  )
  const columnRefs = useRef([])
  const [isEditStatusModalOpen, setIsStatusModalOpen] = useState(false)

  // useEffect(() => {
  //   let tasks = boardTasksData

  //   if (showApprovalTasks)
  //     tasks = tasks.filter((item) => approvalTasks.includes(item._id))

  //   let timer = setTimeout(() => {
  //     const { allTasks } = getSearchedTasks(search, tasks)
  //     setAllTasksData(allTasks)
  //   }, 300)

  //   return () => clearTimeout(timer)
  // }, [search, boardTasksData, showApprovalTasks, approvalTasks])

  useEffect(() => {
    hideChatMessenger()
  })

  useEffect(() => {
    setBoardId(boardId)
  }, [boardId])

  // Setting Status sections
  useEffect(() => {
    if (currentWorkspaceBoard?.boardSection) {
      let update =
        currentWorkspaceBoard.boardSection.length !== statusSections.length

      const updatedData = currentWorkspaceBoard.boardSection.map(
        (item, index) => {
          const statusSection = statusSections.data.find(
            sec => sec._id === item._id
          )
          // if the section exists and label is same do nothing
          if (statusSection) {
            if (statusSection.title === item.label) return statusSection
            else {
              update = true
              return { ...statusSection, title: item.label }
            }
          } else {
            update = true
            return {
              _id: item._id,
              title: item.label,
              value: item._id,
              color: item.color || '#000000',
              type: 'status',
              totalTasks: 0
            }
          }
        }
      )

      if (update) {
        setStatusSections({
          data: updatedData,
          loading: false
        })
      }
    }
  }, [currentWorkspaceBoard.boardSection])

  // Setting due date sections
  useEffect(() => {
    const allSections = getDueDateSections()
    setDueDateSections({ data: allSections, loading: false })
  }, [])

  // Updating status sections tasks
  useEffect(() => {
    setStatusSections(prev => ({
      ...prev,
      data: prev.data.map(section => ({
        ...section,
        // tasks:
        //   sectionWiseTasks[section._id]?.data.map(
        //     (taskId) => boardNewTasksData[taskId] || {}
        //   ) ?? [],
        totalTasks: sectionWiseTasks[section._id]?.metadata.total ?? 0
      }))
    }))
  }, [sectionWiseTasks, boardNewTasksData])

  useEffect(() => {
    setDueDateSections(prev => ({
      ...prev,
      data: prev.data.map(section => {
        return {
          ...section,
          // tasks:
          //   dueDateWiseTasks[section._id]?.data.map(
          //     taskId => boardNewTasksData[taskId] || {}
          //   ) ?? [],
          totalTasks: dueDateWiseTasks[section._id]?.metadata.total ?? 0
        }
      })
    }))
  }, [dueDateWiseTasks, boardNewTasksData])

  useEffect(() => {
    if (boardId && groupBy === 'assignee') {
      dispatch(
        getAssigneeSections(boardId, (res, err) => {
          if (err) console.log(err)
          else {
            setFetchedAssigneeSectionsData(res)
          }
        })
      )
    }
  }, [boardId, groupBy])

  useEffect(() => {
    if (fetchedAssigneeSectionsData.length) {
      setAssigneeSections({
        loading: false,
        data: fetchedAssigneeSectionsData.map(section => {
          return {
            _id: section._id,
            title:
              section._id === 'noAssignee' ? (
                <div>No Assignee</div>
              ) : (
                <ColoredAvatarGroup users={section.teammate} />
              ),
            value: section.teammate.map(item => item._id),
            agencyTeammates:
              section._id !== 'noAssignee'
                ? section.teammate
                    .filter(mate => mate.role < userRoles.USER_CLIENT)
                    .map(item => item._id)
                : [],
            clientTeammates:
              section._id !== 'noAssignee'
                ? section.teammate
                    .filter(mate => mate.role >= userRoles.USER_CLIENT)
                    .map(item => item._id)
                : [],
            color: '#000000',
            type: 'assignee',
            // tasks: [],
            totalTasks: 0
          }
        })
      })
    }
  }, [fetchedAssigneeSectionsData])

  useEffect(() => {
    setAssigneeSections(prev => ({
      ...prev,
      data: prev.data.map(section => {
        return {
          ...section,
          totalTasks: assigneeWiseTasks[section._id]?.metadata.total ?? 0
        }
      })
    }))
  }, [assigneeWiseTasks, boardNewTasksData])

  // useEffect(() => {
  //   if (!embedData?.loading) {
  //     const boardData = embedData.data?.find((item) => item._id === boardId)
  //     if (boardData) {
  //       dispatch(addCurrentBoard(boardData))
  //     }
  //   }
  // }, [embedData, boardId])

  // useEffect(() => {
  //   if (defaultBoardActive && currentWorkspaceBoard?._id !== boardId) {
  //     const boardData = embedData.data?.find((item) => item._id === boardId)
  //     if (boardData) {
  //       dispatch(addCurrentBoard(boardData))
  //     }
  //   }
  // }, [defaultBoardActive])

  // useEffect(() => {
  //   // if current board's tasks is already fetched then don't fetch again
  //   if (
  //     !boardTasksData.length ||
  //     boardTasksData[0].workspaceBoard !== boardId
  //   ) {
  //     dispatch(getTaskByWorkspaceBoardId(boardId))
  //   }
  // }, [boardId])

  useEffect(() => {
    if (!taskId) return setTaskPanel({ open: false, data: {} })
    const task = boardNewTasksData[taskId]

    if (task) {
      setTaskPanel({ open: true, data: task })
    }
  }, [taskId, boardNewTasksData])

  // useEffect(() => {
  //   let user = currentWorkspace?.assignedAgencyTeam?.filter(
  //     (item) => item.user._id === me._id
  //   )
  //   let user1 = currentWorkspace?.assignedClientTeam?.filter(
  //     (item) => item.user._id === me._id
  //   )
  //   if (user?.length === 1) dispatch(updateUserAcces(user[0]?.role?.value))
  //   if (user1?.length === 1) dispatch(updateUserAcces(user1[0]?.role?.value))
  // }, [currentWorkspace, me])

  useEffect(() => {
    if (defaultGroup !== groupBy) {
      setOpenSaveViewPopup(true)
    }
  }, [groupBy, defaultGroup])

  // ====== cleanup on unmount ======
  useEffect(() => {
    return () => {
      setStatusSections({ loading: true, data: [] })
      setDueDateSections({ loading: true, data: [] })
    }
  }, [])

  // const projectCompleteMessage = () => {
  //   dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
  // }

  const handleCategoryName = useCallback(() => {
    setCategoryNameChange({ change: false, data: '' })
  }, [])

  const deleteSectionCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    }

    setDeleteSection({
      open: false,
      data: {},
      loading: false
    })
  }

  // const getAssigneeSectionTitle = (teammates = []) => {
  //   const users = teammates.map(mateId => {
  //     let userObj = currentWorkspace.assignedAgencyTeam
  //       .concat(currentWorkspace.assignedClientTeam)
  //       .find(item => item.user._id === mateId)

  //     return userObj ? userObj.user : {}
  //   })

  //   return users.length ? (
  //     <ColoredAvatarGroup users={users} />
  //   ) : (
  //     <div>No Assignee</div>
  //   )
  // }

  const handleDeleteTaskSection = () => {
    setDeleteSection(prevState => ({ ...prevState, loading: true }))
    const data = deleteSection.data
    dispatch(
      deleteSectionOrders(
        { _id: data.value, label: data.title },
        deleteSectionCallback
      )
    )
  }

  const handleDeleteWorkspaceSection = useCallback(
    section => {
      if (deleteSection.open) {
        setDeleteSection({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setDeleteSection({
          open: true,
          data: section,
          loading: false
        })
      }
    },
    [deleteSection]
  )

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result

    //If there is no destination or destination is pending tasks
    if (!destination) return

    if (type === 'column') {
      const sectionsArr = [...sections.data]
      const removedItem = sectionsArr.splice(source.index, 1)
      sectionsArr.splice(destination.index, 0, removedItem[0])
      setStatusSections(prev => ({ ...prev, data: sectionsArr }))

      dispatch(
        updateEmbed(currentWorkspaceBoard._id, {
          boardSection: sectionsArr.map(item => item._id)
        })
      )
    } else {
      //If source and destination is the same

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
        return

      let tasks = userTasks[groupByKeyWords[groupBy]][
          destination.droppableId
        ].data.map(taskId => boardNewTasksData[taskId]),
        destinationSection = sections.data.find(
          sec => sec._id === destination.droppableId
        ),
        destinationAboveTask = tasks[destination.index - 1] ?? null,
        destinationBelowTask = tasks[destination.index] ?? null,
        update = {}

      if (groupBy === 'status') {
        // const tasks = sectionWiseTasks[destination.droppableId].data.map(
        //   taskId => boardNewTasksData[taskId]
        // )
        // destinationAboveTask = tasks[destination.index - 1] ?? null
        // destinationBelowTask = tasks[destination.index] ?? null
        update.taskSection = destinationSection.value
      } else if (groupBy === 'dueDate') {
        // const tasks = dueDateWiseTasks[destination.droppableId].data.map(
        //   taskId => boardNewTasksData[taskId]
        // )
        // destinationAboveTask = tasks[destination.index - 1] ?? null
        // destinationBelowTask = tasks[destination.index] ?? null
        update.dueDate =
          destinationSection.value === 'noDueDate'
            ? null
            : destinationSection.value === 'overdue'
            ? moment().subtract(1, 'day').toISOString()
            : destinationSection.value
      } else if (groupBy === 'assignee') {
        update.assignedAgencyTeam = destinationSection.agencyTeammates
        update.assignedClientTeam = destinationSection.clientTeammates
      }

      dispatch(
        moveSectionTask({
          sourceIndex: source.index,
          destinationIndex: destination.index,
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          taskId: draggableId,
          groupBy
        })
      )

      dndTaskPositionChange({
        aboveTask: destinationAboveTask,
        belowTask: destinationBelowTask,
        targetTaskId: draggableId,
        targetSection: destination.droppableId,
        update
      })
    }
  }

  const handleChangeSectionTitle = useCallback(
    val => {
      if (!workspacePermission.update)
        dispatch(fireErrorToaster('you are not an authorized user'))
      else {
        if (currentWorkspace.isComplete) {
          dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
        } else {
          setCategoryNameChange({ change: true, data: val })
        }
      }
    },
    [workspacePermission.update, currentWorkspace.isComplete]
  )

  const handleCloseTaskSidePanel = () => {
    history.push(url.split('/task')[0] + '?view=board')
  }

  const handleCloseSaveViewPopup = () => {
    setOpenSaveViewPopup(false)
  }

  const handleSaveView = useCallback(() => {
    const data = []

    if (currentWorkspaceBoard.groupByDetail) {
      const arr = currentWorkspaceBoard.groupByDetail.filter(
        item => item.user === meData._id
      )
      data.push(...arr, { groupedByDetail: groupBy, user: meData._id })
    } else {
      data.push({ groupedByDetail: groupBy, user: meData._id })
    }
    dispatch(
      saveBoardView(boardId, data, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          dispatch(fireSuccessToaster('View saved successfully!'))
        }
      })
    )

    handleCloseSaveViewPopup()
  }, [groupBy])

  const handleMarkTaskAsComplete = useCallback(
    (val, task) => {
      markTaskAsComplete({
        value: val,
        task,
        elmFor: 'task',
        socketId,
        board: currentWorkspaceBoard
      })
    },
    [socketId, currentWorkspaceBoard]
  )

  const handleTaskSidePanel = useCallback(
    task => {
      history.push(
        `${getUserPath(meData.role)}/workspace/${task.workSpace}/board/${
          task.workspaceBoard
        }/task/${task._id}?view=board`
      )
    },
    [meData.role]
  )

  const afterTaskDelete = useCallback(
    ({ taskId, sectionId }) => {
      dispatch(removeTaskFromSection({ taskId, sectionId, groupBy }))
    },
    [groupBy]
  )

  const afterTaskStatusChange = useCallback(
    ({ taskId, prevSectionId, newSectionId }) => {
      dispatch(
        moveSectionTask({
          taskId: taskId,
          sourceId: prevSectionId,
          destinationId: newSectionId,
          groupBy
        })
      )
    },
    []
  )

  const afterDueDateChange = useCallback(
    ({ taskId, prevSectionId, nextSectionId }) => {
      dispatch(
        moveSectionTask({
          taskId: taskId,
          sourceId: prevSectionId,
          destinationId: nextSectionId,
          groupBy
        })
      )
    },
    [groupBy]
  )

  const afterCollaboratorsChange = useCallback(
    ({ taskId, prevSectionId, nextSectionId, updatedTask }) => {
      dispatch(
        moveSectionTask({
          taskId: taskId,
          sourceId: prevSectionId,
          destinationId: nextSectionId,
          groupBy
        })
      )

      // If section does not exist then add new section
      let idx = fetchedAssigneeSectionsData.findIndex(
        item => item._id === nextSectionId
      )

      if (idx === -1) {
        setFetchedAssigneeSectionsData(prev => [
          ...prev,
          {
            _id: nextSectionId,
            teammate: updatedTask.assignedAgencyTeam.concat(
              updatedTask.assignedClientTeam
            )
          }
        ])
      }
    },
    [groupBy, fetchedAssigneeSectionsData]
  )

  const handleScroll = e => {
    debounce(() => {
      columnRefs.current.forEach(col => col?.handleParentScroll(e.target))
    }, 300)
  }

  const toggleEditStatusModal = useCallback(() => {
    setIsStatusModalOpen(prev => !prev)
  }, [])

  const sections =
    groupBy === 'status'
      ? statusSections
      : groupBy === 'dueDate'
      ? dueDateSections
      : assigneeSections

  return (
    <Layout>
      {sections.loading || groupBy !== sections.data[0]?.type ? (
        <div className="px-2">
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <div
              className="flex w-full overflow-x-scroll"
              onScroll={handleScroll}
            >
              <Droppable
                droppableId="all-columns"
                direction="horizontal"
                type="column"
              >
                {(provided, snapshot) => (
                  <div
                    className="h-full"
                    // style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <div className="flex w-full h-full space-x-4">
                      {sections.data.map((item, index) => (
                        <Column
                          index={index}
                          column={item}
                          groupBy={groupBy}
                          workspaceComplete={currentWorkspace.isComplete}
                          categoryNameChange={categoryNameChange}
                          handleCategoryName={handleCategoryName}
                          handleChangeSectionTitle={handleChangeSectionTitle}
                          workspacePermission={workspacePermission}
                          showApprovalTasks={showApprovalTasks}
                          taskPermission={taskPermission}
                          handleDeleteWorkspaceSection={
                            handleDeleteWorkspaceSection
                          }
                          handleTaskSidePanel={handleTaskSidePanel}
                          // allTasksLoading={allTasksLoading}
                          handleMarkTaskAsComplete={handleMarkTaskAsComplete}
                          search={search}
                          sectionId={
                            groupBy === 'status'
                              ? // ? item.value
                                item._id
                              : currentWorkspaceBoard.boardSection[0]._id
                          } //is group by status send the current section id (value) else send the first section's id
                          filters={filters}
                          ref={columnRefs}
                          toggleEditStatusModal={toggleEditStatusModal}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
              {/* Show "Add New Category"  if group by is set to status */}
              {groupBy === 'status' && taskPermission.create && (
                <AddNewCategory
                  classes={classes}
                  order={currentWorkspaceBoard?.boardSection?.length}
                  isComplete={currentWorkspace?.isComplete}
                />
              )}
            </div>
          </DragDropContext>
          <TaskPanel
            taskData={taskPanel.data}
            open={taskPanel.open}
            onClose={handleCloseTaskSidePanel}
            afterTaskDelete={afterTaskDelete}
            afterTaskStatusChange={afterTaskStatusChange}
            afterDueDateChange={afterDueDateChange}
            afterCollaboratorsChange={afterCollaboratorsChange}
            // taskPermission={taskPermission}
            // subtaskPermission={subtaskPermission}
            groupBy={groupBy}
            // currentWorkspace={currentWorkspace}
            // currentBoard={currentWorkspaceBoard}
          />
        </>
      )}

      <DeleteModal
        warningText={`The Selected Task section named "${deleteSection.data.title}" and all its related task will be deleted forever.`}
        open={deleteSection.open}
        handleDialog={handleDeleteWorkspaceSection}
        handleDeleteAction={handleDeleteTaskSection}
        loading={deleteSection.loading}
      />
      <SaveViewPopup
        heading="Do you want to save this view?"
        message="This view will be saved as default view"
        open={openSaveViewPopup}
        onClose={handleCloseSaveViewPopup}
        onSave={handleSaveView}
      />

      <EditStatusModal
        open={isEditStatusModalOpen}
        board={currentWorkspaceBoard}
        onClose={toggleEditStatusModal}
        workspaceTitle={currentWorkspace.name}
      />
    </Layout>
  )
}

const AddNewCategory = React.memo(({ classes, isComplete, order }) => {
  const [addCategory, setAddCategory] = useState(false)
  const dispatch = useDispatch()
  const { workspaceId, boardId } = useParams()

  const toggleAddNewCategory = () => {
    if (isComplete) {
      dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
      return
    }

    setAddCategory(prev => !prev)
  }

  const handleBlurInput = e => {
    const label = e.target.value.trim()
    toggleAddNewCategory()
    if (!label) return

    dispatch(
      createWorkspaceSection({
        label,
        order,
        workSpace: workspaceId,
        workspaceBoard: boardId,
        color: getSectionColor(order)
      })
    )
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleBlurInput(event)
    }
  }

  return (
    <div className={clsx(classes.columnContainer, 'ml-4')}>
      {addCategory ? (
        <Box>
          <TextField
            autoFocus
            onBlur={handleBlurInput}
            className={classes.addNewCategoryBtn}
            onKeyPress={handleKeyPress}
          />
        </Box>
      ) : (
        <Button
          className={classes.addNewCategoryBtn}
          onClick={toggleAddNewCategory}
          variant="outlined"
          startIcon={<Add fontSize="small" />}
        >
          Add New Category
        </Button>
      )}
    </div>
  )
})

// const getListStyle = (isDraggingOver) => ({
//   // background: isDraggingOver ? 'lightblue' : 'transparent',
//   // padding: grid,
//   // width: 'inherit'
// })

const LoadingAnimation = () => {
  return (
    <div className="flex space-x-6">
      {Array(4)
        .fill('')
        .map((item, index) => (
          <div key={index} style={{ minWidth: 260 }}>
            <Skeleton className="rounded mb-3" height={50} variant="rect" />
            <Skeleton className="my-2 rounded" height={80} variant="rect" />
            <Skeleton className="my-2 rounded" height={80} variant="rect" />
            <Skeleton className="my-2 rounded" height={80} variant="rect" />
          </div>
        ))}
    </div>
  )
}

export default BoardView
