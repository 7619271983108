import React from 'react'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { Button } from '@material-ui/core'
import { ReactComponent as Stopwatch } from 'static/svg/stopwatch.svg'
import { ReactComponent as Tasks } from 'static/svg/tasks.svg'
import { ReactComponent as ProjectDiagram } from 'static/svg/project-diagram.svg'
import { ReactComponent as InvoiceIcon } from 'static/svg/sack-dollar.svg'
import { useSelector } from 'react-redux'
import { userRoles } from 'utils'

const WidgetModal = ({ open, onClose, handleAddNewWidget }) => {
  const meData = useSelector(state => state.me.data)
  const handleWidgetClick = e => {
    handleAddNewWidget(e.currentTarget.dataset.widget)
    onClose()
  }

  return (
    <CustomModal open={open} handleClose={onClose}>
      <CustomModalHeader heading="New Widget" />

      <CustomModalBody>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 120px)',
            gridAutoRows: 100
          }}
        >
          <WidgetOption
            label="Projects"
            icon={<ProjectDiagram className="w-6 h-6 mb-2 text-gray-600" />}
            handleWidgetClick={handleWidgetClick}
          />
          <WidgetOption
            label="Tasks"
            icon={<Tasks className="w-6 h-6 mb-2 text-gray-600" />}
            handleWidgetClick={handleWidgetClick}
          />

          {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
            meData.role
          ) && (
            <>
              <WidgetOption
                label="Timesheet"
                icon={<Stopwatch className="w-6 h-6 mb-2 text-gray-600" />}
                handleWidgetClick={handleWidgetClick}
              />

              <WidgetOption
                label="Client Requests"
                icon={<Tasks className="w-6 h-6 mb-2 text-gray-600" />}
                handleWidgetClick={handleWidgetClick}
              />
              <WidgetOption
                label="Standups"
                icon={<Tasks className="w-6 h-6 mb-2 text-gray-600" />}
                handleWidgetClick={handleWidgetClick}
              />
            </>
          )}
        </div>
      </CustomModalBody>

      <CustomModalFooter>
        <Button onClick={onClose}>Close</Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

const WidgetOption = ({ label, icon, handleWidgetClick }) => {
  return (
    <div
      className="hover:bg-gray-100 flex flex-col justify-center items-center  rounded cursor-pointer "
      data-widget={label.toLowerCase()}
      onClick={handleWidgetClick}
    >
      {icon}
      <span className="text-base lg:lg font-medium text-primary-main whitespace-nowrap">
        {label}
      </span>
    </div>
  )
}

export default WidgetModal
