import React from 'react'
import { ReactComponent as ChevronRightIcon } from 'static/svg/chevron-right.svg'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

const GoBack = ({ text = 'back', icon, className, ...rest }) => {
  const history = useHistory()

  const navigateBack = () => {
    history.goBack()
  }

  return (
    <button
      className={clsx(
        'text-primary-main flex items-center text-sm leading-3 space-x-1 hover:space-x-0',
        className
      )}
      {...rest}
      onClick={navigateBack}
    >
      {icon ? icon : <ChevronRightIcon className="rotate-180" />}
      <span className="font-medium transition-all">{text}</span>
    </button>
  )
}

export default GoBack
