import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'global/globalComponents'
import { format } from 'date-fns'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import overviewStyles from './overviewStyles'

const ProjectDates = ({ readOnly, workspace }) => {
  const dispatch = useDispatch()
  const classes = overviewStyles()
  const [loading, setLoading] = useState(true)
  const [workspaceData, setWorkspaceData] = useState({
    startDate: '',
    dueDate: ''
  })
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (workspace._id) {
      setLoading(false)
      setWorkspaceData({
        startDate: workspace.startDate,
        dueDate: workspace.dueDate
      })
    }
  }, [workspace])

  const handleDateChange = (e, prop) => {
    setWorkspaceData(prev => ({ ...prev, [prop]: e?.toISOString() }))
    const str = prop === 'startDate' ? 'start date' : 'due date'
    dispatch(
      updateWorkSpace1(
        workspace._id,
        { [prop]: e?.toISOString() },
        str,
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  return (
    <Card className="p-4 h-full">
      <div className="mb-2">
        <h4 className="capitalize text-sm font-semibold text-custom-gray-main">
          Project Dates
        </h4>
      </div>

      <div className="flex items-center space-x-4 text-gray-700 text-base">
        {!edit && !workspaceData.startDate && (
          <div className="text-smaller lg:text-sm text-gray-500">
            <span>View your project dates here.</span>{' '}
            {!readOnly && (
              <button
                className="text-primary-main border-collapse outline-none"
                onClick={() => setEdit(true)}
              >
                Set project date.
              </button>
            )}
          </div>
        )}
      </div>
      {(edit || workspaceData.startDate) && (
        <div className="flex items-center  text-gray-700 text-base">
          <div>
            {readOnly ? (
              <div className="text-base w-28">
                {Boolean(workspaceData.startDate)
                  ? format(new Date(workspaceData.startDate), 'dd MMM yyyy')
                  : ''}
              </div>
            ) : (
              <div>
                <DatePicker
                  onChange={date => handleDateChange(date, 'startDate')}
                  selected={
                    workspaceData.startDate
                      ? new Date(workspaceData.startDate)
                      : null
                  }
                  customInput={<input className="text-base w-28" />}
                  placeholderText="Start Date"
                  dateFormat="MMM d, yyyy"
                  popperPlacement="left"
                />
              </div>
            )}
          </div>
          <div className="">-</div>
          <div>
            {readOnly ? (
              <div className="text-base w-28 text-right">
                {Boolean(workspaceData.dueDate)
                  ? format(new Date(workspaceData.dueDate), 'dd MMM yyyy')
                  : ''}
              </div>
            ) : (
              <div>
                <DatePicker
                  onChange={date => handleDateChange(date, 'dueDate')}
                  selected={
                    workspaceData.dueDate
                      ? new Date(workspaceData.dueDate)
                      : null
                  }
                  customInput={<input className="text-base  text-right w-28" />}
                  placeholderText="Due Date"
                  dateFormat="MMM d, yyyy"
                  popperPlacement="left"
                  minDate={
                    workspaceData.startDate
                      ? new Date(workspaceData.startDate)
                      : null
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Card>
  )
}

export default ProjectDates
