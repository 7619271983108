import React, { useState, useEffect } from 'react'
import { Avatar, Tab, Tabs } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { format, formatDistance, subDays } from 'date-fns'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import CheckIcon from '@material-ui/icons/Check'
import { userRoles } from 'utils'
import workspaceStyles from '../workspaceStyles'
import { ReactComponent as Trash } from 'static/svg/trash.svg'
import { deleteComment } from 'thunks/logs/action'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import Pill from 'global/globalComponents/Pill/Pill'

const LogsSection = ({
  task,
  logsData,
  elmFor,
  selectedTab,
  setSelectedTab
}) => {
  // const logsData = useSelector((state) => state.logs.data)
  // const [selectedTab, setSelectedTab] = useState(0)
  const [comments, setComments] = useState([])
  const [logsHistory, setLogsHistory] = useState([])

  useEffect(() => {
    const c = []
    const h = []

    for (let item of logsData) {
      if (item.activityType === 'comment') {
        c.push(item)
      } else {
        h.push(item)
      }
    }

    setLogsHistory(h)
    setComments(c)
  }, [logsData])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  return (
    <div>
      <header className="px-4">
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {['Comments', 'History'].map((item, i) => (
            <Tab
              style={{ textTransform: 'capitalize' }}
              label={item}
              key={i}
              value={i}
            />
          ))}
        </Tabs>
        {/* <hr /> */}
      </header>

      <div className="px-8 py-8 bg-gray-100">
        {selectedTab === 0 ? (
          <Comments comments={comments} />
        ) : (
          <LogsHistory task={task} logsHistory={logsHistory} elmFor={elmFor} />
        )}
      </div>
    </div>
  )
}

const Comments = React.memo(({ comments }) => {
  const dispatch = useDispatch()

  const deleteCommentById = (id) => {
    dispatch(deleteComment(id))
  }

  return (
    <div className="pb-8">
      {comments.length ? (
        comments.map((item) => (
          <Comment
            key={item._id}
            comment={item}
            deleteOneComment={deleteCommentById}
          />
        ))
      ) : (
        <div className="flex flex-col items-center justify-center text-center">
          <div className="text-xl mb-1">(&gt;_&lt;)</div>
          <p className="text-sm">No comments found</p>
        </div>
      )}
    </div>
  )
})

const LogsHistory = React.memo(({ task, logsHistory, elmFor }) => {
  const classes = workspaceStyles()

  return (
    <div>
      <div className="relative flex items-start space-x-3 mb-3">
        {task.user && <ColoredAvatars user={task.user} tooltip={false} />}
        <div className="min-w-0 flex-1 self-center">
          <div>
            <div className={`flex font-medium text-sm items-center`}>
              <span>
                {' '}
                {!task.user?.isActive && task.user?.isDeleted
                  ? `${task.user?.name} (inactive)`
                  : task.user?.name ?? 'Deleted user'}
              </span>
              &nbsp;
              <p>created this {elmFor}</p>
              &nbsp;
              <span
                className={`text-gray-500 text-xs font-normal ${classes.timeAgo}`}
              >
                {task.createdAt && format(new Date(task.createdAt), 'MMM, dd')}
              </span>
            </div>
          </div>
        </div>
      </div>

      {logsHistory.map((item) => (
        <Log key={item._id} logItem={item} elmFor={elmFor} />
      ))}
    </div>
  )
})

const Comment = ({ comment, deleteOneComment }) => {
  const me = useSelector((state) => state.me.data)
  const classes = workspaceStyles()

  const {
    USER_AGENCY,
    AGENCY_MANAGER,
    AGENCY_EDITOR,
    AGENCY_VIEWER,
    AGENCY_ADMIN
  } = userRoles

  let permission = [
    USER_AGENCY,
    AGENCY_MANAGER,
    AGENCY_EDITOR,
    AGENCY_VIEWER,
    AGENCY_ADMIN
  ].includes(me?.role)
  if ((!comment?.isExternal && permission) || comment?.isExternal) {
    return (
      <div className="relative flex justify-center space-x-3 my-3">
        <ColoredAvatars
          classes={{ root: classes.avatarRoot }}
          alt=""
          user={comment.user || { name: 'unknown' }}
          tooltip={false}
        />

        <div
          className="min-w-0 flex-1 rounded-lg"
          // style={{
          //   backgroundColor: comment.isExternal
          //     ? 'rgb(209, 224, 224, 0.8)'
          //     : 'rgba(254, 226, 226)'
          // }}
        >
          <div className="text-sm flex items-center justify-between mb-1">
            <div className="text-sm flex items-center gap-2">
              <span className="font-semibold text-sm">
                {!comment.user?.isActive && comment.user?.isDeleted
                  ? `${comment.user?.name} (inactive)`
                  : comment.user?.name ?? 'Deleted user'}
              </span>
              {/* <span className="text-gray-400">&#8226;</span> */}

              {!comment.isExternal && (
                <span>
                  <Pill variant="red">Internal</Pill>
                </span>
              )}
              <p className="text-gray-500 text-xs">
                {formatDistance(
                  subDays(new Date(comment.createdAt), 0),
                  new Date(),
                  {
                    addSuffix: true
                  }
                )}
              </p>
            </div>
            {me._id === comment.user?._id && (
              <CustomTooltip title="Delete this comment" placement="top">
                <Trash
                  style={{
                    width: '10px',
                    height: '10px',
                    cursor: 'pointer'
                  }}
                  onClick={() => deleteOneComment(comment._id)}
                />
              </CustomTooltip>
            )}
          </div>

          <p
            className="text-sm text-gray-700"
            dangerouslySetInnerHTML={{
              __html: comment.description
            }}
          ></p>
        </div>
      </div>
    )
  } else {
    return null
  }
}

const Log = ({ logItem, elmFor }) => {
  const classes = workspaceStyles()

  return (
    <>
      {logItem.description
        .toLowerCase()
        .includes('changed the status as "Mark As Complete"') ? (
        <>
          <div className="relative flex items-start space-x-3 mb-3 mt-3">
            <Avatar
              classes={{ root: classes.logAvatar }}
              src={logItem.user?.profileImage}
              alt=""
            >
              <CheckIcon />
            </Avatar>

            <div className="min-w-0 flex-1 self-center">
              <div>
                <div
                  className={`flex font-medium text-sm items-center ${classes.logText}`}
                >
                  <span>
                    {!logItem.user?.isActive && logItem.user?.isDeleted
                      ? `${logItem.user?.name} (inactive)`
                      : logItem.user?.name ?? 'Deleted user'}
                  </span>
                  &nbsp;
                  <p className={classes.logText}>
                    marked this {elmFor} complete
                  </p>
                  &nbsp;
                  <span
                    className={`text-gray-500 text-xs font-normal ${classes.timeAgo}`}
                  >
                    {formatDistance(
                      subDays(new Date(logItem.createdAt), 0),
                      new Date(),
                      {
                        addSuffix: true
                      }
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-3" />
        </>
      ) : (
        <div className="text-xs pl-10 text-gray-600 mb-2">
          <span className="font-medium">
            {!logItem.user?.isActive && logItem.user?.isDeleted
              ? `${logItem.user?.name} (inactive)`
              : logItem.user?.name ?? 'Deleted user'}
          </span>{' '}
          <span
            dangerouslySetInnerHTML={{
              __html:
                logItem.category === 'task'
                  ? logItem.description.split('in the task <b>')[0]
                  : logItem.description.split('in the subtask <b>')[0]
            }}
          ></span>{' '}
          {logItem.activityType === 'tags' && (
            <span
              className="p-1 px-2 font-semibold capitalize"
              style={{
                color: logItem.tag.color,
                backgroundColor: `${logItem?.tag?.color}15`,
                borderRadius: '20px',
                fontSize: '10px'
              }}
            >
              {logItem.tag.name}
            </span>
          )}
          {logItem.description.includes('changed the title') && (
            <span>of this {elmFor}</span>
          )}
          {logItem.activityType === 'assignment' && (
            <span>
              {logItem.assignedUser?.map((person, index) => (
                <span className="font-medium text-gray-900">
                  {!person?.isActive && person?.isDeleted
                    ? `${person?.name} (inactive)`
                    : person?.name ?? 'Deleted user'}
                </span>
              ))}{' '}
            </span>
          )}
          <span
            className={`text-gray-500 text-xs font-normal ${classes.timeAgo}`}
          >
            {formatDistance(
              subDays(new Date(logItem.createdAt), 0),
              new Date(),
              {
                addSuffix: true
              }
            )}
          </span>
        </div>
      )}
    </>
  )
}

export default LogsSection
