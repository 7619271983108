import { List, ListItem, ListItemIcon, Popover } from '@material-ui/core'
import clsx from 'clsx'
import { recurringTime } from 'utils/taskStatus'
import workspaceStyles from './workspaceStyles'
import Check from '@material-ui/icons/Check'

const RecurringDropDown = (props) => {
  const { open, onClose, anchorEl, recurring, ...rest } = props
  const classes = workspaceStyles()
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      classes={{ paper: classes.popoverPaper }}
      {...rest}
    >
      <div>
        <List
          dense
          classes={{ root: classes.listRoot }}
          style={{ maxWidth: '280px', padding: '5px 0' }}
        >
          {Object.values(recurringTime)?.map(([item, value], index) => (
            <ListItem
              style={{
                padding: '3px 8px',
                backgroundColor: recurring === value && '#e2fffa'
              }}
              onClick={() => props.changeStatus(value)}
              key={item}
              button
            >
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                {recurring === value ? (
                  <Check fontSize="small" style={{ color: '#00bf9c' }} />
                ) : null}
              </ListItemIcon>
              <span className="text-sm rounded px-2 py-1">{item}</span>
            </ListItem>
          ))}
        </List>
      </div>
    </Popover>
  )
}
export default RecurringDropDown
