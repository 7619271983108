import React, { useState, useEffect } from 'react'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Tr,
  Td
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { useSelector, useDispatch } from 'react-redux'
import { PopupMenu, ToggleSwitch } from 'global/globalComponents'
import tooltipStyles from 'components/Sidebar/tooltipStyles'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  updateArchiveService,
  updateService,
  deleteService
} from 'thunks/service/actions'
import userServiceStyles from './userServiceStyles'
import Pill from 'global/globalComponents/Pill/Pill'
import { Button, Tooltip } from '@material-ui/core'
import { errorMessages } from 'utils'
import currency from 'static/Currency/Currency'
import Moment from 'react-moment'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { fetchAgencyStripeAccountId } from 'thunks/service/actions'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'

const totalServicesToShow = 8

const ServiceTable = ({
  search = '',
  handleOpenServiceModal,
  shareCheckoutLinkHandler
}) => {
  const dispatch = useDispatch()
  const [visibleServices, setVisibleServices] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const permission = useSelector(state => state.permission)
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])
  const userServices = useSelector(state => state.userServices)
  const userServicesData = userServices.data
  const [serviceAlert, setServiceAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })
  const [copy, setCopy] = useState('')
  // const [agencyStripeId, setAgencyStripeId] = useState('')
  // const [linkGenerated, setLinkGenerated] = useState(null)

  useEffect(() => {
    if (userServicesData.length) {
      if (search.length > 0) {
        let arr = [...userServicesData].filter(item =>
          item?.name.toLowerCase().includes(search.toLowerCase())
        )
        setVisibleServices(
          arr.splice(page * totalServicesToShow, totalServicesToShow)
        )
        setPaginationTotalDataCount(arr?.length)
      } else {
        setVisibleServices(
          [...userServicesData].splice(
            page * totalServicesToShow,
            totalServicesToShow
          )
        )
        setPaginationTotalDataCount(userServicesData.length)
      }
    } else {
      setVisibleServices([])
    }
  }, [userServicesData, page, search, paginationTotalDataCount])

  // useEffect(() => {
  //   dispatch(
  //     fetchAgencyStripeAccountId((res, err) => {
  //       if (!err) {
  //         setAgencyStripeId(res.accountId)
  //       }
  //     })
  //   )
  // }, [])

  const handleSwitch = item => {
    if (permission.loading) return

    if (permission.data.access?.services.update) {
      if (!item.isActive) {
        dispatch(fireErrorToaster('You have to unarchive your plan first'))
      } else {
        setToggleSwitchLoading(prevState => [...prevState, item._id])
        dispatch(
          updateService(
            item._id,
            {
              displayOnCatalog: item.displayOnCatalog == true ? false : true,
              name: item.name,
              description: item.description,
              offerings: item.offerings
            },
            switchCallback
          )
        )
      }
    } else {
      dispatch(fireErrorToaster('You are not an authorized user'))
    }
  }

  const openServiceAlert = data => {
    if (permission.data.access?.services.delete) {
      setServiceAlert({ open: true, loading: false, data })
    } else {
      dispatch(fireErrorToaster('You are not an authorized user'))
    }
  }

  const closeServiceAlert = () => {
    setServiceAlert(prev => ({ ...prev, open: false, loading: false }))
  }

  const handleDeleteService = () => {
    setServiceAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteService(serviceAlert.data._id, (res, err) => {
        setServiceAlert({ loading: false, data: {}, open: false })
        if (!err) {
          dispatch(fireSuccessToaster('Service Deleted Successfully!'))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  const switchCallback = (res, err, id) => {
    if (!err) {
      setToggleSwitchLoading(prevState =>
        prevState.filter(item => res._id !== item)
      )
    } else {
      setToggleSwitchLoading(prevState => prevState.filter(item => id !== item))
    }
  }

  return (
    <div>
      <TableContainer mb={6}>
        <Thead>
          <Th className="whitespace-nowrap">Name</Th>
          <Th className="text-right">Amount</Th>
          <Th className="whitespace-nowrap">Created At</Th>
          <Th className="text-center">Offerings</Th>
          <Th className="text-center whitespace-nowrap">Show in Catalog</Th>
          <Th className="text-center whitespace-nowrap">Checkout Link</Th>
          {(permission.data.access?.services?.update ||
            permission.data.access?.services?.delete) && <Th>Action</Th>}
        </Thead>
        <Tbody>
          {userServices.loading ? (
            <TableDataLoading cols={7} />
          ) : visibleServices.length > 0 ? (
            visibleServices.map(item => (
              <Row
                key={item?._id}
                item={item}
                access={permission.data.access}
                handleSwitch={handleSwitch}
                toggleSwitchLoading={toggleSwitchLoading}
                handleOpenServiceModal={handleOpenServiceModal}
                shareCheckoutLinkHandler={shareCheckoutLinkHandler}
                openServiceAlert={openServiceAlert}
                copy={copy}
                setCopy={setCopy}
                // agencyStripeId={agencyStripeId}
              />
              // <>
              //   {search.length > 0 &&
              //   item?.name.toLowerCase().includes(search.toLowerCase()) ? (
              //   ) : search.length == 0 ? (
              //     <Row
              //       key={item?._id}
              //       item={item}
              //       handleSwitch={handleSwitch}
              //       toggleSwitchLoading={toggleSwitchLoading}
              //       handleEditModal={handleEditModal}
              //       shareCheckoutLinkHandler={shareCheckoutLinkHandler}
              //     />
              //   ) : null}
              // </>
            ))
          ) : (
            <Tr className="bg-white">
              <td
                colSpan="8"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                There are no services
              </td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {!userServices.loading &&
        paginationTotalDataCount > totalServicesToShow && (
          <Pagination
            handlePagination={setPage}
            totalResultsCount={paginationTotalDataCount}
            visibleResultsCount={visibleServices.length}
            page={page}
            limit={totalServicesToShow}
          />
        )}
      <AlertModal
        warningText={`Do you want to delete ${serviceAlert.data.name}`}
        open={serviceAlert.open}
        handleDialog={closeServiceAlert}
        handleDeleteAction={handleDeleteService}
        loading={serviceAlert.loading}
      />
    </div>
  )
}

const Row = ({
  item,
  handleSwitch,
  access,
  toggleSwitchLoading,
  handleOpenServiceModal,
  openServiceAlert,
  shareCheckoutLinkHandler,
  copy,
  setCopy
  // agencyStripeId
}) => {
  const dispatch = useDispatch()
  const dateFormatter = date => {
    return date.slice(0, 10)
  }

  // useEffect(() => {
  //   setTimeout(() => setCopy(false), 10000)
  // }, [copy])

  const getMenuItems = () => {
    let arr = []
    if (access?.services.update) {
      arr.push({
        label: 'Edit',
        method: handleEditClick,
        icon: <EditIcon className="text-primary-main" />
      })
    }
    if (access?.services.update) {
      arr.push({
        label: 'Delete',
        method: handleDeleteClick,
        icon: <DeleteIcon className="text-primary-main" />
      })
    }
    return arr
  }

  /**
   * This functionality has been removed (needed in case of stripe service)
   */
  // const handleArchiveAction = () => {
  //   dispatch(
  //     updateArchiveService(
  //       item._id,
  //       { isActive: !item.isActive },
  //       archiveActionCallback
  //     )
  //   )

  //   // if (item.planId) {
  //   // dispatch(
  //   //   updateArchiveService(
  //   //     item.planId,
  //   //     { isActive: !item.isActive },
  //   //     archiveActionCallback
  //   //   )
  //   // )
  //   // } else {
  //   //   dispatch(fireErrorToaster('Non stripe service  cannot be archived'))
  //   // }
  // }

  const archiveActionCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
    }
  }

  const handleEditClick = () => {
    handleOpenServiceModal({ type: 'edit', data: item })
  }

  const handleDeleteClick = () => {
    openServiceAlert(item)
  }

  const handleCreateLink = () => {
    shareCheckoutLinkHandler(item)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.origin}/services/${item._id}/checkout`
    )
    // dispatch(fireSuccessToaster('URL Copied!'))
    setCopy(item._id)
  }

  return (
    <Tr key={item._id} className="">
      <Td className=" px-6 py-4 whitespace-nowrap">
        <div className="">
          <span className="text-primary-mid-dark font-medium">{item.name}</span>
          {/* <Pill
            variant={item.serviceType === 'stripe' ? 'blue' : 'yellow'}
            className="ml-1"
          >
            {item.serviceType}
          </Pill> */}
        </div>
        {/* <div className="text-custom-table-primaryText">
        </div> */}
      </Td>
      {/* <Td className="whitespace-nowrap text-center truncate text-gray-900">
        <Pill variant={item.serviceType === 'stripe' ? 'blue' : 'red'}>
          {item.serviceType}
        </Pill>
      </Td> */}
      <Td className="whitespace-nowrap text-right text-custom-gray-main">
        {item.currency && currency[item.currency.toUpperCase()]?.symbol_native}
        {item.amount * 0.01}
      </Td>
      <Td className="whitespace-nowrap text-xs text-custom-gray-main">
        <Moment format="MMM D, YYYY" date={dateFormatter(item.createdAt)} />
      </Td>
      {/* <Td
        className="whitespace-nowrap text-center truncate text-gray-900"
        style={{ maxWidth: '10px' }}
      >
        {item.description}
      </Td> */}
      <Td className="text-center text-xs text-custom-gray-main">
        <CustomTooltip
          title={item.offerings.length + ' offering(s) offered'}
          style={{ marginTop: '10px' }}
        >
          <span>{item?.offerings?.length}</span>
        </CustomTooltip>
      </Td>
      <Td className="text-center whitespace-nowrap">
        <ToggleSwitch
          checked={item.displayOnCatalog}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
          style={{ margin: '0 auto' }}
        />
      </Td>
      <Td className="text-center">
        <Button
          variant="outlined"
          // onClick={handleCreateLink}
          onClick={handleCopyLink}
          style={{ margin: '0 auto' }}
          className="whitespace-nowrap"
          size="small"
        >
          {/* Create Link */}
          {copy !== item._id && <CopyIcon fontSize="1rem" />}
          <span className="inline-block py-1 font-medium ml-2">
            {copy === item._id ? 'Copied!' : 'Copy Service URL'}
          </span>
        </Button>
      </Td>
      {(access?.services.update || access?.services.delete) && (
        <Td className="text-center whitespace-nowrap">
          <PopupMenu menuItems={getMenuItems()} />
        </Td>
      )}
    </Tr>
  )
}

/**
 * Custom tooltip
 * @param {Object} props Takes props of `Tooltip` of material ui
 */
const CustomTooltip = props => {
  const classes = tooltipStyles()
  return <Tooltip placement="top" arrow classes={classes} {...props} />
}

export default ServiceTable
