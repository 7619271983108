import {
  // Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { pushBreadcrumb, popBreadcrumb } from 'thunks/breadcrumbs/action'
import {
  fetchAllWebhookEvents,
  createNewWebhook,
  updateSingleWebhook,
  deleteSingleWebhook
} from 'thunks/webhooks/action'
import { LoadingButton } from 'global/globalComponents'
import { useParams, useLocation } from 'react-router'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import BreadCrumbs from 'global/globalComponents/Breadcrumbs'
import LockedFeatureOverlay from 'components/LockedFeatureOverlay/LockedFeatureOverlay'
// import { useBreadcrumbs } from 'customHooks'
import { useHistory } from 'react-router-dom'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'

const slackHookUrl = 'https://hooks.slack.com/services'

const WebhookPage = props => {
  const { webhookType = '', webhookId } = props
  // const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { webhookId: editingWebhookId } = useParams()

  const userActivePlan = useSelector(state => state.userActivePlanInfo.data)
  const { allEvents, createdWebhook, slackData } = useSelector(
    state => state.userWebhooks
  )

  const [newWebhook, setNewWebhook] = useState(true)
  const [loading, setLoading] = useState({
    events: false,
    saving: false,
    deleting: false
  })
  const [searchField, setSearchField] = useState('')
  const [newIntegrationData, setNewIntegrationData] = useState({
    name: webhookType === 'slack' ? 'slack' : '',
    events: [],
    endpoint: '',
    type: webhookType === 'slack' ? 'slack' : 'custom'
  })

  useEffect(() => {
    dispatch(fetchAllWebhookEvents())
  }, [])

  useEffect(() => {
    if (editingWebhookId) {
      setNewWebhook(false)
      const currentWebhook = createdWebhook.find(
        i => i._id === editingWebhookId
      )
      if (currentWebhook) {
        setNewIntegrationData({
          name: currentWebhook.name,
          events: currentWebhook.events,
          endpoint: currentWebhook.endpoint
        })
      }
    } else if (webhookType === 'slack') {
      if (!webhookId) {
        setNewWebhook(true)
      } else {
        setNewWebhook(false)
        if (slackData) {
          setNewIntegrationData({
            name: slackData.name,
            events: slackData.events,
            endpoint: slackData.endpoint
          })
        }
      }
    }
  }, [editingWebhookId])

  const handleSearchInput = e => {
    setSearchField(e.target.value)
  }

  const handleIntegrationTextInput = e => {
    const { name, value } = e.target
    setNewIntegrationData(prev => ({ ...prev, [name]: value }))
  }

  const handleCheckFields = e => {
    const { checked, name } = e.target
    if (checked)
      setNewIntegrationData(prev => ({
        ...prev,
        events: [...prev.events, name]
      }))
    else
      setNewIntegrationData(prev => ({
        ...prev,
        events: prev.events.filter(i => i !== name)
      }))
  }

  const handleCreateNewWebhook = () => {
    if (newIntegrationData.endpoint.trim() === '') {
      return dispatch(fireErrorToaster('URL is required!'))
    }

    setLoading(prev => ({ ...prev, saving: true }))

    if (newWebhook) {
      if (webhookType === 'slack') {
        if (newIntegrationData.endpoint.includes(slackHookUrl)) {
          dispatch(
            createNewWebhook(newIntegrationData, createNewWebhookCallback)
          )
        } else {
          setLoading(prev => ({ ...prev, saving: false }))
          dispatch(fireErrorToaster('This is not a valid slack URL'))
          return false
        }
      } else {
        dispatch(createNewWebhook(newIntegrationData, createNewWebhookCallback))
      }
    } else {
      dispatch(
        updateSingleWebhook(
          editingWebhookId || webhookId,
          newIntegrationData,
          updateWebhookCallback
        )
      )
    }
  }

  const updateWebhookCallback = (res, err) => {
    setLoading(prev => ({ ...prev, saving: false }))

    if (!err) {
      dispatch(fireSuccessToaster('Updated Successfully'))
    } else dispatch(fireErrorToaster(res))
  }

  const createNewWebhookCallback = (res, err) => {
    setLoading(prev => ({ ...prev, saving: false }))
    if (!err) {
      dispatch(fireSuccessToaster('Webhook Added Successfully'))
      history.push('/agency/settings/integrations/webhooks')
    } else dispatch(fireErrorToaster(res))
  }

  const handleDeleteWebhook = () => {
    setLoading(prev => ({ ...prev, deleting: true }))
    dispatch(
      deleteSingleWebhook(editingWebhookId || webhookId, deleteWebhookCallback)
    )
  }

  const deleteWebhookCallback = (res, err) => {
    setLoading(prev => ({ ...prev, deleting: false }))
    if (!err) {
      dispatch(fireSuccessToaster('Webhook Deleted Successfully'))
      history.push('/agency/settings/integrations/webhooks')
    } else dispatch(fireErrorToaster(res))
  }

  return (
    <OuterContentWrapper>
      <SectionHeading className="mb-6 lg:mb-8">
        {webhookType === 'slack' ? (
          <div className="flex items-center">
            <span>Slack Setup</span>

            {/* <img
              src={
                webhookType === 'slack'
                  ? 'https://s3.wasabisys.com/assets.clientvenue.com/other/Slack_RGB.png'
                  : 'https://api.useinfluence.co/images/webhooksBtnIcon.svg'
              }
              className="h-10 w-auto"
              alt="webhook"
            /> */}
          </div>
        ) : (
          'Webhook Setup'
        )}
      </SectionHeading>

      <Wrapper>
        <div className="grid grid-cols-8 gap-2 ">
          <div className="col-span-3">
            <h4 className="font-medium text-base">URL</h4>
            <p className="text-sm mt-2">
              Address where the POST request will be sent.
            </p>
          </div>
          <div className="col-span-5">
            <TextField
              type="url"
              placeholder="http://mysite.com/some/page"
              classes={{ root: 'w-full bg-white' }}
              onChange={handleIntegrationTextInput}
              name="endpoint"
              value={newIntegrationData.endpoint}
            />
          </div>
        </div>

        <div className="grid grid-cols-8 gap-2 mt-10">
          <div className="col-span-3">
            <h4 className="font-medium text-base ">Events</h4>
            <p className="text-sm mt-2">Select events for this webhook.</p>
          </div>
          <div className="col-span-5">
            <p className="text-sm mb-1">
              Events Selected: {newIntegrationData.events.length}
            </p>
            <div className="border border-custom-gray-border rounded p-4">
              <form onSubmit={e => e.preventDefault()}>
                <TextField
                  placeholder="Search Events"
                  classes={{ root: 'w-full' }}
                  onChange={handleSearchInput}
                  endAdornment={
                    <InputAdornment position="end">
                      <Search fontSize="small" />
                    </InputAdornment>
                  }
                />
              </form>
              <div className="h-96 overflow-y-auto mt-4">
                {searchField
                  ? allEvents
                      .filter(i =>
                        i.name
                          .toLocaleLowerCase()
                          .includes(searchField.toLocaleLowerCase())
                      )
                      .map(field => (
                        <div>
                          <FormControlLabel
                            name={field._id}
                            onChange={handleCheckFields}
                            checked={newIntegrationData.events.includes(
                              field._id
                            )}
                            label={
                              <span className="text-xs font-medium text-gray-700 ">
                                {field.name}
                              </span>
                            }
                            control={
                              <Checkbox
                                // onChange={handleCheckEverything}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                              />
                            }
                          />
                        </div>
                      ))
                  : allEvents.map(field => (
                      <div>
                        <FormControlLabel
                          name={field._id}
                          onChange={handleCheckFields}
                          checked={newIntegrationData.events.includes(
                            field._id
                          )}
                          label={
                            <span className="text-xs font-medium text-gray-700 ">
                              {field.name}
                            </span>
                          }
                          control={
                            <Checkbox
                              // onChange={handleCheckEverything}
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                            />
                          }
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
        {webhookType !== 'slack' && (
          <div className="grid grid-cols-8 gap-2 mt-10">
            <div className="col-span-3">
              <h4 className="font-medium text-lg ">Integration Name</h4>
              <p className="text-sm mt-2">Name this integration</p>
            </div>
            <div className="col-span-5">
              <TextField
                placeholder="Name"
                name="name"
                onChange={handleIntegrationTextInput}
                value={newIntegrationData.name}
                classes={{ root: 'w-full bg-white' }}
              />
            </div>
          </div>
        )}
        <div className="flex items-center justify-end space-x-2 mt-4">
          <LoadingButton
            onClick={handleCreateNewWebhook}
            loading={loading.saving}
          >
            {newWebhook ? 'Save' : 'Update'}
          </LoadingButton>
          {!newWebhook && (
            <LoadingButton
              onClick={handleDeleteWebhook}
              loading={loading.deleting}
            >
              Delete
            </LoadingButton>
          )}
        </div>
      </Wrapper>
      <LockedFeatureOverlay active={userActivePlan?.name?.includes('Lite')} />
    </OuterContentWrapper>
  )
}

export default WebhookPage
