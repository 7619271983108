import React from 'react'
import clsx from 'clsx'

const CustomTabs = ({ tabsData, value, onChange, count = true }) => {
  return (
    <div className="w-full bg-white shadow-lg rounded-xl shadow px-6 lg:px-8">
      <div className="flex space-x-8">
        {tabsData.map(item => (
          <SingleTab
            key={item.label}
            item={item}
            selectedValue={value}
            onChange={onChange}
            count={count}
          />
        ))}
      </div>
    </div>
  )
}

const SingleTab = ({ item, selectedValue, onChange, count }) => {
  const handleTabChange = () => {
    if (selectedValue === item.value) return
    onChange(item.value, item.label.toLowerCase())
  }

  return (
    <div
      className={clsx(
        'pt-4 font-medium cursor-pointer select-none',
        selectedValue === item.value
          ? 'text-primary-main border-primary-main'
          : 'text-primary-mid-dark-2'
      )}
      role="button"
      onClick={handleTabChange}
    >
      <span className="mb-3 inline-block text-smaller lg:text-sm">
        {item.label}{' '}
        {count && item.number !== undefined && (
          <span
            className={clsx(
              'text-smaller lg:text-sm',
              selectedValue === item.value
                ? 'text-primary-main'
                : 'text-gray-400'
            )}
          >
            ({item.number ?? 0})
          </span>
        )}
      </span>

      {selectedValue === item.value && (
        <div
          className="bg-primary-main w-full rounded-t w-11/12 mx-auto"
          style={{ height: 4 }}
        ></div>
      )}
    </div>
  )
}

export default React.memo(CustomTabs)
