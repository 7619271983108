import { useEffect, useState } from 'react'
import axios from 'axios'
import clsx from 'clsx'
import { CircularProgress, TextField, Typography } from '@material-ui/core'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import { useDispatch } from 'react-redux'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { ClientVenueLogo, Clientvenue_logo } from 'static/Images/index'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import Testimonial from 'global/globalComponents/Testimonial/Testimonial'
import { userRoles } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import { userSignup } from 'thunks/signup'
import { createFreePlan } from 'thunks/agencyPayment/actions'
import { couponValidation } from 'thunks/couponValidation/couponValidation'
import GeneralFormModal from './generalformModal'
import useTitle from 'customHooks/useTitle'
import signupStyles from './signupStyles'

const PitchgroundSignup = () => {
  const classes = signupStyles()
  const dispatch = useDispatch()
  useTitle()
  const [domain, setDomain] = useState('')
  const [open, setOpen] = useState(false)
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(true)
  const [enterCode, setEnteredCode] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [codeStatus, setCodeStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })
  const [userDomainStatus, setUserDomainStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })
  const [waitingModalStaus, setWaitingModalStatus] = useState({
    open: false,
    time: null
  })
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    brandName: '',
    website: '',
    role: userRoles.USER_AGENCY,
    phoneNumber: '',
    coupen: '',
    type: 'normal'
  })

  useEffect(() => {
    // console.log('disable')
    if (userDomainStatus) {
      if (
        formData.name === '' ||
        formData.email === '' ||
        formData.password === '' ||
        formData.phoneNumber === '' ||
        formData.brandName === '' ||
        domain === '' ||
        enterCode === ''
      ) {
        setDisable(true)
      } else {
        setDisable(false)
      }
    } else {
      setDisable(true)
    }
  }, [
    formData,
    enterCode,
    domain,
    codeStatus,
    userDomainStatus,
    confirmPassword
  ])

  const handleDomain = (e) => {
    const value = e.target.value.trim().toLowerCase()
    if (/^([\w\d]-?)*$/.test(value)) {
      setDomain(value)
    }
  }

  const handleCode = (e) => {
    setCodeStatus({
      loading: false,
      message: undefined,
      available: false
    })
    setEnteredCode(e.target.value)
  }

  // const confirmPasswordHandler = () => {
  //   if (formData.password !== '' && confirmPassword !== '') {
  //     formData.password !== confirmPassword &&
  //       dispatch(
  //         fireErrorToaster('Password does not match with confirm password')
  //       )
  //   }
  // }

  const handleSubmitDomain = async () => {
    setFormData((prev) => ({ ...prev, brandName: '' }))
    if (domain.length > 0) {
      setUserDomainStatus((props) => ({
        ...props,
        loading: true,
        message: 'Checking',
        available: undefined
      }))
      try {
        const res = await axios({
          method: 'GET',
          url: `/user/checkagencydomain?domain=${domain}.clientvenue.com`,
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if (res.data.domainExist) {
          setUserDomainStatus({
            loading: false,
            message: 'Not Available',
            available: false
          })
          dispatch(fireErrorToaster(`${domain} domain already exist.`))
        } else {
          setFormData((prev) => ({ ...prev, brandName: domain }))
          setUserDomainStatus({
            loading: false,
            message: 'Available',
            available: true
          })
        }
      } catch (err) {
        setUserDomainStatus({
          loading: false,
          message: 'Not Available',
          available: false
        })
      }
    } else {
      setUserDomainStatus((props) => ({
        ...props,
        message: undefined,
        available: undefined
      }))
    }
  }

  const couponValidationCallback = (res, err) => {
    if (err) {
      setLoading(false)
      setCodeStatus({
        loading: false,
        message: res,
        available: false
      })
    } else {
      setCodeStatus({
        loading: false,
        message: res.message,
        available: true
      })
      userSignup(
        {
          ...formData,
          source: window.location.href,
          coupen: enterCode,
          agencyDomain: domain + '.clientvenue.com'
        },
        signupCallback
      )
    }
  }

  const formInputHandler = (e) => {
    const { name, value } = e.target
    setFormData((oldState) => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validEmailAddress = () => {
    let validEmail = /.+@.+\..+/.test(formData.email)
    if (!validEmail) {
      dispatch(fireErrorToaster('please enter valid email address'))
      return false
    } else {
      return true
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const phoneNumberIputHandler = (valid, phone, country) => {
    setFormData((oldData) => ({
      ...oldData,
      phoneNumber: phone.length > 0 ? '+' + country.dialCode + ' ' + phone : ''
    }))
  }

  const userSignupHandler = (e) => {
    e.preventDefault()
    if (!userDomainStatus.available) {
      return dispatch(fireErrorToaster('Please enter the valid domain'))
    }
    if (formData.password !== confirmPassword) {
      setLoading(false)
      return dispatch(
        fireErrorToaster('Password does not match with confirm password')
      )
    }
    if (!validEmailAddress()) {
      return dispatch(fireErrorToaster('please enter valid email address'))
    }
    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }
    setLoading(true)
    setCodeStatus({
      loading: true,
      message: 'Checking',
      available: false
    })
    couponValidation(enterCode, couponValidationCallback)
  }

  const getCouponPlan = async (token) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/coupen/user`,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        }
      })
      return res
    } catch (err) {
      dispatch(fireErrorToaster('Unsuccessful Coupon request'))
    }
  }

  const modalCloseHandler = () => {
    setOpen(false)
  }

  const signupCallback = async (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      try {
        let plan = await getCouponPlan(res.login.token)
        if (plan.status === 200) {
          const obj = {
            email: formData.email,
            plan: plan.data
          }

          // const freePlanres = await createFreePlan(obj, res.login.token)
          // setLoading(false)
          // setWaitingModalStatus({ open: true, time: 40 })
          // storeToken(res.login.token)
          setOpen(true)
          setResponse({
            freePlansObj: obj,
            token: res?.login?.token,
            id: res?.profile?._id,
            domain: res?.agencyDomain,
            path: res?.path,
            brandName: res?.profile?.brand?.brandName
          })
          dispatch(fireSuccessToaster('Signup successful'))

          // const intervalTimer = setInterval(() => {
          //   setWaitingModalStatus((prev) => ({
          //     ...prev,
          //     time: prev.time - 1
          //   }))
          // }, 1000)

          // setTimeout(() => {
          //   window.open(
          //     `https://${freePlanres.agencyDomain}${
          //       freePlanres.path
          //     }?signup=true&auth_token=${res.login.token.split(' ')[1]}`,
          //     '_self'
          //   )
          //   clearInterval(intervalTimer)
          // }, 40000)
        } else {
          dispatch(fireErrorToaster('Unsuccessful Coupon request'))
        }
      } catch (err) {
        dispatch(fireErrorToaster('Unsuccessful'))
        return
      }
    }
  }

  return (
    <div className="w-full h-screen grid grid-cols-5">
      <div className="col-span-5 md:col-span-3 p-4 md:p-12">
        <header className="flex flex-col items-center mb-6">
          <div className="text-center h-12">
            <img
              src={ClientVenueLogo}
              alt="logo"
              target="_self"
              className="h-full"
            />
          </div>

          {/* <h1 className="text-3xl font-medium">Sign in to your account</h1> */}
        </header>
        <div className="flex items-center mx-auto">
          <h2 className="text-2xl font-medium flex items-center mx-auto">
            Lifetime Access!
          </h2>
        </div>
        <form
          onSubmit={userSignupHandler}
          className="mt-4 py-4 w-full md:w-3/4 mx-auto space-y-4 bg-white shadow rounded p-8"
        >
          <div>
            <FormLabel required htmlFor="name">
              Name
            </FormLabel>
            <TextField
              onChange={formInputHandler}
              placeholder="Name"
              required
              id="name"
              name="name"
              value={formData.name}
              className="w-full"
            />
          </div>
          <div>
            <FormLabel required htmlFor="email">
              Email
            </FormLabel>
            <TextField
              onChange={formInputHandler}
              required
              value={formData.email}
              placeholder="Email"
              id="email"
              className="w-full"
              name="email"
              onBlur={validEmailAddress}
            />
          </div>
          <div>
            <FormLabel required htmlFor="phone">
              Phone Number
            </FormLabel>
            <IntlTelInput
              name="phone"
              onPhoneNumberChange={phoneNumberIputHandler}
              style={{
                border: '1px solid hsl(0,0%,80%)',
                borderRadius: '3px',
                width: '100%',
                height: '2.1rem',
                padding: '0.2rem',
                fontSize: '14px',
                fontWeight: 'normal'
              }}
              placeholder="Phone Number"
              className={classes.signinInput}
            />
          </div>
          <div>
            <FormLabel required htmlFor="agencyDomain">
              Agency Domain
            </FormLabel>
            <div className="flex h-8" style={{ margin: '0 0 1.425rem' }}>
              <div
                className="flex flex-row p-0 m-0 rounded-sm pr-4 items-center"
                style={{
                  border: '1px solid hsl(0,0%,80%)',
                  width: '100%',
                  height: '2.375rem',
                  borderRadius: '0.375rem'
                }}
              >
                <TextInput
                  required
                  value={domain}
                  inputProps={{ onBlur: handleSubmitDomain }}
                  placeholder="Agency Domain"
                  onChange={handleDomain}
                  style={{
                    border: 'none',
                    margin: '0px',
                    height: '2rem',
                    textTransform: 'lowercase'
                  }}
                />
                <Typography
                  style={{
                    alignSelf: 'center',
                    fontSize: '12px',
                    color: '#778ca2'
                  }}
                >
                  .clientvenue.com
                </Typography>
              </div>
              {userDomainStatus.message && (
                <div className="flex items-center gap-1 ml-4">
                  {userDomainStatus.loading && (
                    <CircularProgress value={0} size={15} />
                  )}
                  <p
                    className={clsx('font-medium text-xs', {
                      'text-red-500': !userDomainStatus.available,
                      'text-green-500': userDomainStatus.available,
                      'text-blue-600': userDomainStatus.loading
                    })}
                  >
                    {userDomainStatus.message}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div>
            <FormLabel required htmlFor="company">
              Company Name
            </FormLabel>
            <TextField
              required
              placeholder="Company name"
              className="w-full"
              name="brandName"
              id="company"
              value={formData.brandName}
              onChange={formInputHandler}
            />
          </div>

          <div>
            <FormLabel required>Password</FormLabel>
            <TextField
              required
              placeholder="Password"
              name="password"
              className="w-full"
              value={formData.password}
              type="password"
              onChange={formInputHandler}
              onBlur={validPasswordHandler}
            />
            {formData.password && !passwordValid && (
              <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                Password should be a combination of an uppercase/lowercase
                letter, a number, a symbol and length between 8 to 20.
              </p>
            )}
          </div>

          <div>
            <FormLabel required>Confirm Password</FormLabel>
            <TextField
              required
              placeholder="Confirm Password"
              name="confirm password"
              className="w-full"
              value={confirmPassword}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              // onBlur={confirmPasswordHandler}
            />
          </div>

          <div>
            <FormLabel required htmlFor="agencyDomain">
              Coupon
            </FormLabel>
            <div className="flex h-8" style={{ margin: '0 0 1.425rem' }}>
              <div
                className="flex flex-row p-0 m-0 rounded-sm pr-4 items-center"
                style={{
                  border: '1px solid hsl(0,0%,80%)',
                  width: '100%',
                  height: '2.375rem',
                  borderRadius: '0.375rem'
                }}
              >
                <TextInput
                  required
                  value={enterCode}
                  placeholder="Coupon"
                  onChange={handleCode}
                  style={{
                    border: 'none',
                    margin: '0px',
                    height: '2rem'
                  }}
                />
              </div>
              {codeStatus.message && (
                <div className="flex items-center gap-1 ml-4">
                  {codeStatus.loading && (
                    <CircularProgress value={0} size={15} />
                  )}
                  <p
                    className={clsx('font-medium text-xs', {
                      'text-red-500': !codeStatus.available,
                      'text-green-500': codeStatus.available,
                      'text-blue-600': codeStatus.loading
                    })}
                  >
                    {codeStatus.message}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div>
            <LoadingButton
              onClick={userSignupHandler}
              style={{ width: '100%' }}
              loading={loading}
              disabled={disable}
            >
              Sign Up
            </LoadingButton>
          </div>
        </form>
      </div>

      <div className="col-span-0 flex flex-col justify-center md:col-span-2 bg-primary-light">
        <div className="fixed hidden px-12 md:block">
          <Testimonial />
        </div>
      </div>
      <GeneralFormModal
        open={open}
        handleClose={modalCloseHandler}
        data={response}
      />
    </div>
  )
}

export default PitchgroundSignup
