import axios from 'axios'
import { catchAsync, errorMessages } from 'utils'
import { FILES_ASSETS } from './reducers'
import { Uploader, userRoles } from 'utils'

const uploaders = {
  data: [],
  remove(uid) {
    this.data = this.data.filter((item) => item.file.uid !== uid)
  },
  addNew(uploaderData) {
    this.data.push(uploaderData)
  }
}

/**
 * Fetch Folders
 * @param {String} workspace workspace Id
 * @param {String} parent parent folder id
 * @param {Function} callback callback function
 */
export const fetchFolders = (workspace, parent, cb) => {
  return catchAsync(async (dispatch, getState) => {
    const { me } = getState()
    const res = await axios({
      method: 'GET',
      url: `/fileassetsfolder/workspace/${workspace}?parent=${parent}`
    })

    // If the user is a client then don't store internal folder
    if (me.data.role >= userRoles.USER_CLIENT && parent === '0') {
      dispatch({
        type: FILES_ASSETS.FOLDERS_FETCHED,
        payload: {
          parent,
          workspace,
          data: res.data.filter(
            (item) => item.name.toLowerCase() !== 'internal'
          )
        } // expected res => [], [...]
      })
    } else {
      dispatch({
        type: FILES_ASSETS.FOLDERS_FETCHED,
        payload: {
          parent,
          workspace,
          data: res.data
        } // expected res => [], [...]
      })
    }

    if (cb) cb(res.data)
  }, cb)
}

/**
 * Fetch Folders
 * @param {String} workspace workspace Id
 * @param {Function} callback callback function
 */
export const createRootFolders = (workspace, cb) => {
  return catchAsync(async (dispatch) => {
    const res = await axios.all([
      axios({
        method: 'POST',
        url: `/fileassetsfolder`,
        data: {
          name: 'Private',
          workspace,
          parent: '0'
        }
      }),
      axios({
        method: 'POST',
        url: `/fileassetsfolder`,
        data: {
          name: 'Shared',
          workspace,
          parent: '0'
        }
      })
    ])

    dispatch({
      type: FILES_ASSETS.ADD_FOLDER,
      payload: {
        parent: '0',
        workspace: workspace,
        data: res[0].data
      }
    })

    dispatch({
      type: FILES_ASSETS.ADD_FOLDER,
      payload: {
        parent: '0',
        workspace: workspace,
        data: res[1].data
      }
    })

    if (cb) cb('Feature activated!')
  }, cb)
}

/**
 * Fetch Files
 * @param {Object} data folder name, workspace id, parent folder id
 * @param {Function} callback optional
 */
export const createFolder = (data, callback) => {
  return catchAsync(async (dispatch) => {
    const res = await axios({
      method: 'POST',
      url: '/fileassetsfolder',
      data
    })

    dispatch({
      type: FILES_ASSETS.ADD_FOLDER,
      payload: {
        parent: data.parent,
        workspace: data.workspace,
        data: res.data
      }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Update folder by id
 * @param {String} id folder id
 * @param {Object} data name
 * @param {Function} callback callback function
 */
export const updateFolderById = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/fileassetsfolder/${id}`,
        data
      })

      dispatch({
        type: FILES_ASSETS.RENAME_FOLDER,
        payload: {
          parent: res.data.parent,
          data: res.data
        }
      })

      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE)
    }
  }
}

/**
 * Update file by id
 * @param {String} id folder id
 * @param {Object} data name
 * @param {Function} callback callback function
 */
export const updateFileById = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/fileassetsfile/${id}`,
        data
      })

      dispatch({
        type: FILES_ASSETS.RENAME_FILE,
        payload: {
          data: res.data,
          parent: res.data.fileAssetsFolder
        }
      })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          callback(
            err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
        )
    }
  }
}

/**
 * Delete Folder
 * @param {String} id folder id
 * @param {Function} callback callback function
 */
export const deleteFolder = (id, callback) => {
  return catchAsync(async (dispatch) => {
    const res = await axios({
      method: 'DELETE',
      url: `/fileassetsfolder/${id}`
    })

    dispatch({
      type: FILES_ASSETS.DELETE_FOLDER,
      payload: {
        parent: res.data.parent,
        data: res.data
      }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch Files
 * @param {String} parent parent folder id
 * @param {Function} callback callback function
 */
export const fetchFiles = (parent, cb) => {
  return catchAsync(async (dispatch) => {
    const res = await axios({
      method: 'GET',
      url: `/fileassetsfile/folder/${parent}`
    })

    dispatch({
      type: FILES_ASSETS.FILES_FETCHED,
      payload: { parent, data: res.data }
    })
    if (cb) cb(res.data)
  }, cb)
}

/**
 * Upload File
 * @param {Object} data {name, size, file, fileAssetsFolder, extension, mimeType}
 * @param {Function} callback callback function
 */
export const uploadFile = (data, callback) => {
  return (dispatch) => {
    let percentage = undefined

    const uploader = new Uploader(data)

    uploaders.addNew({ uploader, file: data })

    dispatch({
      type: FILES_ASSETS.UPLOADING_FILE,
      payload: {
        name: data.fileName,
        extension: data.file.name.slice(data.file.name.lastIndexOf('.')),
        percentage: 0,
        uid: data.uid
      }
    })

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage
          dispatch({
            type: FILES_ASSETS.UPDATE_PERCENTAGE,
            payload: { uid: data.uid, percentage, isComplete: false }
          })
        }
      })
      .onError((error) => {
        callback(error.message ?? 'File uploading failed!', true)
        dispatch({ type: FILES_ASSETS.REMOVE_UPLOADING, payload: data.uid })
        uploaders.remove(data.uid)
      })
      .onComplete((res) => {
        callback(res)
        dispatch({
          type: FILES_ASSETS.UPLOADING_COMPLETE,
          payload: data.uid
        })
        dispatch({
          type: FILES_ASSETS.ADD_FILE,
          payload: {
            parent: res.fileAssetsFolder,
            data: res
          }
        })
        uploaders.remove(data.uid)
      })

    uploader.start()
  }
}

export const cancelFileUpload = (uid) => {
  return (dispatch) => {
    const uploaderObj = uploaders.data.find((item) => item.file.uid === uid)

    if (uploaderObj) {
      uploaderObj.uploader.abort()
    }
  }
}

/**
 * Delete File
 * @param {String} id file id
 * @param {Function} callback callback function
 */
export const deleteFile = (id, callback) => {
  return catchAsync(async (dispatch) => {
    const res = await axios({
      method: 'DELETE',
      url: `/fileassetsfile/${id}`
    })

    dispatch({
      type: FILES_ASSETS.DELETE_FILE,
      payload: {
        parent: res.data.fileAssetsFolder._id,
        data: res.data
      }
    })
    if (callback) callback(res.data)
  }, callback)
}

/**
 * Fetch all files and assets by task id
 * @param {String} taskId task id
 * @param {Function} callback callback function. arguments (response, errStatus)
 */
export const fetchFileAndAssetsBytask = (taskId, callback) => {
  return async (dispatch) => {
    dispatch({ type: FILES_ASSETS.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: `/fileassets/task/${taskId}`
      })
      dispatch({ type: FILES_ASSETS.FETCHED_BY_TASK, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: FILES_ASSETS.LOADING_ERROR, payload: err.response })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete a single
 * @param {String} id file asset id
 * @param {Function} callback callback function. arguments (response, errStatus)
 */
export const deleteOneFileAsset = (id, callback) => {
  return async (dispatch) => {
    dispatch({ type: FILES_ASSETS.DELETING_A_FILE, paylaod: id })
    try {
      const res = await axios({
        url: `/fileassets/${id}`,
        method: 'DELETE'
      })

      dispatch({ type: FILES_ASSETS.DELETED_A_FILE, payload: id })
      callback(res.data, false)
    } catch (err) {
      dispatch({ type: FILES_ASSETS.DELETING_ERROR, payload: err.response })
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Creates the default empty version object
 * @param {Objeect} data default data
 **/
export const createEmptyVersion = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/fileassets',
        method: 'POST',
        data
      })
      dispatch({
        type: FILES_ASSETS.CREATE_EMPTY_FIRST_VERSION,
        payload: res.data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Uploads a single file to a specific version
 * @param {String} verionId fileAsset version id
 * @param {Object} data api object
 * @param {String} data.fileName filename
 * @param {String} data.url file in base64 value
 * @param {Function} callback cb function
 */
export const singleFileUpload = (verionId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/fileassets/uploadimage/${verionId}`,
        method: 'PUT',
        data
      })
      dispatch({
        type: FILES_ASSETS.UPLOAD_SINGLE_FILE,
        payload: res.data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Create a new version from old one
 * @param {Object} data file assets object with files
 * @param {String} fileAssetObjectId _id of the file asset object
 * @param {Function} callback callback function
 */
export const createNewVersion = (data, fileAssetObjectId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/fileassets/newversion/${fileAssetObjectId}`,
        method: 'PUT',
        data
      })
      dispatch({
        type: FILES_ASSETS.CREATE_NEW_VERSION,
        payload: res.data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * toggle status of the version
 * @param {String} taskId taskid
 * @param {String} versionId version id
 * @param {Function} callback callback funntion
 */
export const updateVersionStatus = (taskId, versionId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `fileassets/versionstatus/${taskId}/${versionId}`,
        method: 'PUT'
      })
      dispatch({ type: FILES_ASSETS.FETCHED_BY_TASK, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({ type: FILES_ASSETS.LOADING_ERROR, payload: err.response })
    }
  }
}

/**
 * get folder storage data
 * @param {String} dirId directory id
 * @param {Function} callback callback function
 */
export const getFolderStorageData = (dirId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/fileassetsfolder/workspace/storage/${dirId}`,
        method: 'GET'
      })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Move file from one directory to another
 * @param {String} id file id
 * @param {Object} data oldFileAssetsFolder, newFileAssetsFolder, newKey, oldKey
 * @param {Function} callback callback function
 */
export const moveFile = (fileId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/fileassetsfile/move/${fileId}`,
        method: 'PUT',
        data
      })
      dispatch({
        type: FILES_ASSETS.MOVE_FILE,
        payload: {
          newFileAssetsFolder: res.data.fileAssetsFolder,
          oldFileAssetsFolder: data.oldFileAssetsFolder,
          file: res.data
        }
      })
      if (callback) callback(res.data)
    } catch (err) {
      console.log('ERROR', err)
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Copy file from one directory to another
 * @param {String} id file id
 * @param {Object} data oldFileAssetsFolder, newFileAssetsFolder, newKey, oldKey
 * @param {Function} callback callback function
 */
export const copyFile = (fileId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: `/fileassetsfile/copy/${fileId}`,
        method: 'PUT',
        data
      })
      dispatch({
        type: FILES_ASSETS.COPY_FILE,
        payload: {
          newFileAssetsFolder: res.data.fileAssetsFolder,
          oldFileAssetsFolder: data.oldFileAssetsFolder,
          file: res.data
        }
      })

      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
