import ForgotModal from './ForgotModal'

const ForgotDomain = () => {
  return (
    <ForgotModal
      name="domain"
      title=""
      buttonText="Send"
      link="/login/domain"
    />
  )
}

export default ForgotDomain
