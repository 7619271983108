import React from 'react'
import { Switch, Route } from 'react-router'
import { getUserPath } from 'utils/userRoles'
import { useSelector } from 'react-redux'
import StripeConnectedModal from 'components/Setup/StripeConnectedModal'
import WebhookPage from 'components/Setup/Webhooks/WebhookPage'
import AllWebhoks from 'components/Setup/Webhooks/AllWebhoks'
import SlackWebhook from 'components/Setup/Webhooks/SlackWebhook'
import Portal from 'components/Setup/Portal/Portal'
import StripePage from 'components/Setup/StripePage'
import SettingMain from './SettingMain'
import Connection from 'components/Setup/Connection'

const SettingNewDesign = (props) => {
  const meData = useSelector((state) => state.me.data)

  return (
    <Switch>
      <Route
        path="/agency/settings/integrations/webhooks/new"
        component={WebhookPage}
      />

      <Route
        path="/agency/settings/integrations/webhooks/:webhookId"
        component={WebhookPage}
      />

      <Route
        path="/agency/settings/integrations/connection/:name"
        component={Connection}
      />

      <Route
        path="/agency/settings/integrations/slack"
        component={SlackWebhook}
      />

      <Route
        path="/agency/settings/integrations/webhooks"
        component={AllWebhoks}
      />

      <Route
        path={`${getUserPath(meData?.role)}/settings/portal`}
        component={Portal}
      />

      <Route
        path="/agency/settings/integrations/stripe"
        component={StripePage}
      />

      <Route
        path="/agency/settings/integrations/stripe-connect"
        component={StripeConnectedModal}
      />

      <Route
        path={`${getUserPath(meData?.role)}/settings`}
        component={SettingMain}
      />
    </Switch>
  )
}

export default SettingNewDesign
