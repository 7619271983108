import React, { useEffect, useState } from 'react'
import taskSidePanelStyles from './taskSidePanelStyles'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import clsx from 'clsx'
import { ReactComponent as VideoIcon } from 'static/svg/video.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'

const AttachmentImage = ({
  item,
  attachmentDropdownData,
  handleAttachmentDropDown,
  handlePreviewImage
}) => {
  const classes = taskSidePanelStyles()
  const [fileType, setFileType] = useState('file')
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    if (!item) return

    if (/\.(mp3|mp4|mkv|avi|webm|mov)$/.test(item)) setFileType('video')
    else if (/\.(png|svg|jpe?g|gif|tiff?)$/.test(item)) setFileType('image')

    const arr = item.split('/')
    setFileName(arr[arr.length - 1])
  }, [item])

  const openOptions = (e) => {
    e.stopPropagation()
    handleAttachmentDropDown(e, item)
  }

  const viewImagePreview = () => {
    handlePreviewImage(item)
  }

  return (
    <div className={classes.attachemntPreview}>
      <div
        className={clsx(classes.attachmentWrapper, {
          active: item === attachmentDropdownData.data
        })}
        onClick={viewImagePreview}
      >
        {fileType === 'image' ? (
          <img alt="" className={classes.attachmentThumbnail} src={item} />
        ) : fileType === 'video' ? (
          <span className="text-2xl text-gray-500">
            <VideoIcon />
          </span>
        ) : (
          <span className="text-2xl text-gray-500">
            <FileIcon />
          </span>
        )}
        <ExpandMoreRoundedIcon onClick={openOptions} />
      </div>
      <p className="text-xs truncate w-16">{fileName}</p>
    </div>
  )
}

export default AttachmentImage
