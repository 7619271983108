import React, { useEffect, useMemo, useState } from 'react'
import { List, ListItem, Popover } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as CheckIcon } from 'static/svg/check-icon.svg'
import { useSelector } from 'react-redux'

const TaskStatusDropdown = ({
  anchorEl,
  options,
  onClose,
  handleChangeTaskStatus,
  key
}) => {
  const handleSelectOption = (value) => {
    handleChangeTaskStatus(value, key)
    onClose()
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      style={{ zIndex: 2000 }}
    >
      <div className="bg-white shadow w-40">
        <List>
          {options.map((item) => (
            <ListItem
              key={item.value}
              button
              onClick={() => handleSelectOption(item)}
            >
              <div className="flex items-center">
                <span className="text-sm">{item.label}</span>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </Popover>
  )
}

export default React.memo(TaskStatusDropdown)
