import { CircularProgress, Drawer } from '@material-ui/core'
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback
} from 'react'
// import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import StatusDropDown from '../StatusDropDown'
// import NewVersionCreateModal from '../NewVersionCreateModal'
import { useDispatch, useSelector } from 'react-redux'
import CustomDrawer, {
  DrawerContent
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
// import CreateVersionModal from '../CreateVersionModal'
import 'react-datepicker/dist/react-datepicker.css'
import userRoles from 'utils/userRoles'
import clsx from 'clsx'
import {
  updateSubTaskDetails,
  updateSubTaskPriority,
  uploadSubTaskAttachment,
  deleteSubTaskByID,
  updateSubTaskDetailsteam,
  deleteSubtaskCollaborator
} from 'thunks/subTask/actions'
import 'react-datetime/css/react-datetime.css'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { postTaskLog, findCommentBySubtaskId } from 'thunks/logs/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { deleteSubtaskAttachment } from 'thunks/subTask/actions'
import AttachmentDropDown from '../AttachmentDropDown'
import taskSidePanelStyles from './taskSidePanelStyles'
import PriorityDropDown, {
  priorityLabels
} from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
// import useWorkspacePermission from 'customHooks/useWorkspacePermission'
import { errorMessages } from 'utils'
import LogsSection from './LogsSection'
import TaskPanelHeader from './TaskPanelHeader'
import TaskPanelFooter from './TaskPanelFooter'
import TaskTitle from './TaskTitle'
import TaskBody from './TaskBody'
import CollabDrowdown from './CollabDrowdown'
import AddAttachment from './AddAttachment'
import TaskDescription from './TaskDescription'
import AttachmentImage from './AttachmentImage'
// import { useRouteMatch } from 'react-router-dom'
import { createAccountLogs } from 'thunks/accountLogs/action'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import useWorkspaceFunctions from '../useWorkspaceFunctions'
import ApprovalPanel from './Approval/ApprovalPanel'
import { fetchTaskApprovals } from 'thunks/approvals/actions'
import AutoSavingIndicator from './AutoSavingIndicator'

const SubtaskPanel = ({
  subtaskPermission,
  open,
  onClose,
  taskId,
  handlePreviewImage,
  inDashboardArea,
  groupBy
  // currentWorkspace,
  // currentBoard
}) => {
  const classes = taskSidePanelStyles({ inDashboardArea })
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const dispatch = useDispatch()
  const logsBottomRef = useRef(null)
  // const [newFileDialog, setNewFileDialog] = useState({ open: false })
  // const [newVersionDialog, setNewVersionDialog] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [deleteAttachmentModal, setDeleteAttachmentModal] = useState({
    open: false,
    loading: false
  })
  const logsData = useSelector(state => state.logs.subtaskLogs)
  const allSubTasks = useSelector(state => state.subTasks.data)
  const [taskData, setTaskData] = useState({})
  // const [externalCommentCheck, setExternalCommentCheck] = useState(false)
  // const taskData = allSubTasks?.find((i) => i._id === taskId)

  // const access = useSelector((state) => state.permission.data?.access)
  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  // const workspacePermission = useWorkspacePermission(currentWorkspace?._id)
  const [collabOptions, setCollabOptions] = useState([])
  const meData = useSelector(state => state.me.data)
  const { socketId } = useContext(WorkspacePusherContext)
  // const [view, setView] = useState(true)
  const [taskState, setTaskState] = useState({
    creator: '',
    title: '',
    workSpace: '',
    taskSection: '',
    dueDate: `${new Date()}`,
    description: '',
    assignedAgencyTeam: [],
    assignedClientTeam: [],
    imageUrl: [],
    priority: {}
  })
  const [deleteSubtaskLoading, setDeleteSubtaskLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [attachmentDropdownData, setAttachmentDropdownData] = useState({
    anchorEl: null,
    open: false,
    data: {}
  })
  const [loadingAttachment, setLoadingAttachment] = useState(false)
  const [approvalPanel, setApprovalPanel] = useState({
    open: false,
    loading: true,
    data: []
  })

  const {
    getCollaboratorsOptions,
    canUpdateTask,
    markTaskAsComplete
  } = useWorkspaceFunctions(groupBy)
  const [savingStatus, setSavingStatus] = useState('') // statuses: "saving", "saved", "failed"

  useEffect(() => {
    if (taskId) {
      const task = allSubTasks?.find(i => i._id === taskId)
      if (task) {
        setTaskData(task)
      }
    }
  }, [taskId, allSubTasks])

  useEffect(() => {
    if (taskData._id && currentBoard.boardSection) {
      setTaskState({
        ...taskData,
        description: taskData.description ?? ''
      })
    }
  }, [taskData, currentBoard.boardSection])

  // useEffect(() => {
  //   meData?.role >= userRoles.USER_CLIENT && setExternalCommentCheck(true)
  // }, [meData])

  useEffect(() => {
    if (open) {
      dispatch(findCommentBySubtaskId(taskId))
    }
  }, [open, taskId])

  useEffect(() => {
    if (taskData.assignedAgencyTeam) {
      setCollabOptions(
        getCollaboratorsOptions(
          [...taskData.assignedAgencyTeam, ...taskData.assignedClientTeam],
          currentWorkspace
        )
      )
    }
  }, [taskData.assignedAgencyTeam, taskData.assignedClientTeam])

  useEffect(() => {
    if (taskData._id && approvalPanel.open) {
      setApprovalPanel(prev => ({ ...prev, loading: true }))
      fetchTaskApprovals(taskData._id, 'subtask', (res, err) => {
        if (!err) {
          setApprovalPanel(prev => ({ ...prev, loading: false, data: res }))
        } else {
          setApprovalPanel(prev => ({ ...prev, loading: false }))
          dispatch(fireErrorToaster(res))
        }
      })
    }
  }, [taskData._id, approvalPanel.open])

  // useEffect(() => {
  //   setBoardArea(url.includes('/board'))
  // }, [url])

  const taskUpdateCallback = (res, err, options = {}) => {
    if (err) {
      setSavingStatus('failed')
      dispatch(fireErrorToaster(res))
      if (options.resetProp) {
        setTaskState(prev => ({
          ...prev,
          [options.resetProp]: taskData[options.resetProp]
        }))
      }
    } else {
      setSavingStatus('saved')
    }
  }

  const updateLog = (logMsg = 'updated this subtask') => {
    dispatch(
      postTaskLog({
        description: logMsg,
        activityType: 'log',
        category: 'subtask',
        subTask: taskState._id,
        workSpace: taskData.workSpace
      })
    )
  }

  const updateAccountLogs = logMsg => {
    dispatch(
      createAccountLogs({
        description: `${logMsg} <strong>${taskState?.title}</strong> in the project <strong>${currentWorkspace?.name}</strong>`,
        category: 'subtask',
        subTask: taskState?._id
      })
    )
  }

  const deleteTaskByIDCallback = (res, err) => {
    setDeleteSubtaskLoading(false)
    setOpenConfirm(false)
    onClose()
    if (err) {
      dispatch(fireErrorToaster(err))
    } else {
      dispatch(fireSuccessToaster('Task Deleted'))
      updateLog(`deleted the subtask <strong>${taskState.title}</strong>`)
    }
  }

  const handleOnDeleteTask = event => {
    setDeleteSubtaskLoading(true)
    dispatch(deleteSubTaskByID(taskData._id, deleteTaskByIDCallback))
  }
  const handleCloseConfirm = () => {
    setOpenConfirm(false)
    setAnchorEl(null)
  }

  const handleAttachmentDropDown = (e, data) => {
    if (e)
      setAttachmentDropdownData({ anchorEl: e.currentTarget, open: true, data })
    else setAttachmentDropdownData({ anchorEl: null, open: false, data })
  }

  // const handleNewFileDialog = () => {
  //   setNewFileDialog((prev) => ({ open: !prev.open }))
  // }

  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   setTaskState((prev) => ({ ...prev, [name]: value }))
  // }

  // const titleCallback = (res, error) => {
  //   taskUpdateCallback(res, error)
  //   if (error) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
  //   }
  // }

  const updateTitle = title => {
    dispatch(
      updateSubTaskDetails(
        taskState._id,
        {
          title,
          workSpace: taskData.workSpace,
          taskSection: taskData.taskSection
        },
        (res, err) => {
          if (!err) {
            setTaskState(prev => ({ ...prev, title }))
            dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
            updateLog(`changed the title`)
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const uploadAttachmentCallback = (res, err) => {
    setLoadingAttachment(false)
    if (!err) {
      setTaskState(prev => ({
        ...prev,
        imageUrl: res.imageUrl
      }))
      updateLog(
        `uploaded an attachment in the subtask <b>${taskState.title}</b>`
      )
    }
  }

  const handleSubtaskAttachment = result => {
    setLoadingAttachment(true)

    dispatch(
      uploadSubTaskAttachment(
        {
          workSpace: taskData?.workSpace,
          task: taskData?.task,
          subtask: taskData._id,
          file: result
        },
        uploadAttachmentCallback
      )
    )
  }

  const handleUploadDescriptionAttachment = (file, cb) => {
    dispatch(
      uploadSubTaskAttachment(
        {
          workSpace: taskData?.workSpace,
          task: taskData?.task,
          subtask: taskData._id,
          file: file
        },
        (res, err) => {
          cb(res, err)
          uploadAttachmentCallback(res, err)
        }
      )
    )
  }

  const handleDateChange = (e, prop) => {
    const date = e?.toISOString() || null

    if (subtaskPermission.update) {
      setTaskState(prev => ({
        ...prev,
        [prop]: date
      }))

      setTaskState(prev => ({ ...prev, [prop]: date }))
      setSavingStatus('saving')
      dispatch(
        updateSubTaskDetails(
          taskData._id,
          {
            [prop]: date,
            workSpace: taskData.workSpace,
            taskSection: taskData.taskSection
          },
          (res, err) => {
            taskUpdateCallback(res, err, { resetProp: prop })
          }
        )
      )
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const triggerPopover = (event, value) => {
    if (subtaskPermission.update) {
      setAnchorEl(event.currentTarget)
      setPopperOpen(value)
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const handlePopperClose = () => {
    setPopperOpen('')
    setAnchorEl(null)
  }

  const [popperOpen, setPopperOpen] = useState('')

  const handlePriorityLabel = priority => {
    handlePopperClose()
    setSavingStatus('saving')
    setTaskState(prev => ({ ...prev, priority: priority.order }))
    dispatch(
      updateSubTaskPriority(
        taskData._id,
        { priority: priority.order },
        priorityCallback
      )
    )
  }

  const priorityCallback = (res, err) => {
    taskUpdateCallback(res, err, { resetProp: 'priority' })
    if (err) {
      setTaskState(prev => ({ ...prev, priority: res.priority }))
      let oldPriority = priorityLabels.find(
        el => el.order === taskState.priority
      )
      let newPriority = priorityLabels.find(el => el.order === res.priority)

      const msg = `changed the priority ${
        taskState.priority ? `from "${oldPriority.label}"` : ''
      } to "${newPriority.label}"`

      updateLog(msg)
      updateAccountLogs(`${msg} of the subtask <b>${taskState.title}</b>`)
    }
  }

  const handleClickOpenConfirm = e => {
    if (subtaskPermission.delete) {
      e.stopPropagation()
      setOpenConfirm(true)
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  // const assigneeCallback = (res, err) => {
  //   if (!err) {
  //     setTaskState(res)
  //   }
  // }

  // const getAddDeleteCollaboratorData = (teammateRole, teammateId) => {
  //   if (teammateRole < userRoles.USER_CLIENT) {
  //     return { assignedAgencyTeam: teammateId }
  //   }
  //   return { assignedClientTeam: teammateId }
  // }

  const handleAddCollaborator = ({ userId, userRole }) => {
    setSavingStatus('saving')
    handlePopperClose()

    const data =
      userRole < userRoles.USER_CLIENT
        ? { assignedAgencyTeam: userId }
        : { assignedClientTeam: userId }

    dispatch(
      updateSubTaskDetailsteam(taskState._id, data, (res, err) => {
        taskUpdateCallback(res, err)
        if (!err) {
          setTaskState(res)
        }
      })
    )
  }

  const handleRemoveCollaborator = ({ userId, userRole }) => {
    setSavingStatus('saving')

    const data =
      userRole < userRoles.USER_CLIENT
        ? { assignedAgencyTeam: userId }
        : { assignedClientTeam: userId }

    dispatch(
      deleteSubtaskCollaborator(taskState._id, data, (res, err) => {
        taskUpdateCallback(res, err)
        if (!err) {
          setTaskState(res)
        }
      })
    )
  }

  // const handleCollaborator = (teammate) => {
  //   handlePopperClose()
  //   const data = getAddDeleteCollaboratorData(teammate.role, teammate.user._id)
  //   dispatch(updateSubTaskDetailsteam(taskData?._id, data, assigneeCallback))
  // }

  const errorToaster = error => {
    dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const handleTaskStatus = taskStatus => {
    handlePopperClose()

    if (currentWorkspace.isComplete)
      dispatch(
        fireErrorToaster(
          `${taskState.title} belongs to a complete workspace and you can not change its status.`
        )
      )
    else {
      const prevStatus = currentBoard.boardSection.find(
        item => item._id === taskState.taskSection
      )
      setSavingStatus('saving')
      setTaskState(prev => ({ ...prev, taskSection: taskStatus._id }))
      if (subtaskPermission.update) {
        dispatch(
          updateSubTaskDetails(
            taskState._id,
            {
              taskSection: taskStatus._id,
              workSpace: currentWorkspace._id
            },
            (res, err) => {
              taskUpdateCallback(res, err, { resetProp: 'taskSection' })
              if (!err) {
                updateLog(
                  `changed the status from "${prevStatus.label}" to "${taskStatus.label}" in the subtask <b>${taskState.title}</b>`
                )
              }
            }
          )
        )
      }
    }
  }

  const handleDeleteAttachmentDialog = () => {
    setDeleteAttachmentModal(prevState => ({
      loading: false,
      open: !prevState.open
    }))
  }

  const handleDeleteAttachmentCallback = (res, err) => {
    setAttachmentDropdownData({
      anchorEl: null,
      open: false,
      data: {}
    })
    setDeleteAttachmentModal(prevState => ({
      loading: false,
      open: !prevState.open
    }))

    handlePopperClose()
    if (err) dispatch(fireErrorToaster('unable to delete attachment'))
    else {
      setTaskState(res)
      dispatch(fireSuccessToaster('attachment deleted'))
      updateLog(
        `deleted an attachment in the subtask <b>${taskState.title}</b>`
      )
    }
  }

  const handleDeleteAttachment = () => {
    setDeleteAttachmentModal(prevState => ({
      ...prevState,
      loading: true
    }))

    dispatch(
      deleteSubtaskAttachment(
        taskData._id,
        attachmentDropdownData.data,
        handleDeleteAttachmentCallback
      )
    )
  }

  const handlePreviewImageSubtask = data => {
    handleAttachmentDropDown()
    handlePreviewImage(data)
  }

  const updateDescription = description => {
    setSavingStatus('saving')
    dispatch(
      updateSubTaskDetails(
        taskState._id,
        {
          description,
          workSpace: taskState.workSpace,
          updateType: 'description',
          socketId
        },
        updateTaskDetailsCallback
      )
    )
  }

  const updateTaskDetailsCallback = (res, err) => {
    taskUpdateCallback(res, err)
    if (!err) {
      updateLog(`changed the description`)
      // updateLog({
      //   taskId: taskData._id,
      //   logMsg: `changed the description`,
      //   workspaceId: taskData.workSpace
      // })
    }
  }

  const changeEstimateTime = data => {
    dispatch(
      updateSubTaskDetails(
        taskData._id,
        {
          ...data,
          workSpace: taskState.workSpace
        },
        (res, err) => {
          if (!err) {
            setTaskState({ ...res })
            updateLog(
              `updated the estimate time ${
                res.estimateTimeInString ? res.estimateTimeInString : ''
              } in the subtask <b>${taskState.title}</b>`
            )
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleMarkTaskAsComplete = value => {
    setSavingStatus('saving')
    setTaskState(prev => ({ ...prev, markAsComplete: value }))
    markTaskAsComplete({
      value,
      task: taskState,
      elmFor: 'subtask',
      socketId,
      cb: (res, err) => {
        taskUpdateCallback(res, err, { resetProp: 'markAsComplete' })
        if (!err) {
          setTaskState(res)
        }
      }
    })
  }

  const toggleApprovalPanel = useCallback(() => {
    setApprovalPanel(prev => ({ ...prev, open: !prev.open }))
  }, [])

  const addNewApproval = data => {
    setApprovalPanel(prev => ({ ...prev, data: [data, ...prev.data] }))
  }

  const removeApprovalById = approvalId => {
    setApprovalPanel(prev => ({
      ...prev,
      data: [...prev.data.filter(item => item._id !== approvalId)]
    }))
  }

  const updateApproval = updatedData => {
    setApprovalPanel(prev => ({
      ...prev,
      data: prev.data.map(item =>
        item._id === updatedData._id ? updatedData : item
      )
    }))
  }

  return (
    <Drawer
      onClose={onClose}
      classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
      anchor="right"
      open={open}
    >
      <TaskPanelHeader
        task={taskState}
        onClose={onClose}
        handleAttachmentUpload={handleSubtaskAttachment}
        handleOpenDeleteTaskConfirm={handleClickOpenConfirm}
        taskPermission={subtaskPermission}
        triggerPopover={triggerPopover}
        elmFor="subtask"
        changeEstimateTime={changeEstimateTime}
        // socketId={socketId}
        canUpdateTask={canUpdateTask}
        markTaskAsComplete={handleMarkTaskAsComplete}
        // currentWorkspace={currentWorkspace}
        currentBoard={currentBoard}
        inDashboardArea={inDashboardArea}
        openApprovalPanel={toggleApprovalPanel}
      />
      <DrawerContent open={open} style={{ padding: 0 }}>
        <div className="overflow-y-auto">
          <div className="px-2 mt-2 mb-2 flex items-center">
            <div className="flex-1">
              <TaskTitle
                taskPermission={subtaskPermission}
                taskTitle={taskState.title}
                updateTitle={updateTitle}
                elmFor="subtask"
              />
            </div>
            <div className="text-right text-xs px-8">
              <AutoSavingIndicator status={savingStatus} />
            </div>
          </div>

          <TaskBody
            taskPermission={subtaskPermission}
            taskState={taskState}
            handleDateChange={handleDateChange}
            setTaskState={setTaskState}
            triggerPopover={triggerPopover}
            updateLog={updateLog}
            errorToaster={errorToaster}
            elmFor="subtask"
            addCollaborator={handleAddCollaborator}
            removeCollaborator={handleRemoveCollaborator}
          />

          <div className="px-6 py-2">
            <TaskDescription
              workspace={taskState.workSpace}
              taskId={taskState._id}
              taskPermission={subtaskPermission}
              taskDescription={taskState.description}
              updateDescription={updateDescription}
              handleUploadDescriptionAttachment={
                handleUploadDescriptionAttachment
              }
              // elmFor="subtask"
              // parentTask={taskState.task}
            />
          </div>

          <div
            className={clsx(
              // classes.attachmentContainer,
              'space-y-1 px-4 sm:space-y-0 flex items-center flex-wrap mx-0 mb-4 sm:gap-4 sm:px-6 sm:py-5'
            )}
          >
            {taskState?.imageUrl?.length > 0 &&
              taskState?.imageUrl?.map((item, index) => (
                <AttachmentImage
                  key={index}
                  item={item}
                  attachmentDropdownData={attachmentDropdownData}
                  handleAttachmentDropDown={handleAttachmentDropDown}
                  handlePreviewImage={handlePreviewImage}
                />
              ))}
            {subtaskPermission.update && (
              <AddAttachment
                labelProps={{ className: classes.attachmentAddbtn }}
                handleFile={handleSubtaskAttachment}
                id={'subtask-attachment'} //don't remove this id
                disabled={loadingAttachment}
              >
                {loadingAttachment ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Add />
                )}
              </AddAttachment>
            )}
          </div>
          <div>
            <LogsSection
              task={taskData}
              logsData={logsData}
              elmFor="subtask"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <span ref={logsBottomRef}></span>
          </div>
        </div>
      </DrawerContent>

      <TaskPanelFooter
        taskId={taskData._id}
        elmFor="subtask"
        setSelectedTab={setSelectedTab}
        workspaceId={taskData.workSpace}
        socketId={socketId}
      />

      <AlertModal
        warningText={`The Selected Task named "${taskData?.title}" will be deleted forever`}
        handleDialog={handleCloseConfirm}
        open={openConfirm}
        handleDeleteAction={handleOnDeleteTask}
        archiveButton={false}
        loading={deleteSubtaskLoading}
        style={{ zIndex: 2000 }}
      />
      <AlertModal
        warningText={'attachment will be deleted'}
        handleDialog={handleDeleteAttachmentDialog}
        open={deleteAttachmentModal.open}
        handleDeleteAction={handleDeleteAttachment}
        loading={deleteAttachmentModal.loading}
      />

      {attachmentDropdownData.open && (
        <AttachmentDropDown
          open={attachmentDropdownData.open}
          onClose={() => handleAttachmentDropDown(false)}
          anchorEl={attachmentDropdownData.anchorEl}
          data={attachmentDropdownData.data}
          handleDeleteAttachment={handleDeleteAttachmentDialog}
          handlePreviewImage={handlePreviewImageSubtask}
          style={{ zIndex: 2000 }}
        />
      )}

      <StatusDropDown
        open={popperOpen === 'status'}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        changeStatus={handleTaskStatus}
        // statusList={taskData.statuses}
        statusList={currentBoard.boardSection}
        taskSection={taskData.taskSection}
        style={{ zIndex: 2000 }}
      />
      <PriorityDropDown
        onClose={handlePopperClose}
        anchorEl={anchorEl}
        currentPriority={taskState?.priority}
        open={popperOpen === 'priority'}
        handlePriorityLabel={handlePriorityLabel}
        style={{ zIndex: 2000 }}
      />

      <CollabDrowdown
        open={popperOpen === 'collab' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        list={collabOptions}
        handleListItemClick={teammate =>
          handleAddCollaborator({
            userId: teammate.user._id,
            userRole: teammate.role
          })
        }
        meData={meData}
        style={{ zIndex: 2000 }}
      />
      <ApprovalPanel
        open={approvalPanel.open}
        taskId={taskState.task}
        subtaskId={taskState._id}
        isLoading={approvalPanel.loading}
        data={approvalPanel.data}
        currentWorkspace={currentWorkspace}
        boardId={currentBoard._id}
        onClose={toggleApprovalPanel}
        socketId={socketId}
        addNewApproval={addNewApproval}
        removeApprovalById={removeApprovalById}
        updateApproval={updateApproval}
        elmFor="subtask"
      />
    </Drawer>
    // </SingleTaskPusherWrapper>
  )
}

export default React.memo(SubtaskPanel)
