import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import { updateClientCompany } from 'thunks/clientCompany/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Countries from 'static/countries/Countries'
import fileToDataURL from 'utils/fileToDataUrl'

const AddressModal = ({ open, handleClose, company }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [companyData, setCompanyData] = useState({
    address: {
      city: '',
      country: '',
      line1: '',
      line2: '',
      state: '',
      zipCode: ''
    }
  })

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 7,
      paddingBottom: 7,
      height: '30px'
    })
  }

  useEffect(() => {
    setCompanyData({
      address: company.address || {
        city: '',
        country: '',
        line1: '',
        line2: '',
        state: '',
        zipCode: ''
      }
    })
  }, [company])

  const handleAddressChange = (e) => {
    const { name, value } = e.target
    setCompanyData((prev) => ({
      ...prev,
      address: { ...prev.address, [name]: value }
    }))
  }

  const handleCountryChange = (e) => {
    setCompanyData((prev) => ({
      ...prev,
      address: { ...prev.address, country: e }
    }))
  }

  const imageLoadHandler = (e) => {
    const file = e.target.files[0]
    fileToDataURL(file).then((res) =>
      setCompanyData((prev) => ({ ...prev, file: res }))
    )
  }

  const handleButtonClick = () => {
    setLoading(true)
    dispatch(
      updateClientCompany(company._id, companyData, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster('Address Updated Successfully!'))
          handleClose()
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <CustomModal
      // classes={{ paper: classes.dialogPaper }}
      open={open}
      handleClose={handleClose}
    >
      <CustomModalHeader heading="Change company address" />

      <CustomModalBody className="pb-4">
        <Box mt={2}>
          <FormLabel>Company Address</FormLabel>
          <div className="grid grid-cols-8 gap-4">
            <TextField
              placeholder="Line 1"
              name="line1"
              className="w-full col-span-4"
              value={companyData.address.line1}
              onChange={handleAddressChange}
            />
            <TextField
              placeholder="Line 2"
              name="line2"
              value={companyData.address.line2}
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />
            <TextField
              placeholder="City"
              value={companyData.address.city}
              name="city"
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />
            <TextField
              value={companyData.address.state}
              name="state"
              placeholder="State"
              className="w-full col-span-4"
              onChange={handleAddressChange}
            />

            <Select
              className="w-full col-span-4 text-sm"
              placeholder="Country"
              isSearchable={true}
              options={Countries}
              value={companyData.address.country}
              maxMenuHeight={250}
              onChange={handleCountryChange}
              menuPlacement="top"
              styles={{ ...reactSelectCustomStyles, ...customStyles }}
            />
            <TextField
              placeholder="Zip Code"
              name="zipCode"
              value={companyData.address.zipCode}
              className="w-full col-span-4 "
              onChange={handleAddressChange}
              type="text"
            />
          </div>
        </Box>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton loading={loading} onClick={handleButtonClick}>
          Save Changes
        </LoadingButton>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default AddressModal
