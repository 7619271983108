import axios from 'axios'
import { LOGIN } from 'thunks/login/reduers'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import { getAuthToken, postEvent, userRoles } from 'utils'
import { ME } from './reducers'
import serviceWorker from 'serviceWorker'

/**
 * Fetch data of user
 * @param {Function} callback function will run after fetch. Recieve (err/response, error status) as arguments
 */
export const fetchMeData = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/me',
        headers: {
          Authorization: getAuthToken()
        }
      })
      // Subscribing to service worker after getting me data
      serviceWorker({ meId: res.data._id })

      dispatch({ type: ME.FETCHED, payload: res.data })
      // dispatch(updateUserAcces(res.data.role))

      if (callback) callback(res.data, false)
      if (res.data?.role < userRoles.USER_CLIENT) {
        // startChatService(res.data?.name, res.data?.email, res.data?._id)
        postEvent('login', {
          name: res.data?.name,
          email: res.data?.email,
          agencyDomain: res.data?.agencyDomain,
          role: res.data?.role
        })
      }
    } catch (err) {
      if (callback)
        callback(err?.response?.data?.message ?? 'Some error occurred!', true)
      if (err?.response?.status === 401) {
        dispatch({ type: LOGIN.LOGOUT })
        // localStorage.removeItem('authToken')
      }
    }
  }
}

export const setLeftTrialDays = (days) => ({
  type: ME.SET_LEFT_TRIAL_DAYS,
  payload: days
})
