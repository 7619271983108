import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { getNewApiKey } from 'thunks/user/actions'
import { useBreadcrumbs } from 'customHooks'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'

const Apikey = () => {
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const [loading, setLoading] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [copy, setCopy] = useState(false)

  // useBreadcrumbs([
  //   {
  //     name: 'Settings',
  //     link: `/settings/general`,
  //     index: 0
  //   },
  //   {
  //     name: 'Api Key',
  //     link: `/settings/apikey`,
  //     index: 1
  //   }
  // ])

  useEffect(() => {
    if (!me.loading) {
      setApiKey(me.data.apiKey)
      setCopy(false)
    }
  }, [me])

  const getApiKey = () => {
    setLoading(true)
    dispatch(getNewApiKey(getApiKeyCallback))
  }

  const getApiKeyCallback = (res, err) => {
    setLoading(false)
    if (err) {
      return dispatch(
        fireErrorToaster('Fail to generate new api key. Please try again later')
      )
    } else {
      setApiKey(res.apiKey)
      dispatch(fireSuccessToaster('New api key generated successfully!'))
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(apiKey)
    setCopy(true)
    dispatch(fireSuccessToaster('Link Copied successfully'))
  }

  return (
    <Wrapper>
      <SecondaryHeading>Api Key</SecondaryHeading>
      <div>
        <div className="w-full">
          <div
            className="flex justify-between items-center space-x-4 border text-xs text-primary-dark"
            style={{ maxWidth: '400px' }}
          >
            <span className="py-1 px-4">{apiKey}</span>
            <button
              className="text-primary-main font-medium flex items-center px-2 py-1 border-2 rounded border-primary-main bg-primary-light"
              onClick={handleCopyLink}
            >
              <CopyIcon className="text-primary-main" />
              <span>{copy ? 'copied' : 'copy'}</span>
            </button>
          </div>
        </div>
        <div className="text-right mt-12">
          <LoadingButton className="w-52" onClick={getApiKey} loading={loading}>
            Generate New Api
          </LoadingButton>
        </div>
      </div>
    </Wrapper>
  )
}

export default Apikey
