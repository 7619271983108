import React, { useEffect, useState } from 'react'
// import { Button, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
import { setBoardSearch } from 'thunks/generlAppState/actions'
// import { IconButton } from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'

const BoardTaskSearch = () => {
  const [search, setSearch] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    let timer = setTimeout(() => {
      dispatch(setBoardSearch(search))
    }, 500)

    return () => clearTimeout(timer)
  }, [search])

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  // console.log(search, 'search in serch comp')

  return (
    <div className="w-48  pl-2 py-4 mr-2">
      <div className="flex items-center border-r">
        <SearchIcon
          className="text-primary-main"
          width="0.8em"
          height="0.8em"
        />
        <input
          type="text"
          placeholder="search task..."
          className="bg-transparent px-2 py-1 w-full text-sm "
          value={search}
          onChange={handleSearchChange}
        />

        {search && (
          <CancelRoundedIcon
            fontSize="small"
            style={{ fill: 'gray' }}
            onClick={() => setSearch('')}
            className="cursor-pointer"
          />
        )}
      </div>
    </div>
  )
}

export default BoardTaskSearch
