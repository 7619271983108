import { ReactComponent as AngleRight } from 'static/svg/angle-right.svg'
import { ReactComponent as AngleLeft } from 'static/svg/angle-left.svg'
import LoopRoundedIcon from '@material-ui/icons/LoopRounded'
import DatePicker from 'antd/lib/date-picker'
import ConfigProvider from 'antd/lib/config-provider'
// import 'antd/dist/antd.css'
import locale from 'antd/es/locale/en_GB'
import moment from 'moment'
import { getUserPath } from 'utils/userRoles'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})

function getWeekDays(weekStart) {
  const days = []
  for (let i = 0; i < 7; i++) {
    days.push(moment(weekStart).add(i, 'days').toDate().toDateString())
  }
  return days
}

const WorkloadHead = ({ selectedDate, setSelectedDate }) => {
  const history = useHistory()
  const meData = useSelector((state) => state.me.data)

  const getPrevWeek = () => {
    const date = new Date(selectedDate)
    const prevWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    )
    setSelectedDate(prevWeek)
    // handleUrl(prevWeek)
  }

  const getNextWeek = () => {
    const date = new Date(selectedDate)
    const nextWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 7
    )
    setSelectedDate(nextWeek)
    // handleUrl(nextWeek)
  }

  const customFormat = (value) =>
    `${moment(value).startOf('week').format('DD MMM YY')} - ${moment(value)
      .endOf('week')
      .format('DD MMM YY')}`

  const onChange = (date) => {
    setSelectedDate(date)
    // handleUrl(date)
  }

  return (
    <div className="flex justify-start items-center px-4 py-2 text-sm text-gray-700 bg-white">
      <div className="cursor-pointer flex justify-start items-center">
        <div
          className="h-8 flex items-center rounded-sm hover:bg-gray-100"
          onClick={getPrevWeek}
        >
          <AngleLeft
            className="h-6 w-6 rounded-sm hover:bg-gray-100"
            style={{ fill: 'gray' }}
          />
        </div>
        <div
          className="h-8 flex items-center rounded-sm hover:bg-gray-100"
          onClick={getNextWeek}
        >
          <AngleRight
            className=" h-6 w-6 rounded-sm hover:bg-gray-100"
            style={{ fill: 'gray' }}
          />
        </div>
        <div className="px-2 h-8 flex items-center rounded-sm hover:bg-gray-100">
          <ConfigProvider locale={locale}>
            <DatePicker
              defaultValue={
                moment(new Date()).startOf('week').format('DD MMM YY') -
                moment(new Date()).endOf('week').format('DD MMM YY')
              }
              value={moment(selectedDate)}
              format={customFormat}
              onChange={onChange}
              picker="week"
              bordered={false}
              allowClear={false}
              style={{
                width: '205px'
              }}
            />
          </ConfigProvider>
        </div>
      </div>
      {!getWeekDays(moment(selectedDate).startOf('week').toDate()).includes(
        new Date().toDateString()
      ) && (
        <div
          className="cursor-pointer h-8 flex items-center px-4 rounded-sm  hover:bg-gray-100"
          onClick={() => setSelectedDate(new Date())}
        >
          <LoopRoundedIcon fontSize="small" />
          This Week
        </div>
      )}
    </div>
  )
}

export default WorkloadHead
