import userDashboardStyles from 'components/UserDashboard/userDashboardStyles'
import { Popover, List, ListItem } from '@material-ui/core'
import clsx from 'clsx'
import { formatDistance, subDays, format } from 'date-fns'
import {
  fetchAccountLogsByUser,
  updateLogsView
} from 'thunks/accountLogs/action'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColoredAvatars } from 'global/globalComponents'
import { ReactComponent as ArrowDown } from 'static/svg/arrow-down.svg'
import { ReactComponent as ArrowUp } from 'static/svg/arrow-up.svg'
import { ReactComponent as Circle } from 'static/svg/circle.svg'
import { IconButton, Box, Button, Tabs, Tab } from '@material-ui/core'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useBreadcrumbs } from 'customHooks'
import { ReactComponent as UserCircle } from 'static/svg/user-circle.svg'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Check from '@material-ui/icons/Check'
import { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import userRoles from 'utils/userRoles'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AccountLogs = () => {
  const ref = useRef()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const accountLogs = useSelector(state => state.accountLogs)
  const dispatch = useDispatch()
  const [filterState, setFilterState] = useState(0)
  const [top, setTop] = useState(false)
  const [disable, setDisable] = useState(false)
  const [time, setTime] = useState(4)
  const [isFetching, setIsFetching] = useState(false)
  const [popoverData, setPopoverData] = useState({
    open: false,
    anchorEl: null,
    data: ''
  })

  useBreadcrumbs([
    {
      name: 'Notifications',
      link: '/notifications'
    }
  ])

  useEffect(() => {
    if (!accountLogs?.fetched) {
      const d = new Date()
      const startDate = new Date(d.setDate(d.getDate())).toISOString()
      const endDate = new Date(d.setDate(d.getDate() - 3)).toISOString()
      dispatch(fetchAccountLogsByUser(startDate, endDate))
    }
  }, [accountLogs?.fetched])

  useEffect(() => {
    if (accountLogs.data.length >= 25) {
      setTop(true)
    } else {
      setTop(false)
    }
  }, [accountLogs.data])

  useEffect(() => {
    if (accountLogs.data.length === 0) {
      setDisable(true)
    }
  }, [accountLogs.data])

  const handleFilterState = (e, newVal) => {
    setFilterState(newVal)
  }

  const updateLogs = key => {
    let data
    if (key === 'all') {
      data = accountLogs.data
        .filter(item => !item.readBy?.includes(meData._id))
        .map(el => el._id)
    } else {
      data = [key]
    }
    dispatch(
      updateLogsView({ readNotification: data }, (res, err) => {
        if (!err) {
          setPopoverData({ open: false, anchorEl: null, data: '' })
        }
      })
    )
  }

  const fetchLogs = () => {
    setIsFetching(true)
    const d = new Date()
    const startDate = new Date(d.setDate(d.getDate() - time)).toISOString()
    const endDate = new Date(d.setDate(d.getDate() - 3)).toISOString()
    dispatch(
      fetchAccountLogsByUser(startDate, endDate, (res, err) => {
        setIsFetching(false)
        if (!err) {
          setTime(time + 4)
          setDisable(false)
        } else {
          setDisable(true)
        }
      })
    )
  }

  // const goToTop = () => {
  //   ref.current.scrollIntoView()
  // }

  return (
    <>
      <Box className="flex flex-col h-full mb-8" ref={ref}>
        <OuterContentWrapper>
          <div
            className="relative w-full xl:px-10 md:p-6 sm:p-3 py-6 mt-8 mx-auto bg-white rounded border-2 shadow-lg"
            style={{ maxWidth: 800 }}
          >
            <Box className="flex justify-between mb-6 lg:mb-8">
              <SectionHeading>Notifications</SectionHeading>

              <div className="flex items-center nter justify-between">
                {accountLogs.data.filter(
                  event => !event.readBy?.includes(meData._id)
                )?.length > 0 && (
                  <p
                    className="text-xs cursor-pointer font-medium text-primary-main"
                    onClick={() => updateLogs('all')}
                  >
                    Mark All As Read
                  </p>
                )}
              </div>
            </Box>

            {/* <div
              className="absolute flex items-center justify-between"
              style={{ top: -20, right: 0 }}
            >
              <p
                className="text-xs cursor-pointer font-medium text-primary-main"
                onClick={() => updateLogs('all')}
              >
                Mark All Read
              </p>
            </div> */}
            {accountLogs.loading ? (
              <TableDataLoading cols={2} />
            ) : accountLogs.data.length > 0 ? (
              <div className="flow-root">
                <ul className="mb-8">
                  {accountLogs.data.map((event, eventIdx) => (
                    <SingleLog
                      key={event.id}
                      event={event}
                      eventIdx={eventIdx}
                      updateLogs={updateLogs}
                      accountLogs={accountLogs}
                    />
                  ))}
                </ul>

                {isFetching && (
                  <p className="flex justify-center items-center text-sm font-medium mt-10 space-x-1 cursor-pointer">
                    Loading More...
                  </p>
                )}
                <>
                  {!(isFetching || disable) && (
                    <button
                      className="bg-white text-primary-main hover:bg-primary-main hover:text-white border border-primary-main text-xs px-4 h-8 rounded-full absolute flex space-x-2 items-center left-2/4 transition font-medium"
                      onClick={fetchLogs}
                      style={{ transform: 'translate(-50%, 25%)' }}
                    >
                      {' '}
                      <span>View more</span>
                      <ArrowUp className="rotate-180" />
                    </button>
                  )}
                  {/* {top && (
                    <button
                      className="bg-white text-primary-main hover:bg-primary-main hover:text-white border border-primary-main text-xs px-4 h-8 rounded-full absolute flex space-x-2 items-center left-2/4"
                      onClick={goToTop}
                      style={{ transform: 'translate(-50%, 25%)' }}
                    >
                      <ArrowUp />
                      <span>Go to top</span>
                    </button>
                  )} */}
                </>
              </div>
            ) : (
              <p className="flex justify-center items-center text-sm font-medium text-gray-500 my-4 py-4 space-x-1 cursor-pointer">
                Notifications are not found
              </p>
            )}
          </div>
        </OuterContentWrapper>
        <Popover
          open={popoverData.open}
          onClose={() =>
            setPopoverData({ open: false, anchorEl: null, data: '' })
          }
          anchorEl={popoverData.anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <List
            disablePadding
            dense
            style={{ display: 'flex', flexWrap: 'no-wrap' }}
          >
            <ListItem button onClick={() => updateLogs(popoverData.data._id)}>
              <Check
                fontSize="small"
                style={{
                  color: 'green',
                  marginRight: '5px'
                }}
              />
              <p style={{ fontSize: '14px' }}>mark as read</p>
              {/* {popoverData.data.readBy?.includes(meData._id) && (
                <Check
                  fontSize="small"
                  style={{
                    color: 'green',
                    marginRight: '5px'
                  }}
                />
              )}
              <p style={{ fontSize: '14px' }}>
                {popoverData.data.readBy?.includes(meData._id)
                  ? 'read'
                  : 'mark as read'}
              </p> */}
            </ListItem>
          </List>
        </Popover>
      </Box>
    </>
  )
}

const SingleLog = ({ event, eventIdx, updateLogs, accountLogs }) => {
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const [time, setTime] = useState('')
  const date = new Date(event.createdAt)
  const [read, setRead] = useState(false)
  const classes = userDashboardStyles()

  useEffect(() => {
    const dif = getTimeDifference(event.createdAt)
    if (dif > 24 && dif <= 48) {
      setTime(
        `Yesterday at ${date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })}`
      )
    } else if (dif < 24) {
      setTime(
        `Today at ${date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })}`
      )
    } else {
      setTime(format(new Date(event.createdAt), 'd MMM p'))
    }
  }, [event])

  useEffect(() => {
    if (event.readBy && event.readBy.includes(meData._id)) {
      setRead(true)
    }
  }, [event])

  const handleClickEvent = event => {
    if (!event.readBy.includes(meData._id)) {
      updateLogs(event._id)
    }
    if (event.category === 'workspace') {
      history.push(
        `${getUserPath(meData.role)}/workspace/${event.workspace._id}/overview`
      )
    }
    if (event.category === 'task' && event.task.workspaceBoard) {
      history.push(
        `${getUserPath(meData.role)}/workspace/${event.task.workSpace}/board/${
          event.task.workspaceBoard
        }/task/${event.task._id}`
      )
    }
    if (
      event.category === 'subTask' &&
      event.subTask.workspaceBoard &&
      event.subTask.workSpace
    ) {
      history.push(
        `${getUserPath(meData.role)}/workspace/${
          event.subTask.workSpace
        }/board/${event.subTask.workspaceBoard}/task/${event.subTask.task}/${
          event.subTask._id
        }`
      )
    }
    if (event.category === 'client') {
      meData.role < userRoles.USER_CLIENT
        ? history.push(`/agency/clients/${event.client}`)
        : history.push(`/client/dashboard`)
    }
    if (['agencyTeammate', 'clientTeammate'].includes(event.category)) {
      history.push(`${getUserPath(meData.role)}/teammates/all`)
    }
    if (event.category === 'service') {
      meData.role < userRoles.USER_CLIENT
        ? history.push('/agency/payments/services/all')
        : history.push(`/client/services/all`)
    }
    if (event.category === 'workspaceInvoice') {
      meData.role < userRoles.USER_CLIENT
        ? history.push(
            `/agency/payments/client-billing/${
              event.workspaceInvoice?._id
                ? `i/${event.workspaceInvoice._id}`
                : 'all'
            }`
          )
        : history.push(`/client/invoices`)
    }
  }

  return (
    <li
      key={event.id}
      className="cursor-pointer hover:bg-gray-100 transition-all duration-300 ease-in-out"
      onClick={() => handleClickEvent(event)}
    >
      <div className="relative p-1 mb-6 ">
        {eventIdx !== accountLogs.data.length - 1 ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            style={{ left: '19px' }}
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex space-x-3">
          <div>
            <span
              className={classNames(
                'green'
                // 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
              )}
            >
              {/* {!event.user ? (
                <UserCircle
                  width="32px"
                  height="32px"
                  className="text-gray-700"
                  aria-hidden="true"
                />
              ) : (
                <ColoredAvatars user={event.user ?? ''} size="medium" />
              )} */}
              <ColoredAvatars
                user={event.user ?? { name: 'Unknown' }}
                size="medium"
              />
            </span>
          </div>
          <div className="min-w-0 flex-1 pt-2 flex justify-between space-x-8">
            <div>
              <p
                className="text-sm"
                style={{
                  color: event.readBy?.includes(meData._id)
                    ? '#585858'
                    : 'black'
                }}
              >
                <span
                  className={classes.singleLog}
                  dangerouslySetInnerHTML={{
                    __html: event.description
                  }}
                ></span>{' '}
              </p>
            </div>
            <div
              className={clsx(
                'flex space-x-4 text-xs whitespace-nowrap',
                read ? 'text-gray-500' : 'text-primary-main'
              )}
            >
              {time}
              {/* {!event.readBy?.includes(meData._id) && (
                              <Circle
                                className="h-2 w-2 ml-2"
                                style={{
                                  fill: event.readBy?.includes(meData._id)
                                    ? 'blue'
                                    : 'blue'
                                }}
                                onClick={(e) =>
                                  setPopoverData({
                                    open: true,
                                    anchorEl: e.currentTarget,
                                    data: event
                                  })
                                }
                              />
                            )} */}
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

const getTimeDifference = timestamp => {
  const difference = Date.now() - new Date(timestamp)
  return Math.floor(difference / (1000 * 60 * 60))
}

export default AccountLogs
