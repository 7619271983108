import React, { useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  // Popover,
  makeStyles
  // Popper,
  // ClickAwayListener
} from '@material-ui/core'
import clsx from 'clsx'
import CustomPopper from '../CustomPopper/CustomPopper'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'

const PopupMenu = ({ menuItems, targetComp, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  // const arrowRef = useRef();
  // const [arrowRef, setArrowRef] = useState(null)

  const handleOpenMenu = e => {
    e.stopPropagation()
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      if (onClick) onClick()
      setAnchorEl(e.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!targetComp ? (
        <button onClick={handleOpenMenu}>
          <ThreeDotsIcon className="w-4 h-4 lg:w-5 lg:h-5" />
        </button>
      ) : (
        <>{React.cloneElement(targetComp, { onClick: handleOpenMenu })}</>
      )}

      <CustomPopper anchorEl={anchorEl} onClose={handleCloseMenu}>
        <div className={clsx('bg-white', classes.menuPaper)}>
          <List className={classes.list}>
            {menuItems.map((item, index) => (
              <SingleListItem
                key={index}
                item={item}
                index={index}
                handleCloseMenu={handleCloseMenu}
                classes={classes}
              />
            ))}
          </List>
        </div>
      </CustomPopper>
    </>
  )
}

const SingleListItem = ({ item, index, handleCloseMenu, classes }) => {
  const handleClick = e => {
    handleCloseMenu()
    item.method(e, item)
  }

  return (
    <ListItem
      onClick={handleClick}
      // classes={{ gutters: classes.listItem }}
      button
    >
      <div className="flex items-center py-1">
        {item.icon && (
          <span className="mr-2 text-primary-main">{item.icon}</span>
        )}
        <ListItemText
          primary={
            <p className="capitalize text-xs lg:text-sm">{item.label}</p>
          }
          classes={{ root: classes.listItemText }}
        />
      </div>
    </ListItem>
  )
}

const useStyles = makeStyles({
  menuPaper: {
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    maxHeight: 350,
    overflow: 'auto'
  },

  list: {
    padding: '4px 0'
  },

  listItem: {
    padding: '0.5rem 1.5rem',
    minWidth: 115
  },

  listItemText: {
    margin: 0
  }
})

export default React.memo(PopupMenu)
