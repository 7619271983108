import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
// import StatusStrip from './StatusStrip'
import AllStatusesCategories from './AllStatusesCategories'
import StatusColorPopup from './StatusColorPopup'
import { updateBoardStatuses } from 'thunks/embed/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const EditStatusModal = ({ open, onClose, board, workspaceTitle }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([
    {
      id: 'open',
      title: 'not started statuses',
      info: "For tasks that haven't been worked on yet",
      statuses: []
    },
    {
      id: 'active',
      title: 'active statuses',
      info: 'For tasks that have been started',
      statuses: []
    },
    {
      id: 'completed',
      title: 'done statuses',
      info: 'For tasks that are completed',
      statuses: []
    }
  ])
  const [statusStripPopper, setStatusStripPopper] = useState({
    anchorEl: null,
    type: '',
    data: {
      statusId: '',
      categoryId: ''
    }
  })

  useEffect(() => {
    if (board._id && open) {
      let openStatuses = [],
        activeStatuses = [],
        completedStatuses = []

      board.boardSection.forEach(item => {
        if (item.category === 'open') openStatuses.push({ ...item })
        else if (item.category === 'completed')
          completedStatuses.push({ ...item })
        else activeStatuses.push({ ...item })
      })

      setCategories(prev =>
        prev.map(item => {
          if (item.id === 'open') return { ...item, statuses: openStatuses }
          else if (item.id === 'active')
            return { ...item, statuses: activeStatuses }
          else return { ...item, statuses: completedStatuses }
        })
      )
    }
  }, [board, open])

  const updateStatusesPosition = useCallback(update => {
    setCategories(update)
  }, [])

  const updateCategoryStatus = ({ categoryId, statusId, prop, value }) => {
    setCategories(prev =>
      prev.map(category => {
        if (category.id === categoryId) {
          category.statuses = category.statuses.map(status => {
            if (status._id === statusId) return { ...status, [prop]: value }
            return status
          })
        }
        return category
      })
    )
  }

  const openPopper = ({ elm, type, data }) => {
    setStatusStripPopper({ anchorEl: elm, type, data })
  }

  const closePopper = () => {
    setStatusStripPopper(prev => ({ ...prev, anchorEl: null, type: '' }))
  }

  const handleSaveChanges = () => {
    setLoading(true)
    const statuses = []
    categories.forEach(item => statuses.push(...item.statuses))

    dispatch(
      updateBoardStatuses(
        board._id,
        {
          sections: statuses,
          boardSection: statuses.map(item => item._id)
        },
        (res, err) => {
          setLoading(false)

          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            dispatch(fireSuccessToaster('Changes saved successfully'))
          }
        }
      )
    )
  }

  return (
    <>
      <CustomModal open={open} size="small">
        <CustomModalHeader
          heading={`Edit statuses for ${workspaceTitle}`}
          handleClose={onClose}
          className="text-center"
        />
        <CustomModalBody>
          {/* <div className="flex space-x-4 mt-8"> */}
          <div className="mt-8">
            {/* <div className="flex-1">
              <p>
                From here you can update the position, color and title of the
                statuses
              </p>
            </div> */}
            <div className="flex-1 space-y-1">
              <AllStatusesCategories
                categories={categories}
                updateCategories={updateStatusesPosition}
                openPopper={openPopper}
                updateCategoryStatus={updateCategoryStatus}
              />
            </div>
          </div>
        </CustomModalBody>
        <CustomModalFooter className="my-4">
          <LoadingButton
            onClick={handleSaveChanges}
            loading={loading}
            className="w-1/2"
            style={{ margin: 'auto' }}
          >
            Save
          </LoadingButton>
        </CustomModalFooter>
      </CustomModal>
      {statusStripPopper.type === 'color' && (
        <StatusColorPopup
          anchorEl={statusStripPopper.anchorEl}
          updateCategoryStatus={updateCategoryStatus}
          onClose={closePopper}
          data={statusStripPopper.data}
        />
      )}
    </>
  )
}

export default EditStatusModal
