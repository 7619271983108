import React, { useState, useEffect, useRef, useMemo } from 'react'
import { AvatarGroup } from '@material-ui/lab'
// import { ColoredAvatars } from 'global/globalComponents'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { useSelector } from 'react-redux'
import CellWrapper from './CellWrapper'
// import { TableContext } from './ListView'
import { userRoles } from 'utils'
import Collaborator from 'components/Workspace/Panel/Collaborator'
import { stopEventPropagation } from 'utils'
import { ReactComponent as UserPlusIcon } from 'static/svg/user-plus.svg'

const PersonCell = (props) => {
  const {
    value,
    row: { index, values, original },
    column: { id, fieldType },
    updateDynamicColData,
    addTaskCollaborator,
    removeTaskCollaborator,
    // updateStaticColData,
    // handleRemoveAssignee,
    taskPermission,
    classes
  } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const meData = useSelector((state) => state.me.data)
  const [collabs, setCollabs] = useState([])
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const ref = useRef()
  const initialValue = useMemo(() => {
    return value ? value : []
  }, [value])

  // const { cellWidth } = useContext(TableContext)

  useEffect(() => {
    if (currentWorkspace.assignedAgencyTeam) {
      const arr = [
        ...currentWorkspace.assignedAgencyTeam,
        ...currentWorkspace.assignedClientTeam
      ].filter((mate) => {
        if (initialValue) {
          const match = initialValue.find(
            (selectedMate) => selectedMate._id === mate.user._id
          )
          return !Boolean(match)
        } else return true
      })

      setCollabs(arr)
    }
  }, [currentWorkspace, initialValue])

  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const selectCollaborator = (teammate) => {
    const arr = initialValue || []

    // Here i'm mapping teammate obj bcoz the user data format is different in
    // workspace.assignedAgencyTeam (or assignedClientTeam) and task.assignedAgencyTeam (or assignedClientTeam)

    const mappedTeammate = {
      ...teammate.user,
      role: teammate.role
    }

    if (fieldType === 'static') {
      // userRole, userId, taskId, cb
      addTaskCollaborator({
        userRole: teammate.role,
        userId: teammate.user._id,
        taskId: original._id
      })
      // updateStaticColData({
      //   rowIndex: index,
      //   columnId: id,
      //   value: [...arr, teammate],
      //   original,
      //   updateData: {
      //     assignedAgencyTeam: [...arr, mappedTeammate]
      //       .filter((item) => item.role < userRoles.USER_CLIENT)
      //       .map((item) => item._id),
      //     assignedClientTeam: [...arr, mappedTeammate]
      //       .filter((item) => item.role >= userRoles.USER_CLIENT)
      //       .map((item) => item._id)
      //   },
      //   taskId: original.taskId
      // })
    } else {
      updateDynamicColData({
        rowIndex: index,
        columnId: id,
        original,
        value: [...arr, mappedTeammate],
        allRowValues: { ...values },
        taskId: original.taskId
      })
    }
    handleClosePopup()
  }

  const handleOpenPopup = (e) => {
    setAnchorEl(ref.current)
  }

  const handleRemove = (teammate) => {
    if (fieldType === 'static') {
      // userRole, userId, taskId, cb
      removeTaskCollaborator({
        rowIndex: index,
        columnId: id,
        teammate,
        taskId: original.taskId
      })
    } else {
      const data = { ...values }
      const filteredData = data[id].filter((user) => user._id !== teammate._id)
      data[id] = filteredData

      updateDynamicColData({
        rowIndex: index,
        columnId: id,
        value: filteredData,
        allRowValues: data,
        taskId: original.taskId
      })
      // updateDynamicColData()
    }
  }

  return (
    <>
      <CellWrapper
        onClick={taskPermission.update ? handleOpenPopup : undefined}
        styles={{ minWidth: 180 }}
        className="justify-center relative"
      >
        {initialValue.length === 0 ? (
          <div
            ref={ref}
            className="absolute top-0 left-0 w-full h-full show-on-hover-parent flex justify-center items-center"
          >
            <div className="w-6 h-6 border border-gray-500 border-dashed rounded-full flex justify-center items-center show-on-hover-child">
              <UserPlusIcon className="w-3 h-3" />
            </div>
          </div>
        ) : (
          <div ref={ref} onClick={stopEventPropagation}>
            <AvatarGroup max={3} classes={{ avatar: classes.avatarRoot }}>
              {initialValue &&
                initialValue.map((user) => (
                  <Collaborator
                    removeBtn={taskPermission.update}
                    teammate={user}
                    handleRemove={handleRemove}
                  />
                ))}
            </AvatarGroup>
          </div>
        )}
      </CellWrapper>
      <CollabDrowdown
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        meData={meData}
        onClose={handleClosePopup}
        list={collabs}
        handleListItemClick={selectCollaborator}
      />
    </>
  )
}

export default PersonCell
