import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllServices,
  fetchServiceCategories,
  deleteServiceCategory
} from 'thunks/service/actions'
import NewService from './NewService'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
// import SearchBar from 'global/globalComponents/SearchBar/SearchBar'
import Box from '@material-ui/core/Box/Box'
import CancelRequests from './CancelRequests'
import ServicePreviewModal from './ServicePreviewModal'
import CheckoutLinkModal from './CheckoutLinkModal'
import ServiceHeader from './ServiceHeader'
import CategoriesModal from './CategoriesModal'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import ServiceTable from './ServiceTable'
import { CustomTabs } from 'global/globalComponents'
import { fetchCancelServiceRequests } from 'thunks/cancelClientServiceRequest/actions'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'

export default function Service() {
  const dispatch = useDispatch()
  const permission = useSelector((state) => state.permission)
  const me = useSelector((state) => state.me.data)
  const userServices = useSelector((state) => state.userServices)
  const cancelClientServiceReq = useSelector(
    (state) => state.cancelClientServiceRequest
  )
  const search = useSelector((state) => state.generalAppState.headerSearch)
  const [selectedTab, setSelectedTab] = useState(0)
  const [page, setPage] = useState(0)
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [shareLink, setShareLink] = useState({
    open: false,
    data: {}
  })
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false)
  const [serviceCategories, setServiceCategories] = useState([])
  const [categoryAlert, setCategoryAlert] = useState({
    open: false,
    loading: false,
    data: {}
  })
  const [openServiceModal, setOpenServiceModal] = useState({
    type: '',
    data: null
  })

  useEffect(() => {
    fetchServiceCategories((res, err) => {
      if (!err) {
        setServiceCategories(res)
      }
    })
  }, [])

  useEffect(() => {
    if (!cancelClientServiceReq.fetched) {
      dispatch(fetchCancelServiceRequests())
    }
  }, [cancelClientServiceReq.fetched])

  const handleOpenServiceModal = (data) => {
    if (permission.loading) return

    if (permission.data.access?.services.update) {
      setOpenServiceModal(data)
    } else {
      dispatch(fireErrorToaster('You are not an authorized user'))
    }
  }

  const handleOpenNewServiceModal = () => {
    if (permission.loading) return

    if (permission.data.access?.services.update) {
      setOpenServiceModal({ type: 'create', data: null })
    } else {
      dispatch(fireErrorToaster('You are not an authorized user'))
    }
  }

  const handleCloseServiceModal = () => {
    setOpenServiceModal({ type: '', data: null })
  }

  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value)
  //   setPage(0)
  // }

  const handleTabChange = useCallback((newVal) => {
    setSelectedTab(newVal)
  }, [])

  useEffect(() => {
    if (!userServices.fetched) {
      dispatch(fetchAllServices())
      dispatch(fetchCancelServiceRequests())
    }
    // dispatch(updateUserAcces(me.role))
  }, [userServices.fetched])

  const dateFormatter = (date) => {
    return date.slice(0, 10)
  }

  const shareCheckoutLinkHandler = (serviceData) => {
    if (shareLink.open) {
      setShareLink((prev) => ({ open: false, data: {} }))
    } else {
      if (!serviceData.isActive) {
        dispatch(
          fireErrorToaster(
            'Please first archive this service to generate the link'
          )
        )
        return
      }
      if (!serviceData.displayOnCatalog) {
        dispatch(
          fireErrorToaster(
            'Please display your service in catalog to generate the link'
          )
        )
        return
      }
      setShareLink((prev) => ({ open: true, data: serviceData }))
    }
  }

  const handlePreviewModal = () => {
    setOpenPreviewModal((prev) => !prev)
  }

  const toggleCategoriesModal = () => {
    setOpenCategoriesModal((prev) => !prev)
  }

  const handleUpdateServiceCategory = (data) => {
    setServiceCategories((prev) =>
      prev.map((item) => {
        if (item._id === data._id) return data
        return item
      })
    )
  }

  const addNewCategory = (data) => {
    setServiceCategories((prev) => [...prev, data])
  }

  const openCategoryAlert = (data) => {
    setCategoryAlert({ open: true, loading: false, data })
  }
  const closeCategoryAlert = () => {
    setCategoryAlert((prev) => ({ ...prev, open: false, loading: false }))
  }

  const handleDeleteCategory = () => {
    setCategoryAlert((prev) => ({ ...prev, loading: true }))
    deleteServiceCategory(categoryAlert.data._id, deleteServiceCategoryCallback)
  }

  const deleteServiceCategoryCallback = (res, err) => {
    if (!err) {
      setCategoryAlert({ loading: false, data: {}, open: false })
      setServiceCategories((prev) =>
        prev.filter((item) => item._id !== res._id)
      )
    } else {
      setCategoryAlert((prev) => ({ ...prev, loading: false }))
      dispatch(fireErrorToaster(res))
    }
  }

  return (
    <>
      <div>
        <ServiceHeader
          handlePreviewModal={handlePreviewModal}
          services={userServices.data}
          // handleCreateServicePanel={handleCreateServicePanel}
          handleOpenNewServiceModal={handleOpenNewServiceModal}
          openCategoriesModal={toggleCategoriesModal}
        />
        <div>
          <Box className="flex justify-between items-center mb-6">
            <div className="flex-1">
              <CustomTabs
                tabsData={[
                  {
                    label: 'Services',
                    value: 0,
                    number: userServices.data.length
                  },
                  {
                    label: 'Cancellations',
                    value: 1,
                    number: cancelClientServiceReq.data.length
                  }
                ]}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
            {/* <SearchBar placeholder="Search…" onChange={handleSearchChange} /> */}
          </Box>

          {search !== '' && (
            <p className="text-sm mb-2 ml-1 text-gray-500 font-medium">
              Search results for:{' '}
              <span className="text-primary-dark">{search}</span>
            </p>
          )}

          {selectedTab === 0 ? (
            <ServiceTable
              search={search}
              handleOpenServiceModal={handleOpenServiceModal}
              shareCheckoutLinkHandler={shareCheckoutLinkHandler}
            />
          ) : (
            <CancelRequests search={search} dateFormatter={dateFormatter} />
          )}
        </div>

        {openServiceModal.type === 'create' && (
          <NewService
            open={openServiceModal.type === 'create'}
            onClose={handleCloseServiceModal}
            serviceCategories={serviceCategories}
          />
        )}
        {openServiceModal.type === 'edit' && (
          <NewService
            open={openServiceModal.type === 'edit'}
            onClose={handleCloseServiceModal}
            data={openServiceModal.data}
            serviceCategories={serviceCategories}
          />
        )}

        {openPreviewModal && (
          <ServicePreviewModal
            open={openPreviewModal}
            handleClose={handlePreviewModal}
          />
        )}
        {shareLink.open && (
          <CheckoutLinkModal
            service={shareLink}
            handleClose={shareCheckoutLinkHandler}
          />
        )}
      </div>

      <CategoriesModal
        open={openCategoriesModal}
        onClose={toggleCategoriesModal}
        serviceCategories={serviceCategories}
        handleUpdateServiceCategory={handleUpdateServiceCategory}
        openCategoryAlert={openCategoryAlert}
        addNewCategory={addNewCategory}
      />
      <AlertModal
        warningText={`Do you want to delete ${categoryAlert.data.name}`}
        open={categoryAlert.open}
        handleDialog={closeCategoryAlert}
        handleDeleteAction={handleDeleteCategory}
        loading={categoryAlert.loading}
      />
    </>
  )
}
