import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import CellWrapper from './CellWrapper'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { isEqual } from 'lodash'

const DateCell = (props) => {
  const {
    value,
    row: { index, values, original },
    column: { id, fieldType },
    updateDynamicColData,
    handleTaskDateChange,
    taskPermission
  } = props

  const [openCalendar, setOpenCalendar] = useState(false)
  const [date, setDate] = useState(value)

  useEffect(() => {
    if (value && value !== date) {
      setDate(value)
    }
  }, [value])

  const handleDateChange = (date) => {
    setDate(date)
  }

  const handleClick = () => {
    setOpenCalendar(true)
  }

  const closeCalender = () => {
    setOpenCalendar(false)
    if (value === date) return
    if (fieldType === 'static') {
      // updateKey, updateValue, task, cb
      handleTaskDateChange({
        updateKey: id,
        updateValue: date ? new Date(date).toISOString() : null,
        task: original
      })
      // updateStaticColData({
      //   rowIndex: index,
      //   columnId: id,
      //   value: date ? new Date(date).toISOString() : null,
      //   updateData: { [id]: date ? new Date(date).toISOString() : null },
      //   taskId: original.taskId
      // })
    } else {
      updateDynamicColData({
        rowIndex: index,
        columnId: id,
        value: date ? new Date(date).toISOString() : null,
        allRowValues: values,
        taskId: original.taskId
      })
    }
  }

  return (
    <CellWrapper
      className="flex"
      styles={{ minWidth: 80 }}
      onClick={taskPermission.update ? handleClick : undefined}
    >
      {!date ? (
        <div
          style={{ minHeight: 'inherit' }}
          className="w-full h-full show-on-hover-parent flex justify-center items-center"
        >
          <CalendarIcon className="show-on-hover-child w-4 h-4" />
          {openCalendar && (
            <DatePicker
              style={{ width: '100%', height: '100%' }}
              selected={date ? new Date(date) : false}
              onChange={handleDateChange}
              showTimeSelect
              customInput={
                date ? (
                  <input className="text-xs bg-transparent" />
                ) : (
                  <div className="w-full h-full bg-transparent">{date}</div>
                )
              }
              dateFormat="MMM d, yyyy h:mm aa"
              className="text-red-400"
              open={openCalendar}
              onClickOutside={closeCalender}
            />
          )}
        </div>
      ) : (
        <div className="px-3">
          <DatePicker
            style={{ width: '100%', height: '100%' }}
            selected={date ? new Date(date) : false}
            onChange={handleDateChange}
            showTimeSelect
            customInput={
              date ? (
                <input className="text-xs bg-transparent" />
              ) : (
                <div className="w-full h-full bg-transparent">{date}</div>
              )
            }
            dateFormat="MMM d, yyyy h:mm aa"
            className="text-red-400"
            open={openCalendar}
            onClickOutside={closeCalender}
          />
        </div>
      )}
    </CellWrapper>
  )
}

// const MyContainer = ({ className, children, includeTime, setIncludeTime }) => {
//   const handleChange = () => {
//     setIncludeTime((prev) => !prev)
//   }

//   return (
//     <div className="p-4 bg-white border">
//       <CalendarContainer className={className}>
//         <div style={{ position: 'relative' }}>{children}</div>
//       </CalendarContainer>
//       <div className="text-left flex items-center">
//         <p className="text-xs text-gray-500">Include time</p>
//         <span className="ml-2">
//           <ToggleSwitch checked={includeTime} onChange={handleChange} />
//         </span>
//       </div>
//     </div>
//   )
// }

// ===== Prevents unnecessary re-rendering ====
const canRender = (prevState, nextState) => {
  return isEqual(prevState.value, nextState.value)
}

export default React.memo(DateCell, canRender)
