import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import {
  deleteWorkspace,
  archiveWorkspace,
  UpdateWorkspceCompleteStatus,
  createWorkspaceCopy
} from 'thunks/workspace/actions'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { useDispatch } from 'react-redux'
// import clsx from 'clsx'
import ProjectCard from './ProjectCard'
import WorkspaceMenu from '../WorkspaceMenu'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireProcessToaster
} from 'thunks/fireToaster/actions'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
// import { toggleAddWorkspaceSidePanel } from 'thunks/generlAppState/actions'
// import AsanaIcons from 'static/asana/AsanaIcons'
import UserDashboardStyles from '../../userDashboardStyles'
import EditWorkspaceDialog from '../EditWorkspaceDialog'
import useStyles from './projectsCardViewStyles'
import clsx from 'clsx'

const ProjectCards = ({ data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [deleteWorkspaceModal, setDeleteWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [archiveWorkspaceModal, setArchiveWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [editWorkspaceDialog, setEditWorkspaceDialog] = useState({
    open: false,
    workspace: {}
  })
  const [completeWorkspaceDialog, setCompleteWorkspaceDialog] = useState({
    open: false,
    workspace: {},
    loading: false
  })
  const [selectedWorkspace, setSelectedWorkspace] = useState({})
  const [workspaceSubMenuData, setWorkspaceSubMenuData] = useState({
    opne: false,
    anchorEl: null
  })

  const handleDeleteWorkspaceModal = (workspace) => {
    if (deleteWorkspaceModal.open) {
      setDeleteWorkspaceModal((prev) => ({
        ...prev,
        open: false,
        loading: false
      }))
    } else {
      setDeleteWorkspaceModal({
        open: true,
        data: workspace,
        loading: false
      })
    }
  }

  const handleArhciveWorkspaceModal = (workspace) => {
    if (archiveWorkspaceModal.open) {
      setArchiveWorkspaceModal((prev) => ({
        ...prev,
        open: false,
        loading: false
      }))
    } else {
      setArchiveWorkspaceModal({
        open: true,
        data: workspace,
        loading: false
      })
    }
  }

  const handleCompleteWorkspaceModal = (workspace) => {
    if (completeWorkspaceDialog.open) {
      setCompleteWorkspaceDialog((prev) => ({
        ...prev,
        open: false,
        loading: false
      }))
    } else {
      setCompleteWorkspaceDialog({
        open: true,
        data: workspace,
        loading: false
      })
    }
  }

  // const fetchAllWorkspacesForCurrentUserCallback = (res, err) => {}

  const handleOpenEditWorkspaceDialog = (workspace) => {
    if (editWorkspaceDialog.open) {
      setEditWorkspaceDialog({
        open: false,
        workspace: {}
      })
    } else {
      setEditWorkspaceDialog({
        open: true,
        workspace
      })
    }
  }
  useEffect(() => {}, [])
  const deleteWorkspaceCallback = (res, err) => {
    if (err) {
      setDeleteWorkspaceModal((prev) => ({ ...prev, loading: false }))
    } else {
      handleDeleteWorkspaceModal()
    }
  }

  const handleDeleteWorkspace = () => {
    setDeleteWorkspaceModal((prev) => ({ ...prev, loading: true }))
    dispatch(
      deleteWorkspace(deleteWorkspaceModal.data._id, deleteWorkspaceCallback)
    )
  }

  const handleArchiveWorkspace = () => {
    setArchiveWorkspaceModal((prev) => ({ ...prev, loading: true }))
    dispatch(
      archiveWorkspace(
        archiveWorkspaceModal.data._id,
        !archiveWorkspaceModal.data.isArchived,
        handleArchiveCallback
      )
    )
  }

  const handleArchiveCallback = (res, err) => {
    if (err) setArchiveWorkspaceModal((prev) => ({ ...prev, loading: false }))
    else handleArhciveWorkspaceModal()
  }

  const handleOpenWorkspaceSubMenu = (e, workspace) => {
    if (e) {
      e.preventDefault()
      setSelectedWorkspace(workspace)
      setWorkspaceSubMenuData({ open: true, anchorEl: e.currentTarget })
    } else {
      // setSelectedWorkspace({})
      setWorkspaceSubMenuData({ open: false, anchorEl: null })
    }
  }

  const completestatusCallback = (res, err) => {
    handleCompleteWorkspaceModal()
  }

  const completeStatus = () => {
    setCompleteWorkspaceDialog((prev) => ({ ...prev, loading: true }))

    dispatch(
      UpdateWorkspceCompleteStatus(
        completeWorkspaceDialog.data._id,
        { isComplete: true },
        completestatusCallback
      )
    )
  }

  const duplicateWorkspaceHandler = (workspace, data) => {
    dispatch(
      createWorkspaceCopy(workspace?._id, data, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(`Duplicate was created successfully!`))
          dispatch(
            fetchWorkspacePermissions((res, err) => {
              // setLoading(false)
              if (err) {
                dispatch(fireErrorToaster(res))
              }
            })
          )
        } else {
          // setLoading(false)
          dispatch(fireErrorToaster(res))
        }
      })
    )
    // setLoading(true)
    dispatch(
      fireProcessToaster(
        `Duplicate of ${workspace.name} is being created! Please wait...`
      )
    )
  }

  return (
    <Box className={clsx(classes.cardsContainer, 'mb-12')}>
      {data.map((item) => (
        <ProjectCard
          handleSubMenu={handleOpenWorkspaceSubMenu}
          key={item._id}
          activeMenu={item._id === selectedWorkspace._id}
          workspaceData={item}
          elmFor="workspace"
        />
      ))}

      <WorkspaceMenu
        open={workspaceSubMenuData.open}
        anchorEl={workspaceSubMenuData.anchorEl}
        onClose={() => handleOpenWorkspaceSubMenu(false)}
        workspace={selectedWorkspace}
        EditWorkspace={handleOpenEditWorkspaceDialog}
        duplicateWorkspace={duplicateWorkspaceHandler}
        deleteWorkspace={handleDeleteWorkspaceModal}
        archiveWorkspace={handleArhciveWorkspaceModal}
        completeStatus={handleCompleteWorkspaceModal}
      />
      <EditWorkspaceDialog
        handleDialog={handleOpenEditWorkspaceDialog}
        open={editWorkspaceDialog.open}
        workspace={editWorkspaceDialog.workspace}
      />

      <AlertModal
        heading={deleteWorkspaceModal.data?.name}
        warningText="All the tasks and attachments related to this project will be deleted."
        open={deleteWorkspaceModal.open}
        handleDialog={handleDeleteWorkspaceModal}
        handleDeleteAction={handleDeleteWorkspace}
        loading={deleteWorkspaceModal.loading}
      />
      {/* <DeleteModal
        warningText="All the tasks and attachments related to this workspace wil be deleted."
        open={deleteWorkspaceModal.open}
        handleDialog={handleDeleteWorkspaceModal}
        handleDeleteAction={handleDeleteWorkspace}
        loading={deleteWorkspaceModal.loading}
      /> */}

      {/* Archive Modal */}
      <AlertModal
        heading={archiveWorkspaceModal.data?.name}
        warningText={`All the tasks and attachments related to this project will be ${
          archiveWorkspaceModal.data.isArchived ? 'unarchived' : 'archived'
        }.`}
        open={archiveWorkspaceModal.open}
        handleDialog={handleArhciveWorkspaceModal}
        handleDeleteAction={handleArchiveWorkspace}
        loading={archiveWorkspaceModal.loading}
        deleteBtnText={
          archiveWorkspaceModal.data.isArchived ? 'Unarchive' : 'Archive'
        }
      />
      {/* Mark As complete Dialog Box */}
      <AlertModal
        heading={completeWorkspaceDialog.data?.name}
        warningText="Once you mark this project as completed you won't be able to change its status back and won't be able to make any changes either."
        open={completeWorkspaceDialog.open}
        handleDialog={handleCompleteWorkspaceModal}
        handleDeleteAction={completeStatus}
        loading={completeWorkspaceDialog.loading}
        deleteBtnText="Complete"
      />

      {/* <DeleteModal
        warningText="All the tasks and attachments related to this project will be marked as Completed."
        open={completeWorkspaceDialog.open}
        handleDialog={handleCompleteWorkspaceModal}
        handleDeleteAction={completeStatus}
        loading={completeWorkspaceDialog.loading}
        deleteBtnText="Complete"
      /> */}
    </Box>
  )
}

export default ProjectCards
