import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import loginPattern from 'static/Images/login-pattern.png'
import { hideChatMessenger } from 'utils'

const PatternScreen = ({ bgImage, children }) => {
  useEffect(() => {
    hideChatMessenger()
  })

  const styleProps = {
    bgImage: bgImage ?? loginPattern
  }

  const classes = useStyles(styleProps)

  return (
    <div className={clsx(classes.container, 'w-screen h-screen bg-white')}>
      <section className="md:w-1/2 h-full bg-white flex justify-center items-center">
        <div className="w-2/3" style={{ maxWidth: 420, minWidth: 350 }}>
          {children}
        </div>
      </section>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  container: ({ bgImage }) => ({
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: '145% 50%',
    backgroundSize: '78%',
    backgroundRepeat: 'no-repeat'
  })
}))

export default PatternScreen
