import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createNewWebhook, updateSingleWebhook } from 'thunks/webhooks/action'

import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const WebhookModal = ({ open, edit, data, onClose, elmFor }) => {
  const dispatch = useDispatch()
  const { allEvents } = useSelector(state => state.userWebhooks)
  const [webhookData, setWebhookData] = useState({
    name: '',
    url: ''
  })
  const [activeEvents, setActiveEvents] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (edit) {
      setWebhookData({ name: data.name, url: data.endpoint })
      setActiveEvents(data.events)

      const eventsObj = {}
      for (let eventId of data.events) {
        eventsObj[eventId] = true
      }

      setActiveEvents(eventsObj)
    } else {
      setActiveEvents({})
      setWebhookData({ name: '', url: '' })
    }
  }, [open, edit, data])

  const handleWebhookChange = e => {
    setWebhookData(prev => ({
      ...prev,
      [e.target.dataset.field]: e.target.value
    }))
  }

  const handleChangeActiveEvent = eventId => {
    const obj = { ...activeEvents }

    if (activeEvents[eventId]) delete obj[eventId]
    else obj[eventId] = true

    setActiveEvents(obj)
  }

  const handleSaveChanges = () => {
    if (
      elmFor === 'slackWebhook' &&
      !webhookData.url.startsWith('https://hooks.slack.com')
    ) {
      dispatch(fireErrorToaster('Invalid url'))
      return
    }

    setLoading(true)
    const bodyData = {
      name: webhookData.name,
      endpoint: webhookData.url,
      events: Object.keys(activeEvents)
    }

    if (edit) {
      dispatch(updateSingleWebhook(data._id, bodyData, updateWebhookCallback))
    } else {
      if (elmFor === 'slackWebhook') bodyData.type = 'slack'
      dispatch(createNewWebhook(bodyData, createNewWebhookCallback))
    }
  }

  const updateWebhookCallback = (res, err) => {
    setLoading(false)
    if (err) dispatch(fireErrorToaster(res))
    else {
      dispatch(fireSuccessToaster('Changes saved'))
      onClose()
    }
  }

  const createNewWebhookCallback = (res, err) => {
    setLoading(false)
    if (err) dispatch(fireErrorToaster(res))
    else {
      dispatch(fireSuccessToaster('Webhook Added Successfully!'))
      onClose()
    }
  }

  return (
    <CustomModal open={open} handleClose={onClose} size="small">
      <CustomModalHeader heading={edit ? 'Edit Webhook' : 'Add a Webhook'} />
      <CustomModalBody>
        <div className="mb-8">
          <form>
            <div className="mb-6">
              <TextInput
                value={webhookData.name}
                placeholder="Webhook Name"
                size="medium"
                rounded
                onChange={handleWebhookChange}
                inputProps={{ 'data-field': 'name' }}
              />
            </div>
            <div>
              <TextInput
                value={webhookData.url}
                placeholder="Webhook Post URL"
                size="medium"
                rounded
                onChange={handleWebhookChange}
                inputProps={{ 'data-field': 'url' }}
              />
              <small className="ml-3 font-medium text-gray-400">
                Where do you want us to hit
              </small>
            </div>
          </form>
        </div>

        <div>
          <h4 className="text-primary-dark font-bold text-lg mb-2">Setting</h4>

          <div>
            {allEvents.map(item => (
              <SingleEvent
                key={item._id}
                event={item}
                isActive={activeEvents[item._id] ?? false}
                handleChangeActiveEvent={handleChangeActiveEvent}
              />
            ))}
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton onClick={handleSaveChanges} loading={loading}>
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

const SingleEvent = ({ event, isActive, handleChangeActiveEvent }) => {
  const handleChange = () => {
    handleChangeActiveEvent(event._id)
  }

  return (
    <div className="flex items-center justify-between mb-1">
      <label
        htmlFor={event.name}
        className="text-smaller lg:text-sm font-medium text-primary-dark"
      >
        {event.name}
      </label>
      <span>
        <ToggleSwitch
          checked={isActive}
          onChange={handleChange}
          loading={false}
          id={event.name}
        />
      </span>
    </div>
  )
}
export default WebhookModal
