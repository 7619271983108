import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import { errorMessages } from 'utils'
// import NewTeammateModal from './NewTeammateStructure/NewTeammateModal'
// import NewClientModal from './NewTeammateStructure/NewClientModal'
import ProjectStats from './ProjectStats/ProjectStats'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import {
  getProjectStats
  // fetchProjectStatusPosts
} from 'thunks/workspace/actions'
import { useParams } from 'react-router-dom'
import { toggleEditTeammatesSidePanel } from 'thunks/generlAppState/actions'

const OverView = () => {
  // const workspaceContext = useContext(SingleWorkspaceContext)
  const dispatch = useDispatch()
  const workspace = useSelector(state => state.userWorkspaces.currentWorkspace)
  // const [openAddPanel, setOpenAddPanel] = React.useState(false)
  // const meData = useSelector((state) => state.me.data)
  const { workspaceState } = useContext(SingleWorkspaceContext)
  const { workspacePermission } = workspaceState.permission
  const [projectStats, setProjectStats] = useState({
    loading: true,
    data: {}
  })
  // const [statusPosts, setStatusPosts] = useState({ loading: true, data: [] })
  const { workspaceId } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (workspaceId) {
      getProjectStats(workspaceId, projectStatsCallback)
    }
  }, [workspaceId])

  // useEffect(() => {
  //   fetchProjectStatusPosts(workspaceId, (res, err) => {
  //     if (!err) {
  //       setStatusPosts({
  //         loading: false,
  //         data: res.filter((item) => item.state !== 'draft').reverse()
  //       })
  //     }
  //   })
  // }, [workspaceId])

  const projectStatsCallback = (res, err) => {
    if (!err) {
      setProjectStats({ loading: false, data: res })
    }
  }

  function handleChildClick() {
    if (workspace.isComplete)
      dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
    else {
      if (workspacePermission?.update) {
        dispatch(toggleEditTeammatesSidePanel())
      } else {
        dispatch(fireErrorToaster('You are not an authorized user'))
      }
    }
  }

  // const handleTeammatesEditPanel = () => {
  //   // setOpenAddPanel((oldState) => !oldState)
  // }

  return (
    <>
      <div className="overflow-x-auto">
        <div className="mb-8">
          <h1 className="text-lg lg:text-xl font-semibold text-primary-dark">
            How we'll collaborate
          </h1>
          <p className="text-smaller lg:text-sm mt-3 text-custom-gray-light-1">
            {workspace.description ||
              ` Welcome your team and set the tone for how you’ll work together. Add
            meeting details, communication channels, and other important
            information.`}
          </p>
        </div>
        <div>
          <ProjectStats
            projectStats={projectStats}
            openInviteTeammate={handleChildClick}
            // statusPosts={statusPosts}
            // statusPosts={workspaceState.statusPosts}
          />
        </div>
        {/* <div className={`h-full ${classes.overview}`}>
        </div> */}
        {/* {meData.role < userRoles.USER_CLIENT ? (
          <NewTeammateModal
            open={openAddPanel}
            onChildClick={handleChildClick}
            onClose={handleTeammatesEditPanel}
            // openAddTeammatePanel={handleToggleAddPanelOpen}
          />
        ) : (
          <NewClientModal
            open={openAddPanel}
            onChildClick={handleChildClick}
            onClose={handleTeammatesEditPanel}
          />
        )} */}
      </div>
    </>
  )
}

export default OverView
