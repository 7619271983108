import { CustomPopper } from 'global/globalComponents'

const InfoPopper = ({ element, data, onClose }) => {
  return (
    <CustomPopper anchorEl={element} onClose={onClose}>
      <div className="w-48 p-3">
        <div className="flex justify-between items-center mb-1">
          <span className="text-sm font-semibold text-gray-500">
            Completed tasks
          </span>
          <span className="text-sm text-gray-900 font-semibold">
            {data.completedTasks}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-sm font-semibold text-gray-500">
            Total tasks
          </span>
          <span className="text-sm text-gray-900 font-semibold">
            {data.totalTasks}
          </span>
        </div>
      </div>
    </CustomPopper>
  )
}

export default InfoPopper
