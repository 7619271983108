import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as Add } from 'static/svg/plus.svg'
// import NewVersionCreateModal from '../NewVersionCreateModal'
import { useDispatch, useSelector } from 'react-redux'
// import { fetchFileAndAssetsBytask } from 'thunks/fileAssets/actions'
// import CreateVersionModal from '../CreateVersionModal'
// import { fetchUserById } from 'thunks/user/actions'
import {
  deleteTaskByID,
  updateTaskDetails,
  // updateTaskPriority,
  uploadAttachment,
  deleteAttachment
  // updateTaskDetailsteam
} from 'thunks/task/actions'
import { findCommentBytaskId, postTaskLog } from 'thunks/logs/action'
import { getAllSubTask } from 'thunks/subTask/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import { userRoles, hideChatMessenger, showChatMessenger } from 'utils'
import clsx from 'clsx'
import 'react-datetime/css/react-datetime.css'
import SubtaskPanel from './SubtaskPanel'
import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
// import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import AttachmentDropDown from '../AttachmentDropDown'
import StatusDropDown from '../StatusDropDown'
// import taskStatus from 'utils/taskStatus'
import 'react-quill/dist/quill.snow.css'
import { DrawerContent } from 'global/globalComponents/CustomDrawer/CustomDrawer'
import taskSidePanelStyles from './taskSidePanelStyles'
import ShareTaskModal from '../ShareTaskModal'
import LogsSection from './LogsSection'
import TaskPanelHeader from './TaskPanelHeader'
import TaskPanelFooter from './TaskPanelFooter'
// import useWorkspacePermission from 'customHooks/useWorkspacePermission'
// import { errorMessages } from 'utils'
import SingleTaskPusherWrapper from 'components/Pusher/SingleTaskPusherWrapper'
import Subtasks from './Subtasks'
import TaskBody from './TaskBody'
import TaskTitle from './TaskTitle'
import TaskDescription from './TaskDescription'
import CollabDrowdown from './CollabDrowdown'
import AddAttachment from './AddAttachment'
import { CircularProgress } from '@material-ui/core'
import RecurringDropDown from '../RecurringDropDown'
import Tags from 'components/UserDashboard/Workspaces/Tags'
// import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
// import { createAccountLogs } from 'thunks/accountLogs/action'
import AttachmentImage from './AttachmentImage'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'
import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
import {
  showHeaderShadow,
  handleFilePreview
} from 'thunks/generlAppState/actions'
import { Drawer } from '@material-ui/core'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import TaskPanelLoading from './TaskPanelLoading'
import ApprovalPanel from './Approval/ApprovalPanel'
import { fetchTaskApprovals } from 'thunks/approvals/actions'
import { useWorkspacePermission } from 'customHooks'
import AutoSavingIndicator from './AutoSavingIndicator'
import { userRoles } from 'utils'
import { moveSectionTask } from 'thunks/task/actions'
import moment from 'moment'
import { dateAndTimeHelper } from 'utils'

export default function TaskPanel({
  open,
  onClose,
  taskData,
  inDashboardArea = false,
  isLoading,
  ...rest
}) {
  const classes = taskSidePanelStyles({ inDashboardArea })

  return (
    <SingleTaskPusherWrapper taskId={taskData._id} connect={open}>
      <Drawer
        onClose={onClose}
        classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
        anchor="right"
        open={open}
        transitionDuration={400}
        // variant="persistent"
      >
        {/* ============== HEADER =============== */}
        {isLoading ? (
          <TaskPanelLoading />
        ) : (
          <TaskPanelContents
            open={open}
            onClose={onClose}
            taskData={taskData}
            inDashboardArea={inDashboardArea}
            isLoading={isLoading}
            classes={classes}
            {...rest}
          />
        )}
      </Drawer>
    </SingleTaskPusherWrapper>
  )
}

const TaskPanelContents = ({
  open,
  onClose,
  taskData,
  // taskPermission,
  // subtaskPermission,
  groupBy = 'status',
  // aboveTask,
  isOutsideBoardArea,
  type,
  updateProjectStats,
  inDashboardArea,
  classes,
  afterTaskDelete,
  afterTaskStatusChange,
  afterDueDateChange,
  afterCollaboratorsChange
}) => {
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const {
    task: taskPermission,
    subtask: subtaskPermission
  } = useWorkspacePermission(taskData.workSpace)

  const logsBottomRef = useRef(null)
  const [openDeleteTaskConfirm, setOpenDeleteTaskConfirm] = useState(false)
  const logsData = useSelector(state => state.logs.data)

  const meData = useSelector(state => state.me.data)
  const taskTags = useSelector(state => state.userTags.currentWorkspaceTags)
  const [openSubTask, setOpenSubTask] = useState({ open: false, data: {} })
  const [collabOptions, setCollabOptions] = useState([])
  const [assignedTags, setAssignedTags] = useState([])
  const [attachmentDropdownData, setAttachmentDropdownData] = useState({
    anchorEl: null,
    open: false,
    data: {}
  })
  const [shareTask, setShareTask] = useState({
    open: false,
    data: {}
  })

  const [taskState, setTaskState] = useState({
    title: '',
    workSpace: '',
    taskSection: {},
    createdAt: `${new Date()}`,
    startDate: '',
    dueDate: '',
    description: '',
    assignedAgencyTeam: [],
    assignedClientTeam: [],
    imageUrl: [],
    comment: '',
    status: null,
    user: {},
    approval: {},
    estimateTimeInMillisecond: '',
    estimateTimeInString: ''
  })

  const [loadingAttachment, setLoadingAttachment] = useState(false)

  const [deleteTaskLoading, setDeleteTaskLoading] = useState(false)
  // const [imgPreview, setImgPreview] = useState({
  //   open: false,
  //   url: ''
  // })

  const [tagsPopoverData, setTagsPopoverData] = useState({
    open: false,
    anchorEl: null
  })
  const [selectedTab, setSelectedTab] = useState(0)
  // const [boardArea, setBoardArea] = useState(false)
  const allSubtasks = useSelector(state => state.subTasks.data)
  const { subtaskId } = useParams()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { socketId } = useContext(WorkspacePusherContext)
  const [approvalPanel, setApprovalPanel] = useState({
    open: false,
    loading: true,
    data: []
  })
  const [savingStatus, setSavingStatus] = useState('') // statuses: "saving", "saved", "failed"
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [popperOpen, setPopperOpen] = useState('')

  const {
    getCollaboratorsOptions,
    handleTaskDateChange,
    // updateTaskDueDateInDueDateGroup,
    canUpdateTask,
    addTaskCollaborator,
    removeTaskCollaborator,
    changeTaskSection,
    updateLog,
    updateAccountLogs,
    changeTaskPriority,
    markTaskAsComplete
    // removeTaskCollaborator,
    // handleTaskDueDateChange,
    // changeTaskPositionToBottom
  } = useWorkspaceFunctions(groupBy)

  useEffect(() => {
    dispatch(showHeaderShadow(!open))
  }, [open])

  useEffect(() => {
    if (taskData._id) {
      setTaskState({
        estimateTimeInString: '', // old tasks may not have this prop
        estimateTimeInMillisecond: '', // old tasks may not have this prop
        ...taskData
      })
    }
  }, [taskData])

  useEffect(() => {
    if (open && taskData._id) {
      dispatch(getAllSubTask(taskData._id))
      dispatch(findCommentBytaskId(taskData._id))
    }
  }, [open, taskData._id])

  useEffect(() => {
    if (taskState.assignedAgencyTeam) {
      setCollabOptions(
        getCollaboratorsOptions(
          [...taskState.assignedAgencyTeam, ...taskState.assignedClientTeam],
          currentWorkspace
        )
      )
    }
  }, [taskState.assignedAgencyTeam, taskState.assignedClientTeam])

  // useEffect(() => {
  //   setCollabOptions(getCollaboratorsOptions(taskState))
  // }, [taskState])

  useEffect(() => {
    if (taskState._id) {
      let tags = taskTags.filter(tag => tag.taskIds.includes(taskState._id))
      setAssignedTags(tags)
    }
  }, [taskState, taskTags])

  // useEffect(() => {
  //   setBoardArea(url.includes('/board'))
  // }, [url])

  useEffect(() => {
    if (type !== 'overview' && !inDashboardArea) {
      const subtaskToOpen = allSubtasks.find(item => item._id === subtaskId)
      if (!subtaskId) setOpenSubTask(prev => ({ ...prev, open: false }))
      if (subtaskToOpen) setOpenSubTask({ open: true, data: subtaskToOpen })
    }
  }, [subtaskId, allSubtasks, type])

  useEffect(() => {
    if (taskData._id && approvalPanel.open) {
      setApprovalPanel(prev => ({ ...prev, loading: true }))
      fetchTaskApprovals(taskData._id, 'task', (res, err) => {
        if (!err) {
          setApprovalPanel(prev => ({ ...prev, loading: false, data: res }))
        } else {
          setApprovalPanel(prev => ({ ...prev, loading: false }))
          dispatch(fireErrorToaster(res))
        }
      })
    }
  }, [taskData._id, approvalPanel.open])

  const taskUpdateCallback = (res, err, options = {}) => {
    if (err) {
      setSavingStatus('failed')
      dispatch(fireErrorToaster(res))
      if (options.resetProp) {
        setTaskState(prev => ({
          ...prev,
          [options.resetProp]: taskData[options.resetProp]
        }))
      }
    } else {
      setSavingStatus('saved')
    }
  }

  const handleDateChange = (e, prop) => {
    if (!canUpdateTask(taskPermission.update)) return
    if (
      ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role) &&
      currentWorkspace.restrictDueDate?.isDueDateRestrict &&
      currentWorkspace.restrictDueDate?.totalDueDateChangeCounter <=
        taskData.editDueDateCounter
    ) {
      dispatch(
        fireErrorToaster('Due Date editing has been restricted by Admin')
      )
      return
    }

    const date = e ? e.toISOString() : null
    const savedDate = taskData[prop] //could be start or due date

    setTaskState(prev => ({ ...prev, [prop]: date }))
    setSavingStatus('saving')
    handleTaskDateChange({
      updateKey: prop,
      updateValue: date,
      task: taskState,
      cb: (res, err) => {
        taskUpdateCallback(res, err, { resetProp: prop })
        if (!err) {
          setTaskState(res)
          if (type === 'overview') {
            updateProjectStats(res)
          }

          if (!err && groupBy === 'dueDate' && afterDueDateChange) {
            let prevSectionId = !savedDate
              ? 'noDueDate'
              : dateAndTimeHelper.compareDate(
                  new Date(),
                  new Date(savedDate)
                ) === 1
              ? 'overdue'
              : moment(savedDate).format('DD-MM-YYYY')
            let nextSectionId = !date
              ? 'noDueDate'
              : dateAndTimeHelper.compareDate(new Date(), new Date(date)) === 1
              ? 'overdue'
              : moment(date).format('DD-MM-YYYY')

            afterDueDateChange({
              taskId: taskState._id,
              prevSectionId,
              nextSectionId,
              groupBy
            })
          }
        }
      }
    })
  }

  const uploadAttachmentCallback = (res, err) => {
    setLoadingAttachment(false)
    if (!err) {
      setTaskState(prev => ({
        ...prev,
        imageUrl: res.imageUrl
      }))
      if (type === 'overview') {
        updateProjectStats(res)
      }
      updateLog({
        taskId: res._id,
        logMsg: `uploaded an attachment in the task <b>${res.title}</b>`,
        workspaceId: res.workSpace
      })
    }
  }

  const handleAttachmentUpload = useCallback(
    result => {
      setLoadingAttachment(true)
      dispatch(
        uploadAttachment(
          {
            workSpace: taskState.workSpace,
            task: taskState._id,
            file: result
          },
          uploadAttachmentCallback
        )
      )
    },
    [taskState.workSpace, taskState._id]
  )

  const handleUploadDescriptionAttachment = (file, cb) => {
    dispatch(
      uploadAttachment(
        {
          workSpace: taskData.workSpace, //not using task state here bcoz of scoping issue
          task: taskData._id, //not using task state here bcoz of scoping issue
          file
        },
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          }
          uploadAttachmentCallback(res, err)
          cb(res, err)
        }
      )
    )
  }

  // const handleNewFileDialog = () => {
  //   if (taskPermission.update) {
  //     setNewFileDialog((prev) => ({ open: !prev.open }))
  //   } else dispatch(fireErrorToaster('You are not an authorized user'))
  // }

  const updateTitle = title => {
    setTaskState(prev => ({ ...prev, title }))

    setSavingStatus('saving')
    dispatch(
      updateTaskDetails(
        taskState._id,
        {
          title,
          workSpace: taskState.workSpace,
          taskSection: taskState.taskSection,
          socketId
        },
        (res, err) => {
          taskUpdateCallback(res, err)
          if (!err) {
            setTaskState(prev => ({
              ...prev,
              title: res.title
            }))
            if (type === 'overview') {
              updateProjectStats(res)
            }
            updateLog({
              taskId: taskState._id,
              logMsg: `changed the title`,
              workspaceId: taskState.workSpace
            })
            updateAccountLogs({
              taskTitle: taskState.title,
              logMsg: `changed the name of the task`,
              taskId: taskState._id
            })
          }
        }
      )
    )
  }

  const updateDescription = description => {
    setSavingStatus('saving')
    dispatch(
      updateTaskDetails(
        taskState._id,
        {
          description,
          workSpace: taskState.workSpace,
          taskSection: taskState.taskSection,
          updateType: 'description',
          socketId
        },
        updateTaskDetailsCallback
      )
    )
  }

  const changeEstimateTime = useCallback(
    data => {
      dispatch(
        updateTaskDetails(
          taskState._id,
          {
            ...data,
            workSpace: taskState.workSpace,
            taskSection: taskState.taskSection,
            socketId
          },
          (res, err) => {
            if (!err) {
              setTaskState(res)
              if (type === 'overview') {
                updateProjectStats(res)
              }
              updateLog({
                taskId: taskState._id,
                logMsg: `updated the estimate time ${
                  res.estimateTimeInString ? res.estimateTimeInString : ''
                } in the task <b>${taskState.title}</b>`,
                workspaceId: taskState.workSpace
              })
            }
          }
        )
      )
    },
    [taskState._id, taskState.workSpace, taskState.taskSection, socketId]
  )

  const updateTaskDetailsCallback = (res, err) => {
    taskUpdateCallback(res, err)

    if (!err) {
      if (type === 'overview') {
        updateProjectStats(res)
      }
      // dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      updateLog({
        taskId: taskData._id,
        logMsg: `changed the description`,
        workspaceId: taskData.workSpace
      })
    }
  }

  /**
   * Delete an attachment
   * @param {String} deletingAttachment url of the deleting attachment
   */
  const handleDeleteAttachment = deletingAttachment => {
    // const newData = taskState?.imageUrl?.filter((i) => i !== deletingAttachment)
    dispatch(
      deleteAttachment(
        taskData._id,
        deletingAttachment,
        deleteAttachmentCallback
      )
    )
    handleAttachmentDropDown()
  }

  const deleteAttachmentCallback = (res, err) => {
    if (!err) {
      setTaskState(prev => ({
        ...prev,
        imageUrl: res.imageUrl
      }))
      if (type === 'overview') {
        updateProjectStats(res)
      }
    }
  }

  const triggerPopover = useCallback((event, value) => {
    setAnchorEl(event.currentTarget)
    setPopperOpen(value)
  }, [])

  const handlePopperClose = () => {
    setPopperOpen('')
    setAnchorEl(null)
  }

  const handlePriorityLabel = priority => {
    if (!canUpdateTask(taskPermission.update)) return

    handlePopperClose()
    setSavingStatus('saving')
    setTaskState(prev => ({ ...prev, priority: priority.order }))
    changeTaskPriority({
      task: taskState,
      priority: priority.order,
      cb: (res, err) => {
        taskUpdateCallback(res, err, { resetProp: 'priority' })
        if (!err) {
          setTaskState(res)
          if (type === 'overview') {
            updateProjectStats(res)
          }
        }
      }
    })
  }

  const handleMarkTaskAsComplete = value => {
    setSavingStatus('saving')

    setTaskState(prev => ({ ...prev, markAsComplete: value }))
    markTaskAsComplete({
      value,
      task: taskState,
      elmFor: 'task',
      board: currentBoard,
      socketId,
      cb: (res, err) => {
        taskUpdateCallback(res, err, { resetProp: 'markAsComplete' })
        if (!err) {
          setTaskState(res)

          if (type === 'overview') {
            updateProjectStats(res)
          }
        }
      }
    })
  }

  const handleSubTaskClick = useCallback(
    sub => {
      if (openSubTask.open) {
        if (type === 'overview' || isOutsideBoardArea) {
          setOpenSubTask(prev => ({ ...prev, open: false, data: '' }))
        } else {
          history.push(url.split('/task')[0] + '/task/' + taskData._id)
        }
      } else {
        if (type === 'overview' || isOutsideBoardArea) {
          setOpenSubTask(prev => ({ ...prev, open: true, data: sub }))
        } else {
          history.push(`${url}/${sub._id}`)
        }
      }
    },
    [openSubTask.open, taskData._id, url]
  )

  const deleteTaskByIDCallback = (res, err) => {
    setDeleteTaskLoading(false)
    setOpenDeleteTaskConfirm(false)
    onClose()
    if (err) {
      dispatch(fireErrorToaster(err))
    } else {
      if (afterTaskDelete)
        afterTaskDelete({ taskId: res._id, sectionId: res.taskSection })
      // if (type === 'overview') {
      //   updateProjectStats(res, 'delete')
      // }
      dispatch(fireSuccessToaster('Deleted Successfully'))
    }
  }

  const handleDeleteTask = event => {
    setDeleteTaskLoading(true)
    dispatch(deleteTaskByID(taskState._id, deleteTaskByIDCallback))
  }

  const handleOpenDeleteTaskConfirm = useCallback(
    e => {
      if (canUpdateTask(taskPermission.update)) {
        e.stopPropagation()
        setOpenDeleteTaskConfirm(true)
      }
    },
    [taskPermission]
  )

  const handleCloseConfirm = () => {
    setOpenDeleteTaskConfirm(false)
    setAnchorEl(null)
  }

  const handleAddCollaborator = ({ userRole, userId }) => {
    handlePopperClose()

    setSavingStatus('saving')
    addTaskCollaborator({
      userRole: userRole,
      userId: userId,
      taskId: taskState._id,
      cb: (res, err) => {
        if (!err && groupBy === 'assignee') {
          // taskId, prevSectionId, nextSectionId
          const prevSectionId = taskData.assignedAgencyTeam
            .concat(taskData.assignedClientTeam)
            .map(mate => mate._id)
            .sort()
            .join('&')
          const nextSectionId = res.assignedAgencyTeam
            .concat(res.assignedClientTeam)
            .map(mate => mate._id)
            .sort()
            .join('&')

          afterCollaboratorsChange({
            taskId: taskState._id,
            prevSectionId: prevSectionId || 'noAssignee',
            nextSectionId: nextSectionId || 'noAssignee',
            updatedTask: res
          })
        }

        taskUpdateCallback(res, err)
        setTaskState(res)
        if (type === 'overview') {
          updateProjectStats(res)
        }
      }
    })
  }

  const handleRemoveCollaborator = ({ userId, userRole }) => {
    setSavingStatus('saving')
    removeTaskCollaborator({
      userRole,
      userId,
      taskId: taskState._id,
      cb: (res, err) => {
        if (!err && groupBy === 'assignee') {
          // taskId, prevSectionId, nextSectionId
          const prevSectionId = taskData.assignedAgencyTeam
            .concat(taskData.assignedClientTeam)
            .map(mate => mate._id)
            .sort()
            .join('&')
          const nextSectionId = res.assignedAgencyTeam
            .concat(res.assignedClientTeam)
            .map(mate => mate._id)
            .sort()
            .join('&')

          afterCollaboratorsChange({
            taskId: taskState._id,
            prevSectionId: prevSectionId || 'noAssignee',
            nextSectionId: nextSectionId || 'noAssignee',
            updatedTask: res
          })
        }

        taskUpdateCallback(res, err)
        setTaskState(res)
        if (type === 'overview') {
          updateProjectStats(res)
        }
      }
    })
  }

  const handleAttachmentDropDown = (e, data) => {
    if (e)
      setAttachmentDropdownData({ anchorEl: e.currentTarget, open: true, data })
    else setAttachmentDropdownData({ anchorEl: null, open: false, data })
  }

  const handleChangeSection = section => {
    if (!canUpdateTask(taskPermission.update)) return

    handlePopperClose()
    setTaskState(prev => ({ ...prev, taskSection: section._id }))

    setSavingStatus('saving')
    changeTaskSection({
      task: taskState,
      section,
      board: currentBoard,
      cb: (res, err) => {
        taskUpdateCallback(res, err, {
          resetProp: 'taskSection'
        })
        setTaskState(res)
        if (type === 'overview') {
          updateProjectStats(res)
        }

        if (!err && groupBy === 'status' && afterTaskStatusChange) {
          afterTaskStatusChange({
            taskId: taskState._id,
            prevSectionId: taskData.taskSection,
            newSectionId: section._id
          })
        }
      }
    })
  }

  const handleRecurringTime = recurringTime => {
    if (canUpdateTask(taskPermission.update)) {
      const data = {
        workSpace: taskState.workSpace,
        taskSection: taskState.taskSection,
        setRecurringTime: recurringTime,
        socketId
      }
      data.isTaskRecurring = recurringTime === 0 ? false : true

      setSavingStatus('saving')
      dispatch(
        updateTaskDetails(taskState._id, data, recurringTimeChangeCallback)
      )
    }
  }

  const recurringTimeChangeCallback = (res, err) => {
    taskUpdateCallback(res, err)
    if (!err) {
      setTaskState(prev => ({
        ...prev,
        isTaskRecurring: res.isTaskRecurring,
        setRecurringTime: res.setRecurringTime
      }))
      if (type === 'overview') {
        updateProjectStats(res)
      }
      handlePopperClose()

      // if (res.status === 3) {
      //   updateLog(`marked as completed"`)
      // } else {
      //   updateLog(
      //     `changed status from "${taskStatus[taskData.status]}" to "${
      //       taskStatus[res.status]
      //     }"`
      //   )
      // }
    }
  }

  const handleShareTask = useCallback(() => {
    if (shareTask.open) {
      setShareTask(prev => ({ open: false, data: {} }))
    } else {
      setShareTask(prev => ({ open: true, data: taskData }))
    }
  }, [taskData])
  // const FlagPriority = () => {
  //   return taskState.priority ? taskState.priority : 0
  // }
  // FlagPriorityNum.order = FlagPriority()
  // taskState.priority

  const handlePreviewImage = (imgUrl = '') => {
    handleAttachmentDropDown()
    dispatch(handleFilePreview({ open: true, file: imgUrl }))
    // setImgPreview((prev) => ({ open: !prev.open, url: imgUrl }))
  }

  const toggleApprovalPanel = useCallback(() => {
    setApprovalPanel(prev => ({ ...prev, open: !prev.open }))
  }, [])

  const addNewApproval = data => {
    setApprovalPanel(prev => ({ ...prev, data: [data, ...prev.data] }))
  }

  const removeApprovalById = approvalId => {
    setApprovalPanel(prev => ({
      ...prev,
      data: [...prev.data.filter(item => item._id !== approvalId)]
    }))
  }

  const updateApproval = updatedData => {
    setApprovalPanel(prev => ({
      ...prev,
      data: prev.data.map(item =>
        item._id === updatedData._id ? updatedData : item
      )
    }))
  }

  return (
    <>
      <TaskPanelHeader
        onClose={onClose}
        task={taskState}
        handleAttachmentUpload={handleAttachmentUpload}
        handleOpenDeleteTaskConfirm={handleOpenDeleteTaskConfirm}
        handleShareTask={handleShareTask}
        taskPermission={taskPermission}
        triggerPopover={triggerPopover}
        elmFor="task"
        changeEstimateTime={changeEstimateTime}
        // socketId={socketId}
        // updateProjectStats={updateProjectStats}
        // type={type}
        canUpdateTask={canUpdateTask}
        markTaskAsComplete={handleMarkTaskAsComplete}
        // currentWorkspace={currentWorkspace}
        currentBoard={currentBoard}
        inDashboardArea={inDashboardArea}
        openApprovalPanel={toggleApprovalPanel}
      />
      <DrawerContent open={open} style={{ padding: 0 }}>
        <div className="overflow-y-auto">
          <div className="px-2 mt-2 mb-4 flex items-center">
            <div className="flex-1">
              <TaskTitle
                taskPermission={taskPermission}
                taskTitle={taskState.title}
                updateTitle={updateTitle}
              />
            </div>

            <div className="text-right text-xs px-8">
              <AutoSavingIndicator status={savingStatus} />
            </div>
          </div>
          <TaskBody
            taskState={taskState}
            triggerPopover={triggerPopover}
            taskPermission={taskPermission}
            handleDateChange={handleDateChange}
            assignedTags={assignedTags}
            setTagsPopoverData={setTagsPopoverData}
            elmFor="task"
            updateProjectStats={updateProjectStats}
            addCollaborator={handleAddCollaborator}
            removeCollaborator={handleRemoveCollaborator}
            type={type}
          />

          <div className="px-6 py-2">
            <TaskDescription
              workspace={taskState.workSpace}
              taskId={taskState._id}
              taskPermission={taskPermission}
              taskDescription={taskState.description}
              updateDescription={updateDescription}
              handleUploadDescriptionAttachment={
                handleUploadDescriptionAttachment
              }
              elmFor="task"
            />
          </div>

          <Subtasks
            workspaceId={taskState.workSpace}
            boardId={taskState.workspaceBoard}
            taskId={taskState._id}
            // taskStatuses={taskState.statuses}
            taskTitle={taskState.title}
            handleSubTaskClick={handleSubTaskClick}
            subtaskPermission={subtaskPermission}
            socketId={socketId}
            currentWorkspace={currentWorkspace}
            currentBoard={currentBoard}
          />

          {/* -----Attachments ---- */}
          <div
            className={clsx(
              // classes.attachmentContainer,
              'space-y-1 px-4 sm:space-y-0 flex flex-wrap mx-0 mb-4 sm:gap-4 sm:px-6 sm:py-5'
            )}
          >
            {taskState?.imageUrl?.length > 0 &&
              taskState?.imageUrl?.map((item, index) => (
                <AttachmentImage
                  key={item._id}
                  item={item}
                  attachmentDropdownData={attachmentDropdownData}
                  handleAttachmentDropDown={handleAttachmentDropDown}
                  handlePreviewImage={handlePreviewImage}
                />
              ))}

            {taskPermission.update && (
              <AddAttachment
                labelProps={{
                  className: classes.attachmentAddbtn
                }}
                handleFile={handleAttachmentUpload}
                id={'task-attachment'} //don't remove this id
                disabled={loadingAttachment}
              >
                {loadingAttachment ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Add />
                )}
              </AddAttachment>
            )}
          </div>

          <div>
            <LogsSection
              task={taskState}
              logsData={logsData}
              elmFor="task"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <span ref={logsBottomRef}></span>
          </div>
        </div>
      </DrawerContent>
      {/* ========== FOOTER ============= */}
      <TaskPanelFooter
        taskId={taskState._id}
        elmFor="task"
        setSelectedTab={setSelectedTab}
        workspaceId={taskState.workSpace}
        socketId={socketId}
      />
      <SubtaskPanel
        open={openSubTask.open}
        onClose={handleSubTaskClick}
        taskId={openSubTask.data._id}
        handlePreviewImage={handlePreviewImage}
        subtaskPermission={subtaskPermission}
        inDashboardArea={inDashboardArea}
        groupBy={groupBy}
        // currentWorkspace={currentWorkspace}
        // currentBoard={currentBoard}
      />
      <DeleteModal
        warningText={`The Selected Task named "${taskState.title}" will be deleted forever`}
        handleDialog={handleCloseConfirm}
        open={openDeleteTaskConfirm}
        handleDeleteAction={handleDeleteTask}
        archiveButton={false}
        loading={deleteTaskLoading}
        style={{ zIndex: 2000 }}
      />
      <Tags
        taskId={taskState?._id}
        workspace={currentWorkspace}
        tagsPopoverData={tagsPopoverData}
        setTagsPopoverData={setTagsPopoverData}
        elmFor="task"
      />
      <PriorityDropDown
        open={popperOpen === 'priority' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        currentPriority={taskState?.priority}
        handlePriorityLabel={handlePriorityLabel}
        style={{ zIndex: 2000 }}
      />
      <StatusDropDown
        open={popperOpen === 'status' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        changeStatus={handleChangeSection}
        // selectedStatus={taskState.status}
        statusList={currentBoard.boardSection}
        taskSection={taskState.taskSection}
        style={{ zIndex: 2000 }}
      />
      <RecurringDropDown
        open={popperOpen === 'recurring' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        changeStatus={handleRecurringTime}
        recurring={taskState?.setRecurringTime}
        style={{ zIndex: 2000 }}
      />
      <AttachmentDropDown
        open={attachmentDropdownData.open}
        onClose={() => handleAttachmentDropDown(false)}
        anchorEl={attachmentDropdownData.anchorEl}
        data={attachmentDropdownData.data}
        handleDeleteAttachment={handleDeleteAttachment}
        handlePreviewImage={handlePreviewImage}
        style={{ zIndex: 2000 }}
      />
      <ShareTaskModal task={shareTask} handleClose={handleShareTask} />
      <CollabDrowdown
        open={popperOpen === 'collab' ? true : false}
        anchorEl={anchorEl}
        onClose={handlePopperClose}
        list={collabOptions}
        handleListItemClick={team =>
          handleAddCollaborator({
            userId: team.user._id,
            userRole: team.role
          })
        }
        meData={meData}
        style={{ zIndex: 2000 }}
      />
      <ApprovalPanel
        open={approvalPanel.open}
        taskId={taskState._id}
        boardId={currentBoard._id}
        isLoading={approvalPanel.loading}
        data={approvalPanel.data}
        currentWorkspace={currentWorkspace}
        onClose={toggleApprovalPanel}
        socketId={socketId}
        addNewApproval={addNewApproval}
        removeApprovalById={removeApprovalById}
        updateApproval={updateApproval}
        elmFor="task"
      />
    </>
  )
}

export const RowContainer = props => {
  const { children, ...restProps } = props
  const classes = taskSidePanelStyles()
  return (
    <div
      {...restProps}
      className={clsx(
        'px-6',
        classes.rowContainer
        // sm:grid sm:grid-cols-3 sm:gap-4
      )}
    >
      {children}
    </div>
  )
}

export const FieldLabel = props => {
  const { className, children, ...restProps } = props
  return (
    <label
      {...restProps}
      className={clsx('block text-xs text-gray-600', className)}
    >
      {children}
    </label>
  )
}
