import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pill from 'global/globalComponents/Pill/Pill'
import { Td, Tr } from 'global/globalComponents/TailwindTable/TailwindTable'
import { adjustColor } from 'utils'
import { CustomFlag, PopupMenu } from 'global/globalComponents'
import pillVariantObj from 'utils/pillVariantObj'
// import widgetStyles from '../widgetStyles'
// import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import taskStatus from 'utils/taskStatus'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { ReactComponent as CheckCircleFilled } from 'static/svg/check-circle-filled.svg'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import userRoles, { getUserPath } from 'utils/userRoles'
import { Collaborators } from 'components/Workspace/Panel/Collaborator'
import { ReactComponent as Add } from 'static/svg/plus.svg'
// import {
//   deleteTaskDetailsteam,
//   updateTaskDetails,
//   updateTaskDetailsteam,
//   updateTaskPriority
// } from 'thunks/task/actions'
import { useEffect } from 'react'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import PriorityDropDown from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import { useWorkspacePermission } from 'customHooks'
import { addOpenedWorkspace } from 'thunks/workspace/actions'
import StatusDropDown from 'components/Workspace/StatusDropDown'
import { fetchEmbed } from 'thunks/embed/action'

const WidgetTableRow = ({
  task,
  openDeleteAlertModal,
  allProjects,
  updateSingleTask,
  openEditTaskPanel,
  hiddenCols
}) => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  const [taskState, setTaskState] = useState({
    ...task,
    workSpace: task.workSpace._id,
    teammates: [...task.assignedAgencyTeam, ...task.assignedClientTeam]
  })

  const {
    task: taskPermission,
    subtask: subtaskPermission
  } = useWorkspacePermission(task.workSpace._id)

  const [collabOptions, setCollabOptions] = useState([])
  const [popper, setPopper] = useState({
    type: '',
    anchorEl: null
  })
  const [taskBoard, setTaskBoard] = useState({})
  // const [priority, setPriority] = useState(task.priority)
  const project = useMemo(() => {
    return allProjects.find(item => item._id === task.workSpace._id) || {}
  }, [allProjects])

  const {
    getCollaboratorsOptions,
    removeTaskCollaborator,
    addTaskCollaborator,
    changeTaskPriority,
    handleTaskDateChange,
    markTaskAsComplete,
    canUpdateTask,
    setCurrentBoardById,
    changeTaskSection
  } = useWorkspaceFunctions()

  useEffect(() => {
    setTaskState({
      ...task,
      workSpace: task.workSpace._id,
      teammates: [...task.assignedAgencyTeam, ...task.assignedClientTeam]
    })
  }, [task])

  useEffect(() => {
    if (!taskPermission.update) return
    const opt = getCollaboratorsOptions(taskState.teammates, project)
    setCollabOptions(opt)
  }, [project, taskState.teammates, taskPermission])

  useEffect(() => {
    if (popper.type === 'status' && project._id) {
      dispatch(
        fetchEmbed(project._id, (res, err) => {
          // board data is in an array
          if (!err) setTaskBoard(res[0])
        })
      )
    }
  }, [popper.type, project])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${getUserPath(meData.role)}/workspace/${
        task.workSpace._id
      }/board/${task.workspaceBoard}/task/${task._id}?view=board`
    )
    dispatch(fireSuccessToaster('Link copied!'))
  }

  const handleDeleteTask = () => {
    if (!canUpdateTask(taskPermission.update)) return
    openDeleteAlertModal('deleteTask', {
      _id: task._id,
      title: task.title
    })
  }

  const handleRemoveTeammate = teammate => {
    if (!canUpdateTask(taskPermission.update)) return

    removeTaskCollaborator({
      userRole: teammate.role,
      userId: teammate._id,
      taskId: task._id,
      cb: (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setTaskState(prev => ({
            ...prev,
            teammates: [...task.assignedAgencyTeam, ...task.assignedClientTeam]
          }))
        } else {
          updateSingleTask({
            ...task,
            assignedAgencyTeam:
              teammate.role < userRoles.USER_CLIENT
                ? task.assignedAgencyTeam.filter(
                    item => item._id !== teammate._id
                  )
                : task.assignedAgencyTeam,
            assignedClientTeam:
              teammate.role >= userRoles.USER_CLIENT
                ? task.assignedClientTeam.filter(
                    item => item._id !== teammate._id
                  )
                : task.assignedClientTeam
          })
        }
      }
    })
    setTaskState(prev => ({
      ...prev,
      teammates: prev.teammates.filter(item => item._id !== teammate._id)
    }))
    // const data = getUpdateCollabBody(teammate.role, teammate._id)
    // dispatch(deleteTaskDetailsteam(task._id, data, callback))
  }

  const togglePopper = (e, type) => {
    if (!canUpdateTask(taskPermission.update)) return

    if (popper.type) {
      setPopper({ anchorEl: null, type: '' })
    } else {
      setPopper({ anchorEl: e.currentTarget, type })
    }
  }

  const handleAddCollaborator = teammate => {
    // const data = getUpdateCollabBody(teammate.role, teammate.user._id)
    const mateObj = { ...teammate.user, role: teammate.role }

    setTaskState(prev => ({
      ...prev,
      teammates: [...prev.teammates, mateObj]
    }))

    addTaskCollaborator({
      userRole: teammate.role,
      userId: teammate.user._id,
      taskId: task._id,
      cb: (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setTaskState(prev => ({
            ...prev,
            teammates: prev.filter(item => item._id !== teammate.user._id)
          }))
        } else {
          updateSingleTask({
            ...task,
            assignedAgencyTeam:
              teammate.role < userRoles.USER_CLIENT
                ? [...task.assignedAgencyTeam, mateObj]
                : task.assignedAgencyTeam,
            assignedClientTeam:
              teammate.role >= userRoles.USER_CLIENT
                ? [...task.assignedClientTeam, mateObj]
                : task.assignedClientTeam
          })
        }
      }
    })
    // dispatch(updateTaskDetailsteam(task._id, data, callback))
    togglePopper()
  }

  // const triggerAddCollab = (e) => {
  //   togglePopper(e, 'collab')
  // }

  const handlePriorityLabel = priority => {
    if (!canUpdateTask(taskPermission.update)) return

    togglePopper()

    changeTaskPriority({
      task: taskState,
      priority: priority.order,
      cb: (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setTaskState(prev => ({
            ...prev,
            priority: task.priority
          }))
        } else {
          updateSingleTask({ ...task, priority: priority.order })
        }
      }
    })

    setTaskState(prev => ({ ...prev, priority: priority.order }))
  }

  const handleDateChange = (e, prop) => {
    if (!canUpdateTask(taskPermission.update)) return
    const date = e ? e.toISOString() : null

    handleTaskDateChange({
      updateKey: prop,
      updateValue: date,
      task: { ...taskState, taskSection: taskState.taskSection._id },
      cb: (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setTaskState(prev => ({
            ...prev,
            [prop]: task[prop]
          }))
        } else {
          updateSingleTask({ ...task, [prop]: date })
        }
      }
    })

    setTaskState(prev => ({ ...prev, [prop]: date }))
    // if (
    //   ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role) &&
    //   project.restrictDueDate?.isDueDateRestrict &&
    //   project.restrictDueDate?.totalDueDateChangeCounter <=
    //     task.editDueDateCounter
    // ) {
    //   dispatch(
    //     fireErrorToaster('Due Date editing has been restricted by Admin')
    //   )
    //   return
    // }
    // dispatch(
    //   updateTaskDetails(
    //     task._id,
    //     {
    //       [prop]: date.toISOString(),
    //       workSpace: task.workSpace,
    //       taskSection: task.taskSection
    //     },
    //     callback
    //   )
    // )
  }

  const handleMarkAsComplete = value => {
    if (!canUpdateTask(taskPermission.update)) return

    markTaskAsComplete({
      value,
      task: { ...taskState, taskSection: taskState.taskSection._id },
      elmFor: 'task',
      cb: (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setTaskState(prev => ({
            ...prev,
            markAsComplete: task.markAsComplete
          }))
        } else {
          updateSingleTask({ ...task, markAsComplete: value })
        }
      }
    })

    setTaskState(prev => ({ ...prev, markAsComplete: value }))
  }

  const taskPanelHandler = () => {
    dispatch(addOpenedWorkspace(project)) //setting currentWorkspace before opening task panel
    setCurrentBoardById(task.workspaceBoard)

    openEditTaskPanel({
      taskData: {
        ...task,
        workSpace: task.workSpace._id,
        taskSection: task.taskSection._id
      },
      taskPermission,
      subtaskPermission
      // project,
      // board: project.defaultBoard
    })
    // history.push(
    //   `${getUserPath(meData.role)}/workspace/${task.workSpace._id}/board/${
    //     task.workspaceBoard
    //   }/task/${task._id}?view=board`
    // )
    // const permission = workspacePermission.find((item) => {
    //   return Object.keys(item)[0] === task.workSpace._id
    // })
    // setTaskEditPanelStatus({
    //   task: task,
    //   open: true,
    //   workspacePermission: Object.values(permission)[0].access
    // })
    // dispatch(
    //   addOpenedWorkspace(
    //     userWorkspaces.data.find((i) => i._id === task?.workSpace)
    //   )
    // )
  }

  const handleChangeSection = section => {
    if (!canUpdateTask(taskPermission.update)) return
    const prevSection = task.taskSection

    togglePopper()
    setTaskState(prev => ({ ...prev, taskSection: section }))

    changeTaskSection({
      task: { ...taskState, taskSection: taskState.taskSection._id },
      section,
      board: taskBoard,
      cb: (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
          setTaskState(prev => ({ ...prev, taskSection: prevSection }))
        }
      }
    })
  }

  return (
    <>
      <Tr key={task._id}>
        {!hiddenCols.task && (
          <Td
            className="text-gray-900 cursor-pointer select-none"
            onClick={taskPanelHandler}
          >
            <div className="w-52">{task.title}</div>
          </Td>
        )}
        {!hiddenCols.project && (
          <Td className="whitespace-nowrap">
            {task.workSpace ? (
              <Pill variant="red">{task.workSpace.name}</Pill>
            ) : (
              <span>-</span>
            )}
          </Td>
        )}
        {!hiddenCols.assignee && (
          <Td>
            <div className="flex">
              <Collaborators
                teammates={taskState.teammates}
                removeBtn={true}
                handleRemove={handleRemoveTeammate}
              />
              <button
                className="flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
                onClick={e => togglePopper(e, 'collab')}
              >
                <Add />
              </button>
            </div>
          </Td>
        )}
        {!hiddenCols.priority && (
          <Td className="text-center text-xs">
            <div
              onClick={e => togglePopper(e, 'priority')}
              className="cursor-pointer"
            >
              {taskState.priority && taskState.priority !== 5 ? (
                <CustomFlag priority={taskState.priority} />
              ) : (
                <span>-</span>
              )}
            </div>
          </Td>
        )}
        {!hiddenCols.status && (
          <Td className="whitespace-nowrap">
            {task.taskSection && (
              <Pill
                className="capitalize cursor-pointer"
                style={{
                  color: adjustColor(task.taskSection.color, -70),
                  background: task.taskSection.color + '20'
                }}
                onClick={e => togglePopper(e, 'status')}
              >
                {taskState.taskSection.label}
              </Pill>
            )}
          </Td>
        )}
        {!hiddenCols.startDate && (
          <Td className="whitespace-nowrap">
            <DatePicker
              selected={
                taskState.startDate ? new Date(taskState.startDate) : false
              }
              onChange={e => {
                handleDateChange(e, 'startDate')
              }}
              // disabled={task.workSpace?.isComplete || !taskPermission.update}
              disabled={task.workSpace?.isComplete}
              customInput={
                taskState.startDate ? (
                  <input
                    className="w-full bg-transparent text-extraSmaller lg:text-xs"
                    style={{ width: '180px' }}
                  />
                ) : (
                  <CalenderIcon className="text-base text-gray-600 cursor-pointer" />
                )
              }
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              showDisabledMonthNavigation
              popperPlacement="left"
            />
          </Td>
        )}

        {!hiddenCols.dueDate && (
          <Td className="whitespace-nowrap">
            <DatePicker
              selected={taskState.dueDate ? new Date(taskState.dueDate) : false}
              onChange={e => {
                handleDateChange(e, 'dueDate')
              }}
              // disabled={taskState.workSpace?.isComplete || !taskPermission.update}
              disabled={task.workSpace?.isComplete}
              customInput={
                taskState.dueDate ? (
                  <input
                    className="w-full bg-transparent text-extraSmaller lg:text-xs"
                    style={{ width: '180px' }}
                  />
                ) : (
                  <CalenderIcon className="text-base text-gray-600 cursor-pointer" />
                )
              }
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              showDisabledMonthNavigation
              popperPlacement="left"
            />
          </Td>
        )}

        {!hiddenCols.markAsComplete && (
          <Td className="text-center">
            <button
              className="text-green-500"
              onClick={() => handleMarkAsComplete(!taskState.markAsComplete)}
            >
              {taskState.markAsComplete ? (
                <CheckCircleFilled className="w-5 h-5" />
              ) : (
                <CheckCircle className="w-5 h-5" />
              )}
            </button>
          </Td>
        )}
        <Td>
          <PopupMenu
            menuItems={[
              {
                label: 'Copy link',
                method: handleCopyLink,
                icon: <CopyIcon />
              },
              { label: 'Delete', method: handleDeleteTask, icon: <TrashIcon /> }
            ]}
          />
        </Td>
      </Tr>

      <CollabDrowdown
        open={popper.type === 'collab'}
        anchorEl={popper.anchorEl}
        onClose={togglePopper}
        list={collabOptions}
        handleListItemClick={handleAddCollaborator}
        meData={meData}
      />

      <PriorityDropDown
        open={popper.type === 'priority'}
        anchorEl={popper.anchorEl}
        onClose={togglePopper}
        currentPriority={taskState.priority}
        handlePriorityLabel={handlePriorityLabel}
        style={{ zIndex: 2000 }}
      />

      <StatusDropDown
        open={popper.type === 'status'}
        anchorEl={popper.anchorEl}
        onClose={togglePopper}
        changeStatus={handleChangeSection}
        statusList={taskBoard.boardSection}
        taskSection={taskState.taskSection._id}
      />
    </>
  )
}

export default React.memo(WidgetTableRow)
