import { useState, useEffect } from 'react'
import {
  Button,
  Popover,
  List,
  ListItem,
  CircularProgress
} from '@material-ui/core'
import { CustomFlag, LoadingButton } from 'global/globalComponents'
import { updateRaisedRequest } from 'thunks/clientRaisedRequestAgency/actions'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import Select from 'react-select'
import OptionsButton, {
  statusColors
} from 'components/OptionsButton/OptionsButton'
import format from 'date-fns/format'
import {
  fetchRequetsComments,
  addRequestComment
} from 'thunks/clientRaisedRequestAgency/actions'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import clientStyles from './ClientStyles'
import { userRoles, getRoleBadge } from 'utils'
import { handleFilePreview } from 'thunks/generlAppState/actions'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'

const RequestDetailsModal = ({
  open,
  handleClose,
  data,
  teammates,
  handleTaskModal,
  handleUpdateRequest,
  canUpdate
}) => {
  const classes = clientStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [comments, setComments] = useState({
    loading: true,
    data: []
  })
  const [selectTeammatesOptions, setSelectTemmatesOptions] = useState([])
  const [selectedTeammate, setSelectedTeammate] = useState({})
  const [requestStatus, setReqstatus] = useState(data.status)
  const [selectTeammateLoading, setSelectTeammateLoading] = useState(false)

  const closeStatusPopup = () => {
    setAnchorEl(null)
  }

  const openStatusPopup = e => {
    setAnchorEl(e.currentTarget)
  }

  const optionLabel = assignee => {
    return (
      <div className="flex">
        <span className="p-2">
          {assignee.name}{' '}
          <span className="pl-2">
            {assignee.role ? getRoleBadge(assignee.role) : ''}
          </span>
        </span>
      </div>
    )
  }

  useEffect(() => {
    fetchRequetsComments(data._id, fetchRequestsCommentsCallback)

    if (data.assignAgency?.name) {
      setSelectedTeammate({
        label: data.assignAgency.name,
        value: data.assignAgency?._id,
        ...data.assignAgency
      })
    } else {
      const selectedTeammate = teammates.find(
        item => item._id === (data.assignAgency?._id ?? data.assignAgency)
      )
      setSelectedTeammate({
        label: selectedTeammate?.name,
        value: selectedTeammate?._id,
        ...selectedTeammate
      })
    }
  }, [])

  useEffect(() => {
    if (teammates.length) {
      const options = teammates
        .filter(item =>
          [
            userRoles.USER_AGENCY,
            userRoles.AGENCY_MANAGER,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_EDITOR
          ].includes(item.role)
        )
        .map(item => ({
          label: item.name,
          value: item._id,
          ...item
        }))
      setSelectTemmatesOptions(options)
    }
  }, [teammates.length])

  const fetchRequestsCommentsCallback = (res, err) => {
    if (!err) {
      setComments({
        loading: false,
        data: res
      })
    } else {
      setComments({ loading: false, data: [] })
    }
  }

  const handleAddComment = comment => {
    if (comment.trim()) {
      setComments(prev => ({ ...prev, loading: true }))
      addRequestComment(
        { comments: comment, clientRequest: data._id },
        handleAddCommentCallback
      )
    } else {
      dispatch(fireErrorToaster('Please enter the valid comment'))
    }
  }

  const handleAddCommentCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setComments(prev => ({
        loading: false,
        data: [...prev.data, res]
      }))
    }
  }

  const handleStatusChange = status => {
    closeStatusPopup()
    handleUpdateRequest(data._id, { status })
    setReqstatus(status)
  }

  const handleSelectTeammate = item => {
    setSelectTeammateLoading(true)
    setSelectedTeammate(item)
    dispatch(
      updateRaisedRequest(
        data._id,
        { assignAgency: item.value },
        handleUpdateRequestCallback
      )
    )
  }

  const handleUpdateRequestCallback = (res, err) => {
    setSelectTeammateLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Updated Successfully'))
    }
  }

  const handlePreviewFile = e => {
    dispatch(
      handleFilePreview({
        open: true,
        file: e.currentTarget.dataset.url
      })
    )
  }

  return (
    <div>
      <CustomModal size="large" open={open} handleClose={handleClose}>
        <CustomModalHeader heading="Manage Request" />

        <CustomModalBody>
          <div className="flex justify-between mb-8">
            <div className="text-xs">
              <div className="font-semibold capitalize mb-1 flex">
                <h4 style={{ width: '75px' }}>Title: </h4>
                <span className="text-gray-500">{data.title?.trim()}</span>
              </div>

              <div className="flex font-semibold mb-1">
                <h4 style={{ width: '75px' }}>Raised By: </h4>
                {/* <div className="text-gray-500">{data.user.name}</div> */}
                <div className="text-gray-500">
                  {data.client.name} ({data.client.email})
                </div>
              </div>

              <div className="font-semibold flex text-gray-500">
                <h4 style={{ width: '75px' }}>Raised on: </h4>
                <time>{format(new Date(data.createdAt), 'MMM d')}</time>
              </div>
            </div>

            <div className="font-semibold flex text-xs self-start">
              <span>Priority:</span>
              <div className="ml-2 flex items-center">
                {isNaN(data.priority) || data.priority === 5 ? (
                  <span className="text-gray-400 text-xs">Not set</span>
                ) : (
                  <CustomFlag priority={data.priority} />
                )}
              </div>
            </div>
          </div>
          <hr />

          {/* ------ Description ----- */}
          <div className="flex gap-8 flex-start py-2">
            <h4 className="font-bold text-smaller lg:text-sm">Description:</h4>
            <p className="text-xs text-gray-500" style={{ minHeight: 40 }}>
              {data.description}
            </p>
          </div>
          <hr />

          {/* ------ Attachments ----- */}
          <div className="flex items-start gap-8 py-2">
            <h4 className="font-bold text-smaller lg:text-sm">Attachments:</h4>

            <div className=" flex gap-2 flex-wrap">
              {data.attachments.length ? (
                data.attachments?.map((url, index) => (
                  <div
                    key={index}
                    className="relative rounded-2xl w-32 h-20 object-cover rounded-xl overflow-hidden border"
                  >
                    {/.(png|jpe?g|svg|gif|avif|apng|webp)$/.test(url) ? (
                      <img
                        src={url}
                        alt=""
                        onClick={handlePreviewFile}
                        data-url={url}
                        className="w-full h-full object-cover cursor-pointer"
                      />
                    ) : (
                      <div
                        className="w-full h-full flex justify-center items-center text-xl text-gray-400 cursor-pointer"
                        onClick={handlePreviewFile}
                        data-url={url}
                      >
                        <FileIcon />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                // data.attachments.map((item, index) => (
                //   <a href={item} target="_blank" rel="noreferrer" key={index}>
                //     <img className={classes.attachment} src={item} alt="" />
                //   </a>
                // ))
                <p
                  className="text-xs font-medium text-gray-500"
                  style={{ minHeight: 40 }}
                >
                  No attachments available
                </p>
              )}
            </div>
          </div>
          <hr />

          {/*  ------ Comments ---------- */}
          <div className="flex items-start gap-8 py-2 mb-2">
            <h4 className="font-bold text-smaller lg:text-sm">Comments:</h4>
            <div className="flex-1" style={{ minHeight: 80 }}>
              <ModalComments
                comments={comments}
                handleAddComment={handleAddComment}
                canUpdate={canUpdate}
              />
            </div>
          </div>
          <hr />

          {canUpdate && (
            <>
              <div className="flex justify-between text-xs py-2 w-full">
                <div className="flex items-center space-x-2 flex-1">
                  <div className="font-bold text-smaller lg:text-sm sm:whitespace-nowrap">
                    Assign teammates:{' '}
                  </div>
                  <div className="w-1/2">
                    <Select
                      menuPlacement="top"
                      menuPosition={'fixed'}
                      placeholder="Select Teammates"
                      options={selectTeammatesOptions}
                      value={selectedTeammate}
                      className="w-full"
                      styles={reactSelectCustomStyles}
                      onChange={handleSelectTeammate}
                      maxMenuHeight="150px"
                      getOptionLabel={optionLabel}
                    />
                  </div>
                  {selectTeammateLoading && <CircularProgress size={20} />}
                </div>

                <div className="flex items-center space-x-2">
                  <div className="font-bold text-smaller lg:text-sm">
                    Change status:{' '}
                  </div>
                  <OptionsButton
                    onClick={openStatusPopup}
                    bgColor={statusColors[requestStatus]}
                  >
                    {requestStatus}
                  </OptionsButton>
                </div>
              </div>
              <hr />
            </>
          )}

          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={Boolean(anchorEl)}
            onClose={closeStatusPopup}
            anchorEl={anchorEl}
          >
            <List dense>
              {['pending', 'resolved', 'rejected', 'closed'].map(item => (
                <ListItem
                  onClick={() => handleStatusChange(item)}
                  button
                  key={item}
                  className="capitalize text-smaller lg:text-sm font-medium"
                  disabled={requestStatus === item}
                  selected={requestStatus === item}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </Popover>
        </CustomModalBody>

        <CustomModalFooter>
          <Button
            onClick={() => {
              handleTaskModal(data)
              handleClose()
            }}
          >
            Convert To Task
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ width: '100px' }}
          >
            Close
          </Button>
        </CustomModalFooter>
      </CustomModal>
    </div>
  )
}

const ModalComments = ({ comments, handleAddComment, canUpdate }) => {
  const handleCommentSubmit = e => {
    e.preventDefault()
    const comment = new FormData(e.target).get('comment')
    handleAddComment(comment)
    e.target.reset()
  }

  return (
    <>
      {/* Comments Wrapper */}
      {comments.loading ? (
        <CircularProgress size={20} />
      ) : (
        comments.data.map(item => (
          <div key={item._id} className="mb-6">
            <div className="flex gap-2">
              <ColoredAvatars user={item.user} size="small" />
              <div className="text-xs">
                <h6 className="font-bold mb-1">{item.user.name}</h6>
                <p className="text-gray-500">{item.comments}</p>
              </div>
            </div>
          </div>
        ))
      )}

      {canUpdate && (
        <form onSubmit={handleCommentSubmit}>
          <div className="flex flex-col">
            <label htmlFor="comment" className="font-semibold text-xs mb-1">
              Add Comment
            </label>
            {/* <input
              className="text-xs p-2 bg-gray-100 mb-4 rounded"
              placeholder="Type your reply here..."
              name="comment"
              id="comment"
            /> */}
            <div className="flex justify-between items-center rounded border text-xs text-primary-dark overflow-hidden">
              <input
                placeholder="Type your reply here..."
                name="comment"
                id="comment"
                className="text-xs bg-gray-100 flex-1  p-2"
              />
              <button
                type="submit"
                className="text-white font-medium flex items-center px-2 py-1.5 border-2  border-primary-main bg-primary-main"
              >
                Comment
              </button>
            </div>
          </div>

          {/* <div className="flex justify-end gap-2">
            <LoadingButton
              loading={comments.loading}
              type="submit"
              size="small"
            >
              Comment
            </LoadingButton>
          </div> */}
        </form>
      )}
    </>
  )
}

export default RequestDetailsModal
