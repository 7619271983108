import CustomPopper from 'global/globalComponents/CustomPopper/CustomPopper'
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball'
import { useEffect, useState } from 'react'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import VerticalsModal from 'components/Teammates/VerticalsModal'

const CompanyDropdown = ({
  anchorEl,
  onClose,
  handleDialogue,
  list,
  elmFor,
  canCreate,
  zIndex = ''
}) => {
  const [search, setSearch] = useState('')
  const [newList, setNewList] = useState(list)

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const [openVerticalModal, setOpenVerticalModal] = useState(false)

  const toggleCreateRoleModal = () => {
    setOpenVerticalModal((prev) => !prev)
  }

  const handleVerticalsChange = (vertical) => {
    handleDialogue({ ...vertical })
  }

  useEffect(() => {
    if (search) {
      setNewList(
        list.filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase())
        )
      )
    } else {
      setNewList(list)
    }
  }, [search, list])

  return (
    <CustomPopper
      anchorEl={anchorEl}
      onClose={onClose}
      zIndex={zIndex ? zIndex : undefined}
    >
      <div
        className="py-4 rounded-xl overflow-y-auto overflow-x-hidden"
        style={{ width: 250, maxHeight: '50vh' }}
      >
        <header className="px-3 mb-1">
          <h1 className="capitalize text-sm font-semibold mb-3 ">
            Select {elmFor}
          </h1>
          <input
            type="text"
            value={search}
            onChange={handleSearch}
            className="w-full px-2 py-1 border rounded text-sm"
            placeholder="Search"
            autoFocus
          />
        </header>
        {canCreate && (
          <div>
            {' '}
            <div className="px-4 py-2 hover:bg-custom-blue-light">
              <button
                onClick={toggleCreateRoleModal}
                className="text-sm text-primary-main flex items-center"
              >
                <AddIcon className="mr-1" />
                <span>Create new role</span>
              </button>
            </div>
          </div>
        )}
        {newList.length ? (
          newList.map((item) => (
            <div
              className="flex items-center space-x-4 text-sm text-primary-dark px-4 py-2 cursor-pointer hover:bg-custom-blue-light"
              onClick={() => handleDialogue(item)}
            >
              {item.logo !== undefined && (
                <div className=" w-6 rounded-full">
                  {item.logo ? (
                    <img
                      src={item.logo}
                      className="selected-icon object-content"
                    />
                  ) : (
                    <SportsVolleyballIcon
                      className="selected-icon"
                      style={{
                        fill: '#1600e5',
                        width: '1.1em',
                        height: '1.1em'
                      }}
                    />
                  )}
                </div>
              )}
              <p>{item.label}</p>
            </div>
          ))
        ) : (
          <div className="text-sm text-primary-mid-dark-2 px-5 py-2 mr-8">
            <p>No {elmFor} available</p>
          </div>
        )}
      </div>
      <VerticalsModal
        open={openVerticalModal}
        onClose={toggleCreateRoleModal}
        addNewVertical={handleVerticalsChange}
      />
    </CustomPopper>
  )
}

export default CompanyDropdown
