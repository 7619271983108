import React, { useState } from 'react'
import { ReactComponent as TimerIcon } from 'static/svg/play-circle.svg'
import { ReactComponent as StartTimer } from 'static/svg/play.svg'
import { ReactComponent as StopTimer } from 'static/svg/stop.svg'
import { useDispatch } from 'react-redux'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { ReactComponent as Clock } from 'static/svg/clock.svg'
// import DatePicker from 'react-datepicker'
import { addManualTime } from 'thunks/Tracking/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { CircularProgress, input } from '@material-ui/core'
import useTimerFunctions from './useTimerFunctions'
import { getTimeSuggestion } from 'utils'

const TimerFooter = ({
  onClose,
  time,
  isActive,
  startTimer,
  stopTimer,
  elmFor,
  task,
  totalTimeTracking
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [option, setOption] = useState({
    timer: true,
    manual: false,
    range: false
  })
  const [manualTimeData, setManualTimeData] = useState({
    start: '',
    end: '',
    date: ''
  })
  const [timeOption, setTimeOption] = useState({
    show: false,
    value: ''
  })
  const [manualTime, setManualTime] = useState('')
  const { showPicker } = useTimerFunctions()

  const inputDateHandler = (e) => {
    const { name, value } = e.target

    setManualTimeData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSave = () => {
    if (option.range) {
      handleRangeTimeTracking()
    } else {
      handleManualTimeTracking()
    }
  }

  const handleRangeTimeTracking = () => {
    if (!manualTimeData.start || !manualTimeData.end || !manualTimeData.date) {
      return dispatch(fireErrorToaster('Please enter values in all the fields'))
    }
    if (
      new Date(
        `${new Date(manualTimeData.date).toDateString()} ${manualTimeData.end}`
      ).getTime() -
        new Date(
          `${new Date(manualTimeData.date).toDateString()} ${
            manualTimeData.start
          }`
        ).getTime() <=
      0
    ) {
      return dispatch(
        fireErrorToaster('End time should be greater than start time')
      )
    }
    let data = {
      start: new Date(
        `${new Date(manualTimeData.date).toDateString()} ${
          manualTimeData.start
        }`
      ).getTime(),
      end: new Date(
        `${new Date(manualTimeData.date).toDateString()} ${manualTimeData.end}`
      ).getTime(),
      category: 'manual',
      type: elmFor
    }

    setLoading(true)

    data =
      elmFor === 'task'
        ? { ...data, task: task._id }
        : { ...data, task: task.task, subTask: task._id }

    dispatch(
      addManualTime(data, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster('Time Added Successfully!'))
          totalTimeTracking()
        } else {
          dispatch(fireErrorToaster(res))
        }
        handleClose()
        onClose()
        setLoading(false)
      })
    )
  }

  const handleManualTimeTracking = () => {
    if (!manualTime.trim()) {
      return dispatch(fireErrorToaster('Please enter the time duration'))
    }

    let manualData = { ...manualTimeData }
    let data = {
      category: 'manual',
      type: elmFor
    }

    if (!manualData.end) {
      let val
      manualTime.replace(/\d+\s?\w{0,2}/gi, (match) => {
        val += getTimeAndUnitOption(match) + ' '
      })

      const { totalMs } = getTimeSuggestion(val)
      manualData.end = totalMs
    }

    if (!manualData.date) {
      data.start = new Date().getTime() - manualData.end
      data.end = new Date().getTime()
    } else {
      data.start = new Date(manualData.date).getTime()
      data.end = new Date(manualData.date).getTime() + manualData.end
    }

    setLoading(true)

    data =
      elmFor === 'task'
        ? { ...data, task: task._id }
        : { ...data, task: task.task, subTask: task._id }

    dispatch(
      addManualTime(data, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster('Time Added Successfully!'))
          totalTimeTracking()
        } else {
          dispatch(fireErrorToaster(res))
        }
        handleClose()
        onClose()
        setLoading(false)
      })
    )
  }

  const handleClose = () => {
    setManualTimeData({ date: '', start: '', end: '' })
    setManualTimeData({ date: '', start: '', end: '' })
    setManualTime('')
  }

  const handleTimeChange = (e) => {
    const value = e.target.value.toLowerCase()
    if (value.includes('.')) return
    if (value.includes('-')) return
    setManualTime(value)

    if (value.trim() !== '') {
      let optionVal = ''

      value.replace(/\d+\s?\w{0,2}/gi, (match) => {
        optionVal += getTimeAndUnitOption(match) + ' '
      })

      if (optionVal !== null) {
        setTimeOption({ show: true, value: optionVal })
      }
    } else {
      setTimeOption({
        show: false,
        value: ''
      })
    }
  }

  const handleBlur = (e) => {
    setTimeout(() => {
      setTimeOption({ show: false, value: '' })
    }, 500)
  }

  const handleSelectTime = () => {
    const { timeStr, totalMs } = getTimeSuggestion(timeOption.value)
    setManualTime(timeStr)
    setManualTimeData((prev) => ({
      ...prev,
      end: totalMs
    }))
  }

  const units = {
    s: 'seconds',
    m: 'minutes',
    h: 'hours',
    d: 'days',
    mo: 'months',
    y: 'years'
  }

  const getTimeAndUnitOption = (str) => {
    return str.replace(/(\d+)\s?(\w{0,2})/, (match, p1, p2) => {
      const key = p2 === 'mo' ? p2 : p2[0]
      return `${parseFloat(p1)} ${units[key] ?? 'seconds'}`
    })
  }

  const handleSwitchTab = (e) => {
    setOption({
      timer: false,
      manual: false,
      range: false,
      [e.currentTarget.dataset.tab]: true
    })
  }

  return (
    <div className="p-4 bg-primary-main">
      <div className="flex justify-between items-center px-2 mb-4 text-primary-main">
        <SingleTab
          label="Timer"
          icon={<TimerIcon />}
          isActive={option.timer}
          onClick={handleSwitchTab}
          data-tab="timer"
        />
        <SingleTab
          label="Manual"
          icon={<Pen />}
          isActive={option.manual}
          onClick={handleSwitchTab}
          data-tab="manual"
        />
        <SingleTab
          label="Range"
          icon={<Clock />}
          isActive={option.range}
          onClick={handleSwitchTab}
          data-tab="range"
        />
      </div>

      {option.timer ? (
        <TimerTabContent
          time={time}
          isActive={isActive}
          startTimer={startTimer}
          stopTimer={stopTimer}
        />
      ) : option.manual ? (
        <ManualTabContent
          manualTime={manualTime}
          handleTimeChange={handleTimeChange}
          handleBlur={handleBlur}
          timeOption={timeOption}
          handleSelectTime={handleSelectTime}
        />
      ) : (
        <RangeTabContent
          manualTimeData={manualTimeData}
          inputDateHandler={inputDateHandler}
          showPicker={showPicker}
        />
      )}

      {!option.timer && (
        <div>
          <div className="flex space-x-2 items-center justify-between mb-4">
            <span className="text-xs font-medium text-white">When:</span>
            <input
              type="date"
              name="date"
              value={manualTimeData.date}
              className="white-icon text-sm text-white flex w-full px-2 py-1 border-collapse"
              style={{
                backgroundColor: '#351fff',
                borderRadius: '5px'
              }}
              onChange={inputDateHandler}
            />
          </div>
          <div className="flex justify-between items-center">
            <button
              className="cursor-pointer text-white text-xs font-medium"
              onClick={() => {
                handleClose()
                onClose()
              }}
            >
              Cancel
            </button>
            {!loading ? (
              <button
                className="cursor-pointer text-white text-xs font-medium"
                onClick={handleSave}
              >
                Save
              </button>
            ) : (
              <span className="cursor-pointer text-white text-xs font-medium">
                <CircularProgress
                  style={{ width: '10px', height: '10px', color: 'white' }}
                />
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const SingleTab = ({ label, isActive, icon, ...rest }) => {
  return (
    <div
      className="text-sx flex items-center space-x-2 mb-4 cursor-pointer"
      {...rest}
    >
      {React.cloneElement(icon, {
        className: 'h-3 w-3 text-white',
        style: { fill: isActive ? 'white' : '#C8C2FF' }
      })}
      <span
        className="text-xs font-semibold"
        style={{ color: isActive ? 'white' : '#C8C2FF' }}
      >
        {label}
      </span>
    </div>
  )
}

const TimerTabContent = ({ time, isActive, startTimer, stopTimer }) => {
  return (
    <div className="flex items-center space-x-2">
      <div
        className="flex justify-center items-center bg-white w-10 h-10 rounded-full cursor-pointer"
        onClick={isActive ? stopTimer : startTimer}
      >
        {!isActive ? (
          <StartTimer
            className="h-2 w-2 text-primary-main"
            onClick={startTimer}
          />
        ) : (
          <StopTimer className="h-2 w-2 text-primary-main" />
        )}
      </div>
      <div className="text-white">
        <span>{time.hours}</span>
        <span>:</span>
        <span>{time.minutes}</span>
        <span>:</span>
        <span>{time.seconds}</span>
      </div>
    </div>
  )
}

const ManualTabContent = ({
  manualTime,
  handleTimeChange,
  handleBlur,
  timeOption,
  handleSelectTime
}) => {
  return (
    <div>
      <div className="mb-4">
        <input
          type="text"
          name="start"
          placeholder="Enter time e.g. 3 hours 30 mins"
          value={manualTime}
          onChange={handleTimeChange}
          autoFocus
          onBlur={handleBlur}
          className="manual_time text-sm w-full py-1 px-4 text-white rounded-md"
          style={{
            backgroundColor: '#351fff'
          }}
        />
      </div>
      {timeOption.show && (
        <div
          onClick={handleSelectTime}
          className={
            'absolute text-black cursor-pointer px-4 py-2 rounded shadow-lg border border-gray-200 bg-white hover:bg-gray-100 text-sm'
          }
        >
          {timeOption.value}
        </div>
      )}
    </div>
  )
}

const RangeTabContent = ({ manualTimeData, inputDateHandler, showPicker }) => {
  return (
    <div className="flex justify-between items-center space-x-2 text-white font-medium mb-4">
      <input
        type="time"
        name="start"
        value={manualTimeData.start}
        onChange={inputDateHandler}
        className="hide-time-icon text-sm w-full py-1 text-center"
        style={{ backgroundColor: '#351fff', borderRadius: '5px' }}
        onClick={showPicker}
      />
      <span>to</span>
      <input
        type="time"
        name="end"
        value={manualTimeData.end}
        onChange={inputDateHandler}
        className="hide-time-icon text-sm w-full  py-1 text-center"
        style={{ backgroundColor: '#351fff', borderRadius: '5px' }}
        onClick={showPicker}
      />
    </div>
  )
}

export default TimerFooter
