import { useEffect, useMemo } from 'react'
import { Box, Drawer, Tooltip } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import { useHistory } from 'react-router'
import userRoles, { getUserPath } from 'utils/userRoles'
import { ReactComponent as Home } from 'static/svg/home.svg'
import { ReactComponent as Folder } from 'static/svg/folder.svg'
// import { ReactComponent as UserCircle } from 'static/svg/user-circle.svg'
import { ReactComponent as UsersIcon } from 'static/svg/users.svg'
// import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import { ReactComponent as ServiceIcon } from 'static/svg/flash.svg'
import { ReactComponent as PeopleIcon } from 'static/svg/people.svg'
import { ReactComponent as InvoicesIcon } from 'static/svg/invoice.svg'
// import { ReactComponent as SetupIcon } from 'static/svg/setup.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
// import { ReactComponent as BarsIcon } from 'static/svg/bars.svg'
import { ReactComponent as IndentDecrease } from 'static/svg/indent-decrease.svg'
import { ReactComponent as ActivityIcon } from 'static/svg/activity.svg'
// import { ReactComponent as Usd } from 'static/svg/usd-circle.svg'
// import { ReactComponent as Cog } from 'static/svg/cog.svg'
// import { ReactComponent as Tool } from 'static/svg/tools.svg'
// import { ReactComponent as LogOut } from 'static/svg/sign-out.svg'
// import { ReactComponent as Bell } from 'static/svg/bell.svg'
import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
// import { ReactComponent as PaperClip } from 'static/svg/paperclip.svg'
// import { setExpandState } from 'thunks/generlAppState/actions'
// import DonutLargeIcon from '@material-ui/icons/DonutLarge'
// import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'
// import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded'
// import { SidebarLogo } from 'static/Images'
import sidebarStyles from './sidebarStyles'
import tooltipStyles from './tooltipStyles'
import { format } from 'date-fns'
import clsx from 'clsx'
import { ClientvenueLogo } from 'static/Images'
import { toggleSidebar } from 'thunks/generlAppState/actions'
// import ManagerContact from 'components/Sidebar/ManagerContact'
// import { CVLogo } from 'static/Images'

const Sidebar = ({ isDrawerOpen }) => {
  const classes = sidebarStyles()
  const dispatch = useDispatch()
  // const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const myRole = meData.role
  const permission = useSelector(state => state.permission.data?.access)
  const userPath = useMemo(() => {
    return getUserPath(meData.role)
  }, [meData.role])

  const logo = useMemo(() => {
    return (
      (meData.profile?.brand?.largeLogo ??
        meData.team?.profile?.brand?.largeLogo ??
        meData.agency?.brand?.largeLogo) ||
      ClientvenueLogo
    )
  }, [meData])

  useEffect(() => {
    const closeSidebar = () => {
      if (window.innerWidth <= 992) {
        dispatch(toggleSidebar(false))
      }
    }

    if (window.innerWidth <= 992) {
      closeSidebar() // invoke this function after reload if screen size is less than 992
    }

    window.addEventListener('resize', closeSidebar)

    return () => {
      window.removeEventListener('resize', closeSidebar)
    }
  }, [])

  const sideDrawerLinks = useMemo(
    () => [
      // {
      //   text: 'Dashboard',
      //   link: '/dashboard',

      //   icon: <Home className="w-5 h-5" />,
      //   activeNavString: '/dashboard',
      //   access: { view: true } // setting it to view true manually
      // },
      {
        text: 'Projects',
        link: '/workspace/all',
        icon: <Folder className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/workspace',
        // access: permission?.workspace
        access: {
          create: [
            userRoles.USER_AGENCY,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER
          ].includes(meData?.role),
          view: [
            userRoles.USER_AGENCY,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER,
            userRoles.AGENCY_EDITOR,
            userRoles.AGENCY_VIEWER
          ].includes(meData?.role)
        }
      },
      {
        text: 'Reports',
        link: '/reports',

        icon: <ActivityIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/reports',
        access: permission?.report
      },
      {
        text: 'Clients',
        link: '/clients/all',
        icon: <UsersIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/clients',
        access: permission?.clients
      },

      {
        text: 'Services',
        link: '/services/all',
        icon: <ServiceIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/services',
        access:
          meData?.role >= userRoles.USER_CLIENT ? permission?.services : null //Temporary
        // access: permission?.services
      },
      {
        text: 'Teams',
        link: '/teammates/all',
        icon: <PeopleIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/teammates',
        access: permission?.teammate
      },
      // {
      //   text: 'Invoices',
      //   link: '/client-billing/all',
      //   icon: <InvoicesIcon className="w-5 h-5" />,
      //   activeNavString: '/client-billing',
      //   access: permission?.billings
      // },
      {
        text: 'Payments',
        link:
          permission?.billings?.create ||
          permission?.billings?.update ||
          permission?.billings?.view ||
          permission?.billings?.delete
            ? '/payments/client-billing/all'
            : '/payments/services/all',
        icon: <InvoicesIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/payments',
        access:
          meData?.role < userRoles.USER_CLIENT
            ? {
                create:
                  permission?.billings?.create || permission?.services?.create,
                update:
                  permission?.billings?.update || permission?.services?.update,
                view: permission?.billings?.view || permission?.services?.view,
                delete:
                  permission?.billings?.delete || permission?.services?.delete
              }
            : null
      },

      {
        text: 'Invoices',
        link: '/invoices',
        // icon: <ReceiptRoundedIcon />,
        icon: <InvoicesIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/client/invoices',
        access:
          meData?.role === userRoles.USER_CLIENT
            ? { view: true, update: true }
            : null //Temporary
      },
      {
        text: 'Timesheets',
        link: `/resource-management/timesheets/${format(
          new Date(),
          'yyyy-MM-dd'
        )}`,
        icon: <StopWatchIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: `/resource-management`,
        access: permission?.timesheet
        // access: {
        //   view: [
        //     userRoles.USER_AGENCY,
        //     userRoles.AGENCY_ADMIN,
        //     userRoles.AGENCY_MANAGER,
        //     userRoles.AGENCY_EDITOR,
        //     userRoles.AGENCY_VIEWER
        //   ].includes(meData?.role)
        // }
      },

      // {
      //   text: 'Timesheets',
      //   link: `/timesheets/${format(new Date(), 'yyyy-MM-dd')}`,
      //   icon: <StopWatchIcon className="w-5 h-5" />,
      //   activeNavString: `/timesheets`,
      //   access: {
      //     view: [
      //       userRoles.USER_AGENCY,
      //       userRoles.AGENCY_ADMIN,
      //       userRoles.AGENCY_MANAGER,
      //       userRoles.AGENCY_EDITOR,
      //       userRoles.AGENCY_VIEWER
      //     ].includes(meData?.role)
      //   }
      // },

      // {
      //   text: 'Setup',
      //   link: '/setup/branding',
      //   icon: <SetupIcon />,
      //   activeNavString: '/setup',
      //   access: permission?.setup
      // },
      {
        text: 'Settings',
        link: ![
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN,
          userRoles.AGENCY_MANAGER
        ].includes(myRole)
          ? '/settings/profile'
          : '/settings/general',
        icon: <SettingIcon className="w-4 h-4 lg:w-5 lg:h-5" />,
        activeNavString: '/settings',
        access: { manage: true, view: true }
      }
    ],
    [permission]
  )

  const handleDrawer = () => {
    dispatch(toggleSidebar())
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          {
            expanded: isDrawerOpen
          },
          // {
          //   [classes.drawerOpen]: isDrawerOpen,
          //   [classes.drawerClose]: !isDrawerOpen
          // },
          'shadow-lg fixed top-0 bottom-0 bg-white'
        )
      }}
    >
      <Box>
        <header
          className={`${classes.sidebarHeader} flex items-center justify-between px-5`}
        >
          <img
            src={
              logo.includes('https://s3')
                ? `${logo}?${Date.now()}`?.replace('s3.wasabisys.com/', '')
                : logo
            }
            alt="logo"
            className="h-10 object-contain mx-auto"
            style={{ maxWidth: 130 }}
          />
          <div className="shrink-0">
            <CustomTooltip title="collapse sidebar">
              <IndentDecrease
                onClick={handleDrawer}
                className="text-primary-mid-dark cursor-pointer hover:text-primary-main p-1 rounded-md w-7 h-7"
              />
            </CustomTooltip>
          </div>
          {/* )} */}
        </header>
        <div className={`${classes.navIconsContainer} px-3 lg:px-5`}>
          <NavLink
            isActive={(match, location) => {
              return location.pathname.includes('/dashboard')
            }}
            // activeClassName="bg-primary-main"
            to={`${userPath}/dashboard`}
            className={clsx(
              'flex items-center rounded-full w-full',
              classes.iconLink
            )}
          >
            <span className="mr-2">
              <Home className="w-4 h-4 lg:w-5 lg:h-5" />
            </span>
            <span className="font-medium">Dashboard</span>
          </NavLink>
          {myRole &&
            sideDrawerLinks.map((item, index) => {
              if (
                item.access?.create ||
                item.access?.update ||
                item.access?.view ||
                item.access?.delete
              )
                return (
                  <SidebarLink
                    key={item.text}
                    item={item}
                    path={`${userPath}${item.link}`}
                    classes={classes}
                  />
                )
            })}

          {/* {myRole >= userRoles.USER_CLIENT && meData.agencyRepresentative?._id && (
            <div
              className={clsx(
                'flex items-center py-2 px-6 whitespace-nowrap w-full shadow-md',
                classes.iconLink
              )}
              style={{ marginTop: 'auto' }}
            >
              <div className="flex flex-col items-center space-y-1 ">
                <span className="font-medium">Account Manager</span>
                <ManagerContact manager={meData.agencyRepresentative} />
              </div>
            </div>
          )} */}

          {/* {myRole <= userRoles.AGENCY_ADMIN && (
            <SidebarLink
              classes={classes}
              item={{
                activeNavString: '/agency/onboarding',
                icon: <SetupIcon />,
                text: 'Onboarding'
              }}
              path="/agency/onboarding"
            />
          )} */}
        </div>
      </Box>
    </Drawer>
  )
}

export default Sidebar

const SidebarLink = ({ item, path, classes }) => {
  return (
    <NavLink
      isActive={(match, location) => {
        return location.pathname.includes(item.activeNavString)
      }}
      // activeClassName="bg-primary-main"
      to={path}
      className={clsx(
        'flex items-center rounded-full w-full',
        classes.iconLink
      )}
    >
      <span className="mr-2">{item.icon}</span>
      <span className="font-medium">{item.text}</span>
    </NavLink>
  )
}

/**
 * Custom tooltip
 * @param {Object} props Takes props of `Tooltip` of material ui
 */
const CustomTooltip = props => {
  const classes = tooltipStyles()
  return <Tooltip placement="right" arrow classes={classes} {...props} />
}
