import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useTable, useSortBy } from 'react-table'
import SelectColumnsPopup from './SelectColumnsPopup'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
// import { getDaysInMonth } from 'date-fns'
import PeoplePerformanceHeader from './PeoplePerformanceHeader'
import PeoplePerformanceTable from './PeoplePerformanceTable'
import DaysFilterPopup from './DaysFilterPopup'
import { createReportsView, updateReportsView } from 'thunks/views/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { isEqual } from 'lodash'
import useReportsFunctions from '../useReportsFunctions'

const PeoplePerformanceContents = ({
  loading,
  cols,
  data,
  staticCols,
  projectCols,
  handleChangeTimeline,
  peoplePerformanceView
}) => {
  const [timelineAnchorEl, setTimelineAnchorEl] = useState(null)
  const [colSelectAnchorEl, setColSelectAnchorEl] = useState(null)
  const [columnsToHide, setColumnsToHide] = useState(
    projectCols.map(item => item.accessor)
  )
  const [savedHiddenCols, setSavedHiddenCols] = useState(
    projectCols.map(item => item.accessor)
  )
  const [selectedDaysOption, setSelectedDaysOption] = useState({
    label: 'Last 30 days',
    value: 'last30Days'
  })
  const [downloadData, setDownloadData] = useState([])
  const [saveView, setSaveView] = useState(false)
  const childRef = useRef()
  const dispatch = useDispatch()
  const { getTimelineQuery } = useReportsFunctions()

  useEffect(() => {
    const downloadData = data.map(item => {
      const obj = {}

      for (let key in item) {
        // If key value is an object (project) then we need to use project name as key instead of id
        if (typeof item[key] === 'object' && 'completedTasks' in item[key]) {
          if (columnsToHide.includes(key)) continue // if it's an hidden field do nothing

          let val = Object.values(item[key]).join('/') // represent object values in an string (completed tasks / total tasks)
          let project = projectCols.find(item => item.accessor === key) // get project object by id
          if (project) {
            obj[project.label] = val
          }
        } else {
          obj[key] = item[key]
        }
      }
      return obj
    })

    setDownloadData(downloadData)
  }, [data, projectCols, columnsToHide])

  useEffect(() => {
    if (peoplePerformanceView._id) {
      setSavedHiddenCols([
        ...peoplePerformanceView.content[0].hiddenColumns,
        ...peoplePerformanceView.content[0].hiddenProjectColumns
      ])
      setColumnsToHide([
        ...peoplePerformanceView.content[0].hiddenColumns,
        ...peoplePerformanceView.content[0].hiddenProjectColumns
      ])
    } else {
      let cols = projectCols.map(item => item.accessor)

      setSavedHiddenCols(cols)
      setColumnsToHide(cols)
    }
  }, [peoplePerformanceView, projectCols])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns
  } = useTable(
    {
      columns: cols,
      data,
      initialState: { hiddenColumns: columnsToHide }
    },
    useSortBy
  )

  const daysOptions = useMemo(
    () => [
      { label: 'Last 30 days', value: 'last30Days' },
      { label: 'Last 60 days', value: 'last60Days' },
      { label: 'Last 90 days', value: 'last90Days' }
    ],
    []
  )

  useEffect(
    function () {
      setHiddenColumns(columnsToHide)
      setSaveView(!isEqual(savedHiddenCols, columnsToHide))
      // let timer = setTimeout(() => {
      // }, 100)

      // return () => clearTimeout(timer)
    },
    [columnsToHide]
  )

  const toggleColumnsVisibility = (accessor, checked) => {
    if (!checked) {
      if (accessor === 'allProjects') {
        const cols = projectCols.map(item => item.accessor)
        const hiddenCols = new Set([...cols, ...columnsToHide])
        setColumnsToHide([...hiddenCols])
      } else {
        setColumnsToHide(prev => [...prev, accessor])
      }
    } else {
      if (accessor === 'allProjects') {
        const filteredCols = columnsToHide.filter(item => {
          let idx = projectCols.findIndex(col => col.accessor === accessor)
          return idx !== -1
        })
        setColumnsToHide(filteredCols)
      } else {
        setColumnsToHide(prev => prev.filter(item => item !== accessor))
      }
    }
  }

  const handleSelectTimelineOption = sel => {
    const query = getTimelineQuery(sel.value)
    setSelectedDaysOption(sel)
    handleChangeTimeline(query)
  }

  const handleToggleTimelinePopup = useCallback(
    e => {
      if (timelineAnchorEl) setTimelineAnchorEl(null)
      else {
        setTimelineAnchorEl(e.currentTarget)
        e.stopPropagation()
      }
    },
    [timelineAnchorEl]
  )

  const toggleSelectColumnsPopup = useCallback(
    e => {
      if (colSelectAnchorEl) setColSelectAnchorEl(null)
      else {
        e.stopPropagation()
        setColSelectAnchorEl(e.currentTarget)
      }
    },
    [colSelectAnchorEl]
  )

  const handleSaveView = useCallback(() => {
    childRef.current.setLoading(true)

    // Separating hidden project cols and other cols from columnsToHide (requested by backend developer)
    let hiddenProjectColumns = [],
      hiddenColumns = []

    for (let hiddenCol of columnsToHide) {
      let idx = projectCols.findIndex(item => item.accessor === hiddenCol)
      if (idx !== -1) {
        hiddenProjectColumns.push(hiddenCol)
      } else {
        hiddenColumns.push(hiddenCol)
      }
    }

    // If view object exists then call update else call create
    if (peoplePerformanceView._id) {
      dispatch(
        updateReportsView(
          peoplePerformanceView._id,
          { content: [{ hiddenColumns, hiddenProjectColumns }] },
          saveViewCallback
        )
      )
    } else {
      dispatch(
        createReportsView(
          {
            type: 'report',
            content: [{ hiddenColumns, hiddenProjectColumns }]
          },
          saveViewCallback
        )
      )
    }
  }, [columnsToHide, peoplePerformanceView._id])

  const saveViewCallback = (res, err) => {
    childRef.current.setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else dispatch(fireSuccessToaster('Changes saved successfully!'))
  }

  return (
    <>
      <PeoplePerformanceHeader
        handleToggleTimelinePopup={handleToggleTimelinePopup}
        selectedTimeline={selectedDaysOption.label}
        downloadData={downloadData}
        toggleSelectColumnsPopup={toggleSelectColumnsPopup}
        selectPopupOpen={Boolean(colSelectAnchorEl)}
      />
      {loading ? (
        <LoadingTable staticCols={staticCols} />
      ) : (
        <>
          {/* <PeoplePerformanceHeader
            handleToggleTimelinePopup={handleToggleTimelinePopup}
            selectedTimeline={selectedDaysOption.label}
            downloadData={downloadData}
            toggleSelectColumnsPopup={toggleSelectColumnsPopup}
            selectPopupOpen={Boolean(colSelectAnchorEl)}
          /> */}

          <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-xl border rounded-xl">
            <PeoplePerformanceTable
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              prepareRow={prepareRow}
            />
          </div>
        </>
      )}

      <SelectColumnsPopup
        anchorEl={colSelectAnchorEl}
        handleClose={toggleSelectColumnsPopup}
        toggleColumnsVisibility={toggleColumnsVisibility}
        hiddenCols={columnsToHide}
        projectCols={projectCols}
        saveView={saveView}
        handleSaveView={handleSaveView}
        ref={childRef}
      />

      <DaysFilterPopup
        anchorEl={timelineAnchorEl}
        daysOptions={daysOptions}
        onClose={handleToggleTimelinePopup}
        selectedDaysOption={selectedDaysOption}
        handleSelectTimelineOption={handleSelectTimelineOption}
      />
    </>
  )
}

const LoadingTable = ({ staticCols }) => {
  return (
    <TableContainer>
      <Thead>
        {staticCols.map(item => (
          <Th key={item.label}>{item.label}</Th>
        ))}
      </Thead>
      <Tbody>
        <TableDataLoading cols={staticCols.length} />
      </Tbody>
    </TableContainer>
  )
}

export default PeoplePerformanceContents
