import React, { useState, useEffect, useRef, useMemo } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Pill from 'global/globalComponents/Pill/Pill'
import clsx from 'clsx'
import { ReactComponent as Repeat } from 'static/svg/repeat.svg'
import { ReactComponent as VerticalThreeDots } from 'static/svg/ellipsis-h.svg'
import taskSidePanelStyles from './taskSidePanelStyles'
import { useSelector, useDispatch } from 'react-redux'
import {
  // fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
// import Check from '@material-ui/icons/Check'
import CustomTooltip from 'global/globalComponents/CustomTooltip/CustomTooltip'
import { CustomFlag, OutlinedButton, PopupMenu } from 'global/globalComponents'
import { ReactComponent as FlagOutlined } from 'static/svg/pennant-outlined.svg'
import { fileToDataUrl } from 'utils'
import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
import EstimateTimePopup from './EstimateTimePopup'
import PanelTimer from 'components/Timer/PanelTimer'
import { userRoles, adjustColor } from 'utils'
import { CircularProgress, Toolbar } from '@material-ui/core'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
// import useWorkspaceFunctions from '../useWorkspaceFunctions'
// import { updateTaskDetails } from 'thunks/task/actions'
// import { isEqual } from 'lodash'
import { ReactComponent as UserCheckIcon } from 'static/svg/user-check.svg'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'

const TaskPanelHeader = ({
  task,
  onClose,
  handleAttachmentUpload,
  handleOpenDeleteTaskConfirm,
  taskPermission,
  triggerPopover,
  elmFor, // using this header for 2 comp => task and subtask
  changeEstimateTime,
  // socketId,
  // updateProjectStats,
  // type,
  canUpdateTask,
  markTaskAsComplete,
  // currentWorkspace,
  // currentBoard,
  inDashboardArea,
  openApprovalPanel
}) => {
  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)
  const taskStatus = useMemo(() => {
    if (currentBoard.boardSection) {
      return currentBoard.boardSection.find(
        item => item._id === task.taskSection
      )
    }
  }, [task.taskSection, currentBoard.boardSection])
  const [menuOptions, setMenuOptions] = useState([])
  const inputRef = useRef()
  const [selectedStatus, setSelectedStatus] = useState({ label: '', color: '' })
  const [esTimeAnchor, setEsTimeAnchor] = useState(null)
  const [selectedTime, setSelectedTime] = useState('')
  const dispatch = useDispatch()
  const classes = taskSidePanelStyles()
  const meData = useSelector(state => state.me.data)
  // const { canUpdateTask, markTaskAsComplete } = useWorkspaceFunctions()

  useEffect(() => {
    if (task.estimateTimeInString) {
      setSelectedTime(task.estimateTimeInString)
    }
  }, [task.estimateTimeInString])

  useEffect(() => {
    if (taskStatus) {
      setSelectedStatus({
        label: taskStatus.label,
        color: taskStatus.color
      })
    }
  }, [taskStatus])

  useEffect(() => {
    if (taskPermission?.update) {
      const arr = [
        {
          label: 'Upload image',
          method: handleImageUploadClick
        },
        // { label: 'Approvals', method: openApprovalPanel },
        { label: 'Copy link', method: handleCopyLink },
        { label: 'Delete', method: handleOpenDeleteTaskConfirm }
      ]

      setMenuOptions(arr)
    }
  }, [taskPermission, elmFor])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href)
    dispatch(fireSuccessToaster('Link copied!'))
  }

  const handleChangePriority = e => {
    if (canUpdateTask(taskPermission.update)) {
      triggerPopover(e, 'priority')
    }
  }

  const handleUploadImage = async e => {
    if (canUpdateTask(taskPermission.update)) {
      const file = e.target.files[0]
      if (file) {
        const res = await fileToDataUrl(file)
        handleAttachmentUpload(res)
      }
    }
  }

  const handleChangeStatus = e => {
    if (canUpdateTask(taskPermission.update)) {
      triggerPopover(e, 'status')
    }
  }

  const handleImageUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const toggleEstimateTimePopup = e => {
    if (esTimeAnchor) {
      setEsTimeAnchor(null)
    } else {
      setEsTimeAnchor(e.currentTarget)
    }
  }

  const handleChangeEstimateTime = ({ timeStr, totalMs }) => {
    if (timeStr !== '') {
      setSelectedTime(timeStr)

      changeEstimateTime({
        estimateTimeInString: timeStr,
        estimateTimeInMilliSecond: totalMs
      })
      toggleEstimateTimePopup()
    } else {
      changeEstimateTime({
        estimateTimeInString: '',
        estimateTimeInMilliSecond: null
      })
    }
  }

  const handleToggleComplete = () => {
    if (canUpdateTask(taskPermission.update)) {
      markTaskAsComplete(!task.markAsComplete)
    }
  }

  const handleRecurringTask = e => {
    if (canUpdateTask(taskPermission.update)) {
      triggerPopover(e, 'recurring')
    }
  }

  // const handleChangeApprovalStatus = (reqStatus) => {
  //   dispatch(
  //     updateTaskDetails(
  //       task._id,
  //       {
  //         clientApproval: reqStatus || null,
  //         workSpace: task.workSpace,
  //         taskSection: task.taskSection,
  //         socketId
  //       },
  //       (res, err) => {
  //         if (!err) {
  //           if (elmFor === 'task' && type === 'overview') {
  //             updateProjectStats(res)
  //           }
  //         } else {
  //           dispatch(fireErrorToaster(res))
  //         }
  //       }
  //     )
  //   )
  // }

  return (
    <Toolbar onClose={onClose} className={classes.drawerPaper1}>
      <div className={classes.drawerHeader}>
        <div className="flex items-center space-x-2 md:space-x-4 lg:space-x-6">
          <div onClick={handleChangeStatus} className="cursor-pointer">
            {selectedStatus.label ? (
              <Pill
                style={{
                  color: adjustColor(selectedStatus.color, -70),
                  backgroundColor: selectedStatus.color + '20'
                }}
              >
                {selectedStatus.label}
              </Pill>
            ) : (
              <div className="flex items-center px-6 h-7 border border-gray-400 rounded-full">
                <CircularProgress size={14} />
              </div>
            )}
          </div>

          <div onClick={handleChangePriority} className="leading-3">
            {task.priority && task.priority !== 5 ? (
              <CustomFlag priority={task.priority} />
            ) : (
              <CustomTooltip title="Set Priority" placement="top">
                <button className="text-base inline-block text-gray-400 w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 border border-gray-400 border-dashed rounded-full flex justify-center items-center">
                  <FlagOutlined className="text-sm lg:text-base" />
                </button>
              </CustomTooltip>
            )}
          </div>

          {/* {hasPassedDueDate && (
            <Pill variant="red" className="leading-3">
              Past Due
            </Pill>
          )} */}

          {meData.role < userRoles.USER_CLIENT && (
            <PanelTimer task={task} elmFor={elmFor} />
            // <div className="text-xs text-gray-500 cursor-pointer">
            //   Time Tracked
            //   <div className="flex items-center space-x-1">
            //     <PanelTimer task={task} elmFor={elmFor} />
            //   </div>
            // </div>
          )}

          <CustomTooltip title="Estimate Time" placement="top">
            <div
              className="flex items-center space-x-1"
              onClick={toggleEstimateTimePopup}
            >
              <div className="cursor-pointer inline-block text-gray-500">
                <StopWatchIcon />
              </div>
              {task.estimateTimeInString && (
                <span className="text-xs -mb-1">
                  {task.estimateTimeInString}
                </span>
              )}
            </div>
          </CustomTooltip>

          {elmFor === 'task' && (
            <CustomTooltip title="Recurring Task" placement="top">
              <label
                role="button"
                className={classes.headerIconContainer}
                onClick={handleRecurringTask}
              >
                <Repeat
                  className={clsx(
                    task?.isTaskRecurring
                      ? 'text-primary-main'
                      : 'text-gray-500'
                  )}
                />
              </label>
            </CustomTooltip>
          )}

          {taskPermission?.update && (
            <>
              <div className="flex space-x-1 items-center text-gray-700">
                <OutlinedButton
                  variant="success"
                  isActive={task.markAsComplete}
                  onClick={handleToggleComplete}
                  roundedFull={false}
                >
                  <CheckIcon />
                  <span className=" ml-2">
                    {task?.markAsComplete ? 'Completed' : 'Mark Complete'}
                  </span>
                </OutlinedButton>

                <input
                  type="file"
                  style={{ display: 'none' }}
                  multiple={false}
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleUploadImage}
                />
              </div>
            </>
          )}

          <OutlinedButton onClick={openApprovalPanel} roundedFull={false}>
            <UserCheckIcon />
            <span className="ml-2">Approvals</span>
          </OutlinedButton>

          {/* {elmFor === 'task' &&
            currentWorkspace.category === 'External' &&
            currentWorkspace.assignedClientTeam.length > 0 && (
              <div className="ml-auto mr-2">
                <ButtonApproval
                  taskId={task._id}
                  userRole={meData.role}
                  clientApproval={task.clientApproval}
                  handleChangeApprovalStatus={handleChangeApprovalStatus}
                />
              </div>
            )} */}
        </div>

        <div className="flex items-center space-x-4 ml-2">
          {!inDashboardArea && taskPermission?.update && (
            <PopupMenu
              targetComp={
                <button className="font-lg text-gray-500">
                  <VerticalThreeDots />
                </button>
              }
              menuItems={menuOptions}
            />
          )}

          <button
            onClick={onClose}
            className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 rounded-full border flex justify-center items-center border-primary-mid-light"
          >
            <CloseIcon className="text-primary-main text-lg lg:text-xl" />
          </button>
        </div>
      </div>

      <EstimateTimePopup
        anchorEl={esTimeAnchor}
        open={Boolean(esTimeAnchor)}
        onClose={toggleEstimateTimePopup}
        selectedTime={selectedTime}
        handleChangeEstimateTime={handleChangeEstimateTime}
        style={{ zIndex: 2000 }}
      />
    </Toolbar>
  )
}

// const ButtonApproval = React.memo(
//   ({ userRole, clientApproval, handleChangeApprovalStatus }) => {
//     let element = null
//     const classes =
//       'text-xs border rounded px-2 py-1 font-semibold whitespace-nowrap'

//     const handleApproval = (e) => {
//       const reqStatus = e.currentTarget.dataset.request
//       handleChangeApprovalStatus(reqStatus)
//     }

//     if (
//       clientApproval &&
//       [userRoles.USER_CLIENT, userRoles.CLIENT_MANAGER].includes(userRole)
//     ) {
//       element =
//         clientApproval === 'approve' ? (
//           <CustomTooltip placement="top" title="Approved by client">
//             <div>
//               <Pill variant="green">Approved</Pill>
//             </div>
//           </CustomTooltip>
//         ) : (
//           <CustomTooltip placement="top" title="Approve this task">
//             <button
//               onClick={handleApproval}
//               className={classes}
//               data-request="approve"
//             >
//               Approve
//             </button>
//           </CustomTooltip>
//         )
//     } else if (
//       [
//         userRoles.AGENCY_ADMIN,
//         userRoles.AGENCY_MANAGER,
//         userRoles.USER_AGENCY
//       ].includes(userRole)
//     ) {
//       element =
//         clientApproval === 'approve' ? (
//           <CustomTooltip placement="top" title="Approved by client">
//             <div>
//               <Pill variant="green">Approved</Pill>
//             </div>
//           </CustomTooltip>
//         ) : !Boolean(clientApproval) ? (
//           <CustomTooltip
//             placement="top"
//             title={"Send request for client's approval"}
//           >
//             <button
//               className={clsx(classes, 'text-orange-400 border-orange-400')}
//               onClick={handleApproval}
//               data-request="request"
//             >
//               Request approval
//             </button>
//           </CustomTooltip>
//         ) : (
//           <CustomTooltip placement="top" title={'Cancel approval request'}>
//             <button
//               onClick={handleApproval}
//               className={clsx(classes, 'border-red-400 text-red-400')}
//               data-request=""
//             >
//               Cancel Request
//             </button>
//           </CustomTooltip>
//         )
//     }

//     return element
//   }
// )

export default React.memo(TaskPanelHeader)
