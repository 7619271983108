import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { CustomModal } from 'global/globalComponents/CustomModal'
import Step3 from './Step3'
import Step4 from './Step4'
import Step1 from './Step1'
import { createProjectFromImport } from 'thunks/workspace/actions'
import ImportedDataTable from './ImportedDataTable'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { useHistory } from 'react-router-dom'
import { getUserPath } from 'utils/userRoles'

const colorOptions = [
  '#8d84e8',
  '#f06a6a',
  '#aecf55',
  '#ec8d71',
  '#f1bd6c',
  '#f8df72',
  '#5da283',
  '#4ecbc4',
  '#4573d2',
  '#b36bd4',
  '#f9aaef',
  '#f26fb2',
  '#fc979a',
  '#6d6e6f',
  '#c7c4c4',
  '#9ee7e3'
]

const initialOptions = [
  { label: 'Do not import user', value: false },
  { label: 'Select user', value: 'select user' }
]

const ImportMappingModal = ({ open = true, onClose, fileData }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector((state) => state.me.data)
  const [columnHeader, setColumnHeader] = useState([])
  const [taskData, setTaskData] = useState([])
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [projectSpace, setProjectSpace] = useState({
    space: {},
    project: ''
  })
  const [mappedColumn, setMappedColumn] = useState({})
  const [projectData, setProjectData] = useState({
    new: true,
    name: ''
  })
  const [collaborators, setCollaborators] = useState({})
  const [delimiter, setDelimiter] = useState({
    key: null,
    other: false
  })
  const [delimiterKeys, setDelimiterKeys] = useState({})

  useEffect(() => {
    setColumnHeader(fileData[0]?.map((el) => 'false'))
  }, [fileData])

  const handleTaskData = (newTaskData) => {
    if (columnHeader.includes('taskStatus')) {
      let sectionData = []
      newTaskData.forEach((task) => {
        if (task.taskStatus && !sectionData.includes(task.taskStatus)) {
          sectionData.push(task.taskStatus)
        }
      })
      sectionData = sectionData.map((section, index) => {
        const data = {
          label: section.toLowerCase(),
          order: index + 1,
          color: colorOptions[index]
        }
        if (!projectData.new) {
          data.workspaceBoard = projectData.workspaceBoard
        }
        if (columnHeader.includes('subTask')) {
          let sectionTasks = newTaskData.filter(
            (task) => task.taskStatus === section && !task.subTask
          )
          const subtasks = newTaskData.filter((task) => task.subTask)
          sectionTasks = sectionTasks.map((item) => ({
            ...item,
            subTask: subtasks.filter((el) => el.subTask === item?.title)
          }))
          data.task = sectionTasks
        } else {
          data.task = newTaskData.filter((task) => task.taskStatus === section)
        }
        return data
      })
      return sectionData
    } else {
      const data = {
        label: 'untitled',
        order: 1,
        color: colorOptions[0],
        task: newTaskData
      }
      if (!projectData.new) {
        data.workspaceBoard = projectData.workspaceBoard
      }
      return [data]
    }
  }

  const handleColumnMapping = (e, index) => {
    const updatedData = [...columnHeader]
    updatedData[index] = e.value
    setColumnHeader(updatedData)
    setMappedColumn((prev) => ({
      ...prev,
      [index]: e
    }))
  }

  const handleReview = () => {
    if (!columnHeader.includes('title')) {
      return dispatch(fireErrorToaster('Title is required'))
    }

    const newColumnHeader = columnHeader.filter((el) => el !== 'false')
    const duplicateField = newColumnHeader.filter(
      (item, ind) => newColumnHeader.indexOf(item) !== ind
    )

    if (duplicateField.length) {
      return dispatch(
        fireErrorToaster('Sorry, One field can be mapped only once!.')
      )
    }

    const removedIndex = []
    columnHeader.map((item, index) => {
      if (item === 'false') {
        removedIndex.push(index)
      }
    })

    const updatedData = fileData.slice(1).map((item) => {
      const taskObj = {}

      const filterData = item.filter(
        (el, index) => !removedIndex.includes(index)
      )
      filterData.forEach((el, ind) => {
        if (newColumnHeader[ind] === 'title') {
          taskObj[newColumnHeader[ind]] = el ? el : 'Untitled'
        } else {
          taskObj[newColumnHeader[ind]] = el
        }
      })
      return taskObj
    })
    setTaskData([...updatedData])
    setStep(2)
  }

  // console.log(delimiterKeys, 'delimiterKeysssssssss', delimiter)
  // console.log(collaborators, 'collaborators')

  const handleContinue = () => {
    if (columnHeader.includes('assignedAgencyTeam')) {
      for (let i = 0; i <= taskData.length; i++) {
        if (taskData[i]?.assignedAgencyTeam) {
          setDelimiterKeys((prev) => ({
            ...prev,
            collaborators: false
          }))
          break
        }
      }
    }
    setStep(3)
  }

  const handleChanges = () => {
    if (delimiterKeys.collaborators) {
      if (!delimiter.key) {
        return dispatch(fireErrorToaster('Please enter a delimiter!'))
      }
      const collabs = {}
      taskData.forEach((task) => {
        if (task.assignedAgencyTeam) {
          task.assignedAgencyTeam.split(`${delimiter.key}`).forEach((mate) => {
            if (!(mate.trim() in collabs)) {
              collabs[mate.trim()] = {
                userState: { label: 'Do not import user', value: false },
                options: initialOptions
              }
            }
          })
        }
      })

      setCollaborators(collabs)
      setStep(4)
    } else {
      handleSave()
    }
  }

  const handleSave = () => {
    const newTaskData = taskData.map((item) => {
      if (
        delimiterKeys.collaborators &&
        typeof item.assignedAgencyTeam === 'string'
      ) {
        const team = item?.assignedAgencyTeam
          ?.split(`${delimiter.key}`)
          .map((user) => {
            if (collaborators[user.trim()]) {
              return collaborators[user.trim()].userState.value
            } else {
              return ''
            }
          })
        item.assignedAgencyTeam = team?.filter((el) => el)
      } else {
        item.assignedAgencyTeam = []
      }

      if (!projectData.new) {
        return {
          ...item,
          workSpace: projectData._id,
          workspaceBoard: projectData.workspaceBoard
        }
      } else {
        return item
      }
    })
    // }
    const projectSections = handleTaskData(newTaskData)
    console.log({ ...projectData, sections: projectSections }, 'projectData')
    setLoading(true)
    dispatch(
      createProjectFromImport(
        { project: { ...projectData, sections: projectSections } },
        handleSaveCallback
      )
    )
  }

  const handleSaveCallback = (WSRes, WSErr) => {
    setLoading(false)
    if (!WSErr) {
      dispatch(
        fireSuccessToaster(
          'Data imported successfully. Redirecting to imported project!'
        )
      )
      dispatch(
        fetchWorkspacePermissions((res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
            handleClose()
          } else {
            handleClose()
            history.push(
              `${getUserPath(meData.role)}/workspace/${WSRes._id}/overview`
            )
          }
        })
      )
    } else {
      dispatch(fireErrorToaster(WSRes))
    }
  }

  const handleClose = () => {
    onClose()
    setMappedColumn({})
    setColumnHeader([])
    setTaskData([])
    setProjectSpace({
      space: {},
      project: ''
    })
    setProjectData({
      new: true,
      name: ''
    })
    setCollaborators({})
    setDelimiterKeys({})
    setDelimiter({})
  }

  console.log(taskData, 'taskDataaaaaaaaaaaaaaaaaaaaaa')

  return (
    <CustomModal
      open={open}
      // handleClose={onClose}
      size="extraLarge"
    >
      {step === 1 && (
        <Step1
          setStep={setStep}
          handleColumnMapping={handleColumnMapping}
          handleReview={handleReview}
          fileData={fileData}
          onClose={onClose}
          mappedColumn={mappedColumn}
        />
      )}
      {step === 2 && (
        <ImportedDataTable
          columnHeader={columnHeader}
          mappedColumn={mappedColumn}
          handleContinue={handleContinue}
          handleBack={() => setStep(1)}
          taskData={taskData}
          setTaskData={setTaskData}
          collaborators={collaborators}
          setCollaborators={setCollaborators}
        />
      )}
      {step === 3 && (
        <Step3
          setStep={setStep}
          projectData={projectData}
          setProjectData={setProjectData}
          columnHeader={columnHeader}
          handleChanges={handleChanges}
          loading={loading}
          projectSpace={projectSpace}
          setProjectSpace={setProjectSpace}
          delimiter={delimiter}
          setDelimiter={setDelimiter}
          delimiterKeys={delimiterKeys}
          setDelimiterKeys={setDelimiterKeys}
        />
      )}
      {step === 4 && (
        <Step4
          setStep={setStep}
          projectData={projectData}
          setProjectData={setProjectData}
          handleSave={handleSave}
          columnHeader={columnHeader}
          loading={loading}
          collaborators={collaborators}
          setCollaborators={setCollaborators}
        />
      )}
    </CustomModal>
  )
}

export default ImportMappingModal
