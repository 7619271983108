import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import Select from 'react-select'
import userRoles from 'utils/userRoles'
import { useDispatch, useSelector } from 'react-redux'
import Category from 'utils/workspaceCategories'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import clientStyles from './ClientStyles'
import {
  addClientCompany,
  updateClientCompany
} from 'thunks/clientCompany/action'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import currency from 'static/Currency/Currency'
import { getClient } from 'thunks/addClient/actions'
import Countries from 'static/countries/Countries'
import ct from 'countries-and-timezones'
import fileToDataURL from 'utils/fileToDataUrl'

const EditClientCompany = ({ open, company, handleClose }) => {
  const classes = clientStyles()
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const { fetched, loading, data: userClients } = useSelector(
    (state) => state.userClients
  )
  const [currencyOptions, setCurrencyOptions] = useState([])
  const [companyloading, setCompanyLoading] = useState(false)
  const [companyData, setCompanyData] = useState({
    brandName: '',
    companyLegalName: '',
    tax: '',
    category: '',
    paymentTerm: '',
    defaultCurrency: '',
    clientOwner: '',
    address: {
      city: '',
      country: '',
      line1: '',
      line2: '',
      state: '',
      zipCode: ''
    },
    file: ''
  })

  const clientOwner = useMemo(() => {
    return userClients?.find((item) => item._id === company.clientOwner)
  }, [company])

  // const defaultCurrency = useMemo(() => {
  //   return currency[company.defaultCurrency.toUppercase()]
  // }, [company])

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      paddingTop: 7,
      paddingBottom: 7,
      height: '30px'
    })
  }

  useEffect(() => {
    setCompanyData({
      brandName: company.brandName ?? '',
      companyLegalName: company.companyLegalName ?? '',
      tax: company.tax,
      category: company.category,
      paymentTerm: company.paymentTerm,
      clientOwner: clientOwner
        ? { label: clientOwner.name, value: clientOwner._id }
        : '',
      defaultCurrency: company.defaultCurrency
        ? {
            label: company.defaultCurrency?.toUpperCase(),
            value: company.defaultCurrency
          }
        : '',
      address: company.address || {
        city: '',
        country: '',
        line1: '',
        line2: '',
        state: '',
        zipCode: ''
      },
      file: company.logo ?? ''
    })
  }, [company])

  useEffect(() => {
    if (!loading && !fetched) {
      getClient()
    }
  }, [])

  useEffect(() => {
    let arr = Object.keys(currency).map((el) => ({
      value: el.toLowerCase(),
      label: el
    }))
    setCurrencyOptions(arr)
  }, [])

  const clientOptions = useMemo(() => {
    return userClients
      .filter((el) => !el.company)
      .map((client) => ({
        label: client.name,
        value: client._id
      }))
  }, [userClients])

  // const country = []
  const formInputHandler = (e) => {
    const { value, name } = e.target
    setCompanyData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleChange = (e, key) => {
    setCompanyData((prev) => ({ ...prev, [key]: e }))
  }

  const handleAddressChange = (e) => {
    const { name, value } = e.target
    setCompanyData((prev) => ({
      ...prev,
      address: { ...prev.address, [name]: value }
    }))
  }

  const handleCountryChange = (e) => {
    setCompanyData((prev) => ({
      ...prev,
      address: { ...prev.address, country: e }
    }))
  }

  const imageLoadHandler = (e) => {
    const file = e.target.files[0]
    fileToDataURL(file).then((res) =>
      setCompanyData((prev) => ({ ...prev, file: res }))
    )
  }

  const closeSidePanel = () => {
    handleClose()
    setCompanyData({
      brandName: '',
      companyLegalName: '',
      // email: '',
      category: '',
      tax: '',
      paymentTerm: '',
      defaultCurrency: '',
      address: {
        city: '',
        country: '',
        line1: '',
        line2: '',
        state: '',
        zipCode: ''
      },
      file: ''
    })
  }

  const handleButtonClick = () => {
    const {
      brandName,
      companyLegalName,
      clientOwner,
      tax,
      paymentTerm,
      defaultCurrency
    } = companyData
    if (!brandName || !companyLegalName || !defaultCurrency) {
      return dispatch(fireErrorToaster('Please fill the required fields'))
    }

    const data = { ...companyData }

    // delete data.clientOwner

    if (defaultCurrency.value) {
      data.defaultCurrency = defaultCurrency.value
    }

    if (companyData.file === company.logo) {
      delete data.file
    }

    if (clientOwner.value !== company.clientOwner) {
      data.newClientOwner = clientOwner.value
      delete data.clientOwner
    } else {
      data.clientOwner = clientOwner.value
    }

    setCompanyLoading(true)
    dispatch(
      updateClientCompany(company._id, data, (res, err) => {
        setCompanyLoading(false)
        if (!err) {
          dispatch(fireSuccessToaster('Company Updated Successfully!'))
          closeSidePanel()
        }
      })
    )
  }

  return (
    <>
      <CustomDrawer onClose={closeSidePanel} anchor="right" open={open}>
        <DrawerHeader onClose={closeSidePanel}>Edit Company</DrawerHeader>
        <DrawerContent open={open} className="pb-4">
          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Brand Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="brandName"
                value={companyData.brandName}
                type="text"
                placeholder="Full Name"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box
            mt={2}
            style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
          >
            <Box style={{ flexGrow: '1' }}>
              <FormLabel required>Company's Legal Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                name="companyLegalName"
                value={companyData.companyLegalName}
                type="text"
                placeholder="Full Name"
                onChange={formInputHandler}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <FormLabel>Add Company Logo</FormLabel>
            <div className="flex flex-row items-center gap-4">
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="user-image-uploadbtn"
                multiple={false}
                type="file"
                onChange={imageLoadHandler}
              />

              <FormLabel htmlFor="user-image-uploadbtn">
                <Button component="span" size="small">
                  Choose File
                </Button>
              </FormLabel>
              <img
                src={
                  companyData.file
                    ? companyData.file
                    : 'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'
                }
                alt="service icon"
                className="ml-4 outline-none h-14 w-14 rounded-full object-cover shadow-md"
                height="60px"
                width="60px"
              />
            </div>
          </Box>

          <Box mt={2}>
            <FormLabel>Company Address</FormLabel>
            <div className="grid grid-cols-8 gap-4">
              <TextField
                placeholder="Line 1"
                name="line1"
                className="w-full col-span-4"
                value={companyData.address.line1}
                onChange={handleAddressChange}
              />
              <TextField
                placeholder="Line 2"
                name="line2"
                value={companyData.address.line2}
                className="w-full col-span-4"
                onChange={handleAddressChange}
              />
              <TextField
                placeholder="City"
                value={companyData.address.city}
                name="city"
                className="w-full col-span-4"
                onChange={handleAddressChange}
              />
              <TextField
                value={companyData.address.state}
                name="state"
                placeholder="State"
                className="w-full col-span-4"
                onChange={handleAddressChange}
              />

              <Select
                className="w-full col-span-4 text-sm"
                placeholder="Country"
                isSearchable={true}
                options={Countries}
                value={companyData.address.country}
                maxMenuHeight={250}
                onChange={handleCountryChange}
                menuPlacement="top"
                styles={{ ...reactSelectCustomStyles, ...customStyles }}
              />
              <TextField
                placeholder="Zip Code"
                name="zipCode"
                value={companyData.address.zipCode}
                className="w-full col-span-4 "
                onChange={handleAddressChange}
                type="text"
              />
            </div>
          </Box>

          <Box mt={2}>
            <FormLabel>Category (Eg: Manufacturing, Software etc)</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="category"
              placeholder="Category"
              value={companyData.category}
              onChange={formInputHandler}
            />
          </Box>

          <Box mt={2}>
            <FormLabel>Tax id</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="tax"
              placeholder="Tax id"
              value={companyData.tax}
              onChange={formInputHandler}
            />
          </Box>

          <Box mt={2}>
            <FormLabel required>Currency</FormLabel>
            <Select
              placeholder="Select default currency"
              styles={reactSelectCustomStyles}
              options={currencyOptions}
              onChange={(e) => handleChange(e, 'defaultCurrency')}
              value={companyData.defaultCurrency}
              menuPlacement="top"
              maxMenuHeight={170}
            />
          </Box>

          {/* <Box mt={2}>
            <FormLabel>Payment terms (in days)</FormLabel>
            <TextField
              className={classes.textFieldStyle}
              name="paymentTerm"
              type="number"
              InputProps={{
                max: 100,
                min: 0
              }}
              placeholder="payment terms"
              value={companyData.paymentTerm}
              onChange={formInputHandler}
            />
          </Box> */}

          <Box mt={2}>
            <FormLabel>Company Representative</FormLabel>
            <Select
              options={clientOptions}
              styles={reactSelectCustomStyles}
              menuPlacement="top"
              maxMenuHeight={170}
              value={companyData.clientOwner}
              onChange={(e) => handleChange(e, 'clientOwner')}
            />
          </Box>
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={companyloading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Update Company
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(EditClientCompany)
