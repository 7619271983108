export const WORKSPACE_PERMISSION = {
  LOADING: 'WORKSPACE_PERMISSION_LOADING',
  FETCHED: 'WORKSPACE_PERMISSION_FETCHED'
}

const initialState = {
  fetched: false,
  loading: true,
  data: []
}

const workspacePermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORKSPACE_PERMISSION.FETCHED:
      return { loading: false, data: action.payload, fetched: true }

    default:
      return state
  }
}

export default workspacePermissionReducer
