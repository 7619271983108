import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import CellWrapper from './CellWrapper'
import { isEqual } from 'lodash'

const EditableCell = props => {
  const {
    value,
    row: { index, values, original },
    column: { id, fieldType },
    // updateStaticColData,
    updateTaskProperty,
    updateDynamicColData,
    handleOpenTaskSidePanel,
    taskPermission
  } = props
  // We need to keep and update the state of the cell normally
  const [text, setText] = useState(value ?? '')
  const [edit, setEdit] = useState(false)

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    if (value && value !== text) {
      setText(value)
    }
  }, [value])

  const onChange = e => {
    setText(e.target.value)
  }

  const handleToggleEdit = () => {
    setEdit(prev => !prev)
  }

  // We'll only update the external data when the input is blurred
  const handleUpdateValue = e => {
    e.preventDefault()
    handleToggleEdit()

    if (value === text.trim()) return

    if (fieldType === 'static') {
      // taskId, workspaceId, taskSection, updateData, cb
      updateTaskProperty({
        taskId: original._id,
        workspaceId: original.workSpace,
        taskSection: original.taskSection,
        updateData: { [id]: text.trim() }
      })
      // updateStaticColData({
      //   rowIndex: index,
      //   columnId: id,
      //   value: text,
      //   updateData: { [id]: text },
      //   taskId: original.taskId
      // })
    } else {
      updateDynamicColData({
        rowIndex: index,
        columnId: id,
        value: text,
        allRowValues: values,
        taskId: original.taskId
      })
    }
  }

  const handleOpenClick = e => {
    e.stopPropagation()
    handleOpenTaskSidePanel(original)
  }

  return (
    <CellWrapper
      onClick={taskPermission.update ? handleToggleEdit : undefined}
      styles={{ minWidth: 250 }}
    >
      <div className="w-full">
        {edit ? (
          <form onSubmit={handleUpdateValue}>
            <input
              value={text}
              onChange={onChange}
              onBlur={handleUpdateValue}
              className="bg-transparent w-full px-2 h-full"
              autoFocus
            />
          </form>
        ) : (
          <div
            className={clsx(
              'px-2 w-full h-full flex justify-between text-smaller',
              id === 'title' ? 'show-on-hover-parent' : ''
            )}
          >
            <span>{text}</span>
            {id === 'title' && (
              <button
                onClick={handleOpenClick}
                className="text-xs border rounded px-2 py-0.5 uppercase show-on-hover-child self-center"
              >
                Open
              </button>
            )}
          </div>
        )}
      </div>
    </CellWrapper>
  )
}

// ===== Prevents unnecessary re-rendering ====
const canRender = (prevState, nextState) => {
  return isEqual(prevState.value, nextState.value)
}

export default React.memo(EditableCell, canRender)
