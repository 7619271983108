import Card from 'components/Workspace/ProjectStats/Card'
import clsx from 'clsx'
import {
  TableDataLoading,
  Td,
  Th,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Currency from 'static/Currency/Currency'

const ProjectCostCard = ({ projects, currency, loading }) => {
  return (
    <Card className="h-full w-full">
      <h1 className="text-sm font-semibold text-primary-dark mb-4">
        Cost Per Project
      </h1>
      <div
        className={clsx(`align-middle min-w-full overflow-auto sm:rounded-xl `)}
        style={{
          overflowY: 'auto',
          maxHeight: '300px',
          background: '#fff'
        }}
      >
        <table className="min-w-full divide-y divide-gray-200 border-collapse border-hidden">
          <thead className="bg-white h-14 sticky top-0" style={{ zIndex: 99 }}>
            <Th className="text-left text-gray-900">Project Name</Th>
            <Th center>Cost Input</Th>
            <Th center>Budgeted</Th>
          </thead>
          <tbody>
            {loading ? (
              <TableDataLoading cols={3} />
            ) : (
              projects.map(project => (
                <Tr className="w-full p-2 text-gray-700 text-xs lg:text-smaller rounded cursor-pointer hover:bg-gray-100">
                  <Td className="text-gray-900">{project.name}</Td>
                  <Td center>
                    {project.forecast?.costInputTillNow > 0
                      ? Currency[currency].symbol_native
                      : ''}
                    {project.forecast?.costInputTillNow ?? 0}
                  </Td>
                  <Td center>
                    {project.cost > 0 ? Currency[currency].symbol_native : ''}
                    {project.cost ?? 0}
                  </Td>
                </Tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </Card>
  )
}

export default ProjectCostCard
