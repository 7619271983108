import { ReactComponent as AngleRight } from 'static/svg/angle-right.svg'
import { ReactComponent as AngleLeft } from 'static/svg/angle-left.svg'
import LoopRoundedIcon from '@material-ui/icons/LoopRounded'
import DatePicker from 'antd/lib/date-picker'
import ConfigProvider from 'antd/lib/config-provider'
// import 'antd/dist/antd.css'
import locale from 'antd/es/locale/en_GB'
import moment from 'moment'
import { getUserPath } from 'utils/userRoles'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { CustomTooltip } from 'global/globalComponents'
import { CSVLink } from 'react-csv'
import { ReactComponent as ImportIcon } from 'static/svg/import.svg'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})

function getWeekDays(weekStart) {
  const days = []
  for (let i = 0; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, 'days').toDate().toDateString())
  }
  return days
}

const TimesheetHead = ({
  totalTimeTracked,
  totalUsers,
  elmFor,
  downloadData
}) => {
  const { time: selectedDate, userId } = useParams()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const [capacity, setCapacity] = useState(48)

  useEffect(() => {
    if (meData?._id) {
      setCapacity(
        meData?.profile?.capacity ?? meData?.team?.profile?.capacity ?? 48
      )
    }
  }, [meData])

  const getPrevWeek = () => {
    const date = new Date(selectedDate)
    const prevWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7
    )
    handleUrl(prevWeek)
  }

  const getNextWeek = () => {
    const date = new Date(selectedDate)
    const nextWeek = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 7
    )
    handleUrl(nextWeek)
  }

  const customFormat = value =>
    `${moment(value).startOf('week').format('DD MMM YY')} - ${moment(value)
      .endOf('week')
      .format('DD MMM YY')}`

  const onChange = date => {
    handleUrl(date)
  }

  const handleUrl = date => {
    if (userId && selectedDate) {
      history.push(
        `${getUserPath(meData.role)}/resource-management/timesheets/${moment(
          date
        ).format('YYYY-MM-DD')}/${userId}`
      )
    } else {
      history.push(
        `${getUserPath(meData.role)}/resource-management/timesheets/${moment(
          date
        ).format('YYYY-MM-DD')}`
      )
    }
  }

  return (
    <div className="md:flex items-center justify-between text-smaller text-gray-700 bg-white p-2">
      <div className="flex mb-3 md:mb-0">
        <div className="cursor-pointer flex justify-start items-center">
          <div
            className="h-8 flex items-center rounded-sm hover:bg-gray-100"
            onClick={getPrevWeek}
          >
            <AngleLeft
              className="h-6 w-6 rounded-sm hover:bg-gray-100"
              style={{ fill: 'gray' }}
            />
          </div>
          <div
            className="h-8 flex items-center rounded-sm hover:bg-gray-100"
            onClick={getNextWeek}
          >
            <AngleRight
              className=" h-6 w-6 rounded-sm hover:bg-gray-100"
              style={{ fill: 'gray' }}
            />
          </div>
          <div className="px-2 h-8 flex items-center rounded-sm text-xs lg:text-sm hover:bg-gray-100">
            <ConfigProvider locale={locale}>
              <DatePicker
                defaultValue={
                  moment(new Date()).startOf('week').format('DD MMM YY') -
                  moment(new Date()).endOf('week').format('DD MMM YY')
                }
                value={moment(selectedDate)}
                format={customFormat}
                onChange={onChange}
                picker="week"
                bordered={false}
                allowClear={false}
                style={{
                  width: '205px',
                  fontSize: 13
                }}
              />
            </ConfigProvider>
          </div>
        </div>
        {!getWeekDays(moment(selectedDate).startOf('week').toDate()).includes(
          new Date().toDateString()
        ) && (
          <div
            className="cursor-pointer h-8 flex items-center px-4 rounded-sm  hover:bg-gray-100"
            onClick={() => handleUrl(new Date())}
          >
            <LoopRoundedIcon fontSize="small" />
            This Week
          </div>
        )}
      </div>
      <div className="flex mb-2 md:mb-0">
        <div>
          <span className="text-primary-dark">Time Tracked:</span>
          <span className="ml-2">{totalTimeTracked ?? 0}</span>
        </div>
        <div className="ml-6 mr-6">
          <span className="text-primary-dark">
            {elmFor === 'users' ? 'Team' : ''} Capacity:
          </span>
          <span className="ml-2">{capacity * totalUsers}h</span>
        </div>
        <div className="ml-auto">
          <CustomTooltip title="Download CSV file" placement="top">
            <CSVLink
              data={downloadData.data ?? []}
              filename={`${downloadData.fileName}.csv`}
            >
              <ImportIcon className="w-4 h-4 hover:text-primary-main" />
            </CSVLink>
          </CustomTooltip>
        </div>
      </div>
    </div>
  )
}

export default TimesheetHead
