import axios from 'axios'
import { errorMessages } from 'utils'
import { WIDGET } from './reducer'

export const createDefaultWidgets = async (data) => {
  try {
    const res = await Promise.all(
      data.map((widget) => {
        return axios({
          method: 'POST',
          url: '/widget',
          data: widget
        })
      })
    )
  } catch (err) {
    throw new Error(err)
  }
}

// const createDefaultWidgets1 = async (data) => {
//   try {
//     const res = await Promise.all([
//       axios({
//         method: 'POST',
//         url: '/widget',
//         data: {
//           title: 'projects',
//           attributes: {
//             column_position: 0,
//             row_position: 0,
//             width: 6,
//             height: 7
//           }
//         }
//       }),
//       axios({
//         method: 'POST',
//         url: '/widget',
//         data: {
//           title: 'timesheet',

//           attributes: {
//             column_position: 6,
//             row_position: 0,
//             width: 6,
//             height: 7
//           }
//         }
//       }),
//       axios({
//         method: 'POST',
//         url: '/widget',
//         data: {
//           title: 'tasks',
//           default: true,
//           attributes: {
//             column_position: 0,
//             row_position: 10,
//             width: 12,
//             height: 10
//           }
//         }
//       })
//     ])
//   } catch (err) {
//     throw new Error(err)
//   }
// }

/**
 * Add new widget to the dashboard
 * @param {Object} data
 * @param {Function} callback
 */
export const createNewWidget = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/widget',
        data
      })

      dispatch({ type: WIDGET.ADD_NEW, payload: res.data })
    } catch (err) {
      if (callback) {
        callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE)
      }
      throw new Error(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      )
    }
  }
}

/**
 * Fetch all widgets
 * @param {Object} data
 * @param {Function} callback
 */
export const fetchAllWidgets = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/widget/user'
      })

      // if (res.data.length === 0) {
      //   const res = await createDefaultWidgets() // creating and returning data
      //   dispatch({ type: WIDGET.FETCHED, payload: res })
      // } else {
      // }
      dispatch({ type: WIDGET.FETCHED, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE)
      }
    }
  }
}

/**
 * Update widget data
 * @param {Object} data
 * @param {Function} callback
 */
export const updateWidgetById = (widgetId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/widget/${widgetId}`,
        data
      })

      dispatch({ type: WIDGET.UPDATE_ONE, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE)
      }
    }
  }
}

/**
 * Delete widget
 * @param {Object} data
 * @param {Function} callback
 */
export const deleteWidgetById = (widgetId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/widget/${widgetId}`
      })

      dispatch({ type: WIDGET.REMOVE_ONE, payload: widgetId })
    } catch (err) {
      if (callback) {
        callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE)
      }
    }
  }
}

// /**
//  * Fetch all project's analytics
//  * @param {Function} callback
//  */
// export const fetchAllProjectsAnalytics = (callback) => {
//   return async (dispatch) => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: '/workspace/dashboard/analytics/project'
//       })

//       dispatch({ type: WIDGET.FETCHED_PROJECT_ANALYTICS, payload: res.data })
//       if (callback) callback(res.data)
//     } catch (err) {
//       if (callback) {
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       }
//     }
//   }
// }

/**
 * Fetch all project's analytics
 * @param {Function} callback
 */
export const fetchAllTeammatesAnalytics = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/dashboard/analytics/teammate'
      })

      dispatch({ type: WIDGET.FETCHED_TEAMMATE_TIMESHEET, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Fetch all standups's analytics
 * @param {Function} callback
 */
export const fetchAllStandupsAnalytics = (startDate, endDate, callback) => {
  return async (dispatch) => {
    dispatch({ type: WIDGET.FETCHED_STANDUPS_LOADING, payload: true })
    try {
      const res = await axios({
        method: 'GET',
        url: `/standups/dashboard/analytics/standups?startDate=${startDate}&endDate=${endDate}`
      })
      const arr = []
      res.data.forEach((item) => {
        arr.push(...item.standups)
      })
      dispatch({ type: WIDGET.FETCHED_STANDUPS, payload: arr })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}
