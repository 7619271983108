/**
 * Add Ordinal suffix after a number
 * @param {Number | string} n Number
 * @returns {String} String appended with ordinal suffix
 */
function getNumberWithOrdinal(n) {
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export default getNumberWithOrdinal
