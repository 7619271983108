import React, { useEffect, useState } from 'react'
import {
  // Button,
  List,
  ListItem,
  TextField,
  Popover
  // Box
} from '@material-ui/core'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import { userRoles, errorMessages } from 'utils'
import { useDispatch } from 'react-redux'
import { updateWorkspaceSection } from 'thunks/embed/action'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as ThreeDotsIcon } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as CogIcon } from 'static/svg/cog.svg'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined'
import { PopupMenu } from 'global/globalComponents'

const ColumnHeader = ({
  columnId,
  columnTitle,
  totalTasks,
  dragHandleProps,
  isWorkspaceComplete,
  taskPermission,
  workspacePermission,
  groupBy,
  // handleRenameSection,
  handleDeleteSection,
  handleCreateNewTask,
  meRole,
  toggleEditStatusModal
}) => {
  const dispatch = useDispatch()
  const [editSectionTitle, setEditSectionTitle] = useState(false)
  const [sectionTitle, setSectionTitle] = useState(columnTitle)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setSectionTitle(columnTitle)
  }, [columnTitle])

  const onBlur = () => {
    const newName = sectionTitle.trim()
    toggleEditSectionTitle()

    if (!newName) {
      setSectionTitle(columnTitle)
      return
    }

    if (newName === columnTitle) return // if nothing changed

    dispatch(
      updateWorkspaceSection(
        columnId,
        { label: sectionTitle },
        columnTitle,
        sectionUpdateCallback
      )
    )
  }

  const handleRenameSection = () => {
    console.log('clicked!')
    setAnchorEl(null)

    // text input not showing up (BUG fix)
    setTimeout(() => {
      toggleEditSectionTitle()
    }, 100)
  }

  const sectionUpdateCallback = (res, err) => {
    if (err) {
      setSectionTitle(columnTitle)
      dispatch(fireErrorToaster('error'))
    } else {
      dispatch(fireSuccessToaster('Updated Successfully!'))
    }
  }

  const toggleEditSectionTitle = () => {
    if (isWorkspaceComplete) {
      dispatch(fireErrorToaster(errorMessages.PROJECT_COMPLETE))
      return
    }

    setEditSectionTitle(prev => !prev)
  }

  const handleTaskCategoryName = e => {
    setSectionTitle(e.target.value)
  }

  const handleTogglePopup = e => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  return (
    <>
      {editSectionTitle ? (
        <TextField
          value={sectionTitle}
          onBlur={onBlur}
          onChange={handleTaskCategoryName}
          // inputRef={sectionNameInputRef}
          autoFocus
          style={{ marginRight: 5 }}
        />
      ) : (
        <div {...dragHandleProps} className="flex items-center">
          {groupBy === 'status' && taskPermission?.update && (
            <span className="mr-1">
              <DragIndicatorOutlinedIcon
                fontSize="small"
                className="text-gray-400"
              />
            </span>
          )}
          <div className="flex items-center">
            <h3 className="font-semibold text-sm capitalize">{sectionTitle}</h3>
            <span
              className="mr-2 w-8 h-8 inline-flex items-center justify-center ml-2 text-sm font-bold bg-gray-200 text-primary-dark rounded-full"
              style={{ flexShrink: 0 }}
            >
              {totalTasks}
            </span>
          </div>
        </div>
      )}

      <div className="flex items-center space-x-2">
        {taskPermission?.create && (
          <button
            onClick={() =>
              [userRoles.AGENCY_VIEWER, userRoles.CLIENT_VIEWER].includes(
                meRole
              )
                ? null
                : handleCreateNewTask('top')
            }
            className="w-8 h-8 border rounded-full flex justify-center items-center text-primary-mid-dark"
          >
            <AddIcon className="w-5 h-5" />
          </button>
        )}

        {groupBy === 'status' && workspacePermission.update && (
          <span
            onClick={handleTogglePopup}
            aria-label="More actions for column"
            role="button"
            className="w-8 h-8 border rounded-full flex justify-center items-center text-primary-mid-dark text-lg"
          >
            {/* <ThreeDotsIcon /> */}
            <PopupMenu
              targetComp={
                <button className="w-8 h-8 rounded-full text-primary-mid-dark-2 flex justify-center items-center">
                  <ThreeDotsIcon />
                </button>
              }
              menuItems={[
                // { label: 'Manage Roles', method: openAddVerticalModal },
                {
                  label: 'Rename',
                  icon: <Pen className="w-4 h-4" />,
                  method: handleRenameSection
                },
                {
                  label: 'Edit Statuses',
                  icon: <CogIcon className="w-4 h-4" />,
                  method: toggleEditStatusModal
                },
                {
                  label: 'Delete',
                  icon: <TrashIcon className="w-4 h-4" />,
                  method: handleDeleteSection
                }
              ]}
            />
          </span>
        )}
      </div>

      {/* {groupBy === 'status' && workspacePermission.update && (
        // <Popover
        //   onClose={handleTogglePopup}
        //   anchorEl={anchorEl}
        //   open={Boolean(anchorEl)}
        //   anchorOrigin={{
        //     vertical: 'bottom',
        //     horizontal: 'right'
        //   }}
        //   transformOrigin={{
        //     vertical: 'top',
        //     horizontal: 'right'
        //   }}
        // >
        //   <List dense>
        //     <ListItem
        //       onClick={handleRenameSection}
        //       button
        //       style={{ fontSize: 14 }}
        //     >
        //       <Pen className="w-4 h-4 mr-4" />
        //       <p style={{ fontSize: '14px' }}>Rename</p>
        //     </ListItem>
        //     <ListItem
        //       button
        //       onClick={toggleEditStatusModal}
        //       style={{ fontSize: 14 }}
        //     >
        //       <CogIcon className="w-4 h-4 mr-4" />
        //       <p style={{ fontSize: '14px' }}>Edit Statuses</p>
        //     </ListItem>
        //     <ListItem
        //       button
        //       onClick={handleDeleteSection}
        //       style={{ fontSize: 14 }}
        //     >
        //       <TrashIcon className="w-4 h-4 mr-4" />
        //       <p style={{ fontSize: '14px' }}>Delete</p>
        //     </ListItem>
        //   </List>
        // </Popover>
      )} */}
    </>
  )
}

export default React.memo(ColumnHeader)
