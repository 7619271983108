/**
 *
 * @param {String} url or base64
 * @param {Function} cb optional
 */

const downloadFile = (file, cb) => {
  fetch(file, {
    method: 'GET',
    headers: {
      Accept: '*'
    }
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      console.log('URL', url)
      console.log('File', file)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `FileName${file.slice(file.lastIndexOf('.'))}` //getting file extension from url
      )

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)

      if (cb) cb()
    })
    .catch((err) => {
      if (cb) cb(true)
    })
}

export default downloadFile
