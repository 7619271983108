import axios from 'axios'
import { errorMessages } from 'utils'
import { CLIENT_RAISED_REQUESTS_AGENCY } from './reducer'

/**
 * Fetch raised requests by client
 * @param {Function} callback callback function
 */
export const fetchClientRaisedRequests = (callback) => {
  return async (dispatch) => {
    dispatch({ type: CLIENT_RAISED_REQUESTS_AGENCY.LOADING })

    try {
      const res = await axios({
        url: '/clientrequest/user',
        method: 'GET'
      })

      let sortedClientRequests = res.data
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .sort((item) => {
          if (item.status === 'resolved') return 0
          else if (item.status === 'pending') return -1
          return 1
        })

      dispatch({
        type: CLIENT_RAISED_REQUESTS_AGENCY.FETCHED,
        payload: sortedClientRequests
      })

      if (callback) callback(res.data)
    } catch (err) {
      dispatch({ type: CLIENT_RAISED_REQUESTS_AGENCY.ERROR })
      if (callback) {
        callback(
          err?.response?.data?.message ?? "Couldn't fetch raised requests",
          true
        )
      }
    }
  }
}

/**
 * Update raised request
 * @param {String} id request id
 * @param {object} data
 * @param {Function} callback callback function
 */
export const updateRaisedRequest = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/clientrequest/${id}`,
        data
      })
      dispatch({
        type: CLIENT_RAISED_REQUESTS_AGENCY.UPDATE_ONE,
        payload: res.data
      })
      if (callback) callback(res.data)
    } catch (err) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Fetch comment by request ID
 * @param {String} id request id
 * @param {Function} callback callback function
 */
export const fetchRequetsComments = async (id, callback) => {
  try {
    const res = await axios({
      url: `/comments/clientrequest/${id}`,
      method: 'GET'
    })

    callback(res.data)
  } catch (err) {
    callback(
      err?.response?.data?.message ?? "Couldn't fetch raised requests",
      true
    )
  }
}

/**
 * Add comment in a particular request
 * @param {String} id request id
 * @param {Function} callback callback function
 */
export const addRequestComment = async (data, callback) => {
  try {
    const res = await axios({
      url: `/comments`,
      method: 'POST',
      data
    })

    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}
