import axios from 'axios'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { postTaskLog } from 'thunks/logs/action'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { errorMessages } from 'utils'
import { TASKS } from './reducers'

// async function broadcast() {
//   const d = await fetch(
//     'https://clientvenue-dev2.herokuapp.com/v1/subscription/broadcast',
//     {
//       method: 'GET',
//       headers: {
//         'content-type': 'application/json'
//       }
//     }
//   )
// }

/**
 * Invite a user by email
 * @param {String} email User email
 * @param {Function} callback callback function
 */

export const createTask = (data, callback) => {
  return async (dispatch, getState) => {
    const {
      userWorkspaces: { currentWorkspace }
    } = getState()

    try {
      const res = await axios({
        method: 'POST',
        url: '/task',
        data: data
      })

      dispatch({
        type: TASKS.ADD_ONE_TASK,
        payload: res.data
      })

      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE,
        payload: {
          ...currentWorkspace,
          totalTask: (currentWorkspace.totalTask ?? 0) + 1
        }
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Fetch all tasks of a workspace
 * @param {String} wid Workspace id
 * @param {Function} callback callback functoin
 */
// export const getTaskByWorkspaceId = (wid, callback) => {
//   return async (dispatch) => {
//     dispatch({ type: TASKS.LOADING, payload: { all: true } })
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/task/workspace/${wid}`
//       })
//       if (callback) callback(res.data, false)
//       dispatch({ type: TASKS.FETCHED, payload: res.data })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       // dispatch({
//       //   type: TASKS.FETCHED,
//       //   payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
//       // })
//     }
//   }
// }

/**
 * Fetch all tasks of a board
 * @param {String} boardId board id
 * @param {Function} callback callback function
 */
// export const getTaskByWorkspaceBoardId = (boardId, callback, query = '') => {
//   return async dispatch => {
//     dispatch({ type: TASKS.LOADING, payload: { all: true } })
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `/task/board/${boardId}${query}`
//       })
//       if (callback) callback(res.data, false)
//       dispatch({ type: TASKS.FETCHED, payload: res.data })
//     } catch (err) {
//       if (callback)
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       // dispatch({
//       //   type: TASKS.FETCHED,
//       //   payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
//       // })
//     }
//   }
// }

/**
 * Fetch all tasks of a particular section
 * @param {Object} data workspace, workspaceBoard, section, myApprovals(optional), search(optional)
 * @param {Object} query view, type, limit, page
 * @param {Function} callback callback function
 */
export const getSectionTasks = (data, query, sectionId, groupBy, callback) => {
  data = data.filters
    ? data
    : {
        ...data,
        filters: {
          op: 'AND',
          fields: []
        }
      }

  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/task/section?view=${query.view}&type=${query.type}&limit=${query.limit}&page=${query.page}`,
        data
      })

      dispatch({
        type:
          query.page === 0
            ? TASKS.FETCHED_SECTION_TASKS
            : TASKS.LOAD_MORE_SECTION_TASKS,
        payload: { sectionId, tasks: res.data, groupBy }
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch group by assignee sections
 * @param {String} id board id
 * @param {Function} callback callback function
 */
export const getAssigneeSections = (boardId, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/task/board/assignee/section/${boardId}`
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch all tasks of list view
 * @param {Object} data workspace, workspaceBoard, myApprovals
 * @param {Object} query type, limit, page
 * @param {Function} callback callback function
 */
export const getListViewTasks = (data, query, callback) => {
  data = data.filters
    ? data
    : {
        ...data,
        filters: {
          op: 'AND',
          fields: []
        }
      }
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/task/section?view=list&type=${query.type}&limit=${query.limit}&page=${query.page}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type:
          query.page === 0
            ? TASKS.FETCHED_LIST_VIEW_TASKS
            : TASKS.LOAD_MORE_LIST_VIEW_TASKS,
        payload: res.data
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch all tasks of calendar view
 * @param {Object} data workspace, workspaceBoard, startDate, endDate, myApprovals
 * @param {Object} query type, limit, page
 * @param {Function} callback callback function
 */
export const getCalendarViewTasks = (data, query, callback) => {
  data = data.filters
    ? data
    : {
        ...data,
        filters: {
          op: 'AND',
          fields: []
        }
      }

  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/task/section?view=calendar&type=${query.type}&limit=${query.limit}&page=${query.page}`,
        data
      })

      dispatch({
        type: TASKS.FETCHED_CALENDAR_VIEW_TASKS,
        payload: res.data.data
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete a task
 * @param {string} Id task id
 * @param {Function} callback callback function
 */

export const deleteTaskByID = (taskId, callback) => {
  return async (dispatch, getState) => {
    const {
      userWorkspaces: { currentWorkspace }
    } = getState()

    try {
      const res = await axios({
        method: 'DELETE',
        url: `/task/${taskId}`
      })
      // dispatch({
      //   type: WORKSPACES.UPDATE_ONE_WORKSPACE,
      //   payload: filterDeletedTask(
      //     allWs.find((i) => i._id === task.workSpace),
      //     task.taskSection,
      //     task._id
      //   )
      // })
      // const board = userWorkspaceEmbed.data.find(
      //   (item) => item._id === res.data.workspaceBoard
      // )
      // const boardClone = { ...board }
      // boardClone.boardSection = [...boardClone.boardSection]
      // const sectionIndex = boardClone.boardSection.findIndex(
      //   (item) => item._id === res.data.taskSection
      // )

      // boardClone.boardSection[sectionIndex].taskOrder = boardClone.boardSection[
      //   sectionIndex
      // ].taskOrder.filter((taskId) => taskId !== res.data._id)

      // dispatch({ type: EMBEDS.UPDATE, payload: boardClone })
      dispatch({ type: TASKS.DELETE_ONE_TASK, payload: taskId })

      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE,
        payload: {
          ...currentWorkspace,
          totalTask: (currentWorkspace.totalTask || 1) - 1,
          completedTask: res.data.markAsComplete
            ? (currentWorkspace.completedTask || 1) - 1
            : currentWorkspace.completedTask ?? 0
        }
      })

      if (callback) callback(res.data, false)
    } catch (err) {
      // dispatch({type: , payload:err.response.data.message})
      if (callback)
        callback(
          err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete a teammate from a task
 * @param {String} task_id task id
 * @param {Object} data teammate data
 * @param {Function} callback callback function
 */
export const deleteTaskDetailsteam = (taskId, data, callback) => {
  return async (dispatch, getState) => {
    const {
      userWorkspaces: { currentWorkspace },
      userTeammates,
      userClients
    } = getState()
    try {
      const res = await axios({
        method: 'PUT',
        url: `/task/deleteteammate/${taskId}`,
        data
      })

      const obj = data.assignedAgencyTeam
        ? userTeammates.data.find(el => el._id === data.assignedAgencyTeam)
        : userClients.data.find(el => el._id === data.assignedClientTeam)

      dispatch(
        createAccountLogs({
          description: `removed <strong>${
            obj ? obj.name : 'a member'
          }</strong> from the task <strong>${
            res.data.title
          }</strong> in the project <strong>${
            currentWorkspace?.name
          }</strong> `,
          category: 'task',
          task: res.data._id
        })
      )
      dispatch(
        postTaskLog({
          description: `removed`,
          activityType: 'assignment',
          category: 'task',
          task: res.data._id,
          assignedUser: data.assignedAgencyTeam ?? data.assignedClientTeam,
          workSpace: res.data.workSpace
        })
      )
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

// export const fetchAllSubTasks = (callback) => {
//   return async (dispatch) => {
//     dispatch({ type: TASKS.LOADING, payload:{all:true}  })
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: '/workspace/user'
//       })
//       if (callback) callback(res.data, false)
//       dispatch({ type: TASKS.FETCHED, payload: res.data })
//     } catch (err) {
//       if (callback)
//         callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
//       dispatch({
//         type: TASKS.FETCHED,
//         payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
//       })
//     }
//   }
// }

/**
 * Get priority tasks of a user with start date and end date (tasks which are due soon)
 * @param {Function} callback callback function that
 */
export const getPrioritizedTaskWithDates = (endDate, startDate, callback) => {
  return async dispatch => {
    dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    try {
      const res = await axios({
        method: 'GET',
        url: `/task/prioritytask?startDate=${startDate}&endDate=${endDate}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.FETCHED_PRIORITY_TASKS, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      // dispatch({
      //   type: TASKS.FETCHED,
      //   payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      // })
    }
  }
}

/**
 * Get priority tasks of a user (tasks which are due soon)
 * @param {Function} callback callback function that
 */
export const getPrioritizedTask = callback => {
  return async dispatch => {
    dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    try {
      const res = await axios({
        method: 'GET',
        url: `/task/prioritytask`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.FETCHED_PRIORITY_TASKS, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      // dispatch({
      //   type: TASKS.FETCHED,
      //   payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      // })
    }
  }
}

/**
 * Fetch single task details by id
 * @param {String} taskId task id
 * @param {Function} callback callback function
 */
export const fetchTaskById = (taskId, callback) => {
  return async dispatch => {
    // dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    try {
      const res = await axios({
        method: 'GET',
        url: `/task/${taskId}`
      })
      if (callback) callback(res.data)
      dispatch({ type: TASKS.ADD_ONE_TASK, payload: res.data })
      // dispatch({ type: TASKS.SET_CURRENT_TASK, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}
/**
 * Update task data
 * @param {String} taskId task id
 * @param {{}} data Task object
 * @param {Function} callback callback function
 */
export const updateTaskDetails = (taskId, data, callback) => {
  return async (dispatch, getState) => {
    const {
      userWorkspaces: { currentWorkspace }
    } = getState()
    // dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/task/${taskId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
      if (data.markAsComplete !== undefined) {
        dispatch({
          type: WORKSPACES.UPDATE_ONE_WORKSPACE,
          payload: {
            ...currentWorkspace,
            completedTask: data.markAsComplete
              ? (currentWorkspace.completedTask ?? 0) + 1
              : (currentWorkspace.completedTask || 1) - 1
          }
        })
      }
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

export const updateTaskSection = (taskId, data, section, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/task/${taskId}`,
        data
      })

      if (callback) callback(res.data, false)

      dispatch({
        type: TASKS.UPDATE_TASK_SECTION,
        payload: { taskId, section }
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateTaskPosition = (taskId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/task/position/${taskId}`,
        data
      })
      dispatch({ type: TASKS.UPDATE_POSITION, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * update a task assigned team
 * @param {String} taskId task id
 * @param {Object} data  assigned team id
 * @param {Function} callback callback function
 */

export const updateTaskDetailsteam = (taskId, data, callback) => {
  return async (dispatch, getState) => {
    const {
      userWorkspaces: { currentWorkspace },
      userTeammates,
      userClients
    } = getState()

    try {
      const res = await axios({
        method: 'PUT',
        url: `/task/addteammate/${taskId}`,
        data
      })
      if (callback) callback(res.data, false)
      const obj = data.assignedAgencyTeam
        ? userTeammates?.data.find(el => el._id === data.assignedAgencyTeam)
        : userClients?.data.find(el => el._id === data.assignedClientTeam)

      dispatch(
        createAccountLogs({
          description: `assigned <strong>${
            obj ? obj.name : 'a member'
          }</strong> to the task <strong>${
            res.data.title
          }</strong> in the project <strong>${
            currentWorkspace?.name
          }</strong> `,
          category: 'task',
          task: res.data._id
        })
      )
      dispatch(
        postTaskLog({
          description: `assigned`,
          activityType: 'assignment',
          category: 'task',
          task: res.data._id,
          assignedUser: data.assignedAgencyTeam ?? data.assignedClientTeam,
          workSpace: res.data.workSpace
        })
      )
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

// /**
//  * update a task assigned team
//  * @param {String} taskId task id
//  * @param {Object} data  assigned team id
//  * @param {Function} callback callback function
//  */
export const deleteTaskCollaborator = (taskId, data, callback) => async (
  dispatch,
  getState
) => {
  const {
    userWorkspaces: { currentWorkspace },
    userTeammates,
    userClients
  } = getState()
  try {
    const res = await axios({
      method: 'PUT',
      url: `/task/deleteteammate/${taskId}`,
      data
    })

    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
    if (callback) callback(res.data)
    const obj = data.assignedAgencyTeam
      ? userTeammates.data.find(el => el._id === data.assignedAgencyTeam)
      : userClients.data.find(el => el._id === data.assignedClientTeam)

    dispatch(
      createAccountLogs({
        description: `removed <strong>${
          obj ? obj.name : 'a member'
        }</strong> from the task <strong>${
          res.data.title
        }</strong> in the project <strong>${currentWorkspace?.name}</strong> `,
        category: 'task',
        task: res.data._id
      })
    )
    dispatch(
      postTaskLog({
        description: `removed`,
        activityType: 'assignment',
        category: 'task',
        task: res.data._id,
        assignedUser: data.assignedAgencyTeam ?? data.assignedClientTeam,
        workSpace: res.data.workSpace
      })
    )
  } catch (err) {
    const message = err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
    if (callback) callback(message, true)
  }
}

/**
 * Update task data
 * @param {String} taskId task id
 * @param {{}} data Task object
 * @param {Function} callback callback function
 */
export const updateTaskPriority = (taskId, data, callback) => {
  return async dispatch => {
    // dispatch({ type: TASKS.LOADING, payload: { priority: true } })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/task/taskPriority/${taskId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )

      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

// /**
//  * @typedef {Object} Point
//  * @property {String} workSpace - Current workspace id
//  * @property {String} task - task id
//  * @property {String} file - File in base64 format
//  */

/**
 * upload a task attachment
 * @param {Object} data Task data
 * @param {String} data.workSpace workSpace id
 * @param {String} data.task task id
 * @param {String} data.file file in base64 format
 * @param {Function} callback callback function
 */
export const uploadAttachment = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/task/attachment`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.UPLOAD_ATTACHMENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * delete a task attachment
 * @param {String} id task id
 * @param {Object} url  attachment url
 * @param {Function} callback callback function
 */
export const deleteAttachment = (id, url, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/task/attachment?task=${id}&url=${url}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
      dispatch(
        postTaskLog({
          description: `deleted an attachment in the task <b>${res.data.title}</b>`,
          activityType: 'log',
          task: id,
          workSpace: res.data.workSpace
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch all logs of a taskks
 * @param {String} taskId task id
 * @param {Function} callback callback function
 */
export const fetchTaskLogs = (taskId, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/log/task/${taskId}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.FETCH_LOGS, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Update tasks order on dragging and dropping
 * @param {Object} data Source section tasks and destination section tasks
 * @param {Function} callback  callback funtion(response/error, errorStatus)
 */
export const redorderTasks = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `workspacesection/taskorder`,
        data
      })
      // dispatch({ type: TASKS.REORDER_TASKS, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update task sharing link
 * @param {String} id subTask id
 * @param {{}} taskId  sharing
 * @param {Function} callback callback function
 */
export const shareTask = (id, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `task/updatesharing/${id}`,
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

export const deletOneTaskByPusher = (task, callback) => {
  return dispatch => {
    dispatch({ type: TASKS.DELETE_ONE_TASK, payload: task._id })
  }
}

export const addOneTaskByPusher = newTask => {
  return (dispatch, getState) => {
    const { userTasks } = getState()

    // const match = userTasks.data.find(item => item._id === newTask._id)

    if (!userTasks.data[newTask._id]) {
      dispatch({
        type: TASKS.ADD_ONE_TASK,
        payload: newTask
      })
    }
  }
}

export const pusherUpdateOneTask = data => {
  return dispatch => {
    dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: data })
  }
}

// /**
//  * Get ALL Pending Tasks From Other Boards
//  * @param {String} id workspace id
//  * @param {String} id board id
//  * @param {Function} callback callback function
//  */
// export const fetchAllPendingTasks = (workspaceId, boardId, callback) => {
//   return async (dispatch) => {
//     try {
//       const res = await axios({
//         method: 'GET',
//         url: `task/workspace/board/${workspaceId}/${boardId}`
//       })

//       dispatch({ type: TASKS.ADD_PENDING_TASKS, payload: res.data })
//       if (callback) callback(res.data, false)
//     } catch (err) {
//       if (callback) {
//         callback(
//           err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
//           true
//         )
//       }
//     }
//   }
// }

/**
 * Get ALL data types Tasks From Other Boards
 * @param {Function} callback callback function
 */
export const fetchAllDataTypes = async callback => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/datatype'
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Create new column in task table
 * @param {Object} data name, board, workspace, datatypeId
 * @param {Function} callback callback function
 */
export const createNewColumn = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/tableColumn',
      data
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch all task table columns
 * @param {String} Id board id
 * @param {Function} callback callback function
 */
export const fetchTaskTableColumns = async (id, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/tablecolumn/board/${id}`
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch all task table columns
 * @param {String} Id board id
 * @param {Function} callback callback function
 */
export const deleteTaskColumn = async (id, callback) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/tablecolumn/${id}`
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Update task (in table)
 * @param {String} id task id
 * @param {String} id Workspace Id
 * @param {Array} data tableColumnId, value
 * @param {Function} callback callback function
 */
export const updateDynamicField = (id, wsId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/task/dynamic/${id}`,
        data: {
          workSpace: wsId,
          dynamicField: data
        }
      })

      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Upload attachment from dynamic col (table)
 * @param {String} id task id
 * @param {String} id Workspace Id
 * @param {Array} data tableColumnId, value
 * @param {Function} callback callback function
 */
export const uploadDynamicColAttachment = (taskId, wsId, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'PUT',
        url: '/task/dynamic/attachment',
        data: {
          workSpace: wsId,
          task: taskId,
          dynamicField: data
        }
      })

      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete attachment from dynamic col (table)
 * @param {String} id task id
 * @param {String} url img url
 * @param {String} id column id
 * @param {Function} callback callback function (optional)
 */
export const deleteDynamicColAttachment = (id, url, columnId, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/task/dynamic/attachment?task=${id}&url=${url}&taskColumnId=${columnId}`
      })

      if (callback) callback(res.data, false)
      dispatch({ type: TASKS.UPDATE_ONE_TASK, payload: res.data })
      // dispatch(
      //   postTaskLog({
      //     description: 'updated this task',
      //     activityType: 'log',
      //     task: id
      //   })
      // )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TASKS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Fetch all task attachments by workspace id
 * @param {String} id workspace Id
 * @param {Function} callback callback function
 */
export const fetchAllTaskAttachmentsByWorkspace = async (
  workspaceId,
  callback
) => {
  try {
    const res = await axios({
      url: `/fileassetsfile/alltask/${workspaceId}`,
      method: 'GET'
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

export const moveSectionTask = ({
  sourceIndex,
  destinationIndex,
  sourceId,
  destinationId,
  taskId,
  groupBy
}) => {
  return {
    type: TASKS.MOVE_SECTION_TASK,
    payload: {
      sourceIndex,
      destinationIndex,
      sourceId,
      destinationId,
      taskId,
      groupBy
    }
  }
}

export const addTaskInSection = ({ sectionId, top, taskId, groupBy }) => {
  return {
    type: TASKS.ADD_TASK_IN_SECTION,
    payload: { sectionId, top, taskId, groupBy }
  }
}
export const addTaskInList = taskId => {
  return { type: TASKS.ADD_TASK_IN_LIST, payload: { taskId } }
}

export const addTaskInCalendar = taskId => {
  return { type: TASKS.ADD_TASK_IN_CALENDAR, payload: { taskId } }
}

export const removeTaskFromSection = ({ taskId, sectionId, groupBy }) => {
  return {
    type: TASKS.REMOVE_TASK_FROM_SECTION,
    payload: { taskId, sectionId, groupBy }
  }
}

export const removeTaskFromList = ({ taskId }) => {
  return {
    type: TASKS.REMOVE_TASK_FROM_LIST_VIEW,
    payload: { taskId }
  }
}

export const removeTaskFromCalendar = ({ taskId }) => {
  return {
    type: TASKS.REMOVE_TASK_FROM_CALENDAR_VIEW,
    payload: { taskId }
  }
}
