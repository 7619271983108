import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomTooltip, RefreshButton } from 'global/globalComponents'
import { Skeleton } from '@material-ui/lab'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Moment from 'react-moment'
import Pill from 'global/globalComponents/Pill/Pill'
import CancelRequestsModal from './CancelRequestsModal'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { fetchCancelServiceRequests } from 'thunks/cancelClientServiceRequest/actions'
import Currency from 'static/Currency/Currency'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

const totalReqToShow = 8
const CancelRequests = ({ search, dateFormatter }) => {
  const dispatch = useDispatch()
  const cancelClientServiceReq = useSelector(
    state => state.cancelClientServiceRequest
  )
  const cancelClientServiceReqData = cancelClientServiceReq.data
  const [cancelReqModal, setCancelReqModal] = useState({
    open: false,
    data: {}
  })
  const [visibleRequests, setVisibleRequests] = useState([])
  const [page, setPage] = useState(0)
  const [refreshing, setRefreshing] = useState(false)

  useEffect(() => {
    if (!cancelClientServiceReq.fetched) {
      dispatch(fetchCancelServiceRequests())
    }
  }, [cancelClientServiceReq.fetched])

  useEffect(() => {
    if (cancelClientServiceReqData.length) {
      const filteredData = cancelClientServiceReqData.filter(item => {
        return (
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.user?.name.toLowerCase().includes(search.toLowerCase())
        )
      })

      setVisibleRequests(
        filteredData.splice(page * totalReqToShow, totalReqToShow)
      )
    } else {
      setVisibleRequests([])
    }
  }, [cancelClientServiceReqData, page, search])

  const openCancelReqModal = data => {
    setCancelReqModal({
      open: true,
      data
    })
  }

  const closeCancelReqModal = data => {
    setCancelReqModal({
      open: false,
      data: {}
    })
  }

  const refreshData = () => {
    if (refreshing) return

    setRefreshing(true)
    dispatch(
      fetchCancelServiceRequests((err, res) => {
        setRefreshing(false)
      })
    )
  }

  return (
    <div>
      <div className="text-right mb-6">
        <RefreshButton refreshing={refreshing} onClick={refreshData} />
      </div>
      <TableContainer mb={6}>
        <Thead>
          {/* <Th></Th> */}
          <Th className="whitespace-nowrap">Client</Th>
          <Th className="whitespace-nowrap">Service</Th>
          {/* <Th className="text-center">Service Type</Th> */}
          <Th className="whitespace-nowrap">Date</Th>
          {/* <Th className="text-left">Client Name</Th> */}
          <Th className="text-right">Amount</Th>
        </Thead>
        <Tbody>
          {cancelClientServiceReq.loading ? (
            <TableDataLoading cols={4} />
          ) : visibleRequests.length > 0 ? (
            visibleRequests.map(item => (
              <Row
                key={item._id}
                item={item}
                openCancelReqModal={openCancelReqModal}
                dateFormatter={dateFormatter}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <td colSpan="5" className=" py-3 px-6 text-gray-500 font-normal">
                There are no requests
              </td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {!cancelClientServiceReq.loading &&
        cancelClientServiceReqData.length > totalReqToShow && (
          <Pagination
            handlePagination={setPage}
            totalResultsCount={cancelClientServiceReqData.length}
            visibleResultsCount={visibleRequests.length}
            page={page}
            limit={totalReqToShow}
          />
        )}

      {cancelReqModal.open && (
        <CancelRequestsModal
          open={cancelReqModal.open}
          onClose={closeCancelReqModal}
          data={cancelReqModal.data}
        />
      )}
    </div>
  )
}

const Row = ({ item, openCancelReqModal, dateFormatter }) => (
  <Tr
    key={item._id}
    onClick={() => openCancelReqModal(item)}
    className="cursor-pointer"
  >
    <Td className=" px-6 py-4 whitespace-nowrap text-gray-900">
      <div className="text-custom-table-primaryText">
        {/* <div>{item.user.name}</div> */}
        <div className="flex items-end">
          <span className={!item?.user?.name ? 'text-gray-400' : 'capitalize'}>
            {item?.user?.name || 'No name'}
          </span>
          {!item?.user?.isActive && item?.user?.isDeleted && (
            <span>
              <Pill variant={'red'} className="ml-1">
                Inactive
              </Pill>
            </span>
          )}
        </div>
      </div>
    </Td>
    <Td className="whitespace-nowrap truncate text-xs text-gray-500">
      <div>
        <span className="text-xs text-gray-500">{item.name}</span>
        <Pill
          variant={item.type === 'one_time' ? 'blue' : 'red'}
          className="ml-1"
        >
          {item.type}
        </Pill>
      </div>
    </Td>
    <Td className="whitespace-nowrap text-xs">
      <Moment format="MMM D, YYYY" date={dateFormatter(item.updatedAt)} />
    </Td>
    {/* <Td
      className="whitespace-nowrap truncate text-gray-900 text-xs"
      style={{ maxWidth: '10px' }}
    >
      {item.user.name}
    </Td> */}
    <Td className="text-right text-xs">
      <span>
        {item.payment_plan.currency &&
          Currency[item.payment_plan.currency.toUpperCase()]?.symbol_native}
        {item?.amount * 0.01}
      </span>
    </Td>
  </Tr>
)

export default CancelRequests
