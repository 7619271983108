import { useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress } from '@material-ui/core'
import { Check } from '@material-ui/icons'

const FilesUploadLoading = ({ items, open, onClose }) => {
  const styles = { transform: open ? 'none' : 'translateY(100%)' }
  const [uploading, setUploading] = useState(true)

  useEffect(() => {
    const allLoaded = items.every((item) => !item.loading)
    setUploading(!allLoaded)
  }, [items])

  return (
    <div
      style={styles}
      className="w-60 bg-white rounded shadow-xl fixed bottom-0 right-4 border border-gray-300 shadow-lg transition"
    >
      <div className="border-b-2 border-gray-300 px-4 py-3 flex justify-between items-center">
        <div>
          {uploading
            ? `Uploading ${items.length} ${
                items.length > 1 ? 'items' : 'item'
              }...`
            : 'Upload successful!'}
        </div>
        <button onClick={onClose}>
          <CloseIcon fontSize="small" />
        </button>
      </div>
      <div className="px-4">
        {items.map((item, index) => (
          <div key={index} className="flex items-center justify-between">
            <p className="py-2">{item.name}</p>
            <div>
              {item.loading ? (
                <CircularProgress size={15} />
              ) : (
                <Check fontSize="small" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilesUploadLoading
