import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { Button } from '@material-ui/core'
import UpdateCardModal from 'components/Settings/Subscription/UpdateCardModal'
import { useState } from 'react'

const AccountBlockedModal = ({ open, payNowLink }) => {
  const [isCardModalOpen, setIsCardModalOpen] = useState(false)

  const toggleCardModal = () => {
    setIsCardModalOpen((prev) => !prev)
  }

  return (
    <CustomModal open={open} size="small">
      <CustomModalBody>
        {/* <div className="p-8" style={{ height: '70vh' }}> */}
        <div className="w-full text-2xl font-semibold py-4 text-gray-700 text-center">
          Account locked
        </div>
        <div
          className="w-full text-sm font-medium py-2 px-2 rounded"
          style={{ backgroundColor: '#f6c6bd', color: '#8f4204' }}
        >
          Too many failed payment attempts. Account locked.
        </div>
        {/* </div> */}
      </CustomModalBody>

      <CustomModalFooter>
        {payNowLink && (
          <>
            <a
              href={payNowLink}
              // target="_blank"
              // className="ml-4 bg-white text-primary-main px-4 py-2 rounded
              //         font-medium text-xs"
            >
              <Button>Pay Now</Button>
            </a>
            <Button variant="outlined" onClick={toggleCardModal}>
              Update Payment Method
            </Button>
          </>
        )}
      </CustomModalFooter>
      <UpdateCardModal
        addingNewCard={true}
        open={isCardModalOpen}
        handleClose={toggleCardModal}
      />
    </CustomModal>
  )
}

export default AccountBlockedModal
