import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, TextField, Button } from '@material-ui/core'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { LoadingButton } from 'global/globalComponents'
import { userRoles } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useDispatch, useSelector } from 'react-redux'
import { createTeamVerticals } from 'thunks/Verticals/action'
import Select from 'react-select'
import { getRoleBadge } from 'utils'
import VerticalsTable from './VerticalsTable'

const VerticalsModal = ({ open, onClose, addNewVertical }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const userTeammates = useSelector((state) => state.userTeammates.data)
  const verticals = useSelector((state) => state.teamVerticals.data)
  const [selectTeammatesOptions, setSelectTemmatesOptions] = useState([])
  const [verticalData, setVerticalData] = useState({
    name: '',
    description: 'role',
    assignedTeammates: []
  })

  const optionLabel = (assignee) => {
    return (
      <div className="flex">
        <span className="">
          {assignee.name}{' '}
          <span className="pl-2">
            {assignee.role ? getRoleBadge(assignee.role, 'py-0.5') : ''}
          </span>
        </span>
      </div>
    )
  }

  useEffect(() => {
    if (userTeammates.length) {
      const options = userTeammates
        .filter(
          (mate) =>
            mate.role !== userRoles.AGENCY_ADMIN &&
            (!mate.verticals || mate.verticals?.length === 0)
        )
        .map((item) => ({
          label: item.name,
          value: item._id,
          ...item
        }))
      setSelectTemmatesOptions(options)
    }
  }, [userTeammates])

  const handleTabChange = (e, newVal) => {
    console.log(newVal, 'new Value')
    setSelectedTab(newVal)
  }

  const inputDataHandler = (e) => {
    const { name, value } = e.target
    setVerticalData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSelectTeammates = (e) => {
    setVerticalData((prev) => ({
      ...prev,
      assignedTeammates: e
    }))
  }

  const handleCreateVertical = async (e) => {
    e.preventDefault()

    if (!verticalData.name)
      return dispatch(fireErrorToaster('Please enter the role name!'))

    const match = verticals.find(
      (item) => item.name.toLowerCase() === verticalData.name.toLowerCase()
    )

    if (match)
      return dispatch(fireErrorToaster(`"${match.name}" already exists!`))

    const bodyData = {
      name: verticalData.name,
      description: verticalData.description,
      newTeammates: [],
      type: 'vertical'
    }

    if (verticalData.assignedTeammates.length) {
      bodyData.newTeammates = verticalData.assignedTeammates.map(
        (item) => item.value
      )
    }

    setLoading(true)
    dispatch(
      createTeamVerticals(bodyData, (res, err) => {
        setLoading(false)
        if (err) {
          dispatch(fireErrorToaster(res))
        } else {
          setVerticalData({
            name: '',
            description: '',
            assignedTeammates: []
          })
          dispatch(fireSuccessToaster('New Role Created Successfully!'))
          addNewVertical({ ...res, label: res.name, value: res._id })
          handleCloseModal()
        }
      })
    )
  }

  const handleCloseModal = () => {
    setLoading(false)
    setSelectedTab(0)
    setVerticalData({
      name: '',
      description: '',
      assignedTeammates: []
    })
    onClose()
  }

  return (
    <CustomModal open={open} handleClose={handleCloseModal}>
      <CustomModalHeader heading={'Manage Roles'} />
      <CustomModalBody style={{ minHeight: '150px' }}>
        <div className="mb-6">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            {['Create Role', 'Roles'].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
        </div>

        {selectedTab === 1 ? (
          <VerticalsTable />
        ) : (
          <form onSubmit={handleCreateVertical}>
            <div className="mb-4">
              <FormLabel htmlFor="name" required>
                Role Name
              </FormLabel>

              <TextField
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder="Enter name"
                id="name"
                name="name"
                value={verticalData.name}
                onChange={inputDataHandler}
              />
            </div>
            {/* <div className="mb-4">
              <FormLabel htmlFor="description" required>
                Role Description
              </FormLabel>

              <TextField
                className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
                placeholder="Enter description"
                id="description"
                multiline={true}
                rows={2}
                name="description"
                value={verticalData.description}
                onChange={inputDataHandler}
              />
            </div> */}
            {/* <div className="mb-4">
              <FormLabel>Assign Users</FormLabel>
              <Select
                menuPlacement="top"
                menuPosition={'fixed'}
                placeholder="Select Teammates"
                isSearchable={true}
                onChange={handleSelectTeammates}
                isMulti
                value={verticalData.assignedTeammates}
                options={selectTeammatesOptions}
                styles={reactSelectCustomStyles}
                maxMenuHeight="120px"
                getOptionLabel={optionLabel}
              />
            </div> */}
          </form>
        )}
      </CustomModalBody>

      <CustomModalFooter>
        {selectedTab === 0 && (
          <LoadingButton loading={loading} onClick={handleCreateVertical}>
            Create
          </LoadingButton>
        )}
        <Button variant="outlined" onClick={handleCloseModal}>
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default VerticalsModal
