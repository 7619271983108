export const WORKSPACES = {
  LOADING: 'ALL_WORKSPACES_LOADING',
  FETCHED: 'ALL_WORKSPACES_FETCHED',
  ADD_ONE_WORKSPACE: 'ADD_ONE_WORKSPACE',
  UPDATE_ONE_WORKSPACE: 'UPDATE_ONE_WORKSPACE',
  DELETE_ONE_WORKSPACE: 'DELETE_ONE_WORKSPACE',
  ERROR: 'ALL_WORKSPACES_ERROR',
  CREATE_NEW_SECTION: 'CREATE_NEW_WORKSPACE_SECTION',
  ADD_OPENED_WORKSPACE: 'ADD_OPENED_WORKSPACE',
  ASSIGN_TEAMMATE: 'ASSIGN_WORKSPACE_TEAMMATE',
  UPDATE_ONE_WORKSPACE_SECTION: 'UPDATE_ONE_WORKSPACE_SECTION',
  UPDATE_SECTION_ORDERS: 'UPDATE_SECTION_ORDERS',
  TOGGLE_ARCHIVE: 'TOGGLE_ARCHIVE',
  DELETE_ONE_WORKSPACE_SECTION: 'DELETE_ONE_WORKSPACE_SECTION',
  UPDATE_TASK_ORDER: 'UPDATE_TASK_ORDER',
  UNASSIGN_PERSON: 'UNASSIGN_PERSON_FROM_WORKSPACE',
  UPDATE_MANY_WORKSPACES: 'UPDATE_MANY_WORKSPACES',
  PUSHER_ADD_ONE_WORKSPACE: 'PUSHER_ADD_ONE_WORKSPACE',
  PUSHER_DELETE_ONE_WORKSPACE: 'PUSHER_DELETE_ONE_WORKSPACE',
  ADD_CURRENT_BOARD: 'WORKSPACES_ADD_CURRENT_BOARD',
  CHANGE_DEFAULT_BOARD: 'WORKSPACES_CHANGE_DEFAULT_BOARD'
}

const initialState = {
  error: false,
  loading: true,
  data: [],
  fetchedFirstTime: false,
  currentWorkspace: {},
  currentBoard: {}
}

const workspacesReducer = (state = initialState, action) => {
  // console.log('action----->>>', {state,action})
  // const currIndex = state.data.findIndex(i=> i._id)
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case WORKSPACES.LOADING:
      return { ...state, loading: true, error: false }

    case WORKSPACES.ERROR:
      return { ...state, loading: false, error: action.payload }

    case WORKSPACES.FETCHED:
      return {
        ...state,
        loading: false,
        data: action.payload,
        fetchedFirstTime: true
      }

    case WORKSPACES.ADD_ONE_WORKSPACE: {
      const isAvailable = state.data.find((i) => i._id === action.payload._id)
      if (isAvailable !== undefined) {
        return state
      } else {
        return {
          ...state,
          data: [action.payload, ...state.data]
        }
      }
    }

    case WORKSPACES.UPDATE_ONE_WORKSPACE:
      const ind = state.data.findIndex(
        (item) => item._id === action.payload?._id
      )
      const newData = [...state.data]
      newData[ind] = { ...newData[ind], ...action.payload }
      return {
        ...state,
        loading: false,
        data: newData,
        currentWorkspace:
          state.currentWorkspace &&
          state.currentWorkspace._id === action.payload?._id
            ? { ...state.currentWorkspace, ...action.payload }
            : state.currentWorkspace
      }

    case WORKSPACES.UNASSIGN_PERSON:
      return {
        ...state,
        loading: false,
        data: updateOneObjectFromArray(state.data, action.payload)
      }

    case WORKSPACES.DELETE_ONE_WORKSPACE:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }

    case WORKSPACES.CREATE_NEW_SECTION:
      const updatedData = [...state.data]
      updatedData[
        getCurrentIndex(action.payload.workSpace)
      ].workspaceSection.push(action.payload)
      return {
        ...state,
        loading: false,
        data: updatedData,
        currentWorkspace: {
          ...state.currentWorkspace,
          workspaceSection: [
            ...state.currentWorkspace.workspaceSection,
            action.payload
          ]
        }
      }

    case WORKSPACES.UPDATE_ONE_WORKSPACE_SECTION:
      const updatingData = [...state.data]
      const updatingCurrentWorkspace = { ...state.currentWorkspace }
      updatingCurrentWorkspace.workspaceSection[
        updatingCurrentWorkspace.workspaceSection.findIndex(
          (i) => i._id === action.payload._id
        )
      ] = action.payload

      updatingData[
        getCurrentIndex(updatingCurrentWorkspace._id)
      ] = updatingCurrentWorkspace
      return {
        ...state,
        loading: false,
        data: updatingData,
        currentWorkspace: updatingCurrentWorkspace
      }

    case WORKSPACES.ADD_OPENED_WORKSPACE:
      return {
        ...state,
        loading: false,
        currentWorkspace: action.payload
      }

    case WORKSPACES.ADD_CURRENT_BOARD: {
      return {
        ...state,
        currentBoard: action.payload
      }
    }

    case WORKSPACES.ASSIGN_TEAMMATE:
      const updatedData1 = [...state.data]
      const index = getCurrentIndex(action.payload._id)
      updatedData1[index] = { ...updatedData1[index], ...action.payload }
      return {
        ...state,
        currentWorkspace: { ...state.currentWorkspace, ...action.payload },
        data: updatedData1
      }

    case WORKSPACES.UPDATE_SECTION_ORDERS:
      return {
        ...state,
        currentWorkspace: {
          ...state.currentWorkspace,
          workspaceSection: action.payload
        }
      }

    case WORKSPACES.TOGGLE_ARCHIVE:
      return {
        ...state,
        data: state.data.map((item) =>
          item._id === action.payload._id ? action.payload : item
        )
      }

    // case WORKSPACES.UPDATE_TASK_ORDER:
    //   return {
    //     ...state,
    //     data: state.data.map((i) => i._id)
    //   }

    case WORKSPACES.DELETE_ONE_WORKSPACE_SECTION:
      const updatedData2 = [...state.data]
      updatedData2[
        getCurrentIndex(state.currentWorkspace._id)
      ].workspaceSection = state.currentWorkspace.workspaceSection.filter(
        (item) => item._id !== action.payload._id
      )
      return {
        ...state,
        data: updatedData2,
        currentWorkspace: {
          ...state.currentWorkspace,
          workspaceSection: state.currentWorkspace.workspaceSection.filter(
            (item) => item._id !== action.payload._id
          )
        }
      }

    case WORKSPACES.UPDATE_MANY_WORKSPACES:
      const updatedData3 = [...state.data].map((workspace) => {
        let newAgencyTeam = workspace[action.payload.key].filter(
          (team) => team.user._id !== action.payload.id
        )
        return { ...workspace, [action.payload.key]: newAgencyTeam }
      })
      return {
        ...state,
        data: updatedData3
        // currentWorkspace: {
        //   ...state.currentWorkspace,
        //   [action.payload.key]: state.currentWorkspace[
        //     action.payload.key
        //   ].filter((team) => team.user._id !== action.payload.id)
        // }
      }

    case WORKSPACES.PUSHER_ADD_ONE_WORKSPACE:
      const isAvailable = state.data.find((i) => i._id === action.payload._id)
      if (isAvailable !== undefined) {
        return state
      } else {
        return {
          ...state,
          data: [action.payload, ...state.data]
        }
      }

    case WORKSPACES.PUSHER_DELETE_ONE_WORKSPACE:
      const filteredData = state.data.filter(
        (i) => i._id !== action.payload._id
      )

      return {
        ...state,
        loading: false,
        data: filteredData
      }

    case WORKSPACES.CHANGE_DEFAULT_BOARD:
      // Updating defaultBoard on pin board change
      return {
        ...state,
        data: state.data.map((item) => {
          if (item._id === action.payload.workspace) {
            return { ...item, defaultBoard: action.payload }
          }
          return item
        }),
        currentWorkspace: {
          ...state.currentWorkspace,
          defaultBoard: action.payload
        },
        currentBoard: action.payload
      }

    default:
      return state
  }
}

export default workspacesReducer

const updateOneObjectFromArray = (arr, obj) => {
  const ind = arr.findIndex((item) => item._id === obj._id)
  const newData = [...arr]
  newData[ind] = { ...newData[ind], ...obj }
  return newData
}
