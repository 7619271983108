import React, { useEffect, useState } from 'react'
import { Dialog } from '@material-ui/core'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import { useHistory } from 'react-router-dom'

const PaymentSuccessfulModal = (props) => {
  const { open, path } = props
  const history = useHistory()
  const [counter, setCounter] = useState(5)
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    let interval = null
    if (open) {
      interval = setInterval(() => {
        setCounter((prev) => prev - 1)
      }, 1000)
      setTimer(interval)
    }
    return () => clearInterval(interval)
  }, [open])

  useEffect(() => {
    if (counter === 0) {
      clearInterval(timer)
      history.push(path)
    }
  }, [counter])

  return (
    <Dialog
      open={open}
      style={{ minWidth: '400px' }}
      fullWidth
      // maxWidth="md"
    >
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
              <CheckCircleOutlineRoundedIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Success
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  We've recieved your payment. You will be redirect to
                  personalized dashboard automatically in{' '}
                </p>
                <p className="text-2xl text-center mt-4 pb-4">
                  {counter} Seconds
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          
        </div> */}
      </div>
    </Dialog>
  )
}

export default PaymentSuccessfulModal
