/**
 *
 * @param {Object} e event
 * @param {Object} options offset (optional), cb
 */
// const triggerOnScrollToBottom = (e, { offset = 100, cb }) => {
//   const target = e.currentTarget
//   const offsetHeight = target.offsetHeight
//   const scrollHeight = target.scrollHeight
//   const scrollTop = target.scrollTop

//   if (offsetHeight + scrollTop + offset >= scrollHeight) {
//     cb()
//   }
// }

/**
 *
 * @param {Object} e event
 * @param {Object} options offset (optional), cb
 */
export const triggerOnScrollToBottom = () => {
  let isActive = true,
    lastScrollTop = 0

  const activateInfiniteScroll = () => {
    isActive = true
  }

  return (e, { cb }) => {
    const target = e.currentTarget
    const offsetHeight = target.offsetHeight
    const scrollHeight = target.scrollHeight
    const scrollTop = target.scrollTop

    if (!isActive) return
    if (offsetHeight + scrollTop >= scrollHeight / 2) {
      isActive = false
      cb(activateInfiniteScroll)
    }
  }
}

export default triggerOnScrollToBottom
