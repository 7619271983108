import React, { useState, useEffect } from 'react'
// import { InputBase, TextField } from '@material-ui/core'
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded'
import clsx from 'clsx'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { useSelector, useDispatch } from 'react-redux'
import { userRoles } from 'utils'
import taskSidePanelStyles from './taskSidePanelStyles'
import { postTaskLog } from 'thunks/logs/action'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

function TaskPanelFooter({
  elmFor,
  taskId,
  workspaceId,
  setSelectedTab,
  socketId
}) {
  const [comment, setComment] = useState('')
  const classes = taskSidePanelStyles()
  const meData = useSelector((state) => state.me.data)
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const [externalCommentCheck, setExternalCommentCheck] = useState(false)
  const dispatch = useDispatch()

  const handleComment = (e) => {
    setComment(e.target.value)
  }

  useEffect(() => {
    meData.role >= userRoles.USER_CLIENT && setExternalCommentCheck(true)
  }, [meData.role])

  const commentCheckHandler = () => {
    if (meData.role < userRoles.USER_CLIENT)
      setExternalCommentCheck((prev) => !prev)
  }

  const postComment = () => {
    if (comment.trim()) {
      const data = {
        description: comment,
        activityType: 'comment',
        isExternal: externalCommentCheck,
        workSpace: workspaceId,
        socketId
      }

      if (elmFor === 'task') {
        data.task = taskId
      } else {
        data.subTask = taskId
        data.category = 'subtask'
      }

      dispatch(postTaskLog(data, commentCallback))
    }
  }

  const commentCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setComment('')
      setSelectedTab(0)
      meData.role < userRoles.USER_CLIENT
        ? setExternalCommentCheck(false)
        : setExternalCommentCheck(true)
      // logsBottomRef.current.scrollIntoView()
      // dispatch(findCommentBytaskId(taskData._id))
    }
  }

  return (
    <div className={clsx(classes.drawerFooter, 'px-4')}>
      <div
        className={clsx(
          classes.bottomCommentContainer,
          'flex items-start space-x-2'
        )}
      >
        <ColoredAvatars user={meData} tooltip={false} size="medium" />
        <div className="hover:border-blue-500 w-full flex items-center gap-2">
          <textarea
            value={comment}
            onChange={handleComment}
            className={clsx(
              'w-full border-2 border-gray-300 hover:border-primary-main focus:h-20 resize-none rounded h-10 py-1 px-2 transition-all text-sm',
              comment && 'h-20'
            )}
          ></textarea>
        </div>
        <div className="self-center">
          <div className="flex border divide-x-2 px-2 py-2 divide-custom-gray-border border border-custom-gray-border text-xs rounded-md h-8">
            {currentWorkspace.category === 'External' &&
              meData.role < userRoles.USER_CLIENT && (
                <span
                  role="button"
                  onClick={commentCheckHandler}
                  className={clsx(
                    'relative flex items-center cursor-pointer pr-2 gap-1',
                    classes.rotateSwapIcon
                  )}
                >
                  <SwapHorizRoundedIcon style={{ fontSize: 18 }} />
                  {externalCommentCheck ? 'External' : 'Internal'}

                  <div
                    style={{ top: '102%', width: '100%' }}
                    className="hidden absolute bottom-0 z-10 bg-white text-xs h-9 shadow flex-col gap-0.5 divide-y divide-custom-gray-border"
                  >
                    <p>Internal</p>
                    <p>External</p>
                  </div>
                </span>
              )}
            <button onClick={postComment} className="pl-2">
              Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(TaskPanelFooter)
