import { Check } from '@material-ui/icons'
import { LoadingButton } from 'global/globalComponents'
import Pill from 'global/globalComponents/Pill/Pill'
import { Button, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import currency from 'static/Currency/Currency'
import { recurringData } from 'utils'
import Card from '../Card/Card'

const ServiceCard = ({ plans, selectPlanHandler, loading }) => {
  const classes = useStyles()
  const getPillVariant = status => {}

  const truncateText = (text, maxWord) => {
    text = text.replace(text[0], text[0]?.toUpperCase())
    if (text.length >= maxWord) {
      const transformed = text.substr(0, maxWord - 2)
      return `${transformed}...`
    }
    return text
  }

  return (
    <div className=" space-y-4  sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl xl:max-w-none xl:mx-0 xl:grid-cols-4">
      {plans &&
        plans.map(plan => (
          <Card
            key={plan.name}
            className="p-4 sm:p-6 flex flex-col justify-between"
          >
            <div className="">
              <div className="flex justify-between items-start space-x-2">
                <h2 className="text-base lg:text-lg leading-6 font-semibold text-custom-gray-dark-2 w-3/4">
                  {truncateText(plan.name, 30)}
                </h2>
                <Pill
                  className="whitespace-nowrap"
                  variant={
                    !plan.isRecurring
                      ? 'red'
                      : plan?.recurring?.interval === 'month'
                      ? 'blue'
                      : 'green'
                  }
                >
                  {plan.isRecurring
                    ? recurringData.find(
                        el =>
                          el.interval === plan?.recurring?.interval &&
                          el.interval_count === plan?.recurring?.interval_count
                      ).tag
                    : 'One TIme'}
                </Pill>
              </div>

              <div>
                <p className="text-xs py-1 text-custom-gray-dark-2">
                  {truncateText(plan.description, 80)}
                </p>
                <p className="text-xs py-1 text-primary-main">
                  <span>
                    {plan?.currency.toUpperCase()} {plan.amount * 0.01}
                  </span>
                  <span>
                    {plan.isRecurring && (
                      <>
                        {
                          recurringData.find(
                            el =>
                              el.interval === plan?.recurring?.interval &&
                              el.interval_count ===
                                plan?.recurring?.interval_count
                          ).name
                        }
                        &nbsp;
                      </>
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div>
              <div className="my-4 h-32">
                {/* <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3> */}
                <ul className="space-y-2">
                  {plan.offerings.map(feature => (
                    <li key={feature} className="flex space-x-3 truncate">
                      <Check
                        fontSize="small"
                        className="flex-shrink-0 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-xs text-black">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="">
                {selectPlanHandler ? (
                  <LoadingButton
                    // href={'#'}
                    onClick={() => selectPlanHandler(plan)}
                    className="w-full truncate"
                    // className={classes.planBuyButton}
                    loading={plan._id === loading}
                  >
                    Buy
                  </LoadingButton>
                ) : (
                  <div className="">
                    <Button
                      component={Link}
                      to={`/services/${plan?._id}/checkout`}
                      className="w-full truncate"
                    >
                      Buy
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Card>
        ))}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  planBuyButton: {
    marginTop: 32,
    background: 'rgb(31,41,55)',
    fontSize: 14,
    '&:hover': {
      background: 'rgb(17,24,39)'
    }
  }
}))

export default ServiceCard
