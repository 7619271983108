import { useEffect } from 'react'
import { ReactComponent as FileCheckIcon } from 'static/svg/file-check.svg'
import { Button } from '@material-ui/core'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import userRoles, { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NoAccess } from 'static/Images'

const StatusUpdatePage = ({ addNewStatusPost }) => {
  const { workspaceId } = useParams()
  const meData = useSelector(state => state.me.data)

  const addNewPost = () => {
    addNewStatusPost({
      workspace: workspaceId,
      title: 'Untitled',
      status: 1,
      state: 'draft',
      sections: [
        {
          title: 'Summary',
          description: '',
          tasks: [],
          taskConfiguration: {}
        },
        {
          title: "What we've accomplished",
          description: '',
          tasks: [],
          taskConfiguration: {}
        },
        {
          title: "What's blocked",
          description: '',
          tasks: [],
          taskConfiguration: {}
        },
        {
          title: 'Next steps',
          description: '',
          tasks: [],
          taskConfiguration: {}
        }
      ],
      file: []
    })
  }

  return (
    <OuterContentWrapper>
      {meData.role < userRoles.USER_CLIENT ? (
        <div className="" style={{ maxWidth: '350px', padding: '100px 0' }}>
          <FileCheckIcon
            className="h-10 w-8 mb-4"
            style={{ fill: 'lightgray' }}
          />
          <h1 className="text-lg lg:text-xl text-gray-900 mb-2">
            Update your client on the current status of their Project.
          </h1>
          <p className="text-smaller lg:text-sm text-gray-700 mb-4">
            Simply attach the tasks based on due dates, publish project updates
            for everyone or only private to your team.
          </p>
          {addNewStatusPost && <Button onClick={addNewPost}>New Update</Button>}
        </div>
      ) : (
        <NoAccessUI />
      )}
    </OuterContentWrapper>
  )
}

const NoAccessUI = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div
        style={{ width: '100%', maxHeight: '500px' }}
        className="bg-white my-auto"
      >
        <img
          src={NoAccess}
          alt="No Access"
          className="w-full h-full object-contain"
          style={{ maxWidth: '800px', maxHeight: '500px', margin: 'auto' }}
        />
      </div>
    </div>
  )
}

export default StatusUpdatePage
