import React, { useState, useEffect } from 'react'
import { gettingAllTasksStatus } from 'thunks/gettingStarted/actions'
import { useDispatch, useSelector } from 'react-redux'
import GettingStartedTasksNav from './GettingStartedTasksNav'
import CircularProgress from '@material-ui/core/CircularProgress'

import { ReactComponent as Plug } from 'static/svg/plug.svg'
import { ReactComponent as List } from 'static/svg/list.svg'
import { ReactComponent as Tasks } from 'static/svg/tasks.svg'
import { ReactComponent as PlusSquare } from 'static/svg/plus-square.svg'
import { ReactComponent as UserPlus } from 'static/svg/user-plus.svg'
import { ReactComponent as Tags } from 'static/svg/tags.svg'
import {
  CreateServicesTask,
  CreateWorkspaceTask,
  AddClientsTask,
  AddTeammateTask,
  StripeConnectionTask,
  WhiteLabelPortalTask
} from './GettingStartedTasksInfo'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useHistory } from 'react-router-dom'
import { updateUser } from 'thunks/user/actions'
import { LoadingButton } from 'global/globalComponents'

function GettingStartedTasks() {
  const dispatch = useDispatch()
  const history = useHistory()
  const gettingStartedTasks = useSelector((state) => state.gettingStartedTasks)
  const meData = useSelector((state) => state.me.data)
  const [tabs, setTabs] = useState([
    {
      text: 'Onboard Clients',
      icon: <Tasks width="20" height="20" fill="currentColor" />,
      isOpen: true,
      isCompleted: false
    },
    {
      text: 'Add Teammates',
      icon: <UserPlus width="20" height="20" fill="currentColor" />,
      isOpen: false,
      isCompleted: false
    },
    {
      text: 'Setup your Client portal',
      icon: <Tags width="20" height="20" fill="currentColor" />,
      isOpen: false,
      isCompleted: false
    },
    {
      text: 'Create Services',
      icon: <List width="20" height="20" fill="currentColor" />,
      isOpen: false,
      isCompleted: false
    },
    {
      text: 'Setup Invoicing',
      icon: <Plug width="20" height="20" fill="currentColor" />,
      isOpen: false,
      isCompleted: false
    },
    {
      text: 'Create project',
      icon: <PlusSquare width="20" height="20" fill="currentColor" />,
      isOpen: false,
      isCompleted: false
    }
  ])
  const [skipLoading, setSkipLoading] = useState(false)
  const [openTabIndex, setOpenTabIndex] = useState(0)

  useEffect(() => {
    dispatch(gettingAllTasksStatus())
    if (gettingStartedTasks.fetched) {
    } else {
    }
  }, [gettingStartedTasks.fetched])

  useEffect(() => {
    const data = gettingStartedTasks.data

    // ===== This data goes to Side Navbar ====
    setTabs((prevState) => [
      {
        ...prevState[0],
        isCompleted: data.clientsConnected
      },
      {
        ...prevState[1],
        isCompleted: data.teammatesConnected
      },
      {
        ...prevState[2],
        isCompleted: data.whiteLabelActive
      },
      {
        ...prevState[3],
        isCompleted: data.servicesConnected
      },
      {
        ...prevState[4],
        isCompleted: data.stripeConnected
      },
      {
        ...prevState[5],
        isCompleted: data.workspaceConnected
      }
    ])

    if (
      meData?.path?.includes('getting-started') &&
      !Object.values(data).join(' ').includes('false')
    ) {
      updateUser({ path: '/agency/dashboard' })
    }
  }, [gettingStartedTasks.data])

  let completedTasksCount = 0
  for (let key in gettingStartedTasks.data) {
    if (gettingStartedTasks.data[key]) {
      completedTasksCount++
    }
  }

  const taskInfoArr = [
    <AddClientsTask />,
    <AddTeammateTask />,
    <WhiteLabelPortalTask />,
    <CreateServicesTask />,
    <StripeConnectionTask />,
    <CreateWorkspaceTask />
  ]

  const changeTab = (index) => {
    setOpenTabIndex(index)
  }

  const handleSkipTutorials = () => {
    dispatch(
      updateUser({ path: '/agency/dashboard' }, handleSkipTutorialsCallback)
    )
  }

  const handleSkipTutorialsCallback = (res, err) => {
    setSkipLoading(true)
    if (err) {
      dispatch(fireErrorToaster('Unsuccessful'))
    } else {
      history.push('/agency/dashboard')
    }
  }

  return (
    <div className="bg-white rounded border shadow select-none">
      <header className="py-4 px-6 border-b flex items-center justify-between">
        <div>
          <h2 className="text-lg">You're almost there</h2>
          <span className="text-sm text-gray-500">
            {completedTasksCount}/6 tasks completed
          </span>
        </div>
        <LoadingButton
          disabled={gettingStartedTasks.loading}
          loading={skipLoading}
          onClick={handleSkipTutorials}
        >
          I have done these
        </LoadingButton>
      </header>

      <div className="flex">
        {/* Side Navigation (Left) */}
        <GettingStartedTasksNav
          tabs={tabs}
          changeTab={changeTab}
          openTabIndex={openTabIndex}
          loading={gettingStartedTasks.loading}
        />

        {/* Task Info (Right) */}
        <div className="p-6 flex-1">
          {gettingStartedTasks.loading ? (
            <div className="h-full flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <div>{taskInfoArr[openTabIndex]}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GettingStartedTasks
