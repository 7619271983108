const getSectionColor = index => {
  const colors = [
    {
      label: 'open',
      color: '#D3D3D3'
    },
    {
      label: 'pending',
      color: '#FFCC00'
    },
    {
      label: 'in-progress',
      color: '#F81C07'
    },
    {
      label: 'completed',
      color: '#000000'
    },
    {
      label: 'review',
      color: '#FF9900'
    },
    {
      label: 'blocked',
      color: '#FF9900'
    },
    {
      label: 'released-to-client',
      color: '#FF7FAB'
    }
  ]

  if (!isNaN(index)) {
    return colors[index % colors.length].color
  }
}

export const sectionColorOptions = [
  '#D3D3D3',
  '#FFCC00',
  '#F81C07',
  '#000000',
  '#FF9900',
  '#FF7FAB',
  '#800000',
  '#FF4081',
  '#EAF08C',
  '#BF55EC',
  '#9B59B6',
  '#0231E8',
  '#81B1FF',
  '#3082B7',
  '#1BBC9C',
  '#F9D900',
  '#AF7E2E',
  '#667684',
  '#2ECD6F'
]

export default getSectionColor
