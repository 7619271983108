// import { v4 as uuidv4 } from 'uuid'

import moment from 'moment'

export const indexKey = {
  status: 'orderIndex',
  dueDate: 'dueDateOrderIndex',
  assignee: 'assigneeOrderIndex'
}

// 1. Sections based on Status
// export const getStatusSections = (statuses) => {
//   return statuses.map((status) => ({
//     _id: status._id,
//     title: status.label,
//     value: status._id,
//     color: status.color || '#000000',
//     type: 'status'
//   }))
//   // const sectionsObj = {}

//   // for (let i = 0; i < statuses.length; i++) {
//   //   let status = statuses[i]

//   //   sectionsObj[status._id] = {
//   //     id: status._id,
//   //     title: status.label,
//   //     value: status._id,
//   //     color: status.color || '#000000',
//   //   }
//   // }

//   // for (let status of statuses) {
//   // }

//   // for (let task of tasks) {
//   //   if (sectionsObj[task.taskSection]) {
//   //     sectionsObj[task.taskSection].tasks.push(task)
//   //   }
//   // }

//   // const allSections = handleSortSectionTasks(
//   //   groupBy,
//   //   sortBy,
//   //   Object.values(sectionsObj)
//   // )
//   // return allSections
// }

// 2. Sections based on Due Date
export const getDueDateSections = () => {
  const sections = [
    {
      _id: 'overdue',
      title: 'Overdue',
      value: moment().subtract(1, 'days').toISOString(),
      color: '#000',
      type: 'dueDate',
      startDate: moment()
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .toISOString(),
      endDate: moment()
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toISOString(),
      tasks: [],
      totalTasks: 0
    },
    {
      _id: moment().format('DD-MM-YYYY'),
      title: 'Today',
      startDate: moment()
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .toISOString(),
      endDate: moment()
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toISOString(),
      value: moment().toISOString(),
      color: '#000',
      type: 'dueDate',
      tasks: [],
      totalTasks: 0
    },
    {
      _id: moment().add(1, 'days').format('DD-MM-YYYY'),
      title: 'Tomorrow',
      startDate: moment()
        .add(1, 'days')
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .toISOString(),
      endDate: moment()
        .add(1, 'days')
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toISOString(),
      value: moment().add(1, 'days').toISOString(),
      color: '#000',
      type: 'dueDate',
      tasks: [],
      totalTasks: 0
    }
  ]

  for (let i = 2; i < 7; i++) {
    let day = moment().add(i, 'days').format('dddd')
    sections.push({
      _id: moment().add(i, 'days').format('DD-MM-YYYY'),
      title: day,
      startDate: moment()
        .add(i, 'days')
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .toISOString(),
      endDate: moment()
        .add(i, 'days')
        .set('hours', 23)
        .set('minutes', 59)
        .set('seconds', 59)
        .toISOString(),
      value: moment().add(i, 'days').toISOString(),
      color: '#000',
      type: 'dueDate',
      tasks: [],
      totalTasks: 0
    })
  }

  sections.push({
    _id: 'noDueDate',
    title: 'No Due Date',
    value: null,
    color: '#000',
    type: 'dueDate',
    tasks: [],
    totalTasks: 0
  })

  return sections

  // const weekDays = [
  //   'Sunday',
  //   'Monday',
  //   'Tuesday',
  //   'Wednesday',
  //   'Thursday',
  //   'Friday',
  //   'Saturday'
  // ]
  // const date = new Date()
  // const today = new Date(date.getFullYear(), date.getMonth(), date.getDate()) //setting today from time 00:00:00
  // const yesterday = new Date(today)
  // const tomorrow = new Date(today)
  // const future = new Date(today)
  // const dueDateSections = []

  // yesterday.setDate(today.getDate() - 1)
  // tomorrow.setDate(today.getDate() + 1)
  // future.setDate(today.getDate() + 7)

  // dueDateSections.push(
  //   { label: 'Overdue', value: yesterday.toISOString() },
  //   { label: 'Today', value: today.toISOString() },
  //   { label: 'Tomorrow', value: tomorrow.toISOString() }
  // )

  // for (let i = 2; i < weekDays.length; i++) {
  //   // i=2 (skip 2 days - today, tomorrow)
  //   const newDate = new Date(today)
  //   newDate.setDate(newDate.getDate() + i)

  //   dueDateSections.push({
  //     label: weekDays[newDate.getDay()],
  //     value: newDate.toISOString()
  //   })
  // }

  // dueDateSections.push(
  //   ...[
  //     {
  //       label: 'Future',
  //       value: future.toISOString()
  //     },
  //     { label: 'No Due Date', value: null }
  //   ]
  // )

  // ========= Add Tasks To Sections =======
  // return (groupBy, sortBy, tasks) => {
  //   const sectionsObj = {}

  //   for (let section of dueDateSections) {
  //     sectionsObj[section.label] = {
  //       id: section.label,
  //       title: section.label,
  //       value: section.value,
  //       color: '#000000',
  //       tasks: [],
  // totalTasks: 0
  //     }
  //   }

  //   for (let task of tasks) {
  //     if (task.dueDate) {
  //       if (
  //         task.markAsComplete &&
  //         compareDate(new Date(), new Date(task.dueDate)) === 1
  //       ) {
  //         sectionsObj['Overdue'].tasks.push(task)
  //       } else if (new Date(task.dueDate) - new Date() > 518400000) {
  //         sectionsObj['Future'].tasks.push(task)
  //       } else {
  //         for (let [key, section] of Object.entries(sectionsObj)) {
  //           if (
  //             compareDate(new Date(task.dueDate), new Date(section.value)) === 0
  //           ) {
  //             sectionsObj[key].tasks.push(task)
  //           }
  //         }
  //       }
  //     } else {
  //       sectionsObj['No Due Date'].tasks.push(task)
  //     }
  //   }

  //   const allSections = handleSortSectionTasks(
  //     groupBy,
  //     sortBy,
  //     Object.values(sectionsObj)
  //   )

  //   return allSections
  // }
}

// 3. Sections based on Assignee
// export const getAssigneeSections = (groupBy, sortBy, tasks) => {
//   const sectionsObj = {
//     noAssignee: {
//       id: 'noAssignee',
//       value: {
//         assignedAgencyTeam: [],
//         assignedClientTeam: []
//       },
//       title: 'No Assignee',
//       color: '#000000',
//       tasks: [],
// totalTasks: 0
//     }
//   }

//   for (let task of tasks) {
//     let value = [...task.assignedAgencyTeam, ...task.assignedClientTeam]
//       .map((item) => item._id)
//       .sort()
//       .join('&')

//     if (value === '') {
//       sectionsObj.noAssignee.tasks.push(task)
//       continue
//     }

//     if (sectionsObj[value]) {
//       sectionsObj[value].tasks.push(task)
//     } else {
//       sectionsObj[value] = {
//         id: value,
//         value: {
//           assignedAgencyTeam: task.assignedAgencyTeam,
//           assignedClientTeam: task.assignedClientTeam
//         },
//         color: '#000000',
//         tasks: [task]
//       }
//     }
//   }

//   const allSections = handleSortSectionTasks(
//     groupBy,
//     sortBy,
//     Object.values(sectionsObj)
//   )
//   return Object.values(allSections)
// }

// const handleSortSectionTasks = (groupBy, sortBy, sections) => {
//   return [...sections].map((sec) => {
//     sec.tasks = [...sec.tasks].sort((a, b) =>
//       parseFloat(a[indexKey[groupBy]]) > parseFloat(b[indexKey[groupBy]])
//         ? 1
//         : -1
//     )
//     return sec
//   })
//   // if (sortBy.value === '') {
//   //   return [...sections].map((sec) => {
//   //     sec.tasks = [...sec.tasks].sort((a, b) =>
//   //       parseFloat(a[indexKey[groupBy]]) > parseFloat(b[indexKey[groupBy]])
//   //         ? 1
//   //         : -1
//   //     )
//   //     return sec
//   //   })
//   // }

//   // if (sortBy.value === 'status') {
//   //   return [...sections].map((sec) => {
//   //     sec.tasks = [...sec.tasks].sort((a, b) =>
//   //       a.taskSection.label > b.taskSection.label ? 1 : -1
//   //     )
//   //     return sec
//   //   })
//   // }

//   // if (sortBy.value === 'dueDate') {
//   //   return [...sections].map((sec) => {
//   //     sec.tasks = [...sec.tasks].sort((a, b) =>
//   //       new Date(a.dueDate || null) > new Date(b.dueDate || null) ? 1 : -1
//   //     )
//   //     return sec
//   //   })
//   // }

//   // return sections
// }

// const compareDate = (date1, date2) => {
//   if (date1.getFullYear() !== date2.getFullYear())
//     return date1.getFullYear() > date2.getFullYear() ? 1 : -1
//   if (date1.getMonth() !== date2.getMonth())
//     return date1.getMonth() > date2.getMonth() ? 1 : -1
//   if (date1.getDate() !== date2.getDate())
//     return date1.getDate() > date2.getDate() ? 1 : -1
//   return 0
// }
