import React, { useMemo } from 'react'
import { IconButton } from '@material-ui/core'
import { ReactComponent as BugIcon } from 'static/asana/bug.svg'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import { AvatarGroup } from '@material-ui/lab'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import userRoles, { getUserPath } from 'utils/userRoles'
import { useSelector } from 'react-redux'
import AsanaIcons from 'static/asana/AsanaIcons'
import ColoredAvatars, {
  ColoredAvatarGroup
} from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
// import useWorkspacePermission from 'customHooks/useWorkspacePermission'
// import UserDashboardStyles from '../../userDashboardStyles'
// import { CustomTooltip } from 'global/globalComponents'
import { Card } from 'global/globalComponents'
import { ReactComponent as VerticalThreeDots } from 'static/svg/verticalThreeDot.svg'
import useStyles from './projectsCardViewStyles'

const ProjectCard = props => {
  const classes = useStyles()
  const {
    workspaceData,
    handleSubMenu,
    activeMenu,
    newTag = false,
    elmFor
  } = props
  const userRole = useSelector(state => state.me.data?.role)
  // const { workspace: workspacePermission } = useWorkspacePermission(
  //   workspaceData._id
  // )
  const meData = useSelector(state => state.me.data)
  const workspacePermissionData = useSelector(
    state => state.workspacePermission.data
  )

  const workspacePermission = useMemo(() => {
    let permissionObj
    for (let item of workspacePermissionData) {
      if (typeof item[workspaceData?._id] === 'object') {
        permissionObj = item[workspaceData._id].access
      }
    }
    return permissionObj?.workspace
  }, [workspacePermissionData, workspaceData])

  const owner = useMemo(() => {
    if (workspaceData.assignedAgencyTeam) {
      const userObj = [
        ...workspaceData.assignedAgencyTeam,
        ...workspaceData.assignedClientTeam
      ].find(mate => mate.user._id === workspaceData.user)

      if (userObj) return userObj.user
    }
  }, [workspaceData.user])

  const members = useMemo(() => {
    return [
      ...workspaceData.assignedAgencyTeam,
      ...workspaceData.assignedClientTeam
    ].map(obj => obj.user)
  }, [workspaceData.assignedAgencyTeam, workspaceData.assignedClientTeam])

  return (
    <ConditionalLink
      to={`${getUserPath(userRole)}/workspace/${workspaceData._id}/overview`}
      condition={!workspaceData.isArchived && elmFor === 'workspace'}
    >
      <Card className={classes.cardWrapper}>
        <div>
          <header className="flex items-start justify-between p-4">
            <div
              className="rounded-full p-2"
              style={{
                backgroundColor: workspaceData.backgroundcolor + '70',
                width: '36px',
                height: '36px'
              }}
            >
              {AsanaIcons[workspaceData.image] ? (
                AsanaIcons[workspaceData.image]({ className: 'selected-icon' })
              ) : (
                <BugIcon className="selected-icon" />
              )}
            </div>
            <div className={clsx('leading-none', classes.owner)}>
              <span
                className="inline-block mb-1 text-custom-gray-light-3 font-medium"
                style={{ fontSize: 9 }}
              >
                Owner
              </span>
              <ColoredAvatars size="small" user={owner} tooltip={true} />
            </div>
          </header>

          <div className="mb-4 lg:mb-6 px-4">
            <h4 className="font-medium text-primary-dark truncate capitalize text-smaller lg:text-sm">
              {workspaceData.name}
            </h4>
            {/* <div className="text-primary-mid-dark text-sm flex justify-between items-center mb-2 font-medium">
              <span>Ongoing Task</span>
              <span>20 (static Data)</span>
            </div>
            <div className="text-primary-mid-dark text-sm flex justify-between items-center font-medium">
              <span>Completed Task</span>
              <span>10 (static Data)</span>
            </div> */}
          </div>

          <footer className="py-3 px-4 border-t flex justify-between items-center">
            <ColoredAvatarGroup users={members} size="small" tooltip={true} />
            {elmFor === 'workspace' ? (
              workspacePermission?.update &&
              meData.role !== userRoles.USER_CLIENT && (
                <IconButton
                  size="small"
                  onClick={e => handleSubMenu(e, workspaceData)}
                >
                  <VerticalThreeDots className="w-4 h-4" />
                </IconButton>
              )
            ) : (
              <Link
                to={`${getUserPath(userRole)}/workspace/${
                  workspaceData._id
                }/overview`}
                className="link text-blue-400 text-xs px-2 py-1 hover:bg-gray-50 opacity-0 "
              >
                Go to project
              </Link>
            )}
          </footer>
        </div>
      </Card>
      {/* <Box
        className={clsx(classes.cardWrapper, {
          active: activeMenu
        })}
      >
        <div
          data-wstype={newTag ? 'new' : workspaceData.category}
          className={clsx(
            classes.cardContainer,
            {
              archived: workspaceData.isArchived
            },
            {
              new: newTag
            }
          )}
          style={{ backgroundColor: workspaceData.backgroundcolor }}
        >
          <div className={classes.svgIconContainer}>
            {AsanaIcons[workspaceData.image] ? (
              AsanaIcons[workspaceData.image]({ className: 'selected-icon' })
            ) : (
              <BugIcon className="selected-icon" />
            )}
          </div>
          <div className={classes.avatarContainer}>
            <AvatarGroup
              classes={{ root: classes.avatar }}
              spacing="spacing"
              max={4}
            >
              {[
                ...workspaceData.assignedAgencyTeam,
                ...workspaceData.assignedClientTeam
              ].map((mate, index) => (
                <ColoredAvatars
                  style={{ borderColor: workspaceData.backgroundcolor }}
                  // src={mate?.user?.profileImage}
                  // alt={mate?.user?.name}
                  // bgColorString={mate?.user?.name ?? ''}
                  user={mate?.user}
                  tooltip={false}
                  size="small"
                />
              ))}
            </AvatarGroup>
          </div>
          {workspacePermission?.update &&
            meData.role !== userRoles.USER_CLIENT && (
              <div
                onClick={(e) => handleSubMenu(e, workspaceData)}
                className={classes.moreIconContianer}
              >
                <MoreVertIcon fontSize="small" />
              </div>
            )}
        </div>
        <CustomTooltip title={workspaceData?.name} placement="bottom">
          <p
            // title={workspaceData?.name}
            className="text-sm font-medium mt-2 text-gray-800 truncate w-4/5 text-center"
          >
            {workspaceData?.name}
          </p>
        </CustomTooltip>
      </Box> */}
    </ConditionalLink>
  )
}

const ConditionalLink = ({ condition, to, children }) => {
  return <>{condition ? <Link to={to}>{children}</Link> : children}</>
}

export default ProjectCard
