const { withStyles, InputBase } = require('@material-ui/core')

const PseudoTextField = withStyles(theme => ({
  root: {
    width: '100%',
    border: '1px solid transparent',
    fontSize: '13px',
    '@media (min-width: 1284px)': {
      fontSize: '14px'
    }
  },
  Input: {
    fontSize: '14px'
  },
  input: {
    border: '1px solid transparent',
    borderRadius: '6px',
    padding: '8px 6px',
    transition: 'border-color 100ms',
    '&:focus, &:hover ': {
      borderColor: '#cbd4db',
      '& $root': {
        borderColor: '#cbd4db'
      }
    }
  }
}))(({ classes, inputStyles, ...props }) => {
  return (
    <InputBase
      classes={{ root: classes.root, input: classes.input }}
      inputProps={{ style: inputStyles }}
      {...props}
    />
  )
})

export default PseudoTextField
