import { Clientvenue_logo } from 'static/Images/index'

const updateDocumentTitle = (title, favicon) => {
  if (favicon) {
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = `${favicon}?${Date.now()}`
    document.querySelectorAll('[rel="icon"]')[0].replaceWith(link)
  } else {
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = Clientvenue_logo
    document.querySelectorAll('[rel="icon"]')[0].replaceWith(link)
  }

  if (title) {
    document.title = `${title}`
  } else {
    document.title = 'clientVenue'
  }
}

export default updateDocumentTitle
