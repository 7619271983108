var uid = '89123891231239' // Dummy global id variable
var token = 'eo4Dr8qhrFY' // Dummy global token variable

export default function serviceWorker({ meId }) {
  // let swUrl = './sw.js'
  navigator.serviceWorker.register('/sw.js').then((response) => {
    console.log('Service Worker: ', response)

    window.Notification.requestPermission(function (result) {
      // console.log('User choice', result)
      if (result !== 'granted') {
        console.log('No notification permission granted!')
      } else {
        subscribe()
      }
    })
  })

  const publicVapidKey =
    'BMKjfIh-RVSEHCa_TeVIa6Gj-OTR1BLnpesDIXSRTfv5JFdgVHrnBDObAf9iiEWY4OPIZG2sUBL7-sohl9Gm3fs'

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  // const getSubscribedElement = () => document.getElementById('subscribed');
  // const getUnsubscribedElement = () => document.getElementById('unsubscribed');

  // const setSubscribeMessage = async () => {
  //   const registration = await navigator.serviceWorker.ready
  //   // const subscription = await registration.pushManager.getSubscription()
  //   // getSubscribedElement().setAttribute('style', `display: ${subscription ? 'block' : 'none'};`);
  //   // getUnsubscribedElement().setAttribute('style', `display: ${subscription ? 'none' : 'block'};`);
  // }

  function detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera'
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome'
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari'
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox'
    } else if (
      navigator.userAgent.indexOf('MSIE') != -1 ||
      !!document.documentMode == true
    ) {
      return 'IE' //crap
    } else {
      return 'Unknown'
    }
  }

  const subscribe = async () => {
    if (!('serviceWorker' in navigator)) return

    const registration = await navigator.serviceWorker.ready

    // Subscribe to push notifications
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    })

    let body = {
      subscription,
      userId: meId,
      browser: {
        browserName: detectBrowser()
      }
    }

    console.log('BODY ==============>', body)

    // registration.active.postMessage(JSON.stringify({ meId: meId }))

    // subscription.meId = meId

    // console.log('========>', subscription)

    const response = await fetch(
      `${process.env.REACT_APP_CONNECTION_URL}/subscription`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json'
        }
      }
    )

    // if (response.ok) {
    //   setSubscribeMessage()
    // }
  }

  // window.unsubscribe = async () => {
  //   const registration = await navigator.serviceWorker.ready;
  //   const subscription = await registration.pushManager.getSubscription();
  //   if (!subscription) return;

  //   const { endpoint } = subscription;
  //   const response = await fetch(`/subscription?endpoint=${endpoint}`, {
  //     method: 'DELETE',
  //     headers: {
  //       'content-type': 'application/json',
  //     },
  //   });

  //   if (response.ok) {
  //     await subscription.unsubscribe();
  //     setSubscribeMessage();
  //   }
  // };

  // window.broadcast = async () => {
  //   await fetch('/broadcast', {
  //     method: 'GET',
  //     headers: {
  //       'content-type': 'application/json',
  //     },
  //   });
  // };

  // setSubscribeMessage()

  // function configurePushSub() {}

  // if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'production') {
  //   window.addEventListener('load', function () {
  //     navigator.serviceWorker
  //       .register(swUrl)
  //       .then(
  //         function (registration) {
  //           console.log(
  //             'worker registration is successfull',
  //             registration.scope
  //           )
  //         },
  //         function (err) {
  //           console.log('Failed')
  //         }
  //       )
  //       .catch(function (err) {
  //         console.log(err)
  //       })
  //   })
  // } else {
  //   console.log('Service worker is not supported')
  // }

  // var self = window

  // self.addEventListener('install', (event) => {
  //   self.skipWaiting()
  // })

  // self.addEventListener('push', (e) => {
  //   const body = e.data.text() || 'Push message has no payload'

  //   const options = {
  //     body,
  //     icon: 'images/checkmark.png',
  //     vibrate: [100, 50, 100],
  //     data: {
  //       dateOfArrival: Date.now(),
  //       primaryKey: 1
  //     },
  //     actions: [
  //       {
  //         action: 'explore',
  //         title: 'Explore this new world',
  //         icon: 'images/checkmark.png'
  //       },
  //       {
  //         action: 'close',
  //         title: "I don't want any of this",
  //         icon: 'images/xmark.png'
  //       }
  //     ]
  //   }
  //   e.waitUntil(
  //     self.registration.showNotification(
  //       'Notification via Server and Push API',
  //       options
  //     )
  //   )
  // })

  // self.addEventListener('notificationclick', (event) => {
  //   const eventAction = event.action
  //   console.log('message event fired! event action is:', `'${eventAction}'`)

  //   if (eventAction !== 'explore') {
  //     return
  //   }

  //   const url = 'https://developer.mozilla.org/en-US/docs/Web/API/Push_API'
  //   event.notification.close() // Android needs explicit close.
  //   event.waitUntil(
  //     self.clients.matchAll({ type: 'window' }).then((windowClients) => {
  //       // Check if there is already a window/tab open with the target URL
  //       var self = this
  //       for (var i = 0; i < windowClients.length; i++) {
  //         var client = windowClients[i]
  //         // If so, just focus it.
  //         if (client.url === url && 'focus' in client) {
  //           return client.focus()
  //         }
  //       }
  //       // If not, then open the target URL in a new window/tab.
  //       if (self.AbortControllerclients.openWindow) {
  //         return self.clients.openWindow(url)
  //       }
  //     })
  //   )
  // })
}
