import axios from 'axios'
import { errorMessages } from 'utils'

/**
 * Forgot password api call
 * @param {String} email User email
 * @param {Function} callback callback function
 */
export const forgotPassword = async (email, callback) => {
  try {
    const res = await axios({
      url: '/user/forgetpassword',
      method: 'POST',
      data: {
        email
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    let errMsg = errorMessages.ERROR_MESSAGE

    if (err.response.status === 404) {
      errMsg = 'No user found with the given Email ID'
    }

    if (callback) callback(errMsg, true)
    // callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Reset password Api call
 * @param {{newPassword:String, confirmNewPassword:String}} data user new password
 * @param {String} resetPasswordToken User auth token recieved in url params
 * @param {Function} callback Callback function
 */
export const resetPassword = async (data, resetPasswordToken, callback) => {
  try {
    const res = await axios({
      url: `/user/resetPassword/${resetPasswordToken}`,
      method: 'POST',
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Reset password token validity Api call
 * @param {String} resetPasswordToken User auth token recieved in url params
 * @param {Function} callback Callback function
 */
export const resetPasswordTokenValidity = async (
  resetPasswordToken,
  callback
) => {
  try {
    const res = await axios({
      url: `/user/resetpassword/${resetPasswordToken}`,
      method: 'GET'
    })
    if (callback) callback(res, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Forgot domain api call
 * @param {String} email User email
 * @param {Function} callback callback function
 */
export const forgotDomain = async (email, callback) => {
  try {
    const res = await axios({
      url: '/user/forgetDomain',
      method: 'POST',
      data: {
        email
      }
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    let errMsg = errorMessages.ERROR_MESSAGE
    if (err.response.status === 404) {
      errMsg = 'No user found with the given Email ID'
    }
    if (callback) callback(errMsg, true)
  }
}

export const checkDomainAvailability = async (domain, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/user/checkagencydomain?domain=${domain}.clientvenue.com`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}
