import { useRef } from 'react'
import { PseudoTextField } from 'global/globalComponents'
import clsx from 'clsx'
import BoardsStyles from '../BoardsStyles'

const CreateTaskCard = ({ titleInputOnBlur }) => {
  const inputRef = useRef(null)
  const classes = BoardsStyles()

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  return (
    <div className={clsx(classes.taskCardContainer, 'rounded-lg bg-white p-4')}>
      <PseudoTextField
        onKeyUp={handleKeyPress}
        inputProps={{ onBlur: titleInputOnBlur }}
        autoFocus
        placeholder="Enter task name"
        type="text"
        name="task-name"
        inputRef={inputRef}
      />
    </div>
  )
}

export default CreateTaskCard
