import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { deleteclient, updateClient } from '../../thunks/addClient/actions'
import ClientEditPanel from './ClientEditPanel'
import ToggleSwitch from '../../global/globalComponents/ToggleSwitch/ToggleSwitch'
import { updateClientStatus } from '../../thunks/addClient/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { useHistory, useLocation } from 'react-router'
import { getUserPath } from 'utils/userRoles'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import Pill, { pillVariantObj } from 'global/globalComponents/Pill/Pill'
import { CustomTooltip, PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import ClientDeleteModal from './ClientDeleteModal'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import CompanyDropdown from 'components/ClientCompanyComponents/CompanyDropdown'
import { updateClientCompany } from 'thunks/clientCompany/action'

const totalClientsToShow = 8
const ActiveClients = () => {
  const dispatch = useDispatch()
  const me = useSelector(state => state.me.data)
  const userClients = useSelector(state => state.userClients)
  const access = useSelector(state => state.permission.data?.access)
  const search = useSelector(state => state.generalAppState.headerSearch)
  const clientCompany = useSelector(state => state.clientCompany)
  const [deleteClient, setdeleteClient] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [clientdata, setClientData] = useState({
    open: false,
    data: {},
    close: true
  })
  const [checkList, setCheckList] = useState({
    isClientPaymentDelete: false,
    isWorkspaceDelete: false,
    isOnBoardingResponseDelete: false
  })
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])
  const [userClientsData, setUserClientData] = useState([])
  const [visibleClients, setVisibleClients] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const [assignToMe, setAssignToMe] = useState(false)
  const [companyAnchorEl, setCompanyAnchorEl] = useState({
    anchorEl: null,
    data: {}
  })

  useEffect(() => {
    setUserClientData(userClients.data)
  }, [userClients])

  useEffect(() => {
    if (userClientsData.length) {
      if (search.length) {
        let arr = [...userClientsData].filter(
          item =>
            item?.name.toLowerCase().includes(search.toLowerCase()) ||
            item?.email.toLowerCase().includes(search.toLowerCase())
        )
        setVisibleClients(
          arr.splice(page * totalClientsToShow, totalClientsToShow)
        )
        setPaginationTotalDataCount(arr?.length)
      } else {
        setVisibleClients(
          [...userClientsData].splice(
            page * totalClientsToShow,
            totalClientsToShow
          )
        )
        setPaginationTotalDataCount(userClientsData.length)
      }
    } else {
      setVisibleClients([])
      setPaginationTotalDataCount(userClientsData.length)
    }
  }, [userClientsData, page, search, paginationTotalDataCount])

  const handleDeleteClient = client => {
    if (access?.clients.delete) {
      if (deleteClient.open) {
        setdeleteClient({
          open: false,
          data: {},
          loading: false
        })
        setCheckList({
          isClientPaymentDelete: false,
          isWorkspaceDelete: false,
          isOnBoardingResponseDelete: false
        })
      } else {
        setdeleteClient({
          open: true,
          data: client,
          loading: false
        })
      }
    } else {
      dispatch(fireErrorToaster('You are not authorized user'))
    }
  }

  const handleClientDeleteData = () => {
    setdeleteClient(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteclient(deleteClient.data._id, checkList, deleteClientcallback)
    )
  }

  const deleteClientcallback = (res, err) => {
    setdeleteClient({
      open: false,
      data: {},
      loading: false
    })
    setCheckList({
      isClientPaymentDelete: false,
      isWorkspaceDelete: false,
      isOnBoardingResponseDelete: false
    })
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Client deleted successfully'))
    }
  }

  const handleEditPanelClose = () => {
    setClientData({ open: false, data: {} })
  }

  const switchcallback = (res, err) => {
    setToggleSwitchLoading(prevState =>
      prevState.filter(item => res._id !== item)
    )
  }

  const handleSwitch = item => {
    if (access?.clients.update) {
      setToggleSwitchLoading(prevState => [...prevState, item._id])
      dispatch(
        updateClientStatus(
          item._id,
          { isActive: !item.isActive },
          switchcallback
        )
      )
    } else {
      dispatch(fireErrorToaster('You are not authorized user'))
    }
  }

  const handleEditPanelOpen = item => {
    if (clientdata.open) {
      setClientData({
        open: false,
        data: {}
      })
    } else {
      setClientData({
        open: true,
        data: item
      })
    }
  }

  const filterUserAssignedClients = key => {
    if (key) {
      setAssignToMe(true)
      setUserClientData(
        userClients.data.filter(
          client => client.agencyRepresentative === me._id
        )
      )
    } else {
      setAssignToMe(false)
      setUserClientData(userClients.data)
    }
  }

  const handleCompany = item => {
    dispatch(
      updateClientCompany(
        item._id,
        { newClientOwner: companyAnchorEl.data._id },
        (res, err) => {
          setCompanyAnchorEl({
            anchorEl: null,
            data: {}
          })
          if (!err) {
            dispatch(fireSuccessToaster('Company assigned successfully!'))
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  return (
    <div>
      <div className="text-right my-6">
        <Button
          variant="outlined"
          onClick={() => filterUserAssignedClients(!assignToMe)}
          size="small"
        >
          {!assignToMe ? 'assigned to me' : 'All'}
        </Button>
      </div>
      <>
        <TableContainer mb={6}>
          <Thead>
            <Th className="w-44 whitespace-nowrap">Name</Th>
            <Th className="w-44 whitespace-nowrap">Company</Th>
            <Th>Services</Th>
            <Th center>Account Manager</Th>
            <Th className="whitespace-nowrap">Payment Status</Th>
            <Th>Projects</Th>
            <Th className="text-center">Status</Th>
            {(access?.clients.update || access?.clients.delete) && (
              <Th className="whitespace-nowrap"></Th>
            )}
          </Thead>
          <Tbody>
            {userClients.loading ? (
              <TableDataLoading cols={7} />
            ) : visibleClients.length > 0 ? (
              visibleClients.map((item, index) => (
                <>
                  {search.length > 0 &&
                  item?.name.toLowerCase().includes(search.toLowerCase()) ? (
                    <Row
                      key={item._id}
                      item={item}
                      handleEditPanelOpen={handleEditPanelOpen}
                      handleDeleteClient={handleDeleteClient}
                      handleSwitch={handleSwitch}
                      toggleSwitchLoading={toggleSwitchLoading}
                    />
                  ) : search.length == 0 ? (
                    <Row
                      key={item._id}
                      item={item}
                      handleEditPanelOpen={handleEditPanelOpen}
                      handleDeleteClient={handleDeleteClient}
                      handleSwitch={handleSwitch}
                      toggleSwitchLoading={toggleSwitchLoading}
                      setCompanyAnchorEl={setCompanyAnchorEl}
                    />
                  ) : null}
                </>
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan="9"
                  className="text-left py-3 px-6 text-gray-500 font-normal"
                >
                  There are no clients
                </th>
              </Tr>
            )}
          </Tbody>
        </TableContainer>
        {!userClients.loading &&
          paginationTotalDataCount > totalClientsToShow && (
            <Pagination
              handlePagination={setPage}
              totalResultsCount={paginationTotalDataCount}
              visibleResultsCount={visibleClients.length}
              page={page}
              limit={totalClientsToShow}
            />
          )}
      </>

      <ClientEditPanel
        open={clientdata.open}
        onClose={handleEditPanelClose}
        data={clientdata.data}
      />
      <ClientDeleteModal
        open={deleteClient.open}
        handleDialog={handleDeleteClient}
        handleDeleteAction={handleClientDeleteData}
        loading={deleteClient.loading}
        checkList={checkList}
        setCheckList={setCheckList}
        type="client"
      />
      {/* <AddNewClient
        open={addClientSidePanelStatus}
        onClose={() => dispatch(toggleAddClinetSidePanel())}
      /> */}
      <CompanyDropdown
        anchorEl={companyAnchorEl.anchorEl}
        onClose={() => setCompanyAnchorEl({ anchorEl: null, data: {} })}
        handleDialogue={handleCompany}
        list={clientCompany.data
          .filter(company => !company.clientOwner)
          .map(company => ({
            ...company,
            label: company.brandName,
            logo: company.logo ?? ''
          }))}
        elmFor="company"
      />
    </div>
  )
}

const Row = ({
  item,
  handleEditPanelOpen,
  handleDeleteClient,
  handleSwitch,
  toggleSwitchLoading,
  setCompanyAnchorEl
}) => {
  const meData = useSelector(state => state.me.data)
  const history = useHistory()
  const access = useSelector(state => state.permission.data?.access)
  const teammates = useSelector(state => state.userTeammates.data)
  const representative = useMemo(() => {
    return [
      ...teammates,
      {
        _id: meData._id,
        name: meData.name,
        profileImage: meData.profileImage,
        email: meData.email
      }
    ].find(mate => mate._id === item.agencyRepresentative)
  }, [item.agencyRepresentative, teammates.length])

  const openEditClientPanel = () => handleEditPanelOpen(item)
  const OpenDeleteDialog = () => handleDeleteClient(item)
  const OpenSingleClientPage = e => {
    history.push(`${getUserPath(meData.role)}/clients/${item._id}`)
  }

  return (
    <Tr key={item._id}>
      <Td
        onClick={OpenSingleClientPage}
        className="px-6 py-4 whitespace-nowrap flex items-center space-x-2 cursor-pointer"
      >
        <ColoredAvatars user={item} size="medium" />
        <div className="flex flex-col ">
          <span className=" text-primary-mid-dark font-medium truncate">
            {item.name.length < 40
              ? item.name
              : `${item.name.substring(0, 39)}...`}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.email}</span>
        </div>
      </Td>
      <Td className="whitespace-nowrap text-left">
        {item.company?._id ? (
          <div className="flex flex-col ">
            <span className="text-primary-mid-dark font-medium">
              {item.company?.companyLegalName}
            </span>
            <span className="text-primary-mid-dark-2 text-xs">
              {item.company?.category}
            </span>
          </div>
        ) : access?.clients.update ? (
          <div>
            <CustomTooltip title="Assign Company" placement="top">
              <AddIcon
                className="h-5 w-5 p-1 cursor-pointer border rounded-full"
                onClick={e => {
                  e.stopPropagation()
                  setCompanyAnchorEl({ anchorEl: e.currentTarget, data: item })
                }}
              />
            </CustomTooltip>
          </div>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        <Pill variant="orange" style={{ fontSize: 12 }}>
          {item.payment?.length ?? 0}
        </Pill>
      </Td>
      <Td className="text-center">
        <div>
          {representative ? (
            <ColoredAvatars
              className="mx-auto"
              size="small"
              user={representative}
              tooltip={true}
            ></ColoredAvatars>
          ) : (
            '-'
          )}
        </div>
      </Td>
      <Td>
        {item.payment?.length ? (
          item.payment.every(obj => obj.status === 'paid') ? (
            <Pill variant={pillVariantObj['paid']}>paid</Pill>
          ) : (
            <Pill variant={pillVariantObj['open']}>open</Pill>
          )
        ) : (
          <span>-</span>
        )}
      </Td>
      <Td className="whitespace-nowrap w-0.5 truncate">
        {item?.assignedWorkspace?.length ? (
          <div>
            {item.assignedWorkspace.slice(0, 2).map((ws, index) => (
              <div className={index === 0 ? 'leading-6' : 'mt-2'}>
                {index === 0 ? (
                  <div className="leading-3 ">
                    <Pill variant="pink">{ws.name}</Pill>
                  </div>
                ) : (
                  <div className="flex items-center leading-3">
                    <Pill variant="blue">{ws.name}</Pill>
                    {item.assignedWorkspace?.length > 2 ? (
                      <Pill
                        variant="black"
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        +{item.assignedWorkspace?.length - 2}
                      </Pill>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div>-</div>
        )}
      </Td>
      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      {(access?.clients.update || access?.clients.delete) && (
        <Td className=" whitespace-nowrap">
          <PopupMenu
            menuItems={[
              {
                label: 'Edit Client',
                method: openEditClientPanel,
                icon: <EditIcon className="text-primary-main" />
              },
              {
                label: 'Settings',
                method: OpenSingleClientPage,
                icon: <SettingIcon className="text-primary-main" />
              },
              access.clients.delete && {
                label: 'Delete Client',
                method: OpenDeleteDialog,
                icon: <DeleteIcon className="text-primary-main" />
              }
            ]}
          />
        </Td>
      )}
    </Tr>
  )
}

export default ActiveClients
