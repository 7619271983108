import React from 'react'
// import {
//   Popover,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText
// } from '@material-ui/core'
// import { Menu, Transition } from '@headlessui/react'
// import { PersonOutline, PowerSettingsNew, Settings } from '@material-ui/icons'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { useHistory } from 'react-router-dom'
import userRoles, { getUserPath } from 'utils/userRoles'
// import headerStyles from './headerStyles'
import { PopupMenu } from 'global/globalComponents'
import { ReactComponent as UserIcon } from 'static/svg/user.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as LogoutIcon } from 'static/svg/logout.svg'
import { useDispatch } from 'react-redux'
import { LOGIN } from 'thunks/login/reduers'

const accountRoles = {
  [userRoles.USER_AGENCY]: {
    label: 'super admin',
    color: '#4d8dde'
  },
  [userRoles.AGENCY_MANAGER]: {
    label: 'manager',
    color: '#69d4ff'
  },
  [userRoles.AGENCY_EDITOR]: {
    label: 'editor',
    color: '#7a78d6'
  },
  [userRoles.AGENCY_ADMIN]: {
    label: 'admin',
    color: '#DB1F48'
  }
}

const HeaderProfileMenu = ({ meData, size = 'normal' }) => {
  // const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()
  // const classes = headerStyles()

  // function classNames(...classes) {
  //   return classes.filter(Boolean).join(' ')
  // }

  // const handleCloseMenu = () => {
  //   setAnchorEl(null)
  // }

  // const handleOpenMenu = (e) => {
  //   setAnchorEl(e.currentTarget)
  // }

  const handleLogout = () => {
    // localStorage.removeItem('authToken')
    // handleCloseMenu()
    // window.location.reload() // Giving manual reload on logout
    dispatch({ type: LOGIN.LOGOUT })
  }

  const goToSettings = () => {
    history.push(
      `${getUserPath(meData.role)}${
        ![
          userRoles.USER_AGENCY,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_ADMIN
        ].includes(meData.role)
          ? '/settings/profile'
          : '/settings/general'
      }`
    )
    // handleCloseMenu()
  }

  const goToProfile = () => {
    // handleCloseMenu()
    history.push(`${getUserPath(meData.role)}/settings/profile`)
  }

  return (
    <div>
      <div className="flex items-center">
        {accountRoles[meData.role] && (
          <div
            className="py-1 px-2 mr-2 text-tiny lg:text-xs text-white rounded-full capitalize"
            style={{ background: accountRoles[meData.role].color }}
          >
            {accountRoles[meData.role].label}
          </div>
        )}

        <PopupMenu
          targetComp={
            <ColoredAvatars
              user={meData}
              size={size}
              tooltip={false}
              className="cursor-pointer"
            />
          }
          menuItems={[
            {
              label: 'My Profile',
              icon: <UserIcon />,
              method: goToProfile
            },
            {
              label: 'Settings',
              icon: <SettingIcon />,
              method: goToSettings
            },
            {
              label: 'Logout',
              icon: <LogoutIcon style={{ transform: 'rotate(-90deg)' }} />,
              method: handleLogout
            }
          ]}
        />
      </div>
    </div>
  )
}

export default React.memo(HeaderProfileMenu)
