import React from 'react'
import { ReactComponent as StopWatchIcon } from 'static/svg/stopwatch.svg'
import { ReactComponent as PlusIcon } from 'static/svg/plus.svg'
import { PopupMenu } from 'global/globalComponents'

const TimerPopupOptions = ({ toggleTimerModal, toggleLogModal }) => {
  return (
    <PopupMenu
      targetComp={
        <button>
          <StopWatchIcon className="w-5 h-5 text-primary-main" />
        </button>
      }
      menuItems={[
        {
          label: 'Start Timer',
          icon: <StopWatchIcon />,
          method: toggleTimerModal
        },
        {
          label: 'Log Time',
          icon: <PlusIcon />,
          method: toggleLogModal
        }
      ]}
    />
  )
}

export default TimerPopupOptions
