import { useState } from 'react'
import axios from 'axios'
import clsx from 'clsx'
import { CircularProgress, TextField, Typography } from '@material-ui/core'
import IntlTelInput from 'react-intl-tel-input/dist/components/IntlTelInput'
import { useDispatch } from 'react-redux'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { Clientvenue_logo } from 'static/Images/index'
import TextInput from 'global/globalComponents/TextInput/TextInput'
import { userRoles } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
import { userSignup } from 'thunks/signup'
import { createFreePlan } from 'thunks/agencyPayment/actions'
import { Redirect } from 'react-router-dom'
import GeneralFormModal from './generalformModal'
import Testimonial from 'global/globalComponents/Testimonial/Testimonial'
import signupStyles from './signupStyles'

const FreeAgencySignup = () => {
  const classes = signupStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [response, setResponse] = useState({})
  const [domain, setDomain] = useState('')
  const [loading, setLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(true)
  const [userDomainStatus, setUserDomainStatus] = useState({
    message: undefined,
    loading: true,
    available: undefined
  })
  const [waitingModalStaus, setWaitingModalStatus] = useState({
    open: false,
    time: null
  })
  const [formData, setFormData] = useState({
    // firstName: '',
    // lastName: '',
    name: '',
    email: '',
    password: '',
    brandName: '',
    website: '',
    role: userRoles.USER_AGENCY,
    phoneNumber: ''
  })

  const handleDomain = (e) => {
    const value = e.target.value.trim().toLowerCase()
    if (/^([\w\d]-?)*$/.test(value)) {
      setDomain(value)
    }
  }

  const handleSubmitDomain = async () => {
    setFormData((prev) => ({ ...prev, brandName: '' }))
    if (domain.length > 0) {
      setUserDomainStatus((props) => ({
        ...props,
        loading: true,
        message: 'Checking',
        available: undefined
      }))
      try {
        const res = await axios({
          method: 'GET',
          url: `/user/checkagencydomain?domain=${domain}.clientvenue.com`,
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if (res.data.domainExist) {
          setUserDomainStatus({
            loading: false,
            message: 'Not Available',
            available: false
          })
          dispatch(fireErrorToaster(`${domain} domain already exist.`))
        } else {
          setFormData((prev) => ({ ...prev, brandName: domain }))
          setUserDomainStatus({
            loading: false,
            message: 'Available',
            available: true
          })
        }
      } catch (err) {
        setUserDomainStatus({
          loading: false,
          message: 'Not Available',
          available: false
        })
      }
    } else {
      setUserDomainStatus((props) => ({
        ...props,
        message: undefined,
        available: undefined
      }))
    }
  }

  const formInputHandler = (e) => {
    const { name, value } = e.target
    setFormData((oldState) => ({ ...oldState, [name]: value }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const phoneNumberIputHandler = (valid, phone, country) => {
    setFormData((oldData) => ({
      ...oldData,
      phoneNumber: phone.length > 0 ? '+' + country.dialCode + ' ' + phone : ''
    }))
  }

  const modalCloseHandler = () => {
    setOpen(false)
  }

  const userSignupHandler = (e) => {
    e.preventDefault()
    if (!userDomainStatus.available) {
      return dispatch(fireErrorToaster('Please enter the valid domain'))
    }
    if (!validPasswordHandler()) {
      setPasswordValid(false)
      return
    }
    if (formData.password !== confirmPassword) {
      setLoading(false)
      return dispatch(
        fireErrorToaster('Password does not match with confirm password')
      )
    }
    setLoading(true)
    userSignup(
      {
        ...formData,
        source: window.location.href,
        agencyDomain: domain + '.clientvenue.com'
      },
      signupCallback
    )
  }

  const signupCallback = async (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      const obj = {
        email: formData.email,
        plan: {
          active: true,
          amount: 0,
          amount_decimal: '0',
          currency: 'usd',
          metadata: {
            serviceQuota: '3',
            teammateQuota: '3',
            storageQuota: '0.8',
            clientQuota: '3'
          },
          nickname: 'Free Plan'
        }
      }

      try {
        // const freePlanres = await createFreePlan(obj, res.login.token)
        // setLoading(false)
        // setWaitingModalStatus({ open: true, time: 15 })
        setResponse({
          freePlansObj: obj,
          token: res?.login?.token,
          id: res?.profile?._id,
          domain: res?.agencyDomain,
          path: res?.path,
          brandName: res?.profile?.brand?.brandName
        })
        setOpen(true)
        dispatch(fireSuccessToaster('Signup successful'))

        // const intervalTimer = setInterval(() => {
        //   setWaitingModalStatus((prev) => ({
        //     ...prev,
        //     time: prev.time - 1
        //   }))
        // }, 1000)

        // setTimeout(() => {
        //   window.open(
        //     `https://${freePlanres.agencyDomain}${
        //       freePlanres.path
        //     }?signup=true&auth_token=${res.login.token.split(' ')[1]}`,
        //     '_self'
        //   )
        //   clearInterval(intervalTimer)
        // }, 15000)
      } catch (err) {
        dispatch(fireErrorToaster('Unsuccessful'))
        return
      }
    }
  }

  return (
    <div>
      {/* {window.location.href === 'https://app.clientvenue.com/free-trial' ? ( */}
      <div className="w-screen h-screen grid grid-cols-5">
        <div
          className={`${classes.formWrapper} col-span-5 md:col-span-3 p-10 w-full mx-auto`}
        >
          <div className="flex items-center gap-4">
            <img
              className="h-12 w-12"
              src={Clientvenue_logo}
              alt="Clientvenue"
            />
            <div>
              <h2 className="text-2xl font-medium">
                Signup for a Free Account
              </h2>
              <p className="text-xs text-gray-800">
                Trial period is limited to 14 days
              </p>
            </div>
          </div>
          <form
            onSubmit={userSignupHandler}
            className={`${classes.signupForm} mt-8 space-y-4 bg-white shadow rounded p-8`}
          >
            <div>
              <FormLabel required htmlFor="name">
                Name
              </FormLabel>
              <TextField
                onChange={formInputHandler}
                placeholder="Name"
                required
                id="name"
                name="name"
                value={formData.name}
                className="w-full"
              />
            </div>
            <div>
              <FormLabel required htmlFor="email">
                Email
              </FormLabel>
              <TextField
                onChange={formInputHandler}
                required
                value={formData.email}
                placeholder="Email"
                id="email"
                className="w-full"
                name="email"
              />
            </div>
            <div>
              <FormLabel required htmlFor="phone">
                Phone Number
              </FormLabel>
              <IntlTelInput
                name="phone"
                onPhoneNumberChange={phoneNumberIputHandler}
                style={{
                  border: '1px solid hsl(0,0%,80%)',
                  borderRadius: '3px',
                  width: '100%',
                  height: '2.1rem',
                  padding: '0.2rem',
                  fontSize: '14px',
                  fontWeight: 'normal'
                }}
                placeholder="Phone Number"
                className={classes.signinInput}
              />
            </div>
            <div>
              <FormLabel required htmlFor="agencyDomain">
                Agency Domain
              </FormLabel>
              <div className="flex h-8" style={{ margin: '0 0 1.425rem' }}>
                <div
                  className="flex flex-row p-0 m-0 rounded-sm pr-4 items-center"
                  style={{
                    border: '1px solid hsl(0,0%,80%)',
                    width: '100%',
                    height: '2.375rem',
                    borderRadius: '0.375rem'
                  }}
                >
                  <TextInput
                    required
                    value={domain}
                    inputProps={{ onBlur: handleSubmitDomain }}
                    placeholder="Agency Domain"
                    onChange={handleDomain}
                    style={{
                      border: 'none',
                      margin: '0px',
                      height: '2rem',
                      textTransform: 'lowercase'
                    }}
                  />
                  <Typography
                    style={{
                      alignSelf: 'center',
                      fontSize: '12px',
                      color: '#778ca2'
                    }}
                  >
                    .clientvenue.com
                  </Typography>
                </div>
                {userDomainStatus.message && (
                  <div className="flex items-center gap-1 ml-4">
                    {userDomainStatus.loading && (
                      <CircularProgress value={0} size={15} />
                    )}
                    <p
                      className={clsx('font-medium text-xs', {
                        'text-red-500': !userDomainStatus.available,
                        'text-green-500': userDomainStatus.available,
                        'text-blue-600': userDomainStatus.loading
                      })}
                    >
                      {userDomainStatus.message}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div>
              <FormLabel required htmlFor="company">
                Company Name
              </FormLabel>
              <TextField
                required
                placeholder="Company name"
                className="w-full"
                name="brandName"
                id="company"
                value={formData.brandName}
                onChange={formInputHandler}
              />
            </div>

            <div>
              <FormLabel required>Password</FormLabel>
              <TextField
                required
                placeholder="Password"
                name="password"
                className="w-full"
                value={formData.password}
                type="password"
                onChange={formInputHandler}
                onBlur={validPasswordHandler}
              />
              {formData.password && !passwordValid && (
                <p className="text-xs mt-1" style={{ color: '#f44336' }}>
                  Password should be a combination of an uppercase/lowercase
                  letter, a number, a symbol and length between 8 to 20.
                </p>
              )}
            </div>

            <div>
              <FormLabel required>Confirm Password</FormLabel>
              <TextField
                required
                placeholder="Confirm Password"
                name="confirm password"
                className="w-full"
                value={confirmPassword}
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <div>
              <LoadingButton
                onClick={userSignupHandler}
                style={{ width: '100%' }}
                loading={loading}
              >
                Start For Free
              </LoadingButton>
            </div>
          </form>
        </div>

        <div className="hidden md:flex flex-col justify-center md:col-span-2 bg-primary-light">
          <div className={`${classes.testimonialWrapper} px-10`}>
            <Testimonial />
          </div>
        </div>

        <GeneralFormModal
          open={open}
          handleClose={modalCloseHandler}
          data={response}
        />

        {/* <Dialog
            open={waitingModalStaus.open}
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogContent>
              <h4 className="text-2xl text-center mt-12">
                We're creating your personalized domain. Please wait for a few
                seconds...
              </h4>
              <p className="text-lg text-center font-medium py-4">
                {waitingModalStaus.time}
              </p>
              <p className="text-sm font-medium text-center mt-12">
                Please don't refresh or close the session
              </p>
            </DialogContent>
          </Dialog> */}
      </div>
      {/* ) : (
        <Redirect to="/login" />
      )} */}
    </div>
  )
}

export default FreeAgencySignup
