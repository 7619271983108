import { useState, useEffect } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { useSelector } from 'react-redux'
import userRoles from 'utils/userRoles'
import { ColoredAvatars } from 'global/globalComponents'
import { Card } from 'global/globalComponents'
import { ReactComponent as InviteIcon } from 'static/svg/plane.svg'
import { getRoleBadge } from 'utils'

const Teammates = ({
  openInviteTeammate,
  readOnly = false,
  workspace = {},
  workspacePermission = {}
}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const me = useSelector((state) => state.me.data)
  // const permission = useContext(SingleWorkspaceContext)
  const [agencyTeammates, setAgencyTeammates] = useState([])
  const [clientTeammates, setClientTeammates] = useState([])

  useEffect(() => {
    if (workspace?._id) {
      // const filteredAgencyTeammates = workspace.assignedAgencyTeam.filter(
      //   (item) =>
      //     item.role !== userRoles.USER_AGENCY &&
      //     item.role !== userRoles.AGENCY_ADMIN
      // )
      const filteredAgencyTeammates = [
        userRoles.USER_AGENCY,
        userRoles.AGENCY_ADMIN
      ].includes(me.role)
        ? workspace.assignedAgencyTeam?.filter(
            (mate) => mate.role !== userRoles.USER_AGENCY
          )
        : workspace.assignedAgencyTeam?.filter(
            (mate) =>
              ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
                mate.role
              )
          )

      setAgencyTeammates(filteredAgencyTeammates)
      setClientTeammates([...workspace.assignedClientTeam])
    }
  }, [workspace?.assignedAgencyTeam, workspace?.assignedClientTeam])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  return (
    <Card className="h-full p-4">
      <header className="flex justify-between items-center">
        <h4 className="text-sm font-semibold text-primary-dark">
          Team Members
        </h4>
        {!readOnly && workspacePermission.update && (
          <button
            onClick={openInviteTeammate}
            className="text-blue-700 flex items-center"
          >
            <InviteIcon />
            <span className="ml-1 font-medium text-sm">Invite</span>
          </button>
        )}
      </header>
      <div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          {['Teammates', 'Clients'].map((item, index) => (
            <Tab label={item} key={index} />
          ))}
        </Tabs>
        <div
          className="pb-6 pt-4 px-2 overflow-y-auto"
          style={{ maxHeight: 235 }}
        >
          {selectedTab === 0 ? (
            <Members members={agencyTeammates} readOnly={readOnly} />
          ) : (
            <Members members={clientTeammates} readOnly={readOnly} />
          )}
        </div>
      </div>
    </Card>
  )
}

const Members = ({ members, readOnly }) => {
  return (
    <>
      {members.length ? (
        <div className="space-y-4">
          {members.map((item, index, arr) => (
            <Member
              key={index}
              mate={item}
              isLastItem={arr.length - 1 === index}
              readOnly={readOnly}
            />
          ))}
        </div>
      ) : (
        <p className="text-xs text-gray-500 pl-1 py-3">No members found</p>
      )}
    </>
  )
}

const Member = ({ mate, isLastItem, readOnly }) => {
  const meData = useSelector((state) => state.me.data)

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center mr-2">
        <ColoredAvatars user={mate.user} />
        <div className="flex flex-col ml-3">
          <div className="flex items-center">
            <span className="mr-2 text-custom-table-primaryText capitalize font-medium text-xs">
              {mate.user.name}
            </span>
          </div>
          {!readOnly
            ? (meData.role < userRoles.USER_CLIENT ||
                mate.role >= userRoles.USER_CLIENT) && (
                <span className="text-gray-500 text-xs">{mate.user.email}</span>
              )
            : null}
        </div>
      </div>
      <div>{getRoleBadge(mate.role)}</div>
    </div>
  )
}

export default Teammates
