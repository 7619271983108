import { useContext, useEffect, useState, memo } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField
} from '@material-ui/core'
import { CustomTooltip, FormLabel } from 'global/globalComponents'
import { OnboardingContext } from '../onboardingReducer'
import { CheckBoxField } from 'global/globalComponents'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ONBOARDING } from '../onboardingReducer'
// import { v4 as uuidv4 } from 'uuid'
import SectionHeader from './SectionHeader'
import ObjectID from 'bson-objectid'
import { Container, Draggable } from 'react-smooth-dnd'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'

const intakeFormFieldOptions = [
  { label: 'Text field', value: 'text' },
  { label: 'File field', value: 'file' },
  { label: 'Email field', value: 'email' },
  { label: 'Date field', value: 'date' },
  { label: 'Number field', value: 'number' },
  { label: 'Description field', value: 'textarea' }
]

const IntakeForm = (props) => {
  const {
    id: sectionId,
    sectionHeading,
    description,
    fields: formFields
  } = props
  const { onboardingDispatch } = useContext(OnboardingContext)
  const [fields, setFields] = useState([...formFields])
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setFields([...formFields])
  }, [props])

  const toggleAddFieldPopup = (e) => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }

  const handleAddField = (data) => {
    onboardingDispatch({
      type: ONBOARDING.ADD_INTAKE_FIELD,
      payload: {
        id: sectionId,
        value: {
          id: ObjectID().toString(),
          label: 'New field',
          type: data.value,
          required: false
        }
      }
    })

    toggleAddFieldPopup()
  }

  const onFieldDrop = (dropResult) => {
    onboardingDispatch({
      type: ONBOARDING.INTAKE_FIELD_MOVE,
      payload: {
        sectionId,
        dropResult
      }
    })
  }

  return (
    <div>
      <SectionHeader
        sectionHeading={sectionHeading}
        description={description}
        sectionId={sectionId}
      />

      <div className="border p-6 rounded">
        <Container onDrop={onFieldDrop}>
          {fields.map((field) => (
            <Draggable key={field.id}>
              <Field sectionId={sectionId} {...field} />
            </Draggable>
          ))}
        </Container>
        <div className="text-right py-4">
          <CustomTooltip title="Add new field" placement="top">
            <Button
              size="small"
              onClick={toggleAddFieldPopup}
              startIcon={<AddIcon />}
            >
              New Field
            </Button>
          </CustomTooltip>
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={toggleAddFieldPopup}
      >
        <List disablePadding dense>
          {intakeFormFieldOptions.map((item, index) => (
            <ListItem key={index} onClick={() => handleAddField(item)} button>
              <ListItemText
                // classes={{ root: classes.listTextItemRoot }}
                primary={item.label}
                text
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

// ======================== FIELD =======================
const Field = ({ type, label, id, sectionId, required }) => {
  const { onboardingDispatch } = useContext(OnboardingContext)
  const [fieldLabel, setFieldLabel] = useState({
    edit: false,
    value: ''
  })
  const [fieldRequired, setFieldRequired] = useState(required)

  useEffect(() => {
    setFieldLabel({ edit: false, value: label })
  }, [label])

  useEffect(() => {
    setFieldRequired(required)
  }, [required])

  const toggleEditLabel = () => {
    setFieldLabel((prev) => ({ edit: !prev.edit, value: prev.value }))
  }

  const handleLabelChange = (e) => {
    setFieldLabel({ edit: true, value: e.target.value })
  }

  const saveLabelText = (e) => {
    // sectionId, fieldId, prop, value
    if (fieldLabel.value !== '') {
      onboardingDispatch({
        type: ONBOARDING.UPDATE_INTAKE_FIELD,
        payload: {
          sectionId,
          fieldId: id,
          prop: 'label',
          value: fieldLabel.value
        }
      })
    }
    toggleEditLabel()
  }

  const toggleRequiredField = (e) => {
    onboardingDispatch({
      type: ONBOARDING.UPDATE_INTAKE_FIELD,
      payload: {
        sectionId,
        fieldId: id,
        prop: 'required',
        value: e.target.checked
      }
    })
  }

  const removeField = () => {
    onboardingDispatch({
      type: ONBOARDING.REMOVE_INTAKE_FIELD,
      payload: {
        sectionId,
        fieldId: id
      }
    })
  }

  return (
    <div className="mb-6">
      <div>
        {fieldLabel.edit ? (
          <input
            autoFocus
            type="text"
            value={fieldLabel.value}
            onChange={handleLabelChange}
            onBlur={saveLabelText}
            className="text-sm p-1"
          />
        ) : (
          <div className="flex items-center" style={{ cursor: 'grab' }}>
            <div>
              <FormLabel
                onClick={toggleEditLabel}
                className="block cursor-pointer text-sm"
                required={required}
              >
                {fieldLabel.value}
              </FormLabel>
            </div>
            <div className="ml-auto flex items-center">
              <CustomTooltip
                title="Mark this field as required"
                placement="top"
              >
                <div>
                  <FormControlLabel
                    className="flex items-center"
                    control={
                      <Checkbox
                        onChange={toggleRequiredField}
                        checked={fieldRequired}
                        icon={
                          <CheckBoxOutlineBlank style={{ fontSize: '20px' }} />
                        }
                        checkedIcon={<CheckBox style={{ fontSize: '20px' }} />}
                      />
                    }
                    label={
                      <span className="-ml-2 text-xs font-medium text-gray-700 truncate leading-9">
                        Required
                      </span>
                    }
                  />
                </div>
              </CustomTooltip>
              <CustomTooltip title="Remove this field" placement="top">
                <button className="text-gray-500" onClick={removeField}>
                  <CloseIcon fontSize="small" />
                </button>
              </CustomTooltip>
            </div>
          </div>
        )}
      </div>
      {type === 'textarea' ? (
        <TextField
          multiline
          rows={4}
          fullWidth={true}
          disabled={true}
          inputProps={{ style: { cursor: 'grab' } }}
        />
      ) : type === 'file' ? (
        <Button component="span">Choose File</Button>
      ) : (
        <TextField
          type={type}
          disabled={true}
          fullWidth={true}
          inputProps={{ style: { cursor: 'grab' } }}
        />
      )}
    </div>
  )
}

export default memo(IntakeForm)
