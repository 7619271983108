import { useContext, useEffect, useState } from 'react'
import {
  CheckBoxField,
  CustomPopper,
  LoadingButton,
  RoundedTextInput
} from 'global/globalComponents'
import {
  Button,
  createStyles,
  FormLabel,
  makeStyles,
  Popover,
  TextField
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
import { createNewCalendarEvents } from 'thunks/calender/action'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { format } from 'date-fns'
import TimeDropdown from 'global/globalComponents/TimeDropdown/TimeDropdown'
import { ToggleOnRounded } from '@material-ui/icons'
import useCalendarFunctions from './useCalendarFunctions'

const EventCreatePopover = ({ anchorEl, data, handleClose }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [eventData, setEventData] = useState({
    eventType: '',
    title: '',
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    calender: '',
    allDay: true
  })
  const [time, setTime] = useState({
    startTime: '',
    endTime: ''
  })
  const [timeAnchorEl, setTimeAnchorEl] = useState({
    anchorEl: null,
    name: 'startTime'
  })
  const eventsOptions = [
    { label: 'Holiday', value: 'holiday', color: '#7986cb' },
    { label: 'Nationl Holiday', value: 'national holidays', color: '#0b8043' },
    { label: 'Leave', value: 'leaves', color: '#4285f4' },
    { label: 'Medical', value: 'medical', color: '#ed5757' },
    { label: 'Meeting', value: 'meeting', color: '#3f51b5' },
    { label: 'Event', value: 'event', color: '#2ecd6f' },
    { label: 'Other', value: 'other', color: '#ff9900' }
  ]
  const {
    setTimeInTwelveHoursFormat,
    setTimeInTwentyFourHoursFormat,
    validateTime
  } = useCalendarFunctions()

  useEffect(() => {
    if (anchorEl) {
      setEventData(prev => ({
        ...prev,
        start: new Date(data.start).toISOString(),
        end: new Date(data.end).toISOString(),
        calender: data.calendar,
        allDay: data.allDay
      }))
    }
  }, [data, anchorEl])

  // useEffect(() => {
  //   if (!time.startTime) return
  //   const timer = setTimeout(() => {
  //     if (
  //       !(
  //         /^(?:[01]?\d|2[0-3])(?::[0-5]\d){1,2}$/.test(time.startTime) ||
  //         /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time.startTime) ||
  //         /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(time.startTime)
  //       )
  //     ) {
  //       dispatch(fireErrorToaster('Please enter valid time'))
  //     }
  //   }, 1000)
  //   return () => clearTimeout(timer)
  // }, [time.startTime])

  // useEffect(() => {
  //   if (!time.endTime) return
  //   const timer = setTimeout(() => {
  //     if (
  //       !(
  //         !/^(?:[01]?\d|2[0-3])(?::[0-5]\d){1,2}$/.test(time.endTime) ||
  //         /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time.endTime) ||
  //         /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(time.endTime)
  //       )
  //     ) {
  //       dispatch(fireErrorToaster('Please enter valid time'))
  //     }
  //   }, 1000)
  //   return () => clearTimeout(timer)
  // }, [time.endTime])

  const handleCreateEvent = e => {
    e.preventDefault()
    if (eventData.title.trim() === '') return
    if (!eventData.eventType)
      return dispatch(fireErrorToaster('Please select the event type'))

    // if (eventData.allDay) {
    //   eventData.start = new Date(new Date(eventData.start).setHours(0, 0, 0, 0))
    //   eventData.end = new Date(new Date(eventData.end).setHours(23, 59, 59, 0))
    // } else {
    //   if (validateTime(time.startTime) && validateTime(time.endTime)) {
    //     eventData.start = new Date(
    //       `${new Date(
    //         eventData.start
    //       ).toDateString()} ${setTimeInTwentyFourHoursFormat(time.startTime)}`
    //     ).toISOString()
    //     eventData.end = new Date(
    //       `${new Date(
    //         eventData.end
    //       ).toDateString()} ${setTimeInTwentyFourHoursFormat(time.endTime)}`
    //     ).toISOString()
    //   } else {
    //     return
    //   }
    // }

    // if (
    //   new Date(eventData.start).getTime() > new Date(eventData.end).getTime()
    // ) {
    //   return dispatch(
    //     fireErrorToaster("Event's end time must be greater than start Time")
    //   )
    // }

    setLoading(true)
    dispatch(
      createNewCalendarEvents(
        {
          ...eventData,
          eventType: eventData.eventType.value,
          color: eventData.eventType.color
        },
        createNewEventCallback
      )
    )
  }

  const createNewEventCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Event created successfully'))
      handleModalClose()
    }
  }

  const handleSelectEvent = e => {
    setEventData(prev => ({ ...prev, eventType: e }))
  }

  const handleTitleChange = e => {
    setEventData(prev => ({ ...prev, title: e.target.value }))
  }

  const handleDateChange = (date, key) => {
    if (
      key === 'start' &&
      new Date(date).getTime() > new Date(eventData.end).getTime()
    ) {
      const timeDiff =
        new Date(eventData.end).getTime() - new Date(eventData.start).getTime()
      setEventData(prev => ({
        ...prev,
        start: date.toISOString(),
        end: new Date(new Date(date).getTime() + timeDiff).toISOString()
      }))
    } else {
      setEventData(prev => ({ ...prev, [key]: date.toISOString() }))
    }
  }

  const handleAllDayEvent = () => {
    setEventData(prev => ({ ...prev, allDay: !prev.allDay }))
    if (eventData.allDay) {
      setTime({
        startTime: setTimeInTwelveHoursFormat(eventData.start),
        endTime: setTimeInTwelveHoursFormat(
          new Date(new Date(eventData.end).setHours(23, 59, 59))
        )
      })
    }
  }

  const handleTimeInput = e => {
    const { value, name } = e.target
    setTime(prev => ({ ...prev, [name]: value }))
  }

  const handleSelectTime = (value, name) => {
    console.log(value, 'event')
    setTime(prev => ({ ...prev, [name]: value }))
    toggleTimePopup()
  }

  const toggleTimePopup = e => {
    e?.stopPropagation()
    if (!timeAnchorEl.anchorEl) {
      setTimeAnchorEl({ anchorEl: e.currentTarget, name: e?.target?.name })
    } else {
      setTimeAnchorEl({ anchorEl: null, name: 'startTime' })
    }
  }

  const handleModalClose = () => {
    handleClose()
    setEventData({
      eventType: '',
      title: '',
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      calender: '',
      allDay: true
    })
    setTime({ startTime: '', endTime: '' })
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      onClose={handleModalClose}
      anchorEl={anchorEl}
      classes={{ paper: classes.paper }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <div className="w-72 p-4">
        <h5 className="mb-4 font-bold">Create Event</h5>
        <form onSubmit={handleCreateEvent}>
          <TextField
            value={eventData.title}
            onChange={handleTitleChange}
            autoFocus
            size="small"
            className="w-full "
            placeholder="Enter Title"
          />
          <div className="mt-4">
            <Select
              menuPlacement="top"
              styles={reactSelectCustomStyles}
              options={eventsOptions}
              onChange={handleSelectEvent}
              value={eventData.eventType}
              placeholder="Select Event"
              className="border-0 border-none w-full"
            />
          </div>

          {/* <div
            className={`${
              eventData.allDay
                ? 'flex items-center space-x-2 text-smaller justify-between'
                : ''
            }`}
          >
            <div className="flex items-center space-x-2 text-xs mt-4">
              {!eventData.allDay && (
                <span className="text-xs font-medium w-7">Start</span>
              )}
              <div className="flex bg-custom-gray-light-4">
                <DatePicker
                  onChange={date => handleDateChange(date, 'start')}
                  selected={eventData.start ? new Date(eventData.start) : null}
                  customInput={
                    eventData.start ? (
                      <input
                        className=" py-1 px-2 bg-custom-gray-light-4"
                        style={{
                          width: '100px'
                        }}
                      />
                    ) : (
                      <CalenderIcon
                        className="text-base text-gray-600"
                        width="2.5em"
                      />
                    )
                  }
                  placeholderText="Select Date"
                  dateFormat="d MMM, yyyy"
                />
                {!eventData.allDay && (
                  <input
                    name="startTime"
                    onChange={handleTimeInput}
                    onClick={toggleTimePopup}
                    value={time.startTime}
                    className="w-20 text-xs py-1 px-2 bg-custom-gray-light-4"
                  />
                )}
              </div>
            </div>
            {eventData.allDay && <span className="font-medium mt-4">to</span>}
            <div className="flex items-center space-x-2 text-xs mt-4">
              {!eventData.allDay && (
                <span className="text-xs font-medium w-7">End</span>
              )}
              <div className="flex bg-custom-gray-light-4">
                <DatePicker
                  onChange={date => handleDateChange(date, 'end')}
                  selected={eventData.end ? new Date(eventData.end) : null}
                  customInput={
                    eventData.end ? (
                      <input
                        className=" py-1 px-2 bg-custom-gray-light-4"
                        style={{
                          width: '100px'
                        }}
                      />
                    ) : (
                      <CalenderIcon
                        className="text-base text-gray-600"
                        width="2.5em"
                      />
                    )
                  }
                  minDate={new Date(eventData.start)}
                  placeholderText="Select Date"
                  dateFormat="d MMM, yyyy"
                />
                {!eventData.allDay && (
                  <input
                    name="endTime"
                    onChange={handleTimeInput}
                    value={time.endTime}
                    onClick={toggleTimePopup}
                    className="w-20 text-xs py-1 px-2 bg-custom-gray-light-4"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="mt-2">
            <CheckBoxField
              name="All Date"
              label="All Day"
              onChange={handleAllDayEvent}
              checked={eventData.allDay}
            />
          </div> */}
        </form>
        <div className="flex justify-end space-x-2 mt-4">
          <Button variant="outlined" size="small" onClick={handleModalClose}>
            Cancel
          </Button>
          <LoadingButton
            size="small"
            loading={loading}
            onClick={handleCreateEvent}
          >
            Create
          </LoadingButton>
        </div>
      </div>
      <TimeDropdown
        anchorEl={timeAnchorEl.anchorEl}
        onClose={toggleTimePopup}
        handleSelectTime={handleSelectTime}
        name={timeAnchorEl.name}
      />
    </Popover>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      overflow: 'visible',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)'
    }
  })
)

export default EventCreatePopover
