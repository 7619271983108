import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { CustomModal } from 'global/globalComponents/CustomModal'
import Step3 from 'components/Settings/Import/Step3'
import Step1 from 'components/Settings/Import/Step1'
import { createProjectFromImport } from 'thunks/workspace/actions'
import ImportedDataTable from 'components/Settings/Import/ImportedDataTable'
import TemplateImportedDataTable from './TemplateImportedDataTable'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { useHistory } from 'react-router-dom'
import { getUserPath } from 'utils/userRoles'
import TemplateInputs from './TemplateInputs'
import { createProjectTemplate } from 'thunks/projectTemplate/action'
import CustomFieldsMapping from './CustomFieldsMapping'

const colorOptions = [
  '#8d84e8',
  '#f06a6a',
  '#aecf55',
  '#ec8d71',
  '#f1bd6c',
  '#f8df72',
  '#5da283',
  '#4ecbc4',
  '#4573d2',
  '#b36bd4',
  '#f9aaef',
  '#f26fb2',
  '#fc979a',
  '#6d6e6f',
  '#c7c4c4',
  '#9ee7e3'
]

const TemplateDataMappingModal = ({ open = true, onClose, fileData }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector((state) => state.me.data)
  const [columnHeader, setColumnHeader] = useState([])
  const [taskData, setTaskData] = useState([])
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [mappedColumn, setMappedColumn] = useState({})
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    type: 'public',
    content: { private: false, section: [] }
  })

  useEffect(() => {
    setColumnHeader(fileData[0]?.map((el) => 'false'))
  }, [fileData])

  const handleDynamicFieldKey = () => {
    let customFieldsName = Object.values(mappedColumn)
      .filter((el) => el.customField)
      .map((el) => el.value)

    let taskColumns = {}

    Object.values(mappedColumn)
      .filter((el) => el.customField)
      .forEach((column) => {
        const data = {
          id: column.id,
          name: column.value,
          datatype: column.dataType
        }
        if (['singleSelect', 'multiSelect'].includes(column.dataType)) {
          data.metaData = {
            isMultiSelect: column.dataType === 'multiSelect',
            options: Object.keys(column.options)
          }
        }
        taskColumns[column.value] = data
      })

    const newData = taskData.map((task) => {
      let dynamicField = []
      Object.keys(task).forEach((key) => {
        const ind = columnHeader.indexOf(key)

        if (customFieldsName.includes(key) && task[key]) {
          if (mappedColumn[ind].dataType === 'singleSelect') {
            if (!(task[key] in taskColumns[key].metaData.options)) {
              taskColumns[key].metaData.options[task[key]] = {
                label: task[key],
                value: task[key].toLowerCase()
              }
            }
            dynamicField.push({
              taskColumnId: mappedColumn[ind].id,
              value: { label: task[key], value: task[key].toLowerCase() }
            })
          } else if (mappedColumn[ind].dataType === 'multiSelect') {
            const newVal = []
            task[key]
              .split(',')
              .filter((el) => el)
              .map((el) => {
                if (!(el in taskColumns[key].metaData.options)) {
                  taskColumns[key].metaData.options[el] = {
                    label: el,
                    value: el.toLowerCase()
                  }
                }
                newVal.push({ label: el, value: el.toLowerCase() })
              })
            dynamicField.push({
              taskColumnId: mappedColumn[ind].id,
              value: newVal
            })
          } else if (
            ['person', 'email', 'attachment'].includes(
              mappedColumn[ind].dataType
            )
          ) {
          } else {
            dynamicField.push({
              taskColumnId: mappedColumn[ind].id,
              value: task[key]
            })
          }
        }
      })
      return { ...task, dynamicField: dynamicField }
    })

    return { newTaskData: newData, taskColumns: taskColumns }
  }

  const handleTaskData = (newTaskData) => {
    if (columnHeader.includes('taskStatus')) {
      let sectionData = []

      newTaskData.forEach((task) => {
        if (task.taskStatus && !sectionData.includes(task.taskStatus)) {
          sectionData.push(task.taskStatus)
        }
      })
      sectionData = sectionData.map((section, index) => {
        const sectionId = parseInt(Math.random() * 10000000000)
        const data = {
          id: sectionId,
          label: section.toLowerCase(),
          order: index + 1,
          color: colorOptions[index]
        }
        if (columnHeader.includes('subTask')) {
          let sectionTasks = newTaskData.filter(
            (task) => task.taskStatus === section && !task.subTask
          )
          const subtasks = newTaskData.filter((task) => task.subTask)
          sectionTasks = sectionTasks.map((item) => ({
            ...item,
            sectionId: sectionId,
            taskId: parseInt(Math.random() * 10000000000),
            subTask: subtasks.filter((el) => el.subTask === item?.title)
          }))
          data.tasks = sectionTasks
        } else {
          data.tasks = newTaskData
            .filter((task) => task.taskStatus === section)
            .map((task) => ({
              ...task,
              sectionId: sectionId,
              taskId: parseInt(Math.random() * 10000000000)
            }))
        }
        return data
      })
      return sectionData
    } else {
      const sectionId = parseInt(Math.random() * 10000000000)
      const data = {
        id: sectionId,
        label: 'untitled',
        order: 1,
        color: colorOptions[0],
        task: newTaskData.map((task) => ({
          ...task,
          sectionId: sectionId,
          taskId: parseInt(Math.random() * 10000000000)
        }))
      }
      return [data]
    }
  }

  const handleColumnMapping = (e, index, item) => {
    const updatedData = [...columnHeader]
    updatedData[index] = e.customField ? `${item.toLowerCase()}` : e.value
    setColumnHeader(updatedData)
    setMappedColumn((prev) => ({
      ...prev,
      [index]: e.customField
        ? {
            ...e,
            id: parseInt(Math.random() * 100000000000),
            label: `${item}`,
            value: `${item.toLowerCase()}`,
            options: {}
          }
        : e
    }))
  }

  const handleReview = () => {
    if (!columnHeader.includes('title')) {
      return dispatch(fireErrorToaster('Title is required'))
    }

    const newColumnHeader = columnHeader.filter((el) => el !== 'false')
    const duplicateField = newColumnHeader.filter(
      (item, ind) => newColumnHeader.indexOf(item) !== ind
    )

    if (duplicateField.length) {
      return dispatch(
        fireErrorToaster('Sorry, One field can be mapped only once!.')
      )
    }

    const removedIndex = []
    columnHeader.map((item, index) => {
      if (item === 'false') {
        removedIndex.push(index)
      }
    })

    const updatedData = fileData.slice(1).map((item) => {
      const taskObj = {}

      const filterData = item.filter(
        (el, index) => !removedIndex.includes(index)
      )
      filterData.forEach((el, ind) => {
        if (newColumnHeader[ind] === 'title') {
          taskObj[newColumnHeader[ind]] = el ? el : 'Untitled'
        } else {
          taskObj[newColumnHeader[ind]] = el
        }
      })
      return taskObj
    })
    setTaskData([...updatedData])
    setStep(2)
  }

  const handleSave = () => {
    let tempData = {}
    if (
      Object.values(mappedColumn).filter((column) => column.customField).length
    ) {
      const { newTaskData, taskColumns } = handleDynamicFieldKey()
      const Sections = handleTaskData(newTaskData)
      const taskColumn = Object.values(taskColumns).map((option) => {
        if (['singleSelect', 'multiSelect'].includes(option.datatype)) {
          return {
            ...option,
            metaData: {
              ...option.metaData,
              options: Object.values(option.metaData.options)
            }
          }
        } else {
          return { ...option }
        }
      })
      tempData = {
        ...formData,
        content: {
          ...formData.content,
          taskColumn: taskColumn,
          section: Sections
        }
      }
    } else {
      const Sections = handleTaskData(taskData)
      tempData = {
        ...formData,
        content: { ...formData.content, section: Sections }
      }
    }

    setLoading(true)
    createProjectTemplate(tempData, handleSaveCallback)
  }

  const handleSaveCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      dispatch(fireSuccessToaster('Template imported successfully!'))
      handleClose()
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleClose = () => {
    onClose()
    setStep(1)
    setMappedColumn({})
    setColumnHeader([])
    setTaskData([])
    setFormData({
      title: '',
      description: '',
      category: '',
      type: 'public',
      content: { private: false, section: [] }
    })
  }

  const handleChanges = () => {
    console.log(mappedColumn, 'mapped Column')
    if (
      Object.values(mappedColumn).filter((column) => column.customField).length
    ) {
      setStep(4)
    } else {
      handleSave()
    }
  }

  return (
    <CustomModal open={open} size="extraLarge">
      {step === 1 && (
        <Step1
          setStep={setStep}
          handleColumnMapping={handleColumnMapping}
          handleReview={handleReview}
          fileData={fileData}
          onClose={onClose}
          mappedColumn={mappedColumn}
          elmFor="template"
          handleClose={handleClose}
        />
      )}
      {step === 2 && (
        <TemplateImportedDataTable
          columnHeader={columnHeader}
          mappedColumn={mappedColumn}
          handleContinue={() => setStep(3)}
          handleBack={() => setStep(1)}
          taskData={taskData}
          setTaskData={setTaskData}
          handleClose={handleClose}
        />
      )}
      {step === 3 && (
        <TemplateInputs
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          setStep={setStep}
          handleChanges={handleChanges}
          handleClose={handleClose}
        />
      )}
      {step === 4 && (
        <CustomFieldsMapping
          loading={loading}
          setStep={setStep}
          handleSave={handleSave}
          mappedColumn={mappedColumn}
          setMappedColumn={setMappedColumn}
          handleClose={handleClose}
        />
      )}
    </CustomModal>
  )
}

export default TemplateDataMappingModal
