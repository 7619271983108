import React, { useEffect, useState, useCallback } from 'react'
import InactiveUserCard from './InactiveUserCard'
// import './InactiveClient.css'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { NoData } from 'static/Images'
import { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'
import clientStyles from './ClientStyles'

const totalClientsToShow = 8
const InactiveClients = () => {
  const classes = clientStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const me = useSelector(state => state.me.data)
  const search = useSelector(state => state.generalAppState.headerSearch)
  const userClients = useSelector(state => state.userClients)
  const [userClientsData, setUserClientData] = useState([])
  const [visibleClients, setVisibleClients] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)

  useEffect(() => {
    setUserClientData(userClients.data.filter(item => !item.isActive))
  }, [userClients])

  useEffect(() => {
    if (userClientsData.length) {
      if (search.length > 0) {
        let arr = [...userClientsData].filter(item =>
          item?.name.toLowerCase().includes(search.toLowerCase())
        )
        setVisibleClients(
          arr.splice(page * totalClientsToShow, totalClientsToShow)
        )
        setPaginationTotalDataCount(arr?.length)
      } else {
        setVisibleClients(
          [...userClientsData].splice(
            page * totalClientsToShow,
            totalClientsToShow
          )
        )
        setPaginationTotalDataCount(userClientsData.length)
      }
    } else {
      setVisibleClients([])
      setPaginationTotalDataCount(userClientsData.length)
    }
  }, [userClientsData, page, search, paginationTotalDataCount])

  const OpenSingleClientPage = item => {
    history.push(`${getUserPath(me.role)}/clients/${item._id}`)
  }

  return (
    <div>
      {visibleClients.length ? (
        <div className={classes.inactiveClient}>
          {visibleClients.map(item => (
            <InactiveUserCard
              user={item}
              handleViewProfile={() => OpenSingleClientPage(item)}
            />
          ))}
        </div>
      ) : (
        <NoAccessUI />
      )}
      {paginationTotalDataCount > totalClientsToShow && (
        <Pagination
          handlePagination={setPage}
          totalResultsCount={paginationTotalDataCount}
          visibleResultsCount={visibleClients.length}
          page={page}
          limit={totalClientsToShow}
        />
      )}
    </div>
  )
}

const NoAccessUI = () => {
  return (
    <div className=" bg-white flex flex-col items-center gap-4 w-full h-full text-center">
      <div className="w-full" style={{ height: '380px', overflow: 'hidden' }}>
        <img
          src={NoData}
          alt="No Access"
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  )
}

export default InactiveClients
