import { TextField } from '@material-ui/core'
import CellWrapper from 'components/Boards/TableView/CellWrapper'
import { useState } from 'react'

const EditableCell = ({
  updateColData,
  value,
  row: { index, values, original },
  column: { id, fieldType }
}) => {
  const [text, setText] = useState(value ?? '')
  const [edit, setEdit] = useState(false)

  const onChange = (e) => {
    setText(e.target.value)
  }

  const handleToggleEdit = () => {
    setEdit((prev) => !prev)
  }

  const handleUpdateValue = (e) => {
    e.preventDefault()
    handleToggleEdit()

    if (value === text) return

    updateColData({
      rowIndex: index,
      columnId: id,
      value: text
    })
  }

  return (
    <div onClick={handleToggleEdit} styles={{ minWidth: 250 }}>
      <div className="text-sm w-full whitespace-nowrap ">
        {edit ? (
          <form onSubmit={handleUpdateValue}>
            <TextField
              value={text}
              onChange={onChange}
              onBlur={handleUpdateValue}
              className="w-full h-full  p-2"
              autoFocus
            />
          </form>
        ) : (
          <div className="w-full p-2">{text}</div>
        )}
      </div>
    </div>
  )
}

export default EditableCell
