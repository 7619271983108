import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
import { projectStatusData } from './StatusUpdateDropdown'
import { ColoredAvatars } from 'global/globalComponents'
import { useParams, useHistory } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { Add, CheckCircle, CheckCircleOutline } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { userRoles } from 'utils'

const sections = [
  {
    title: 'Summary',
    description: '',
    tasks: [],
    taskConfiguration: {}
  },
  {
    title: "What we've accomplished",
    description: '',
    tasks: [],
    taskConfiguration: {}
  },
  {
    title: "What's blocked",
    description: '',
    tasks: [],
    taskConfiguration: {}
  },
  {
    title: 'Next steps',
    description: '',
    tasks: [],
    taskConfiguration: {}
  }
]

const StatusUpdateSidebar = ({
  allStatusPosts,
  currentStatusPostId,
  handleViewStatus,
  addNewStatusPost,
  timeStringHandler
}) => {
  const { workspaceId } = useParams()
  const meData = useSelector(state => state.me.data)

  const draftNavLinks = useMemo(() => {
    return allStatusPosts
      .filter(item => item.state === 'draft' && item.user._id === meData?._id)
      .map(item => ({
        _id: item._id,
        title: item.title,
        createdAt: item.createdAt,
        status: item.status,
        user: item.user
      }))
  }, [allStatusPosts])

  const publishNavLinks = useMemo(() => {
    return allStatusPosts
      .filter(item => item.state !== 'draft')
      .map(item => ({
        _id: item._id,
        title: item.title,
        createdAt: item.createdAt,
        status: item.status,
        user: item.user
      }))
  }, [allStatusPosts])

  const handleCreatePost = () => {
    addNewStatusPost({
      workspace: workspaceId,
      title: 'Untitled',
      status: 1,
      state: 'draft',
      sections: sections,
      file: []
    })
  }

  return (
    <nav className="bg-white pb-1">
      {meData.role < userRoles.USER_CLIENT && (
        <div className="mt-4 mb-4 px-8 ">
          <Button className="w-full" onClick={handleCreatePost}>
            New Update
          </Button>
        </div>
      )}
      {draftNavLinks?.length > 0 && (
        <>
          <ul>
            <li className="p-3 lg:p-4 text-smaller lg:text-sm text-blue-700">
              Drafts
            </li>
            {draftNavLinks.map((item, index) => (
              <li
                key={item._id}
                className={clsx(
                  'p-3 lg:p-4 border-b cursor-pointer',
                  item._id === currentStatusPostId && 'bg-custom-blue-light'
                )}
                role="button"
                onClick={handleViewStatus}
                data-id={item._id}
              >
                <div className="flex items-start space-x-4 mb-2 px-2">
                  <ColoredAvatars user={item.user} size="small" />
                  <p className="truncate text-smaller lg:text-sm ">
                    {item.title}
                  </p>
                </div>
                <div className="flex items-center">
                  <CheckCircleOutline
                    fontSize="small"
                    className="mx-2 text-gray-600"
                    style={{ width: '15px', height: '15px' }}
                  />
                  <span className="text-xs text-gray-600">
                    Draft saved {timeStringHandler(item.createdAt)}{' '}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
      {publishNavLinks?.length > 0 && (
        <>
          <ul>
            {draftNavLinks.length > 0 && (
              <li className="p-3 lg:p-4 text-smaller lg:text-sm text-blue-700">
                Published
              </li>
            )}
            {publishNavLinks.map((item, index) => (
              <li
                key={item._id}
                className={clsx(
                  'p-3 lg:p-4 border-b cursor-pointer',
                  item._id === currentStatusPostId && 'bg-custom-blue-light'
                )}
                role="button"
                onClick={handleViewStatus}
                data-id={item._id}
              >
                <div className="flex items-start space-x-4 mb-2 px-2">
                  <ColoredAvatars user={item.user} size="small" />
                  <p className="truncate text-smaller lg:text-sm ">
                    {item.title}
                  </p>
                </div>
                <div className="flex items-center">
                  <span
                    className={clsx(
                      'inline-block w-2 h-2 rounded-full mx-2',
                      projectStatusData[item.status].bgColorClass
                    )}
                  ></span>
                  <span className="text-xs font-medium text-gray-700">
                    {projectStatusData[item.status].label}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </nav>
  )
}

export default StatusUpdateSidebar
