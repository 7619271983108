import React from 'react'
import { Button } from '@material-ui/core'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { CheckBoxField, LoadingButton } from 'global/globalComponents'

export default function ClientDeleteModal({
  open,
  handleDialog,
  handleDeleteAction,
  loading,
  checkList,
  setCheckList,
  type
}) {
  return (
    <CustomModal open={open} size="extraSmall">
      <CustomModalHeader heading={`Delete this ${type}`} size="small" />
      <CustomModalBody>
        <p className="text-sm mb-4 font-medium">
          Select the data you want to delete or keep
        </p>
        <div className="flex flex-col">
          {type === 'company' && (
            <CheckBoxField
              name="Delete client representative"
              disabled={true}
              label="Delete client representative"
              checked={true}
            />
          )}
          <CheckBoxField
            name="delete project"
            onChange={() =>
              setCheckList((prev) => ({
                ...prev,
                isWorkspaceDelete: !checkList.isWorkspaceDelete
              }))
            }
            checked={checkList.isWorkspaceDelete}
            label={`Delete Projects related to this ${type}`}
          />
          <CheckBoxField
            name="delete onboarding"
            onChange={() =>
              setCheckList((prev) => ({
                ...prev,
                isOnBoardingResponseDelete: !checkList.isOnBoardingResponseDelete
              }))
            }
            checked={checkList.isOnBoardingResponseDelete}
            label={`Delete onboarding data`}
          />
          <CheckBoxField
            name="delete clientPayment"
            onChange={() =>
              setCheckList((prev) => ({
                ...prev,
                isClientPaymentDelete: !checkList.isClientPaymentDelete
              }))
            }
            checked={checkList.isClientPaymentDelete}
            label={`Delete ${type} payment data and invoices`}
          />
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <div className="flex gap-2">
          <LoadingButton onClick={handleDeleteAction} loading={loading}>
            Delete
          </LoadingButton>
          <Button variant="outlined" onClick={handleDialog}>
            Cancel
          </Button>
        </div>
      </CustomModalFooter>
    </CustomModal>
  )
}
