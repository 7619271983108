import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { TextField, Button } from '@material-ui/core'
import {
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'

import { ReactComponent as ArrowRightIcon } from 'static/svg/arrow-right.svg'
import { useMemo } from 'react'

const options = [
  {
    label: 'Ignore this column',
    value: 'false',
    dataType: 'text',
    customField: false
  },
  { label: 'Title', value: 'title', dataType: 'text', customField: false },
  {
    label: 'Collaborators',
    value: 'assignedAgencyTeam',
    dataType: 'text',
    customField: false
  },
  {
    label: 'Status',
    value: 'taskStatus',
    dataType: 'text',
    customField: false
  },
  {
    label: 'Description',
    value: 'description',
    dataType: 'text',
    customField: false
  },
  // { label: 'Start Date', value: 'startDate', dataType: 'text', customField: false },
  // { label: 'Due Date', value: 'dueDate', dataType: 'text', customField: false },
  // { label: 'Priority', value: 'priority', dataType: 'text', customField: false },
  // { label: 'Date Created', value: 'createdAt', dataType: 'text', customField: false },
  { label: 'Subtask', value: 'subTask', dataType: 'text', customField: false },
  {
    label: 'Include as custom field',
    value: '',
    dataType: 'text',
    customField: true
  }
]

const Step1 = ({
  onClose,
  fileData,
  handleColumnMapping,
  handleReview,
  mappedColumn,
  elmFor = 'project',
  handleClose
}) => {
  const columnOptions = useMemo(() => {
    if (elmFor === 'template') {
      return options.filter((el) => el.value !== 'assignedAgencyTeam')
    } else {
      return options
    }
  }, [elmFor])

  return (
    <>
      <CustomModalHeader
        heading="Choose Space and Custom Fields"
        handleClose={handleClose}
      />
      <CustomModalBody style={{ height: '450px' }}>
        <form className=" w-2/3 mx-auto">
          <div className="flex space-x-4 items-center justify-between w-full">
            <p className="w-1/2 text-xs">Imported Keys:</p>

            <p className="w-1/2 pl-4 text-xs">Your Keys:</p>
          </div>
          {fileData[0]?.map((item, index) => (
            <div className="flex items-center space-x-4 mt-3 w-full">
              <TextField value={item} className="w-full" disabled={true} />
              <ArrowRightIcon className="text-primary-main" width={40} />
              <Select
                menuPosition={'fixed'}
                styles={reactSelectCustomStyles}
                options={columnOptions}
                onChange={(e) => handleColumnMapping(e, index, item)}
                value={mappedColumn[index]}
                maxMenuHeight={150}
                placeholder="Select"
                className="border-0 border-none w-full"
              />
            </div>
          ))}
        </form>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton onClick={handleReview}>Review</LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </CustomModalFooter>
    </>
  )
}

export default Step1
