import { makeStyles, createStyles, fade, lighten } from '@material-ui/core'

export default makeStyles(theme =>
  createStyles({
    headerContainer: ({ headerShadow }) => ({
      width: '100%',
      minHeight: theme.custom.header.height,
      boxShadow: headerShadow
        ? '0px 4px 24px -4px rgba(0, 0, 0, 0.08)'
        : 'none',
      userSelect: 'none',
      position: 'fixed',
      zIndex: theme.zIndex.drawer + 1,
      background: '#fff',
      top: 0,
      right: 0,
      borderBottom: '1px solid #e8ecee',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen
      }),
      '&.sidebarExpanded': {
        width: `calc(100% - ${theme.custom.sidebar.width}px )`
      }
    }),
    errorMessageContainer: {
      position: 'absolute',
      top: -2,
      left: '50%',
      transform: 'translate(-50%, 0)',
      fontSize: 12,
      padding: '6px 10px',
      borderRadius: theme.shape.borderRadius,
      border: '1px solid lightgray',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      cursor: 'pointer',
      zIndex: 9,
      background: '#fff',
      '&>span.refreshBtn': {
        transition: '200ms transform, 200ms color',
        color: 'gray'
      },
      '&:hover': {
        '&>span.refreshBtn': {
          transform: 'scale(1.1) rotate(60deg)',
          color: 'black'
        }
      },
      '&.offlineMessage': {
        background: '#fff',
        color: 'red',
        cursor: 'default'
      }
    },
    logoContainer: {
      paddingLeft: 16
    },
    // profileContainer: {
    //   paddingRight: '15px',
    //   display: 'flex',
    //   alignItems: 'center',
    //   gap: theme.spacing(1.5)
    // },
    // upgradeBtn: {
    //   backgroundColor: theme.palette.warning.main,
    //   color: theme.palette.common.white,
    //   '&:hover': {
    //     backgroundColor: lighten(theme.palette.warning.main, 0.2)
    //   }
    // },
    profileIcon: {
      cursor: 'pointer',
      textTransform: 'uppercase'
    },
    listItemIconRoot: {
      minWidth: 32
    },
    listTextItemRoot: {
      '&>span': {
        whiteSpace: 'nowrap',
        textTransform: 'capitalize'
      }
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      maxWidth: '40vw',
      height: 32,
      whiteSpace: 'nowrap',
      marginTop: 8,
      '&>h1': {
        fontSize: 20,
        fonWeight: 500
        // overflow: 'hidden'
        // textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap'
      }
    },
    headerBtns: {
      borderRadius: theme.shape.borderRadius,
      height: 24,
      width: 24,
      '&:hover': {
        backgroundColor: '#f9f9f9'
      }
    },
    tabSwitchContainer: {
      display: 'flex',
      alignItems: 'center',
      // gap: theme.spacing(2.5),
      height: 32,
      paddingTop: 2,
      '&>p, &>a': {
        fontSize: 14,
        fontWeight: 500,
        color: '#7D7D82',
        cursor: 'pointer',
        minWidth: 32,
        textAlign: 'center',
        userSelect: 'none',
        transitionDuration: '.2s',
        transitionProperty: 'box-shadow,color',
        whiteSpace: 'nowrap',
        '&.isActive, &:hover': {
          boxShadow: `0px 2px ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      }
    },
    // tabBtn: {
    //   position: 'relative',
    //   '&:hover .cross-btn': {
    //     opacity: 1
    //   }
    // },
    tab: {
      padding: '6px',
      textTransform: 'capitalize',
      fontWeight: 500,
      color: '#4C4C5B',
      opacity: 0.7,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      minWidth: 32,
      textAlign: 'center',
      userSelect: 'none',
      fontSize: '12.5px',
      '@media (min-width: 1284px)': {
        fontSize: '14px'
      }
    },
    deleteEmbedBtn: {
      position: 'absolute',
      top: '-2px',
      right: '-10px',
      color: 'rgba(255, 0, 0, 0.75)',
      opacity: 0,
      transition: 'opacity 150ms ease-in-out'
    },
    tabsRoot: {
      position: 'relative',
      minHeight: '34px',
      height: '34px',
      maxWidth: '30vw',
      indicator: {
        width: '50%',
        backgroundColor: '#1600E5',
        height: 2
      },
      '@media (min-width: 1284px)': {
        maxWidth: '40vw',
        minHeight: '32px',
        height: '32px'
      }
    },
    tabRoot: {
      minHeight: '34px',
      height: '34px',
      fontSize: '12.5px',
      '@media (min-width: 1284px)': {
        fontSize: '14px',
        minHeight: '32px',
        height: '32px'
      }
    },
    popover: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      marginLeft: '8px',
      padding: '1px 3px',
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: '#e0e0e0'
      },
      '&.active': {
        backgroundColor: '#f0f0f0'
      }
    },
    listTextItemRoot: {
      fontSize: '13px',
      lineHeight: 1.5,
      fontWeight: 500
    },
    avatarRoot: {
      height: 28,
      fontSize: 11,
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&>div': {
        width: 30,
        height: 30,
        borderWidth: 1,
        fontSize: 11,
        boxShadow: 'inset 0 0 0 1px rgb(0 0 0 / 7%)',
        fontWeight: 300
      }
    },
    filledIconButton: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      minWidth: 28,
      minHeight: 28,
      padding: 0,
      backgroundColor: theme.palette.primary.main,
      '& svg': {
        fill: '#fff'
      }
    },
    textFieldRootStyles: {
      width: '140px',
      height: 32,
      borderRadius: 16,
      fontSize: 14,
      transition: 'width 400ms',
      '&:focus': {
        width: 320
      }
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
      }
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit',
      borderRadius: 16,
      border: '1px solid gray',
      height: 32,
      fontSize: 14
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width', { duration: '200ms' }),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch'
        }
      }
    },
    notificationBody: {
      maxHeight: 550,
      overflow: 'auto',
      minWidth: 350,
      maxWidth: 350
    },
    notificationText: {
      '& strong': {
        fontWeight: 500
      }
    },
    notifBadge: {
      backgroundColor: '#E02A2A',
      color: '#fff'
    },

    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: 'rgba(0, 0, 0, 0)'
    },
    add: {
      fontSize: '1rem'
    }
  })
)
