import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllServicesByDomain,
  fetchServiceCategories
} from 'thunks/service/actions'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import ServiceCard from 'global/globalComponents/ServiceCard/ServiceCard'
import { Skeleton } from '@material-ui/lab'
import useTitle from 'customHooks/useTitle'
import { hideChatMessenger } from 'utils'
import { NoAccess } from 'static/Images'

const CheckoutServices = () => {
  const dispatch = useDispatch()
  const userServices = useSelector(state => state.userServices)
  const me = useSelector(state => state.me.data)
  const [pageLoading, setPageLoading] = useState(true)
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })
  const [categories, setCategories] = useState({
    loading: true,
    data: []
  })
  const [visibleServices, setVisibleServices] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)

  // useTitle(me?.profile?.brand?.brandName, me?.profile?.brand?.favicon)
  useTitle(agencyInfo?.brandName, agencyInfo?.favicon)

  useEffect(() => {
    dispatch(fetchAllServicesByDomain(fetchAllServicesByDomainCallback))
    fetchServiceCategories(fetchCategoryCallback)
  }, [])

  useEffect(() => {
    hideChatMessenger()
  })

  useEffect(() => {
    fetchAgencyInfo()
  }, [])

  useEffect(() => {
    if (userServices.data.length) {
      if (selectedTab !== 0) {
        const categoryServices = userServices.data.filter(item => {
          return item.category === selectedTab
        })
        setVisibleServices(categoryServices)
      } else {
        setVisibleServices([...userServices.data])
      }
    }
  }, [userServices.data, selectedTab])

  const fetchCategoryCallback = (res, err) => {
    if (!err) {
      setCategories({ loading: false, data: res })
    } else {
      setCategories(prev => ({ ...prev, loading: false }))
    }
  }

  const fetchAllServicesByDomainCallback = (res, err) => {
    setPageLoading(false)
  }

  const fetchAgencyInfo = async () => {
    try {
      const res = await getAgencyInfoByDomain()
      // setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
      setAgencyInfo(res?.data?.brand?.smallLogo ? res?.data.brand : agencyInfo)
    } catch (err) {
      setAgencyInfo(agencyInfo)
    }
  }

  const handleTabChange = (e, index) => {
    setSelectedTab(index)
  }

  return (
    <div className="px-4 sm:px-8 py-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto mb-8"
          src={agencyInfo.largeLogo?.replace('s3.wasabisys.com/', '')}
          alt=""
        />
      </div>
      <div>
        <SectionHeading className="mb-2 lg:mb-4">
          Service Offerings
        </SectionHeading>
        <div>
          {categories.loading ? (
            <div className="flex gap-4">
              {[...Array(3)].map((item, index) => (
                <Skeleton
                  key={index}
                  variant="rect"
                  width={100}
                  height={30}
                  className="rounded"
                />
              ))}
            </div>
          ) : (
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="All" value={0} />
              {categories.data.map(item => (
                <Tab
                  className="text-capitalize"
                  label={item.name}
                  key={item._id}
                  value={item._id}
                />
              ))}
            </Tabs>
          )}
        </div>
        <div>
          <div className="mt-8">
            {pageLoading ? (
              <PageLoadingSkeleton />
            ) : (
              <div className="">
                {visibleServices.length ? (
                  <ServiceCard
                    plans={visibleServices}
                    // loading={loading}
                  />
                ) : (
                  <NoAccessUI />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const PageLoadingSkeleton = () => {
  return (
    <div>
      <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        {[...Array(4)].map((i, index) => (
          <Skeleton
            key={index}
            animation="wave"
            variant="rect"
            height={400}
            className="rounded-lg"
          />
        ))}
      </div>
    </div>
  )
}

const NoAccessUI = () => {
  return (
    <div className="p-8 bg-white flex flex-col items-center gap-4 w-full h-full text-center">
      <div className="w-full" style={{ height: '360px', overflow: 'hidden' }}>
        <img
          src={NoAccess}
          alt="No Access"
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  )
}

export default CheckoutServices
