import clsx from 'clsx'

const Card = ({ children, className, ...rest }) => {
  return (
    <div
      className={clsx('bg-white shadow px-4 py-4 rounded', className)}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Card
