import axios from 'axios'
import { errorMessages } from 'utils'
import { TAGS } from './reducers'
import { postTaskLog } from 'thunks/logs/action'

/**
 * create tags in a workspace
 * @param {Function} callback Callback function
 */
export const createTags = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/tag`,
        data
      })

      if (callback) callback(res.data, false)
      dispatch({
        type: TAGS.ADD_ONE_TAG,
        payload: { response: res.data, new: data.new }
      })
      dispatch(
        postTaskLog({
          description: `assigned the tag`,
          tag: {
            name: `${res.data.name}`,
            color: `${res.data.textColor}`
          },
          activityType: 'tags',
          workSpace: data.workspace ?? data.workspaceId,
          task: data.taskId,
          category: data.workspaceId ? 'workspace' : 'task'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch tags by user
 * @param {Function} callback Callback function
 */
export const fetchTagsByUser = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/tag/user`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TAGS.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch tags in a workspace
 * @param {Function} callback Callback function
 */
export const fetchWorkspaceTagsById = (workspaceId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `tag/workspace/${workspaceId}`
      })

      if (callback) callback(res.data, false)
      dispatch({ type: TAGS.OPEN_CURRENT_WORKSPACE_TAGS, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update tags in a workspace or task
 * @param {String} tagId tag id
 * @param {Function} callback Callback function
 */
export const updateTagsById = (data, tagId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/tag/${tagId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TAGS.UPDATE_ONE_TAG, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Delete tags
 * @param {String} tagID tag id
 * @param {Function} callback Callback function
 */
export const deleteTagsById = (tagId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/tag/${tagId}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: TAGS.DELETE_ONE_TAG, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Unassign tags from workspace and task
 * @param {String} tagId tag id
 * @param {Function} callback Callback function
 */
export const unassignTagById = (data, tagId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/tag/unassign/${tagId}`,
        data
      })
      console.log(res.data, 'LINE 137')
      if (callback) callback(res.data, false)
      dispatch({ type: TAGS.UPDATE_ONE_TAG, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
