import { useState, useEffect } from 'react'
import clsx from 'clsx'
import SectionHeading from 'global/globalComponents/SectionHeading'
import CloseIcon from '@material-ui/icons/Close'
import { LoadingButton } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import axios from 'axios'
import { Skeleton } from '@material-ui/lab'
import { Button, Checkbox } from '@material-ui/core'
import { userRoles } from 'utils'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { addClient, addMultiClient, getClient } from 'thunks/addClient/actions'
import {
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  Th
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { fetchStripeCustomers } from 'thunks/stripeAccount/actions'

// const fetchStripeCustomers = async (cancelToken, cb) => {
//   try {
//     const res = await axios(
//       {
//         method: 'GET',
//         url: '/user/stripe-customer'
//       },
//       { cancelToken }
//     )

//     cb(res)
//   } catch (err) {
//     cb(err, true)
//   }
// }

const StripeCustomersModal = ({ open, onClose }) => {
  const [customersData, setCustomersData] = useState([])
  const [stripeDataLoading, setStripeDataLoading] = useState(true)
  const dispatch = useDispatch()
  // const cancelToken = axios.CancelToken.source()
  const [checkedCustomer, setCheckedCustomer] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchStripeCustomersCallback = (res, err) => {
    if (!err) {
      setStripeDataLoading(false)
      setCustomersData(
        res.map(customer => ({
          id: customer.id,
          name: customer.name || customer.email.split('@')[0], // if name is null, use username instead
          email: customer.email
        }))
      )
    } else {
      setStripeDataLoading(false)
      // if (!axios.isCancel(err)) {
      dispatch(fireErrorToaster(res))
      // }
    }
  }

  useEffect(() => {
    fetchStripeCustomers(fetchStripeCustomersCallback)
  }, [])

  // const removeCustomer = (id) => {
  //   setCustomersData(customersData.filter((cus) => cus.id !== id))
  // }

  const handleCheckedAllCustomer = e => {
    if (e.target.checked) {
      setCheckedCustomer(customersData.map(el => el.id))
    } else {
      setCheckedCustomer([])
    }
  }

  const handleCheckSingleCustomer = (checked, id) => {
    if (checked) {
      setCheckedCustomer(prev => [...prev, id])
    } else {
      setCheckedCustomer(prev => prev.filter(el => el !== id))
    }
  }

  const handleRegistration = () => {
    setLoading(true)
    const data = customersData
      .filter(el => checkedCustomer.includes(el.id))
      .map(customer => ({
        name: customer.name,
        email: customer.email,
        customerId: customer.id,
        role: userRoles.USER_CLIENT,
        newWorkspace: [],
        password: 'Test@123',
        phone: '',
        address: {
          country: ''
        },
        agencyRepresentative: null,
        canAddTeammates: true,
        path: '/client/dashboard'
      }))
    dispatch(addMultiClient(data, handleRegistrationCallback))
  }

  const handleRegistrationCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      // removeCustomer(customer.id)
      setCustomersData(prev =>
        prev.filter(cus => !checkedCustomer.includes(cus.id))
      )
      setCheckedCustomer([])
      dispatch(getClient())
      dispatch(fireSuccessToaster('Registered Successfully'))
    }
  }

  return (
    <CustomModal open={open} handleClose={onClose} size="medium">
      <CustomModalHeader
        heading={`Stripe Customers (${customersData.length})`}
      />
      <CustomModalBody className="relative h-full" style={{ height: '420px' }}>
        {/* <div className="flex flex-col">
          <div className="py-2 align-middle inline-block min-w-full"> */}
        <TableContainer
          className="min-w-full divide-y divide-gray-200"
          shadow="sm"
          mb={0}
          containerProps={{
            style: {
              overflowY: 'auto',
              height: '100%',
              background: '#fff'
            }
          }}
        >
          <Thead className="sticky top-0" style={{ zIndex: 99 }}>
            <Th>
              <Checkbox
                onChange={handleCheckedAllCustomer}
                inputProps={{ 'aria-label': 'controlled' }}
                size="small"
              />
            </Th>
            <Th>Name</Th>
            <Th className="text-center">Email</Th>
            {/* <Th>
              <span className="sr-only">Action</span>
            </Th> */}
          </Thead>
          <Tbody className="bg-white divide-y divide-gray-200">
            {stripeDataLoading ? (
              <TableDataLoadingAnimation />
            ) : customersData.length ? (
              customersData.map(customer => (
                <Row
                  customer={customer}
                  // removeCustomer={removeCustomer}
                  handleCheckSingleCustomer={handleCheckSingleCustomer}
                  checkedCustomer={checkedCustomer}
                />
              ))
            ) : (
              <Tr className="tracking-wider bg-white">
                <Td
                  colSpan="3"
                  className="text-left px-6 py-4 whitespace-nowrap font-medium text-gray-900"
                >
                  There are no customers
                </Td>
              </Tr>
            )}
          </Tbody>
        </TableContainer>
        {/* </div>
        </div> */}
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          // size="small"
          onClick={handleRegistration}
          loading={loading}
          disabled={!Boolean(checkedCustomer.length)}
        >
          Register
        </LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

const Row = ({
  customer,
  // removeCustomer,
  checkedCustomer,
  handleCheckSingleCustomer
}) => {
  // const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  // const handleRegistration = (id) => {
  //   setLoading(true)
  //   const data = {
  //     name: customer.name,
  //     email: customer.email,
  //     customerId: customer.id,
  //     role: userRoles.USER_CLIENT,
  //     newWorkspace: [],
  //     password: 'Test@123',
  //     phone: '',
  //     address: {
  //       country: ''
  //     },
  //     agencyRepresentative: null,
  //     canAddTeammates: true,
  //     path: '/client/dashboard'
  //   }

  //   dispatch(addClient(data, handleRegistrationCallback))
  // }

  // const handleRegistrationCallback = (res, err) => {
  //   setLoading(false)
  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     removeCustomer(customer.id)
  //     dispatch(fireSuccessToaster('Registered Successfully'))
  //   }
  // }

  return (
    <Tr key={customer.email}>
      <Td>
        <Checkbox
          checked={checkedCustomer.includes(customer.id)}
          onChange={e =>
            handleCheckSingleCustomer(e.target.checked, customer.id)
          }
          inputProps={{ 'aria-label': 'controlled' }}
          size="small"
        />
      </Td>
      <Td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
        {customer.name}
      </Td>
      <Td className="px-6 py-4 text-center whitespace-nowrap text-gray-500">
        {customer.email}
      </Td>
      {/* <Td className="px-6 py-4 whitespace-nowrap text-right font-medium">
        <LoadingButton
          size="small"
          onClick={() => handleRegistration(customer.id)}
          loading={loading}
        >
          Register
        </LoadingButton>
      </Td> */}
    </Tr>
  )
}

const TableDataLoadingAnimation = () => (
  <>
    {[...Array(3)].map((i, index) => (
      <tr key={index} className="bg-white">
        <td className="px-6 py-1 whitespace-nowrap text-sm text-gray-900">
          <Skeleton height={40} />
        </td>
        <td className="px-6 py-1 text-right whitespace-nowrap text-sm text-gray-500">
          <Skeleton height={40} />
        </td>
        <td className="w-4 px-6 py-1 whitespace-nowrap text-sm text-gray-500">
          <Skeleton height={40} width={40} />
        </td>
      </tr>
    ))}
  </>
)

export default StripeCustomersModal
