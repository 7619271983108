import { useState, useEffect, useCallback } from 'react'
import { Button } from '@material-ui/core'
import {
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useTable, useSortBy } from 'react-table'
import EditableCell from './EditableCell'
import clsx from 'clsx'
import styles from './import.module.css'

// import styles from './table.module.css'

// const initialOptions = [
//   { label: 'Do not import user', value: false },
//   { label: 'Select user', value: 'select user' }
// ]

const ImportedDataTable = ({
  columnHeader,
  mappedColumn = {},
  handleContinue,
  handleBack,
  taskData,
  setTaskData,
  collaborators,
  setCollaborators
}) => {
  const [columnsData, setColumnsData] = useState([])

  const defaultColumn = {
    Cell: EditableCell
  }

  useEffect(() => {
    setColumnsData(
      Object.values(mappedColumn)
        .filter((el) => el.label !== 'Ignore this column')
        .map((el) => ({
          Header: el.label,
          accessor: el.value,
          Cell: EditableCell,
          datatype: el.dataType
        }))
    )
  }, [mappedColumn])

  // console.log(
  //   mappedColumn,
  //   'mapped columnnnnnnn',
  //   columnsData,
  //   'columnsDataaaaaaaaaaaaaa',
  //   tableData.slice(0, 1),
  //   'table dataaaaaaaaaaaaa'
  // )

  // useEffect(() => {
  //   setTableData(taskData)
  // }, [taskData, mappedColumn])

  const updateColData = useCallback(({ rowIndex, columnId, value }) => {
    // if (
    //   columnId === 'assignedAgencyTeam' &&
    //   !(value.toLowerCase() in collaborators)
    // ) {
    //   setCollaborators((prev) => ({
    //     ...prev,
    //     [value.toLowerCase()]: {
    //       userState: { label: 'Do not import user', value: false },
    //       options: initialOptions
    //     }
    //   }))
    // }
    setTaskData((prev) =>
      prev.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          }
        }
        return row
      })
    )
  }, [])

  const tableInstance = useTable(
    {
      columns: columnsData,
      defaultColumn,
      data: taskData,
      updateColData
    },
    useSortBy
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance

  return (
    <>
      <CustomModalHeader heading="Import from CSV/Excel file" />
      <CustomModalBody
        className="px-0"
        style={{ height: '450px', padding: '0' }}
      >
        <div className={styles.listView}>
          <table
            {...getTableProps()}
            className="text-sm"
            style={{ backgroundColor: '#f9f8f8' }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th
                    className={`border ${styles.extraRow} sticky left-0  ${styles.stickyCell}`}
                  ></th>
                  {headerGroup.headers.map((col, index) => (
                    <th
                      {...col.getHeaderProps(col.getSortByToggleProps())}
                      className={clsx(
                        'text-sm font-normal capitalize text-left whitespace-nowrap'
                      )}
                    >
                      <div className="flex items-center">
                        <span>{col.render('Header')}</span>
                        <span className="ml-1">
                          {col.isSorted ? (
                            col.isSortedDesc ? (
                              <>&#8593;</>
                            ) : (
                              <>&#8595;</>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    <td
                      className={`${styles.extraRow} sticky left-0  ${styles.stickyCell}`}
                    >
                      {index + 1}
                    </td>
                    {row.cells.map((cell, index) => (
                      <td {...cell.getCellProps()} className={clsx('bg-white')}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button onClick={handleContinue}>Continue</Button>
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
      </CustomModalFooter>
    </>
  )
}

export default ImportedDataTable
