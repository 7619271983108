import axios from 'axios'
import { errorMessages } from 'utils'
import { WORKSPACES } from 'thunks/workspace/reducers'
import { LOGS } from 'thunks/logs/reducer'
import { WORKSPACE_PERMISSION } from 'thunks/workspacePermission/reducer'
import { TASKS } from 'thunks/task/reducers'
import { EMBEDS } from 'thunks/embed/reducer'
import { VIEWS } from 'thunks/views/reducer'

/**
 * Get public workspace status (whether it's public or not)
 * @param {String} id workspace ID
 * @param {Function} callback Callback function
 */
export const getPublicWorkspaceStatus = async (id, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/validate/${id}`
    })

    if (callback) callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Get public workspace data (tasks)
 * @param {String} id workspace ID
 * @param {String} passcode optional
 * @param {Function} callback Callback function
 */
export const getPublicWorkspaceData = (id, passcode, callback) => {
  const permission = {
    role: 0,
    access: {
      workspace: { create: null, view: true, update: false, delete: false },
      workspaceView: {
        create: false,
        view: true,
        update: false,
        delete: false
      },
      task: { create: false, view: true, update: false, delete: false },
      subTask: { create: false, view: true, update: false, delete: false }
    }
  }

  return async dispatch => {
    try {
      let params = passcode ? `?passcode=${passcode}` : ''
      const res = await axios({
        method: 'GET',
        url: `/workspace/public/${id}${params}`
      })
      callback(res.data)
      dispatch({
        type: WORKSPACES.ADD_OPENED_WORKSPACE,
        payload: res.data.workspace
      })
      dispatch({
        type: TASKS.FETCHED_PUBLIC_TASKS,
        payload: res.data.task
      })
      dispatch({
        type: EMBEDS.FETCHED,
        payload: res.data.embedData
      })
      dispatch({
        type: WORKSPACE_PERMISSION.FETCHED,
        payload: { [res._id]: permission }
      })
    } catch (err) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * fetch logs by workspace id
 * @param {String} id workspace id
 * @param {Function} callback callback function
 */
export const fetchPublicWorkspaceLogs = (id, page, limit, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `log/public/workspace/${id}?page=${page}&limit=${limit}`
      })
      const sortedData = res.data.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      if (callback) callback(res.data, false)
      dispatch({
        type: LOGS.WORKSPACE_LOGS,
        payload: { _id: id, data: sortedData }
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: LOGS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch shared board's stats (overview data)
 * @param {String} id workspace id
 * @param {Function} callback callback function
 */
export const getSharedProjectStats = async (workspaceId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/public/overview/${workspaceId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch posts regarding workspace status
 * @param {Object} data workspace id, status (1/2/3), title, file, sec1, sec2
 * @param {Function} callback
 */
export const fetchPublicProjectStatusPosts = async (wsId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspacestatus/public/workspace/${wsId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch worfspace level invoices
 * @param {Function} callback
 */
export const fetchProjectLevelInvoices = async (wsId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `custominvoice/public/workspace/${wsId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch dynamic table column
 * @param {Function} callback
 */
export const fetchPublicTaskTableColumn = async (boardId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/tablecolumn/public/board/${boardId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch single view
 * @param {String} type (report / projectList)
 * @param {Function} callback
 **/
export const fetchPublicProjectTabView = (wsId, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/views/public?type=shareBoardTab&workspace=${wsId}`
      })
      dispatch({ type: VIEWS.FETCHED_PROJECT_TAB_VIEW, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (err?.response?.status === 404) {
        dispatch({ type: VIEWS.FETCHED_PROJECT_TAB_VIEW, payload: {} })

        if (callback) callback({})
      } else {
        if (callback) {
          callback(
            err.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
            true
          )
        }
      }
    }
  }
}

export const fetchActualPublicUrlData = async (customKey, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/custom/public?customUrl=${customKey}`
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
