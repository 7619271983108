import React, { useState, useEffect } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import { fetchFiles, fetchFolders } from 'thunks/fileAssets/actions'
import { useParams } from 'react-router-dom'
import { ReactComponent as FolderIcon } from 'static/svg/folder.svg'
import { ReactComponent as UndoIcon } from 'static/svg/undo.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import { moveFile, copyFile } from 'thunks/fileAssets/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const MoveFileModal = ({ open, onClose, file, actionType }) => {
  const filesAssetsData = useSelector(
    (state) => state.filesAssets.currentWSAssets.data
  )
  const [parentDirIdsStack, setParentDirIdsStack] = useState([0])
  // const [assetsFolders, setAssetsFolders] = useState([])
  // const [assetsFiles, setAssetsFiles] = useState([])
  const [openedDirAssets, setOpenedDirAssets] = useState({
    name: '',
    id: '',
    files: [],
    folders: []
  })
  const [loadingAssets, setLoadingAssets] = useState(false)
  const [loading, setLoading] = useState(false)

  const { workspaceId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const dirId = parentDirIdsStack[parentDirIdsStack.length - 1]
    const data = filesAssetsData[dirId]

    if (typeof data !== 'object') {
      setLoadingAssets(true)
      dispatch(fetchFolders(workspaceId, dirId, fetchFilesFoldersCallback))
      dispatch(fetchFiles(dirId, fetchFilesFoldersCallback))
      return
    }
    setOpenedDirAssets({
      files: data.files,
      folders: data.folders,
      id: dirId,
      name: data.name
    })
  }, [parentDirIdsStack, filesAssetsData])

  const fetchFilesFoldersCallback = (res, err) => {
    setLoadingAssets(false)
  }

  const handleOpenFolder = (data) => {
    setParentDirIdsStack((prev) => [...prev, data._id])
  }

  const goBack = () => {
    const stack = [...parentDirIdsStack]
    setParentDirIdsStack(stack.slice(0, -1))
  }

  const handleMoveFile = () => {
    let match = openedDirAssets.files.findIndex(
      (item) => item.name + item.extension === file.name + file.extension
    )

    if (match > -1) {
      dispatch(
        fireErrorToaster(
          `This folder already has a file named ${file.name}${file.extension}`
        )
      )
      return
    }

    setLoading(true)

    if (actionType === 'move') {
      dispatch(
        moveFile(
          file._id,
          {
            oldFileAssetsFolder: file.fileAssetsFolder,
            newFileAssetsFolder: openedDirAssets.id,
            newKey: file.name + file.extension,
            oldKey: file.name + file.extension
          },
          moveFileCallback
        )
      )
    } else {
      dispatch(
        copyFile(
          file._id,
          {
            oldFileAssetsFolder: file.fileAssetsFolder,
            newFileAssetsFolder: openedDirAssets.id,
            newKey: file.name + file.extension,
            oldKey: file.name + file.extension
          },
          moveFileCallback
        )
      )
    }
  }

  const moveFileCallback = (res, err) => {
    setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      dispatch(
        fireSuccessToaster(
          `File ${actionType === 'move' ? 'moved' : 'copied'} successfully!`
        )
      )
      handleClose()
    }
  }

  const handleClose = () => {
    setOpenedDirAssets({ id: '', files: [], folders: [], name: '' })
    setLoading(false)
    setParentDirIdsStack([0])
    onClose()
  }

  return (
    <CustomModal open={open} handleClose={handleClose} size="extraSmall">
      <CustomModalHeader
        heading={openedDirAssets.name ?? 'Root'}
        className="truncate"
      />
      <CustomModalBody>
        <div className="relative overflow-y-auto" style={{ maxHeight: 200 }}>
          {loadingAssets && (
            <div
              className="absolute top-0 left-0 bottom-0 right-0 z-1"
              style={{ background: 'rgba(255, 255, 255, 0.6)' }}
            ></div>
          )}
          {parentDirIdsStack.length > 1 && (
            <button
              className="p-2 hover:bg-primary-light flex items-center text-primary-main block w-full rounded"
              onClick={goBack}
            >
              <UndoIcon />
            </button>
          )}
          <div>
            {openedDirAssets.folders.map((item) => (
              <div
                onClick={() => handleOpenFolder(item)}
                className="cursor-pointer p-2 hover:bg-primary-light flex items-center rounded"
              >
                <div className="shrink-0 text-primary-main">
                  <FolderIcon />
                </div>
                <p className="ml-2 truncate text-sm">{item.name}</p>
              </div>
            ))}

            {openedDirAssets.files.map((item) => (
              <div className="p-2 flex items-center">
                <div className="shrink-0 text-primary-main">
                  <FileIcon />
                </div>
                <p className="ml-2 truncate text-sm">{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          loading={loading}
          disabled={openedDirAssets.id === 0}
          onClick={handleMoveFile}
        >
          {actionType === 'move' ? 'Move Here' : 'Copy Here'}
        </LoadingButton>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default MoveFileModal
