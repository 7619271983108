import React, { useState, useEffect } from 'react'
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField
} from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
// import { fetchEmbed } from 'thunks/embed/action'
import { FormLabel, LoadingButton } from 'global/globalComponents'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

export default function DuplicateModal({
  open,
  handleDialog,
  handleDuplicateAction,
  workspace,
  workspaceBoard
}) {
  const dispatch = useDispatch()
  const [checkList, setCheckList] = useState([
    { key: 'Teammates', value: false, name: 'teammate' },
    { key: 'Client', value: false, name: 'client' },
    { key: 'All Tasks', value: false, name: 'task' },
    { key: 'All Subtasks', value: false, name: 'subtask' },
    { key: 'Tags', value: false, name: 'tag' },
    { key: ' Views', value: false, name: 'views' }
  ])

  const [boardList, setBoardList] = useState([])

  const [projectName, setProjectName] = useState(workspace?.name)

  useEffect(() => {
    const board = workspaceBoard
      .filter((el) => el.type === 'board')
      .map((item) => ({
        key: item,
        name: 'board',
        id: item._id,
        value: false
      }))
    setBoardList(board)
  }, [workspace._id, workspaceBoard])

  useEffect(() => {
    setCheckList([
      { key: 'Teammates', value: false, name: 'teammate' },
      { key: 'Client', value: false, name: 'client' },
      { key: 'All Tasks', value: false, name: 'task' },
      { key: 'All Subtasks', value: false, name: 'subtask' },
      { key: 'Tags', value: false, name: 'tag' },
      { key: ' Views', value: false, name: 'views' }
    ])
    setProjectName(workspace.name)
  }, [open])

  const handleCheckListItem = (index) => {
    const newData = [...checkList]
    newData[index] = { ...newData[index], value: !newData[index].value }
    setCheckList(newData)
  }

  const handleBoardListItem = (index) => {
    const newData = [...boardList]
    newData[index] = { ...newData[index], value: !newData[index].value }
    setBoardList(newData)
  }

  const createDuplicateProject = () => {
    const listObj = {}
    const boardLists = boardList
      .filter((item) => item.value === true)
      .map((el) => el.id)
    checkList.forEach((item) => {
      listObj[item.name] = item.value
    })
    if (boardLists.length === 0) {
      return dispatch(fireErrorToaster('Please Check Atleast One Board'))
    }

    if (listObj.subtask && !listObj.task) {
      return dispatch(
        fireErrorToaster('Please Check Task for duplicating the Subtask')
      )
    }
    // console.log({ ...listObj, board: boardLists, name: projectName }, 'listObj')
    handleDuplicateAction({ ...listObj, board: boardLists, name: projectName })
    handleDialog()
  }

  return (
    <CustomModal open={open} size="small" handleClose={handleDialog}>
      <CustomModalHeader heading="Duplicate Project" size="medium" />
      <div>
        <div className="text-xs px-8 py-2 mb-4 font-medium text-gray-700 bg-custom-blue-light">
          Duplicating this project will make a copy of the project in its
          current team. Select which parts of the project to copy below.
        </div>

        <CustomModalBody>
          <div className="w-full mb-4">
            <p className="text-xs font-medium text-gray-700 mb-2 ">
              Project Name
            </p>
            <TextField
              required
              onChange={(e) => setProjectName(e.target.value)}
              value={projectName}
              className="w-full"
            />
          </div>
          <div className="flex flex-row justify-between items-start">
            <div className="flex flex-col">
              {checkList.map((item, index) => (
                <FormControlLabel
                  name={item.key}
                  onChange={() => handleCheckListItem(index)}
                  checked={item.value}
                  label={
                    <span className="text-xs font-medium text-gray-700 ">
                      {item.key}
                    </span>
                  }
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                    />
                  }
                />
              ))}
            </div>
            <div className="flex flex-col">
              <p className="text-xs pt-3 pb-2 font-medium" sty>
                Board (Atleast one board is required)
              </p>
              {boardList.map((item, index) => (
                <FormControlLabel
                  name={item.key.name}
                  onChange={() => handleBoardListItem(index)}
                  checked={item.value}
                  label={
                    <span className="text-xs font-medium text-gray-700 ">
                      {item.key.name}
                    </span>
                  }
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                    />
                  }
                />
              ))}
            </div>
          </div>
        </CustomModalBody>
      </div>
      <CustomModalFooter>
        <div className="flex flex-row-reverse gap-2">
          <LoadingButton onClick={createDuplicateProject}>
            Create New Project
          </LoadingButton>
          <Button variant="outlined" onClick={handleDialog}>
            Cancel
          </Button>
        </div>
      </CustomModalFooter>
    </CustomModal>
  )
}
