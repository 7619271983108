import { CallReceived } from '@material-ui/icons'
import axios from 'axios'
import { errorMessages } from 'utils'
import { CALENDAR } from './reducer'

export const createNewCalendar = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calender`,
        method: 'POST',
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: CALENDAR.ADD_ONE_CALENDAR, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
export const updateCalendar = (id, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calender/${id}`,
        method: 'PUT',
        data
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const deleteCalendar = (id, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calender/${id}`,
        method: 'DELETE'
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchCalendar = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calender/user`,
        method: 'GET'
      })
      if (callback) callback(res.data, false)
      dispatch({ type: CALENDAR.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const createNewCalendarEvents = (data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calenderevent`,
        method: 'POST',
        data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: CALENDAR.ADD_ONE_CALENDAR_EVENT,
        payload: {
          ...res.data,
          start: new Date(Date.parse(res.data.start)),
          end: new Date(Date.parse(res.data.end))
        }
      })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
export const updateCalendarEvents = (id, data, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calenderevent/${id}`,
        method: 'PUT',
        data
      })
      dispatch({
        type: CALENDAR.UPDATE_ONE_CALENDAR_EVENT,
        payload: {
          ...res.data,
          start: new Date(Date.parse(res.data.start)),
          end: new Date(Date.parse(res.data.end))
        }
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const deleteCalendarEvents = (id, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calenderevent/${id}`,
        method: 'DELETE'
      })
      if (callback) callback(res.data, false)
      dispatch({ type: CALENDAR.DELETE_ONE_CALENDAR_EVENT, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchCalendarEvents = (calendarId, query, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calenderevent/calender/${calendarId}${query ? query : ''}`,
        method: 'GET'
      })
      dispatch({
        type: CALENDAR.FETCHED_CALENDAR_EVENT,
        payload: res.data.map(item => ({
          ...item,
          start: new Date(Date.parse(item.start)),
          end: new Date(Date.parse(item.end))
        }))
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchProjectsEvents = (query, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calenderevent/project${query ? query : ''}`,
        method: 'GET'
      })
      const transformedData = res.data.map(project => ({
        _id: project._id,
        start: new Date(Date.parse(project.dueDate)),
        end: new Date(Date.parse(project.dueDate)),
        title: project.name,
        eventType: 'projects',
        allDay: true
      }))
      dispatch({
        type: CALENDAR.FETCHED_PROJECTS_EVENT,
        payload: transformedData
      })
      if (callback) callback(transformedData, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const fetchAllCalendarEvents = callback => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/calenderevent/user`,
        method: 'GET'
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
