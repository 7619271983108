import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import OverdueTasks from './OverdueTasks'
import TasksCount from './TasksCount'
// import workspaceStyles from '../workspaceStyles'
// import clsx from 'clsx'
// import { getTaskByWorkspaceBoardId } from 'thunks/task/actions'
import Teammates from './Teammates'
import ProjectInfo from './ProjectInfo'
import ProjectLogs from './ProjectLogs'
import { useParams } from 'react-router-dom'
import LateCompletedTasks from './LateCompletedTask'
import TaskPanel from '../Panel/TaskPanel'
import {
  fetchProjectFinancialData,
  getSingleProjectStats
} from 'thunks/workspace/actions'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
// import { useWorkspacePermission } from 'customHooks'
import { userRoles } from 'utils'
import ProjectHours from './ProjectHours'
import TotalTimeByTask from './TotalTimeByTask'
// import ProjectDetails from './ProjectDetails'
import Timesheet from './Timesheet'
import clsx from 'clsx'
import ProjectCompletedStatus from './ProjectCompletedStatus'
import ProjectDates from './ProjectDates'
import overviewStyles from './overviewStyles'
import OverviewLoading from './OverviewLoading'
import StatusUpdateCard from './StatusUpdateCard'
import ProjectCost from './ProjectCost'

const ProjectStats = ({ projectStats, openInviteTeammate, statusPosts }) => {
  const classes = overviewStyles()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const { workspaceState } = useContext(SingleWorkspaceContext)
  const {
    // taskPermission,
    // subtaskPermission,
    workspacePermission
  } = workspaceState.permission
  // const [currentOverviewBoard, setCurrentOverviewBoard] = useState({})
  // const userTasks = useSelector((state) => state.userTasks.data)
  // const [currentWorkspace, setCurrentWorkspace] = useState({})
  // const dispatch = useDispatch()
  // const [loading, setLoading] = useState(true)
  const { workspaceId } = useParams()
  // const history = useHistory()
  // const { url } = useRouteMatch()
  const [viewTask, setViewTask] = useState({
    open: false,
    data: {},
    key: ''
  })
  const [projectStatsData, setProjectStatsData] = useState({ ...projectStats })
  const [projectStatusPosts, setProjectStatusPosts] = useState({
    data: [],
    loading: true
  })
  const [financialData, setFinancialData] = useState({
    data: {},
    loading: true
  })
  const meData = useSelector(state => state.me.data)
  const userReportings = useSelector(state => state.userReportings)

  const updateProjectStatsData = (taskData, key) => {
    setProjectStatsData(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [viewTask.key]: {
          ...prev.data[viewTask.key],
          tasks: prev.data[viewTask.key].tasks.map(el => {
            if (el._id === taskData._id) {
              return taskData
            } else {
              return el
            }
          })
        }
      }
    }))
    setViewTask(prev => ({ ...prev, data: taskData }))
  }
  // const currentWorkspacePermission = useWorkspacePermission(
  //   currentWorkspace?._id
  // )

  useEffect(() => {
    setProjectStatsData(projectStats)
  }, [projectStats])

  useEffect(() => {
    if (userReportings.fetched) {
      setProjectStatusPosts({
        data: userReportings.data.filter(status => status.state !== 'draft'),
        loading: false
      })
    }
  }, [userReportings])

  useEffect(() => {
    if (workspaceId) {
      fetchProjectFinancialData(workspaceId, (res, err) => {
        setFinancialData(prev => ({
          ...prev,
          data: res,
          loading: false
        }))
      })
    }
  }, [workspaceId])

  // useEffect(() => {
  //   const cw = userWorkspaces.data.find((item) => item._id === workspaceId)
  //   console.log('CW', cw?._id)
  //   console.log('CWEEEEE', userWorkspaces.currentWorkspace?._id)
  //   if (cw) {
  //     setCurrentWorkspace(cw)
  //   }
  // }, [userWorkspaces, workspaceId])

  // useEffect(() => {
  //   if (currentWorkspace?.defaultBoard) {
  //     setCurrentOverviewBoard(currentWorkspace.defaultBoard)
  //     // setLoading(false)
  //   }
  // }, [currentWorkspace])

  // useEffect(() => {
  //   if (currentOverviewBoard._id) {
  //     setLoading(true)
  //     dispatch(
  //       getTaskByWorkspaceBoardId(currentOverviewBoard._id, (res, err) => {
  //         if (!err) {
  //           setLoading(false)
  //         }
  //       })
  //     )
  //   }
  // }, [currentOverviewBoard._id])

  const changeOverviewBoard = board => {
    if (board === 'all') {
      setProjectStatsData(projectStats)
    } else {
      getSingleProjectStats(workspaceId, board._id, (res, err) => {
        if (!err) {
          setProjectStatsData({ loading: false, data: res })
        }
      })
    }
    // setCurrentOverviewBoard(board)
  }

  // const handleViewAll = () => {
  //   history.push(
  //     `${url.split('/overview')[0]}/board/${currentWorkspace.defaultBoard._id}`
  //   )
  // }

  const openTaskPanel = (task, key) => {
    setViewTask({ open: true, data: task, key })
  }

  const closeTaskPanel = () => {
    setViewTask(prev => ({ ...prev, open: false, key: '' }))
  }

  return (
    <>
      <div className={clsx('mb-8', classes.container)}>
        {projectStatsData.loading ? (
          <OverviewLoading />
        ) : (
          <>
            <div className={classes.tasksCount}>
              <TasksCount
                loading={projectStatsData.loading}
                // currentBoard={currentOverviewBoard}
                atRisk={projectStatsData.data.atRisk}
                completed={projectStatsData.data.completed}
                unPlanned={projectStatsData.data.unPlanned}
                overdue={projectStatsData.data.overdue}
              />
            </div>

            <div className={classes.projectCompletion}>
              <ProjectCompletedStatus
                totalTasks={projectStatsData.data.totalTask}
                completedTasks={projectStatsData.data.completed.totalTask}
              />
            </div>

            <div className={classes.projectDates}>
              <ProjectDates
                workspace={currentWorkspace}
                readOnly={!workspacePermission.update}
              />
            </div>

            {/* <ProjectDetails
              workspace={currentWorkspace}
              loading={projectStatsData.loading}
              completedTasks={projectStatsData.data.completed.totalTask}
              totalTasks={projectStatsData.data.totalTask}
            /> */}
            <div
              className={clsx(
                classes.tallerCard,
                classes.tallerCardSecondRow,
                classes.tasksOverdue
              )}
            >
              <OverdueTasks
                loading={projectStatsData.loading}
                openTaskPanel={openTaskPanel}
                tasks={projectStatsData.data.overdue.tasks}
              />
            </div>

            <div className={clsx(classes.tallerCard, classes.projectInfo)}>
              <ProjectInfo
                changeOverviewBoard={changeOverviewBoard}
                workspace={currentWorkspace}
                completedTasks={projectStatsData.data.completed.totalTask}
                totalTasks={projectStatsData.data.totalTask}
                workspacePermission={workspacePermission}
                readOnly={false}
              />
            </div>

            {/* <div className={clsx(classes.tallerCard, classes.statusUpdate)}>
              <StatusUpdateCard
                statusPostsData={projectStatusPosts.data}
                loading={projectStatusPosts.loading}
              />
            </div> */}

            <div
              className={clsx(classes.tallerCard, classes.tallerCardSecondRow)}
            >
              <LateCompletedTasks
                loading={projectStatsData.loading}
                openTaskPanel={openTaskPanel}
                tasks={projectStatsData.data.lateCompleted.tasks}
              />
            </div>
            {meData.role < userRoles.USER_CLIENT && (
              <div
                className={clsx(
                  classes.tallerCard,
                  classes.tallerCardSecondRow,
                  classes.projectHours
                )}
              >
                <ProjectHours
                  workspace={currentWorkspace}
                  timeTracked={projectStatsData.data.totalTimeTracked}
                  roleData={projectStatsData.data.roleWiseData}
                />
              </div>
            )}

            <div className={clsx(classes.tallerCard, classes.taskTimeWise)}>
              <TotalTimeByTask
                loading={projectStatsData.loading}
                openTaskPanel={openTaskPanel}
                tasks={projectStatsData.data.completed.tasks}
              />
            </div>

            {meData.role < userRoles.USER_CLIENT && (
              <div className={clsx(classes.tallerCard, classes.projectCost)}>
                <ProjectCost
                  workspace={currentWorkspace}
                  // loading={financialData.loading}
                  // financialData={financialData.data}
                />
              </div>
            )}

            <div className={clsx(classes.tallerCard, classes.timesheet)}>
              <Timesheet
                loading={projectStatsData.loading}
                userTimesheetData={projectStatsData.data.userWiseTimesheetData}
              />
            </div>

            <div className={clsx(classes.tallerCard, classes.statusUpdate)}>
              <StatusUpdateCard
                statusPostsData={projectStatusPosts.data}
                loading={projectStatusPosts.loading}
                readOnly={false}
              />
            </div>

            <div className={clsx(classes.tallerCard)}>
              <Teammates
                openInviteTeammate={openInviteTeammate}
                workspace={currentWorkspace}
                workspacePermission={workspacePermission}
              />
            </div>

            <div className={clsx(classes.tallerCard)}>
              <ProjectLogs />
            </div>
          </>
        )}
      </div>
      <TaskPanel
        open={viewTask.open}
        taskData={viewTask.data}
        onClose={closeTaskPanel}
        workspacePermission={workspacePermission}
        // currentWorkspace={currentWorkspace}
        // currentBoard={currentOverviewBoard}
        // taskPermission={taskPermission}
        // subtaskPermission={subtaskPermission}
        updateProjectStats={updateProjectStatsData}
        type="overview"
      />
    </>
  )
}

const LoadingAnimation = () => {}

export default ProjectStats
