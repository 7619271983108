import axios from 'axios'
import { errorMessages } from 'utils'
import { INTEGRATIONS } from './reducers'

/**
 * Create a Workspace
 * @param {Object} data app data
 * @param {Function} callback callback function
 */
export const addIntegration = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/connectedapps',
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: INTEGRATIONS.ADD_ONE_INTEGRATION, payload: res.data })
    } catch (err) {
      console.log(err)
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: INTEGRATIONS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Find INTEGRATION APP by Id
 * @param {String} appId APP ID
 * @param {Function} callback callback function
 */
export const findIntegrationById = (appId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/connectedapps/${appId}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: INTEGRATIONS.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: INTEGRATIONS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * update data of a integrated app
 * @param {String} aapid integrated app id
 * @param {Object} data updating data
 * @param {Function} callback callback function
 */
export const updateIntegratedApp = (appId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/connectedapps/${appId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: INTEGRATIONS.UPDATE_ONE_INTEGRATION, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: INTEGRATIONS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * delete integrated app
 * @param {String} aapid integrated app id
 * @param {Function} callback callback function
 */
export const deleteIntegratedApp = (appId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/connectedapps/${appId}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: INTEGRATIONS.DELETE_ONE_INTEGRATION, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: INTEGRATIONS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetches all integrations of a user
 * @param {Function} callback callback function
 */
export const fetchAllIntegrationsForCurrentUser = (callback) => {
  return async (dispatch) => {
    dispatch({ type: INTEGRATIONS.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/connectedapps/user'
      })
      if (callback) callback(res.data, false)
      dispatch({ type: INTEGRATIONS.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: INTEGRATIONS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

// export const addIntegration = (data, callback) => {
//   console.log('ADD INTEGRATIONS')
//   // return async (dispatch) => {
//   console.log('HAHAHA')
//   // try {
//   axios({
//     method: 'POST',
//     url: '/connectedapps',
//     headers: {
//       Authorization: getAuthToken()
//     },
//     data
//   }).then((res) => console.log(res))
//   // }
// }
