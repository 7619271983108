import React, { useEffect, useState, useCallback, useContext } from 'react'
// import { Calendar, momentLocalizer } from 'react-big-calendar'
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
// import moment from 'moment'
import { Skeleton } from '@material-ui/lab'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { hideChatMessenger } from 'utils'
import {
  getCalendarViewTasks,
  removeTaskFromCalendar
} from 'thunks/task/actions'
import { updateTaskDetails } from 'thunks/task/actions'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import TaskPanel from 'components/Workspace/Panel/TaskPanel'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import CreateTaskPopover from './CreateTaskPopover'
// import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
// import AddIcon from '@material-ui/icons/Add'
// import { CustomTooltip } from 'global/globalComponents'
// import CustomToolbar from './CustomToolbar'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import TasksCalendar from './TasksCalendar'
import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
// import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'

// const localizer = momentLocalizer(moment)
// const DragAndDropCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

const CalendarView = () => {
  const dispatch = useDispatch()
  const search = useSelector(state => state.generalAppState.boardSearch)
  const {
    // data: boardTasksData,
    data: boardNewTasksData,
    // loading: { all: allTasksLoading },
    calendarViewTasks
    // pendingTasks
  } = useSelector(state => state.userTasks)
  const [events, setEvents] = useState([])
  const { boardId, workspaceId, taskId } = useParams()
  const [taskPanel, setTaskPanel] = useState({
    open: false,
    data: {}
  })
  const history = useHistory()
  const { url } = useRouteMatch()
  const {
    workspaceState: { permission, filters }
  } = useContext(SingleWorkspaceContext)
  const { taskPermission } = permission
  const approvalTasks = useSelector(state => state.approvals.data.tasks)
  // const [createTaskModal, setCreateTaskModal] = useState({
  //   open: false,
  //   data: {}
  // })
  const [createTask, setCreateTask] = useState({
    anchorEl: null,
    data: {}
  })

  const [allTasksData, setAllTasksData] = useState([])
  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  const currentWorkspaceBoard = useSelector(
    state => state.userWorkspaces.currentBoard
  )
  // const { socketId } = useContext(WorkspacePusherContext)

  const {
    workspaceState: { groupBy, showApprovalTasks }
  } = useContext(SingleWorkspaceContext)

  // const { getTaskById, getSearchedTasks } = useWorkspaceFunctions(groupBy)
  const [visibleDates, setVisibleDates] = useState({
    startDate: localizer.firstVisibleDay().toISOString(),
    endDate: localizer.lastVisibleDay().toISOString()
  })

  useEffect(
    function () {
      setAllTasksData(
        calendarViewTasks.data
          .filter(taskId => boardNewTasksData[taskId])
          .map(taskId => boardNewTasksData[taskId])
      )
    },
    [calendarViewTasks, boardNewTasksData]
  )

  // const handleSlotClick = useCallback(({ action, start, end }) => {
  //   if (action === 'doubleClick') {
  //     // setCreateTaskModal({ open: true, data: { start, end } })
  //   }
  // }, [])

  useEffect(() => {
    hideChatMessenger()
  })
  useEffect(() => {
    if (filters.loading) return
    if (
      workspaceId &&
      boardId &&
      visibleDates.startDate &&
      visibleDates.endDate
    ) {
      handleFetchCalendarViewTasks()
    }
  }, [showApprovalTasks, visibleDates, search, filters])

  useEffect(() => {
    const mappedData = allTasksData
      .filter(item => item.dueDate)
      .map(item => {
        return {
          id: item._id,
          title: item.title,
          allDay: false,
          start: item.startDate
            ? new Date(item.startDate)
            : new Date(item.createdAt),
          end: new Date(item.dueDate),
          taskSection: currentWorkspaceBoard.boardSection.find(
            section => section._id === item.taskSection
          )
        }
      })

    setEvents(mappedData)
  }, [allTasksData])

  useEffect(() => {
    if (!taskId) return setTaskPanel({ open: false, data: {} })

    const task = boardNewTasksData[taskId]

    if (task) setTaskPanel({ open: true, data: task })
  }, [taskId, boardNewTasksData])

  const handleFetchCalendarViewTasks = () => {
    const body = {
      workspace: workspaceId,
      workspaceBoard: boardId,
      myApprovals: showApprovalTasks,
      startDate: visibleDates.startDate,
      endDate: visibleDates.endDate,
      search: search || null
    }

    if (filters.data._id) {
      body.filters = {
        op: filters.data.op,
        fields: filters.data.fields
      }
    }

    dispatch(
      getCalendarViewTasks(body, { limit: 10, type: 'calendar', page: 0 })
    )
  }

  const handleUpdateEvent = useCallback(
    ({ event, start, end }) => {
      const updatedData = events.map(item => {
        if (item.id === event.id) {
          return { ...item, start: start, end: end }
        }
        return item
      })
      setEvents(updatedData)

      dispatch(
        updateTaskDetails(event.id, {
          workSpace: workspaceId,
          taskSection: event.taskSection._id,
          startDate: start.toISOString(),
          dueDate: end.toISOString()
        })
      )
    },
    [events]
  )

  // const eventPropGetter = (event, start, end, isSelected) => ({
  //   style: { backgroundColor: event.taskSection.color }
  // })

  const handleCloseTaskSidePanel = () => {
    history.push(url.split('/task')[0] + '?view=calendar')
  }

  const openTaskSidePanel = task => {
    history.push(`${url}/task/${task.id}?view=calendar`)
  }

  const handleCloseCreateTask = () => {
    setCreateTask(prev => ({ ...prev, anchorEl: null }))
    // setCreateTaskModal((prev) => ({ ...prev, open: false }))
  }

  const handleAddClick = e => {
    setCreateTask({
      anchorEl: e.currentTarget,
      data: {
        startDate: e.currentTarget.dataset.value,
        dueDate: e.currentTarget.dataset.value
      }
    })
  }

  const updateVisibleDates = val => {
    if (typeof val === 'object' && val.start && val.end) {
      setVisibleDates({
        startDate: val.start.toISOString(),
        endDate: val.end.toISOString()
      })
    }
  }

  const afterTaskDelete = useCallback(({ taskId, sectionId }) => {
    dispatch(removeTaskFromCalendar({ taskId }))
  }, [])

  return (
    <>
      <TasksCalendar
        isLoading={calendarViewTasks.loading}
        events={events}
        permission={taskPermission}
        handleUpdateEvent={handleUpdateEvent}
        openTaskSidePanel={openTaskSidePanel}
        onAddTaskClick={handleAddClick}
        localizer={localizer}
        onRangeChange={updateVisibleDates}
      />
      <TaskPanel
        taskData={taskPanel.data}
        open={taskPanel.open}
        onClose={handleCloseTaskSidePanel}
        afterTaskDelete={afterTaskDelete}
      />
      <CreateTaskPopover
        anchorEl={createTask.anchorEl}
        data={createTask.data}
        handleClose={handleCloseCreateTask}
      />
    </>
  )
}

export const CalendarViewLoading = () => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
      gridAutoRows: 180,
      gridGap: 2
    }}
  >
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
    <Skeleton height={180} variant="rect" />
  </div>
)

export default CalendarView
