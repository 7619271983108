import React, { useEffect, useState } from 'react'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { useDispatch, useSelector } from 'react-redux'
import { SvgIcon, TextField } from '@material-ui/core'
import {
  addIntegration,
  deleteIntegratedApp,
  updateIntegratedApp
} from 'thunks/integration/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { LoadingButton } from 'global/globalComponents'
import { ReactComponent as QuestionCircleIcon } from 'static/svg/question-circle.svg'
import { useHistory, useParams } from 'react-router-dom'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import integrationCardDetails from 'static/IntegrationCardDetails/IntegrationCardDetail'

const Connection = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState({
    update: false,
    delete: false
  })
  const userIntegrations = useSelector(state => state.userIntegrations)
  const [data, setData] = useState({})
  const [integrationAppData, setIntegrationAppData] = useState({
    appName: '',
    key: ''
  })
  const { name } = useParams()

  // useEffect(() => {
  //   if (!data?.name) {
  //     history.goBack()
  //   }
  // }, [data])

  useEffect(() => {
    let chatApp = integrationCardDetails.chat.find(
      el => el?.name.split(' ').join('').toLowerCase() === name
    )

    let app = userIntegrations.data?.find(
      el => el?.appName.split(' ').join() === name?.toLowerCase()
    )

    chatApp = app
      ? {
          ...chatApp,
          connected: app.isActive,
          ...chatApp.modalContent,
          key: app?.key,
          appId: app._id
        }
      : { ...chatApp, ...chatApp.modalContent }

    setData(chatApp)
  }, [name])

  useEffect(
    () =>
      setIntegrationAppData(() => ({
        appName: data?.name?.toLowerCase(),
        key: data?.key
      })),
    [data]
  )

  const appIdHandler = e => {
    setIntegrationAppData({
      appName: data?.name?.toLowerCase(),
      key: e.target.value
    })
  }

  const integrationAddHandler = () => {
    if (!integrationAppData.key)
      return dispatch(fireErrorToaster(`${data.accountId} is required`))
    if (data?.connected) {
      if (data?.key === integrationAppData.key)
        return dispatch(
          fireErrorToaster(
            `App is already integrated with this ${data?.accountId}`
          )
        )
      setLoading(prev => ({ ...prev, update: true }))
      const updatedData = {
        isActive: data?.connected,
        key: integrationAppData.key
      }
      dispatch(
        updateIntegratedApp(data.appId, updatedData, updateIntegrationCallback)
      )
    } else {
      setLoading(prev => ({ ...prev, update: true }))
      dispatch(
        addIntegration(integrationAppData, (res, err) => {
          setLoading(prev => ({ ...prev, update: false }))
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            dispatch(fireSuccessToaster('Integrated Successfully!'))
            setIntegrationAppData(prev => ({
              ...prev,
              key: res.key
            }))
            setData(prev => ({
              ...prev,
              connected: true,
              key: res?.key,
              appId: res._id
            }))
          }
        })
      )
    }
  }

  const updateIntegrationCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Updated Successfully!'))
      setIntegrationAppData(prev => ({
        ...prev,
        key: res.key
      }))
      setData(prev => ({
        ...prev,
        key: res?.key
      }))
    }
  }

  const integrationDeleteHandler = () => {
    setLoading(prev => ({ ...prev, delete: true }))
    dispatch(deleteIntegratedApp(data.appId, deleteIntegrationCallback))
  }

  const deleteIntegrationCallback = (res, err) => {
    setLoading(prev => ({ ...prev, delete: false }))
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Deleted Successfully!'))
      setIntegrationAppData(prev => ({
        ...prev,
        key: ''
      }))
      setData(prev => ({
        ...prev,
        connected: false,
        key: '',
        appId: ''
      }))
    }
  }

  return (
    <OuterContentWrapper>
      <SectionHeading className="mb-6 lg:mb-8">
        Connect with {data?.name}
      </SectionHeading>
      <Wrapper>
        <div className="flex justify-between flex-start items-baseline mb-4">
          <p className="mr-4 text-primary-mid-dark text-smaller lg:text-sm">
            {data?.description}
          </p>
        </div>
        <div className=" border rounded px-8 py-8">
          <div className="mb-8">
            <div className="flex">
              <p className="text-sm lg:text-base font-medium mb-2">
                {data?.accountId}
              </p>
              <a
                href="https://help.clientvenue.com"
                target="_blank"
                className="ml-2"
              >
                <SvgIcon
                  component={QuestionCircleIcon}
                  style={{
                    color: '#1600e5',
                    fontSize: '1rem',
                    marginBottom: '2px'
                  }}
                  className="cursor-pointer"
                  viewBox="0 0 600 476.6"
                />
              </a>
            </div>

            <TextField
              className="w-full p-2"
              variant="outlined"
              value={integrationAppData.key}
              onChange={appIdHandler}
            />
          </div>

          <div className="flex justify-end space-x-2">
            <LoadingButton
              onClick={integrationAddHandler}
              loading={loading.update}
            >
              {data?.connected ? 'Update' : 'Save'}
            </LoadingButton>
            {data?.connected && (
              <LoadingButton
                onClick={integrationDeleteHandler}
                loading={loading.delete}
              >
                Delete
              </LoadingButton>
            )}
          </div>
        </div>
      </Wrapper>
    </OuterContentWrapper>
  )
}

export default Connection
