import React, { useState } from 'react'
import moment from 'moment'
import { PopupMenu } from 'global/globalComponents'
import { ReactComponent as ChevronRightIcon } from 'static/svg/chevron-right.svg'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import { ReactComponent as CalendarNoteIcon } from 'static/svg/calendar-note.svg'
import { ReactComponent as CalendarNumberIcon } from 'static/svg/calendar-number.svg'

const CustomCalendarToolbar = toolbar => {
  // const [view, setView] = useState('month')
  // const [selectedView, setSelectedView] = useState('Month')
  // const goToDayView = () => toolbar.onView('day')s
  // const goToWeekView = () => toolbar.onView('week')
  // const goToMonthView = () => toolbar.onView('month')

  const goToBack = () => toolbar.onNavigate('PREV')
  const goToNext = () => toolbar.onNavigate('NEXT')
  const goToCurrent = () => toolbar.onNavigate('TODAY')

  const handleChangeView = newVal => {
    // setSelectedView(newVal)
    toolbar.onView(newVal)
  }

  // const label = () => {
  //   const date = moment(toolbar.date)
  //   return (
  //     <span>
  //       {date.format('MMMM')}
  //       <span> {date.format('YYYY')}</span>
  //     </span>
  //   )
  // }

  return (
    <div className="flex items-center" style={{ background: '#f9f8f8' }}>
      <div className="space-x-2 mr-4 py-4">
        <button
          onClick={goToCurrent}
          className="text-sm hover:bg-primary-light px-3 py-1 rounded-sm"
        >
          Today
        </button>
        <button
          onClick={goToBack}
          className="px-2 border rounded-sm border-gray-300 hover:bg-primary-light transition-color bg-white"
        >
          &#8249;
        </button>
        <button
          onClick={goToNext}
          className="px-2 border border-gray-300 rounded-sm hover:bg-primary-light transition-color bg-white"
        >
          &#8250;
        </button>
      </div>

      <span className="text-sm py-4">{toolbar.label}</span>
      {/* <span className="text-sm py-4">{label()}</span> */}

      <div className="ml-4 py-2">
        <PopupMenu
          targetComp={
            <button className="text-sm flex items-center pb-2 pt-2 px-2 rounded-sm capitalize hover:bg-primary-light focus:bg-primary-light">
              {toolbar.view}
              <ChevronRightIcon className="ml-2 rotate-90" />
            </button>
          }
          menuItems={[
            {
              label: 'Month',
              icon: <CalendarIcon />,
              method: () => handleChangeView('month')
            },
            {
              label: 'Week',
              icon: <CalendarNoteIcon />,
              method: () => handleChangeView('week')
            },
            {
              label: 'Day',
              icon: <CalendarNumberIcon />,
              method: () => handleChangeView('day')
            }
          ]}
        />
      </div>
    </div>
  )
}

export default CustomCalendarToolbar
