import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import StandupList from './StandupsListItem'

const MorningStandups = ({
  // timeWindow,
  // abbreviation,
  toggleDeleteStandupAlert,
  toggleStandupCompletion,
  standupsOpenFor,
  openEditTaskPanel,
  standupsData
  // isDisabled
}) => {
  const meData = useSelector(state => state.me.data)
  const filteredStandupsData = useMemo(() => {
    return standupsData.filter(item => item.type === 'morning')
  }, [standupsData])

  return (
    <div>
      {/* {Boolean(filteredStandupsData.length) && (
        <div className="text-right space-x-2">
          <span className="text-xs pr-2">
            Total tasks: {filteredStandupsData.length}
          </span>
        </div>
      )} */}
      {!Boolean(filteredStandupsData.length) && (
        <div className="mb-2">
          <h3 className="font-semibold text-lg lg:text-xl mb-1">
            Good morning {meData.name}!
          </h3>
          <p className="text-smaller lg:text-sm leading-6">
            Here you can add your morning standups. Please select projects and
            tasks that you'll be working on.
          </p>
        </div>
      )}

      <StandupList
        data={filteredStandupsData}
        canUpdate={true}
        canDelete={true}
        toggleStandupCompletion={toggleStandupCompletion}
        toggleDeleteStandupAlert={toggleDeleteStandupAlert}
        standupsOpenFor={standupsOpenFor}
        openEditTaskPanel={openEditTaskPanel}
      />
    </div>
  )
}

export default React.memo(MorningStandups)
