import { useSelector } from 'react-redux'
import { ReactComponent as ShareIcon } from 'static/svg/share-icon.svg'
import templateStyles from './templateStyles'
import clsx from 'clsx'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'

const TemplateModalLeftView = ({
  linkData,
  setLinkData,
  setSelectedCategory,
  setStep
}) => {
  const classes = templateStyles()
  const meData = useSelector(state => state.me.data)

  return (
    <div
      style={{ height: 'calc(80vh - 64px)', overflow: 'auto', width: '240px' }}
    >
      <div className="p-4">
        <button
          onClick={() => setStep(1)}
          className="text-primary-main text-smaller lg:text-sm"
        >
          <ArrowBackRoundedIcon fontSize="small" />
          &nbsp; back
        </button>
      </div>
      <div className="px-4">
        <div className=" ml-1 mb-2 text-sm lg:text-base font-medium ">
          Explore
        </div>
        <div className="mb-6 text-sm">
          {['  All Templates', ' Your Templates'].map((item, index) => (
            <div
              className={clsx(
                { active: linkData === index },
                classes.activeRow,
                'text-smaller lg:text-sm px-4 py-2 mb-1 rounded-sm cursor-pointer hover:bg-custom-blue-light'
              )}
              onClick={() => {
                setLinkData(index)
                setSelectedCategory('all')
              }}
            >
              {item}
            </div>
          ))}
        </div>
        <div className=" border-b"></div>

        <div className="my-6 text-sm">
          {[
            'Marketing',
            'Design',
            'Software Development'
            // 'Manufacturing',
            // 'Operation'
          ].map((item, index) => (
            <div
              className={clsx(
                { active: linkData === index + 2 },
                classes.activeRow,
                'text-smaller lg:text-sm px-4 py-2 mb-1 rounded-sm cursor-pointer hover:bg-custom-blue-light'
              )}
              onClick={() => {
                setLinkData(index + 2)
                setSelectedCategory(item)
              }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TemplateModalLeftView
