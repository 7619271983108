import { GENERAL_APP_STATE } from './reducers'

export const toggleSideDrawer = (value) => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.TOGGLE_SIDE_DRAWER, payload: value })
  }
}

export const toggleSidebar = (value) => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.TOGGLE_SIDE_BAR, payload: value })
  }
}

/**
 * Deprecated. No longer maintained
 */
export const showSideBar = (data) => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.SHOW_SIDEDRAWER, payload: data })
  }
}

export const showIntegratedHeader = () => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.SHOW_INTEGRATED_HEADER })
  }
}

export const toggleCreateInvoiceModal = (invoiceType) => {
  return (dispatch) => {
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_CREATE_INVOICE_MODAL,
      payload: invoiceType
    })
  }
}

export const showNormalHeader = () => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.SHOW_NORMAL_HEADER })
  }
}

// export const updateUserAcces = (role) => {
//   return (dispatch, getState) => {
//     // const {
//     //   me: { data }
//     // } = getState()
//     dispatch({
//       type: GENERAL_APP_STATE.UPDATE_USER_ACCESS,
//       payload: role
//     })
//   }
// }

export const toggleAddTeammateSidePanel = (
  open,
  data = {},
  aboveModal = false
) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        addTeammate: {
          open:
            open === undefined
              ? !state.generalAppState.sidePanel.addTeammate.open
              : open,
          data,
          aboveModal
        }
      }
    })
  }
}

export const toggleEmbedModal = (value, anchorPos = '') => {
  return {
    type: GENERAL_APP_STATE.TOGGLE_MODAL,
    payload: {
      embedModal: {
        open: value,
        anchorPos
      }
    }
  }
}

export const toggleAddClientSidePanel = (
  open,
  data = {},
  aboveModal = false
) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        addClient: {
          open:
            open === undefined
              ? !state.generalAppState.sidePanel.addClient.open
              : open,
          data,
          aboveModal
        }
      }
    })
  }
}

export const toggleAddWorkspaceSidePanel = (value, category = 'Internal') => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        addWorkspace: {
          open:
            value === undefined
              ? !state.generalAppState.sidePanel.addWorkspace.open
              : value,
          category
        }
      }
    })
  }
}

export const toggleAddServiceSidePanel = () => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: { addService: !state.generalAppState.sidePanel.addService }
    })
  }
}

export const toggleEditTeammatesSidePanel = (value) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        editTeammate:
          value === undefined
            ? !state.generalAppState.sidePanel.editTeammate
            : value
      }
    })
  }
}

export const toggleLogsSidePanel = (value) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        logsPanel:
          value === undefined
            ? !state.generalAppState.sidePanel.logsPanel
            : value
      }
    })
  }
}

export const setTeammateRole = (value) => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.SET_TEAMMATE_ROLE, payload: value })
  }
}

export const setExpandState = (value) => {
  return (dispatch) => {
    dispatch({ type: GENERAL_APP_STATE.SET_EXPAND_STATE, payload: value })
  }
}

export const removedFromWSModal = (openStatus = true) => {
  return {
    type: GENERAL_APP_STATE.OPEN_REMOVED_FROM_WS_MODAL,
    payload: openStatus
  }
}

export const toggleUpgradeModal = (callback) => {
  return {
    type: GENERAL_APP_STATE.TOGGLE_UPGRADE_PLAN_MODAL
  }
}

export const handleFilePreview = ({ open = true, file }) => {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_DOCUMENT_PREVIEW,
      payload: { open: open, file }
    })
  }
}

export const showHeaderShadow = (value) => {
  return { type: GENERAL_APP_STATE.TOGGLE_HEADER_SHADOW, payload: value }
}

export const setHeaderSearch = (value) => {
  return { type: GENERAL_APP_STATE.SET_HEADER_SEARCH, payload: value }
}

export const setBoardSearch = (value) => {
  return { type: GENERAL_APP_STATE.SET_BOARD_SEARCH, payload: value }
}

export const toggleCreateTaskPanel = (open, data = {}) => {
  return (dispatch) => {
    dispatch({
      type: GENERAL_APP_STATE.TOGGLE_SIDE_PANEL,
      payload: {
        addTask: {
          open,
          data
        }
      }
    })
  }
}
