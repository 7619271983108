import React, { useEffect, useState } from 'react'
import { Button, TextField, InputAdornment } from '@material-ui/core'
import {
  createWorkspace,
  createWorkspaceByTemplate
} from 'thunks/workspace/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Select, { components } from 'react-select'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import 'react-datetime/css/react-datetime.css'
import AsanaIcons from 'static/asana/AsanaIcons'
import clsx from 'clsx'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { LoadingButton, ToggleSwitch } from 'global/globalComponents'
import UserDashboardStyles from '../userDashboardStyles'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { userRoles } from 'utils'
import { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'
import TemplatesModal from 'components/Templates/TemplateModal'
import { CVLogo } from 'static/Images'
import Pill from 'global/globalComponents/Pill/Pill'
import { getRoleBadge } from 'utils'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import {
  toggleAddClientSidePanel,
  toggleAddTeammateSidePanel
} from 'thunks/generlAppState/actions'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import {
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'

const colorOptions = [
  '#ffffff',
  '#fb5779',
  '#ff7511',
  '#ffa800',
  '#ffd100',
  '#ace60f',
  '#19db7e',
  '#00d4c8',
  '#48dafd',
  '#0064fb',
  '#6457f9',
  '#9f46e4',
  '#ff78ff',
  '#ff4ba6',
  '#ff93af',
  '#5a7896'
]

const AddProjectModal = ({
  onClose,
  category = 'Internal',
  setStep,
  selectedTemplate,
  templateData,
  formData,
  setFormData,
  setSelectedTemplate,
  elmFor
}) => {
  let selectRef = null
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    data: userTeammates,
    fetched: teammatesFetched,
    loading: teammatesLoading
  } = useSelector(state => state.userTeammates)
  const userClients = useSelector(state => state.userClients.data)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const me = useSelector(state => state.me.data)
  const permission = useSelector(state => state.permission.data?.access)
  // const [userSelectedTemplate, setUserSelectedTemplate] = useState({})
  const [tempModalOpen, setTempModalOpen] = useState(false)
  const [templateOptions, setTemplateOptions] = useState([])
  const [teammateOptions, setTeammateOptions] = useState({})
  const [clientOptions, setClientOptions] = useState({})
  const [invitationLoading, setInvitationLoading] = useState(false)

  const [toggleSwitch, setToggleSwitch] = useState({
    checked: false,
    disable: false,
    load: false
  })

  useEffect(() => {
    const options = userTeammates
      .filter(mate => mate.role !== userRoles.AGENCY_ADMIN)
      .map(mate => {
        if (mate._id === me._id) {
          return
        } else {
          return {
            label: mate.name,
            value: mate._id,
            ...mate
          }
        }
      })
    setTeammateOptions(options)
  }, [userTeammates])

  useEffect(() => {
    const options = userClients.map(client => ({
      label: client.name,
      value: client._id
    }))

    setClientOptions(options)
  }, [userClients])

  useEffect(() => {
    const options = templateData.map(temp => ({
      label: temp.title,
      value: temp._id,
      ...temp
    }))
    setTemplateOptions(options)
  }, [templateData])

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      category: category
    }))
  }, [category])

  useEffect(() => {
    if (!teammatesFetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [teammatesFetched])

  // useEffect(() => {
  //   if (selectedTemplate) {
  //     setUserSelectedTemplate({
  //       label: selectedTemplate.title,
  //       value: selectedTemplate._id
  //     })
  //   } else {
  //     setSelectedTemplate({})
  //   }
  // }, [selectedTemplate])

  const teammateOptionsLabel = teammate => {
    return (
      <div className="">
        <span className="">
          {teammate.name}{' '}
          <span className="pl-2">
            {teammate.role ? getRoleBadge(teammate.role) : ''}
          </span>
        </span>
      </div>
    )
  }

  const clearValue = () => {
    selectRef?.select.clearValue()
  }

  const handleOncreateNewWorkspace = e => {
    e.preventDefault()

    const createdWorkspaces = userWorkspaces.data.map(i => i.name)
    if (createdWorkspaces.includes(formData.name)) {
      dispatch(fireErrorToaster('Duplicate project not allowed'))
      return
    }

    const workspaceData = {
      ...formData,
      assignedAgencyTeam: formData.assignedAgencyTeam.map(i => i.value),
      assignedClientTeam: formData.assignedClientTeam.value
        ? [formData.assignedClientTeam.value]
        : [],
      image: Object.keys(AsanaIcons)[
        Math.floor(Math.random() * Object.keys(AsanaIcons).length)
      ],
      backgroundcolor:
        colorOptions[Math.floor(Math.random() * colorOptions.length)]
    }

    setInvitationLoading(true)

    if (selectedTemplate.value) {
      dispatch(
        createWorkspaceByTemplate(
          { ...workspaceData, templateId: selectedTemplate.value },
          createNewWorkspaceCallback
        )
      )
    } else {
      dispatch(createWorkspace(workspaceData, createNewWorkspaceCallback))
    }
  }

  const createNewWorkspaceCallback = (wsRes, wsErr) => {
    if (wsErr) {
      setInvitationLoading(false)
      dispatch(fireErrorToaster(wsRes))
    } else {
      // ----- Fetching workspace permission after successfully creating new workspace -----
      dispatch(
        fetchWorkspacePermissions((res, err) => {
          dispatch(fireSuccessToaster('Project Created Successfully'))
          setInvitationLoading(false)
          handleDrawerClose()
          clearValue()
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            history.push(
              `${getUserPath(me.role)}/workspace/${wsRes._id}/overview`
            )
          }
        })
      )
    }
  }

  // const handleSwitch = () => {
  //   setToggleSwitch((prev) => ({
  //     ...prev,
  //     checked: !toggleSwitch.checked
  //   }))
  //   setFormData((prev) => ({
  //     ...prev,
  //     category: prev.category === 'Internal' ? 'External' : 'Internal'
  //   }))
  // }

  const handleAddTeammate = e => {
    setFormData(prev => ({
      ...prev,
      assignedAgencyTeam: e
    }))
  }

  const handleAddClient = e => {
    setFormData(prev => ({
      ...prev,
      assignedClientTeam: e
    }))
  }

  const handleFormInputChange = e => {
    const { name, value } = e.target
    if (name === 'budgetedHours' && (+value < 0 || value === '0')) {
      return dispatch(fireErrorToaster('Please enter a valid number'))
    }
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleDrawerClose = () => {
    setFormData({
      name: '',
      description: '',
      dueDate: '',
      assignedAgencyTeam: [],
      assignedClientTeam: {}
    })
    setStep(1)
    setSelectedTemplate({})
    onClose()
  }

  const handleSwitch = () => {
    if (elmFor !== 'workspace') return
    setToggleSwitch(prev => ({
      ...prev,
      checked: !toggleSwitch.checked
    }))
    setFormData(prev => ({
      ...prev,
      category: prev.category === 'Internal' ? 'External' : 'Internal',
      assignedClientTeam: {}
    }))
  }

  const handleDateChange = (e, prop) => {
    setFormData(prev => ({ ...prev, [prop]: e?.toISOString() }))
  }

  // const handleTemplateSection = () => {
  //   // history.push(`${getUserPath(me.role)}/template-center`)
  //   setTempModalOpen(true)
  // }

  const handleAddTemplate = e => {
    setSelectedTemplate(e)
  }

  const optionLabel = template => {
    let color
    if (template.category?.toLowerCase() === 'marketing') {
      color = 'pink'
    } else if (template.category?.toLowerCase() === 'software development') {
      color = 'blue'
    } else if (template.category?.toLowerCase() === 'design') {
      color = 'yellow'
    } else {
      color = 'green'
    }
    return (
      <div className="flex justify-between items-center">
        <span className="pr-2">
          {template.title}{' '}
          <span className="pl-2">
            {template.category ? (
              <Pill variant={color}>{template.category}</Pill>
            ) : (
              ''
            )}
          </span>
        </span>
      </div>
    )
  }

  const handleAddNewTeammate = teammate => {
    setFormData(prev => ({
      ...prev,
      assignedAgencyTeam: [...prev.assignedAgencyTeam, teammate]
    }))
  }

  const inviteTeammate = (inputValue = '') => {
    dispatch(toggleAddTeammateSidePanel(true, { handleAddNewTeammate }, true))
    // onClose()
  }

  const inviteClientUser = (inputValue = '') => {
    dispatch(toggleAddClientSidePanel(true, { handleAddClient }, true))
    // onClose()
  }

  const filterOptions = (candidate, input) => {
    return Boolean(candidate.data.label.match(new RegExp(input, 'i')))
  }

  // const onClose = () => {
  //   // history.push(`${getUserPath(me.role)}/`)
  //   setTempModalOpen(false)
  // }

  return (
    <>
      <div className="h-full flex flex-col justify-between">
        <header
          className={clsx(
            'flex items-center px-6 select-none rounded-t-3xl border-b h-16'
          )}
          style={{ borderBottom: '1px solid #ddd' }}
        >
          <div
            className="flex space-x-2 items-center"
            style={{ flex: '0 0 300px' }}
          >
            <div>
              <h2 className="text-lg lg:text-xl font-semibold text-primary-dark mr-8 whitespace-nowrap">
                New Project
              </h2>
            </div>
          </div>
          <div className="flex items-center ml-auto">
            <div className="flex items-center justify-between">
              <p className=" text-sm text-primary-main font-medium">
                Client Project
              </p>

              <div className="flex items-center gap-2">
                <ToggleSwitch
                  checked={formData.category === 'External'}
                  onChange={handleSwitch}
                  disableRipple={true}
                  loading={toggleSwitch.load}
                  disable={elmFor !== 'workspace'}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="pt-5 px-6 flex-1 overflow-auto">
          <div className="w-full flex items-center space-x-8">
            <div className="w-1/2">
              <FormLabel required>Project Name</FormLabel>

              <TextField
                className="w-full"
                onChange={handleFormInputChange}
                value={formData.name}
                placeholder="Enter Name"
                name="name"
                required
                autoFocus
              />
            </div>
            <div className="w-1/2">
              <FormLabel>Description</FormLabel>
              <TextField
                multiline
                className="w-full"
                onChange={handleFormInputChange}
                minRows={4}
                name="description"
                value={formData.description}
                placeholder="Enter Description"
              />
            </div>
          </div>

          <div className="w-full mt-8 flex space-x-8">
            <div className="w-1/2">
              <FormLabel>Add Teammates</FormLabel>

              <div>
                <Select
                  isSearchable={true}
                  ref={ref => {
                    selectRef = ref
                  }}
                  value={formData.assignedAgencyTeam}
                  options={teammateOptions}
                  isMulti
                  onChange={handleAddTeammate}
                  styles={reactSelectCustomStyles}
                  maxMenuHeight="200px"
                  getOptionLabel={teammateOptionsLabel}
                  isLoading={teammatesLoading}
                  components={{ MenuList: CustomMenu }}
                  filterOption={filterOptions}
                  selectProps={{
                    inviteTeammate: inviteTeammate,
                    elmFor: 'agency'
                  }}
                  // noOptionsMessage={({ inputValue }) => {
                  //   return (
                  //     <span
                  //       className="text-md text-blue-700 text-left cursor-pointer"
                  //       onClick={() => inviteTeammate(inputValue)}
                  //     >
                  //       <AddIcon fontSize="small" className="mr-2" />
                  //       Add new teammate named '{inputValue}'
                  //     </span>
                  //   )
                  // }}
                />
                <p className="text-xs text-gray-500 py-1">
                  Admin users will be added by default
                </p>
              </div>
            </div>
            <div className="w-1/2">
              <FormLabel>Add Client</FormLabel>

              <div>
                <Select
                  isSearchable={true}
                  isDisabled={
                    formData.category === 'Internal' || elmFor !== 'workspace'
                      ? true
                      : false
                  }
                  value={formData.assignedClientTeam}
                  options={clientOptions}
                  onChange={handleAddClient}
                  styles={reactSelectCustomStyles}
                  maxMenuHeight="200px"
                  components={{ MenuList: CustomMenu }}
                  selectProps={{
                    inviteTeammate: inviteClientUser,
                    elmFor: 'client'
                  }}
                  // noOptionsMessage={({ inputValue }) => {
                  //   return (
                  //     <span
                  //       className="text-md text-blue-700 text-left cursor-pointer"
                  //       onClick={() => inviteClientUser(inputValue)}
                  //     >
                  //       <AddIcon fontSize="small" className="mr-2" />
                  //       Add new client named '{inputValue}'
                  //     </span>
                  //   )
                  // }}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between space-x-8">
            <div className="w-1/2 mt-8">
              <FormLabel>Choose a project template</FormLabel>

              <div>
                <Select
                  isSearchable={true}
                  value={selectedTemplate}
                  options={templateOptions}
                  onChange={handleAddTemplate}
                  styles={reactSelectCustomStyles}
                  maxMenuHeight="150px"
                  getOptionLabel={optionLabel}
                />
              </div>
              <span className="text-xs text-gray-500">
                Explore or create a new template{' '}
                <button
                  onClick={() => setStep(2)}
                  className="text-primary-main"
                >
                  here
                </button>
              </span>
            </div>
            <div className="mt-2 w-1/2 flex justify-between space-x-8 ">
              <div className=" flex-1">
                <FormLabel className=" whitespace-nowrap">Start Date</FormLabel>

                <div>
                  <DatePicker
                    popperPlacement="bottom-left"
                    wrapperClassName={classes.datePicker}
                    onChange={date => handleDateChange(date, 'startDate')}
                    selected={
                      formData.startDate ? new Date(formData.startDate) : null
                    }
                    customInput={
                      <TextField
                        className="text-xs w-full"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <CalenderIcon className="text-base text-gray-600 cursor-pointer" />
                            </InputAdornment>
                          )
                        }}
                      />
                    }
                    placeholderText="Select Date"
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
              </div>

              <div className="w-1/2 flex-1 ml-auto">
                <FormLabel className=" whitespace-nowrap">Due Date</FormLabel>

                <div className="w-full">
                  <DatePicker
                    popperPlacement="bottom-left"
                    wrapperClassName={classes.datePicker}
                    onChange={date => handleDateChange(date, 'dueDate')}
                    selected={
                      formData.dueDate ? new Date(formData.dueDate) : null
                    }
                    customInput={
                      <TextField
                        className="text-xs w-full"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <CalenderIcon className="text-base text-gray-600 cursor-pointer" />
                            </InputAdornment>
                          )
                        }}
                      />
                    }
                    placeholderText="Select Date"
                    dateFormat="MMMM d, yyyy"
                    minDate={
                      formData.startDate ? new Date(formData.startDate) : null
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-8 flex items-center justify-between  space-x-8"></div>
          {/* </div> */}
        </div>
        <CustomModalFooter>
          <LoadingButton
            onClick={handleOncreateNewWorkspace}
            loading={invitationLoading}
            // type="submit"
            style={{ color: '#fff' }}
            disabled={!formData.name || !permission?.workspace?.create}
          >
            Create
          </LoadingButton>
          <Button variant="outlined" onClick={handleDrawerClose}>
            Cancel
          </Button>
        </CustomModalFooter>
      </div>
      <TemplatesModal
        open={tempModalOpen}
        onClose={() => setTempModalOpen(false)}
      />
    </>
  )
}

const CustomMenu = props => {
  return (
    <components.MenuList {...props}>
      <button
        onClick={props.selectProps.selectProps.inviteTeammate}
        className="text-sm text-primary-main flex items-center w-full py-2 px-4"
      >
        <AddIcon className="mr-1" />
        <span>
          Add new{' '}
          {props.selectProps.selectProps.elmFor === 'agency'
            ? 'teammate'
            : 'client'}
        </span>
      </button>
      {props.children}
    </components.MenuList>
  )
}

export default AddProjectModal
