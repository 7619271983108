import axios from 'axios'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { GENERAL_APP_STATE } from 'thunks/generlAppState/reducers'
import { WORKSPACES } from './reducers'
import { fetchPermissions } from 'thunks/permission/actions'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { errorMessages } from 'utils'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { postTaskLog } from 'thunks/logs/action'
// import { userRoles } from 'utils'
import { EMBEDS } from 'thunks/embed/reducer'

/**
 * Create a Workspace
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const createWorkspace = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/workspace',
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `created the project <strong>${res.data.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'post'
        })
      )
      dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: res.data })
      dispatch(
        postTaskLog({
          description: `created this project`,
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Create a Workspace using template
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const createWorkspaceByTemplate = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/workspacetemplate/usetemplate',
        data: data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `created the project <strong>${res.data.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'post'
        })
      )
      dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: res.data })
      dispatch(
        postTaskLog({
          description: `created this project`,
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Create a Workspace
 * @param {Object} data Workspace data
 * @param {Function} callback callback function
 */
export const createWorkspaceCopy = (workspaceId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/workspace/copy/${workspaceId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `created a ${res.data?.category} project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'post'
        })
      )
      dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: res.data })
      dispatch(
        postTaskLog({
          description: `created this project`,
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Create Project from Import
 * @param {Function} callback function.
 */
export const createProjectFromImport = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/workspace/import',
        data
      })
      if (callback) callback(res.data)
      dispatch(
        createAccountLogs({
          description: `created the project <strong>${res.data.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'post'
        })
      )
      dispatch({ type: WORKSPACES.ADD_ONE_WORKSPACE, payload: res.data })
      dispatch(
        postTaskLog({
          description: `created this project`,
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Find Workspace by Id
 * @param {String} wid Workspace ID
 * @param {Function} callback callback function
 */
export const findWorkspaceById = (wid, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/workspace/${wid}`
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Not working ------------
 * update data of a workspace
 * @param {String} wid workspace id
 * @param {Object} data updating data
 * @param {Function} callback callback function
 */
export const updateWorkSpace = async (wid, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/${wid}`,
        data
      })
      if (callback) callback(res.data, false)
      createAccountLogs({
        description: `updated a <strong>${res?.data?.name}</strong> project`,
        category: 'workspace',
        method: 'put'
      })
      dispatch({ type: WORKSPACES.ADD_OPENED_WORKSPACE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Change/updae the wokrpsace external/internal category
 * @param {String} wid workspace id
 * @param {String} category Internal/External
 * @param {Function} callback callback funciton
 */
export const updateWorkspaceCategory = (wid, category, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/category/${wid}`,
        data: {
          category
        }
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: res.data })
      dispatch(
        createAccountLogs({
          description: `changed  the category of project <strong>${
            res?.data?.name
          }</strong> from ${
            category === 'Internal'
              ? 'External to Internal'
              : 'Internal to External'
          }`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'put'
        })
      )
      dispatch(
        postTaskLog({
          description: `changed the category of this project from ${
            category === 'Internal'
              ? 'External to Internal'
              : 'Internal to External'
          }`,
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update a workspace data
 * @param {String} id workspace id
 * @param {{}} data Workspace object
 * @param {Function} callback callback function
 */
export const updateWorkSpace1 = (id, data, updateData, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/${id}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: res.data })
      if (updateData) {
        dispatch(
          createAccountLogs({
            description: `updated the ${updateData} of the project <strong>${res?.data?.name}</strong>`,
            category: 'workspace',
            workspace: res.data._id,
            method: 'put'
          })
        )
        dispatch(
          postTaskLog({
            description: `updated the ${updateData} of this project`,
            activityType: 'log',
            workSpace: res.data._id,
            category: 'workspace'
          })
        )
      }
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Update role of a teammate
 * Either assignToClientTeammate or assignToAgencyTeammate at once.
 * ```data = {
 *  assignToClientTeammate:{
 *    user: teammate  user id,
 *    role: roleId
 *   }
 * }```
 * @param {String} workspaceId workspace id
 * @param {Object} data Role object
 * @param {Function} callback callback function
 */
export const updateTeammateRole = (workspaceId, data, name, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/updaterole/${workspaceId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: res.data })
      dispatch(fetchPermissions())
      dispatch(fetchWorkspacePermissions())
      dispatch(
        createAccountLogs({
          description: `changed the role of <strong>${
            name ? name : 'a member'
          }</strong> in the project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id
        })
      )
      dispatch(
        postTaskLog({
          description: `changed the role of`,
          activityType: 'assignment',
          workSpace: res.data._id,
          category: 'workspace',
          assignedUser:
            data.assignToAgencyTeammate?.user ??
            data.assignToClientTeammate?.user
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Remove a teammate from workspace
 * @param {String} workspaceId workspace id
 * @param {Object} data removing teammated from workspace
 * @param {Function} callback callbakc funtion
 */
export const removeTeammateFromWorkspace = (
  workspaceId,
  data,
  name,
  callback
) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/deleteteammate/${workspaceId}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch(
        postTaskLog({
          description: `removed`,
          activityType: 'assignment',
          workSpace: res.data._id,
          category: 'workspace',
          assignedUser:
            data.assignToAgencyTeammate?.user ??
            data.assignToClientTeammate?.user
        })
      )
      dispatch(
        createAccountLogs({
          description: `removed <strong>${
            name ? name : 'a member'
          }</strong> from the project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'put'
        })
      )
      dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * delete a Workspace
 * @param {String} workspaceId workspace id
 * @param {Function} callback callback function
 */

export const deleteWorkspace = (workspaceId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/workspace/${workspaceId}`
      })
      if (callback) callback(res.data, false)
      dispatch(
        createAccountLogs({
          description: `deleted a project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'delete'
        })
      )
      dispatch({ type: WORKSPACES.DELETE_ONE_WORKSPACE, payload: res.data })
      dispatch(fireSuccessToaster('Project Deleted Successfully'))
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetches all workspace of a user
 * @param {Function} callback callback function
 */
export const fetchAllWorkspacesForCurrentUser = (callback) => {
  return async (dispatch) => {
    dispatch({ type: WORKSPACES.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/workspace/user'
      })
      const sortedData = res.data.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      if (callback) callback(sortedData, false)
      dispatch({ type: WORKSPACES.FETCHED, payload: sortedData })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetches all workspaces of a user
 * @param {Function} callback callback function
 */
export const fetchAllWorkspaces_ = (callback) => {
  return async (dispatch) => {
    dispatch({ type: WORKSPACES.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/workspace'
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.FETCHED, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

export const addOpenedWorkspace = (workspace) => {
  return { type: WORKSPACES.ADD_OPENED_WORKSPACE, payload: workspace }
}

export const addCurrentBoard = (board) => {
  return { type: WORKSPACES.ADD_CURRENT_BOARD, payload: board }
}

/**
 * creates new workspace
 * @param {Object} data New section data
 * @param {String} data.label Workspace section label
 * @param {Number} data.order Workspace section label
 * @param {String} data.workSpace Workspace section label
 *
 * @param {Function} callback callback function
 */
export const createWorkspaceSection = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `/workspacesection`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.CREATE_NEW_SECTION, payload: res.data })
      dispatch(
        createAccountLogs({
          description: `created ${res.data.label} category in the project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.workSpace,
          method: 'post'
        })
      )
      dispatch(
        postTaskLog({
          description: `created ${res.data.label} category`,
          activityType: 'log',
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Update workspace section data
 * @param {String} id Section id
 * @param {Object} data Updating data object
 * @param {Function} callback callback function
 */
export const updateWorkspaceSection = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `workspacesection/${id}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE_SECTION,
        payload: res.data
      })
      dispatch(
        createAccountLogs({
          description: `updated ${res.data.label} category of the project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'put'
        })
      )
      dispatch(
        postTaskLog({
          description: `updated ${res.data.label} category`,
          activityType: 'log',
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.UPDATE_ONE_WORKSPACE_SECTION,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * update section orders for a Workspace
 * @param {Object} data  workspaceSections,arranged workspaceSections
 * @param {Function} callback callback function
 */

export const updateSectionOrders = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspacesection/updateorder`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.UPDATE_SECTION_ORDERS, payload: data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.UPDATE_SECTION_ORDERS,
        payload: [{ name: 'error' }]
      })
    }
  }
}

/**
 * update section orders for a Workspace
 * @param {Object} data  workspaceSections,arranged workspaceSections
 * @param {Function} callback callback function
 */

export const updateSectionOrdersOld = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspacesection/updateorder`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.UPDATE_SECTION_ORDERS, payload: data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.UPDATE_SECTION_ORDERS,
        payload: [{ name: 'error' }]
      })
    }
  }
}

/**
 * Unassign a workspace to a teammate
 * @param {String} userId user id of the teammate
 * @param {String} workspaceId workspace id
 * @param {Number} role teammate role
 * @param {Function} callback Callback function
 */
export const unassignWorkspace = (userId, workspaceId, role = 2, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/unassignworkspace/${workspaceId}`,
        params: {
          userId,
          role
        }
      })
      dispatch({ type: WORKSPACES.UNASSIGN_TEAMMATE, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Assign a workspace to a teammate
 * @param {Object} data user id of the teammate
 * @param {String} data.workspace workspace id
 * @param {Array<String>} data.assignToAgencyTeammate agency teammates id
 * @param {Array<String>} data.assignToClientTeammate clients id
 * @param {Function} callback Callback function
 */
export const assignWorkspace = (data, teammate, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/assignedworkspace`,
        data
      })
      dispatch(
        createAccountLogs({
          description: `assigned <strong>${
            teammate.length > 1 ? teammate.join(', ') : teammate[0]
          }</strong> in the project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'put'
        })
      )
      dispatch(
        postTaskLog({
          description: `assigned`,
          activityType: 'assignment',
          workSpace: res.data._id,
          category: 'workspace',
          assignedUser:
            data.assignToAgencyTeammate.length > 0
              ? data.assignToAgencyTeammate
              : data.assignToClientTeammate
        })
      )
      dispatch({ type: WORKSPACES.ASSIGN_TEAMMATE, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * Archive a workspace
 * @param {String} workspaceId Worskpace id
 * @param {Function} callback callback function
 */
export const archiveWorkspace = (
  workspaceId,
  archiveStatus = true,
  callback
) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `workspace/archived/${workspaceId}`,
        data: {
          isArchived: archiveStatus
        }
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: WORKSPACES.TOGGLE_ARCHIVE,
        payload: res.data
      })
      dispatch(
        createAccountLogs({
          description: `marked the project <strong>${res?.data?.name}</strong> to Archive`,
          category: 'workspace',
          workspace: res.data._id,
          method: 'put'
        })
      )
      dispatch(
        postTaskLog({
          description: `marked this project Archive`,
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      dispatch({
        type: WORKSPACES.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * delete a section order
 * @param {Object} data task data
 * @param {Function} callback callback function
 */

export const deleteSectionOrders = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/workspacesection/${data._id}`
      })
      if (callback) callback(res.data, false)
      dispatch({
        type: WORKSPACES.DELETE_ONE_WORKSPACE_SECTION,
        payload: res.data
      })
      dispatch(
        createAccountLogs({
          description: `deleted ${data?.label} category from the project <strong>${res?.data?.name}</strong>`,
          category: 'workspace',
          workspace: res.workSpace
        })
      )
      dispatch(
        postTaskLog({
          description: `deleted ${data?.label} category`,
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      // dispatch({
      //   type: WORKSPACES.DELETE_ONE_WORKSPACE_SECTION,
      //   payload: [{ name: 'error' }]
      // })
    }
  }
}

/**
 * update a workspace status to complete
 * @param {String} data workspace id
 * @param {Object} data is Complete
 * @param {Function} callback callback function
 */

export const UpdateWorkspceCompleteStatus = (wid, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/workspace/complete/${wid}`,
        data
      })
      if (callback) callback(res.data, false)
      dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: res.data })
      dispatch(
        createAccountLogs({
          description: `marked the project <strong>${res?.data?.name}</strong> to Complete`,
          category: 'workspace',
          workspace: res.data._id
        })
      )
      dispatch(
        postTaskLog({
          description: 'marked this project Complete',
          activityType: 'log',
          workSpace: res.data._id,
          category: 'workspace'
        })
      )
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

export const updateOneWokspaceData = (data) => {
  return (dispatch) => {
    // Receiving Board
    dispatch({ type: WORKSPACES.UPDATE_ONE_WORKSPACE, payload: data })
  }
}

export const updateBoardData = (data) => {
  return (dispatch) => {
    if (data.type === 'board') {
      // Here the data is board object
      dispatch({ type: EMBEDS.UPDATE, payload: data })
    } else if (data.type === 'section') {
      // Here the data is section object
      dispatch({ type: EMBEDS.SECTION_UPDATE, payload: data })
    }
  }
}

export const handleAssignTeammateEvent = (data) => {
  return (dispatch) => {
    dispatch({ type: WORKSPACES.PUSHER_ADD_ONE_WORKSPACE, payload: data })
  }
}

export const handleUnAssignPersonEvent = (data, callback) => {
  return (dispatch, getState) => {
    const {
      me: {
        data: { _id: currentUserId }
      }
    } = getState()
    const allAssignedPeopleIds = []
    data?.assignedAgencyTeam?.forEach((i) =>
      allAssignedPeopleIds?.push(i.user?._id)
    )
    data?.assignedClientTeam?.forEach((i) =>
      allAssignedPeopleIds?.push(i.user?._id)
    )
    if (allAssignedPeopleIds.length > 0) {
      dispatch({ type: WORKSPACES.UNASSIGN_PERSON, payload: data })
      if (!allAssignedPeopleIds.includes(currentUserId)) {
        if (callback) callback()
        dispatch({
          type: GENERAL_APP_STATE.OPEN_REMOVED_FROM_WS_MODAL,
          payload: true
        })

        dispatch({
          type: WORKSPACES.PUSHER_DELETE_ONE_WORKSPACE,
          payload: data
        })
      }
    }
  }
}

/**
 * fetch all project stats (overview data)
 * @param {String} id workspace id
 * @param {Function} callback callback function
 */
export const getProjectStats = async (workspaceId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/overview/${workspaceId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * fetch single project's stats (overview data)
 * @param {String} id workspace id
 * @param {String} id board id
 * @param {Function} callback callback function
 */
export const getSingleProjectStats = async (workspaceId, boardId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/overview/${workspaceId}?workspaceBoard=${boardId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Create post regarding workspace status
 * @param {Object} data workspace id, status (1/2/3), title, file, sec1, sec2
 * @param {Function} callback
 */
export const createProjectStatusPost = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/workspacestatus',
      data: data
    })

    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Fetch posts regarding workspace status
 * @param {Object} data workspace id, status (1/2/3), title, file, sec1, sec2
 * @param {Function} callback
 */
export const fetchProjectStatusPosts = async (wsId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspacestatus/workspace/${wsId}`
    })
    if (callback) callback(res.data)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}

/**
 * Update project status post
 * @param {String} id workspace id
 * @param {Object} data update data
 * @param {Function} callback
 */
export const updateProjectStatusPost = async (wsId, data, callback) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `/workspacestatus/${wsId}`,
      data
    })

    callback(res.data, false)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Delete project status post
 * @param {String} id post id
 * @param {Function} callback
 */
export const deleteProjectStatusPost = async (postId, callback) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/workspacestatus/${postId}`
    })
    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * Fetch all project's financial data
 * @param {Function} callback
 */
export const fetchProjectFinancialData = async (wsId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/workspace/forecast/${wsId}`
    })

    callback(res.data)
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}
