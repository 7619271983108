import { makeStyles, createStyles } from '@material-ui/core'

const tooltipStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      color: theme.palette.common.white
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      padding: '8px 12px',
      fontSize: '13px',
      fontWeight: '600',
      color: '#222',
      boxShadow: '0 4px 14px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05)',
      borderRadius: '6px',
      lineHeight: '20px'
    }
  })
)

export default tooltipStyles
