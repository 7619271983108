import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import Currency from 'static/Currency/Currency'
import { makeStyles } from '@material-ui/core'

const InvoicePricingTable = ({
  invoiceItems,
  addInvoiceItem,
  addAnotherInvoiceItem,
  removeInvoiceItem,
  updateInvoiceItemField,
  currency,
  services,
  selectServiceFromPopup,
  type
}) => {
  const classes = useStyles()

  return (
    <div>
      <div className={clsx(classes.invoicePricingTable, 'mb-2')}>
        <div className="uppercase text-xs">Item name</div>
        <div className="uppercase text-right text-xs">Units</div>
        <div className="uppercase text-right text-xs">Rate</div>
        <div className="uppercase text-right text-xs">Total</div>
      </div>
      {invoiceItems.map((item, index) => (
        <InvoiceItem
          key={index}
          invoice={item}
          type={type}
          index={index}
          addInvoiceItem={addInvoiceItem}
          removeInvoiceItem={removeInvoiceItem}
          addAnotherInvoiceItem={addAnotherInvoiceItem}
          updateInvoiceItemField={updateInvoiceItemField}
          currency={currency}
          services={services}
          classes={classes}
          selectServiceFromPopup={selectServiceFromPopup}
        />
      ))}
    </div>
  )
}

const InvoiceItem = ({
  invoice,
  index,
  classes,
  addInvoiceItem,
  addAnotherInvoiceItem,
  removeInvoiceItem,
  updateInvoiceItemField,
  currency,
  services,
  selectServiceFromPopup,
  type
}) => {
  const [anchorEl, setAnchorEl] = useState('')
  const [matchedServices, setMatchedServices] = useState([])
  const ref = useRef()

  useEffect(() => {
    if (matchedServices.length) {
      setAnchorEl(ref.current)
    } else {
      setAnchorEl(null)
    }
  }, [matchedServices])

  const handleItemNameChange = (e) => {
    const value = e.target.value.trim().toLowerCase()
    const match = services.filter((item) =>
      item.name.toLowerCase().includes(value)
    )

    updateInvoiceItemField(e)
    setMatchedServices(match)
  }

  const handleCloseMenu = () => {
    setTimeout(() => {
      setMatchedServices([])
    }, 500)
  }

  const handleSelectService = (service) => {
    selectServiceFromPopup(service, index)
  }

  return (
    <div
      className={clsx(
        classes.invoicePricingTable,
        'border-b py-4 mb-2 relative'
      )}
    >
      <div className="relative">
        <input
          className="text-sm py-1 hover:bg-gray-100 rounded border border-transparent focus:bg-gray-100  focus:border-primary-main px-1"
          type="text"
          placeholder="Example Item"
          value={invoice.name}
          name="name"
          data-index={index}
          onChange={handleItemNameChange}
          ref={ref}
          autoComplete="none"
          onBlur={handleCloseMenu}
        />
        {Boolean(matchedServices.length) && type === 'service' && (
          <ServicesPopup
            services={matchedServices}
            handleSelectService={handleSelectService}
          />
        )}
      </div>

      <input
        className="text-sm text-right py-1 rounded border border-transparent hover:bg-gray-100 focus:bg-gray-100 focus:border-primary-main px-1"
        type="number"
        placeholder="0"
        value={invoice.quantity}
        name="quantity" //unit
        data-index={index}
        onChange={updateInvoiceItemField}
      />

      <input
        className="text-sm text-right rounded border border-transparent py-1 hover:bg-gray-100 focus:bg-gray-100 focus:border-primary-main px-1"
        type="number"
        placeholder="0.0"
        value={invoice.rate}
        name="rate"
        data-index={index}
        onChange={updateInvoiceItemField}
      />
      <div className="text-right">
        <span>{Currency[currency.toUpperCase()].symbol_native}</span>
        <span>{invoice.total || 0}</span>
      </div>

      {index === 0 ? (
        addAnotherInvoiceItem ? null : (
          <button
            onClick={addInvoiceItem}
            className="p-1 text-primary-main text-lg"
          >
            &#43;
          </button>
        )
      ) : (
        <button
          className="p-1 text-primary-main text-lg"
          onClick={removeInvoiceItem}
          data-index={index}
        >
          &#8722;
        </button>
      )}
    </div>
  )
}

const ServicesPopup = ({ services, handleSelectService }) => {
  return (
    <div
      className="absolute bg-white rounded shadow-lg border py-2 z-10 w-full"
      style={{ top: 30 }}
    >
      <ul>
        {services.map((item) => (
          <PopupOption
            key={item._id}
            service={item}
            handleSelectService={handleSelectService}
          />
        ))}
      </ul>
    </div>
  )
}

const PopupOption = ({ service, handleSelectService }) => {
  const handleClick = () => {
    handleSelectService(service)
  }

  return (
    <li
      className="p-2 text-sm text-gray-500 hover:bg-gray-100 cursor-pointer"
      onClick={handleClick}
    >
      {service.name}
    </li>
  )
}

const useStyles = makeStyles({
  invoicePricingTable: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px 100px 100px 10px',
    // gridGap: '1rem',
    alignItems: 'center'
  },
  paidBadge: {
    transform: 'rotate(40deg)',
    transformOrigin: 'bottom left'
  }
})

export default React.memo(InvoicePricingTable)
