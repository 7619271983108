import React, { useMemo } from 'react'
// import FieldLabel from './FieldLabel'
import { useSelector } from 'react-redux'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
import ReactQuill from 'react-quill'
import Quill from 'quill'
import 'react-quill/dist/quill.snow.css'
import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import MagicUrl from 'quill-magic-url'
import stringToHslColor from 'utils/stringToHslColor'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

const AdvanceEditor = React.forwardRef(
  ({ users, imageDrop, imageHandler, handleKeyPress, ...rest }, ref) => {
    const meData = useSelector((state) => state.me.data)

    const modules = useMemo(() => {
      const obj = {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@'],
          source: function (searchTerm, renderItem, mentionChar) {
            let values
            if (mentionChar === '@') {
              values = users || []
            }
            if (searchTerm.length === 0) {
              renderItem(values, searchTerm)
            } else {
              const matches = []
              for (let i = 0; i < values.length; i++) {
                if (
                  values[i].value
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  matches.push(values[i])
                }
              }
              renderItem(matches, searchTerm)
            }
          },
          renderItem: (item, searchTerm) => {
            const nameInitials = item.value
              .split(' ')
              .slice(0, 2)
              .map((item) => item[0])
              .join('')
            const color = stringToHslColor(item.value ?? '')

            return `
          <div class="flex items-center space-x-2 py-2">
          ${
            item.profileImage
              ? `
          <img class="ql-mention-list-item__img w-7 h-7 rounded-full object-cover" src="${item.profileImage}">
          `
              : `
          <div class="w-7 h-7 rounded-full uppercase flex justify-center items-center text-white text-xs" style="background: ${color};">
            ${nameInitials}
          </div>
          `
          }
            <div>
              <div class="text-sm font-medium leading-none">${item.value}</div>
            </div>
        </div>`
          },
          onSelect: (item, insertItem) => {
            const user = users.find((user) => user._id === item.id)
            insertItem({ ...item, ...user, notify: meData._id !== user._id })
            setTimeout(() => {
              if (handleKeyPress) handleKeyPress() // triggering keyPress event on select
            }, [100])
          }
        },
        magicUrl: true
      }

      if (imageDrop) {
        obj.imageDropAndPaste = {
          // add an custom image handler
          handler: imageHandler
        }
      }
      return obj
    }, [users, imageDrop])
    // const modules = useMemo(() => {
    //   const obj = {
    //     toolbar,
    //     mention: {
    //       allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    //       mentionDenotationChars: ['@'],
    //       source: function (searchTerm, renderItem, mentionChar) {
    //         let values
    //         if (mentionChar === '@') {
    //           values = users
    //         }
    //         if (searchTerm.length === 0) {
    //           renderItem(values, searchTerm)
    //         } else {
    //           const matches = []
    //           for (let i = 0; i < values.length; i++) {
    //             if (
    //               values[i].value
    //                 .toLowerCase()
    //                 .includes(searchTerm.toLowerCase())
    //             ) {
    //               matches.push(values[i])
    //             }
    //           }
    //           renderItem(matches, searchTerm)
    //         }
    //       },
    //       renderItem: (item, searchTerm) => {
    //         const nameInitials = item.value
    //           .split(' ')
    //           .slice(0, 2)
    //           .map((item) => item[0])
    //           .join('')
    //         const color = stringToHslColor(item.value ?? '')

    //         return `
    //       <div class="flex items-center space-x-2 py-2">
    //       ${
    //         item.profileImage
    //           ? `
    //       <img class="ql-mention-list-item__img w-7 h-7 rounded-full object-cover" src="${item.profileImage}">
    //       `
    //           : `
    //       <div class="w-7 h-7 rounded-full uppercase flex justify-center items-center text-white text-xs" style="background: ${color};">
    //         ${nameInitials}
    //       </div>
    //       `
    //       }
    //         <div>
    //           <div class="text-sm font-medium leading-none">${item.value}</div>
    //         </div>
    //     </div>`
    //       },
    //       onSelect: (item, insertItem) => {
    //         const user = users.find((user) => user._id === item.id)
    //         insertItem({ ...item, ...user, notify: meData._id !== user._id })
    //       }
    //     },
    //     magicUrl: true
    //   }

    //   if (imageDrop) {
    //     obj.imageDropAndPaste = {
    //       // add an custom image handler
    //       handler: imageHandler
    //     }
    //   }
    //   return obj
    // }, [users, imageDrop])

    const handleClick = (e) => {
      if (e.target.tagName === 'A' && e.target.href !== '') {
        const a = document.createElement('a')
        a.setAttribute('href', e.target.href)
        a.setAttribute('target', '_blank')
        a.click()
      }
    }

    return (
      <div className="text-sm w-full h-full" onClick={handleClick}>
        <ReactQuill theme="snow" modules={modules} {...rest} ref={ref} />
      </div>
    )
  }
)

export default React.memo(AdvanceEditor)
