import { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { ToggleSwitch, LoadingButton } from 'global/globalComponents'
import { useDispatch } from 'react-redux'
import ShareBoardCopyLink from './ShareBoardCopyLink'
import { updateWorkSpace1 } from 'thunks/workspace/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { ReactComponent as Icon } from 'static/svg/i.svg'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import ShareBoardAdvanceOptions from './ShareBoardAdvanceOptions'
import {
  createProjectTabView,
  fetchProjectTabView,
  updateProjectTabView
} from 'thunks/views/actions'
import { fetchCustomURLData } from 'thunks/customUrl/action'

const ShareBoardModal = props => {
  const {
    open,
    onClose,
    boardSections,
    sharing,
    workspace,
    workspacePermission
  } = props
  const boardSectionsIds = boardSections.map(item => item._id)
  const [selectedSections, setSelectedSections] = useState(boardSectionsIds)
  // const [shareProject, setShareProject] = useState(false)

  const dispatch = useDispatch()
  const [passcode, setPasscode] = useState('')
  const [customizeLink, setCustomizeLink] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingToggleBtn, setLoadingToggleBtn] = useState(false)
  const [visibleTabs, setVisibleTabs] = useState([
    { label: 'Overview', accessor: 'overview', disable: false },
    { label: 'Board', accessor: 'board', disable: false },
    { label: 'List', accessor: 'list', disable: false },
    { label: 'Calendar', accessor: 'calendar', disable: false },
    { label: 'Reporting', accessor: 'reporting', disable: false },
    // { label: 'Files', accessor: 'files', disable: false },
    {
      label: 'Invoices',
      accessor: 'invoice',
      disable: false
    },
    { label: 'Embed Views', accessor: 'embeds', disable: false }
  ])
  const [hiddenTab, setHiddenTab] = useState([])
  const [projectTabViews, setProjectTabViews] = useState({})
  const [url, setURL] = useState(
    `${window.location.origin}/public/share/board/${workspace._id}`
  )

  useEffect(() => {
    fetchCustomURLData(`/public/share/board/${workspace._id}`, (res, err) => {
      if (!err) {
        setCustomizeLink(res)
      }
    })
  }, [workspace._id])

  useEffect(() => {
    if (customizeLink._id) {
      setURL(`${window.location.origin}/public/${customizeLink.customUrl}`)
    } else {
      setURL(`${window.location.origin}/public/share/board/${workspace._id}`)
    }
  }, [customizeLink])
  useEffect(() => {
    dispatch(
      fetchProjectTabView(
        workspace._id,
        'shareBoardTab',
        'share',
        (res, err) => {
          if (!err) {
            setProjectTabViews(res)
          }
        }
      )
    )
  }, [])

  useEffect(() => {
    if (workspace?.category === 'Internal') {
      setVisibleTabs(prev => prev.filter(el => el.accessor !== 'invoice'))
    }
  }, [workspace?.category])

  useEffect(() => {
    if (projectTabViews._id) {
      setHiddenTab(projectTabViews.content[0].hiddenColumns)
    } else {
      setHiddenTab([])
    }
  }, [projectTabViews])

  useEffect(() => {
    // setShareProject(sharing.public)
    setPasscode(sharing.passcode)
  }, [sharing])

  const switchBoardViewState = e => {
    if (!workspacePermission.update) {
      dispatch(
        fireErrorToaster('You do not have permission to perform this action!')
      )
      return
    }

    setLoadingToggleBtn(true)
    const checked = e.target.checked

    dispatch(
      updateWorkSpace1(
        workspace._id,
        {
          sharing: {
            public: checked,
            passcodeProtected: Boolean(passcode) && checked,
            passcode: Boolean(passcode) && checked ? passcode : null,
            publicData: {
              subtask: true,
              workspaceSection: selectedSections,
              publicFields: [
                'title',
                'description',
                'attachments',
                'dueDate',
                'startDate',
                'priority',
                'status',
                'assignedAgencyTeam',
                'assignedClientTeam'
              ]
            }
          }
        },
        'sharing data',
        (res, err) => {
          setLoadingToggleBtn(false)
          if (err) {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleSaveChanges = () => {
    if (sharing.passcode === passcode) {
      return
    }

    dispatch(
      updateWorkSpace1(
        workspace._id,
        {
          sharing: {
            public: sharing.public,
            passcodeProtected: Boolean(passcode) && sharing.public,
            passcode: Boolean(passcode) && sharing.public ? passcode : null,
            publicData: {
              subtask: true,
              workspaceSection: selectedSections,
              publicFields: [
                'title',
                'description',
                'attachments',
                'dueDate',
                'startDate',
                'priority',
                'status',
                'assignedAgencyTeam',
                'assignedClientTeam'
              ]
            }
          }
        },
        'sharing data',
        (res, err) => {
          if (err) {
            dispatch(fireErrorToaster(res))
          } else {
            dispatch(fireSuccessToaster('Passcode Saved Successfully!'))
          }
        }
      )
    )
  }

  const handleTabSelection = (accessor, checked) => {
    if (checked) {
      setHiddenTab(prev => prev.filter(el => el !== accessor))
    } else {
      setHiddenTab(prev => [...prev, accessor])
    }
  }

  const handleSaveView = () => {
    setLoading(true)
    if (projectTabViews._id) {
      dispatch(
        updateProjectTabView(
          projectTabViews._id,
          {
            content: [{ hiddenColumns: hiddenTab }]
          },
          'share',
          saveViewCallback
        )
      )
    } else {
      dispatch(
        createProjectTabView(
          {
            type: 'shareBoardTab',
            workspace: workspace._id,
            content: [{ hiddenColumns: hiddenTab }]
          },
          'share',
          saveViewCallback
        )
      )
    }
  }

  const saveViewCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setProjectTabViews(res)
      dispatch(fireSuccessToaster('Saved Successfully!'))
    }
  }

  // const saveChangeCallback = (res, err) => {
  //   setLoadingToggleBtn(false)

  //   if (err) {
  //     dispatch(fireErrorToaster(res))
  //   } else {
  //     dispatch(fireSuccessToaster('Changes saved!'))
  //     // onClose()
  //   }
  // }

  return (
    <CustomModal onClose={onClose} open={open} size="small">
      <CustomModalHeader heading="Share Board" />
      <CustomModalBody>
        <div>
          <p className="text-smaller lg:text-sm">
            You can share this board publicly, and also add a 4-digit passcode
            to restrict access
          </p>
          <div className="mt-4 flex justify-between items-center border rounded-md py-2 px-4">
            <p className="flex-1 text-sm lg:text-base">Public sharing</p>
            <ToggleSwitch
              checked={sharing.public}
              onChange={switchBoardViewState}
              loading={loadingToggleBtn}
            />
          </div>
          {sharing.public && (
            <>
              <ShareBoardCopyLink workspaceId={workspace._id} url={url} />
              <ShareBoardAdvanceOptions
                workspace={workspace}
                passcode={passcode}
                setPasscode={setPasscode}
                handleSaveChanges={handleSaveChanges}
                handleTabSelection={handleTabSelection}
                hiddenTab={hiddenTab}
                visibleTabs={visibleTabs}
                customizeLink={customizeLink}
                setCustomizeLink={setCustomizeLink}
              />
            </>
          )}
        </div>

        <div className="flex mt-4 text-primary-main mb-4">
          <Icon
            height="12px"
            width="12px"
            style={{ alignSelf: 'center', fill: 'currentColor' }}
          />
          <p className="self-center ml-2 text-xs font-normal text-primary-main">
            This board will be shared as view-only
          </p>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton onClick={handleSaveView} loading={loading}>
          Save
        </LoadingButton>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default ShareBoardModal
