import React, { useRef } from 'react'
import { ReactComponent as AngleRight } from 'static/svg/angle-right.svg'
import { ReactComponent as AngleLeft } from 'static/svg/angle-left.svg'
import DatePicker from 'antd/lib/date-picker'
import ConfigProvider from 'antd/lib/config-provider'
import locale from 'antd/es/locale/en_GB'
import moment from 'moment'
import { CustomTooltip, OutlinedButton } from 'global/globalComponents'
import { CSVLink } from 'react-csv'
import { ReactComponent as ImportIcon } from 'static/svg/import.svg'
import { useEffect } from 'react'

const StandupsFilter = ({
  selectedDate,
  onDateChange,
  handleDownloadData,
  downloadData,
  userName
}) => {
  const ref = useRef()
  const customFormat = (value) =>
    `${moment(value).startOf('week').format('DD MMM YY')} - ${moment(value)
      .endOf('week')
      .format('DD MMM YY')}`

  const handleChangeWeek = (e) => {
    const dir = e.currentTarget.dataset.dir
    const date = moment(selectedDate).add(dir === 'next' ? 7 : -7, 'days')

    onDateChange(date)
  }

  useEffect(() => {
    if (downloadData.length) {
      ref.current.link.click()
    }
  }, [downloadData])

  return (
    <div className="flex justify-between items-center px-4 py-2 bg-white">
      <div className="flex justify-start items-center text-sm text-gray-700">
        <div className="cursor-pointer flex justify-start items-center">
          <button
            className="h-8 flex items-center rounded-sm hover:bg-gray-100"
            onClick={handleChangeWeek}
            data-dir="prev"
          >
            <AngleLeft className="h-6 w-6 rounded-sm hover:bg-gray-100" />
          </button>
          <button
            className="h-8 flex items-center rounded-sm hover:bg-gray-100"
            onClick={handleChangeWeek}
            data-dir="next"
          >
            <AngleRight className=" h-6 w-6 rounded-sm hover:bg-gray-100" />
          </button>
          <div className="px-2 h-8 flex items-center rounded-sm hover:bg-gray-100">
            <ConfigProvider locale={locale}>
              <DatePicker
                defaultValue={
                  moment(new Date()).startOf('week').format('DD MMM YY') -
                  moment(new Date()).endOf('week').format('DD MMM YY')
                }
                value={moment(selectedDate)}
                format={customFormat}
                onChange={onDateChange}
                picker="week"
                bordered={false}
                allowClear={false}
                style={{
                  width: '205px'
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>

      <div>
        <OutlinedButton onClick={handleDownloadData} className="ml-4">
          <span>
            <ImportIcon className="w-4 h-4" />
          </span>
          <span className="ml-2">Download CSV</span>
        </OutlinedButton>
        <CSVLink
          ref={ref}
          data={downloadData}
          filename={`${userName}_standups.csv`}
        ></CSVLink>
      </div>
    </div>
  )
}

export default StandupsFilter
