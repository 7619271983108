import { CustomPopper } from 'global/globalComponents'
import clsx from 'clsx'

const DaysFilterPopup = ({
  anchorEl,
  onClose,
  daysOptions,
  selectedDaysOption,
  handleSelectTimelineOption
}) => {
  const handleListItemClick = (e) => {
    onClose()
    handleSelectTimelineOption(daysOptions[e.currentTarget.dataset.index])
  }
  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <div className="w-40 py-2">
        {daysOptions.map((item, index) => (
          <div
            key={index}
            onClick={handleListItemClick}
            data-index={index}
            className={clsx(
              'py-2 px-4 cursor-pointer',
              selectedDaysOption.value === item.value &&
                'bg-primary-light text-primary-main'
            )}
          >
            <span className="text-sm">{item.label}</span>
          </div>
        ))}
      </div>
    </CustomPopper>
  )
}

export default DaysFilterPopup
