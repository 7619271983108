import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  // useReducer,
  useState
} from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import {
  AlertModal,
  Loader,
  LoadingButton,
  OutlinedButton
} from 'global/globalComponents'
import { Button } from '@material-ui/core'
import Select, { components } from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchTaskById,
  getListViewTasks
  // getTaskByWorkspaceBoardId
} from 'thunks/task/actions'
import {
  addCurrentBoard,
  addOpenedWorkspace,
  // addOpenedWorkspace,
  fetchAllWorkspacesForCurrentUser
} from 'thunks/workspace/actions'
import { ReactComponent as PlaneIcon } from 'static/svg/plane.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as ArrowRight } from 'static/svg/arrow-right.svg'
// import { toggleCreateTaskPanel } from 'thunks/generlAppState/actions'
// import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
// import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
// import { ReactComponent as CheckCircleFilled } from 'static/svg/check-circle-filled.svg'
// import Pill from 'global/globalComponents/Pill/Pill'
// import clsx from 'clsx'
import {
  createStandups,
  deleteStandup,
  fetchTodaysStandups,
  markStandupAsComplete,
  updateStandupById
} from 'thunks/standups/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import MorningStandups from './MorningStandups'
import EveningStandups from './EveningStandups'
import StandupList from './StandupsListItem'
// import Pill from 'global/globalComponents/Pill/Pill'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
// import CreateTaskModal from './CreateTaskModal'
import ct from 'countries-and-timezones'
import { Link } from 'react-router-dom'
import {
  dateAndTimeHelper,
  userRoles,
  triggerOnScrollToBottom,
  debounce
} from 'utils'
// import { toggleCreateTaskPanel } from 'thunks/generlAppState/actions'
import CreateTaskPanel from 'components/CreateTaskPanel/CreateTaskPanel'
import TaskPanel from 'components/Workspace/Panel/TaskPanel'
import { fetchWorkspaceTagsById } from 'thunks/tags/actions'
import CreateStandupsModalTop from './CreateStandupsModalTop'
import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import { fetchSingleEmbedById } from 'thunks/embed/action'

// import {
//   SingleWorkspaceContext,
//   reducer,
//   initialState,
//   WORKSPACE
// } from '../SingleWorkspace/singleWorkspaceContext'

const infiniteScroll = triggerOnScrollToBottom()

const CreateStandupsModal = ({ onClose, open }) => {
  const dispatch = useDispatch()
  const {
    fetchedFirstTime,
    loading: projectsLoading,
    data: allProjects
  } = useSelector(state => state.userWorkspaces)
  const currentBoard = useSelector(state => state.userWorkspaces.currentBoard)

  const [projectsOptions, setProjectsOptions] = useState([])
  const [taskOptions, setTaskOptions] = useState({
    loading: true,
    data: []
  })
  const [selectedProject, setSelectedProject] = useState({})
  const [selectedTasks, setSelectedTasks] = useState([])
  const {
    data: boardNewTasksData,
    listViewTasks
    // data: userTasksData
    // loading: { all: loadingTasks }
  } = useSelector(state => state.userTasks)
  const meData = useSelector(state => state.me.data)
  const {
    // loading: standupsLoading,
    // fetched: standupsFetched,
    data: standupsData
  } = useSelector(state => state.standups)
  const [standupList, setStandupList] = useState({ morning: [], evening: [] }) //syntax: [{projectName: "syz",projectId: "111",name: "Task 1",taskId: "123",isComplete: false,taskDelete: true}]
  const [standupsDataState, setStandupsDataState] = useState([])
  const [standupsOpenFor, setStandupsOpenFor] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState('morning')
  const [standupTimeWindow, setStandupTimeWindow] = useState({
    morningStart: '',
    morningEnd: '',
    eveningStart: '',
    eveningEnd: ''
  })
  const {
    fetched: workspacePermissionFetched,
    data: workspacePermission
  } = useSelector(state => state.workspacePermission)
  const [
    selectedProjectTaskPermission,
    setSelectedProjectTaskPermission
  ] = useState({})
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false)
  // const [workspaceState, workspaceDispatch] = useReducer(reducer, initialState)
  // const [openedTask, setOpenedTask] = useState(null)
  const [taskPanel, setTaskPanel] = useState({
    open: false,
    data: {
      title: '',
      workSpace: '',
      taskSection: {},
      createdAt: `${new Date()}`,
      startDate: '',
      dueDate: '',
      description: '',
      assignedAgencyTeam: [],
      assignedClientTeam: [],
      imageUrl: [],
      comment: '',
      status: null,
      user: {},
      estimateTimeInMillisecond: '',
      estimateTimeInString: ''
    },
    loading: true
  })
  const [allTasksData, setAllTasksData] = useState([])
  const [time, abbreviation] = useMemo(() => {
    let timezone =
      meData.profile?.defaultTimeZone ?? meData.team?.profile?.defaultTimeZone

    const time = new Date().toLocaleTimeString(undefined, {
      timeZone: timezone,
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    })

    const timezoneObj = ct.getCountryForTimezone(timezone)

    return [time, timezoneObj?.id]
  }, [meData])
  const [deleteStandupAlert, setDeleteStandupAlert] = useState({
    open: false,
    data: {},
    loading: false
  })
  // const [standupTaskPermission, setStandupTaskPermission] = useState({
  //   task: {},
  //   subtask: {}
  // })
  const [unsavedChangesAlertOpen, setUnsavedChangesAlertOpen] = useState(false)
  const summaryUrl = useMemo(() => {
    return meData.role === userRoles.AGENCY_ADMIN ||
      meData.role === userRoles.USER_AGENCY
      ? '/agency/reports/standups'
      : `/agency/reports/standups/${meData._id}`
  }, [meData])
  const [showMyTasks, setShowMyTasks] = useState(false)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  // const [search, setSearch] = useState(null)

  // const isDisabled = useMemo(() => {
  //   if (
  //     meData.profile?.standupTimeWindow?.morningStart ??
  //     meData.team?.profile?.standupTimeWindow?.morningStart
  //   ) {
  //     return selectedTab !== standupsOpenFor
  //   } else {
  //     return false
  //   }
  // }, [selectedTab, standupsOpenFor, meData])

  useEffect(() => {
    if (open) {
      dispatch(fetchTodaysStandups())
    }
  }, [open])

  useLayoutEffect(() => {
    if (open) {
      setSelectedProject({})
      setSelectedTasks([])
      setPage(0)
      // setSearch(null)
      setTaskOptions({
        loading: false,
        data: []
      })
    }
  }, [open])

  useEffect(() => {
    if (selectedProject.boardId) {
      setTaskOptions({ data: [], loading: true })
      // setSelectedTasks([])
      handleFetchListViewTasks((res, err) => {
        setTaskOptions(prev => ({ ...prev, loading: false }))
      })
    }
  }, [selectedProject.value, showMyTasks, search])

  // useEffect(() => {
  //   if (search === null) return
  //   let timerId = setTimeout(() => {
  //     setTaskOptions(prev => ({ ...prev, data: [], loading: true }))
  //     handleFetchListViewTasks((res, err) => {
  //       setTaskOptions(prev => ({ ...prev, loading: false }))
  //     })
  //   }, 350)

  //   return () => {
  //     clearTimeout(timerId)
  //   }
  // }, [search])

  // useEffect(() => {
  //   const task = userTasksData.find(item => item._id === taskPanel.data._id);
  //   if (task) {
  //     setTaskPanel(prev => ({...prev, data: task}))
  //   }
  // }, [userTasksData])

  useEffect(() => {
    let timeWindow =
      meData.profile?.standupTimeWindow ??
      meData.team?.profile?.standupTimeWindow

    if (timeWindow) {
      setStandupTimeWindow({ ...timeWindow })
    }
  }, [meData])

  // useEffect(() => {
  //   if (
  //     time >= standupTimeWindow.morningStart &&
  //     time <= standupTimeWindow.morningEnd
  //   ) {
  //     setStandupsOpenFor('morning')
  //   } else if (
  //     time >= standupTimeWindow.eveningStart &&
  //     time <= standupTimeWindow.eveningEnd
  //   ) {
  //     setStandupsOpenFor('evening')
  //   } else {
  //     setStandupsOpenFor('')
  //   }
  // }, [time, open, standupTimeWindow, standupsData._id])

  useEffect(() => {
    const tasks = listViewTasks.data
      .filter(taskId => boardNewTasksData[taskId])
      .map(taskId => boardNewTasksData[taskId])

    setAllTasksData(tasks)
  }, [listViewTasks, boardNewTasksData])

  useEffect(() => {
    if (standupsData._id) {
      setStandupsDataState([...standupsData.standups])
    }
  }, [standupsData])

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useEffect(() => {
    if (allProjects.length) {
      setProjectsOptions(
        allProjects
          .filter(item => !item.isArchived && !item.isComplete)
          .map(item => ({
            label: item.name,
            value: item._id,
            boardId: item.defaultBoard?._id
          }))
      )
    }
  }, [allProjects])

  useEffect(() => {
    if (selectedProject.value) {
      setTaskOptions(prev => ({
        ...prev,
        data: allTasksData
          .filter(item => {
            if (item.markAsComplete) return false
            // if (showMyTasks) {
            //   const idx = item.assignedAgencyTeam
            //     .concat(item.assignedClientTeam)
            //     .findIndex((item) => item._id === meData._id)
            //   if (idx === -1) return false
            // }

            const idx = [
              ...standupList.morning,
              ...standupList.evening,
              ...standupsData.standups
            ].findIndex(task => task.task?._id === item._id)

            if (idx !== -1) return false
            return true
          })
          .map(item => ({
            label: item.title,
            value: item._id
          }))
      }))
    } else {
      setTaskOptions(prev => ({ ...prev, data: [] }))
    }
  }, [allTasksData, standupList, standupsData, showMyTasks])

  useEffect(() => {
    if (!workspacePermissionFetched) {
      dispatch(fetchWorkspacePermissions())
    }
  }, [workspacePermissionFetched])

  useEffect(() => {
    if (selectedProject.value) {
      let permissionObj = {}
      for (let item of workspacePermission) {
        if (typeof item[selectedProject.value] === 'object') {
          permissionObj = item[selectedProject.value].access
        }
      }

      setSelectedProjectTaskPermission(permissionObj.task ?? {})
    }
  }, [selectedProject.value, workspacePermission])

  // useEffect(() => {
  //   if (openedTask) {
  //     setTaskPanel((prev) => ({ ...prev, open: true, loading: true }))
  //     dispatch(
  //       fetchTaskById(openedTask, (res, err) => {
  //         if (err) dispatch(fireErrorToaster(res))
  //         else {
  //           setTaskPanel((prev) => ({ ...prev, data: res, loading: false }))
  //         }
  //       })
  //     )
  //   }
  // }, [openedTask])

  useEffect(() => {
    if (taskPanel.data.workSpace) {
      const ws = allProjects.find(item => item._id === taskPanel.data.workSpace)

      dispatch(addOpenedWorkspace(ws)) //setting currentWorkspace
      setCurrentBoardById(taskPanel.data.workspaceBoard)

      // if (ws) {
      //   let permissionObj = {}
      //   for (let item of workspacePermission) {
      //     if (typeof item[taskPanel.data.workSpace] === 'object') {
      //       permissionObj = item[taskPanel.data.workSpace].access
      //     }
      //   }

      //   setStandupTaskPermission({
      //     task: permissionObj.task,
      //     subtask: permissionObj.subTask
      //   })

      //   // setWorkspaceData({ workspace: ws, board: ws.defaultBoard })
      // }
    }
  }, [taskPanel.data.workSpace, allProjects, workspacePermission])

  useEffect(() => {
    if (taskPanel.data._id) {
      setStandupsDataState(prev =>
        prev.map(item => {
          if (item.task._id === taskPanel.data._id) {
            return {
              ...item,
              task: {
                ...item.task,
                estimateTimeInMillisecond:
                  taskPanel.data.estimateTimeInMillisecond,
                estimateTimeInString: taskPanel.data.estimateTimeInString
              }
            }
          }
          return item
        })
      )
    }
  }, [
    taskPanel.data.estimateTimeInString,
    taskPanel.data._id,
    taskPanel.data.estimateTimeInMillisecond
  ])

  useEffect(() => {
    if (taskPanel.open && !taskPanel.loading) {
      dispatch(fetchWorkspaceTagsById(taskPanel.data.workSpace))
    }
  }, [taskPanel.open, taskPanel.loading])

  useEffect(() => {
    if (allTasksData.length && standupList[selectedTab].length) {
      const updatedStandupList = standupList[selectedTab].map(item => {
        const matchedTask = boardNewTasksData[item.task._id]
        // const matchedTask = allTasksData.find(
        //   task => task._id === item.task._id
        // )

        if (matchedTask)
          return {
            ...item,
            name: matchedTask.title,
            task: {
              ...item.task,
              estimateTimeInString: matchedTask.estimateTimeInString
            }
          }
        return item
      })

      setStandupList(prev => ({ ...prev, [selectedTab]: updatedStandupList }))
    }
  }, [allTasksData])

  const handleSelectProject = projectOption => {
    setSelectedProject(projectOption)
    const ws = allProjects.find(item => item._id === projectOption.value)

    if (ws) {
      dispatch(addOpenedWorkspace(ws))
      setCurrentBoardById(ws.defaultBoard._id)
    }
  }

  const handleFetchListViewTasks = (cb, pageCount = 0) => {
    dispatch(
      getListViewTasks(
        {
          workspace: selectedProject.value,
          workspaceBoard: selectedProject.boardId,
          myTasks: showMyTasks,
          search: search || null
        },
        { limit: 20, page: pageCount, type: 'list' },
        cb
      )
    )
  }

  const handleSelectTask = newVal => {
    setSelectedTasks(newVal)
  }

  const handleAddStandup = () => {
    const newData = selectedTasks.map(item => {
      const task = boardNewTasksData[item.value]
      // const task = allTasksData.find(task => task._id === item.value)

      const obj = {
        projectName: selectedProject.label,
        projectId: selectedProject.value,
        name: item.label,
        task: {
          _id: item.value,
          estimateTimeInString: task.estimateTimeInString
        },
        isComplete: false,
        type: selectedTab
      }
      return obj
    })

    setStandupList(prev => ({
      ...prev,
      [selectedTab]: [...prev[selectedTab], ...newData]
    }))
    setSelectedProject({})
    setSelectedTasks([])
  }

  const handleRemoveStandup = useCallback(
    index => {
      setStandupList(prev => ({
        ...prev,
        [selectedTab]: prev[selectedTab].filter((item, idx) => idx !== index)
      }))
    },
    [selectedTab]
  )

  const toggleStandupCompletion = useCallback(
    standup => {
      dispatch(
        markStandupAsComplete(
          standup.task._id,
          standupsData._id,
          { markAsComplete: !standup.isComplete },
          (res, err) => {
            if (err) dispatch(fireErrorToaster(res))
          }
        )
      )
    },
    [selectedTab, standupsData._id]
  )

  const standupsCallback = (res, err) => {
    setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      dispatch(fireSuccessToaster('Changes saved successfully!'))
      setStandupList({ morning: [], evening: [] })
    }
  }

  const handleSaveChanges = () => {
    const transformedData = standupList[selectedTab].map(item => ({
      ...item,
      task: item.task._id
    }))

    const reqBody = {
      type: selectedTab === 'morning' ? 'morningStandups' : 'eveningStandups',
      standups: transformedData
    }

    setLoading(true)
    if (!standupsData._id) {
      dispatch(createStandups(reqBody, standupsCallback))
    } else {
      dispatch(updateStandupById(standupsData._id, reqBody, standupsCallback))
    }
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const toggleCreateTaskModal = () => {
    if (createTaskModalOpen) {
      setCreateTaskModalOpen(false)
    } else {
      setCreateTaskModalOpen(true)
    }
  }

  const handleDeleteSavedStandup = () => {
    setDeleteStandupAlert(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteStandup(
        standupsData._id,
        deleteStandupAlert.data.task._id,
        (res, err) => {
          if (err) {
            setDeleteStandupAlert(prev => ({ ...prev, loading: false }))
            dispatch(fireErrorToaster(res))
          } else {
            setDeleteStandupAlert(prev => ({
              ...prev,
              open: false,
              loading: false
            }))
            dispatch(fireSuccessToaster('Standup removed successfully!'))
          }
        }
      )
    )
  }

  const toggleDeleteStandupAlert = data => {
    if (deleteStandupAlert.open) {
      setDeleteStandupAlert(prev => ({ ...prev, open: false }))
    } else {
      setDeleteStandupAlert({ open: true, data, loading: false })
    }
  }

  const addSelectedTask = ({ taskTitle, taskId, projectId }) => {
    if (selectedProject.value === projectId) {
      setSelectedTasks(prev => [...prev, { label: taskTitle, value: taskId }])
    }
  }

  const openEditTaskPanel = taskId => {
    const task = boardNewTasksData[taskId]
    // const task = allTasksData.find(item => item._id === taskId)

    if (task) {
      setTaskPanel(prev => ({
        data: { ...task },
        open: true,
        loading: false
      }))
    } else {
      setTaskPanel(prev => ({ ...prev, open: true, loading: true }))
      dispatch(
        fetchTaskById(taskId, (res, err) => {
          if (err) dispatch(fireErrorToaster(res))
          else {
            setTaskPanel(prev => ({ ...prev, data: res, loading: false }))
          }
        })
      )
    }
  }

  const closeEditTaskPanel = () => {
    setTaskPanel(prev => ({
      ...prev,
      open: false
    }))
  }

  const handleClose = () => {
    if (standupList.morning.length > 0 || standupList.evening.length > 0) {
      setUnsavedChangesAlertOpen(true)
    } else {
      onClose()
    }
  }

  const setCurrentBoardById = boardId => {
    if (currentBoard._id === boardId) return

    fetchSingleEmbedById(boardId, (res, err) => {
      if (!err) dispatch(addCurrentBoard(res))
    })
  }

  const loadMoreTasks = e => {
    infiniteScroll(e, {
      cb: activateInfiniteScroll => {
        setTaskOptions(prev => ({ ...prev, loading: true }))
        handleFetchListViewTasks((res, err) => {
          activateInfiniteScroll()
          setTaskOptions(prev => ({ ...prev, loading: false }))
        }, page + 1)
        setPage(prev => prev + 1)
      }
    })
  }

  const handleSearchTasks = (input, eventObj) => {
    if (eventObj.action === 'input-change') {
      debounce(() => {
        setSearch(input)
      }, 300)
    }
  }

  const handleMenuClose = () => {
    console.log('menu close')
    setSearch('')
    setPage(0)
  }

  return (
    <>
      <CustomModal onClose={handleClose} open={open} size="extraLarge">
        <CustomModalHeader
          heading="Daily Standups"
          icon={
            <div className="w-8 h-8 lg:w-10 lg:h-10 bg-primary-light rounded flex justify-center items-center text-xl">
              <PlaneIcon className="text-primary-main" />
            </div>
          }
        />
        <CustomModalBody overflow="visible">
          <CreateStandupsModalTop
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            showMyTasks={showMyTasks}
            setShowMyTasks={setShowMyTasks}
          />

          <div className="pt-6">
            {/* ======= Add Area ====== */}
            <div className="flex items-center space-x-4 mb-8">
              <div className="flex-1">
                <Select
                  options={projectsOptions}
                  value={selectedProject.value ? selectedProject : ''}
                  className="w-full"
                  styles={reactSelectCustomStyles}
                  onChange={handleSelectProject}
                  isLoading={projectsLoading}
                  placeholder="Select Project"
                  // isDisabled={isDisabled}
                />
              </div>
              <div className="flex-1">
                <Select
                  options={taskOptions.data}
                  value={selectedTasks.length ? selectedTasks : ''}
                  className="w-full"
                  styles={reactSelectCustomStyles}
                  onChange={handleSelectTask}
                  isLoading={taskOptions.loading}
                  placeholder="Select Task"
                  isMulti
                  components={{ MenuList: CustomMenu }}
                  onInputChange={handleSearchTasks}
                  onMenuClose={handleMenuClose}
                  // filterOption={handleFilterOption}
                  selectProps={{
                    toggleCreateTaskModal: toggleCreateTaskModal,
                    canAdd:
                      Boolean(selectedProject.value) &&
                      selectedProjectTaskPermission.create,
                    showMyTasks,
                    setShowMyTasks,
                    onScroll:
                      listViewTasks.metadata.total > listViewTasks.data.length
                        ? loadMoreTasks
                        : undefined,
                    isLoading: taskOptions.loading
                  }}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleAddStandup}
                  // disabled={isDisabled}
                >
                  Add
                </Button>
              </div>
            </div>

            {/* ======= Added Standups List ====== */}
            <div className="h-60 overflow-y-auto">
              {selectedTab === 'morning' ? (
                <>
                  <MorningStandups
                    timeWindow={standupTimeWindow}
                    abbreviation={abbreviation}
                    // isDisabled={isDisabled}
                    toggleDeleteStandupAlert={toggleDeleteStandupAlert}
                    toggleStandupCompletion={toggleStandupCompletion}
                    standupsOpenFor={standupsOpenFor}
                    openEditTaskPanel={openEditTaskPanel}
                    standupsData={standupsDataState}
                  />
                  <StandupList
                    data={standupList.morning}
                    handleRemoveStandup={handleRemoveStandup}
                    canRemove={true}
                    standupsOpenFor={standupsOpenFor}
                    openEditTaskPanel={openEditTaskPanel}
                  />
                </>
              ) : (
                <>
                  <EveningStandups
                    toggleDeleteStandupAlert={toggleDeleteStandupAlert}
                    toggleStandupCompletion={toggleStandupCompletion}
                    timeWindow={standupTimeWindow}
                    abbreviation={abbreviation}
                    // isDisabled={isDisabled}
                    standupsOpenFor={standupsOpenFor}
                    openEditTaskPanel={openEditTaskPanel}
                    standupsData={standupsDataState}
                  />

                  {Boolean(standupsData.standups.length) &&
                    Boolean(standupList.evening.length) && (
                      <div className="mt-4 border-b border-red-400 mr-2">
                        <span
                          className="inline-block text-red-400 border-t border-l border-r border-red-400 text-xs font-semibold px-2 py-1"
                          style={{ fontSize: 10 }}
                        >
                          Newly Added
                        </span>
                      </div>
                      // <hr className="my-1" />
                    )}

                  <StandupList
                    data={standupList.evening}
                    handleRemoveStandup={handleRemoveStandup}
                    canRemove={true}
                    standupsOpenFor={standupsOpenFor}
                    openEditTaskPanel={openEditTaskPanel}
                  />
                </>
              )}
            </div>

            {Boolean(standupsData.standups.length) && <Stats />}
          </div>
        </CustomModalBody>
        <CustomModalFooter>
          <LoadingButton
            loading={loading}
            onClick={handleSaveChanges}
            disabled={standupList[selectedTab].length === 0}
          >
            Save
          </LoadingButton>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
          <div className="ml-auto" style={{ marginLeft: 'auto' }}>
            <Link
              to={summaryUrl}
              onClick={onClose}
              className="text-primary-main text-smaller flex items-center"
            >
              <span className="mr-1">View standups summary</span> <ArrowRight />
            </Link>
          </div>
        </CustomModalFooter>
      </CustomModal>
      {createTaskModalOpen && (
        <CreateTaskPanel
          open={createTaskModalOpen}
          onClose={toggleCreateTaskModal}
          addSelectedTask={addSelectedTask}
        />
      )}
      <AlertModal
        open={deleteStandupAlert.open}
        warningText={`Do you want to delete "${deleteStandupAlert.data.name}"?`}
        handleDialog={toggleDeleteStandupAlert}
        handleDeleteAction={handleDeleteSavedStandup}
        loading={deleteStandupAlert.loading}
      />
      <AlertModal
        open={unsavedChangesAlertOpen}
        warningText="Do you want to close this modal? Closing it will discard all the unsaved changes."
        handleDialog={() => setUnsavedChangesAlertOpen(false)}
        deleteBtnText="Close"
        handleDeleteAction={onClose}
        loading={deleteStandupAlert.loading}
      />
      <TaskPanel
        taskData={taskPanel.data}
        open={taskPanel.open}
        onClose={closeEditTaskPanel}
        // taskPermission={standupTaskPermission.task}
        // subtaskPermission={standupTaskPermission.subtask}
        // groupBy={'status'}
        inDashboardArea={true}
        isOutsideBoardArea={true}
        isLoading={taskPanel.loading}
        // currentWorkspace={workspaceData.workspace}
        // currentBoard={workspaceData.board}
      />
    </>
  )
}

const Stats = () => {
  const standupsData = useSelector(state => state.standups.data.standups)
  const { estimatedTime, trackedTime, completion } = useMemo(() => {
    const allStandups = standupsData || []
    let totalTasks = allStandups.length,
      completedTasks = 0,
      trackedTime = 0,
      estimatedTime = 0

    for (let standup of standupsData) {
      if (standup.isComplete) completedTasks++
      if (standup.task.estimateTimeInMilliSecond)
        estimatedTime += standup.task.estimateTimeInMilliSecond
      trackedTime += standup.timeTracked
    }

    return {
      estimatedTime: dateAndTimeHelper.formatMilliseconds(estimatedTime),
      trackedTime: dateAndTimeHelper.formatMilliseconds(trackedTime),
      completion: Math.round((completedTasks / totalTasks) * 100)
    }
  }, [standupsData])
  return (
    <div
      className="text-xs flex items-center justify-end space-x-8 text-gray-700 py-2 pr-4"
      style={{ margin: '0 -24px' }}
    >
      <div>
        <span className="font-semibold">Estimated time: </span>
        <span className="font-medium">{estimatedTime}</span>
      </div>
      <div>
        <span className="font-semibold">Time tracked: </span>
        <span className="font-medium">{trackedTime}</span>
      </div>
      <div>
        <span className="font-semibold ">Completion: </span>
        <span className="font-medium">{completion}%</span>
      </div>
    </div>
  )
}

const CustomMenu = props => {
  const toggleShowMyTasks = () => {
    props.selectProps.selectProps.setShowMyTasks(prev => !prev)
  }

  return (
    <components.MenuList
      {...props}
      innerProps={{
        ...props.innerProps,
        onScroll: props.selectProps.selectProps.onScroll
      }}
    >
      {props.selectProps.selectProps.canAdd && (
        <div className="flex items-center justify-between px-4 py-2">
          <button
            onClick={props.selectProps.selectProps.toggleCreateTaskModal}
            className="text-sm text-primary-main flex items-center px-2 py-1"
          >
            <AddIcon className="mr-1" />
            <span>Create new task</span>
          </button>
          <OutlinedButton
            isActive={props.selectProps.selectProps.showMyTasks}
            onClick={toggleShowMyTasks}
            hoverColorChange={false}
          >
            <FilterIcon />
            <span className="ml-2">My tasks</span>
          </OutlinedButton>
        </div>
      )}
      {props.children}
      {Array.isArray(props.children) &&
        props.selectProps.selectProps.isLoading && (
          <div className="w-full flex justify-center">
            <div style={{ transform: 'scale(0.6)' }}>
              <Loader />
            </div>
          </div>
        )}
    </components.MenuList>
  )
}

export default CreateStandupsModal
