import { useEffect } from 'react'
import { hideChatMessenger } from 'utils'

const AccessDenied = () => {
  useEffect(() => {
    hideChatMessenger()
  })

  return (
    <div className="w-screen h-screen flex flex-col text-center items-center justify-center">
      <h1 className="text-3xl">Access Denied</h1>
      <p className="text-gray-800">
        You do not have permission to access this page!
      </p>
      <a
        href="/"
        className="mt-4 bg-primary-main text-white px-4 py-1 rounded font-medium"
      >
        Go Home
      </a>
    </div>
  )
}

export default AccessDenied
