import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import {
  Button,
  Box,
  Typography,
  TextField,
  CircularProgress
} from '@material-ui/core'
import { DefaultServiceImage, PaymentCardLogo } from 'static/Images'
import { Link } from 'react-router-dom'
import { LoadingButton } from 'global/globalComponents'
import { useState, useEffect } from 'react'
import { fetchServiceById, stripePayment } from 'thunks/service/actions'
import { useDispatch, useSelector } from 'react-redux'
import { storeToken } from 'utils/userLogin'
import { userSignup } from 'thunks/signup'
import { userRoles, checkLogin } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { hideChatMessenger } from 'utils'
import PaymentSuccessfulModal from 'components/AgencyPlans/PaymentSuccessfulModal'
import PaymentFailModal from 'components/AgencyPlans/PaymentFailModal'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import clsx from 'clsx'
import ClientCheckoutStyles from './ClientCheckoutStyles'
import useTitle from 'customHooks/useTitle'
import { fetchMeData } from 'thunks/me/actions'
import { Loader } from 'global/globalComponents'
import { generateInvoice } from 'thunks/invoices/actions'
import { RoundedTextInput } from 'global/globalComponents'

const OrderCheckout = () => {
  // const stripe = useStripe()
  // const elements = useElements()
  const classes = ClientCheckoutStyles()
  const dispatch = useDispatch()
  const [selectedPlan, setSelectedPlan] = useState({
    amount: 0,
    description: '',
    name: '',
    image: null,
    currency: ''
  })
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  // const [accountHolderName, setAccountHolderName] = useState('')
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  })
  const [paymentStatusModal, setPaymentStatusModal] = useState({
    success: false,
    fail: false
  })
  const [domain, setDomain] = useState('')
  // const [success, setSuccess] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [passwordValid, setPasswordValid] = useState(true)
  // const [active, setActive] = useState('')
  // const [disable, setDisable] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)
  const [purchaseReqCount, setPurchaseReqCount] = useState(0)

  useTitle(agencyInfo?.brandName, agencyInfo?.favicon)

  useEffect(() => {
    let serviceId = window.location.href
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split('/')[2]

    dispatch(fetchServiceById(serviceId, fetchServiceByIdCallback))
  }, [])

  useEffect(() => {
    setDomain(
      window.location.href
        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
        .split('/')[0]
        .replace('/', '')
    )
  }, [])

  useEffect(() => {
    hideChatMessenger()
  })

  useEffect(() => {
    dispatch(
      fetchMeData((res, err) => {
        if (!err) {
          setLoggedIn(true)
          setFormData({ name: res.name, email: res.email, _id: res._id })
        }
      })
    )
  }, [])

  const fetchAgencyInfo = async () => {
    try {
      const res = await getAgencyInfoByDomain()
      // setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
      setAgencyInfo(res?.data?.brand?.smallLogo ? res?.data.brand : agencyInfo)
    } catch (err) {
      setAgencyInfo(agencyInfo)
    }
  }

  useEffect(() => {
    fetchAgencyInfo()
  }, [])

  useEffect(() => {
    if (purchaseReqCount) {
      handleServicePurchase()
    }
  }, [purchaseReqCount])

  const modalCloseHandler = (type) => {
    setPaymentStatusModal((prev) => ({ ...prev, [type]: false }))
  }

  const fetchServiceByIdCallback = (res, err) => {
    if (!err) {
      setSelectedPlan(res)
      setPageLoading(false)
      // setActive(res?.serviceType)
      // res?.serviceType === 'stripe' ? setDisable(false) : setDisable(true)
    }
  }

  const handleSubmit = async (e) => {
    // console.log(accountHolderName)
    if (
      !formData.name ||
      !formData.email ||
      !formData.password ||
      // !accountHolderName ||
      !confirmPassword
    ) {
      return dispatch(fireErrorToaster('please fill all the fields'))
    }
    if (formData.password !== confirmPassword) {
      return dispatch(
        fireErrorToaster('Password does not match with confirm password')
      )
    }
    let validEmail = /.+@.+\..+/.test(formData.email)
    if (!validEmail) {
      return dispatch(fireErrorToaster('please enter valid email address'))
    }
    if (!validPasswordHandler()) {
      setPasswordValid(false)
      dispatch(fireErrorToaster('Please enter a valid password'))
      return
    }
    // if (!stripe || !elements) {
    //   dispatch(
    //     fireErrorToaster(
    //       'Stripe is loading. Please try again after few seconds'
    //     )
    //   )
    //   return
    // }
    setLoading(true)
    // const cardElement = elements.getElement(CardElement)
    // const { error: tokenError, token } = await stripe.createToken(cardElement, {
    //   name: accountHolderName,
    //   email: formData.email
    // })

    // if (tokenError) {
    //   setLoader(false)
    //   setLoading(false)
    //   dispatch(
    //     fireErrorToaster(
    //       'Some error occurred while initiating your payment. Please check your card number or try again'
    //     )
    //   )
    //   return
    // }
    userSignup(
      {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        agencyDomain: domain,
        role: userRoles.USER_CLIENT
      },
      signupCallback
    )
  }

  const signupCallback = async (res, err) => {
    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res))
    } else {
      storeToken(res.login.token)
      // setLoader(true)
      setFormData({ name: res.name, email: res.email, _id: res._id })
      setPurchaseReqCount((prev) => prev + 1)
    }
  }

  // const stripePaymentCallback = (res, err) => {
  //   setLoader(false)
  //   if (err) {
  //     setLoading(false)
  //     setPaymentStatusModal({ fail: true, success: false })
  //   } else {
  //     setSuccess(true)
  //     setPaymentStatusModal({ fail: false, success: true })
  //   }
  // }

  const handleFormChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
    if (name === 'password') {
      setPasswordValid(true)
    }
  }

  const validPasswordHandler = () => {
    const password = formData.password
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g) &&
      password.trim().length >= 8 &&
      password.trim().length <= 20
    ) {
      setPasswordValid(true)
      return true
    } else {
      setPasswordValid(false)
      return false
    }
  }

  const handleServicePurchase = () => {
    if (!formData.name || !formData.email) {
      return dispatch(fireErrorToaster('Please fill all the fields')) // just for safety
    }

    const now = new Date()
    const days =
      new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() || 30

    let data = {
      autoRepeat: selectedPlan.isRecurring ?? false,
      dueDate: new Date(now.setDate(now.getDate() + days)).toISOString(), //will change it soon,
      autoRepeatFrequency: 30, //will change it soon
      client: {
        clientName: formData.name,
        clientEmail: formData.email,
        clientId: formData._id
      },
      invoiceItems: {
        ...selectedPlan,
        name: selectedPlan.name,
        quantity: 1,
        rate: selectedPlan.amount * 0.01,
        total: selectedPlan.amount * 0.01
      },
      totalAmount: selectedPlan.amount * 0.01,
      type: 'service',
      currency: selectedPlan.currency.toUpperCase()
    }

    setLoading(true)
    dispatch(generateInvoice(data, selectPlanHandlerCallback))
  }

  const selectPlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      window.location.href = `${window.location.protocol}//${window.location.host}/i/${res._id}/checkout?vtID=${res.viewToken}`
    }
  }

  return (
    <>
      {pageLoading ? (
        <div className="w-screen h-screen bg-white flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <Box className="h-screen bg-gray-100">
          <Box className="max-w-7xl mx-auto px-4 pb-8 sm:px-6 md:px-8">
            <Box className="flex align-center py-8 justify-center">
              <img
                className="h-12 w-auto"
                src={agencyInfo.smallLogo?.replace('s3.wasabisys.com/', '')}
                alt=""
              />
            </Box>

            <Box className="bg-white overflow-hidden shadow sm:rounded-lg">
              <Box className="px-4 py-5 sm:p-6 md:grid grid-cols-1 md:grid-cols-2 flex flex-col-reverse">
                <Box className="overflow-hidden p-3 sm:col-span-1 mb-6">
                  <Box className=" mb-6 hidden md:flex">
                    <Box className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-200 text-white">
                      <img
                        className={classes.serviceImage}
                        src={(
                          selectedPlan?.image ?? DefaultServiceImage
                        )?.replace('s3.wasabisys.com/', '')}
                        alt=""
                      />
                    </Box>
                    <Box className="ml-4">
                      <h4 className="text-lg leading-6 font-medium capitalize text-gray-900">
                        {selectedPlan?.name}
                      </h4>
                      <Typography className="mt-2 text-base leading-6 text-gray-500 trix-content">
                        {selectedPlan?.description}
                      </Typography>
                    </Box>
                  </Box>

                  <div className="border-t border-gray-200 pt-8">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Billing information
                    </h3>
                  </div>

                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        className="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <RoundedTextInput
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleFormChange}
                        className="w-full"
                        id="name"
                        placeholder="Name"
                        disabled={loggedIn}
                      />
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        className="block text-sm font-medium leading-5 text-gray-700 mb-2"
                        htmlFor="userEmail"
                      >
                        Email
                      </label>
                      <RoundedTextInput
                        name="email"
                        value={formData.email}
                        onChange={handleFormChange}
                        className="w-full"
                        id="userEmail"
                        placeholder="Email"
                        required
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        disabled={loggedIn}
                      />
                      <p className="mt-2 text-sm text-gray-500 sm:col-span-6">
                        {loggedIn
                          ? 'Not this account?'
                          : 'Already have an account?'}
                        &nbsp;
                        <a
                          className="text-primary-main	"
                          href={`https://${domain}/login`}
                        >
                          Login
                        </a>
                      </p>
                    </div>
                    {!loggedIn && (
                      <>
                        <div className="sm:col-span-6">
                          <label
                            className="block text-sm font-medium leading-5 text-gray-700 mb-2"
                            htmlFor="password"
                          >
                            Password
                          </label>
                          <RoundedTextInput
                            name="password"
                            type="password"
                            inputProps={{
                              autoComplete: 'new-password'
                            }}
                            onChange={handleFormChange}
                            className="w-full"
                            id="password"
                            placeholder="Password"
                            required
                            onBlur={validPasswordHandler}
                          />
                          {formData.password && !passwordValid && (
                            <p
                              className="text-xs mt-1"
                              style={{ color: '#f44336' }}
                            >
                              Password should be a combination of an
                              uppercase/lowercase letter, a number, a symbol and
                              length between 8 to 20.
                            </p>
                          )}
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            className="block text-sm font-medium leading-5 text-gray-700 mb-2"
                            htmlFor="confirm-password"
                          >
                            Confirm Password
                          </label>
                          <RoundedTextInput
                            name="password"
                            type="password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full"
                            id="confirm-password"
                            placeholder="Confirm Password"
                            required
                          />
                        </div>
                      </>
                    )}
                    {/* <div className={clsx('sm:col-span-6', classes[active])}>
                      <label
                        htmlFor="card_number"
                        className="block text-sm font-medium leading-5 text-gray-700 mb-2"
                      >
                        Payment Method
                      </label>
                      <div className="p-6 shadow-md grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="flex flex-wrap gap-2 sm:col-span-6">
                          <img
                            src={PaymentCardLogo}
                            className={classes.image}
                            alt=""
                          />
                          <span className={classes.label}>and more...</span>
                        </div>
                        <div className="sm:col-span-6">
                          <div className="border border-gray-400 p-2">
                            <CardElement
                              options={{
                                iconStyle: 'solid',
                                style: {
                                  base: {
                                    fontSize: '15px',
                                    color: '#424770',
                                    iconColor: '#c4f0ff',
                                    '::placeholder': {
                                      color: '#aab7c4'
                                    }
                                  },
                                  invalid: {
                                    iconColor: '#9e2146',
                                    color: '#9e2146',
                                    BorderColor: 'red'
                                  }
                                },
                                disabled: disable
                              }}
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <TextField
                            disabled={disable}
                            className="w-full"
                            id="card name"
                            value={accountHolderName}
                            name="card name"
                            onChange={(e) =>
                              setAccountHolderName(e.target.value)
                            }
                            placeholder="Name on card"
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="mt-6">
                    <p className="text-gray-500 mb-3 text-sm">
                      By submitting this form you agree to our terms of service.
                    </p>
                    <LoadingButton
                      className="mt-8 w-full"
                      size="large"
                      loading={loading}
                      onClick={loggedIn ? handleServicePurchase : handleSubmit}
                    >
                      Complete Checkout
                    </LoadingButton>
                  </div>
                </Box>

                <div className="overflow-hidden p-3 sm:col-span-1">
                  <div className="mb-6 sm:hidden block">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-200 text-white">
                          <img
                            className="h-full object-cover w-auto"
                            src={(
                              selectedPlan?.image ?? DefaultServiceImage
                            )?.replace('s3.wasabisys.com/', '')}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="ml-4 ">
                        <h4 className="text-lg leading-6 font-medium capitalize text-primary-main">
                          {selectedPlan?.name}
                        </h4>
                        <div className="mt-2 text-base leading-6 text-gray-500 trix-content ">
                          <div>{selectedPlan?.description}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="overflow-hidden shadow rounded-lg">
                    <div className="border-b border-primary-light bg-primary-light px-4 py-5 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium  text-primary-main">
                        Order summary
                      </h3>
                    </div>
                    <div className="px-4 py-5 sm:px-6">
                      <div className="mt-2 min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                        <span className="block text-sm font-medium capitalize leading-5 text-gray-700">
                          1x {selectedPlan?.name}
                        </span>
                        <h5 className="block text-sm font-medium leading-5 text-primary-main	">
                          <span>{selectedPlan?.amount / 100} </span>
                          {selectedPlan?.currency?.toUpperCase()}
                        </h5>
                      </div>
                    </div>
                    <div className="border-t border-primary-light px-4 py-4 sm:px-6 min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <span className="block text-sm font-medium leading-5 text-gray-700">
                        Sub-Total:
                      </span>
                      <h5 className="block text-sm font-medium leading-5 text-primary-main	">
                        <span>{selectedPlan?.amount / 100} </span>
                        {selectedPlan?.currency?.toUpperCase()}
                      </h5>
                    </div>
                    <div className="border-t border-primary-light px-4 py-4 sm:px-6 min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <span className="block text-sm font-medium leading-5 text-gray-700">
                        Total:
                      </span>
                      <h5 className="block text-sm font-medium leading-5 text-primary-main	">
                        <span>{selectedPlan?.amount / 100} </span>
                        {selectedPlan?.currency?.toUpperCase()}
                      </h5>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
          <PaymentSuccessfulModal
            open={paymentStatusModal.success}
            path={'/client/dashboard?newClient=true&payment=true'}
          />
          <PaymentFailModal
            onClose={modalCloseHandler}
            open={paymentStatusModal.fail}
          />
          {loader && (
            <div className={clsx(classes.loader, 'inset-0')}>
              <PaymentLoader />
            </div>
          )}
        </Box>
      )}
    </>
  )
}

const PaymentLoader = () => (
  <div className="w-screen h-screen flex flex-col justify-center items-center">
    <CircularProgress />
    <h2 className="text-medium text-lg text-white z-10">
      Your payment request is being processed...
    </h2>
  </div>
)

export default OrderCheckout
