import { PageNotFoundImage } from 'static/svg'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { checkLogin, hideChatMessenger } from 'utils'

const PageNotFound = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const loginStatus = checkLogin()

    if (!loginStatus) {
      hideChatMessenger()
    }
  }, [])

  return (
    <>
      {pathname.startsWith('/client') ||
      pathname.startsWith('/agency') ||
      pathname.startsWith('/select-service') ||
      pathname.startsWith('/plans') ||
      pathname === '/' ? null : (
        <div className="w-screen h-screen flex flex-col justify-center items-center text-center">
          <img src={PageNotFoundImage} alt="" style={{ maxWidth: '300px' }} />
          <h2 className="text-2xl mt-4 font-medium text-gray-800 tracking-wide">
            Page not found!
          </h2>
          <a
            href="/"
            className="mt-4 bg-primary-main text-white px-4 py-1 rounded font-medium"
          >
            Go Home
          </a>
        </div>
      )}
    </>
  )
}

export default PageNotFound
