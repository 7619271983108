import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core'

export default function CustomTableHeader({ headings }) {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        `grid grid-cols-8 gap-2 items-center mt-6  text-xs font-medium ml-2 ${classes.headerContainer}`
      )}
    >
      {headings.map((item) => (
        <div className={item.className}>
          <p>{item.title}</p>
        </div>
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      '& p': {
        color: 'rgba(9,30,66,.74)',
        fontWeight: 600,
        letterSpacing: '0.2px',
        userSelect: 'none'
      }
    }
  })
)
