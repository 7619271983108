import React from 'react'
import { useEffect, useState, useMemo } from 'react'
import { ChevronRightRounded } from '@material-ui/icons'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserPath } from 'utils/userRoles'
import { ReactComponent as HomeIcon } from 'static/svg/home.svg'

const FilesAssetsBreadCrumbs = () => {
  const [breadCrumbData, setBreadCrumbsData] = useState([])
  let { workspaceId, dirIds: dirIdsString } = useParams()
  const dirIdsArray = useMemo(() => {
    return dirIdsString.split('/')
  }, [dirIdsString])
  const meData = useSelector((state) => state.me.data)
  const filesAssetsData = useSelector(
    (state) => state.filesAssets.currentWSAssets.data
  )

  const { url } = useRouteMatch()

  useEffect(() => {
    const breadcrumbs = []

    for (let dirId of dirIdsArray) {
      for (let key in filesAssetsData) {
        const folder = filesAssetsData[key].folders?.find(
          (item) => item._id === dirId
        )

        if (folder) {
          breadcrumbs.push({
            id: dirId,
            name: folder.name,
            link: `${getUserPath(
              meData.role
            )}/workspace/${workspaceId}/files/${dirIdsString.slice(
              0,
              dirIdsString.indexOf(dirId)
            )}${dirId}`
          })
        }
      }
    }

    setBreadCrumbsData(breadcrumbs)
  }, [dirIdsArray.length, filesAssetsData])

  return (
    <ol className="flex items-center space-x-2 'text-gray-500'">
      <li className="flex items-center text-sm">
        <Link className="mr-2" to={url.split('/files')[0] + '/files'}>
          <HomeIcon className="text-primary-main w-5 h-5 text-gray-500 hover:text-gray-700" />
        </Link>
        <ChevronRightRounded
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          fontSize="small"
        />
      </li>
      {breadCrumbData.map((item, index, arr) => (
        <li key={item.name} className="flex items-center text-sm">
          {index !== 0 && (
            <ChevronRightRounded
              className="flex-shrink-0 h-5 w-5 text-gray-400"
              fontSize="small"
            />
          )}
          <Link
            className={`hover:text-gray-700 ${
              index === arr.length - 1
                ? 'font-bold text-gray-700'
                : 'text-gray-500'
            }`}
            to={item.link}
          >
            {item.name}
          </Link>
        </li>
      ))}
    </ol>
  )
}

export default FilesAssetsBreadCrumbs
