import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchPublicProjectTemplates } from 'thunks/projectTemplate/action'

import Templates from './Templates'
import { getUserPath } from 'utils/userRoles'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { useRouteMatch, useHistory } from 'react-router-dom'
import SingleTemplate from './SingleTemplate'
import CreateTemplateModal from './CreateTemplateModal'

const TemplateModalRightView = ({
  linkData,
  templateData,
  handleSelectTemplate,
  updateDataHandler,
  selectedCategory,
  deleteTemplateHandler,
  updateTemplateHandler
}) => {
  const meData = useSelector(state => state.me.data)
  const [userPrivateTemplate, setUserPrivateTemplate] = useState([])
  const [selectedCategoryTemplates, setSelectedCategoryTemplates] = useState([])
  const [pageState, setPageState] = useState()
  const [singleTemplateData, setSingleTemplateData] = useState({})
  const history = useHistory()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setUserPrivateTemplate(
      templateData?.filter(temp => temp.type === 'private')
    )
  }, [templateData])

  useEffect(() => {
    if (selectedCategory !== 'all') {
      setSelectedCategoryTemplates(
        templateData?.filter(
          temp =>
            temp.category?.toLowerCase() === selectedCategory?.toLowerCase()
        )
      )
    } else {
      setSelectedCategoryTemplates(templateData)
    }
  }, [selectedCategory, templateData])

  useEffect(() => {
    setPageState(0)
  }, [linkData])

  return (
    <div
      className="px-8 pt-8"
      style={{ height: 'calc(80vh - 64px)', overflow: 'auto' }}
    >
      {linkData !== 1 ? (
        pageState === 0 ? (
          <div>
            <Templates
              templatesData={selectedCategoryTemplates}
              setPageState={setPageState}
              linkData={linkData}
              setSingleTemplateData={setSingleTemplateData}
              updateDataHandler={updateDataHandler}
              deleteTemplateHandler={deleteTemplateHandler}
              updateTemplateHandler={updateTemplateHandler}
            />
          </div>
        ) : (
          <SingleTemplate
            handleBack={() => setPageState(0)}
            data={singleTemplateData}
            handleSelectTemplate={handleSelectTemplate}
          />
        )
      ) : pageState === 0 ? (
        <div>
          <div
            role="button"
            aria-label="Add new template"
            onClick={() => setOpen(true)}
            className={`cursor-pointer  border-2 rounded-xl flex justify-center items-center mb-8 bg-gray-100 border-2 border-dashed border-gray-400 hover:shadow-md hover:bg-gray-200 `}
            style={{ width: '280px', height: '150px' }}
          >
            <div
              className="flex justify-center flex-col items-center text-center text-gray-600"
              onClick={() => setOpen(true)}
            >
              <div className="">
                <AddIcon className="w-7 h-7 " />
              </div>
              <span className="text-smaller lg:text-sm ">New Template</span>
            </div>
          </div>
          <Templates
            templatesData={userPrivateTemplate}
            setPageState={setPageState}
            linkData={linkData}
            setSingleTemplateData={setSingleTemplateData}
            deleteTemplateHandler={deleteTemplateHandler}
            updateDataHandler={updateDataHandler}
            updateTemplateHandler={updateTemplateHandler}
          />
        </div>
      ) : (
        <SingleTemplate
          handleBack={() => setPageState(0)}
          data={singleTemplateData}
          handleSelectTemplate={handleSelectTemplate}
        />
      )}
      <CreateTemplateModal
        open={open}
        handleClose={() => setOpen(false)}
        updateDataHandler={updateDataHandler}
      />
    </div>
  )
}

export default TemplateModalRightView
