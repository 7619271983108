import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomFlag } from 'global/globalComponents'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { Button } from '@material-ui/core'
import Pill from 'global/globalComponents/Pill/Pill'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { fetchClientRaisedRequests } from 'thunks/clientRaisedRequestAgency/actions'
import pillVariantObj from 'utils/pillVariantObj'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { updateRaisedRequest } from 'thunks/clientRaisedRequestAgency/actions'
import RequestDetailsModal from './RequestDetailsModal'

import { NoAccess } from 'static/svg'
import { RefreshButton } from 'global/globalComponents'
import moment from 'moment'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import RequestTaskModel from './RequestTaskModal'
import { PopupMenu } from 'global/globalComponents'
import { getRoleBadge, userRoles } from 'utils'
import RaiseRequestModal from 'components/ClientComponent/ClientDashboard/RaiseRequestModal'

const totalRequestsToShow = 8

const ClientRequestsAgency = () => {
  const dispatch = useDispatch()
  const clientRaisedRequests = useSelector(
    state => state.clientRaisedRequestAgency
  )
  const teammates = useSelector(state => state.userTeammates.data)
  const meData = useSelector(state => state.me.data)
  const search = useSelector(state => state.generalAppState.headerSearch)
  const [openReqModal, setOpenReqModal] = useState({
    open: false,
    data: {}
  })
  const [visibleRequests, setVisibleRequests] = useState([])
  const [page, setPage] = useState(0)
  const permission = useSelector(state => state.permission)
  const [refreshing, setRefreshing] = useState(false)
  const [taskModalOpen, setTaskModalOpen] = useState({ open: false, data: {} })
  const [assignToMe, setAssignToMe] = useState(false)
  const [clientRaisedRequestsData, setClientRaisedRequestsData] = useState([])
  const [createClientRequestModal, setCreateClientRequestModal] = useState({
    open: false,
    data: {}
  })

  useEffect(() => {
    setClientRaisedRequestsData(clientRaisedRequests.data)
  }, [clientRaisedRequests])

  useEffect(() => {
    if (clientRaisedRequestsData.length) {
      if (search.length) {
        let arr = [...clientRaisedRequestsData].filter(
          item =>
            item?.user.email.toLowerCase().includes(search.toLowerCase()) ||
            item.user.name.toLowerCase().includes(search.toLowerCase()) ||
            item.title.toLowerCase().includes(search.toLowerCase())
        )
        setVisibleRequests(
          arr.splice(page * totalRequestsToShow, totalRequestsToShow)
        )
        // setPaginationTotalDataCount(arr?.length)
      } else {
        setVisibleRequests(
          [...clientRaisedRequestsData].splice(
            page * totalRequestsToShow,
            totalRequestsToShow
          )
        )
      }
    } else {
      setVisibleRequests([])
    }
  }, [clientRaisedRequestsData, search, page])

  useEffect(() => {
    if (!clientRaisedRequests.fetched) {
      dispatch(fetchClientRaisedRequests())
    }
  }, [clientRaisedRequests.fetched])

  useEffect(() => {
    if (assignToMe) {
      setClientRaisedRequestsData(
        clientRaisedRequests.data.filter(
          request =>
            (request.assignAgency?._id ?? request.assignAgency) === meData._id
        )
      )
    } else {
      setClientRaisedRequestsData(clientRaisedRequests.data)
    }
  }, [clientRaisedRequests, assignToMe])

  // const closeStatusPopup = () => {
  //   setAnchorEl(null)
  // }

  // const openStatusPopup = (e) => {
  //   setAnchorEl(e.currentTarget)
  // }

  // const handleStatusChange = (status) => {
  //   closeStatusPopup()
  //   handleUpdateRequest(currentRequest._id, { status })
  // }

  const handleUpdateRequest = (id, data) => {
    dispatch(updateRaisedRequest(id, data, handleUpdateRequestCallback))
  }

  const handleTaskModal = data => {
    if (taskModalOpen.open) {
      setTaskModalOpen({ open: false, data: {} })
    } else {
      setTaskModalOpen({ open: true, data: data })
    }
  }

  const handleUpdateRequestCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Updated Successfully'))
    }
  }

  const handleOpenReqModal = data => {
    setOpenReqModal({ open: true, data })
  }

  const handleCloseReqModal = () => {
    setOpenReqModal({ open: false, data: null })
  }

  const refreshData = () => {
    if (refreshing) return

    setRefreshing(true)
    dispatch(
      fetchClientRaisedRequests((err, res) => {
        setRefreshing(false)
      })
    )
  }

  const filterUserRequest = key => {
    if (key) {
      setAssignToMe(true)
      // setClientRaisedRequestsData(
      //   clientRaisedRequests.data.filter(
      //     (request) =>
      //       (request.assignAgency?._id ?? request.assignAgency) === meData._id
      //   )
      // )
    } else {
      setAssignToMe(false)
      // setClientRaisedRequestsData(clientRaisedRequests.data)
    }
  }

  const toggleCreateClientRequestModal = () => {
    if (createClientRequestModal.open) {
      setCreateClientRequestModal({ open: false, data: {} })
    } else {
      setCreateClientRequestModal(prev => ({ open: true, data: {} }))
    }
  }

  return (
    <div>
      {permission.data?.access?.clientRequest.view && (
        <div className="flex justify-end my-6 space-x-2">
          {permission.data.access?.clientRequest?.update && (
            <Button
              size="small"
              variant="outlined"
              onClick={toggleCreateClientRequestModal}
            >
              Create Request
            </Button>
          )}
          {[
            userRoles.USER_AGENCY,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER
          ].includes(meData.role) && (
            <Button
              variant="outlined"
              onClick={() => filterUserRequest(!assignToMe)}
              size="small"
            >
              {!assignToMe ? 'Assigned to me' : 'All'}
            </Button>
          )}
          <RefreshButton
            refreshing={refreshing}
            onClick={refreshData}
            size="small"
          />
        </div>
      )}

      {permission.loading || clientRaisedRequests.loading ? (
        <CancelRequestsSkeleton />
      ) : permission.data?.access?.clientRequest.view ? (
        <>
          <TableContainer mb={6}>
            <Thead>
              <Th>Title</Th>
              <Th>Assignee</Th>
              <Th>Priority</Th>
              <Th>Raised By</Th>
              <Th>Raised Date</Th>
              <Th>Status</Th>
              {permission.data.access.clientRequest.update && <Th center></Th>}
            </Thead>
            <Tbody>
              {visibleRequests.length ? (
                visibleRequests.map(item => (
                  <TableRow
                    key={item._id}
                    item={item}
                    // openStatusPopup={openStatusPopup}
                    // setCurrentRequest={setCurrentRequest}
                    handleTaskModal={handleTaskModal}
                    handleOpenReqModal={handleOpenReqModal}
                    canUpdate={permission.data.access.clientRequest.update}
                  />
                ))
              ) : (
                <Tr>
                  <Td colSpan={7}>There are no requests</Td>
                </Tr>
              )}
            </Tbody>
          </TableContainer>
          {clientRaisedRequestsData.length > totalRequestsToShow && (
            <Pagination
              handlePagination={setPage}
              totalResultsCount={clientRaisedRequestsData.length}
              visibleResultsCount={visibleRequests.length}
              page={page}
              limit={totalRequestsToShow}
            />
          )}

          {openReqModal.open && (
            <RequestDetailsModal
              open={openReqModal.open}
              handleClose={handleCloseReqModal}
              data={openReqModal.data}
              teammates={teammates}
              handleUpdateRequest={handleUpdateRequest}
              handleTaskModal={handleTaskModal}
              canUpdate={permission.data.access?.clientRequest?.update}
            />
          )}
          <RequestTaskModel
            open={taskModalOpen.open}
            handleDialog={handleTaskModal}
            data={taskModalOpen.data}
          />
          <RaiseRequestModal
            open={createClientRequestModal.open}
            handleClose={toggleCreateClientRequestModal}
            // data={createClientRequestModal.data}
            canUpdate={permission.data?.access?.clientRequest.update}
            elmFor="agency"
          />
        </>
      ) : (
        <div className="pt-4">
          <NoAccessUI />
        </div>
      )}
    </div>
  )
}

const NoAccessUI = () => {
  return (
    <div className="flex flex-col items-center gap-4 w-full h-full text-center">
      <div style={{ width: '300px', height: '200px' }}>
        <img
          src={NoAccess}
          alt="No Access"
          className="w-full h-full object-contain"
        />
      </div>
      <p className="text-gray-500">
        You do not have Permission to view client requests
      </p>
    </div>
  )
}

const TableRow = ({ item, handleTaskModal, handleOpenReqModal, canUpdate }) => {
  const handleRowClick = () => {
    if (canUpdate) {
      handleOpenReqModal(item)
    }
  }

  const handleConvertTask = () => {
    handleTaskModal(item)
  }

  return (
    <Tr>
      <Td onClick={handleRowClick} className="text-left cursor-pointer">
        <span className="text-primary-mid-dark font-medium">{item.title}</span>
      </Td>
      <Td className="text-xs">
        {item.assignAgency ? (
          <>
            <div>
              <span className="text-primary-mid-dark text-xs font-medium">
                {item.assignAgency?.name}
              </span>{' '}
              {item.role ? getRoleBadge(item.role) : ''}
            </div>
            <div className="text-primary-mid-dark-2 text-xs">
              {item.assignAgency?.email}
            </div>
          </>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {isNaN(item.priority) || item.priority === 5 ? (
          <span className="text-gray-400 text-xs">-</span>
        ) : (
          <CustomFlag priority={item.priority} />
        )}
      </Td>
      <Td>
        <span className="text-primary-mid-dark text-xs font-medium">
          {item.client?.name}
        </span>
        <p className="text-primary-mid-dark-2 text-xs">
          ({item.client?.email})
        </p>
      </Td>
      <Td>
        <time className="text-xs">
          {moment(item.createdAt).endOf('minute').fromNow()}
        </time>
      </Td>
      <Td>
        <Pill variant={pillVariantObj[item.status]}>{item.status}</Pill>
      </Td>
      {canUpdate && (
        <Td>
          <PopupMenu
            menuItems={[
              {
                label: 'View',
                method: handleRowClick
              },
              {
                label: 'Convert to task',
                method: handleConvertTask
              }
            ]}
          />
        </Td>
      )}
    </Tr>
  )
}

const CancelRequestsSkeleton = () => {
  return (
    <>
      <TableContainer mb={2}>
        <Thead>
          <Th>Title</Th>
          <Th>Assignee</Th>
          <Th>Priority</Th>
          <Th>Raised By</Th>
          <Th>Raised Date</Th>
          <Th>Status</Th>
          <Th></Th>
        </Thead>

        <Tbody>
          <TableDataLoading cols={7} />
        </Tbody>
      </TableContainer>
    </>
  )
}

export default ClientRequestsAgency
