import React, { useState, useEffect } from 'react'
import { LoadingButton } from 'global/globalComponents'
// import { Clientvenue_logo } from 'static/Images'
import { fetchFolders, createRootFolders } from 'thunks/fileAssets/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useParams
} from 'react-router-dom'
import FilesFolders from './FilesFolders'
import Folder from './Folder'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { Loader } from 'global/globalComponents'
import { userRoles } from 'utils'
import { ReactComponent as FolderOpen } from 'static/svg/folder-open.svg'
// import TaskAttachments from './TaskAttachments'
import TaskAttachmentsFolder from './TaskAttachmentsFolder'
// import { HistoryOutlined } from '@material-ui/icons'
import { fetchAllTaskAttachmentsByWorkspace } from 'thunks/task/actions'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import FilesAssetsBreadCrumbs from './FilesAssetsBreadCrumbs'
// import MoveFileModal from './MoveFileModal'

const FilesAssets = () => {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const { workspaceId } = useParams()
  const [loading, setLoading] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [activateLoading, setActivateLoading] = useState(false)
  const [allAttachments, setAllAttachments] = useState({
    loading: true,
    data: []
  })

  useEffect(() => {
    fetchAllTaskAttachmentsByWorkspace(workspaceId, (res, err) => {
      if (!err) {
        const mappedData = []

        for (let obj of res) {
          for (let url of obj.imageUrl) {
            mappedData.push({ _id: obj._id, title: obj.title, url: url })
          }
        }
        setAllAttachments({ loading: false, data: mappedData })
      } else {
        setAllAttachments({ loading: false, data: [] })
      }
    })
  }, [])

  useEffect(() => {
    dispatch(fetchFolders(workspaceId, '0', fetchFoldersCallback))
  }, [workspaceId])

  // Sending request to create root directories
  const activateFeature = () => {
    setActivateLoading(true)
    dispatch(createRootFolders(workspaceId, createRootCallback))
  }

  const createRootCallback = (res, err) => {
    if (!err) {
      setActivateLoading(false)
      setIsActive(true)
    }
  }

  const fetchFoldersCallback = (res, err) => {
    if (!err) {
      setIsActive(Boolean(res.length))
      setLoading(false)
    }
  }

  return (
    <div className="px-6 py-4 w-full h-full">
      {isActive ? (
        <Switch>
          <Route exact path={path}>
            <RootFolders />
            {/* <TaskAttachments
              loading={allAttachments.loading}
              allAttachments={allAttachments.data}
            /> */}
          </Route>
          <Route path={`${path}/task_attachments`}>
            <TaskAttachmentsFolder
              loading={allAttachments.loading}
              allAttachments={allAttachments.data}
            />
          </Route>
          <Route path={`${path}/:dirIds+`}>
            <FilesFolders displayLoader={setLoading} />
          </Route>
        </Switch>
      ) : (
        <FilesAssetsActivate
          activateFeature={activateFeature}
          loading={activateLoading}
        />
      )}
      {loading && (
        <div
          className="w-full h-full flex justify-center items-center section-loader absolute inset-0"
          style={{ background: '#f8f9f9' }}
        >
          <Loader />
        </div>
      )}
    </div>
  )
}

const RootFolders = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const rootData = useSelector(state => state.filesAssets.currentWSAssets.data)
  const meData = useSelector(state => state.me.data)
  // const dispatch = useDispatch()

  const handleFolderClick = id => {
    history.push(`${url}/${id}`)
  }

  const handleTaskAttachments = () => {
    history.push(`${url}/task_attachments`)
  }

  // const handler = () => {
  //   dispatch(fireErrorToaster('Root folders cannot be renamed or deleted!'))
  // }

  const elms = rootData['0'].folders
    // .filter((el) =>
    //   meData.role >= userRoles.USER_CLIENT &&
    //   el.name.toLowerCase() === 'private'
    //     ? false
    //     : true
    // )
    .map(item => (
      <Folder
        key={item._id}
        onClick={() => handleFolderClick(item._id)}
        name={item.name}
        className="text-primary-main"
        // folderOptions={[{label: "Rename", method: handler}, {label: }]}
        // onDelete={handler}
        // onRename={handler}
      />
    ))

  return (
    <div>
      <header className="mb-6">
        <SectionHeading>Root Folders</SectionHeading>
      </header>

      <div className="flex gap-4">
        {elms}
        <Folder
          onClick={handleTaskAttachments}
          name="Task Attachments"
          className="text-primary-main"
        />
      </div>
    </div>
  )
}

const FilesAssetsActivate = ({ activateFeature, loading }) => {
  const meData = useSelector(state => state.me.data)

  return (
    <div className="w-full h-5/6 flex justify-center items-center">
      <div className="text-center">
        {/* <div className="w-40 mx-auto"> */}
        {/* <img src={Clientvenue_logo} alt="logo" /> */}
        {/* </div> */}
        <FolderOpen className="w-20 h-20 text-primary-main mx-auto" />

        <div className="mb-6 mt-2">
          <h1 className="font-medium text-gray-700 text-lg md:text-xl lg:text-2xl">
            Files Storage
          </h1>
          <p className="text-gray-500 text-sm">
            {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
              meData.role
            )
              ? 'You can upload / create files and folders related to this project'
              : 'This feature is not active in this project'}
          </p>
        </div>

        {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
          meData.role
        ) && (
          <LoadingButton
            size="large"
            onClick={activateFeature}
            loading={loading}
          >
            Activate
          </LoadingButton>
        )}
      </div>
    </div>
  )
}

export default FilesAssets
