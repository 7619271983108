import React, { useMemo, useState } from 'react'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import Select from 'react-select'
import { updateClient } from 'thunks/addClient/actions'
import { Card, LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
// import clientListStyles from './ClientListStyles'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
// import { getRoleBadge } from 'utils'
// import Pill from 'global/globalComponents/Pill/Pill'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import Members from './Member'
import { getRoleBadge, userRoles } from 'utils'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import AddNewTeammatePanel from 'components/Teammates/AddNewTeammatePanel'

export default function TeamMembers({
  clientInfo,
  setClientInfo,
  elmFor = 'client'
}) {
  // const classes = clientListStyles()
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const clientData = useSelector((state) => state.userClients.currentClient)
  const allAgencyTeammates = useSelector((state) => state.userTeammates.data)
  const teammates = useSelector((state) => state.userTeammates.data)
  const representative = useMemo(() => {
    return teammates.find(
      (mate) => mate._id === clientData?.agencyRepresentative
    )
  }, [clientData?.agencyRepresentative, teammates])

  const [teammateSelectOptions, setTeammateSelectOptions] = useState([])
  const [
    updateRepresentativeDialogData,
    setUpdateRepresentativeDialogData
  ] = useState({
    isNew: false,
    open: false
  })
  const [selectedTeammate, setSeletedTeammate] = useState(undefined)
  const [savingChanges, setSavingChanges] = useState(false)
  const userPermission = useSelector((state) => state.permission.data)
  const [addClientTeammate, setAddClientTeammate] = useState(false)

  const handleOpenUpdateRepresentativeDialog = (isNew) => {
    setUpdateRepresentativeDialogData({
      isNew,
      open: true
    })
    const filteredArr = allAgencyTeammates.filter(
      (i) => i._id !== representative?._id
    )
    setTeammateSelectOptions(
      filteredArr.map((i) => ({ label: i.name, value: i._id }))
    )
  }

  const handleCloseUpdateRepresentativeDialog = () => {
    setUpdateRepresentativeDialogData({
      isNew: false,
      open: false
    })
  }

  const handleSelectTeammate = (e) => {
    setSeletedTeammate(e)
  }

  const handleSaveRepresentative = () => {
    setSavingChanges(true)
    dispatch(
      updateClient(
        clientData._id,
        {
          agencyRepresentative: selectedTeammate.value
        },
        saveRepresentativeCallback
      )
    )
  }

  const saveRepresentativeCallback = (res, err) => {
    setSavingChanges(false)
    handleCloseUpdateRepresentativeDialog()
    if (err) dispatch(fireErrorToaster(res))
    else dispatch(fireSuccessToaster('Changes Saved Successfully'))
  }

  const handleAddClientTeammate = () => {
    if (clientData?._id) {
      setAddClientTeammate(true)
    } else {
      dispatch(fireWarningToaster('Company representative is required!'))
    }
  }

  return (
    <div className="">
      {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
        meData.role
      ) && (
        <div className="text-right">
          <Button
            variant="outlined"
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleAddClientTeammate}
          >
            Add Client Teammate
          </Button>
        </div>
      )}
      {elmFor === 'client' && (
        <Wrapper className="my-8">
          <h5 className="font-medium text-xl mb-4 text-black">
            Account Manager
          </h5>
          <div className="flex justify-between items-start">
            {representative ? (
              <>
                <div>
                  <div
                    className="flex items-center"
                    style={{ minWidth: '300px' }}
                  >
                    <ColoredAvatars user={representative} size="medium" />

                    <p className="capitalize font-medium text-base text-primary-mid-dark ml-2 mr-4">
                      {representative.name}
                    </p>
                    {representative.role && getRoleBadge(representative.role)}
                  </div>
                </div>

                {userPermission.access?.clients?.update && (
                  <Button
                    variant="outlined"
                    startIcon={<EditIcon className="text-primary-main" />}
                    onClick={() => handleOpenUpdateRepresentativeDialog(false)}
                  >
                    Change Manager
                  </Button>
                )}
              </>
            ) : (
              <>
                <p className="text-base text-custom-gray-light-6 font-medium">
                  No manager is assigned
                </p>

                <Button
                  variant="outlined"
                  startIcon={<AddIcon fontSize="small" />}
                  loading={savingChanges}
                  onClick={() => handleOpenUpdateRepresentativeDialog(true)}
                >
                  Assign a Manager
                </Button>
              </>
            )}
          </div>
        </Wrapper>
      )}

      <Members
        clientData={clientData}
        clientInfo={clientInfo}
        setClientInfo={setClientInfo}
      />
      <AddNewTeammatePanel
        open={addClientTeammate}
        onClose={() => setAddClientTeammate(false)}
        elmFor="client"
        aboveModal={false}
        clientOwner={clientData?._id}
        setClientInfo={setClientInfo}
      />

      <CustomModal
        // classes={{ paper: classes.dialogPaper }}
        open={updateRepresentativeDialogData.open}
        handleClose={handleCloseUpdateRepresentativeDialog}
      >
        <CustomModalHeader heading=" Change Representative" />

        <CustomModalBody className="pb-4">
          <p className="mb-2">Select any Teammate to assign to this client</p>
          <Select
            menuPosition={'fixed'}
            onChange={handleSelectTeammate}
            options={teammateSelectOptions}
            styles={reactSelectCustomStyles}
            maxMenuHeight={150}
          />
        </CustomModalBody>
        <CustomModalFooter>
          <LoadingButton
            disabled={!selectedTeammate}
            loading={savingChanges}
            onClick={handleSaveRepresentative}
          >
            Save Changes
          </LoadingButton>
          <Button
            onClick={handleCloseUpdateRepresentativeDialog}
            variant="outlined"
          >
            Cancel
          </Button>
        </CustomModalFooter>
      </CustomModal>
    </div>
  )
}
