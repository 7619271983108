import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fireWarningToaster } from 'thunks/fireToaster/actions'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as LockIcon } from 'static/svg/lock.svg'
import ProjectCard from 'components/UserDashboard/Workspaces/ProjectsCardView/ProjectCard'
import clsx from 'clsx'
import useStyles from 'components/UserDashboard/Workspaces/ProjectsCardView/projectsCardViewStyles'
import { Skeleton } from '@material-ui/lab'
import TemplatesModal from 'components/Templates/TemplateModal'
import { userRoles } from 'utils'

const ProjectCardsPage = ({
  clientInfo,
  userWorkspaces,
  clientData,
  companyData,
  access,
  elmFor
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const [projectModalOpen, setProjectModalOpen] = useState(false)
  const clientWorkspaces = useMemo(() => {
    const clientAssignedWorkspaces = clientInfo.data.assignedWorkspace?.map(
      (el) => el._id
    )
    return userWorkspaces.filter((project) =>
      clientAssignedWorkspaces?.includes(project._id)
    )
  }, [clientInfo.data.assignedWorkspace, userWorkspaces])

  const openAddProjectModal = () => {
    if (elmFor === 'client') {
      setProjectModalOpen(true)
    } else {
      if (
        companyData.clientOwner &&
        companyData.clientOwner === clientData?._id
      ) {
        setProjectModalOpen(true)
      } else {
        dispatch(
          fireWarningToaster(
            'To create the project please assign the company representative'
          )
        )
      }
    }
  }

  return (
    <div className="mt-8">
      {!clientInfo.loading ? (
        access?.clients?.update ||
        [userRoles.AGENCY_ADMIN, userRoles.USER_AGENCY].includes(
          meData.role
        ) ? (
          <div className={clsx(classes.cardsContainer, 'mb-12')}>
            {clientWorkspaces.map((item) => (
              <ProjectCard
                key={item._id}
                workspaceData={item}
                elmFor="client"
              />
            ))}
            <div
              className="bg-custom-gray-light-7 border-2 border-dashed border-gray-400 hover:bg-gray-200 hover:shadow-md rounded-lg"
              // style={{ backgroundColor: '#f0f0f0' }}
            >
              <div
                className="flex flex-col justify-center items-center h-full w-full cursor-pointer text-gray-700 text-sm"
                onClick={openAddProjectModal}
                style={{ minHeight: 170 }}
              >
                <AddIcon className="h-7 w-7" />
                <span>New Project</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white shadow rounded-xl text-center py-8">
            <span className="inline-block text-primary-main">
              <LockIcon className="w-10 h-10" />
            </span>
            <p>You do not have permission to view projects</p>
          </div>
          // <div className="flex items-center justify-center w-full h-full">
          //   <div
          //     style={{ width: '100%', maxHeight: '300px' }}
          //     className="bg-white my-auto"
          //   >
          //     <img
          //       src={NoAccess}
          //       alt="No Access"
          //       className="w-full h-full object-contain"
          //       style={{
          //         maxWidth: '800px',
          //         maxHeight: '300px',
          //         margin: 'auto'
          //       }}
          //     />
          //   </div>
          // </div>
        )
      ) : (
        <div className={clsx(classes.cardsContainer, 'mb-12')}>
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
          <Skeleton variant="rect" style={{ height: '200px' }} />
        </div>
      )}
      <TemplatesModal
        open={projectModalOpen}
        onClose={() => setProjectModalOpen(false)}
        selectedClient={{
          ...clientData,
          label: clientData?.name,
          value: clientData?._id
        }}
        elmFor="client"
      />
    </div>
  )
}

export default ProjectCardsPage
