import axios from 'axios'
import { storeToken } from 'utils/userLogin'
import { LOGIN } from './reduers'
import { catchAsync } from 'utils'

/**
 * Agency user login action
 * @route `user/login`
 * @param {Object} data User data
 * @param {Function} callback Callback function. (response/errorMessage, error)
 */
export const agencyUserLogin = (data, callback) => {
  return catchAsync(async (dispatch) => {
    const res = await axios({
      method: 'POST',
      url: '/user/login',
      data
    })

    storeToken(res.data.token)
    dispatch({ type: LOGIN.LOGGED_IN })
    if (callback) callback(res.data, false)
  }, callback)
}
