import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, TextField, Button } from '@material-ui/core'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import { LoadingButton } from 'global/globalComponents'
import CategoryCard from './CategoryCard'
import userServiceStyles from './userServiceStyles'
import { createServiceCategory } from 'thunks/service/actions'
import { fileToDataUrl } from 'utils'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { useDispatch } from 'react-redux'
import { ReactComponent as LinkIcon } from 'static/svg/link.svg'

const CategoriesModal = ({
  open,
  onClose,
  serviceCategories,
  handleUpdateServiceCategory,
  openCategoryAlert,
  addNewCategory
}) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [categoryImg, setCategoryImg] = useState('')
  const formRef = useRef()
  const dispatch = useDispatch()

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleCreateCategory = async (e) => {
    e.preventDefault()
    const form = formRef.current
    const title = form.title.value.trim()
    const description = form.description.value.trim()

    if (!title || !description)
      return dispatch(fireErrorToaster('Please fill all the required fields!'))

    const match = serviceCategories.find(
      (item) => item.name.toLowerCase() === title.toLowerCase()
    )

    if (match)
      return dispatch(fireErrorToaster(`"${match.name}" already exists!`))

    setLoading(true)
    createServiceCategory(
      {
        name: title,
        description,
        image: categoryImg,
        type: 'service'
      },
      createServiceCallback
    )
  }

  const createServiceCallback = (res, err) => {
    setLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      addNewCategory(res)
      setCategoryImg('')
      formRef.current.reset()
      dispatch(fireSuccessToaster('Created new category'))
    }
  }

  const handleCloseModal = () => {
    setSelectedTab(0)
    setLoading(false)
    setCategoryImg('')
    onClose()
  }

  return (
    <CustomModal size="large" open={open} handleClose={handleCloseModal}>
      <CustomModalHeader
        heading="Categories"
        handleClose={handleCloseModal}
        icon={
          <LinkIcon
            width={40}
            height={40}
            className="text-primary-main bg-custom-blue-light rounded-lg p-3"
          />
        }
      />
      <CustomModalBody>
        <div className="mb-6">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            {['Create New', 'Edit Category'].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
        </div>

        {selectedTab === 0 ? (
          <CreateCategory
            ref={formRef}
            handleCreateCategory={handleCreateCategory}
            categoryImg={categoryImg}
            setCategoryImg={setCategoryImg}
          />
        ) : (
          <EditCategory
            serviceCategories={serviceCategories}
            handleUpdateServiceCategory={handleUpdateServiceCategory}
            openCategoryAlert={openCategoryAlert}
          />
        )}
      </CustomModalBody>

      <CustomModalFooter>
        <Button variant="outlined" onClick={handleCloseModal}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleCreateCategory}
          disabled={Boolean(selectedTab)}
        >
          Create
        </LoadingButton>
      </CustomModalFooter>
    </CustomModal>
  )
}

const CreateCategory = React.forwardRef(
  ({ handleCreateCategory, categoryImg, setCategoryImg }, ref) => {
    const imageLoadHandler = (e) => {
      const file = e.target.files[0]

      if (file) {
        fileToDataUrl(file).then((res) => {
          setCategoryImg(res)
          e.target.value = ''
        })
      }
    }

    return (
      <form ref={ref} onSubmit={handleCreateCategory}>
        <div className="mb-4">
          <FormLabel htmlFor="categoryTitle" required>
            Title
          </FormLabel>

          <TextField
            className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
            placeholder="Enter title"
            id="title"
            name="title"
          />
        </div>
        <div className="mb-4">
          <FormLabel htmlFor="categoryDesc" required>
            Description
          </FormLabel>

          <TextField
            className="hover:border-blue-500 w-full border border-custom-gray-main p-2 rounded-md outline-none"
            placeholder="Enter description"
            id="categoryDesc"
            multiline={true}
            rows={2}
            name="description"
          />
        </div>
        <div className="mb-4 flex gap-4">
          <div>
            <FormLabel>Add Image</FormLabel>

            <FormLabel htmlFor="categoryImg">
              <Button component="span">Choose File</Button>
            </FormLabel>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="categoryImg"
              multiple={false}
              type="file"
              name="image"
              onChange={imageLoadHandler}
            />
          </div>
          <div>
            {categoryImg && (
              <img
                src={categoryImg}
                alt=""
                className="w-40 h-32 object-contain rounded"
              />
            )}
          </div>
        </div>
      </form>
    )
  }
)

const EditCategory = ({
  serviceCategories,
  handleUpdateServiceCategory,
  openCategoryAlert
}) => {
  const classes = userServiceStyles()
  const [categories, setCategories] = useState(serviceCategories)

  useEffect(() => {
    setCategories(serviceCategories)
  }, [serviceCategories])

  return (
    <div>
      {categories.length ? (
        <div className={classes.categoriesWrapper}>
          {categories.map((item) => (
            <CategoryCard
              key={item._id}
              data={item}
              handleUpdateServiceCategory={handleUpdateServiceCategory}
              openCategoryAlert={openCategoryAlert}
            />
          ))}
        </div>
      ) : (
        <div className="w-full h-52 flex items-center justify-center">
          <p className="text-2xl text-gray-400 text-center">
            No Categories Found!
          </p>
        </div>
      )}
    </div>
  )
}

export default CategoriesModal
