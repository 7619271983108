import { makeStyles } from '@material-ui/core'

export default makeStyles({
  card: {
    width: 180,
    height: 160
  },
  newTemplateWrapper: {
    maxWidth: 800,
    margin: '0 auto'
  },
  categoryWrapper: {
    background: 'rgba(110, 110, 110, 0.28)',
    zIndex: 9999,
    backdropFilter: 'blur(2px)'
  },
  stepsCardWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: 120,
    gap: 16,
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  stepsCard: {
    transition: 'all 150ms ease-in-out',
    '& svg': {
      width: 40,
      height: 40
    },

    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: `0 0 10px rgba(0, 0, 0, 0.15)`
    }
  },
  instruction: {
    '& button': {
      opacity: 0,
      transition: 'opacity 100ms ease'
    },

    '&:hover button': {
      opacity: 1
    }
  }
})
