import axios from 'axios'
import { fetchMeData } from 'thunks/me/actions'
import { USER } from 'thunks/user/reducers'
import { errorMessages } from 'utils'
import { ME } from '../me/reducers'

/**
 * Fetch data of user
 * @param {Function} callback function will run after fetch. Recieve (err/response, error status) as arguments
 */
export const whitelabel = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/agencyprofile/agency/whitelabel/${id}`,
        data
      })
      dispatch({ type: USER.UPDATE_PROFILE_DATA, payload: res.data })
      // dispatch(fetchMeData())
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: USER.ERROR, payload: err.response })
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * update whitelabel url for agency
 * @param {String} id user id
 * @param {data} data  oldWhitelabelUrl,WhitelabelUrl
 * @param {Function} callback function will run after fetch. Recieve (err/response, error status) as arguments
 */

export const whitelabelSubDomain = (id, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: ME.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/whitelabelurl/${id}`,
        data
      })
      dispatch({
        type: ME.UPDATE_AGENCY_DOMAIN,
        payload: res.data.whitelabelUrl
      })
      if (callback) callback(res.data, false)
    } catch (err) {
      dispatch({ type: ME.ERROR, payload: err.response })
      if (callback) callback(err, true)
    }
  }
}
