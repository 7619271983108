import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import globalCustomStyles from 'global/stlyeClasses/GlobalCustomStyles'

const OptionsButton = ({ onClick, children, bgColor }) => {
  const classes = globalCustomStyles()
  return (
    <div
      role="button"
      className={`inline-flex text-white rounded shadow ${
        bgColor ? bgColor : 'bg-primary-main'
      }`}
      onClick={onClick}
    >
      <div
        className={`self-center px-2 py-1 border-r-2 border-white-400 capitalize ${classes.btnLeft}`}
        // style={{ width: '75px' }}
      >
        {children}
      </div>
      <div className="py-1 px-1">
        <ArrowRightIcon fontSize="small" />
      </div>
    </div>
  )
}

export const statusColors = {
  pending: 'bg-yellow-400 text-white',
  resolved: 'bg-primary-main text-white',
  rejected: 'bg-red-400 text-white',
  closed: 'bg-gray-800 text-white'
}

export default OptionsButton
