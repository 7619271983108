import clsx from 'clsx'

export const FieldLabel = (props) => {
  const { className, children, ...restProps } = props
  return (
    <label
      {...restProps}
      className={clsx(
        'block text-xs text-gray-700 font-bold whitespace-nowrap',
        className
      )}
    >
      {children}
    </label>
  )
}

export default FieldLabel
