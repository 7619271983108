import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useWorkspacePermission } from 'customHooks'
import { createSubTask, updateSubTaskDetails } from 'thunks/subTask/actions'
import { FieldLabel } from './TaskPanel'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { CustomFlag, CustomTooltip } from 'global/globalComponents'
import { ColoredAvatarGroup } from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { Add, CheckCircle } from '@material-ui/icons'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { format } from 'date-fns'
import { Button } from '@material-ui/core'
// import { useParams } from 'react-router-dom'

const Subtasks = ({
  workspaceId,
  boardId,
  taskId,
  // taskStatuses,
  taskTitle,
  handleSubTaskClick,
  subtaskPermission,
  socketId,
  currentWorkspace,
  currentBoard
}) => {
  const allSubtasks = useSelector((state) => state.subTasks.data)
  // const { boardId } = useParams()

  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  // const workspacePermission = useWorkspacePermission(currentWorkspace?._id)
  const dispatch = useDispatch()
  const [addSubtask, setAddSubtask] = useState(false)
  const [subtask, setSubtask] = useState('')
  const [loading, setLoading] = useState(false)

  const handleAddSubtaskClick = () => {
    setAddSubtask(true)
  }

  const handleCreateSubtask = (e) => {
    e.preventDefault()

    if (subtask.length > 0) {
      setLoading(true)
      dispatch(
        createSubTask(
          {
            // user: taskData.user,
            workSpace: workspaceId,
            workspaceBoard: boardId,
            title: subtask.trim(),
            task: taskId,
            taskSection: currentBoard.boardSection[0]._id,
            socketId: socketId
          },
          taskTitle,
          createSubTaskCallback
        )
      )
    } else {
      setAddSubtask(false)
    }
  }

  const createSubTaskCallback = (res, err) => {
    setLoading(false)

    if (!err) {
      setAddSubtask(false)
      setSubtask('')
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  const handleSubtaskChange = (e) => {
    setSubtask(e.target.value)
  }

  const updateSubtaskStatus = (subtaskId, data) => {
    dispatch(
      updateSubTaskDetails(
        subtaskId,
        {
          ...data,
          workSpace: currentWorkspace._id
        },
        updateSubtaskCallback
      )
    )
  }

  const updateSubtaskCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    }
  }

  return (
    <>
      <div className="px-6 pt-2">
        <div className="mb-2">
          <FieldLabel>Subtasks</FieldLabel>
        </div>
        <div>
          {allSubtasks?.map((item, idx, arr) => (
            <SingleSubtask
              item={item}
              key={item._id}
              total={arr.length}
              index={idx}
              subtaskPermission={subtaskPermission}
              currentWorkspace={currentWorkspace}
              currentBoard={currentBoard}
              // taskData={taskData}
              taskTitle={taskTitle}
              handleSubTaskClick={handleSubTaskClick}
              updateSubtaskStatus={updateSubtaskStatus}
            />
          ))}
        </div>
      </div>
      <div>
        {addSubtask && (
          <div className="bg-blue-50 px-8">
            <form onSubmit={handleCreateSubtask}>
              <div className="flex h-8 items-center justify-between">
                <div className="flex-1">
                  <input
                    className="w-full text-smaller"
                    type="text"
                    onBlur={handleCreateSubtask}
                    onChange={handleSubtaskChange}
                    autoFocus
                    style={{ background: 'transparent' }}
                  />
                </div>

                <button
                  className="border rounded text-xs py-1 px-2 border-primary-main text-primary-main font-medium hover:bg-primary-main hover:text-white w-12 text-center ml-4"
                  disabled={loading}
                >
                  {loading ? '...' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        )}
        <div className="mt-2 px-6">
          <Button
            variant="outlined"
            startIcon={<Add fontSize="small" />}
            size="small"
            onClick={handleAddSubtaskClick}
            disabled={!subtaskPermission.create || currentWorkspace?.isComplete}
          >
            Add subtask
          </Button>
        </div>
      </div>
    </>
  )
}

const SingleSubtask = ({
  item,
  index,
  total,
  // workspacePermission,
  subtaskPermission,
  currentWorkspace,
  currentBoard,
  // taskData,
  taskTitle,
  handleSubTaskClick,
  updateSubtaskStatus
}) => {
  const dispatch = useDispatch()
  const [subtask, setSubtask] = useState({ ...item })
  const collaborators = useMemo(() => {
    return [...subtask.assignedAgencyTeam, ...subtask.assignedClientTeam]
  }, [subtask.assignedAgencyTeam, subtask.assignedClientTeam])
  const subtaskStatus = useMemo(() => {
    return (
      currentBoard.boardSection?.map((item) => item._id === item.taskSection) ??
      []
    )
  }, [item.taskSection])

  useEffect(() => {
    setSubtask({ ...item })
  }, [item])

  const handleSubtaskClick = () => {
    if (currentWorkspace?.isComplete) {
      dispatch(
        fireErrorToaster(
          `${taskTitle} belongs to a complete project and you can not set its status.`
        )
      )
    } else {
      subtaskPermission.update || subtaskPermission.view
        ? handleSubTaskClick(item)
        : dispatch(fireErrorToaster('You are not an authorized user'))
    }
  }

  const handleToggleComplete = (e) => {
    e.stopPropagation()
    let data
    if (!item.markAsComplete) {
      const completeSection = item.statuses?.find(
        (item) => item.label.toLowerCase() === 'completed'
      )
      data = { markAsComplete: true }
      if (completeSection) {
        data = { ...data, taskSection: completeSection._id }
      }
      updateSubtaskStatus(subtask._id, data)
      setSubtask((prev) => ({
        ...prev,
        ...data
      }))
    }
  }

  return (
    <div
      className={`flex flex-row border-t h-9 border-gray-200 items-center px-2 justify-between cursor-pointer ${
        total - 1 === index ? 'border-b' : ''
      }`}
      onClick={handleSubtaskClick}
    >
      <div className="flex items-center justify-start overflow-x-hidden">
        <div className="leading-none">
          <button onClick={handleToggleComplete}>
            {subtaskStatus?.label?.toLowerCase() === 'completed' ||
            subtask.markAsComplete ? (
              <CustomTooltip placement="top" title="Complete">
                <CheckCircle
                  fontSize="small"
                  className="mr-2 text-primary-main"
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip placement="top" title="Mark complete">
                <CheckCircleIcon className="mr-2 text-gray-600 hover:text-primary-main w-4 h-4" />
              </CustomTooltip>
            )}
          </button>
        </div>
        <p
          className={`text-smaller truncate ${
            subtaskStatus?.label?.toLowerCase() === 'completed' ||
            subtask.markAsComplete
              ? 'text-gray-400'
              : ''
          }`}
        >
          {subtask.title}
        </p>
      </div>
      <div className="flex flex-row items-center">
        {subtask.priority && subtask.priority !== 5 && (
          <div className="mr-2">
            <CustomFlag priority={subtask.priority} />
          </div>
        )}
        <ColoredAvatarGroup users={collaborators} size="small" />
        <p className="self-center ml-2 text-xs text-custom-gray-main">
          {format(new Date(subtask.createdAt), 'MMM d')}
        </p>
      </div>
    </div>
  )
}

export default React.memo(Subtasks)
