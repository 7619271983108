import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import useTitle from 'customHooks/useTitle'
import { LoadingButton } from 'global/globalComponents'
import clientVenueAssets from 'static/clientVenueAssets'
import { getAgencyInfoByDomain } from 'thunks/user/actions'
import { PatternScreen, RoundedTextInput } from 'global/globalComponents'
import { checkDomainAvailability } from 'thunks/passwordAndDomain/actions'

const Domain = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [agencyInfo, setAgencyInfo] = useState({
    brandName: '',
    favicon: '',
    smallLogo: '',
    largeLogo: ''
  })
  const [domain, setDomain] = useState()

  useTitle(agencyInfo.brandName, agencyInfo.favicon)

  useEffect(() => {
    fetchAgencyInfo()
  }, [])

  const domainHandler = async (e) => {
    e.preventDefault()

    if (!domain) return dispatch(fireErrorToaster('Please enter your domain!'))

    setLoading(true)
    checkDomainAvailability(domain, proceedDomainCallback)
  }

  const proceedDomainCallback = (res, err) => {
    setLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
      return
    }

    if (res.domainExist) {
      dispatch(fireSuccessToaster('Redirecting to you domain...'))
      window.open(`https://${domain}.clientvenue.com`, '_self')
    } else {
      dispatch(fireErrorToaster(`${domain} domain does not exist.`))
    }
  }

  const fetchAgencyInfo = async () => {
    try {
      const res = await getAgencyInfoByDomain()
      setAgencyInfo(res?.data?.brand ?? clientVenueAssets)
    } catch (err) {
      setAgencyInfo(clientVenueAssets)
    }
  }

  const formInputHandler = (e) => {
    setDomain(e.target.value.split('.')[0])
  }

  return (
    <PatternScreen>
      <div>
        <header className="mb-8">
          {/* {agencyInfo.largeLogo && (
            <img
              src={agencyInfo.largeLogo?.replace('s3.wasabisys.com/', '')}
              alt="logo"
              target="_self"
              style={{
                height: '3rem',
                margin: 'auto'
              }}
            />
          )} */}

          <h1 className="text-4xl font-bold">
            Sign in to your <br />
            <span className="text-primary-main">account</span>
          </h1>
        </header>

        <div>
          <form onSubmit={domainHandler}>
            <label
              htmlFor="agencyDomain"
              className="inline-block text-sm font-medium tracking-wide mb-2"
            >
              Agency Domain
            </label>
            <div className="flex">
              <RoundedTextInput
                id="agencyDomain"
                placeholder="Domain Name"
                type="text"
                name="domain"
                onChange={formInputHandler}
                value={domain}
              />
              <p className="self-center text-gray-500">.clientvenue.com</p>
            </div>
            <div className="mt-8">
              <LoadingButton loading={loading} type="submit" className="w-full">
                Proceed to your domain
              </LoadingButton>
            </div>
          </form>

          <p className="text-xs mt-2">
            Not registered yet?{' '}
            <Link to="/signup" className="text-primary-main font-bold">
              Signup here
            </Link>
          </p>

          <p className="text-xs mt-1">
            Forgot domain?{' '}
            <Link to="/forgot-domain" className="text-primary-main font-bold">
              Click here
            </Link>
          </p>
        </div>
      </div>
    </PatternScreen>
  )
}

export default Domain
