import React from 'react'
import { ReactComponent as LockIcon } from 'static/svg/lock.svg'
import clsx from 'clsx'
import { Button, makeStyles } from '@material-ui/core'
import { LockOpen } from '@material-ui/icons'
import AgencPlansModal from 'components/AgencyPlans/AgencPlansModal'
import { toggleUpgradeModal } from 'thunks/generlAppState/actions'
import { useDispatch, useSelector } from 'react-redux'

const LockedFeatureOverlay = props => {
  const { className = '', fullScreen = true, active = false } = props
  const sideDrawerOpen = useSelector(
    state => state.generalAppState.sideDrawerOpen
  )
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleCloseDialog = () => {
    dispatch(toggleUpgradeModal())
  }

  return (
    <>
      {active && (
        <div
          className={clsx('h-full top-0 p-6 z-50', className, {
            absolute: !fullScreen,
            fixed: fullScreen,
            [classes.wrapper]: sideDrawerOpen,
            [classes.wrapperExpanded]: !sideDrawerOpen
          })}
          style={{ backgroundColor: '#808080d9' }}
        >
          <div className="flex items-center justify-center flex-col h-full">
            <span className="rounded-full bg-gray-800 p-4">
              <LockIcon
                style={{ fill: '#fff' }}
                className="h-10 w-10 text-white"
              />
            </span>
            <div className="mt-8 text-center rounded p-6  bg-gray-800">
              {/* <div
                style={{ filter: 'blur(4px)' }}
                className="absolute  w-full h-full left-0 top-0"
              ></div> */}
              <p className="font-medium text-lg text-white">
                Your current plan doesn't include access to this feature
              </p>
              <p className="text-white">
                Upgrade your plan to use this feature.
              </p>
              <div className="text-center mt-6 pb-4">
                <Button onClick={handleCloseDialog} startIcon={<LockOpen />}>
                  Unlock This Feature
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <AgencPlansModal />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    left: theme.custom.sidebar.width + theme.custom.sideDrawer.width,
    width: `calc(100vw - ${
      theme.custom.sidebar.width + theme.custom.sideDrawer.width
    }px)`
  },
  wrapperExpanded: {
    left: theme.custom.sidebar.width,
    width: `calc(100vw - ${theme.custom.sidebar.width}px)`
  }
}))

export default LockedFeatureOverlay
