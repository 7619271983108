import React from 'react'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import { Card } from 'global/globalComponents'

const TotalTimeByTask = ({ loading, tasks, openTaskPanel }) => {
  return (
    <Card className="h-full p-4">
      <header className="flex items-center mb-2">
        <h4 className="text-sm font-semibold text-primary-dark">
          Time Taken Task Wise
        </h4>
        <QuestionMarkTooltip
          text="Only completed tasks are shown here"
          direction="right"
          toolTipstyles="pl-1"
        />
        <span className="w-8 h-8 rounded-full border inline-flex justify-center items-center ml-2 text-primary-mid-dark">
          {tasks.length}
        </span>
      </header>

      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div className="text-sm">
          {tasks.length ? (
            <>
              <div
                className={clsx(
                  'px-2 pb-3 pt-1 flex border-b border-gray-100 justify-between whitespace-nowrap'
                )}
              >
                <div className="text-gray-900 font-medium text-xs">Tasks</div>
                <div className="text-gray-900 font-medium text-xs">
                  Tracked Time
                </div>
              </div>

              <ul
                className="pt-1"
                style={{ maxHeight: '230px', overflow: 'scroll' }}
              >
                {tasks
                  .sort(
                    (a, b) =>
                      new Date(b.completedAt).getTime() -
                      new Date(a.completedAt).getTime()
                  )
                  // .slice(0, 5)
                  .map(item => (
                    <li
                      key={item._id}
                      className={clsx(
                        'p-2 flex text-gray-700 text-xs lg:text-smaller mb-1 justify-between rounded cursor-pointer hover:bg-gray-100'
                      )}
                      onClick={openTaskPanel.bind(this, item, 'completed')}
                    >
                      <div className="flex-1 overdue-task-title">
                        {item.title}
                      </div>
                      <div
                        className="text-gray-700 ml-4 mr-2 text-xs"
                        // style={{
                        //   color:
                        //     item.dueDate &&
                        //     new Date(item.completedAt).getTime() -
                        //       new Date(item.dueDate).getTime() >
                        //       0
                        //       ? 'red'
                        //       : 'green'
                        // }}
                      >
                        {item.timerDuration || item.subtaskTimerDuration
                          ? getTimeString(
                              item.timerDuration / 1000 +
                                item.subtaskTimerDuration / 1000
                            )
                          : '0s'}
                      </div>
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <div className="text-gray-500">No completed tasks found</div>
          )}
        </div>
      )}
    </Card>
  )
}

const getTimeDifference = task => {
  let timeDiff = null
  if (task.startDate) {
    timeDiff =
      new Date(task.completedAt).getTime() - new Date(task.startDate).getTime()
  } else {
    timeDiff =
      new Date(task.completedAt).getTime() - new Date(task.createdAt).getTime()
  }
  return convertTimeToString(timeDiff)
}

const convertTimeToString = timeDiff => {
  let time = null

  let diff = Math.round(timeDiff / 3600000)

  if (diff < 24) {
    time = `${diff}h`
  } else {
    diff = Math.round(diff % 24)
    const day = Math.round(timeDiff / (3600 * 1000 * 24)).toFixed(0)
    time = `${day}d ${diff}h`
  }
  return time
}

const getTimeString = time => {
  if (time === 0) return 0
  const hours = Math.floor(time / (60 * 60))
  const minutes = Math.floor(Math.floor(time % (60 * 60)) / 60)
  const seconds = Math.ceil(Math.floor(time % (60 * 60)) % 60)
  if (hours !== 0) {
    return minutes !== 0 ? `${hours}h ${minutes}m` : `${hours}h`
  } else if (hours === 0 && minutes === 0) {
    return `${seconds}s`
  } else {
    return `${minutes}m`
  }

  // if (hours !== 0) {
  //   return minutes !== 0
  //     ? seconds !== 0
  //       ? `${hours}h ${minutes}m ${seconds}s`
  //       : `${hours}h ${minutes}m`
  //     : `${hours}h`
  // } else if (hours === 0 && minutes === 0) {
  //   return `${seconds}s`
  // } else {
  //   return seconds === 0 ? `${minutes}m` : `${minutes}m ${seconds}s`
  // }
}

const LoadingSkeleton = () => {
  return (
    <>
      {[
        Array(4)
          .fill('')
          .map(item => <Skeleton variant="rect" height={20} className="mb-4" />)
      ]}
    </>
  )
}

export default TotalTimeByTask
