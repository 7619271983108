import React, { useEffect, useState } from 'react'
import { Popover } from '@material-ui/core'
import CellWrapper from './CellWrapper'
import { ReactComponent as PhoneAlt } from 'static/svg/phone-alt.svg'
import { stopEventPropagation } from 'utils'

const PhoneCell = ({
  value,
  row: { index, values, original },
  column: { id },
  updateDynamicColData,
  taskPermission
}) => {
  const [anchorEl, setAnchorEl] = useState()
  const [phoneNumber, setPhoneNumber] = useState(value ?? '')
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (value && value !== phoneNumber) {
      setPhoneNumber(value)
    }
  }, [value])

  const handleOpenPopup = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleNumberChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleClose = () => {
    console.log('Yeah!!!')
    setPhoneNumber('')
    setAnchorEl(null)
  }

  const handleUpdateData = (e) => {
    e.preventDefault()

    if (value === phoneNumber) return
    updateDynamicColData({
      rowIndex: index,
      columnId: id,
      value: phoneNumber,
      allRowValues: values,
      taskId: original.taskId
    })
    handleClose()
  }

  const handleClick = () => {
    setClicked(true)
  }

  return (
    <>
      <CellWrapper
        className="px-2"
        styles={{ minWidth: 180 }}
        onClick={taskPermission.update ? handleOpenPopup : undefined}
      >
        {phoneNumber && (
          <div
            className="flex justify-between items-center show-on-hover-parent w-full h-full"
            onClick={handleClick}
          >
            <span className="truncate">{phoneNumber}</span>
            <a
              href={`tel:${phoneNumber}`}
              onClick={stopEventPropagation}
              className="inline-block border px-2 py-1 rounded ml-2 text-gray-300 show-on-hover-child"
            >
              <PhoneAlt />
            </a>
          </div>
        )}
        {!clicked && (
          <div
            style={{ minHeight: 'inherit' }}
            className="w-full h-full show-on-hover-parent flex justify-center items-center"
            onClick={handleClick}
          >
            <PhoneAlt className="w-4 h-4 show-on-hover-child" />
          </div>
        )}
      </CellWrapper>
      {clicked && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <div className="w-60 bg-white rounded">
            <form onSubmit={handleUpdateData}>
              <input
                type="number"
                value={phoneNumber}
                onChange={handleNumberChange}
                className="w-full border resize-none rounded p-2 text-sm"
                autoFocus
                onBlur={handleUpdateData}
              />
            </form>
          </div>
        </Popover>
      )}
    </>
  )
}

export default React.memo(PhoneCell)
