import React from 'react'
// import FolderIcon from '@material-ui/icons/Folder'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
// import { useDispatch } from 'react-redux'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { CustomPopper } from 'global/globalComponents'
import { PopupMenu } from 'global/globalComponents'
import { ReactComponent as VerticalThreeDot } from 'static/svg/verticalThreeDot.svg'
import { ReactComponent as FolderIcon } from 'static/svg/folder.svg'
import FilesAssetsStyles from './filesAssetsStyles'
// import { LinearProgress } from '@material-ui/core'

const Folder = ({ name, onClick, folderOptions = [], canUpdate }) => {
  const classes = FilesAssetsStyles()
  // const elRef = useRef()
  // const [menu, setMenu] = useState({
  //   show: false,
  //   xPos: 0,
  //   yPos: 0
  // })
  // const [anchorEl, setAnchorEl] = useState(null)

  // useEffect(() => {
  //   if (elRef.current) {
  //     elRef.current.addEventListener('contextmenu', displayContextMenu)
  //   }

  //   return () => {
  //     if (elRef.current) {
  //       elRef.current.removeEventListener('contextmenu', displayContextMenu)
  //     }
  //   }
  // }, [])

  // const displayContextMenu = (e) => {
  //   e.preventDefault()
  //   const scrollY = Math.floor(window.scrollY)
  //   setMenu({
  //     show: true,
  //     xPos: `${e.pageX}px`,
  //     yPos: `${e.pageY - scrollY}px`
  //   })
  // }

  // const hideContextMenu = () => {
  //   setTimeout(() => {
  //     setMenu((prev) => ({ ...prev, show: false }))
  //   }, 500) // adding a little delay, so that when user clicks on a list item, the onClick ev executes
  // }

  // const toggleOptionsPopup = () => {
  //   setAnchorEl((prev) => !prev)
  // }

  return (
    <div
      tabIndex="0"
      className={clsx(
        'w-52 rounded-xl p-4 cursor-pointer bg-white relative',
        classes.folder
      )}
      onClick={onClick}
    >
      <FolderIcon
        className={clsx('text-primary-main w-10 h-10 mb-1', classes.folderIcon)}
      />
      <div className="font-semibold text-smaller lg:text-sm">{name}</div>
      {/* <LinearProgress
        variant="determinate"
        value={50}
        className="mt-2 rounded h-4"
        style={{ height: 6 }}
      /> */}
      {Boolean(folderOptions.length) && canUpdate && (
        <PopupMenu
          menuItems={folderOptions}
          targetComp={
            <button className="absolute top-4 right-4 ">
              <VerticalThreeDot />
            </button>
          }
        />
      )}
    </div>
  )
}

// const Menu = ({ show, xPos, yPos, id, name, onRename, onDelete }) => {
//   const handleRename = (e) => {
//     e.stopPropagation()
//     onRename({
//       id,
//       name,
//       actionType: 'edit'
//     })
//   }

//   const handleDelete = (e) => {
//     e.stopPropagation()
//     onDelete(id)
//   }

//   if (!show) return null
//   return (
//     <div
//       className="bg-white shadow-lg rounded-lg fixed"
//       style={{ left: xPos, top: yPos }}
//     >
//       <List>
//         <ListItem onClick={handleRename} button>
//           <ListItemText primary={'Rename'} />
//         </ListItem>
//         <ListItem onClick={handleDelete} button>
//           <ListItemText primary={'Delete'} />
//         </ListItem>
//       </List>
//     </div>
//   )
// }

// const useStyles = makeStyles((theme) => ({
//   folder: {
//     transition:
//       'transform 300ms ease-in-out, box-shadow 300ms ease-in-out, background-color 300ms ease-in-out',

//     '& button': {
//       color: '#aaa'
//     },

//     '&:hover, &:focus, &:focus-within': {
//       background: theme.palette.primary.main,
//       color: '#fff',
//       transform: 'scale(1.05)',
//       boxShadow: '0 0 10px rgba(0, 0, 255, 0.25)'
//     },
//     '&:hover button, &:focus button, &:focus-within button': {
//       color: '#fff'
//     },
//     '&:hover $folderIcon, &:focus $folderIcon, &:focus-within $folderIcon': {
//       color: '#fff'
//     }
//   },
//   folderIcon: {
//     fill: '#ffd734'
//   }
// }))

export default Folder
