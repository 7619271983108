import { IconButton, Popover, Badge } from '@material-ui/core'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
// import { userRoles } from 'utils'
import headerStyles from '../Header/headerStyles'
import { ReactComponent as Bell } from 'static/svg/bell.svg'
import {
  CustomTooltip,
  ColoredAvatars,
  CustomPopper
} from 'global/globalComponents'
import {
  fetchAccountLogsByUser,
  updateLogsView
} from 'thunks/accountLogs/action'
import { getUserPath } from 'utils/userRoles'
import clsx from 'clsx'

const getTimeDifference = (timestamp) => {
  const difference = Date.now() - new Date(timestamp)
  return Math.floor(difference / (1000 * 60 * 60))
}

const AccountNotifications = () => {
  const meData = useSelector((state) => state.me.data)
  // const userAccess = useSelector((state) => state.permission.data?.access)
  const dispatch = useDispatch()
  const accountLogs = useSelector((state) => state.accountLogs)
  const [notificationsMenuAnchor, setRequestsMenuAnchor] = useState(null)
  const classes = headerStyles()

  const handleOpenRequestsMenu = (e) => {
    setRequestsMenuAnchor(e.currentTarget)
    updateLogs()
  }

  const updateLogs = () => {
    let data = accountLogs.data
      .filter((item) => !item.readBy?.includes(meData._id))
      .map((el) => el._id)
    if (data.length) {
      dispatch(updateLogsView({ readNotification: data }))
    }
  }

  const handleCloseNotificationsMenu = () => {
    setRequestsMenuAnchor(null)
  }

  useEffect(() => {
    if (!accountLogs?.fetched) {
      const d = new Date()
      const startDate = new Date(d.setDate(d.getDate())).toISOString()
      const endDate = new Date(d.setDate(d.getDate() - 3)).toISOString()
      dispatch(fetchAccountLogsByUser(startDate, endDate))
    }
  }, [])

  return (
    <>
      {!accountLogs.loading && (
        <div className="mr-4">
          <CustomTooltip title="Notifications">
            <Badge
              badgeContent={
                accountLogs.data?.filter(
                  (el) => !el.readBy?.includes(meData._id)
                )?.length
              }
              classes={{ badge: classes.notifBadge }}
              max={9}
            >
              <button
                onClick={handleOpenRequestsMenu}
                disabled={accountLogs.loading}
              >
                <Bell className="w-6 h-6 text-primary-main" />
              </button>
            </Badge>
          </CustomTooltip>
        </div>
      )}

      {Boolean(notificationsMenuAnchor) && (
        <CustomPopper
          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'right'
          // }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'right'
          // }}
          // open={Boolean(notificationsMenuAnchor)}
          anchorEl={notificationsMenuAnchor}
          onClose={handleCloseNotificationsMenu}
        >
          <Notifications
            handleCloseNotificationsMenu={handleCloseNotificationsMenu}
            notifications={accountLogs.data}
          />
        </CustomPopper>
      )}
    </>
  )
}

const Notifications = ({ handleCloseNotificationsMenu, notifications }) => {
  const meData = useSelector((state) => state.me.data)
  const classes = headerStyles()

  return (
    <div>
      <header className="flex justify-between items-center px-4 py-3">
        <h2 className="font-bold">Notifications</h2>

        <Link
          to={`${getUserPath(meData.role)}/notifications`}
          onClick={handleCloseNotificationsMenu}
          className="font-medium text-primary-main text-xs uppercase tracking-wider"
        >
          View all
        </Link>
      </header>
      <hr />

      <div className={clsx(classes.notificationBody, 'pt-2 pb-3')}>
        {notifications.length ? (
          <>
            {notifications.slice(0, 10).map((item) => (
              <SingleNotification
                key={item._id}
                notification={item}
                meData={meData}
                handleCloseNotificationsMenu={handleCloseNotificationsMenu}
              />
            ))}
          </>
        ) : (
          <p className="py-1 px-6 text-gray-500 text-center font-medium">
            No Pending Notifications
          </p>
        )}
      </div>
    </div>
  )
}

const SingleNotification = ({
  handleCloseNotificationsMenu,
  notification,
  meData
}) => {
  const [time, setTime] = useState('')
  const classes = headerStyles()
  const date = new Date(notification.createdAt)

  useEffect(() => {
    const dif = getTimeDifference(notification.createdAt)
    if (dif > 24 && dif <= 48) {
      setTime(
        `Yesterday at ${date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })}`
      )
    } else if (dif < 24) {
      setTime(
        `Today at ${date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })}`
      )
    } else {
      setTime(format(new Date(notification.createdAt), 'd MMM p'))
    }
  }, [])

  return (
    <Link
      key={notification._id}
      to={`${getUserPath(meData.role)}/notifications`}
      className="py-2 block hover:bg-gray-100"
      onClick={handleCloseNotificationsMenu}
    >
      <div className="px-4 flex space-x-4 items-start">
        <ColoredAvatars user={notification.user ?? { name: 'Unknown' }} />
        <div>
          <div className="text-xs mb-1">
            <span
              dangerouslySetInnerHTML={{
                __html: notification.description
              }}
              className={classes.notificationText}
            ></span>{' '}
          </div>
          <div className="text-xs text-primary-main">{time}</div>
        </div>
      </div>
    </Link>
  )
}

export default AccountNotifications
