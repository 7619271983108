export const APPROVALS = {
  FETCHED: 'FETCHED_APPROVALS',
  LOADING: 'LOADING_APPROVALS',
  ERROR: 'ERROR_APPROVALS',
  REMOVE_ONE_TASK: 'APPROVALS_REMOVE_ONE_TASK'
}

const initialState = {
  loading: false,
  data: {
    tasks: [] //for now only storing the task ids that requires my approval (saving memory space)
  },
  error: null
}

const approvalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPROVALS.FETCHED:
      return {
        ...state,
        loading: false,
        data: { ...state.data, tasks: action.payload.map((item) => item.task) }
      }

    case APPROVALS.REMOVE_ONE_TASK:
      return {
        ...state,
        data: {
          ...state.data,
          tasks: state.data.tasks.filter((item) => item !== action.payload)
        }
      }

    default:
      return state
  }
}

export default approvalsReducer
