import axios from 'axios'
import { errorMessages } from 'utils'
import { ONBOARDING } from './reducer'

/**
 * Fetch all onboarding templates
 * @param {Function} callback function (optional)
 */
export const fetchAllTemplates = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/onboarding/user',
        method: 'GET'
      })

      dispatch({ type: ONBOARDING.FETCHED, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Create new onboarding template
 * @param {Object} data name, isActive, welcomeText, data
 * @param {Function} callback function (optional)
 */
export const createNewTemplate = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/onboarding',
        method: 'POST',
        data
      })

      dispatch({ type: ONBOARDING.ADD_TEMPLATE, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Update Template data
 * @param {String} ID template id
 * @param {Object} data
 * @param {Function} callback function (optional)
 */

export const updateTemplate = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/onboarding/${id}`,
        data
      })

      dispatch({ type: ONBOARDING.UPDATE_TEMPLATE, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Update Template Active Status
 * @param {String} ID template id
 * @param {Object} data isActive (boolean)
 * @param {Function} callback function (optional)
 */

export const updateTemplateStatus = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/onboarding/status/${id}`,
        data
      })

      dispatch({ type: ONBOARDING.UPDATE_ALL, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Fetch client onboarding data
 * @param {String} ID Client ID
 * @param {Function} callback function (optional)
 */

export const fetchClientOnboardingData = async (clientId, callback) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/clientonboardingresponse/client/${clientId}`
    })

    if (callback) callback(res.data)
  } catch (err) {
    if (callback) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true,
        err?.response?.status
      )
    }
  }
}
