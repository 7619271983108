import { FormLabel } from 'global/globalComponents'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button
} from '@material-ui/core'
import { previousDay } from 'date-fns'
import {
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
import CustomTableHeader from 'global/globalComponents/CustomTable/CustomTableHeader'
import {
  fetchAllWorkspacesForCurrentUser,
  handleAssignTeammateEvent
} from 'thunks/workspace/actions'

const TemplateInputs = ({
  setStep,
  handleChanges,
  formData,
  setFormData,
  loading,
  handleClose
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <CustomModalHeader
        heading="Import Detail"
        className="text-center"
        handleClose={handleClose}
      />
      <CustomModalBody style={{ height: '450px' }}>
        <form className="my-4 w-2/3 mx-auto">
          <div className="mt-2">
            <div className="">
              <FormLabel required>Template Name</FormLabel>
              <TextField
                onChange={handleInputChange}
                className="w-full"
                placeholder="Enter Template Name"
                value={formData.title}
                name="title"
              />
            </div>

            <div className="mt-4">
              <FormLabel required>Template Description</FormLabel>
              <TextField
                onChange={handleInputChange}
                className="w-full"
                placeholder="Enter Template Description"
                value={formData.description}
                name="description"
              />
            </div>

            <div className="mt-4">
              <FormLabel required>Category</FormLabel>
              <TextField
                onChange={handleInputChange}
                className="w-full"
                placeholder="Enter Template Category"
                value={formData.category}
                name="category"
              />
            </div>
          </div>
        </form>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          loading={loading}
          onClick={handleChanges}
          disabled={formData.title ? false : true}
        >
          Continue
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={() => {
            setStep(2)
          }}
        >
          Back
        </Button>
      </CustomModalFooter>
    </>
  )
}

export default TemplateInputs
