import { useEffect, useState } from 'react'
import {
  Box,
  FormControlLabel,
  // Dialog,
  // FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
// import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
// import clsx from 'clsx'
// import { generateInvoice } from 'thunks/agencyPayment/actions'
import { generateInvoice } from 'thunks/invoices/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getClient } from 'thunks/addClient/actions'
import { fetchAllServices } from 'thunks/service/actions'
import Select from 'react-select'
// import InputBase from '@material-ui/core/InputBase'
import { CheckBoxField, LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
// import SectionHeading from 'global/globalComponents/SectionHeading'
// import CloseButton from 'global/globalComponents/CloseButton/CloseButton'
import { toggleCreateInvoiceModal } from 'thunks/generlAppState/actions'
// import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
// import CloseIcon from '@material-ui/icons/Close'
// import InfoIcon from '@material-ui/icons/Info'
// import { Link, useHistory } from 'react-router-dom'
import currencySymbol from 'static/Currency/Currency'
import StripeMessage from 'global/globalComponents/StripeMessage/StripeMessage'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
// import SelectInvoiceStyle from './SelectInvoiceStyle'
import Pill from 'global/globalComponents/Pill/Pill'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import invoiceStyles from '../InvoiceStyles'
// import { createCustomInvoice } from 'thunks/ProjectInvoices/actions'
import { isArray } from 'lodash'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import DatePicker from 'react-datepicker'

export default function CreateInvoiceModal({
  handleClose,
  currentClient,
  handleEditInvoice,
  handleViewInvoice
}) {
  const classes = invoiceStyles()
  const dispatch = useDispatch()
  const AgencyClients = useSelector(state => state.userClients)
  const userServices = useSelector(state => state.userServices)
  const createInvoiceModal = useSelector(
    state => state.generalAppState.createInvoiceModal
  )
  // const stripeInfo = useSelector((state) => state.userStripeInfo.data)
  const [clients, setClients] = useState([])
  const [clientdata, setClientData] = useState([])
  const [loading, setLoading] = useState(false)
  const [autoPay, setAutoPay] = useState(false)
  const [services, setServices] = useState([])
  // const [isRecurring, setIsRecuuring] = useState(0)
  // const [customer, setCustomer] = useState(false)
  // const [activeServiceType, setActiveServiceType] = useState('stripe')
  // const [otherPayment, setOtherPayment] = useState('due_date')
  // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
  //   'send_invoice'
  // )
  // const [billDueDays, setBillDueDays] = useState({
  //   other: '7',
  //   stripe: '30'
  // })
  // const [oneClientInvoice, setOneClientInvoice] = useState({
  //   client: currentClient ? clientdata[0] : '',
  //   // client: '',
  //   service: []
  // })
  // const [selectedTab, setSelectedTab] = useState(0)
  // const [otherRecurring, setOtherRecurring] = useState(false)
  const [serviceIsRecurring, setServiceIsRecurring] = useState(false)
  // const history = useHistory()
  const [selectedClient, setSelectedClient] = useState({})
  const [selectedServices, setSelectedServices] = useState([])
  const [selectedProject, setSelectedProject] = useState({})
  const [markAsPaid, setMarkAsPaid] = useState(false)
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(0)
  const [selectedInvoiceUntil, setSelectedInvoiceUntil] = useState(
    invoiceUntilOptions[0]
  )
  const [selectedInvoiceFrequency, setSelectedInvoiceFrequency] = useState({
    label: 'Every Week',
    value: 7
  })
  const [chosenDueDate, setChosenDueDate] = useState(new Date())

  // console.log(
  //   oneClientInvoice.client,
  //   'one client invoice',
  //   currentClient,
  //   'clients',
  //   clients
  // )

  // useEffect(() => {
  //   if (currentClient) {
  //     setAutoPay(false)
  //     if (currentClient.customerId) {
  //       dispatch(
  //         fetchCustomerData(currentClient.customerId, customerDataCallback)
  //       )
  //       setCustomer(false)
  //     } else {
  //       setCustomer(true)
  //       setSelectedPaymentMethod('send_invoice')
  //     }
  //   }
  // }, [activeServiceType])

  const [alertModalOpen, setAlertModalOpen] = useState(false)

  // const handleOtherPaymentMethod = (e) => {
  //   setOtherPayment(e.target.value)
  //   if (e.target.value === 'mark_as_paid') setAlertModalOpen(true)
  // }

  useEffect(() => {
    if (currentClient) {
      setSelectedClient({
        label: `${currentClient.name} (${currentClient.email?.toLowerCase()})`,
        value: currentClient._id,
        ...currentClient
      })
    }
  }, [currentClient])

  useEffect(() => {
    if (!AgencyClients.fetched) {
      dispatch(getClient())
    }
    if (!userServices.fetched) {
      dispatch(fetchAllServices())
    }
  }, [userServices.fetched, AgencyClients.fetched])

  useEffect(() => {
    if (currentClient) {
      setClients([currentClient])
    } else {
      setClients(AgencyClients.data)
    }
  }, [currentClient, AgencyClients.data])

  useEffect(() => {
    let arr = clients?.map(client => ({
      label: `${client.name} (${client.email?.toLowerCase()})`,
      value: client._id,
      ...client
    }))
    setClientData(arr)
  }, [clients])

  const getCompanyAddress = () => {
    if (selectedClient.company) {
      const {
        line1,
        line2,
        city,
        state,
        country,
        zipCode
      } = selectedClient.company.address
      return `${line1 ? `${line1}, ` : ''}${line2 ? `${line2}, ` : ''}${
        city ? `${city}, ` : ''
      }${state ? `${state}, ` : ''}${
        country.label ? `${country.label}, ` : ''
      }${zipCode ? zipCode : ''}`
    }
    return ''
  }

  // useEffect(() => {
  //   // let stripeServices = []
  //   // let otherServices = []

  //   // userServices.data.forEach((service) => {
  //   //   if (!service.isActive) return false

  //   //   if (service?.isStripePlan || service?.planId) {
  //   //     stripeServices.push({
  //   //       name: service.name,
  //   //       value: service._id,
  //   //       amount: service.amount,
  //   //       isRecurring: service.isRecurring,
  //   //       currency: service?.currency
  //   //     })
  //   //   } else {
  //   //     otherServices.push({
  //   //       name: service.name,
  //   //       value: service._id,
  //   //       amount: service.amount,
  //   //       isRecurring: service.isRecurring,
  //   //       currency: service?.currency
  //   //     })
  //   //   }
  //   // })

  //   setServices(
  //     userServices.data.map((item) => ({
  //       label: item.name,
  //       value: item._id,
  //       ...item
  //     }))
  //   )
  // }, [userServices.data])

  // useEffect(() => {
  //   if (
  //     activeServiceType === 'other' &&
  //     oneClientInvoice.service?.length === 1
  //   ) {
  //     if (oneClientInvoice.service[0].isRecurring) {
  //       setOtherRecurring(true)
  //     } else {
  //       setOtherRecurring(false)
  //       setServices((prev) =>
  //         [...prev].filter(
  //           (item) =>
  //             item.currency?.toUpperCase() ===
  //               oneClientInvoice.service[0].currency && !item.isRecurring
  //         )
  //       )
  //     }
  //   }
  //   if (
  //     activeServiceType === 'other' &&
  //     oneClientInvoice.service?.length === 0
  //   ) {
  //     handleServices('other')
  //   }
  // }, [oneClientInvoice.service, activeServiceType])

  // useEffect(() => {
  //   if (createInvoiceModal.invoiceType === 'other') {
  //     handleServices('other')
  //   }
  //   setOtherPayment('due_date')
  // }, [createInvoiceModal])

  // const handleReccuringChange = () => {
  //   setIsRecuuring((prev) => !prev)
  // }

  const handleClientChange = val => {
    // let v = clients.find((item) => item._id === e.value)
    // setAutoPay(false)

    // if (v.customerId) {
    //   dispatch(fetchCustomerData(v.customerId, customerDataCallback))
    //   setCustomer(false)
    // } else {
    //   setCustomer(true)
    //   setSelectedPaymentMethod('send_invoice')
    // }

    // setOneClientInvoice((prev) => ({ ...prev, client: e }))
    setSelectedProject([])
    setSelectedClient(val)
  }

  // const customerDataCallback = (res, err) => {
  //   if (!err) {
  //     if (res.paymentMethod) {
  //       setAutoPay(true)
  //     }
  //   } else setAutoPay(false)
  // }

  // const optionLabel = (service) => {
  //   return (
  //     <div className="flex justify-between items-center">
  //       <span className="pr-2">
  //         {service.name} (
  //         {currencySymbol[service?.currency?.toUpperCase()]?.symbol_native}
  //         {service.amount * 0.01}){' '}
  //       </span>

  //       {/* {activeServiceType === 'stripe' && ( */}
  //       <Pill variant={service.isRecurring ? 'yellow' : 'blue'}>
  //         {service.isRecurring ? 'Recurring' : 'One Time'}
  //       </Pill>
  //       {/* )} */}
  //     </div>
  //   )
  // }

  useEffect(() => {
    if (selectedServices.length === 1) {
      if (selectedServices[0].isRecurring) {
        setServiceIsRecurring(true)
      } else {
        setServiceIsRecurring(false)
        setServices(services.filter(item => !item.isRecurring))
      }
    }
  }, [selectedServices])

  // const handleServiceChange = (val, sel) => {
  //   const option = sel.option
  //   if (!isArray(val)) {
  //     setSelectedServices([val])
  //   } else {
  //     setSelectedServices(val)
  //   }

  //   if (option) {
  //     if (val.length === 1) {
  //       //do this only on first select
  //       if (!option.isRecurring) {
  //         setServices(services.filter((item) => !item.isRecurring))
  //       }
  //     }
  //   }

  //   if (!val.length) {
  //     // Reset if select is cleared
  //     setServices(
  //       userServices.data.map((item) => ({
  //         label: item.name,
  //         value: item._id,
  //         ...item
  //       }))
  //     )
  //   }

  //   // if (activeServiceType === 'other') {
  //   //   let arr = []
  //   //   if (otherRecurring) {
  //   //     arr.push(userServices.data.find((res) => res._id === e.value))
  //   //   } else {
  //   //     e.forEach((service) =>
  //   //       arr.push(userServices.data.find((res) => res._id === service.value))
  //   //     )
  //   //   }
  //   //   setOneClientInvoice((prev) => ({
  //   //     ...prev,
  //   //     service: arr.map((service) => ({
  //   //       name: service?.name,
  //   //       value: service?.planId || service._id,
  //   //       amount: service?.amount,
  //   //       isRecurring: service.isRecurring,
  //   //       currency: service?.currency?.toUpperCase()
  //   //     }))
  //   //   }))
  //   // } else {
  //   //   let service = userServices.data.find((res) => res._id == e.value)
  //   //   setIsRecuuring(service.isRecurring)
  //   //   setOneClientInvoice((prev) => ({
  //   //     ...prev,
  //   //     service: {
  //   //       name: service.name,
  //   //       value: service.planId || service._id,
  //   //       amount: service.amount,
  //   //       isRecurring: service.isRecurring,
  //   //       currency: service?.currency?.toUpperCase()
  //   //     }
  //   //   }))
  //   // }
  // }

  // useEffect(() => {
  //   // if (activeServiceType === 'other') {
  //   //   setOneClientInvoice({ client: '', service: [] })
  //   // } else
  //   setOneClientInvoice({
  //     client: currentClient ? clientdata[0] : '',
  //     service: []
  //   })
  // }, [activeServiceType, createInvoiceModal.invoiceType])

  // const selectFilterHandler = (option, inputValue) => {
  //   // console.log(option, inputValue, 'filter')
  //   const reg = new RegExp(`^${inputValue?.toLowerCase()}`)
  //   return reg.test(option.data.name?.toLowerCase())
  // }

  const autoRepeatFrequencyHandler = data => {
    let autoRepeatFrequency = 30
    if (data.length === 1 && data[0].isRecurring) {
      if (data[0].recurring.interval === 'month') {
        autoRepeatFrequency = Math.round(
          (365 * data[0].recurring.interval_count) / 12
        )
      } else {
        autoRepeatFrequency = 365
      }
    }
    return autoRepeatFrequency
  }

  const generateServiceInvoice = () => {
    const now = new Date()
    const days =
      new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() || 30
    // ---------- Checking if all required fields are filled -----------

    // if (!selectedClient.value) {
    //   dispatch(fireErrorToaster('Please select the client!'))
    //   return
    // }

    let data = {
      autoRepeat: selectedServices[0]?.isRecurring ?? false,
      dueDate: new Date(now.setDate(now.getDate() + days)).toISOString(), //will change it soon,
      autoRepeatFrequency:
        parseInt(autoRepeatFrequencyHandler(selectedServices)) ?? 30,
      invoiceItems: selectedServices.map(item => {
        const obj = { ...item }
        delete obj.category
        return {
          ...obj,
          name: obj.name,
          quantity: 1,
          rate: obj.amount * 0.01,
          total: obj.amount * 0.01
        }
      }),
      totalAmount: selectedServices.reduce(
        (prev, curr) => prev + curr.amount * 0.01,
        0
      ),
      type: 'service',
      nextInvoice:
        selectedInvoiceType.value === 'recurring'
          ? new Date(
              new Date().setDate(
                new Date().getDate() + selectedInvoiceFrequency.value
              )
            ).toISOString()
          : null
    }

    if (selectedClient._id) {
      data.client = {
        clientName: selectedClient.name ?? '',
        clientEmail: selectedClient.email ?? '',
        clientId: selectedClient._id ?? '',
        clientCompanyName: selectedClient.company
          ? selectedClient.company.companyLegalName
          : '',
        clientAddress: getCompanyAddress()
      }
    }

    if (markAsPaid) {
      data.status = 'paid'
    }

    setLoading(true)
    dispatch(generateInvoice(data, invoiceCallback))
  }

  const generateProjectInvoice = () => {
    // if (!selectedProject.length) {
    //   return dispatch(fireErrorToaster('Please select a project'))
    // }
    const dueDate =
      selectedInvoiceUntil.value === 'date'
        ? new Date(chosenDueDate).toISOString()
        : addDaysToCurrentDate(30)

    const data = {
      autoRepeat: selectedInvoiceType.value === 'recurring',
      dueDate,
      autoRepeatFrequency: selectedInvoiceFrequency.value,
      client: {
        clientName: selectedClient.name,
        clientEmail: selectedClient.email,
        clientId: selectedClient._id,
        clientCompanyName: selectedClient.company
          ? selectedClient.company.companyLegalName
          : '',
        clientAddress: getCompanyAddress()
      },
      workspace: selectedProject[0]._id,
      workspaceName: selectedProject[0].name,
      type: 'workspace',
      invoiceItems: [],
      nextInvoice:
        selectedInvoiceType.value === 'recurring'
          ? new Date(
              new Date().setDate(
                new Date().getDate() + selectedInvoiceFrequency.value
              )
            ).toISOString()
          : null
    }

    setLoading(true)
    dispatch(generateInvoice(data, invoiceCallback))
  }

  const invoiceCallback = (result, err) => {
    setLoading(false)

    if (!err) {
      handleCloseModal()
      dispatch(fireSuccessToaster('Invoice generated'))
      if (result.status === 'paid') {
        handleViewInvoice(result)
      } else {
        handleEditInvoice(result)
      }
    } else {
      dispatch(fireErrorToaster(result))
    }
  }

  // const handleServices = (type) => {
  //   setActiveServiceType(type)

  //   var services = userServices.data?.filter(
  //     (service) => service.serviceType === type
  //   )

  //   var arr2 = []
  //   services.map((service) => {
  //     arr2.push({
  //       name: service.name,
  //       value: service._id,
  //       amount: service.amount,
  //       isRecurring: service.isRecurring,
  //       currency: service?.currency
  //     })
  //   })

  //   setServices(arr2)
  //   setCustomer(false)
  // }

  // const closeModal = () => {
  //   setCustomer(false)
  //   handleClose()
  // }

  // const othersAmount = () => {
  //   return oneClientInvoice.service?.length > 0
  //     ? oneClientInvoice.service
  //         .map((el) => el.amount * 0.01)
  //         .reduce((a, b) => a + b)
  //     : 0
  // }

  const handleMarkAsPaid = () => {
    if (markAsPaid) {
      setMarkAsPaid(false)
    } else {
      setMarkAsPaid(true)
      setAlertModalOpen(true)
    }
  }

  const setInvoiceType = val => {
    setSelectedInvoiceType(parseInt(val))
    if (parseInt(val) === 0) {
      setSelectedServices([])
    }
  }

  const handleCloseModal = () => {
    if (!currentClient) {
      setSelectedClient({})
    }
    setInvoiceType(0)
    setSelectedProject([])
    setSelectedServices([])
    dispatch(toggleCreateInvoiceModal())
  }

  // const handleTabChange = (e, newVal) => {
  //   setSelectedTab(newVal)
  //   handleServices(newVal === 0 ? 'stripe' : 'other')
  // }

  // console.log(oneClientInvoice?.service?.amount, 'amount in invoice')
  return (
    <CustomModal
      open={createInvoiceModal.open}
      handleClose={handleCloseModal}
      size="small"
    >
      <CustomModalHeader heading="Create Invoice" />

      <CustomModalBody>
        {/* <div className="mb-6">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            {['Stripe', 'Other'].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
        </div> */}

        {/* {!stripeInfo.isConnected && selectedTab === 0 && (
          <div className="mb-3">
            <CreateInvoiceStripeMessage />
          </div>
        )} */}

        <Box className={classes.modalRowContainer}>
          <Grid item xs={12}>
            <Box mb={4}>
              <FormLabel>Clients</FormLabel>
              <Select
                menuPosition={'fixed'}
                // styles={{
                //   // Fixes the overlapping problem of the component
                //   menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                // }}
                isSearchable={true}
                isDisabled={Boolean(currentClient)}
                className={classes.textFieldStyle}
                onChange={handleClientChange}
                options={clientdata}
                styles={{
                  ...reactSelectCustomStyles,
                  menuPortal: provided => ({ ...provided, zIndex: 9999 })
                }}
                // value={oneClientInvoice.client}
                value={selectedClient}
                placeholder="Select Client"

                // maxMenuHeight={130}
              />
              {/* {customer && activeServiceType === 'stripe' && (
                <p className="text-xs text-yellow-500">
                  *Alert: This customer does not exist in Stripe.
                </p>
              )} */}
            </Box>
            <Box mb={2}>
              <FormLabel>Raise For</FormLabel>
              <RadioGroup
                row
                value={selectedInvoiceType}
                onChange={e => setInvoiceType(e.target.value)}
                name="invoice_type"
              >
                <FormControlLabel
                  control={<Radio />}
                  value={0}
                  label={
                    <span className="text-smaller lg:text-sm font-normal">
                      None
                    </span>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={1}
                  label={
                    <span className="text-smaller lg:text-sm font-normal">
                      Service
                    </span>
                  }
                />
                <FormControlLabel
                  control={<Radio />}
                  value={2}
                  label={
                    <span className="text-smaller lg:text-sm font-normal">
                      Project
                    </span>
                  }
                />
              </RadioGroup>
            </Box>
            {selectedInvoiceType === 1 ? (
              <Box mb={4}>
                <SelectForService
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                />
              </Box>
            ) : selectedInvoiceType === 2 ? (
              <Box mb={4}>
                <SelectForProject
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  selectedClient={selectedClient}
                  selectedInvoiceUntil={selectedInvoiceUntil}
                  setSelectedInvoiceUntil={setSelectedInvoiceUntil}
                  selectedInvoiceFrequency={selectedInvoiceFrequency}
                  setSelectedInvoiceFrequency={setSelectedInvoiceFrequency}
                  chosenDueDate={chosenDueDate}
                  setChosenDueDate={setChosenDueDate}
                />
              </Box>
            ) : null}
            {/* <Box mb={4}>
              <FormLabel>
                Select Service{' '}
                <span className="text-xs font-normal text-gray-500">
                  (optional)
                </span>
              </FormLabel>
              <Select
                menuPosition={'fixed'}
                placeholder="Select Service"
                isMulti={!serviceIsRecurring}
                className={classes.textFieldStyle}
                options={services}
                onChange={handleServiceChange}
                styles={{
                  ...SelectInvoiceStyle,
                  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                }}
                maxMenuHeight={130}
                value={selectedServices}
                // filterOption={selectFilterHandler}
                isSearchable={false}
                getOptionLabel={optionLabel}
              />
            </Box> */}
            {/* {activeServiceType === 'stripe' ? (
              <>
                <Box mt={2}>
                  <FormLabel>Payment</FormLabel>
                  <RadioGroup
                    value={selectedPaymentMethod}
                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    name="payment_method"
                  >
                    <FormControlLabel
                      className="mt-2 mb-2 text-sm"
                      control={<Radio />}
                      value="charge_automatically "
                      // label="Automatically charge a payment method on file"
                      label={
                        <span className="text-sm font-normal">
                          Automatically charge a payment method on file
                        </span>
                      }
                      disabled={!autoPay}
                    />
                    <FormControlLabel
                      className="mb-2"
                      control={<Radio />}
                      value="send_invoice"
                      // label="Email Invoice to the customer to pay manually"
                      label={
                        <span className="text-sm font-normal">
                          Email Invoice to the customer to pay manually
                        </span>
                      }
                    />
                  </RadioGroup>
                </Box>

                {selectedPaymentMethod === 'send_invoice' && (
                  <div className="mt-2">
                    <p className="text-xs font-medium">Select Due Date</p>
                    <div className="flex items-center gap-2 mt-2">
                      <TextField
                        style={{ width: 100 }}
                        value={billDueDays.stripe}
                        onChange={(e) =>
                          setBillDueDays((prev) => ({
                            ...prev,
                            stripe: e.target.value
                          }))
                        }
                        onBlur={(e) =>
                          e.target.value < 1
                            ? setBillDueDays((prev) => ({ ...prev, stripe: 1 }))
                            : null
                        }
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ fontSize: 12, fontWeight: 500 }}
                            >
                              Days
                            </InputAdornment>
                          )
                        }}
                      />
                      <span className="">after invoice is sent</span>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-4">
                <Box mt={2}>
                  <FormLabel>Payment</FormLabel>
                  <RadioGroup
                    value={otherPayment}
                    onChange={handleOtherPaymentMethod}
                    name="payment"
                  >
                    <FormControlLabel
                      className="mt-2 mb-2 text-sm"
                      control={<Radio />}
                      value="mark_as_paid"
                      // label="Mark as paid"
                      label={
                        <span className="text-sm font-normal">
                          Mark as paid
                        </span>
                      }
                    />
                    <FormControlLabel
                      className="mb-2"
                      control={<Radio />}
                      value="due_date"
                      label={
                        <span className="text-sm font-normal">
                          Select due date
                        </span>
                      }
                    />
                  </RadioGroup>
                </Box>
                {otherPayment === 'due_date' && (
                  <div className="flex items-center gap-2 mt-2">
                    <TextField
                      style={{ width: 100 }}
                      value={billDueDays.other}
                      onChange={(e) =>
                        setBillDueDays((prev) => ({
                          ...prev,
                          other: e.target.value
                        }))
                      }
                      onBlur={(e) =>
                        e.target.value < 1
                          ? setBillDueDays((prev) => ({ ...prev, other: 1 }))
                          : null
                      }
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ fontSize: 12, fontWeight: 500 }}
                          >
                            Days
                          </InputAdornment>
                        )
                      }}
                    />
                    <span className="">after invoice is sent</span>
                  </div>
                )}
              </div>
            )} */}

            {Boolean(selectedServices.length) && (
              <div className="mt-4">
                <Box mt={2}>
                  <FormLabel>Payment</FormLabel>
                  <CheckBoxField
                    label="Mark as paid"
                    checked={markAsPaid}
                    onChange={handleMarkAsPaid}
                  />
                  {/* <RadioGroup
                  value={otherPayment}
                  onChange={handleOtherPaymentMethod}
                  name="payment"
                >
                  <FormControlLabel
                    className="mt-2 mb-2 text-sm"
                    control={<Radio />}
                    value="mark_as_paid"
                    // label="Mark as paid"
                    label={
                      <span className="text-sm font-normal">Mark as paid</span>
                    }
                  /> */}
                  {/* <FormControlLabel
                    className="mb-2"
                    control={<Radio />}
                    value="due_date"
                    label={
                      <span className="text-sm font-normal">
                        Select due date
                      </span>
                    }
                  /> */}
                  {/* </RadioGroup> */}
                </Box>
                {/* {otherPayment === 'due_date' && (
                <div className="flex items-center gap-2 mt-2">
                  <TextField
                    style={{ width: 100 }}
                    value={billDueDays.other}
                    onChange={(e) =>
                      setBillDueDays((prev) => ({
                        ...prev,
                        other: e.target.value
                      }))
                    }
                    onBlur={(e) =>
                      e.target.value < 1
                        ? setBillDueDays((prev) => ({ ...prev, other: 1 }))
                        : null
                    }
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ fontSize: 12, fontWeight: 500 }}
                        >
                          Days
                        </InputAdornment>
                      )
                    }}
                  />
                  <span className="">after invoice is sent</span>
                </div>
              )} */}
              </div>
            )}
          </Grid>
          {/* <Grid
            item
            xs={3}
            style={{
              maxWidth: '100%',
              width: '40%',
              flexBasis: 'unset',
              alignSelf: 'flex-start',
              marginLeft: '3rem'
            }}
          >
            <Box>
              <div className="flex justify-between">
                <div>
                  <p className="text-sm font-medium mb-1">Qty</p>
                  <TextField
                    style={{ width: '60px' }}
                    value={selectedServices.length ?? 0}
                    disabled={true}
                  />
                </div>

                <div>
                  <p className="text-sm font-medium mb-1">Price</p>
                  <TextField
                    style={{ width: '60px' }}
                    value={
                      activeServiceType === 'other'
                        ? othersAmount()
                        : oneClientInvoice?.service?.amount
                        ? oneClientInvoice?.service?.amount * 0.01
                        : 0
                    }
                    disabled={true}
                  />
                </div>

                <div>
                  <p className="text-sm font-medium mb-1">Total</p>
                  <TextField
                    style={{ width: '60px' }}
                    value={
                      activeServiceType === 'other'
                        ? othersAmount()
                        : oneClientInvoice?.service?.amount
                        ? oneClientInvoice?.service?.amount * 0.01
                        : 0
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div
                style={{
                  backgroundColor: '#f2f5f7'
                }}
                className="mt-4 p-4 rounded shadow-md"
              >
                <Box
                  className={classes.displayClass}
                  style={{
                    justifyContent: 'space-between',
                    color: 'black'
                  }}
                >
                  <p className="text-sm font-medium">Subtotal</p>
                  <p className="text-sm font-medium">
                    {oneClientInvoice.service?.currency ||
                    oneClientInvoice.service[0]?.currency ? (
                      <>
                        <span>
                          {activeServiceType === 'other'
                            ? currencySymbol[
                                oneClientInvoice?.service[0]?.currency
                              ]?.symbol_native
                            : currencySymbol[
                                oneClientInvoice?.service?.currency
                              ]?.symbol_native}
                        </span>
                        <span>
                          {activeServiceType === 'other'
                            ? othersAmount()
                            : oneClientInvoice?.service?.amount * 0.01}
                        </span>
                      </>
                    ) : (
                      0
                    )}
                  </p>
                </Box>
                <Box
                  mt={2}
                  className={classes.displayClass}
                  style={{
                    justifyContent: 'space-between',
                    color: 'black'
                  }}
                >
                  <p className="text-sm font-medium text-primary-main">Total</p>
                  <p className="uppercase font-medium text-primary-main">
                    {oneClientInvoice.service.currency ||
                    oneClientInvoice.service[0]?.currency ? (
                      <>
                        <span>
                          {activeServiceType === 'other'
                            ? currencySymbol[
                                oneClientInvoice?.service[0]?.currency
                              ]?.symbol_native
                            : currencySymbol[
                                oneClientInvoice?.service?.currency
                              ]?.symbol_native}
                        </span>
                        <span>
                          {activeServiceType === 'other'
                            ? othersAmount()
                            : oneClientInvoice?.service?.amount * 0.01}
                        </span>
                      </>
                    ) : (
                      0
                    )}
                  </p>
                </Box>
              </div>
            </Box>
          </Grid> */}
        </Box>
      </CustomModalBody>

      <CustomModalFooter className="items-center">
        <LoadingButton
          // className={classes.sendButton}
          onClick={
            selectedInvoiceType === 2
              ? generateProjectInvoice
              : generateServiceInvoice
          }
          loading={loading}
          disabled={
            selectedInvoiceType === 2 && !selectedProject.length ? true : false
          }
          // disabled={!stripeInfo.isConnected && selectedTab === 0}
        >
          Create Invoice
        </LoadingButton>

        {/* <Box className="flex items-center justify-between flex-1">
          {true ? (
            <StripeMessage action={false} stripProps="p-2 text-xs">
              First create Clients & Services to generate an Invoice
            </StripeMessage>
          ) : (
            <div></div>
          )}
        </Box> */}
      </CustomModalFooter>
      <AlertModal
        open={alertModalOpen}
        handleDialog={() => setAlertModalOpen(false)}
        heading="Alert"
        warningText="Mark as paid status can not be changed after the invoice generated"
        cancelText="Ok"
        deleteBtnText=""
      />
    </CustomModal>
  )
}

const SelectForService = ({ selectedServices, setSelectedServices }) => {
  const userServices = useSelector(state => state.userServices)
  const [services, setServices] = useState([])
  const classes = invoiceStyles()

  useEffect(() => {
    setServices(
      userServices.data.map(item => ({
        label: item.name,
        value: item._id,
        ...item
      }))
    )
  }, [userServices.data])

  const handleServiceChange = (val, sel) => {
    // const option = sel.option
    const value = isArray(val) ? val : [val]

    setSelectedServices(value)

    // value[0] could be undefined (is select is empty)
    if (value[0] && !value[0].isRecurring) {
      setServices(services.filter(item => !item.isRecurring))
    }

    if (!value[0]) {
      // Reset if select is cleared
      setServices(
        userServices.data.map(item => ({
          label: item.name,
          value: item._id,
          ...item
        }))
      )
    }
  }

  const optionLabel = service => {
    return (
      <div className="self-center flex justify-between items-center">
        <span className="pr-2">
          {service.name} (
          {currencySymbol[service?.currency?.toUpperCase()]?.symbol_native}
          {service.amount * 0.01}){' '}
        </span>

        <Pill
          variant={service.isRecurring ? 'yellow' : 'blue'}
          // className="py-0.5"
        >
          {service.isRecurring ? 'Recurring' : 'One Time'}
        </Pill>
      </div>
    )
  }

  return (
    <>
      <FormLabel>Select Service</FormLabel>
      <Select
        menuPosition={'fixed'}
        placeholder="Select Service"
        isMulti={selectedServices[0] && !selectedServices[0].isRecurring}
        className={classes.textFieldStyle}
        options={services}
        onChange={handleServiceChange}
        styles={{
          ...reactSelectCustomStyles,
          menuPortal: provided => ({ ...provided, zIndex: 9999 })
        }}
        maxMenuHeight={155}
        value={selectedServices}
        // filterOption={selectFilterHandler}
        isSearchable={false}
        getOptionLabel={optionLabel}
      />
    </>
  )
}

const SelectForProject = ({
  selectedClient,
  selectedProject,
  setSelectedProject,
  selectedInvoiceUntil,
  setSelectedInvoiceUntil,
  selectedInvoiceFrequency,
  setSelectedInvoiceFrequency,
  chosenDueDate,
  setChosenDueDate
}) => {
  const userWorkspaces = useSelector(state => state.userWorkspaces.data)
  const [projects, setProjects] = useState([])
  const [selectedInvoiceType, setSelectedInvoiceType] = useState({
    label: 'Single Invoice',
    value: 'single'
  })

  // const [selectedInvoiceUntil, setSelectedInvoiceUntil] = useState(
  //   invoiceUntilOptions[0]
  // )
  // const [chosenDueDate, setChosenDueDate] = useState(new Date())

  const dispatch = useDispatch()
  const classes = invoiceStyles()

  useEffect(() => {
    const options = userWorkspaces
      .filter(item => {
        const match = item.assignedClientTeam.find(item => {
          return item.user._id === selectedClient._id
        })
        return Boolean(match)
      })
      .map(item => ({ label: item.name, value: item._id, ...item }))

    setProjects(options)
  }, [userWorkspaces, selectedClient])

  const handleProjectChange = val => {
    setSelectedProject([val])
  }

  const selectInvoiceType = newVal => {
    setSelectedInvoiceType(newVal)
  }

  const selectInvoiceFrequency = newVal => {
    setSelectedInvoiceFrequency(newVal)
  }

  // const handleInvoiceUntilChange = (newVal) => {
  //   setSelectedInvoiceUntil(newVal)
  // }

  // const handleSelectDueDate = (date) => {
  //   setChosenDueDate(date)
  // }

  return (
    <>
      <div className="mb-4">
        <FormLabel required>Select Project</FormLabel>
        <Select
          isDisabled={projects.length ? false : true}
          menuPosition={'fixed'}
          placeholder={
            !projects.length && selectedClient.value
              ? 'No projects available for this user'
              : 'Select Project'
          }
          className={classes.textFieldStyle}
          options={projects}
          onChange={handleProjectChange}
          styles={{
            ...reactSelectCustomStyles,
            menuPortal: provided => ({ ...provided, zIndex: 9999 })
          }}
          maxMenuHeight={130}
          value={selectedProject}
          isSearchable={true}
        />
      </div>

      <div className="mb-8">
        <div className="flex items-end space-x-3 mb-4">
          <div className="flex-1">
            <FormLabel>Invoice Type</FormLabel>
            <Select
              menuPlacement="top"
              options={invoiceTypeOptions}
              onChange={selectInvoiceType}
              value={selectedInvoiceType}
              styles={reactSelectCustomStyles}
              className="w-full"
            />
          </div>

          <div className="flex-1">
            {selectedInvoiceType.value === 'recurring' && (
              <Select
                menuPlacement="top"
                options={invoiceRepeatFrequency}
                onChange={selectInvoiceFrequency}
                value={selectedInvoiceFrequency}
                styles={reactSelectCustomStyles}
                className="w-full"
                maxMenuHeight={100}
              />
            )}
          </div>
        </div>

        {/* <div>
          {selectedInvoiceType.value === 'recurring' && (
            <div className="flex space-x-3">
              <div className="flex-1">
                <Select
                  menuPlacement="top"
                  options={invoiceUntilOptions}
                  value={selectedInvoiceUntil}
                  styles={reactSelectCustomStyles}
                  className="w-full"
                  onChange={handleInvoiceUntilChange}
                />
              </div>

              <div className="flex-1">
                {selectedInvoiceUntil.value === 'date' && (
                  <DatePicker
                    selected={chosenDueDate}
                    onChange={handleSelectDueDate}
                    dateFormat="MMM d, yyyy"
                    className="text-sm"
                    customInput={
                      <input
                        className="py-2 text-center border rounded"
                        style={{ width: 237 }}
                      />
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div> */}
      </div>
    </>
  )
}

function addDaysToCurrentDate(number) {
  const newDate = new Date()
  return new Date(newDate.setDate(newDate.getDate() + number)).toISOString()
}

const invoiceTypeOptions = [
  { label: 'Single Invoice', value: 'single' },
  { label: 'Recurring Invoice', value: 'recurring' }
]

export const invoiceRepeatFrequency = [
  { label: 'Every Week', value: 7 },
  { label: 'Every Two Week', value: 15 },
  { label: 'Every Month', value: 30 },
  { label: 'Every Year', value: 365 }
]

const invoiceUntilOptions = [
  { label: 'I manually stop it', value: 'manual' },
  { label: 'A specific date', value: 'date' }
]

// const CreateInvoiceStripeMessage = () => (
//   <div className="rounded bg-primary-light w-full px-3 py-2 flex items-center justify-between">
//     <div className="flex items-center leading-none">
//       <InfoIcon
//         className="h-2 w-2 text-primary-main"
//         aria-hidden="true"
//         style={{ fontSize: '1.1rem' }}
//       />
//       <p className="text-xs text-primary-main ml-2">
//         To raise a Stripe invoice please connect your Stripe account
//       </p>
//     </div>
//     <div>
//       <Link
//         to="/agency/settings/integrations"
//         className="text-sm font-medium text-primary-main mr-4"
//       >
//         Connect
//       </Link>
//     </div>
//   </div>
// )
