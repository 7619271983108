import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Pill from 'global/globalComponents/Pill/Pill'
import { Td, Tr } from 'global/globalComponents/TailwindTable/TailwindTable'
import pillVariantObj from 'utils/pillVariantObj'
import { format } from 'date-fns'
import Currency from 'static/Currency/Currency'
import InvoiceStatusModal from './InvoiceStatusModal'
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover
} from '@material-ui/core'
import ReactToPrint from 'react-to-print'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as ViewIcon } from 'static/svg/view.svg'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
import { ReactComponent as BellIcon } from 'static/svg/bell.svg'
import { ReactComponent as SendIcon } from 'static/svg/send.svg'
import InvoiceFile from '../InvoiceFile'
import invoiceStyles from '../InvoiceStyles'

export default function InvoiceTableRow({
  invoice,
  openInvoicePreview,
  handleEditInvoice,
  openMarkAsPaidAlert,
  openDeleteAlert,
  handleSendReminder,
  elmFor = 'agency',
  clientCol
}) {
  const access = useSelector(state => state.permission.data?.access)
  const [statusModal, setStatusModal] = useState({ id: '', open: false })
  const [menuOptions, setMenuOptions] = useState([])
  const [download, setDownload] = useState(false)
  const invoiceRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    const arr = [
      {
        label: 'Preview',
        method: handleViewInvoice,
        icon: <ViewIcon className="text-primary-main" />
      }
    ]

    if (elmFor === 'agency') {
      //DO NOT REMOVE
      if (invoice.status !== 'paid' && access?.billings.update) {
        arr.unshift({
          label: 'Edit',
          method: handleEditClick,
          icon: <EditIcon className="text-primary-main" />
        })
      }

      if (
        invoice.status !== 'paid' &&
        invoice.status !== 'draft' &&
        access?.billings.update
      ) {
        arr.push({
          label: 'Mark As Paid',
          method: handleMarkAsPaid,
          icon: <CheckCircleIcon className="text-primary-main" />
        })
        arr.push({
          label: 'Send Reminder',
          method: () => handleSendReminder(invoice),
          icon: <BellIcon className="text-primary-main" />
        })
      }

      // if (invoice.status === 'draft' && access?.billings.delete) {
      if (access?.billings.delete) {
        arr.push({
          label: 'Delete',
          method: handleDeleteInvoice,
          icon: <DeleteIcon className="text-primary-main" />
        })
      }
    } else if (
      invoice.status !== 'draft' &&
      elmFor === 'client' &&
      invoice.status !== 'paid'
    ) {
      arr.push({
        label: 'Pay Now',
        icon: <SendIcon className="text-primary-main" />,
        method: handlePayInvoice
      })
    }

    if (invoice.status !== 'draft') {
      arr.push({
        label: (
          <ReactToPrint
            trigger={() => {
              return <button>Download</button>
            }}
            content={() => invoiceRef.current}
            documentTitle={`${invoice.invoiceNumber}_invoice`}
          />
        ),
        method: () => false,
        icon: <DownloadIcon className="text-primary-main" fontSize="small" />
      })
    }

    setMenuOptions(arr)
  }, [invoice])

  const handleOpenMenu = e => {
    setAnchorEl(e.currentTarget)
    setDownload(true)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setDownload(false)
  }

  const handleMarkAsPaid = () => {
    openMarkAsPaidAlert(invoice._id)
  }

  const handleEditClick = () => {
    handleEditInvoice(invoice)
  }

  const handleDeleteInvoice = () => {
    openDeleteAlert(invoice._id)
  }

  const handlePayInvoice = () => {
    let a = document.createElement('a')
    a.target = '_blank'
    a.href = `/i/${invoice._id}/checkout?vtID=${invoice.viewToken}`
    a.click()
  }

  const handleCloseStatusModal = () => {
    setStatusModal(prev => ({
      ...prev,
      id: '',
      open: false
    }))
  }

  const handleViewInvoice = () => {
    openInvoicePreview(invoice)
  }

  return (
    <>
      <Tr className="">
        <Td className="px-6 py-4 whitespace-nowrap text-gray-900">
          {clientCol ? (
            <div className="flex flex-col">
              <div className="flex items-end">
                {invoice.client?.clientName ? (
                  <span>{invoice.client?.clientName}</span>
                ) : (
                  <span className="text-gray-500">NA</span>
                )}
              </div>
              <span className="text-gray-500 text-xs">
                {invoice.client?.clientEmail}
              </span>
            </div>
          ) : (
            <div className="text-xs">{invoice.invoiceNumber}</div>
          )}
        </Td>
        {/* <Td className="text-center text-xs whitespace-nowrap ">
          {invoice?.invoiceNumber}
        </Td> */}
        <Td className="text-center text-xs whitespace-nowrap w-0.5 truncate space-y-2">
          {invoice.invoiceItems.length
            ? invoice.invoiceItems.slice(0, 2).map((item, index) => (
                <div key={index} className={index === 0 ? 'leading-6' : ''}>
                  {item.name ? (
                    <Pill variant={['yellow', 'purple'][index]}>
                      {item.name}
                    </Pill>
                  ) : (
                    '-'
                  )}
                </div>
              ))
            : 'NA'}
        </Td>
        <Td className="text-center whitespace-nowrap ">
          <Pill variant={pillVariantObj[invoice.status]}>{invoice.status}</Pill>
        </Td>
        <Td className="text-right whitespace-nowrap text-gray-900 text-xs ">
          <div className="text-custom-table-primaryText">
            {Currency[invoice.currency].symbol_native}
            {invoice.totalAmount}
          </div>
        </Td>

        {invoice?.issuedDate ? (
          <Td className="whitespace-nowrap text-xs">
            {Boolean(invoice.dueDate) &&
              format(new Date(invoice.issuedDate), 'MMM d, yyyy')}
          </Td>
        ) : (
          <Td className="whitespace-nowrap text-xs">NA</Td>
        )}
        {/* <Td className="text-center whitespace-nowrap">
          <PopupMenu menuItems={getMenuList(invoice)} />
        </Td> */}
        <Td className="text-center">
          <div>
            <button onClick={handleOpenMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </button>

            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              classes={{ paper: classes.menuPaper }}
            >
              {/*handleCloseMenu => Event Propagation */}
              <List className={classes.list} onClick={handleCloseMenu}>
                {menuOptions.map((item, index) => (
                  <ListItem
                    key={index}
                    onClick={item.method}
                    classes={{ gutters: classes.listItem }}
                    button
                  >
                    <div className="flex items-center py-1">
                      {item.icon && <span className="mr-2">{item.icon}</span>}
                      <ListItemText
                        primary={<p className="text-sm">{item.label}</p>}
                        classes={{ root: classes.listItemText }}
                      />
                    </div>
                  </ListItem>
                ))}
              </List>
            </Popover>

            <div
              style={{
                position: 'fixed',
                left: '-1000px',
                visibility: 'hidden',
                height: '1000px'
              }}
            >
              <div ref={invoiceRef}>
                {download && <InvoiceFile invoice={invoice} />}
              </div>
            </div>
          </div>
        </Td>
      </Tr>
      {statusModal.open && (
        <InvoiceStatusModal
          open={statusModal.open}
          onClose={handleCloseStatusModal}
          id={statusModal.id}
        />
      )}
    </>
  )
}

const useStyles = makeStyles({
  menuPaper: {
    borderRadius: '0.375rem'
  },

  list: {
    padding: '4px 0'
  },

  listItem: {
    padding: '0.5rem 1.5rem',
    minWidth: 115
  },

  listItemText: {
    margin: 0
  }
})
