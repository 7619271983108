import React, { useEffect, useState } from 'react'
import { Dialog, Button } from '@material-ui/core'
import { useHistory, withRouter } from 'react-router'
// import AnimatedCheckIcon from 'global/globalComponents/AnimatedCheckIcon/AnimatedCheckIcon'
import {
  getStripeConnectStatus,
  postStripeAccountData
} from 'thunks/stripeAccount/actions'
// import CancelIcon from '@material-ui/icons/Cancel'
import { ErrorOutlineRounded } from '@material-ui/icons'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { fetchMeData } from 'thunks/me/actions'
import CloseButton from 'global/globalComponents/CloseButton/CloseButton'
import setupStyles from './setupStyles'

const StripeConnectedModal = (props) => {
  const classes = setupStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  // const [open, setOpen] = useState(true)
  const [stripeApiCalledFlag, setStripeApiCalledFlag] = useState(false)
  const [loading, setLoading] = useState(true)
  const [errorInPostindData, setErrorInPostingData] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const success = params.get('success'),
      code = params.get('code')

    if (success === 'true' && code && !stripeApiCalledFlag) {
      postStripeAccountData(code, postStripeDataCallback)
      setStripeApiCalledFlag(true)
      dispatch(fetchMeData())
    } else {
      // setErrorInPostingData(true)
    }
  }, [props])

  const postStripeDataCallback = (res, err) => {
    setLoading(false)
    if (err) {
      setErrorInPostingData(res.message)
    } else {
      dispatch(getStripeConnectStatus())
    }
  }

  const pushToIntegrationScreen = () => {
    setErrorInPostingData(null)
    history.push('/agency/settings/integrations')
  }

  return (
    <Dialog open={true} maxwidth="md" classes={{ paper: classes.dialogPaper }}>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:w-full relative">
        {!loading && <CloseButton onClick={pushToIntegrationScreen} />}
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div
              className={clsx(
                'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10',
                {
                  'bg-blue-100': loading,
                  'bg-red-100': errorInPostindData,
                  'bg-green-100': !errorInPostindData
                }
              )}
            >
              <ErrorOutlineRounded
                className={clsx('h-6 w-6', {
                  'text-blue-600': loading,
                  'text-red-600': errorInPostindData,
                  'text-green-600': !errorInPostindData
                })}
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Stripe Integration
              </h3>
              {/* <div className="mt-2">
                <p className="text-sm text-gray-500">Some Error Occured</p>
              </div> */}
            </div>
          </div>

          <div className="mt-8">
            <div className="mt-8">
              {errorInPostindData ? (
                <div>
                  <p className="font-medium text-center">
                    {errorInPostindData?.requirements?.errors?.map((i) => (
                      <span className="font-normal text-sm">{i?.reason}</span>
                    )) ??
                      `Some error occured in the process. Please try
                    again or contact support.`}
                  </p>
                  <div className="text-center mt-6">
                    <Button onClick={pushToIntegrationScreen}>Try Again</Button>
                  </div>
                </div>
              ) : loading ? (
                <div className="text-center py-4">
                  <p className="font-medium">
                    Please wait while we're verifying the stripe connection
                    status...
                  </p>
                  <span className="text-sm font-medium text-custom-gray-dark">
                    Don't close window or refresh the browser
                  </span>
                </div>
              ) : (
                <div className="text-center py-4">
                  <p className="text-lg font-medium">
                    Your Stripe account connected successfully!
                  </p>
                  <div
                    onClick={pushToIntegrationScreen}
                    className="flex items-center justify-center mt-6"
                  >
                    <Button variant="outlined">Close</Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default withRouter(StripeConnectedModal)
