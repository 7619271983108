import { makeStyles, createStyles } from '@material-ui/core'

const singleWorkspaceStyles = makeStyles((theme) =>
  createStyles({
    // componentContainer: {
    //   minHeight: `calc( 100vh - ${theme.custom.header.height + 5}px)`
    //   // overflow: 'hidden'
    // },
    saveViewPopup: {
      bottom: '1.5rem',
      right: '1.5rem',
      boxShadow: '0 10px 16px rgba(0, 0, 0, 0.15)',
      zIndex: 9999999999,
      opacity: 0,
      transform: 'translateY(10px)',
      visibility: 'hidden',
      transition: 'all 200ms ease-in-out'
    },
    saveViewPopupVisible: {
      visibility: 'visible',
      opacity: 1,
      transform: 'none'
    }
  })
)

export default singleWorkspaceStyles
