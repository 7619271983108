import React from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Box, createStyles, makeStyles } from '@material-ui/core'
// import { Delete, Edit } from '@material-ui/icons'

export const HoverMenu = (props) => {
  const { scale = 0 } = props
  const classes = useStyles({ scale: parseInt(scale) })
  return (
    <div className={classes.container}>
      <MoreVertIcon />
      <Box className={classes.iconsContainer}>{props.children}</Box>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      width: 'fit-content',
      cursor: 'pointer',
      // border: "1px solid transparent",
      display: 'inline-block',
      '& svg': {
        color: '#4f566b',
        fontSize: ({ scale }) => 16 + 0.5 * scale,
        boxSizing: 'content-box',
        '&:hover': {
          color: '#000'
        }
      },

      '&>svg': {
        // padding: '5px 10px',
        padding: ({ scale }) => `${5 + 0.5 * scale}px ${10 + 0.5 * scale}px`,
        border: '1px solid transparent',
        borderRadius: '0 5px 5px 0'
      },

      '&:hover': {
        '& svg': {
          backgroundColor: 'rgba(255,255,255, 0.6)'
        },
        '&>svg': {
          border: '1px solid #c6c6c6',
          cursor: 'default',
          color: 'rgb(79 86 107 / 59%)',

          '&:hover': {
            color: 'rgb(79 86 107 / 59%)'
          }
        },
        '&> $iconsContainer': {
          display: 'flex',
          transform: 'scaleX(1)  translateX(0)'
        }
      }
    },
    iconsContainer: {
      position: 'absolute',
      top: 0,
      right: ({ scale }) => 37 + 1.7 * scale,
      display: 'flex',
      transform: 'scaleX(0)  translateX(40px)',
      transitionTimingFunction: 'cubic-bezier(0.09, 0.35, 0.84, 1.19)',
      transition: '0.3s',
      transformOrigin: 'right',
      '&>svg': {
        cursor: 'pointer',
        padding: ({ scale }) => `${5 + 0.5 * scale}px ${10 + 0.5 * scale}px`,
        border: '1px solid #c6c6c6',
        borderStyle: 'solid solid solid none',
        // margin:'1px',
        '&:hover': {
          // color:'red'
        }
      },
      '&>svg:first-child': {
        borderRadius: '5px 0 0 5px',
        borderStyle: 'solid solid solid solid'

        // border:'1px solid grey'
      }
    }
  })
)

export default HoverMenu
