import { TextField } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { useDispatch, useSelector } from 'react-redux'
import { generateEmbed } from 'thunks/embed/action'
import { useHistory } from 'react-router'
import { getUserPath } from 'utils/userRoles'
import { LoadingButton } from 'global/globalComponents'
import embedStyles from './EmbedStyles'

const EmbedModalUI = ({ id, name, image, description, handleClose }) => {
  const classes = embedStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector(state => state.me)
  const { currentWorkspace } = useSelector(state => state.userWorkspaces)
  const [embedData, setEmbedData] = useState({ name: '', url: '' })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setEmbedData({ name: '', url: '' })
  }, [id])

  const saveEmbedLink = e => {
    e.preventDefault()
    if (!embedData.name) {
      return dispatch(fireErrorToaster('Please fill all the fields'))
    }

    if (id !== 'board' && !embedData.url) {
      return dispatch(fireErrorToaster('Please fill all the fields'))
    }

    setLoading(true)
    const data = {
      name: embedData.name,
      type: id,
      category: id === 'board' ? 'External' : currentWorkspace.category,
      workspace: currentWorkspace._id,
      embed_settings: { url: embedData.url }
    }

    if (
      embedData.url &&
      !(
        embedData.url.startsWith('https://') ||
        embedData.url.startsWith('http://')
      )
    ) {
      data.embed_settings.url = `https://${embedData.url}`
    }

    dispatch(generateEmbed(data, generateEmbedCallback))
  }

  const generateEmbedCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      handleClose()
      history.push(
        `${getUserPath(meData.data?.role)}/workspace/${
          currentWorkspace?._id
        }/${name.toLowerCase().replace(' ', '')}/${res._id}`
      )
    } else {
      dispatch(fireErrorToaster(res))
    }
  }

  return (
    <div className={classes.area}>
      <div className={classes.image}>{image}</div>
      <div className="px-8">
        <h3 className="text-base lg:text-lg text-left font-normal pb-4 text-gray-700">
          {name}
        </h3>
        <p className="text-smaller lg:text-sm text-left font-normal pb-4 text-gray-600 ">
          {description}
        </p>
        {/* <div className="flex pb-5">
          {embedObj.map((el, index) => (
            <div
              key={index.toString()}
              className={classes.embedBox}
              onClick={() => embedHandler(index)}
            >
              <div className="relative h-4 w-4 mb-2 ">
                <img src={el.image} />
              </div>
              <p className="relative text-xs font-medium">{el.name}</p>
            </div>
          ))}
        </div> */}
        <form onSubmit={saveEmbedLink}>
          {name.toLowerCase() !== 'board' && (
            <div>
              <p className="text-left text-sm font-base pb-2 text-gray-700">
                {name}
              </p>
              <TextField
                required
                className="w-full"
                style={{ backgroundColor: '#fff' }}
                value={embedData.url}
                placeholder="Enter URL"
                onChange={e =>
                  setEmbedData(prev => ({ ...prev, url: e.target.value }))
                }
              />
            </div>
          )}
          <div className="mt-3">
            <p className="text-left text-smaller lg:text-sm font-base pb-2 text-gray-700">
              {name} Name
            </p>
            <TextField
              required
              className="w-full"
              style={{ backgroundColor: '#fff' }}
              value={embedData.name}
              placeholder="Enter Name"
              onChange={e =>
                setEmbedData(prev => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div className="flex justify-end items-center mt-8">
            {/* <div className="flex items-center">
              <Checkbox size="small" />
              <p className="text-xs text-gray-600">Pin view</p>
            </div> */}
            <LoadingButton type="submit" loading={loading}>
              Add View
            </LoadingButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmbedModalUI
