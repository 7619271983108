import { makeStyles, createStyles } from '@material-ui/core'

const gettingStartedStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '880px'
    },

    videoCard: {
      display: 'flex',
      flexDirection: 'column',

      '& $videoOverlay, & img': {
        borderRadius: '0.25rem 0.25rem 0 0'
      },

      '@media (min-width: 950px)': {
        flexDirection: 'row',

        '& $videoOverlay, & img': {
          borderRadius: '0.25rem 0 0 0.25rem'
        }
      }
    },

    videoCardCol: {
      minWidth: '50%'
    },
    videoOverlay: {
      background: 'rgba(15, 15, 15, 0.2)'
    },
    videoPlayButton: {
      width: '50px',
      height: '50px',
      background: '#fff',
      borderRadius: '50%'
    },

    sideNav: {
      width: '265px',
      flexShrink: 0
    },
    sideNavTab: {
      height: '65px',
      userSelect: 'none'
    },
    activeTabLine: {
      display: 'inline-block',
      position: 'absolute',
      left: 0,
      height: 'calc(100% / 6)', // total six tabs are there (hardcoding it for now)
      width: '3px',
      transition: 'transform 300ms ease-in-out'
    },
    videoWrapper: {
      background: 'rgba(0, 0, 0, 0.5)',
      transition: 'opacity 500ms ease-in-out'
    }
  })
)

export default gettingStartedStyles
