import { CustomPopper, LoadingButton } from 'global/globalComponents'
import AssignedWorkspaces from './AssignedWorkspaces'
import React, { useEffect, useState, useMemo } from 'react'
import { Box, Popover, List, ListItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
import UserDashboardStyles from 'components/UserDashboard/userDashboardStyles'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { updateTeammate } from 'thunks/teammate/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

const ProjectsDropdown = ({ anchorEl, onClose, teammate, list }) => {
  const classes = UserDashboardStyles()
  const dispatch = useDispatch()
  const [userProjects, setUserProjects] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (teammate?.assignedWorkspace) {
      setUserProjects(teammate.assignedWorkspace)
    } else {
      setUserProjects([])
    }
  }, [teammate._id, teammate.assignedWorkspace])

  const projectsList = useMemo(() => {
    const userProjectsIds = userProjects?.map((item) => item._id)
    return list?.filter((item) => !userProjectsIds?.includes(item._id))
  }, [list, userProjects])

  const handleUpdateTeammate = () => {
    let bodyData = { removedWorkspace: [], newWorkspace: [] }
    // Removed Workspaces
    for (let ws of teammate?.assignedWorkspace) {
      const match = userProjects.find((item) => item._id === ws._id)

      if (!match) {
        bodyData.removedWorkspace.push(ws._id)
      }
    }

    // Added Workspaces
    for (let ws of userProjects) {
      const match = teammate.assignedWorkspace.find(
        (item) => item._id === ws._id
      )

      if (!match) {
        bodyData.newWorkspace.push(ws._id)
      }
    }

    setLoading(true)
    dispatch(
      updateTeammate(teammate._id, bodyData, (res, err) => {
        setLoading(false)
        onClose()
        if (!err) {
          dispatch(fireSuccessToaster('Saved Successfully'))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <CustomPopper anchorEl={anchorEl} onClose={onClose}>
      <div style={{ maxWidth: '300px' }}>
        <AssignedWorkspaces
          teammate={teammate}
          userProjects={userProjects}
          setUserProjects={setUserProjects}
          className="flex-wrap"
        />
        {/* <Box
          className={classes.colorsContainer}
          style={{
            borderTop:
              teammate?.assignedWorkspace?.length > 0
                ? '0.5px solid #e8ecee'
                : 'none',
            paddingTop: teammate?.assignedWorkspace?.length > 0 ? '10px' : 0
          }}
        >
          <input
            required
            placeholder="Search"
            style={{ outline: 'none', border: 'none' }}
            className="pl-2 text-sm"
            name="projects"
          />
        </Box> */}
        <div className={classes.iconScrollableWrapper}>
          {projectsList.map((item) => (
            <>
              <div
                className={`py-2 pl-2 text-sm text-primary-dark flex w-full flex justify-between items-center cursor-pointer ${classes.tags}`}
                onClick={() =>
                  setUserProjects((prev) => [
                    ...prev,
                    { _id: item._id, name: item.name }
                  ])
                }
                key={item._id}
              >
                <p className="capitalize">{item.name}</p>
              </div>
            </>
          ))}
        </div>
        <div className=" p-2">
          <LoadingButton
            size="small"
            onClick={handleUpdateTeammate}
            loading={loading}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </CustomPopper>
  )
}

export default ProjectsDropdown
