const FormLabel = props => {
  const { children, required = false, className, ...restProps } = props
  return (
    <label
      className={`block text-smaller lg:text-sm font-medium text-primary-mid-dark pb-1 ${
        required ? 'required' : ''
      } ${className}`}
      {...restProps}
    >
      {children}
    </label>
  )
}

export default FormLabel
