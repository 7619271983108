import { Button } from '@material-ui/core'
import { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(err) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) return <FallbackUI />
    return this.props.children
  }
}

const FallbackUI = () => {
  const handleReload = () => {
    window.location.reload(true) //hard refresh
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center text-center">
      <div style={{ maxWidth: 500 }}>
        <h2 className="text-sm">Error Occurred</h2>
        <p className="text-xs text-gray-500 mb-3">
          Reloading your browser might fix this issue. <br /> We would really
          appreciate if you could report this bug to us.
        </p>
        <Button size="small" variant="outlined" onClick={handleReload}>
          Reload
        </Button>
      </div>
    </div>
  )
}

export default ErrorBoundary
