import { useMemo } from 'react'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import { Button } from '@material-ui/core'
import { CustomTooltip } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import { fireSuccessToaster } from 'thunks/fireToaster/actions'
import { userRoles } from 'utils'

const TeammateOnboardingLinkModal = ({ open, handleClose }) => {
  const dispatch = useDispatch()
  const meData = useSelector((state) => state.me.data)
  const agencyId = useMemo(() => {
    if (meData.role === userRoles.USER_AGENCY) return meData._id
    return meData.team?._id
  }, [meData.role])

  const copyTeammateSignupLink = () => {
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/teammate-signup/${agencyId}`
    )

    dispatch(fireSuccessToaster('Link copied!'))
  }

  return (
    <CustomModal open={open} handleClose={handleClose} size="small">
      <CustomModalHeader heading="Team Onboarding Link" />

      <CustomModalBody>
        <p className="text-sm mb-4">
          Share this link with your team &amp; help them onboard quicker
        </p>

        <div className="rounded-lg p-4 border-2 mb-6">
          <div>
            <div className="flex justify-between items-center border px-2 py-2 rounded border-primary-main text-sm">
              <span className="truncate mr-2">{`${window.location.protocol}//${window.location.host}/teammate-signup/${agencyId}`}</span>
              <CustomTooltip title="Copy" placement="top">
                <button
                  onClick={copyTeammateSignupLink}
                  className="text-gray-500 hover:text-primary-main"
                >
                  <CopyIcon className="w-4 h-4" />
                </button>
              </CustomTooltip>
            </div>
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default TeammateOnboardingLinkModal
