import { useEffect, useState, useMemo, useRef } from 'react'
import React from 'react'
import FieldLabel from './FieldLabel'
import { useDispatch, useSelector } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { uploadAttachment } from 'thunks/task/actions'
import { AdvanceEditor } from 'global/globalComponents'
import { makeStyles } from '@material-ui/core'
// import { getQuillHTML } from 'utils'

const TaskDescription = ({
  taskId,
  taskDescription,
  taskPermission,
  updateDescription,
  handleUploadDescriptionAttachment
}) => {
  const classes = useStyles()
  const [description, setDescription] = useState('')
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const [updateReqCount, setUpdateReqCount] = useState(0)
  const [prevTaskId, setPrevTaskId] = useState('')
  const editorRef = useRef()

  const users = useMemo(() => {
    if (currentWorkspace.assignedAgencyTeam) {
      return [
        ...currentWorkspace.assignedAgencyTeam,
        ...currentWorkspace.assignedClientTeam
      ].map((item) => ({
        ...item.user,
        id: item.user._id,
        value: item.user.name
      }))
    }
  }, [currentWorkspace])

  useEffect(() => {
    if (taskId && taskId !== prevTaskId && editorRef.current) {
      setTimeout(() => {
        const editor = editorRef.current?.editor
        if (editor) {
          if (typeof taskDescription === 'string') {
            editor.setText(taskDescription)
          } else {
            editor.setContents(taskDescription)
          }
        }
      }, 100)

      setPrevTaskId(taskId)
    }
  }, [taskDescription, taskId, editorRef])

  useEffect(() => {
    if (updateReqCount) {
      let timer = setTimeout(() => {
        handleUpdateDescription()
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [updateReqCount])

  const imageHandler = (imageDataUrl, type, imageData) => {
    // imageData
    //   .minify({
    //     maxWidth: 320,
    //     maxHeight: 320,
    //     quality: 0.7
    //   })
    //   .then((miniImageData) => {
    //     console.log('MINI', miniImageData.dataUrl)
    //     // create a form data, and upload to the server...
    //   })

    const editor = editorRef.current.getEditor()
    let index = (editor.getSelection() || {}).index
    let loadingText = 'uploading...'

    if (index === undefined || index < 0) {
      index = editor.getLength()
    }

    editor.insertText(index, loadingText)
    handleUploadDescriptionAttachment(imageDataUrl, (res, err) => {
      if (err) return

      const editor = editorRef.current.getEditor()
      editor.deleteText(index, loadingText.length)
      editor.insertEmbed(
        index,
        'image',
        res.imageUrl[res.imageUrl.length - 1],
        'user'
      )

      setDescription(editor.getContents())
      setUpdateReqCount((prev) => prev + 1)
    })
    // dispatch(
    //   uploadAttachment(body, (res, err) => {

    //   })
    // )
  }

  const handleUpdateDescription = () => {
    if (currentWorkspace?.isComplete) {
      dispatch(
        fireErrorToaster(
          'This task belongs to a complete project and you can not change its description.'
        )
      )
    } else {
      if (updateDescription) {
        updateDescription(description)
      }
    }
  }

  // const handleDescriptionChange = (range, source, user, editor) => {
  //   const desc = getQuillHTML(description)

  //   console.log('DESCRIPTION CHANGESSSSSSS 11111111111111111')

  //   if (desc !== editor.getText()) {
  //     console.log('DESCRIPTION CHANGESSSSSSS 222222222222222')
  //     setDescription(editor.getContents())
  //     setUpdateReqCount((prev) => prev + 1)
  //   }
  // }

  const handleClick = (e) => {
    if (e.target.tagName === 'A' && e.target.href !== '') {
      const a = document.createElement('a')
      a.setAttribute('href', e.target.href)
      a.setAttribute('target', '_blank')
      a.click()
    }
  }

  const handleKeyPress = () => {
    setTimeout(() => {
      const editor = editorRef.current.editor
      setDescription(editor.getContents())
      setUpdateReqCount((prev) => prev + 1)
    }, 100)
  }

  return (
    <>
      <div className="mb-4">
        <FieldLabel>Description</FieldLabel>
      </div>
      <div className="text-sm" onClick={handleClick}>
        <AdvanceEditor
          ref={editorRef}
          // value={description}
          readOnly={!taskPermission.update}
          imageDrop={true}
          imageHandler={imageHandler}
          users={users}
          onKeyDown={handleKeyPress}
          handleKeyPress={handleKeyPress}
          // onChange={handleDescriptionChange}
          // onBlur={handleUpdateDescription}
          // onChangeSelection={handleSelection}
          className={classes.editor}
        />
      </div>
    </>
  )
}

const useStyles = makeStyles({
  editor: {
    '& .ql-editor': {
      minHeight: 260,
      overflow: 'auto'
    }
  }
})

export default React.memo(TaskDescription)
