import { Button } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import SectionHeading from 'global/globalComponents/SectionHeading'
import React, { useEffect, useState } from 'react'
import {
  // fetchClientStripeChekoutSession,
  // fetchClientStripeNonStripeChekoutSession,
  fetchPlans
} from 'thunks/clientService/action'
import clsx from 'clsx'
import { LOGIN } from 'thunks/login/reduers'
import { useDispatch, useSelector } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { withRouter } from 'react-router-dom'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { LoadingButton } from 'global/globalComponents'
import { Skeleton } from '@material-ui/lab'
// import Pill from 'global/globalComponents/Pill/Pill'
import useTitle from 'customHooks/useTitle'
import PaymentSuccessfulModal from 'components/AgencyPlans/PaymentSuccessfulModal'
import PaymentFailModal from 'components/AgencyPlans/PaymentFailModal'
import ServiceCard from 'global/globalComponents/ServiceCard/ServiceCard'
// import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import clientServiceStyles from './ClientServiceStyles'
import { userRoles } from 'utils'
import { generateInvoice } from 'thunks/invoices/actions'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const ClientServices = props => {
  const { location } = props
  const meData = useSelector(state => state.me)
  const classes = clientServiceStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true)
  const [allPlans, setAllPlans] = useState([])
  const [loading, setLoading] = useState(null)
  const [paymentStatusModal, setPaymentStatusModal] = useState({
    success: false,
    fail: false
  })

  useTitle(
    meData.data?.profile?.brand?.brandName ??
      meData.data?.team?.profile?.brand?.brandName,
    meData.data?.profile?.brand?.favicon ??
      meData.data?.team?.profile?.brand?.favicon
  )

  useEffect(() => {
    fetchPlans(planCallback)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const success = params.get('success')

    if (meData?.data?.path) {
      if (success === 'true' && meData.data.path === '/client/dashboard') {
        setPaymentStatusModal({ fail: false, success: true })
      } else if (
        success === 'false' &&
        meData.data.path !== '/client/dashboard'
      ) {
        setPaymentStatusModal({ fail: true, success: false })
      }
    }
  }, [location, meData?.data])

  const modalCloseHandler = type => {
    setPaymentStatusModal(prev => ({ ...prev, [type]: false }))
  }

  const planCallback = (res, err) => {
    setPageLoading(false)
    if (err) {
      dispatch(fireErrorToaster('Unsuccessful'))
    } else {
      setAllPlans(res)
    }
  }

  const handleLogout = () => {
    // localStorage.removeItem('authToken')
    // localStorage.removeItem('openTimeCounter')
    dispatch({ type: LOGIN.LOGOUT })
  }

  const selectPlanHandler = async plan => {
    if (meData.data.role !== userRoles.USER_CLIENT) return

    const now = new Date()
    const days =
      new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() || 30

    let data = {
      autoRepeat: plan.isRecurring ?? false,
      dueDate: new Date(now.setDate(now.getDate() + days)).toISOString(), //will change it soon,
      autoRepeatFrequency: 30, //will change it soon
      client: {
        clientName: meData.data.name,
        clientEmail: meData.data.email,
        clientId: meData.data._id
      },
      invoiceItems: {
        ...plan,
        name: plan.name,
        quantity: 1,
        rate: plan.amount * 0.01,
        total: plan.amount * 0.01
      },
      totalAmount: plan.amount * 0.01,
      type: 'service',
      currency: plan.currency.toUpperCase()
    }

    setLoading(plan._id)
    dispatch(generateInvoice(data, selectPlanHandlerCallback))

    // let domain = window.location.href
    //   .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    //   .split('/')[0]
    //   .replace('/', '')
    // if (plan.serviceType === 'stripe') {
    //   await fetchClientStripeChekoutSession(
    //     {
    //       user: meData.data,
    //       plan: plan,
    //       url: {
    //         success_url: `https://${domain}/select-service?success=true`,
    //         cancel_url: `https://${domain}/select-service?success=false`
    //       }
    //     },
    //     selectPlanHandlerCallback
    //   )
    // } else {
    //   await fetchClientStripeNonStripeChekoutSession(
    //     { plan },
    //     selectNonStripePlanHandlerCallback
    //   )
    // }
  }

  const selectPlanHandlerCallback = async (res, err) => {
    setLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      window.location.href = `${window.location.protocol}//${window.location.host}/i/${res._id}/checkout?vtID=${res.viewToken}`
      // if (res.url) {
      //   window.location.href = res.url
      // } else {
      //   const stripe = await stripePromise
      //   await stripe.redirectToCheckout({
      //     sessionId: res.id
      //   })
      // }
    }
  }

  const selectNonStripePlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster('Unable to select files'))
    } else {
      window.open(res.path, '_self')
    }
  }

  return (
    <div className="p-8">
      <div className={classes.headerContainer}>
        <div>{/* Logo */}</div>
        {meData.data.path === '/client/dashboard' && (
          <Button
            onClick={() => history.push(meData.data.path)}
            variant="outlined"
            style={{ marginRight: 16 }}
            button
          >
            Go to dashboard
          </Button>
        )}
        <Button
          onClick={handleLogout}
          style={{ marginRight: 16 }}
          variant="outlined"
        >
          Logout
        </Button>
      </div>
      <PaymentSuccessfulModal
        open={paymentStatusModal.success}
        path={'/client/dashboard?newClient=true&payment=true'}
      />
      <PaymentFailModal
        onClose={modalCloseHandler}
        open={paymentStatusModal.fail}
      />
      <div className={classes.bodyContainer}>
        <SectionHeading>Service Offerings</SectionHeading>
        <div className="mt-6 lg:mt-8">
          <div className="">
            {pageLoading ? (
              <PageLoadingSkeleton />
            ) : (
              <>
                {allPlans.length ? (
                  <ServiceCard
                    plans={allPlans.filter(plan => plan.displayOnCatalog)}
                    selectPlanHandler={selectPlanHandler}
                    loading={loading}
                  />
                ) : (
                  <p className="text-center">No plans available for now!</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ClientServices)

// const CustomTab = (props) => {
//   const { active = false, children, ...restprops } = props
//   return (
//     <button
//       {...restprops}
//       type="button"
//       className={clsx(
//         'ml-0.5 select-none relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none active:ring-2 active:ring-indigo-500 active:z-10 sm:w-auto sm:px-8',
//         { 'text-gray-900 bg-white': active }
//       )}
//     >
//       {children}
//     </button>
//   )
// }

const PageLoadingSkeleton = () => {
  return (
    <div>
      <div className="mb-8 flex items-center justify-center">
        <Skeleton variant="rect" width={310} height={44} />
      </div>
      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        {[...Array(4)].map((i, index) => (
          <div className="rounded-lg shadow-sm divide-y divide-gray-200">
            <Skeleton animation="wave" variant="rect" height={400} />
          </div>
        ))}
      </div>
    </div>
  )
}
