import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback
} from 'react'
import { SingleWorkspaceContext } from 'components/SingleWorkspace/singleWorkspaceContext'
import {
  // getTaskByWorkspaceBoardId,
  getListViewTasks,
  // updateTaskDetails,
  deleteTaskByID,
  deleteAttachment,
  // uploadAttachment,
  fetchTaskTableColumns,
  createNewColumn,
  deleteTaskColumn,
  updateDynamicField,
  // deleteTaskCollaborator,
  // deleteTaskDetailsteam,
  uploadDynamicColAttachment,
  deleteDynamicColAttachment,
  removeTaskFromList
} from 'thunks/task/actions'
import {
  useParams,
  useHistory,
  // useRouteMatch,
  useLocation
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AddColumnPopover from './AddColumnPopover'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import PersonCell from './PersonCell'
import DescriptionCell from './DescriptionCell'
import DateCell from './DateCell'
import PhoneCell from './PhoneCell'
import AttachmentCell from './AttachmentCell'
import {
  // Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover
} from '@material-ui/core'
import ReactTable from './ReactTable'
import EditableCell from './EditableCell'
import UrlCell from './UrlCell'
// import clsx from 'clsx'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
// import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
// import styles from './table.module.css'
import TaskPanel from 'components/Workspace/Panel/TaskPanel'
import { hideChatMessenger, userRoles } from 'utils'
import NumbersCell from './NumbersCell'
import EmailCell from './EmailCell'
import CheckboxCell from './CheckboxCell'
import SelectCell from './SelectCell'
import { handleFilePreview } from 'thunks/generlAppState/actions'
import NewTaskModal from './NewTaskModal'
import useWorkspaceFunctions from 'components/Workspace/useWorkspaceFunctions'
import StatusCell from './StatusCell'
import PriorityCell from './PriorityCell'
import { Layout } from 'global/globalComponents'
// import WorkspacePusherContext from 'components/Pusher/WorkspacePusherContext'
// import { fetchProjectListView } from 'thunks/views/actions'
// import TagsCell from './TagsCell'

// export const TableContext = React.createContext({})

const TableView = () => {
  const dispatch = useDispatch()
  const {
    workspaceState: { permission, showApprovalTasks, groupBy, filters }
  } = useContext(SingleWorkspaceContext)
  const { taskPermission, fetched } = permission
  const classes = useStyles()
  const [columns, setColumns] = useState([])
  const [dynamicCols, setDynamicCols] = useState([])
  const { boardId, workspaceId, taskId } = useParams()
  const [tableData, setTableData] = useState([])
  const search = useSelector(state => state.generalAppState.boardSearch)
  const { data: boardNewTasksData, listViewTasks } = useSelector(
    state => state.userTasks
  )

  const [tableLoading, setTableLoading] = useState(true)
  const [loadingMoreData, setLoadingMoreData] = useState(false)

  const [addColumnPopup, setAddColumnPopup] = useState({
    anchorEl: null,
    data: {}
  })
  const addColumnPopupRef = useRef()
  const [columnOptionsPopup, setColumnOptionsPopup] = useState({
    anchorEl: null,
    data: null
  })
  // const [loading, setLoading] = useState(true)
  const [deleteTaskAlert, setDeleteTaskAlert] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [deleteColAlert, setDeleteColAlert] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [taskPanel, setTaskPanel] = useState({
    open: false,
    data: {}
  })
  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false)

  const history = useHistory()
  const { pathname } = useLocation()

  const [allTasksData, setAllTasksData] = useState([])
  // const currentWorkspace = useSelector(
  //   (state) => state.userWorkspaces.currentWorkspace
  // )
  // const currentWorkspaceBoard = useSelector(
  //   (state) => state.userWorkspaces.currentBoard
  // )
  // const approvalTasks = useSelector((state) => state.approvals.data.tasks)
  // const { socketId } = useContext(WorkspacePusherContext)
  const [page, setPage] = useState(0)
  // const [allPendingTasks, setAllPendingTasks] = useState([])
  const {
    // getTaskById,
    // getSearchedTasks,
    changeTaskSection,
    changeTaskPriority,
    addTaskCollaborator,
    removeTaskCollaborator,
    updateTaskProperty,
    uploadTaskAttachment,
    handleTaskDateChange,
    canUpdateTask
  } = useWorkspaceFunctions(groupBy)

  useEffect(() => {
    setAllTasksData(
      listViewTasks.data
        .filter(taskId => boardNewTasksData[taskId])
        .map(taskId => boardNewTasksData[taskId])
    )
  }, [listViewTasks.data, boardNewTasksData])

  useEffect(() => {
    if (filters.loading) return
    if (workspaceId && boardId) {
      handleFetchListViewTasks()
    }
  }, [showApprovalTasks, workspaceId, boardId, search, filters])

  const handleFetchListViewTasks = (cb, pageCount = 0) => {
    const body = {
      workspace: workspaceId,
      workspaceBoard: boardId,
      myApprovals: showApprovalTasks,
      search: search || null
    }

    if (filters.data._id) {
      body.filters = {
        op: filters.data.op,
        fields: filters.data.fields
      }
    }
    // incrementRequestCount()
    dispatch(
      getListViewTasks(
        body,
        {
          limit: 20,
          page: pageCount,
          type: 'list'
        },
        cb
      )
    )
  }

  // ======= Setting up columns =========
  useEffect(() => {
    fetchTaskTableColumns(boardId, (res, err) => {
      if (!err) {
        setDynamicCols(res)
        const cols = mapColsData(res)
        setColumns([...staticColumns, ...cols])
      }
    })
  }, [])

  useEffect(() => {
    if (!listViewTasks.loading) {
      // 1. Set Columns
      const tasks = []

      // expected tasks body
      // [{taskColumnId: value, taskColumnId2: value}, {taskColumnId: value, taskColumnId2: value}]

      const obj = {}

      // 1. Setting dynamic cols cells
      for (let col of dynamicCols) {
        obj[col._id] = ''
      }

      for (let task of allTasksData) {
        // 2. Adding values to static cols cells
        const obj2 = {
          ...obj,
          ...task,
          taskId: task._id,
          collaborators: [
            ...task.assignedAgencyTeam,
            ...task.assignedClientTeam
          ]
        }

        // 3. Adding values to dynamic cols cells
        if (task.dynamicField) {
          for (let data of task.dynamicField) {
            obj2[data.taskColumnId] = data.value
          }
        }

        tasks.push(obj2)
      }

      setTableData(tasks)
      setTimeout(() => {
        setTableLoading(false)
      }, 1000)
    } else {
      setTableLoading(true)
    }
  }, [allTasksData, columns, dynamicCols])

  useEffect(() => {
    if (!taskId) return setTaskPanel({ open: false, data: {} })
    const task = boardNewTasksData[taskId]

    if (task) {
      setTaskPanel({ open: true, data: task })
    }
  }, [taskId, boardNewTasksData])

  useEffect(() => {
    hideChatMessenger()
  })

  const updateDynamicColData = useCallback(
    function ({ rowIndex, columnId, value, taskId }) {
      const task = allTasksData.find(item => item._id === taskId)
      const dynamicFields = task.dynamicField ? [...task.dynamicField] : []

      const index = dynamicFields.findIndex(
        item => item.taskColumnId === columnId
      )

      if (index === -1) {
        dynamicFields.push({
          taskColumnId: columnId,
          value
        })
      } else {
        dynamicFields.splice(index, 1, {
          taskColumnId: columnId,
          value: value
        })
      }

      dispatch(
        updateDynamicField(
          taskId,
          workspaceId,
          dynamicFields,
          updateTaskCallback
        )
      )
    },
    [allTasksData]
  )

  const handleUploadDynamicAttachment = useCallback(
    function ({ columnId, value, taskId, cb }) {
      const task = allTasksData.find(item => item._id === taskId)
      const dynamicFields = task.dynamicField ? [...task.dynamicField] : []

      const index = dynamicFields.findIndex(
        item => item.taskColumnId === columnId
      )

      if (index === -1) {
        dynamicFields.push({
          taskColumnId: columnId,
          value: value
        })
      } else {
        dynamicFields.splice(index, 1, {
          taskColumnId: columnId,
          value: value
        })
      }

      dispatch(
        uploadDynamicColAttachment(
          taskId,
          workspaceId,
          dynamicFields,
          (res, err) => {
            cb(res, err)
            updateTaskCallback(res, err)
          }
        )
      )
    },
    [allTasksData]
  )

  // const handleDeleteDynamicAttachment = useCallback(
  //   ({ rowIndex, columnId, value, taskId }) => {
  //     dispatch(deleteAttachment(taskId, value))
  //   },
  //   [allTasksData.length]
  // )

  const handleDeleteAttachment = useCallback(
    ({ rowIndex, columnId, value, taskId, fieldType }) => {
      if (fieldType === 'static') {
        dispatch(deleteAttachment(taskId, value))
      } else {
        dispatch(deleteDynamicColAttachment(taskId, value, columnId))
      }
    },
    [allTasksData.length]
  )

  // const handleRemoveAssignee = useCallback(
  //   ({ rowIndex, columnId, teammate, taskId }) => {
  //   removeTaskCollaborator({
  //     userRole: teammate.role,
  //     userId: teammate._id,
  //     taskId
  //   })
  // },
  //   [allTasksData.length]
  // )

  const openOptionsPopup = e => {
    e.stopPropagation()
    setColumnOptionsPopup({
      data: e.currentTarget.dataset.id,
      anchorEl: e.currentTarget
    })
  }

  // const getTableHeader = (text, columnId) => {
  //   return (
  //     <div className="flex items-center justify-between">
  //       <span>{text}</span>
  //       <button onClick={openOptionsPopup} className="p-1" data-id={columnId}>
  //         <VerticalThreeDots />
  //       </button>
  //     </div>
  //   )
  // }

  const mapColsData = data => {
    // return data.map((item) => ({ dataField: item.name, caption: item.name }))
    return data.map(item => {
      // const dType = dataTypes[item.datatype]
      let cellRender = EditableCell

      switch (item.datatype) {
        case 'person':
          cellRender = PersonCell
          break
        case 'date':
          cellRender = DateCell
          break
        case 'phone':
          cellRender = PhoneCell
          break
        case 'numbers':
          cellRender = NumbersCell
          break
        case 'attachment':
          cellRender = AttachmentCell
          break
        case 'multiline':
          cellRender = DescriptionCell
          break
        case 'url':
          cellRender = UrlCell
          break
        case 'email':
          cellRender = EmailCell
          break
        case 'checkbox':
          cellRender = CheckboxCell
          break
        case 'singleSelect':
          cellRender = SelectCell
          break
        case 'multiSelect':
          cellRender = SelectCell
          break
        default:
          cellRender = EditableCell
      }

      return {
        ...item,
        Header: item.name,
        accessor: item._id,
        Cell: cellRender,
        fieldType: 'dynamic',
        datatype: item.datatype
      }
    })
  }

  const updateTaskCallback = (res, err) => {
    if (err) dispatch(fireErrorToaster(res))
  }

  const handleAddNewColumn = ({ name, datatype, metaData = {} }) => {
    addColumnPopupRef.current.setLoading(true)
    const data = {
      board: boardId,
      workspace: workspaceId,
      name,
      datatype
    }

    if (Object.values(metaData).length) {
      data.metaData = metaData
    }

    createNewColumn(data, addColumnCallback)
  }

  const handleDeleteColumn = () => {
    setDeleteColAlert(prev => ({ ...prev, loading: true }))
    deleteTaskColumn(columnOptionsPopup.data, (res, err) => {
      if (!err) {
        const filteredColumns = columns.filter(
          item => item._id !== columnOptionsPopup.data
        )
        setColumns(filteredColumns)
        setDeleteColAlert(prev => ({ ...prev, loading: false, open: false }))
      } else {
        setDeleteColAlert(prev => ({ ...prev, loading: false }))
      }
    })
  }

  const addColumnCallback = (res, err) => {
    addColumnPopupRef.current.setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      const arr = mapColsData([res])
      setColumns(prev => [...prev, ...arr])
      toggleAddColumnPopup() //close popup
    }
  }

  const toggleAddColumnPopup = e => {
    if (addColumnPopup.anchorEl) {
      setAddColumnPopup(prev => ({ ...prev, anchorEl: null }))
    } else {
      // e.stopPropagation() // don't remove it or else popup won't open (it will close in instant)
      setAddColumnPopup(prev => ({ ...prev, anchorEl: e.currentTarget }))
    }
  }

  const handleCloseOptionsPopup = () => {
    setColumnOptionsPopup(prev => ({ ...prev, anchorEl: null }))
  }

  const handleDeleteTask = () => {
    setDeleteTaskAlert(prev => ({ ...prev, loading: true }))
    dispatch(deleteTaskByID(deleteTaskAlert.data._id, deleteTaskCallback))
  }

  const deleteTaskCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
      setDeleteTaskAlert(prev => ({ ...prev, loading: false }))
    } else {
      setDeleteTaskAlert(prev => ({ ...prev, loading: false, open: false }))
      dispatch(fireSuccessToaster('Task deleted successfully!'))
    }
  }

  const openDeleteTaskAlert = data => {
    setDeleteTaskAlert({ open: true, data, loading: false })
  }

  const closeDeleteTaskAlert = () => {
    setDeleteTaskAlert(prev => ({ ...prev, open: false, loading: false }))
  }

  const openDeleteColAlert = data => {
    handleCloseOptionsPopup()
    setDeleteColAlert({ open: true, data })
  }

  const closeDeleteColAlert = () => {
    setDeleteColAlert(prev => ({ ...prev, open: false, loading: false }))
  }

  const handleOpenTaskSidePanel = useCallback(
    task => {
      history.push(`${history.location.pathname}/task/${task._id}?view=list`)
    },
    [history]
  )

  const handleCloseTaskSidePanel = () => {
    history.push(pathname.split('/task')[0] + '?view=list')
  }

  const handlePreviewAttachment = useCallback(file => {
    dispatch(handleFilePreview({ open: true, file }))
  }, [])

  const toggleCreateTaskModal = () => {
    setCreateTaskModalOpen(prev => !prev)
  }

  const checkPermissionBeforeUpdate = fn => {
    return props => {
      if (!canUpdateTask(taskPermission.update)) return
      return fn(props)
    }
  }

  const afterTaskDelete = useCallback(({ taskId, sectionId }) => {
    dispatch(removeTaskFromList({ taskId }))
  }, [])

  const loadMoreTasks = cb => {
    setLoadingMoreData(true)
    handleFetchListViewTasks((res, err) => {
      if (cb) cb(res, err)
      setLoadingMoreData(false)
    }, page + 1)
    setPage(prev => prev + 1)
  }

  return (
    <Layout>
      {fetched && (
        <ReactTable
          columnsData={columns}
          data={tableData}
          updateDynamicColData={updateDynamicColData}
          // updateStaticColData={updateStaticColData}
          handleDeleteAttachment={handleDeleteAttachment}
          // handleUploadAttachment={handleUploadAttachment}
          uploadTaskAttachment={checkPermissionBeforeUpdate(
            uploadTaskAttachment
          )}
          handleTaskDateChange={checkPermissionBeforeUpdate(
            handleTaskDateChange
          )}
          handleOpenTaskSidePanel={handleOpenTaskSidePanel}
          addTaskCollaborator={addTaskCollaborator}
          removeTaskCollaborator={removeTaskCollaborator}
          updateTaskProperty={checkPermissionBeforeUpdate(updateTaskProperty)}
          // handleRemoveAssignee={handleRemoveAssignee}
          changeTaskSection={checkPermissionBeforeUpdate(changeTaskSection)}
          loading={tableLoading}
          openDeleteTaskAlert={openDeleteTaskAlert}
          openOptionsPopup={openOptionsPopup}
          handleUploadDynamicAttachment={handleUploadDynamicAttachment}
          handlePreviewAttachment={handlePreviewAttachment}
          classes={classes}
          toggleCreateTaskModal={toggleCreateTaskModal}
          toggleAddColumnPopup={toggleAddColumnPopup}
          changeTaskPriority={checkPermissionBeforeUpdate(changeTaskPriority)}
          taskPermission={taskPermission}
          totalTasks={listViewTasks.metadata?.total ?? null}
          totalVisibleTasks={allTasksData.length}
          loadMoreTasks={loadMoreTasks}
          loadingMoreData={loadingMoreData}
        />
      )}
      <AddColumnPopover
        anchorEl={addColumnPopup.anchorEl}
        handleClose={toggleAddColumnPopup}
        handleAddNewColumn={handleAddNewColumn}
        ref={addColumnPopupRef}
      />

      <ColumnHeaderPopover
        anchorEl={columnOptionsPopup.anchorEl}
        onClose={handleCloseOptionsPopup}
        handleDeleteColumn={handleDeleteColumn}
        openDeleteColAlert={openDeleteColAlert}
      />
      <AlertModal
        open={deleteTaskAlert.open}
        warningText={`Do you want to DELETE ${deleteTaskAlert.data.title} task?`}
        handleDeleteAction={handleDeleteTask}
        handleDialog={closeDeleteTaskAlert}
        loading={deleteTaskAlert.loading}
      />
      <AlertModal
        open={deleteColAlert.open}
        warningText={`Deleting this column would delete all its related cells data too. Do you still want to delete this column?`}
        handleDeleteAction={handleDeleteColumn}
        handleDialog={closeDeleteColAlert}
        loading={deleteColAlert.loading}
      />
      <TaskPanel
        taskData={taskPanel.data}
        open={taskPanel.open}
        onClose={handleCloseTaskSidePanel}
        afterTaskDelete={afterTaskDelete}
      />
      <NewTaskModal
        open={createTaskModalOpen}
        handleClose={toggleCreateTaskModal}
      />
    </Layout>
  )
}

const ColumnHeaderPopover = ({ anchorEl, onClose, openDeleteColAlert }) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <div>
        <List dense>
          <ListItem button onClick={openDeleteColAlert}>
            <ListItemText>Delete</ListItemText>
          </ListItem>
        </List>
      </div>
    </Popover>
  )
}

const useStyles = makeStyles({
  avatarRoot: {
    width: 27,
    height: 27,
    fontSize: 12,
    marginLeft: -2,
    marginTop: -1
  }
})

const staticColumns = [
  {
    Header: 'Title',
    accessor: 'title',
    Cell: EditableCell,
    fieldType: 'static',
    datatype: 'text'
  },
  {
    Header: 'Status',
    accessor: 'taskSection',
    Cell: StatusCell,
    fieldType: 'static',
    datatype: 'badge'
  },
  {
    Header: 'Collaborators',
    accessor: 'collaborators',
    Cell: PersonCell,
    fieldType: 'static',
    datatype: 'person'
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    Cell: PriorityCell,
    fieldType: 'static',
    datatype: 'flag'
  },
  // {
  //   Header: 'Tags',
  //   accessor: 'tags',
  //   Cell: TagsCell,
  //   fieldType: 'static',
  //   datatype: 'tag'
  // },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: DateCell,
    fieldType: 'static',
    datatype: 'date'
  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
    Cell: DateCell,
    fieldType: 'static',
    datatype: 'date'
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: DescriptionCell,
    fieldType: 'static',
    datatype: 'multiline'
  },
  {
    Header: 'Attachments',
    accessor: 'imageUrl',
    Cell: AttachmentCell,
    fieldType: 'static',
    datatype: 'attachment'
  }
]

export default TableView
