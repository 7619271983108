import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPrioritizedTask
  // getPrioritizedTaskWithDates
} from 'thunks/task/actions'
// import { userRoles } from 'utils'
import { useCallback } from 'react'
import WidgetHeader from './WidgetHeader'
import WidgetTable from './WidgetTable'
import moment from 'moment'
import { getClient } from 'thunks/addClient/actions'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import TaskPanel from 'components/Workspace/Panel/TaskPanel'

const ConsolidatedTasksWidget = ({
  widgetId,
  editMode,
  handleRemoveWidget
}) => {
  const dispatch = useDispatch()
  const {
    data: priorityTasksData,
    // fetched: priorityTasksFetched,
    loading: priorityTasksLoading
  } = useSelector((state) => state.userTasks.priorityTasks)
  const [allTasks, setAllTasks] = useState({
    loading: true,
    data: []
  })
  const [filteredTasks, setFilteredTasks] = useState({
    loading: true,
    data: []
  })
  const [page, setPage] = useState(1)
  const userClients = useSelector((state) => state.userClients)
  const { fetched: workspacePermissionFetched } = useSelector(
    (state) => state.workspacePermission
  )
  const [taskPanel, setTaskPanel] = useState({
    open: false,
    data: {
      title: '',
      workSpace: '',
      taskSection: {},
      createdAt: `${new Date()}`,
      startDate: '',
      dueDate: '',
      description: '',
      assignedAgencyTeam: [],
      assignedClientTeam: [],
      imageUrl: [],
      comment: '',
      status: null,
      user: {},
      estimateTimeInMillisecond: '',
      estimateTimeInString: ''
    },
    permission: {},
    subtaskPermission: {}
    // project: {},
    // board: {}
  })

  useEffect(() => {
    if (!priorityTasksLoading) {
      const savedFilters = window.localStorage.getItem('taskFilter_' + widgetId)

      const filteredData = getFilteredData(
        priorityTasksData,
        savedFilters ? JSON.parse(savedFilters) : []
      )
      setAllTasks({ loading: false, data: filteredData })
    }
  }, [priorityTasksData])

  useEffect(() => {
    if (!workspacePermissionFetched) {
      dispatch(fetchWorkspacePermissions())
    }
  }, [workspacePermissionFetched])

  useEffect(() => {
    if (!allTasks.loading) {
      setFilteredTasks({
        loading: false,
        data: allTasks.data.slice(0, page * 15)
      })
    }
  }, [allTasks, page])
  // const meData = useSelector((state) => state.me.data)

  // const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    dispatch(
      getPrioritizedTask((res, err) => {
        if (!err) {
          const savedFilters = window.localStorage.getItem(
            'taskFilter_' + widgetId
          )

          const filteredData = getFilteredData(
            res,
            savedFilters ? JSON.parse(savedFilters) : []
          )
          setAllTasks({ loading: false, data: filteredData })
        }
      })
    )
  }, [])

  useEffect(() => {
    if (!userClients.fetched) {
      dispatch(getClient())
    }
  }, [userClients.fetched])

  // console.log('PRIORITY TASKS', priorityTasks)

  // useEffect(() => {
  //   const endDate = new Date().setHours(23, 59, 0)
  //   const startDate = new Date(endDate - 30 * 24 * 3600 * 1000 + 60000)
  //   if (
  //     [
  //       userRoles.USER_AGENCY,
  //       userRoles.AGENCY_ADMIN
  //       // userRoles.USER_CLIENT
  //     ].includes(meData.role)
  //   ) {
  //     dispatch(
  //       getPrioritizedTaskWithDates(
  //         new Date(endDate).toISOString(),
  //         new Date(startDate).toISOString()
  //       )
  //     )
  //   } else {
  //     dispatch(getPrioritizedTask())
  //   }
  // }, [])

  // useEffect(() => {
  //   setFilteredTasks(priorityTasksData.filter((task) => !task.markAsComplete))
  // }, [priorityTasks])

  // useEffect(() => {
  //   if (selectedTab === null) return

  //   switch (selectedTab) {
  //     case 1:
  //       // overdue
  //       setFilteredTasks(
  //         priorityTasksData.filter(
  //           (item) => item.dueDate && new Date(item.dueDate) < new Date()
  //         )
  //       )
  //       break

  //     default:
  //       setFilteredTasks(priorityTasksData.filter((task) => !task.markAsComplete))
  //   }
  // }, [selectedTab])

  // const handleTabChange = (e, newVal) => {
  //   setSelectedTab(newVal)
  //   // setSelectedOption(null)
  // }

  const handleDeleteWidgetClick = useCallback(() => {
    handleRemoveWidget(widgetId)
  }, [])

  const taskDateFilter = ({ tasks, field, value }) => {
    const op = value.valueOf().slice(0, 1)
    const val = value.valueOf().slice(1)
    const today = moment().format('ll')

    return tasks.filter((item) => {
      if (!item[field]) return false
      const taskFieldDate = moment(item[field]).format('ll')

      if (op === '=')
        return moment(taskFieldDate).isSame(moment(today).format('ll'))
      else if (op === '-')
        return (
          moment(taskFieldDate).isSameOrAfter(moment(val)) &&
          moment(taskFieldDate).isBefore(moment(today))
        )
      else
        return (
          moment(taskFieldDate).isSameOrBefore(moment(val)) &&
          moment(taskFieldDate).isAfter(moment(today))
        )
    })
  }

  const taskPriorityFilter = ({ tasks, op, values }) => {
    return tasks.filter((task) => {
      return op === 'EQ'
        ? values.includes(task.priority)
        : !values.includes(task.priority)
    })
  }

  const taskTeammatesFilter = ({ tasks, field, op, values }) => {
    return tasks.filter((task) => {
      if (op === 'NE' && task[field].length === 0) return true

      const idx = task[field].findIndex((item) => {
        if (op === 'EQ') return values.includes(item._id)
        return !values.includes(item._id)
      })

      return idx !== -1
    })
  }

  const taskProjectsFilter = ({ tasks, op, values }) => {
    const res = tasks.filter((task) => {
      if (op === 'EQ') return values.includes(task.workSpace._id)
      return !values.includes(task.workSpace._id)
    })

    return res
  }

  const getFilteredData = (tasks, filters) => {
    let filteredData = [...tasks]

    for (let i = 0; i < filters.length; i++) {
      const { field, op, values } = filters[i]

      if (field === 'startDate' || field === 'dueDate') {
        filteredData = taskDateFilter({
          tasks: filteredData,
          field,
          value: values[0]
        }) //date filter will always have single value
        continue
      }

      if (field === 'priority') {
        filteredData = taskPriorityFilter({ tasks: filteredData, values, op })
        continue
      }

      if (
        field === 'assignedAgencyTeam' ||
        field === 'assignedClientTeam' ||
        field === 'user'
      ) {
        filteredData = taskTeammatesFilter({
          tasks: filteredData,
          field,
          values,
          op
        })
        continue
      }

      if (field === 'projects') {
        filteredData = taskProjectsFilter({ tasks: filteredData, op, values })
      }
    }

    return filteredData
  }

  const handleApplyFilters = (filters) => {
    const filteredData = getFilteredData(priorityTasksData, filters)

    setAllTasks({ loading: false, data: filteredData })
  }

  const loadMoreTasks = () => {
    setPage((prev) => prev + 1)
  }

  const removeTaskById = useCallback((taskId) => {
    setAllTasks((prev) => ({
      loading: false,
      data: prev.data.filter((item) => item._id !== taskId)
    }))
  }, [])

  const updateSingleTask = useCallback((task) => {
    setAllTasks((prev) => ({
      loading: false,
      data: prev.data.map((item) => (item._id === task._id ? task : item))
    }))
  }, [])

  const openEditTaskPanel = useCallback(
    ({ taskData, taskPermission, subtaskPermission }) => {
      setTaskPanel({
        open: true,
        data: taskData,
        permission: taskPermission,
        subtaskPermission
      })
    },
    []
  )

  const closeEditTaskPanel = () => {
    setTaskPanel((prev) => ({ ...prev, open: false }))
  }

  return (
    <>
      <WidgetHeader
        tasksCount={allTasks.data.length}
        handleDeleteWidgetClick={handleDeleteWidgetClick}
        editMode={editMode}
        handleApplyFilters={handleApplyFilters}
        widgetId={widgetId}
      />
      <WidgetTable
        isLoading={priorityTasksLoading}
        tasksCount={allTasks.data.length}
        tasks={filteredTasks.data}
        loadMoreTasks={loadMoreTasks}
        removeTaskById={removeTaskById}
        updateSingleTask={updateSingleTask}
        openEditTaskPanel={openEditTaskPanel}
      />
      <TaskPanel
        taskData={taskPanel.data}
        open={taskPanel.open}
        onClose={closeEditTaskPanel}
        // taskPermission={taskPanel.permission}
        // subtaskPermission={taskPanel.subtaskPermission}
        // groupBy={'status'}
        inDashboardArea={true}
        isOutsideBoardArea={true}
        // currentWorkspace={taskPanel.project}
        // currentBoard={taskPanel.board}
      />
    </>
  )
}

export default React.memo(ConsolidatedTasksWidget)
