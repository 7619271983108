import React from 'react'
import clsx from 'clsx'
import { ReactComponent as ArrowRight } from 'static/svg/angle-right.svg'

const Pagination = ({
  page,
  handlePagination,
  totalResultsCount,
  limit = 5,
  shadow = 'lg'
}) => {
  const totalBtns = Math.ceil(totalResultsCount / limit)

  const paginate = e => {
    const type = e.currentTarget.dataset.type

    if (type === 'next') {
      handlePagination(page + 1)
    } else {
      handlePagination(page + -1)
    }
  }

  const jumpTo = e => {
    handlePagination(Number(e.target.dataset.page))
  }

  return (
    <nav
      className={`bg-white rounded-xl py-4 flex items-center justify-between pr-12 shadow-${shadow}`}
      aria-label="Pagination"
    >
      <div className="flex-1 flex justify-end text-smaller lg:text-sm">
        <button
          data-type="prev"
          onClick={paginate}
          disabled={page === 0}
          className={clsx(
            'flex items-center font-semibold mr-4',
            page === 0 ? 'text-primary-mid-light' : 'text-primary-main'
          )}
        >
          <ArrowRight className="rotate-180 text-lg mt-0.5" />
          <span>Previous</span>
        </button>

        <div className="flex items-center space-x-2">
          {Array(totalBtns)
            .fill('')
            .map((item, index) => (
              <PaginationButton
                key={index}
                index={index}
                isActive={page === index}
                onClick={jumpTo}
              />
            ))}
        </div>

        <button
          data-type="next"
          onClick={paginate}
          disabled={(page + 1) * limit + 1 > totalResultsCount}
          className={clsx(
            'flex items-center font-semibold ml-4',
            (page + 1) * limit + 1 > totalResultsCount
              ? 'text-primary-mid-light'
              : 'text-primary-main'
          )}
        >
          <span>Next</span>
          <ArrowRight className="text-lg mt-0.5" />
        </button>
      </div>
    </nav>
  )
}

const PaginationButton = ({ index, isActive, onClick }) => {
  return (
    <div
      className={clsx(
        'w-6 h-6  rounded-lg flex justify-center items-center cursor-pointer font-semibold',
        isActive ? 'text-primary-main bg-primary-light' : 'text-mid-dark-2'
      )}
      data-page={index}
      onClick={onClick}
    >
      {index + 1}
    </div>
  )
}

export default React.memo(Pagination)
