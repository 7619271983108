import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Popover
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { ReactComponent as CheckIcon } from 'static/svg/check-icon.svg'
// import clsx from 'clsx'
import { CustomFlag } from '..'

const PriorityDropDown = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    currentPriority,
    handlePriorityLabel,
    ...rest
  } = props
  // const classes = useStyles()
  const [newPriorityLabels, setNewPriorityLabels] = useState([priorityLabels])

  useEffect(() => {
    if (currentPriority === 5 || (!currentPriority && currentPriority !== 0)) {
      setNewPriorityLabels(priorityLabels.slice(0, 4))
    } else {
      setNewPriorityLabels(priorityLabels)
    }
  }, [currentPriority])

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      {...rest}
    >
      <List dense>
        {newPriorityLabels?.map((item, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handlePriorityLabel(item)}
          >
            {typeof currentPriority !== 'undefined' && (
              <ListItemIcon style={{ minWidth: 25 }}>
                <span>
                  {currentPriority == item.order && (
                    <CheckIcon className="text-sm" />
                  )}
                </span>
              </ListItemIcon>
            )}
            <ListItemText>
              <span className="flex items-center space-x-2">
                <CustomFlag priority={item.order} />
                <span style={{ fontSize: 13 }}>{item.label}</span>
              </span>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Popover>
  )
}

export const priorityColors = [
  'rgb(245, 0, 0)',
  'rgb(255, 204, 0)',
  'rgb(111, 221, 255)',
  'rgb(216, 216, 216)',
  'rgb(216, 216, 216)'
]

export const priorityLabels = [
  { label: 'Urgent', order: 1 },
  { label: 'High', order: 2 },
  { label: 'Medium', order: 3 },
  { label: 'Low', order: 4 },
  { label: 'clear', order: 5 }
]

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    minWidth: '150px'
  }
}))

export default PriorityDropDown
