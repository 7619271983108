import { makeStyles, createStyles } from '@material-ui/core'

const workspaceStyles = makeStyles(theme =>
  createStyles({
    popoverPaper: {
      minWidth: '150px'
    },
    icons: {
      color: '#6f7782',
      transition: '0.2s transform',
      '&.expanded': {
        transform: 'rotate(180deg)'
      }
    },
    roundCheckbox: {
      borderRadius: '50%'
    },
    paper: {
      width: '50vw'
    },
    dialogPaper: {
      padding: theme.spacing(2),
      width: '30vw'
    },
    // overview: {
    //   // width: `calc(100% - ${theme.custom.logsDrawer.width}px)`
    // },
    fieldWrapper: {
      display: 'flex',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontSize: 14,
      alignItems: 'center',
      '&> :first-child': {
        width: '30%',
        color: 'rgba(20, 20, 43, 0.39)'
      }
    },
    headerIconsContainer: {
      display: 'flex',
      gap: theme.spacing(1),
      '&>div': {
        borderRadius: theme.shape.borderRadius,
        width: 40,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F2F2F2'
      }
    },
    closeBtn: {
      transform: 'rotate(45deg)',
      color: 'rgba(77,124,254,1)',
      fontSize: 26,
      width: 20,
      height: 20,
      lineHeight: '0.5',
      userSelect: 'none'
    },
    avatarRoot: {
      width: '1.95rem',
      height: '1.95rem',
      fontSize: '0.9rem',
      textTransform: 'capitalize'
    },
    logAvatar: {
      width: '1.95rem',
      height: '1.95rem',
      background: '#5da283'
    },
    logText: {
      color: '#5da283'
    },
    timeAgo: {
      marginBottom: '-3px',
      marginLeft: '6px'
    },
    listRoot: {
      '&>div': {
        fontSize: 13
      }
    },
    listItemIconRoot: {
      minWidth: '20px'
    },
    listTextItemRoot: {
      padding: '0 8px'
    },
    summaryCard: {
      minWidth: 220,
      '@media (min-width: 924px)': {
        minWidth: 250
      },
      '@media (min-width: 1284px)': {
        minWidth: 300
      }
    },
    invoicePricingTable: {
      display: 'grid',
      gridTemplateColumns: '1fr 100px 100px 100px 10px',
      // gridGap: '1rem',
      alignItems: 'center'
    }
  })
)

export default workspaceStyles
