import React from 'react'
import GroupByPopup from './GroupByPopup'
import { SingleWorkspaceContext, WORKSPACE } from './singleWorkspaceContext'
import Filter from 'components/Boards/Filter/Filter'
import BoardTaskSearch from 'components/Boards/BoardTaskSearch'
import { ReactComponent as CrossIcon } from 'static/svg/cross.svg'
import { OutlinedButton } from 'global/globalComponents'
import { useContext } from 'react'

const SingleWorkspaceActions = ({ pathname, search }) => {
  const { workspaceDispatch, workspaceState } = useContext(
    SingleWorkspaceContext
  )

  const toggleMyApprovals = () => {
    workspaceDispatch({ type: WORKSPACE.TOGGLE_SHOW_APPROVAL_TASKS })
  }

  return (
    <div
      className="absolute top-0 left-0 w-full h-11 bg-white shadow-sm flex items-center px-6"
      style={{ zIndex: 2 }}
    >
      {pathname.includes('/board') && <BoardTaskSearch />}

      <div className="flex self-center items-center space-x-3 ml-auto">
        <OutlinedButton
          onClick={toggleMyApprovals}
          isActive={workspaceState.showApprovalTasks}
        >
          <span>My Approvals</span>
          {workspaceState.showApprovalTasks && (
            <CrossIcon className="ml-1 w-4 h-4" />
          )}
        </OutlinedButton>
        <Filter />
        {search === '?view=board' && <GroupByPopup />}
      </div>
    </div>
  )
}

export default React.memo(SingleWorkspaceActions)
