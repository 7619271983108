import { ReactComponent as Add } from 'static/svg/plus.svg'
import React from 'react'

const CloseButton = (props) => {
  const { children, ...restProps } = props
  return (
    <span
      {...restProps}
      className=" absolute top-4 right-4 w-8 h-8 transform rotate-45 cursor-pointer hover:bg-gray-200 rounded-full flex items-center justify-center p-2"
    >
      <Add />
    </span>
  )
}

export default CloseButton
