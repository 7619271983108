import { useEffect, useState, useContext } from 'react'
import { Button } from '@material-ui/core'
import { ToggleSwitch } from 'global/globalComponents'
import { memo } from 'react'
import onboardingStyles from './onboardingStyles'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { OnboardingContext, ONBOARDING } from './onboardingReducer'
import { updateTemplate, updateTemplateStatus } from 'thunks/onboarding/actions'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import ThreeDotMenu from 'global/globalComponents/ThreeDotMenu'
import Pill from 'global/globalComponents/Pill/Pill'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'

const TemplateCard = ({ template }) => {
  const classes = onboardingStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { onboardingDispatch } = useContext(OnboardingContext)
  const onboardingData = useSelector((state) => state.onboarding.data)
  const history = useHistory()
  const { path } = useRouteMatch()
  // const [deleteAlert, setDeleteAlert] = useState({
  //   open: false,
  //   loading: false,
  //   data: null
  // })
  const [publishAlert, setPublishAlert] = useState({
    open: false,
    loading: false,
    data: null
  })

  const handleEditTemplate = () => {
    const temp = onboardingData.find((item) => item._id === template._id)
    onboardingDispatch({ type: ONBOARDING.VIEW_TEMPLATE, payload: temp })
    history.push(`${path}/template/${temp._id}`)
  }

  const toggleActiveStatus = () => {
    setLoading(true)
    dispatch(
      updateTemplateStatus(
        template._id,
        { isActive: !template.isActive },
        toggleActiveStatusCallback
      )
    )
  }

  const toggleActiveStatusCallback = (res, err) => {
    if (err) {
      setPublishAlert({ loading: false, open: true, data: null })
      dispatch(fireErrorToaster(err))
    } else {
      setPublishAlert({ loading: false, open: false, data: null })
    }
  }

  // const handleDeleteAction = () => {
  //   alert('Deleted!')
  // }

  const handlePublishAction = () => {
    setPublishAlert((prev) => ({ ...prev, loading: true }))
    toggleActiveStatus()
  }

  // const handleDeleteOptionClick = () => {
  //   setDeleteAlert((prev) => ({ ...prev, open: true }))
  // }

  const handlePublishOptionClick = () => {
    setPublishAlert((prev) => ({ ...prev, open: true }))
  }

  // const closeDeleteAlert = () => {
  //   setDeleteAlert((prev) => ({ ...prev, open: false }))
  // }

  const closePublishAlert = () => {
    setPublishAlert((prev) => ({ ...prev, open: false }))
  }

  return (
    <div
      className={`${classes.card} bg-white border-2 rounded-xl pt-2 pl-4 pr-4 pb-4 flex flex-col relative`}
    >
      <div className="absolute w-full left-0 px-4 flex items-center">
        {template.isActive && (
          <span className="leading-none">
            <Pill variant="green">Active</Pill>
          </span>
        )}
        <div className="ml-auto mt-2" style={{ marginRight: '-0.5rem' }}>
          <ThreeDotMenu
            threeDotProps={[
              { name: 'Edit', method: handleEditTemplate },
              {
                name: !template.isActive ? 'Publish' : 'Unpublish',
                method: handlePublishOptionClick
              }
              // { name: 'Delete', method: handleDeleteOptionClick }
            ]}
          />
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center text-center">
        <h5 className="font-semibold mt-4">{template.templateName}</h5>
      </div>

      <AlertModal
        open={publishAlert.open}
        heading={'Are you sure'}
        alertType="confirmation"
        warningText={`Do you really want to ${
          !template.isActive ? 'Publish' : 'Unpublish'
        } this template?`}
        cancelText="Cancel"
        deleteBtnText={!template.isActive ? 'Publish' : 'Unpublish'}
        handleDialog={closePublishAlert}
        handleDeleteAction={handlePublishAction}
        loading={publishAlert.loading}
      />
    </div>
  )
}

export default memo(TemplateCard)
