import { TextField, Button } from '@material-ui/core'
import {
  ColoredAvatars,
  FormLabel,
  LoadingButton
} from 'global/globalComponents'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { updateResourceManagementData } from 'thunks/resourceManagement/action'
// import Select from 'react-select'
// import Currency from 'static/Currency/Currency'
// import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
// import { Button } from '@material-ui/core'
import { userRoles } from 'utils'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import { ME } from 'thunks/me/reducers'
import { ReactComponent as CalenderIcon } from 'static/svg/calender-icon.svg'
import CalendarModal from './CalendarModal'
import { fetchCalendar } from 'thunks/calender/action'

const ResourceManagement = () => {
  const dispatch = useDispatch()
  const {
    loading,
    data: userTeammates,
    fetched: teammatesFetched
  } = useSelector(state => state.userTeammates)
  const calendar = useSelector(state => state.calendar)
  const [changes, setChanges] = useState(false)
  const { meLoading, data: meData } = useSelector(state => state.me)
  const [resourceData, setResourceData] = useState({
    capacity: 56,
    workingDays: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true
    }
  })

  const [userHourlyRate, setUserHourlyRate] = useState([])
  const [standupTimeWindow, setStandupTimeWindow] = useState({
    morningStart: '',
    morningEnd: '',
    eveningStart: '',
    eveningEnd: ''
  })
  const [saveChangesLoading, setSaveChangesLoading] = useState(false)
  const [openCalendarModal, setOpenCalendarModal] = useState(false)

  const agencyOwnerId = role => {
    if (role === userRoles.AGENCY_ADMIN) {
      return meData.team._id
    } else {
      return meData._id
    }
  }

  // useEffect(() => {
  //   if (!calendar.fetched) {
  //     dispatch(fetchCalendar())
  //   }
  // }, [calendar.fetched])

  useEffect(() => {
    if (!teammatesFetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [teammatesFetched])

  useEffect(() => {
    if (!meLoading) {
      setResourceData(prev => ({
        capacity:
          meData?.profile?.capacity ?? meData?.team?.profile?.capacity ?? 1,
        workingDays: (meData?.profile?.workingDays ||
          meData?.team?.profile?.workingDays) ?? {
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: false,
          sun: false
        }
      }))

      let timeWindow =
        meData.profile?.standupTimeWindow ??
        meData.team?.profile?.standupTimeWindow

      if (timeWindow) {
        setStandupTimeWindow(timeWindow)
      }
    }
  }, [meData, meLoading])

  useEffect(() => {
    const userRate = userTeammates.map(item => ({
      userId: item._id,
      hourlyRate: item.hourlyRate ?? '',
      name: item.name,
      profileImage: item.profileImage,
      edit: false
    }))
    setUserHourlyRate(userRate)
  }, [userTeammates])

  // useEffect(() => {
  //   if (changes) {
  //     let timer = setTimeout(() => {
  //       const data = {
  //         ...resourceData,
  //         userHourlyRate: userHourlyRate
  //           .filter((item) => item.edit)
  //           .map((item) => ({
  //             userId: item.userId,
  //             hourlyRate: item.hourlyRate
  //           }))
  //       }
  //       updateResourceManagementData(
  //         agencyOwnerId(meData.role),
  //         data,
  //         (res, err) => {
  //           if (!err) {
  //             const updatedMeData = { ...meData }
  //             if (meData.role === userRoles.USER_AGENCY) {
  //               updatedMeData.profile.capacity = res.capacity
  //               updatedMeData.profile.workingDays = res.workingDays
  //             } else {
  //               updatedMeData.team.profile.capacity = res.capacity
  //               updatedMeData.team.profile.workingDays = res.workingDays
  //             }
  //             dispatch({ type: ME.UPDATE, payload: updatedMeData })
  //             dispatch(fireSuccessToaster('Updated Successfully'))
  //             setChanges(false)
  //           }
  //         }
  //       )
  //     }, 3000)

  //     return () => clearTimeout(timer)
  //   }
  // }, [resourceData, userHourlyRate])

  useEffect(() => {
    if (!changes) return
    let timer = setTimeout(() => {
      if (resourceData.capacity <= 0) {
        return dispatch(
          fireErrorToaster(
            'Please enter a valid number in capacity. Should be more than 0!'
          )
        )
      }
      const data = {
        ...resourceData,
        standupTimeWindow: standupTimeWindow,
        userHourlyRate: userHourlyRate
          .filter(item => item.edit)
          .map(item => ({
            userId: item.userId,
            hourlyRate: item.hourlyRate
          }))
      }
      updateResourceManagementData(
        agencyOwnerId(meData.role),
        data,
        (res, err) => {
          if (!err) {
            const updatedMeData = { ...meData }
            if (meData.role === userRoles.USER_AGENCY) {
              updatedMeData.profile.standupTimeWindow = standupTimeWindow
              updatedMeData.profile.capacity = res.capacity
              updatedMeData.profile.workingDays = res.workingDays
            } else {
              updatedMeData.team.profile.standupTimeWindow = standupTimeWindow
              updatedMeData.team.profile.capacity = res.capacity
              updatedMeData.team.profile.workingDays = res.workingDays
            }
            dispatch({ type: ME.UPDATE, payload: updatedMeData })
            dispatch(fireSuccessToaster('Updated Successfully'))
            setChanges(false)
          }
        }
      )
    }, 2500)

    return () => clearTimeout(timer)
  }, [changes])

  // useEffect(() => {
  //   let arr = Object.keys(Currency).map((el) => ({
  //     value: el.toLowerCase(),
  //     label: el.toUpperCase()
  //   }))
  //   setCurrencyOptions(arr)
  // }, [])

  const handleCapacity = e => {
    setChanges(true)
    setResourceData(prev => ({
      ...prev,
      capacity: e.target.value
    }))
  }

  const handleWorkingDays = (e, day) => {
    setChanges(true)
    setResourceData(prev => ({
      ...prev,
      workingDays: { ...resourceData.workingDays, [day]: e.target.checked }
    }))
  }

  const handleSave = e => {
    e.preventDefault()
    if (resourceData.capacity <= 0) {
      return dispatch(
        fireErrorToaster(
          'Please enter a valid number in capacity. Should be more than 0!'
        )
      )
    }
    const data = {
      ...resourceData,
      standupTimeWindow: standupTimeWindow,
      // defaultCurrecy: resourceData.defaultCurrecy.value,
      userHourlyRate: userHourlyRate
        .filter(item => item.edit)
        .map(item => ({
          userId: item.userId,
          hourlyRate: item.hourlyRate
        }))
    }

    setSaveChangesLoading(true)
    updateResourceManagementData(
      agencyOwnerId(meData.role),
      data,
      (res, err) => {
        setSaveChangesLoading(false)
        if (!err) {
          const updatedMeData = { ...meData }
          if (meData.role === userRoles.USER_AGENCY) {
            updatedMeData.profile.standupTimeWindow = standupTimeWindow
          } else {
            updatedMeData.team.profile.standupTimeWindow = standupTimeWindow
          }

          dispatch({ type: ME.UPDATE, payload: updatedMeData })
          dispatch(fireSuccessToaster('Updated Successfully'))
        } else {
          dispatch(fireErrorToaster(res))
        }
      }
    )
  }

  // const selectCurrency = (newVal) => {
  //   setResourceData((prev) => ({
  //     ...prev,
  //     defaultCurrecy: newVal
  //   }))
  // }

  const handleUserHourlyRate = (e, userId) => {
    if (e.target.value < 0) {
      return dispatch(fireErrorToaster('Please enter a valid rate'))
    }
    setChanges(true)
    let updatedData = [...userHourlyRate]
    const index = updatedData.findIndex(item => item.userId === userId)
    updatedData[index].hourlyRate = e.target.value
    updatedData[index].edit = true
    setUserHourlyRate(updatedData)
  }

  const handleSetStandupTimeWindow = e => {
    const field = e.target.dataset.field
    if (standupTimeWindow.morningStart && field === 'morningEnd') {
      if (
        standupTimeWindow.morningStart.split(':')[0] * 1 >=
        e.target.value.split(':')[0] * 1
      ) {
        return dispatch(
          fireErrorToaster('End time should be greater than start time')
        )
      }
    }
    if (standupTimeWindow.eveningStart && field === 'eveningEnd') {
      if (
        standupTimeWindow.eveningStart.split(':')[0] * 1 >=
        e.target.value.split(':')[0] * 1
      ) {
        return dispatch(
          fireErrorToaster('End time should be greater than start time')
        )
      }
    }
    if (standupTimeWindow.morningEnd && field === 'eveningStart') {
      if (
        standupTimeWindow.morningEnd.split(':')[0] * 1 >
        e.target.value.split(':')[0] * 1
      ) {
        return dispatch(
          fireErrorToaster('Evening time should be greater than Morning time')
        )
      }
    }
    setStandupTimeWindow(prev => ({
      ...prev,
      [field]: `${e.target.value.split(':')[0]}:00`
    }))
    setChanges(true)
  }

  return (
    <Wrapper>
      <div className="flex justify-between items-center mb-6 lg:mb-8">
        <SecondaryHeading className="mb-0">
          Resource Management
        </SecondaryHeading>

        {/* <LoadingButton onClick={handleSave}>Save Changes</LoadingButton> */}
      </div>

      <form>
        <div>
          <div className="flex flex-col md:flex-row md:items-center mb-8">
            <FieldLabel label="Team member's capacity" />
            <div className="flex-1 flex items-center">
              <TextField
                type="number"
                // InputProps={{ inputProps: { min: 1, max: 100 } }}
                onChange={handleCapacity}
                value={resourceData.capacity}
                className="w-28"
              />
              <span className="text-smaller lg:text-sm text-custom-gray-main ml-2">
                hours per week
              </span>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center">
            <FieldLabel label="Working days" />
            <div className="flex-1 flex items-center flex-wrap">
              {Object.keys(resourceData.workingDays).map(item => (
                <FormControlLabel
                  onChange={e => handleWorkingDays(e, item)}
                  label={
                    <p
                      className="capitalize text-smaller lg:text-sm text-primary-dark-2"
                      style={{ marginLeft: '-4px' }}
                    >
                      {item}
                    </p>
                  }
                  checked={resourceData.workingDays[item]}
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                    />
                  }
                  style={{ padding: '2px' }}
                />
              ))}
              {/* <Button
                variant="outlined"
                size="small"
                // className="flex justify-center items-center space-x-2 border-2 border-primary-main rounded py-1 px-4"
                onClick={() => setOpenCalendarModal(true)}
                startIcon={<CalenderIcon className="h-4 w-4" />}
              >
                <span className="text-sm">Team Calendar</span>
              </Button> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center py-4 text-smaller lg:text-sm">
          <FieldLabel label="Standups Time Window" />
          <div className="flex-1 flex items-center flex-wrap gap-1.5">
            <div className="flex items-center space-x-2 mr-6">
              <span className=" text-custom-gray-main">Morning</span>
              <input
                type="time"
                className="border px-2 py-1 rounded border-gray-300 text-smaller lg:text-sm"
                value={standupTimeWindow.morningStart}
                onChange={handleSetStandupTimeWindow}
                data-field="morningStart"
                label="Morning Start"
              />
              <span className=" text-custom-gray-main">&#8211;</span>
              <input
                type="time"
                className="border px-2 py-1 rounded border-gray-300"
                value={standupTimeWindow.morningEnd}
                onChange={handleSetStandupTimeWindow}
                data-field="morningEnd"
                label="Morning End"
              />
            </div>

            <div className="flex items-center space-x-2">
              <span className=" text-custom-gray-main">Evening</span>
              <input
                type="time"
                className="border px-2 py-1 rounded border-gray-300 "
                value={standupTimeWindow.eveningStart}
                onChange={handleSetStandupTimeWindow}
                data-field="eveningStart"
                label="Evening Start"
              />
              <span className=" text-custom-gray-main">&#8211;</span>
              <input
                type="time"
                className="border px-2 py-1 rounded border-gray-300 "
                value={standupTimeWindow.eveningEnd}
                onChange={handleSetStandupTimeWindow}
                data-field="eveningEnd"
                label="Evening End"
              />
            </div>
          </div>
        </div>

        {!loading && userTeammates.length > 0 && (
          <>
            <hr className="my-4" />
            <div className="py-8 text-smaller lg:text-sm">
              <div className="flex flex-col md:flex-row md:items-start mb-8">
                <FieldLabel label="Team's Hourly Rates" />
                {/* <div className=" mr-8" style={{ flex: '0 0 200px' }}>
                  <p className="text-sm text-primary-dark font-semibold">
                    Team's Hourly Rates
                  </p>
                </div> */}
                <div className="w-full md:w-2/3">
                  <table className="w-full">
                    <thead className="bg-gray-100">
                      <th className=" text-primary-dark font-semibold p-2 text-left">
                        Team Member ({userTeammates.length})
                      </th>
                      <th className=" text-primary-dark font-semibold p-2 text-left">
                        Hourly Rate (
                        {(
                          meData.profile?.defaultCurrency ??
                          meData.team?.profile?.defaultCurrency
                        )?.toUpperCase() ?? 'USD'}
                        )
                      </th>
                    </thead>
                    {/* <div style={{ maxHeight: '250px', overflow: 'scroll' }}> */}
                    <tbody>
                      {userHourlyRate.map(teammate => (
                        <tr className="border-b">
                          <td className=" text-primary-dark-2 p-2">
                            <div className="flex items-center">
                              <ColoredAvatars user={teammate} size="small" />
                              <span className="ml-2">{teammate.name}</span>
                            </div>
                          </td>
                          <td className="text-xs text-primary-dark-2 p-2">
                            <TextField
                              type="number"
                              InputProps={{ inputProps: { min: 0, max: 100 } }}
                              placeholder="Enter hourly rate"
                              value={teammate.hourlyRate}
                              onChange={e =>
                                handleUserHourlyRate(e, teammate.userId)
                              }
                              InputLabelProps={{
                                shrink: true
                              }}
                              className="w-48"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* </div> */}
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="text-right">
          <LoadingButton loading={saveChangesLoading} onClick={handleSave}>
            Save Changes
          </LoadingButton>
        </div>
      </form>
      <CalendarModal
        open={openCalendarModal}
        handleClose={() => setOpenCalendarModal(false)}
        elmFor="agency"
      />
    </Wrapper>
  )
}

const FieldLabel = ({ label }) => {
  return (
    <div className="w-1/3">
      <FormLabel className="text-primary-dark font-semibold mb-2">
        {label}
      </FormLabel>
    </div>
  )
}

export default ResourceManagement
