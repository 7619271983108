import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import SendInvoiceMailModal from 'components/Workspace/ProjectInvoices/SendInvoiceMailModal'
// import { Skeleton } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster
  // fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { ArrowDropDown } from '@material-ui/icons'
import { PopupMenu } from 'global/globalComponents'
// import { markInvoiceAsPaid } from 'thunks/invoices/actions'
import Currency from 'static/Currency/Currency'
import { INVOICES } from 'thunks/invoices/reducer'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { markInvoiceAsPaid } from 'thunks/invoices/actions'
import InvoiceFile from '../InvoiceFile'
import ReactToPrint from 'react-to-print'
import { Skeleton } from '@material-ui/lab'

const InvoiceView = ({
  allInvoices = [],
  handleEditInvoice,
  billingPermission = {}
}) => {
  const { invoiceId } = useParams()
  const [invoice, setInvoice] = useState({})
  const [loading, setLoading] = useState(true)
  const [menuOptions, setMenuOptions] = useState([])
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const history = useHistory()
  const [markAsPaidModal, setMarkAsPaidModal] = useState({
    open: false,
    loading: false,
    data: ''
  })
  const invoiceRef = useRef()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const i = allInvoices.find((item) => item._id === invoiceId)

    if (i) {
      setInvoice(i)
      setLoading(false)
    }
  }, [allInvoices, invoiceId])

  useEffect(() => {
    const arr = [{ label: 'Go Back', method: handleGoBack }]

    if (billingPermission.update) {
      arr.push({ label: 'Edit Invoice', method: handleBackToEdit })

      if (invoice.status !== 'draft' && invoice.status !== 'paid') {
        arr.push({ label: 'Mark As Paid', method: openMarkAsPaidAlert })
      }
    }

    setMenuOptions(arr)
  }, [invoice])

  const handleGoBack = () => {
    // history.push('/agency/client-billing/all')
    history.push('/agency/payments/client-billing/all')
    // history.goBack()
  }

  const handleBackToEdit = () => {
    handleEditInvoice(invoice)
  }

  const toggleInvoiceModal = () => {
    if (isModalOpen) {
      setIsModalOpen(false)
    } else {
      if (!invoice.client?.clientId) {
        return dispatch(fireErrorToaster(`Client is required!`))
      }
      if (parseFloat(invoice.totalAmount) > 0) {
        setIsModalOpen(true)
      } else {
        dispatch(
          fireErrorToaster(
            `Invoice must have a value greater than ${
              Currency[invoice.currency].symbol_native
            }0 to be sent!`
          )
        )
      }
    }
  }

  const updateInvoiceData = (invoice) => {
    dispatch({ type: INVOICES.UPDATE_ONE_INVOICE, payload: { ...invoice } })
  }

  const openMarkAsPaidAlert = () => {
    setMarkAsPaidModal({ open: true, loading: false, data: invoiceId })
  }

  const closeMarkAsPaidAlert = () => {
    setMarkAsPaidModal((prev) => ({ ...prev, open: false }))
  }

  const handleMarkInvoiceAsPaid = () => {
    setMarkAsPaidModal((prev) => ({ ...prev, loading: true }))

    dispatch(
      markInvoiceAsPaid(markAsPaidModal.data, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setMarkAsPaidModal((prev) => ({ ...prev, loading: false }))
        } else {
          setMarkAsPaidModal((prev) => ({ ...prev, open: false }))
        }
      })
    )
  }

  return (
    <>
      <div className="px-10 w-90 mx-auto py-10 mt-10" style={{ maxWidth: 700 }}>
        {loading ? (
          <Skeleton
            variant="rect"
            width={650}
            height={600}
            className="rounded"
          />
        ) : (
          <>
            <div className="flex items-center justify-between mb-6">
              {invoice.status !== 'paid' ? (
                <>
                  <PopupMenu
                    targetComp={
                      <Button
                        variant="outlined"
                        onClick={handleBackToEdit}
                        endIcon={<ArrowDropDown />}
                      >
                        More Options
                      </Button>
                    }
                    menuItems={menuOptions}
                  />
                  <div className="flex items-center space-x-4">
                    {invoice.status !== 'draft' && (
                      <div className="text-center">
                        <ReactToPrint
                          trigger={() => {
                            return (
                              <Button
                                // className="px-3 py-2 rounded text-gray-100 text-sm inline-flex items-center gap-2"
                                style={{ backgroundColor: '#222' }}
                              >
                                Download PDF
                                {/* <span>Download PDF</span> */}
                              </Button>
                            )
                          }}
                          content={() => invoiceRef.current}
                          documentTitle={`${
                            invoice.customInvoiceNumber ?? invoice.invoiceNumber
                          }_invoice`}
                        />
                      </div>
                    )}
                    {invoice.status === 'draft' ? (
                      <Button onClick={toggleInvoiceModal}>Send Now</Button>
                    ) : (
                      <Button onClick={toggleInvoiceModal}>
                        Send Reminder
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<ArrowLeft />}
                    onClick={handleGoBack}
                  >
                    Go back
                  </Button>{' '}
                  {invoice.status !== 'draft' && (
                    <div className="text-center">
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <Button
                              // className="px-3 py-2 rounded text-gray-100 text-sm inline-flex items-center gap-2"
                              style={{ backgroundColor: '#222' }}
                            >
                              Download PDF
                              {/* <span>Download PDF</span> */}
                            </Button>
                          )
                        }}
                        content={() => invoiceRef.current}
                        documentTitle={`${
                          invoice.customInvoiceNumber ?? invoice.invoiceNumber
                        }_invoice`}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="mb-4">
              <div ref={invoiceRef}>
                <InvoiceFile invoice={invoice} />
              </div>
              {/* {invoice.status !== 'draft' && (
                <div className="text-center my-4">
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button
                          size="small"
                          className="px-3 py-2 rounded text-gray-100 text-sm inline-flex items-center gap-2"
                          style={{ backgroundColor: '#222' }}
                        >
                          <span>Download PDF,,,</span>
                        </button>
                      )
                    }}
                    content={() => invoiceRef.current}
                    documentTitle={`${
                      invoice.customInvoiceNumber ?? invoice.invoiceNumber
                    }_invoice`}
                  />
                </div>
              )} */}
            </div>

            <SendInvoiceMailModal
              open={isModalOpen}
              handleClose={toggleInvoiceModal}
              invoice={invoice}
              isReminder={invoice.status === 'pending'}
              updateInvoiceData={updateInvoiceData}
            />
          </>
        )}
      </div>

      <AlertModal
        heading="Are you sure"
        alertType="confirm"
        warningText="This invoice will marked as paid, and no additional client reminders will be sent."
        open={markAsPaidModal.open}
        handleDialog={closeMarkAsPaidAlert}
        handleDeleteAction={handleMarkInvoiceAsPaid}
        loading={markAsPaidModal.loading}
        deleteBtnText="Mark As Paid"
      />
    </>
  )
}

export default InvoiceView
