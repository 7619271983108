import { useEffect, useState } from 'react'
import { CustomTooltip, ToggleSwitch } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { Avatar, TextField, CircularProgress } from '@material-ui/core'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'
import { PopupMenu } from 'global/globalComponents'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { userRoles, getRoleBadge } from 'utils'
import {
  deleteTeamVertical,
  fetchTeamVerticals,
  updateTeamVerticals
} from 'thunks/Verticals/action'
import { AvatarGroup } from '@material-ui/lab'
import { ColoredAvatars } from 'global/globalComponents'
import teammateStyles from './teammateStyles'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import CollabDrowdown from 'components/Workspace/Panel/CollabDrowdown'
import { IconButton } from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import clsx from 'clsx'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import Check from '@material-ui/icons/Check'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'
import CloseIcon from '@material-ui/icons/Close'

const totalVerticalsToShow = 4

const VerticalsTable = () => {
  const dispatch = useDispatch()
  const meData = useSelector(state => state.me.data)
  const userTeammates = useSelector(state => state.userTeammates.data)
  const verticals = useSelector(state => state.teamVerticals)
  const [visibleVerticals, setVisibleVerticals] = useState([])
  const [page, setPage] = useState(0)
  const [paginationTotalDataCount, setPaginationTotalDataCount] = useState(0)
  const [active, setActive] = useState('')
  const [loading, setLoading] = useState(false)
  const [editable, setEditable] = useState({
    data: {},
    open: false,
    value: ''
  })

  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [anchorEl, setAnchorEl] = useState({
    open: null,
    data: {}
  })

  useEffect(() => {
    if (!verticals.fetched) {
      dispatch(fetchTeamVerticals())
    }
  }, [verticals])

  const handleEditField = e => {
    e.preventDefault()
    if (!editable.value) {
      return dispatch(fireErrorToaster('Please fill the empty field!'))
    }
    const match = verticals.data.find(
      item => item.name.toLowerCase() === editable.value?.toLowerCase()
    )

    if (match)
      return dispatch(fireErrorToaster(`"${match.name}" already exists!`))

    if (editable.value.toLowerCase() === editable.data.name?.toLowerCase()) {
      return setEditable({
        data: {},
        open: false,
        value: ''
      })
    }
    setLoading(true)
    dispatch(
      updateTeamVerticals(
        editable.data._id,
        { name: editable.value },
        updateVerticalCallback
      )
    )
  }

  const updateVerticalCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      setEditable({
        data: {},
        open: false,
        value: ''
      })
    }
  }

  const handleOpenDeleteModal = data => {
    setOpenDeleteModal({
      loading: false,
      open: true,
      data
    })
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      loading: false,
      open: false,
      data: {}
    })
  }

  const handleDeleteVertical = () => {
    setOpenDeleteModal(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteTeamVertical(openDeleteModal.data._id, (res, err) => {
        setOpenDeleteModal({
          loading: false,
          open: false,
          data: {}
        })
        if (!err) {
          dispatch(fireSuccessToaster('Vertical Deleted Successfully!'))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  const handleOpenCollab = (event, data) => {
    setActive(data._id)
    setAnchorEl({
      open: event.currentTarget,
      data: data
    })
  }

  const handleAssignTeammate = team => {
    dispatch(
      updateTeamVerticals(
        anchorEl.data._id,
        { newTeammates: [team._id] },
        (res, err) => {
          if (!err) {
            dispatch(fireSuccessToaster('Teammate Assigned Successfully!'))
            setAnchorEl({ open: null, data: {} })
            setActive('')
          } else {
            dispatch(fireErrorToaster(res))
          }
        }
      )
    )
  }

  const handleRemoveTeammate = (id, teamId) => {
    dispatch(
      updateTeamVerticals(id, { removedTeammates: [teamId] }, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster('Teammate Removed Successfully!'))
        } else {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }

  return (
    <div
      style={{ height: verticals.data.length > 2 ? '285px' : '185px' }}
      className="pb-4"
    >
      {/* <NewTable roles={verticals.data} /> */}
      <TableContainer
        className="min-w-full divide-y divide-gray-200"
        shadow="sm"
        mb={0}
        containerProps={{
          style: {
            overflowY: 'auto',
            height: '100%',
            background: '#fff'
          }
        }}
      >
        <Thead className="sticky top-0" style={{ zIndex: 99 }}>
          <Th>Roles</Th>
          {/* <Th className="text-left whitespace-nowrap">Role description</Th> */}
          {/* <Th className="text-left whitespace-nowrap">Assigned Users</Th> */}
          <Th className="text-right whitespace-nowrap">Actions</Th>
        </Thead>

        <Tbody>
          {!verticals.loading ? (
            verticals.data.length > 0 ? (
              verticals.data.map((item, index) => (
                <Row
                  key={item._id}
                  item={item}
                  handleDelete={() => handleOpenDeleteModal(item)}
                  handleEditField={handleEditField}
                  handleRemoveTeammate={handleRemoveTeammate}
                  handleOpenCollab={handleOpenCollab}
                  editable={editable}
                  setEditable={setEditable}
                  meData={meData}
                  active={active}
                  loading={loading}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan="5"
                  className="text-left py-3 px-6 text-gray-500 font-normal"
                >
                  There are no verticals
                </th>
              </Tr>
            )
          ) : (
            <TableDataLoading cols={4} />
          )}
        </Tbody>
      </TableContainer>

      <CollabDrowdown
        open={Boolean(anchorEl.open)}
        anchorEl={anchorEl.open}
        onClose={() => {
          setAnchorEl({ open: null, data: {} })
          setActive('')
        }}
        list={userTeammates.filter(
          mate =>
            mate.role !== userRoles.AGENCY_ADMIN &&
            (!mate.verticals || mate.verticals?.length === 0)
        )}
        handleListItemClick={handleAssignTeammate}
        meData={meData}
        elmFor="teammate"
      />

      <DeleteModal
        warningText="Role will be deleted."
        open={openDeleteModal.open}
        handleDialog={handleCloseDeleteModal}
        handleDeleteAction={handleDeleteVertical}
        loading={openDeleteModal.loading}
      />
    </div>
  )
}

const Row = ({
  item,
  handleDelete,
  handleEditField,
  handleRemoveTeammate,
  handleOpenCollab,
  editable,
  active,
  setEditable,
  meData,
  loading
}) => {
  const classes = teammateStyles()
  const handleEditText = e => {
    const { name, value } = e.target
    setEditable(prev => ({
      ...prev,
      value: value
    }))
  }

  const truncateText = text => {
    const maxWord = 45
    if (text?.length >= maxWord) {
      const transformed = text.substr(0, maxWord - 2)
      return `${transformed}...`
    }
    return text
  }

  return (
    <Tr className={clsx({ active: active === item._id }, classes.activeRow)}>
      <Td className="">
        {!(editable.data._id === item._id && editable.open) ? (
          <div className="show-on-hover-parent flex items-center space-x-2 ">
            <span className=" text-custom-table-primaryText">{item.name}</span>
            <CustomTooltip title="Edit Role" placement="top">
              <Pen
                className={`show-on-hover-child ${classes.penIcon}`}
                onClick={() =>
                  setEditable(prev => ({
                    data: item,
                    open: true,
                    value: item.name
                  }))
                }
              />
            </CustomTooltip>
          </div>
        ) : (
          <form
            onSubmit={handleEditField}
            className="flex items-center space-x-2"
          >
            <TextField
              type="text"
              value={editable.value}
              name="name"
              onChange={handleEditText}
              autoFocus
            />
            {!loading ? (
              <IconButton size="small">
                <Check
                  className=" text-primary-main "
                  fontSize="small"
                  onClick={handleEditField}
                />
              </IconButton>
            ) : (
              <CircularProgress
                fontSize="small"
                style={{ width: '10px', height: '10px' }}
              />
            )}
            <IconButton size="small">
              <CloseIcon
                className=" text-red-500 "
                fontSize="small"
                onClick={() =>
                  setEditable(prev => ({
                    data: {},
                    open: false,
                    value: ''
                  }))
                }
              />
            </IconButton>
          </form>
        )}
      </Td>
      {/* <Td className="text-left text-xs">
        {!(editable.id === item._id && editable.open === 'description') ? (
          <div className={classes.editIcon}>
            <span className="mr-2">{truncateText(item.description)}</span>
            <CustomTooltip title="Edit Role Description" placement="top">
              <Pen
                className={`pen ${classes.penIcon}`}
                onClick={() =>
                  setEditable((prev) => ({
                    ...prev,
                    id: item._id,
                    open: 'description',
                    description: item.description
                  }))
                }
              />
            </CustomTooltip>
          </div>
        ) : (
          <TextField
            type="text"
            id="standard-basic"
            variant="standard"
            name="description"
            value={editable.description}
            onChange={handleEditText}
            onKeyDown={(e) => handleEditField(e, item)}
            autoFocus
            helperText="Press enter to update or exit"
          />
        )}
      </Td> */}
      {/* <Td className="text-left text-xs whitespace-nowrap">
        <AssignedTeam
          item={item}
          teammates={item.assignedTeammates}
          handleRemoveTeammate={handleRemoveTeammate}
          handleOpenCollab={handleOpenCollab}
          meData={meData}
        />
      </Td> */}

      <Td className="text-right whitespace-nowrap cursor-pointer">
        <div className="flex justify-end space-x-4">
          {/* {!editable.open ? (
            <IconButton size="small">
              <EditIcon
                className="h-4 w-4 text-primary-main"
                onClick={() =>
                  setEditable((prev) => ({
                    data: item,
                    open: true,
                    value: item.name
                  }))
                }
              />
            </IconButton>
          ) : !workspaceData.loading ? (
            <Check
              className="p-0.5 cursor-pointer text-primary-main hover:bg-gray-50"
              fontSize="small"
              onClick={handleEditField}
            />
          ) : (
            <CircularProgress
              fontSize="small"
              style={{ width: '10px', height: '10px' }}
            />
          )} */}

          <IconButton size="small" onClick={handleDelete}>
            <TrashIcon className="h-4 w-4 text-custom-red-main" />
          </IconButton>
        </div>
      </Td>
    </Tr>
  )
}

const AssignedTeam = ({
  item,
  teammates,
  handleRemoveTeammate,
  handleOpenCollab,
  meData
}) => {
  const classes = teammateStyles()
  return (
    <div className="flex items-center">
      {teammates?.map(teammate => (
        <div className={`relative cursor-pointer ${classes.teammate}`}>
          {typeof teammate === 'object' && (
            <>
              <ColoredAvatars size="small" tooltip="true" user={teammate} />

              <button
                onClick={() => handleRemoveTeammate(item._id, teammate._id)}
                className={`cross-btn ${classes.removeAssigneeBtn}`}
              >
                <CancelRoundedIcon fontSize="small" />
              </button>
            </>
          )}
        </div>
      ))}
      <CustomTooltip title="Add Users" placement="top">
        <button
          type="button"
          onClick={e => handleOpenCollab(e, item)}
          className="flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
        >
          <span className="sr-only">Add team member</span>
          <Add style={{ fontSize: '16px' }} aria-hidden="true" />
        </button>
      </CustomTooltip>
    </div>
  )
}

export default VerticalsTable
