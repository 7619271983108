import React from 'react'
// import { Button } from '@material-ui/core'
// import { useBreadcrumbs } from 'customHooks'
// import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
// import SectionHeading from 'global/globalComponents/SectionHeading'
import { useSelector } from 'react-redux'
// import { ReactComponent as Add } from 'static/svg/plus.svg'
// import WebhookPage from './WebhookPage'
// import { useEffect } from 'react'
// import SlackWebhookTable from './WebhookTable'
// import WebhookModal from './WebhookModal'
// import {
//   fetchAllWebhookEvents,
//   fetchUserCreatedWebhooks,
//   updateSingleWebhook
// } from 'thunks/webhooks/action'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { AlertModal } from 'global/globalComponents'
import CommonWebhookPage from './CommonWebhookPage'

const SlackWebhook = () => {
  // const dispatch = useDispatch()
  const slackWebhookData = useSelector((state) => state.userWebhooks.slackData)
  // const [slackHookData, setSlackHookData] = useState([])
  // const [webhookModal, setWebhookModal] = useState({
  //   open: false,
  //   data: {},
  //   edit: false
  // })
  // const [deleteAlert, setDeleteAlert] = useState({
  //   open: false,
  //   data: {},
  //   loading: false
  // })

  // useEffect(() => {
  //   dispatch(fetchAllWebhookEvents())
  // }, [])

  // useEffect(() => {
  //   dispatch(fetchUserCreatedWebhooks())
  //   dispatch(fetchAllWebhookEvents())
  // }, [])

  // useBreadcrumbs([
  //   {
  //     name: 'Settings',
  //     link: `/settings/general`,
  //     index: 0
  //   },
  //   {
  //     name: 'Integrations',
  //     link: `/settings/integrations`,
  //     index: 1
  //   },
  //   {
  //     name: 'Slack',
  //     link: `/settings/integrations/slack`,
  //     index: 2
  //   }
  // ])

  // useEffect(() => {
  //   if (slackWebhookData) {
  //     setSlackHookData(
  //       Array.isArray(slackWebhookData) ? slackWebhookData : [slackWebhookData]
  //     )
  //   }
  // }, [slackWebhookData])

  // const toggleWebhookModal = () => {
  //   setWebhookModal((prev) => ({ open: !prev.open }))
  // }

  // const openEditModal = useCallback((data) => {
  //   setWebhookModal({ open: true, edit: true, data })
  // }, [])

  // const toggleWebhookStatus = (e, cb) => {
  //   const { name, checked } = e.target
  //   dispatch(
  //     updateSingleWebhook(name, { isActive: checked }, (res, err) => {
  //       if (err) {
  //         dispatch(fireErrorToaster(res))
  //       }
  //       if (cb) cb()
  //     })
  //   )
  // }

  return (
    <CommonWebhookPage elmFor="slackWebhook" webhookData={slackWebhookData} />
    // <div>
    //   <OuterContentWrapper>
    //     <div className="flex items-center justify-between">
    //       <SectionHeading>Slack Integration</SectionHeading>
    //       <Button
    //         startIcon={<Add fontSize="small" />}
    //         onClick={toggleWebhookModal}
    //       >
    //         Add New
    //       </Button>
    //     </div>

    //     <SlackWebhookTable
    //       slackWebhooksData={slackWebhookData}
    //       openEditModal={openEditModal}
    //       toggleWebhookStatus={toggleWebhookStatus}
    //     />
    //   </OuterContentWrapper>
    //   <WebhookModal
    //     {...webhookModal}
    //     onClose={toggleWebhookModal}
    //     elmFor="slackWebhook"
    //   />
    //   <AlertModal
    //     open={deleteAlert.open}
    //     handleDialog={toggleDeleteAlert}
    //     handleDeleteAction={handleDeleteWebhook}
    //     warningText={`Do you want to delete ${deleteAlert.data.name} webhook`}
    //     heading="Alert"
    //     loading={deleteAlert.loading}
    //   />
    // </div>
  )
}

export default SlackWebhook
