import React, { useEffect, useMemo, useState } from 'react'
import { Button, IconButton, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
// import { AvatarGroup } from '@material-ui/lab'
// import PeopleIcon from '@material-ui/icons/People'
import userRoles, { getUserPath } from 'utils/userRoles'
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined'
// import { toggleEditTeammatesSidePanel } from 'thunks/generlAppState/actions'
import { ColoredAvatarGroup } from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import useWorkspacePermission from 'customHooks/useWorkspacePermission'
import HeaderProfileMenu from '../HeaderProfileMenu'
import headerStyles from '../headerStyles'
import AsanaIcons from 'static/asana/AsanaIcons'
import WorkspaceMenu from 'components/UserDashboard/Workspaces/WorkspaceMenu'
import EditWorkspaceDialog from 'components/UserDashboard/Workspaces/EditWorkspaceDialog'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { useHistory } from 'react-router'
import {
  archiveWorkspace,
  deleteWorkspace,
  UpdateWorkspceCompleteStatus,
  createWorkspaceCopy,
  updateWorkSpace1
} from 'thunks/workspace/actions'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import WorkspaceTabs from './WorkspaceTabs'
import { errorMessages } from 'utils'
import Pill from 'global/globalComponents/Pill/Pill'
import {
  fireErrorToaster,
  fireSuccessToaster,
  fireProcessToaster
} from 'thunks/fireToaster/actions'
import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
import ShareBoardModal from 'components/Boards/ShareBoardModal'
import { ReactComponent as TwoUserIcon } from 'static/svg/users.svg'
// import { ReactComponent as BarsIcon } from 'static/svg/bars.svg'
import { ReactComponent as IndentIncrease } from 'static/svg/indent-increase.svg'
import {
  toggleSidebar,
  toggleEditTeammatesSidePanel
} from 'thunks/generlAppState/actions'
import WorkspaceIconPopover from 'components/UserDashboard/Workspaces/WorkspaceIconPopover'
import { CustomTooltip } from 'global/globalComponents'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'

const WorkspaceHeader = ({ isSidebarOpen }) => {
  const dispatch = useDispatch()
  const classes = headerStyles()
  const { currentWorkspace, currentBoard } = useSelector(
    state => state.userWorkspaces
  )
  const userTags = useSelector(state => state.userTags)
  const userAccess = useSelector(state => state.permission.data?.access)
  const { workspace: workspacePermissionData } = useWorkspacePermission(
    currentWorkspace?._id
  )
  const meData = useSelector(state => state.me.data)
  const userData = useSelector(state => state.user.data)
  const history = useHistory()
  const { data: userActivePlan, loading: planDataLoading } = useSelector(
    state => state.userActivePlanInfo
  )
  const [worksapceMenuEl, setWorkspaceMenuEl] = useState('')
  // const [loading, setLoading] = useState(false)
  const [editWorkspaceDialog, setEditWorkspaceDialog] = useState({
    open: false,
    workspace: {}
  })
  const [deleteWorkspaceModal, setDeleteWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [archiveWorkspaceModal, setArchiveWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [completeWorkspaceModal, setCompleteWorkspaceModal] = useState({
    open: false,
    data: {},
    loading: false
  })
  const [embedModalOpen, setEmbedModalOpen] = useState(false)
  const [teammates, setTeammates] = useState([])
  const [shareBoladDialogStatus, setShareBoladDialogStatus] = useState(false)
  const [iconPopoverData, setIconPopoverData] = useState({
    open: false,
    anchorEl: null
  })
  const [editName, setEditName] = useState({ edit: false, projectName: '' })

  useEffect(() => {
    if (currentWorkspace.assignedAgencyTeam) {
      setTeammates(
        [
          ...currentWorkspace.assignedAgencyTeam,
          ...currentWorkspace.assignedClientTeam
        ].map(item => item.user)
      )
    }
  }, [currentWorkspace.assignedAgencyTeam, currentWorkspace.assignedClientTeam])

  // const handleTabClick = (event, data) => {
  //   setAnchorE2(event.currentTarget)
  //   setPopoverData(data)
  // }

  // const handleClosePopover = () => {
  //   setAnchorE2(null)
  // }

  const workspacePermission = {
    view: userAccess?.workspace.view,
    create: userAccess?.workspace.create,
    update: workspacePermissionData?.update,
    delete: workspacePermissionData?.delete
  }

  const handleProjectNameUpdate = () => {
    if (workspacePermission.update && meData.role < userRoles.USER_CLIENT) {
      setEditName({
        edit: true,
        projectName: currentWorkspace.name
      })
    }
  }

  const updateProjectName = e => {
    console.log()
    e.preventDefault()
    if (
      editName.projectName &&
      editName.projectName !== currentWorkspace.name
    ) {
      dispatch(
        updateWorkSpace1(
          currentWorkspace._id,
          { name: editName.projectName },
          'name',
          (res, err) => {
            setEditName({ edit: false, projectName: '' })
            if (err) {
              dispatch(fireErrorToaster(res))
            }
          }
        )
      )
    } else {
      setEditName({ edit: false, projectName: '' })
    }
  }

  const openWorkspaceMenu = e => {
    setWorkspaceMenuEl(e.currentTarget)
  }
  const closeWorksapceMenu = e => {
    setWorkspaceMenuEl(null)
  }

  const handleOpenEditWorkspaceDialog = workspace => {
    if (workspacePermission.update) {
      if (editWorkspaceDialog.open) {
        setEditWorkspaceDialog({
          open: false,
          workspace: {}
        })
      } else {
        setEditWorkspaceDialog({
          open: true,
          workspace
        })
      }
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const handleDeleteWorkspaceModal = workspace => {
    if (workspacePermission.delete) {
      if (deleteWorkspaceModal.open) {
        setDeleteWorkspaceModal({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setDeleteWorkspaceModal({
          open: true,
          data: workspace,
          loading: false
        })
      }
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const handleArhciveWorkspaceModal = workspace => {
    if (workspacePermission.update) {
      if (archiveWorkspaceModal.open) {
        setArchiveWorkspaceModal({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setArchiveWorkspaceModal({
          open: true,
          data: workspace,
          loading: false
        })
      }
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const handleCompleteWorkspaceModal = workspace => {
    if (workspacePermission.update) {
      if (completeWorkspaceModal.open) {
        setCompleteWorkspaceModal({
          open: false,
          data: {},
          loading: false
        })
      } else {
        setCompleteWorkspaceModal({
          open: true,
          data: workspace,
          loading: false
        })
      }
    } else dispatch(fireErrorToaster('You are not an authorized user'))
  }

  const handleDeleteWorkspace = () => {
    setDeleteWorkspaceModal(prev => ({ ...prev, loading: true }))
    dispatch(
      deleteWorkspace(deleteWorkspaceModal.data._id, deleteWorkspaceCallback)
    )
  }

  const deleteWorkspaceCallback = (res, err) => {
    if (err) {
      setDeleteWorkspaceModal(prev => ({ ...prev, loading: false }))
    } else {
      handleDeleteWorkspaceModal()
      history.push(`${getUserPath(meData.role)}/workspace/all`)
    }
  }

  const handleArchiveWorkspace = () => {
    setArchiveWorkspaceModal(prev => ({ ...prev, loading: true }))
    dispatch(
      archiveWorkspace(
        archiveWorkspaceModal.data._id,
        !archiveWorkspaceModal.data.isArchived,
        handleArchiveCallback
      )
    )
  }

  const handleArchiveCallback = (res, err) => {
    if (err) setArchiveWorkspaceModal(prev => ({ ...prev, loading: false }))
    else {
      handleArhciveWorkspaceModal()
      history.push(`${getUserPath(meData.role)}/workspace/all`)
    }
  }

  const completeStatus = () => {
    setCompleteWorkspaceModal(prev => ({ ...prev, loading: true }))
    dispatch(
      UpdateWorkspceCompleteStatus(
        currentWorkspace._id,
        { isComplete: true },
        completeStatusCallback
      )
    )
  }

  const completeStatusCallback = (res, err) => {
    if (err) {
      setCompleteWorkspaceModal(prev => ({ ...prev, loading: false }))
      dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    } else {
      handleCompleteWorkspaceModal()
      history.push(`${getUserPath(meData.role)}/workspace/all`)
    }
  }

  const assignedTags = id => {
    const workspaceTag = userTags.data?.find(tag =>
      tag.workspaceIds.includes(id)
    )
    if (workspaceTag) {
      return [workspaceTag]
    } else {
      return []
    }
  }

  // const closeEmbedModalHandler = () => {
  //   setEmbedModalOpen(false)
  // }

  const duplicateWorkspaceHandler = (workspace, data) => {
    dispatch(
      createWorkspaceCopy(workspace?._id, data, (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster(`Duplicate was created successfully!`))
          dispatch(
            fetchWorkspacePermissions((res, err) => {
              // setLoading(false)
              if (err) {
                dispatch(fireErrorToaster(res))
              }
            })
          )
        } else {
          // setLoading(false)
          dispatch(fireErrorToaster(res))
        }
      })
    )
    // setLoading(true)
    dispatch(
      fireProcessToaster(
        `Duplicate of ${workspace.name} is being created! Please wait...`
      )
    )
  }

  const handleShareBoardDialog = () => {
    setShareBoladDialogStatus(prev => !prev)
  }

  const handleSidebar = () => {
    dispatch(toggleSidebar())
  }

  const toggleAddTeammatePanel = () => {
    dispatch(toggleEditTeammatesSidePanel())
  }

  // const openEmbedModalHandler = () => {
  //   setEmbedModalOpen(true)
  // }

  return (
    <>
      <div className="w-full min-w-full h-full px-4 lg:px-6">
        <div className="w-full min-w-full h-full flex items-center flex-shrink-0 relative ">
          <div className="flex items-center h-full flex-auto pr-2 lg:pr-4">
            {!isSidebarOpen && (
              <button>
                <IndentIncrease
                  width="2em"
                  height="2em"
                  onClick={handleSidebar}
                  className="text-primary-mid-dark hover:text-primary-main p-1 rounded-md w-7 h-7 mr-4"
                />
              </button>
            )}

            <div
              className="h-10 w-10 lg:h-12 lg:w-12 rounded-xl overflow-hidden mr-4 flex items-center justify-center cursor-pointer"
              style={{
                backgroundColor: currentWorkspace?.backgroundcolor,
                boxShadow: 'inset 0 -2px rgb(0 0 0 / 5%)'
              }}
              onClick={e => {
                if (
                  workspacePermission.update &&
                  meData.role < userRoles.USER_CLIENT
                ) {
                  setIconPopoverData({ open: true, anchorEl: e.currentTarget })
                }
              }}
            >
              {AsanaIcons[currentWorkspace?.image] ? (
                AsanaIcons[currentWorkspace?.image]({
                  height: '24px',
                  width: '24px',
                  className: 'selected-icon'
                })
              ) : (
                <AsanaIcons.Bug height="24px" width="24px" />
              )}
            </div>

            <div className="flex flex-col items-start h-full flex-grow">
              <div className={classes.titleContainer}>
                <div className="w-full flex items-center font-medium mb-0">
                  {editName.edit ? (
                    <form onSubmit={updateProjectName}>
                      <input
                        value={editName.projectName}
                        onChange={e =>
                          setEditName(prev => ({
                            ...prev,
                            projectName: e.target.value
                          }))
                        }
                        autoFocus
                        onBlur={updateProjectName}
                        className="border p-1 w-full"
                      />
                    </form>
                  ) : (
                    <CustomTooltip
                      title={
                        currentWorkspace?.name?.length > 35
                          ? currentWorkspace?.name
                          : ''
                      }
                      placement="top"
                    >
                      <h2
                        className="w-full cursor-pointer truncate text-sm lg:text-base"
                        onClick={handleProjectNameUpdate}
                      >
                        {currentWorkspace?.name}
                      </h2>
                    </CustomTooltip>
                  )}
                  {userTags.fetched ? (
                    <AssignedTags
                      assignedTag={assignedTags(currentWorkspace?._id)}
                      workspace={currentWorkspace}
                      elmFor="workspace"
                      className="flex-wrap ml-1"
                    />
                  ) : null}
                </div>
                {workspacePermission.update &&
                  meData.role < userRoles.USER_CLIENT && (
                    <IconButton
                      className={classes.headerBtns}
                      onClick={openWorkspaceMenu}
                    >
                      <MoreVertRoundedIcon fontSize="small" />
                    </IconButton>
                  )}
              </div>

              <WorkspaceTabs
                embedModalOpen={embedModalOpen}
                setEmbedModalOpen={setEmbedModalOpen}
                // openEmbedModalHandler={openEmbedModalHandler}
              />
            </div>

            <div className="flex items-center h-full pl-2 flex-grow-0 flex-shrink-0 gap-2">
              {currentWorkspace?.isArchived && (
                <Pill variant="gray">Archived</Pill>
              )}
              {currentWorkspace?.isComplete && (
                <Pill variant="green">Completed</Pill>
              )}
              <div className="flex items-center">
                <ColoredAvatarGroup
                  users={teammates}
                  tooltip={true}
                  // expandOnHover={true}
                />
                {workspacePermissionData?.update && (
                  <button
                    onClick={toggleAddTeammatePanel}
                    className="-ml-2 z-10 bg-white text-gray-500 w-6 h-6 lg:w-8 lg:h-8 rounded-full border border-gray-300 border-dashed flex justify-center items-center"
                  >
                    <AddIcon />
                  </button>
                )}
              </div>
              <div>
                {workspacePermissionData?.update &&
                  meData.role < userRoles.USER_CLIENT && (
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      startIcon={
                        <TwoUserIcon className="w-3 h-3 lg:w-4 lg:h-4" />
                      }
                      onClick={handleShareBoardDialog}
                    >
                      Share
                    </Button>
                  )}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3 flex-shrink-0 pl-2 lg:pl-4">
            {meData.role === userRoles.USER_AGENCY &&
            !planDataLoading &&
            [
              'enterprise',
              'plan-d',
              'plan-c',
              'plan-b',
              'plan-a',
              'plan-e'
            ].every(i => {
              return !userActivePlan?.name?.toLocaleLowerCase()?.includes(i)
            }) ? (
              <Button
                onClick={() =>
                  history.push(
                    `${getUserPath(meData.role)}/settings/subscription`
                  )
                }
                size="small"
                startIcon={<TrendingUpOutlinedIcon fontSize="small" />}
              >
                Upgrade
              </Button>
            ) : null}
            {/*
          Here i'm sending user data instead of me data bcoz when user updates his profile picture
          user data gets updated and not the me data (meData vs userData issue)
          */}
            <HeaderProfileMenu meData={userData} size="medium" />
          </div>
        </div>
      </div>
      <WorkspaceMenu
        open={Boolean(worksapceMenuEl)}
        anchorEl={worksapceMenuEl}
        onClose={closeWorksapceMenu}
        workspace={currentWorkspace}
        duplicateWorkspace={duplicateWorkspaceHandler}
        EditWorkspace={handleOpenEditWorkspaceDialog}
        deleteWorkspace={handleDeleteWorkspaceModal}
        archiveWorkspace={handleArhciveWorkspaceModal}
        completeStatus={handleCompleteWorkspaceModal}
      />

      <WorkspaceIconPopover
        setIconPopoverData={setIconPopoverData}
        iconPopoverData={iconPopoverData}
        onClose={() => setIconPopoverData({ open: false, anchorEl: null })}
        workspace={currentWorkspace}
      />

      <EditWorkspaceDialog
        handleDialog={handleOpenEditWorkspaceDialog}
        open={editWorkspaceDialog.open}
        workspace={editWorkspaceDialog.workspace}
      />

      <AlertModal
        warningText="All the Tasks, Attachments and Invoices related to this project will be completely deleted."
        open={deleteWorkspaceModal.open}
        handleDialog={handleDeleteWorkspaceModal}
        handleDeleteAction={handleDeleteWorkspace}
        loading={deleteWorkspaceModal.loading}
      />

      <AlertModal
        warningText={`All the Tasks and Attachments related to this project will be ${
          archiveWorkspaceModal.data.isArchived ? 'unachived' : 'archived'
        }.`}
        open={archiveWorkspaceModal.open}
        handleDialog={handleArhciveWorkspaceModal}
        handleDeleteAction={handleArchiveWorkspace}
        loading={archiveWorkspaceModal.loading}
        deleteBtnText={
          archiveWorkspaceModal.data.isArchived ? 'Unarchive' : 'Archive'
        }
      />

      {/* Mark As complete Dialog Box */}
      <AlertModal
        warningText="Once you mark this project as completed you won't be able to change its status back and won't be able to make any changes either."
        open={completeWorkspaceModal.open}
        handleDialog={handleCompleteWorkspaceModal}
        handleDeleteAction={completeStatus}
        loading={completeWorkspaceModal.loading}
        deleteBtnText="Complete"
      />

      {currentWorkspace?.sharing && shareBoladDialogStatus && (
        <ShareBoardModal
          open={shareBoladDialogStatus}
          onClose={handleShareBoardDialog}
          workspace={currentWorkspace}
          boardSections={currentBoard.boardSection ?? []}
          sharing={currentWorkspace.sharing}
          workspacePermission={workspacePermissionData}
        />
      )}
    </>
  )
}

export default React.memo(WorkspaceHeader)
