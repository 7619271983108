import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { hideChatMessenger } from 'utils'
import { ReactComponent as AngleDown } from 'static/svg/angle-down.svg'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { ReactComponent as CheckCircle } from 'static/svg/check-circle.svg'
import clsx from 'clsx'
import { cancelFileUpload } from 'thunks/fileAssets/actions'

const FileUploading = () => {
  const dispatch = useDispatch()
  const [totalUploaded, setTotalUploaded] = useState(0)
  const { uploadingFiles } = useSelector((state) => state.filesAssets)
  const [visibleState, setVisibleState] = useState('hidden') //hidden, visible, partially-visible
  const classes = useStyles({ visibleState })

  let radius = 10
  let circumference = radius * 2 * Math.PI

  useEffect(() => {
    if (uploadingFiles.length) {
      hideChatMessenger()
      setTotalUploaded(uploadingFiles.filter((item) => item.isComplete).length)
    }
  }, [uploadingFiles])

  useEffect(() => {
    if (uploadingFiles.length) {
      setVisibleState('visible')
    } else {
      setVisibleState('hidden')
    }
  }, [uploadingFiles.length, totalUploaded])

  const toggleVisibilityState = (e) => {
    const value = e.currentTarget.dataset.value
    setVisibleState(value)
  }

  const handleCancelUpload = (e) => {
    dispatch(cancelFileUpload(e.currentTarget.dataset.id))
  }

  return (
    <>
      {Boolean(uploadingFiles.length) && (
        <div
          className={clsx(
            'w-72 bg-white fixed rounded shadow-lg bottom-0 right-4',
            classes.container
          )}
        >
          <header className="bg-primary-main px-6 py-3 text-white text-sm flex items-center justify-between">
            <h4 className="font-medium text-white">
              {totalUploaded !== 0
                ? `${totalUploaded} ${
                    totalUploaded > 1 ? 'files' : 'file'
                  } uploaded`
                : 'Uploading...'}
            </h4>

            <div className="flex items-center">
              <button
                onClick={toggleVisibilityState}
                data-value={
                  visibleState === 'partially-visible'
                    ? 'visible'
                    : 'partially-visible'
                }
                className={clsx(
                  'text-2xl mr-2 transition',
                  visibleState === 'partially-visible' && 'rotate-180'
                )}
              >
                <AngleDown />
              </button>
              <button onClick={toggleVisibilityState} data-value="hidden">
                <CloseIcon className="w-5 h-5" />
              </button>
            </div>
          </header>
          <div className="px-6 py-4 h-40 overflow-y-auto">
            <ul>
              {uploadingFiles.map((item, index) => (
                <li
                  key={item.uid}
                  className="flex justify-between items-center mb-2"
                >
                  <p className="truncate text-sm w-2/3">
                    {item.name}
                    {item.extension}
                  </p>
                  <div className="relative flex">
                    {item.percentage === 0 ||
                    (item.percentage === 100 && !item.isComplete) ? (
                      <CircularProgress size={23} />
                    ) : item.isComplete ? (
                      <CheckCircle className="w-5 h-5 text-custom-green-main" />
                    ) : (
                      <>
                        <svg className="relative z-10" width="26" height="26">
                          <circle
                            stroke="#32c632"
                            stroke-width="4"
                            fill="transparent"
                            r={radius}
                            cx="13"
                            cy="13"
                            style={{
                              strokeDasharray: `${circumference} ${circumference}`,
                              strokeDashoffset:
                                circumference -
                                (item.percentage / 100) * circumference,
                              transition: 'all 1s ease-in-out',
                              transformOrigin: 'center',
                              transform: 'rotate(-90deg)'
                            }}
                            strokeLinecap="round"
                            className="origin-center"
                          ></circle>
                        </svg>
                        <svg className="absolute top-0" width="26" height="26">
                          <circle
                            stroke="#efefef"
                            stroke-width="4"
                            fill="transparent"
                            r={radius}
                            cx="13"
                            cy="13"
                          />
                        </svg>
                        {!item.isComplete && (
                          <button
                            className="text-custom-red-light ml-2"
                            onClick={handleCancelUpload}
                            data-id={item.uid}
                          >
                            <CloseIcon className="text-xs text-gray-500 hover:text-custom-red-main" />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(() => ({
  container: ({ visibleState }) => ({
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
    transform: `translateY(${
      visibleState === 'hidden' ? 100 : visibleState === 'visible' ? 0 : 77
    }%)`,
    transition: 'transform 300ms ease-in-out',
    zIndex: 99999
  })
}))

export default FileUploading
