import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import usePlanFunctions from 'components/AgencyPlans/usePlanFunctions'
import { openChatMessengerWithMessage } from 'utils'

function SubscriptionCard({
  plan,
  isActive,
  activePlan,
  loading,
  toggleUpgradePlanModal,
  selectedSeats,
  contactUs
}) {
  // const [selectedSeats, setSelectedSeats] = useState(1)
  const [totalAmount, setTotalAmount] = useState(0)
  const { getSeatBasedPlanAmount } = usePlanFunctions()

  // useEffect(() => {
  //   if (isActive && activePlan.quantity) setSelectedSeats(activePlan.quantity)
  // }, [isActive, activePlan])

  useEffect(() => {
    setTotalAmount(getSeatBasedPlanAmount({ plan, seats: selectedSeats }))
  }, [plan.tiers, selectedSeats])

  const handleUpgradePlanClick = () => {
    toggleUpgradePlanModal({
      plan:
        activePlan.payment_plan.id === plan.id ? activePlan.payment_plan : plan,
      quantity: selectedSeats,
      amount: totalAmount
    })
  }

  const handleContactUs = () => {
    openChatMessengerWithMessage('I want to upgrade my plan')
  }

  // const updateSeatCount = (e) => {
  //   const action = e.currentTarget.dataset.action

  //   if (selectedSeats === 1 && action === 'minus') return
  //   setSelectedSeats((prev) => (action === 'plus' ? prev + 1 : prev - 1))
  // }

  return (
    <div
      style={{
        minWidth: '206px'
      }}
      key={plan.id}
      className={`rounded-xl p-6 w-60 flex flex-col justify-between text-center ${
        isActive ? 'border-2 border-primary-main' : 'border border-gray-300'
      } shadow-md`}
    >
      <header>
        <p className="text-smaller md:text-sm lg:text-base font-medium mb-2">
          {plan?.nickname ?? 'Static'}
        </p>
      </header>

      <div className="text-center mb-2">
        <div className="text-sm md:text-base lg:text-xl font-semibold">
          {/* ${parseFloat(plan.amount / 100).toFixed(2)} */}$
          {totalAmount.toFixed(2)}
        </div>
        <div className="text-smaller lg:text-sm text-gray-600">
          seat / {plan.interval}
        </div>
      </div>

      <footer className="mt-4">
        {/* <div className="flex justify-center items-center space-x-2 mt-2 mb-4">
          <button
            onClick={updateSeatCount}
            data-action="minus"
            className="bg-primary-main text-white w-5 h-5 flex justify-center items-center border border-gray-400 rounded"
          >
            &minus;
          </button>
          <input
            type="number"
            className="w-8 h-8 border border-gray-400 rounded text-center text-primary-main"
            value={selectedSeats}
          />
          <button
            onClick={updateSeatCount}
            data-action="plus"
            className="bg-primary-main text-white w-5 h-5 flex justify-center items-center border border-gray-400 rounded"
          >
            &#43;
          </button>
        </div> */}
        {contactUs ? (
          <Button
            variant="outlined"
            className="w-full"
            onClick={handleContactUs}
          >
            Contact Us
          </Button>
        ) : (
          <LoadingButton
            variant={isActive ? 'contained' : 'outlined'}
            onClick={handleUpgradePlanClick}
            disabled={isActive && selectedSeats === activePlan.quantity}
            loading={plan.id === loading}
            className="w-full"
          >
            {isActive && selectedSeats === activePlan.quantity
              ? 'Active Plan'
              : 'Upgrade Plan'}
          </LoadingButton>
        )}
      </footer>
    </div>
  )
}

export default SubscriptionCard
