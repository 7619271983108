import axios from 'axios'
import { errorMessages } from 'utils'
import { REPORTS } from './reducer'

const transformStandupsReport = res => {
  const response = { ...res }

  response.data = response.data.map(item => {
    item.profile = {
      ...item.profile,
      hourlyRate: item.profile.hourlyRate || 0,
      workingDays: item.profile.workingDays
        ? {
            ...item.profile.workingDays,
            count: Object.values(item.profile.workingDays).filter(item => item)
              .length
          }
        : { count: 0 },
      capacity: response.agencyProfile?.capacity,
      defaultCurrency:
        response.agencyProfile?.defaultCurrency?.toUpperCase() || 'USD'
    }

    return item
  })
  return response
}

/**
 * Get public workspace status (whether it's public or not)
 * @param {String} id workspace ID
 * @param {Function} callback Callback function
 */
export const fetchPeoplePerformanceData = (query, callback) => {
  return async dispatch => {
    const url =
      query !== '' ? `/user/teammate/report?${query}` : '/user/teammate/report'

    try {
      dispatch({ type: REPORTS.LOADING })
      const res = await axios({
        method: 'GET',
        url
      })

      dispatch({ type: REPORTS.FETCHED, payload: res.data })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback) {
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      }
    }
  }
}

/**
 * Fetch date wise teammate's standups summary
 * @param {String} query
 * @param {Function} callback Callback function
 */
export const fetchStandupsReport = (query, callback) => {
  return async dispatch => {
    try {
      dispatch({ type: REPORTS.LOADING_STANDUPS })
      const res = await axios({
        url: `/standups/summary?${query}`,
        method: 'GET'
      })

      const transformedResponse = transformStandupsReport(res.data)
      const sortedData = transformedResponse.data.sort(
        (a, b) => b.standups.length - a.standups.length
      )

      dispatch({
        type: REPORTS.FETCHED_STANDUPS_SUMMARY,
        payload: sortedData
      })
      if (callback) callback(sortedData.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch date wise user's standups summary
 * @param {String} query
 * @param {Function} callback Callback function
 */
export const fetchStandupsReportByUserId = (userId, query, callback) => {
  return async dispatch => {
    try {
      const res = await axios({
        url: `/standups/summary/user/${userId}?${query}`,
        method: 'GET'
      })
      if (callback) callback(res.data)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}

/**
 * Fetch date wise client revenue
 * @param {String} query
 * @param {Function} callback Callback function
 */
export const fetchClientRevenue = (query, callback) => {
  return async (dispatch, getState) => {
    const {
      me: { data: meData }
    } = getState()
    const agencyCurrency =
      meData.profile?.defaultCurrency?.toUpperCase() ??
      meData.team?.profile?.defaultCurrency?.toUpperCase() ??
      'USD'
    try {
      const res = await axios({
        url: `user/client/revenue?${query}`,
        method: 'GET'
      })
      if (callback) callback(res.data)
      const transformedRevenueData = res.data.clientwiseRevenue.map(client => {
        if (client.invoices.length) {
          const totalPayments = client.invoices
            .filter(invoice => invoice.currency === agencyCurrency)
            .reduce((acc, obj) => acc + obj.paidAmount, 0)

          return { ...client, totalPayments: totalPayments }
        } else {
          return { ...client, totalPayments: 0 }
        }
      })

      dispatch({
        type: REPORTS.FETCHED_FINANCIALS,
        payload: { ...res.data, clientwiseRevenue: transformedRevenueData }
      })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
