import React, { useEffect, useState } from 'react'
import { IconButton, Divider, Tabs, Tab } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Select from 'react-select'
import {
  assignWorkspace,
  updateTeammateRole,
  removeTeammateFromWorkspace
} from 'thunks/workspace/actions'
import userRoles from 'utils/userRoles'
import { getClient } from 'thunks/addClient/actions'
import clsx from 'clsx'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useWorkspacePermission } from 'customHooks'
import { errorMessages } from 'utils'
import {
  CustomModal,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import TotalMember from './TotalMember'
import MemberList from './MemberList'
import CloseIcon from '@material-ui/icons/Close'
import newTeammateStyles from './newTeammateStyles'

const NewClientModal = (props) => {
  const { open, onClose } = props

  return (
    <CustomModal open={open} handleClose={onClose} size="medium">
      <CustomModalHeader heading="Share project" />
      <NewClientModalContents />
    </CustomModal>
  )
}

const NewClientModalContents = () => {
  const classes = newTeammateStyles()
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const userTeammates = useSelector((state) => state.userTeammates.data)
  const { workspace: workspacePermission } = useWorkspacePermission(
    currentWorkspace?._id
  )
  const me = useSelector((state) => state.me.data)
  const userClients = useSelector((state) => state.userClients)
  const [agencyTeammates, setAgencyTeammates] = useState([])
  const [clientTeammates, setClientTeammates] = useState([])
  const [userState, setUserState] = useState(0)
  const [teammateOptions, setTeammateOptions] = useState([])
  const [selectedTeammates, setSelectedTeammates] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  // const canUpdate = type === 'client' && workspacePermission?.

  useEffect(() => {
    if (!userClients.fetched && me?.role < userRoles.USER_CLIENT) {
      dispatch(getClient())
    }
  }, [userClients.fetched])

  // const handleTeammateSelection = (e) => {
  //   setSelectedTeammates(e)
  // }

  const addTeammateCallback = (res, err) => {
    setLoading(false)
    if (err) {
    } else {
      dispatch(fireSuccessToaster('teammate added successfully'))
      setSelectedTeammates([])
    }
  }

  useEffect(() => {
    if (currentWorkspace?.assignedAgencyTeam) {
      const filteredAgencyTeammates = currentWorkspace.assignedAgencyTeam.filter(
        (item) =>
          ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(item.role)
      )

      const filteredClientTeammates = currentWorkspace.assignedClientTeam.filter(
        (item) => item.role !== userRoles.USER_CLIENT
      )

      const options = []
      const addedClientMates = filteredClientTeammates.map(
        (item) => item.user._id
      )

      userTeammates.map((mate) => {
        if (!addedClientMates?.includes(mate._id)) {
          options.push({
            label: `${mate.name} (${mate?.email.toLowerCase()})`,
            value: mate._id
          })
        }
      })

      setTeammateOptions(options)
      setAgencyTeammates(filteredAgencyTeammates)
      setClientTeammates(filteredClientTeammates)
    }
  }, [userTeammates, currentWorkspace])

  const handleAddTeammate = () => {
    if (selectedTeammates?.length === 0) {
      return dispatch(fireErrorToaster('please enter the clients'))
    }
    if (workspacePermission?.update) {
      const selectedTeammate = selectedTeammates.map((i) => i.value)
      setLoading(true)
      const teammate = userTeammates
        .filter((team) => selectedTeammate.includes(team._id))
        .map((el) => el.name)
      dispatch(
        assignWorkspace(
          {
            workspace: currentWorkspace._id,
            assignToAgencyTeammate: [],
            assignToClientTeammate: selectedTeammate
          },
          teammate,
          addTeammateCallback
        )
      )

      // Since we added a new teammate in a workspace we need to fetch the updated data of teammates
      // So that we could show the changes on Teammates page
      // dispatch(fetchTeammateOfCurrentUser())
    } else {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
    }
  }

  const handleRemoveTeammate = (teammate) => {
    if (teammate.user._id === me.profile?.user) {
      return dispatch(
        fireErrorToaster('You are not authorizes to remove yourself')
      )
    }
    dispatch(
      removeTeammateFromWorkspace(
        currentWorkspace._id,
        {
          assignToClientTeammate: {
            user: teammate.user._id,
            role: teammate.role
          }
        },
        teammate.user?.name
      )
    )
  }

  const handleUserRoleUpdate = (value, user) => {
    if (user === me.profile?.user) {
      dispatch(fireErrorToaster('You are not authorised to update your role'))
      return
    }
    const userObj = userTeammates.find((team) => team._id === user)
    dispatch(
      updateTeammateRole(
        currentWorkspace._id,
        {
          assignToClientTeammate: { user, role: value }
        },
        userObj?.name
      )
    )
  }

  const handleUserState = (value) => {
    setUserState(value)
  }

  const threeDotProps = (userState) => {
    const arr = [
      {
        name: 'Can manage',
        description: 'Team can add, edit, and delete anything in the project',
        method: handleUserRoleUpdate,
        role: userRoles.CLIENT_MANAGER
      },
      {
        name: 'Can edit',
        description:
          "Team can edit and view, but can't add and delete anything in the project.",
        method: handleUserRoleUpdate,
        role: userRoles.CLIENT_EDITOR
      },
      {
        name: 'Can view',
        description: "Team can view, but can't edit anything in the project.",
        method: handleUserRoleUpdate,
        role: userRoles.CLIENT_VIEWER
      },
      {
        name: 'Remove from project',
        method: handleRemoveTeammate
      }
    ]
    if (workspacePermission?.update) {
      return arr
    }
  }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
    setUserState(newVal)
  }

  return (
    <>
      <div className="px-8">
        <div>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            {['Share', 'Agency Team', 'Client Team'].map((item, index) => (
              <Tab label={item} key={index} />
            ))}
          </Tabs>
        </div>
        <Divider orientation="horizontal" />

        {userState === 0 && (
          <>
            <div className="w-full mt-6 mb-3">
              <div className={`${classes.addUser} mb-2`}>Add teammate</div>
              <div className="flex items-center justify-between gap-2">
                <Select
                  placeholder="Add Teammates"
                  isSearchable={true}
                  onChange={(e) => setSelectedTeammates(e)}
                  value={selectedTeammates}
                  isMulti
                  options={teammateOptions}
                  styles={reactSelectCustomStyles}
                  className="flex-grow"
                  maxMenuHeight={150}
                />
                <LoadingButton onClick={handleAddTeammate} loading={loading}>
                  Add
                </LoadingButton>
              </div>
              <TotalMember
                members={clientTeammates}
                handleUserState={handleUserState}
                userState={2}
                type="client"
              />
            </div>
          </>
        )}

        {userState === 1 && (
          <MemberList members={agencyTeammates} userState={1} type="client" />
        )}

        {userState === 2 && (
          <MemberList
            members={clientTeammates}
            threeDotProps={threeDotProps}
            userState={2}
            type="client"
          />
        )}

        <div className={clsx('mt-6', classes.footer)}></div>
      </div>
    </>
  )
}

export default NewClientModal
