export const REPORTS = {
  LOADING: 'REPORTS_LOADING',
  LOADING_STANDUPS: 'REPORTS_LOADING_STANDUPS',
  FETCHED: 'REPORTS_FETCHED',
  FETCHED_STANDUPS_SUMMARY: 'REPORTS_FETCHED_STANDUPS_SUMMARY',
  FETCHED_FINANCIALS: 'REPORTS_FETCHED_FINANCIALS'
}

const initialState = {
  fetched: false,
  loading: true,
  data: [],
  standupsSummary: {
    loading: true,
    fetched: false,
    data: []
  },
  financials: {
    loading: true,
    fetched: false,
    data: { avgInputCost: 0, clientwiseRevenue: [] }
  }
}

const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS.LOADING:
      return {
        ...state,
        loading: true
      }

    case REPORTS.FETCHED:
      return { ...state, loading: false, fetched: true, data: action.payload }

    case REPORTS.LOADING_STANDUPS:
      return {
        ...state,
        standupsSummary: { ...state.standupsSummary, loading: true }
      }

    case REPORTS.FETCHED_STANDUPS_SUMMARY:
      return {
        ...state,
        standupsSummary: { loading: false, fetched: true, data: action.payload }
      }

    case REPORTS.FETCHED_FINANCIALS:
      return {
        ...state,
        financials: { loading: false, fetched: true, data: action.payload }
      }

    default:
      return state
  }
}

export default permissionReducer
