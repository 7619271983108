import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import { hideChatMessenger } from 'utils'
import embedStyles from './EmbedStyles'
import theme from 'themes/themes'

const EmbedFrame = ({ name, shared = false }) => {
  const classes = embedStyles()
  const embedData = useSelector((state) => state.userWorkspaceEmbed)
  // const sideDrawerOpen = useSelector(
  //   (state) => state.generalAppState.sideDrawerOpen
  // )
  const [embedLink, setEmbedLink] = useState({})
  // const [loading, setLoading] = useState(true)
  const iframeRef = useRef(null)

  useEffect(() => {
    hideChatMessenger()
  })

  // useEffect(() => {
  //   setLoading(true)
  // }, [name])

  useEffect(() => {
    if (!embedData?.loading) {
      const arr = window.location.href.split('/')
      const link = embedData.data?.find(
        (item) => item._id === arr[arr.length - 1]
      )
      setEmbedLink(link)
    }
  }, [embedData, name, window.location.href])

  // useEffect(() => {
  //   if (iframeRef.current) {
  //     iframeRef.current.addEventListener('load', () => {
  //       setLoading(false)
  //     })
  //   }
  // })

  return (
    <div
      className={clsx(classes.embedWrapper, 'relative', {
        expanded: !shared
      })}
    >
      <Loader shared={shared} />
      <iframe
        ref={iframeRef}
        src={embedLink?.embed_settings?.url}
        title={embedLink?.name}
        className={clsx('h-full w-full absolute z-20 shadow')}
      />
    </div>
  )
}

const Loader = ({ shared }) => (
  <div className="absolute  w-full h-full flex justify-center items-center bg-white">
    <CircularProgress />
  </div>
)

export default EmbedFrame
