// import userDashboardStyles from 'components/UserDashboard/userDashboardStyles'
// import { Drawer, CircularProgress } from '@material-ui/core'
import { DrawerContent } from 'global/globalComponents/CustomDrawer/CustomDrawer'
// import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { ReactComponent as UserCircle } from 'static/svg/user-circle.svg'
import { ReactComponent as TagIcon } from 'static/svg/tags.svg'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { ColoredAvatars } from 'global/globalComponents'
import { fetchLogsByWorkspaceId } from 'thunks/logs/action'
import { fetchPublicWorkspaceLogs } from 'thunks/publicSharedBoard/actions'
// import { ReactComponent as ArrowDown } from 'static/svg/arrow-down.svg'
// import { ReactComponent as ArrowUp } from 'static/svg/arrow-up.svg'
import { useParams } from 'react-router'
// import InfiniteScroll from 'react-infinite-scroll-component'
import { Skeleton } from '@material-ui/lab'

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

const WorkspaceLogs = ({ elmFor }) => {
  // const classes = userDashboardStyles()
  // const activity = useSelector((state) => state.logs.currentWorkspaceLogs)
  const dispatch = useDispatch()
  // const ref = useRef()
  const limit = 20
  const { _id: currentWorkspaceId, data: activity } = useSelector(
    (state) => state.logs.currentWorkspaceLogs
  )
  const currentWorkspace = useSelector(
    (state) => state.userWorkspaces.currentWorkspace
  )
  const [page, setPage] = useState(2)
  const [top, setTop] = useState(false)
  const [isNext, setIsNext] = useState(true)
  const [loading, setLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  // const { currentWorkspace._id } = useParams()

  useEffect(() => {
    if (activity.length < limit) {
      setIsNext(false)
      setTop(false)
    } else {
      setTop(true)
      setIsNext(true)
    }
  }, [currentWorkspaceId])

  useEffect(() => {
    if (currentWorkspace._id !== currentWorkspaceId && elmFor !== 'public') {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [currentWorkspace._id, currentWorkspaceId])

  const fetchLogs = () => {
    setIsFetching(true)
    if (elmFor !== 'public') {
      dispatch(
        fetchLogsByWorkspaceId(
          currentWorkspace._id,
          page,
          limit,
          fetchLogsCallback
        )
      )
    } else {
      dispatch(
        fetchPublicWorkspaceLogs(
          currentWorkspace._id,
          page,
          limit,
          fetchLogsCallback
        )
      )
    }
  }

  const fetchLogsCallback = (res, err) => {
    setIsFetching(false)
    if (!err) {
      if (res.length < limit) {
        setIsNext(false)
      } else {
        setPage(page + 1)
        setIsNext(true)
      }
    } else {
      setIsNext(false)
    }
  }

  // const goToTop = () => {
  //   ref.current.scrollIntoView()
  // }

  const handleScroll = (e) => {
    const target = e.currentTarget
    const offsetHeight = target.offsetHeight
    const scrollHeight = target.scrollHeight
    const scrollTop = target.scrollTop

    if (Math.ceil(scrollTop + offsetHeight) === scrollHeight) {
      if (isNext) fetchLogs()
    }
  }

  return (
    <div
      id="scrollableDiv"
      className="overflow-y-auto"
      onScroll={handleScroll}
      style={{ height: 300 }}
    >
      {/* <InfiniteScroll
        dataLength={activity.length}
        next={fetchLogs}
        hasMore={isNext}
        loader={
          isFetching && (
            <h2 className="flex justify-center items-center text-md font-medium mb-8 space-x-1 cursor-pointer">
              Fetching more logs...
            </h2>
          )
        }
        scrollableTarget="scrollableDiv"
        endMessage={
          activity.length > 10 ? (
            <p
              style={{
                textAlign: 'center',
                color: 'gray',
                marginBottom: '20px'
              }}
            >
              <b>No more logs to see!</b>
            </p>
          ) : null
        }
      > */}
      <DrawerContent
        open={true}
        style={{ overflow: 'hidden', paddingLeft: 0, paddingTop: 0 }}
      >
        <div className="flow-root mb-8">
          {/* <p className="text-xl font-medium text-gray-500 mt-4 mb-6">
              What's the status?
            </p> */}

          {!loading ? (
            <ul className="-mb-8">
              {activity.map((item, index) => (
                <li key={item.id}>
                  <div className="relative pb-2">
                    {index !== activity?.length - 1 ? (
                      <span
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                        style={{ left: '18px' }}
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      {item.activityType === 'comment' ? (
                        <>
                          <div className="relative px-1">
                            <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                              {!item.user ? (
                                <UserCircle
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ColoredAvatars user={item.user} size="small" />
                              )}
                            </div>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div>
                              <div style={{ fontSize: '12px' }}>
                                <a
                                  href={item.person?.href}
                                  className="font-medium text-gray-900"
                                >
                                  {!item.user?.isActive && item.user?.isDeleted
                                    ? `${item.user?.name} (inactive)`
                                    : item.user?.name ?? 'Deleted user'}
                                </a>
                              </div>
                              <p
                                className={`mt-1 text-sm text-gray-500 `}
                                style={{ fontSize: '13px' }}
                              >
                                Commented in the {item.category}{' '}
                                <strong>
                                  {' '}
                                  {item.category === 'task'
                                    ? item.task?.title
                                    : item.subTask?.title}
                                </strong>
                                {'  '}
                                <span className="block text-xs">
                                  {format(new Date(item.createdAt), 'd MMM p')}
                                </span>
                              </p>
                            </div>
                            <div
                              className="mt-2 text-sm text-gray-700"
                              style={{ fontSize: '13px' }}
                            >
                              {/* <p>{item.comment}</p> */}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              ></span>
                            </div>
                          </div>
                        </>
                      ) : item.activityType === 'assignment' ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                {!item.user ? (
                                  <UserCircle
                                    className="h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ColoredAvatars
                                    user={item.user}
                                    size="small"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div
                              className="text-gray-500"
                              style={{ fontSize: '13px' }}
                            >
                              <a
                                href={item.person?.href}
                                className="font-medium text-gray-900"
                              >
                                {!item.user?.isActive && item.user?.isDeleted
                                  ? `${item.user?.name} (inactive)`
                                  : item.user?.name ?? 'Deleted user'}
                              </a>{' '}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              ></span>{' '}
                              {item.assignedUser?.map((person, index) => (
                                <span className="font-medium text-gray-900">
                                  {!person?.isActive && person?.isDeleted
                                    ? `${person?.name} (inactive)`
                                    : person?.name ?? 'Deleted user'}
                                  {index < item.assignedUser?.length - 2
                                    ? ', '
                                    : index <= item.assignedUser?.length - 2
                                    ? ' and '
                                    : ''}
                                </span>
                              ))}{' '}
                              {!(
                                item.description.includes('role') ||
                                item.description.includes('created')
                              ) && (
                                <span>
                                  {item.description.includes('removed')
                                    ? 'from'
                                    : 'in'}{' '}
                                  {['workspace', 'workspaceInvoice'].includes(
                                    item.category
                                  ) ? (
                                    'this project '
                                  ) : item.category === 'task' ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: `the task <b>${item.task?.title}</b> `
                                      }}
                                    ></span>
                                  ) : item.category === 'subtask' ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: `the subtask <b>${item.subTask?.title}</b> `
                                      }}
                                    ></span>
                                  ) : null}
                                </span>
                              )}
                              <span
                                className={`block whitespace-nowrap text-xs mt-1`}
                              >
                                {format(new Date(item.createdAt), 'd MMM p')}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : item.activityType === 'tags' ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                <TagIcon
                                  className="h-5 w-5 text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div
                              className="text-sm text-gray-500"
                              style={{ fontSize: '13px' }}
                            >
                              <span className="mr-0.5">
                                <a
                                  href={item.person?.href}
                                  className="font-medium text-gray-900"
                                >
                                  {!item.user?.isActive && item.user?.isDeleted
                                    ? `${item.user?.name} (inactive)`
                                    : item.user?.name ?? 'Deleted user'}
                                </a>{' '}
                                {/* added tags */}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.description
                                  }}
                                ></span>
                              </span>{' '}
                              <span
                                className="p-1 px-2 text-xs font-bold capitalize"
                                style={{
                                  color: item.tag.color,
                                  backgroundColor: `${item?.tag?.color}15`,
                                  borderRadius: '20px'
                                }}
                              >
                                {item.tag.name}
                              </span>{' '}
                              <span>
                                {item.category === 'task' ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `to the task <b>${item.task?.title}</b> `
                                    }}
                                  ></span>
                                ) : item.category === 'workspace' ? (
                                  ` to this project`
                                ) : null}
                              </span>
                              <span
                                className={`block whitespace-nowrap text-xs mt-1`}
                              >
                                {format(new Date(item.createdAt), 'd MMM p')}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : item.activityType === 'log' ? (
                        <>
                          <div>
                            <div className="relative px-1">
                              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                {!item.user ? (
                                  <UserCircle
                                    className="h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ColoredAvatars
                                    user={item.user}
                                    size="small"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="min-w-0 flex-1 py-1.5">
                            <div
                              className="text-sm text-gray-500"
                              style={{ fontSize: '13px' }}
                            >
                              <span className="mr-0.5">
                                <a
                                  href={item.person?.href}
                                  className="font-medium text-gray-900"
                                >
                                  {!item.user?.isActive && item.user?.isDeleted
                                    ? `${item.user?.name} (inactive)`
                                    : item.user?.name ?? 'Deleted user'}
                                </a>{' '}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.description
                                  }}
                                ></span>
                              </span>{' '}
                              {!item.description.includes('<b>') && (
                                <span>
                                  {item.description.includes('marked') ? (
                                    item.category === 'task' ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: `to the task <b>${item.task?.title}</b> `
                                        }}
                                      ></span>
                                    ) : item.category === 'subtask' ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: `to the subtask <b>${item.subTask?.title}</b> `
                                        }}
                                      ></span>
                                    ) : null
                                  ) : item.description.includes('changed') ? (
                                    item.category === 'task' ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: `of the task <b>${item.task?.title}</b> `
                                        }}
                                      ></span>
                                    ) : item.category === 'subtask' ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: `of the subtask <b>${item.subTask?.title}</b> `
                                        }}
                                      ></span>
                                    ) : null
                                  ) : null}
                                </span>
                              )}
                              <span
                                className={` block whitespace-nowrap text-xs mt-1`}
                              >
                                {format(new Date(item.createdAt), 'd MMM p')}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <Loader />
          )}
        </div>

        {isFetching && (
          <p className="text-center text-sm my-4 text-gray-500">Loading...</p>
        )}
      </DrawerContent>
      {/* </InfiniteScroll> */}
    </div>
  )
}

const Loader = () => (
  <div>
    {Array(3)
      .fill('')
      .map((item, index) => (
        <div key={index} className="flex items-center space-x-4 mb-3">
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton variant="rect" height={15} width={200} />
        </div>
      ))}
  </div>
)

export default WorkspaceLogs
