import { userRoles, getRoleBadge } from 'utils'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import Pill from 'global/globalComponents/Pill/Pill'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as AngleDown } from 'static/svg/angle-down.svg'
// import { Check } from 'static/svg'
import Check from '@material-ui/icons/Check'
import React, { useState } from 'react'
import newTeammateStyles from './newTeammateStyles'
import { useSelector } from 'react-redux'
import PopOver from './TeammatePopover'

const roleText = {
  15: 'project owner',
  20: 'agency owner',
  21: 'can manage',
  22: 'can edit',
  23: 'can view',
  30: 'client owner',
  31: 'can manage',
  32: 'can edit',
  33: 'can view'
}

const MemberList = (props) => {
  const { members, threeDotProps, userState, type } = props
  const classes = newTeammateStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [user, setUser] = useState({})
  const [activeMember, setActiveMember] = useState(null)
  const meData = useSelector((state) => state.me.data)

  const handleClick = (event, mate) => {
    setAnchorEl(event.currentTarget)
    setUser(mate)
    setActiveMember(mate.user?._id)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActiveMember(null)
  }

  // const getRoleBagde = (roleNum) => {
  //   let roles = [...agencyRoleOptions, ...clientRoleOptions]
  //   const role = roles.find((obj) => obj.value === roleNum)

  //   return (
  //     <Pill
  //       style={{ paddingTop: 0, paddingBottom: 0 }}
  //       variant={
  //         role?.label === 'Manager'
  //           ? 'blue'
  //           : role?.label === 'Viewer'
  //           ? 'red'
  //           : 'yellow'
  //       }
  //     >
  //       {role?.label}
  //     </Pill>
  //   )
  // }

  return (
    <div className="w-full mt-6 mb-3">
      <div className="flex justify-between items-center">
        <div className={clsx(classes?.addUser)}>
          {members?.length}
          {type === 'agency'
            ? userState === 1
              ? ' teammates'
              : ' clients'
            : userState === 1
            ? ' teammates'
            : ' teammates'}
        </div>
        {members?.length > 0 && threeDotProps && (
          <p className={classes.addUser}>Manage permissions</p>
        )}
      </div>
      <div className={classes.list}>
        {members?.length > 0 ? (
          members?.map((mate) => (
            <Box
              key={mate.user._id}
              className={clsx(
                { active: activeMember === mate.user?._id },
                classes.teammatesListRow
              )}
            >
              <Box className="flex justify-center items-center gap-4">
                <ColoredAvatars user={mate.user} size="small" />
                <div className="flex flex-col">
                  <div className="flex items-center leading-3">
                    <span className="mr-2 text-custom-table-primaryText capitalize text-sm">
                      {mate.user.name}
                    </span>{' '}
                    {getRoleBadge(mate.role)}
                    {/* {mate.user.verticals?.length > 0 && (
                      <Pill variant={['pink', 'green'][0]}>
                        {mate.user.verticals[0].name}
                      </Pill>
                    )} */}
                  </div>
                  {(meData.role < userRoles.USER_CLIENT ||
                    mate.role >= userRoles.USER_CLIENT) && (
                    <span className="text-gray-500 text-xs mt-1">
                      {mate.user.email}
                    </span>
                  )}
                </div>
              </Box>
              {meData._id !== mate.user._id && threeDotProps && (
                <div>
                  <div
                    onClick={(e) => handleClick(e, mate)}
                    className={clsx(
                      { active: activeMember === mate.user?._id },
                      classes.icon,
                      'p-2 flex justify-center text-sm cursor-pointer'
                    )}
                  >
                    <p> {roleText[mate.role]}</p>
                    <AngleDown
                      className={`h-6 w-6 text-white fill-white`}
                      style={{ fill: 'gray', fontSize: '5px' }}
                    />
                  </div>

                  <PopOver
                    handleClose={handleClose}
                    threeDotProps={threeDotProps(userState)}
                    mate={user}
                    anchorEl={anchorEl}
                  />
                </div>
              )}
            </Box>
          ))
        ) : (
          <p className="text-sm text-gray-400">
            No Assigned{' '}
            {type === 'agency'
              ? userState === 1
                ? ' Teammate'
                : 'Client'
              : userState === 1
              ? 'Agency Teammate'
              : 'Teammate'}
          </p>
        )}
      </div>
    </div>
  )
}

export default MemberList
