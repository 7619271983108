const permissionObj = {
  billings: { label: 'Billings', value: 'billings' },
  clientRequest: { label: 'Client Requests', value: 'clientRequest' },
  clients: { label: 'Clients', value: 'clients' },
  fileAssets: { label: 'File Storage', value: 'fileAssets' },
  onboarding: { label: 'Onboarding', value: 'onboarding' },
  report: { label: 'Reports', value: 'report' },
  services: { label: 'Services', value: 'services' },
  settings: { label: 'Settings', value: 'settings' },
  setup: { label: 'Setup', value: 'setup' },
  subTask: { label: 'Subtasks', value: 'subTask' },
  subscription: { label: 'Subscription', value: 'subscription' },
  task: { label: 'Tasks', value: 'task' },
  teammate: { label: 'Teammates', value: 'teammate' },
  timesheet: { label: 'Timesheet', value: 'timesheet' },
  workspace: { label: 'Projects', value: ' workspace' },
  workspaceView: { label: 'Projects Tab', value: 'workspaceView' }
}

export default permissionObj
