import React, { useState, useEffect } from 'react'
import {
  TableContainer,
  Thead,
  Td,
  Tr,
  Th,
  Tbody,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { Tabs, Tab } from '@material-ui/core'
import { fetchAllTeammatesAnalytics } from 'thunks/widget/actions'
import Pill from 'global/globalComponents/Pill/Pill'
import widgetStyles from '../widgetStyles'
import InfoPopper from './InfoPopper'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { useSelector, useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'

const TeammateAnalyticsTable = ({ widgetId, editMode, handleRemoveWidget }) => {
  const classes = widgetStyles()
  const dispatch = useDispatch()
  const [mappedData, setMappedData] = useState([])
  const [teammatesData, setTeammatesData] = useState([])
  // const [loading, setLoading] = useState(true)
  const [tabsData, setTabsData] = useState([
    { label: 'Monthly', value: 0 },
    { label: 'Weekly', value: 1 }
  ])
  const [selectedTab, setSelectedTab] = useState(0)
  const [visibleTeammatesData, setVisibleTeammatesData] = useState([])
  const [anchorEl, setAnchorEl] = useState({
    element: null,
    data: {}
  })
  // const [totalTimeTracked, setTotalTimeTracked] = useState(0)
  const [sortByData, setSortByData] = useState({
    name: 0,
    trackedHours: 0,
    activeTasks: 0,
    projects: 0,
    completedLate: 0
  })
  const teammateTimesheet = useSelector(state => state.widget.teammateTimesheet)

  useEffect(() => {
    dispatch(
      fetchAllTeammatesAnalytics((res, err) => {
        if (err) {
          dispatch(fireErrorToaster(res))
        }
      })
    )
  }, [])

  useEffect(() => {
    if (teammateTimesheet.fetched) {
      const mappedData = teammateTimesheet.data.userWiseTimeTracked.map(
        item => {
          let totalDuration = 0,
            lateCompletedTasksCount = 0

          for (let timeObj of item.data.timeTrackedByDay) {
            totalDuration += timeObj.totalDuration
          }

          for (let taskObj of item.data.completedTasks) {
            if (taskObj.dueDate && taskObj.completedAt) {
              if (new Date(taskObj.completedAt) > new Date(taskObj.dueDate))
                lateCompletedTasksCount++
            }
          }

          // if (item.data.completedTasks.length)
          //   lateCompletedCount /= item.data.completedTasks.length

          // return [lateCompletedCount, lateCompletedCount.toFixed(1)]

          return {
            user: item.user,
            data: item.data,
            totalTrackedTime: (totalDuration / (1000 * 60 * 60)).toFixed(2), //in hours,
            lateCompletedTasksCount
          }
        }
      )

      setTeammatesData(mappedData)
      setMappedData(mappedData)
    }
  }, [teammateTimesheet.data, teammateTimesheet.fetched])

  useEffect(() => {
    if (teammatesData.length) {
      switch (selectedTab) {
        case 1:
          const updatedData = teammatesData.map(item => {
            const date = new Date()
            const today = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            ) //setting today from time 00:00:00
            const last6thDay = new Date(today)
            last6thDay.setDate(today.getDate() - 6)

            // filter fields: activeTasks, completed tasks, timeTrackedByDay,

            return {
              ...item,
              data: {
                ...item.data,
                activeTasks: item.data.activeTasks.filter(
                  item => new Date(item.createdAt) >= last6thDay
                ),
                completedTasks: item.data.completedTasks.filter(
                  task => new Date(task.completedAt) >= last6thDay
                ),
                timeTrackedByDay: item.data.timeTrackedByDay.filter(
                  timeObj => new Date(timeObj._id) >= last6thDay
                )
              }
            }
          })

          setVisibleTeammatesData(updatedData)
          break
        default:
          setVisibleTeammatesData([...teammatesData])
      }
    }
  }, [selectedTab, teammatesData.length])

  // useEffect(() => {
  //   if (teammatesData.length) {
  //     let totalDuration = 0

  //     for (let teammate of teammatesData) {
  //       for (let timeObj of teammate.data.timeTrackedByDay) {
  //         totalDuration += timeObj.totalDuration
  //       }
  //     }

  //     setTotalTimeTracked((totalDuration / (1000 * 60 * 60)).toFixed(2)) //in hours)
  //   }
  // }, [teammatesData.length])

  useEffect(() => {
    if (visibleTeammatesData.length) {
      const arr = [...visibleTeammatesData]

      // If there is no sort set, sort by createdAt (descending order)
      if (
        sortByData.name === 0 &&
        sortByData.trackedHours === 0 &&
        sortByData.activeTasks === 0 &&
        sortByData.projects === 0 &&
        sortByData.completedLate === 0
      ) {
        setVisibleTeammatesData([...mappedData])
        return
      }

      if (sortByData.name !== 0) {
        arr.sort((a, b) =>
          a.user.name.toLowerCase() > b.user.name.toLowerCase() ? 1 : -1
        )

        if (sortByData.name === -1) {
          arr.reverse()
        }
      }

      if (sortByData.trackedHours !== 0) {
        arr.sort(
          (a, b) => Number(a.totalTrackedTime) - Number(b.totalTrackedTime)
        )

        if (sortByData.trackedHours === -1) {
          arr.reverse()
        }
      }

      if (sortByData.activeTasks !== 0) {
        arr.sort(
          (a, b) => a.data.activeTasks.length - b.data.activeTasks.length
        )

        if (sortByData.activeTasks === -1) {
          arr.reverse()
        }
      }

      if (sortByData.projects !== 0) {
        arr.sort((a, b) => a.data.projects.length - b.data.projects.length)

        if (sortByData.projects === -1) {
          arr.reverse()
        }
      }

      if (sortByData.completedLate !== 0) {
        arr.sort(
          (a, b) => a.lateCompletedTasksCount - b.lateCompletedTasksCount
        )

        if (sortByData.completedLate === -1) {
          arr.reverse()
        }
      }

      setVisibleTeammatesData(arr)
    }
  }, [sortByData])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleSort = e => {
    const field = e.currentTarget.dataset.field

    setSortByData(prev => ({
      ...prev,
      [field]: prev[field] === 0 ? 1 : prev[field] === 1 ? -1 : 0
    }))
  }

  const handleDeleteWidgetClick = () => {
    handleRemoveWidget(widgetId)
  }

  return (
    <>
      <div className="mb-2 flex justify-between items-center pr-4">
        <h2 className="text-base lg:text-lg leading-6 font-medium text-gray-900 flex items-center">
          <span>
            Timesheet{' '}
            <span className="text-gray-500 text-sm">
              ({teammatesData.length})
            </span>
          </span>

          {/* <CustomTooltip title="Total hours" placement="top">
            <span className="rounded-full border border-gray-300 bg-white px-2 h-7 ml-2 flex justify-center items-center text-custom-dark-main text-sm truncate">
              {totalTimeTracked} h
            </span>
          </CustomTooltip> */}
        </h2>

        <div>
          {editMode ? (
            <button className="text-red-400" onClick={handleDeleteWidgetClick}>
              <TrashIcon />
            </button>
          ) : (
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              classes={{ root: classes.tabRoot }}
            >
              {tabsData.map((item, index) => (
                <Tab
                  label={
                    <div className="flex items-center gap-1 text-xs">
                      {item.label}
                    </div>
                  }
                  key={index}
                  value={item.value}
                />
              ))}
            </Tabs>
          )}
        </div>
      </div>

      <TableContainer
        mb={9}
        containerProps={{
          style: {
            overflowY: 'auto',
            height: 'calc(100% - 55px)',
            background: '#fff'
          }
        }}
      >
        <Thead className="sticky top-0" style={{ zIndex: 99 }}>
          <Th
            className="w-44 whitespace-nowrap h-14 cursor-pointer"
            data-field="name"
            onClick={handleSort}
          >
            <div className="flex items-center">
              <span>Name</span>
              <span className="ml-2">
                {sortByData.name === 1 ? (
                  <>&#8593;</>
                ) : sortByData.name === 0 ? (
                  <>&#8645;</>
                ) : (
                  <>&#8595;</>
                )}
              </span>
            </div>
          </Th>
          <Th></Th>
          <Th></Th>
          <Th></Th>
          <Th
            className="text-center h-14 cursor-pointer flex justify-center"
            data-field="trackedHours"
            onClick={handleSort}
          >
            <div className="flex items-center">
              <span>Tracked Hours </span>

              <span className="ml-2 text-sm">
                {sortByData.trackedHours === 1 ? (
                  <>&#8593;</>
                ) : sortByData.trackedHours === 0 ? (
                  <>&#8645;</>
                ) : (
                  <>&#8595;</>
                )}
              </span>
            </div>
          </Th>
          {/* <Th
            className="text-center h-14 cursor-pointer"
            data-field="activeTasks"
            onClick={handleSort}
          >
            <div className="flex items-center">
              <span>Open Tasks </span>
              {Boolean(sortByData.activeTasks) && (
                <span className="ml-2">
                  {sortByData.activeTasks === 1 ? <>&#8593;</> : <>&#8595;</>}
                </span>
              )}
            </div>
          </Th>
          <Th
            className="text-center h-14 cursor-pointer"
            data-field="projects"
            onClick={handleSort}
          >
            <div className="flex items-center">
              <span>Projects </span>
              {Boolean(sortByData.projects) && (
                <span className="ml-2">
                  {sortByData.projects === 1 ? <>&#8593;</> : <>&#8595;</>}
                </span>
              )}
            </div>
          </Th> */}
          {/* <Th
            className="text-center h-14 cursor-pointer"
            data-field="completedLate"
            onClick={handleSort}
          >
            <div className="flex items-center">
              <span>Delayed Tasks </span>
              {Boolean(sortByData.completedLate) && (
                <span className="ml-2">
                  {sortByData.completedLate === 1 ? <>&#8593;</> : <>&#8595;</>}
                </span>
              )}
            </div>
          </Th> */}
        </Thead>
        <Tbody>
          {teammateTimesheet.loading ? (
            <TableDataLoading cols={5} />
          ) : visibleTeammatesData.length > 0 ? (
            visibleTeammatesData.map((item, index) => (
              <Row
                key={index}
                {...item}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="5"
                className="text-left py-3 px-6 text-gray-500 font-normal"
              >
                There are no teammates
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      <InfoPopper
        {...anchorEl}
        onClose={() => setAnchorEl({ element: null, data: {} })}
      />
    </>
  )
}

const Row = ({
  user,
  // data,
  totalTrackedTime
  // lateCompletedTasksCount,
  // anchorEl,
  // setAnchorEl
}) => {
  // const timeTracked = useMemo(() => {
  //   let totalDuration = 0

  //   for (let timeObj of data.timeTrackedByDay) {
  //     totalDuration += timeObj.totalDuration
  //   }

  //   return (totalDuration / (1000 * 60 * 60)).toFixed(2) //in hours,
  // }, [data.timeTrackedByDay])

  // const [lateCompletedCount, lateCompletedPercentage] = useMemo(() => {
  //   let lateCompletedCount = 0

  //   for (let taskObj of data.completedTasks) {
  //     if (taskObj.dueDate && taskObj.completedAt) {
  //       if (new Date(taskObj.completedAt) > new Date(taskObj.dueDate))
  //         lateCompletedCount++
  //     }
  //   }

  //   if (data.completedTasks.length)
  //     lateCompletedCount /= data.completedTasks.length

  //   return [lateCompletedCount, lateCompletedCount.toFixed(1)]
  // }, [data.completedTasks])

  return (
    <Tr>
      <Td>
        <div className="flex items-center text-gray-900">
          <div>{user.name}</div>
          <div className="ml-2">
            {user.verticals.length ? (
              <Pill variant="yellow">{user.verticals[0].name}</Pill>
            ) : null}
          </div>
        </div>
      </Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
      <Td className="text-center">{totalTrackedTime}</Td>
      {/* <Td className="text-center">{data.activeTasks.length}</Td>
      <Td className="text-center">{data.projects.length}</Td> */}
      {/* <Td className="flex justify-center">
        <Completion
          completion={
            data.completedTasks.length
              ? (lateCompletedTasksCount / data.completedTasks.length) * 100
              : 0
          }
          lateCompletedCount={lateCompletedTasksCount}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </Td> */}
    </Tr>
  )
}

export default React.memo(TeammateAnalyticsTable)
