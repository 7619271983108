// import { Button } from '@material-ui/core'
// import { ReactComponent as Add } from 'static/svg/plus.svg'
// import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
// import SectionHeading from 'global/globalComponents/SectionHeading'
import React from 'react'
import { useSelector } from 'react-redux'
// import {
//   deleteSingleWebhook,
//   fetchAllWebhookEvents,
//   fetchUserCreatedWebhooks,
//   updateSingleWebhook
// } from 'thunks/webhooks/action'
// import {
//   Th,
//   Thead,
//   TableContainer,
//   Tbody,
//   Td,
//   Tr
// } from 'global/globalComponents/TailwindTable/TailwindTable'
// import { ToggleSwitch, AlertModal } from 'global/globalComponents'
// import { format } from 'date-fns'
// import { useBreadcrumbs } from 'customHooks'
// import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
// import WebhookModal from './WebhookModal'
import CommonWebhookPage from './CommonWebhookPage'

const AllWebhooks = () => {
  // const dispatch = useDispatch()
  const createdWebhook = useSelector(
    (state) => state.userWebhooks.createdWebhook
  )
  // const [toggleLoadingWebhooks, setToggleLoadingWebhooks] = useState([])
  // const [webhookModal, setWebhookModal] = useState({
  //   open: false,
  //   data: {},
  //   edit: false
  // })
  // const [deleteAlert, setDeleteAlert] = useState({
  //   open: false,
  //   data: {},
  //   loading: false
  // })

  // useBreadcrumbs([
  //   {
  //     name: 'Settings',
  //     link: `/settings/general`,
  //     index: 0
  //   },
  //   {
  //     name: 'Integrations',
  //     link: `/settings/integrations`,
  //     index: 1
  //   },
  //   {
  //     name: 'Webhooks',
  //     link: `/settings/integrations/webhooks`,
  //     index: 2
  //   }
  // ])

  // useEffect(() => {
  //   dispatch(fetchUserCreatedWebhooks())
  //   dispatch(fetchAllWebhookEvents())
  // }, [])

  // const toggleWebhookStatus = (e) => {
  //   const { name, checked } = e.target
  //   setToggleLoadingWebhooks((prev) => [...prev, name])
  //   dispatch(
  //     updateSingleWebhook(name, { isActive: checked }, toggleWebhookStatusCB)
  //   )
  // }

  // const toggleWebhookStatusCB = (res, err, id) => {
  //   setToggleLoadingWebhooks((prev) => prev.filter((i) => i !== id))
  // }

  // const toggleWebhookModal = () => {
  //   setWebhookModal((prev) => ({ open: !prev.open }))
  // }

  // const openEditModal = (data) => {
  //   setWebhookModal({ open: true, edit: true, data })
  // }

  // const handleDeleteWebhook = () => {
  //   setDeleteAlert((prev) => ({ ...prev, loading: true }))
  //   dispatch(deleteSingleWebhook(deleteAlert.data._id, deleteWebhookCallback))
  // }

  // const deleteWebhookCallback = (res, err) => {
  //   if (err) {
  //     fireErrorToaster(res)
  //     setDeleteAlert((prev) => ({ ...prev, loading: false }))
  //   } else {
  //     fireSuccessToaster('Webhook deleted successfully!')
  //     toggleDeleteAlert()
  //   }
  // }

  // const toggleDeleteAlert = (data) => {
  //   if (deleteAlert.open) {
  //     setDeleteAlert((prev) => ({ ...prev, open: false }))
  //   } else {
  //     setDeleteAlert({ data, open: true, loading: false })
  //   }
  // }

  return (
    <CommonWebhookPage elmFor="allWebhook" webhookData={createdWebhook} />
    // <>
    //   <OuterContentWrapper>
    //     <div className="flex items-center justify-between">
    //       <SectionHeading>Webhooks Setup</SectionHeading>
    //       <Button
    //         startIcon={<Add fontSize="small" />}
    //         onClick={toggleWebhookModal}
    //       >
    //         Add New
    //       </Button>
    //     </div>
    //     <div className="mt-12">
    //       <TableContainer shadow="none" mb={0}>
    //         <Thead>
    //           <Th>Webhook</Th>
    //           <Th center>Events</Th>
    //           <Th>Last Updated</Th>
    //           <Th center>Status</Th>
    //           <Th></Th>
    //         </Thead>
    //         <Tbody>
    //           {createdWebhook.length ? (
    //             createdWebhook.map((item) => (
    //               <Row
    //                 key={item._id}
    //                 item={item}
    //                 openEditModal={openEditModal}
    //                 toggleDeleteAlert={toggleDeleteAlert}
    //                 toggleWebhookStatus={toggleWebhookStatus}
    //                 isLoading={toggleLoadingWebhooks.includes(item._id)}
    //               />
    //             ))
    //           ) : (
    //             <Tr>
    //               <Td>No connected Webhook</Td>
    //               <Td />
    //               <Td />
    //               <Td />
    //               <Td />
    //             </Tr>
    //           )}
    //         </Tbody>
    //       </TableContainer>
    //     </div>
    //   </OuterContentWrapper>
    //   <WebhookModal
    //     {...webhookModal}
    //     onClose={toggleWebhookModal}
    //     elmFor="allWebhook"
    //   />
    //   <AlertModal
    //     open={deleteAlert.open}
    //     handleDialog={toggleDeleteAlert}
    //     handleDeleteAction={handleDeleteWebhook}
    //     warningText={`Do you want to delete ${deleteAlert.data.name} webhook`}
    //     heading="Alert"
    //     loading={deleteAlert.loading}
    //   />
    // </>
  )
}

// const Row = ({
//   item,
//   openEditModal,
//   toggleDeleteAlert,
//   toggleWebhookStatus,
//   isLoading
// }) => {
//   const handleOpenEditModal = () => {
//     openEditModal(item)
//   }

//   const handleDeleteWebhook = () => {
//     toggleDeleteAlert(item)
//   }

//   return (
//     <Tr>
//       <Td
//         className="text-primary-mid-dark font-medium text-sm font-bold cursor-pointer"
//         onClick={handleOpenEditModal}
//       >
//         {item.name}
//       </Td>
//       <Td center className="font-semibold text-gray-500">
//         {item.events?.length}
//       </Td>
//       <Td className="text-custom-gray-main text-xs font-semibold">
//         {format(new Date(item?.updatedAt ?? ''), 'do MMM, yyyy')}
//       </Td>
//       <Td center>
//         <ToggleSwitch
//           onChange={toggleWebhookStatus}
//           loading={isLoading}
//           name={item._id}
//           checked={item?.isActive}
//         />
//       </Td>
//       <Td center>
//         <button
//           onClick={handleDeleteWebhook}
//           className="w-8 h-8 border rounded-full inline-flex justify-center items-center"
//         >
//           <TrashIcon className="text-lg" />
//         </button>
//       </Td>
//     </Tr>
//   )
// }

export default AllWebhooks
