import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { userRoles } from 'utils'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
// import { OutlinedButton } from 'global/globalComponents'
// import { Tabs, Tab } from '@material-ui/core'
// import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
// import widgetStyles from '../widgetStyles'
import WidgetFilter from './WidgetFilter'

const WidgetHeader = ({
  tasksCount,
  editMode,
  handleDeleteWidgetClick,
  handleApplyFilters,
  widgetId,
  handleFilterClick
}) => {
  const meData = useSelector(state => state.me.data)
  // const [selectedTab, setSelectedTab] = useState(0)
  // const classes = widgetStyles()
  // const tabsData = [
  //   { label: 'Open', value: 0 },
  //   { label: 'Overdue', value: 1 }
  // ]

  // useEffect(() => {
  //   if (selectedTab === null) return

  //   switch (selectedTab) {
  //     case 1:
  //       // overdue
  //       setFilteredTasks(
  //         priorityTasks.filter(
  //           (item) => item.dueDate && new Date(item.dueDate) < new Date()
  //         )
  //       )
  //       break

  //     default:
  //       setFilteredTasks(priorityTasks.filter((task) => !task.markAsComplete))
  //   }
  // }, [selectedTab])

  // const handleTabChange = (e, newVal) => {
  //   setSelectedTab(newVal)
  //   // setSelectedOption(null)
  // }

  return (
    <div className="flex justify-between items-center py-2 pr-2 mb-1">
      <h2 className="text-base lg:text-lg leading-6 font-medium text-gray-900 flex items-center">
        Tasks <span className="text-gray-500 text-sm ml-2">({tasksCount})</span>
      </h2>

      <div>
        {editMode ? (
          meData.role < userRoles.USER_CLIENT ? (
            <button className="text-red-400" onClick={handleDeleteWidgetClick}>
              <TrashIcon />
            </button>
          ) : null
        ) : (
          <div className="flex items-center">
            {/* <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              classes={{ root: classes.tabRoot }}
            >
              {tabsData.map((item, index) => (
                <Tab
                  label={
                    <div className="flex items-center gap-1 text-xs">
                      {item.label}
                    </div>
                  }
                  key={index}
                  value={item.value}
                />
              ))}
            </Tabs> */}

            <div>
              <WidgetFilter
                handleApplyFilters={handleApplyFilters}
                widgetId={widgetId}
              />
              {/* <OutlinedButton
                isActive={isFilterActive}
                onClick={handleFilterClick}
              >
                <FilterIcon />
                <span className="ml-1">Filter</span>
              </OutlinedButton> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default WidgetHeader
