import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import StatusStrip from './StatusStrip'

const StatusCategory = ({
  title,
  id,
  info,
  statuses,
  openPopper,
  updateCategoryStatus
}) => {
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          // className={clsx(classes.taskCardsContainer)}
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
          // className="h-full overflow-y-scroll"
        >
          <div className="mb-8">
            <header className="flex items-center leading-3 mb-2">
              <h6 className="uppercase text-tiny text-gray-500">{title}</h6>
              <span className="ml-2">
                <QuestionMarkTooltip text={info} direction="top" color="#777" />
              </span>
            </header>

            <div className="space-y-1">
              {statuses.map((item, index) => (
                <StatusStrip
                  key={item._id}
                  {...item}
                  index={index}
                  openPopper={openPopper}
                  updateCategoryStatus={updateCategoryStatus}
                />
              ))}
              {provided.placeholder}
              {!statuses.length && (
                <div className="border border-dashed border-gray-400 rounded text-xs text-gray-400 py-1 px-2">
                  Move statuses here to consider{' '}
                  {id === 'open'
                    ? 'tasks not started'
                    : id === 'active'
                    ? 'tasks started but not done yet'
                    : 'tasks done'}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  )
}

export default StatusCategory
