// import { useContext } from 'react'
import Close from '@material-ui/icons/Close'
import singleWorkspaceStyles from './singleWorkspaceStyles'
import clsx from 'clsx'
// import { SingleWorkspaceContext } from './singleWorkspaceContext'
// import { useEffect } from 'react'
// import { useState } from 'react'
// import { saveBoardView } from 'thunks/embed/action'
// import { useDispatch, useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'

const SaveViewPopup = ({ open, heading, message, onClose, onSave }) => {
  const classes = singleWorkspaceStyles()
  // const {
  //   workspaceState: { groupBy, defaultGroup }
  // } = useContext(SingleWorkspaceContext)
  // const [show, setShow] = useState(false)
  // const dispatch = useDispatch()
  // const { boardId } = useParams()
  // const currentBoard = useSelector((state) => state.userWorkspaces.currentBoard)
  // const meData = useSelector((state) => state.me.data)

  // useEffect(() => {
  //   if (defaultGroup !== groupBy) {
  //     setShow(true)
  //   }
  // }, [groupBy, defaultGroup])

  // const closePopup = () => {
  //   setShow(false)
  // }

  return (
    <div
      className={clsx(
        'fixed bg-white shadow shadow-lg p-4 border-l-4 border-primary-main',
        classes.saveViewPopup,
        open && classes.saveViewPopupVisible
      )}
    >
      <header className="flex items-center justify-between mb-2">
        <h4 className="font-bold mr-8">{heading}</h4>
        <button className="text-gray-500" onClick={onClose}>
          <Close fontSize="small" />
        </button>
      </header>
      <p className="text-sm text-gray-500 mb-4">{message}</p>
      <button
        className="text-primary-main font-medium text-sm"
        onClick={onSave}
      >
        Save
      </button>
    </div>
  )
}

export default SaveViewPopup
