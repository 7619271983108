import { Button, Typography } from '@material-ui/core'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined'
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import CustomTableHeader from 'global/globalComponents/CustomTable/CustomTableHeader'
import format from 'date-fns/format'
import Pill from 'global/globalComponents/Pill/Pill'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllServices } from 'thunks/service/actions'
import axios from 'axios'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  fetchClientStripeChekoutSession,
  fetchClientStripeNonStripeChekoutSession,
  cancelClientService
} from 'thunks/clientService/action'
import { loadStripe } from '@stripe/stripe-js'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { LocalAtmOutlined, TrendingUpRounded } from '@material-ui/icons'
import { LoadingButton } from 'global/globalComponents'
import { Divider } from '@material-ui/core'
import { useBreadcrumbs } from 'customHooks'
import SubscriptionCard from './SubscriptionCard'
import SubscriptionCancelModal from './SubscriptionCancelModal'
import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import clientSubscriptionStyles from './ClientSubscriptionStyles'
import { generateInvoice } from 'thunks/invoices/actions'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const headerItems = [
  {
    title: 'Name',
    className: 'col-span-2'
  },
  {
    title: 'Subscribed',
    className: 'col-span-2'
  },
  {
    title: 'Description',
    className: 'col-span-2'
  },
  {
    title: 'Offerings',
    className: 'col-span-1'
  },
  // {
  //   title: 'Active',
  //   className: 'col-span-1'
  // },
  {
    title: '',
    className: 'col-span-1'
  }
]

const ClientSubscription = () => {
  const allPlansEl = useRef(null)
  const dispatch = useDispatch()
  const classes = clientSubscriptionStyles()

  const meData = useSelector((state) => state.me)
  // const services = useSelector((state) => state.userServices.data)
  // const userAccess = useSelector((state) => state.permission.data?.access)
  // const [activeServicesLoading, setActiveServicesLoading] = useState(true)
  const [allServicesLoading, setAllServicesLoading] = useState(true)
  const [loading, setLoading] = useState(null)
  const [activeServices, setActiveServices] = useState([])
  const [activeServicesIds, setActiveServicesIds] = useState([])
  const [AllServices, setAllServices] = useState([])
  const [activeDuration, setActiveTabDuration] = useState(0)
  const [expandState, setExpandState] = useState({
    active: true,
    cancelled: false,
    available: true
  })

  useBreadcrumbs([
    {
      name: 'Services',
      link: `/services/all`,
      index: 0
    }
  ])

  const handledurationTabSwitch = (ind) => {
    setActiveTabDuration(ind)
  }

  const handleExpandServices = (value) => {
    setExpandState((prevState) => ({
      ...prevState,
      [value]: !expandState[value]
    }))
  }

  const scrollPlansHorizontally = (scrollOffset) => {
    allPlansEl.current.scrollLeft += scrollOffset
  }
  const [subscrbedActiveservices, setSubscribedActiveServices] = useState({
    open: false,
    data: {},
    loading: false
  })

  const allServices = async () => {
    const res = await axios({
      method: 'GET',
      url: 'clientpayment/fetchplan'
    })
    if (res?.status === 200) {
      setAllServices(res.data)
      setAllServicesLoading(false)
    } else {
      setAllServicesLoading(null)
      dispatch(fireErrorToaster('no services Fetched'))
    }
  }

  const fetchActiveServices = async () => {
    const res = await axios({
      method: 'GET',
      url: 'clientpayment/user'
    })
    if (res?.status === 200) {
      setActiveServices(res.data)
      // console.log()
      setActiveServicesIds(
        res.data
          .filter((service) => service.status !== 'canceled')
          .map((i) => i.payment_plan._id)
      )

      // setActiveServicesLoading(false)
    } else {
      // setActiveServicesLoading(null)
      // dispatch(fireErrorToaster('no active services'))
    }
  }

  const selectPlanHandlerCallback = async (res, err) => {
    setLoading(false)

    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      window.location.href = `${window.location.protocol}//${window.location.host}/i/${res._id}/checkout?vtID=${res.viewToken}`
      // if (res.url) {
      //   window.location.href = res.url
      // } else {
      //   const stripe = await stripePromise
      //   await stripe.redirectToCheckout({
      //     sessionId: res.id
      //   })
      // }
    }
  }

  // const selectPlanHandlerCallback = async (res, err) => {
  //   setLoading(false)

  //   if (err) {
  //     dispatch(
  //       fireErrorToaster(
  //         'Some error occured. Please try again or contact support.'
  //       )
  //     )
  //   } else {
  //     // const stripe = await stripePromise
  //     // const result = await stripe.redirectToCheckout({
  //     //   sessionId: res.id
  //     // })
  //     window.open(res.url, '_self')
  //   }
  // }

  const selectNonStripePlanHandlerCallback = async (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster('Unable to select plan'))
    } else {
      window.open(res.path, '_self')
    }
  }

  const selectPlanHandler = async (plan) => {
    const now = new Date()
    const days =
      new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() || 30

    let data = {
      autoRepeat: plan.isRecurring ?? false,
      dueDate: new Date(now.setDate(now.getDate() + days)).toISOString(), //will change it soon,
      autoRepeatFrequency: 30, //will change it soon
      client: {
        clientName: meData.data.name,
        clientEmail: meData.data.email,
        clientId: meData.data._id
      },
      invoiceItems: {
        ...plan,
        name: plan.name,
        quantity: 1,
        rate: plan.amount * 0.01,
        total: plan.amount * 0.01
      },
      totalAmount: plan.amount * 0.01,
      type: 'service',
      currency: plan.currency.toUpperCase()
    }

    setLoading(plan._id)
    dispatch(generateInvoice(data, selectPlanHandlerCallback))
    // let domain = window.location.href
    //   .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    //   .split('/')[0]
    //   .replace('/', '')
    // if (plan.isStripePlan || plan.planId || plan.serviceType === 'stripe') {
    //   const stripeSessionInfo = await fetchClientStripeChekoutSession(
    //     {
    //       user: meData.data,
    //       plan: plan,
    //       url: {
    //         success_url: `https://${meData.data.agencyDomain}/select-service?success=true`,
    //         cancel_url: `https://${meData.data.agencyDomain}/select-service?success=false`
    //       }
    //     },
    //     selectPlanHandlerCallback
    //   )
    // } else {
    //   const stripeSessionInfo = await fetchClientStripeNonStripeChekoutSession(
    //     {
    //       plan: plan
    //     },
    //     selectNonStripePlanHandlerCallback
    //   )
    // }
  }

  const cancelSubscriptionCallback = (res, error) => {
    // Closing the alert modal
    setSubscribedActiveServices({
      open: false,
      data: {},
      loading: false
    })

    if (error) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Request sent!'))
      const activeServicesClone = [...activeServices]
      const index = activeServicesClone.findIndex(
        (service) => service._id === res._id
      )

      if (index !== -1) {
        activeServicesClone[index] = res
      }
      setActiveServices(activeServicesClone)
    }
  }

  const cancelSubscription = (reason, refundCheck) => {
    setSubscribedActiveServices((prev) => ({
      ...prev,
      loading: true
    }))
    const data = {
      cancelRequest: {
        requestStatus: 'raise',
        reason,
        refund: refundCheck
      }
    }

    dispatch(
      cancelClientService(
        subscrbedActiveservices.data._id,
        data,
        cancelSubscriptionCallback
      )
    )
  }

  const handleManageSubscription = (service) => {
    setSubscribedActiveServices((prev) => ({
      ...prev,
      open: true,
      data: service
    }))
  }

  const handleCancelSubscription = (val) => {
    setSubscribedActiveServices((prev) => ({
      ...prev,
      open: val,
      data: {}
    }))
  }

  useEffect(() => {
    allServices()
    fetchActiveServices()
  }, [])

  // console.log(subscrbedActiveservices.data, 'ACTICE SERVICE')

  return (
    <OuterContentWrapper>
      <div>
        <div className="flex items-center justify-between mb-4">
          <SectionHeading>Manage Services</SectionHeading>
        </div>

        <div className="col-span-6 my-6 hidden">
          <div className="mb-4 flex justify-between">
            <p>{meData.data?.team?.profile?.brand?.brandName} Plans</p>
            <div className="flex items-center gap-4">
              <div className={classes.durationTabContainer}>
                {['Stripe', 'Non-Stripe'].map((item, index) => (
                  <p
                    className={clsx({ active: activeDuration === index })}
                    onClick={() => handledurationTabSwitch(index)}
                  >
                    {item}
                  </p>
                ))}
              </div>
              <div className="flex items-center gap-2">
                <span
                  role="button"
                  className="rounded w-6 h-6 cursor-pointer flex items-center justify-center border border-gray-200"
                  onClick={() => scrollPlansHorizontally(-200)}
                >
                  <ChevronLeftRoundedIcon />
                </span>
                <span
                  role="button"
                  className="rounded w-6 h-6 cursor-pointer flex items-center justify-center border border-gray-200"
                  onClick={() => scrollPlansHorizontally(200)}
                >
                  <ChevronRightRoundedIcon />
                </span>
              </div>
            </div>
          </div>
          <div
            ref={allPlansEl}
            style={{ scrollBehavior: 'smooth' }}
            className="flex gap-4 pb-4 items-start overflow-scroll"
          >
            {
              // clientVenuPlans.data
              //   .filter(
              //     (i) => i.interval === (activeDuration === 0 ? 'month' : 'year')
              //   )
              AllServices?.map((plan, index) =>
                plan.isStripePlan && activeDuration == 0 ? (
                  <div
                    style={{ minWidth: '206px' }}
                    key={plan?.id}
                    className="rounded-xl p-6 w-52 flex items-center flex-col border border-gray-300 shadow-md"
                  >
                    <p className="text-lg font-medium">
                      {plan?.name ?? 'Static'}
                    </p>
                    <p className="text-sm text-custom-gray-main">
                      {plan?.description ?? 'Static text'}
                    </p>
                    <p className="font-bold text-2xl my-4">
                      ${parseFloat(plan?.amount / 100)?.toFixed(2)}
                    </p>
                    <Button
                      // variant={plan.active ? 'contained' : 'outlined'}
                      variant="outlined"
                      onClick={() => selectPlanHandler(plan)}
                    >
                      {/* {plan.active ? 'Selected' : 'Change Plan'} */}
                      Select Plan
                    </Button>
                  </div>
                ) : !plan.isStripePlan && activeDuration == 1 ? (
                  <div
                    style={{ minWidth: '206px' }}
                    key={plan?.id}
                    className="rounded-xl p-6 w-52 flex items-center flex-col border border-gray-300 shadow-md"
                  >
                    <p className="text-lg font-medium">
                      {plan?.name ?? 'Static'}
                    </p>
                    <p className="text-sm text-custom-gray-main">
                      {plan?.description ?? 'Static text'}
                    </p>
                    <p className="font-bold text-2xl my-4">
                      ${parseFloat(plan?.amount / 100)?.toFixed(2)}
                    </p>
                    <Button
                      // variant={plan.active ? 'contained' : 'outlined'}
                      variant="outlined"
                      onClick={() => selectPlanHandler(plan)}
                    >
                      {/* {plan.active ? 'Selected' : 'Change Plan'} */}
                      Select Plan
                    </Button>
                  </div>
                ) : null
              )
            }
          </div>
        </div>

        {/* <div className="flex flex-row">
          <Typography
            className={classes.activeSection}
            onClick={() => handleExpandServices('active')}
          >
            <ArrowRightIcon
              className={clsx(classes.icons, {
                expanded: expandState.active
              })}
            />
            Active Services
          </Typography>
          <QuestionMarkTooltip
            text="Services or Products which you have subscribed to"
            direction="right"
            toolTipstyles=" pl-1 pt-8"
          />
        </div> */}

        {/* {expandState.active && (
          <div className={`mt-6 ${classes.cardsWrapper}`}>
            {activeServicesLoading ? (
              <>
                {[...Array(3)].map(() => (
                  <Skeleton
                    variant="rect"
                    height={180}
                    animation="wave"
                    className="rounded-lg"
                  />
                ))}
              </>
            ) : (
              <>
                {activeServices.length ? (
                  activeServices
                    .filter((service) =>
                      ['paid', 'unpaid', 'open'].includes(service.status)
                    )
                    .map((service) => (
                      <SubscriptionCard
                        key={service._id}
                        service={service}
                        isActive={true}
                        handleCardBtnClick={handleManageSubscription}
                        withCancel={true}
                        status={service.status}
                        cancelRequested={service.cancelRequest?.requestStatus}
                      />
                    ))
                ) : (
                  <div className="col-span-7">
                    <p className="text-gray-500 font-mediume text-sm">
                      No Available Service
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        )} */}

        {/* <div className="my-6">
          <Divider orientation="horizontal" />
        </div>

        <div className="mb-2">
          <div className="flex flex-row">
            <Typography
              className={classes.activeSection}
              onClick={() => handleExpandServices('cancelled')}
            >
              <ArrowRightIcon
                className={clsx(classes.icons, {
                  expanded: expandState.cancelled
                })}
              />
              Cancelled Services
            </Typography>
            <QuestionMarkTooltip
              text="These projects are internal to the Agency & cannot be shared with any Client"
              direction="right"
              toolTipstyles=" pl-1 pt-8"
            />
          </div>

          {expandState.cancelled && (
            <div className={`mt-6 ${classes.cardsWrapper}`}>
              {activeServices.length ? (
                activeServices
                  .filter((service) => service.status === 'canceled')
                  .map((service) => (
                    <SubscriptionCard
                      key={service._id}
                      service={service}
                      isActive={true}
                      status={service.status === 'canceled'}
                    />
                  ))
              ) : (
                <div className="col-span-7">
                  <p className="text-gray-500 font-mediume text-sm">
                    No Cancelled Services
                  </p>
                </div>
              )}
            </div>
          )}
        </div> */}

        {/* <div className="my-6">
          <Divider orientation="horizontal" />
        </div> */}

        <div className="mb-2">
          <div className="flex items-center">
            <h4
              onClick={() => handleExpandServices('available')}
              className="font-semibold"
            >
              <button className="mr-1">
                <ArrowRightIcon
                  className={clsx('rounded', classes.icons, {
                    expanded: expandState.available
                  })}
                />
              </button>
              Available {meData.data?.team?.profile?.brand?.brandName} Services
            </h4>
            <QuestionMarkTooltip
              text="Services or Products which are available for you"
              direction="right"
              toolTipstyles="ml-2"
            />
          </div>

          {expandState.available && (
            <div className={`mt-6 ${classes.cardsWrapper}`}>
              {allServicesLoading ? (
                <>
                  {[...Array(3)].map(() => (
                    <div className="mt-6 grid">
                      <Skeleton
                        variant="rect"
                        height={180}
                        animation="wave"
                        className="rounded-lg"
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {AllServices?.length > 0 ? (
                    AllServices.map((service) => (
                      <SubscriptionCard
                        key={service._id}
                        service={service}
                        isActive={activeServicesIds.includes(service._id)}
                        handleCardBtnClick={selectPlanHandler}
                        loading={loading}
                      />
                    ))
                  ) : (
                    <div className="col-span-7">
                      <p className="text-gray-500 font-mediume text-sm">
                        No Available Service
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {/* <AlertModal
        warningText="This will send a Service Cancellation request to the Agency. Do you want to Proceed?"
        open={subscrbedActiveservices.open}
        handleDialog={handleCancelSubscription}
        handleDeleteAction={cancelSubscription}
        loading={subscrbedActiveservices.loading}
      /> */}
      {subscrbedActiveservices.open && (
        <SubscriptionCancelModal
          open={subscrbedActiveservices.open}
          onClose={handleCancelSubscription}
          handleUnsubscribe={cancelSubscription}
          loading={subscrbedActiveservices.loading}
          serviceStatus={subscrbedActiveservices.data.status}
        />
      )}
    </OuterContentWrapper>
  )
}

export default ClientSubscription
