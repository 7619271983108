// import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
// import TaskCollaborators from './TaskCollaborators'
import taskSidePanelStyles from '../Workspace/Panel/taskSidePanelStyles'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalenderIcon } from 'static/svg/calendar.svg'
import { FieldLabel } from './CreateTaskPanel'
import clsx from 'clsx'
// import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
import { setHours } from 'date-fns'
// import { ReactComponent as TagsIcon } from 'static/svg/tags.svg'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import Collaborator from 'components/Workspace/Panel/Collaborator'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'

const TaskBody = ({
  taskState,
  triggerPopover,
  taskPermission,
  handleDateChange,
  handleRemoveTeammate,
  project
}) => {
  const classes = taskSidePanelStyles()

  return (
    <div className={clsx(classes.taskBodyContainer, 'px-6 mb-4')}>
      <RowContainer>
        <div>
          <FieldLabel>Collaborators</FieldLabel>
        </div>
        <div>
          <TaskCollaborators
            workspace={project}
            taskState={taskState}
            taskPermission={taskPermission}
            handleRemoveTeammate={handleRemoveTeammate}
            triggerPopover={triggerPopover}
          />
        </div>
      </RowContainer>
      <RowContainer>
        <div>
          <FieldLabel>Start Date</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div className={classes.pseudoDropDownContainer}>
            <DatePicker
              selected={
                taskState.startDate
                  ? new Date(taskState.startDate)
                  : setHours(new Date(), new Date().getHours())
              }
              onChange={(date) => {
                handleDateChange(date, 'startDate')
              }}
              customInput={
                taskState.startDate ? (
                  <input style={{ width: '180px', padding: '4px 0' }} />
                ) : (
                  <CalenderIcon className="text-base text-gray-600" />
                )
              }
              showTimeSelect
              dateFormat="MMM d, yyyy h:mm aa"
              showDisabledMonthNavigation
            />
          </div>
        </div>
      </RowContainer>
      <RowContainer>
        <div>
          <FieldLabel>Due Date</FieldLabel>
        </div>
        <div className="flex gap-1">
          <div className={classes.pseudoDropDownContainer}>
            <div>
              <DatePicker
                selected={
                  taskState.dueDate
                    ? new Date(taskState.dueDate)
                    : setHours(new Date(), new Date().getHours())
                }
                onChange={(date) => {
                  handleDateChange(date, 'dueDate')
                }}
                showTimeSelect
                customInput={
                  taskState.dueDate ? (
                    <input style={{ width: '180px', padding: '4px 0' }} />
                  ) : (
                    <CalenderIcon className="text-base text-gray-600" />
                  )
                }
                minDate={
                  taskState.startDate
                    ? new Date(taskState.startDate)
                    : new Date()
                }
                placeholderText="Select Date"
                dateFormat="MMM d, yyyy h:mm aa"
              />
            </div>
          </div>
        </div>
      </RowContainer>
      {/* {elmFor === 'task' && (
        <RowContainer>
          <div>
            <FieldLabel>Tags</FieldLabel>
          </div>
          <div className="flex space-x-2 items-center">
            {Boolean(assignedTags.length) && (
              <AssignedTags
                assignedTag={assignedTags}
                taskId={taskState?._id}
                elmFor="task"
                setTagsPopoverData={setTagsPopoverData}
                tagIcon={true}
                className="flex-wrap"
              />
            )}
            <button
              // className={classes.outlinedIcon}
              onClick={(e) => {
                setTagsPopoverData({ open: true, anchorEl: e.currentTarget })
              }}
              className="w-6 h-6 border border-dashed flex justify-center items-center text-base rounded-full text-gray-400"
            >
              <TagsIcon />
            </button>
          </div>
        </RowContainer>
      )} */}
    </div>
  )
}

export const RowContainer = (props) => {
  const { children, ...restProps } = props
  return (
    <div {...restProps} className="flex items-center space-x-4 leading-3">
      {children}
    </div>
  )
}

const TaskCollaborators = ({
  workspace,
  taskState,
  taskPermission,
  handleRemoveTeammate,
  triggerPopover
}) => {
  const dispatch = useDispatch()

  const handleAddCollaborator = (e) => {
    if (workspace.isComplete) {
      dispatch(
        fireErrorToaster(
          `${workspace.name} is already complete and you can not add new teammates to any of its task.`
        )
      )
      return
    }

    triggerPopover(e, 'collab')
  }

  return (
    <div className="flex space-x-2 items-center ">
      {taskState.collaborators.map((teammate) => (
        <Collaborator
          key={teammate._id}
          teammate={teammate}
          handleRemove={handleRemoveTeammate}
          removeBtn={true}
        />
      ))}
      <button
        type="button"
        onClick={handleAddCollaborator}
        className="flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none"
      >
        <AddIcon style={{ fontSize: '16px' }} />
      </button>
    </div>
  )
}

export default TaskBody
