import React from 'react'
import {
  InputAdornment,
  List,
  ListItem,
  Popover,
  TextField
} from '@material-ui/core'
import { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
// import { useHistory } from 'react-router'
// import { getUserPath } from 'utils/userRoles'
// import headerStyles from '../headerStyles'
import { ReactComponent as DotIcon } from 'static/svg/3dots-h.svg'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { updateEmbed } from 'thunks/embed/action'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { ReactComponent as Pen } from 'static/svg/pen.svg'
import { ReactComponent as PaperClipIcon } from 'static/svg/paperclip.svg'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { postTaskLog } from 'thunks/logs/action'

const BoardDropdown = ({
  handleClose,
  anchorEl,
  menuItems,
  workspaceViewPermission,
  activeBoardId = '',
  handleDeleteBoardAlert,
  handlePinBoardAlert,
  currentWorkspace
}) => {
  const dispatch = useDispatch()

  const [updatePopup, setUpdatePopup] = useState({
    anchorEl: null,
    data: null
  })

  const [updatedName, setUpdatedName] = useState({
    open: false,
    name: '',
    data: {}
  })

  useEffect(() => {
    setUpdatedName({
      open: false,
      name: '',
      data: {}
    })
  }, [handleClose])

  const handleRename = (data) => {
    setUpdatedName((prev) => ({
      ...prev,
      open: true,
      data,
      name: data.label
    }))
    setUpdatePopup((prev) => ({ ...prev, anchorEl: null }))
  }

  const updateNameCallback = (res, err) => {
    dispatch(
      createAccountLogs({
        description: `updated a ${res.type} <strong>${res.name}</strong> in the project <strong>${currentWorkspace.name}</strong>`,
        activityType: 'log',
        category: 'workspaceView',
        workspaceView: res._id
      })
    )
    dispatch(
      postTaskLog({
        description: `updated embed ${res.name}`,
        activityType: 'log',
        category: 'workspaceView'
      })
    )
    setUpdatedName({ open: false, name: '', data: {} })
  }

  const updateEmbedName = (e) => {
    if (e.key === 'Enter') {
      const data = {
        name: updatedName.name
      }
      if (!data.name) {
        return dispatch(fireErrorToaster('Name is required'))
      }
      dispatch(updateEmbed(updatedName.data._id, data, updateNameCallback))
    }
  }

  const openUpdatePopup = (e, data) => {
    e.stopPropagation()
    setUpdatePopup({ anchorEl: e.currentTarget, data })
  }
  const closeUpdatePopup = () => {
    setUpdatePopup((prev) => ({ ...prev, anchorEl: null }))
    handleClose()
  }

  const handleDelete = () => {
    handleDeleteBoardAlert(updatePopup.data)
    closeUpdatePopup()
  }

  const handlePinBoard = () => {
    handlePinBoardAlert(updatePopup.data)
    closeUpdatePopup()
  }

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List style={{ minWidth: '140px', maxWidth: '250px' }}>
          {menuItems.map((item, index) => (
            <ListItem
              key={item._id}
              button
              style={activeBoardId === item._id ? { background: '#ddd' } : {}}
              onClick={() => item.method(item._id)}
            >
              {updatedName.open && updatedName.data?._id === item?._id ? (
                <TextField
                  // className="py-1 pl-2 border text-sm"
                  // style={{ border: '1px solid black' }}
                  value={updatedName.name}
                  autoFocus
                  onChange={(e) =>
                    setUpdatedName((prev) => ({
                      ...prev,
                      name: e.target.value
                    }))
                  }
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={updateEmbedName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="text-xs text-gray-500">
                          Press Enter
                        </span>
                      </InputAdornment>
                    )
                  }}
                  style={{ width: 170 }}
                />
              ) : (
                <div
                  className={clsx('w-full flex justify-between items-center')}
                >
                  <p className="text-sm">
                    <span className="capitalize">{item.label}</span>
                    {item.pinView && (
                      <span className="ml-1 text-xs">(pinned)</span>
                    )}
                  </p>

                  {workspaceViewPermission?.delete && (
                    <button
                      onClick={(e) => openUpdatePopup(e, item)}
                      className="ml-2 hover:bg p-1"
                    >
                      <DotIcon />
                    </button>
                  )}
                </div>
              )}
            </ListItem>
          ))}
        </List>
      </Popover>
      <Popover
        open={Boolean(updatePopup.anchorEl)}
        onClose={closeUpdatePopup}
        anchorEl={updatePopup.anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <List disablePadding>
          {!updatePopup?.data?.pinView && (
            <>
              <ListItem onClick={handleDelete} button>
                <TrashIcon className="w-4 h-4 mr-4" />
                <p style={{ fontSize: '14px' }}>Delete</p>
              </ListItem>
              <ListItem onClick={handlePinBoard} button>
                <PaperClipIcon className="w-4 h-4 mr-4" />
                <p style={{ fontSize: '14px' }}>Pin board</p>
              </ListItem>
            </>
          )}
          <ListItem onClick={() => handleRename(updatePopup.data)} button>
            <Pen className="w-4 h-4 mr-4" />
            <p style={{ fontSize: '14px' }}>Rename</p>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default BoardDropdown
