import React, { useEffect, useState } from 'react'
import { Popover } from '@material-ui/core'
import CellWrapper from './CellWrapper'

const NumbersCell = ({
  value,
  row: { index, values, original },
  column: { id },
  updateDynamicColData,
  taskPermission
}) => {
  const [anchorEl, setAnchorEl] = useState()
  const [number, setNumber] = useState(value ?? '')

  useEffect(() => {
    if (value && value !== number) {
      setNumber(value)
    }
  }, [value])

  const handleOpenPopup = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleNumberChange = (e) => {
    setNumber(e.target.value)
  }

  const handleClose = () => {
    setNumber('')
    setAnchorEl(null)
  }

  const handleUpdateData = (e) => {
    e.preventDefault()

    if (value === number) return
    updateDynamicColData({
      rowIndex: index,
      columnId: id,
      value: number,
      allRowValues: values,
      taskId: original.taskId
    })
    handleClose()
  }

  return (
    <>
      <CellWrapper
        className="px-4 px-2"
        styles={{ minWidth: 180 }}
        onClick={taskPermission.update ? handleOpenPopup : undefined}
      >
        <div className="text-sm">{value}</div>
      </CellWrapper>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className="w-60 bg-white rounded">
          <form onSubmit={handleUpdateData} noValidate>
            <input
              type="number"
              value={number}
              onChange={handleNumberChange}
              className="w-full border resize-none rounded p-2 text-sm"
              autoFocus
              onBlur={handleUpdateData}
            />
          </form>
        </div>
      </Popover>
    </>
  )
}

export default NumbersCell
