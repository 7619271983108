import { createContext } from 'react'

export const SingleWorkspaceContext = createContext(null)

export const WORKSPACE = {
  UPDATE_SORT_BY: 'WORKSPACE_UPDATE_SORT_BY',
  UPDATE_GROUP_BY: 'WORKSPACE_UPDATE_GROUP_BY',
  UPDATE_DEFAULT_GROUP_BY: 'WORKSPACE_UPDATE_DEFAULT_GROUP_BY',
  CHANGE_VIEW: 'WORKSPACE_CHANGE_VIEW',
  SET_PERMISSION: 'WORKSPACE_SET_PERMISSION',
  PAGE_LOADING: 'WORKSPACE_PAGE_LOADING',
  // FETCHED_STATUS_POSTS: 'WORKSPACE_FETCHED_STATUS_POSTS',
  // UPDATE_STATUS_POSTS: 'WORKSPACE_UPDATE_STATUS_POSTS',
  TOGGLE_SHOW_APPROVAL_TASKS: 'WORKSPACE_TOGGLE_SHOW_APPROVAL_TASKS',
  FILTERS_FETCHED: 'WORKSPACE_FILTERS_FETCHED',
  UPDATE_FILTERS: 'WORKSPACE_UPDATE_FILTERS'
}

export const initialState = {
  permission: {
    fetched: false,
    workspacePermission: {},
    workspaceViewPermission: {},
    taskPermission: {},
    subtaskPermission: {}
  },
  defaultGroup: 'status',
  groupBy: 'status',
  sortBy: { value: '', order: 1 },
  defaultView: 'card',
  pageLoading: true,
  // statusPosts: {
  //   loading: true,
  //   fetched: false,
  //   data: []
  // },
  showApprovalTasks: false,
  filters: {
    loading: true,
    fetched: false,
    data: {}
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case WORKSPACE.PAGE_LOADING:
      return { ...state, pageLoading: action.payload }

    case WORKSPACE.SET_PERMISSION:
      return { ...state, permission: { ...action.payload, fetched: true } }

    case WORKSPACE.UPDATE_SORT_BY:
      return { ...state, sortBy: action.payload }

    case WORKSPACE.UPDATE_GROUP_BY:
      return { ...state, groupBy: action.payload }

    case WORKSPACE.UPDATE_DEFAULT_GROUP_BY:
      return { ...state, defaultGroup: action.payload }

    case WORKSPACE.CHANGE_VIEW:
      return { ...state, defaultView: action.payload }

    // case WORKSPACE.FETCHED_STATUS_POSTS:
    //   return {
    //     ...state,
    //     statusPosts: { loading: false, fetched: true, data: action.payload }
    //   }

    // case WORKSPACE.UPDATE_STATUS_POSTS:
    //   return {
    //     ...state,
    //     statusPosts: { ...state.statusPosts, data: action.payload }
    //   }
    case WORKSPACE.TOGGLE_SHOW_APPROVAL_TASKS:
      return { ...state, showApprovalTasks: !state.showApprovalTasks }

    case WORKSPACE.FILTERS_FETCHED:
      return {
        ...state,
        filters: { loading: false, data: action.payload, fetched: true }
      }

    case WORKSPACE.UPDATE_FILTERS:
      return { ...state, filters: { ...state.filters, data: action.payload } }

    default:
      return state
  }
}
