import React, { useState } from 'react'
import { Popover, Checkbox } from '@material-ui/core'
import {
  CheckBoxField,
  CustomPopper,
  LoadingButton
} from 'global/globalComponents'

const SelectColumnsPopup = ({
  anchorEl,
  handleClose,
  columns,
  toggleColumnsVisibility,
  hiddenCols,
  handleSaveColumns,
  columnSaveState
}) => {
  const [loading, setLoading] = useState(false)
  const handleSave = () => {
    setLoading(true)
    handleSaveColumns(() => {
      setLoading(false)
    })
  }
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <div className="py-4 rounded-xl" style={{ minWidth: 200 }}>
        <div className="px-4 ">
          {columns.map((item) => (
            <div key={item.label}>
              <CheckBoxField
                name="toggle column"
                onChange={(e) =>
                  toggleColumnsVisibility(item.accessor, e.target.checked)
                }
                checked={!hiddenCols.includes(item.accessor)}
                label={item.label}
              />
            </div>
          ))}
        </div>
        <hr className="my-2" />
        <div className=" px-4 text-right">
          <LoadingButton
            size="small"
            loading={loading}
            disabled={!columnSaveState}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </Popover>
  )
}

export default SelectColumnsPopup
