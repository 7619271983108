import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import UniversalToast from 'global/globalComponents/UniversalToast/UniversalToast'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkLogin,
  chatMessengerInit,
  chatMessengerDestroy,
  userRoles
} from 'utils'
import { fetchUser } from 'thunks/user/actions'
import { fetchMeData } from '../../thunks/me/actions'
// import { updateUserAcces } from 'thunks/generlAppState/actions'
import { FilePreview } from 'global/globalComponents'
import 'config/axiosConfig'
import FileUploading from 'components/FilesAssets/FileUploading'
import { AccountNotApproved } from 'components'
import FloatingTimer from 'components/Timer/FloatingTimer'

const App = (props) => {
  // const dispatch = useDispatch()
  const history = useHistory()
  const { loggedIn } = useSelector((state) => state.login)
  const userPath = useSelector((state) => state.user.data)
  const { data: meData, fetched: meDataFetched } = useSelector(
    (state) => state.me
  )
  const timerData = useSelector((state) => state.timer.data)
  // const { timer } = useSelector((state) => state.dashboard)
  // const { timerFloat } = useSelector((state) => state.generalAppState)

  // useEffect(() => {
  //   if (checkLogin()) {
  //     dispatch(fetchUser())
  //     if (!meDataFetched) {
  //       dispatch(fetchMeData())
  //     }
  //   }
  // }, [])

  // useEffect(() => {
  //   dispatch(updateUserAcces(meData.role))
  // }, [meData])

  useEffect(() => {
    const loginStatus = checkLogin()
    if (loginStatus) {
      if (props.location.pathname === '/') {
        if (Object.keys(userPath).includes('path')) {
          history.push(userPath.path)
        }
      }
      // history.push(props.location.path)
    } else {
      if (props.location.pathname === '/') {
        history.push('/login')
      }
    }
  }, [loggedIn, userPath])

  useEffect(() => {
    if (meDataFetched) {
      if (
        [
          userRoles.USER_AGENCY,
          userRoles.AGENCY_MANAGER,
          userRoles.AGENCY_ADMIN
        ].includes(meData.role)
      ) {
        chatMessengerInit(meData)
      } else {
        chatMessengerDestroy()
      }
    }
  }, [meDataFetched, meData.role])

  return (
    <Box>
      {meData?.status === 'need_approval' ? (
        <AccountNotApproved open={meData?.status === 'need_approval'} />
      ) : (
        <>
          {props.children}
          <UniversalToast />
          <FilePreview />
          <FileUploading />
          {timerData.float && timerData.activeTimer && <FloatingTimer />}
        </>
      )}
    </Box>
  )
}

export default App
