import { useState, useEffect } from 'react'
import { Button, TextField, List, ListItem, Popover } from '@material-ui/core'
import {
  CustomModal,
  CustomModalHeader,
  CustomModalBody,
  CustomModalFooter
} from 'global/globalComponents/CustomModal'
import PriorityDropDown, {
  priorityLabels
} from 'global/globalComponents/PriorityDropdown/PriorityDropdown'
import { LoadingButton, FormLabel, CustomFlag } from 'global/globalComponents'
import {
  raiseNewRequest,
  updateRaisedRequest
} from 'thunks/clientRaiseRequest/actions'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import OptionsButton, {
  statusColors
} from 'components/OptionsButton/OptionsButton'
import { errorMessages } from 'utils'
import ClientDashboardStyles from './ClientyDashboardStyles'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const RaiseRequestModal = ({
  open,
  handleClose,
  data,
  canUpdate,
  elmFor = 'client'
}) => {
  const meData = useSelector((state) => state.me.data)
  const userClients = useSelector((state) => state.userClients.data)
  const [anchorEl, setAnchorEl] = useState('')
  const [statusAnchor, setStatusAnchor] = useState('')
  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState('')
  const [clientOptions, setClientOptions] = useState([])
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    attachments: [],
    priority: {},
    status: 'pending'
  })

  const dispatch = useDispatch()
  const classes = ClientDashboardStyles()

  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        priority: data.priority
          ? priorityLabels.find((item) => item.order === data.priority)
          : {}
      })
    }
  }, [data])

  useEffect(() => {
    if (elmFor === 'agency') {
      setClientOptions(
        userClients
          .filter((user) => user.isActive)
          .map((user) => ({ ...user, label: user.name, value: user._id }))
      )
    } else {
      setClientOptions([])
    }
  }, [userClients, elmFor])

  // useEffect(() => {
  //   if (data) {
  //     setFormData({
  //       title: data.title,
  //       description: data.description,
  //       attachments: data.attachments,
  //       priority:
  //         priorityLabels.find((item) => item.order === data.priority) || {}
  //     })
  //   }
  // }, [data])

  const handlePopperClose = () => {
    setAnchorEl('')
  }

  const handlePriorityLabel = (item) => {
    handlePopperClose()
    setFormData((prev) => ({ ...prev, priority: item }))
  }

  const handleRaiseRequest = (e) => {
    e.preventDefault()

    if (!canUpdate) {
      dispatch(
        fireErrorToaster('You do not have permission to perform this action')
      )
      return
    }

    if (!formData.title.trim() || !formData.description.trim()) {
      dispatch(fireErrorToaster('Please fill all the required fields!'))
      return
    }

    let newData = { ...formData, priority: formData.priority.order }

    if (elmFor === 'agency') {
      if (!client.value)
        return dispatch(fireErrorToaster('Please select the client!'))
      newData.client = client.value
      newData.agencyRepresentative = client.agencyRepresentative
        ? client.agencyRepresentative
        : null
    } else {
      if (meData.agencyRepresentative) {
        newData.assignAgency =
          meData.agencyRepresentative._id ?? meData.agencyRepresentative
      }
    }

    setLoading(true)
    if (data) {
      dispatch(updateRaisedRequest(data._id, newData, raiseRequestCallback))
    } else {
      dispatch(
        raiseNewRequest(
          { ...newData, status: 'pending' },
          elmFor,
          raiseRequestCallback
        )
      )
    }
  }

  const raiseRequestCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Request Raised Successfully'))
      setFormData({
        title: '',
        description: '',
        attachments: [],
        priority: {},
        status: 'pending'
      })
      setClient({})
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    setFormData({
      title: '',
      description: '',
      attachments: [],
      priority: {},
      status: 'pending'
    })
    setClient('')
    handleClose()
  }

  const fileToDataURL = (file) => {
    const reader = new FileReader()
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        resolve(event.target.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleFileUpload = (e) => {
    if (formData.attachments.length + e.target.files.length > 3) {
      dispatch(fireErrorToaster('You can attach maximum 3 files'))
      return
    }
    const files = e.target.files

    const isImg = Object.values(files).every((item) =>
      item.type.startsWith('image')
    )

    if (isImg && files.length) {
      Promise.all([...files].map(fileToDataURL)).then((res) =>
        setFormData((prev) => ({
          ...prev,
          attachments: [...prev.attachments, ...res]
        }))
      )
    }
  }

  const handleRemoveAttachment = (index) => {
    if (!canUpdate) {
      dispatch(
        fireErrorToaster('You do not have permission to perform this action')
      )
      return
    }

    const filteredList = formData.attachments.filter(
      (item, idx) => index !== idx
    )
    setFormData((prev) => ({ ...prev, attachments: filteredList }))
  }

  const handleInputChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleChooseFile = (e) => {
    if (!canUpdate) {
      e.preventDefault()
      dispatch(
        fireErrorToaster('You do not have permission to perform this action')
      )
    }
  }

  const closeStatusPopup = () => {
    setStatusAnchor(null)
  }

  const handleStatusChange = (status) => {
    setFormData((prev) => ({ ...prev, status }))
    closeStatusPopup()
  }

  const handleOptionsBtnClick = (e) => {
    if (canUpdate) {
      setStatusAnchor(e.currentTarget)
    } else {
      dispatch(fireErrorToaster(errorMessages.NOT_AUTHORIZED))
    }
  }

  return (
    <div>
      <CustomModal open={open} handleClose={handleCloseModal} size="large">
        <CustomModalHeader
          heading="Raise a New Request"
          handleClose={handleCloseModal}
        />

        <CustomModalBody>
          <div>
            {elmFor === 'agency' && (
              <div className="mb-4">
                <FormLabel htmlFor="client" required>
                  Client
                </FormLabel>
                <Select
                  styles={reactSelectCustomStyles}
                  options={clientOptions}
                  value={client}
                  placeholder="Select Client"
                  onChange={(e) => setClient(e)}
                />
              </div>
            )}
            <div className="mb-4">
              <FormLabel htmlFor="name" required>
                Title
              </FormLabel>

              <TextField
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="hover:border-blue-500 w-full border border-custome-gray-main p-2 rounded-md outline-none"
                placeholder="Request title"
                style={{ marginTop: 2 }}
                disabled={!canUpdate}
              />
            </div>

            <div className="mb-4">
              <FormLabel htmlFor="name" required>
                Description
              </FormLabel>
              <div>
                <TextField
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  multiline
                  className="w-full"
                  placeholder="Write"
                  rows={3}
                  style={{ marginTop: 2 }}
                  disabled={!canUpdate}
                />
              </div>
            </div>

            <div className="flex gap-8 items-start">
              <div className="w-1/2">
                <FormLabel>Attachment</FormLabel>
                <div className="flex flex-col gap-4 mt-1">
                  <Button
                    className="self-start"
                    component="label"
                    onClick={handleChooseFile}
                  >
                    Choose File
                    <input
                      type="file"
                      hidden
                      onChange={handleFileUpload}
                      multiple
                      accept="image/*"
                    />
                  </Button>

                  {formData.attachments.length > 0 ? (
                    <div className="flex gap-2">
                      {formData.attachments.map((item, index) => (
                        <Attachment
                          key={index}
                          index={index}
                          classes={classes}
                          item={item}
                          handleRemoveAttachment={handleRemoveAttachment}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="w-1/2">
                <FormLabel>Set Priority</FormLabel>

                <div className="sm:col-span-2 flex gap-1 text-sm">
                  <div role="button" onClick={(e) => setAnchorEl(e.target)}>
                    {formData.priority.order &&
                    formData.priority.order !== 5 ? (
                      <div className="text-center w-16">
                        <CustomFlag priority={formData.priority.order} />
                      </div>
                    ) : (
                      <span className="px-4 py-2 border border-gray-100 rounded mt-1 inline-block text-xs font-medium bg-gray-100 hover:bg-gray-200">
                        Select
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <FormLabel>Status</FormLabel>
                <OptionsButton
                  onClick={handleOptionsBtnClick}
                  textValue="Open"
                  bgColor={statusColors[formData.status]}
                >
                  {formData.status}
                </OptionsButton>
              </div>
            </div>
          </div>

          <PriorityDropDown
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopperClose}
            handlePriorityLabel={handlePriorityLabel}
            currentPriority={formData.priority.order}
          />

          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={Boolean(statusAnchor)}
            onClose={closeStatusPopup}
            anchorEl={statusAnchor}
          >
            <List dense>
              {['pending', 'resolved', 'closed'].map((item) => (
                <ListItem
                  onClick={() => handleStatusChange(item)}
                  button
                  key={item}
                  className="capitalize text-sm font-medium"
                  disabled={formData.status === item}
                  selected={formData.status === item}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </Popover>
        </CustomModalBody>

        <CustomModalFooter>
          <Button type="button" variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            onClick={handleRaiseRequest}
            loading={loading}
          >
            {data ? 'Update' : 'Submit'}
          </LoadingButton>
        </CustomModalFooter>
      </CustomModal>
    </div>
  )
}

const Attachment = ({ classes, item, index, handleRemoveAttachment }) => {
  const handleRemove = () => {
    handleRemoveAttachment(index)
  }

  return (
    <div className={`${classes.attachment} relative`}>
      <button
        type="button"
        className={`absolute right-0 top-0 text-red-600 ${classes.attachmentRemoveBtn}`}
        onClick={handleRemove}
      >
        <HighlightOffIcon size="small" />
      </button>
      <img
        src={item}
        alt="attachment"
        className="outline-none rounded object-cover w-full h-full"
      />
    </div>
  )
}

export default RaiseRequestModal
