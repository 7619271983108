import React, { useState, useEffect, useMemo } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import widgetStyles from '../widgetStyles'
import {
  TableDataLoading,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useTable, useSortBy } from 'react-table'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import Pill from 'global/globalComponents/Pill/Pill'
import ColoredAvatars, {
  ColoredAvatarGroup
} from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import {
  archiveWorkspace,
  createWorkspaceCopy,
  deleteWorkspace,
  UpdateWorkspceCompleteStatus
} from 'thunks/workspace/actions'
import {
  fireErrorToaster,
  fireProcessToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { fetchEmbed } from 'thunks/embed/action'
import { useHistory } from 'react-router-dom'
import userRoles, { getUserPath } from 'utils/userRoles'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as CheckCircleIcon } from 'static/svg/check-circle.svg'
import { ReactComponent as ArchiveIcon } from 'static/svg/archive.svg'
import { ReactComponent as CopyIcon } from 'static/svg/copy.svg'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import { Pagination, PopupMenu } from 'global/globalComponents'
import SelectColumnsPopup from 'components/UserDashboard/Workspaces/ProjectsTableView/SelectColumnsPopup'
import AssignedTags from 'components/UserDashboard/Workspaces/AssignedTags'
import currency from 'static/Currency/Currency'
import Completion from 'components/UserDashboard/Widget/ProjectAnalyticsTable/Completion'
import InfoPopper from 'components/UserDashboard/Widget/ProjectAnalyticsTable/InfoPopper'
import {
  createProjectWidgetView,
  fetchProjectWidgetView,
  updateProjectWidgetView
} from 'thunks/views/actions'

const NewProjectsAnalyticsTable = ({
  widgetId,
  editMode,
  handleRemoveWidget
}) => {
  const dispatch = useDispatch()
  const classes = widgetStyles()
  const history = useHistory()
  const meData = useSelector(state => state.me.data)
  const { loading, fetchedFirstTime, data: workspaceData } = useSelector(
    state => state.userWorkspaces
  )
  const projectWidgetView = useSelector(state => state.views.data.projectWidget)
  const [projectsData, setProjectsData] = useState([])
  const [tabsData, setTabsData] = useState([
    { label: 'All', value: 0 },
    { label: 'Active', value: 1 },
    { label: 'Completed', value: 2 }
  ])
  const [selectedTab, setSelectedTab] = useState(0)
  const [visibleProjects, setVisibleProjects] = useState([])
  const [columnsToHide, setColumnsToHide] = useState([])
  // const [columnSaveState, setColumnSaveState] = useState(false)
  const [anchorEl, setAnchorEl] = useState({
    element: null,
    data: {}
  })
  const [selectColumnsAnchorEl, setSelectColumnsAnchorEl] = useState(null)
  const [visibleColumns, setVisibleColumns] = useState([
    {
      Header: 'Name',
      label: 'Name',
      accessor: 'projectName',
      align: 'left',
      Cell: ({ value }) => {
        return (
          <div className="px-6 py-4 flex items-center space-x-2">
            <div className="whitespace-nowrap">
              {value.name.length < 30
                ? value.name
                : `${value.name.substring(0, 29)}...`}
            </div>
            <Pill
              variant={
                value.category.toLowerCase() === 'internal' ? 'blue' : 'pink'
              }
              // className="py-0.5"
            >
              {value.category}
            </Pill>
          </div>
        )
      },
      show: true
    },
    {
      Header: 'Status',
      label: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <div className="px-6 py-4">{getStatusBadge(value)}</div>
      ),
      align: 'center'
    },
    {
      Header: 'Total Tasks',
      label: 'Total Tasks',
      accessor: 'totalTask',
      Cell: ({ value }) => (
        <div className="px-6 py-4 text-center">
          <Pill variant="orange" style={{ fontSize: 11 }}>
            {value ?? 0}
          </Pill>
        </div>
      ),
      align: 'center'
    },
    {
      Header: 'Pending Tasks',
      label: 'Pending Tasks',
      accessor: 'pendingTask',
      Cell: ({ value }) => (
        <div className="px-6 py-4 text-center">
          <Pill variant="yellow" style={{ fontSize: 12 }}>
            {value ?? 0}
          </Pill>
        </div>
      ),
      align: 'center'
    },
    {
      label: 'Completion',
      Header: 'Completion',
      accessor: 'completion',
      Cell: ({ value }) => (
        <div className="px-6 py-4 text-center">
          <div className="whitespace-nowrap">
            <Completion
              totalTasks={value.totalTasks}
              completedTasks={value.completedTasks}
              completion={(
                (value.completedTasks / value.totalTasks) *
                100
              ).toFixed(1)}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
            />
          </div>
        </div>
      ),

      show: true,
      align: 'center'
    },
    {
      Header: 'Tracked Hours',
      label: 'Tracked Hours',
      accessor: 'trackedHours',
      Cell: ({ value }) => <div className="px-6 py-4 text-center">{value}</div>,
      align: 'center'
    },
    {
      Header: 'Budgeted Hours',
      label: 'Budgeted Hours',
      accessor: 'budgetedHours',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="whitespace-nowrap">
            {value ? `${value} hours` : '-'}
          </div>
        </div>
      ),
      align: 'left'
    },
    {
      label: 'Budget',
      Header: 'Budget',
      accessor: 'budget',
      Cell: ({ value }) => (
        <div className="px-6 py-4">
          <div className="whitespace-nowrap">
            {value.cost
              ? `${
                  currency[value.currency?.toUpperCase() ?? 'USD'].symbol_native
                } ${value.cost}`
              : '-'}
          </div>
        </div>
      ),
      align: 'left'
    }
  ])

  const columnSaveState = useMemo(() => {
    if (projectWidgetView._id) {
      let savedColumnData = projectWidgetView.content[0].hiddenColumns
      if (columnsToHide.length !== savedColumnData.length) {
        return true
      } else {
        for (let item of columnsToHide) {
          if (!savedColumnData.includes(item)) return true
        }
        return false
      }
    } else {
      return true
    }
  }, [columnsToHide, projectWidgetView])

  useEffect(() => {
    if (!fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [fetchedFirstTime])

  useEffect(() => {
    if (!projectWidgetView._id) {
      dispatch(fetchProjectWidgetView())
    }
  }, [])

  useEffect(() => {
    if (projectWidgetView._id)
      setColumnsToHide(projectWidgetView.content[0].hiddenColumns)
  }, [projectWidgetView])

  useEffect(() => {
    if (!loading) {
      const creator = value => {
        const userObj = [
          ...value.assignedAgencyTeam,
          ...value.assignedClientTeam
        ].find(mate => mate.user._id === value.user)

        if (userObj) return userObj.user
        return ''
      }

      const client = project => {
        const clientObj = project.assignedClientTeam.find(
          mate => mate.role === userRoles.USER_CLIENT
        )
        if (clientObj) return clientObj.user
        return ''
      }

      const members = project => {
        return [
          ...project.assignedAgencyTeam,
          ...project.assignedClientTeam
        ].map(obj => obj.user)
      }

      const modifiedData = workspaceData.map(project => ({
        ...project,
        projectName: { name: project.name, category: project.category },
        creator: creator(project),
        client: client(project),
        members: members(project),
        status: {
          isComplete: project.isComplete,
          isArchived: project.isArchived
        },
        budget: { cost: project.cost ?? 0, currency: project.currency },
        completion: {
          totalTasks: project.totalTask,
          completedTasks: project.completedTask
        },
        trackedHours: (
          (project.totalTimeTracked ?? 0) /
          (1000 * 60 * 60)
        ).toFixed(2), //in hours,
        pendingTask: (project.totalTask ?? 0) - (project.completedTask ?? 0)
      }))
      setProjectsData(modifiedData)
    }
  }, [workspaceData])

  useEffect(() => {
    if (projectsData.length) {
      switch (selectedTab) {
        case 1:
          setVisibleProjects(
            projectsData.filter(item => !item.isComplete && !item.isArchived)
          )
          break

        case 2:
          setVisibleProjects(projectsData.filter(item => item.isComplete))
          break

        default:
          setVisibleProjects([...projectsData])
      }
    } else {
      setVisibleProjects([])
    }
  }, [selectedTab, projectsData])

  // React Table variable

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns
  } = useTable(
    {
      columns: visibleColumns,
      data: visibleProjects,
      initialState: { hiddenColumns: columnsToHide }
    },
    useSortBy
  )

  useEffect(() => {
    setHiddenColumns(columnsToHide)
  }, [columnsToHide])

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
  }

  const handleDeleteWidgetClick = () => {
    handleRemoveWidget(widgetId)
  }

  const handleViewProject = workspace => {
    history.push(
      `${getUserPath(meData.role)}/workspace/${workspace._id}/board/${
        workspace.defaultBoard._id
      }?view=board`
    )
  }

  const toggleSelectColumnsPopup = e => {
    // e?.stopPropagation()
    if (selectColumnsAnchorEl) {
      setSelectColumnsAnchorEl(null)
    } else {
      setSelectColumnsAnchorEl(e.currentTarget)
    }
  }

  const toggleColumnsVisibility = (accessor, checked) => {
    if (!checked) {
      setColumnsToHide(prev => [...prev, accessor])
    } else {
      setColumnsToHide(prev => prev.filter(item => item !== accessor))
    }
  }

  const handleSaveColumns = callback => {
    if (projectWidgetView._id) {
      dispatch(
        updateProjectWidgetView(
          projectWidgetView._id,
          {
            content: [{ hiddenColumns: columnsToHide }]
          },
          (res, err) => {
            callback()
            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(fireSuccessToaster('Saved Successfully!'))
              toggleSelectColumnsPopup()
            }
          }
        )
      )
    } else {
      dispatch(
        createProjectWidgetView(
          {
            type: 'projectWidget',
            content: [{ hiddenColumns: columnsToHide }]
          },
          (res, err) => {
            callback()
            if (err) {
              dispatch(fireErrorToaster(res))
            } else {
              dispatch(fireSuccessToaster('Saved Successfully!'))
              toggleSelectColumnsPopup()
            }
          }
        )
      )
    }
  }

  return (
    <>
      <div className="mb-2 flex justify-between items-center pr-2">
        <h2 className="text-base lg:text-lg leading-6 font-medium text-gray-900 flex items-center">
          <span>
            Projects{' '}
            <span className="text-gray-500 text-sm">
              ({visibleProjects.length})
            </span>
          </span>
        </h2>
        <div>
          {editMode ? (
            meData.role < userRoles.USER_CLIENT ? (
              <button
                className="text-red-400"
                onClick={handleDeleteWidgetClick}
              >
                <DeleteIcon />
              </button>
            ) : null
          ) : (
            <div className="flex items-center space-x-2">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="simple tabs example"
                classes={{ root: classes.tabRoot }}
              >
                {tabsData.map((item, index) => (
                  <Tab
                    label={
                      <div className="flex items-center gap-1 text-xs">
                        {item.label}
                      </div>
                    }
                    key={index}
                    value={item.value}
                  />
                ))}
              </Tabs>

              <button
                className={clsx(
                  'w-8 h-8 rounded-full text-sm flex justify-center items-center shadow-lg border',
                  Boolean(selectColumnsAnchorEl) &&
                    'text-white bg-primary-main border-primary-main'
                )}
                tabIndex={0}
                onClick={toggleSelectColumnsPopup}
              >
                <AddIcon className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        className={clsx(
          `mb-9 align-middle min-w-full overflow-hidden sm:rounded-xl border rounded-xl shadow-lg`,
          !loading && 'overflow-x-auto'
        )}
        style={{
          overflowY: 'auto',
          height: 'calc(100% - 55px)',
          background: '#fff'
        }}
      >
        <table
          {...getTableProps()}
          className="min-w-full divide-y divide-gray-200 border-collapse border-hidden"
        >
          <thead
            className="font-medium uppercase text-left h-14 sticky top-0"
            style={{ zIndex: 99 }}
          >
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="hover:bg-gray-100"
              >
                {headerGroup.headers.map((col, index) => (
                  <th
                    className={clsx(
                      'tracking-wider font-medium px-5 py-3 lg:px-6 text-custom-gray-dark whitespace-nowrap text-extraSmaller lg:text-xs bg-custom-gray-light-5'
                      // col.id === 'projectName' && `sticky left-0 z-10 sticky`,
                      // col.align === 'center' && 'text-center'
                    )}
                    {...col.getHeaderProps(col.getSortByToggleProps())}
                  >
                    {col.render('Header')}
                    <span className="ml-1">
                      {col.isSorted ? (
                        col.isSortedDesc ? (
                          <>&#8593;</>
                        ) : (
                          <>&#8595;</>
                        )
                      ) : (
                        <>&#8645;</>
                      )}
                    </span>
                  </th>
                ))}
                {/* <th className="px-6">
                    <span className="inline-block">
                      <button
                        className={clsx(
                          'w-8 h-8 rounded-full text-sm flex justify-center items-center shadow-lg border',
                          Boolean(selectColumnsAnchorEl) &&
                            'text-white bg-primary-main border-primary-main'
                        )}
                        tabIndex={0}
                        onClick={toggleSelectColumnsPopup}
                      >
                        <AddIcon className="w-5 h-5" />
                      </button>
                    </span>
                  </th> */}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200 text-xs lg:text-sm text-gray-500"
          >
            {!loading ? (
              visibleProjects.length ? (
                rows.map(row => {
                  prepareRow(row)
                  return (
                    <tr
                      {...row.getRowProps()}
                      // className="hover:bg-gray-100"
                    >
                      {row.cells.map((cell, index) => (
                        <td
                          {...cell.getCellProps()}
                          className={clsx(
                            'text-gray-500 bg-white',
                            cell.column.id === 'projectName' &&
                              ` cursor-pointer text-gray-900`
                            // `sticky left-0 z-20 cursor-pointer`
                          )}
                          onClick={
                            cell.column.id === 'projectName'
                              ? () => handleViewProject(row.original)
                              : null
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                      {/* <td className="px-6">
                        {permission(row.original)?.update &&
                          permission(row.original)?.delete && (
                            <PopupMenu
                              menuItems={getPopupMenuOptions(row.original)}
                            />
                          )}
                      </td> */}
                    </tr>
                  )
                })
              ) : (
                <Tr className="bg-white">
                  <th
                    colSpan={visibleColumns.length + 1}
                    className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
                  >
                    There are no projects
                  </th>
                </Tr>
              )
            ) : (
              <TableDataLoading cols={visibleColumns.length} />
            )}
          </tbody>
        </table>
      </div>

      <InfoPopper
        {...anchorEl}
        onClose={() => setAnchorEl({ element: null, data: {} })}
      />
      <SelectColumnsPopup
        anchorEl={selectColumnsAnchorEl}
        handleClose={toggleSelectColumnsPopup}
        toggleColumnsVisibility={toggleColumnsVisibility}
        hiddenCols={columnsToHide}
        columns={visibleColumns}
        handleSaveColumns={handleSaveColumns}
        columnSaveState={columnSaveState ?? false}
      />
    </>
  )
}

const getStatusBadge = project => {
  return (
    <Pill
      variant={
        project.isComplete ? 'orange' : project.isArchived ? 'gray' : 'green'
      }
    >
      {project.isComplete
        ? 'Completed'
        : project.isArchived
        ? 'Archived'
        : 'Active'}
    </Pill>
  )
}

// const Row = ({
//   title,
//   totalTasks,
//   completedTasks,
//   timeTracked,
//   setAnchorEl,
//   anchorEl,
//   workspace,
//   meData
// }) => {
//   const history = useHistory()

//   const handleProjectRedirect = () => {
//     console.log(workspace)
//     history.push(
//       `${getUserPath(meData.role)}/workspace/${workspace._id}/board/${
//         workspace.defaultBoard
//       }?view=board`
//     )
//   }

//   return (
//     <Tr>
//       <Td onClick={handleProjectRedirect} className="cursor-pointer">
//         <div className="whitespace-nowrap capitalize">
//           <span className="mr-2 text-gray-500 group-hover:text-gray-900">
//             {title}
//           </span>
//           <Pill variant={workspace.category === 'Internal' ? 'indigo' : 'pink'}>
//             {workspace.category}
//           </Pill>
//         </div>
//       </Td>
//       <Td className="text-center">{timeTracked}</Td>
//       <Td className="flex justify-center">
//         <Completion
//           totalTasks={totalTasks}
//           completedTasks={completedTasks}
//           completion={((completedTasks / totalTasks) * 100).toFixed(1)}
//           setAnchorEl={setAnchorEl}
//           anchorEl={anchorEl}
//         />
//       </Td>
//     </Tr>
//   )
// }

export default React.memo(NewProjectsAnalyticsTable)
