import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { Button, TextField } from '@material-ui/core'
import {
  ColoredAvatars,
  CustomTooltip,
  LoadingButton,
  PopupMenu
} from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
// import StatusUpdateHeader from './StatusUpdateHeader'
import { useSelector } from 'react-redux'
import { formatDistanceToNow } from 'date-fns'
import { format } from 'date-fns'
import { ReactComponent as PaperclipIcon } from 'static/svg/paperclip.svg'
import { ReactComponent as AngleIcon } from 'static/svg/angle-down.svg'
import { ReactComponent as VerticalThreeDots } from 'static/svg/ellipsis-h.svg'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
// import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as FileIcon } from 'static/svg/file.svg'
import StatusUpdateSection from './StatusUpdateSection'
import StatusUpdateDropdown, { projectStatusData } from './StatusUpdateDropdown'
import clsx from 'clsx'
import { useParams, useHistory } from 'react-router-dom'
import { downloadFile, errorMessages, fileToDataUrl } from 'utils'
import projectStatusUpdateStyles from './projectStatusUpdateStyles'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  createProjectStatusPost,
  updateProjectStatusPost
} from 'thunks/StatusPost/action'
import { handleFilePreview } from 'thunks/generlAppState/actions'
import ReactToPrint from 'react-to-print'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'

const CreateNewStatus = ({
  toggleDeletePostAlert,
  currentStatusPost,
  updateStatusPost,
  readOnly = false,
  agencyLogo
}) => {
  const classes = projectStatusUpdateStyles()
  const dispatch = useDispatch()
  const currentWorkspace = useSelector(
    state => state.userWorkspaces.currentWorkspace
  )
  const meData = useSelector(state => state.me.data)
  const [loading, setLoading] = useState({ publish: false, draft: false })
  const [editStatus, setEditStatus] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [changes, setChanges] = useState(false)
  const ref = useRef()
  const [statusObj, setStatusObj] = useState({
    title: '',
    state: 'draft',
    status: 1,
    statusSections: [],
    files: []
  })
  const statusRef = useRef()

  const logo = useMemo(() => {
    if (!readOnly) {
      return (
        meData.profile?.brand?.smallLogo ??
        meData.team?.profile?.brand?.smallLogo ??
        meData.agency?.brand?.smallLogo
      )
    } else {
      return agencyLogo
    }
  }, [meData])

  // useEffect(() => {
  //   if (editStatus && changes) {
  //     const timer = setTimeout(() => {
  //       updateProjectStatusPost(
  //         currentStatusPost._id,
  //         {
  //           title: statusObj.title,
  //           status: statusObj.status,
  //           state: 'draft',
  //           sections: statusObj.statusSections.map((sec) => ({
  //             title: sec.title,
  //             description: sec.description,
  //             tasks: sec.tasks.map((task) => {
  //               if (typeof task === 'object') {
  //                 return task._id
  //               } else {
  //                 return task
  //               }
  //             }),
  //             taskConfiguration: sec.taskConfiguration?.workspaceSection?._id
  //               ? {
  //                   ...sec.taskConfiguration,
  //                   workspaceSection:
  //                     sec.taskConfiguration.workspaceSection?._id
  //                 }
  //               : sec.taskConfiguration
  //           })),
  //           file: statusObj.files
  //         },
  //         (res, err) => {
  //           if (!err) {
  //             updateStatusPost(res)
  //           }
  //         }
  //       )
  //       setChanges(false)
  //     }, 5000)
  //     return () => clearTimeout(timer)
  //   }
  // }, [statusObj])

  useEffect(() => {
    if (currentStatusPost._id) {
      if (currentStatusPost.state === 'draft') {
        setEditStatus(true)
      } else {
        setEditStatus(false)
      }

      setStatusObj({
        title: currentStatusPost.title ?? '',
        status: currentStatusPost.status ?? '1',
        statusSections: currentStatusPost?.sections.map((item, idx) => ({
          ...item,
          taskConfiguration: item.taskConfiguration
            ? { ...item.taskConfiguration }
            : {}
        })),
        files: currentStatusPost.file ?? []
      })
    }
  }, [currentStatusPost._id])

  // useEffect(() => {
  //   document.body.classList.add('overflow-hidden')
  //   return () => document.body.classList.remove('overflow-hidden')
  // }, [])

  const handleInputChange = e => {
    const { name, value } = e.target
    setStatusObj(prev => ({
      ...prev,
      [name]: value
    }))
    setChanges(true)
  }

  const handleUpdateSectionData = (field, input, index) => {
    if (editStatus) {
      setStatusObj(prev => ({
        ...prev,
        statusSections: prev.statusSections.map((item, idx) => {
          if (idx === index)
            return {
              ...item,
              [field]: input
            }
          return item
        })
      }))
      setChanges(true)
    }
  }

  const toggleStatusDropdown = e => {
    if (editStatus) {
      if (anchorEl) setAnchorEl(null)
      else {
        setAnchorEl(e.currentTarget)
      }
    }
  }

  const handleChangeStatus = useCallback(val => {
    setStatusObj(prev => ({
      ...prev,
      status: val
    }))
    setChanges(true)
  }, [])

  const handleAddNewBlock = () => {
    setStatusObj(prev => ({
      ...prev,
      statusSections: [
        ...prev.statusSections,
        {
          title: 'New Section',
          placeholder: 'Continue writing here...',
          tasks: [],
          taskConfiguration: {},
          description: ''
        }
      ]
    }))
    setChanges(true)
  }

  const handleUploadFiles = e => {
    let base64 = []

    for (let file of e.target.files) {
      base64.push(fileToDataUrl(file))
    }

    // for (let file of base64) {
    //   var stringLength = new Buffer(file, 'base64').length
    //   console.log(stringLength)

    //   // var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
    //   // var sizeInKb = sizeInBytes / 1000
    // }

    Promise.all(base64).then(res => {
      setStatusObj(prev => ({ ...prev, files: [...prev.files, ...res] }))
      setChanges(true)
    })
  }

  const handleReplaceFile = e => {
    const replaceIndex = Number(e.target.dataset.replaceIndex)
    const file = e.target.files[0]

    fileToDataUrl(file).then(res => {
      setStatusObj(prev => ({
        ...prev,
        files: prev.files.map((item, idx) => {
          if (idx === replaceIndex) return res
          return item
        })
      }))
      setChanges(true)
    })
  }

  const handleDownloadImage = index => {
    downloadFile(statusObj.files[index], err => {
      if (err)
        dispatch(
          fireErrorToaster(
            'Error occurred while downloading image, please try again'
          )
        )
    })
  }

  const handleReplaceImage = index => {
    ref.current.setAttribute('data-replace-index', index)
    ref.current.click()
  }

  const handleRemoveImage = index => {
    setStatusObj(prev => ({
      ...prev,
      files: prev.files.filter((item, idx) => idx !== index)
    }))
    setChanges(true)
  }

  const handlePreviewFile = e => {
    dispatch(
      handleFilePreview({
        open: true,
        file: e.currentTarget.dataset.url
      })
    )
  }

  const handleSaveChanges = () => {
    setLoading({ publish: true, draft: false })
    setChanges(false)
    dispatch(
      updateProjectStatusPost(
        currentStatusPost._id,
        {
          title: statusObj.title,
          status: statusObj.status,
          state: 'publish',
          sections: statusObj.statusSections.map(sec => ({
            title: sec.title,
            description: sec.description,
            tasks: sec.tasks.map(task => {
              if (typeof task === 'object') {
                return task._id
              } else {
                return task
              }
            }),
            taskConfiguration: sec.taskConfiguration?.workspaceSection?._id
              ? {
                  ...sec.taskConfiguration,
                  workspaceSection: sec.taskConfiguration.workspaceSection?._id
                }
              : sec.taskConfiguration
            // taskConfiguration: sec.taskConfiguration
          })),
          file: statusObj.files
        },
        saveChangesCallback
      )
    )
  }

  const handleSaveDraft = () => {
    setLoading({ publish: false, draft: true })
    dispatch(
      updateProjectStatusPost(
        currentStatusPost._id,
        {
          title: statusObj.title,
          status: statusObj.status,
          state: 'draft',
          sections: statusObj.statusSections.map(sec => ({
            title: sec.title,
            description: sec.description,
            tasks: sec.tasks.map(task => {
              if (typeof task === 'object') {
                return task._id
              } else {
                return task
              }
            }),
            taskConfiguration: sec.taskConfiguration?.workspaceSection?._id
              ? {
                  ...sec.taskConfiguration,
                  workspaceSection: sec.taskConfiguration.workspaceSection?._id
                }
              : sec.taskConfiguration
          })),
          file: statusObj.files
        },
        (res, err) => {
          setLoading({ publish: false, draft: false })
          if (!err) {
            // updateStatusPost(res)
          }
        }
      )
    )
  }

  const saveChangesCallback = (res, err) => {
    setLoading({ publish: false, draft: false })
    if (err) dispatch(fireErrorToaster(errorMessages.ERROR_MESSAGE))
    else {
      // updateStatusPost(res)
      setEditStatus(false)
      dispatch(fireSuccessToaster('Updated Successfully!'))
    }
  }

  const handleDeleteSection = ind => {
    setStatusObj(prev => ({
      ...prev,
      statusSections: prev.statusSections.filter((item, index) => index !== ind)
    }))
    setChanges(true)
  }

  return (
    <div className="h-full my-8">
      <div className="mx-auto" style={{ maxWidth: 700 }}>
        <div className="flex justify-end space-x-4 my-4">
          {editStatus && (
            <>
              <LoadingButton onClick={handleSaveDraft} loading={loading.draft}>
                Save Changes
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                onClick={handleSaveChanges}
                loading={loading.publish}
              >
                Publish
              </LoadingButton>
            </>
          )}

          {!editStatus && (
            <ReactToPrint
              trigger={() => {
                return (
                  <CustomTooltip title="Download Report" placement="top">
                    <button
                      className={clsx(
                        'w-8 h-8 rounded-full  text-sm flex justify-center items-center shadow-lg border text-white bg-primary-light border-primary-main'
                      )}
                    >
                      <DownloadIcon className="text-primary-main w-5 h-5" />
                    </button>
                  </CustomTooltip>
                )
              }}
              content={() => statusRef.current}
              documentTitle={`${currentStatusPost.title}`}
            />
          )}
        </div>

        <div
          style={{
            height: 'calc(100vh - 74px - 32px )',
            overflow: 'auto',
            boxShadow:
              '0 3.2px 7.2px rgba(0,0,0,0.13),0 0.6px 1.8px rgba(0,0,0,0.11)'
          }}
          className=" bg-white"
        >
          <div ref={statusRef}>
            {/* ============= 1st Section ========== */}

            <section
              className="px-6 pt-6 lg:px-8 lg:pt-8 pb-2 "
              style={{ backgroundColor: '#f9f9fa' }}
            >
              <header className="">
                {!readOnly && currentStatusPost.user?._id === meData._id && (
                  <div className="text-right">
                    <PopupMenu
                      menuItems={[
                        {
                          label: 'delete',
                          method: () =>
                            toggleDeletePostAlert(currentStatusPost),
                          icon: <DeleteIcon className="text-primary-main" />
                        }
                      ]}
                    />
                  </div>
                )}
                {logo && (
                  <img
                    src={
                      logo.includes('https://s3')
                        ? `${logo}?${Date.now()}`?.replace(
                            's3.wasabisys.com/',
                            ''
                          )
                        : logo
                    }
                    alt="logo"
                    className={clsx(
                      'object-contain mx-auto mb-4',
                      classes.logo
                    )}
                  />
                )}
                <div className="flex space-x-4 items-center mb-2">
                  {editStatus ? (
                    <TextField
                      variant="standard"
                      multiline
                      type="text"
                      value={statusObj.title}
                      name="title"
                      autoFocus
                      disabled={editStatus ? false : true}
                      className="text-base lg:text-lg font-medium w-full bg-transparent border border-transparent hover:border-gray-300 focus:outline-gray-300 p-1"
                      onChange={handleInputChange}
                      InputProps={{
                        disableUnderline: true, // <== added this to remove border with variant = 'standard'
                        className: classes.mainTitle
                      }}
                    />
                  ) : (
                    <h1
                      className={`text-base lg:text-lg font-medium border-none w-full bg-transparent ${classes.mainTitle}`}
                    >
                      {statusObj.title}
                    </h1>
                  )}
                </div>
              </header>

              <div className="flex justify-between flex-wrap text-smaller lg:text-sm mb-4">
                <div className="w-1/2 flex items-center">
                  <h5 className="mr-4 text-gray-500">Status</h5>
                  <div className="flex items-center">
                    <button
                      onClick={toggleStatusDropdown}
                      className={clsx(
                        ' px-2 py-2 rounded transition-colors ease-in-out duration-150 flex items-center',
                        Boolean(anchorEl) && 'bg-gray-100'
                      )}
                    >
                      <span
                        className={clsx(
                          'inline-block w-3 h-3 rounded-full mr-1',
                          projectStatusData[statusObj.status]?.bgColorClass
                        )}
                      ></span>
                      <span className=" text-gray-700 font-medium">
                        {projectStatusData[statusObj.status]?.label}
                      </span>

                      {editStatus && (
                        <AngleIcon className="h-3 w-3 lg:h-4 lg:w-4 ml-1" />
                      )}
                    </button>
                  </div>
                </div>

                <div className="w-1/2 flex items-center">
                  <h5 className="mr-4 text-gray-500  ">
                    {currentStatusPost.state === 'draft'
                      ? 'Owner'
                      : 'Published by'}
                  </h5>
                  <div className="flex items-center">
                    <ColoredAvatars user={currentStatusPost.user} />
                    <span className=" text-gray-700 font-medium ml-1">
                      {currentStatusPost.user?.name}
                    </span>
                    <span
                      className={clsx(
                        'inline-block w-2 h-2 rounded-full mr-1 text-black'
                      )}
                    ></span>
                    {currentStatusPost.state !== 'draft' &&
                      currentStatusPost?.createdAt && (
                        <span className="text-xs text-gray-500 font-medium ml-2">
                          {formatDistanceToNow(
                            new Date(currentStatusPost.createdAt)
                          )}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </section>
            <hr className=" " />

            <div className="py-4 px-6 lg:px-8 text-smaller lg:text-sm">
              <div>
                {editStatus && !statusObj.files.length && (
                  <div className="w-full flex items-center">
                    <label className="cursor-pointer px-2 py-2 rounded hover:bg-gray-100 inline-flex items-center  text-gray-500">
                      <PaperclipIcon className="h-4 w-4" />
                      <span className=" text-gray-900  font-medium ml-2">
                        Add attachment
                      </span>
                      <input
                        type="file"
                        multiple
                        className="hidden"
                        onChange={handleUploadFiles}
                      />
                    </label>
                  </div>
                )}
              </div>
              {Boolean(statusObj.files?.length) ? (
                editStatus ? (
                  <div className={clsx(classes.attachmentsContainer, 'w-full')}>
                    {statusObj.files.map((item, index) => (
                      <div
                        key={index}
                        className={clsx(
                          'relative show-on-hover-parent rounded-2xl overflow-hidden',
                          classes.attachmentWrapper
                        )}
                      >
                        <PopupMenu
                          targetComp={
                            <button
                              tabIndex={0}
                              className="show-on-hover-child w-6 h-6 rounded-full text-white border border-white absolute top-4 right-4 flex justify-center items-center z-10"
                            >
                              <VerticalThreeDots className="text-smaller lg:text-sm" />
                            </button>
                          }
                          menuItems={[
                            {
                              label: 'Download file',
                              method: () => handleDownloadImage(index)
                            },
                            {
                              label: 'Remove file',
                              method: () => handleRemoveImage(index)
                            },
                            {
                              label: 'Replace file',
                              method: () => handleReplaceImage(index)
                            }
                          ]}
                        />

                        {item.startsWith('data:image') ? (
                          <img
                            key={index}
                            src={item}
                            alt=""
                            className="file w-full h-full object-content rounded-2xl border"
                          />
                        ) : item.startsWith('https://s3.wasabisys.com') &&
                          /.(png|jpe?g|svg|gif|avif|apng|webp)$/.test(item) ? (
                          <img
                            src={item}
                            key={index}
                            alt=""
                            onClick={handlePreviewFile}
                            data-url={item}
                            className="file w-full h-full object-cover rounded-2xl border"
                          />
                        ) : (
                          <div className="file w-full h-full object-cover rounded-2xl flex justify-center items-center text-gray-400 border">
                            <FileIcon className="w-8 h-8" />
                          </div>
                        )}
                      </div>
                    ))}
                    {/* {editStatus && ( */}
                    <>
                      <label
                        className="border-2 border-dashed flex justify-center items-center text-custom-gray-light-3 text-xl lg:text-2xl rounded-xl lg:rounded-2xl"
                        role="button"
                      >
                        <Add />
                        <input
                          type="file"
                          multiple
                          className="hidden"
                          onInput={handleUploadFiles}
                        />
                      </label>
                      <input
                        ref={ref}
                        type="file"
                        className="hidden"
                        onChange={handleReplaceFile}
                      />
                    </>
                    {/* )} */}
                  </div>
                ) : (
                  <div className="mb-6 flex gap-4 flex-wrap">
                    {statusObj.files?.map((url, index) => (
                      <div
                        key={index}
                        className="relative rounded-2xl w-28 object-content rounded-xl overflow-hidden border"
                        style={{ maxHeight: '120px' }}
                      >
                        {/.(png|jpe?g|svg|gif|avif|apng|webp)$/.test(url) ? (
                          <img
                            src={url}
                            alt=""
                            onClick={handlePreviewFile}
                            data-url={url}
                            className="w-full h-full object-content cursor-pointer"
                          />
                        ) : (
                          <div
                            className="w-full h-full flex justify-center items-center text-xl text-gray-400 cursor-pointer"
                            onClick={handlePreviewFile}
                            data-url={url}
                          >
                            <FileIcon />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )
              ) : null}

              {editStatus && <hr className="mt-4" />}

              {statusObj.statusSections?.map((item, index) => (
                <StatusUpdateSection
                  key={index}
                  {...item}
                  index={index}
                  handleUpdateSectionData={handleUpdateSectionData}
                  disabled={editStatus ? false : true}
                  currentStatusPostId={currentStatusPost._id}
                  handleDeleteSection={handleDeleteSection}
                />
              ))}

              {currentStatusPost.state === 'draft' && (
                <div
                  className="flex items-center justify-center border-2 border-dashed py-4 text-gray-500 mb-8 cursor-pointer"
                  role="button"
                  onClick={handleAddNewBlock}
                >
                  <Add className="mr-2" />
                  Add new block
                </div>
              )}
            </div>

            <StatusUpdateDropdown
              anchorEl={anchorEl}
              onClose={toggleStatusDropdown}
              selectedStatus={statusObj.status}
              handleChangeStatus={handleChangeStatus}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateNewStatus
