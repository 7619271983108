import React from 'react'
import { Popover, Checkbox } from '@material-ui/core'
import { CheckBoxField } from 'global/globalComponents'

const SelectColumnsPopup = ({
  anchorEl,
  handleClose,
  columns,
  toggleColumnsVisibility,
  hiddenCols
}) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <div className="p-4 rounded-xl" style={{ minWidth: 200 }}>
        {columns.map((item) => (
          <div key={item.label}>
            <CheckBoxField
              name="toggle column"
              onChange={(e) =>
                toggleColumnsVisibility(item.value, e.target.checked)
              }
              checked={!hiddenCols[item.value]}
              label={item.label}
            />
          </div>
        ))}
      </div>
    </Popover>
  )
}

export default SelectColumnsPopup
