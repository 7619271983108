import React from 'react'
import { CustomTooltip } from 'global/globalComponents'
import { CSVLink } from 'react-csv'
import { ReactComponent as ImportIcon } from 'static/svg/import.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { ReactComponent as CalendarIcon } from 'static/svg/calendar.svg'
import clsx from 'clsx'

const PeoplePerformanceHeader = ({
  handleToggleTimelinePopup,
  selectedTimeline,
  toggleSelectColumnsPopup,
  downloadData,
  selectPopupOpen
}) => {
  return (
    <div className="ml-auto mb-4 pr-2">
      <div className="flex items-center space-x-4 pr-2">
        <div
          role="button"
          onClick={handleToggleTimelinePopup}
          className="cursor-pointer flex items-center text-smaller"
        >
          <CalendarIcon />{' '}
          <span className="font-medium ml-1 text-gray-600 text-xs">
            {selectedTimeline}
          </span>
        </div>

        <button
          onClick={toggleSelectColumnsPopup}
          className={clsx(
            'w-7 h-7 rounded-full text-sm flex justify-center items-center shadow-lg border',
            selectPopupOpen && 'bg-primary-main text-white'
          )}
        >
          <AddIcon />
        </button>

        <CustomTooltip title="Download CSV file" placement="top">
          <CSVLink data={downloadData} filename="people_performance.csv">
            <ImportIcon className="w-4 h-4 hover:text-primary-main" />
          </CSVLink>
        </CustomTooltip>
      </div>
    </div>
  )
}

export default React.memo(PeoplePerformanceHeader)
