import React from 'react'
import clsx from 'clsx'

const PeoplePerformanceTable = React.memo(
  ({ getTableProps, headerGroups, getTableBodyProps, rows, prepareRow }) => {
    return (
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-200 border-collapse border-hidden"
      >
        <thead className="font-medium uppercase text-left h-14">
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="hover:bg-gray-100"
            >
              {headerGroup.headers.map((col, index) => (
                <th
                  className={clsx(
                    'px-6 py-4 text-gray-500 whitespace-nowrap text-extraSmaller lg:text-xs bg-custom-gray-light-5',
                    col.id === 'Name' && `sticky left-0 z-10 sticky`,
                    col.align === 'center' && 'text-center'
                  )}
                  {...col.getHeaderProps(col.getSortByToggleProps())}
                >
                  {col.render('Header')}
                  <span className="ml-1">
                    {col.isSorted ? (
                      col.isSortedDesc ? (
                        <>&#8593;</>
                      ) : (
                        <>&#8595;</>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200 text-xs lg:text-sm text-gray-500"
        >
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                // className="bg-white hover:bg-gray-100"
              >
                {row.cells.map((cell, index) => (
                  <td
                    {...cell.getCellProps()}
                    className={clsx(
                      'text-gray-500 bg-white',
                      cell.column.id === 'Name' && `sticky left-0 z-10 sticky`
                    )}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
                {/* <td className="w-12"></td> */}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
)

export default PeoplePerformanceTable
