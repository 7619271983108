import axios from 'axios'
import { errorMessages } from 'utils'
import { CLIENT_RAISE_REQUEST } from './reducer'
import { createAccountLogs } from 'thunks/accountLogs/action'
import { CLIENT_RAISED_REQUESTS_AGENCY } from 'thunks/clientRaisedRequestAgency/reducer'

/**
 * Fetch raised requests by client
 * @param {Function} callback callback function
 */
export const fetchClientRaisedRequests = (callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        url: '/clientrequest/user',
        method: 'GET'
      })

      let sortedClientRequests = res.data
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        .sort((item) => {
          if (item.status === 'resolved') return 0
          else if (item.status === 'pending') return -1
          return 1
        })

      dispatch({
        type: CLIENT_RAISE_REQUEST.FETCHED,
        payload: sortedClientRequests
      })

      if (callback) callback(res.data)
    } catch (err) {
      dispatch({ type: CLIENT_RAISE_REQUEST.ERROR })
      if (callback) {
        callback(
          err?.response?.data?.message ?? "Couldn't fetch raised requests",
          true
        )
      }
    }
  }
}

/**
 * Raise a new client request
 * @param {object} data title, description, file (optional), priority(optional)
 * @param {Function} callback callback function
 */
export const raiseNewRequest = (data, elmFor, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/clientrequest',
        data: { ...data, createdAt: new Date(), updatedAt: new Date() }
      })

      if (elmFor === 'agency') {
        dispatch({
          type: CLIENT_RAISED_REQUESTS_AGENCY.ADD_ONE,
          payload: res.data
        })
      } else {
        dispatch({ type: CLIENT_RAISE_REQUEST.ADD_ONE, payload: res.data })
      }
      if (callback) callback(res.data)
      // dispatch(
      //   createAccountLogs({
      //     description: `raised a new request <b>${data.title}</b> with status '${data.status}`,
      //     category: 'clientRequest',
      //     task: res?.data?._id
      //   })
      // )
    } catch (err) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * Update raised request
 * @param {String} id request id
 * @param {object} data
 * @param {Function} callback callback function
 */
export const updateRaisedRequest = (id, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/clientrequest/${id}`,
        data: { ...data, updatedAt: new Date() }
      })
      dispatch({ type: CLIENT_RAISE_REQUEST.UPDATE_ONE, payload: res.data })
      if (callback) callback(res.data)
      // dispatch(
      //   createAccountLogs({
      //     description: `updated the request <b>${data.title}</b> with status '${data.status}`,
      //     category: 'clientRequest',
      //     task: id
      //   })
      // )
    } catch (err) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}
