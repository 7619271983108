import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ACCOUNTLOGS } from 'thunks/accountLogs/reducers'
import userRoles from 'utils/userRoles'

const NotificationsPusherWrapper = (props) => {
  const { data: notifications, fetched: fetchedFirstTime } = useSelector(
    (state) => state.accountLogs
  )
  const meData = useSelector((state) => state.me.data)
  const dispatch = useDispatch()
  const history = useHistory()

  const [channelConnectedFlag, setConnectedFlag] = useState(false)

  // const pushUserToNotifications = () => {
  //   history.push(`${getUserPath(meData.data.userRuserRoles)}/notifications`)
  // }

  useEffect(() => {
    let agencyOwner
    if (meData.role === userRoles.USER_AGENCY) {
      agencyOwner = meData._id
    } else if (
      [
        userRoles.USER_CLIENT,
        userRoles.AGENCY_MANAGER,
        userRoles.AGENCY_EDITOR,
        userRoles.AGENCY_VIEWER,
        userRoles.AGENCY_ADMIN
      ].includes(meData.role)
    ) {
      agencyOwner = meData.team._id
    } else if (
      [
        userRoles.CLIENT_MANAGER,
        userRoles.CLIENT_EDITOR,
        userRoles.CLIENT_VIEWER
      ].includes(meData.role)
    ) {
      agencyOwner = meData.agency
    }
    const notificationSubscribe = window.pusherInstance?.channel(
      `accountNotification_${agencyOwner}`
    )

    if (!notificationSubscribe || !notificationSubscribe.subscribed) {
      const subscribeNotifications = window.pusherInstance?.subscribe(
        `accountNotification_${agencyOwner}`
      )

      if (subscribeNotifications) {
        subscribeNotifications.bind('notification_add', (data) => {
          console.log('notification_posted event capture', data)
          const user = data.userDetails?.find((el) => el.user === meData._id)
          if (user) {
            dispatch({ type: ACCOUNTLOGS.POSTED, payload: data })
          }
        })
      }
    }

    return () => {
      window.pusherInstance.unsubscribe(`accountNotification_${agencyOwner}`)
    }
  }, [])

  return <>{props.children}</>
}

export default NotificationsPusherWrapper
