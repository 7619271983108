import React, { useEffect, useMemo, useState } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
import { Button } from '@material-ui/core'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector } from 'react-redux'
import { ReactComponent as PlaneIcon } from 'static/svg/plane.svg'
import usePlanFunctions from 'components/AgencyPlans/usePlanFunctions'
import { openChatMessengerWithMessage } from 'utils'

const PlanSubscriptionModal = ({
  open,
  onClose,
  data,
  selectPlanHandler,
  loading
}) => {
  // const dispatch = useDispatch()
  const agencyPlansData = useSelector((state) => state.agencyPlans.data)
  const [planOptions, setPlanOptions] = useState([])
  const [selectedPlan, setSelectedPlan] = useState({})
  const { getSeatBasedPlanAmount } = usePlanFunctions()
  const [totalAmount, setTotalAmount] = useState(0)
  const seatOptions = useMemo(() => {
    const arr = [1, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((item) => ({
      label: `${item} ${item <= 1 ? 'Seat' : 'Seats'}`,
      value: item
    }))
    arr.push({ label: '50+ Seats', value: 'contact us' })
    return arr
  }, [])
  const [selectedSeats, setSelectedSeats] = useState({
    label: '1 Seat',
    value: 1
  })

  useEffect(() => {
    if (open) {
      // const plan = data.payment_plan ? data.payment_plan : data
      // console.log('PLAN', plan)
      setSelectedPlan({ label: data.plan.nickname, value: data.plan.id })
      setSelectedSeats({
        label: `${data.quantity ?? 1} ${data.quantity <= 1 ? 'Seat' : 'Seats'}`,
        value: data.quantity ?? 1
      })
      setTotalAmount(data.amount)
    }
  }, [open])

  useEffect(() => {
    setPlanOptions(
      agencyPlansData
        .filter((item) => item.tiers_mode === 'graduated')
        .map((item) => ({ label: item.nickname, value: item.id }))
    )
  }, [agencyPlansData])

  useEffect(() => {
    const plan = agencyPlansData.find((item) => item.id === selectedPlan.value)
    if (plan) {
      setTotalAmount(
        getSeatBasedPlanAmount({
          plan: plan,
          seats: selectedSeats.value === 'contact us' ? 0 : selectedSeats.value
        })
      )
    }
  }, [selectedSeats, data, selectedPlan])

  const handlePlanChange = (val) => {
    setSelectedPlan(val)
  }

  // const updateSeatCount = (e) => {
  //   const action = e.currentTarget.dataset.action

  //   if (selectedSeats === 1 && action === 'minus') return
  //   setSelectedSeats((prev) => (action === 'plus' ? prev + 1 : prev - 1))
  // }
  const handleSeatChange = (val) => {
    setSelectedSeats(val)
  }

  const handleUpgradePlan = () => {
    const plan = agencyPlansData.find((item) => item.id === selectedPlan.value)
    selectPlanHandler({
      plan: plan,
      amount: totalAmount * 100,
      quantity: selectedSeats.value
    })
  }

  const handleContactUs = () => {
    onClose()
    openChatMessengerWithMessage('I want to upgrade my plan')
  }

  return (
    <CustomModal onClose={onClose} open={open} size="large">
      <CustomModalHeader
        heading="Upgrade your plan"
        icon={
          <div className="w-10 h-10 bg-primary-light rounded flex justify-center items-center text-xl">
            <PlaneIcon className="text-primary-main" />
          </div>
        }
      />
      <CustomModalBody overflow="visible">
        <div>
          <div className="flex items-center mt-2 mb-8 space-x-4">
            <div className="flex items-center whitespace-nowrap flex-1">
              <label className="inline-block text-smaller font-semibold text-gray-600 mr-2">
                Select Plan:
              </label>
              <Select
                options={planOptions}
                value={selectedPlan}
                onChange={handlePlanChange}
                styles={reactSelectCustomStyles}
                className="w-full"
              />
            </div>

            <div className="flex items-center">
              <label className="inline-block text-smaller font-semibold text-gray-600 mr-2">
                Choose team size:
              </label>
              <Select
                options={seatOptions}
                value={selectedSeats}
                className="w-40 text-sm"
                onChange={handleSeatChange}
                styles={reactSelectCustomStyles}
                maxMenuHeight={200}
              />
            </div>

            {/* <div className="flex justify-center items-center space-x-2 ml-4">
              <label className="inline-block text-smaller font-semibold text-gray-600">
                Select Seats:
              </label>
              <button
                onClick={updateSeatCount}
                data-action="minus"
                className="bg-primary-main text-white w-5 h-5 flex justify-center items-center border border-gray-400 rounded"
              >
                &minus;
              </button>
              <input
                type="number"
                className="w-8 h-8 border border-gray-400 rounded text-center text-primary-main"
                value={selectedSeats}
              />
              <button
                onClick={updateSeatCount}
                data-action="plus"
                className="bg-primary-main text-white w-5 h-5 flex justify-center items-center border border-gray-400 rounded"
              >
                &#43;
              </button>
            </div> */}
          </div>

          <div>
            <h3 className="font-semibold mb-2">Summary</h3>
            <table className="w-full mb-6">
              <thead>
                <th className="text-left text-smaller font-medium pb-1">
                  Plan
                </th>
                <th className="text-smaller font-medium pb-1">Seats</th>
                <th className="text-right text-smaller font-medium pb-1">
                  Total amount
                </th>
              </thead>
              <tbody className="border-t border-b">
                <td className="text-left text-smaller text-gray-600 py-1 pl-2">
                  {selectedPlan.label}
                </td>
                <td className="text-center text-smaller text-gray-600 py-1">
                  {selectedSeats.value === 'contact us'
                    ? '50+'
                    : selectedSeats.value}
                </td>
                <td className="text-right text-smaller text-gray-600 py-1 pr-2">
                  ${totalAmount}
                </td>
              </tbody>
            </table>
          </div>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          loading={loading}
          onClick={
            selectedSeats.value !== 'contact us'
              ? handleUpgradePlan
              : handleContactUs
          }
        >
          {selectedSeats.value !== 'contact us' ? 'Upgrade' : 'Contact Us'}
        </LoadingButton>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

// const useStyles = makeStyles({
//   summaryGrid: {
//     display: ""
//   }
// })

export default PlanSubscriptionModal
