import React, { useState, useMemo, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector, useDispatch } from 'react-redux'
import { handleFilePreview } from 'thunks/generlAppState/actions'
import { ReactComponent as DownloadIcon } from 'static/svg/download.svg'
// import { downloadFile } from 'utils'
// import { fireErrorToaster } from 'thunks/fireToaster/actions'
// import { CircularProgress } from '@material-ui/core'

const FilePreview = () => {
  const dispatch = useDispatch()
  const { open, file } = useSelector(
    (state) => state.generalAppState.filePreview
  )
  // const [downloading, setDownloading] = useState(false)
  const [customDownloadBtn, setCustomDownloadBtn] = useState(false)
  const fileType = useMemo(() => {
    if (/\.(png|svg|jpe?g|gif|tiff?|webp)$/i.test(file)) return 'image'
    if (/\.(m4a|flac|mp3|wav|aiff)$/i.test(file)) return 'audio'
    if (/\.(mp4|mov|avi|flv|mkv|wmv|avchd|webm|mpeg-4)$/i.test(file))
      return 'video'
    return 'other'
  }, [file])

  useEffect(() => {
    if (fileType === 'image') setCustomDownloadBtn(true)
    else setCustomDownloadBtn(false)
  }, [fileType])

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closePreview()
    }
  }

  const closePreview = () => {
    // setDownloading(false)
    dispatch(handleFilePreview({ open: false, file }))
  }

  const handleDownloadFile = () => {
    // setDownloading(true)
    const link = document.createElement('a')
    link.setAttribute('download', '')
    link.href = file
    document.body.appendChild(link)
    link.click()
    link.remove()

    // downloadFile(file, (err) => {
    //   setDownloading(false)
    //   if (err) {
    //     dispatch(
    //       fireErrorToaster(
    //         'Some error occurred while downloading file! please try again later'
    //       )
    //     )
    //   }
    // })
  }

  if (!open) return null

  const hideLoadingText = (e) => {
    e.target.style.backgroundImage = 'none'
  }

  return (
    // using high z-index because we need to hide the intercom chat icon
    <div
      className="fixed inset-0 flex justify-center items-center"
      style={{ background: 'rgba(0, 0, 0, 0.75)', zIndex: 2000 }}
      onClick={handleOverlayClick}
    >
      <div className="absolute top-4 right-4 flex items-center">
        {customDownloadBtn && (
          <button onClick={handleDownloadFile} className="text-white mr-4">
            {/* {downloading ? (
            <CircularProgress size={20} color="#ffffff" />
          ) : (
            )} */}
            <DownloadIcon className="w-6 h-6" />
          </button>
        )}
        <button onClick={closePreview} className="text-white">
          <CloseIcon style={{ width: '1.5rem', height: '1.5rem' }} />
        </button>
      </div>
      <div
        onClick={handleOverlayClick}
        className="flex justify-center items-center"
        style={{
          width: '90%',
          height: '90%',
          maxWidth: '1200px',
          maxHeight: '500px'
        }}
      >
        {fileType === 'image' ? (
          <img
            src={file}
            alt=""
            className="object-contain"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        ) : fileType === 'audio' ? (
          <audio controls>
            {console.log('FILE', file)}
            <source src={file} />
            Your browser does not support this audio format.
          </audio>
        ) : fileType === 'video' ? (
          <video width="320" height="240" controls>
            <source src={file} />
            Your browser does not support this video format.
          </video>
        ) : (
          <iframe
            src={file}
            // src={`https://docs.google.com/gview?url=${file}&embedded=true`}
            title="attachment"
            style={{
              width: '90%',
              maxWidth: 500,
              height: 400,
              backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='10' y='25' fill='black' font-size='14'>Loading...</text></svg>")`,
              backgroundRepeat: 'no-repeat'
            }}
            className="bg-white rounded"
            // allowFullScreen={true}
            // webkitallowfullscreen={true}
            // mozallowfullscreen={true}
            onLoad={hideLoadingText}
          ></iframe>
        )}
      </div>
    </div>
  )
}

export default FilePreview
