import axios from 'axios'
import { CLIENT } from 'thunks/addClient/reducers'
import { errorMessages, getAuthToken } from 'utils'

/**
 * Add a Stripe plan for client
 * @param {object} data user,plan,url
 * @param {Function} callback callback function
 */

export const fetchClientStripeChekoutSession = async (data, callback) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/clientpayment/checkout',
      data,
      headers: {
        Authorization: getAuthToken()
      }
    })
    callback(res.data, false)
    // return res.data
  } catch (err) {
    callback(err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE, true)
  }
}

/**
 * fetch all plans for client
 * @param {Function} callback callback function
 */

export const fetchPlans = async (callback) => {
  const res = await axios({
    method: 'GET',
    url: '/service/domain',
    headers: {
      Authorization: getAuthToken()
      // origin: 'https://paid.clientvenue.com'
    }
  })
  callback(res.data, false)
  return res.data
}

/**
 * Add a Non Stripe plan for client
 * @param {object} data user,plan
 * @param {Function} callback callback function
 */

export const fetchClientStripeNonStripeChekoutSession = async (
  data,
  callback
) => {
  const res = await axios({
    method: 'POST',
    url: '/clientpayment/checkout/agency',
    data,
    headers: {
      Authorization: getAuthToken()
    }
  })
  callback(res.data, false)
  return res.data
}

export const assignServiceToClient = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/clientpayment/checkout/agency',
        data
      })
      dispatch({ type: CLIENT.ASSIGN_SERVICE_TO_CLIENT, payload: res.data })
      if (callback) callback(res, false)
    } catch (err) {
      if (callback)
        callback(err?.response?.data?.message ?? 'Unsuccessful', true)
    }
  }
}

/**
 * it will cancel the service (for client side)
 * @param {String} id service payment id
 * @param {Function} callback  callback function
 */

export const cancelClientService = (paymentId, data, callback) => async (
  dispatch
) => {
  try {
    const res = await axios({
      url: `/clientpayment/subscription/cancelrequest/${paymentId}`,
      method: 'PUT',
      data
    })
    if (callback) callback(res.data, false)
  } catch (err) {
    if (callback)
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
  }
}
