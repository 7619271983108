import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: 'none',
    '&:focus-within': {
      borderColor: '#1600E5',
      boxShadow: `0 0 0 1px #1600E5`
    },
    backgroundColor: state.isDisabled ? '#fff' : '#fff'
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  dropdownIndicator: (base, state) => ({ ...base, display: 'none' })
}

const SelectCell = (props) => {
  const {
    value,
    row: { index, values, original },
    column: { id, metaData },
    updateDynamicColData,
    taskPermission
  } = props
  const [options, setOptions] = useState(metaData?.options ?? {})
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    if (value) setSelectedValue(value)
  }, [value])

  const handleSelectOption = (newVal) => {
    setSelectedValue(newVal)
    updateDynamicColData({
      rowIndex: index,
      columnId: id,
      value: newVal,
      allRowValues: values,
      taskId: original.taskId
    })
  }

  return (
    <div style={{ minWidth: 200 }}>
      <Select
        options={options}
        value={selectedValue}
        className="w-full"
        styles={{ ...reactSelectCustomStyles, ...selectStyles }}
        onChange={handleSelectOption}
        isOpen={true}
        isMulti={metaData?.isMultiSelect}
        placeholder=""
        isDisabled={!taskPermission.update}
      />
    </div>
  )
}

export default SelectCell
