import React, { useState } from 'react'
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined'
import { Draggable } from 'react-beautiful-dnd'

const StatusStrip = ({
  _id,
  label,
  color,
  index,
  openPopper,
  category = 'active',
  updateCategoryStatus
}) => {
  const [edit, setEdit] = useState(false)

  const handleOpenColorPopper = e => {
    openPopper({
      elm: e.currentTarget,
      type: 'color',
      data: { statusId: _id, categoryId: category }
    })
  }

  const toggleEditState = () => {
    setEdit(prev => !prev)
  }

  const updateStatusLabel = e => {
    console.log('EEEEEE', e)
    const value = e.target.value.toLowerCase().trim()

    if (value !== '' && value !== label) {
      updateCategoryStatus({
        categoryId: category,
        statusId: _id,
        prop: 'label',
        value
      })
    }

    toggleEditState()
  }

  return (
    <Draggable draggableId={_id.toString()} index={index} key={_id}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <div className="flex items-center text-xs uppercase border border-gray-300 rounded p-1 bg-white">
            {edit ? (
              <input
                defaultValue={label}
                onBlur={updateStatusLabel}
                autoFocus
                className="flex-1 text-sm"
              />
            ) : (
              <>
                <button
                  {...provided.dragHandleProps}
                  className="cursor-move text-gray-300"
                >
                  <DragIndicatorOutlinedIcon fontSize="small" />
                </button>
                <button
                  onClick={handleOpenColorPopper}
                  className="ml-2 leading-3"
                >
                  <span
                    className="inline-block w-3 h-3 rounded-sm"
                    style={{ backgroundColor: color }}
                  ></span>
                </button>
                <span
                  className="mr-auto ml-2 flex-1 cursor-pointer"
                  style={{ color }}
                  onClick={toggleEditState}
                >
                  {label}
                </span>
                {/* <button className="text-gray-400">
                  <HorizontalThreeDots className="w-4 h-4" />
                </button> */}
              </>
            )}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default StatusStrip
