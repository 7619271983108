import { useEffect, useState } from 'react'
import { TextField, Button, SvgIcon } from '@material-ui/core'
import { ReactComponent as QuestionCircleIcon } from 'static/svg/question-circle.svg'
import clsx from 'clsx'
import { LoadingButton } from 'global/globalComponents'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { ToggleSwitch } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { errorMessages } from 'utils'

/**
 * Setting up SMTP
 * @param {object} data service,host,port,crypto,user,email,password
 * @param {String} axios cancel token
 * @param {Function} callback function
 */
const submitSMTPForm = async (data, cancelToken, callback) => {
  try {
    const res = await axios(
      {
        method: 'POST',
        url: `/agencyprofile/email-settings`,
        data
      },
      { cancelToken }
    )
    callback(res)
  } catch (err) {
    if (!axios.isCancel(err)) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

/**
 * GET SMTP Setting
 * @param {String} axios cancel token
 * @param {Function} callback function
 */
const getSMTPSettings = async (cancelToken, callback) => {
  try {
    const res = await axios(
      {
        method: 'GET',
        url: `/agencyprofile/email-settings`
      },
      { cancelToken }
    )
    callback(res.data)
  } catch (err) {
    if (!axios.isCancel(err)) {
      callback(
        err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
        true
      )
    }
  }
}

const SMTPServices = [
  {
    label: 'Gmail',
    value: 'gmail',
    host: 'smtp.gmail.com',
    port: 465,
    encription: 'SSL'
  },
  {
    label: 'Hotmail/Outlook',
    value: 'hotmailoutlook',
    host: 'smtp.office365.com',
    port: 587,
    encription: 'TLS'
  },
  {
    label: 'SendInBlue',
    value: 'sendinblue',
    host: 'smtp-relay.sendinblue.com',
    port: 587,
    encription: 'TLS'
  },
  {
    label: 'SendGrid',
    value: 'sendgrid',
    host: 'smtp.sendgrid.net',
    port: 465,
    encription: 'SSL'
  },
  {
    label: 'MailGun',
    value: 'mailgun',
    host: 'smtp.mailgun.org',
    port: 587,
    encription: 'TLS'
  },
  {
    label: 'Other',
    value: 'other',
    host: '',
    port: '',
    encription: ''
  }
]

const cryptoList = [
  { label: 'SSL', value: 'ssl' },
  { label: 'TLS', value: 'tls' },
  { label: 'None', value: 'none' }
]

const EmailSetting = () => {
  const dispatch = useDispatch()
  const me = useSelector(state => state.me.data)
  const [sendEmailCheck, setSendEmailCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [submitSMTPReqCount, setSubmitSMTPReqCount] = useState(0)
  const cancelToken = axios.CancelToken.source()
  const [selectedService, setSelectedService] = useState({})
  const [selectedCrypto, setSelectedCrypto] = useState({})
  const [responseData, setResponseData] = useState(null)

  useEffect(() => {
    getSMTPSettings(cancelToken, getSMTPSettingsCallback)
    return () => cancelToken.cancel()
  }, [])

  const getSMTPSettingsCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setResponseData(res.email_settings)
    }
  }

  useEffect(() => {
    if (responseData) {
      setFields(responseData)
    }
  }, [responseData])

  const setFields = data => {
    const {
      smtp_email = '',
      smtp_user = '',
      smtp_pass = '',
      active = false
    } = data

    const service =
      SMTPServices.find(item => item.value === data.service) || SMTPServices[0]
    const crypto =
      cryptoList.find(item => item.value === data.smtp_crypto) ||
      cryptoList.find(
        item => item.value === SMTPServices[0].encription.toLowerCase()
      )

    setFormData({
      smtp_email: smtp_email.trim(),
      smtp_user: smtp_user.trim(),
      smtp_pass: smtp_pass.trim()
    })
    setSelectedService(service)
    setSelectedCrypto(crypto)
    setSendEmailCheck(active)
  }

  const checkedHandler = e => {
    const Plans = [
      'Plan-C',
      'Plan-D',
      'Plan-E',
      'Enterprise Plan',
      'Enterprise Monthly Plan',
      'Enterprise Yearly Plan',
      'AppSumo Enterprise Plan 1',
      'AppSumo Enterprise Plan 2',
      'AppSumo Enterprise Plan 3',
      'AppSumo Enterprise Plan 4',
      'AppSumo Enterprise Plan 5',
      'AppSumo Enterprise Plan 6',
      'AppSumo Enterprise Plan 7',
      'AppSumo Enterprise Plan 8'
    ]
    const checkPlan = Plans.find(
      plan => plan === (me?.payment?.name ?? me?.team?.payment?.name)
    )
    if (sendEmailCheck) {
      setSendEmailCheck(false)
      dispatch(fireSuccessToaster('Disable Successfully'))
    } else if (!sendEmailCheck && checkPlan) {
      setFormData({
        smtp_email: '',
        smtp_user: '',
        smtp_pass: ''
      })
      setSendEmailCheck(true)
      dispatch(fireSuccessToaster('Enabled Successfully'))
    } else {
      dispatch(
        fireErrorToaster('please upgrade your plan to activate this feature')
      )
    }
  }

  const handleServiceChange = e => {
    setSelectedService(e)

    const crypto = cryptoList.find(
      item => item.value === e.encription.toLowerCase()
    )
    setSelectedCrypto(crypto || { label: 'None', value: 'none' })
  }

  const handleServiceSubField = e => {
    if (selectedService.value === 'other') {
      setSelectedService(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }))
    }
  }

  const handleEncryptionChange = e => {
    setSelectedCrypto(e)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const formSubmitHandler = async e => {
    e.preventDefault()

    if (sendEmailCheck) {
      // 1) Checking if email is valid
      if (!/\S+@\S+\.\S+/.test(formData.smtp_email)) {
        dispatch(fireErrorToaster('Invalid email address!'))
        return
      }

      // 2) Checking if all required fields are filled
      if (
        !selectedService.host ||
        !selectedService.port ||
        !formData.smtp_email ||
        !formData.smtp_user ||
        !formData.smtp_pass
      ) {
        dispatch(fireErrorToaster('Please fill all the required fields!'))
        return
      }
    }

    setLoading(true)
    setSubmitSMTPReqCount(prev => prev + 1)
  }

  const submitSMTPFormCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
    }
  }

  useEffect(() => {
    if (submitSMTPReqCount) {
      submitSMTPForm(
        {
          email_settings: {
            smtp_email: formData.smtp_email || ' ',
            smtp_user: formData.smtp_user || ' ',
            smtp_pass: formData.smtp_pass || ' ',
            service: sendEmailCheck ? selectedService.value : ' ',
            smtp_host: sendEmailCheck ? selectedService.host : ' ',
            smtp_port: sendEmailCheck ? selectedService.port : ' ',
            smtp_crypto: sendEmailCheck ? selectedCrypto.value : ' ',
            active: sendEmailCheck
          }
        },
        cancelToken,
        submitSMTPFormCallback
      )
    }

    return () => cancelToken.cancel()
  }, [submitSMTPReqCount])

  const discardHandler = () => {
    setFields(responseData)
  }

  return (
    <div>
      <div className="flex items-center">
        <div>
          <ToggleSwitch checked={sendEmailCheck} onChange={checkedHandler} />
        </div>
        <p className="pl-4 pr-1 text-primary-dark text-sm md:text-base lg:text-xl font-medium">
          Email Whitelabel
        </p>
        <a
          href="https://help.clientvenue.com/en/articles/5638807-how-to-white-label-email"
          target="_blank"
          className=""
        >
          <SvgIcon
            component={QuestionCircleIcon}
            style={{
              color: '#1600e5',
              fontSize: '1rem',
              marginBottom: '2px'
            }}
            className="cursor-pointer"
            viewBox="0 0 600 476.6"
          />
        </a>
      </div>
      {sendEmailCheck && (
        <form
          autoComplete="off"
          className="w-full mt-4 bg-white rounded text-smaller lg:text-sm"
        >
          <div className="flex space-x-4 mt-4">
            <div className="flex-grow w-full">
              <FormLabel required>Service</FormLabel>
              <Select
                value={selectedService}
                options={SMTPServices}
                onChange={handleServiceChange}
                styles={reactSelectCustomStyles}
              />
            </div>
            <div className="flex-grow w-full">
              <FormLabel htmlFor="host" required>
                Host
              </FormLabel>
              <TextField
                required
                id="host"
                className="w-full"
                name="host"
                onChange={handleServiceSubField}
                value={selectedService.host}
              />
            </div>
          </div>
          <div className="mt-4 flex space-x-4">
            <div className="flex-grow w-full">
              <FormLabel htmlFor="port" required>
                Port
              </FormLabel>
              <TextField
                type="number"
                required
                id="port"
                className="w-full"
                name="port"
                onChange={handleServiceSubField}
                value={selectedService.port}
              />
            </div>

            <div className="flex-grow w-full">
              <FormLabel required>Encryption</FormLabel>
              <Select
                value={selectedCrypto}
                options={cryptoList}
                styles={reactSelectCustomStyles}
                onChange={handleEncryptionChange}
              />
            </div>
          </div>

          <div className="mt-4 flex space-x-4">
            <div className="flex-grow w-full">
              <FormLabel htmlFor="email" required>
                From Email Address
              </FormLabel>
              <TextField
                required
                type="email"
                name="smtp_email"
                className="w-full"
                onChange={handleChange}
                value={formData.smtp_email}
              />
            </div>
            <div className="flex-grow w-full">
              <FormLabel htmlFor="username" required>
                Username
              </FormLabel>
              <TextField
                required
                name="smtp_user"
                className="w-full"
                onChange={handleChange}
                value={formData.smtp_user}
              />
            </div>
          </div>
          <div className="mt-4 w-full md:w-1/2 md:pr-2">
            <FormLabel htmlFor="password" required>
              Password (Enter your App password only)
            </FormLabel>
            <TextField
              autoComplete="new-password"
              required
              type="password"
              name="smtp_pass"
              className="w-full"
              onChange={handleChange}
              value={formData.smtp_pass}
            />
          </div>
        </form>
      )}

      <div className="mt-8 flex justify-end gap-2">
        <Button variant="outlined" onClick={discardHandler}>
          Discard Changes
        </Button>
        <LoadingButton loading={loading} onClick={formSubmitHandler}>
          Save
        </LoadingButton>
      </div>
    </div>
  )
}

export default EmailSetting
