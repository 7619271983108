import React, { useState, useEffect } from 'react'
import { ColoredAvatars, GoBack, Layout } from 'global/globalComponents'
import { useSelector, useDispatch } from 'react-redux'
import DayWiseStandupsTable from './DayWiseStandupsTable'
import StandupsFilter from './StandupsFilter'
import moment from 'moment'
import {
  fetchStandupsReport,
  fetchStandupsReportByUserId
} from 'thunks/reports/actions'
import { useParams } from 'react-router-dom'
import { dateAndTimeHelper, userRoles } from 'utils'
import useReportsFunctions from '../useReportsFunctions'
import Currency from 'static/Currency/Currency'

const SingleUserStandups = ({ showBackButton }) => {
  const [user, setUser] = useState({})
  const meData = useSelector(state => state.me.data)
  const dispatch = useDispatch()
  const [standupsData, setStandupsData] = useState({
    loading: true,
    data: []
  })
  const permission = useSelector(state => state.permission.data)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const {
    loading: standupsSummaryLoading,
    fetched: standupsSummaryFetched,
    data: standupsSummary
  } = useSelector(state => state.reports.standupsSummary)
  const { userId } = useParams()
  const [downloadData, setDownloadData] = useState([])
  const {
    getTrackedTime,
    getCostByTrackedTime,
    getTimelineQuery
  } = useReportsFunctions()

  useEffect(() => {
    if ([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)) {
      const query = getTimelineQuery('last7Days')
      dispatch(fetchStandupsReport(query))
    }
  }, [standupsSummaryFetched])

  useEffect(() => {
    const startDate = moment(selectedDate)
      .startOf('week')
      .set('hours', 0)
      .set('minutes', 0)
      .set('seconds', 0)
      .toISOString()
    const endDate = moment(selectedDate)
      .endOf('week')
      .set('hours', 23)
      .set('minutes', 59)
      .set('seconds', 59)
      .toISOString()

    setStandupsData(prev => ({ ...prev, loading: true }))

    // dispatch(
    //   fetchStandupsReport(`startDate=${startDate}&endDate=${endDate}`, () => {
    //     setStandupsData((prev) => ({ ...prev, loading: false }))
    //   })
    // )
    dispatch(
      fetchStandupsReportByUserId(
        userId,
        `startDate=${startDate}&endDate=${endDate}`,
        (res, err) => {
          const dataArr = []
          const weekDays = dateAndTimeHelper.getWeekDays(selectedDate)
          for (let day of weekDays) {
            const dayObj = moment(day)
            const obj = {
              day: dayObj.format('dddd'),
              date: day,
              standups:
                res
                  .filter(
                    item =>
                      dayObj.format('l') === moment(item.createdAt).format('l')
                  )
                  .map(item => item.standups)[0] ?? []
            }
            dataArr.push(obj)
          }
          setStandupsData({ loading: false, data: dataArr })
          // setStandupsData((prev) => ({ ...prev, loading: false }))
        }
      )
    )
  }, [selectedDate, userId])

  useEffect(() => {
    if (
      !(
        permission?.access?.report.view ||
        permission?.access?.report.create ||
        permission?.access?.report.update ||
        permission?.access?.report.delete
      ) ||
      ![userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
    ) {
      setUser({
        name: meData.name,
        email: meData.email,
        profileImage: meData.profileImage,
        profile: {
          ...meData.profile,
          workingDays: meData.team?.profile?.workingDays ?? {
            mon: true,
            tue: true,
            wed: true,
            thu: true,
            fri: true,
            sat: true,
            sun: false,
            count: 6
          },
          capacity: meData.team?.profile?.capacity ?? 48,
          defaultCurrency:
            meData.team?.profile?.defaultCurrency?.toUpperCase() ?? 'USD'
        }
      })
    }
  }, [permission, meData.role])

  useEffect(() => {
    if ([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)) {
      const userSummary = standupsSummary.find(item => item._id === userId)

      if (userSummary) {
        setUser({
          name: userSummary.name,
          email: userSummary.email,
          profileImage: userSummary.profileImage,
          profile: userSummary.profile
        })
      }
    }
  }, [standupsSummary, userId])

  const getCompletion = standups => {
    const completed = standups.filter(item => item.isComplete)
    return ((completed.length / standups.length) * 100).toFixed(1)
  }

  const handleDownloadData = () => {
    if (!user.profile) return
    const formattedData = []

    for (let { date, day, standups } of standupsData.data) {
      if (standups.length) {
        for (let i = 0; i < standups.length; i++) {
          const cost = getCostByTrackedTime({
            trackedTimeInMs: getTrackedTime(
              standups[i].task.timeTracking,
              user.profile.user
            ),
            hourlyRate: user.profile.hourlyRate
          })

          const trackedTime = getTrackedTime(
            standups[i].task.timeTracking,
            user.profile.user
          )

          formattedData.push({
            Days: i === 0 ? day : '',
            Date: i === 0 ? moment(date).format('MMM Do') : '',
            Task: standups[i].name,
            'Time Estimate': standups[i].task.estimateTimeInString,
            'Tracked Time': trackedTime
              ? dateAndTimeHelper.formatMilliseconds(trackedTime)
              : '',
            Cost: cost
              ? Currency[user.profile?.defaultCurrency ?? 'USD']
                  ?.symbol_native + cost
              : '',
            Status: standups[i].isComplete ? 'completed' : 'incomplete',
            Completion: i === 0 ? `${getCompletion(standups)}%` : ''
          })
        }
      } else {
        formattedData.push({
          Days: day,
          Date: moment(date).format('MMM Do'),
          Task: '',
          'Time Estimate': '',
          'Tracked Time': '',
          Cost: '',
          Status: '',
          Completion: ''
        })
      }
    }

    setDownloadData(formattedData)
  }

  const onDateChange = date => {
    const formattedDate = moment(date).startOf('week').toDate()
    setSelectedDate(formattedDate)

    const data = []

    for (let i = 0; i < 7; i++) {
      const obj = {
        day: moment(formattedDate).add(i, 'days').format('dddd'),
        date: moment(formattedDate).add(i, 'days').toDate()
      }
      data.push(obj)
    }
  }

  return (
    <Layout>
      <div>
        <header>
          {showBackButton && <GoBack className="mb-2" />}
          <div className="flex items-center">
            <div>
              <ColoredAvatars user={user} tooltip={false} />
            </div>
            <div className="ml-3 leading-3">
              <h2 className="text-base lg:text-lg font-medium mb-0">
                {user.name}
              </h2>
              <span className="text-gray-500 text-xs">{user.email}</span>
            </div>
          </div>
        </header>

        <div className="mt-8">
          <StandupsFilter
            selectedDate={selectedDate}
            onDateChange={onDateChange}
            handleDownloadData={handleDownloadData}
            downloadData={downloadData}
            userName={user.name}
          />

          <DayWiseStandupsTable user={user} standupsData={standupsData} />
        </div>
      </div>
    </Layout>
  )
}

export default SingleUserStandups
