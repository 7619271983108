import { useState, useEffect, useMemo } from 'react'
import { userRoles } from 'utils'
import UsersTimesheets from './UsersTimesheets'
import MyTimesheets from './MyTimesheets'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchUserTimesheets,
  fetchAllUsersTimesheets
} from 'thunks/timesheets/action'
import moment from 'moment'
import TimesheetHead from './TimesheetHead'
import { useParams } from 'react-router-dom'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import getTimeString from './TimesheetFunctions'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})

function getWeekDays(weekStart) {
  const days = []
  for (let i = 0; i < 7; i++) {
    days.push(moment(weekStart).add(i, 'days').toDate().toDateString())
  }
  return days
}

const Timesheets = () => {
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const userWorkspaces = useSelector((state) => state.userWorkspaces.data)
  const [loading, setLoading] = useState(false)
  const [timesheetData, setTimesheetData] = useState({ user: {}, data: [] })
  const [selectedDays, setSelectedDays] = useState(getWeekDays(new Date()))
  const { time: selectedDate, userId } = useParams()
  const [totalTimeTracked, setTotalTimeTracked] = useState()
  const [totalUsers, setTotalUsers] = useState(0)
  const [downloadData, setDownloadData] = useState({
    fileName: 'UsersTimesheet',
    data: []
  })

  const startTime = useMemo(() => {
    return moment(selectedDate).startOf('week').toISOString()
  }, [selectedDate])

  const endTime = useMemo(() => {
    return moment(selectedDate).endOf('week').toISOString()
  }, [selectedDate])

  useEffect(() => {
    if (!me.loading && !window.location.href.split(selectedDate)[1]) {
      fetchWeeklyData()
    }
  }, [me, window.location.href])

  useEffect(() => {
    setSelectedDays(getWeekDays(moment(selectedDate).startOf('week').toDate()))
  }, [selectedDate])

  const fetchWeeklyData = () => {
    const startDate = moment(selectedDate).startOf('week').toISOString()
    const endDate = moment(selectedDate).endOf('week').toISOString()
    setLoading(true)
    if (
      [userRoles.AGENCY_ADMIN, userRoles.USER_AGENCY].includes(me.data.role)
    ) {
      const agencyOwnerId =
        me.data.role === userRoles.USER_AGENCY ? me.data._id : me.data.team._id
      dispatch(
        fetchAllUsersTimesheets(
          agencyOwnerId,
          startDate,
          endDate,
          (res, err) => {
            setLoading(false)
            if (!err) {
              let totalTracked = []

              res
                .filter((item) => item.user)
                .forEach((el) => {
                  totalTracked = [...totalTracked, ...el.data]
                })

              // console.log(
              //   res,
              //   'responseeeeee',
              //   new Date(startTime).getTime(),
              //   new Date(endTime).getTime(),
              //   totalTracked
              //     .filter(
              //       (time) =>
              //         time.start >= new Date(startTime).getTime() &&
              //         time.start <= new Date(endTime).getTime()
              //     )
              //     .reduce((acc, obj) => acc + obj.duration, 0) / 1000
              // )

              setTotalTimeTracked(
                getTimeString(
                  totalTracked
                    .filter(
                      (time) =>
                        time.start >= new Date(startTime).getTime() &&
                        time.start <= new Date(endTime).getTime()
                    )
                    .reduce((acc, obj) => acc + obj.duration, 0) / 1000
                )
              )
              setTotalUsers(res.filter((item) => item.user).length)
              setTimesheetData({
                user: {},
                data: res.filter((item) => item.user)
              })
            }
          }
        )
      )
    } else {
      dispatch(
        fetchUserTimesheets(startDate, endDate, (res, err) => {
          setLoading(false)
          if (!err) {
            const workspaces = res.map((el) => el._id)
            const newWs = userWorkspaces
              .filter((ws) => !workspaces.includes(ws._id))
              .map((ws) => ({
                _id: ws._id,
                defaultBoard: ws.defaultBoard._id,
                name: ws.name,
                tasks: []
              }))

            let totalTracked = []
            res.forEach((ws) => {
              ws.tasks.forEach((task) => {
                totalTracked = [...totalTracked, ...task.data]
              })
            })

            setTotalTimeTracked(
              getTimeString(
                totalTracked
                  .filter(
                    (time) =>
                      time.start >= new Date(startTime).getTime() &&
                      time.start <= new Date(endTime).getTime()
                  )
                  .reduce((acc, obj) => acc + obj.duration, 0) / 1000
              )
            )
            setTimesheetData({ user: {}, data: [...res, ...newWs] })
            setTotalUsers(1)
            // setTimesheetData(res)
          }
        })
      )
    }
  }

  // const addNewTimesheetData = (wsId, taskId, newData) => {
  //   setTimesheetData((prev) =>
  //     prev.map((entry) => {
  //       if (entry._id === wsId) {
  //         let newTasksData = [...entry.tasks]
  //         const taskObj = entry.tasks.find((task) => task._id === taskId)
  //         if (taskObj) {
  //           newTasksData = entry.tasks.map((task) => {
  //             if (task._id === taskId) {
  //               return { ...task, data: [...task.data, newData] }
  //             } else {
  //               return task
  //             }
  //           })
  //         } else {
  //           newTasksData = [
  //             ...entry.tasks,
  //             {
  //               _id: newData.task?._id,
  //               title: newData.task?.title,
  //               data: [newData]
  //             }
  //           ]
  //         }

  //         return { ...entry, tasks: newTasksData }
  //       }
  //       return entry
  //     })
  //   )
  // }

  return (
    <Wrapper>
      <TimesheetHead
        totalTimeTracked={totalTimeTracked}
        totalUsers={totalUsers}
        elmFor={
          [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(me.data.role)
            ? 'users'
            : 'selectedUser'
        }
        downloadData={downloadData}
      />

      {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
        me.data.role
      ) ? (
        <UsersTimesheets
          loading={loading}
          timesheetData={timesheetData.data}
          selectedDays={selectedDays}
          startTime={moment(selectedDate).startOf('week').toISOString()}
          endTime={moment(selectedDate).endOf('week').toISOString()}
          setDownloadData={setDownloadData}
        />
      ) : (
        <MyTimesheets
          loading={loading}
          timesheetData={timesheetData.data}
          setTimesheetData={setTimesheetData}
          selectedDays={selectedDays}
          startTime={startTime}
          endTime={endTime}
          // addNewTimesheetData={addNewTimesheetData}
          userId={me.data._id}
          setDownloadData={setDownloadData}
        />
      )}
    </Wrapper>
  )
}

export default Timesheets
