import Card from 'components/Workspace/ProjectStats/Card'
import clsx from 'clsx'
import {
  TableDataLoading,
  Td,
  Th,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Currency from 'static/Currency/Currency'
import financialReportStyles from './financialReportStyles'

const ClientRevenueCard = ({ clientwiseRevenue, currency, loading }) => {
  const classes = financialReportStyles()
  return (
    <Card className="h-full w-full">
      <h1 className="text-sm font-semibold text-primary-dark mb-4">
        Client Wise Revenue
      </h1>
      <div
        className={clsx(
          `align-middle min-w-full overflow-auto sm:rounded-xl`,
          classes.clientWiseRevenue
        )}
      >
        <table className="min-w-full divide-y divide-gray-200 border-collapse border-hidden">
          <thead className="bg-white h-14 sticky top-0" style={{ zIndex: 99 }}>
            <Th className="text-left text-gray-900">Client Name</Th>
            <Th center>Total Payments</Th>
          </thead>
          <tbody>
            {loading ? (
              <TableDataLoading cols={2} />
            ) : (
              clientwiseRevenue.map(client => (
                <Tr className="w-full p-2 text-gray-700 text-xs lg:text-smaller rounded cursor-pointer hover:bg-gray-100">
                  <Td className="text-gray-900">{client.name}</Td>
                  <Td center>
                    {client.totalPayments > 0
                      ? Currency[currency].symbol_native
                      : ''}
                    {client.totalPayments ?? 0}
                  </Td>
                </Tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </Card>
  )
}

const Row = ({ children, className }) => {
  return (
    <div className={`flex items-center space-x-8 text-xs ${className}`}>
      {children}
    </div>
  )
}

export default ClientRevenueCard
