import { useEffect, useState, useCallback } from 'react'
import { Box, Button, Badge } from '@material-ui/core'
import { useSelector } from 'react-redux'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import Pagination from 'global/globalComponents/Pagination/Pagination'
import { useHistory, useRouteMatch } from 'react-router-dom'
import InvoiceTable from './InvoiceTable'
import { CustomTabs, OutlinedButton } from 'global/globalComponents'
import { ReactComponent as FilterIcon } from 'static/svg/filter.svg'
import clsx from 'clsx'
import PopupMenu from 'global/globalComponents/PopupMenu/PopupMenu'

const totalInvoicesToShow = 8
export default function CreateInvoices({
  invoices,
  handleToggleInvoiceModal,
  openMarkAsPaidAlert,
  openDeleteAlert
}) {
  const history = useHistory()
  const { url } = useRouteMatch()
  const search = useSelector(state => state.generalAppState.headerSearch)
  const access = useSelector(state => state.permission.data?.access)

  // const [visibleInvoicesData, setVisibleInvoiceData] = useState({
  //   all: [],
  //   paid: [],
  //   pending: [],
  //   draft: [],
  //   overdue: []
  // })

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedTabKey, setSelectedTabKey] = useState('all')
  const [page, setPage] = useState(0)
  const [filter, setFilter] = useState(null)
  const [invoiceData, setInvoiceData] = useState({
    all: [],
    paid: [],
    pending: [],
    draft: [],
    overdue: []
  })

  const handleTabChange = useCallback((newVal, key) => {
    setSelectedTab(newVal)
    setSelectedTabKey(key)
    setPage(0)
  }, [])

  useEffect(() => {
    const invoiceGroup = {
      paid: [],
      pending: [],
      draft: [],
      overdue: []
    }
    let totalInvoices = [...invoices]
    if (search.trim().length) {
      totalInvoices = totalInvoices.filter(item =>
        item.client.clientName
          ? item.client.clientName
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.client.clientEmail.toLowerCase().includes(search.toLowerCase())
          : false
      )
    }
    if (filter) {
      const newDate = new Date().getTime() - filter * 24 * 3600 * 1000
      totalInvoices = totalInvoices.filter(
        item =>
          new Date(item.createdAt).getTime() >
          new Date(newDate).setHours(0, 0, 0, 0)
      )
    }

    for (let invoice of totalInvoices) {
      if (invoiceGroup[invoice.status]) {
        invoiceGroup[invoice.status].push(invoice)
      }
    }

    setInvoiceData({ all: totalInvoices, ...invoiceGroup })
  }, [invoices, search, filter])

  // useEffect(() => {
  //   if (invoices.length) {
  //     const allInvoices = [...invoiceData[selectedTabKey]]
  //     const sliced = allInvoices.splice(
  //       page * totalInvoicesToShow,
  //       totalInvoicesToShow
  //     )

  //     setVisibleInvoiceData((prevState) => ({
  //       ...prevState,
  //       [selectedTabKey]: sliced
  //     }))
  //   }
  // }, [invoiceData, page, selectedTabKey])

  // const handlePagination = (val) => {
  //   if (selectedTabKey === 'all') {
  //     setPageData((prevState) => ({ ...prevState, all: val }))
  //   } else {
  //     setPageData((prevState) => ({
  //       ...prevState,
  //       [selectedTabKey]: val
  //     }))
  //   }
  // }

  const handleCreateInvoice = () => {
    handleToggleInvoiceModal('stripe')
  }

  const openInvoicePreview = invoice => {
    history.push(`${url.split('/all')[0]}/i/${invoice._id}`)
  }

  const handleEditInvoice = invoice => {
    history.push(`${url.split('/all')[0]}/i/${invoice._id}/edit`)
  }

  return (
    <>
      <Box className=" flex flex-col h-full">
        <>
          <Box>
            <div className="flex items-center justify-end mb-6">
              {/* <SectionHeading>Invoices</SectionHeading> */}
              <div className="flex space-x-4">
                <PopupMenu
                  targetComp={
                    <Badge
                      color="primary"
                      variant="dot"
                      badgeContent={filter ? 1 : 0}
                      // classes={{ badge: classes.badge }}
                    >
                      <OutlinedButton isActive={Boolean(filter)}>
                        <FilterIcon />
                        <div className="flex">
                          <span className="ml-1">Filter</span>
                          <span className="">
                            {filter ? `: Last ${filter} days` : null}
                          </span>
                        </div>
                      </OutlinedButton>
                      {/* <button
                        className={clsx(
                          'text-sm text-custom-gray-light-6 font-medium flex items-center px-2 py-1 rounded-sm text-primary-main hover:bg-custom-blue-light',
                          filter && 'bg-custom-blue-light'
                        )}
                      >
                        <FilterIcon />
                        <div className="flex">
                          <span className="ml-1">Filter</span>
                          <span className="">
                            {filter ? `: Last ${filter} days` : null}
                          </span>
                        </div>
                      </button> */}
                    </Badge>
                  }
                  menuItems={[
                    {
                      label: '7 days',
                      method: () => setFilter(7)
                    },
                    {
                      label: '14 days',
                      method: () => setFilter(14)
                    },
                    {
                      label: '28 days',
                      method: () => setFilter(28)
                    },
                    {
                      label: 'Clear',
                      method: () => setFilter(null)
                    }
                  ]}
                />

                {access?.billings.create && (
                  <Button
                    variant="outlined"
                    onClick={handleCreateInvoice}
                    startIcon={<Add fontSize="small" />}
                  >
                    Create Invoice
                  </Button>
                )}
              </div>
            </div>

            <div className="mb-6">
              <CustomTabs
                tabsData={[
                  { label: 'All', value: 0, number: invoiceData['all'].length },
                  {
                    label: 'Draft',
                    value: 1,
                    number: invoiceData['draft'].length
                  },
                  {
                    label: 'Pending',
                    value: 2,
                    number: invoiceData['pending'].length
                  },
                  {
                    label: 'Paid',
                    value: 3,
                    number: invoiceData['paid'].length
                  },
                  {
                    label: 'Overdue',
                    value: 4,
                    number: invoiceData['overdue'].length
                  }
                ]}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
          </Box>
          <InvoiceTable
            invoices={invoiceData[selectedTabKey]}
            openInvoicePreview={openInvoicePreview}
            handleEditInvoice={handleEditInvoice}
            openMarkAsPaidAlert={openMarkAsPaidAlert}
            openDeleteAlert={openDeleteAlert}
            clientCol={true}
          />
        </>
      </Box>
    </>
  )
}
