import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef
} from 'react'
import { Button, Popover, TextField } from '@material-ui/core'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { CustomPopper, LoadingButton } from 'global/globalComponents'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import { ReactComponent as TextIcon } from 'static/svg/text-left.svg'
import { ReactComponent as CloseIcon } from 'static/svg/cross.svg'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { dataTypes } from 'utils'

const selectStyles = {
  ...reactSelectCustomStyles,
  menu: (provided, state) => ({
    ...provided,
    position: 'relative'
  })
}

const AddColumnPopover = forwardRef(
  ({ anchorEl, handleClose, handleAddNewColumn }, ref) => {
    const [dataTypeOptions, setDataTypeOptions] = useState([])
    const [selectedDataType, setSelectedDataType] = useState({
      label: (
        <div className="flex items-center space-x-2">
          <span>
            <TextIcon />
          </span>
          <span>Text</span>
        </div>
      ),
      value: 'text'
    })
    const [columnName, setColumnName] = useState('Column')
    const [loading, setLoading] = useState()
    const [selectOptions, setSelectOptions] = useState([])

    useEffect(() => {
      if (anchorEl) {
        setColumnName('Column')
        setSelectOptions([])
      }
    }, [anchorEl])

    useEffect(() => {
      const mappedData = dataTypes.map(item => ({
        label: (
          <div className="flex items-center space-x-2">
            <span>{item.icon}</span>
            <span>{item.label}</span>
          </div>
        ),
        value: item.value
      }))

      setDataTypeOptions(mappedData)
    }, [])

    useImperativeHandle(ref, () => {
      return {
        setLoading
      }
    })

    const handleColumnNameChange = e => {
      setColumnName(e.target.value)
    }

    const handleSelectOption = newVal => {
      setSelectedDataType(newVal)
    }

    const handleCreateColumn = () => {
      let metaData = {}

      if (
        selectedDataType.value === 'singleSelect' ||
        selectedDataType.value === 'multiSelect'
      ) {
        metaData.options = selectOptions.map(item => ({
          label: item,
          value: item.toLowerCase()
        }))
        metaData.isMultiSelect = selectedDataType.value === 'multiSelect'
      }

      // if (selectedDataType.value === 'numbers') {
      //   metaData = numbersMetaData
      // }

      handleAddNewColumn({
        name: columnName,
        datatype: selectedDataType.value,
        metaData
      })
    }

    const handleAddSelectOption = val => {
      setSelectOptions(prev => [...prev, val])
    }

    const handleRemoveSelectOption = index => {
      const filteredOptions = selectOptions.filter((item, idx) => idx != index)
      setSelectOptions(filteredOptions)
    }

    return (
      // <Popover
      //   open={Boolean(anchorEl)}
      //   onClose={handleClose}
      //   anchorEl={anchorEl}
      //   anchorOrigin={{
      //     vertical: 'center',
      //     horizontal: 'right'
      //   }}
      //   transformOrigin={{
      //     vertical: 'top',
      //     horizontal: 'left'
      //   }}
      // >
      <CustomPopper anchorEl={anchorEl} onClose={handleClose}>
        <div className="p-4 rounded" style={{ width: 240 }}>
          <div className="mb-4">
            <TextField
              value={columnName}
              onChange={handleColumnNameChange}
              autoFocus
              className="w-full"
            />
          </div>
          <div className="mb-2">
            <Select
              options={dataTypeOptions}
              value={selectedDataType}
              className="w-full"
              styles={selectStyles}
              onChange={handleSelectOption}
            />
          </div>
          <p className="text-xs text-gray-500 mb-4">
            {selectedDataType.value && datatypeInfo[selectedDataType.value]}
          </p>
          {(selectedDataType.value === 'singleSelect' ||
            selectedDataType.value === 'multiSelect') && (
            <AddSelectOption
              selectOptions={selectOptions}
              handleAddSelectOption={handleAddSelectOption}
              handleRemoveSelectOption={handleRemoveSelectOption}
            />
          )}

          {/* {selectedDataType.value === 'Numbers' && (
            <NumberFields setNumbersMetaData={setNumbersMetaData} />
          )} */}

          <div className="space-x-2">
            <LoadingButton
              onClick={handleCreateColumn}
              loading={loading}
              disabled={
                (selectedDataType.value === 'singleSelect' ||
                  selectedDataType.value === 'multiSelect') &&
                !selectOptions.length
              }
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </CustomPopper>
    )
  }
)

const AddSelectOption = ({
  selectOptions,
  handleAddSelectOption,
  handleRemoveSelectOption
}) => {
  const [add, setAdd] = useState(false)
  const [value, setValue] = useState('')
  const dispatch = useDispatch()

  const handleToggleAdd = () => {
    setAdd(prev => !prev)
  }

  const handleAddOption = e => {
    e.preventDefault()

    if (value.trim()) {
      const index = selectOptions.findIndex(
        item => item.toLowerCase() === value.toLowerCase()
      )

      if (index !== -1) {
        dispatch(fireErrorToaster('This option already exists!'))
      } else {
        handleAddSelectOption(value)
      }
    }
    setAdd(false)
    setValue('')
  }

  const handleSetValue = e => {
    setValue(e.currentTarget.value)
  }

  const handleDelete = e => {
    handleRemoveSelectOption(e.currentTarget.dataset.index)
  }

  return (
    <div className="mb-5">
      <h6 className="text-xs mb-2 font-medium">Options</h6>
      {selectOptions.map((item, index) => (
        <div className="flex items-center mb-2">
          <div
            key={item}
            className="text-sm border rounded px-2 py-1 mr-auto truncate flex-1"
          >
            {item}
          </div>
          <button className="ml-2" data-index={index} onClick={handleDelete}>
            <CloseIcon className="text-gray-400" />
          </button>
        </div>
      ))}
      {add ? (
        <form onSubmit={handleAddOption}>
          <input
            type="text"
            className="w-full px-2 py-2 bg-gray-100 rounded text-sm"
            value={value}
            onChange={handleSetValue}
            autoFocus
            onBlur={handleAddOption}
          />
        </form>
      ) : (
        <div role="button" className="cursor-pointer" onClick={handleToggleAdd}>
          <AddIcon style={{ fontSize: 12 }} />
          <span className="text-xs ml-1">Add an option</span>
        </div>
      )}
    </div>
  )
}

const datatypeInfo = {
  text: 'A text field accepts you simple texts',
  multiline:
    'With multiline field you can add different kind of texts example: bold, italic, list etc',
  person: 'Using person field, you add collaborators to your table',
  url: 'A URL field accepts valid url example: https://example.com',
  'single select':
    'A single select shows a dropdown with the specified options and accepts single value',
  'multi select':
    'A multi select shows a dropdown with the specified options and accepts multiple value',
  date: 'A date field accepts date',
  'email id':
    'An email field accepts email valid address example: john@gmail.com',
  phone: 'A phone field accepts phone number',
  numbers: 'A numbers field accepts number values like decimal or integer',
  checkbox:
    'A single checkbox that can be checked or unchecked to indicate true or false state.',
  attachment:
    'An attachment field accepts different kinds of files like image, text'
}

AddColumnPopover.propTypes = {
  anchorEl: PropTypes.element,
  handleClose: PropTypes.func,
  dataTypes: PropTypes.array,
  handleAddNewColumn: PropTypes.func
}

export default React.memo(AddColumnPopover)
