import React, { useState, useEffect } from 'react'
import { CheckBoxField, LoadingButton } from 'global/globalComponents'
import { TextField, InputAdornment } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import BoardsStyles from './BoardView/BoardsStyles'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import {
  createCustomURL,
  deleteCustomURL,
  updateCustomURL
} from 'thunks/customUrl/action'

import { CircularProgress, input } from '@material-ui/core'

const ShareBoardAdvanceOptions = ({
  workspace,
  passcode,
  setPasscode,
  handleSaveChanges,
  handleTabSelection,
  hiddenTab,
  visibleTabs,
  customizeLink,
  setCustomizeLink
}) => {
  const [passcodeProtected, setPasscodeProtected] = useState(Boolean(passcode))
  const [customize, setCustomize] = useState(false)
  const [expand, setExpand] = useState(false)
  const classes = BoardsStyles()
  const dispatch = useDispatch()
  const [validate, setValidate] = useState({ loading: false, valid: true })
  const [customURL, setCustomURL] = useState('')

  useEffect(() => {
    if (customizeLink._id) {
      setCustomURL(customizeLink.customUrl)
    } else {
      setCustomURL('')
    }
  }, [customizeLink])

  const togglePasscodeProtected = e => {
    setPasscodeProtected(e.currentTarget.checked)
  }

  const toggleCustomizeLink = e => {
    setCustomize(e.currentTarget.checked)
  }

  const handlePasscodeChange = e => {
    setPasscode(e.target.value)
  }

  const handleLinkChange = e => {
    const value = e.target.value.trim().toLowerCase()
    if (/^([\w\d]-?)*$/.test(value)) {
      setValidate({ loading: false, valid: true })
      setCustomURL(value)
    }
  }

  const saveCustomizeLink = () => {
    if (customURL) {
      const data = {
        customUrl: `${customURL}`,
        type: 'sharedBoard',
        data: {
          url: `/public/share/board/${workspace._id}`,
          data: {
            workspace: workspace._id
          }
        }
      }

      if (customizeLink._id) {
        if (customizeLink.customUrl !== customURL) {
          setValidate({ loading: true, valid: true })
          updateCustomURL(data, customizeLink._id, saveCustomizeLinkCallback)
        } else {
          setValidate({ loading: true, valid: true })
          deleteCustomURL(customizeLink._id, (res, err) => {
            setValidate({ loading: false, valid: true })
            if (!err) {
              setCustomizeLink({})
              dispatch(fireSuccessToaster('URL Deleted Successfully!'))
            } else {
              dispatch(fireErrorToaster(res))
            }
          })
        }
      } else {
        setValidate({ loading: true, valid: true })
        createCustomURL(data, saveCustomizeLinkCallback)
      }
    }
  }

  const saveCustomizeLinkCallback = (res, err) => {
    if (!err) {
      setCustomizeLink(res)
      setValidate({ loading: false, valid: true })
      dispatch(fireSuccessToaster('URL Saved Successfully!'))
    } else {
      dispatch(fireErrorToaster(res))
      setValidate({ loading: false, valid: false })
    }
  }

  const toggleExpand = () => {
    setExpand(prev => !prev)
  }

  return (
    <div className="bg-white mt-4">
      <div
        className="flex m-auto justify-center cursor-pointer items-center text-gray-900 mb-4 pb-1"
        onClick={toggleExpand}
      >
        <p className="text-smaller lg:text-sm font-bold">
          Show Advance Options
        </p>
        <ExpandMoreIcon
          className={clsx(classes.icons, {
            expanded: expand
          })}
        />
      </div>

      {expand && (
        <div className="mb-4">
          <div>
            <CheckBoxField
              label={
                <div className="flex flex-wrap items-center">
                  <p className="text-smaller lg:text-sm font-normal">
                    Customize your public share link
                  </p>
                </div>
              }
              checked={customize}
              onChange={toggleCustomizeLink}
            />

            <div className="relative">
              <div className="flex items-center">
                <TextField
                  error={!validate.valid}
                  id="outlined-error"
                  value={customURL}
                  onChange={handleLinkChange}
                  placeholder="Enter your custom url"
                  className="w-full mt-4"
                  autoFocus
                  disabled={!customize}
                  onBlur={
                    customizeLink.customUrl && !customURL
                      ? () => setCustomURL(customizeLink.customUrl)
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {customize && Boolean(customURL) ? (
                          validate.loading ? (
                            <span className="cursor-pointer text-primary-main text-xs font-medium">
                              <CircularProgress
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  color: '#1600e5'
                                }}
                              />
                            </span>
                          ) : (
                            <button
                              type="submit"
                              className={clsx(
                                'text-sm font-medium text-primary-main'
                              )}
                              onClick={saveCustomizeLink}
                            >
                              {customURL === customizeLink.customUrl
                                ? 'Delete'
                                : 'Validate'}
                            </button>
                          )
                        ) : null}
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {customize && (
                <p className="text-xs my-1">
                  {window.location.origin}/public/
                  {customURL ? customURL : `share/board/${workspace._id}`}
                </p>
              )}
            </div>
          </div>
          <div className="my-4">
            <CheckBoxField
              label={
                <p className="text-smaller lg:text-sm font-normal">
                  Make your link secure
                </p>
              }
              checked={passcodeProtected}
              onChange={togglePasscodeProtected}
            />

            {/* {passcodeProtected && ( */}
            <div className="">
              <TextField
                value={passcode}
                onChange={handlePasscodeChange}
                placeholder="Enter Passcode (only numbers)"
                className="w-full mt-4"
                disabled={!passcodeProtected}
                onBlur={handleSaveChanges}
              />
            </div>
            {/* )} */}
          </div>
          <div className="my-4">
            <p className="text-sm">
              Selected tabs will be available for share board
            </p>
            <ul className="flex items-center flex-wrap">
              {visibleTabs.map(el => (
                <li key={el.accessor} className="w-1/3">
                  <CheckBoxField
                    label={el.label}
                    checked={!hiddenTab.includes(el.accessor)}
                    onChange={e =>
                      handleTabSelection(el.accessor, e.target.checked)
                    }
                    disabled={el.disable}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShareBoardAdvanceOptions
