import React from 'react'
import PropTypes from 'prop-types'
import { createStyles, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const pillColors = {
  gray: 'text-custom-gray-light-2 bg-custom-gray-lightest',
  red: 'text-custom-red-main bg-custom-red-light',
  yellow: 'text-custom-yellow-main bg-custom-yellow-light',
  green: 'text-custom-green-main bg-custom-green-light',
  blue: 'text-custom-blue-main bg-custom-blue-light',
  pink: 'text-custom-pink-main bg-custom-pink-light',
  purple: 'text-custom-purple-main bg-custom-purple-light',
  orange: 'text-custom-orange-main bg-custom-orange-light',

  indigo: 'bg-indigo-100 text-indigo-800',
  greenLight2: 'bg-primary-light2 text-green-800'
}

export const pillVariantObj = {
  paid: 'green',
  draft: 'yellow',
  uncollectible: 'pink',
  open: 'green',
  send: 'blue',
  pending: 'yellow',
  rejected: 'red',
  resolved: 'green',
  closed: 'gray',
  canceled: 'yellow'
}

export const taskStatusVariants = {
  completed: 'green',
  review: 'orange',
  blocked: 'indigo',
  pending: 'yellow',
  open: 'gray',
  inProgress: 'red'
}

const Pill = props => {
  const { variant, className = '', ...rest } = props
  const classes = useStyles()
  return (
    <span
      {...rest}
      className={clsx(
        'inline-block px-3 font-medium rounded-full capitalize border border-current whitespace-nowrap',
        classes.genralStyle,
        pillColors[variant],
        className
      )}
    >
      {props.children}
    </span>
  )
}
export default Pill

const useStyles = makeStyles(theme =>
  createStyles({
    genralStyle: {
      fontSize: '9px',
      userSelect: 'none',
      height: 23,
      lineHeight: '23px',
      '@media (min-width: 1284px)': {
        fontSize: '10px',
        height: 25,
        lineHeight: '25px'
      }
    },
    purple: {
      backgroundColor: '#fbcffc'
    }
  })
)
Pill.propTypes = {
  variant: PropTypes.oneOf([
    'gray',
    'pink',
    'purple',
    'red',
    'blue',
    'yellow',
    'green',
    'indigo'
  ])
}
