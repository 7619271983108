import { makeStyles, createStyles } from '@material-ui/core'

const clientServiceStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      width: `calc(100vw - ${0}px)`,
      height: theme.custom.header.height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      boxShadow: theme.shadows[1],
      position: 'fixed',
      zIndex: theme.zIndex.drawer + 1,
      background: '#fff',
      top: 0,
      left: 0,
      padding: theme.spacing(0, 2)
    },
    bodyContainer: {
      marginTop: theme.custom.header.height
    }
  })
)

export default clientServiceStyles
