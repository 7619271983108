import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import Pill from 'global/globalComponents/Pill/Pill'
import { fetchClientRaisedRequests } from 'thunks/clientRaisedRequestAgency/actions'
import pillVariantObj from 'utils/pillVariantObj'
import moment from 'moment'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'
import widgetStyles from '../widgetStyles'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { PopupMenu } from 'global/globalComponents'
import { updateRaisedRequest } from 'thunks/clientRaisedRequestAgency/actions'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import RequestDetailsModal from 'components/Clients/RequestDetailsModal'
import RequestTaskModel from 'components/Clients/RequestTaskModal'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'

const totalRequestsToShow = 8

const RequestAnalyticsTable = ({ widgetId, editMode, handleRemoveWidget }) => {
  const dispatch = useDispatch()
  const classes = widgetStyles()
  const history = useHistory()
  const clientRaisedRequests = useSelector(
    state => state.clientRaisedRequestAgency
  )
  const teammates = useSelector(state => state.userTeammates)
  const meData = useSelector(state => state.me.data)
  const [openReqModal, setOpenReqModal] = useState({
    open: false,
    data: {}
  })
  const permission = useSelector(state => state.permission)
  const [taskModalOpen, setTaskModalOpen] = useState({ open: false, data: {} })
  const [clientRaisedRequestsData, setClientRaisedRequestsData] = useState([])

  useEffect(() => {
    if (!clientRaisedRequests.fetched) {
      dispatch(fetchClientRaisedRequests())
    }
  }, [clientRaisedRequests.fetched])

  useEffect(() => {
    setClientRaisedRequestsData(clientRaisedRequests.data)
  }, [clientRaisedRequests])

  useEffect(() => {
    if (!teammates.fetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [teammates.fetched])

  const handleRequestOpen = () => {
    history.push(`${getUserPath(meData.role)}/clients/all/requests`)
  }

  const handleDeleteWidgetClick = () => {
    handleRemoveWidget(widgetId)
  }

  const handleUpdateRequest = (id, data) => {
    dispatch(updateRaisedRequest(id, data, handleUpdateRequestCallback))
  }

  const handleTaskModal = data => {
    if (taskModalOpen.open) {
      setTaskModalOpen({ open: false, data: {} })
    } else {
      setTaskModalOpen({ open: true, data: data })
    }
  }

  const handleUpdateRequestCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Updated Successfully'))
    }
  }

  const handleOpenReqModal = data => {
    setOpenReqModal({ open: true, data })
  }

  const handleCloseReqModal = () => {
    setOpenReqModal({ open: false, data: null })
  }

  return (
    <>
      <div
        className="mb-2 flex justify-between items-center pr-4"
        // style={{ minHeight: '44px' }}
      >
        <h2 className="text-base lg:text-lg leading-6 font-medium text-gray-900 flex items-center">
          <span>
            Client Requests{' '}
            <span className="text-gray-500 text-sm">
              ({clientRaisedRequestsData.length})
            </span>
          </span>
        </h2>

        <div style={{ minHeight: editMode ? 'auto' : '44px' }}>
          {editMode ? (
            <button className="text-red-400" onClick={handleDeleteWidgetClick}>
              <TrashIcon />
            </button>
          ) : // : (
          //   <Tabs
          //     value={selectedTab}
          //     onChange={handleTabChange}
          //     aria-label="simple tabs example"
          //     classes={{ root: classes.tabRoot }}
          //   >
          //     {tabsData.map((item, index) => (
          //       <Tab
          //         label={
          //           <div className="flex items-center gap-1 text-xs">
          //             {item.label}
          //           </div>
          //         }
          //         key={index}
          //         value={item.value}
          //       />
          //     ))}
          //   </Tabs>
          // )
          null}
        </div>
      </div>
      <TableContainer
        containerProps={{
          style: {
            overflowY: 'auto',
            height: 'calc(100% - 55px)',
            background: '#fff'
          }
        }}
        mb={9}
      >
        <Thead className="sticky top-0" style={{ zIndex: 99 }}>
          <Th>Title</Th>
          <Th>Raised By</Th>
          <Th>Raised Date</Th>
          <Th>Status</Th>
          <Th center></Th>
        </Thead>
        <Tbody>
          {clientRaisedRequests.loading ? (
            <TableDataLoading cols={5} />
          ) : clientRaisedRequestsData.length ? (
            clientRaisedRequestsData.map(item => (
              <TableRow
                key={item._id}
                item={item}
                handleTaskModal={handleTaskModal}
                handleOpenReqModal={handleOpenReqModal}
                canUpdate={permission.data.access?.clientRequest?.update}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>There are no requests</Td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>

      {openReqModal.open && (
        <RequestDetailsModal
          open={openReqModal.open}
          handleClose={handleCloseReqModal}
          data={openReqModal.data}
          teammates={teammates.data}
          handleUpdateRequest={handleUpdateRequest}
          handleTaskModal={handleTaskModal}
          canUpdate={permission.data.access.clientRequest.update}
        />
      )}
      <RequestTaskModel
        open={taskModalOpen.open}
        handleDialog={handleTaskModal}
        data={taskModalOpen.data}
      />
    </>
  )
}

const TableRow = ({ item, handleTaskModal, handleOpenReqModal, canUpdate }) => {
  const handleRowClick = () => {
    if (canUpdate) {
      handleOpenReqModal(item)
    }
  }

  const handleConvertTask = () => {
    handleTaskModal(item)
  }
  return (
    <Tr>
      <Td
        className="text-left text-gray-900 cursor-pointer"
        onClick={handleRowClick}
      >
        <span>{item.title}</span>
      </Td>
      <Td>
        <span className="text-primary-mid-dark text-xs font-medium">
          {item.client?.name}
        </span>
        <p className="text-primary-mid-dark-2 text-xs">
          ({item.client?.email})
        </p>
      </Td>
      <Td>
        <time className="text-xs">
          {moment(item.createdAt).endOf('minute').fromNow()}
        </time>
      </Td>
      <Td>
        <Pill variant={pillVariantObj[item.status]}>{item.status}</Pill>
      </Td>

      {canUpdate && (
        <Td>
          <PopupMenu
            menuItems={[
              {
                label: 'View',
                method: handleRowClick
              },
              {
                label: 'Convert to task',
                method: handleConvertTask
              }
            ]}
          />
        </Td>
      )}
    </Tr>
  )
}

export default React.memo(RequestAnalyticsTable)
