import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as TableViewIcon } from 'static/svg/bars.svg'
import { ReactComponent as GridViewIcon } from 'static/svg/grid-view.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllWorkspacesForCurrentUser } from 'thunks/workspace/actions'
import { removedFromWSModal } from 'thunks/generlAppState/actions'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import clsx from 'clsx'
import ProjectsCardView from './ProjectsCardView/ProjectsCardView'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import { fetchTagsByUser } from 'thunks/tags/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import TemplatesModal from 'components/Templates/TemplateModal'
import ProjectsReactTableView from './ProjectsTableView/ProjectsReactTableView'
import { fetchWorkspacePermissions } from 'thunks/workspacePermission/action'
import { userRoles } from 'utils'

const Workspaces = () => {
  const dispatch = useDispatch()
  const userTags = useSelector(state => state.userTags)
  const meData = useSelector(state => state.me.data)
  const userWorkspaces = useSelector(state => state.userWorkspaces)
  const removedSidePanelOpenStatus = useSelector(
    state => state.generalAppState.workspaceRemoveModal
  )
  const [projectModalOpen, setProjectModalOpen] = useState(false)
  const [projectsView, setProjectsView] = useState('table')
  const permission = useSelector(state => state.permission.data?.access)
  const { fetched: workspacePermissionFetched } = useSelector(
    state => state.workspacePermission
  )

  useEffect(() => {
    if (!userTags.fetched) {
      dispatch(fetchTagsByUser())
    }
  }, [userTags.fetched])

  useEffect(() => {
    if (!userWorkspaces.fetchedFirstTime) {
      dispatch(fetchAllWorkspacesForCurrentUser())
    }
  }, [userWorkspaces.fetchedFirstTime])

  useEffect(() => {
    if (!workspacePermissionFetched) {
      dispatch(fetchWorkspacePermissions())
    }
  }, [workspacePermissionFetched])

  const switchProjectsView = e => {
    const view = e.currentTarget.dataset.view
    if (view) setProjectsView(view)
  }

  return (
    <OuterContentWrapper>
      <header className="flex justify-between items-center mb-6 lg:mb-8">
        <SectionHeading>Projects ({userWorkspaces.data.length})</SectionHeading>

        <div className="flex items-center text-gray-400">
          {[
            userRoles.USER_AGENCY,
            userRoles.AGENCY_ADMIN,
            userRoles.AGENCY_MANAGER
          ].includes(meData.role) && (
            <Button
              variant="outlined"
              onClick={() => setProjectModalOpen(true)}
              // onClick={openAddProjectPanel}
              startIcon={<AddIcon />}
            >
              New Project
            </Button>
          )}

          <div className="flex bg-white shadow rounded-full p-1 ml-4">
            <button
              className={clsx(
                'w-6 h-6 lg:w-8 lg:h-8  rounded-full flex justify-center items-center',
                projectsView === 'table' && 'bg-primary-main text-white'
              )}
              onClick={switchProjectsView}
              data-view="table"
            >
              <TableViewIcon />
            </button>
            <button
              className={clsx(
                'w-6 h-6 lg:w-8 lg:h-8 rounded-full flex justify-center items-center',
                projectsView === 'grid' && 'bg-primary-main text-white'
              )}
              onClick={switchProjectsView}
              data-view="grid"
            >
              <GridViewIcon />
            </button>
          </div>
        </div>
      </header>

      {projectsView === 'grid' ? (
        <ProjectsCardView />
      ) : (
        <ProjectsReactTableView />
      )}
      <AlertModal
        open={removedSidePanelOpenStatus}
        warningText={'You have been removed from the project'}
        cancelText="Okay"
        deleteBtnText={false}
        handleDialog={() => dispatch(removedFromWSModal(false))}
      />
      <TemplatesModal
        open={projectModalOpen}
        onClose={() => setProjectModalOpen(false)}
      />
    </OuterContentWrapper>
  )
}

export default Workspaces
