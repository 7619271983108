import React from 'react'
import { Button } from '@material-ui/core'
import WidgetImage from 'static/svg/widget.svg'

const NoWidgetMessage = ({ openAddWidgetModal }) => {
  return (
    <div className="w-full bg-white shadow rounded text-center flex justify-center py-12 mt-8">
      <div className="text-center">
        <img src={WidgetImage} alt="" className="mx-auto w-40 h-40" />
        <h2 className="text-2xl font-bold">Add custom widget</h2>
        <p className="text-primary-main mt-4 mb-8 w-2/3 text-sm mx-auto">
          Customize your dashboard and have a complete overview of the thing
          that are most important to you.
        </p>
        <Button onClick={openAddWidgetModal}>Add a widget</Button>
      </div>
    </div>
  )
}

export default NoWidgetMessage
