import {
  Add,
  Note,
  OndemandVideo,
  InsertDriveFile,
  MenuBook
} from '@material-ui/icons'

export const onboardingCategories = [
  {
    id: 1,
    category: 'intakeForm',
    label: 'Intake form',
    icon: <Note />
  },
  {
    id: 2,
    category: 'video',
    label: 'Video',
    icon: <OndemandVideo />
  },
  {
    id: 3,
    category: 'document',
    label: 'Document',
    icon: <InsertDriveFile />
  },
  {
    id: 4,
    category: 'instructions',
    label: 'Instructions',
    icon: <MenuBook />
  }
]
