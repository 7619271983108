import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext
} from 'react'
import { Skeleton } from '@material-ui/lab'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchUsersWorkload,
  fetchUserWiseWorkload
} from 'thunks/Tracking/action'
import { userRoles } from 'utils'
import WorkloadHead from './WorkloadHead'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import WorkloadTable from './WorkloadTable'
import moment from 'moment'

moment.locale('en-gb', {
  week: {
    dow: 1 /// Date offset
  }
})

function getWeekDays(weekStart) {
  const days = []
  for (let i = 0; i < 7; i++) {
    days.push(moment(weekStart).add(i, 'days').toDate().toDateString())
  }
  return days
}

const Workload = () => {
  const meData = useSelector((state) => state.me.data)
  const [loading, setLoading] = useState(false)
  const [timelineData, setTimelineData] = useState([])
  const [selectedDays, setSelectedDays] = useState(getWeekDays(new Date()))
  const [selectedDate, setSelectedDate] = useState(new Date())

  const startTime = useMemo(() => {
    return moment(selectedDate).startOf('week').toISOString()
  }, [selectedDate])

  const endTime = useMemo(() => {
    return moment(selectedDate).endOf('week').toISOString()
  }, [selectedDate])

  useEffect(() => {
    setLoading(true)
    if ([userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)) {
      fetchUsersWorkload(startTime, endTime, fetchDataCallback)
    } else {
      fetchUserWiseWorkload(startTime, endTime, fetchDataCallback)
    }
  }, [])

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 100)
  // }, [startTime, endTime])

  const fetchDataCallback = (res, err) => {
    setLoading(false)
    if (!err) {
      setTimelineData(res)
    }
  }

  useEffect(() => {
    setSelectedDays(getWeekDays(moment(selectedDate).startOf('week').toDate()))
  }, [selectedDate])

  return (
    <Wrapper>
      {/* <SecondaryHeading>Resource Management</SecondaryHeading> */}
      <WorkloadHead
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <WorkloadTable
        timelineData={timelineData}
        loading={loading}
        setLoading={setLoading}
        selectedDays={selectedDays}
        startDate={startTime}
        endDate={endTime}
      />
    </Wrapper>
  )
}

export default Workload
