import { makeStyles, createStyles } from '@material-ui/core'

const widgetStyles = makeStyles((theme) =>
  createStyles({
    tabRoot: {
      minHeight: 0,
      height: 44
    },
    avatarRoot: {
      '& > div': {
        width: '24px',
        height: '24px',
        fontSize: 10,
        textTransform: 'uppercase'
      }
    },
    invoiceCardsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '1rem',
      gridGap: '1rem' //fro the sake of browser support
    }
  })
)

export default widgetStyles
