/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'
import {
  fetchClientRaisedRequests,
  updateRaisedRequest
} from 'thunks/clientRaiseRequest/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { CustomFlag, LoadingButton } from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import pillVariantObj from 'utils/pillVariantObj'
import Pill from 'global/globalComponents/Pill/Pill'
import { userRoles } from 'utils'
import moment from 'moment'
import ThreeDotMenu from 'global/globalComponents/ThreeDotMenu'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'
import { PopupMenu } from 'global/globalComponents'
import RaiseRequestModal from 'components/ClientComponent/ClientDashboard/RaiseRequestModal'

const ClientRequestAnalyticsTable = ({
  widgetId,
  editMode,
  handleRemoveWidget
}) => {
  const [openReqModal, setOpenReqModal] = useState({
    open: false,
    data: null
  })
  const raisedRequests = useSelector(state => state.clientRaisedRequest)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentRequest, setCurrentRequest] = useState({})
  const meData = useSelector(state => state.me.data)
  const [visibleRequests, setVisibleRequests] = useState([])
  const [page, setPage] = useState(0)

  useEffect(() => {
    dispatch(fetchClientRaisedRequests())
  }, [])

  // useEffect(() => {
  //   if (raisedRequests.data.length) {
  //     setVisibleRequests(
  //       [...raisedRequests.data].splice(
  //         page * totalRequestsToShow,
  //         totalRequestsToShow
  //       )
  //     )
  //   }
  // }, [raisedRequests, page])

  const handleOpenReqModal = data => {
    setOpenReqModal({ open: true, data })
  }

  console.log(openReqModal, '')

  const handleCloseReqModal = () => {
    setOpenReqModal({ open: false, data: null })
  }

  const closeStatusPopup = () => {
    setAnchorEl(null)
  }

  const openStatusPopup = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleStatusChange = status => {
    closeStatusPopup()
    dispatch(
      updateRaisedRequest(
        currentRequest._id,
        { status },
        handleStatusChangeCallback
      )
    )
  }

  const handleStatusChangeCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Status updated successfully'))
    }
  }

  const handleDeleteWidgetClick = () => {
    handleRemoveWidget(widgetId)
  }

  return (
    <>
      <div
        className="mb-2 flex justify-between items-center pr-4"
        // style={{ minHeight: '44px' }}
      >
        <h2 className="text-base lg:text-lg leading-6 font-medium text-gray-900 flex items-center">
          <span>
            Requests
            <span className="text-gray-500 text-sm">
              {' '}
              ({raisedRequests.data.length})
            </span>
          </span>
        </h2>

        <div style={{ minHeight: editMode ? 'auto' : '44px' }}>
          {/* {meData.role !== userRoles.CLIENT_VIEWER && (
            <Button
              size="small"
              startIcon={<Add fontSize="small" />}
              onClick={() => handleOpenReqModal()}
            >
              Raise Request
            </Button>
          )} */}
          {editMode ? (
            meData.role < userRoles.USER_CLIENT ? (
              <button
                className="text-red-400"
                onClick={handleDeleteWidgetClick}
              >
                <TrashIcon />
              </button>
            ) : null
          ) : // : (
          //   <Tabs
          //     value={selectedTab}
          //     onChange={handleTabChange}
          //     aria-label="simple tabs example"
          //     classes={{ root: classes.tabRoot }}
          //   >
          //     {tabsData.map((item, index) => (
          //       <Tab
          //         label={
          //           <div className="flex items-center gap-1 text-xs">
          //             {item.label}
          //           </div>
          //         }
          //         key={index}
          //         value={item.value}
          //       />
          //     ))}
          //   </Tabs>
          // )
          null}
        </div>
      </div>
      <TableContainer
        containerProps={{
          style: {
            overflowY: 'auto',
            height: 'calc(100% - 55px)',
            background: '#fff'
          }
        }}
        mb={9}
      >
        <Thead className="sticky top-0" style={{ zIndex: 99 }}>
          <Th>Title</Th>
          <Th center>Status</Th>
          <Th center>Priority</Th>
          <Th>Raised Date</Th>
          {meData.role <= userRoles.CLIENT_MANAGER && <Th center></Th>}
        </Thead>
        <Tbody>
          {raisedRequests.loading ? (
            <TableDataLoading cols={5} />
          ) : raisedRequests.data.length ? (
            raisedRequests.data.map((item, index) => (
              <TableRow
                key={index}
                item={item}
                openStatusPopup={openStatusPopup}
                setCurrentRequest={setCurrentRequest}
                handleOpenReqModal={handleOpenReqModal}
                meData={meData}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={5}>No requests data available</Td>
            </Tr>
          )}
        </Tbody>
      </TableContainer>
      {openReqModal.open && (
        <RaiseRequestModal
          open={openReqModal.open}
          handleClose={handleCloseReqModal}
          data={openReqModal.data}
          canUpdate={meData.role <= userRoles.CLIENT_MANAGER}
        />
      )}
    </>
  )
}

const TableRow = ({
  item,
  openStatusPopup,
  setCurrentRequest,
  handleOpenReqModal,
  meData
}) => {
  const handleOpenPopop = e => {
    e.stopPropagation()

    openStatusPopup(e)
    setCurrentRequest(item)
  }

  const handleRowClick = () => {
    handleOpenReqModal(item)
  }

  return (
    <Tr>
      <Td>{item.title}</Td>
      <Td className="truncate max-w-sm" center>
        <Pill variant={pillVariantObj[item.status]}>{item.status}</Pill>
      </Td>
      <Td center>
        {isNaN(item.priority) || item.priority === 5 ? (
          <span className="text-gray-400 text-xs">-</span>
        ) : (
          <CustomFlag priority={item.priority} />
        )}
      </Td>
      <Td>
        <time className="text-xs">
          {moment(item.createdAt).endOf('minute').fromNow()}
        </time>
      </Td>
      {meData.role <= userRoles.CLIENT_MANAGER && (
        <Td center>
          <PopupMenu
            menuItems={[
              {
                label: 'View',
                method: handleRowClick
              }
            ]}
          />
        </Td>
      )}
    </Tr>
  )
}

export default React.memo(ClientRequestAnalyticsTable)
