import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import ColoredAvatars from 'global/globalComponents/ColoredAvatars/ColoredAvatars'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
// import taskSidePanelStyles from './taskSidePanelStyles'
import clsx from 'clsx'

export const Collaborators = ({ teammates, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={clsx('flex items-center', classes.avatarsWrapper)}>
      {teammates.map((item, index) => (
        <Collaborator key={index} teammate={item} {...rest} />
      ))}
    </div>
  )
}

function Collaborator({ removeBtn, teammate, handleRemove, size = 'small' }) {
  const classes = useStyles()

  const handleRemoveClick = () => {
    handleRemove(teammate)
  }

  return (
    <div
      className={clsx(
        'relative cursor-pointer show-on-hover-parent hover:z-10'
      )}
    >
      {typeof teammate === 'object' && (
        <>
          <ColoredAvatars size={size} tooltip={true} user={teammate} />
          {removeBtn && (
            <button
              onClick={handleRemoveClick}
              className={`cross-btn ${classes.removeAssigneeBtn} show-on-hover-child bg-white rounded-full z-10`}
            >
              <CancelRoundedIcon fontSize="small" />
            </button>
          )}
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles({
  avatarsWrapper: {
    '& > * + *': {
      marginLeft: '-8px'
    }
  },
  removeAssigneeBtn: {
    position: 'absolute',
    top: '-8px',
    right: '-8px',
    color: 'rgba(255, 0, 0, 0.75)'
    // '&:hover': {
    //   color: 'rgba(255, 0, 0)'
    // }
  }
})

export default React.memo(Collaborator)
