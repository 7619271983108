import { useEffect, useState, useMemo } from 'react'
import { Button, TextField } from '@material-ui/core'
import { LoadingButton } from 'global/globalComponents'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import React from 'react'
import clsx from 'clsx'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import userRoles, {
  agencyRoleOptions,
  agencyRoleOptionsForManager,
  clientRoleOptions
} from 'utils/userRoles'
import {
  createTeamVerticals,
  fetchTeamVerticals
} from 'thunks/Verticals/action'
import { fireErrorToaster } from 'thunks/fireToaster/actions'
import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import { InputAdornment, IconButton } from '@material-ui/core'
import { ReactComponent as EyeIcon } from 'static/svg/eye.svg'
import { ReactComponent as EyeSlashIcon } from 'static/svg/eye-slash.svg'
import teammateStyles from './teammateStyles'
import { validator } from 'utils'
import CryptoJS from 'crypto-js'
import VerticalsModal from './VerticalsModal'
import CustomMenu from './CustomMenu'

const EditTeammatePanelNew = ({
  open,
  onClose,
  data,
  updateTeammate,
  canEditAccount,
  elmFor,
  clientOwner
}) => {
  const classes = teammateStyles()
  const dispatch = useDispatch()
  const [teammateData, setTeammateData] = useState({
    name: '',
    email: '',
    role: {},
    assignedWorkspace: [],
    verticals: {}
    // verticals: []
  })
  const userClients = useSelector((state) => state.userClients)
  const workspaces = useSelector((state) => state.userWorkspaces)
  const { data: verticalsData, fetched } = useSelector(
    (state) => state.teamVerticals
  )
  const [password, setPassword] = useState('')
  // const [selectWorkspace, setSelectWorkspace] = useState(false)
  const [projectOptions, setProjectOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [roleOptions, setRoleOptions] = useState([])
  const [passwordValid, setPasswordValid] = useState(true)
  const meData = useSelector((state) => state.me.data)
  // const [newWorkspace, setNewWorkspace] = useState([]);
  // const [removedWorkspace, setRemovedWorkspace] = useState([]);
  const [verticalsOptions, setVerticalsOptions] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  // const canEditNameEmail = useMemo(() => {
  //   return [userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(meData.role)
  // }, [meData.role])

  const [openVerticalModal, setOpenVerticalModal] = useState(false)

  useEffect(() => {
    if (!fetch) dispatch(fetchTeamVerticals())
  }, [])

  useEffect(() => {
    if (meData.role < userRoles.USER_CLIENT && elmFor !== 'client') {
      if (
        ![
          userRoles.USER_ADMIN,
          userRoles.USER_AGENCY,
          userRoles.AGENCY_ADMIN
        ].includes(meData.role)
      ) {
        return setRoleOptions(agencyRoleOptionsForManager)
      } else return setRoleOptions(agencyRoleOptions)
    } else {
      setRoleOptions(clientRoleOptions)
    }
  }, [])
  useEffect(() => {
    if (open) {
      setTeammateData({
        name: data.name,
        email: data.email,
        role: [...clientRoleOptions, ...agencyRoleOptions].find(
          (item) => item.value === data.role
        ),
        verticals:
          data.verticals?.length > 0
            ? {
                value: data.verticals[0]?._id,
                label: data.verticals[0]?.name
              }
            : {},
        // verticals: data.verticals
        //   ? data.verticals.map((item) => ({
        //       value: item?._id,
        //       label: item?.name
        //     }))
        //   : [],
        assignedWorkspace: data.assignedWorkspace?.map((item) => ({
          value: item._id,
          label: item.name
        }))
      })

      // if me role is agency admin or super admin then i can see teammate's password
      if (
        (meData.role === userRoles.AGENCY_ADMIN ||
          meData.role === userRoles.USER_AGENCY) &&
        data.passwordInString
      ) {
        try {
          const bytes = CryptoJS.AES.decrypt(
            data.passwordInString,
            process.env.REACT_APP_PASSWORD_SECRET
          )
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
          setPassword(decryptedData)
        } catch (err) {
          setPassword(data.passwordInString)
        }
      }
    }
  }, [open, data])

  useEffect(() => {
    if (elmFor === 'client' && Boolean(clientOwner)) {
      const currentClient = userClients.data.find(
        (item) => item._id === clientOwner
      )

      if (currentClient) {
        setProjectOptions(
          currentClient.assignedWorkspace
            .filter((el) => !el.isArchived)
            .map((item) => ({ label: item.name, value: item._id }))
        )
      }
    } else {
      if (workspaces.data.length) {
        const activeProjects = workspaces.data.filter((item) => {
          if (item.isArchived || item.isCompleted) return false
          return true
        })

        setProjectOptions(
          activeProjects.map((item) => ({ label: item.name, value: item._id }))
        )
      }
    }
  }, [open, workspaces])

  useEffect(() => {
    if (verticalsData.length) {
      setVerticalsOptions(
        verticalsData.map((item) => ({ label: item.name, value: item._id }))
      )
    }
  }, [open, verticalsData])

  const handleRoleChange = (e) => {
    setTeammateData((oldState) => ({
      ...oldState,
      role: e
    }))
  }

  const toggleCreateRoleModal = () => {
    setOpenVerticalModal((prev) => !prev)
  }

  const handleSaveChanges = () => {
    if (!validator.isEmailValid(teammateData.email)) {
      dispatch(fireErrorToaster('Invalid email address!'))
      return
    }
    // From here we need to send 1) assignedWorkspace, 2) Role (only if updated), 3) Password (only if updated)
    const bodyData = {
      name: teammateData.name.trim(),
      email: teammateData.email.trim(),
      removedWorkspace: [],
      newWorkspace: [],
      verticals: teammateData.verticals.value
        ? [teammateData.verticals.value]
        : []
    }

    if (bodyData.name === '' || bodyData.email === '') {
      dispatch(fireErrorToaster('Please fill all the required fields!'))
      return
    }

    // Removed Workspaces
    for (let ws of data?.assignedWorkspace) {
      const match = teammateData?.assignedWorkspace.find(
        (item) => item.value === ws._id
      )

      if (!match) {
        bodyData.removedWorkspace.push(ws._id)
      }
    }

    // Added Workspaces
    for (let ws of teammateData.assignedWorkspace) {
      const match = data.assignedWorkspace.find((item) => item._id === ws.value)

      if (!match) {
        bodyData.newWorkspace.push(ws.value)
      }
    }

    if (password) {
      if (!validPasswordHandler()) {
        setPasswordValid(false)
        return
      } else {
        bodyData.password = password
      }
    }

    if (teammateData.role.value !== data.role) {
      bodyData.role = teammateData.role.value
    }
    if (bodyData.role === userRoles.AGENCY_ADMIN) {
      bodyData.newWorkspace = []
      bodyData.removedWorkspace = []
      // bodyData.verticals = []
    }

    setLoading(true)
    // if (
    //   teammateData.verticals['__isNew__'] &&
    //   teammateData.role !== userRoles.AGENCY_ADMIN
    // ) {
    //   const roleData = {
    //     name: teammateData.verticals.label,
    //     type: 'vertical',
    //     description: 'role',
    //     newTeammates: []
    //   }

    //   dispatch(
    //     createTeamVerticals(roleData, (res, err) => {
    //       if (!err) {
    //         bodyData.verticals = [res._id]
    //         updateTeammate(data._id, bodyData, () => {
    //           setLoading(false)
    //           handleDrawerClose()
    //         })
    //       } else {
    //         setLoading(false)
    //         dispatch(fireErrorToaster('Error occured to update the role!'))
    //       }
    //     })
    //   )
    // } else {
    updateTeammate(data._id, bodyData, () => {
      setLoading(false)
      handleDrawerClose()
    })
    // }
  }

  // const handleSelectWorkspace = (e) => {
  //   setSelectWorkspace(!selectWorkspace)
  //   setTeammateData((prev) => ({
  //     ...prev,
  //     assignedWorkspace: [...prev.assignedWorkspace, e]
  //   }))
  //   let arr = projectOptions.filter((o) => o.value !== e.value)
  //   setProjectOptions(arr)
  // }

  const handleProjectsChange = (val, e) => {
    setTeammateData((prev) => ({ ...prev, assignedWorkspace: val }))
    // let removedWSo09
    // setClientWorkspaces(e)
  }

  const handleVerticalsChange = (e) => {
    setTeammateData((oldState) => ({
      ...oldState,
      verticals: e
    }))
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    setPasswordValid(true)
  }

  const validPasswordHandler = () => {
    const isValid = validator.isPasswordValid(password)
    setPasswordValid(isValid)
    return isValid

    // if (
    //   password.match(/[a-z]/g) &&
    //   password.match(/[A-Z]/g) &&
    //   password.match(/[0-9]/g) &&
    //   password.match(/[^a-zA-Z\d]/g) &&
    //   password.trim().length >= 8 &&
    //   password.trim().length <= 20
    // ) {
    //   setPasswordValid(true)
    //   return true
    // } else {
    //   setPasswordValid(false)
    //   return false
    // }
  }

  const handleDrawerClose = () => {
    setPassword('') //clear field before closing
    onClose()
  }

  const togglePasswordView = () => {
    setShowPassword((prev) => !prev)
  }

  const handleFieldChange = (e) => {
    setTeammateData((prev) => ({
      ...prev,
      [e.target.dataset.field]: e.target.value
    }))
  }

  return (
    <CustomDrawer
      onClose={handleDrawerClose}
      classes={{ paper: clsx(classes.drawerPaper, { drawerOpen: open }) }}
      anchor="right"
      open={open}
    >
      <DrawerHeader onClose={handleDrawerClose}>
        Edit {elmFor === 'client' && 'Client'} Teammates
      </DrawerHeader>
      <DrawerContent open={open}>
        <div className="mb-4">
          <FormLabel className="text-sm">Name</FormLabel>
          <TextField
            className="w-full"
            value={teammateData.name}
            onChange={handleFieldChange}
            // disabled={!canEditNameEmail}
            disabled={!canEditAccount}
            inputProps={{ 'data-field': 'name' }}
          />
        </div>

        <div className="mb-4">
          <FormLabel className="text-sm">Email</FormLabel>
          <TextField
            className="w-full"
            value={teammateData.email}
            onChange={handleFieldChange}
            // disabled={!canEditNameEmail}
            disabled={!canEditAccount}
            inputProps={{ 'data-field': 'email' }}
          />
        </div>

        <div className="mb-4">
          <div className="flex items-center mb-1 flex-row">
            <FormLabel>Change Account Permissions</FormLabel>
            <QuestionMarkTooltip
              text="Edit or assign the default role of this teammate across the portal"
              direction="right"
              toolTipstyles="pl-1 -mt-2"
            />
          </div>

          <Select
            isSearchable={false}
            onChange={handleRoleChange}
            options={roleOptions}
            value={teammateData.role}
            placeholder={'Select Roles'}
            styles={reactSelectCustomStyles}
            isDisabled={!canEditAccount}
          />
        </div>

        {meData.role < userRoles.USER_CLIENT && elmFor !== 'client' && (
          <div className="mb-4">
            <FormLabel className="flex items-center gap-1">
              Role (Eg: Engineer, Designer, etc)
            </FormLabel>
            <Select
              isSearchable={true}
              onChange={handleVerticalsChange}
              value={teammateData.verticals}
              options={verticalsOptions}
              styles={reactSelectCustomStyles}
              isDisabled={!canEditAccount}
              placeholder="Select Role"
              components={{ MenuList: CustomMenu }}
              selectProps={{
                toggleCreateRoleModal: toggleCreateRoleModal,
                canAdd: true
              }}
            />
          </div>
        )}

        {teammateData.role.value !== userRoles.AGENCY_ADMIN && (
          <div className="mb-4">
            <div className="flex items-center flex-row mb-1">
              <FormLabel>Assigned Projects</FormLabel>
              <QuestionMarkTooltip
                text="Assign any project to this teammate"
                direction="right"
                toolTipstyles="pl-1 -mt-2"
              />
            </div>
            <div className="mb-4">
              <Select
                onChange={handleProjectsChange}
                placeholder="Assign Project"
                isSearchable={false} // preventing auto fill
                options={projectOptions}
                value={teammateData.assignedWorkspace}
                isMulti={true}
                styles={reactSelectCustomStyles}
                maxMenuHeight={120}
                isDisabled={!canEditAccount}
              />
              {/* <AssignedProjects
              teammateData={teammateData}
              workspaces={workspaces}
              data={data}
              handleProjectsChange={handleProjectsChange}
            /> */}
            </div>

            {/* {selectWorkspace ? (
            <div className="p-2">
              <FormLabel>Select project</FormLabel>
              <div className="mt-1">
                <Select
                  menuPlacement="top"
                  isSearchable={true}
                  options={projectOptions}
                  onChange={handleSelectWorkspace}
                  styles={reactSelectCustomStyles}
                  maxMenuHeight="100px"
                />
              </div>
            </div>
          ) : null}
          <Button
            startIcon={<Add fontSize="small" />}
            variant="outlined"
            size="small"
            onClick={() => setSelectWorkspace(true)}
          >
            Add New
          </Button> */}
          </div>
        )}

        <div className="mt-2">
          <FormLabel>Reset Password</FormLabel>
          <TextField
            className="w-full"
            placeholder="Add New Password"
            name="new-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            onBlur={validPasswordHandler}
            disabled={!canEditAccount}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={togglePasswordView}>
                    {!showPassword ? (
                      <EyeIcon className="text-base" />
                    ) : (
                      <EyeSlashIcon className="text-base" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {password && !passwordValid && (
            <p className="text-xs mt-1" style={{ color: '#f44336' }}>
              Password should be a combination of an uppercase/lowercase letter,
              a number, a symbol and length between 8 to 20.
            </p>
          )}
        </div>
      </DrawerContent>
      <DrawerFooter className={classes.panelFooter}>
        <Button
          variant="outlined"
          onClick={handleDrawerClose}
          className="w-full"
        >
          Cancel
        </Button>

        <LoadingButton
          onClick={handleSaveChanges}
          loading={loading}
          className="w-full"
          disabled={!canEditAccount}
        >
          Save Changes
        </LoadingButton>
      </DrawerFooter>
      <VerticalsModal
        open={openVerticalModal}
        onClose={toggleCreateRoleModal}
        addNewVertical={handleVerticalsChange}
      />
    </CustomDrawer>
  )
}

// const AssignedProjects = ({
//   teammateData,
//   workspaces,
//   data,
//   handleProjectsChange
// }) => {
//   // console.log(teammateData)
//   // const [projectOptions, setProjectOptions] = useState([])
//   // const [selectedProjects, setSelectedProjects] = useState('')

//   // useEffect(() => {
//   //   if (teammateData.assignedWorkspace.length) {
//   //     setProjectOptions()
//   //   }
//   // }, [teammateData.assignedWorkspace])

//   useEffect(() => {}, [])

//   const getWorkspaceRole = (workspaceId) => {
//     const workspace = workspaces.data.find((item) => item._id === workspaceId)
//     let userObj

//     if (workspace) {
//       userObj = [
//         ...workspace.assignedAgencyTeam,
//         ...workspace.assignedClientTeam
//       ].find((item) => item.user._id === data._id)
//     }

//     return userObj?.role?.label ?? 'viewer'
//   }

//   const handleAssignProject = (val) => {
//     console.log(val)
//     // handleProjectsChange([...val])
//     // let e = [...event]
//     // setDataSet((prev) => ({ ...prev, newWorkspace: e }))
//     // setClientWorkspaces(e)
//   }

//   return (
//     <Select
//       onChange={handleAssignProject}
//       placeholder="Assign Project"
//       isSearchable={false} // preventing auto fill
//       options={[]}
//       value={teammateData.assignedWorkspace}
//       isMulti={true}
//       styles={reactSelectCustomStyles}
//       maxMenuHeight={120}
//     />
//     // <TableContainer mb={2}>
//     //   <Thead>
//     //     <Th>Project</Th>
//     //     <Th>Role (for project)</Th>
//     //   </Thead>
//     //   <Tbody>
//     //     {teammateData.assignedWorkspace.length > 0 ? (
//     //       teammateData.assignedWorkspace.map((item, index) => (
//     //         <Tr key={item.value} className="">
//     //           <Td className="px-6 py-4 whitespace-nowrap text-gray-900 font-medium text-xs">
//     //             {item.label}
//     //           </Td>
//     //           <Td className="px-6 py-4 whitespace-nowrap text-xs font-medium">
//     //             <span className="capitalize">
//     //               {getWorkspaceRole(item.value)}
//     //             </span>
//     //           </Td>
//     //         </Tr>
//     //       ))
//     //     ) : (
//     //       <Tr className="tracking-wider bg-white">
//     //         <Th colSpan="2" className="text-left">
//     //           No projects assigned
//     //         </Th>
//     //       </Tr>
//     //     )}
//     //   </Tbody>
//     // </TableContainer>
//   )
// }

export default EditTeammatePanelNew
