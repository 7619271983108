import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
  CustomModal,
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  createFolder,
  updateFolderById,
  updateFileById
} from 'thunks/fileAssets/actions'
import {
  FormLabel,
  LoadingButton,
  RoundedTextInput
} from 'global/globalComponents'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { Button } from '@material-ui/core'

const CreateRenameAssetModal = ({
  open,
  onClose,
  data,
  elmFor,
  actionType
}) => {
  const [loading, setLoading] = useState(false)
  const [fieldValue, setFieldValue] = useState('')
  const { workspaceId, dirIds: dirIdsString } = useParams()
  let currentDirId = dirIdsString.split('/').reverse()[0]
  const [heading, setHeading] = useState('')
  const dispatch = useDispatch()
  const currentWSAssetsData = useSelector(
    (state) => state.filesAssets.currentWSAssets.data
  )

  useLayoutEffect(() => {
    if (open && actionType === 'create') setFieldValue('')
  }, [open])

  useEffect(() => {
    if (actionType === 'create') {
      setHeading('Create')
    } else {
      setHeading('Rename')
    }
  }, [elmFor, actionType])

  useEffect(() => {
    if (data) {
      setFieldValue(data.name ?? '')
    } else {
      setFieldValue('')
    }
  }, [data])

  const handleNameChange = (e) => {
    if (e.target.value.indexOf('.') !== -1) return
    setFieldValue(e.target.value)
  }

  const folderMatch = (name) => {
    const match = currentWSAssetsData[currentDirId].folders.findIndex(
      (item) => item.name === name
    )

    return match > -1
  }

  const handleCreateNewFolder = (name) => {
    if (folderMatch(name)) {
      dispatch(
        fireErrorToaster(`This folder already has a directory named ${name}`)
      )
      return
    }

    setLoading(true)
    dispatch(
      createFolder(
        {
          name,
          workspace: workspaceId,
          parent: currentDirId
        },
        submitCallback
      )
    )
  }

  const handleRenameFolder = (name) => {
    if (folderMatch(name)) {
      dispatch(
        fireErrorToaster(`This folder already has a directory named ${name}`)
      )
      return
    }

    setLoading(true)
    dispatch(updateFolderById(data._id, { name }, submitCallback))
  }

  const handleRenameFile = (name) => {
    const match = currentWSAssetsData[currentDirId].files.findIndex(
      (item) => item.name + item.extension === name + data.extension
    )

    if (match > -1) {
      dispatch(
        fireErrorToaster(
          `This folder already has a file named ${name}${data.extension}`
        )
      )
      return
    }

    setLoading(true)
    dispatch(
      updateFileById(
        data._id,
        {
          type: 'renameFileName',
          fileAssetsFolder: data.fileAssetsFolder,
          oldKey: data.name + data.extension,
          newKey: name + data.extension
        },
        submitCallback
      )
    )
  }

  const submitCallback = (res, err) => {
    setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      dispatch(
        fireSuccessToaster(
          actionType === 'create'
            ? 'New folder created successfully!'
            : 'Changes saved!'
        )
      )
      onClose()
    }
  }

  // const handleRenameFile = (name) => {
  //   setLoading(true)
  //   dispatch(updateFileById(data.id, { name }, submitCallback))
  // }

  const handleSubmit = (e) => {
    e.preventDefault()
    const name = fieldValue.trim()

    if (actionType === 'create') handleCreateNewFolder(name)
    else {
      if (elmFor === 'folder') {
        handleRenameFolder(name)
      } else {
        handleRenameFile(name)
      }
    }
  }

  return (
    <CustomModal open={open} handleClose={onClose} size="extraSmall">
      <CustomModalHeader heading={heading} />
      <CustomModalBody>
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="flex flex-col">
            <FormLabel>
              {elmFor === 'folder' ? 'Folder' : 'File'} name
            </FormLabel>
            <RoundedTextInput
              value={fieldValue}
              onChange={handleNameChange}
              autoFocus
            />
          </div>
        </form>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          disabled={fieldValue.trim() === ''}
        >
          {actionType === 'create' ? 'Create' : 'Rename'}
        </LoadingButton>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </CustomModalFooter>
    </CustomModal>
  )
}

export default CreateRenameAssetModal
