import React, { useMemo, useCallback, useState, useEffect } from 'react'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as AudioFile } from 'static/svg/music-note.svg'
import { ReactComponent as VideoFile } from 'static/svg/film-reel.svg'
import { ReactComponent as NoteText } from 'static/svg/note-text.svg'
import { handleFilePreview } from 'thunks/generlAppState/actions'
import { useDispatch } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { useHistory, useParams, Link, useRouteMatch } from 'react-router-dom'
import { fetchAllTaskAttachmentsByWorkspace } from 'thunks/task/actions'
import clsx from 'clsx'
import { ChevronRightRounded } from '@material-ui/icons'
import { ReactComponent as HomeIcon } from 'static/svg/home.svg'
import FilesAssetsStyles from './filesAssetsStyles'

const TaskAttachmentsFolder = ({ loading, allAttachments }) => {
  const dispatch = useDispatch()
  const classes = FilesAssetsStyles()
  const history = useHistory()
  const { workspaceId } = useParams()
  const { url } = useRouteMatch()
  // const [allAttachments, setAllAttachments] = useState({
  //   loading: true,
  //   data: []
  // })

  // useEffect(() => {
  //   fetchAllTaskAttachmentsByWorkspace(workspaceId, (res, err) => {
  //     if (!err) {
  //       const mappedData = []

  //       for (let obj of res) {
  //         for (let url of obj.imageUrl) {
  //           mappedData.push({ _id: obj._id, title: obj.title, url: url })
  //         }
  //       }
  //       setAllAttachments({ loading: false, data: mappedData })
  //     } else {
  //       setAllAttachments({ loading: false, data: [] })
  //     }
  //   })
  // }, [])

  const handleOpenPreview = useCallback(e => {
    dispatch(
      handleFilePreview({ open: true, file: e.currentTarget.dataset.url })
    )
  }, [])

  return (
    <div className="h-full">
      <div className="flex justify-between items-center mb-6 mt-4">
        <ol className="flex items-center space-x-2 'text-gray-500'">
          <li className="flex items-center text-smaller lg:text-sm">
            <Link className="mr-2" to={url.split('/files')[0] + '/files'}>
              <HomeIcon className="text-primary-main w-5 h-5 text-gray-500 hover:text-gray-700" />
            </Link>
            <ChevronRightRounded
              className="flex-shrink-0 h-5 w-5 text-gray-400"
              fontSize="small"
            />
          </li>
          <li className="flex items-center text-smaller lg:text-sm cursor-pointer hover:text-gray-700 font-bold text-gray-700">
            Task Attachments
          </li>
        </ol>
      </div>
      <div className="">
        {/* <header className="mb-6">
            <SectionHeading>All Task Attachments</SectionHeading>
          </header> */}
        <div>
          {loading ? (
            <LoadingAnimation />
          ) : allAttachments.length ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gridGap: '1.5rem'
              }}
            >
              {allAttachments.map(item => (
                <AttachmentCard
                  key={item._id}
                  attachment={item}
                  openPreview={handleOpenPreview}
                />
              ))}
            </div>
          ) : (
            <p className="text-lg font-medium h-20 bg-white text-center pt-6">
              No data found!
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

const AttachmentCard = React.memo(({ attachment, openPreview }) => {
  const fileType = useMemo(() => {
    if (/\.(png|svg|jpe?g|gif|tiff?|webp)$/i.test(attachment.url))
      return 'image'
    if (/\.(m4a|flac|mp3|wav|aiff)$/i.test(attachment.url)) return 'audio'
    if (/\.(mp4|mov|avi|flv|mkv|wmv|avchd|webm|mpeg-4)$/i.test(attachment.url))
      return 'video'
    return 'other'
  }, [attachment.url])

  return (
    <div
      className="bg-white rounded-lg overflow-hidden border border-gray-200 cursor-pointer"
      onClick={openPreview}
      data-url={attachment.url}
    >
      <header className="bg-gray-200 pt-4 h-36">
        {fileType === 'image' ? (
          <img
            src={attachment.url}
            alt=""
            className="w-full h-full object-contain"
          />
        ) : fileType === 'audio' ? (
          <AudioFile className="h-full w-full text-primary-main p-2" />
        ) : fileType === 'video' ? (
          <VideoFile className="h-full w-full text-primary-main p-2" />
        ) : (
          <NoteText className="h-full w-full text-primary-main p-2" />
        )}
      </header>
      <div className="p-4">
        <h4 className="font-medium text-sm text-gray-600">
          {attachment.title}
        </h4>
      </div>
    </div>
  )
})

const LoadingAnimation = () => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gridGap: '1.5rem'
      }}
    >
      {new Array(4).fill('').map((item, index) => (
        <Skeleton
          key={index}
          variant="rect"
          height={200}
          className="rounded-lg"
        />
      ))}
    </div>
  )
}

export default React.memo(TaskAttachmentsFolder)
