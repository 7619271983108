import { makeStyles, createStyles } from '@material-ui/core'

const templateStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
      // userSelect: 'none'
    },
    paper: {
      width: '80vw',
      height: '80vh',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      outline: 'none'
    },
    closeButton: {
      position: 'absolute',
      top: '1rem',
      right: '1rem'
    },
    btn: {
      '&> svg': {
        color: 'gray',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.enteringScreen,
          easing: theme.transitions.easing.easeInOut
        })
      },
      '&.expanded': {
        '&> svg': {
          transform: 'rotate(90deg)',
          transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.leavingScreen
          })
        }
      }
    },
    templateWrapper: {
      // width: '400px',
      minHeight: `calc(100vh - ${theme.custom.header.height}px)`,
      backgroundColor: '#fff'
    },
    templateGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '1rem'
    },

    [theme.breakpoints.down('lg')]: {
      templateGrid: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      }
    },

    [theme.breakpoints.down('md')]: {
      templateGrid: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },

    [theme.breakpoints.down('sm')]: {
      templateGrid: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },
    activeRow: {
      '&.active': {
        backgroundColor: '#F2F0FF'
      }
    }
  })
)

export default templateStyles
