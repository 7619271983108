import { makeStyles } from '@material-ui/core'

const overviewStyles = makeStyles({
  container: {
    display: 'grid',
    gridAutoFlow: 'dense',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridAutoRows: 100,
    gridGap: '1.5rem'
  },

  tasksCount: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: '1rem',
    gridRow: 'span 4'
  },

  tallerCard: {
    gridRow: 'span 3'
  },

  '@media(min-width: 768px)': {
    container: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },

    tasksCount: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '1rem',
      gridRow: 'span 2'
    },
    tallerCard: {
      gridRow: 'span 3'
    }
  },

  '@media(min-width: 1284px)': {
    container: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridAutoRows: 150
    },
    tallerCard: {
      gridRow: 'span 2'
    },

    tasksCount: {
      gridColumn: '1/2',
      gridRow: '1/3'
    },
    projectCompletion: {
      gridColumn: '2/3',
      gridRow: '1/2'
    },
    projectDates: {
      gridColumn: '2/3',
      gridRow: '2/3'
    },
    projectInfo: {
      gridColumn: '3/4',
      gridRow: '1/3'
    },
    tallerCardSecondRow: {
      gridRow: '3 / 5'
    },
    projectCost: {
      gridColumn: '3/4',
      gridRow: '5 / 7'
    }
  },

  '@media(min-width: 1600px)': {
    container: {
      gridTemplateColumns: 'repeat(4, 1fr)'
    },
    tasksOverdue: {
      gridRow: '1/3',
      gridColumn: '3/4'
    },
    projectInfo: {
      gridColumn: '4/5'
    },
    timesheet: {
      gridColumn: '2/3',
      gridRow: '3/5'
    },
    projectHours: {
      gridColumn: '4/5'
    },
    projectCost: {
      gridColumn: '4/5'
    },
    taskTimeWise: {
      gridColumn: '3/4'
    }
  }
})
export default overviewStyles
