import { ColoredAvatars, FormLabel } from 'global/globalComponents'
import Select from 'react-select'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button
} from '@material-ui/core'
import { previousDay } from 'date-fns'
import {
  CustomModalBody,
  CustomModalFooter,
  CustomModalHeader
} from 'global/globalComponents/CustomModal'
import { LoadingButton } from 'global/globalComponents'
import CustomTableHeader from 'global/globalComponents/CustomTable/CustomTableHeader'
import { ReactComponent as ArrowRightIcon } from 'static/svg/arrow-right.svg'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'

const initialOptions = [
  { label: 'Do not import user', value: false },
  { label: 'Select user', value: 'select user' }
]

const Step3 = ({
  setStep,
  handleSave,
  loading,
  collaborators,
  setCollaborators
}) => {
  const dispatch = useDispatch()
  const userTeammates = useSelector(state => state.userTeammates)
  const [teammates, setTeammates] = useState([])

  // useEffect(() => {
  //   Object.keys(collaborators).forEach((collab) => {
  //     collaborators[collab] = {
  //       userState: { label: 'Do not import user', value: false },
  //       options: initialOptions
  //     }
  //   })
  //   setCollaborators()
  // }, [collaborators])

  useEffect(() => {
    setTeammates(
      userTeammates.data.map(mate => ({
        label: mate.name,
        value: mate._id,
        ...mate
      }))
    )
  }, [userTeammates])

  useEffect(() => {
    if (!userTeammates.fetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [])

  const optionLabel = mate => {
    if (mate.name) {
      return (
        <div className="flex space-x-4 items-center">
          <ColoredAvatars user={mate} size="small" />
          <span className="pr-2 text-sm">{mate.name}</span>
        </div>
      )
    } else {
      return <div>{mate.label}</div>
    }
  }

  const onChange = (e, user) => {
    if (e) {
      if (!e.value) {
        setCollaborators(prev => ({
          ...prev,
          [user]: { userState: e, options: initialOptions }
        }))
      } else {
        setCollaborators(prev => ({
          ...prev,
          [user]: { userState: e, options: teammates }
        }))
      }
    } else {
      setCollaborators(prev => ({
        ...prev,
        [user]: {
          userState: { label: 'Do not import user', value: false },
          options: initialOptions
        }
      }))
    }
  }

  console.log(collaborators, 'usersssssssssssss')

  return (
    <div id="import">
      <CustomModalHeader heading="User Mapping" className="text-center" />
      <CustomModalBody style={{ height: '450px' }}>
        <div className="w-2/3 mx-auto">
          <p className="text-xs text-center">
            Map users to ClickUp users in order to preserve assignees, history,
            and task activity. If you don't map users, the data will still be
            imported, but will not be synced to a ClickUp user and assignees
            that aren't mapped to ClickUp will not be preserved.
          </p>
          <form>
            <div className="flex space-x-4 items-center justify-between w-full mt-4">
              <p className="w-1/2 text-xs">Imported Users:</p>
              <p className="w-1/2 pl-4 text-xs">Your Users:</p>
            </div>

            {Object.keys(collaborators).map(item => (
              <div className="flex space-x-4 items-center justify-between w-full mt-4">
                <TextField value={item} className="w-full" disabled={true} />
                <ArrowRightIcon className="text-primary-main" width={40} />
                <Select
                  menuPosition={'fixed'}
                  styles={{
                    ...reactSelectCustomStyles,
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                  }}
                  onChange={e => onChange(e, item)}
                  options={collaborators[item].options}
                  value={collaborators[item].userState}
                  className="w-full"
                  getOptionLabel={optionLabel}
                  isClearable={
                    collaborators[item].userState.value ? true : false
                  }
                />
              </div>
            ))}
          </form>
        </div>
      </CustomModalBody>
      <CustomModalFooter>
        <LoadingButton loading={loading} onClick={handleSave}>
          Complete
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={() => {
            setStep(3)
            setCollaborators({})
          }}
        >
          Back
        </Button>
      </CustomModalFooter>
    </div>
  )
}

export default Step3
