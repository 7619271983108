import axios from 'axios'
import { getAuthToken } from 'utils'
import * as Sentry from '@sentry/react'

const APP_BASE_URL = process.env.REACT_APP_CONNECTION_URL

axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.baseURL = APP_BASE_URL

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.data?.message === 'Account has been Blocked') {
      const domain = window.location.href
        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
        .split('/')[0]
        .replace('/', '')
      localStorage.removeItem('authToken')
      if (!window.location.href.includes('/login')) {
        alert(
          'Sorry! Your account has been blocked. Please contact your support'
        )
        window.location.href = `${window.location.protocol}//${domain}/login`
      }
    }
    if (process.env.NODE_ENV !== 'development') {
      Sentry.captureException(error)
    }
    return Promise.reject(error)
  }
)

axios.interceptors.request.use(function (config) {
  config.headers['Authorization'] = getAuthToken()
  config.baseURL = `${APP_BASE_URL}`
  return config
})
