import { useState } from 'react'
import EmbedModalUI from './EmbedModalUI'
import { Box } from '@material-ui/core'
// import { ReactComponent as EmbedCalender } from 'static/svg/embed-calender.svg'
import { ReactComponent as ServiceIcon } from 'static/svg/list-alt.svg'
import { embedObj, sideDrawerLinks } from './EmbedModalData'
import clsx from 'clsx'
import embedStyles from './EmbedStyles'
import { ReactComponent as BoardImg } from 'static/svg/board.svg'

const EmbedModal = ({ anchorPosFromLeft, onClose }) => {
  const classes = embedStyles(anchorPosFromLeft)

  const [selectedEmbedLink, setSelectedEmbedLink] = useState({
    id: 'board',
    name: 'Board',
    description:
      'Build your perfect Board and easily drag-and-drop tasks between columns.',
    image: <BoardImg />
  })

  const handleEmbedLink = key => {
    setSelectedEmbedLink(embedObj[key.toLowerCase()])
  }

  const handleCloseModal = e => {
    if (e.target === e.currentTarget) onClose()
  }

  return (
    <div className={classes.modalBackdrop} onClick={handleCloseModal}>
      <div className={`${classes.wrapper}`}>
        <div
          style={{
            overflowY: 'auto',
            borderRadius: '6px 0 6px 6px'
          }}
        >
          <div className={`${classes.search} text-smaller lg:text-sm`}>
            <ServiceIcon className="h-4 w-4 pr-1" />
            Add View
          </div>
          <div className={classes.container}>
            <div className={classes.sidebarContainer}>
              <Box className="">
                <p
                  style={{
                    color: '#7c828d',
                    opacity: 0.6,
                    textAlign: 'left',
                    fontSize: '13px',
                    padding: '0 9px 9px'
                  }}
                >
                  EMBED VIEWS
                </p>
              </Box>
              {sideDrawerLinks.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={clsx(
                      { active: selectedEmbedLink.id === item.id },
                      classes.sidebarItems
                    )}
                    onClick={() => handleEmbedLink(item.text)}
                  >
                    <div className="relative w-4 mr-2">
                      <img src={item.icon} alt="" />
                    </div>
                    <div className="text-smaller lg:text-sm">{item.text}</div>
                  </Box>
                )
              })}
            </div>
            <div>
              <EmbedModalUI {...selectedEmbedLink} handleClose={onClose} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmbedModal
