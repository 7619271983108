// import Pill from 'global/globalComponents/Pill/Pill'
import React, { useEffect, useState } from 'react'
// import SectionHeading from 'global/globalComponents/SectionHeading'
import { useDispatch, useSelector } from 'react-redux'
import { getStripeConnectStatus } from 'thunks/stripeAccount/actions'
import { fetchUserCreatedWebhooks } from 'thunks/webhooks/action'
import { useBreadcrumbs } from 'customHooks'
import { Box, Tabs, Tab } from '@material-ui/core'
import IntegrationCard from 'global/globalComponents/IntegrationCard/IntegrationCard'
import { fetchAllIntegrationsForCurrentUser } from 'thunks/integration/actions'
import integrationCardDetails from 'static/IntegrationCardDetails/IntegrationCardDetail'
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import Connection from './Connection'
import { userRoles } from 'utils'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'
import clsx from 'clsx'
import setupStyles from './setupStyles'

// const filterButtons = ['All', 'Support', 'Payments', 'Marketing', 'Others']

const Integrations = () => {
  const classes = setupStyles()
  const meData = useSelector(state => state.me.data)
  const userStripeInfo = useSelector(state => state.userStripeInfo)
  const userIntegrations = useSelector(state => state.userIntegrations)
  const userWebhooks = useSelector(state => state.userWebhooks)
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [integrationState, setIntegrationState] = useState(0)
  const dispatch = useDispatch()
  const history = useHistory()
  const [displayCardsData, setDisplayCardsData] = useState([
    integrationCardDetails.stripe,
    integrationCardDetails.zapier,
    ...integrationCardDetails.webhooks,
    ...integrationCardDetails.chat
  ])

  const [visibleData, setVisibleData] = useState(displayCardsData)
  const { path } = useRouteMatch()
  const [selectedTab, setSelectedTab] = useState('all')

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Integrations',
      link: `/settings/integrations`,
      index: 1
    }
  ])

  useEffect(() => {
    dispatch(fetchUserCreatedWebhooks())
  }, [])

  useEffect(() => {
    if (
      !userIntegrations?.fetchedFirstTime &&
      [
        userRoles.USER_AGENCY
        // userRoles.AGENCY_MANAGER,
        // userRoles.AGENCY_ADMIN
      ].includes(meData.role)
    ) {
      dispatch(fetchAllIntegrationsForCurrentUser())
    }
  }, [userIntegrations?.fetchedFirstTime])

  const modalHandler = data => {
    setOpen(true)
    setModalData(data)
  }

  const handleClose = () => {
    setOpen(false)
    setModalData({})
  }

  const checkIntegrationIsActive = (arr, name) => {
    if (arr) {
      return arr?.find(el => el?.appName === name?.toLowerCase())
    }
  }

  useEffect(() => {
    const { stripe, zapier, webhooks, chat } = integrationCardDetails
    const NewChatCards = chat?.map(el => {
      let app = checkIntegrationIsActive(userIntegrations.data, el.name)
      return app
        ? {
            ...el,
            connected: app.isActive,
            handlerFunction: modalHandler,
            modalContent: { ...el.modalContent, key: app?.key, appId: app._id }
          }
        : { ...el, handlerFunction: modalHandler }
    })
    const newWebhooks = webhooks?.map(el => {
      if (el.name === 'Slack') {
        return { ...el, connected: Boolean(userWebhooks?.slackData?.length) }
      } else {
        let isActive = userWebhooks?.createdWebhook?.filter(
          item => item.isActive === true
        )?.length
        return { ...el, connected: isActive > 0 }
      }
    })
    const newStripe = {
      ...stripe,
      connected: Object.keys(userStripeInfo?.data).includes('accountId'),
      handlerFunction: handleClick
    }
    const newZapier = {
      ...zapier,
      handlerFunction: handleZapier
    }
    setDisplayCardsData([newStripe, ...newWebhooks, ...NewChatCards, newZapier])
  }, [userWebhooks, userIntegrations?.data, userStripeInfo?.data])

  useEffect(() => {
    // let filterButton = filterButtons[integrationState]
    if (selectedTab === 'all') {
      setVisibleData(displayCardsData)
    } else if (selectedTab === 'others') {
      setVisibleData(
        displayCardsData?.filter(
          app => !['support', 'marketing', 'payments'].includes(app.type)
        )
      )
    } else {
      setVisibleData(displayCardsData?.filter(app => app.type === selectedTab))
    }
  }, [displayCardsData, integrationState])

  function handleClick() {
    if (Object.keys(userStripeInfo.data).includes('accountId')) {
      history.push('/agency/settings/integrations/stripe')
    } else {
      dispatch(getStripeConnectStatus(stripeCallback))
    }
  }

  function stripeCallback(res, error) {
    if (!error) {
      window.open(res.url)
    }
  }

  const handleZapier = () => {
    const link =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 'https://zapier.com/developer/public-invite/143697/b7683e69b6e937ecdc6ecbaca3d0dd94/'
        : ' https://zapier.com/developer/public-invite/151020/45c1d927e4cadb5cadec3e30dbf8c4a2/'
    window.open(link)
  }

  useEffect(() => {
    dispatch(getStripeConnectStatus())
  }, [])

  // const handleIntegrationState = (value, name) => {
  //   setIntegrationState(value)
  // }

  const handleTabChange = (e, newVal) => {
    setSelectedTab(newVal)
    setIntegrationState(newVal)
  }

  return (
    <Wrapper>
      <Box>
        <SecondaryHeading className="mb-3 lg:mb-5">
          Integrations
        </SecondaryHeading>

        <div className="mb-4 lg:mb-6">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
          >
            {['All', 'Support', 'Payments', 'Marketing', 'Others'].map(
              (item, index) => (
                <Tab
                  label={item}
                  value={item.toLocaleLowerCase()}
                  key={index}
                />
              )
            )}
          </Tabs>
        </div>
      </Box>
      <div className={classes.integrationCards} style={{ margin: '24px 0' }}>
        {visibleData?.map((item, index) => (
          <IntegrationCard key={item.name} data={item} />
        ))}
      </div>
    </Wrapper>
  )
}

export default Integrations
