import { TextField, Box, SvgIcon } from '@material-ui/core'
import { ReactComponent as PlayCircle } from 'static/svg/play-circle.svg'
import LockedFeatureOverlay from 'components/LockedFeatureOverlay/LockedFeatureOverlay'
import useBreadcrumbs from 'customHooks/useBreadcrumbs'
import { LoadingButton } from 'global/globalComponents'
import StripeMessage from 'global/globalComponents/StripeMessage/StripeMessage'
import FormLabel from 'global/globalComponents/FormLabel/FormLabel'
import SectionHeading from 'global/globalComponents/SectionHeading'
import EmailSetting from './EmailSetting'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAndReplaceBreadCumbs } from 'thunks/breadcrumbs/action'
import { whitelabelSubDomain } from 'thunks/whiteLabel/action'
import { getUserPath } from 'utils/userRoles'
import { updateUser } from 'thunks/user/actions'
import { fetchMeData } from 'thunks/me/actions'
import { ToggleSwitch } from 'global/globalComponents'
// import { ReactComponent as LockIcon } from 'static/svg/lock.svg'
// import { LockOpen } from '@material-ui/icons'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import SecondaryHeading from 'global/globalComponents/SectionHeading/SecondaryHeading'

const Branding = () => {
  const dispatch = useDispatch()
  const me = useSelector(state => state.me.data)
  const userActivePlan = useSelector(state => state.userActivePlanInfo.data)

  const [subDomainName, setSubDomainName] = useState('')
  const [whitelabelEnable, setWhitelabelEnable] = useState(false)
  const [upgradeDomainName, setUpgradeDomainName] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [upgradePlanModal, setUpgradePlanModal] = useState(false)

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  useBreadcrumbs([
    {
      name: 'Settings',
      link: `/settings/general`,
      index: 0
    },
    {
      name: 'Branding',
      link: `/settings/branding`,
      index: 1
    }
  ])

  useEffect(() => {
    setWhitelabelEnable(me?.isWhiteLabelActive)
  }, [me?.isWhiteLabelActive])

  useEffect(() => {
    const whitelabelEnablePlans = [
      'Plan-C',
      'Plan-D',
      'Plan-E',
      'Enterprise Plan',
      'Enterprise Monthly Plan',
      'Enterprise Yearly Plan',
      'AppSumo Enterprise Plan 1',
      'AppSumo Enterprise Plan 2',
      'AppSumo Enterprise Plan 3',
      'AppSumo Enterprise Plan 4',
      'AppSumo Enterprise Plan 5',
      'AppSumo Enterprise Plan 6',
      'AppSumo Enterprise Plan 7',
      'AppSumo Enterprise Plan 8'
    ]
    // console.log(me?.payment, me?.team?.payment, 'kkkk')
    const checkPlan = whitelabelEnablePlans.find(
      plan => plan === (me?.payment?.name ?? me?.team?.payment?.name)
    )
    if (!checkPlan && me?.isWhiteLabelActive) {
      whitelabelActiveHandler(false)
    }
  }, [me?.payment, whitelabelEnable])

  useEffect(() => {
    if (Object.keys(me).includes('whitelabelUrl'))
      setSubDomainName(me.whitelabelUrl)
  }, [me])

  const handleSubDomainChange = e => {
    setSubDomainName(e.target.value)
  }

  const whitelabelActiveHandler = value => {
    setLoading(true)
    dispatch(
      updateUser({ isWhiteLabelActive: value }, whitelabelActiveCallBack)
    )
  }

  const whitelabelHandler = () => {
    const whitelabelEnablePlans = [
      'Plan-C',
      'Plan-D',
      'Plan-E',
      'Enterprise Plan',
      'Enterprise Monthly Plan',
      'Enterprise Yearly Plan',
      'AppSumo Enterprise Plan 1',
      'AppSumo Enterprise Plan 2',
      'AppSumo Enterprise Plan 3',
      'AppSumo Enterprise Plan 4',
      'AppSumo Enterprise Plan 5',
      'AppSumo Enterprise Plan 6',
      'AppSumo Enterprise Plan 7',
      'AppSumo Enterprise Plan 8'
    ]
    // console.log(me?.payment, me?.team?.payment, 'kkkk')
    const checkPlan = whitelabelEnablePlans.find(
      plan => plan === (me?.payment?.name ?? me?.team?.payment?.name)
    )
    if (whitelabelEnable) {
      whitelabelActiveHandler(false)
    } else if (!whitelabelEnable && checkPlan) {
      whitelabelActiveHandler(true)
    } else {
      dispatch(
        fireErrorToaster('please upgrade your plan to activate this feature')
      )
    }
  }

  const whitelabelActiveCallBack = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      setWhitelabelEnable(!whitelabelEnable)
      dispatch(
        fireSuccessToaster(
          !whitelabelEnable ? 'Enabled Successfully' : 'Disabled Successfully'
        )
      )
      dispatch(fetchMeData())
    }
  }

  const whitelabelCallback = (res, err) => {
    // dispatch(fetchMeData())
    setLoadingSubmit(false)
    if (err) {
    } else {
      setUpgradeDomainName(true)
    }
  }

  const handleSubDomainBlurChange = () => {
    let validSubDomain = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
    if (!validSubDomain.test(subDomainName)) {
      return dispatch(fireErrorToaster('Please enter valid domain'))
    }
    setLoadingSubmit(true)
    if (Object.keys(me).includes('whitelabelUrl')) {
      dispatch(
        whitelabelSubDomain(
          me._id,
          {
            oldWhitelabelUrl: me.whitelabelUrl,
            whitelabelUrl: subDomainName
          },
          whitelabelCallback
        )
      )
    } else {
      dispatch(
        whitelabelSubDomain(
          me._id,
          {
            whitelabelUrl: subDomainName
          },
          whitelabelCallback
        )
      )
    }
    // if (
    //   /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
    //     subDomainName
    //   )
    // ) {
    //   setLoadingSubmit(true)
    //   if (Object.keys(me).includes('whitelabelUrl')) {
    //     dispatch(
    //       whitelabelSubDomain(
    //         me._id,
    //         {
    //           oldWhitelabelUrl: me.whitelabelUrl,
    //           whitelabelUrl: subDomainName
    //         },
    //         whitelabelCallback
    //       )
    //     )
    //   } else {
    //     dispatch(
    //       whitelabelSubDomain(
    //         me._id,
    //         {
    //           whitelabelUrl: subDomainName
    //         },
    //         whitelabelCallback
    //       )
    //     )
    //   }
    // } else dispatch(fireErrorToaster('Set a valid domain name'))
  }

  function subDomain(url) {
    url = url?.replace(new RegExp(/^\s+/), '') // START
    url = url?.replace(new RegExp(/\s+$/), '') // END
    url = url?.replace(new RegExp(/\\/g), '/')
    url = url?.replace(new RegExp(/^www\./i), '')
    url = url?.replace(new RegExp(/\/(.*)/), '')
    if (url?.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
      url = url?.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), '')
    } else if (url?.match(new RegExp(/\.[a-z]{2,4}$/i))) {
      url = url?.replace(new RegExp(/\.[a-z]{2,4}$/i), '')
    }
    var subDomain = url?.match(new RegExp(/\./g)) ? true : false
    return subDomain
  }

  return (
    <Wrapper>
      <div>
        <div className="flex justify-between">
          <div className="flex items-center mb-6 lg:mb-8">
            <SecondaryHeading className="mb-0">Branding</SecondaryHeading>
            <a
              href="https://youtu.be/Pw6xvWVLrOA"
              target="_blank"
              className="pl-1"
            >
              <SvgIcon
                component={PlayCircle}
                style={{
                  color: '#1600e5',
                  fontSize: '1rem'
                }}
                className="cursor-pointer"
                viewBox="0 0 600 476.6"
              />
            </a>
          </div>
          <div className="flex">
            <p className="py-1 px-4 text-primary-dark font-medium text-sm md:text-base lg:text-xl">
              Enable Whitelabel
            </p>
            <div>
              <ToggleSwitch
                checked={whitelabelEnable}
                onChange={whitelabelHandler}
                loading={loading}
              />
            </div>
          </div>
          {/* <LockedFeatureOverlay active = {upgradePlanModal} /> */}
        </div>
        <StripeMessage
          action={true}
          url="https://help.clientvenue.com/"
          stripProps="w-full p-2 bg-primary-light"
          actionText="Learn more"
        >
          Whitelabel settings
        </StripeMessage>
        <div className="flex flex-row">
          <h3 className="text-sm md:text-base lg:text-xl font-medium text-primary-dark mt-6">
            Domain whitelabel setup
          </h3>
        </div>

        <div className="my-4 text-smaller lg:text-sm">
          <p className="">
            To enable mapping of a custom domain on your portal first add your
            domain in the field &amp; then use the values in your DNS panel
          </p>
        </div>
        <div>
          <div className="flex-1">
            <div className="text-smaller lg:text-sm">
              <form
                className="grid grid-cols-12 gap-6"
                onSubmit={e => e.preventDefault()}
              >
                <div className="col-span-5">
                  <div className="flex flex-row">
                    <FormLabel htmlFor="yourDomain">
                      Specify your custom domain
                    </FormLabel>
                  </div>
                  <TextField
                    disabled={!whitelabelEnable}
                    inputProps={{ id: 'yourDomain' }}
                    // type="url"
                    // required
                    variant="outlined"
                    className="w-full"
                    name="subDomainName"
                    placeholder="subdomain.domain.com"
                    value={subDomainName}
                    onChange={handleSubDomainChange}
                    // disabled={Object.keys(me).includes('whitelabelUrl')}
                  />
                </div>
                <div className="col-span-3 flex items-end">
                  <LoadingButton
                    loading={loadingSubmit}
                    onClick={handleSubDomainBlurChange}
                    style={{ padding: '6px 24px' }}
                    disabled={!whitelabelEnable}
                    className=""
                  >
                    Add
                  </LoadingButton>
                </div>
              </form>
              {upgradeDomainName ? (
                <div className="mt-3">
                  <p className="text-red-500">
                    *Please replace old value to new value from your DNS system.
                  </p>
                </div>
              ) : null}
            </div>

            <div className="text-smaller lg:text-sm flex items-center my-4">
              {!Object.keys(me).includes('whitelabelUrl') ? null : !subDomain(
                  me?.whitelabelUrl
                ) ? (
                <div>
                  <div className="flex flex-row">
                    <p className="font-bold text-gray-600 ">Type : </p>
                    <p
                      className="font-bold text-xs text-gray-400 ml-2 "
                      style={{ alignItems: 'center' }}
                    >
                      A
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="font-bold text-gray-600">Name : </p>
                    <p
                      className="text-xs font-medium text-gray-400 ml-2"
                      style={{ alignItems: 'center' }}
                    >
                      {me.whitelabelUrl}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="font-bold text-gray-600">IPv4 Address : </p>
                    <p
                      className="text-xs font-medium text-gray-400 ml-2"
                      style={{ alignItems: 'center' }}
                    >
                      75.2.60.5
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-row">
                    <p className="font-bold text-gray-600 ">Type :&nbsp;</p>
                    <p
                      className="text-tiny lg:text-xs font-medium   text-gray-400  flex"
                      style={{ alignItems: 'center' }}
                    >
                      CNAME
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="font-bold text-gray-600">Name :&nbsp;</p>
                    <p
                      className="text-xs font-medium text-gray-400  flex"
                      style={{ alignItems: 'center' }}
                    >
                      {me?.whitelabelUrl?.split('.')[0]}
                    </p>
                  </div>
                  <div className="flex flex-row">
                    <p className="font-bold text-gray-600">Target :&nbsp;</p>
                    <p
                      className="text-xs font-medium text-gray-400  flex"
                      style={{ alignItems: 'center' }}
                    >
                      {' '}
                      hardcore-sammet-26a69f.netlify.app
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!Object.keys(me).includes('whitelabelUrl') ? null : (
            <div className="mt-4 rounded-md bg-yellow-100 p-4 shadow">
              <p className="text-yellow-700 text-xs mb-2">
                Login to your DNS settings dashboard, and add a &nbsp;
                {!subDomain(me?.whitelabelUrl) ? ' A ' : 'CNAME'} type record
                pointing to <b>hardcore-sammet-26a69f.netlify.app </b>
                <br />
                If you have already done this, allow upto 24 hours for the
                changes to propagate.
              </p>
              <p className="text-yellow-700 text-xs">
                Enable SSL/Proxy from your DNS provider's end
              </p>
            </div>
          )}
        </div>
        <LockedFeatureOverlay active={userActivePlan?.name?.includes('Lite')} />
      </div>
      <div className="mt-8 lg:mt-12">
        <EmailSetting />
      </div>
    </Wrapper>
  )
}

export default Branding
