const debounce = (() => {
  let timerId

  return (cb, time = 300) => {
    if (!cb) return

    clearTimeout(timerId)
    timerId = setTimeout(cb, time)
  }
})()

export default debounce
