import axios from 'axios'
import { errorMessages } from 'utils'
import { AGENCY_PLAN } from './reducers'

/**
 * fetch all plan for agency
 * @param {Function} callback callback function
 */
export const fetchAgencyPlan = (callback) => {
  return async (dispatch) => {
    dispatch({ type: AGENCY_PLAN.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/agencypayment/plan'
      })
      dispatch({ type: AGENCY_PLAN.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err.response?.data?.message || errorMessages.ERROR_MESSAGE,
          true
        )
    }
  }
}
