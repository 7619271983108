const recurringData = [
  {
    interval: 'month',
    interval_count: 1,
    trial_period_days: 0,
    name: '/month ',
    tag: 'monthly'
  },
  {
    interval: 'month',
    interval_count: 3,
    trial_period_days: 0,
    name: '/quarter',
    tag: 'quarterly'
  },
  {
    interval: 'month',
    interval_count: 6,
    trial_period_days: 0,
    name: '/biannual',
    tag: 'biannually'
  },
  {
    interval: 'year',
    interval_count: 1,
    trial_period_days: 0,
    name: '/year',
    tag: 'yearly'
  }
]

export default recurringData
