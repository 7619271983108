import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import { ArrowRight } from '@material-ui/icons'

import timesheetStyles from 'components/ResourceManagement/Timesheets/timesheetStyles'
import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as PeopleIcon } from 'static/svg/people.svg'
import { ReactComponent as SearchIcon } from 'static/svg/search.svg'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import {
  fetchPulseGraphData,
  fetchUsersActivityLogs
} from 'thunks/pulse/action'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTeammateOfCurrentUser } from 'thunks/teammate/actions'
import { ColoredAvatars, CustomTooltip } from 'global/globalComponents'
import { format } from 'date-fns'
import { ReactComponent as AngleLeft } from 'static/svg/angle-left.svg'
import { ReactComponent as AngleRight } from 'static/svg/angle-right.svg'
import { GoBack } from 'global/globalComponents'
import { ReactComponent as DotIcon } from 'static/svg/circle.svg'
import DatePicker from 'react-datepicker'
import { Skeleton } from '@material-ui/lab'

const Pulse = () => {
  const classes = timesheetStyles()
  const dispatch = useDispatch()
  const search = useSelector((state) => state.generalAppState.headerSearch)
  const [expanded, setExpanded] = useState({ online: false, offline: false })
  const userPulses = useSelector((state) => state.pulse.data)
  const userTeammates = useSelector((state) => state.userTeammates)
  const meData = useSelector((state) => state.me.data)
  const [onlineUsers, setOnlineUsers] = useState([])
  const [offlineUsers, setOfflineUsers] = useState([])
  const [pulseGraphData, setPulseGraphData] = useState({
    graphData: [],
    activeMembers: []
  })
  const [teammates, setTeammates] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString())
  const [loading, setLoading] = useState(false)

  const startDate = useMemo(() => {
    return new Date(new Date(selectedDate).setHours(0, 0, 0)).toISOString()
  }, [selectedDate])

  const endDate = useMemo(() => {
    return new Date(new Date(selectedDate).setHours(23, 59, 59)).toISOString()
  }, [selectedDate])

  const maxNumberOfActiveUsers = useMemo(() => {
    const arr = pulseGraphData.graphData.map((el) => el.activeUsers)
    if (arr.length) {
      let max = arr[0]
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] > max) {
          max = arr[i]
        }
      }
      return max
    } else {
      return 0
    }
  }, [pulseGraphData])

  useEffect(() => {
    setTeammates([
      ...userTeammates.data,
      {
        _id: meData._id,
        role: meData.role,
        name: meData.name,
        profileImage: meData.profileImage,
        lastActive: new Date()
      }
    ])
  }, [userTeammates.data, meData._id])

  useEffect(() => {
    if (!userTeammates.fetched) {
      dispatch(fetchTeammateOfCurrentUser())
    }
  }, [userTeammates.fetched])

  useEffect(() => {
    setLoading(true)
    fetchPulseGraphData(startDate, endDate, (res, err) => {
      setLoading(false)
      if (!err) {
        setPulseGraphData(res)
      }
    })
  }, [selectedDate])

  useEffect(() => {
    const online = pulseGraphData.activeMembers.map((item) => item._id)
    if (online.length) {
      if (search) {
        setOnlineUsers(
          pulseGraphData.activeMembers.filter((user) =>
            user.name.toLowerCase().includes(search.toLowerCase())
          )
        )
        setOfflineUsers(
          teammates.filter(
            (user) =>
              !online.includes(user._id) &&
              user.name.toLowerCase().includes(search.toLowerCase())
          )
        )
      } else {
        setOnlineUsers(pulseGraphData.activeMembers)
        setOfflineUsers(teammates.filter((user) => !online.includes(user._id)))
      }
    } else {
      setOnlineUsers([])
      setOfflineUsers(
        teammates.filter((user) =>
          user.name.toLowerCase().includes(search?.toLowerCase())
        )
      )
    }
  }, [pulseGraphData, teammates, search])

  const activeUsersOnTime = (hour) => {
    const data = pulseGraphData.graphData.find(
      (item) => new Date(item.timestamp).getHours() === hour
    )
    if (data && maxNumberOfActiveUsers) {
      return {
        activeUsers: data.activeUsers,
        height: (data.activeUsers * 100) / maxNumberOfActiveUsers
      }
    } else {
      return { activeUsers: 0, height: 100 }
    }
  }

  const handleSelectedDate = (key) => {
    let d = new Date(selectedDate)
    if (key === 'prev') {
      setSelectedDate(new Date(d.getTime() - 24 * 3600 * 1000).toISOString())
    } else {
      setSelectedDate(new Date(d.getTime() + 24 * 3600 * 1000).toDateString())
    }
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  return (
    <OuterContentWrapper>
      <GoBack className="mb-6" />
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center space-x-4">
          <h1 className="text-lg font-medium ml-1">Pulse</h1>
          <p className="border-l h-5"></p>
          <p className="text-sm text-primary-mid-dark-2">See who's online</p>
        </div>
        <div className="flex">
          {/* {format(new Date(selectedDate), 'MMM d, YYY')} */}

          <DatePicker
            popperPlacement="bottom-left"
            onChange={handleDateChange}
            selected={new Date(selectedDate)}
            customInput={
              <input
                className="bg-transparent px-2 border-b-2  border-dashed py-1 text-sm text-primary-mid-dark-2 cursor-pointer"
                style={{ width: '110px' }}
              />
            }
            dateFormat="MMM d, yyyy"
            maxDate={new Date()}
            showDisabledMonthNavigation
          />

          <div className="flex space-x-2">
            <CustomTooltip title="Prev day">
              <AngleLeft
                className="h-7 w-7 p-1 border cursor-pointer hover:bg-gray-100 ml-4"
                onClick={() => handleSelectedDate('prev')}
              />
            </CustomTooltip>
            <button
              // disabled={true}
              disabled={
                new Date(selectedDate).toDateString() ===
                new Date().toDateString()
              }
              onClick={() => handleSelectedDate('next')}
            >
              <CustomTooltip
                title={
                  new Date(selectedDate).toDateString() ===
                  new Date().toDateString()
                    ? ''
                    : 'Next day'
                }
              >
                <AngleRight
                  className={clsx(
                    'h-7 w-7 p-1 border cursor-pointer hover:bg-gray-100',
                    new Date(selectedDate).toDateString() ===
                      new Date().toDateString() &&
                      'cursor-not-allowed bg-gray-100 text-gray-300'
                  )}
                />
              </CustomTooltip>
            </button>
          </div>
        </div>
      </div>
      <div className="mx-auto" style={{ width: '95%', maxWidth: '1030px' }}>
        {!loading ? (
          <>
            <div className="bg-white shadow-lg rounded-xl shadow mb-8 px-2">
              <h2
                className="flex items-center uppercase text-gray-400 font-semibold space-x-2 py-2"
                style={{ fontSize: '9.5px' }}
              >
                <DotIcon className="h-2 w-2" style={{ fill: 'blue' }} />
                <span>people online </span>
              </h2>
              <div className="w-full flex  pb-4 overflow-auto">
                {Array(24)
                  .fill(11)
                  .map((el, ind) => (
                    <div className="flex flex-col items-center space-y-2 px-2">
                      <div className="w-3 h-20 flex flex-col">
                        <div
                          style={{
                            height: `${100 - activeUsersOnTime(ind).height}%`
                          }}
                        ></div>
                        <CustomTooltip
                          title={`${activeUsersOnTime(ind).activeUsers} users`}
                          placement="top"
                        >
                          <div
                            className="rounded"
                            style={{
                              backgroundImage: activeUsersOnTime(ind)
                                .activeUsers
                                ? 'linear-gradient(45deg, #1600e5, #fff)'
                                : 'none',
                              height: `${activeUsersOnTime(ind).height}%`
                            }}
                          ></div>
                        </CustomTooltip>
                      </div>
                      <span className="text-xs">
                        {ind === 0 ? 12 : ind <= 12 ? ind : ind - 12}
                        {ind < 12 ? 'am' : 'pm'}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className="mb-8 flex justify-between items-center px-2">
                <h3 className="text-sm text-primary-dark px-4">
                  {new Date(selectedDate).toDateString() ===
                  new Date().toDateString()
                    ? 'Now'
                    : `${format(new Date(selectedDate), 'MMM d, YYY')}`}
                </h3>
                {/* <div className="flex items-center">
              <div className="text-xs p-2 rounded text-gray-500 ml-4 cursor-pointer hover:text-gray-700 hover:bg-gray-100">
                Show details
              </div>
            </div> */}
              </div>
              <div className="mb-8">
                <div className="flex items-center">
                  <span
                    className={clsx(
                      {
                        expanded: expanded.online
                      },
                      classes.btn
                    )}
                    onClick={() =>
                      setExpanded((prev) => ({ ...prev, online: !prev.online }))
                    }
                  >
                    <ArrowRight />
                  </span>
                  <h3 className="text-sm font-medium">
                    {' '}
                    {new Date(selectedDate).toDateString() ===
                    new Date().toDateString()
                      ? 'Online'
                      : 'Was online'}
                  </h3>
                  <PeopleIcon className="text-gray-500 mx-2" />
                  <span className="text-gray-500 text-sm">
                    {onlineUsers.length}
                  </span>
                </div>
                {expanded.online && onlineUsers.length > 0 && (
                  <div className="flex items-center space-x-4 mt-4 pl-6">
                    {onlineUsers.map((user) => (
                      <div className="flex flex-col items-center space-y-2">
                        <ColoredAvatars
                          user={user}
                          size="medium"
                          online={
                            new Date(selectedDate).toDateString() ===
                            new Date().toDateString()
                              ? true
                              : false
                          }
                        />
                        <span className="text-xs">
                          {user.name.length <= 4
                            ? user.name
                            : `${user.name.substring(0, 4)}...`}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="mb-8">
                <div className="flex items-center">
                  <span
                    className={clsx(
                      {
                        expanded: expanded.offline
                      },
                      classes.btn
                    )}
                    onClick={() =>
                      setExpanded((prev) => ({
                        ...prev,
                        offline: !prev.offline
                      }))
                    }
                  >
                    <ArrowRight className="text-gray-500" />
                  </span>
                  <h3 className="text-sm font-medium text-gray-500">
                    {new Date(selectedDate).toDateString() ===
                    new Date().toDateString()
                      ? 'Offline'
                      : 'Was offline'}
                  </h3>
                  <PeopleIcon className="text-gray-500 mx-2" />
                  <span className="text-gray-500 text-sm">
                    {offlineUsers.length}
                  </span>
                </div>
                {expanded.offline && offlineUsers.length > 0 && (
                  <div className="flex items-center gap-5 mt-4 flex-wrap justify-start pl-6">
                    {offlineUsers.map((user) => (
                      <div className="flex flex-col items-center space-y-2">
                        <ColoredAvatars
                          user={user}
                          size="medium"
                          bgColor="gray"
                        />
                        <span className="text-xs">
                          {user.name.length <= 4
                            ? user.name
                            : `${user.name.substring(0, 4)}...`}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <Animation />
        )}
      </div>
    </OuterContentWrapper>
  )
}

const Animation = () => {
  return (
    // <OuterContentWrapper>
    //   <div className="flex justify-between items-center mb-8 mt-6">
    //     <div className="flex items-center space-x-4">
    //       <Skeleton className="w-16" style={{ height: '40px' }}></Skeleton>
    //       <Skeleton className="w-16" style={{ height: '40px' }}></Skeleton>
    //     </div>
    //     <div className="flex space-x-4">
    //       <Skeleton className="w-16" style={{ height: '40px' }}></Skeleton>
    //       <Skeleton className="w-16" style={{ height: '40px' }}></Skeleton>
    //     </div>
    //   </div>
    //   <div className=" mx-auto" style={{ width: '95%', maxWidth: '1090px' }}>
    //     <Skeleton style={{ height: '250px' }}></Skeleton>
    //     <Skeleton className="w-24" style={{ height: '40px' }}></Skeleton>
    //   </div>
    // </OuterContentWrapper>
    <>
      <Skeleton
        className="rounded-md"
        style={{ height: '250px', marginTop: '-50px', marginBottom: '-50px' }}
      ></Skeleton>
      <div className="mt-8">
        <Skeleton className="w-28 mb-8" style={{ height: '50px' }}></Skeleton>
        <Skeleton className="w-28 mb-8" style={{ height: '50px' }}></Skeleton>
        <Skeleton className="w-28 mb-8" style={{ height: '50px' }}></Skeleton>
      </div>
    </>
  )
}

export default Pulse
