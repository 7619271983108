import React from 'react'
import { Tooltip, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

/**
 * Custom tooltip
 * @param {Object} props Takes props of `Tooltip` of material ui
 */
const CustomTooltip = (props) => {
  const classes = useStyles(Boolean(props.isme))
  return <Tooltip arrow classes={classes} {...props} />
}

const useStyles = makeStyles((theme) => ({
  arrow: (isme) => ({
    color: isme ? theme.palette.custom.green.main : theme.palette.common.black
  }),
  tooltip: (isme) => ({
    backgroundColor: isme
      ? theme.palette.custom.green.main
      : theme.palette.common.black
  }),
  popper: {
    zIndex: 2000
  }
}))

export default CustomTooltip
