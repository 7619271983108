import AlertModal from 'global/globalComponents/AlertModal/AlertModal'
import {
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableDataLoading
} from 'global/globalComponents/TailwindTable/TailwindTable'
import { useEffect, useState } from 'react'
import { deleteCustomInvoice, markInvoiceAsPaid } from 'thunks/invoices/actions'
import InvoiceTableRow from './InvoiceTableRow'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { sendInvoiceEmail } from 'thunks/ProjectInvoices/actions'
import Currency from 'static/Currency/Currency'
import { format } from 'date-fns'
import { Pagination } from 'global/globalComponents'

const totalInvoicesToShow = 8

const InvoiceTable = ({
  invoices,
  openInvoicePreview,

  handleEditInvoice,
  elmFor = 'agency',
  clientCol = true,
  loading,
  markAsPaidCallback,
  deleteInvoiceCallback
}) => {
  const [markAsPaidModal, setMarkAsPaidModal] = useState({
    open: false,
    loading: false,
    data: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    loading: false,
    data: ''
  })
  const [visibleInvoices, setVisibleInvoices] = useState([])
  const [page, setPage] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    setVisibleInvoices(
      [...invoices].splice(page * totalInvoicesToShow, totalInvoicesToShow)
    )
  }, [invoices, page])

  const handleMarkInvoiceAsPaid = () => {
    setMarkAsPaidModal((prev) => ({ ...prev, loading: true }))

    dispatch(
      markInvoiceAsPaid(markAsPaidModal.data, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setMarkAsPaidModal((prev) => ({ ...prev, loading: false }))
        } else {
          setMarkAsPaidModal((prev) => ({ ...prev, open: false }))
          if (markAsPaidCallback) markAsPaidCallback(res)
        }
      })
    )
  }

  const handleDeleteInvoice = () => {
    setDeleteModal((prev) => ({ ...prev, loading: true }))

    dispatch(
      deleteCustomInvoice(deleteModal.data, (res, err) => {
        if (err) {
          dispatch(fireErrorToaster(err))
          setDeleteModal((prev) => ({ ...prev, loading: false }))
        } else {
          setDeleteModal((prev) => ({ ...prev, open: false }))
          if (deleteInvoiceCallback) {
            deleteInvoiceCallback(res._id)
          }
        }
      })
    )
  }

  const handleSendEmail = (invoice) => {
    sendInvoiceEmail(
      invoice._id,
      {
        subject: `You have an outstanding invoice from ${invoice.agency.agencyName}`,
        message: `
        <h2>You have an outstanding invoice.</h2>
        <p>This is a reminder that ${
          invoice.agency.agencyCompanyName
        } sent you an invoice for ${Currency[invoice.currency]?.symbol_native}${
          invoice.totalAmount
        }, due on ${format(new Date(invoice.dueDate), 'MMMM, dd, yyyy')} with ${
          Currency[invoice.currency]?.symbol_native
        }${invoice.totalAmount} to be paid.</p>

        <p>This is just a friendly reminder to take a look at the invoice I've sent. Please email me at ${
          invoice.agency.agencyEmail
        } with any questions.</p>
        <p>&nbsp;</p>
        <p>Thanks!</p>
      `,
        mailType: 'reminder'
      },
      (res, err) => {
        if (!err) {
          dispatch(fireSuccessToaster('Reminder Sent Successfully!'))
        }
      }
    )
  }

  const openMarkAsPaidAlert = (invoiceId) => {
    setMarkAsPaidModal({ open: true, loading: false, data: invoiceId })
  }

  const closeMarkAsPaidAlert = () => {
    setMarkAsPaidModal((prev) => ({ ...prev, open: false }))
  }

  const openDeleteAlert = (invoiceId) => {
    setDeleteModal({ open: true, loading: false, data: invoiceId })
  }

  const closeDeleteAlert = () => {
    setDeleteModal((prev) => ({ ...prev, open: false }))
  }

  return (
    <>
      <TableContainer mb={6}>
        <Thead>
          {clientCol ? (
            <Th className="text-left whitespace-nowrap">Client</Th>
          ) : (
            <Th className="text-left whitespace-nowrap">Invoice No.</Th>
          )}
          {/* <Th className="text-center whitespace-nowrap">Invoice NO.</Th> */}
          <Th className="text-center whitespace-nowrap">Items</Th>
          <Th className="text-center whitespace-nowrap">Status</Th>
          <Th className="text-right whitespace-nowrap">Amount</Th>
          <Th className="whitespace-nowrap">Issued date</Th>
          <Th className="text-center whitespace-nowrap"></Th>
        </Thead>
        <Tbody>
          {loading ? (
            <TableDataLoading cols={6} />
          ) : visibleInvoices.length === 0 ? (
            <Tr>
              <Td colSpan="6">No invoices available</Td>
            </Tr>
          ) : (
            visibleInvoices.map((item) => (
              <InvoiceTableRow
                invoice={item}
                openInvoicePreview={openInvoicePreview}
                handleEditInvoice={handleEditInvoice}
                openMarkAsPaidAlert={openMarkAsPaidAlert}
                handleSendReminder={handleSendEmail}
                openDeleteAlert={openDeleteAlert}
                elmFor={elmFor}
                clientCol={clientCol}
              />
            ))
          )}
        </Tbody>
      </TableContainer>
      {invoices.length > totalInvoicesToShow && (
        <Pagination
          handlePagination={setPage}
          totalResultsCount={invoices.length}
          visibleResultsCount={visibleInvoices.length}
          page={page}
          limit={totalInvoicesToShow}
        />
      )}

      <AlertModal
        heading="Are you sure"
        alertType="confirm"
        warningText="This invoice will marked as paid, and no additional client reminders will be sent."
        open={markAsPaidModal.open}
        handleDialog={closeMarkAsPaidAlert}
        handleDeleteAction={handleMarkInvoiceAsPaid}
        loading={markAsPaidModal.loading}
        deleteBtnText="Mark As Paid"
      />

      <AlertModal
        heading="Are you sure"
        warningText="Do you want to permanently delete this invoice?"
        open={deleteModal.open}
        handleDialog={closeDeleteAlert}
        handleDeleteAction={handleDeleteInvoice}
        loading={deleteModal.loading}
        deleteBtnText="Delete"
      />
    </>
  )
}

export default InvoiceTable
