import { Button, SvgIcon } from '@material-ui/core'
// import { ReactComponent as QuestionCircleIcon } from 'static/svg/question-circle.svg'
import { ReactComponent as PlayCircle } from 'static/svg/play-circle.svg'
import { userRoles } from 'utils'
import SectionHeading from 'global/globalComponents/SectionHeading'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
// import SearchBar from 'global/globalComponents/SearchBar/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { ReactComponent as Setting } from 'static/svg/setting.svg'
import {
  // fireErrorToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
// import { useLocation, useHistory } from 'react-router'
// import QuestionMarkTooltip from 'global/globalComponents/CustomTooltip'
import { CustomTooltip, PopupMenu } from 'global/globalComponents'
import { toggleAddTeammateSidePanel } from 'thunks/generlAppState/actions'
import TeammateOnboardingLinkModal from './TeammateOnboardingLinkModal'

// const tabsData = [
//   { label: 'Teammates', endPath: '' },
//   { label: 'Verticals', endPath: '/verticals' }
// ]

const TeammatesHeader = ({ openAddVerticalModal }) => {
  const dispatch = useDispatch()
  const access = useSelector(state => state.permission.data.access)
  const me = useSelector(state => state.me)
  const [addTeammateValid, setAddTeammateValid] = useState(null)
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false)

  useEffect(() => {
    if (!me.loading) {
      ;(me.data.profile?.quota?.teammate?.left ??
        me.data.team?.profile?.quota?.teammate?.left) <= 0
        ? setAddTeammateValid(false)
        : setAddTeammateValid(true)
    }
  }, [me])

  const teammateValidMessage = () => {
    dispatch(
      fireWarningToaster(
        'Your teammate quota has been exhausted. Please upgrade your plan to add new teammates'
      )
    )
  }

  const openAddTeammatePanel = () => {
    console.log('CLICKED!')
    dispatch(toggleAddTeammateSidePanel())
  }

  const toggleTeammateOnboardingModal = () => {
    setOpenOnboardingModal(prev => !prev)
  }

  return (
    <header>
      <div className="flex justify-between items-center mb-6 lg:mb-8">
        <div className="flex items-center">
          <SectionHeading>Teams</SectionHeading>
          {me.data.role === userRoles.AGENCY_MANAGER && (
            <a
              href="https://youtu.be/46wH7yZ-IEA"
              target="_blank"
              className="pl-1"
            >
              <SvgIcon
                component={PlayCircle}
                style={{
                  color: '#1600e5',
                  fontSize: '1rem',
                  marginBottom: '3px'
                }}
                className="cursor-pointer"
                viewBox="0 0 600 476.6"
              />
            </a>
          )}
        </div>
        {access?.teammate.create ? (
          me.data.role === userRoles.USER_CLIENT &&
          (me.data.canAddTeammates !== undefined
            ? !me.data.canAddTeammates
            : false) ? null : (
            <div className="flex items-center space-x-4">
              <Button
                variant="outlined"
                startIcon={<AddIcon fontSize="small" />}
                onClick={
                  addTeammateValid ? openAddTeammatePanel : teammateValidMessage
                }
              >
                New Teammate
              </Button>
              {[userRoles.USER_AGENCY, userRoles.AGENCY_ADMIN].includes(
                me.data.role
              ) && (
                <PopupMenu
                  targetComp={
                    <button className="w-8 h-8 rounded-full text-primary-mid-dark-2 flex justify-center items-center">
                      <Setting className="text-lg" />
                    </button>
                  }
                  menuItems={[
                    // { label: 'Manage Roles', method: openAddVerticalModal },
                    {
                      label: 'Onboarding',
                      method: toggleTeammateOnboardingModal
                    }
                  ]}
                />
                // <CustomTooltip title="Advance Setting" placement="top">
                // <button className="w-8 h-8 rounded-full bg-primary-main text-white flex justify-center items-center">
                //   <Setting
                //     onClick={openAddVerticalModal}
                //     className="text-lg"
                //   />
                // </button>
                // </CustomTooltip>
              )}
            </div>
          )
        ) : null}
      </div>
      {/*
      <div className="flex justify-end items-center my-8">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="flex items-center justify-end mx-0"
        >
          <SearchBar
            placeholder="Search…"
            value={search}
            onChange={handleSearchChange}
          />
        </form>
      </div> */}
      <TeammateOnboardingLinkModal
        open={openOnboardingModal}
        handleClose={toggleTeammateOnboardingModal}
      />
    </header>
  )
}

export default TeammatesHeader
