import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import {
  // ProjectAnalyticsTable,
  TeammateAnalyticsTable,
  // TaskTable,
  ConsolidatedTasksWidget,
  WidgetModal,
  NoWidgetMessage
} from './Widget'
// import WorkspacesList from './Workspaces/AgencyWorkspacesList'
import userRoles from 'utils/userRoles'
import GridLayout from 'react-grid-layout'
import { Button } from '@material-ui/core'
import {
  fetchAllWidgets,
  updateWidgetById,
  deleteWidgetById,
  createNewWidget
} from 'thunks/widget/actions'
import 'react-grid-layout/css/styles.css'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { LoadingButton } from 'global/globalComponents'
// import userDashboardStyles from './userDashboardStyles'
import { ReactComponent as BorderOuter } from 'static/svg/border-outer.svg'
import { CustomTooltip } from 'global/globalComponents'
import { Skeleton } from '@material-ui/lab'
import { findSpaceInGrid } from 'utils'
// import { userActiveTimeLog } from 'thunks/logs/action'
import RequestAnalyticsTable from './Widget/RequestsAnalyticsTable/RequestAnalyticsTable'
import ClientRequestAnalyticsTable from './Widget/ClientRequestAnalyticsTable/ClientRequestAnalyticsTable'
import RaiseRequestModal from 'components/ClientComponent/ClientDashboard/RaiseRequestModal'
import { ReactComponent as Add } from 'static/svg/plus.svg'
import { useTheme } from '@material-ui/core/styles'
import NewProjectAnalyticsTable from './Widget/ProjectAnalyticsTable/NewProjectAnalyticsTable'
import StandupsTasks from './Widget/StandupsTask/StandupsTasks'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'

const UserDashboard = () => {
  // const me = useSelector((state) => state.me)
  // const userWorkspaces = useSelector((state) => state.userWorkspaces)

  return (
    // <WorkspacesPusherWrapper>
    <OuterContentWrapper>
      <DashboardWithWidgets />
    </OuterContentWrapper>
    // </WorkspacesPusherWrapper>
  )
}

const DashboardWithWidgets = () => {
  const me = useSelector(state => state.me)
  // const classes = userDashboardStyles()
  const dispatch = useDispatch()
  const [openWidgetModal, setOpenWidgetModal] = useState(false)
  const { loading: widgetLoading, fetched, data: widgetData } = useSelector(
    state => state.widget
  )
  const { sidebarOpen } = useSelector(state => state.generalAppState)

  const [openReqModal, setOpenReqModal] = useState(false)
  const theme = useTheme()

  const [layout, setLayout] = useState({
    totalWidth: sidebarOpen
      ? window.innerWidth - (theme.custom.sidebar.width + 60)
      : window.innerWidth - 60,
    cols: 12,
    data: [],
    loading: true
  })

  const [editMode, setEditMode] = useState(false)
  const [savingData, setSavingData] = useState(false)
  const [addedNewWidgetCount, setAddedNewWidgetCount] = useState(0)
  const [widgetToRemove, setWidgetToRemove] = useState({})
  const ref = useRef()
  // const newRef = useRef()

  useEffect(() => {
    const handleWidgetContainerSize = () => {
      if (sidebarOpen) {
        setLayout(prev => ({
          ...prev,
          totalWidth: window.innerWidth - (theme.custom.sidebar.width + 60)
        }))
      } else {
        setLayout(prev => ({ ...prev, totalWidth: window.innerWidth - 60 }))
      }
    }

    window.addEventListener('resize', handleWidgetContainerSize)

    return () => {
      window.removeEventListener('resize', handleWidgetContainerSize)
    }
  }, [sidebarOpen])

  // useEffect(() => {
  //   newRef.current = setInterval(userActiveTimeLog(), 1 * 60 * 1000)
  //   return () => {
  //     if (newRef.current) {
  //       clearInterval(newRef.current)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (fetched) return
    dispatch(fetchAllWidgets())
  }, [fetched])

  useEffect(() => {
    if (widgetLoading || savingData) return
    const mappedData = widgetData
      .filter(item => item.title.toLowerCase() !== 'invoices')
      .map(item => ({
        i: item._id,
        title: item.title,
        x: item.attributes.column_position,
        y: item.attributes.row_position,
        w: item.attributes.width,
        h: item.attributes.height
      }))

    setLayout(prev => ({
      ...prev,
      data: mappedData,
      loading: false
    }))
  }, [widgetLoading, widgetData, savingData])

  useEffect(() => {
    if (addedNewWidgetCount) {
      window.scroll({
        left: 0,
        top: ref.current.offsetHeight,
        behavior: 'smooth'
      })
    }
  }, [addedNewWidgetCount])

  useEffect(() => {
    if (sidebarOpen)
      setLayout(prev => ({
        ...prev,
        totalWidth: window.innerWidth - (theme.custom.sidebar.width + 60)
      }))
    else setLayout(prev => ({ ...prev, totalWidth: window.innerWidth - 60 }))
  }, [sidebarOpen])

  const handleLayoutChange = updatedData => {
    const updatedLayout = layout.data.map(item => {
      const match = updatedData.find(obj => obj.i === item.i)
      return { ...item, ...match }
    })

    setLayout(prev => ({ ...prev, data: updatedLayout }))
  }

  const toggleEditMode = () => {
    setEditMode(prev => !prev)
  }

  const cancelLayoutChanges = () => {
    const data = widgetData
      .filter(item => item.title.toLowerCase() !== 'invoices')
      .map(item => {
        const match = layout.data.find(obj => obj.i === item._id) ?? {}

        return {
          ...match,
          i: item._id,
          title: item.title,
          x: item.attributes.column_position,
          y: item.attributes.row_position,
          w: item.attributes.width,
          h: item.attributes.height
        }
      })

    setLayout(prev => ({
      ...prev,
      data
    }))

    setEditMode(false)
    setWidgetToRemove({})
  }

  const handleSaveLayoutChanges = () => {
    setSavingData(true)
    const promises = []

    for (let widget of layout.data) {
      const obj = {
        title: widget.title,
        attributes: {
          column_position: widget.x,
          row_position: widget.y,
          width: widget.w,
          height: widget.h
        }
      }

      // if widget is in "widgetToRemove" list then break
      if (widgetToRemove[widget.i]) break

      if (widget.tempAdded) {
        promises.push(dispatch(createNewWidget(obj)))
      } else {
        promises.push(dispatch(updateWidgetById(widget.i, obj)))
      }
    }

    Object.keys(widgetToRemove).forEach(id => {
      promises.push(dispatch(deleteWidgetById(id)))
    })

    Promise.all(promises)
      .then(res => {
        setSavingData(false)
        dispatch(fireSuccessToaster('Changes saved successfully!'))
        setEditMode(false)
        setWidgetToRemove([])
      })
      .catch(() => {
        setSavingData(false)
        dispatch(
          fireErrorToaster(
            'Error occurred while saving widget, please try again!'
          )
        )
      })
  }

  const handleAddNewWidget = widgetTitle => {
    const width = widgetTitle === 'invoices' ? 4 : 6,
      height = widgetTitle === 'invoices' ? 6 : 8

    const res = findSpaceInGrid(
      {
        x: 0,
        y: 0,
        w: width,
        h: height
      },
      layout.data
    )

    setLayout(prev => ({
      ...prev,
      data: prev.data.concat({
        i: Date.now().toString(),
        tempAdded: true,
        title: widgetTitle,
        x: res.x,
        y: res.y,
        w: width,
        h: height
      })
    }))

    setEditMode(true)
    setAddedNewWidgetCount(prev => prev + 1)
  }

  const toggleWidgetModal = () => {
    setOpenWidgetModal(prev => !prev)
  }

  const handleRemoveWidget = useCallback(id => {
    let isTemp = false
    setLayout(prev => ({
      ...prev,
      data: prev.data.filter(item => {
        isTemp = item.tempAdded
        return item.i !== id
      })
    }))

    // If not temporary widget only then store its id
    if (!isTemp) setWidgetToRemove(prev => ({ ...prev, [id]: id }))
  }, [])

  return (
    <>
      {layout.loading ? (
        <WidgetsLoading />
      ) : (
        <>
          <div className="flex justify-end space-x-2 pb-4 lg:pb-6">
            {editMode ? (
              <>
                <LoadingButton
                  onClick={handleSaveLayoutChanges}
                  loading={savingData}
                >
                  Save layout
                </LoadingButton>
                <Button variant="outlined" onClick={cancelLayoutChanges}>
                  Cancel
                </Button>
              </>
            ) : (
              <CustomTooltip title="Edit Widgets" placement="bottom">
                <button
                  onClick={toggleEditMode}
                  className="mr-2 text-lg text-gray-500"
                >
                  <BorderOuter />
                </button>
              </CustomTooltip>
            )}
            {me.data.role < userRoles.USER_CLIENT ? (
              <Button onClick={toggleWidgetModal}>Add Widget</Button>
            ) : (
              ![userRoles.CLIENT_EDITOR, userRoles.CLIENT_VIEWER].includes(
                me.data.role
              ) && (
                <Button
                  startIcon={<Add fontSize="small" />}
                  onClick={() => setOpenReqModal(true)}
                >
                  Raise Request
                </Button>
              )
            )}
          </div>
          <div ref={ref}>
            {layout.data.length ? (
              <GridLayout
                layout={layout.data}
                cols={layout.cols}
                rowHeight={30}
                width={layout.totalWidth}
                margin={[20, 20]}
                onLayoutChange={handleLayoutChange}
                isResizable={editMode}
                isDraggable={editMode}
                resizeHandles={['se', 'sw']}
              >
                {layout.data.map(item => (
                  <div key={item.i}>
                    {item.title === 'projects' ? (
                      // <ProjectAnalyticsTable
                      //   widgetId={item.i}
                      //   editMode={editMode}
                      //   handleRemoveWidget={handleRemoveWidget}
                      // />
                      <NewProjectAnalyticsTable
                        widgetId={item.i}
                        editMode={editMode}
                        handleRemoveWidget={handleRemoveWidget}
                      />
                    ) : item.title === 'timesheet' ? (
                      <TeammateAnalyticsTable
                        widgetId={item.i}
                        editMode={editMode}
                        handleRemoveWidget={handleRemoveWidget}
                      />
                    ) : item.title.toLowerCase() === 'tasks' ? (
                      <ConsolidatedTasksWidget
                        widgetId={item.i}
                        editMode={editMode}
                        handleRemoveWidget={handleRemoveWidget}
                      />
                    ) : item.title.toLowerCase() === 'client requests' ? (
                      <RequestAnalyticsTable
                        widgetId={item.i}
                        editMode={editMode}
                        handleRemoveWidget={handleRemoveWidget}
                      />
                    ) : item.title.toLowerCase() === 'standups' ? (
                      <StandupsTasks
                        widgetId={item.i}
                        editMode={editMode}
                        handleRemoveWidget={handleRemoveWidget}
                      />
                    ) : item.title.toLowerCase() === 'client own requests' ||
                      item.title.toLowerCase() === 'requests' ? (
                      <ClientRequestAnalyticsTable
                        widgetId={item.i}
                        editMode={editMode}
                        handleRemoveWidget={handleRemoveWidget}
                      />
                    ) : null}
                  </div>
                ))}
              </GridLayout>
            ) : (
              <NoWidgetMessage openAddWidgetModal={toggleWidgetModal} />
            )}
          </div>
        </>
      )}

      <WidgetModal
        open={openWidgetModal}
        onClose={toggleWidgetModal}
        handleAddNewWidget={handleAddNewWidget}
      />
      <RaiseRequestModal
        open={openReqModal}
        handleClose={() => setOpenReqModal(false)}
        canUpdate={me.data.role <= userRoles.CLIENT_MANAGER}
      />
    </>
  )
}

const WidgetsLoading = () => {
  return (
    <div className="py-8 mt-12">
      <div className="flex w-full h-full space-x-4 flex-wrap mb-4">
        <Skeleton variant="rect" height={300} className="flex-1 rounded" />
        <Skeleton variant="rect" height={300} className="flex-1 rounded" />
      </div>
    </div>
  )
}

export default UserDashboard
