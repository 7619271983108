import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Card = ({ children, className, style }) => {
  return (
    <div
      className={clsx(
        'bg-white border rounded-xl shadow-md overflow-hidden',
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string
}

export default React.memo(Card)
